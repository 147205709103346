import { PaperworkFieldName, ProviderGroupPaperworkFieldName } from '../../../paperworkConstants';
import { SectionPageCollection } from '../../../interfaces';

export enum LifestyleFactorsPageNumber {
  MaritalStatus,
  Alcohol,
  AlcoholIntake,
  EverSmoked,
  CurrentSmoker,
  SmokingIntake,
  SmokingLength,
  Drugs,
  DrugsList,
  SexuallyActive,
}

export const LIFESTYLE_FACTORS_PAGES: SectionPageCollection = new Map([
  [
    LifestyleFactorsPageNumber.MaritalStatus,
    {
      fieldNames: [PaperworkFieldName.PATIENT_MARITAL_STATUS],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.Alcohol,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.DO_YOU_DRINK],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.AlcoholIntake,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.HOW_MUCH_ALCOHOL],
    },
  ],
  [
    LifestyleFactorsPageNumber.EverSmoked,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.EVER_SMOKED],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.CurrentSmoker,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.PAST_SMOKING_USAGE],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.SmokingIntake,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.SMOKING_AMOUNT],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.SmokingLength,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.SMOKING_HOW_LONG],
    },
  ],
  [
    LifestyleFactorsPageNumber.Drugs,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.DRUGS],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.DrugsList,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.DRUGS_LIST],
      allowDisableSubmit: true,
    },
  ],
  [
    LifestyleFactorsPageNumber.SexuallyActive,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.SEXUAL_ACTIVITY],
      showSubmit: false,
      allowSkip: true,
    },
  ],
]);
