import Specialty from '@solvhealth/types/interfaces/Specialty';

export const PG_BOOK_MODULE_MODAL = 'pg-book-module-modal';
export const DESKTOP_CALL_MODAL = 'pg-desktop-call-modal';
export const CALL_LOCATION_MODAL = 'pg-call-location-modal';
export const WRITE_A_REVIEW_MODAL = 'pg-write-a-review-modal';

const FETCH_SLOTS_INTERVAL_DAYS = 7;
const EXTERNAL_SLOT_DATE_FETCH_FORMAT = 'YYYY-MM-DD';

enum SpecialtyNames {
  FAMILY_MEDICINE = 'family_medicine',
  FAMILY_PHYSICIAN = 'family_physician',
  INTERNAL_MEDICINE = 'internist',
  PEDIATRICS = 'pediatrician',
  PRIMARY_CARE = 'primary_care',
  URGENT_CARE = 'urgent_care',
}

const PRIMARY_CARE_SUBSPECIALTIES = [
  SpecialtyNames.FAMILY_MEDICINE,
  SpecialtyNames.FAMILY_PHYSICIAN,
  SpecialtyNames.INTERNAL_MEDICINE,
  SpecialtyNames.PEDIATRICS,
  SpecialtyNames.URGENT_CARE,
];

const PRIMARY_CARE_SPECIALTY_NAME = 'primary_care';

// primary care is not an actual specialty, but rather a collection of subspecialties (listed above).
// however, we still need to display primary care as a specialty on our front-end, so we are using this
// dummy specialty object so that it can be treated as a normal specialty on our front end
const PRIMARY_CARE_SPECIALTY_OBJECT: Specialty = {
  id: 'primary care fake id',
  name: PRIMARY_CARE_SPECIALTY_NAME,
  display_name: 'Primary Care',
  specialty_field: 'primary_care',
  display_name_plural: 'Primary Care Providers',
  specialty_field_display_name: 'Primary Care',
};

// pg url query param constants
enum ProviderListSortTypes {
  FIRST_AVAILABLE = 'fa',
}

enum ValidProviderGroupQueryParamKeys {
  DR_PREFIX_DISABLED = 'dpd',
  SPECIALTY = 'specialty',
  SOLV_LOGO_DISABLED = 'sld',
  SORT_BY = 'sort',
}

const NEW_PATIENT = 'New Patient';

export {
  EXTERNAL_SLOT_DATE_FETCH_FORMAT,
  FETCH_SLOTS_INTERVAL_DAYS,
  SpecialtyNames,
  PRIMARY_CARE_SUBSPECIALTIES,
  PRIMARY_CARE_SPECIALTY_NAME,
  PRIMARY_CARE_SPECIALTY_OBJECT,
  ValidProviderGroupQueryParamKeys,
  ProviderListSortTypes,
  NEW_PATIENT,
};
