import { PARSE_URL_PARAMETERS, ADD_URL_PARAMETERS_FROM_COOKIE_HISTORY } from '../constants/index';

export default function trackingReducer(state = {}, action: any) {
  switch (action.type) {
    case PARSE_URL_PARAMETERS: {
      return {
        ...state,
        campaign: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'campaign' does not exist on type '{}'.
          ...state.campaign,
          ...action.payload.value,
        },
      };
    }
    case ADD_URL_PARAMETERS_FROM_COOKIE_HISTORY: {
      return {
        ...state,
        campaignFromCookies: action.payload.value,
      };
    }
    default:
      return state;
  }
}
