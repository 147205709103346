import { GenericObj } from '@solvhealth/types/interfaces/generics';
import { Action, AnyAction, Reducer } from 'redux';

enum PaperworkMetaActions {
  META_PAPERWORK_SET_FORM_DATA = 'paperworkMeta/SET_FORM_DATA',
  META_PAPERWORK_SET_PHOTO_ID_FRONT_ID = 'paperworkMeta/SET_PHOTO_ID_FRONT_ID',
  META_PAPERWORK_SET_PHOTO_ID_BACK_ID = 'paperworkMeta/SET_PHOTO_ID_BACK_ID',
  META_PAPERWORK_SET_PHOTO_ID_FRONT_IMG_URL = 'paperworkMeta/SET_PHOTO_ID_FRONT_IMG_URL',
  META_PAPERWORK_SET_PHOTO_ID_BACK_IMG_URL = 'paperworkMeta/SET_PHOTO_ID_BACK_IMG_URL',
  META_PAPERWORK_SET_INSURANCE_ID_FRONT_ID = 'paperworkMeta/SET_INSURANCE_ID_FRONT_ID',
  META_PAPERWORK_SET_INSURANCE_ID_BACK_ID = 'paperworkMeta/SET_INSURANCE_ID_BACK_ID',
  META_PAPERWORK_SET_INSURANCE_ID_FRONT_IMG_URL = 'paperworkMeta/SET_INSURANCE_ID_FRONT_IMG_URL',
  META_PAPERWORK_SET_INSURANCE_ID_BACK_IMG_URL = 'paperworkMeta/SET_INSURANCE_ID_BACK_IMG_URL',
  META_PAPERWORK_RESET_STATE = 'paperworkMeta/RESET_STATE',
}

export const setMetaPaperwork = (formData: GenericObj) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_FORM_DATA,
  payload: { formData },
});

export const setMetaPaperworkPhotoIdFrontId = (id: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_FRONT_ID,
  payload: { id },
});

export const setMetaPaperworkPhotoIdBackId = (id: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_BACK_ID,
  payload: { id },
});

export const setMetaPaperworkPhotoIdFrontImgUrl = (imgData: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_FRONT_IMG_URL,
  payload: { imgData },
});

export const setMetaPaperworkPhotoIdBackImgUrl = (imgData: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_BACK_IMG_URL,
  payload: { imgData },
});

export const setMetaPaperworkInsuranceIdFrontId = (id: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_FRONT_ID,
  payload: { id },
});

export const setMetaPaperworkInsuranceIdBackId = (id: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_BACK_ID,
  payload: { id },
});

export const setMetaPaperworkInsuranceIdFrontImgUrl = (imgData: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_FRONT_IMG_URL,
  payload: { imgData },
});

export const setMetaPaperworkInsuranceIdBackImgUrl = (imgData: string) => ({
  type: PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_BACK_IMG_URL,
  payload: { imgData },
});

export const resetPaperworkMetaState = () => ({
  type: PaperworkMetaActions.META_PAPERWORK_RESET_STATE,
});

export const getInitialState = (): PaperworkMetaState => ({
  formData: {},
  photoId: {},
  insuranceId: {},
});

interface IdInfo {
  frontId?: string;
  backId?: string;
  frontImgUrl?: string;
  backImgUrl?: string;
}

export type PaperworkMetaState = {
  formData: GenericObj;
  photoId: IdInfo;
  insuranceId: IdInfo;
};

const reducer: Reducer<PaperworkMetaState> = (
  state = getInitialState(),
  action
): PaperworkMetaState => {
  switch (action.type) {
    case PaperworkMetaActions.META_PAPERWORK_SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload.formData,
      };

    case PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_FRONT_ID:
      return {
        ...state,
        photoId: {
          ...state.photoId,
          frontId: action.payload.id,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_BACK_ID:
      return {
        ...state,
        photoId: {
          ...state.photoId,
          backId: action.payload.id,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_FRONT_IMG_URL:
      return {
        ...state,
        photoId: {
          ...state.photoId,
          frontImgUrl: action.payload.imgData,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_PHOTO_ID_BACK_IMG_URL:
      return {
        ...state,
        photoId: {
          ...state.photoId,
          backImgUrl: action.payload.imgData,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_FRONT_ID:
      return {
        ...state,
        insuranceId: {
          ...state.insuranceId,
          frontId: action.payload.id,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_BACK_ID:
      return {
        ...state,
        insuranceId: {
          ...state.insuranceId,
          backId: action.payload.id,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_FRONT_IMG_URL:
      return {
        ...state,
        insuranceId: {
          ...state.insuranceId,
          frontImgUrl: action.payload.imgData,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_SET_INSURANCE_ID_BACK_IMG_URL:
      return {
        ...state,
        insuranceId: {
          ...state.insuranceId,
          backImgUrl: action.payload.imgData,
        },
      };
    case PaperworkMetaActions.META_PAPERWORK_RESET_STATE:
      return {
        ...state,
        ...getInitialState(),
      };
    default:
      return state;
  }
};

export default reducer;
