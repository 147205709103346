import type { ResponseOption } from '@solvhealth/types/interfaces/PaperworkField';

/**
 * Determines whether responseOptions is a string array or ResponseOption array
 *
 * @param responseOptions string array or ResponseOption array
 * @returns boolean
 */
export function isResponseOptions(
  responseOptions: string[] | ResponseOption[]
): responseOptions is ResponseOption[] {
  return typeof responseOptions?.[0] !== 'string' && !!responseOptions?.[0]?.label;
}
