import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const UNSELECTED_STAR_URL = '/images/stars/Star-Grey-Fill.svg';
const SELECTED_STAR_URL = '/images/stars/Star-Orange-Fill.svg';
const UNDEFINED_STAR_URL = '/images/stars/Star-Outline.svg';
const STAR_HEIGHT_RATIO = 93 / 100; // star height / width ratio

const Container = styled.div`
  white-space: nowrap;
`;

const StarContainer = styled.div<{ width: number; height: number }>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: transform 200ms ease;
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
    margin: 0 ${width / 8}px;
  `};

  &:hover {
    transform: scale(1.1);
  }
`;

const BaseStar = styled.div<{ opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-size: contain;
  transition: opacity 200ms ease;
  opacity: ${({ opacity }) => opacity};
`;

const UnselectedStar = styled(BaseStar)`
  background-image: url(${UNSELECTED_STAR_URL});
`;

const UndefinedStar = styled(BaseStar)`
  background-image: url(${UNDEFINED_STAR_URL});
`;

const SelectedStar = styled(BaseStar)`
  background-image: url(${SELECTED_STAR_URL});
`;

type Props = {
  onChange: (starValue: number) => void;
  rating?: number;
  className?: string;
  starWidth?: number;
};

const StarSelector = ({ rating, onChange, className, starWidth = 16 }: Props) => {
  const [hoverIdx, setHoverIdx] = useState(0);

  const getOpacity = (idx: any, imageUrl: any) => {
    const selection = (hoverIdx || rating) ?? 0;
    switch (imageUrl) {
      default:
      case UNDEFINED_STAR_URL:
        return selection ? 0 : 1;
      case SELECTED_STAR_URL:
        return idx <= selection ? 1 : 0;
      case UNSELECTED_STAR_URL:
        return idx > selection ? 1 : 0;
    }
  };

  const handleMouseLeave = () => {
    setHoverIdx(0);
  };

  const height = starWidth * STAR_HEIGHT_RATIO;

  return (
    <Container
      className={className}
      data-testid="star-sel"
      onMouseLeave={handleMouseLeave}
      role="menu"
    >
      {[1, 2, 3, 4, 5].map((idx) => (
        <StarContainer height={height} key={idx} onClick={() => onChange(idx)} width={starWidth}>
          <UnselectedStar
            aria-hidden={getOpacity(idx, UNSELECTED_STAR_URL) === 0}
            aria-label={`${idx} star`}
            opacity={getOpacity(idx, UNSELECTED_STAR_URL)}
            role="menuitemradio"
          />
          <UndefinedStar aria-hidden opacity={getOpacity(idx, UNDEFINED_STAR_URL)} />

          <SelectedStar
            aria-checked
            aria-hidden={getOpacity(idx, SELECTED_STAR_URL) === 0}
            aria-label={`${idx} star`}
            onMouseOver={() => setHoverIdx(idx)}
            opacity={getOpacity(idx, SELECTED_STAR_URL)}
            role="menuitemradio"
          />
        </StarContainer>
      ))}
    </Container>
  );
};

export default StarSelector;
