/**
 * Function with an empty body
 *
 * @returns {void}
 */
const emptyFunction: (...args: any[]) => void = () => {};

/**
 * Compares two values using strict equals (===).
 *
 * @param {*} val1
 * @param {*} val2
 * @returns {boolean}
 */
const basicComparator = (val1: any, val2: any) => val1 === val2;

export { emptyFunction, basicComparator };
