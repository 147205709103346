import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Text, Button } from '@solvhealth/jigsaw';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  NATIVE_PUSH_FULL_SCREEN_DENY,
  NATIVE_PUSH_FULL_SCREEN_ALLOW,
  NATIVE_PUSH_FULL_SCREEN_OPEN_MODAL,
} from '~/core/analytics/events';
import { showModal } from '~/actions/runtime';
import { NativeFunctions } from '~/core/util/native';

export const FULL_SCREEN_PUSH_PROMPT_MODAL = 'native push full screen modal';

const SmoothDialog = styled(Dialog)`
  -webkit-font-smoothing: antialiased;
`;

const Root = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 48px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 480px;
  flex-grow: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const PhoneImage = styled.img`
  margin-top: auto;
  padding-bottom: 48px;
`;

const Title = styled(Text)`
  margin: 30px 0;
  text-align: center;
  max-width: 80%;
`;

const Disclaimer = styled(Text)`
  max-width: 80%;
  text-align: center;
`;

const onAllow = (callback: any) => () => {
  analyticsTrackEvent(NATIVE_PUSH_FULL_SCREEN_ALLOW);
  if (window.handleAcceptIosPushNative) {
    // Try using the function that triggers the native prompt directly
    // If it's not available (older versions of the app),
    // trigger the flow which includes also a custom alert before the native one
    window.handleAcceptIosPushNative();
  } else if (window.handleAcceptIosPushPermission) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleAcceptIosPushPermission' does not ... Remove this comment to see the full error message
    window.handleAcceptIosPushPermission();
  }
  NativeFunctions.directToSettings.call();
  callback();
};

const onDeny = (callback: any) => () => {
  analyticsTrackEvent(NATIVE_PUSH_FULL_SCREEN_DENY);
  callback();
};

type FullScreenPromptProps = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

export const FullScreenPrompt = withMobileDialog<FullScreenPromptProps>()(
  ({ onClose, open }: FullScreenPromptProps) => {
    useEffect(() => {
      if (open) {
        analyticsTrackEvent(NATIVE_PUSH_FULL_SCREEN_OPEN_MODAL);
      }
    }, [open]);
    const [hasSeen, setHasSeen] = useState(false);

    const handleClose = () => {
      setHasSeen(true);
      onClose();
    };

    if (hasSeen) {
      return null;
    }
    return (
      <SmoothDialog
        fullScreen
        fullWidth
        onClose={handleClose}
        open={open}
        PaperProps={{ classes: { root: 'dialog-root' } }}
      >
        <Root>
          <Top>
            <Title variant="h3">Don't miss important health reminders</Title>
            <PhoneImage src="/images/account/phone_push_notification_demo.svg" />
            <Disclaimer>
              Stay on top of your health care, from upcoming appointments to insurance updates, and
              more.
            </Disclaimer>
          </Top>

          <ButtonsContainer>
            <Button fullWidth onClick={onAllow(handleClose)} sx={{ mb: 2 }} variant="contained">
              Allow notifications
            </Button>

            <Button fullWidth onClick={onDeny(handleClose)} variant="text">
              Skip for now
            </Button>
          </ButtonsContainer>
        </Root>
      </SmoothDialog>
    );
  }
);

export const showFullScreenPrompt = () => showModal(FULL_SCREEN_PUSH_PROMPT_MODAL);
