/**
 * handleCheckBoxChange
 *
 * A convenience method for handling selection changes in a checkbox group.
 * This function will take the value of a clicked checkbox and update the status of
 * the provided array of values.  Works well with the 'useState' hook where the
 * values and setValues params are based on the returned variables from useState:
 *     const [values, setValues] = useState()
 *     handleCheckboxChange(valueToToggle, setValues, values, onChange)
 */
const handleCheckboxChange = (
  toggledValue: any,
  setValues: (values: any[]) => void,
  values?: any[],
  onChange?: Function
) => {
  let newValues = [];
  if (values && values.indexOf(toggledValue) > -1) {
    newValues = values.filter((v) => v !== toggledValue);
  } else {
    newValues = [...(values ?? []), toggledValue];
  }
  setValues(newValues);
  onChange?.(newValues);
};

export { handleCheckboxChange };
