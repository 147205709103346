import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { fontFamily, fontSize } from '../../../constants/text';
import { SolvReduxState } from '../../../reducers';
import { REQUEST_UNIVERSAL_SEARCH } from '../../../sagas/universalSearch';
import AutocompleteResults from '../AutocompleteResults';
import Header from '../Header';

const Root = styled.div`
  background-color: ${
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'greyDim' does not exist on type '{ fadeT... Remove this comment to see the full error message
    colors.greyDim
  };
  min-height: 100%;
`;

const ContentContainer = styled.div`
  padding: 24px 16px;
  text-align: center;
`;

const ErrorReason = styled.div`
  margin: 0 auto;
  font-size: ${fontSize.default};
  font-family: ${fontFamily.book};
  line-height: 24px;
  color: ${colors.squid};

  & em {
    color: ${colors.crunchBerry};
    font-style: normal;
  }

  & b {
    font-family: ${fontFamily.bold};
    font-weight: normal;
  }
`;

type Props = {
  onClickBack?: () => void;
  requestSearch?: (doc: any) => void;
  fromSrp?: boolean;
};

const UserChoiceContent = ({ fromSrp, onClickBack }: Props) => {
  const { universalSearch, position, searchPreferences } = useSelector((state: SolvReduxState) => ({
    universalSearch: state.universalSearch,
    position: state.position,
    searchPreferences: state.searchPreferences,
  }));

  const dispatch = useDispatch();
  const requestSearch = useCallback(
    (fullDoc: any) =>
      dispatch({
        type: REQUEST_UNIVERSAL_SEARCH,
        fullDoc,
        fromSrp,
      }),
    [dispatch, fromSrp]
  );

  const suggestionCopy = useMemo(
    () =>
      universalSearch?.results?.length
        ? 'Try the suggestions below'
        : 'Try the options below or refine your search',
    [universalSearch.results.length]
  );

  const locationLabel = useMemo(
    () => searchPreferences?.location?.label ?? position?.result?.label ?? '',
    [position, searchPreferences]
  );

  const remappedSuggestions = universalSearch.results.map((result: any) => ({
    ...result,
    onClick: () => requestSearch(result.fullDoc),
  }));

  return (
    <Root>
      <Header
        cancelCopy=""
        confirmCopy="Cancel"
        onCancel={onClickBack}
        onConfirm={onClickBack}
        title="Find Care"
      />

      <ContentContainer>
        <ErrorReason>
          We couldn't find an exact match for
          <br />"<em>{universalSearch.query}</em>" in
          <b> {locationLabel}</b>
        </ErrorReason>

        <br />

        <ErrorReason>{suggestionCopy}</ErrorReason>
      </ContentContainer>

      <AutocompleteResults results={remappedSuggestions} />
    </Root>
  );
};

export default UserChoiceContent;
