import { useSelector } from 'react-redux';
import useSelectUserProfile from '~/components/AccountGetsMarried/hooks/useSelectUserProfile';
import useStateFromPosition from '~/components/BookingFlow/hooks/useStateFromPosition';
import { getProfileFromAccountSummary } from '~/core/util/account';
import { useAccount } from './account/useAccount';
import useSelectActiveUserProfile from './useSelectActiveUserProfile';

/**
 * Returns the 2-letter US State Code for which the user is currently seeking care
 * or that we presume them to be in.
 *
 * Prioritizes data in the following order:
 * 1. Search Preferences Location
 * 2. User's IP Geolocation
 * 3. User's Profile Address State
 *
 * @returns {string} the 2-letter state code
 */
export function useCurrentCareRegion(userProfileId?: string) {
  const summary = useAccount();
  const overrideUserProfile = getProfileFromAccountSummary(summary, userProfileId);
  const activeUserProfile = useSelectActiveUserProfile();
  const userProfile = overrideUserProfile || activeUserProfile;

  const regionFromLocation = useSelector((state) => state.searchPreferences.location?.state_code);
  const regionFromProfile = userProfile?.address_state;
  const regionFromPosition = useStateFromPosition();

  const region = regionFromLocation ?? regionFromPosition ?? regionFromProfile;

  return region;
}
