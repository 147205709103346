import { takeEvery, call, put } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getLeadsUrl } from '../core/dapi/leads';
import {
  bigTexBookingSubmitting,
  bigTexBookingReceived,
  bigTexBookingError,
} from '../ducks/bigTexBooking';
import { leadTypes } from '../constants/leadFormTypes';

const SUBMIT_BIGTEX_BOOKING_TO_SLACK = 'saga/SUBMIT_BIGTEX_BOOKING_TO_SLACK';

function* submitBigTexBookingToSlack({ form }: any) {
  try {
    yield put(bigTexBookingSubmitting(true));
    const leadsUrl = getLeadsUrl();

    const formWithLeadType = { ...form, lead_type: leadTypes.BIG_TEX };
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const bigTexBookingResponse = yield call(apiPost, leadsUrl, formWithLeadType);
    yield put(bigTexBookingReceived(bigTexBookingResponse));
  } catch (e) {
    yield put(bigTexBookingError(e.message));
  } finally {
    yield put(bigTexBookingSubmitting(false));
  }
}

function* rootSaga() {
  yield takeEvery(SUBMIT_BIGTEX_BOOKING_TO_SLACK, submitBigTexBookingToSlack);
}

export { SUBMIT_BIGTEX_BOOKING_TO_SLACK, rootSaga as default };
