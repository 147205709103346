import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isCobrandedSrpLocation } from '~/components/CoBrandedSRP/util';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  ACTION_BLUR,
  ACTION_CHANGE_VALUE,
  ACTION_CLEAR_VALUE,
  ACTION_FOCUS,
  UNIVERSAL_AUTOSUGGEST_INPUT,
} from '~/core/analytics/events';
import { apiGetJson } from '~/core/dapi';
import { getSymptomsIsEmergencyUrl } from '~/core/dapi/symptom';
import { isMobile } from '~/core/util/device';
import { isEmptyString } from '~/core/util/string';
import {
  FETCH_UNIVERSAL_SEARCH_SUGGESTIONS,
  REQUEST_UNIVERSAL_SEARCH,
  TRACK_UNIVERSAL_SEARCH_IMPRESSION,
} from '~/sagas/universalSearch';
import logger from '../../../core/logger/index';
import Input, { Clear } from './Base';
import { generateDefaultSuggestions } from './defaults';
import dataTestIds from '~/test/test-ids';

export const UNIVERSAL_SEARCH_PLACEHOLDER = 'Symptom, service, or provider';

const Root = styled.div`
  position: relative;
`;

type UniversalProps = {
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  fetchSuggestions?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setSearchEnabled?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  onlySelectDoc?: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  fromSrp?: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  updateSuggestions?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setSelectedDocument?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  isInMarketPlaceSearch?: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  onSelect?: func;
  initialValue?: string;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  clearOnBlur?: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  refSetter?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  onBlur?: func;
  className?: string;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  toggleEmergencyModal?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  trackImpression?: func;
};

type UniversalState = any;

class Universal extends PureComponent<UniversalProps, UniversalState> {
  defaultSuggestions: any;

  inputRef: any;

  constructor(props: UniversalProps) {
    super(props);
    this.state = { clearEnabled: !!props.initialValue };
    this.defaultSuggestions = generateDefaultSuggestions(this.handleDocSelect);
  }

  componentDidMount() {
    const { clearOnBlur, updateSuggestions, initialValue } = this.props;

    if (initialValue && this.inputRef) this.inputRef.value = initialValue;
    if (!clearOnBlur) updateSuggestions(this.getDefaultSuggestions());
  }

  componentDidUpdate(prevProps: UniversalProps) {
    const { initialValue: previousInitialValue = '' } = prevProps;
    const { initialValue = '' } = this.props;

    if (initialValue !== previousInitialValue && initialValue != null && this.inputRef) {
      this.inputRef.value = initialValue;
    }
  }

  getDefaultSuggestions = () => {
    if (this.inputRef && this.inputRef.value) {
      this.fetchSuggestions(this.inputRef.value);
      return [];
    }

    return this.defaultSuggestions;
  };

  handleDocSelect = (selectedDoc: any) => {
    const { clearOnBlur, updateSuggestions, setSelectedDocument, onSelect } = this.props;
    this.inputRef.value = selectedDoc.highlight.replace(/<em>|<\/em>|"/g, '');
    this.inputRef.blur();
    clearOnBlur && updateSuggestions([]);
    this.setState({ clearEnabled: true });
    setSelectedDocument(selectedDoc);
    onSelect && onSelect(selectedDoc);
  };

  fetchSuggestions = (query: any) => {
    const { updateSuggestions, fetchSuggestions, isInMarketPlaceSearch } = this.props;

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    if (!query) updateSuggestions(this.getDefaultSuggestions(isInMarketPlaceSearch));

    fetchSuggestions(query, this.handleDocSelect, updateSuggestions);
  };

  handleChange = ({ target: { value } }: any) => {
    analyticsTrackEvent(UNIVERSAL_AUTOSUGGEST_INPUT, {
      action: ACTION_CHANGE_VALUE,
      value,
      isMobile: isMobile(),
    });
    const { setSearchEnabled } = this.props;
    this.fetchSuggestions(value);
    setSearchEnabled && setSearchEnabled(!!value);
    this.state.clearEnabled !== !!value && this.setState({ clearEnabled: !!value });
  };

  handleClearClick = () => {
    analyticsTrackEvent(UNIVERSAL_AUTOSUGGEST_INPUT, {
      action: ACTION_CLEAR_VALUE,
      isMobile: isMobile(),
    });
    const { updateSuggestions, setSearchEnabled, isInMarketPlaceSearch } = this.props;
    this.inputRef.value = '';
    this.inputRef.focus();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    updateSuggestions(this.getDefaultSuggestions(isInMarketPlaceSearch));
    setSearchEnabled(false);
    this.setState({ clearEnabled: false });
  };

  handleBlur = () => {
    analyticsTrackEvent(UNIVERSAL_AUTOSUGGEST_INPUT, {
      action: ACTION_BLUR,
      isMobile: isMobile(),
    });
    const { onBlur } = this.props;
    onBlur && onBlur();
    this.props.trackImpression(this.inputRef.value);
    this.conditionallyShowEmergencyToolTip(this.inputRef.value);
  };

  handleFocus = () => {
    analyticsTrackEvent(UNIVERSAL_AUTOSUGGEST_INPUT, {
      action: ACTION_FOCUS,
      isMobile: isMobile(),
    });
    const { clearOnBlur, updateSuggestions, isInMarketPlaceSearch } = this.props;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    if (clearOnBlur) updateSuggestions(this.getDefaultSuggestions(isInMarketPlaceSearch));
  };

  conditionallyShowEmergencyToolTip = (newSymptom: any) => {
    if (isEmptyString(newSymptom)) {
      return;
    }
    let withoutComma = newSymptom;
    if (withoutComma.includes(',')) {
      withoutComma = newSymptom.split(',').join(' ');
    }
    if (!this.state.currentSymptomObject) {
      apiGetJson(getSymptomsIsEmergencyUrl(withoutComma))
        .then(
          (response: any) => response.data.is_emergency && this.props.toggleEmergencyModal(true)
        )
        .catch((err: any) => logger.debug(err));
    } else if (this.state.currentSymptomObject.isEmergency) {
      this.props.toggleEmergencyModal(true);
    }
  };

  render() {
    const { className, isInMarketPlaceSearch, refSetter } = this.props;
    const { clearEnabled } = this.state;

    return (
      <Root className={className}>
        <Input
          $url="/images/icons/search-icons/Icon-Search.svg"
          data-testid={dataTestIds.universalSearch.searchInput}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          placeholder={UNIVERSAL_SEARCH_PLACEHOLDER}
          ref={(el) => {
            this.inputRef = el;
            refSetter && refSetter(el);
          }}
          sc={{ shortEllipsis: clearEnabled }}
        />

        <Clear
          $shown={clearEnabled}
          alt="clear"
          onClick={this.handleClearClick}
          src="/images/icons/search-icons/Icon-Search-Clear.svg"
        />
      </Root>
    );
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  fetchSuggestions: (query: any, callback: any, resultsCallback: any) =>
    dispatch({
      type: FETCH_UNIVERSAL_SEARCH_SUGGESTIONS,
      query,
      callback,
      resultsCallback,
    }),
  setSelectedDocument: (doc: any) => {
    const { onlySelectDoc, fromSrp } = ownProps;
    dispatch({
      type: REQUEST_UNIVERSAL_SEARCH,
      fullDoc: doc,
      onlySelectDoc,
      fromSrp,
    });
  },
  trackImpression: (label: any) => dispatch({ type: TRACK_UNIVERSAL_SEARCH_IMPRESSION, label }),
});

const mapStateToProps = (state: any) => {
  let initialValue = state.universalSearch.displayQuery;

  if (isCobrandedSrpLocation() && !initialValue) {
    initialValue = 'COVID test';
  }

  return {
    isInMarketPlaceSearch: state.searchPreferences.isInMarketPlaceSearch,
    initialValue,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Universal);
