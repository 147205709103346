import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { apiGet } from '../core/dapi';
import { getNextAvailableLocationsUrl } from '../core/dapi/location';
import { PROVIDER_TYPES } from '../components/Account/Profile/FindCare/util';
import { locationResponseFormatter } from '../reducers/formatters/location';
import { getSearchUrl } from '../core/dapi/search';
import { PATIENT_TYPE_ADULTS } from '../constants/index';

const FETCH_NEXT_AVAILABLE = 'saga/FETCH_NEXT_AVAILABLE';
const NEXT_AVAILABLE_SET = 'saga/NEXT_AVAILABLE_RECEIVED';
const NEXT_AVAILABLE_URGENT_CARE_SET = 'saga/NEXT_AVAILABLE_URGENT_CARE_RECEIVED';

function* fetchNextAvailableUrgentCare({ latLong }: any) {
  try {
    const { results } = yield call(
      apiGet,
      getSearchUrl({
        searchObj: latLong,
        patientType: { value: PATIENT_TYPE_ADULTS },
        limit: 1,
        page: 1,
      })
    );

    if (results && results.length)
      yield put({
        type: NEXT_AVAILABLE_URGENT_CARE_SET,
        payload: { value: locationResponseFormatter(results[0]) },
      });
  } catch (e) {
    console.error(e);
  }
}

function* fetchNextAvailable({ latLong, fetchUrgentCare }: any) {
  try {
    if (fetchUrgentCare) yield fork(fetchNextAvailableUrgentCare, { latLong });
    const nextAvailable = yield call(
      apiGet,
      getNextAvailableLocationsUrl(
        latLong,
        PROVIDER_TYPES.map((p) => p.providerType)
      )
    );
    for (let [providerType, location] of Object.entries(nextAvailable)) {
      nextAvailable[providerType] = locationResponseFormatter(location);
    }

    yield put({ type: NEXT_AVAILABLE_SET, payload: { value: nextAvailable } });
  } catch (e) {
    console.error(e);
  }
}

function* rootSaga() {
  yield takeLatest(FETCH_NEXT_AVAILABLE, fetchNextAvailable);
}

export {
  NEXT_AVAILABLE_URGENT_CARE_SET,
  FETCH_NEXT_AVAILABLE,
  NEXT_AVAILABLE_SET,
  rootSaga as default,
};
