import fetch from 'isomorphic-fetch';
import { DapiResponse, ResponseType } from '@solvhealth/types/interfaces/dapi';
import { DAPI_AUTH_ENABLED } from '../../config/index';
import { authHeaders } from '../auth';
import logger from '../logger/index';
import { ajaxPending, ajaxSuccess, ajaxError } from '../../actions/runtime';
import { isEmptyObject } from '../util/object';
import { isEmpty } from '../util/empty';
import { DAPI_HOST, DAPI_HOST_NO_CACHE } from '../../config/index';

function promiseWithTimeout(timeoutAfter: any, promise: any, onSuccess: any, onError: any) {
  let timeout: any = null;
  let brokenPromise = false;

  const reject = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    brokenPromise = true;
    onError('timeout');
  };

  timeout = setTimeout(reject, timeoutAfter);

  const resolve = (json: any) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (!brokenPromise) {
      onSuccess(json);
      return;
    }

    onError('timeout');
  };

  return new Promise(() => promise.then(resolve, reject));
}

function getOptions({ camelCase = false }: { camelCase?: boolean } = {}): {
  headers?: HeadersInit;
} {
  let options = {};
  if (DAPI_AUTH_ENABLED) {
    options = {
      headers: {
        ...authHeaders(),
        ...(camelCase && { 'X-JSON-FORMAT': 'json_camelcase' }),
      },
    };
  }

  return options;
}

function postOptions(postData: any, addAuthHeader = true) {
  let options: RequestInit = { headers: {} as HeadersInit };
  if (DAPI_AUTH_ENABLED && addAuthHeader) {
    options = { headers: authHeaders() };
  }

  if (typeof FormData !== 'undefined' && postData instanceof FormData) {
    options.method = 'POST';
    options.body = postData;
  } else if (typeof postData === 'object') {
    options.method = 'POST';
    // @ts-expect-error ts-migrate(7053) FIXME: Property 'Content-Type' does not exist on type '{}... Remove this comment to see the full error message
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(postData);
  }

  return options;
}

function deleteOptions(addAuthHeader = true) {
  let options: RequestInit = { headers: {} };
  if (DAPI_AUTH_ENABLED && addAuthHeader) {
    options = {
      headers: authHeaders(),
      method: 'DELETE',

      // Why sending a payload for DELETE? See https://github.com/timothycrosley/hug/issues/330
      body: JSON.stringify({ delete: true }),
    };
  }

  return options;
}

function apiGetBlob<ET, RT extends ResponseType = ResponseType.Entity>(url: string) {
  return fetch(url).then((res: Response) => res.blob());
}

function apiGetJson<ET, RT extends ResponseType = ResponseType.Entity>(
  url: any,
  options: { camelCase?: boolean } = {}
): Promise<DapiResponse<ET, RT>> {
  const fetchOptions = getOptions({ camelCase: options.camelCase });
  return fetch(url, fetchOptions).then((res: Response) => res.json());
}

function apiPostJson<ET, RT extends ResponseType = ResponseType.Entity>(
  url: string,
  postData?: Record<string, unknown>,
  addAuthHeader: boolean = true
): Promise<DapiResponse<ET, RT>> {
  const options = postOptions(postData, addAuthHeader);
  return fetch(url, options).then((res: Response) => res.json());
}

function apiDeleteJson(url: any) {
  const options = deleteOptions();
  return fetch(url, options).then((res: Response) => res.json());
}

function post(url: any, postData: any, addAuthHeader = true) {
  const options = postOptions(postData, addAuthHeader);
  return fetch(url, options);
}

const handleResponse = (json: any, options = {}) => {
  if (!json) {
    throw new Error('No json at all!');
  }

  if (json.errors) {
    throw new Error(JSON.stringify(json.errors));
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'fullResponse' does not exist on type '{}... Remove this comment to see the full error message
  if (options.fullResponse) {
    return json;
  }

  if (!json.data) {
    throw new Error('No data');
  }

  return json.data;
};

function onValidJsonResponse(
  json: any,
  dispatch: any,
  onSuccess: any,
  onError: any,
  isDapiData = true
) {
  if (isDapiData) {
    if (json && json.errors && json.errors.length) {
      return dispatch(onError(json.errors[0].description, json.errors[0].code));
    }

    if (json && !json.data) {
      return dispatch(onError('Invalid response: no data.'));
    }

    return dispatch(onSuccess(json.data));
  }

  if (json.status && json.status !== 'OK') {
    return dispatch(onError());
  }

  return dispatch(onSuccess(json));
}

function onValidResponse(response: any, dispatch: any, onSuccess: any, onError: any) {
  if (response && !response.ok) {
    return dispatch(onError(response));
  }

  return dispatch(onSuccess(response));
}

function onException(e: any, dispatch: any, onError: any) {
  logger.error(e);
  // this should not be swallowing the whole error except the message. We want the rest of the error.
  return dispatch(onError(e.message));
}

const onValidResponseFull = (json: any, dispatch: any, onSuccess: any, onError: any) => {
  if (json && json.errors && json.errors.length) {
    // this should not be swallowing the errors except the description of the first error. We want the rest of the errors.
    return dispatch(onError(json.errors[0].description, json.errors[0].code));
  }

  if (!json) {
    return dispatch(onError('Invalid response.'));
  }

  return dispatch(onSuccess(json));
};

function getJsonDispatchable(url: any, onSuccess: any, onError: any) {
  return (dispatch: any) =>
    apiGetJson(url)
      .then((json: any) => {
        const isDapiData = false;
        onValidJsonResponse(json, dispatch, onSuccess, onError, isDapiData);
      })
      .catch((e: any) => onException(e, dispatch, onError));
}

function postDispatchable(
  url: any,
  postData: any,
  onSuccess: any,
  onError: any,
  addAuthHeader = true
) {
  return (dispatch: any) =>
    post(url, postData, addAuthHeader)
      .then((response: any) => onValidResponse(response, dispatch, onSuccess, onError))
      .catch((e: any) => onException(e, dispatch, onError));
}

async function apiGetJsonBlocking(url: any) {
  const options = getOptions();
  const response = await fetch(url, options);
  return await response.json();
}

async function apiPostJsonBlocking(url: any, postData: any) {
  const options = postOptions(postData);
  const response = await fetch(url, options);
  return await response.json();
}

function apiGetDispatchable(url: any, onSuccess: any, onError: any) {
  return (dispatch: any) =>
    apiGetJson(url)
      .then((json: any) => onValidJsonResponse(json, dispatch, onSuccess, onError))
      .catch((e: any) => onException(e, dispatch, onError));
}

const apiGetDispatchableFullResponse =
  (url: any, onSuccess: any, onError: any) => (dispatch: any) =>
    apiGetJson(url)
      .then((json: any) => onValidResponseFull(json, dispatch, onSuccess, onError))
      .catch((e: any) => onException(e, dispatch, onError));

function apiPostDispatchable(url: any, postData: any, onSuccess: any, onError: any) {
  return (dispatch: any) =>
    apiPostJson(url, postData)
      .then((json: any) => onValidJsonResponse(json, dispatch, onSuccess, onError))
      .catch((e: any) => onException(e, dispatch, onError));
}

function apiGetDispatchableBlocking(url: any, onSuccess: any, onError: any) {
  return (dispatch: any) =>
    apiGetJsonBlocking(url)
      .then((json) => onValidJsonResponse(json, dispatch, onSuccess, onError))
      .catch((e) => onException(e, dispatch, onError));
}

function apiPostDispatchableBlocking(url: any, postData: any, onSuccess: any, onError: any) {
  return (dispatch: any) =>
    apiPostJsonBlocking(url, postData)
      .then((json) => onValidJsonResponse(json, dispatch, onSuccess, onError))
      .catch((e) => onException(e, dispatch, onError));
}

const patchOptions = (patchData: any) => {
  let options: RequestInit = { headers: {} };
  if (DAPI_AUTH_ENABLED) {
    options = { headers: authHeaders() };
  }

  if (patchData instanceof FormData) {
    options.method = 'PATCH';
    options.body = patchData;
  } else {
    options.method = 'PATCH';
    // @ts-expect-error ts-migrate(7053) FIXME: Property 'Content-Type' does not exist on type '{}... Remove this comment to see the full error message
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(patchData);
  }

  return options;
};

const apiPatchJson = <T, U>(url: string, patchData: T): Promise<U> => {
  const options = patchOptions(patchData);
  return fetch(url, options).then((req: any) => req.json());
};

const apiPatchDispatchableWithoutSource =
  (url: string, patchData: object, onSuccess: any, onError: any) => (dispatch: any) =>
    apiPatchJson(url, patchData)
      .then((json: any) => onValidJsonResponse(json, dispatch, onSuccess, onError))
      .catch((e: any) => onException(e, dispatch, onError));

const apiDeleteDispatchableWithoutSource =
  (url: any, onSuccess: any, onError: any) => (dispatch: any) =>
    apiDeleteJson(url)
      .then((json: any) => onValidJsonResponse(json, dispatch, onSuccess, onError))
      .catch((e: any) => onException(e, dispatch, onError));

const apiGetRuntime = (dispatch: any, requestId: any, url: any, onSuccess: any, onError: any) => {
  const handleSuccess = (response: any) => {
    if (onSuccess) {
      onSuccess(response);
    }

    return ajaxSuccess(requestId)(response);
  };

  const handleError = (error: any) => {
    if (onError) {
      onError(error);
    }

    return ajaxError(requestId)(error);
  };

  dispatch(ajaxPending(requestId));
  apiGetJson(url)
    .then((json: any) => onValidJsonResponse(json, dispatch, handleSuccess, handleError))
    .catch((e: any) => onException(e, dispatch, ajaxError(requestId)));
};

const apiPatchRuntime = (
  dispatch: any,
  requestId: any,
  url: any,
  patchData: any,
  onSuccess: any,
  onError: any
) => {
  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }

    return ajaxSuccess(requestId);
  };

  const handleError = () => {
    if (onError) {
      onError();
    }

    return ajaxError(requestId);
  };

  dispatch(ajaxPending(requestId));
  apiPatchJson(url, patchData)
    .then((json: any) => onValidJsonResponse(json, dispatch, handleSuccess, handleError))
    .catch((e: any) => onException(e, dispatch, ajaxError(requestId)));
};

const apiPostRuntime = (
  dispatch: any,
  requestId: any,
  url: any,
  postData: any,
  onSuccess: any,
  onError: any
) => {
  const handleSuccess = (response: any) => {
    if (onSuccess) {
      onSuccess(response);
    }

    return ajaxSuccess(requestId)(response);
  };

  const handleError = (error: any) => {
    if (onError) {
      onError(error);
    }

    return ajaxError(requestId)(error);
  };

  dispatch(ajaxPending(requestId));
  return apiPostJson(url, postData)
    .then((json: any) => onValidJsonResponse(json, dispatch, handleSuccess, handleError))
    .catch((e: any) => onException(e, dispatch, ajaxError(requestId)));
};

const apiGet = (url: any, options = {}) =>
  apiGetJson(url).then((json: any) => handleResponse(json, options));

const apiPost = (url: any, data: any, options: any) =>
  apiPostJson(url, data).then((json: any) => handleResponse(json, options));

const apiPatch = (url: any, data: any, options: any) =>
  apiPatchJson(url, data).then((json: any) => handleResponse(json, options));

const shouldBypassCache = (context: any) =>
  !isEmptyObject(context.query) &&
  !isEmpty(context.query.bypassCache) &&
  Boolean(context.query.bypassCache) === true;

const getDapiHost = (shouldUseCache = true) => (shouldUseCache ? DAPI_HOST : DAPI_HOST_NO_CACHE);

export {
  post,
  onValidJsonResponse,
  onException,
  getDapiHost,
  postOptions,
  postDispatchable,
  getJsonDispatchable,
  apiGetJson,
  apiGetBlob,
  apiGetJsonBlocking,
  apiGetDispatchable,
  apiGetDispatchableFullResponse,
  apiGetDispatchableBlocking,
  apiPostJson,
  apiPostJsonBlocking,
  apiPostDispatchable,
  apiPostDispatchableBlocking,
  apiPatchJson,
  apiPatchDispatchableWithoutSource,
  promiseWithTimeout,
  apiDeleteJson,
  apiDeleteDispatchableWithoutSource,
  apiGetRuntime,
  apiPatchRuntime,
  apiPostRuntime,
  apiGet,
  apiPost,
  apiPatch,
  shouldBypassCache,
};
