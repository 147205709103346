import { useEffect, useState } from 'react';
import { getState } from '~/components/BookingFlow/util';
import useUserPosition, { PositionTrigger } from '~/hooks/geo/useUserPosition';
import { useSolvSelector } from '~/reducers';

const useStateFromPosition = () => {
  useUserPosition(PositionTrigger.IPOnly);
  const position = useSolvSelector((state) => state.position);
  const [state, setState] = useState<string | null>(getState(position));

  useEffect(() => {
    const stateCode = getState(position);
    setState(stateCode ?? null);
  }, [position]);

  return state;
};

export default useStateFromPosition;
