import React, { PureComponent } from 'react';

type OwnProps = {
  width?: number;
  className?: string;
};

type Props = OwnProps & typeof SolvLogo.defaultProps;

class SolvLogo extends PureComponent<Props> {
  static defaultProps = {
    width: 120,
  };

  render = () => (
    <img
      alt="Solv"
      className={this.props.className}
      src="/images/logo/solv_dark.svg"
      width={this.props.width}
    />
  );
}

export default SolvLogo;
