import fetch from 'isomorphic-fetch';
import { stripCountry } from '~/components/GeoAutoComplete/util';
import { getCityLabelFromGeosuggestValue } from '../../components/Search/components/Filters/Fields/LocationInput/util';
import { DAPI_HOST, GOOGLE_MAPS_CLIENT_API_KEY } from '../../config';
import { isEmptyObject } from '../../core/util/object';
import ProxyTypes from '../proxy/ProxyTypes';
import { safeGet } from '../util/object';
import { apiGetJson } from './index';
import { roundLatOrLng } from '~/core/util/string';

export const FALLBACK_LOCATION: GeoResponse = {
  city: 'Current location',
  label: 'Current location',
};

export const getGeolocateUrl = () =>
  `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAPS_CLIENT_API_KEY}`;

export const getGeoCodeUrl = (address: any) =>
  `/proxy/${ProxyTypes.GeoCode}?address=${encodeURIComponent(address)}`;

export const formatLatitudeLongitude = (latitudeLongitude: any) =>
  `${latitudeLongitude.lat},${latitudeLongitude.lng}`;

export const getReverseGeoCodeUrl = (latitudeLongitude: any) =>
  `/proxy/${ProxyTypes.GeoCode}?latlng=${formatLatitudeLongitude(latitudeLongitude)}`;

export const getGeoDataFromZip = async (zipCode: any) => {
  const response = await fetch(`/proxy/${ProxyTypes.ZipCode}?q=${zipCode}`).then((r: any) =>
    r.json()
  );

  const { latitude, longitude, city, state } = safeGet(response, {})('records.0.fields');
  return { latitude, longitude, city, state };
};

export const parseLocationFromGeoCodeResult = (data: any) => {
  if (!isEmptyObject(data) && data.status === 'OK') {
    if (data.results && data.results.length > 0) {
      const latitudeLongitude = data.results[0].geometry.location;
      return {
        latitude: latitudeLongitude.lat,
        longitude: latitudeLongitude.lng,

        label: safeGet(data, '')('results.0.formatted_address').replace(', USA', ''),
      };
    }
  }

  return null;
};

/**
 * @deprecated use `reverseGeocode` or `getLocationFromIp`
 *
 * @returns str
 */
export const parseLocationFromReverseGeoCodeResult = (result: any) => {
  let city = null;
  let state = null;
  result.address_components.forEach((component: any) => {
    if (component.types.indexOf('locality') !== -1) {
      city = component.long_name;
    }

    if (component.types.indexOf('administrative_area_level_1') !== -1) {
      state = component.short_name;
    }
  });

  const location = {
    latitude: result.geometry.location.lat,
    longitude: result.geometry.location.lng,
    cityLabel: getCityLabelFromGeosuggestValue(result),
  };

  if (city && state) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type '{ latitud... Remove this comment to see the full error message
    location.label = `${city}, ${state}`;
  }

  return location;
};

/**
 * @deprecated use `reverseGeocode` or `getLocationFromIp`
 */
export const geoCode = (locationString: any, onSuccess: any, onError: any) => {
  apiGetJson(getGeoCodeUrl(locationString))
    .then((result: any) => {
      const location = parseLocationFromGeoCodeResult(result);
      if (location) {
        onSuccess(location);
      } else {
        onError(result);
      }
    })
    .catch((e: any) => onError(e));
};

export async function googleGeocode(address: string): Promise<GeoResponse | null> {
  try {
    const results: google.maps.GeocoderResult[] = await fetch(
      `${getGeoCodeUrl(address)}&components=country:us&types=(cities)`
    )
      .then((res) => res.json())
      .then((res) => res.results);

    if (results.length === 0) {
      return null;
    }

    const [result] = results;

    const locality = result.address_components.find((addr) => addr.types.includes('locality'));
    const area = result.address_components.find((addr) => addr.types.includes('colloquial_area'));
    const country = result.address_components.find((addr) => addr.types.includes('country'));
    const zip = result.address_components.find((addr) => addr.types.includes('postal_code'));
    const state = result.address_components.find((addr) =>
      addr.types.includes('administrative_area_level_1')
    );

    const getLabel = () => {
      if (locality?.long_name && state?.short_name) {
        return `${locality?.long_name}, ${state?.short_name}`;
      }
      if (area?.long_name && state?.short_name) {
        return `${area?.long_name}, ${state?.short_name}`;
      }

      return stripCountry(result.formatted_address);
    };

    return {
      city: locality?.long_name ?? area?.long_name,
      country: country?.long_name,
      country_code: country?.short_name,
      state: state?.long_name,
      state_code: state?.short_name,
      label: getLabel(),
      zip: zip?.short_name,
      latitude: result.geometry.location.lat as unknown as number,
      longitude: result.geometry.location.lng as unknown as number,
    };
  } catch (e) {
    return null;
  }
}

/**
 * Perform a google geocode
 *
 * @returns GeoResponse
 */
async function googleFallbackReverseGeocode(
  lat: string | number,
  lng: string | number
): Promise<GeoResponse | null> {
  try {
    const reverseGeocodeResults: { results: google.maps.GeocoderResult[] } = await fetch(
      getReverseGeoCodeUrl({ lat, lng })
    ).then((res) => res.json());

    const {
      results: [result],
    } = reverseGeocodeResults;

    const locality = result.address_components.find((addr) => addr.types.includes('locality'));
    const country = result.address_components.find((addr) => addr.types.includes('country'));
    const zip = result.address_components.find((addr) => addr.types.includes('postal_code'));
    const state = result.address_components.find((addr) =>
      addr.types.includes('administrative_area_level_1')
    );

    return {
      city: locality?.long_name,
      country: country?.long_name,
      country_code: country?.short_name,
      state: state?.long_name,
      state_code: state?.short_name,
      label: `${locality?.long_name}, ${state?.short_name}`,
      zip: zip?.short_name,
      latitude: result.geometry.location.lat as unknown as number, // the exported type is wrong
      longitude: result.geometry.location.lng as unknown as number,
    };
  } catch (e) {
    return FALLBACK_LOCATION;
  }
}

/**
 * Gets geo-information from a lat/lng pair
 *
 * @returns a GeoResponse promise
 */
export async function reverseGeocode(
  lat: number | string,
  lng: number | string,
  { fallbackToGoogle = true } = {}
): Promise<GeoResponse | null> {
  try {
    const response = await apiGetJson<GeoResponse>(
      `${DAPI_HOST}/v1/geo/reverse-geocode?lat=${roundLatOrLng(lat)}&lng=${roundLatOrLng(lng)}`
    );

    if (response.errors?.length || !response.data || !response.data.city) {
      throw new Error();
    }
    return response.data;
  } catch (e) {
    if (!fallbackToGoogle) {
      return FALLBACK_LOCATION;
    }
    return await googleFallbackReverseGeocode(lat, lng);
  }
}

/**
 * Perform a google GeoLocate
 *
 * @returns GeoResponse
 */
async function googleFallbackIpLocation(): Promise<GeoResponse | null> {
  try {
    const googleResponse = await fetch(getGeolocateUrl(), {
      method: 'POST',
      body: JSON.stringify({ considerIp: true }),
    }).then((res) => res.json());

    const reverseGeocodedResponse = await reverseGeocode(
      googleResponse.location.lat.toString(),
      googleResponse.location.lng.toString(),
      { fallbackToGoogle: true }
    );

    return reverseGeocodedResponse;
  } catch (e) {
    return null;
  }
}

/**
 * Gets the users geo-information from their IP Address
 *
 * @returns a GeoResponse promise
 */
export async function getLocationFromIp({
  override,
  fallbackToGoogle = true,
}: { override?: string; fallbackToGoogle?: boolean } = {}): Promise<GeoResponse | null> {
  try {
    const url = override
      ? `${DAPI_HOST}/v1/geo/ip-lookup/for?ip=${override}`
      : `${DAPI_HOST}/v1/geo/ip-lookup`;

    const response = await apiGetJson<GeoResponse>(url);

    if (response?.errors?.length || !response.data || !response.data.city) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    if (!fallbackToGoogle) {
      return null;
    }

    // This is where we fallback to google geolocation
    return await googleFallbackIpLocation();
  }
}
