export const colors = {
  // 31 shades of grey
  fadeToBlack: '#313131',
  darkText: '#3f5062',
  whereNoGreyHasGoneBefore: '#677280',
  mrGray: '#666',
  mildGrey: '#8C96A0',
  lightText: '#84909e',
  lanaDelGrey: '#979797',
  iThoughtWeHadAllTheGreys: '#9BA9B4',
  neverTooManyGreys: '#41505f',
  greyLock: '#9da6b1',
  theOtherGray: '#9da9b5',
  theGreyAlbum: '#b3b3b3',
  greyWorm: '#c4c4c4',
  grayvy: '#d0dae1',
  dorianGrey: '#dcdcdc',
  borderLine: '#e6e9eb',
  greyHounds: '#e7e7e7',
  justAnotherGray: '#e8e8e8',
  granite: '#e8e9ea',
  grayArea: '#eaeae8',
  emptyBarGrey: '#ebecee',
  ivory: '#edeff2',
  karl: '#edf4f9',
  diabolicalGrey: '#eef2f5',
  fatherJohnMisty: '#f3f3f3',
  fiveSevenNineGrey: '#f5f7f9',
  onTheBorderline: '#cfd3d7',
  howGrayciousOfYou: '#41505f',
  grayMatter: '#6a7685',
  greyHairs: '#f6f7f8',
  grayDim: '#f9f9f9',
  whiteGrey: '#fcfcfc',
  pearl: '#fefefc',
  theWhiteAlbum: '#fff',
  lanaDelGray: '#8C95A1',
  lightGray: '#667685',
  disgraybled: '#c6cacf',
  feather: '#f0f4f9',
  grey600: '#222A33',

  ladyInRed: '#ff234e',
  cherry: '#ce2424',
  cherryPie: '#f0006a',
  cherryBlossom: '#c31f5c',
  crunchBerry: '#e30c79',
  crunchBerryXLight: '#fdecf4',
  crunchBerryLight: '#e5a5c5',
  crunchBerryMedium: '#f50b7c',
  crunchBerryDark: '#cc0d71',
  pinkElephant: '#ffaebe',
  whitePink: '#fff8fa',
  pinkie: '#ffcbe5',
  pinkish: '#f5ecff',

  pumpkinStew: '#ff5252',
  burntOrange: '#f57123',
  peaches: '#f5a623',
  appStoreSwagger: '#f9c454',
  amberYellow: '#ffc76a',
  yellowPr: '#fef6e9',
  slightlyYellow: '#fef9ef',
  yellowCorona: '#fff8ee',
  lemonade: '#f5ff93',
  canary: '#fff8b8',
  goldenrod: '#c58300',
  goldenCorona: '#8a7045',
  brownish: '#7c5003',

  grannySmith: '#45c071',
  greenHope: '#65cc01',
  greenPeas: '#4D9C01',
  grass: '#7bb342',
  greenWeed: '#10D1B2',
  greenLightWeed: '#cdfff7',

  deepSea: '#1a237e',
  blueLoadingScreen: '#516072',
  blueMonday: '#5b6b7d',
  blueish: '#809db2',
  blurp: '#e6f6ff',
  blite: '#d2e9f6',
  blueCloud: '#aec4d4',
  cyanogenBlue: '#dae2e8',
  cyancatBlue: '#aab7c1',
  darkBlue: '#0053b8',
  bahamasBlue: '#00669d',
  tangledUpInBlue: '#0084cc',
  blueJayWay: '#169ae1',
  mrBlueSky: '#40b6f5',
  seaBreeze: '#369ad0',
  shaquilleOteal: '#26a69a',
  aKnightsTeal: '#0097a7',
  comeSailAway: '#10d1b2',
  youGottaRide: '#5ec2ca',
  blueCorona: '#afdff8',
  youGottaRideBack: '#f3fbfc',
  purpleTesting: '#f7edff',
  whiteCorona: '#f8fcff',
  greyCorona: '#F2F2F2',
  lightMrBlueSky: 'rgba(64, 182, 245, 0.2)',
  hellaLightMrBlueSky: 'rgba(64, 182, 245, 0.04)',
  lightBlueMonday: 'rgba(132, 144, 158, 0.8)',
  anotherBlue: '#1D9CE2',
  blueberry: '#0072b0',

  myRightFoot: '#384656',
  squid: '#414f60',
  darkSquid: '#222A33',
  octopus: '#49496e',
  licorice: '#4c587a',

  bloop: '#f0a6ff',
  purpleHaze: '#af5ebf',
  slightlyPurple: '#fef8fc',
  purpleRain: '#682e9a',
  grapeLight: '#FAF5FF',
  grapeMedium: '#8D65B5',
  grapeDark: '#59377B',
  purpleMarble: '#905CB8',
  bruisePurple: '#693673',
  pinkDoor: '#ff80c3',
  purpSizzurp: '#bb68fd',
  purplexed: '#7d30bf',
  purpletrator: '#59377b',
  eggplant: '#9c27b0',
  plum: '#673ab7',
  marriedToPurple: '#faf5ff',

  white: '#fff', // theWhiteAlbum
  black: '#313131', // theBlackAlbum
  link: '#0084cc', // tangledUpInBlue

  /** Insurance brand color section */
  aetnaPurple: '#754098',
  ambetterPink: '#ca237d',
  amerigroupBlue: '#0096d4',
  anthemBlue: '#1c79bf',
  bcbsBlue: '#008ccd',
  cignaGreen: '#52b94a',
  humanaGreen: '#5b991c',
  superiorBlue: '#29c4f4',
  uhcBlue: '#173b87',
  /** (end insurance brand color section) */

  /** design system grays */
  gray100: '#f7f7f7',
  gray200: '#e3e5e6',
  gray300: '#c6cacf',
  gray400: '#8c95a1',
  gray500: '#6a7685',
  gray600: '#222a33',

  /** design system colors */
  peachLight: '#FFF6E5',
  peachDark: '#9C6400',
};
