/* eslint-disable react/jsx-fragments */
// keep this eslint disable for proper functioning of LaunchDarkly LDProvider
/// <reference path="./global.d.ts" />
// import './wdyr'; -- Uncomment when testing component rerenders
import { GenericObj } from '@solvhealth/types/interfaces/generics';
// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/query-string` if it exists... Remove this comment to see the full error message
import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/browser';
import { ErrorWithCause } from 'pony-cause';
import i18n, { loadClientI18next } from '~/locale/i18nClient';
import App from './components/App';
import Main from './components/Main/Main';
import {
  ANALYTICS,
  BASE_URL,
  ENV,
  LOGIN_INFO_COOKIE_NAME,
  NOTIFICATIONS_APPLICATION_SERVER_KEY,
  SEGMENT_ANONYMOUS_ID_COOKIE_NAME,
  SENTRY_DSN,
} from './config/index';
import { analyticsTrackPage } from './core/analytics';
import { updateMeta } from './core/DOMUtils';
import history from './core/history';
import logger from './core/logger';
import registerServiceWorker from './core/util/registerServiceWorker';
import { deleteMixpanelCookie, deleteUrlParamsCookie } from './core/util/tracking';
import ErrorPage from './routes/error/ErrorPage';
import configureStore, { getClientInitialState } from './store/configureStore';
import { initClientSideLaunchDarkly } from '~/core/launch-darkly/client';
import { isClientSide } from './core/util/system';
import { LDProxyProvider } from '~/core/launch-darkly/LDProxyProvider';
import { CONSENT_FORM_COMPLETED } from '~/constants';
import { RedirectFunction, RedirectFunctionArgs, RedirectFunctionReturn } from '~/router';
import { getFlag } from '~/core/launch-darkly/flags';

window.addEventListener('unhandledrejection', (event) => {
  // Prevent error output on the console
  event.preventDefault();
});

const serviceWorkerPath = '/serviceWorker.js';

const { swRegistration, onLocationChangeWrapper } = registerServiceWorker(serviceWorkerPath);

const notificationInfo = {
  applicationServerKey: NOTIFICATIONS_APPLICATION_SERVER_KEY,
  swRegistration,
};

let LDProvider: (({ children }: { children: React.ReactNode }) => JSX.Element) | null = null;
let LDWrapper: (({ children }: { children: React.ReactNode }) => JSX.Element) | React.ComponentType;

if (SENTRY_DSN !== null) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: cancelled',
      'TypeError: The Internet connection appears to be offline.',
      'Invariant Violation: unmountComponentAtNode(...): Target container is not a DOM element.',
      'TypeError: The operation couldn’t be completed. Software caused connection abort',
      'TypeError: The network connection was lost.',
      'TypeError: The request timed out.',
      "TypeError: NetworkError: The operation couldn't be completed. Software caused connection abort",
      "TypeError: undefined is not an object (evaluating 'e.originalEvent.origin')",
      // See https://github.com/getsentry/sentry-javascript/issues/3440; this error is generic and non-actionable,
      // even if it _could_ represent a real problem. We must avoid by ensuring we always throw & reject with
      // Error objects, but we cannot prevent libraries from evoking the bad pattern
      // TODO: For now we're going to mute this in Sentry, but let's revisit at the end of the Sentry reporting
      // improvements to consider ignoring altogether
      // 'Non-Error promise rejection captured',
    ],
    // @ts-expect-error
    release: __COMMIT_HASH__,
  });
}

const shutdownIntercom = () => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
  if (typeof window.Intercom === 'function') {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    window.Intercom('shutdown');
  }
};

window.onbeforeunload = shutdownIntercom;

/* eslint-disable global-require */

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const { store } = configureStore(getClientInitialState());
const context = {
  store,
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'styles' implicitly has an 'any[]' ... Remove this comment to see the full error message
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map((x) => x._insertCss());
    return () => {
      removeCss.forEach((f) => f());
    };
  },

  setTitle: (value: string) => {
    document.title = value;
  },

  setMeta: updateMeta,
};

const container = document.getElementById('app');
let currentLocation = history.location;
let router = require('./router').default;

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory: GenericObj = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const removeCssFromDocument = () => {
  const elm = document.getElementById('css');

  if (elm) {
    elm.parentNode?.removeChild(elm);
  }
};

interface OnRenderComplete {
  (route: any, location: typeof history.location): void;
}

/**
 * Last Touch UTM Tags - helper function for campaignParams
 * copied from Mixpanel: https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
 */
function getQueryParam(url: string, param: string) {
  /* eslint-disable */
  // Expects a raw URL
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  let regexS = `[\?&]${param}=([^&#]*)`;
  let regex = new RegExp(regexS);
  let results = regex.exec(url);
  // @ts-ignore
  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return '';
  }
  return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
}

/**
 * Last Touch UTM Tags - main function
 * copied from Mixpanel: https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
 */
function campaignParams() {
  let campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw = '';
  let params: { [index: string]: string } = {};
  let first_params: { [index: string]: string } = {};
  let index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[`${campaign_keywords[index]} [last touch]`] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[`${campaign_keywords[index]} [first touch]`] = kw;
    }
  }
  window.mixpanel.people.set(params);
  window.mixpanel.people.set_once(first_params);
  window.mixpanel.register(params);
}

const subsequentOnRenderComplete: OnRenderComplete = (route, location) => {
  analyticsTrackPage();

  if (route.title) {
    document.title = route.title;
  }

  if (route.description) {
    updateMeta('description', route.description);
  }

  let scrollX = 0;
  let scrollY = 0;

  const pos = location.key && scrollPositionsHistory[location.key];
  if (pos) {
    scrollX = pos.scrollX;
    scrollY = pos.scrollY;
  } else {
    const targetHash = location.hash.substr(1);
    if (targetHash) {
      const target = document.getElementById(targetHash);
      if (target) {
        scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      }
    }
  }

  // Restore the scroll position if it was saved into the state
  // or scroll to the given #hash anchor
  // or scroll to top of the page
  window.scrollTo(scrollX, scrollY);
};
const initialOnRenderComplete = () => {
  removeCssFromDocument();

  // PERSIST STORE ONLY ONCE THE CLIENT SIDE RENDERING ALREADY HAPPENED
  // if you really want to change it, I hope you know what you are doing
  persistStore(context.store);

  // Mixpanel: Last Touch Attribution
  if (isClientSide() && ANALYTICS.enabled) {
    window.analytics?.ready(() => {
      campaignParams();
    });
  }

  analyticsTrackPage();
  deleteUrlParamsCookie();
  deleteMixpanelCookie();
};

let onRenderComplete: OnRenderComplete = () => {
  initialOnRenderComplete();

  onRenderComplete = subsequentOnRenderComplete;
};
/**
 * Re-render the app when window.location changes.
 *
 * @param location new route
 * @param action route change way
 */
async function onLocationChange(location: typeof history.location, action?: any) {
  // Remember the latest scroll position for the previous location
  if (currentLocation.key) {
    scrollPositionsHistory[currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
    };
  }

  // Delete stored scroll position for next page if any
  if (action === 'PUSH' && location.key) {
    delete scrollPositionsHistory[location.key];
  }

  try {
    await loadClientI18next();
  } catch (e) {
    logger.error(new ErrorWithCause('Could not load i18next', { cause: e }));
  }

  const cookies = new Cookies();
  const segmentAnonymousId = cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME);
  const loginCookie = cookies.get(LOGIN_INFO_COOKIE_NAME);

  try {
    if (!LDProvider) {
      LDProvider = await initClientSideLaunchDarkly({
        accountId: loginCookie?.id,
        segmentAnonymousId,
      });
    }
  } catch (e) {
    logger.error(new ErrorWithCause('Could not load Launch Darkly in client', { cause: e }));
  }

  const previousLocation = currentLocation;
  currentLocation = location;
  const isInitialRender = !action;
  const renderApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({
      ...context,
      pathname: location.pathname,
      query: queryString.parse(location.search),
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (typeof route.redirect === 'function') {
      const redirectFunction = route.redirect as RedirectFunction;
      const fullUrl = BASE_URL + location.pathname + location.search;
      const redirect = await redirectFunction({
        request: new Request(fullUrl),
        isClient: true,
        getStore: store.getState,
        getFlag(flagName) {
          return getFlag(flagName);
        },
        clientContext: {
          lastLocation: previousLocation,
          newLocation: location,
          action,
        },
      });
      if (redirect) {
        switch (redirect.type) {
          case 'external':
            // Ensure that we don't have an extra entry in the history
            if (action === 'PUSH') {
              history.replace(previousLocation.pathname + '?' + previousLocation.search);
            }
            window.location.href = redirect.url;
            break;
          case 'replace':
            history.replace(redirect.url);
            break;
          case 'push':
          default:
            history.push(redirect.url);
        }
        return;
      }
    } else if (typeof route.redirect === 'string') {
      history.replace(route.redirect);
      return;
    }

    LDWrapper = LDProvider ?? React.Fragment;

    renderApp(
      <LDWrapper>
        <LDProxyProvider>
          <I18nextProvider i18n={i18n}>
            {/* @ts-ignore */}
            <Provider store={store}>
              <Main context={context} notificationInfo={notificationInfo} route={route} />
            </Provider>
          </I18nextProvider>
        </LDProxyProvider>
      </LDWrapper>,
      container,
      () => onRenderComplete(route, location)
    );
  } catch (error) {
    // Do a full page reload if error occurs during client-side navigation
    if (action && currentLocation.key === location.key) {
      window.location.reload();
    }

    ReactDOM.hydrate(
      <LDWrapper>
        <LDProxyProvider>
          <I18nextProvider i18n={i18n}>
            {/* @ts-ignore */}
            <Provider store={store}>
              <App context={context}>
                <ErrorPage error={error} />
              </App>
            </Provider>
          </I18nextProvider>
        </LDProxyProvider>
      </LDWrapper>,
      container
    );
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChangeWrapper(onLocationChange));

onLocationChange(currentLocation);

if (module.hot) {
  // The main purpose of this block was to continue bubbling/allowing updates
  // where a module is not accepted. I think it's required due to strange
  // unnaccepted-ness behaviors with our chunks. Additionally, Redux action changes
  // always bubble up to this file and thus trigger the replaceReducer line

  if (module.hot.status() === 'ready') {
    module.hot.apply({
      // Seems to bypass edge cases where one of our HMR libraries' dependency tree
      // breaks after an async chunk update as it allows changes to continue propagate.
      // @ts-ignore
      ignoreUnaccepted: true,
    });

    // Retaining for future debugging
    // // @ts-ignore
    // .then((outdatedModules) => {
    //   console.log('Outdated modules: ', outdatedModules);
    // })
    // .catch((error: any) => {
    //   // catch errors
    //   console.error('HMR error: ', error);
    // });
  }

  module.hot.accept();
  store.replaceReducer(require('./reducers/index.ts').default);
}
