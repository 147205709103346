import { analyticsTrackEvent } from '../../../core/analytics';
import { PWA_ADD_TO_HOME_SCREEN } from '../../App/util/tracking';
import logger from '../../../core/logger/index';
import { isIosSafariOnly, isMobile } from '../../../core/util/device';

export const listenToBeforeInstallPrompt = () => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onbeforeinstallprompt' does not exist on... Remove this comment to see the full error message
  if (!window.onbeforeinstallprompt) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onbeforeinstallprompt' does not exist on... Remove this comment to see the full error message
    window.onbeforeinstallprompt = (e: any) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
      logger.debug('beforeinstallprompt fired', e);
      analyticsTrackEvent(PWA_ADD_TO_HOME_SCREEN, {
        action: 'requirements met',
      });
      // Prevent Chrome 67 and earlier from automatically showing the mini-infobar prompt
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredA2HSPrompt = e;

      // Track user choice wherever it comes from
      e.userChoice
        .then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            analyticsTrackEvent(PWA_ADD_TO_HOME_SCREEN, {
              action: 'add',
              data: choiceResult,
            });
          } else {
            analyticsTrackEvent(PWA_ADD_TO_HOME_SCREEN, {
              action: 'cancel',
              data: choiceResult,
            });
          }
          // clean up the stored event since it's been used already
          window.deferredA2HSPrompt = null;
        })
        .catch((err: any) => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
          logger.debug('error processing beforeinstallprompt', err);
          analyticsTrackEvent(PWA_ADD_TO_HOME_SCREEN, {
            action: 'error adding to home screen',
          });
        });
    };
  }
};

export const listenToAppInstalled = () => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onappinstalled' does not exist on type '... Remove this comment to see the full error message
  if (!window.onappinstalled) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onappinstalled' does not exist on type '... Remove this comment to see the full error message
    window.onappinstalled = (e: any) => {
      analyticsTrackEvent(PWA_ADD_TO_HOME_SCREEN, {
        action: 'installed',
        data: e,
      });
    };
  }
};

export const shouldShowAppInstallPrompt = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  if (!isMobile()) {
    return false;
  }

  if (window.deferredA2HSPrompt) {
    return true;
  }

  listenToBeforeInstallPrompt();
  return false;
};
