import { PROFILE_FORM_SUBMISSION, PROFILE_FORM_SUBMITTED } from '../constants/index';

const profileFormSubmitted = (value: any) => ({
  type: PROFILE_FORM_SUBMITTED,
  payload: { value },
});

const profileFormSubmission = (value: any) => ({
  type: PROFILE_FORM_SUBMISSION,
  payload: { value },
});

export { profileFormSubmitted, profileFormSubmission };
