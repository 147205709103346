import { call, put, takeLatest } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import { getPaymentCustomersUrl } from '../core/dapi/invoices';
import { cardsError, createCardSuccess } from '../ducks/payments/customers';
import history from '../core/history';

const ADD_CREDIT_CARD = 'saga/ADD_CREDIT_CARD';

function* addCreditCard({ stripeTokenId, nextPage, callback }: any) {
  let response;
  try {
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    response = yield call(apiPost, getPaymentCustomersUrl(), {
      stripe_token_id: stripeTokenId,
    });
    yield put(createCardSuccess(response));
    if (callback) {
      callback();
      return;
    }
    if (nextPage) {
      history.push(`/${nextPage}`);
    } else {
      history.go(-1);
    }
  } catch (e) {
    yield put(cardsError(response));
  }
}

function* rootSaga() {
  yield takeLatest(ADD_CREDIT_CARD, addCreditCard);
}

export { ADD_CREDIT_CARD, rootSaga as default };
