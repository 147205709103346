import React, { PureComponent } from 'react';
import { array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FETCH_MARKETPLACE_INSURERS } from '../../../sagas/marketplaceInsurers';
import Input from './Base';
import { symptomsSubmit } from '../../../actions/symptoms';
import { analyticsTrackEvent } from '../../../core/analytics';
import {
  ACTION_BLUR,
  ACTION_CHANGE_VALUE,
  ACTION_CLICK,
  UNIVERSAL_INSURANCE_INPUT,
} from '../../../core/analytics/events';
import { isMobile } from '../../../core/util/device';

const InsuranceInput = styled(Input)`
  -webkit-appearance: none;
  text-transform: capitalize;
  padding: 1px;
  text-align: left;
`;

const tagString = (toTag: any) => `<em>${toTag}</em>`;

type InsuranceCarrierProps = {
  className?: string;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  updateSuggestions?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'array' refers to a value, but is being used as a ... Remove this comment to see the full error message
  marketplaceInsurers?: array;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  fetchMarketplaceInsurers?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setRequestedInsurance?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  refSetter?: func;
  requestedInsurance?: object;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  clearOnBlur?: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  onSelect?: func;
};

class InsuranceCarrier extends PureComponent<InsuranceCarrierProps> {
  componentDidMount() {
    const { fetchMarketplaceInsurers } = this.props;
    fetchMarketplaceInsurers();
    this.handleClick();
  }

  getSuggestions = () =>
    this.props.marketplaceInsurers.map((insurer: any) => ({
      primary:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'object'.
        this.props.requestedInsurance && this.props.requestedInsurance.id === insurer.id
          ? tagString(insurer.display_name)
          : insurer.display_name,

      key: insurer.id,
      onClick: () => this.updateAndClear(insurer),
    }));

  updateAndClear = (insurer: any) => {
    analyticsTrackEvent(UNIVERSAL_INSURANCE_INPUT, {
      action: ACTION_CHANGE_VALUE,
      value: insurer && insurer.display_name,
      isMobile: isMobile(),
    });
    const { setRequestedInsurance, onSelect, updateSuggestions } = this.props;

    setRequestedInsurance(insurer);
    updateSuggestions([]);
    onSelect && onSelect(insurer);
  };

  handleClick = () => {
    analyticsTrackEvent(UNIVERSAL_INSURANCE_INPUT, {
      action: ACTION_CLICK,
      isMobile: isMobile(),
    });
    const { updateSuggestions } = this.props;
    updateSuggestions(this.getSuggestions());
  };

  handleBlur = () => {
    analyticsTrackEvent(UNIVERSAL_INSURANCE_INPUT, {
      action: ACTION_BLUR,
      isMobile: isMobile(),
    });
  };

  render() {
    const { refSetter, className, requestedInsurance } = this.props;

    return (
      <InsuranceInput
        $url="/images/icons/insurance_shield.svg"
        className={className}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        onFocus={this.handleClick}
        ref={refSetter}
        sc={{
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'display_name' does not exist on type 'ob... Remove this comment to see the full error message
          isPlaceholder: !(requestedInsurance && requestedInsurance.display_name),
        }}
        type="button"
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'display_name' does not exist on type 'ob... Remove this comment to see the full error message
        value={(requestedInsurance && requestedInsurance.display_name) || 'Select insurance'}
      />
    );
  }
}

export default connect(
  (state: any) => ({
    requestedInsurance: state.searchPreferences.requestedInsurance,
    marketplaceInsurers: state.marketplaceInsurers.insurers || [],
  }),
  (dispatch: any) => ({
    setRequestedInsurance: (requestedInsurance: any) =>
      dispatch(symptomsSubmit({ requestedInsurance })),
    fetchMarketplaceInsurers: () => dispatch({ type: FETCH_MARKETPLACE_INSURERS }),
  })
)(InsuranceCarrier);
