import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Text, Button } from '@solvhealth/jigsaw';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  NATIVE_PUSH_MISSING_NOTIFICATION_ALLOW,
  NATIVE_PUSH_MISSING_NOTIFICATION_DENY,
  NATIVE_PUSH_MISSING_NOTIFICATION_OPEN_MODAL,
} from '~/core/analytics/events';
import { showModal } from '~/actions/runtime';
import { NativeFunctions } from '~/core/util/native';

export const MISSING_NOTIFICATION_PROMPT_MODAL = 'missing_notification_prompt_modal';

const SmoothDialog = styled(Dialog)`
  -webkit-font-smoothing: antialiased;
`;

const Root = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 48px;
`;

const ImageWithHeading = styled.div`
  flex-grow: 1;
  max-height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 480px;
  flex-grow: 1;
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 64px;
  flex-grow: 1;
`;

const PhoneImage = styled.img`
  display: table;
  margin: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CenteredText = styled(Text)`
  text-align: center;
`;

const onAllow = (callback: any) => () => {
  NativeFunctions.directToSettings.call();
  analyticsTrackEvent(NATIVE_PUSH_MISSING_NOTIFICATION_ALLOW);
  if (window.handleAcceptIosPushNative) {
    // Try using the function that triggers the native prompt directly
    // If it's not available (older versions of the app),
    // trigger the flow which includes also a custom alert before the native one
    window.handleAcceptIosPushNative();
  } else if (window.handleAcceptIosPushPermission) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleAcceptIosPushPermission' does not ... Remove this comment to see the full error message
    window.handleAcceptIosPushPermission();
  }
  callback();
};

const onDeny = (callback: any) => () => {
  analyticsTrackEvent(NATIVE_PUSH_MISSING_NOTIFICATION_DENY);
  callback();
};

type MissingNotificationPromptProps = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

export const MissingNotificationPrompt = withMobileDialog<MissingNotificationPromptProps>()(
  ({ onClose, open }: MissingNotificationPromptProps) => {
    useEffect(() => {
      if (open) {
        analyticsTrackEvent(NATIVE_PUSH_MISSING_NOTIFICATION_OPEN_MODAL);
      }
    }, [open]);
    const [hasSeen, setHasSeen] = useState(false);

    const handleClose = () => {
      setHasSeen(true);
      onClose();
    };

    if (hasSeen) {
      return null;
    }
    return (
      <SmoothDialog
        fullScreen
        fullWidth
        onClose={handleClose}
        open={open}
        PaperProps={{ classes: { root: 'dialog-root' } }}
      >
        <Root>
          <Top>
            <ImageWithHeading>
              <PhoneImage src="/images/account/phone_push_notification_demo.svg" />
              <CenteredText variant="h2">Don't miss important health reminders</CenteredText>
            </ImageWithHeading>

            <div>
              <CenteredText>
                Looks like you haven't enabled notifications from Solv. You can enable notifications
                in your settings.
              </CenteredText>
            </div>
          </Top>

          <Steps>
            <CenteredText variant="button">1. Tap "Notifications"</CenteredText>
            <CenteredText variant="button">2. Turn on "Allow Notifications"</CenteredText>
          </Steps>

          <ButtonContainer>
            <Button fullWidth onClick={onAllow(handleClose)} sx={{ mb: 2 }} variant="contained">
              Open settings
            </Button>

            <Button fullWidth onClick={onDeny(handleClose)} variant="text">
              Skip for now
            </Button>
          </ButtonContainer>
        </Root>
      </SmoothDialog>
    );
  }
);

export const showMissingNotificationPrompt = () => showModal(MISSING_NOTIFICATION_PROMPT_MODAL);
