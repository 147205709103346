export default {
  path: '/book',

  children: [
    {
      path: '/direct/:locationId',
      load: () => import(/* webpackChunkName: 'bookingFlow' */ './bookingFlowRoute'),
      exportName: 'directBooking',
    },
    {
      path: '/bf/:page(.*)',
      load: () => import(/* webpackChunkName: 'bookingFlow' */ './bookingFlowRoute'),
      exportName: 'router',
    },
  ],
};
