import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../../constants/colors';
import { FadeIn } from '../../../core/util/commonCssAnimations';
import { PinkPrimaryButton } from './BaseButton';

const borderColor = {
  initial: colors.crunchBerry,
  loading: colors.greyHounds,
  error: colors.crunchBerry,
  success: colors.grannySmith,
};

const backgroundColor = {
  initial: colors.crunchBerry,
  loading: 'transparent',
  error: colors.crunchBerry,
  success: colors.grannySmith,
};

const borderRadius = {
  initial: '3px',
  loading: '50px',
  error: '3px',
  success: '3px',
};

const width = {
  initial: '100%',
  loading: '50px',
  error: '100%',
  success: '100%',
};

const StyledButton = styled(PinkPrimaryButton)`
  transition: all 500ms ease;
  padding: 0;
  min-width: 53px;
  width: 100%;
  ${(props) => css`
    background-color: ${props.disabled
      ? 'rgba(0, 0, 0, 0.12)'
      : // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        backgroundColor[props.status]};
    border: ${props.disabled ? 0 : '3px solid'};
    border-radius: ${
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      borderRadius[props.status]
    };
    border-color: ${
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      borderColor[props.status]
    };
    color: ${['success', 'loading'].includes(
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type 'ButtonPr... Remove this comment to see the full error message
      props.status
    )
      ? 'transparent'
      : colors.white};
    transition-delay: ${
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type 'ButtonPr... Remove this comment to see the full error message
      props.status === 'loading' ? 0 : '400ms'
    };
    box-shadow: none;
    &:hover {
      background-color: ${props.disabled
        ? 'rgba(0, 0, 0, 0.12)'
        : // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          backgroundColor[props.status]};
    }
  `};
`;

const Root = styled.div`
  transition: width 500ms ease;
  text-align: center;
  position: relative;
  margin: 0 auto;
  ${(props) => css`
    width: ${
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      width[props.status]
    };
    transition-delay: ${
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.status === 'loading' ? 0 : '200ms'
    };
  `};
`;

const Spinner = styled(CircularProgress)`
  position: absolute;
  top: -4px;
  left: -4px;
`;

const CheckMark = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  left: 2px;
  right: 0;
  bottom: 3px;
  height: 10px;
  width: 18px;
  transform: rotate(-45deg);
  background-color: transparent;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 3px;
  border-color: ${colors.white};
  border-radius: 1px;
  animation: ${FadeIn} 300ms linear;
`;

export const BUTTON_STATUS_INITIAL = 'initial';
export const BUTTON_STATUS_LOADING = 'loading';
export const BUTTON_STATUS_ERROR = 'error';
export const BUTTON_STATUS_SUCCESS = 'success';

export const buttonStatuses = [
  BUTTON_STATUS_INITIAL,
  BUTTON_STATUS_LOADING,
  BUTTON_STATUS_ERROR,
  BUTTON_STATUS_SUCCESS,
];

type OwnProps = {
  text?: string;
  status?: any; // TODO: PropTypes.oneOf(buttonStatuses)
};

type State = any;

type Props = OwnProps & typeof TransitionButton.defaultProps;

class TransitionButton extends Component<Props, State> {
  static defaultProps = {
    status: 'initial',
  };

  loadingTimeout: any;

  constructor(props: Props) {
    super(props);
    this.loadingTimeout = null;
  }

  state = {
    loading: false,
  };

  componentDidUpdate = (prevProps: any) => {
    const prevStatus = prevProps.status;
    const { status } = this.props;
    if (prevStatus !== 'loading' && status === 'loading') {
      this.loadingTimeout = setTimeout(() => this.setState({ loading: true }), 500);
    } else if (prevStatus === 'loading' && status !== 'loading') {
      clearTimeout(this.loadingTimeout);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout);
  }

  render() {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: Property 'status' does not exist on type 'Intrinsi... Remove this comment to see the full error message
      <Root status={this.props.status}>
        <StyledButton {...this.props}>{this.props.text || this.props.children}</StyledButton>

        {this.state.loading && <Spinner size={61} style={{ color: colors.squid }} />}

        {!this.state.loading && this.props.status === 'success' && <CheckMark />}
      </Root>
    );
  }
}

export default TransitionButton;
