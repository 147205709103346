import { useLocationGeneralConfigs } from '../../../../../components/Paperwork/hooks/useLocationGeneralConfigs';
import { getProfileFromBooking } from '../../../../../core/util/account';
import { useSolvSelector } from '../../../../../reducers';
import { usePaperworkContextBookingId } from '~/routes/paperwork/PaperworkBookingIdContext';

export const useShouldShowAddressValidation = (locationId: string): boolean => {
  const locationGeneralConfigsData = useLocationGeneralConfigs(locationId, [
    'isAddressValidationEnabled',
  ]);
  const accountSummary = useSolvSelector((state) => state.account.summary);
  const bookingId = usePaperworkContextBookingId();
  const booking = useSolvSelector((state) => state.pastBookings[bookingId]);
  const userProfile = getProfileFromBooking(accountSummary, booking);

  if (userProfile) {
    // Don't do address validation if address is already validated or if address validation is not enabled
    return !!(
      !userProfile.is_address_validated && locationGeneralConfigsData?.isAddressValidationEnabled
    );
  }
  // If userProfile is null, i.e. Mary is logged out, only check if address validation is enabled
  return !!locationGeneralConfigsData?.isAddressValidationEnabled;
};
