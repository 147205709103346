import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import moment from 'moment';
import SolvCalendar from '../../SolvPatternLibrary/Calendar';
import Header from '../Header';
import { colors } from '../../../constants/colors';
import { fontSize, fontFamily } from '../../../constants/text';
import { analyticsTrackEvent } from '../../../core/analytics';
import { ACTION_CHANGE_VALUE, UNIVERSAL_MORE_DATES_INPUT } from '../../../core/analytics/events';
import { isMobile } from '../../../core/util/device';

const Root = styled.div``;

const Gutter = styled.div`
  padding: 4px 8px 30px;
  background-color: ${colors.white};
  text-align: center;
`;

const Float = styled.div`
  display: inline-block;
  margin: 0 auto;
`;

const Dates = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  -webkit-padding-start: 0;
  margin: 0;
`;

const DateRow = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.grayArea};
  padding: 16px;
  transition: background-color 100ms ease;

  &:hover {
    background-color: ${colors.greyHounds};
  }

  &:last-child {
    border-bottom: 1px solid ${colors.grayArea};
  }
`;

const DateLabel = styled.span`
  color: ${colors.squid};
  font-size: ${fontSize.default};
  font-family: ${fontFamily.book};
`;

const DateSubLabel = styled.span`
  color: ${colors.lightText};
  font-size: ${fontSize.mediumSmall};
  font-family: ${fontFamily.book};
`;

type Props = {
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  onClose?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setSelectedDate?: func;
};

type State = any;

export default class MoreDates extends PureComponent<Props, State> {
  state = { selectedDate: moment() };

  getDates = () => {
    const { selectedDate } = this.state;

    const date = moment(selectedDate).startOf('day');
    const currentDate = moment();

    const dates = [
      {
        label: 'Morning',
        subLabel: 'Before 12pm',
        value: date.set('hour', 6).valueOf(),
      },
      {
        label: 'Afternoon',
        subLabel: '12pm - 6pm',
        value: date.set('hour', 12).valueOf(),
      },
      {
        label: 'Evening',
        subLabel: '6pm - 12am',
        value: date.set('hour', 18).valueOf(),
      },
    ];

    if (date.isSame(currentDate, 'day')) {
      dates.splice(0, 0, {
        label: 'ASAP',
        subLabel: '',
        value: currentDate.valueOf(),
      });

      currentDate.subtract(4, 'hours');
      return dates.filter((d) => moment(d.value).isAfter(currentDate));
    }

    return dates;
  };

  handleSelect = (date: any) => {
    analyticsTrackEvent(UNIVERSAL_MORE_DATES_INPUT, {
      action: ACTION_CHANGE_VALUE,
      isMobile: isMobile(),
    });

    const { setSelectedDate, onClose } = this.props;

    const dateLabel = `${moment(date.value).format('MMM Do')}, ${date.label}`;
    setSelectedDate(date.value, dateLabel);
    onClose();
  };

  render() {
    return (
      <Root>
        <Header
          cancelCopy="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          onCancel={this.props.onClose}
          onConfirm={() => this.handleSelect(this.getDates()[0])}
          title="Visit Time"
        />

        <Gutter>
          <Float>
            <SolvCalendar
              handleDateChange={(selectedDate: any) => this.setState({ selectedDate })}
              selectedDate={this.state.selectedDate}
            />
          </Float>
        </Gutter>

        <Dates>
          {this.getDates().map((date) => (
            /* @ts-ignore - Open github issue: https://github.com/mui-org/material-ui/issues/15827#issuecomment-809209533*/
            <DateRow component="li" key={date.value} onClick={() => this.handleSelect(date)}>
              <DateLabel>{date.label}</DateLabel>

              <DateSubLabel>{date.subLabel}</DateSubLabel>
            </DateRow>
          ))}
        </Dates>
      </Root>
    );
  }
}
