/* eslint-disable */
import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { colors } from '../../constants/colors';
import { emptyFunction } from '../../core/util/function';
import styled from 'styled-components';

const { mrBlueSky, ladyInRed, grayvy, squid, greyHounds } = colors;

const inputInputStyle = {
  height: '46px',
  boxSizing: 'border-box',
  paddingTop: '10px',
  fontSize: '16px',
  color: squid,
};

const centeredInputLabelShrinkStyle = {
  transform: 'translate(0px, 2px) scale(0.75)',
  zIndex: 1,
  background: 'white',
  paddingLeft: '10px',
  paddingRight: '10px',
};

const StyledTextField = styled(TextField)`
  label {
    font-size: 14px;
    line-height: 12px;
  }
`;

/**
 * The naming pattern here is a camel cased combination of:
 * 1. component name being overridden
 * 2. class name being overridden
 *
 * This is because MUI TextField is composed of Input, InputLabel, FormLabel, Select etc
 * and we have access to classnames like root, underline, focus, etc
 * More info here: https://material-ui.com/api/text-field/
 */
const styles = {
  inputInput: {
    ...inputInputStyle,
    textIndent: '22px',
  },
  centeredInputInput: {
    ...inputInputStyle,
    textIndent: 0,
    textAlign: 'center',
  },
  inputUnderline: {
    '&:before': {
      border: `1px solid ${grayvy}`,
      borderRadius: '4px',
      transition: 'unset',
      top: 0,
    },
    '&$inputError:before': {
      border: `2px solid ${ladyInRed}`,
    },
    '&$inputDisabled:before': {
      border: `1px solid ${greyHounds}`,
    },
    '&:after': {
      transition: 'border-width 200ms ease',
      border: `2px solid ${mrBlueSky}`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      top: 0,
    },
    '&$inputError:after': {
      border: `2px solid ${ladyInRed}`,
    },
    '&$inputDisabled:after': {
      border: `1px solid ${greyHounds}`,
    },
    '&:hover:not($inputDisabled):not($inputFocused):not($inputError):before': {
      border: `1px solid ${grayvy}`,
    },
  },
  inputError: {},
  inputFocused: {},
  inputDisabled: {
    '&:before': {
      border: `1px solid ${greyHounds}`,
    },
    '&:after': {
      border: `1px solid ${greyHounds}`,
    },
  },
  inputLabelShrink: {
    ...centeredInputLabelShrinkStyle,
  },
  centeredInputLabelShrink: {
    ...centeredInputLabelShrinkStyle,
    left: '15px !important',
    right: 'unset !important',
  },
  centeredInputLabelFormControl: {
    top: '10px',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  inputLabelFormControl: {
    left: '15px',
    top: '10px',
  },
  popoverPaper: {
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
  },
  formLabelRoot: {},
  formLabelFocused: {
    color: `${mrBlueSky}!important`,
  },
  formLabelError: {
    color: `${ladyInRed}!important`,
  },
  selectSelect: {
    minWidth: '100px',
    width: '100%',
    lineHeight: '34px',
    '&:focus': {
      background: 'unset',
    },
  },
  selectIcon: {
    display: 'none',
  },
};

export type SolvTextFieldProps = {
  classes: any;
  className?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  InputProps?: any;
  inputProps?: any;
  InputLabelProps?: any;
  FormLabelClasses?: any;
  SelectProps?: any;
  FormHelperTextProps?: any;
  centered?: boolean;
  maxLength?: number;
} & TextFieldProps;

const SolvTextField: React.FC<SolvTextFieldProps> = (props) => {
  const {
    classes,
    className,
    value,
    onChange = emptyFunction,
    InputProps = {},
    InputLabelProps = {},
    FormLabelClasses = {},
    SelectProps = {},
    FormHelperTextProps = {},
    inputProps,
    centered,
    children,
    ...others // jscs:disable
  } = props;

  return (
    <StyledTextField
      onChange={onChange}
      value={value}
      className={className}
      {...others}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: {
          root: classes.formHelperTextRoot,
        },
      }}
      InputProps={{
        ...InputProps,
        classes: {
          underline: classes.inputUnderline,
          input: centered ? classes.centeredInputInput : classes.inputInput,
          error: classes.inputError,
          disabled: classes.inputDisabled,
          focused: classes.inputFocused,
          ...InputProps.classes,
        },
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          shrink: centered ? classes.centeredInputLabelShrink : classes.inputLabelShrink,
          formControl: centered
            ? classes.centeredInputLabelFormControl
            : classes.inputLabelFormControl,
          focused: classes.formLabelFocused,
          error: classes.formLabelError,
          root: classes.formLabelRoot,
          ...InputLabelProps.classes,
          ...FormLabelClasses,
        },
      }}
      SelectProps={{
        ...SelectProps,
        classes: {
          select: classes.selectSelect,
          icon: classes.selectIcon,
          ...(SelectProps && SelectProps.classes),
        },
      }}
      inputProps={{
        ...inputProps,
      }}
    >
      {children}
    </StyledTextField>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Type 'string' is not assignable to type '"border-b... Remove this comment to see the full error message
export default withStyles(styles)(SolvTextField);
