import styled from 'styled-components';

const BorderBoxReset = styled.div`
  & {
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
  }
`;

export default BorderBoxReset;
