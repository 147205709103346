import {
  FLU_COLD_AND_COUGH,
  EAR_PAIN_OR_INFECTION,
  FEVER,
  RASH,
  SORE_THROAT,
  URINARY_TRACT_INFECTION,
} from '../../Home/Tiles/symptoms';
import { Service, services, servicesContainingCovid, labServices } from '../../Home/Tiles/Services';
import { IS_FLU_SEASON } from '../../../config';

export const TIME_SELECT_OPTIONS = [
  { label: 'ASAP', hourOffset: 0, dayOffset: 0, extraLabel: '' },
  { label: 'This morning', hourOffset: 7, dayOffset: 0, extraLabel: '' },
  { label: 'This afternoon', hourOffset: 13, dayOffset: 0, extraLabel: '' },
  {
    label: 'This evening',
    hourOffset: 17,
    dayOffset: 0,
    extraLabel: ' (after 5:00pm)',
  },
  { label: 'Tomorrow morning', hourOffset: 7, dayOffset: 1, extraLabel: '' },
  { label: 'Tomorrow afternoon', hourOffset: 13, dayOffset: 1, extraLabel: '' },
  {
    label: 'Tomorrow evening',
    hourOffset: 17,
    dayOffset: 1,
    extraLabel: ' (after 5:00pm)',
  },
];

const showFluShotAsDefault = (IS_FLU_SEASON: boolean): Array<Service> =>
  IS_FLU_SEASON ? services : servicesContainingCovid;

const defaultSymptoms: { name: string; isCovidRelated: boolean; isFluRelated: boolean }[] = [
  { name: FEVER, isCovidRelated: true, isFluRelated: false },
  { name: FLU_COLD_AND_COUGH, isCovidRelated: true, isFluRelated: true },
  { name: URINARY_TRACT_INFECTION, isCovidRelated: false, isFluRelated: false },
  { name: EAR_PAIN_OR_INFECTION, isCovidRelated: false, isFluRelated: false },
  { name: RASH, isCovidRelated: false, isFluRelated: false },
  { name: SORE_THROAT, isCovidRelated: false, isFluRelated: false },
];

const defaultLabSuggestions = [...servicesContainingCovid, ...labServices];

export const generateDefaultSuggestions = (callback: any) => [
  {
    primary: 'Urgent Care',
    key: 'UrgentCare-default',
    onClick: () =>
      callback({
        highlight: 'Urgent Care',
        name: 'Urgent Care',
        provider_type: 'urgent_care',
        type: 'provider-type',
        is_covid_related: false,
      }),
    fullDoc: { provider_type: 'urgent_care', type: 'provider-type' },
  },

  ...showFluShotAsDefault(IS_FLU_SEASON).map((service) => ({
    primary: service.name,
    secondary: 'Urgent Care',
    key: service.name,
    onClick: () =>
      callback({
        highlight: service.name,
        provider_type: service.providerType,
        type: 'service',
        name: service.name,
        id: service.ids,
        require_hard_filter: true,
        is_covid_related: service.isCovidRelated,
        is_flu_related: service.isFluRelated,
      }),
    fullDoc: { provider_type: 'urgent_care', type: 'service' },
  })),
  ...defaultSymptoms.map((symptom) => ({
    primary: symptom.name,
    secondary: 'Urgent Care',
    key: symptom.name,
    onClick: () =>
      callback({
        highlight: symptom.name,
        provider_type: 'urgent_care',
        type: 'symptom',
        name: symptom.name,
        is_covid_related: symptom.isCovidRelated,
        is_flu_related: symptom.isFluRelated,
      }),
    fullDoc: { provider_type: 'urgent_care', type: 'symptom' },
  })),
];

export const generateDefaultLabSuggestions = (callback: any) => [
  ...defaultLabSuggestions.map((service) => ({
    primary: service.name,
    secondary: '',
    key: service.name,
    onClick: () =>
      callback({
        highlight: service.name,
        provider_type: service.providerType,
        category: service.category,
        type: 'service',
        name: service.name,
        id: service.ids,
        require_hard_filter: true,
        is_covid_related: service.isCovidRelated,
        is_flu_related: service.isFluRelated,
      }),
    fullDoc: { provider_type: 'urgent_care', type: 'service' },
  })),
];
