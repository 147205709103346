import React, { PureComponent } from 'react';

// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { formShape } from 'rc-form';
import TextField from '../../SolvPatternLibrary/TextField';
import { phoneNumberValidator } from '../../../core/util/rcForm/validators';
import { phoneNumberNormalizer } from '../utils';
import { stripNonNumeric } from '../../../core/util/string';
import { PHONE_ID } from './ids';
import { MaskedPhoneTextField } from '../../SolvPatternLibrary/MaskedTextField';

type Props = {
  form: formShape;
  initialValue?: string;
};

export default class PhoneInput extends PureComponent<Props> {
  onFocusFormatPhone = () => {
    const { setFieldsValue, getFieldValue } = this.props.form;
    setFieldsValue({ [PHONE_ID]: stripNonNumeric(getFieldValue(PHONE_ID)) });
  };

  onBlurFormatPhone = () => {
    const { setFieldsValue, getFieldValue, validateFields } = this.props.form;
    setFieldsValue({
      [PHONE_ID]: phoneNumberNormalizer(getFieldValue(PHONE_ID)),
    });
    validateFields([PHONE_ID]);
  };

  get inputProps() {
    const { getFieldError, getFieldValue, getFieldProps } = this.props.form;
    const { initialValue } = this.props;
    const error = getFieldError(PHONE_ID);

    return {
      ...getFieldProps(PHONE_ID, {
        validateTrigger: ['onBlur'],
        initialValue: phoneNumberNormalizer(initialValue) || undefined,
        rules: [
          {
            required: true,
            validator: phoneNumberValidator,
          },
        ],
      }),
      id: PHONE_ID,
      label: 'Mobile Phone Number',
      value: getFieldValue(PHONE_ID) || '',
      error: !!error,
      onBlur: this.onBlurFormatPhone,
      onFocus: this.onFocusFormatPhone,
      type: 'tel',
      helperText: Array.isArray(error) && error[0],
      disabled: !!initialValue,
    };
  }

  render = () => <MaskedPhoneTextField fullWidth {...this.inputProps} />;
}
