import React, { PureComponent } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { formShape } from 'rc-form';
import styled from 'styled-components';
import SelectField from '../SelectTextField';
import { emptyFieldValidator } from '../../../core/util/rcForm/validators';
import { BIRTH_SEX_FEMALE, BIRTH_SEX_MALE } from '../../../constants/index';

export const BIRTH_SEX_FIELD = 'birthSex';

const StyledSelect = styled(SelectField)`
  width: 140px;
  margin-right: 10px;
`;

type BirthSexInputProps = {
  form: formShape;
  className?: string;
  label?: string;
  initialValue?: string;
  t: TFunction<'common'>;
};

class BirthSexInput extends PureComponent<BirthSexInputProps> {
  getBirthSexProps = () => {
    const { t, form, label } = this.props;
    const initialValue = this.props.initialValue?.toLowerCase();
    const error = form.getFieldError(BIRTH_SEX_FIELD);
    return {
      ...form.getFieldProps(BIRTH_SEX_FIELD, {
        initialValue,
        validateTrigger: ['onBlur', 'onChange'],
        rules: [
          {
            required: true,
            validator: emptyFieldValidator,
          },
        ],
      }),
      id: BIRTH_SEX_FIELD,
      label: label || t('userInfo.patientSex.capitalCase'),
      error: !!error,
      selectOptions: [
        {
          key: '',
          value: '',
          label: '',
        },
        {
          key: BIRTH_SEX_MALE.toLowerCase(),
          value: BIRTH_SEX_MALE.toLowerCase(),
          label: t('userInfo.sexMale'),
        },
        {
          key: BIRTH_SEX_FEMALE.toLowerCase(),
          value: BIRTH_SEX_FEMALE.toLowerCase(),
          label: t('userInfo.sexFemale'),
        },
      ],
    };
  };

  render = () => {
    const { className } = this.props;

    return <StyledSelect className={className} {...this.getBirthSexProps()} />;
  };
}

export default withTranslation()(BirthSexInput);
