import { Hidden } from '@material-ui/core';
import { SolvTheme, Stack } from '@solvhealth/jigsaw';
import React, { ReactNode } from 'react';
import BorderBoxReset from '~/components/util/BorderBoxReset';
import CenteredDialog from './CenteredDialog';
import SlideInBottomDialog from './SlideInBottomDialog';

type Props = {
  children: ReactNode;
  open?: boolean;
  onClose?: () => void;
  forceOpen?: boolean;
};

const ResponsiveSlideInDialog = ({ forceOpen, open, onClose, children }: Props) => {
  return (
    <>
      <Hidden only={['xs']}>
        <CenteredDialog onClose={onClose} open={Boolean(forceOpen || open)}>
          <BorderBoxReset>
            <Stack pb={3} px={3}>
              {children}
            </Stack>
          </BorderBoxReset>
        </CenteredDialog>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <SlideInBottomDialog
          onClose={onClose}
          open={Boolean(forceOpen || open)}
          shouldOffsetBottomNav={false}
          squared
        >
          <BorderBoxReset>
            <Stack
              style={{ paddingBottom: `max(env(safe-area-inset-bottom), ${SolvTheme.space[2]})` }}
            >
              {children}
            </Stack>
          </BorderBoxReset>
        </SlideInBottomDialog>
      </Hidden>
    </>
  );
};

export default ResponsiveSlideInDialog;
