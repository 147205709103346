import { connect } from 'react-redux';
import LeaveAReview from './index';
import { reviewSubmitted, starRatingUpdated } from '../../ducks/leaveAReview';

const mapStateToProps = (state: any, ownProps: any) => {
  // don't use location from state if a provider is passed down as a prop
  const location = ownProps.provider ? undefined : state.location;
  return {
    location,
    leaveAReview: state.leaveAReview,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const isPGpage = ownProps.provider ? { isPGPage: true } : undefined;
  return {
    onChange: (update: any) => dispatch(starRatingUpdated(update)),
    submit: (formData: any) => dispatch(reviewSubmitted({ ...formData, ...isPGpage })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveAReview);
