import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Hidden from '@material-ui/core/Hidden';
import { fontSize, fontFamily } from '../../constants/text';
import { colors } from '../../constants/colors';
import { analyticsTrackEvent } from '../../core/analytics';
import { LOGIN_CLICK_HEADER } from '../../core/analytics/events';
import history from '../../core/history';
import { smallerThanOrEqualTo } from '../../core/util/styledComponents';
import Picture from '../SolvPatternLibrary/Picture';
import { LOGIN_ACTION_SIGN_UP, LOGIN_ACTION_SIGN_UP_VERIFY } from '../../constants/index';
import { isUndefined } from '../../core/util/object';
import { isClientSide } from '../../core/util/system';
import useSetNativeSafeAreaColor from '../../hooks/useSetNativeSafeAreaColor';
import useInitialWidth from '../../hooks/useInitialWidth';

const HEADER_HEIGHT = 56;

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: ${HEADER_HEIGHT}px;
`;

const Header = styled.div`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(130.49deg, #e30c79 0%, #d000a4 100%);
`;

const SolvLogo = styled(Picture)`
  z-index: 1;
  height: 28px;
`;

const Back = styled(ArrowBack)`
  color: ${colors.theWhiteAlbum};
  font-size: ${fontSize.quiteExtraExtraLarge};
  cursor: pointer;
  z-index: 1;
  position: absolute;
  left: 25px;
  top: 15px;
`;

const Text = styled.p`
  font-size: ${fontSize.extraLarge};
  color: ${colors.theWhiteAlbum};
  font-family: ${fontFamily.bold};
  z-index: 1;
  ${smallerThanOrEqualTo.sm`
    font-size: ${fontSize.mediumLarge};
  `};
  text-align: center;
`;

const handleClick = () => {
  analyticsTrackEvent(LOGIN_CLICK_HEADER);
  history.goBack();
};

type OwnBrandedHeaderProps = {
  className?: string;
  isModal?: boolean;
  closeModal?: (...args: any[]) => any;
  loginAction?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'BrandedHeaderProps' circularly referen... Remove this comment to see the full error message
type BrandedHeaderProps = OwnBrandedHeaderProps & typeof BrandedHeader.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'BrandedHeader' implicitly has type 'any' because ... Remove this comment to see the full error message
const BrandedHeader = (props: BrandedHeaderProps) => {
  const { className, isModal, loginAction, closeModal } = props;

  useSetNativeSafeAreaColor(colors.theWhiteAlbum);

  const isSignupScreen = () => {
    return (
      loginAction === LOGIN_ACTION_SIGN_UP ||
      loginAction === LOGIN_ACTION_SIGN_UP_VERIFY ||
      (isUndefined(loginAction) && isClientSide() && window.location.pathname.includes('sign-up'))
    );
  };

  const initialWidth = useInitialWidth();

  return (
    <>
      <Hidden initialWidth={initialWidth} mdDown>
        {(isSignupScreen() || isModal) && (
          <Root>
            <Header className={className} />
            {isModal && (
              <SolvLogo
                alt="Solv Logo"
                imageObject={{ fullRes: '/images/logo/white_with_yellow_dot.png' }}
                onClick={handleClick}
              />
            )}

            {!isModal && <Text>Great doctors, available today.</Text>}
          </Root>
        )}
      </Hidden>

      <Hidden initialWidth={initialWidth} smUp>
        <Root>
          <Back onClick={closeModal} />

          <Header className={className} />

          <SolvLogo
            alt="Solv Logo"
            imageObject={{ fullRes: '/images/logo/white_with_yellow_dot.png' }}
            onClick={handleClick}
          />
        </Root>
      </Hidden>
    </>
  );
};

BrandedHeader.defaultProps = {
  isModal: false,
};

export default BrandedHeader;
