import React from 'react';
import AuthenticationContext from './AuthenticationContext';

export default (Component: any) => (props: any) =>
  (
    <AuthenticationContext.Consumer>
      {(value) => {
        if (value === null) return null;

        return <Component {...props} {...value} />;
      }}
    </AuthenticationContext.Consumer>
  );
