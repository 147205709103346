import { produce } from 'immer';
import Location from '@solvhealth/types/interfaces/Location';
import Provider from '@solvhealth/types/interfaces/Provider';
import { locationResponseFormatter } from '../../reducers/formatters/location';

export const formatProvider = (provider: any) =>
  produce<Provider>(provider, (draft: any) => {
    const { locations = [] } = draft;
    draft.locations = locations.map((location: Location) => locationResponseFormatter(location));
  });
