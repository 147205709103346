import moment from 'moment';

/**
 * Normalize waitList data into a friendly format.
 *
 * @param {object} waitList
 * @returns {object}
 */
const waitListResponseFormatter = (waitList: any) => {
  if (waitList.isFormatted) {
    return waitList;
  }

  const formatted = {};
  for (const key in waitList) {
    if (!waitList.hasOwnProperty(key)) {
      continue;
    }

    const camelCaseKey = key.replace(/_(\w)/g, (m, w) => w.toUpperCase());
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    formatted[camelCaseKey] = waitList[key];
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'appointmentTime' does not exist on type ... Remove this comment to see the full error message
  formatted.appointmentTime = moment(waitList.appointment_date).valueOf();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'arrivedTime' does not exist on type '{}'... Remove this comment to see the full error message
  formatted.arrivedTime = waitList.arrived_timestamp
    ? moment(waitList.arrived_timestamp).valueOf()
    : null;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFormatted' does not exist on type '{}'... Remove this comment to see the full error message
  formatted.isFormatted = true;
  return formatted;
};

export { waitListResponseFormatter };
