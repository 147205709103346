export const MARKETPLACE_INSURERS_RESPONSE_RECEIVED =
  'marketplaceInsurers/MARKETPLACE_INSURERS_RESPONSE_RECEIVED';

export const initialState = { insurers: [] };

export function receiveMarketplaceInsurers(value: any) {
  return {
    type: MARKETPLACE_INSURERS_RESPONSE_RECEIVED,
    payload: { value },
  };
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case MARKETPLACE_INSURERS_RESPONSE_RECEIVED: {
      return {
        insurers: action.payload.value.results,
      };
    }
    default:
      return state;
  }
};
