import { css } from 'styled-components';

export const breakpointPixels = {
  xl: 1920,
  lg: 1280,
  mdLg: 1024,
  md: 960,
  tablet: 768,
  sm: 600,
  xs: 320,
};

export const breakpoints = {
  xl: `${breakpointPixels.xl}px`,
  lg: `${breakpointPixels.lg}px`,
  mdLg: `${breakpointPixels.mdLg}px`,
  md: `${breakpointPixels.md}px`,
  tablet: `${breakpointPixels.tablet}px`,
  sm: `${breakpointPixels.sm}px`,
  xs: `${breakpointPixels.xs}px`,
};

/**
 * Function that returns a media css string based on input width
 *
 * @param {number} maxWidth - Unit in pixels
 * @returns {string}
 */
const customMediaQueryWidth = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

/**
 * Function that returns a media css string based on input height
 *
 * @param {number} maxHeight - Unit in pixels
 * @returns {string}
 */
const customMediaQueryHeight = (maxHeight: number) => `@media (max-height: ${maxHeight}px)`;

/**
 * @example
 * media.customWidth(100)
 * >>> '@media (max-width: 100px)'
 *
 * @example
 * media.customHeight(200)
 * >>> '@media (max-height: 200px)'
 *
 * @example
 * media.phonePlusWidth()
 * >>> '@media (max-width: 414px)'
 */
export const media = {
  customWidth: customMediaQueryWidth,
  desktopWidth: customMediaQueryWidth(960),
  tabletWidth: customMediaQueryWidth(768),
  phonePlusWidth: customMediaQueryWidth(414),
  phoneWidth: customMediaQueryWidth(375),
  iphone5Width: customMediaQueryWidth(320),
  customHeight: customMediaQueryHeight,
};

/* a cheater interface, mostly to force typescript into understanding that these
 * all return the `css` variable, along with being descriptive in the type of
 * what kind of media query it actually is. */
interface MediaQuery {
  xl: '1920px' & typeof css;
  lg: '1280px' & typeof css;
  mdLg: '1024px' & typeof css;
  md: '960px' & typeof css;
  tablet: '768px' & typeof css;
  sm: '600px' & typeof css;
  xs: '320px' & typeof css;
}

const getBreakpointValue = (label: any) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const breakpointValueStr = breakpoints[label].replace('px', '');
  return parseInt(breakpointValueStr, 10);
};

/* eslint-disable no-param-reassign */
export const smallerThanOrEqualTo = <MediaQuery>Object.keys(breakpoints).reduce((acc, label) => {
  // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
  acc[label] = (...args) => css`
    @media (max-width: ${
        // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
        breakpoints[label]
      }) {
      ${css(
        // @ts-expect-error ts-migrate(2557) FIXME: Expected at least 1 arguments, but got 0 or more.
        ...args
      )}
    }
  `;

  return acc;
}, {});

export const smallerThan = <MediaQuery>Object.keys(breakpoints).reduce((acc, label) => {
  const breakpointValue = getBreakpointValue(label);

  // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
  acc[label] = (...args) => css`
    @media (max-width: ${breakpointValue - 1}px) {
      ${css(
        // @ts-expect-error ts-migrate(2557) FIXME: Expected at least 1 arguments, but got 0 or more.
        ...args
      )}
    }
  `;

  return acc;
}, {});

export const biggerThanOrEqualTo = <MediaQuery>Object.keys(breakpoints).reduce((acc, label) => {
  // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
  acc[label] = (...args) => css`
    @media (min-width: ${
        // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
        breakpoints[label]
      }) {
      ${css(
        // @ts-expect-error ts-migrate(2557) FIXME: Expected at least 1 arguments, but got 0 or more.
        ...args
      )}
    }
  `;

  return acc;
}, {});

export const biggerThan = <MediaQuery>Object.keys(breakpoints).reduce((acc, label) => {
  const breakpointValue = getBreakpointValue(label);

  // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
  acc[label] = (...args) => css`
    @media (min-width: ${breakpointValue + 1}px) {
      ${css(
        // @ts-expect-error ts-migrate(2557) FIXME: Expected at least 1 arguments, but got 0 or more.
        ...args
      )}
    }
  `;

  return acc;
}, {});
/* eslint-enable no-param-reassign */

/**
 * Target IE10+
 *
 * @param {css} args
 * @returns {css}
 */
// @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
export const ie10Up = (...args) => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${css(
      // @ts-expect-error ts-migrate(2557) FIXME: Expected at least 1 arguments, but got 0 or more.
      ...args
    )}
  }
`;

// @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
export const iphone5 = (...args) => css`
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait) {
    ${css(
      // @ts-expect-error ts-migrate(2557) FIXME: Expected at least 1 arguments, but got 0 or more.
      ...args
    )}
  }
`;
