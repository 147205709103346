import { batch } from 'react-redux';
import moment from 'moment-timezone';
import ExternalSlot from '@solvhealth/types/interfaces/ProviderGroup/ExternalSlot';
import { AppointmentReason } from '@solvhealth/types/interfaces/ProviderGroup/AppointmentReason';
import Booking from '@solvhealth/types/interfaces/Booking';
import Account from '@solvhealth/types/interfaces/Account';
import {
  ASAP_VALUE,
  AUTHOR_TYPE_ACCOUNT,
  BookingStatus,
  INSURANCE_TYPES,
  MARKETPLACE_DFW,
  ORIGIN_REACT_MOBILE_APP,
  TELEMED_IN_PROGRESS,
  TYPE_SKIP,
  UBER_RIDE_REQUESTED,
} from '../../constants/index';
import {
  DAPI_HOST,
  GOOGLE_HEALTH_APPOINTMENTS_COOKIE_NAME,
  GOOGLE_MAP_PACK_COOKIE_NAME,
  RESERVE_WITH_GOOGLE_COOKIE_NAME,
} from '../../config/index';
import { getNativePlatform, isAndroidApp, isIosApp } from '../util/device';
import { apiGetDispatchable, apiPostDispatchable, apiPostJson } from './index';
import { dateFormat } from '../util/date';
import {
  analyticsIdentify,
  analyticsTagHotjarRecording,
  analyticsTrackEvent,
  analyticsTriggerHotjarRecording,
  getBookingProperties,
} from '../analytics';
import {
  CROSSED_PINK_DOOR,
  CROSSED_PINK_DOOR_TYPE_CONSUMER_APP_BOOKING,
  REVIEW_BOOKING_API_NEW_BOOKING,
  REVIEW_BOOKING_API_NEW_IN_PERSON_BOOKING,
  REVIEW_BOOKING_API_NEW_TELEMED_BOOKING,
} from '../analytics/events';
import { getEligibilityId, getInsurerIdByCode } from '../util/insurance';
import { isEmptyString } from '../util/string';
import { isEmptyObject, safeGet } from '../util/object';
import { buildDapiUserProfileObject } from './userProfile';
import { getAccountId } from '../auth';
import {
  getGroupId,
  getLocationSpecialtyValue,
  hasCovidAntibodyTest,
  hasCovidTest,
  isBookable,
  isCovidTestingOnly,
  isCovidTestingV1,
  isExternalTelemedLocation,
  isInDfwMarket,
  isSolvPartner,
  isTelemedLocation,
  isViewable,
} from '../util/location';
import {
  fireGoogleAnalytics,
  GA_EVENT,
  GA_EVENT_ACTION_BOOKING,
  GA_EVENT_CATEGORY_BOOKING,
  isConfiguredForGoogleAnalyticsTracking,
} from '../analytics/trackingForPartners';
import { HOTJAR_TRACKING_LABEL } from '../tracking/hotjar/constants';
import { isEmpty } from '../util/empty';
import { isPersistedLogin } from '../session';
import { getTrackingUrl } from './tracking';
import { TrackingEntities, TrackingTypes } from '../../constants/dapi/tracking';
import { didCrossPinkdoorAfterTimeSpecified, getSolvUserDate } from '../util/account';
import { bookingError, upcomingNewBooking } from '../../actions/account';
import { setLocations } from '../../ducks/locations';
import { getSelfPayV1ServiceName, isProviderGroupBooking, isSelfPay } from '../util/booking';
import { doesCookieExist, getReserveWithGoogleAttributionViaCookies } from '../util/cookies';
import { getDapiLanguageFromLocale } from '../util/localizedStr';
import { ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME } from '~/components/BookingWidget/util/validators';

const getRescheduleBookingUrl = () => `${DAPI_HOST}/v1/registration/reschedule`;
const getAddBackupTimeUrl = () => `${DAPI_HOST}/v1/registration/add-backup-time`;
const getBookingById = (bookingId: any) => `${DAPI_HOST}/v1/bookings/${bookingId}`;
const getNewBookingUrl = () => `${DAPI_HOST}/v1/registration/bookings`;
const getPublicBookingById = (bookingId: string | number) =>
  `${DAPI_HOST}/v1/public-bookings/${bookingId}`;

export const getBookingsEndpointByPublicity = (bookingId: string | number, publicData: boolean) => {
  if (!publicData) {
    return getBookingById(bookingId);
  }
  return getPublicBookingById(bookingId);
};

export const buildBookingStatus = (newBooking: any, location: any) => {
  if (location.isOpaque) {
    return BookingStatus.PENDING;
  }

  return BookingStatus.RESERVED;
};

export const buildZipCode = (searchPreferences: any) => {
  if (!searchPreferences || !searchPreferences.location) {
    return '';
  }

  if (searchPreferences.location.label && searchPreferences.location.label.match(/\d{5}/)) {
    return searchPreferences.location.label.replace(/^.*(\d{5}).*$/, '$1');
  }

  return searchPreferences.location.searchZipCode;
};

export const normalizePhone = (phone: any) => {
  if (typeof phone !== 'string') return phone;

  let sanitizedPhone = phone.replace(/\D/g, '');

  // if phone length more than 10, it's possible they are appending 1 to the phone number
  if (sanitizedPhone.length > 10) {
    sanitizedPhone = sanitizedPhone.replace(/^1/, '');
  }

  if (sanitizedPhone.length === 10) {
    sanitizedPhone = `+1${sanitizedPhone}`;
  }

  return sanitizedPhone;
};

export const reasonForVisitTransformer = (reasonForVisit: any) => {
  if (reasonForVisit instanceof Array) {
    return reasonForVisit.map((element) => element.label).join(', ');
  }

  return typeof reasonForVisit === 'string' ? reasonForVisit : '';
};

export const getInsurerKey = (insurance: any) =>
  insurance.insuranceType === INSURANCE_TYPES.dental ? 'marketplace_insurers_id' : 'insurer_id';

export const getInsurerId = (newbookingInsuranceObj: any, urgentCareInsurers: any) => {
  const insuranceType = safeGet(newbookingInsuranceObj)('insuranceType');
  if (isEmpty(insuranceType) || insuranceType === INSURANCE_TYPES.medical) {
    return (
      newbookingInsuranceObj.insurerId ||
      getInsurerIdByCode(newbookingInsuranceObj.insurerCode, urgentCareInsurers)
    );
  }

  return newbookingInsuranceObj.marketplace_insurers_id;
};

export interface ExternalBooking {
  appointment_id: number;
  reason_id: number;
  external_department_id: number;
  external_practice_id: number;
}

interface BookingPostData {
  account: Partial<Account>;
  booking: Partial<Booking>;
  booking_external?: ExternalBooking;
  eligibility_requests?: any;
  insurance_profile: any;
  tracking: any;
  user_profile: any;
  location: {
    booking_code: any;
  };
}
interface ReschedulePostData {
  booking_id: string;
  appointment_date: string;
  new_booking?: ExternalBooking;
  author_type?: string;
  author_id?: string;
}

const buildBookingPostData = (props: any) => {
  const {
    newBooking,
    location,
    npi,
    searchPreferences,
    eligibilityCheck,
    tracking,
    insurers,
    position,
    trackingPropertiesReferrer,
    photoId,
  } = props;
  const getInsurerType = (insurance: any) => {
    // If insurerType was set through the booking flow use it
    // As as safety net, if that's not the case default to skip
    if (!isEmptyObject(insurance.insurerType)) {
      return insurance.insurerType;
    }

    return TYPE_SKIP;
  };

  const { booking, profile, insurance } = newBooking;
  const status = buildBookingStatus(newBooking, location);
  const zipCode = buildZipCode(searchPreferences);
  const phone = normalizePhone(profile.phone);
  const insurerIdKey = getInsurerKey(insurance);
  const insurerId = getInsurerId(insurance, insurers);
  const language = getDapiLanguageFromLocale(newBooking.locale);

  const postData: BookingPostData = {
    account: {
      phone,
      email: profile.email,
      first_name: profile.firstName,
      last_name: profile.lastName,
      address: profile.address_street,
      city: profile.address_city,
      state: profile.address_state,
      zip_code: profile.address_zip,
      tos_consent: !!profile.tosConsent || !!profile.bookingTosConsent,
      sms_consent: !!profile[ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME],
      is_ios_app_user: isIosApp(),
      is_android_app_user: isAndroidApp(),
    },

    user_profile: buildDapiUserProfileObject(profile),
    booking: {
      first_name: profile.firstName,
      last_name: profile.lastName,
      email: profile.email,
      phone,
      reason_for_visit: reasonForVisitTransformer(booking.reasonForVisit),
      origin: booking.origin || ORIGIN_REACT_MOBILE_APP,
      location_id: booking.locationId || location.id,
      appointment_date: dateFormat(
        booking.appointmentTime ? booking.appointmentTime : profile.appointmentTime,
        'isoUtcDateTime'
      ),
      preferred_time: booking.appointmentType === ASAP_VALUE ? ASAP_VALUE : null,
      zip_code: zipCode,
      status,
      is_logged_in: props.isLoggedIn,
      is_persistent_login: props.isLoggedIn && isPersistedLogin(),
      language,

      // DEPRECATED
      // Search parameter, not really booking data.
      birth_date: profile.birthDate,
      insurer_type: getInsurerType(insurance),
      uber_ride_status: profile.uberRequest === UBER_RIDE_REQUESTED ? profile.uberRequest : null,
      is_existing_patient: profile.isExistingPatient,
      is_direct_telemed: profile.isDirectTelemed,
      notes: booking.notes,
      is_premium_visit: booking.isPremiumVisit,
    },

    tracking,

    insurance_profile: {
      [insurerIdKey]: insurerId,
      insurer_name: insurance.insurerName,
      insurer_type: insurance.insurerType,
      insurance_type: insurance.insuranceType,
      member_code: insurance.memberCode,
    },

    location: {
      booking_code: newBooking?.location?.bookingCode,
    },
  };

  if (
    booking.origin === ORIGIN_REACT_MOBILE_APP &&
    (doesCookieExist(RESERVE_WITH_GOOGLE_COOKIE_NAME) ||
      doesCookieExist(GOOGLE_MAP_PACK_COOKIE_NAME) ||
      doesCookieExist(GOOGLE_HEALTH_APPOINTMENTS_COOKIE_NAME))
  ) {
    const reserveWithGoogleAttribution = getReserveWithGoogleAttributionViaCookies();
    postData.tracking = {
      ...tracking,
      campaign: {
        ...(tracking && tracking.campaign),
        reserve_with_google_attribution: reserveWithGoogleAttribution,
      },
    };
  }

  // if PG booking, add PG specific booking fields
  if (isProviderGroupBooking(booking)) {
    postData.booking = {
      ...postData.booking,
      provider_id: booking.providerId,
      is_external_telemed: booking.reason?.is_telemed,
      external_appointment_reason: booking.reason?.reason_name,
    };

    // and add these external ids to booking_external to allow the booking to be made externally
    postData.booking_external = {
      appointment_id: booking.appointmentId,
      reason_id: booking.reason?.reason_id,
      external_department_id: booking.externalDepartmentId,
      external_practice_id: booking.externalPracticeId,
    };
  }

  let referrerDomain = null;
  if (trackingPropertiesReferrer) {
    try {
      referrerDomain = new URL(trackingPropertiesReferrer).hostname;
    } catch (e) {
      /* continue regardless of error */
    }
  }
  if (!isEmptyString(referrerDomain)) {
    postData.booking.referrer_domain = referrerDomain;
  }

  const nativePlatform = getNativePlatform();
  if (nativePlatform) {
    const utmData = `${nativePlatform}_app`;
    postData.tracking = {
      ...tracking,
      campaign: {
        ...(tracking && tracking.campaign),
        utm_source: utmData,
        utm_campaign: utmData,
        utm_medium: utmData,
      },
    };
  }

  if (getAccountId()) {
    postData.booking.author_type = AUTHOR_TYPE_ACCOUNT;
    postData.booking.author_id = getAccountId();
  }

  if (!isEmptyString(insurance.firstName) || !isEmptyString(profile.firstName)) {
    postData.insurance_profile.first_name = isEmptyString(insurance.firstName)
      ? profile.firstName
      : insurance.firstName;
  }

  if (!isEmptyString(insurance.lastName) || !isEmptyString(profile.lastName)) {
    postData.insurance_profile.last_name = isEmptyString(insurance.lastName)
      ? profile.lastName
      : insurance.lastName;
  }

  if (!isEmptyString(insurance.birthDate)) {
    postData.insurance_profile.birth_date = insurance.birthDate;
  }

  if (
    !isEmptyObject(position) &&
    !isEmptyString(position.result) &&
    !isEmptyString(position.result.latitude) &&
    !isEmptyString(position.result.longitude)
  ) {
    postData.booking.lat_long = `(${position.result.latitude},${position.result.longitude})`;
  }

  if (!isEmptyObject(searchPreferences)) {
    if (
      !isEmptyObject(searchPreferences.location) &&
      !isEmptyString(searchPreferences.location.latitude) &&
      !isEmptyString(searchPreferences.location.longitude)
    ) {
      const searchLat = searchPreferences.location.latitude;
      const searchLng = searchPreferences.location.longitude;
      postData.booking.search_lat_long = `(${searchLat},${searchLng})`;
    }

    if (!isEmptyString(searchPreferences.filterGroups.acceptedInsurance)) {
      postData.booking.search_accepted_insurance_filter =
        searchPreferences.filterGroups.acceptedInsurance;
    }
  }

  if (!isEmptyString(insurance.cardFront)) {
    postData.insurance_profile.card_front_id = insurance.cardFront;
  }

  if (!isEmptyString(insurance.cardBack)) {
    postData.insurance_profile.card_back_id = insurance.cardBack;
  }

  const eligibilityId = getEligibilityId(newBooking, location, npi, eligibilityCheck);
  if (eligibilityId !== null) {
    postData.eligibility_requests = { eligibility_requests_id: eligibilityId };
  }

  if (insurance.insuranceProfileId) {
    postData.booking.insurance_profile_id = insurance.insuranceProfileId;
  }

  if (profile.userProfileId) {
    postData.booking.user_profile_id = profile.userProfileId;
  }

  if (booking.notes) {
    postData.booking.notes = booking.notes;
  }

  if (isTelemedLocation(location)) {
    postData.booking.telemed_status = TELEMED_IN_PROGRESS;
  }

  if (isExternalTelemedLocation(location)) {
    postData.booking.is_external_telemed = true;
  }

  if (safeGet(photoId)('front.id')) {
    postData.user_profile.photo_id_front_id = photoId.front.id;
  }

  if (safeGet(photoId)('back.id')) {
    postData.user_profile.photo_id_back_id = photoId.back.id;
  }

  return postData;
};

const postBooking = (
  dispatch: any,
  props: any,
  onSuccess: any,
  onFailure: any,
  bookingFlowVariant?: 'newBookingFlow' | 'oldBookingFlow'
) => {
  const postBookingTimestamp = moment();
  const postData = buildBookingPostData(props);
  const handleBookingSuccess = (response: any) => {
    if (typeof response !== 'undefined') {
      const {
        location,
        location: { id, market, platformType },
        abTestGroup,
        trackingPropertiesSessionId,
        trackingPropertiesReferrer,
        isLoggedIn,
      } = props;

      // So that the account home has immediate access to booking details without having to re-fetch
      batch(() => {
        dispatch(
          apiGetDispatchable(
            getBookingsEndpointByPublicity(response.booking_id, !isLoggedIn),
            upcomingNewBooking,
            bookingError
          )
        );
        dispatch(setLocations([location]));
      });

      const didJustCrossPinkDoor = didCrossPinkdoorAfterTimeSpecified(
        response,
        postBookingTimestamp
      );

      let v1ServiceName = getSelfPayV1ServiceName(props.newBooking?.booking?.reasonForVisit ?? '');

      // Whether Self-pay was selected in the Booking Flow or Booking Widget
      const is_self_pay =
        (props.newBooking.insurance && isSelfPay(props.newBooking.insurance)) ?? false;

      const eventProperties = {
        ...getBookingProperties(response.account_id, postData),
        booking_id: response.booking_id,
        location_id: id,
        is_solv_partner: isSolvPartner(location),
        is_bookable: isBookable(location),
        is_viewable: isViewable(location),
        is_covid_testing: isCovidTestingV1(location),
        is_covid_testing_only: isCovidTestingOnly(location),
        has_covid_molecular_test: hasCovidTest(location.servicesObj),
        has_covid_antibody_test: hasCovidAntibodyTest(location.servicesObj),
        market,
        platform_type: platformType,
        is_telemed: isTelemedLocation(location),
        srpAbTestGroup: abTestGroup,
        trackingPropertiesSessionId,
        didJustCrossPinkDoor,
        service: v1ServiceName,
        is_self_pay,
        booking_flow_variant: bookingFlowVariant,
        isPerformancePricingEnabled: location.isPerformancePricingEnabled,
        isTelemedConnect: location.isTelemedConnect,
      };

      if (getGroupId(location)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'group_id' does not exist on type '{ book... Remove this comment to see the full error message
        eventProperties.group_id = getGroupId(location);
      }
      analyticsIdentify(response.account_id, {
        ...postData,
        solv_user_date: getSolvUserDate(response),
      });
      if (didJustCrossPinkDoor) {
        analyticsTrackEvent(CROSSED_PINK_DOOR, {
          solv_user_date: getSolvUserDate(response),
          type: CROSSED_PINK_DOOR_TYPE_CONSUMER_APP_BOOKING,
        });
      }

      analyticsTrackEvent(REVIEW_BOOKING_API_NEW_BOOKING, eventProperties);
      const bookingEvent = isTelemedLocation(location)
        ? REVIEW_BOOKING_API_NEW_TELEMED_BOOKING
        : REVIEW_BOOKING_API_NEW_IN_PERSON_BOOKING;
      analyticsTrackEvent(bookingEvent, eventProperties);
      if (trackingPropertiesReferrer) {
        apiPostJson(
          getTrackingUrl(
            TrackingEntities.BOOKINGS,
            response.booking_id,
            TrackingTypes.INFO_REFERRER
          ),
          {
            referrer_url: trackingPropertiesReferrer,
          }
        );
      }
      const locationBookingSpecialty = getLocationSpecialtyValue(location);
      const hotjarTags = [
        HOTJAR_TRACKING_LABEL.booking,
        `${HOTJAR_TRACKING_LABEL.booking}_${locationBookingSpecialty}`,
      ];
      if (isInDfwMarket(location)) {
        hotjarTags.push(MARKETPLACE_DFW);
        analyticsTriggerHotjarRecording(MARKETPLACE_DFW);
      }
      analyticsTagHotjarRecording(hotjarTags);

      if (isConfiguredForGoogleAnalyticsTracking(location)) {
        fireGoogleAnalytics(location, GA_EVENT, GA_EVENT_ACTION_BOOKING, GA_EVENT_CATEGORY_BOOKING);
      }
    }

    return onSuccess(response);
  };

  dispatch(apiPostDispatchable(getNewBookingUrl(), postData, handleBookingSuccess, onFailure));
};

const rescheduleBookingDispatchable = (
  bookingId: string,
  newBooking: {
    appointment_date: string;
    newExternalSlot?: ExternalSlot & { appointmentReason: AppointmentReason };
  },
  onSuccess: () => void,
  onError: (errorMessage: string) => void
) => {
  const postUrl = getRescheduleBookingUrl();
  const { appointment_date, newExternalSlot } = newBooking;

  const postData: ReschedulePostData = {
    booking_id: bookingId,
    appointment_date: dateFormat(appointment_date, 'isoUtcDateTime'),
    // only for external integration bookings
    ...(newExternalSlot && {
      new_booking: {
        appointment_id: newExternalSlot.appointment_id,
        reason_id: newExternalSlot.appointmentReason.reason_id,
        external_department_id: newExternalSlot.external_department_id,
        external_practice_id: newExternalSlot.external_practice_id,
      },
    }),
  };

  if (getAccountId()) {
    postData.author_type = AUTHOR_TYPE_ACCOUNT;
    postData.author_id = getAccountId() ?? undefined;
  }

  return apiPostDispatchable(postUrl, postData, onSuccess, onError);
};

export {
  getAddBackupTimeUrl,
  getNewBookingUrl,
  buildBookingPostData,
  postBooking,
  rescheduleBookingDispatchable,
  getBookingById,
  getRescheduleBookingUrl,
  getPublicBookingById,
};
