/**
 * Checks if typeof window !== 'undefined'.
 *
 * @returns isOnClient {boolean} is the code operating on a client
 */
const isClientSide = () => typeof window !== 'undefined';

/**
 * Is code operating on server
 *
 * @returns isOnServer {boolean} is the code operating on the node server
 */
const isServerSide = () => !isClientSide();

const getFlag = (flagName) => (isServerSide() ? process.env : window.CFG)[flagName];

const isIframed = () => {
  if (isClientSide()) {
    return window.location !== window.parent.location;
  }

  return false;
};

export { isClientSide, isServerSide, getFlag, isIframed };
