import { INSURERS_RECEIVED } from '../constants/index';

export type InsurersState = InsuranceCarrier[];

export interface InsuranceCarrier {
  display_name: string;
  id: string;
  insurer_code: string;
  name: string;
}

const initialState: InsuranceCarrier[] = [];

export default function insurersReducer(state = initialState, action: any): InsurersState {
  switch (action.type) {
    case INSURERS_RECEIVED: {
      if (action.payload.value.hasOwnProperty('results')) {
        return action.payload.value.results;
      }

      return [action.payload.value];
    }

    default:
      return state;
  }
}
