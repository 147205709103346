import { BaseClickable, Stack } from '@solvhealth/jigsaw';
import React, { ComponentProps } from 'react';

const SolvPlusLogo = (
  props: ComponentProps<typeof BaseClickable> & {
    height?: string | number;
    darkLogo?: boolean;
  }
) => {
  const srcPath = props.darkLogo
    ? '/images/membership/plus-logo-full-teal-dark.svg'
    : '/images/membership/logo_full.svg';

  return (
    <BaseClickable {...props}>
      <Stack {...props} alignItems="center" row space={0.5}>
        <img alt="Solv Plus Logo" height={props.height ?? 24} src={srcPath} />
      </Stack>
    </BaseClickable>
  );
};

export default SolvPlusLogo;
