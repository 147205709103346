import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  width: 80%;
  height: 60%;
`;

const Person = styled.img`
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
`;

type Props = {
  className?: string;
};

const RainMan = (props: Props) => (
  <Root className={props?.className}>
    <Person
      alt="person holding umbrella in the rain"
      src="/heroku/mapp/person_holding_umbrella_in_rain.png"
    />
  </Root>
);

export default RainMan;
