import { BookingStatus } from '../../constants/index';
import { isEmptyObject } from '../../core/util/object';

const isWaitlistVisibleForBooking = (booking: any) =>
  isEmptyObject(booking) ||
  [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.IN_EXAM_ROOM].includes(
    booking.status
  );

const isPreAppointment = (booking: any) =>
  [BookingStatus.PENDING, BookingStatus.RESERVED].includes(booking.status);

const isPostAppointment = (booking: any) =>
  [BookingStatus.DISCHARGED, BookingStatus.CANCELLED, BookingStatus.NO_SHOW].includes(
    booking.status
  );

const getWaitListBooking = (waitList: any) => {
  if (isEmptyObject(waitList.waitListBooking)) {
    return null;
  }

  return waitList.waitListBooking.booking;
};

export { isWaitlistVisibleForBooking, isPreAppointment, isPostAppointment, getWaitListBooking };
