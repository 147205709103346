/* eslint-disable max-len */
/* jscs:disable maximumLineLength */
import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { isDatabaseBirthDate } from '../../core/util/time';
import { isDev } from '../../config/index';
import { EN, SupportedLocale } from '../../core/util/localizedStr';
import { capitalCase, toUpperCase } from '../../core/util/string';

const monthNumber = 'M';
const monthAbbv = 'MMM';
const month = 'MMMM';
const dayNumber = 'D';
const dayAbbv = 'ddd';
export const dayFull = 'dddd';
const dayOrd = 'Do';
const yearNumber = 'YYYY';
const hour = 'h';
const minute = 'mm';
const period = 'a';
const timeZone = 'z';
const _hour = 'H';
const seconds = 'ss';

export const MONTH_DAY_YEAR = `${monthNumber}/${dayNumber}/${yearNumber}`;
export const MONTH_YEAR = `${month} ${yearNumber}`;
export const HOUR_MINUTE = `${hour}:${minute}`;
export const PERIOD_TIME_ZONE = `${period} ${timeZone}`;
export const FULL_MONTH_DAY_COMMA_YEAR = `${month} ${dayNumber}, ${yearNumber}`;

export const DAY_ABBV_MONTH_DAY_YEAR = `${dayAbbv}, ${MONTH_DAY_YEAR}`;
export const DAY_ABBV_MONTH_ABBV_DAYORD = `${dayAbbv}, ${monthAbbv} ${dayOrd}`;
export const HOUR_MINUTE_PERIOD = `${HOUR_MINUTE} ${period}`;

export const DAY_ABBV_HOUR_MINUTE_PERIOD = `${dayAbbv}, ${HOUR_MINUTE_PERIOD}`;

export const MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD = `${MONTH_DAY_YEAR} ${HOUR_MINUTE} ${period}`;
export const MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD_TIME_ZONE = `${MONTH_DAY_YEAR} ${HOUR_MINUTE} ${PERIOD_TIME_ZONE}`;

export const MONTH_NAME_DAY_YEAR = 'LL';

export const MONTH_ABBV_DAY = `${monthAbbv} ${dayNumber}`;
export const MONTH_ABBV_DAY_ORD = `${monthAbbv} ${dayOrd}`;
export const MONTH_ABBV_DAY_YEAR = `${monthAbbv} ${dayNumber}, ${yearNumber}`;
export const DAY_ABBV_MONTH_ABBV_DAY_YEAR = `${dayAbbv}, ${MONTH_ABBV_DAY_YEAR}`;
export const DAY_ABBV_MONTH_DAY = `${dayAbbv}, ${MONTH_ABBV_DAY}`;
export const DAY_ABBV_MONTH_ABBV_DAY_TIME = `${DAY_ABBV_MONTH_DAY} \\at ${HOUR_MINUTE_PERIOD}`;
export const DAY_ABBV_MONTH_ABBV_DAY_YEAR_TIME = `${dayAbbv}, ${MONTH_ABBV_DAY_YEAR} \\at ${HOUR_MINUTE_PERIOD}`;
export const DAY_FULL_MONTH_DAY_NUMBER_YEAR = `${dayFull}, ${month} ${dayNumber}, ${yearNumber}`;
export const HOUR_MINUTE_PERIOD_DAY_MONTH_DAY_NUMBER = 'h:mm a  |  ddd, MMM D';
export const DAY_FULL_MONTH_DAY_NUMBER = `${dayFull}, ${month} ${dayNumber}`;
export const HOUR_HOUR_MINUTE = `${_hour}${_hour}:${minute}`;
export const HOUR_HOUR_MINUTE_SECONDS = `${HOUR_HOUR_MINUTE}:${seconds}`;
export const HOUR_MINUTE_PERIOD_TIME_ZONE = `${HOUR_MINUTE} ${PERIOD_TIME_ZONE}`;
// Mon Dec 7
export const DAY_ABBV_MONTH_ABBV_DAY_NUMBER = `${dayAbbv} ${monthAbbv} ${dayNumber}`;
// Mon, Dec 7
export const DAY_ABBV_MONTH_ABBV_DAY_NUMBER_WITH_COMMA = `${dayAbbv}, ${monthAbbv} ${dayNumber}`;

export const APPOINTMENT_TIME_WITH_TIME_ZONE = MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD_TIME_ZONE;
export const BIRTH_DATE = MONTH_ABBV_DAY_YEAR;
export const PERIOD = period;

export const MONTH_DAY_ORDINAL = `${month} ${dayOrd}`;
export const MONTH_DAY_ORD_AT_HOUR_MINUTE_PERIOD = `${MONTH_DAY_ORDINAL} [at] ${HOUR_MINUTE_PERIOD}`;
export const YEAR_MONTH_DAY_DASHED = `${yearNumber}-MM-DD`;
const DATABASE_BIRTH_DATE_FORMAT = YEAR_MONTH_DAY_DASHED;

export const ES_DAY_NUM_OF_MONTH = `${dayNumber} [de] ${month}`;
export const ES_DAY_NUM_OF_MONTH_AT_HOUR_MINUTE_PERIOD = `${ES_DAY_NUM_OF_MONTH} [a las] ${HOUR_MINUTE_PERIOD}`;
export const ES_DAY_FULL_DAY_NUMBER_MONTH = `${dayFull}, ${ES_DAY_NUM_OF_MONTH}`;

interface Options {
  hideTimeZoneIfSame?: boolean;
  parseFormat?: string;
  locale?: SupportedLocale;
}

const defaultOptions: Options = {
  hideTimeZoneIfSame: true,
  locale: EN,
};

const defaultProps = {
  format: MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD,
  options: defaultOptions,
  Wrapper: 'div',
  capitalize: false,
  uppercase: false,
};

type DateInput = moment.Moment | Date | string | number;

type Props = {
  dateTime: DateInput;
  format?: string;
  options?: any;
  timeZone?: string;
  Wrapper: string | React.ComponentType<any>;
  locale?: SupportedLocale;
} & typeof defaultProps;

class DateTime extends PureComponent<Props> {
  static defaultProps = defaultProps;

  static format =
    (dateTime: any, timeZoneIdentifier?: any, options: Options = defaultOptions) =>
    (format: string) => {
      let newDateTime = null;

      if (isDatabaseBirthDate(dateTime)) {
        newDateTime = moment(dateTime, DATABASE_BIRTH_DATE_FORMAT);
      } else if (options.parseFormat) {
        newDateTime = timeZoneIdentifier
          ? moment.tz(dateTime, options.parseFormat, timeZoneIdentifier)
          : moment(dateTime, options.parseFormat);
      } else {
        newDateTime = timeZoneIdentifier
          ? moment.tz(dateTime, timeZoneIdentifier)
          : moment(dateTime);
      }

      if (!newDateTime.isValid()) {
        if (isDev()) {
          throw new Error('Moment did not like the dateTime provided to DateTime.format');
        } else {
          return null;
        }
      }

      return newDateTime.locale(options.locale ?? defaultOptions.locale!).format(
        DateTime.getFormat(format, timeZoneIdentifier, {
          hideTimeZoneIfSame: options.hideTimeZoneIfSame ?? defaultOptions.hideTimeZoneIfSame,
        })
      );
    };

  static getFormat = (format: any, timeZoneIdentifier: any, options: any) => {
    const userTimeZoneIdentifier = moment.tz.guess();

    if (options.hideTimeZoneIfSame && userTimeZoneIdentifier === timeZoneIdentifier) {
      return format.replace(` ${timeZone}`, '');
    }

    return format;
  };

  render = () => {
    const ComponentWrapper = this.props.Wrapper;

    let formattedDateTime = DateTime.format(this.props.dateTime, this.props.timeZone, {
      ...this.props.options,
      ...(!!this.props.locale && { locale: this.props.locale }),
    })(this.props.format);

    if (this.props.uppercase) {
      formattedDateTime = toUpperCase(
        formattedDateTime || '',
        this.props.locale ?? this.props.options?.locale ?? defaultOptions.locale
      );
    } else if (this.props.capitalize) {
      formattedDateTime = capitalCase(formattedDateTime || '', {
        locale: this.props.locale ?? this.props.options?.locale ?? defaultOptions.locale,
      });
    }

    return <ComponentWrapper>{formattedDateTime}</ComponentWrapper>;
  };
}

export default DateTime;
