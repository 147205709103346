export const RECEIVE_PHOTO_ID_FRONT_ID = 'photoId/RECEIVE_PHOTO_ID_FRONT_ID';
export const RECEIVE_PHOTO_ID_BACK_ID = 'photoId/RECEIVE_PHOTO_ID_BACK_ID';
export const SET_PHOTO_ID_FRONT_IMAGE_DATA = 'photoId/SET_PHOTO_ID_FRONT_IMAGE_DATA';
export const SET_PHOTO_ID_BACK_IMAGE_DATA = 'photoId/SET_PHOTO_ID_BACK_IMAGE_DATA';
export const UPLOADING_PHOTO_ID = 'photoId/UPLOADING';
export const RESET_PHOTO_ID = 'photoId/RESET_PHOTO_ID';

export type PhotoIdState = {
  front?: {
    id?: string;
    imageData?: string;
  };

  back?: {
    id?: string;
    imageData?: string;
  };

  loading?: boolean;
};

export const uploadingPhotoId = (value: any) => {
  return {
    type: UPLOADING_PHOTO_ID,
    payload: { value },
  };
};

export const receiveFrontPhotoId = (value: any) => {
  return {
    type: RECEIVE_PHOTO_ID_FRONT_ID,
    payload: { value },
  };
};

export const receiveBackPhotoId = (value: any) => {
  return {
    type: RECEIVE_PHOTO_ID_BACK_ID,
    payload: { value },
  };
};

export const setPhotoIdFrontImageData = (value: any) => {
  return {
    type: SET_PHOTO_ID_FRONT_IMAGE_DATA,
    payload: { value },
  };
};

export const setPhotoIdBackImageData = (value: any) => {
  return {
    type: SET_PHOTO_ID_BACK_IMAGE_DATA,
    payload: { value },
  };
};

export const resetPhotoId = () => {
  return {
    type: RESET_PHOTO_ID,
  };
};

export default (state = { front: {}, back: {} }, action: any) => {
  switch (action.type) {
    case RECEIVE_PHOTO_ID_FRONT_ID: {
      return {
        ...state,
        front: {
          ...state.front,
          id: action.payload.value,
        },
      };
    }

    case RECEIVE_PHOTO_ID_BACK_ID: {
      return {
        ...state,
        back: {
          ...state.back,
          id: action.payload.value,
        },
      };
    }

    case SET_PHOTO_ID_FRONT_IMAGE_DATA: {
      return {
        ...state,
        front: {
          ...state.front,
          imageData: action.payload.value,
        },
      };
    }

    case SET_PHOTO_ID_BACK_IMAGE_DATA: {
      return {
        ...state,
        back: {
          ...state.back,
          imageData: action.payload.value,
        },
      };
    }

    case UPLOADING_PHOTO_ID: {
      return {
        ...state,
        loading: action.payload.value,
      };
    }

    case RESET_PHOTO_ID: {
      return {};
    }

    default:
      return state;
  }
};
