import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { SYMPTOM_GET_911_MESSAGE } from '../../core/analytics/events';
import { analyticsTrackEvent } from '../../core/analytics';
import { BIRTH_DATE_FORMAT } from '../../config/index';
import { isValidDateString } from './date';
import { EMPTY_STRING } from '../../core/util/string';

const getReasonForVisit = (reasonForVisit: any) => {
  if (reasonForVisit instanceof Array) {
    return reasonForVisit.map((rfv) => rfv.value).join(', ');
  }

  return reasonForVisit || EMPTY_STRING;
};

const filterReduxFormInput = (data: any) => {
  const filtered = { ...data };
  delete filtered.autofilled;
  delete filtered.dirty;
  delete filtered.error;
  delete filtered.initialValue;
  delete filtered.invalid;
  delete filtered.pristine;
  delete filtered.touched;
  delete filtered.valid;
  delete filtered.autofill;
  delete filtered.onUpdate;
  delete filtered.active;
  delete filtered.visited;
  return filtered;
};

const deleteNullFields = (form: any) => {
  const newForm = form;
  for (const field in newForm) {
    if (!newForm[field] || newForm[field] === null) {
      delete newForm[field];
    } else {
      if (newForm[field].hasOwnProperty('touched')) {
        delete newForm[field].touched;
      }
    }
  }

  return newForm;
};

const sanitizeForm = (form: any) => {
  const newForm = form;
  for (const field in newForm) {
    if (newForm[field].hasOwnProperty('touched')) {
      delete newForm[field].touched;
    }
  }

  return newForm;
};

const handle911Message = () => analyticsTrackEvent(SYMPTOM_GET_911_MESSAGE);

const checkValidDateOfBirth = (monthName = 'MM', day = 'DD', year = 'YYYY') => {
  const month = moment().month(monthName).format('M');
  const date = `${year}-${month}-${day}`;

  if (!isValidDateString(date)) {
    return 'Invalid Date of Birth';
  }

  const parsedDate = moment(date, BIRTH_DATE_FORMAT);
  if (!parsedDate.isValid()) {
    return 'Invalid Date of Birth';
  }

  if (parsedDate > moment()) {
    return 'Birth date cannot be in the future';
  }

  const lowerDateBoundary = moment().subtract(120, 'year');
  if (parsedDate < lowerDateBoundary) {
    return 'Birth date cannot be more than 120 years ago';
  }

  return '';
};

const validateDob = (month: any, day: any, year: any) => {
  const dobFieldsErrors = {};
  const error = checkValidDateOfBirth(month, day, year);
  if (error.length !== 0) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dobMonth' does not exist on type '{}'.
    dobFieldsErrors.dobMonth = error;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dobDay' does not exist on type '{}'.
    dobFieldsErrors.dobDay = error;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dobYear' does not exist on type '{}'.
    dobFieldsErrors.dobYear = error;
  }

  return dobFieldsErrors;
};

const validateSex = (value: any) => {
  const sexErrors = {};
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'birthSex' does not exist on type '{}'.
  if (value === '') sexErrors.birthSex = 'Please select sex';

  return sexErrors;
};

const shouldDisableButton = (form: any, requiredFieldNames: string[] | undefined = undefined) => {
  const values = Object.values(form.getFieldsValue(requiredFieldNames));
  const errors = Object.values(form.getFieldsError(requiredFieldNames));

  return values.some(isEmpty) || errors.some((error) => error);
};

export {
  filterReduxFormInput,
  getReasonForVisit,
  handle911Message,
  deleteNullFields,
  sanitizeForm,
  validateDob,
  validateSex,
  shouldDisableButton,
};
