export const ELIGIBILITY_REJECT_REASON_MEMBER_NOT_FOUND = 'member_not_found';
export const ELIGIBILITY_REJECT_REASON_CARRIER_DOWN = 'carrier_down';

export class InsurerCodes {
  static AETNA = 'aetna';

  static AMBETTER = 'ambetter';

  static AMERIGROUP = 'amerigroup';

  static ANTHEM = 'anthem';

  static BCBS = 'bcbs';

  static BLUE_CROSS = 'blue_cross';

  static BLUE_SHIELD = 'blue_shield';

  static BLUE_SHIELD_CA = 'blue_shield_ca';

  static BLUE_SHIELD_TX = 'blue_shield_tx';

  static CIGNA = 'cigna';

  static HUMANA = 'humana';

  static SUPERIOR_HEALTH_PLAN = 'superior_health_plan';

  static UNITED_HEALTH_CARE = 'united_health_care';
}

export class InsuranceCarrierPhoneNumbers {
  static AETNA = '1-800-872-3862';

  static AMBETTER = '1-800-955-8770';

  static AMERIGROUP = '1-800-600-4441';

  static ANTHEM = '1-866-461-3585';

  static BLUE_CROSS_BLUE_SHIELD = '1-888-630-2583';

  static CIGNA = '1-800-244-6224';

  static HUMANA = '1-800-457-4708';

  static SUPERIOR_HEALTH_PLAN = '1-844-590-4883';

  static UNITED_HEALTH_CARE = '1-888-545-5205';
}

export class InsuranceBenefitsPhoneNumbers {
  static AETNA = '1-800-556-1555';
}

export class InsuranceCarrierDisplayNames {
  static AETNA = 'Aetna';

  static AMBETTER = 'Ambetter';

  static AMERIGROUP = 'Amerigroup';

  static ANTHEM = 'Anthem';

  static BLUE_CROSS_BLUE_SHIELD = 'Blue Cross Blue Shield';

  static CIGNA = 'Cigna';

  static HUMANA = 'Humana';

  static SUPERIOR_HEALTH_PLAN = 'Superior Health Plan';

  static UNITED_HEALTH_CARE = 'United Health Care';
}

export class InsuranceBenefitStateTypes {
  static NO_DEDUCTIBLE = 'no_deductible';

  static UNMET_DEDUCTIBLE = 'unmet_deductible';

  static MET_DEDUCTIBLE = 'met_deductible';

  static MET_OOP_MAX = 'met_oop_max';
}
