import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Star from './Star';
import { colors } from '../../../constants/colors';

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ length: number; }' is missing the followin... Remove this comment to see the full error message
const STARS = Array.apply(null, { length: 5 });
const Root = styled.div<{ $spread?: boolean }>`
  white-space: nowrap;

  ${(props) =>
    props.$spread &&
    `display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: inherit;`};
`;

type OwnProps = {
  identifier: string;
  rating: number | string;
  size?: number | string;
  className?: string;
  primaryColor?: string;
  secondaryColor?: string;
  spacing?: string;
  spread?: boolean;
};

type Props = OwnProps & typeof StarRating.defaultProps;

export default class StarRating extends PureComponent<Props> {
  static defaultProps = {
    primaryColor: colors.peaches,
    secondaryColor: colors.borderLine,
    spacing: '2px',
  };

  // @ts-expect-error ts-migrate(2345) FIXME: Type 'number' is not assignable to type 'string'.
  rating = parseFloat(this.props.rating) || 0;

  getFillPercentage = (pos: any) => Math.round(Math.max(Math.min(this.rating - pos, 1), 0) * 100);

  render = () => {
    const {
      className,
      size,
      identifier,
      primaryColor,
      secondaryColor,
      spacing,
      spread = false,
    } = this.props;
    const id = identifier.replace(/'|\s/g, '');
    return (
      <Root $spread={this.props.spread} className={className}>
        {STARS.map((_, pos) => (
          <Star
            identifier={id}
            key={pos}
            percentFilled={this.getFillPercentage(pos)}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            // @ts-expect-error ts-migrate(2769) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
            size={size}
            spacing={spacing}
          />
        ))}
      </Root>
    );
  };
}
