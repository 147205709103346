const RECEIVE_UNIVERSAL_SEARCH_QUERY = 'universalSearch/RECEIVE_QUERY';
const SET_ACTIVE_UNIVERSAL_QUERY = 'universalSearch/SET_ACTIVE_QUERY';
const SET_DISPLAY_UNIVERSAL_QUERY = 'universalSearch/SET_DISPLAY_QUERY';
const SET_UNIVERSAL_SEARCH_DOC = 'universalSearch/SET_DOCUMENT';
const CLEAR_UNIVERSAL_SEARCH_DOC = 'universalSearch/CLEAR_DOCUMENT';

export type UniversalSearchState = {
  results: any[];
  action: any;
  selectedDoc?: any;
  query?: string;
  displayQuery?: string;
  lastDispatchTime?: Date;
};

export const receiveUniversalResults = (value: any) => ({
  type: RECEIVE_UNIVERSAL_SEARCH_QUERY,
  payload: { value },
});

export const setUniversalSearchDoc = (value: any) => ({
  type: SET_UNIVERSAL_SEARCH_DOC,
  payload: { value },
});

export const clearUniversalSearchDoc = () => ({
  type: CLEAR_UNIVERSAL_SEARCH_DOC,
});

export const setActiveQuery = (value: any) => ({
  type: SET_ACTIVE_UNIVERSAL_QUERY,
  payload: { value },
});

export const setDisplayQuery = (value: any) => ({
  type: SET_DISPLAY_UNIVERSAL_QUERY,
  payload: { value: value && value.replace(/"/g, '') },
});

export default (state: UniversalSearchState = { results: [], action: {} }, action: any) => {
  switch (action.type) {
    default:
      return state;
    case RECEIVE_UNIVERSAL_SEARCH_QUERY:
      return {
        ...state,
        ...action.payload.value,
      };
    case SET_UNIVERSAL_SEARCH_DOC:
      return {
        ...state,
        selectedDoc: action.payload.value,
        query: action.payload.value.highlight.replace(/<em>|<\/em>|"/g, ''),
        displayQuery: action.payload.value.highlight.replace(/<em>|<\/em>|"/g, ''),
      };
    case CLEAR_UNIVERSAL_SEARCH_DOC:
      return {
        ...state,
        selectedDoc: null,
      };
    case SET_ACTIVE_UNIVERSAL_QUERY:
      return {
        ...state,
        query: action.payload.value,
        lastDispatchTime: +new Date(),
      };
    case SET_DISPLAY_UNIVERSAL_QUERY:
      return {
        ...state,
        displayQuery: action.payload.value,
      };
  }
};
