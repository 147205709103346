export const UNFREEZE_TIME_MINUTES = 2;
export const TEST_PHONE_NUMBER = '4150000000';

// DAPI Login error codes
export const LOGIN_RESPONSE_MAX_ATTEMPTS_EXCEEDED = 'Maximum attempts exceeded';
export const LOGIN_RESPONSE_WRONG_OTP = 'Invalid code: wrong code';

export const PHONE_FIELD_NAME = 'phone';
export const CODE_FIELD_NAME = 'code';
export const PINK_DOOR_ACCEPTED_FIELD_NAME = 'pinkDoorAccepted';
export const TOS_ACCEPTED_FIELD_NAME = 'tosAccepted';
