import { DAPI_HOST, DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config/index';
import { apiPostDispatchable } from '../../core/dapi';
import logger from '../logger/index';
import { PERSISTED_LOGIN_ID } from '../../components/SignUpForm/Fields/ids';

const getLoginUrl = () => `${DAPI_HOST}/v1/auth/token`;
const getRemoteValidatePhoneUrl = (phone: any) => `${DAPI_HOST}/v1/validate/phone/${phone}`;
const getGenerateOneTimePasswordUrl = () => `${DAPI_HOST}/v1/otp/generate`;

const buildLoginByOtpPostData = (props: any) => ({
  client_id: DAPI_CLIENT_ID,
  client_secret: DAPI_CLIENT_SECRET,
  phone: props.phone,
  code: props.code,
  persist: props[PERSISTED_LOGIN_ID],
  grant_type: 'otp_credentials',
});

const buildAutoLoginFromWaitlistPostData = (props: any) => ({
  client_id: DAPI_CLIENT_ID,
  client_secret: DAPI_CLIENT_SECRET,
  booking_id: props.booking_id,
  source: props.source,
  grant_type: 'waitlist_autologin_credentials',
});

const buildLoginRefresh = (refreshToken: any) => ({
  client_id: DAPI_CLIENT_ID,
  client_secret: DAPI_CLIENT_SECRET,
  grant_type: 'refresh_token',
  refresh_token: refreshToken,
});

const postLoginDispatchable = (props: any, onSuccess: any, onFailure: any, loginType = 'otp') => {
  let postData = '';
  if (loginType === 'otp') {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ client_id: string; client_secret: string |... Remove this comment to see the full error message
    postData = buildLoginByOtpPostData(props);
  } else if (loginType === 'waitlist') {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ client_id: string; client_secret: string |... Remove this comment to see the full error message
    postData = buildAutoLoginFromWaitlistPostData(props);
  }

  return (dispatch: any) => {
    dispatch(apiPostDispatchable(getLoginUrl(), postData, onSuccess, onFailure));
  };
};

const postLoginRefresh = (session: any, onSuccess: any, onFailure: any) => {
  const postData = buildLoginRefresh(session.refresh_token);

  return (dispatch: any) => {
    logger.debug('Submitting Refresh');
    logger.debug(postData);
    dispatch(apiPostDispatchable(getLoginUrl(), postData, onSuccess, onFailure));
  };
};

const getAuthTokenUrl = () => `${DAPI_HOST}/v1/auth/token`;

export {
  postLoginRefresh,
  postLoginDispatchable,
  buildLoginByOtpPostData,
  getRemoteValidatePhoneUrl,
  getGenerateOneTimePasswordUrl,
  getAuthTokenUrl,
};
