// "Obfuscated" routes
import { isPhotoIdUploadEnabledForEntrypoint } from './location';
import { getNextRouteForAfterPhotoIdUpload } from './videoVisit';
import { getNextRouteForMobileCheckInFlowAfterPhotoIdUpload } from './mobileCheckIn';

export const ADDITIONAL_PATIENTS_ORIGIN_BOOKING_WIDGET = 'apbw';
export const ADD_INSURANCE_ORIGIN_APP_BOOKING = 'ab';
export const ADD_INSURANCE_ORIGIN_USER_PROFILE = 'up';
export const ADD_INSURANCE_ORIGIN_BOOKING_WIDGET = 'bw';
export type AddInsuranceOrigins =
  | typeof ADD_INSURANCE_ORIGIN_USER_PROFILE
  | typeof ADD_INSURANCE_ORIGIN_APP_BOOKING
  | typeof ADD_INSURANCE_ORIGIN_BOOKING_WIDGET;
export const PHOTO_ID_UPLOAD_ORIGIN_MOBILE_CHECK_IN = 'mc';
export const PHOTO_ID_UPLOAD_ORIGIN_PAPERWORK = 'pw';
export const PHOTO_ID_UPLOAD_ORIGIN_VIDEO_VISIT = 'vv';
export const PHOTO_ID_UPLOAD_ORIGIN_GENERIC_RETURN = 'next';
export const PHOTO_ID_UPLOAD_ORIGIN_BOOKING_WIDGET = 'bw';

export const BOOKING_FORM_PROFILE_ORIGIN_SRP = 's';
export const BOOKING_FORM_PROFILE_ORIGIN_CDP = 'c';
export const BOOKING_FORM_PROFILE_ORIGIN_PROVIDER = 'p';

// "Obfuscated" query params
const USER_PROFILE_ID_PARAM = 'p';

// Next routes
export const FINALIZE_PAGE = '/finalize';
export const SUBMIT_BOOKING_PAGE = '/submitBooking';
const USER_PROFILE_PAGE_BASE_PATH = '/account/home';
const HOME_PAGE = '/';
export const PHOTO_ID_UPLOAD = '/photo-id-upload';

const getUserProfilePageUrl = (queryParams: any) => {
  const userProfileId = queryParams[USER_PROFILE_ID_PARAM];
  return `${USER_PROFILE_PAGE_BASE_PATH}/${userProfileId}/insurance`;
};

export const getNextRouteAfterInsurance = (location: any, origin: any, queryParams: any) => {
  if (origin === ADD_INSURANCE_ORIGIN_USER_PROFILE) {
    return getUserProfilePageUrl(queryParams);
  }

  switch (origin) {
    case ADD_INSURANCE_ORIGIN_APP_BOOKING:
      if (isPhotoIdUploadEnabledForEntrypoint(location, 'solvAttributedBooking')) {
        return `${PHOTO_ID_UPLOAD}/${origin}`;
      }
      return FINALIZE_PAGE;
    case ADD_INSURANCE_ORIGIN_BOOKING_WIDGET:
      if (isPhotoIdUploadEnabledForEntrypoint(location, 'bookingWidget')) {
        return `${PHOTO_ID_UPLOAD}/${origin}`;
      }
      return SUBMIT_BOOKING_PAGE;
    default:
      return HOME_PAGE;
  }
};

export const getNextRouteAfterPhotoIdUpload = (
  origin: any,
  queryParams: any,
  booking: any,
  location: any,
  userProfile: any
) => {
  // A couple of these helpers will throw an error if booking, location, or userProfile are not defined
  // So rather than add conditionals to each of them, let's add a fallback for those
  // edge cases
  try {
    switch (origin) {
      case ADD_INSURANCE_ORIGIN_APP_BOOKING:
        return FINALIZE_PAGE;
      case ADD_INSURANCE_ORIGIN_BOOKING_WIDGET:
        return SUBMIT_BOOKING_PAGE;
      case ADD_INSURANCE_ORIGIN_USER_PROFILE:
        return getUserProfilePageUrl(queryParams);
      case PHOTO_ID_UPLOAD_ORIGIN_PAPERWORK:
        return queryParams.next;
      case PHOTO_ID_UPLOAD_ORIGIN_VIDEO_VISIT:
        return getNextRouteForAfterPhotoIdUpload(booking, location, userProfile);
      case PHOTO_ID_UPLOAD_ORIGIN_MOBILE_CHECK_IN:
        return getNextRouteForMobileCheckInFlowAfterPhotoIdUpload(booking, location, userProfile);
      case PHOTO_ID_UPLOAD_ORIGIN_GENERIC_RETURN:
        if (!queryParams.to) return HOME_PAGE;
        return queryParams.to;
      default:
        return HOME_PAGE;
    }
  } catch (e) {
    return HOME_PAGE;
  }
};
