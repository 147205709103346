import { Button, Stack, Text } from '@solvhealth/jigsaw';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsTrackEvent } from '~/core/analytics';
import {
  SOLV_PLUS_MODAL_ACTION,
  SOLV_PLUS_MODAL_ACTION_DISMISS,
  SOLV_PLUS_MODAL_ACTION_GET_CARE,
  SOLV_PLUS_MODAL_ACTION_LEARN_MORE,
} from '~/core/analytics/events';
import history from '~/core/history';
import useToggleRuntimeModal from '~/hooks/runtime/useToggleRuntimeModal';
import ResponsiveSlideInDialog from '../SolvDesignSystem/Dialog/ResponsiveSlideInDialog';
import {
  MEMBER_TELEMED_VISIT_COST_CENTS,
  MEMBERSHIP_NAME,
  MONTHLY_MEMBERSHIP_COST_STRING,
  PREMIUM_TELEMED_SLA_MINUTES,
} from './constants';
import { centsToCurrencyStr } from '~/core/util/currency';

export const MEMBERSHIP_PRODUCT_MODAL = 'membership-product-modal';

type Props = {
  forceOpen?: boolean;
};

const ModalContent = () => {
  const { t } = useTranslation('membership');
  const [_, toggle] = useToggleRuntimeModal(MEMBERSHIP_PRODUCT_MODAL);

  const goToPayment = () => {
    toggle();
    analyticsTrackEvent(SOLV_PLUS_MODAL_ACTION, {
      action: SOLV_PLUS_MODAL_ACTION_GET_CARE,
    });
    history.push(`/book?return=${window.location.pathname}${window.location.search}`);
  };

  const goToLandingPage = () => {
    toggle();
    analyticsTrackEvent(SOLV_PLUS_MODAL_ACTION, {
      action: SOLV_PLUS_MODAL_ACTION_LEARN_MORE,
    });
    history.push('/membership');
  };

  return (
    <Stack space={3}>
      <Stack space={2}>
        <Stack alignSelf="center" space={1}>
          <img
            alt="Solv Plus Providers"
            height={60}
            src="/images/membership/doctors.png"
            width={165}
          />
        </Stack>

        <Text pr={3} textAlign="center" variant="h3">
          {t('entrypoints.srp.title', {
            MEMBERSHIP_NAME,
            PREMIUM_TELEMED_SLA_MINUTES,
          })}
        </Text>
      </Stack>

      <Stack row space={2}>
        <img alt="Save time" height={40} src="/images/membership/entrypoint_time.svg" width={40} />
        <Stack>
          <Text variant="subtitle">{t('entrypoints.srp.time.title')}</Text>
          <Text display="inline-block" muted variant="body2">
            {t('entrypoints.srp.time.subtitle')}
          </Text>
        </Stack>
      </Stack>
      <Stack row space={2}>
        <img
          alt="Save money"
          height={40}
          src="/images/membership/entrypoint_money.svg"
          width={40}
        />
        <Stack>
          <Text variant="subtitle">{t('entrypoints.srp.cost.title')}</Text>
          <Text muted variant="body2">
            {t('entrypoints.srp.cost.subtitle', {
              TELEMED_VISIT_COST_STRING: centsToCurrencyStr(MEMBER_TELEMED_VISIT_COST_CENTS),
            })}
          </Text>
        </Stack>
      </Stack>

      <Stack
        bg="teal-xlight"
        borderRadius="base"
        justifyContent="space-between"
        px={2}
        py={1.5}
        row
      >
        <Text textColor="teal-dark">{t('entrypoints.productModal.fee')}</Text>
        <Text textColor="teal-dark">
          {t('entrypoints.productModal.justCost', {
            MONTHLY_MEMBERSHIP_COST_STRING,
          })}
        </Text>
      </Stack>

      <Stack space={1}>
        <Button fullWidth onClick={goToPayment}>
          {t('entrypoints.srp.cta')}
        </Button>
        <Button fullWidth onClick={goToLandingPage} variant="text">
          {t('entrypoints.productModal.learnMore')}
        </Button>
      </Stack>
    </Stack>
  );
};

const ProductModal = ({ forceOpen }: Props) => {
  const [open, toggle] = useToggleRuntimeModal(MEMBERSHIP_PRODUCT_MODAL);
  const close = () => {
    toggle();
    analyticsTrackEvent(SOLV_PLUS_MODAL_ACTION, {
      action: SOLV_PLUS_MODAL_ACTION_DISMISS,
    });
  };

  return (
    <ResponsiveSlideInDialog forceOpen={forceOpen} onClose={close} open={open}>
      <ModalContent />
    </ResponsiveSlideInDialog>
  );
};

export default ProductModal;
