import { call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  BIRTHDAY_ID,
  EMAIL_ID,
  FIRST_NAME_ID,
  LAST_NAME_ID,
  PHONE_ID,
} from '../components/SignUpForm/Fields/ids';
import { apiGet, apiPatchJson, apiPostJson } from '../core/dapi';
import { DAPI_HOST } from '../config/index';
import { convertSnakeToCamelCaseRecursive } from '../core/util/object';
import { getGenerateOneTimePasswordUrl } from '../core/dapi/login';
import {
  generateOtpFailure,
  generateOtpSuccess,
  saveSignUpPostData,
  setLoginAction,
} from '../actions/login';
import {
  LOGIN_ACTION_OTP_FORM,
  LOGIN_ACTION_SIGN_UP,
  LOGIN_ACTION_SIGN_UP_VERIFY,
} from '../constants/index';
import {
  receiveAccount,
  receiveAccountSummary,
  receiveUserProfileForAccount,
} from '../actions/account';
import { ACCOUNT_EXISTS_ERROR_DESCRIPTION, getAccountSummaryById } from '../core/dapi/account';
import { stripNonNumeric } from '../core/util/string';
import { analyticsTrackEvent } from '../core/analytics';
import {
  CROSSED_PINK_DOOR,
  CROSSED_PINK_DOOR_TYPE_SIGNUP,
  SIGN_UP_CREATE_ACCOUNT,
  SIGN_UP_DIRECT,
  SIGN_UP_PATCH,
} from '../core/analytics/events';
import { setRuntimeVariable } from '../actions/runtime';
import { isAndroidApp, isAndroidOS, isIOS, isIosApp } from '../core/util/device';
import { redirectAfterLogin } from '../core/login';
import { BIRTH_SEX_FIELD } from '~/components/SolvPatternLibrary/Form/BirthSexInput';

const SUBMIT_ACCOUNT = 'sagas/SUBMIT_ACCOUNT';
const FIRST_LOGIN_CREATE_ACCOUNT = 'sagas/FIRST_LOGIN_CREATE_ACCOUNT';
export const LOGIN_VERIFY_PHONE_SUBMIT = 'login/VERIFY_PHONE_SUBMIT';

function* handleAccountErrors(error: any) {
  console.error(error);
  const description = error && error[0] && error[0].description;

  if (description === ACCOUNT_EXISTS_ERROR_DESCRIPTION) {
    yield put(setLoginAction(LOGIN_ACTION_SIGN_UP_VERIFY));
  }
}

// eslint-disable-next-line camelcase

function* firstLogin({ phone, last_name }: any) {
  // jscs:disable
  try {
    const login = yield call(apiPostJson, getGenerateOneTimePasswordUrl(), {
      phone,
      last_name,
    }); // jscs:enable

    yield put(generateOtpSuccess(phone)(login));
    yield put(setLoginAction(LOGIN_ACTION_OTP_FORM));
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const accountSummary = select((state) => state.account.summary);

    redirectAfterLogin(accountSummary, null, false, true);
  } catch (e) {
    yield put(generateOtpFailure(e, phone));
  }
}

function* submitAccount({ form, isAuthorized, accountId }: any) {
  try {
    const phone = stripNonNumeric(String(form[PHONE_ID]));
    const postData = {
      first_name: form[FIRST_NAME_ID],
      last_name: form[LAST_NAME_ID],
      birth_date: form[BIRTHDAY_ID],
      birth_sex: form[BIRTH_SEX_FIELD],
      email: form[EMAIL_ID],
      is_ios_app_user: isIosApp(),
      is_android_app_user: isAndroidApp(),
      is_ios_user: isIOS(),
      is_android_user: isAndroidOS(),
      is_verified_phone: true,
      ...(!isAuthorized && { phone }),
    };

    yield put(saveSignUpPostData(postData));

    if (isAuthorized) {
      const url = `${DAPI_HOST}/v1/accounts/${accountId}/complete-signup`;
      const response = yield call(apiPatchJson, url, postData);
      // eslint-disable-next-line camelcase
      const { account, user_profile } = response.data;
      user_profile.id = user_profile.user_profile_id;

      // need to fetch the account summary now that we've completed sign-up
      const accountSummaryUrl = getAccountSummaryById(accountId);
      const accountSummaryResponse = yield call(apiGet, accountSummaryUrl);
      yield put(receiveAccountSummary(accountSummaryResponse));

      yield put(receiveAccount(account));
      yield put(receiveUserProfileForAccount(user_profile));
      analyticsTrackEvent(SIGN_UP_CREATE_ACCOUNT, {
        account_id: account.idHash,
        sign_up_type: SIGN_UP_PATCH,
      });
      yield put(setRuntimeVariable({ name: 'tosAcceptanceDate', value: 'trust me' }));
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      redirectAfterLogin(account.summary, null, null, true);
    } else {
      const url = `${DAPI_HOST}/v1/registration/create-account`;
      const response = yield call(apiPostJson, url, postData);
      if (response.errors) {
        yield handleAccountErrors(response.errors);
        return;
      }
      const { account, userProfile } = convertSnakeToCamelCaseRecursive(response.data);
      yield put(receiveAccount(account));
      yield put(receiveUserProfileForAccount(userProfile));
      analyticsTrackEvent(SIGN_UP_CREATE_ACCOUNT, {
        account_id: account.idHash,
        sign_up_type: SIGN_UP_DIRECT,
      });
      yield put(setRuntimeVariable({ name: 'tosAcceptanceDate', value: 'trust me' }));
      yield firstLogin(postData);
    }
    analyticsTrackEvent(CROSSED_PINK_DOOR, {
      solv_user_date: moment(),
      type: CROSSED_PINK_DOOR_TYPE_SIGNUP,
    });
  } catch (e) {
    console.error(e);
    yield put(setLoginAction(LOGIN_ACTION_SIGN_UP));
  }
}

function* phoneVerifyLogin({ updatedPhone, signUpData }: any) {
  try {
    if (updatedPhone === signUpData.phone) {
      yield firstLogin(signUpData);
    } else {
      const form = {
        [FIRST_NAME_ID]: signUpData.first_name,
        [LAST_NAME_ID]: signUpData.last_name,
        [BIRTHDAY_ID]: signUpData.birth_date,
        [EMAIL_ID]: signUpData.email,
        [PHONE_ID]: updatedPhone,
      };
      yield submitAccount({ form });
    }
  } catch (e) {
    yield put(setLoginAction(LOGIN_ACTION_SIGN_UP));
  }
}

function* rootSaga() {
  yield takeLatest(SUBMIT_ACCOUNT, submitAccount);
  yield takeLatest(FIRST_LOGIN_CREATE_ACCOUNT, firstLogin);
  yield takeLatest(LOGIN_VERIFY_PHONE_SUBMIT, phoneVerifyLogin);
}

export { SUBMIT_ACCOUNT, rootSaga as default };
