import { call, all } from 'redux-saga/effects';
import { apiGet } from '../core/dapi';
import { range } from '../core/util/array';

function* apiGetAllPages({ url }: any) {
  const first = yield call(apiGet, url);

  if (first.errors) {
    return first;
  }

  const pageNumbers = range(2, Math.ceil(first.page.results_count / first.page.limit), 1);

  const rest = yield all(pageNumbers.map((n) => call(apiGet, `${url}&page=${n}`)));

  return {
    ...first,
    results: [first, ...rest].reduce((acc, val) => acc.concat(val.results), []),
  };
}

export { apiGetAllPages };
