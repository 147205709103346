import { EMPTY_OBJECT } from '../../core/util/object';

const SET_NUMBER_OF_BOOKINGS_AHEAD = 'videoVisit/SET_NUMBER_OF_BOOKINGS_AHEAD';
const SET_PROGRESS_BAR_STEPS = 'videoVisit/SET_PROGRESS_BAR_STEPS';

export const setNumberOfBookingsAhead = (value: any) => ({
  payload: { value },
  type: SET_NUMBER_OF_BOOKINGS_AHEAD,
});

export const setProgressBarSteps = (value: any) => ({
  type: SET_PROGRESS_BAR_STEPS,
  payload: { value },
});

export type VideoVisitState = Partial<{
  numberOfBookingsAhead: number;
  progressBarSteps: number;
}>;

export default (state = EMPTY_OBJECT, { payload, type }: any) => {
  switch (type) {
    case SET_NUMBER_OF_BOOKINGS_AHEAD:
      return {
        ...state,
        numberOfBookingsAhead: payload.value,
      };
    case SET_PROGRESS_BAR_STEPS:
      return {
        ...state,
        progressBarSteps: payload.value,
      };
    default:
      return state;
  }
};
