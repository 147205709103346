import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { symptomsSubmit } from '../../../actions/symptoms';
import { PATIENT_TYPE_ADULTS } from '../../../constants/index';
import { getReasonForVisit } from '../../../core/util/form';
import {
  clearUniversalSearchDoc,
  setActiveQuery,
  setDisplayQuery,
} from '../../../ducks/universalSearch';
import { REQUEST_UNIVERSAL_SEARCH } from '../../../sagas/universalSearch';
import { DENTIST_VALUE, URGENT_CARE_VALUE } from '../../Home/Tiles/ProviderTypes';
import FilterModal from './Filters/FilterModal';
import UserChoice from '~/components/UniversalSearch/UserChoice';

type ExportableFilterModalProps = {
  searchFilters?: object;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  modalOpen: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  closeModal?: func;
  universalSearch?: object;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  requestUniversalSearch?: func;
  newBooking?: object;
  position?: object;
  searchPreferences?: object;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  submitFilters?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setActiveQuery?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setDisplayQuery?: func;
  HeaderProps?: any; // TODO: shape(Header.propTypes)
  title: string;
  showSolvPlusEntrypoint?: boolean;
};

/*
 * The intent here is to provide an easy way to import and use the filter modal
 * without having to re-write the world. It's connected and can be used to make
 * searches!
 */
class ExportableFilterModal extends PureComponent<ExportableFilterModalProps> {
  shouldShowMarketplaceInsurerCarrierInput = () =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.searchFilters.providerType === DENTIST_VALUE;

  handleUniversalCancel = () => {
    const { setActiveQuery, universalSearch } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    setActiveQuery(universalSearch.displayQuery);
  };

  handleUniversalConfirm = (requestSearch: any) => {
    const { setDisplayQuery, universalSearch, requestUniversalSearch } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    setDisplayQuery(universalSearch.query);
    requestSearch && requestUniversalSearch();
  };

  render() {
    const {
      searchFilters,
      modalOpen,
      closeModal,
      universalSearch,
      requestUniversalSearch,
      newBooking,
      position,
      HeaderProps,
      title,
      searchPreferences,
      showSolvPlusEntrypoint,
    } = this.props;

    return (
      <>
        <FilterModal
          HeaderProps={HeaderProps}
          newBooking={newBooking}
          onClose={closeModal}
          onUniversalCancel={this.handleUniversalCancel}
          onUniversalConfirm={this.handleUniversalConfirm}
          open={!!modalOpen}
          position={position}
          requestUniversalSearch={requestUniversalSearch}
          searchFilters={searchFilters}
          searchPreferences={searchPreferences}
          showInsuranceInput={this.shouldShowMarketplaceInsurerCarrierInput()}
          showSolvPlusEntrypoint={showSolvPlusEntrypoint}
          title={title}
          universalSearch={universalSearch}
        />

        <UserChoice />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  searchFilters: {
    patientType: state.newBooking.booking.patientType || PATIENT_TYPE_ADULTS,
    appointmentTime: state.newBooking.booking.requestedAppointmentTime,
    reasonForVisit: getReasonForVisit(
      state.newBooking.booking.reasonForVisit ||
        state.newBooking.lastCreatedBookingInfo?.reasonForVisit
    ),
    location: state.searchPreferences.location,
    providerType: state.searchPreferences.providerType || URGENT_CARE_VALUE,
  },

  newBooking: state.newBooking,
  position: state.position,
  searchPreferences: state.searchPreferences,
  universalSearch: state.universalSearch,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveQuery: (query: any) => dispatch(setActiveQuery(query)),

  setDisplayQuery: (query: any) => {
    dispatch(setDisplayQuery(query));
    dispatch(clearUniversalSearchDoc());
  },

  submitFilters: (filters: any) => dispatch(symptomsSubmit(filters)),
  requestUniversalSearch: () => dispatch({ type: REQUEST_UNIVERSAL_SEARCH, fromSrp: false }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportableFilterModal);
