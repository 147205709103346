import { Reducer } from 'redux';

const SET_SESSION_TRACKING_PROPERTIES = 'sessionTrackingProperties/SET_PROPERTIES';

export const setSessionTrackingProperties = (context: any) => {
  const { ip, referrer, sessionID, userAgent } = context;
  const properties = { ip, referrer, sessionID, userAgent };

  return {
    type: SET_SESSION_TRACKING_PROPERTIES,
    payload: { value: properties },
  };
};

export interface SessionTrackingPropertiesState {
  ip: string;
  referrer: string;
  sessionID: string;
  userAgent: string;
}

const INITIAL_STATE = {
  ip: 'undefined',
  referrer: 'undefined',
  sessionID: 'undefined',
  userAgent: 'undefined',
};

const reducer: Reducer<SessionTrackingPropertiesState> = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_SESSION_TRACKING_PROPERTIES:
      return {
        ...state,
        ...action.payload.value,
      };
    default:
      return state;
  }
};

export default reducer;
