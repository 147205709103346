import { Check, Close } from '@material-ui/icons';
import { Button, SolvTheme, Stack, Text } from '@solvhealth/jigsaw';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import useToggleRuntimeModal from '~/hooks/runtime/useToggleRuntimeModal';
import ResponsiveSlideInDialog from '../SolvDesignSystem/Dialog/ResponsiveSlideInDialog';

export const VIDEO_VISIT_EXPLAINER_DIALOG = 'video-visit-explainer-dialog';

const Use = ({ children }: PropsWithChildren<{}>) => (
  <Stack alignItems="center" justifyContent="flex-start" row space={1}>
    <Check fontSize="small" htmlColor={SolvTheme.colors['gray-400']} />
    <Text>{children}</Text>
  </Stack>
);

const DontUse = ({ children }: PropsWithChildren<{}>) => (
  <Stack alignItems="center" justifyContent="flex-start" row space={1}>
    <Close fontSize="small" htmlColor={SolvTheme.colors['gray-400']} />
    <Text>{children}</Text>
  </Stack>
);

const VideoVisitExplainerDialog = () => {
  const { t } = useTranslation(['account', 'membership', 'common']);

  const [open, toggle] = useToggleRuntimeModal(VIDEO_VISIT_EXPLAINER_DIALOG);

  return (
    <ResponsiveSlideInDialog onClose={toggle} open={open}>
      <Stack px={2} space={3}>
        <Stack space={1.5}>
          <Text variant="h4">{t('account:getCareNow.videoVisitExplainer.use')}</Text>
          <Stack space={0.5}>
            <Use>{t('membership:reasonForVisit.reason.cold')}</Use>
            <Use>{t('membership:reasonForVisit.reason.cuts')}</Use>
            <Use>{t('membership:reasonForVisit.reason.rashes')}</Use>
            <Use>{t('membership:reasonForVisit.reason.allergies')}</Use>
            <Use>{t('membership:reasonForVisit.reason.prescriptions')}</Use>
            <Use>{t('membership:reasonForVisit.reason.uti')}</Use>
            <Use>{t('membership:reasonForVisit.reason.questions')}</Use>
          </Stack>
        </Stack>
        <Stack space={1.5}>
          <Text variant="h4">{t('account:getCareNow.videoVisitExplainer.dontUse')}</Text>
          <Stack space={0.5}>
            <DontUse>{t('membership:reasonForVisit.reason.headInjuries')}</DontUse>
            <DontUse>{t('membership:reasonForVisit.reason.sports')}</DontUse>
            <DontUse>{t('membership:reasonForVisit.reason.wellness')}</DontUse>
            <DontUse>{t('membership:reasonForVisit.reason.vaccine')}</DontUse>
            <DontUse>{t('membership:reasonForVisit.reason.controlledSubstances')}</DontUse>
          </Stack>
        </Stack>
        <Button fullWidth onClick={toggle}>
          {t('common:ok')}
        </Button>
      </Stack>
    </ResponsiveSlideInDialog>
  );
};

export default VideoVisitExplainerDialog;
