import moment, { Moment } from 'moment-timezone';
// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/dateformat` if it exists o... Remove this comment to see the full error message
import format from 'dateformat';
import { TFunction } from 'react-i18next';
import { isEmptyString } from './string';
import { opaqueLocation } from '../location/opaque';
import { BIRTH_DATE_FORMAT } from '../../config/index';
import DateTime, { HOUR_MINUTE_PERIOD, MONTH_DAY_ORDINAL } from '../../components/DateTime';
import { analyticsTrackEvent } from '../analytics';
import { NUMERIC_KEYPRESS_USED_FOR_MONTH_INPUT } from '../analytics/events';
import { TIMEZONE_ABBR_MAP_LONG_NAME, WeekDay } from '../../constants/index';
import { EN, SupportedLocale } from './localizedStr';
import { NewBookingState } from '~/reducers/newBooking';

const isSolvOpen = () => {
  const now = moment();

  const dayNameFormat = 'dddd';
  const dayName = moment(now).tz(opaqueLocation.timeZone).format(dayNameFormat);

  if (dayName in opaqueLocation.hours) {
    const solvOpens = moment.tz(opaqueLocation.timeZone);
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    const opensArray = opaqueLocation.hours[dayName][0].fromTime.split(':').map(Number);

    solvOpens.hours(opensArray[0]);
    solvOpens.minutes(opensArray[1]);
    solvOpens.seconds(opensArray[2]);
    solvOpens.milliseconds(0);

    const solvCloses = moment.tz(opaqueLocation.timeZone);
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    const closesArray = opaqueLocation.hours[dayName][0].toTime.split(':').map(Number);

    solvCloses.hours(closesArray[0]);
    solvCloses.minutes(closesArray[1]);
    solvCloses.seconds(closesArray[2]);
    solvCloses.milliseconds(0);

    return solvOpens.isSameOrBefore(now) && now.isSameOrBefore(solvCloses);
  }

  return false;
};

/**
 * Format date
 *
 * @param {number} date Unix timestamp
 * @param {string} formatString
 */
const dateFormat = (date: any, formatString: any) => format(new Date(date), formatString);

export const DAPI_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_DASH_FORMAT = 'MM-DD-YYYY';
export const DATE_SLASH_FORMAT = 'MM/DD/YYYY';
export const STANDARD_TIME_FORMAT = 'h:mmA';
export const DAPI_TIME_FORMAT = 'HH:mm';
export const NUMERIC_DATE_FORMAT = 'M/DD/YY';
export const ABBREVIATED_MONTH_DAY_FORMAT = 'MMM D'; // ex: Jul 19
export const MONTH_NAME_DAY_YEAR_FORMAT = 'MMMM D, YYYY'; // ex: July 19, 2021

/**
 * Because Safari is still terrible
 *
 * @param dateTimeString
 * @returns {number}
 */
const parseSqlDateTime = (dateTimeString: any) => {
  // 2016-08-01 21:00:00
  const parseRegex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;

  // see above
  let year = null;
  let month = null;
  let day = null;
  let hour = null;
  let minute = null;
  let second = null;

  dateTimeString.replace(
    parseRegex,
    (wholeMatch: any, $1: any, $2: any, $3: any, $4: any, $5: any, $6: any) => {
      year = $1;
      month = $2;
      day = $3;
      hour = $4;
      minute = $5;
      second = $6;
    }
  );

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  return new Date(year, month - 1, day, hour, minute, second).getTime();
};

/**
 * Because Still
 *
 * @param dateString
 * @returns {number}
 */
const parseSqlDate = (dateString: any) => {
  // 2016-08-01
  const parseRegex = /(\d{4})-(\d{2})-(\d{2})/;

  // see above
  let year = null;
  let month = null;
  let day = null;

  dateString.replace(parseRegex, (wholeMatch: any, $1: any, $2: any, $3: any) => {
    year = $1;
    month = $2;
    day = $3;
  });

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  return new Date(year, month - 1, day).getTime();
};

/**
 * Takes a cb which is called every time this function is called with an event.
 * Converts the numeric key into a month
 *
 * @param {Function} cb callback to receive the month of a key pressed
 * @param {string} source source of caller (ex: booking-widget)
 * @returns {Function}
 */
const keypressToMonth = (cb: any, source: any) => (event: any) => {
  const cbAndTrack = (month: any) => {
    analyticsTrackEvent(NUMERIC_KEYPRESS_USED_FOR_MONTH_INPUT, {
      source,
      month,
    });
    cb(month);
  };

  switch (event.key) {
    case '1':
      return cbAndTrack('January');
    case '2':
      return cbAndTrack('February');
    case '3':
      return cbAndTrack('March');
    case '4':
      return cbAndTrack('April');
    case '5':
      return cbAndTrack('May');
    case '6':
      return cbAndTrack('June');
    case '7':
      return cbAndTrack('July');
    case '8':
      return cbAndTrack('August');
    case '9':
      return cbAndTrack('September');
    case '0':
      return cbAndTrack('October');
    default:
      return null;
  }
};

export enum RelativeDayName {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  YESTERDAY = 'yesterday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
  DEFAULT = '',
}

/**
 * Convert a date string to a relative name, such as "today" or "tomorrow"
 *
 * @param {mixed} date Something parseable by moment()
 * @param {string} timeZone Time zone of date
 * @param {boolean | undefined} doCapitalize whether the resultant string should be capitalized irrespective of locale. Defaults to true for backwards compatibility.
 * @returns {string}
 */
const getRelativeDayName = (date: any, timeZone?: any): RelativeDayName => {
  const dateMoment = timeZone ? moment.tz(date, timeZone) : moment(date);
  const today = moment.tz(timeZone);
  const yesterday = today.clone().subtract(1, 'day');
  const tomorrow = today.clone().add(1, 'day');
  if (dateMoment.isSame(today, 'day')) {
    return RelativeDayName.TODAY;
  }
  if (dateMoment.isSame(yesterday, 'day')) {
    return RelativeDayName.YESTERDAY;
  }
  if (dateMoment.isSame(tomorrow, 'day')) {
    return RelativeDayName.TOMORROW;
  }
  const result =
    DateTime.format(dateMoment, timeZone, { locale: EN })('dddd') ??
    dateMoment.locale('en-us').format('dddd');
  let dayName: RelativeDayName;
  switch (result.toLowerCase()) {
    case 'monday':
      dayName = RelativeDayName.MONDAY;
      break;
    case 'tuesday':
      dayName = RelativeDayName.TUESDAY;
      break;
    case 'wednesday':
      dayName = RelativeDayName.WEDNESDAY;
      break;
    case 'thursday':
      dayName = RelativeDayName.THURSDAY;
      break;
    case 'friday':
      dayName = RelativeDayName.FRIDAY;
      break;
    case 'saturday':
      dayName = RelativeDayName.SATURDAY;
      break;
    case 'sunday':
      dayName = RelativeDayName.SUNDAY;
      break;
    default:
      dayName = RelativeDayName.DEFAULT;
      break;
  }
  return dayName;
};

/**
 * Given RelativeDayName return English string (for non-localized components)
 *
 * @param {RelativeDayName} dayName
 * @param {boolean | undefined} doCapitalize whether the resultant string should be capitalized irrespective of locale. Defaults to true for backwards compatibility.
 * @returns {string}
 */
export const getRelativeDayStringEnglish = (
  dayName: RelativeDayName,
  doCapitalize: boolean = true
): string => {
  let dayOfWeek = '';
  switch (dayName) {
    case RelativeDayName.TODAY:
      dayOfWeek = doCapitalize ? 'Today' : 'today';
      break;
    case RelativeDayName.TOMORROW:
      dayOfWeek = doCapitalize ? 'Tomorrow' : 'tomorrow';
      break;
    case RelativeDayName.MONDAY:
      dayOfWeek = doCapitalize ? 'Monday' : 'monday';
      break;
    case RelativeDayName.TUESDAY:
      dayOfWeek = doCapitalize ? 'Tuesday' : 'tuesday';
      break;
    case RelativeDayName.WEDNESDAY:
      dayOfWeek = doCapitalize ? 'Wednesday' : 'wednesday';
      break;
    case RelativeDayName.THURSDAY:
      dayOfWeek = doCapitalize ? 'Thursday' : 'thursday';
      break;
    case RelativeDayName.FRIDAY:
      dayOfWeek = doCapitalize ? 'Friday' : 'friday';
      break;
    case RelativeDayName.SATURDAY:
      dayOfWeek = doCapitalize ? 'Saturday' : 'saturday';
      break;
    case RelativeDayName.SUNDAY:
      dayOfWeek = doCapitalize ? 'Sunday' : 'sunday';
      break;
    default:
      break;
  }
  return dayOfWeek;
};

/**
 * Given RelativeDayName return relevant user-presenting string in current locale
 *
 * @param {RelativeDayName} dayName
 * @param {TFunction} t
 * @param {boolean | undefined} doCapitalize whether the resultant string should be capitalized irrespective of locale. Defaults to true for backwards compatibility.
 * @returns {string}
 */
export const getRelativeDayString = (
  dayName: RelativeDayName,
  t: TFunction<'common'>,
  doCapitalize: boolean = true
): string => {
  /**
   * Typesafe interpolation of keyname and casing
   *
   * @param {string} F
   * @param {string} S
   * @returns {string}
   */
  function dot<F extends string, S extends string>(f: F, s: S): `${F}.${S}` {
    return `${f}.${s}` as `${F}.${S}`;
  }

  const casing = doCapitalize ? 'capitalize' : 'lowercase';
  let dayString = '';
  switch (dayName) {
    case RelativeDayName.TODAY:
      dayString = t(dot('today', casing));
      break;
    case RelativeDayName.TOMORROW:
      dayString = t(dot('tomorrow', casing));
      break;
    case RelativeDayName.YESTERDAY:
      dayString = t(dot('yesterday', casing));
      break;
    case RelativeDayName.MONDAY:
      dayString = t(dot('monday', casing));
      break;
    case RelativeDayName.TUESDAY:
      dayString = t(dot('tuesday', casing));
      break;
    case RelativeDayName.WEDNESDAY:
      dayString = t(dot('wednesday', casing));
      break;
    case RelativeDayName.THURSDAY:
      dayString = t(dot('thursday', casing));
      break;
    case RelativeDayName.FRIDAY:
      dayString = t(dot('friday', casing));
      break;
    case RelativeDayName.SATURDAY:
      dayString = t(dot('saturday', casing));
      break;
    case RelativeDayName.SUNDAY:
      dayString = t(dot('sunday', casing));
      break;
    default:
      break;
  }
  return dayString;
};

/**
 * Convert YYYY-MM-DD to MM/DD/YYYY
 *
 * @param {string} input
 * @returns {string}
 */
const humanizeDate = (input: any) => {
  if (isEmptyString(input)) {
    return '';
  }

  const validDate = /\d\d\d\d-\d\d-\d\d/;

  if (!validDate.test(input)) {
    return input;
  }

  const parts = input.split('-');
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
};

const isXWithinYMilliseconds = (x: any, y: any) => {
  const now = new Date().getTime();
  return Math.abs(now - x) <= y;
};

/**
 * Apply a timezone offset to a time that doesn't have a timezone
 *
 * @param {string} t (in YYYY-MM-DDTHH:MM format)
 * @param {bool} isTomorrow
 * @returns {number} (in epoch millisecond format)
 */
const appendTimezoneToLocaleTime = (t: any, isTomorrow: any) => {
  const time = moment(t, 'HH:mm');
  if (isTomorrow) time.add(1, 'days');
  return time.valueOf();
};

/**
 * Round moment date to specified parameters
 * first finds current value using moment.minute, day year format
 * then rounds the value
 * then returns the rounded value
 *
 * @param {moment} date
 * @param {string} type
 * @param {number} offset
 * @returns {moment}
 * @example roundDate(moment(), 'minutes', 30)
 */
const roundDate = (date: any, type: any, offset: any) => {
  const curTypeValue = date[type]();
  const roundedTypeValue = Math.ceil((curTypeValue + 1) / offset) * offset;
  return date[type](roundedTypeValue).startOf(type);
};

/**
 * Add 10 minutes to current time and then round to nearest half hour
 */
const nextSlotTime = () => roundDate(moment().add(10, 'minutes'), 'minutes', 30).format('HH:mm');

const epochToLocale = (t: any) => moment(t, 'x').format('HH:mm');

const isTimeTomorrow = (t: any) =>
  Boolean(moment().startOf('day').diff(moment(t, 'x').startOf('day'), 'days'));

const getHoursFromEpochTime = (t: any) => Number(dateFormat(t, 'h'));

const timeStringToDecimal = (t: any) => {
  const hour = t.slice(0, 2);
  const minute = t.slice(3, 5);
  return parseInt(hour, 10) + parseInt(minute, 10) / 60;
};

const isValidDateString = (value: any) => value && /\d\d\d\d-\d\d?-\d\d?/.test(value);
export const isValidDateStringMMDDYYYY = (value: string) =>
  value && /^\d{2}\/\d{2}\/\d{4}$/.test(value);

const formatDatabaseDateToDisplay = (databaseFormat: any, outputFormat = DATE_DASH_FORMAT) => {
  if (isEmptyString(databaseFormat) || !isValidDateStringMMDDYYYY(databaseFormat)) {
    return '';
  }

  const momentDate = moment(databaseFormat, DATE_SLASH_FORMAT);
  if (!momentDate.isValid()) {
    return '';
  }

  return momentDate.format(outputFormat);
};

const thisYear = () => moment().year();

const standardizeYear = (year: any) => {
  let yearString = null;
  if (typeof year === 'number') {
    yearString = String(year);
  } else {
    yearString = year;
  }

  let yearNumber = parseInt(yearString, 10);
  if (Number.isNaN(yearNumber)) return '';
  if (yearString.length === 2 && yearNumber === 0) {
    yearNumber = 2000;
  } else if (yearNumber > 0 && yearNumber < 100) {
    if (yearNumber <= thisYear() - 2000) {
      yearNumber += 2000;
    } else {
      yearNumber += 1900;
    }
  }

  return yearNumber;
};

const getNearestHalfHour = () => {
  const currentTimestamp = moment().valueOf();
  const millisecondsInAHalfHour = 30 * 60 * 1000;
  const previousHalfHour = currentTimestamp - (currentTimestamp % millisecondsInAHalfHour);
  return previousHalfHour + millisecondsInAHalfHour;
};

/**
 * Validates a date entered by the user.
 *
 * @returns a moment date object from the supplised string.
 * @throws An error with a validation message
 */
export function validateArbitraryPastDateString(date: string): Moment {
  const parsedDate = moment(date);

  if (!parsedDate.isValid()) throw new Error('Invalid date');

  if (!parsedDate.isBefore(new Date(), 'hour')) throw new Error('Date cannot be in the future');

  const lowerDateBoundary = moment().subtract(120, 'year');
  if (parsedDate < lowerDateBoundary) throw new Error('Date cannot be more than 120 years ago');

  return parsedDate;
}

/**
 * Returns the abbreviated timezone if a timezone mismatch is detected.
 *
 * In the event that the passed timezone doesn't match the one that moment
 * thinks you're in with `moment.tz.guess()`, return the abbreviated timezone
 * string.
 *
 * @param timeZone timezone to compare `moment.tz.guess()` to
 * @returns tzInfo either an empty string or the tz info to display
 */
const getAppendedTimeZoneInfo = (timeZone: string) => {
  if (timeZone === moment.tz.guess()) {
    return '';
  }

  return ` ${moment.tz(timeZone).zoneAbbr()}`;
};

/**
 * FOR NON-ASAP VISITS get the scheduled appointment time, displaying the timezone
 * when it differs from the user's browser's timezone.
 *
 * @returns the formatted date string
 */
export function getScheduledAppointmentDateWithTimeZone(
  booking: NewBookingState['booking'],
  { subtractMinutes }: { subtractMinutes?: number } = {}
) {
  const { appointmentTime, timeZone } = booking;

  const momentObj = moment(appointmentTime).subtract(subtractMinutes ?? 0, 'minutes');

  let tzInfo = '';
  if (timeZone) {
    momentObj.tz(timeZone);
    tzInfo = getAppendedTimeZoneInfo(timeZone);
  }

  return `${momentObj.calendar()}${tzInfo}`;
}

const parseBirthDate = (input: any) => moment(input);

const validateBirthDate = (birthDate: any) => {
  const validDate = /^\d\d\d\d-\d{1,2}-\d{1,2}$/;
  if (typeof birthDate !== 'undefined') {
    if (!validDate.test(birthDate)) {
      return 'Invalid Birth date';
    }

    const parsedDate = parseBirthDate(birthDate);
    if (!parsedDate.isValid()) {
      return 'Invalid Birth date';
    }

    if (parsedDate > moment()) {
      return 'Birth date cannot be in the future';
    }

    const lowerDateBoundary = moment().subtract(120, 'year');
    if (parsedDate < lowerDateBoundary) {
      return 'Birth date cannot be more than 120 years ago';
    }
  }

  return '';
};

const getAgeFromBirthDate = (birthDate: any) => {
  if (isEmptyString(birthDate)) {
    return null;
  }

  const birthMoment = parseBirthDate(birthDate);
  return moment().diff(birthMoment, 'years', false);
};

const getAppointmentDateString = (booking: any, location: any) => {
  if (!booking.originalAppointmentDate) return '';
  const appointmentDate = moment(booking.originalAppointmentDate);
  const getFormattedAppointmentDate = DateTime.format(
    booking.originalAppointmentDate,
    location.timeZone
  );
  const actualDayName = getFormattedAppointmentDate(MONTH_DAY_ORDINAL);
  const relativeDayName = getRelativeDayName(appointmentDate, location.timeZone);
  const appointmentTime = getFormattedAppointmentDate(HOUR_MINUTE_PERIOD);

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  return `${relativeDayName}, ${actualDayName} at ${appointmentTime.toUpperCase()}`;
};

/**
 * @param {string} year
 * @param {string} monthInput
 * @param {string} dayInput
 * @returns {string} outputs something in the format 'YYYY-MM-DD' e.g '1988-04-09'
 */
const buildDateStringFromUserInput = (year: any, monthInput: any, dayInput: any) => {
  const MONTH_FORMAT = 'MM';
  const DAY_FORMAT = 'DD';
  const month = moment().month(monthInput).format(MONTH_FORMAT);
  const day = moment().date(dayInput).format(DAY_FORMAT);
  return `${year}-${month}-${day}`;
};

/**
 * Parse string and return year, month, and day as an object
 *
 * @param {string} dateString
 * @param {string} format
 * @returns {Object} object in { year, month, day } format
 */
const deconstructDateString = (dateString: any, format = BIRTH_DATE_FORMAT) => {
  if (isEmptyString(dateString)) {
    return {};
  }

  const date = moment(dateString, format);
  return {
    day: date.date(),
    month: date.format('MMMM'),
    year: date.year(),
  };
};

const isDateToday = (inputDate: any, timezone: any) => {
  if (!inputDate) return false;
  const today = moment.tz(timezone);
  return today.isSame(inputDate, 'day');
};

const isDateTomorrow = (inputDate: any, timezone: any) => {
  if (!inputDate) return false;
  const tomorrow = moment.tz(timezone).add(1, 'day');
  return tomorrow.isSame(inputDate, 'day');
};

const isFirstDateAfterSecondDate = (
  firstDate: moment.Moment | null,
  secondDate: moment.Moment | null
) => firstDate && secondDate && firstDate.isAfter(secondDate);

const isFirstDateBeforeSecondDate = (
  firstDate: moment.Moment | null,
  secondDate: moment.Moment | null
) => firstDate && secondDate && firstDate.isBefore(secondDate);

const isDateAfterTomorrow = (inputDate: any, timezone: any) => {
  if (!inputDate) return false;
  const tomorrow = moment.tz(timezone).add(1, 'day');
  return tomorrow.isBefore(inputDate, 'day');
};

const getCurrentDayAsWord = () => moment().format('dddd');

const getNextBirthday = (birthDate: any) => {
  const birthDateParts = birthDate.split('-');
  const day = birthDateParts[2];
  const month = birthDateParts[1];
  const currentYear = moment().format('YYYY');
  const nextYear = moment().add(1, 'years').format('YYYY');
  const currentYearBirthday = `${currentYear}-${month}-${day}`;
  const nextYearBirthday = `${nextYear}-${month}-${day}`;
  const currentDate = moment().format('YYYY-MM-DD');

  return moment(currentYearBirthday).isBefore(currentDate) ? nextYearBirthday : currentYearBirthday;
};

// pass in this callback whenever you want to sort by created date
// ex: userProfiles.sort(sortByCreatedDate)
// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
const sortByCreatedDateAsc = (a: any, b: any) => moment(a.created_date) - moment(b.created_date);

// or if you want in descending order
// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
const sortByCreatedDateDesc = (a: any, b: any) => moment(b.created_date) - moment(a.created_date);

const getDiffBetweenDates = (startDate: any, endDate: any, metric: any) =>
  moment(endDate).diff(startDate, metric);

const getTimeZoneFormat = (timeZone: any, locale: SupportedLocale = EN) =>
  timeZone ? moment().tz(timeZone).locale(locale).format('z') : '';

const getTimeZoneLongName = (timeZone: any, locale: SupportedLocale = EN) => {
  const abbr = moment().locale(locale).tz(timeZone).zoneName();
  // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
  return TIMEZONE_ABBR_MAP_LONG_NAME[abbr];
};

const getCurrentDay = (timeZone: string | undefined, locale: SupportedLocale = EN): WeekDay =>
  (timeZone
    ? moment().tz(timeZone).locale(locale).format('dddd')
    : moment().locale(locale).format('dddd')) as WeekDay;

export {
  getCurrentDay,
  getTimeZoneLongName,
  parseBirthDate,
  validateBirthDate,
  isTimeTomorrow,
  roundDate,
  epochToLocale,
  nextSlotTime,
  appendTimezoneToLocaleTime,
  parseSqlDate,
  parseSqlDateTime,
  getRelativeDayName,
  isDateAfterTomorrow,
  dateFormat,
  keypressToMonth,
  humanizeDate,
  isXWithinYMilliseconds,
  getHoursFromEpochTime,
  timeStringToDecimal,
  formatDatabaseDateToDisplay,
  standardizeYear,
  getNearestHalfHour,
  isValidDateString,
  isDateToday,
  isDateTomorrow,
  isFirstDateAfterSecondDate,
  isFirstDateBeforeSecondDate,
  isSolvOpen,
  getAgeFromBirthDate,
  getAppointmentDateString,
  buildDateStringFromUserInput,
  deconstructDateString,
  getCurrentDayAsWord,
  getNextBirthday,
  sortByCreatedDateAsc,
  sortByCreatedDateDesc,
  getDiffBetweenDates,
  getAppendedTimeZoneInfo,
  getTimeZoneFormat,
};
