import { camelToSnake } from './string';
import { basicComparator } from './function';
import logger from '../logger/index';

/**
 * Determine if value is an empty data point.
 * Will return true if it's an empty object or a null/undefined value
 *
 * @deprecated use lodash isEmpty
 * @param obj object to inquire about
 * @returns {boolean} if the object contains no data or is undefined or null
 */
const isEmptyObject = (obj: any) => {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  }

  if (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  return true;
};

const isUndefined = (obj: any) => typeof obj === 'undefined';

/**
 * Convert the top level of an object from snake_case to camelCase.
 *
 * @param {Object} obj
 * @returns {Object}
 */
const convertSnakeToCamelCase = (obj: any) => {
  if (isEmptyObject(obj)) {
    return obj;
  }

  const converted = {};
  for (const key of Object.keys(obj)) {
    const newKey = key.replace(/_(\w)/g, (originalString, firstCharacter) =>
      firstCharacter.toUpperCase()
    );
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    converted[newKey] = obj[key];
  }

  return converted;
};

const getKeyByValue = (obj: any, value: any) => Object.keys(obj).find((key) => obj[key] === value);

/**
 * Convert all layers of an object from snake_case to camelCase.
 * Even goes through all array properties. Continues until it hit's an non-iterable object.
 *
 * @param {Object} obj
 * @returns {Object}
 */
const convertSnakeToCamelCaseRecursive = (obj: any) => {
  if (typeof obj !== 'object' || isEmptyObject(obj)) {
    return obj;
  }

  /**
   *
   */
  function helper(curObj: any, i: any) {
    const updatedObj = convertSnakeToCamelCaseRecursive(curObj);
    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    const key = Array.isArray(this) ? i : getKeyByValue(this, curObj);
    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    this[key] = updatedObj;
  }

  const base = Array.isArray(obj) ? obj : convertSnakeToCamelCase(obj);
  Object.values(base).forEach(helper, base);

  return base;
};

const convertCamelToSnakeCase = (obj: any) => {
  if (isEmptyObject(obj)) {
    return obj;
  }

  const converted = {};
  for (const [key, value] of Object.entries(obj)) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    converted[camelToSnake(key)] = value;
  }

  return converted;
};

/**
 * Safe get method for objects.
 *
 * Will not error out if you give it an invalid path.
 * To use, put in an object and an optional default
 * value. The second function can be called either
 * traditionally like safeGet(myObj)('path.to.method')
 * or with the newer style function calling method with
 * string templates: safeGet(myObj)\`path.to.method\`
 *
 * @deprecated use optional chaining and nullish coalescing operators
 * @param   {Object} obj
 * @param   {*} defaultVal
 * @returns {Function}
 */
const safeGet =
  (obj: any, defaultVal: any = undefined) =>
  /**
   * @param {string} path
   * @returns {*}
   */
  (path: any) => {
    const [p] = typeof path === 'object' ? path : [path];

    let retValue = obj;

    for (const key of p.split('.')) {
      try {
        retValue = retValue[key];
      } catch (e) {
        return defaultVal;
      }
    }

    return typeof retValue === 'undefined' ? defaultVal : retValue;
  };

const EMPTY_OBJECT = {};

const deleteUndefinedFields = (obj: any) => {
  Object.keys(obj).forEach(
    (key) => typeof obj[key] === 'undefined' && delete obj[key] // eslint-disable-line no-param-reassign
  );

  return obj;
};

/**
 * Zip an array of keys with an array of values
 *
 * Useful if you need to combine a set of keys
 * and values. Maybe it's just me that created
 * the problem so I can find a solution for it.
 *
 * @param   {Array}    keyArr key array to be zipped
 * @returns {Function} function ready to take value array
 */
const zipObj =
  (
    keyArr: any
    /**
     * Secondary function which takes the values array
     * to zip with the already provided keys.
     *
     * @param   {Array} valueArr Array of values to be assigned.
     * @returns {*|{}} Object with keys assigned to values.
     */
  ) =>
  (valueArr: any) => {
    if (keyArr.length !== valueArr.length) {
      logger.error(new Error('Key length does not match value length in zipObj, returning null'));
      return null;
    }

    return keyArr.reduce((acc: any, key: any, i: any) => ({ [key]: valueArr[i], ...acc }), {});
  };

/**
 * Do the objects contain the same values for the given keys.
 *
 * @param {Array<Object>} objects
 * @param {Array<string>} keys
 * @param {function({any}, {any}): boolean} comparator
 * @returns {boolean}
 */
const doValuesMatch = (objects: any, keys: any, comparator = basicComparator) =>
  keys.every(
    (key: any) =>
      objects.reduce(
        (acc: any, curr: any) => ({
          match: acc.match && comparator(acc.obj[key], curr[key]),
          obj: curr,
        }),
        { match: true, obj: objects[0] }
      ).match
  );

/**
 *
 * @param {Object} strongObj The object which keys we iterate through
 * @param weakObj The object that strongObj[key] gets compared to
 * @param {function({any}, {any}): boolean} comparator callback for comparing values
 * @returns {Array<string>} keys that don't match
 */
const getMismatchedFields = (strongObj: any, weakObj: any, comparator = basicComparator) =>
  Object.keys(strongObj).filter((key) => !comparator(strongObj[key], weakObj[key]));

/**
 * Polyfill for object entries
 *
 * @param {Object} obj
 * @returns {Array<({any}, {any})>}
 */
const objectEntries = (obj: any) => {
  if (!Object.entries) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i);

    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  }

  return Object.entries(obj);
};

export {
  safeGet,
  zipObj,
  isEmptyObject,
  doValuesMatch,
  isUndefined,
  convertSnakeToCamelCase,
  objectEntries,
  convertSnakeToCamelCaseRecursive,
  convertCamelToSnakeCase,
  getKeyByValue,
  getMismatchedFields,
  EMPTY_OBJECT,
  deleteUndefinedFields,
};
