import { DAPI_HOST } from '../../config/index';
import { isEmptyObject } from '../../core/util/object';

const filterPopularInsurers = (searchStateCode: any) => {
  if (!isEmptyObject(searchStateCode)) {
    return `&popular_only=True&state=${searchStateCode}`;
  }

  return '';
};

const getFieldList = () => 'fields=id,name,display_name,insurer_code';

const getInsurersListUrl = (stateCode: any) =>
  `${DAPI_HOST}/v1/insurers?&limit=0&${getFieldList()}${filterPopularInsurers(stateCode)}`;

const getInsurerById = (insurerId: any) => `${DAPI_HOST}/v1/insurers/${insurerId}`;

export { getInsurersListUrl, getInsurerById };
