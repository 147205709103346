import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { emptyFunction } from '../../core/util/function';
import Picture from './Picture';
import { LOGO } from '~/components/SolvPatternLibrary/Picture/util';

const Root = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  display: inline-block;
`;

const Circle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  left: 0;
  top: 0;
  // the below causes some weird issues occasionally, but it's the only way to get
  // safari to consistently apply the border radius
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
`;

const Logo = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  left: 0;
  top: 0;
`;

type OwnProps = {
  imageObject?: any;
  imageProps?: any;
  imageType?: string;
  className?: string;
  onClick?: (...args: any[]) => any;
  alt?: string;
  showThumbnail?: boolean;
  thumbnailSize?: string;
};

type Props = OwnProps & typeof CircleImage.defaultProps;

export default class CircleImage extends PureComponent<Props> {
  static defaultProps = {
    onClick: emptyFunction,
  };

  render = () => {
    const {
      className,
      imageProps,
      imageObject,
      imageType,
      children,
      onClick,
      alt,
      showThumbnail,
      thumbnailSize,
    } = this.props;
    if (!imageObject) return null;
    const imgTag = imageType === LOGO ? Logo : Image;

    return (
      <Root className={className} onClick={onClick}>
        <Circle role="img">
          {children}

          <Picture
            alt={alt}
            imageObject={imageObject}
            imgTag={imgTag}
            showThumbnail={showThumbnail}
            thumbnailSize={thumbnailSize}
            {...imageProps}
          />
        </Circle>
      </Root>
    );
  };
}
