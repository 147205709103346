import { takeEvery, call, put } from 'redux-saga/effects';
import { apiGet, apiPatch, apiDeleteJson, apiPost } from '../core/dapi';
import logger from '../core/logger/index';
import {
  getUserRemindersUrl,
  createReminderUrl,
  updateReminderUrl,
  deleteReminderUrl,
} from '../core/dapi/reminders';
import {
  remindersReceived,
  createdReminderReceived,
  updatedReminderReceived,
  deletedReminderReceived,
  remindersError,
  remindersFetching,
} from '../ducks/reminders';
import {
  ReminderStatus,
  getExpirationDate,
  DEFAULT_REMINDER_INTERVAL,
} from '../components/Account/Profile/Reminders/util/reminders';
import { getUserProfileById } from '../core/dapi/userProfile';
import {
  userProfileError,
  userProfileProcessing,
  receiveUserProfileForAccount,
} from '../actions/account';

export const FETCH_REMINDERS = 'saga/FETCH_REMINDERS';
export const CREATE_REMINDER = 'saga/CREATE_REMINDER';
export const UPDATE_REMINDER = 'saga/UPDATE_REMINDER';
export const DELETE_REMINDER = 'saga/DELETE_REMINDER';
export const SET_REMINDER_DAY = 'saga/SET_REMINDER_DAY';

function* fetchReminders({ userProfileId }: any) {
  try {
    const url = getUserRemindersUrl(userProfileId);

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    yield put(remindersFetching());
    const remindersResponse = yield call(apiGet, url);

    yield put(remindersReceived(remindersResponse));
  } catch (e) {
    logger.warn(e);
    yield put(remindersError(e));
  }
}

function* createReminder({
  userProfileId,
  birthDate,
  reminderType,
  firstReminderDate,
  notify = true,
  status = ReminderStatus.NOT_DONE,
}: any) {
  try {
    const url = createReminderUrl();
    const reminderPostData = {
      user_profile_id: userProfileId,
      reminder_type: reminderType,
      first_reminder_date: firstReminderDate,
      notify,
      status,
    };

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const createReminderResponse = yield call(apiPost, url, reminderPostData);
    yield put(createdReminderReceived(createReminderResponse));
  } catch (e) {
    yield put(remindersError(e));
  }
}

function* updateReminder({ reminderId, status, notify }: any) {
  try {
    const url = updateReminderUrl(reminderId);
    const reminderPatchData = { status, notify };
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const updateReminderResponse = yield call(apiPatch, url, reminderPatchData);
    yield put(updatedReminderReceived(updateReminderResponse));
  } catch (e) {
    logger.warn(e);
    yield put(remindersError(e));
  }
}

function* deleteReminder({ reminderId }: any) {
  try {
    const url = deleteReminderUrl(reminderId);
    const deleteReminderResponse = yield call(apiDeleteJson, url);
    yield put(deletedReminderReceived(deleteReminderResponse));
  } catch (e) {
    logger.warn(e);
    yield put(remindersError(e));
  }
}

function* setReminderDay({ userProfileId, reminderDay }: any) {
  try {
    const url = getUserProfileById(userProfileId);
    const userProfilePatchData = { reminder_day: reminderDay };
    yield put(userProfileProcessing());
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const userProfilePatchResponse = yield call(apiPatch, url, userProfilePatchData);
    yield put(receiveUserProfileForAccount(userProfilePatchResponse));
  } catch (e) {
    logger.warn(e);
    yield put(userProfileError(e));
  }
}

function* rootSaga() {
  yield takeEvery(FETCH_REMINDERS, fetchReminders);
  yield takeEvery(CREATE_REMINDER, createReminder);
  yield takeEvery(UPDATE_REMINDER, updateReminder);
  yield takeEvery(DELETE_REMINDER, deleteReminder);
  yield takeEvery(SET_REMINDER_DAY, setReminderDay);
}

export { rootSaga as default };
