import { PaperworkFieldName, ProviderGroupPaperworkFieldName } from '../../../paperworkConstants';
import { SectionPageCollection } from '../../../interfaces';

export enum HealthcareContactsPageNumber {
  Pharmacy,
  HasPrimaryCareProvider,
  PrimaryCareProvider,
  EmergencyContact,
  IsEmployed,
  IsEmployerRequest,
  EmployerInfo,
}

export const HEALTHCARE_CONTACTS_PAGES: SectionPageCollection = new Map([
  [
    HealthcareContactsPageNumber.Pharmacy,
    {
      fieldNames: [PaperworkFieldName.PREFERRED_PHARMACY],
    },
  ],
  [
    HealthcareContactsPageNumber.HasPrimaryCareProvider,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.HAS_PRIMARY_CARE_PROVIDER],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    HealthcareContactsPageNumber.PrimaryCareProvider,
    {
      fieldNames: [PaperworkFieldName.PRIMARY_CARE_PHYSICIAN],
    },
  ],
  [
    HealthcareContactsPageNumber.EmergencyContact,
    {
      fieldNames: [
        PaperworkFieldName.EMERGENCY_CONTACT_FIRST_NAME,
        PaperworkFieldName.EMERGENCY_CONTACT_LAST_NAME,
        PaperworkFieldName.EMERGENCY_CONTACT_PHONE,
        ProviderGroupPaperworkFieldName.EMERGENCY_CONTACT_EMAIL,
        PaperworkFieldName.EMERGENCY_CONTACT_RELATIONSHIP_TO_PATIENT,
      ],
    },
  ],
  [
    HealthcareContactsPageNumber.IsEmployed,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.IS_EMPLOYED],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    HealthcareContactsPageNumber.IsEmployerRequest,
    {
      fieldNames: [PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    HealthcareContactsPageNumber.EmployerInfo,
    {
      fieldNames: [
        PaperworkFieldName.EMPLOYER,
        ProviderGroupPaperworkFieldName.JOB_TITLE,
        PaperworkFieldName.EMPLOYER_CONTACT_PHONE,
      ],
    },
  ],
]);
