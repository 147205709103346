import React, { Fragment, PureComponent } from 'react';
import Collapse from '@material-ui/core/Collapse';
import styled from 'styled-components';
import Location from '@solvhealth/types/interfaces/Location';
import Provider from '@solvhealth/types/interfaces/Provider/index';
import { SolvTheme, Stack, Text } from '@solvhealth/jigsaw';
import StarSelector from '../../SolvPatternLibrary/StarSelector';
import { colors } from '../../../constants/colors';
import { fontSize, fontFamily } from '../../../constants/text';
import { biggerThanOrEqualTo, smallerThanOrEqualTo } from '../../../core/util/styledComponents';
import { PRIMARY_RATING } from './PrimaryRatingSelector';
import { isTelemedLocation } from '../../../core/util/location';
import {
  BEDSIDE_MANNER,
  FACILITY_CLEANLINESS,
  QUALITY_OF_CARE,
  STAFF_FRIENDLINESS,
  WAIT_TIME,
} from '../constants';

const SECONDARY_RATINGS = [
  WAIT_TIME,
  QUALITY_OF_CARE,
  BEDSIDE_MANNER,
  STAFF_FRIENDLINESS,
  FACILITY_CLEANLINESS,
];

const TELEMED_SECONDARY_RATINGS = SECONDARY_RATINGS.slice(0, SECONDARY_RATINGS.length - 1);

const getDefaultRatingsQuestions = (location?: Location) => {
  if (location) {
    return isTelemedLocation(location) ? TELEMED_SECONDARY_RATINGS : SECONDARY_RATINGS;
  }
  return SECONDARY_RATINGS;
};

type Props = {
  leaveAReview?: any;
  onChange?: Function;
  location?: Location;
  ratingQuestions?: string[];
  provider?: Provider;
};

const SecondaryStarSelectors = (props: Props) => {
  const { leaveAReview, onChange, location } = props;
  const { ratingQuestions = getDefaultRatingsQuestions(location) } = props;

  const renderDimensions = (ratingQuestions: any, leaveAReview: any, onChange: any) =>
    ratingQuestions.map((genre: any) => (
      <Stack
        alignItems="center"
        justifyContent="space-between"
        key={genre}
        maxWidth={475}
        mx="auto"
        row
        width="100%"
      >
        <Text flex="1" textAlign="left" variant="subtitle2">
          {genre}
        </Text>

        <StarSelector
          onChange={(value: any) => onChange({ value, genre })}
          rating={leaveAReview[genre]}
          starWidth={29}
        />
      </Stack>
    ));

  return (
    <Collapse in={!!leaveAReview[PRIMARY_RATING]} timeout="auto">
      <Stack space={3}>
        <Text variant="subtitle">How would you rate the following?</Text>
        {renderDimensions(ratingQuestions, leaveAReview, onChange)}
      </Stack>
    </Collapse>
  );
};

export default SecondaryStarSelectors;
