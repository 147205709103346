import { birthDateInputValidator } from '~/core/util/rcForm/validators';

export const MAX_NUMBER_CUSTOM_FIELDS_PER_PAGE = 5;

export enum PaperworkFieldType {
  ADDRESS = 'address',

  BIRTH_DATE = 'birth_date',

  DATE = 'date',

  EMAIL = 'email',

  INPUT = 'input',

  PHONE_NUMBER = 'phone',

  RELATIONSHIP_TO_ACCOUNT = 'relationship_to_account',

  SELECT = 'select',

  SELECT_CARDS = 'select_cards',

  SOCIAL_SECURITY_NUMBER = 'ssn',

  PHARMACY_SEARCH = 'pharmacy_search',

  CHECK_BOX = 'check_box',

  JSON_CHECKBOX = 'json_checkbox',

  JSON_LIST = 'json_list',

  JSON_CHECKBOX_CARDS = 'json_checkbox_cards',

  TEXT_AREA = 'text_area',

  CHECK_BOX_CHOICE_CARDS = 'checkbox_cards',

  JSON_CHECK_BOX_CHOICE_CARDS = 'json_checkbox_cards',
}

export enum PaperworkSectionRoute {
  START = '/paperwork/start',

  PATIENT = '/paperwork/patient',

  PHOTO_ID = '/photo-id-upload/pw',

  EMPLOYER = '/paperwork/employer',

  HEALTHCARE_CONTACTS = '/paperwork/healthcare-contacts',

  PAYMENT = '/paperwork/payment',

  GUARDIAN = '/paperwork/guardian',

  EMERGENCY_CONTACT = '/paperwork/emergency-contact',

  CUSTOM = '/paperwork/custom',

  VERIFICATION_AND_REVIEW = '/paperwork/verification-and-review',

  CONSENT = '/paperwork/consent',

  FINALIZE = '/paperwork/finalize',

  COMPLETE = '/paperwork/complete',
}

export enum ProviderGroupPaperworkSectionRoute {
  PATIENT = '/paperwork/provider-group/patient',

  MEDICAL = '/paperwork/provider-group/medical',

  LIFESTYLE_FACTORS = '/paperwork/provider-group/lifestyle-factors',

  HEALTHCARE_CONTACTS = '/paperwork/provider-group/healthcare-contacts',

  PHOTO_ID = `/photo-id-upload/pw`,

  VERIFICATION_AND_REVIEW = '/paperwork/provider-group/verification-and-review',

  FINALIZE = '/paperwork/provider-group/finalize',
}

export enum PaperworkStatus {
  COMPLETE = 'complete',

  IN_PROGRESS = 'in_progress',
}

export enum GranularPaperworkStatus {
  NeedsToBeFilled,
  NeedsReviewAndVerification, // Paperwork has been filled out for a previous booking (additional fields may be required for the current booking)
  Complete,
  NotNeededForVisit,
}

export enum EmployerRelatedVisitReason {
  PHYSICAL = 'Physical',

  DRUG_SCREEN_ALCOHOL_TEST = 'Drug Screen and/or Alcohol Test',

  WORK_INJURY = 'Work Injury',
}

export const REGISTRATION_PAPERWORK = 'registration';
export const LOCATION_PAPERWORK_RESPONSE_TYPE_REGISTRATION = 'registration';

export const PAPERWORK_FIELD_ANSWER_YES = 'Yes';
export const PAPERWORK_FIELD_ANSWER_NO = 'No';

export enum PaperworkCopyFormFieldsCheckboxName {
  ADDRESS = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_address',
  GUARDIAN_ADDRESS = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_guardianAddress',
  PHOTO_ID = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_photoId',
  INSURANCE = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_insurance',
  PHYSICIAN = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_physician',
  PHARMACY = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_pharmacy',
  EMERGENCY_CONTACT = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_emergencyContact',
  GUARANTOR_INFO = 'PAPERWORK_COPY_FORM_FIELDS_CHECKBOX/same_guarantor',
}

export enum PaperworkFieldName {
  PATIENT_FIELD_FIRST_NAME = 'patientFirstName',

  PATIENT_FIELD_MIDDLE_NAME = 'patientMiddleName',

  PATIENT_FIELD_LAST_NAME = 'patientLastName',

  PATIENT_FIELD_BIRTH_DATE = 'birthDate',

  PATIENT_FIELD_GENDER = 'patientGender',

  PATIENT_FIELD_BIRTH_SEX = 'patientBirthSex',

  PATIENT_FIELD_ETHNICITY = 'ethnicity',

  PATIENT_FIELD_RACE = 'race',

  PATIENT_ADDRESS_STREET = 'patientAddressStreet',

  PATIENT_ADDRESS_STREET_TWO = 'patientAddressStreetTwo',

  PATIENT_ADDRESS_CITY = 'patientAddressCity',

  PATIENT_ADDRESS_STATE = 'patientAddressState',

  PATIENT_ADDRESS_ZIP = 'patientAddressZip',

  PATIENT_PRIMARY_PHONE = 'primaryPhone',

  PATIENT_EMAIL = 'email',

  PATIENT_MARITAL_STATUS = 'maritalStatus',

  PATIENT_SOCIAL_SECURITY_NUMBER = 'ssn',

  REASON_FOR_VISIT = 'reasonForVisit',

  PRIMARY_CARE_PHYSICIAN = 'physician',

  PREFERRED_PHARMACY = 'pharmacy',

  HOW_DID_YOU_HEAR_ABOUT_US = 'howHearAboutUs',

  EMPLOYER = 'employer',

  EMPLOYER_CONTACT_NAME = 'workInjuryEmployerContactName',

  EMPLOYER_CONTACT_PHONE = 'workInjuryEmployerContactPhoneNumber',

  CUSTOM_EMPLOYER = 'customEmployer',

  WHAT_ARE_YOU_HERE_FOR = 'whatAreYouHereFor',

  WHAT_TYPE_OF_PHYSICAL = 'whatTypeOfPhysical',

  WHAT_TYPE_OF_DRUG_SCREEN_OR_ALCOHOL_TEST = 'whatTypeOfDrugScreenAlcoholTest',

  HOW_DID_YOUR_INJURY_OCCUR = 'howDidYourInjuryOccur',

  WHEN_DID_YOUR_INJURY_OCCUR = 'whenDidYourInjuryOccur',

  TO_WHOM_REPORTED_YOUR_INJURY = 'toWhomReportedYourInjury',

  WHERE_WAS_PRIOR_TREATMENT = 'whereWasPriorTreatment',

  TREATED_AT_THE_REQUEST_OF_EMPLOYER = 'areYouBeingTreatedAtTheRequestOfYourEmployer',

  SELF_PAY_CHECK = 'selfPayCheck',

  PRIMARY_INSURANCE_COMPANY = 'insuranceCompany',

  PRIMARY_INSURANCE_PLAN_NAME = 'insurancePlanName',

  PRIMARY_MEMBER_ID = 'memberId',

  PRIMARY_GROUP_NUMBER = 'groupNumber',

  PRIMARY_POLICY_HOLDER_FIRST_NAME = 'policyHolderFirstName',

  PRIMARY_POLICY_HOLDER_MIDDLE_NAME = 'policyHolderMiddleName',

  PRIMARY_POLICY_HOLDER_LAST_NAME = 'policyHolderLastName',

  PRIMARY_POLICY_HOLDER_BIRTH_DATE = 'policyHolderBirthDate',

  PRIMARY_POLICY_HOLDER_BIRTH_SEX = 'policyHolderBirthSex',

  PATIENTS_RELATIONSHIP_TO_INSURED = 'patientsRelationshipToInsured',

  DO_YOU_HAVE_SECONDARY_INSURANCE_INFORMATION = 'doYouHaveSecondInsuranceInformation',

  SECONDARY_INSURANCE_COMPANY = 'secondaryInsuranceCompany',

  SECONDARY_INSURANCE_PLAN_NAME = 'secondaryInsurancePlanName',

  SECONDARY_MEMBER_ID = 'secondaryMemberId',

  SECONDARY_GROUP_NUMBER = 'secondaryGroupNumber',

  SECONDARY_POLICY_HOLDER_FIRST_NAME = 'secondaryPolicyHolderFirstName',

  SECONDARY_POLICY_HOLDER_MIDDLE_NAME = 'secondaryPolicyHolderMiddleName',

  SECONDARY_POLICY_HOLDER_LAST_NAME = 'secondaryPolicyHolderLastName',

  SECONDARY_POLICY_HOLDER_BIRTH_DATE = 'secondaryPolicyHolderBirthDate',

  SECONDARY_PATIENTS_RELATIONSHIP_TO_INSURED = 'secondaryPatientsRelationshipToInsured',

  GUARDIAN_FIRST_NAME = 'guardianFirstName',

  GUARDIAN_MIDDLE_NAME = 'guardianMiddleName',

  GUARDIAN_LAST_NAME = 'guardianLastName',

  GUARDIAN_BIRTH_DATE = 'guardianBirthDate',

  GUARDIAN_RELATIONSHIP_TO_PATIENT = 'relationshipToPatient',

  GUARDIAN_ADDRESS = 'guardianAddress',

  GUARDIAN_ADDRESS_TWO = 'guardianAddressTwo',

  GUARDIAN_CITY = 'guardianCity',

  GUARDIAN_STATE = 'guardianState',

  GUARDIAN_ZIP = 'guardianZip',

  GUARDIAN_PHONE = 'guardianPhone',

  GUARDIAN_ALTERNATE_PHONE = 'altGuardianPhone',

  GUARDIAN_EMAIL = 'guardianEmail',

  GUARANTOR_FIRST_NAME = 'guarantorFirstName',

  GUARANTOR_LAST_NAME = 'guarantorLastName',

  GUARANTOR_BIRTH_DATE = 'guarantorBirthDate',

  GUARANTOR_BIRTH_SEX = 'guarantorBirthSex',

  GUARANTOR_PHONE = 'guarantorPhone',

  GUARANTOR_RELATIONSHIP = 'guarantorRelationship',

  EMERGENCY_CONTACT_FIRST_NAME = 'emergencyContactFirstName',

  EMERGENCY_CONTACT_MIDDLE_NAME = 'emergencyContactMiddleName',

  EMERGENCY_CONTACT_LAST_NAME = 'emergencyContactLastName',

  EMERGENCY_CONTACT_PHONE = 'emergencyContactPhone',

  EMERGENCY_CONTACT_RELATIONSHIP_TO_PATIENT = 'emergencyContactRelationshipToPatient',
}

export enum ProviderGroupPaperworkFieldName {
  GUARDIAN_ADDRESS_TWO = 'pg_guardian_address-two',
  GUARDIAN_CITY = 'pg_guardian_city',
  GUARDIAN_STATE = 'pg_guardian_state',
  GUARDIAN_ZIP = 'pg_guardian_zip',

  LANGUAGE = 'pg_language_primary-language',
  LANGUAGE_OTHER = 'pg_language_other',

  PREFERRED_PRONOUNS = 'pg_pronouns_preferred-pronouns',

  SYMPTOMS = 'pg_review-of-systems_symptoms',

  MEDICAL_CONDITIONS = 'pg_past-conditions_medical-conditions',

  PROCEDURES = 'pg_procedures_surgeries',

  MEDICATIONS_IS_TAKING = 'pg_medications_is-taking',
  MEDICATIONS_LIST = 'pg_medications_list',

  ALLERGIES = 'pg_allergies_any-known',
  ALLERGIES_LIST = 'pg_allergies_list',

  IMMUNIZATION_STATUS_IS_CURRENT = 'pg_immunization_status-is-up-to-date',

  EVER_BEEN_PREGNANT = 'pg_obgyn_pregnancy-ever-been-pregnant',
  BIRTH_CONTROL_USE = 'pg_obgyn_birth-control-use',
  PAP_SMEAR_EVER_HAD = 'pg_obgyn_pap-smear-ever-had',
  PAP_SMEAR_LAST_DATE = 'pg_obgyn_pap-smear-last-date',
  LAST_PERIOD = 'pg_obgyn_menopause-last-period',
  MAMMOGRAM_EVER_HAD = 'pg_obgyn_mammogram-ever-had',

  FAMILY_HISTORY_MEDICAL_CONDITIONS = 'pg_family-history_medical-conditions',

  DO_YOU_DRINK = 'pg_alcohol_do-you-drink',
  HOW_MUCH_ALCOHOL = 'pg_alcohol_how-much',

  EVER_SMOKED = 'pg_smoking_has-ever-smoked',
  PAST_SMOKING_USAGE = 'pg_smoking_past-usage',
  SMOKING_AMOUNT = 'pg_smoking_amount',
  SMOKING_HOW_LONG = 'pg_smoking_how-long',

  DRUGS = 'pg_drugs_recreational-drugs',
  DRUGS_LIST = 'pg_drugs_list',

  SEXUAL_ACTIVITY = 'pg_sexual-activity_active',

  HAS_PRIMARY_CARE_PROVIDER = 'pg_pcp_has-pcp',

  EMERGENCY_CONTACT_EMAIL = 'pg_emergency-contact_email',

  IS_EMPLOYED = 'pg_employment_is-employed',
  JOB_TITLE = 'pg_employment_job-title',
}

// Maps to the validation rules here: https://github.com/yiminghe/async-validator
export const PAPERWORK_FIELD_VALIDATIONS: Record<string, Array<{ [key: string]: any }>> = {
  [PaperworkFieldName.PATIENT_SOCIAL_SECURITY_NUMBER]: [
    {
      len: 9,
      message: 'Social security must be exactly 9 digits.',
    },
  ],
  [PaperworkFieldName.PATIENT_ADDRESS_ZIP]: [
    {
      len: 5,
      message: 'Zip code must be exactly 5 characters.',
    },
  ],
  [PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE]: [
    {
      hasFormatMMDDYYYY: true,
      validator: birthDateInputValidator,
    },
  ],
  [PaperworkFieldName.PRIMARY_POLICY_HOLDER_BIRTH_DATE]: [
    {
      hasFormatMMDDYYYY: true,
      validator: birthDateInputValidator,
    },
  ],
  [PaperworkFieldName.SECONDARY_POLICY_HOLDER_BIRTH_DATE]: [
    {
      hasFormatMMDDYYYY: true,
      validator: birthDateInputValidator,
    },
  ],
  [PaperworkFieldName.GUARDIAN_BIRTH_DATE]: [
    {
      hasFormatMMDDYYYY: true,
      validator: birthDateInputValidator,
    },
  ],
  [PaperworkFieldName.GUARANTOR_BIRTH_DATE]: [
    {
      hasFormatMMDDYYYY: true,
      validator: birthDateInputValidator,
    },
  ],
};

export const PAPERWORK_FIELD_INPUT_ADDITIONAL_PROPS: Record<string, Record<string, any>> = {
  [PaperworkFieldName.PATIENT_ADDRESS_ZIP]: {
    maxLength: 5,
  },
};

export const PAPERWORK_DATE_FIELDS = [
  PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE,
  PaperworkFieldName.GUARDIAN_BIRTH_DATE,
  PaperworkFieldName.PRIMARY_POLICY_HOLDER_BIRTH_DATE,
  PaperworkFieldName.SECONDARY_POLICY_HOLDER_BIRTH_DATE,
  PaperworkFieldName.GUARANTOR_BIRTH_DATE,
];

export const PATIENT_FORM_PAGE_ONE_FIELD_NAMES = [
  PaperworkFieldName.PATIENT_FIELD_FIRST_NAME,
  PaperworkFieldName.PATIENT_FIELD_MIDDLE_NAME,
  PaperworkFieldName.PATIENT_FIELD_LAST_NAME,
  PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE,
  PaperworkFieldName.PATIENT_PRIMARY_PHONE,
  PaperworkFieldName.PATIENT_EMAIL,
  PaperworkFieldName.PATIENT_FIELD_GENDER,
  PaperworkFieldName.PATIENT_FIELD_BIRTH_SEX,
  PaperworkFieldName.PATIENT_FIELD_ETHNICITY,
  PaperworkFieldName.PATIENT_FIELD_RACE,
  PaperworkFieldName.PATIENT_MARITAL_STATUS,
  PaperworkFieldName.PATIENT_SOCIAL_SECURITY_NUMBER,
];

export const PATIENT_FORM_PAGE_TWO_FIELD_NAMES = [
  PaperworkCopyFormFieldsCheckboxName.ADDRESS,
  PaperworkFieldName.PATIENT_ADDRESS_STREET,
  PaperworkFieldName.PATIENT_ADDRESS_STREET_TWO,
  PaperworkFieldName.PATIENT_ADDRESS_CITY,
  PaperworkFieldName.PATIENT_ADDRESS_STATE,
  PaperworkFieldName.PATIENT_ADDRESS_ZIP,
];

export const HEALTHCARE_CONTACTS_PAGE_ONE_FIELD_NAMES = [
  PaperworkFieldName.PRIMARY_CARE_PHYSICIAN,
  PaperworkCopyFormFieldsCheckboxName.PHYSICIAN,
  PaperworkFieldName.PREFERRED_PHARMACY,
  PaperworkCopyFormFieldsCheckboxName.PHARMACY,
  PaperworkFieldName.HOW_DID_YOU_HEAR_ABOUT_US,
];

export const EMPLOYER_RELATED_FIELD_NAMES = [
  PaperworkFieldName.EMPLOYER,
  PaperworkFieldName.EMPLOYER_CONTACT_NAME,
  PaperworkFieldName.EMPLOYER_CONTACT_PHONE,
  PaperworkFieldName.WHAT_ARE_YOU_HERE_FOR,
];

export const WORK_RELATED_INJURY_FIELD_NAMES = [
  PaperworkFieldName.HOW_DID_YOUR_INJURY_OCCUR,
  PaperworkFieldName.WHEN_DID_YOUR_INJURY_OCCUR,
  PaperworkFieldName.TO_WHOM_REPORTED_YOUR_INJURY,
  PaperworkFieldName.WHERE_WAS_PRIOR_TREATMENT,
];

export const CUSTOM_EMPLOYER_RELATED_FIELD_NAMES = [
  PaperworkFieldName.CUSTOM_EMPLOYER,
  ...EMPLOYER_RELATED_FIELD_NAMES.slice(1),
];

export const EMPLOYER_FORM_FIELD_NAMES = [
  PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER,
  ...EMPLOYER_RELATED_FIELD_NAMES,
  PaperworkFieldName.WHAT_TYPE_OF_PHYSICAL,
  PaperworkFieldName.WHAT_TYPE_OF_DRUG_SCREEN_OR_ALCOHOL_TEST,
  ...WORK_RELATED_INJURY_FIELD_NAMES,
];

export const PAYMENT_FORM_FIELD_NAMES = [
  PaperworkCopyFormFieldsCheckboxName.INSURANCE,
  PaperworkFieldName.PRIMARY_INSURANCE_COMPANY,
  PaperworkFieldName.PRIMARY_INSURANCE_PLAN_NAME,
  PaperworkFieldName.PRIMARY_MEMBER_ID,
  PaperworkFieldName.PRIMARY_GROUP_NUMBER,
  PaperworkFieldName.PRIMARY_POLICY_HOLDER_FIRST_NAME,
  PaperworkFieldName.PRIMARY_POLICY_HOLDER_MIDDLE_NAME,
  PaperworkFieldName.PRIMARY_POLICY_HOLDER_LAST_NAME,
  PaperworkFieldName.PRIMARY_POLICY_HOLDER_BIRTH_SEX,
  PaperworkFieldName.PRIMARY_POLICY_HOLDER_BIRTH_DATE,
  PaperworkFieldName.PATIENTS_RELATIONSHIP_TO_INSURED,
  PaperworkFieldName.DO_YOU_HAVE_SECONDARY_INSURANCE_INFORMATION,
];

export const SECONDARY_PAYMENT_FORM_FIELD_NAMES = [
  PaperworkFieldName.SECONDARY_INSURANCE_COMPANY,
  PaperworkFieldName.SECONDARY_INSURANCE_PLAN_NAME,
  PaperworkFieldName.SECONDARY_MEMBER_ID,
  PaperworkFieldName.SECONDARY_GROUP_NUMBER,
  PaperworkFieldName.SECONDARY_POLICY_HOLDER_FIRST_NAME,
  PaperworkFieldName.SECONDARY_POLICY_HOLDER_MIDDLE_NAME,
  PaperworkFieldName.SECONDARY_POLICY_HOLDER_LAST_NAME,
  PaperworkFieldName.SECONDARY_POLICY_HOLDER_BIRTH_DATE,
  PaperworkFieldName.SECONDARY_PATIENTS_RELATIONSHIP_TO_INSURED,
];

export const GUARDIAN_FORM_FIELD_NAMES = [
  PaperworkFieldName.GUARDIAN_FIRST_NAME,
  PaperworkFieldName.GUARDIAN_MIDDLE_NAME,
  PaperworkFieldName.GUARDIAN_LAST_NAME,
  PaperworkFieldName.GUARDIAN_BIRTH_DATE,
  PaperworkFieldName.GUARDIAN_RELATIONSHIP_TO_PATIENT,
  PaperworkCopyFormFieldsCheckboxName.GUARDIAN_ADDRESS,
  PaperworkFieldName.GUARDIAN_ADDRESS,
  PaperworkFieldName.GUARDIAN_ADDRESS_TWO,
  PaperworkFieldName.GUARDIAN_CITY,
  PaperworkFieldName.GUARDIAN_STATE,
  PaperworkFieldName.GUARDIAN_ZIP,
  PaperworkFieldName.GUARDIAN_PHONE,
  PaperworkFieldName.GUARDIAN_ALTERNATE_PHONE,
  PaperworkFieldName.GUARDIAN_EMAIL,
];

export const GUARANTOR_FORM_FIELD_NAMES = [
  PaperworkFieldName.GUARANTOR_RELATIONSHIP,
  PaperworkCopyFormFieldsCheckboxName.GUARANTOR_INFO,
  PaperworkFieldName.GUARANTOR_FIRST_NAME,
  PaperworkFieldName.GUARANTOR_LAST_NAME,
  PaperworkFieldName.GUARANTOR_BIRTH_DATE,
  PaperworkFieldName.GUARANTOR_BIRTH_SEX,
  PaperworkFieldName.GUARANTOR_PHONE,
];

export const COPY_PATIENT_FIELDS_TO_GUARANTOR = {
  [PaperworkFieldName.GUARANTOR_FIRST_NAME]: PaperworkFieldName.PATIENT_FIELD_FIRST_NAME,
  [PaperworkFieldName.GUARANTOR_LAST_NAME]: PaperworkFieldName.PATIENT_FIELD_LAST_NAME,
  [PaperworkFieldName.GUARANTOR_BIRTH_DATE]: PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE,
  [PaperworkFieldName.GUARANTOR_BIRTH_SEX]: PaperworkFieldName.PATIENT_FIELD_BIRTH_SEX,
  [PaperworkFieldName.GUARANTOR_PHONE]: PaperworkFieldName.PATIENT_PRIMARY_PHONE,
};

export const EMERGENCY_CONTACT_FIELD_NAMES = [
  PaperworkCopyFormFieldsCheckboxName.EMERGENCY_CONTACT,
  PaperworkFieldName.EMERGENCY_CONTACT_FIRST_NAME,
  PaperworkFieldName.EMERGENCY_CONTACT_MIDDLE_NAME,
  PaperworkFieldName.EMERGENCY_CONTACT_LAST_NAME,
  PaperworkFieldName.EMERGENCY_CONTACT_PHONE,
  PaperworkFieldName.EMERGENCY_CONTACT_RELATIONSHIP_TO_PATIENT,
];

export enum PaperworkFormName {
  PATIENT = 'patient',

  HEALTHCARE_CONTACTS = 'healthcare_contacts',

  EMPLOYER = 'employer',

  INSURANCE = 'insurance',

  GUARDIAN = 'guardian',

  EMERGENCY_CONTACT = 'emergency_contact',

  ADDITIONAL = 'additional',

  VERIFICATION_AND_REVIEW = 'verification_and_review',

  CONSENT = 'consent',

  REVIEW = 'review',
}

export enum ProviderGroupPaperworkFormName {
  PATIENT = 'provider_group_patient',

  MEDICAL = 'medical',

  LIFESTYLE_FACTORS = 'lifestyle_factors',

  HEALTHCARE_CONTACTS = 'healthcare_contacts',

  VERIFICATION_AND_REVIEW = 'verification_and_review',

  REVIEW = 'provider_group_review',
}
