import React, { useState } from 'react';
import { ChoiceCardRadioGroup, ChoiceCardRadioOption } from '@solvhealth/jigsaw';
import { Stack } from '@solvhealth/jigsaw';
import { FormattedResponseOption } from '~/components/Form/types/types';

type Props = {
  options: FormattedResponseOption[];
  onChange?: (...args: any[]) => any;
  type?: 'submit' | 'button' | 'reset';
  value?: string;
};

const RadioChoiceCardGroup = (props: Props) => {
  const { options, onChange, value, type = 'submit' } = props;
  const [selected, setSelected] = useState<string | undefined>(value);

  const handleChange = (changedValue?: string) => {
    setSelected(changedValue);
    onChange?.(changedValue);
  };

  return (
    <Stack space={3} width="100%">
      <ChoiceCardRadioGroup onChange={handleChange} value={selected} {...props}>
        {options.map((option) => {
          const { key, label } = option;
          return <ChoiceCardRadioOption key={key} title={label} type={type} value={key} />;
        })}
      </ChoiceCardRadioGroup>
    </Stack>
  );
};

export default RadioChoiceCardGroup;
