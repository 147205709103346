import { useEffect, useState } from 'react';
import { isClientSide } from '../core/util/system';

export interface ScriptProps {
  /** The src url of the script */
  src: HTMLScriptElement['src'] | null;

  /** Callback once the script is loaded */
  onLoad?: () => void;

  /** A function that returns true if the script is already loaded. Should test that some globals exist. */
  testIfLoaded: () => boolean;
}

type ErrorState = ErrorEvent | null;

/**
 * Loads a script in asynchronously, only allows it to be loaded in once.
 *
 * @returns Loading and error states
 */
export default function useScript({
  src,
  onLoad,
  testIfLoaded,
}: ScriptProps): [boolean, ErrorState] {
  const [loading, setLoading] = useState(Boolean(src));
  const [error, setError] = useState<ErrorState>(null);

  useEffect(() => {
    if (!isClientSide() || !src) return;

    let scriptEl: HTMLScriptElement | null = null;

    /**
     * If the script already exists, we're just going to attach our own listeners to it
     */
    const existing = document.querySelectorAll(`script[src="${src}"]`);
    if (existing.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      scriptEl = existing[0] as HTMLScriptElement;

      // Test if the script has been previously loaded
      if (testIfLoaded()) {
        setLoading(false);
      }
    }

    if (!scriptEl) {
      scriptEl = document.createElement('script');
      scriptEl.setAttribute('src', src);
      document.body.appendChild(scriptEl);
    }

    const handleLoad = () => {
      setLoading(false);
    };
    const handleError = (error: ErrorEvent) => {
      setError(error);
    };

    scriptEl.addEventListener('load', handleLoad);
    scriptEl.addEventListener('error', handleError);

    // eslint-disable-next-line consistent-return
    return () => {
      scriptEl?.removeEventListener('load', handleLoad);
      scriptEl?.removeEventListener('error', handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  /**
   * Trigger our onLoad callback automatically
   */
  useEffect(() => {
    if (!loading && !error) {
      onLoad?.();
    }
  }, [error, loading, onLoad]);

  return [loading, error];
}
