export class TileType {
  static VISIT = 'v';

  static PROFILE_CLINIC = 'pc';

  static PROFILE_TELEMEDICINE = 'pt';

  static SPECIALIST = 'sp';

  static POPULAR_AVAILABLE = 'pa';

  static INSURANCE_SPEND_WIDGET = 'isw';

  static HEALTH_CHECKLIST = 'hcl';

  static GET_STARTED = 'gs';

  static EMPTY = 'e';

  static INSURANCE_INSIGHT = 'ii';

  static INSURANCE_PROGRESS = 'ip';

  static COPAY_COINSURANCE = 'cc';

  static COPAY_COINSURANCE_BY_SERVICE = 'ccbs';

  static COPAY_COINSURANCE_BY_SERVICE_COMING_SOON = 'ccbscs';

  static COPAY_COINSURANCE_DETAILS_MODAL = 'ccdm';

  static SURVEY_MONKEY_LINK = 'sml';
}

export class TileUsers {
  static IN_MARKET = 'im';

  static OUT_OF_MARKET = 'oom';

  static ALL = 'all';
}

export class TileRules {
  static IS_NOT_CHILD = 'inc';

  static IS_NOT_MALE = 'inm';

  static VISIT_BOOKED = 'vb';

  static VISIT_COMPLETED = 'vc';

  static HAS_NO_PHARMACY = 'hnp';

  static HAS_INSURANCE = 'hi';

  static HAS_NO_INSURANCE = 'hni';

  static FULL_INSURANCE_DATA = 'fi';

  static ANOTHER_PROFILE_ADDED = 'apa';

  static TILE_DISMISSED = 'td';

  static IS_INSURANCE_AETNA = 'i-aetna';

  static IS_INSURANCE_CIGNA = 'i-cigna';

  static IS_INSURANCE_UNITED = 'i-united';

  static IS_INSURANCE_BCBS = 'i-bcbs';

  static IS_INSURANCE_BCBS_CA = 'i-bcbs-ca';

  static IS_INSURANCE_BCBS_TX = 'i-bcbs-tx';

  static INSURANCE_INSIGHT_HIDDEN = 'i-i-h';

  static IS_WOMAN_OVER_39 = 'w-39';

  static IS_WOMAN_OVER_65 = 'w-65';

  static IS_AGE_50_75 = 'a-50-75';

  static IS_WOMAN_AGE_18_65 = 'w-18-65';

  static IS_WOMAN_AGE_25_42 = 'w-25-42';

  static HAS_NO_BOOKINGS = 'hnb';

  static HAS_BOOKINGS = 'hb';

  static HAS_INDIVIDUAL_DEDUCTIBLE_DATA = 'hidd';

  static HAS_INDIVIDUAL_OOP_MAX_DATA = 'hiomd';

  static HAS_FAMILY_DEDUCTIBLE_DATA = 'hfdd';

  static HAS_FAMILY_OOP_MAX_DATA = 'hfomd';

  static HAS_NEITHER_COPAY_NOR_COINSURANCE_FOR_IN_NETWORK_OR_OUT_OF_NETWORK =
    'h-n-either-cop-cos-in-net-or-out-of';

  static HAS_ONE_OF_EACH_COPAY_OR_COINSURANCE_FOR_IN_NETWORK_AND_OUT_OF_NETWORK =
    'h-one-of-each-cop-cos-in-net-and-out-of';

  static NO_FEEDBACK_GIVEN = 'nfg';

  static IS_NOT_NATIVE_APP = 'noapp';

  static IS_NOT_TELEMED_IN_SCHOOL_LOCATIONS = 'intisl';

  static HAS_TELEMED_COPAY_DATA = 'htcd';

  static HAS_NO_COPAY_DATA = 'hncd';

  static HAS_URGENT_CARE_COPAY_DATA = 'huccd';

  static HAS_PCP_COPAY_DATA = 'hpcd';

  static HAS_EMERGENCY_ROOM_COPAY_DATA = 'hercd';

  static HAS_SPECIALIST_COPAY_DATA = 'hscd';

  static HAS_FEWER_THAN_THREE_COPAYS = 'hfttc';
}

export class TileAction {
  static RECENT_URGENT_CARE = 'uc';

  static RECENT_PEDIATRIC = 'ped';

  static BOOKING_FORM = 'bf';

  static COMING_SOON = 'cs';

  static LINK = 'link';

  static LOCATION_LIST = 'li';

  static SRP = 'srp';

  static FIND_CARE_TAB = 'fc';

  static CDP = 'cdp';

  static ADD_PROFILE = 'ap';

  static INSURANCE_TAB = 'it';

  static OPEN_MODAL = 'om';

  static TRACK_CLICK = 'tc';
}

export class TileIds {
  static DENTIST = 'dentist';

  static OPTOMETRIST = 'optometrist';

  static CHIROPRACTOR = 'chiropractor';

  static OBGYN = 'obgyn';

  static DERMATOLOGIST = 'dermatologist';

  static ALLERGIST = 'allergist';

  static IMAGING = 'imaging';

  static ADD_INSURANCE = 'add-insurance';

  static INSURANCE_SPEND_WIDGET = 'insurance-spend-widget';

  static ADD_FAMILY = 'add-family';

  static FIND_CARE = 'find-care';

  static VISIT_UPCOMING = 'visit upcoming';

  static VISIT_COMPLETED = 'visit completed';

  static CLINIC = 'clinic';

  static POPULAR_AVAILABLE = 'popular-available';

  static LOCALLY_LOVED_OOM = 'locally-loved-oom';

  static FAVORITE_CLINIC = 'favorite-clinic';

  // Health checklist reminder tiles
  static FLU_SHOT = 'flu_shot';

  static ANNUAL_CHECK_UP_TWENTY = 'annual_check_up_twenty';

  static ANNUAL_CHECK_UP_FORTY = 'annual_check_up_forty';

  static ANNUAL_CHECK_UP_FIFTY = 'annual_check_up_fifty';

  static TEETH_CLEANING = 'teeth_cleaning';

  static EYE_EXAMS = 'eye_exams';

  static PAP_SMEAR = 'pap_smear';

  static MAMMOGRAM = 'mammogram';

  static COLORECTA_CANCER_SCREENING = 'colorectal_cancer_screening';

  // chat
  static CHAT = 'chat';

  // insurance insights
  static INSURANCE_INSIGHT_BLOOD_PRESSURE = 'blood_pressure';

  static INSURANCE_INSIGHT_COLON_CANCER_SCREENING = 'colon_cancer_screening';

  static INSURANCE_INSIGHT_QUIT_FOR_LIFE = 'quit_for_life';

  static INSURANCE_INSIGHT_ANNUAL_WELLNESS = 'annual_wellness';

  static INSURANCE_INSIGHT_PAP = 'pap_smear';

  static INSURANCE_INSIGHT_OSTEOPOROSIS = 'osteoporosis';

  static INSURANCE_INSIGHT_IMMUNIZATION = 'immunization';

  static INSURANCE_INSIGHT_DIET_COUNSELING = 'diet_counseling';

  static INSURANCE_INSIGHT_DIABETES_SCREENING = 'diabetes_screening';

  static INSURANCE_INSIGHT_MAMMOGRAM = 'mammogram';

  static INSURANCE_INSIGHT_GYM_MEMBERSHIP = 'gym_membership';

  static INSURANCE_INSIGHT_GARMIN = 'garmin';

  static INSURANCE_INSIGHT_FITBIT_GARMIN = 'fitbit_garmin';

  static INSURANCE_INSIGHT_NURSE_HOTLINE = 'nurse_hotline';

  static INSURANCE_INSIGHT_ACUPUNCTURE = 'acupuncture';

  static INSURANCE_INSIGHT_BREAST_PUMP = 'breast_pump';

  static INSURANCE_INSIGHT_THERAPEUTIC_MASSAGE = 'therapeutic_massage';

  static INSURANCE_INSIGHT_LASIK = 'lasik';

  static INSURANCE_INSIGHT_ROUTINE_EYE = 'routine_eye';

  static INSURANCE_INSIGHT_APPAREL = 'apparel';

  static INSURANCE_INSIGHT_CONTACT_LENSES = 'contact_lenses';

  static INSURANCE_INSIGHT_CHIROPRACTOR = 'chiropractor';

  static INSURANCE_INSIGHT_VITAMIX_BLENDER = 'vitamix_blender';

  static INSURANCE_INSIGHT_WELLVOLUTION_PROGRAM = 'wellvolution_program';

  static INSURANCE_INSIGHT_ZUMBA_AT_HOME = 'zumba_at_home';

  static INSURANCE_INSIGHT_PHYSICAL_THERAPY = 'physical_therapy';

  static INSURANCE_INSIGHT_JENNY_CRAIG = 'jenny_craig';

  static INSURANCE_INSIGHT_GAIAM_YOGA = 'gaiam_yoga';

  static INSURANCE_INSIGHT_GLASSES = 'glasses';

  static INSURANCE_INSIGHT_OCCUPATIONAL_THERAPY = 'occupational_therapy';

  static INSURANCE_INSIGHT_FITNESS_MONITORS = 'fitness_monitors';

  static INSURANCE_INSIGHT_STRESS = 'managing_stress';

  static INSURANCE_INSIGHT_FLU_SHOT = 'flu_shot';

  // deductible progress
  static DEDUCTIBLE_PROGRESS_IN_NETWORK_INDIVIDUAL_DEDUCTIBLE = 'in_network_individual_deductible';

  static DEDUCTIBLE_PROGRESS_IN_NETWORK_FAMILY_DEDUCTIBLE = 'in_network_family_deductible';

  static DEDUCTIBLE_PROGRESS_OUT_OF_NETWORK_INDIVIDUAL_DEDUCTIBLE =
    'out_of_network_individual_deductible';

  static DEDUCTIBLE_PROGRESS_OUT_OF_NETWORK_FAMILY_DEDUCTIBLE = 'in_network_family_deductible';

  // out of pocket max
  static OUT_OF_POCKET_MAX_IN_NETWORK_INDIVIDUAL_MAX = 'in_network_individual_max';

  static OUT_OF_POCKET_MAX_IN_NETWORK_FAMILY_MAX = 'in_network_family_max';

  static OUT_OF_POCKET_MAX_OUT_OF_NETWORK_INDIVIDUAL_MAX = 'out_of_network_individual_max';

  static OUT_OF_POCKET_MAX_OUT_OF_NETWORK_FAMILY_MAX = 'out_of_network_family_max';

  // copay & coinsurance
  static COPAY = 'copay';

  static COINSURANCE = 'coinsurance';

  // copay & coinsurance by service
  static COPAY_COINSURANCE_BY_SERVICE_UC = 'copay_coinsurance_by_service_uc';

  static COPAY_COINSURANCE_BY_SERVICE_COMING_SOON = 'copay_coinsurance_by_service_coming_soon';

  static COPAY_COINSURANCE_URGENT_CARE = 'copay_coinsurance_by_service_uc';

  static COPAY_CONINSURANCE_PCP = 'copay_coinsurance_by_service_pcp';

  static COPAY_CONINSURANCE_SPECIALIST = 'copay_coinsurance_by_service_specialist';

  static COPAY_CONINSURANCE_ER = 'copay_coinsurance_by_service_er';

  // copay & coinsurance modal
  static COPAY_COINSURANCE_MODAL_IN_NETWORK = 'copay_coinsurance_modal_in_network';

  static COPAY_COINSURANCE_MODAL_OUT_OF_NETWORK = 'copay_coinsurance_modal_out_of_network';

  // SurveyMonkey card
  static SURVEY_MONKEY_INSURANCE_FEEDBACK = 'survey_monkey_insurance_feedback';
}

export class TileIterator {
  static VISITS = 'v';

  static TILES = 't';

  static TRADITIONAL_LOCATIONS = 'tl';

  static HEALTH_CHECKLIST = 'hcli';

  static FAVORITES = 'f';

  static SAVED = 's';

  static NEARBY_LOCATIONS = 'nb';

  static STATIC = 'a';
}

export class CategoryIds {
  static VISITS = 'visits';

  static SAVED = 'saved';

  static FAVORITES = 'favorites';

  static SPECIALTIES = 'specialties';

  static SPECIALTIES_IN_PVS = 'specialties-in-pvs';

  static SPECIALTIES_IN_REBOOKING = 'specialties-in-rebooking';

  static POPULAR_AVAILABLE = 'popular-available';

  static LOCALLY_LOVED_OOM = 'locally-loved-out-of-market';

  static HEALTH_CHECKLIST = 'health-checklist';

  static GET_STARTED = 'get-started';

  static INSURANCE_CTA = 'insurance-cta';

  static ADD_FAMILY_CTA = 'add-family-cta';

  static INSURANCE_INSIGHTS = 'insurance-insights';

  static DEDUCTIBLE_PROGRESS = 'deductible-progress';

  static OUT_OF_POCKET_MAX = 'out-of-pocket-max';

  static COPAY_COINSURANCE = 'copay-coinsurance';

  static COPAY_COINSURANCE_DETAILS_MODAL = 'copay-coinsurance-modal';

  static SURVEY_MONKEY_FEEDBACK = 'survey-monkey-feedback';

  static INSURANCE_SPEND_WIDGET = 'insurance-spend-widget';

  static SOLV_PLUS_TELEMED = 'SOLV_PLUS_TELEMED';
}

export class TileStyle {
  static CIRCULAR = 'circular';
}
