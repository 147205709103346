import {
  CUSTOM_EMPLOYER_RELATED_FIELD_NAMES,
  EMPLOYER_RELATED_FIELD_NAMES,
  PaperworkFieldName,
  WORK_RELATED_INJURY_FIELD_NAMES,
} from '../../paperworkConstants';
import { SectionPageCollection } from '../../interfaces';

export enum EmployerPageNumber {
  PageOne,
  EmployerFormFieldNames,
  CustomEmployerFormFieldNames,
  WorkRelatedInjuryFields,
  DrugScreenAlcoholTestFields,
  PhysicalFields,
}

export const EMPLOYER_PAGES: SectionPageCollection = new Map([
  [
    EmployerPageNumber.PageOne,
    {
      fieldNames: [PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER],
    },
  ],
  [
    EmployerPageNumber.EmployerFormFieldNames,
    {
      fieldNames: EMPLOYER_RELATED_FIELD_NAMES,
      fieldsRequiredToBeValid: [PaperworkFieldName.EMPLOYER],
    },
  ],
  [
    EmployerPageNumber.CustomEmployerFormFieldNames,
    {
      fieldNames: CUSTOM_EMPLOYER_RELATED_FIELD_NAMES,
      fieldsRequiredToBeValid: [PaperworkFieldName.CUSTOM_EMPLOYER],
    },
  ],
  [
    EmployerPageNumber.WorkRelatedInjuryFields,
    {
      fieldNames: WORK_RELATED_INJURY_FIELD_NAMES,
    },
  ],
  [
    EmployerPageNumber.DrugScreenAlcoholTestFields,
    {
      fieldNames: [PaperworkFieldName.WHAT_TYPE_OF_DRUG_SCREEN_OR_ALCOHOL_TEST],
    },
  ],
  [
    EmployerPageNumber.PhysicalFields,
    {
      fieldNames: [PaperworkFieldName.WHAT_TYPE_OF_PHYSICAL],
    },
  ],
]);
