import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MomentUtils from '@date-io/moment';
import Location from '@solvhealth/types/interfaces/Location';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EN, SupportedLocale } from '~/core/util/localizedStr';
import { dateHasOpenHours, dateIsOutsideOfBeyondRange } from '~/core/util/location';
import { colors } from '~/constants/colors';
import { fontFamily, fontSize } from '~/constants/text';
import { useMomentWithLocale } from './momentWithLocale';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      alignCenter: {
        fontFamily: fontFamily.bold,
        fontSize: fontSize.medium,
        letterSpacing: '-0.18px',
      },
    },
    // @ts-expect-error createMuiTheme is dumb
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: 0,
      },
      dayLabel: {
        color: colors.lightText,
        fontFamily: fontFamily.book,
        letterSpacing: '-0.12px',
        fontSize: fontSize.small,
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.gray600,
        '& p': {
          fontFamily: fontFamily.regular,
          fontSize: fontSize.mediumSmall,
        },
      },
      daySelected: {
        backgroundColor: colors.crunchBerry,
        '&:hover': {
          backgroundColor: colors.crunchBerry,
        },
        '& p': {
          fontFamily: fontFamily.bold,
        },
      },
      current: {
        fontFamily: fontFamily.bold,
        color: colors.crunchBerry,
      },
    },
  },
});

type Props = {
  handleDateChange: (...args: any[]) => any;
  shouldEnableAllCalendarDates?: boolean;
  selectedDate: any;
  locale?: SupportedLocale;
  location?: Location;
};

const SolvCalendar = (props: Props) => {
  const { selectedDate, handleDateChange, location, shouldEnableAllCalendarDates } = props;

  const isDateDisabled = useCallback(
    (day: MaterialUiPickersDate) => {
      if (!day || shouldEnableAllCalendarDates) return false;

      /**
       * All of our helper functions assume an English locale, but material-ui
       * might provide us with a moment-object with a non-English locale
       */
      const date = day.clone().locale(EN);

      if (!location) return true;

      return !dateHasOpenHours(location, date) || dateIsOutsideOfBeyondRange(location, date);
    },
    [location, shouldEnableAllCalendarDates]
  );

  const localizedMomentInstance = useMomentWithLocale(props.locale ?? EN, moment);

  return (
    <MuiPickersUtilsProvider
      libInstance={localizedMomentInstance}
      locale={props.locale ?? EN}
      utils={MomentUtils}
    >
      <ThemeProvider theme={theme}>
        <Calendar
          date={selectedDate}
          disablePast
          onChange={handleDateChange}
          shouldDisableDate={isDateDisabled}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default SolvCalendar;
