import React, { PureComponent, ReactNode } from 'react';
import logger from '../core/logger/index';
import { ERROR_CAUGHT } from '../core/analytics/events';
import { analyticsTrackEvent } from '../core/analytics';

type Props = {
  children: ReactNode;
  fallbackUI: JSX.Element | null;
};

type State = {
  error: Error | null;
  hasError: boolean;
};

class ErrorBoundary extends PureComponent<Props, State> {
  static getDerivedStateFromError = (error: any) => {
    return { hasError: true, error };
  };

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch = (error: Error, info: any) => {
    logger.error(error);
    analyticsTrackEvent(ERROR_CAUGHT, { error, info });
  };

  render = () => {
    const { hasError, error } = this.state;
    const { children, fallbackUI } = this.props;

    if (hasError && fallbackUI) {
      return React.cloneElement(fallbackUI, { error });
    }

    return children;
  };
}

export default ErrorBoundary;
