import { getCurrentPath } from '../util/url';
import {
  isBookable,
  isPaperworkRequired,
  isPaymentsEnabled,
  isPaymentsRequired,
  isSolvPartner,
  isPhotoIdUploadEnabled,
  isPhotoIdFrontRequired,
  isPhotoIdBackRequired,
  getAssociatedTelemedLocationId,
  isCovidTestingV1,
  isCovidTestingOnly,
  hasCovidAntibodyTest,
  hasCovidTest,
} from '../util/location';
import { isIframed } from '../util/system';
import { safeGet } from '../util/object';
import { isEmpty } from '../util/empty';

const getTimeBucket = (msTimeDelta: any) => {
  if (msTimeDelta < 2000) return 'less than 2s';
  if (msTimeDelta < 4000) return '2s - 4s';
  if (msTimeDelta < 6000) return '4s - 6s';
  if (msTimeDelta < 8000) return '6s - 8s';
  if (msTimeDelta < 10000) return '8s - 10s';
  if (msTimeDelta < 12000) return '10s - 12s';
  if (msTimeDelta < 14000) return '12s - 14s';
  if (msTimeDelta < 16000) return '14s - 16s';
  if (msTimeDelta < 20000) return '16s - 20s';
  if (msTimeDelta < 30000) return '20s - 30s';
  if (msTimeDelta < 40000) return '30s - 40s';
  if (msTimeDelta < 50000) return '40s - 50s';
  if (msTimeDelta < 60000) return '50s - 60s';

  return 'greater than 1m';
};

const getLocationTrackingProperties = (location: any, sessionTrackingProperties?: any) => {
  const {
    id,
    uclId,
    city,
    state,
    zipCode,
    market,
    platformType,
    isViewable,
    isTelemed,
    isStandaloneTelemed,
    isBestMatch,
    isInMarketPlace,
    isPaperworkEnabled,
    ratingSolv,
    ratingNonSolv,
    hasBoost,
    isPerformancePricingEnabled,
  } = location;

  return {
    city,
    isBestMatch,
    isBookable: isBookable(location),
    isIframed: isIframed(),
    isInMarketPlace,
    isPaperworkEnabled,
    isPaperworkRequired: isPaperworkRequired(location),
    isPaymentsEnabled: isPaymentsEnabled(location),
    isPaymentsRequired: isPaymentsRequired(location),
    isSolvPartner: isSolvPartner(location),
    isCovidTestingV1: isCovidTestingV1(location),
    hasCovidAntibodyTest: hasCovidAntibodyTest(location.servicesObj),
    hasCovidTest: hasCovidTest(location.servicesObj),
    isStandaloneTelemed,
    isCovidTestingOnly: isCovidTestingOnly(location),
    isTelemed,
    isViewable,
    locationId: id,
    market,
    partner: isSolvPartner(location),
    path: getCurrentPath(),
    platformType,
    ratingNonSolv,
    ratingSolv,
    state,
    trackingPropertiesSessionId: safeGet(sessionTrackingProperties)('sessionID'),
    uclId,
    zipCode,
    isPhotoIdUploadEnabled: isPhotoIdUploadEnabled(location),
    isPhotoIdFrontRequired: isPhotoIdFrontRequired(location),
    isPhotoIdBackRequired: isPhotoIdBackRequired(location),
    hasAssociatedTelemedLocation: !isEmpty(getAssociatedTelemedLocationId(location)),
    hasBoost,
    isPerformancePricingEnabled,
  };
};

export { getTimeBucket, getLocationTrackingProperties };
