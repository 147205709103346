import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../constants/colors';

type OwnProps = {
  color?: string;
  size?: number;
  className?: string;
};

type Props = OwnProps & typeof Loading.defaultProps;

class Loading extends PureComponent<Props> {
  static defaultProps = {
    color: colors.borderLine,
    size: 50,
  };

  render = () => (
    <CircularProgress
      className={this.props.className}
      size={this.props.size}
      style={{ color: this.props.color }}
    />
  );
}

export default Loading;
