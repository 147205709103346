import React from 'react';

export const ADD_YOUR_AVAILABILITY = '/add-your-availability';

export default {
  path: ADD_YOUR_AVAILABILITY,
  get: ({ query }: any) =>
    import(
      /* webpackChunkName: 'addYourAvailability' */ '../../components/AddYourAvailability'
    ).then((module) => {
      const AddYourAvailability = module.default;
      return {
        chunks: ['addYourAvailability'],

        component: <AddYourAvailability query={query} />,
        accountWrapper: {
          loginRequired: false,
          nonBlocking: true,
        },
      };
    }),
};

if (module.hot) {
  module.hot.accept();
}
