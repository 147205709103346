import { useContext, useEffect } from 'react';
import AuthenticationContext from '../../../core/login/AuthenticationContext';
import { isModalOpen } from '../util/modal';
import { LOGIN_SIGN_UP_MODAL } from '../../LoginSignUpModal';

export default (runtime: any, closeModal: any) => {
  const { isLoggedIn } = useContext(AuthenticationContext);

  const isLoginModalOpen = isModalOpen(runtime.modal, LOGIN_SIGN_UP_MODAL);
  useEffect(() => {
    if (isLoggedIn && isLoginModalOpen) {
      closeModal(LOGIN_SIGN_UP_MODAL)();
    }
  }, [closeModal, isLoggedIn, isLoginModalOpen]);
};
