import concat from 'lodash/concat';
import compact from 'lodash/compact';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import Location from '@solvhealth/types/interfaces/Location';
import { shuffle } from 'lodash';
import { hasAvailabilityToday, hasAvailabilityTomorrow } from '../../../../../core/util/location';
import {
  getFirstAvailableAppointmentTime,
  getLocationsSlotsAfterRequestedAppointmentTime,
} from '../../../../../core/location/slots';
import { isRequestedInsuranceMatchingLocation } from '../../../../../core/util/insurance';
import { SrpSortOrder } from '~/reducers/searchPreferences';

const REQUESTED_APPOINTMENT_TIME_BUFFER = 30; // minutes
const MIN_DISTANCE_FROM_SEARCH = 8047; // 5 miles in meters

const distanceComparator = (locationA: Location, locationB: Location) =>
  locationA.distanceFromCurrentLocation - locationB.distanceFromCurrentLocation;

const connectPartnerComparator = (locationA: Location, locationB: Location) => {
  if (locationA.isPerformancePricingEnabled && !locationB.isPerformancePricingEnabled) {
    return -1;
  }
  if (locationB.isPerformancePricingEnabled && !locationA.isPerformancePricingEnabled) {
    return 1;
  }
  return 0;
};

const ratingComparator = (locationA: Location, locationB: Location) =>
  parseFloat(locationB.ratingSolv) || 0 - parseFloat(locationA.ratingSolv) || 0;

const requestedInsuranceAcceptedComparator =
  (requestedInsurance: any) => (locationA: Location, locationB: Location) => {
    if (
      isRequestedInsuranceMatchingLocation(requestedInsurance, locationA) &&
      !isRequestedInsuranceMatchingLocation(requestedInsurance, locationB)
    ) {
      return -1;
    }
    if (
      isRequestedInsuranceMatchingLocation(requestedInsurance, locationB) &&
      !isRequestedInsuranceMatchingLocation(requestedInsurance, locationA)
    ) {
      return 1;
    }

    return 0;
  };

const getUpdatedLocationWithSlotsAfterTime = (location: any, requestedAppointmentTime: any) => {
  const updatedRequestedAppointmentTime = moment(requestedAppointmentTime)
    .tz(location.timeZone)
    .subtract(REQUESTED_APPOINTMENT_TIME_BUFFER, 'minutes')
    .valueOf();
  const slotsAfter = getLocationsSlotsAfterRequestedAppointmentTime(
    location,
    updatedRequestedAppointmentTime
  );
  return { ...location, slots: slotsAfter };
};

export const getUpdatedLocationsWithSlotsAfterTime = (
  locations: any,
  requestedAppointmentTime: any
) =>
  locations.map((location: any) =>
    getUpdatedLocationWithSlotsAfterTime(location, requestedAppointmentTime)
  );

const availabilityComparator =
  (newBooking: any, precision: 'day' | 'time' = 'day') =>
  (locationA: Location, locationB: Location) => {
    if (precision === 'day') {
      const todayA = hasAvailabilityToday(locationA, newBooking);
      const todayB = hasAvailabilityToday(locationB, newBooking);
      if (todayA && todayB) {
        return 0;
      }
      if ((todayA && !todayB) || (todayB && !todayA)) {
        return todayA ? -1 : 1;
      }
      const tomorrowA = hasAvailabilityTomorrow(locationA, newBooking);
      const tomorrowB = hasAvailabilityTomorrow(locationB, newBooking);
      if (tomorrowA && tomorrowB) {
        return 0;
      }
      if ((tomorrowA && !tomorrowB) || (tomorrowB && !tomorrowA)) {
        return tomorrowA ? -1 : 1;
      }
      return 0;
    }

    const firstA = moment(getFirstAvailableAppointmentTime(locationA));
    const firstB = moment(getFirstAvailableAppointmentTime(locationB));
    if (!firstA.isValid() && !firstB.isValid()) {
      return 0;
    }
    if (!firstA.isValid()) {
      return 1;
    }
    if (!firstB.isValid()) {
      return -1;
    }
    if (firstA.isSame(firstB)) {
      return 0;
    }
    return firstA.isBefore(firstB) ? -1 : 1;
  };

/**
 * Combine the searchResults obj into a concatenated Array.
 *
 * @param {{ bestMatchResults: {Array},
 *           nonPartnersResults: Array,
 *           partnersResults: Array }} searchResults
 * @returns {Array}
 */
export const combineSearchResults = (searchResults: any) =>
  compact(
    concat(
      searchResults.partnersResults,
      searchResults.telemedConnectLocations,
      searchResults.nonPartnersResults,
      searchResults.bestMatchResults
    )
  );

const primaryAvailabilitySecondaryDistanceComparator = (
  locationA: Location,
  locationB: Location,
  newBooking: any,
  precision: 'day' | 'time' = 'day'
) => {
  let comparatorValue = availabilityComparator(newBooking, precision)(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = distanceComparator(locationA, locationB);
  return comparatorValue;
};

const primaryRatingSecondaryAvailabilityComparator = (
  locationA: Location,
  locationB: Location,
  newBooking: any
) => {
  let comparatorValue = ratingComparator(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = availabilityComparator(newBooking)(locationA, locationB);
  return comparatorValue;
};

const isCloseBookableLocation = (location: Location) =>
  location.isBookable &&
  location.isSolvPartner &&
  !isEmpty(location.slots) &&
  location.distanceFromCurrentLocation < MIN_DISTANCE_FROM_SEARCH;

const meetsMarketPlacePriorityCriteria = (location: Location) =>
  isCloseBookableLocation(location) && location.isMarketPlacePriority;

const meetsPerformancePricingCriteria = (location: Location) =>
  isCloseBookableLocation(location) && location.isPerformancePricingEnabled;

const performancePricingEnabledComparator = (locationA: Location, locationB: Location) => {
  if (meetsPerformancePricingCriteria(locationA) && !meetsPerformancePricingCriteria(locationB)) {
    return -1;
  }
  if (meetsPerformancePricingCriteria(locationB) && !meetsPerformancePricingCriteria(locationA)) {
    return 1;
  }
  return 0;
};

const marketPlacePriorityComparator = (locationA: Location, locationB: Location) => {
  if (meetsMarketPlacePriorityCriteria(locationA) && !meetsMarketPlacePriorityCriteria(locationB)) {
    return -1;
  }
  if (meetsMarketPlacePriorityCriteria(locationB) && !meetsMarketPlacePriorityCriteria(locationA)) {
    return 1;
  }
  return 0;
};

export const relevancyComparator = (locationA: Location, locationB: Location, newBooking: any) => {
  let comparatorValue = marketPlacePriorityComparator(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = performancePricingEnabledComparator(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = availabilityComparator(newBooking)(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = distanceComparator(locationA, locationB);
  return comparatorValue;
};

const primaryConnectSecondaryDistanceComparator = (locationA: Location, locationB: Location) => {
  let comparatorValue = connectPartnerComparator(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = distanceComparator(locationA, locationB);
  return comparatorValue;
};

export const sortPartnerResults = (newBooking: any, locations: any) =>
  locations.sort((a: any, b: any) =>
    primaryAvailabilitySecondaryDistanceComparator(a, b, newBooking)
  );

export const sortPartnerResultsByRelevancy = (newBooking: any, locations: Location[]) =>
  locations.sort((a: Location, b: Location) => relevancyComparator(a, b, newBooking));

export const sortPartnerResultsBySoonestAvailable = (newBooking: any, locations: Location[]) =>
  locations.sort((a: Location, b: Location) =>
    primaryAvailabilitySecondaryDistanceComparator(a, b, newBooking, 'time')
  );

export const sortPartnerResultsByRating = (newBooking: any, locations: Location[]) =>
  locations.sort((a: Location, b: Location) =>
    primaryRatingSecondaryAvailabilityComparator(a, b, newBooking)
  );

const sortTelemedOnlyResults = (newBooking: any, locations: Location[]) => {
  const locationsWithAvailabilityToday = shuffle(
    locations.filter((location: Location) => hasAvailabilityToday(location, newBooking))
  );
  const locationsWithAvailabilityTomorrowOnly = shuffle(
    locations.filter(
      (location: Location) =>
        hasAvailabilityTomorrow(location, newBooking) && !hasAvailabilityToday(location, newBooking)
    )
  );
  const locationsWithNoAvailability = shuffle(
    locations.filter(
      (location: Location) =>
        !hasAvailabilityTomorrow(location, newBooking) &&
        !hasAvailabilityToday(location, newBooking)
    )
  );
  return [
    ...locationsWithAvailabilityToday,
    ...locationsWithAvailabilityTomorrowOnly,
    ...locationsWithNoAvailability,
  ];
};

export const getSortOrderFromFilters = ({
  sortOrder,
  serviceFilters: { isTelemed, isMobileUrgentCare, isCovid },
}: {
  sortOrder: string;
  serviceFilters: { isTelemed?: boolean; isMobileUrgentCare?: boolean; isCovid?: boolean };
}) => {
  if (isTelemed && !isMobileUrgentCare && !isCovid) return sortTelemedOnlyResults;
  if (sortOrder === SrpSortOrder.Relevance) return sortPartnerResultsByRelevancy;
  if (sortOrder === SrpSortOrder.Rating) return sortPartnerResultsByRating;
  if (sortOrder === SrpSortOrder.SoonestAvailable) return sortPartnerResultsBySoonestAvailable;
  return sortPartnerResults;
};

export const primaryRequestedInsuranceAcceptedSecondaryAvailabilityTertiaryDistanceComparator = (
  locationA: Location,
  locationB: Location,
  requestedInsurance: any,
  newBooking: any
) => {
  let comparatorValue = requestedInsuranceAcceptedComparator(requestedInsurance)(
    locationA,
    locationB
  );
  if (comparatorValue !== 0) {
    return comparatorValue;
  }

  comparatorValue = availabilityComparator(newBooking)(locationA, locationB);
  if (comparatorValue !== 0) {
    return comparatorValue;
  }
  comparatorValue = distanceComparator(locationA, locationB);
  return comparatorValue;
};

export const sortNonBookableResults = (locations: Location[]) =>
  locations.sort((a: Location, b: Location) => primaryConnectSecondaryDistanceComparator(a, b));
