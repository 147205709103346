import { call, put, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiGet, apiPost, apiPatch } from '../core/dapi';
import { getLocationUrl } from '../core/dapi/location';
import { receiveLocation } from '../actions/location';
import { bookingError, receiveBooking } from '../actions/account';
import { getBookingById } from '../core/dapi/newBooking';
import {
  bookingNotesError,
  bookingNotesFetching,
  bookingNotesReceived,
  bookingNotesSubmitting,
} from '../ducks/booking/notes';
import { getBookingNotesUrl } from '../core/dapi/bookingNotes';
import { bookingImagesError, bookingImagesReceived } from '../ducks/booking/images';
import { AccountFileType } from '../constants/index';
import { getAccountImagesUrl } from '../core/util/account';

class PostVisitSummarySagaActions {
  static CLEAR_BOOKING_NOTES = 'saga/PVS_CLEAR_DATA';

  static FETCH_BOOKING_NOTES = 'saga/PVS_FETCH_BOOKING_NOTES';

  static FETCH_DATA = 'saga/PVS_FETCH_DATA';

  static FETCH_IMAGES = 'saga/PVS_FETCH_IMAGES';

  static SAVE_BOOKING_NOTES = 'saga/PVS_SAVE_BOOKING_NOTES';

  static UPDATE_BOOKING_NOTES = 'saga/PVS_UPDATE_BOOKING_NOTES';
}

function* fetchImages({
  accountId,
  bookingId,
  userProfileId,
  imageType = AccountFileType.PVS_PHOTO,
}: any) {
  try {
    const url = getAccountImagesUrl({
      bookingId,
      accountId,
      userProfileId,
      imageType,
    });
    const bookingImagesResponse = yield call(apiGet, url);
    yield put(bookingImagesReceived({ bookingId, ...bookingImagesResponse }));
  } catch (e) {
    yield put(bookingImagesError({ bookingId, ...e }));
  }
}

function* fetchData({ bookingId }: any) {
  try {
    const bookingResponse = yield call(apiGet, getBookingById(bookingId));
    yield put(receiveBooking(bookingResponse));

    const locationResponse = yield call(apiGet, getLocationUrl(bookingResponse.location_id));

    yield put(receiveLocation(locationResponse));
  } catch (e) {
    yield put(bookingError(e));
  }
}

function* fetchNotes({ bookingId }: any) {
  try {
    yield put(bookingNotesFetching({ bookingId }));

    const result = yield call(apiGet, getBookingNotesUrl(bookingId));
    yield put(bookingNotesReceived({ bookingId, result }));
  } catch (e) {
    yield put(bookingNotesError({ bookingId, ...e }));
  }
}

function* saveNotes({ bookingId, notes, prescriptionFilled, prescriptions, onSuccess }: any) {
  try {
    yield put(bookingNotesSubmitting({ bookingId, submitting: true }));
    const postData = {
      notes,
      prescription_filled: prescriptionFilled,
      prescriptions,
    };

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const result = yield call(apiPost, getBookingNotesUrl(bookingId), postData);
    yield put(bookingNotesReceived({ bookingId, result }));
    if (onSuccess) onSuccess();
  } catch (e) {
    yield put(bookingNotesError({ bookingId, message: e.message }));
  } finally {
    yield put(bookingNotesSubmitting({ bookingId, submitting: false }));
  }
}

function* updateNotes({ bookingId, notes, prescriptionFilled, prescriptions, onSuccess }: any) {
  try {
    yield put(bookingNotesSubmitting({ bookingId, submitting: true }));
    const postData = {
      notes,
      prescription_filled: prescriptionFilled,
      prescriptions,
    };

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const result = yield call(apiPatch, getBookingNotesUrl(bookingId), postData);
    yield put(bookingNotesReceived({ bookingId, result }));
    if (onSuccess) onSuccess();
  } catch (e) {
    yield put(bookingNotesError({ bookingId, message: e.message }));
  } finally {
    yield put(bookingNotesSubmitting({ bookingId, submitting: false }));
  }
}

function* clearNotes({ bookingId }: any) {
  yield put(bookingNotesReceived({ bookingId, result: null }));
}

function* rootSaga() {
  yield takeEvery(PostVisitSummarySagaActions.CLEAR_BOOKING_NOTES, clearNotes);
  yield takeEvery(PostVisitSummarySagaActions.FETCH_BOOKING_NOTES, fetchNotes);
  yield takeEvery(PostVisitSummarySagaActions.FETCH_IMAGES, fetchImages);
  yield takeEvery(PostVisitSummarySagaActions.FETCH_DATA, fetchData);
  yield takeEvery(PostVisitSummarySagaActions.SAVE_BOOKING_NOTES, saveNotes);
  yield takeEvery(PostVisitSummarySagaActions.UPDATE_BOOKING_NOTES, updateNotes);
}

export { PostVisitSummarySagaActions, rootSaga as default, fetchImages };
