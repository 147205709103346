import moment from 'moment';
import { Cookies } from 'react-cookie';
import { Cookie, CookieSetOptions } from 'universal-cookie';
import {
  deleteUniversalAuthSession,
  persistUniversalAuthSession,
} from '~/core/session/universalAuthSession';
import {
  IS_COOKIE_HTTPS_ONLY,
  LOGIN_INFO_COOKIE_NAME,
  USER_INFO_COOKIE_NAME,
} from '../../config/index';
import logger from '../logger/index';
import { isNativeApp } from '../util/device';
import { NativeFunctions } from '../util/native';
import { isClientSide } from '../util/system';

const ONE_HOUR_IN_SECONDS = 3600; // 60 * 60

export type LoginCookieData = {
  tokenType: string;
  authToken: string;
  id: string;
  persisted: boolean;
};

export type DapiTokenPayload = {
  token_type: string;
  access_token: string;
  account_id: string;
  expires_in: number;
  expiration_timestamp: string;
};

/**
 * Gets the login info from current cookies (server or client side)
 * @param appCookies The incoming cookies
 * @returns The login info object
 */
export function getLoginInfo(appCookies?: Cookies): LoginCookieData | null {
  const cookies = appCookies ?? new Cookies();
  const returningUserCookie = cookies.get(LOGIN_INFO_COOKIE_NAME);
  if (returningUserCookie) {
    return returningUserCookie;
  }

  return null;
}

/**
 * Checks if the current login is persisted
 * @returns Whether the user requested to persist their login info
 */
export function isPersistedLogin(): boolean {
  const returningUserCookie = getLoginInfo();
  return !!returningUserCookie?.persisted;
}

/**
 * Once a login is successful, persists that login info in cookies
 *
 * @note This now makes a fetch request to the mapp server to set a long-lived cookie, while
 *       also setting the client-side token to preserve existing auth functionality that listens
 *       to `document.cookie`.
 * @param token The token payload returned by dapi
 * @param setCookie A function that can set a client-side cookie
 */
export function setLoginInfo(
  token: DapiTokenPayload,
  setCookie: (name: string, value: Cookie, ops: CookieSetOptions) => void
) {
  const isPersisted = token.expires_in > ONE_HOUR_IN_SECONDS;
  const cookieValue = {
    tokenType: token.token_type,
    authToken: token.access_token,
    id: token.account_id,
    persisted: isPersisted,
    expiresAt: token.expiration_timestamp,
  };

  const opts = {
    maxAge: token.expires_in,
    path: '/',
    secure: IS_COOKIE_HTTPS_ONLY,
  };

  if (isNativeApp()) {
    NativeFunctions.setUserAuth.call(
      token.token_type,
      token.access_token,
      token.account_id,
      token.expiration_timestamp,
      isPersisted
    );
  }

  // Legacy auth cookie -- needed because AuthContext listens to document.cookie
  setCookie(LOGIN_INFO_COOKIE_NAME, cookieValue, opts);

  // Sets the new shared session cookie, that is HttpOnly.
  // Intentionally kicking this off async and not listening to the response
  // since it will fail silently and revert us to previous (7-day) auth persistence.
  persistUniversalAuthSession({
    ...cookieValue,
    accountId: cookieValue.id,
    expiresAt: new Date().getTime() + token.expires_in * 1000,
  });
}

/**
 * Sets the user info cookie data given returned from the `/accounts/{id}` endpoint
 * @param response The response from `/accounts/{id}`
 */
export function setUserInfo(response: any) {
  const cookieValue = {
    firstName: response.first_name,
    lastName: response.last_name,
    email: response.email,
    phone: response.phone,
  };

  const opt = {
    expires: moment().add(1, 'years').toDate(),
    path: '/',
    secure: IS_COOKIE_HTTPS_ONLY,
  };

  new Cookies().set(USER_INFO_COOKIE_NAME, cookieValue, opt);
}

/**
 * Helper function to clear user authentication from the native app
 * @param retryCount How many times the function has been called recusively
 */
function clearUserAuthorization(retryCount = 0) {
  const clearAuth = NativeFunctions.clearUserAuth;
  if (clearAuth.exists) clearAuth.call();
  else if (retryCount < 10) setTimeout(clearUserAuthorization, 100, retryCount + 1);
  else
    logger.error(
      new Error(`Couldn't clear user authorization after ${retryCount} tries at 100ms interval`)
    );
}

/**
 * Universal logout handler that un-sets all login cookies or persisted data.
 * @param cookies a cookies object to mutate via
 */
export function clearUserInfo(cookies: { remove: Cookies['remove'] }) {
  cookies.remove(USER_INFO_COOKIE_NAME, { path: '/' });
  cookies.remove(LOGIN_INFO_COOKIE_NAME, { path: '/' });

  // Un-sets the new shared session cookie, that is HttpOnly.
  if (isClientSide()) {
    deleteUniversalAuthSession().catch((e) =>
      logger.error(new Error(`Error deleting universal auth session: ${JSON.stringify(e)}`))
    );
  }

  if (isNativeApp()) clearUserAuthorization();
}
