import { useCallback, useEffect, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { isClientSide } from '../../core/util/system';
import { GOOGLE_MAPS_CLIENT_API_KEY } from '../../config';
import useScript from '../useScript';

const GEOCODE = 'geocode';

/**
 * Asynchronously include the Google Maps API (at most one time)
 */
export function useGoogleMapsAPI(onLoad: () => any) {
  useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_CLIENT_API_KEY}&libraries=places`,
    testIfLoaded() {
      return isClientSide() && !!window.google;
    },
    onLoad,
  });
}

/**
 * Hook to access location autocomplete
 *
 * @returns Autocomplete input props
 */
export function useLocationAutocomplete(initialValue?: string) {
  const {
    value,
    init,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    ready,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      types: [
        'locality',
        'political',
        'administrative_area_level_3',
        'administrative_area_level_2',
        'postal_code',
      ],
      componentRestrictions: { country: 'us' },
    },
    debounce: 300,
    defaultValue: initialValue,
  });

  useGoogleMapsAPI(init);

  return { ready, value, status, data, setValue, clearSuggestions };
}
