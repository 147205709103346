const BOOKING_NOTES_RECEIVED = 'bookingNotes/RECEIVED';
const BOOKING_NOTES_ERROR = 'bookingNotes/ERROR';
const BOOKING_NOTES_SUBMITTING = 'bookingNotes/SUBMITTING';
const BOOKING_NOTES_FETCHING = 'bookingNotes/FETCHING';

export const bookingNotesReceived = (value: any) => ({
  type: BOOKING_NOTES_RECEIVED,
  payload: { value },
});

export const bookingNotesError = (value: any) => ({
  type: BOOKING_NOTES_ERROR,
  payload: { value },
});

export const bookingNotesSubmitting = (value: any) => ({
  type: BOOKING_NOTES_SUBMITTING,
  payload: { value },
});

export const bookingNotesFetching = (value: any) => ({
  type: BOOKING_NOTES_FETCHING,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case BOOKING_NOTES_RECEIVED:
      return {
        ...state,
        [action.payload.value.bookingId]: {
          result: action.payload.value.result,
          error: null,
          submitting: false,
          fetching: false,
        },
      };
    case BOOKING_NOTES_ERROR:
      return {
        ...state,
        [action.payload.value.bookingId]: {
          result: null,
          error: action.payload.value.message,
          submitting: false,
          fetching: false,
        },
      };
    case BOOKING_NOTES_FETCHING:
      return {
        ...state,
        [action.payload.value.bookingId]: {
          result:
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            (state[action.payload.value.bookingId] &&
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              state[action.payload.value.bookingId].result) ||
            null,
          error: null,
          fetching: true,
          submitting: false,
        },
      };
    case BOOKING_NOTES_SUBMITTING:
      return {
        ...state,
        [action.payload.value.bookingId]: {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[action.payload.value.bookingId],
          submitting: action.payload.value.submitting,
        },
      };
    default:
      return state;
  }
};
