import { useQueries, useQuery } from 'react-query';
import { getLocationSlots } from '~/core/dapi/location';

/**
 * @returns the query key for location slots
 */
export function getSlotsQueryKey(locationId: string) {
  return ['location', 'slots', locationId];
}

/**
 * @returns a config for useQuery
 */
export function getSlotsQueryConfig(locationId: string) {
  return {
    queryFn: () => getLocationSlots(locationId),
    queryKey: getSlotsQueryKey(locationId),
    staleTime: 10000,
  };
}

/**
 * @returns the slots query object
 */
export function useLocationSlots(locationId: string) {
  return useQuery(getSlotsQueryConfig(locationId));
}

/**
 * @returns array of queries for location slots
 */
export function usePreloadSlots(
  locationIds: string[],
  { enabled = false }: { enabled?: boolean } = {}
) {
  return useQueries(
    locationIds.map((id) => ({
      ...getSlotsQueryConfig(id),
      enabled,
    }))
  );
}
