import { TextField as JTextField } from '@solvhealth/jigsaw';
import React, { Component, ComponentProps, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import TextField from '../SolvPatternLibrary/TextField';

type MaskedTextFieldProps1 = {
  mask?: any[];
  inputProps?: any;
  placeholderChar?: string;
  showMask?: boolean;
  onChange?: (...args: any[]) => any;
  value?: string;
  className?: string;
};

type MaskedTextFieldState1 = any;

class MaskedTextField extends Component<MaskedTextFieldProps1, MaskedTextFieldState1> {
  state = {
    textMask: '',
  };

  getCustomMaskedInput = (inputProps: any) => {
    const { inputRef, ...other } = inputProps;
    const { mask, placeholderChar, showMask } = this.props;
    const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    return (
      <MaskedInput
        {...other}
        mask={mask || dateMask}
        placeholderChar={placeholderChar || '\u2000'}
        showMask={showMask}
      />
    );
  };

  handleChange = (name: any) => (event: any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render = () => {
    const { textMask } = this.state;
    const { mask, placeholderChar, value, onChange, className, inputProps, ...others } = this.props;
    const MaskedTextFieldProps = {
      ...others,
      className,
      InputProps: {
        value: value || textMask,
        onChange: onChange || this.handleChange('textMask'),
        inputComponent: this.getCustomMaskedInput,
        ...others,
      },
      inputProps,
      InputLabelProps: {
        shrink: value ? true : undefined, // https://material-ui.com/components/text-fields/#shrink
      },
    };

    return <TextField {...MaskedTextFieldProps} />;
  };
}

const MaskedPhoneInput = React.forwardRef(({ mask, ...props }: any, ref) => (
  <MaskedInput
    guide={false}
    mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    ref={ref}
    showMask={false}
    {...props}
  />
));

export const JigsawMaskedPhoneTextField = forwardRef(
  (props: ComponentProps<typeof JTextField>, ref) => {
    return <JTextField ref={ref} {...props} inputComponent={MaskedPhoneInput} />;
  }
);

const MaskedDateInput = React.forwardRef(({ mask, ...props }: any, ref) => (
  <MaskedInput
    mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
    ref={ref}
    showMask={false}
    {...props}
  />
));

export const JigsawMaskedDateTextField = forwardRef(
  (props: ComponentProps<typeof JTextField>, ref) => {
    return <JTextField ref={ref} {...props} inputComponent={MaskedDateInput} inputMode="numeric" />;
  }
);

export const MaskedPhoneTextField = (props: any) => (
  <MaskedTextField
    {...props}
    mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} // jscs: disable
  />
);

const CodeTextField = styled(MaskedTextField)`
  & input {
    text-indent: 0;
    text-align: center;
    letter-spacing: 5px;
  }
  & p {
    text-indent: 0;
  }
`;
export const MaskedCodeTextField = (props: any) => (
  <CodeTextField {...props} mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]} placeholderChar="-" />
);

export default MaskedTextField;
