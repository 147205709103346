import { Reducer } from 'redux';
import Account from '@solvhealth/types/interfaces/Account';
import {
  LOGIN_ACCOUNT_RECEIVED,
  REMOTE_PHONE_VALIDATION_SUBMITTED,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  LOGIN_FAILED,
  LOGIN_ACCOUNT_FROZEN,
  LOGIN_FAIL_MAX_EXCEEDED,
  LOGIN_RESET_LOGIN_DATA,
  LOGIN_NO_ACCOUNT,
  LOGIN_SUBMIT_BUTTON_DISABLED,
  TOKEN_REFRESH_RESPONSE_RECEIVED,
  LOGIN_RESPONSE_RECEIVED,
  LOGIN_SWITCH_LOGIN_SIGN_UP,
  LOGIN_ACTION_OTP_FORM,
  LOGIN_SAVE_SIGN_UP_DATA_STATE,
  LOGIN_SET_AFTER_LOGIN_URL,
  LOGIN_ACCOUNT_FETCHING,
} from '../constants/index';

export interface LoginLoginState {
  access_token?: string;
  account_id?: string;
  expiration_timestamp?: string;
  expires_in?: number;
  grant_type?: string;
  login?: null | any;
  otp_sent?: boolean;
  phone?: string;
  refresh_token?: string;
  token_type?: string;
  phone_valid?: boolean;
  otp_error?: boolean;
  otp_error_detail?: any;
  account_frozen?: boolean;
  no_account?: boolean;
  auth?: any;
  submitButtonDisabled?: boolean;
}

export interface LoginState {
  login?: LoginLoginState;
  signUpData?: any;
  submitting?: boolean;
  action?: string;
  fetching?: boolean;
  accountError?: boolean | null;
  maxFailLimit?: boolean;
  account?: Account;
  redirectAfterLoginUrl?: string;
}

const INITIAL_STATE: LoginState = {};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_SAVE_SIGN_UP_DATA_STATE:
      return {
        ...state,
        signUpData: action.payload.value,
      };
    case LOGIN_SWITCH_LOGIN_SIGN_UP:
      return {
        ...state,
        action: action.payload.value,
      };
    case TOKEN_REFRESH_RESPONSE_RECEIVED:
      return {
        ...state,
        login: {
          ...state.login,
          refresh_token: action.payload.value.refresh_token,
          access_token: action.payload.value.access_token,
          expiration_timestamp: action.payload.value.expiration_timestamp,
        },
      };
    case LOGIN_RESPONSE_RECEIVED:
      return {
        ...state,
        login: { ...state.login, ...action.payload.value },
        submitting: false,
      };
    case REMOTE_PHONE_VALIDATION_SUBMITTED:
      return {
        ...state,
        login: {
          ...state.login,
          phone_valid: action.payload.value.valid,
        },
      };
    case GENERATE_OTP_SUCCESS:
      return {
        login: {
          ...state.login,
          otp_sent: true,
          phone: action.payload.phone,
        },
        action: LOGIN_ACTION_OTP_FORM,
      };
    case GENERATE_OTP_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          otp_sent: false,
          phone: action.payload.phone,
          otp_error: true,
          otp_error_detail: action.payload.value,
        },
      };
    case LOGIN_ACCOUNT_FROZEN:
      return {
        ...state,
        login: {
          ...state.login,
          otp_sent: false,
          phone: action.payload.value,
          accountFrozen: true,
        },
        account: {},
      };
    case LOGIN_NO_ACCOUNT:
      return {
        ...state,
        login: {
          ...state.login,
          otp_sent: false,
          phone: action.payload.value,
          noAccount: true,
        },
        account: {},
      };
    case LOGIN_FAILED:
      return {
        login: {
          ...state.login,
          auth: action.payload.value,
        },
        accountError: true,
        fetching: false,
      };
    case LOGIN_FAIL_MAX_EXCEEDED:
      return {
        login: {
          ...state.login,
        },
        maxFailLimit: true,
      };
    case LOGIN_ACCOUNT_FETCHING:
      return {
        ...state,
        accountError: null,
        fetching: true,
      };
    case LOGIN_ACCOUNT_RECEIVED:
      return {
        ...state,
        login: { ...state.login, login: null },
        account: {
          ...action.payload.value.data,
          role: action.payload.value.meta.role,
        },
        accountError: null,
        fetching: false,
      };
    case LOGIN_SET_AFTER_LOGIN_URL:
      return {
        ...state,
        redirectAfterLoginUrl: action.payload.value,
      };
    case LOGIN_SUBMIT_BUTTON_DISABLED:
      return {
        ...state,
        login: {
          ...state.login,
          submitButtonDisabled: action.payload.value,
        },
      };
    case LOGIN_RESET_LOGIN_DATA:
      return {};
    default:
      return state;
  }
};

export default reducer;
