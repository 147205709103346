import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import {
  AppointmentReason,
  AppointmentReasons,
} from '@solvhealth/types/interfaces/ProviderGroup/AppointmentReason';
import ExternalSlot from '@solvhealth/types/interfaces/ProviderGroup/ExternalSlot';

import Location from '@solvhealth/types/interfaces/Location';
import Provider, { ProvidersCollection } from '@solvhealth/types/interfaces/Provider';
import { SpecialtiesCollection } from '@solvhealth/types/interfaces/Specialty';
import { ProviderSlots } from '@solvhealth/types/interfaces/Provider';
import {
  EXTERNAL_SLOT_DATE_FETCH_FORMAT,
  FETCH_SLOTS_INTERVAL_DAYS,
  PRIMARY_CARE_SPECIALTY_NAME,
  PRIMARY_CARE_SUBSPECIALTIES,
  SpecialtyNames,
} from './constants';
import ValidProviderGroupQueryParams from './interfaces/ValidProviderGroupQueryParams';
import { STRING_BOOLEANS } from '~/core/util/string';
import { defaultExternalSlotDateRange, Slots } from '../../ducks/providerGroups';
import { SearchFilters } from './interfaces';
import { isFirstDateAfterSecondDate, isFirstDateBeforeSecondDate } from '~/core/util/date';

enum AppointmentReasonTypes {
  NEW = 'new',
  EXISTING = 'existing',
  ALL = 'all',
}

const isAppointmentTypeAvailableForNewPatients = (appointmentReason: AppointmentReason) =>
  [AppointmentReasonTypes.NEW, AppointmentReasonTypes.ALL].includes(appointmentReason.reason_type);

const isAppointmentTypeAvailableForExistingPatients = (appointmentReason: AppointmentReason) =>
  [AppointmentReasonTypes.EXISTING, AppointmentReasonTypes.ALL].includes(
    appointmentReason.reason_type
  );

const isAppointmentTypeAvailableForAppointmentReasonType = (
  appointmentReason: AppointmentReason,
  appointmentReasonType: AppointmentReasonTypes
) => {
  if (appointmentReasonType === AppointmentReasonTypes.NEW)
    return isAppointmentTypeAvailableForNewPatients(appointmentReason);
  return isAppointmentTypeAvailableForExistingPatients(appointmentReason);
};

const getAppointmentReasonsFilteredByAppointmentReasonType = (
  appointmentReasons: AppointmentReasons,
  appointmentReasonType: AppointmentReasonTypes
) => {
  if (!appointmentReasons) return [];
  return Object.values(appointmentReasons).filter((reason) =>
    isAppointmentTypeAvailableForAppointmentReasonType(reason, appointmentReasonType)
  );
};

const getReasonOptionsByReasonName = (
  appointmentReasons: AppointmentReasons,
  appointmentReasonType: AppointmentReasonTypes = AppointmentReasonTypes.ALL
) => {
  const reasonOptions = getAppointmentReasonsFilteredByAppointmentReasonType(
    appointmentReasons,
    appointmentReasonType
  );
  return reasonOptions.reduce(
    (acc: { [reasonName: string]: AppointmentReason }, reasonOption) =>
      Object.assign(acc, { [reasonOption.reason_name]: reasonOption }),
    {}
  );
};

const getSlotsForProvider = (providerId: string, externalSlots: ExternalSlot[]) =>
  externalSlots?.filter((appointment: ExternalSlot) => appointment.provider_id === providerId);

const getSlotsForProviderOnDate = (
  providerSlots: ExternalSlot[],
  date: moment.Moment,
  timeZone: string
) => {
  return providerSlots?.filter((slot) =>
    date.isSame(moment(slot.appointment_date).tz(timeZone), 'day')
  );
};

/**
 *
 * @param externalSlots
 * @param appointmentReasons
 * @returns
 */

const getExternalSlotsFilteredByAppointmentReasonId = (
  externalSlots?: ExternalSlot[],
  appointmentReasons?: AppointmentReason[]
) => {
  const reasonIds: number[] = [];
  appointmentReasons?.forEach((reason) => reasonIds.push(reason.reason_id));
  return externalSlots?.filter((slot) =>
    slot.reason.some((apptReason) => reasonIds?.includes(apptReason.reason_id))
  );
};

const selectedReasonMatchesAppointmentReason = (
  selectedReason: AppointmentReason,
  appointmentReason: AppointmentReason
) =>
  selectedReason.reason_name === appointmentReason.reason_name &&
  selectedReason.reason_type === appointmentReason.reason_type &&
  selectedReason.is_telemed === appointmentReason.is_telemed;

const getExternalSlotsFilteredByAppointmentReasonNameTypeTelemed = (
  externalSlots: ExternalSlot[],
  appointmentReasons?: AppointmentReason[]
) => {
  if (!appointmentReasons) return [];
  return externalSlots?.filter((slot) =>
    slot.reason.some((slotAppointmentReason) => {
      return (
        appointmentReasons.filter((appReason) =>
          selectedReasonMatchesAppointmentReason(slotAppointmentReason, appReason)
        ).length > 0
      );
    })
  );
};

const externalSlotReasonsMatchProviderAppointmentReasons = (
  providerSlotReasons: AppointmentReason[],
  appointmentReasons?: AppointmentReason[]
) => {
  if (!appointmentReasons) return [];
  return providerSlotReasons.some((slotAppointmentReason) => {
    return (
      appointmentReasons.filter((appReason) =>
        selectedReasonMatchesAppointmentReason(slotAppointmentReason, appReason)
      ).length > 0
    );
  });
};

const getDateOfNextAvailableAppointment = (
  externalSlots: ExternalSlot[],
  searchFilters: SearchFilters
) => {
  const filteredSlots = getExternalSlotsFilteredByAppointmentReasonId(
    externalSlots,
    searchFilters.appointmentReasons
  );

  return filteredSlots?.[0]?.appointment_date;
};

const providerHasSlotsForCurrentSearchFilters = (
  providerId: string,
  externalSlots: ExternalSlot[],
  searchFilters: SearchFilters
) => {
  const currentSearchSlots =
    getExternalSlotsFilteredByAppointmentReasonId(
      externalSlots,
      searchFilters?.appointmentReasons
    ) || [];
  return currentSearchSlots.some((slot) => slot.provider_id === providerId);
};

const getProviderSlotsAfterCurrentlySelectedDate = (
  currentlySelectedDate: moment.Moment,
  providerSlots?: ExternalSlot[]
) => {
  return providerSlots?.filter((slot) =>
    moment(slot.appointment_date).isAfter(currentlySelectedDate)
  );
};

const providerOffersNewPatientVisits = (appointmentReasons: AppointmentReasons): boolean => {
  if (isEmpty(appointmentReasons)) return false;
  return Object.values(appointmentReasons).some((reason) =>
    isAppointmentTypeAvailableForAppointmentReasonType(reason, AppointmentReasonTypes.NEW)
  );
};

const providerOffersVideoVisits = (appointmentReasons: AppointmentReasons): boolean => {
  if (isEmpty(appointmentReasons)) return false;
  return Object.values(appointmentReasons).some((reason) => reason.is_telemed);
};

/** Creates a sub collection of specialties that are present in a collection of providers
 *
 * @param providers
 * @param specialties
 * @returns
 */

const getSpecialtiesWithProviders = (
  providers: ProvidersCollection,
  specialties: SpecialtiesCollection
) => {
  if (isEmpty(providers) || isEmpty(specialties)) return {};

  // gets unique specialty names from providers collection
  const providerSpecialtyNameSet = Object.values(providers).reduce(
    (acc: string[], { specialties }) => {
      return uniq([...acc, ...specialties]);
    },
    []
  );

  // constructs specialties collection from name set
  const providerSpecialtyCollection = providerSpecialtyNameSet.reduce(
    (acc: SpecialtiesCollection, specialtyName) => {
      return specialties[specialtyName]
        ? Object.assign(acc, { [specialtyName]: specialties[specialtyName] })
        : acc;
    },
    {}
  );

  if (
    !isEmpty(providerSpecialtyCollection) &&
    Object.keys(providerSpecialtyCollection).some((specialty) =>
      PRIMARY_CARE_SUBSPECIALTIES.includes(specialty as SpecialtyNames)
    )
  ) {
    providerSpecialtyCollection[PRIMARY_CARE_SPECIALTY_NAME] =
      specialties[PRIMARY_CARE_SPECIALTY_NAME];
  }

  return providerSpecialtyCollection;
};

const getReasonByReasonNameAndReasonType = (
  reasons: AppointmentReasons,
  reasonName?: string | null,
  reasonType?: string | null
) => {
  if (reasons) {
    return Object.values(reasons).filter(
      (reason) =>
        reasonName === reason.reason_name &&
        (!reasonType ||
          isAppointmentTypeAvailableForAppointmentReasonType(
            reason,
            reasonType as AppointmentReasonTypes
          ))
    );
  }
  return [];
};

const getDistinctLocationsForProviders = (providers: Provider[]) => {
  const locations: Location[] = [];
  providers?.forEach((provider) =>
    provider.locations?.forEach((location) => locations.push(location))
  );
  const locationIds = locations.map((location) => location.id);
  return locations.filter(({ id }, index) => !locationIds.includes(id, index + 1));
};

const getDistinctAppointmentReasonsForProvider = (
  providerSlots: ProviderSlots
): AppointmentReasons => {
  if (!providerSlots) return {};
  return Object.values(providerSlots).reduce(
    (acc: AppointmentReasons, locationSlots) => Object.assign(acc, locationSlots.reasons),
    {}
  );
};

const getDistinctAppointmentReasonsForProviders = (
  providers: Provider[],
  slots: Slots
): AppointmentReasons => {
  if (!providers || !slots) return {};

  return providers.reduce(
    (acc: AppointmentReasons, { id: providerId }) =>
      Object.assign(acc, getDistinctAppointmentReasonsForProvider(slots[providerId])),
    {}
  );
};

const getSlotsForProviderLocation = (
  slots: Slots,
  providerId: string,
  locationId?: string
): ExternalSlot[] => {
  if (!slots[providerId]) return [];

  if (locationId) {
    if (!slots[providerId][locationId]) return [];
    return slots[providerId][locationId].appointments;
  }

  return Object.values(slots[providerId]).reduce((acc: ExternalSlot[], locationSlots) => {
    return [...acc, ...locationSlots.appointments];
  }, []);
};

const getSpecialtyListFromString = (specialty?: string) => {
  if (!specialty) {
    return undefined;
  }
  return specialty === PRIMARY_CARE_SPECIALTY_NAME ? PRIMARY_CARE_SUBSPECIALTIES : [specialty];
};

const getSlotsOnOrAfterDate = (slots: ExternalSlot[], date: string) => {
  return slots.filter((slot) => moment(slot.appointment_date).isSameOrAfter(date));
};

/** Formats query params passed to determine view/sort logic on PG pages
 *
 * @returns object of reformatted query params
 */
const getFormattedProviderGroupQueryParams = (queryParams: any): ValidProviderGroupQueryParams => {
  const formattedParams = { ...queryParams };

  Object.entries(queryParams).forEach(([key, value]: [string, any]) => {
    if (STRING_BOOLEANS.includes(value)) {
      formattedParams[key] = JSON.parse(value);
    }
  });

  return formattedParams;
};

const userHasSelectedDateBeforeLastFetchStartDate = (
  dateSelectedByUser: moment.Moment | null,
  lastFetchStartDate: string
): boolean => !!isFirstDateBeforeSecondDate(dateSelectedByUser, moment(lastFetchStartDate));

const userHasSelectedDateAfterLastFetchEndDate = (
  dateSelectedByUser: moment.Moment | null,
  lastFetchEndDate: string
): boolean => !!isFirstDateAfterSecondDate(dateSelectedByUser, moment(lastFetchEndDate));

const getNextSlotFetchStartDate = (
  isSingleDateSelectorUserSelection: boolean,
  dateSelectedByUser: moment.Moment | null,
  lastFetchStartDate: string
) => {
  // if user selected went backwards using the single date selector,
  // fetch the *previous* week from the date they selected
  if (
    isSingleDateSelectorUserSelection &&
    userHasSelectedDateBeforeLastFetchStartDate(dateSelectedByUser, lastFetchStartDate)
  ) {
    return moment(dateSelectedByUser)
      .subtract(FETCH_SLOTS_INTERVAL_DAYS, 'days')
      .format(EXTERNAL_SLOT_DATE_FETCH_FORMAT);
  }

  // otherwise, fetch the *next* week from the date they selected
  return moment(dateSelectedByUser).format(EXTERNAL_SLOT_DATE_FETCH_FORMAT);
};

const getNextSlotFetchEndDate = (
  isSingleDateSelectorUserSelection: boolean,
  dateSelectedByUser: moment.Moment | null,
  lastFetchStartDate: string
) => {
  if (
    isSingleDateSelectorUserSelection &&
    userHasSelectedDateBeforeLastFetchStartDate(dateSelectedByUser, lastFetchStartDate)
  ) {
    return moment(dateSelectedByUser).format(EXTERNAL_SLOT_DATE_FETCH_FORMAT);
  }

  return moment(dateSelectedByUser)
    .add(FETCH_SLOTS_INTERVAL_DAYS, 'days')
    .format(EXTERNAL_SLOT_DATE_FETCH_FORMAT);
};

const getNextSlotFetchStartAndEndDates = (
  isSingleDateSelectorUserSelection: boolean,
  dateSelectedByUser: moment.Moment | null,
  lastFetchStartDate: string
) => {
  const { lastFetchStartDate: defaultStartDate, lastFetchEndDate: defaultEndDate } =
    defaultExternalSlotDateRange;

  if (!dateSelectedByUser) {
    return { nextStartDate: defaultStartDate, nextEndDate: defaultEndDate };
  }

  const nextStartDate = getNextSlotFetchStartDate(
    isSingleDateSelectorUserSelection,
    dateSelectedByUser,
    lastFetchStartDate
  );

  const nextEndDate = getNextSlotFetchEndDate(
    isSingleDateSelectorUserSelection,
    dateSelectedByUser,
    lastFetchStartDate
  );

  return { nextStartDate, nextEndDate };
};

const getSelectedAppointmentReason = (
  searchFilterReasons: AppointmentReason[],
  appointmentReasons: AppointmentReason[]
) => {
  const selectedReason = appointmentReasons.filter((apptReason) =>
    searchFilterReasons?.some((reason) => reason.reason_id === apptReason.reason_id)
  )[0];
  return selectedReason;
};

export {
  AppointmentReasonTypes,
  getDateOfNextAvailableAppointment,
  getSlotsForProviderOnDate,
  getSlotsForProvider,
  providerHasSlotsForCurrentSearchFilters,
  getProviderSlotsAfterCurrentlySelectedDate,
  getAppointmentReasonsFilteredByAppointmentReasonType,
  getReasonOptionsByReasonName,
  getExternalSlotsFilteredByAppointmentReasonId,
  getExternalSlotsFilteredByAppointmentReasonNameTypeTelemed,
  externalSlotReasonsMatchProviderAppointmentReasons,
  providerOffersNewPatientVisits,
  providerOffersVideoVisits,
  getSpecialtiesWithProviders,
  getReasonByReasonNameAndReasonType,
  getDistinctLocationsForProviders,
  getDistinctAppointmentReasonsForProvider,
  getDistinctAppointmentReasonsForProviders,
  getSlotsForProviderLocation,
  getSpecialtyListFromString,
  getSlotsOnOrAfterDate,
  getFormattedProviderGroupQueryParams,
  userHasSelectedDateBeforeLastFetchStartDate,
  userHasSelectedDateAfterLastFetchEndDate,
  getNextSlotFetchStartAndEndDates,
  getSelectedAppointmentReason,
};
