import { isDirectTelemedBooking, isPremiumBooking } from './booking';
import {
  isPaperworkEnabled,
  isReliantVirtualVisit,
  isStandaloneTelemed,
  isPaymentsEnabled,
  isPhotoIdUploadEnabledForEntrypoint,
} from './location';
import { isPaperworkCompleted } from './paperwork';
import { isPaymentMethodAddedForCurrentBooking } from './payment';
import { isPhotoIdUploadedForUserProfile } from './account';
import { PaperworkSectionRoute } from '~/components/Paperwork/paperworkConstants';

const isPaperworkEnabledInTelemedFlow = (booking: any, location: any) =>
  isPaperworkEnabled(location) && !isDirectTelemedBooking(booking);

const isPaymentsEnabledInTelemedFlow = (booking: any, location: any) =>
  isPaymentsEnabled(location) &&
  !isStandaloneTelemed(location) &&
  !isReliantVirtualVisit(location) &&
  !isDirectTelemedBooking(booking);

const getDirectTelemedVideoVisitRoute = (booking: any) => `/video-visit/${booking.id}`;

const getInCallVideoVisitRoute = (booking: any) =>
  `/video-visit/${booking.id}/rl?skip_onboarding=true&autoJoin=true`;

const PRE_VISIT_ROUTE = 'pre-visit-ready';
const getPreVisitReadyRoute = (booking: any, paperworkComplete?: boolean) =>
  isPremiumBooking(booking)
    ? `/pass/${booking.id}${paperworkComplete ? '?pwc=true&noback=true' : ''}`
    : `/video-visit/${booking.id}/${PRE_VISIT_ROUTE}?skip_onboarding=true`;

const getPreVisitPaymentRoute = (booking: any) =>
  `/video-visit/${booking.id}/pre-visit-payment?skip_onboarding=true`;

const getPreVisitPaperworkRoute = (booking: any) =>
  isPremiumBooking(booking)
    ? `${PaperworkSectionRoute.PATIENT}/${booking.id}`
    : `/video-visit/${booking.id}/pre-visit-paperwork?skip_onboarding=true`;

const getPreVisitPhotoIdUploadRoute = (booking: any) =>
  `/video-visit/${booking.id}/pre-visit-photo-id-upload?skip_onboarding=true`;

const checkForPaperworkRouting = (booking: any, location: any) => {
  // Does location have paperwork enabled?
  if (isPaperworkEnabledInTelemedFlow(booking, location)) {
    if (isPaperworkCompleted(booking)) {
      // No need to navigate to paperwork
      return null;
    }
    // Paperwork not completed
    return getPreVisitPaperworkRoute(booking);
  }
  // No need to navigate to paperwork since it's not enabled
  return null;
};

const checkForPhotoIdUploadRouting = (booking: any, location: any, userProfile: any) => {
  // Does location have photo ID upload enabled?
  if (isPhotoIdUploadEnabledForEntrypoint(location, 'videoVisit')) {
    if (isPhotoIdUploadedForUserProfile(userProfile)) {
      // No need to navigate to photo id upload
      return null;
    }
    // Photo ID not uploaded yet
    return getPreVisitPhotoIdUploadRoute(booking);
  }
  // No need to navigate to photo ID upload since it's not enabled
  return null;
};

const checkForPaymentRouting = (booking: any, location: any) => {
  // Does location have payment enabled?
  if (isPaymentsEnabledInTelemedFlow(booking, location)) {
    if (isPaymentMethodAddedForCurrentBooking(booking)) {
      // No need to navigate to payment
      return null;
    }
    // Payment not completed
    return getPreVisitPaymentRoute(booking);
  }
  // No need to navigate to payment since it's not enabled
  return null;
};

const getRouteForTelemedFlowBeforePaperwork = (booking: any) => {
  return `/pass/${booking.id}`;
};

const getStartingPointRouteForTelemedFlow = (booking: any, location: any) => {
  let url;

  if (isDirectTelemedBooking(booking)) {
    // if direct telemed then skip paperwork and payment always and go to video visit rout
    url = getDirectTelemedVideoVisitRoute(booking);
  }

  if (!url) {
    // First check if i should route to paperwork
    url = checkForPaperworkRouting(booking, location);
    if (!url) {
      // Second check if should route to payments
      url = checkForPaymentRouting(booking, location);
      if (!url) {
        // Redirect to the pre-visit thank you screen
        url = getPreVisitReadyRoute(booking);
      }
    }
  }

  return url;
};

const getRouteForTelemedFlowBeforePhotoIdUpload = (
  booking: any,
  location: any,
  isLoggedIn: any
) => {
  // if paperwork enabled and not completed then send to paperwork
  if (isPaperworkEnabledInTelemedFlow(booking, location) && !isPaperworkCompleted(booking)) {
    return getPreVisitPaperworkRoute(booking);
  }

  return getRouteForTelemedFlowBeforePaperwork(booking);
};

const getRouteForTelemedFlowBeforePayment = (
  booking: any,
  location: any,
  isLoggedIn: any,
  userProfile: any
) => {
  // if photo id upload enabled and not completed then send to photo id upload
  if (
    isPhotoIdUploadEnabledForEntrypoint(location, 'videoVisit') &&
    !isPhotoIdUploadedForUserProfile(userProfile)
  ) {
    return getPreVisitPhotoIdUploadRoute(booking);
  }

  // if paperwork enabled and not completed then send to paperwork
  if (isPaperworkEnabledInTelemedFlow(booking, location) && !isPaperworkCompleted(booking)) {
    return getPreVisitPaperworkRoute(booking);
  }

  return getRouteForTelemedFlowBeforePaperwork(booking);
};

const getRouteForTelemedFlowBeforePrevisitReady = (
  booking: any,
  location: any,
  isLoggedIn: any,
  userProfile: any
) => {
  // if payment enabled and not completed then send to payment
  if (
    isPaymentsEnabledInTelemedFlow(booking, location) &&
    !isPaymentMethodAddedForCurrentBooking(booking)
  ) {
    return getPreVisitPaymentRoute(booking);
  }

  return getRouteForTelemedFlowBeforePayment(booking, location, isLoggedIn, userProfile);
};

const getNextRouteForTelemedFlowAfterPaperwork = (
  booking: any,
  location: any,
  userProfile: any
) => {
  let url;

  // first check if we should route to photo id upload
  url = checkForPhotoIdUploadRouting(booking, location, userProfile);

  if (!url) {
    // then check if we should route to payment
    url = checkForPaymentRouting(booking, location);

    if (!url) {
      // Redirect to the pre-visit thank you screen
      url = getPreVisitReadyRoute(booking, true);
    }
  }

  return url;
};

const getNextRouteForAfterPhotoIdUpload = (booking: any, location: any, userProfile: any) => {
  let url;

  // first check if we should route to payment
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
  url = checkForPaymentRouting(booking, location, userProfile);

  if (!url) {
    // Redirect to the pre-visit thank you screen
    url = getPreVisitReadyRoute(booking);
  }

  return url;
};

export {
  getStartingPointRouteForTelemedFlow,
  getNextRouteForTelemedFlowAfterPaperwork,
  getRouteForTelemedFlowBeforePrevisitReady,
  getPreVisitPaperworkRoute,
  getPreVisitPaymentRoute,
  getRouteForTelemedFlowBeforePayment,
  getRouteForTelemedFlowBeforePaperwork,
  getPreVisitReadyRoute,
  getInCallVideoVisitRoute,
  isPaperworkEnabledInTelemedFlow,
  isPaymentsEnabledInTelemedFlow,
  PRE_VISIT_ROUTE,
  getNextRouteForAfterPhotoIdUpload,
  getRouteForTelemedFlowBeforePhotoIdUpload,
};
