import { isEmpty } from 'lodash';
import { Dispatch } from 'redux';
import { receiveUserProfileForNewBooking } from '~/actions/newBooking';
import { profileFormSubmission } from '~/actions/profile';
import { UserProfile } from '~/core/dapi/models';
import { convertSnakeToCamelCase } from '~/core/util/object';
import { AccountSummary } from '~/reducers/account';

/**
 * Let us set the profile for the booking state as soon as the user logs in, since other flows
 * depend on its presence.
 */
export function setUserProfileForBooking(
  dispatch: Dispatch<any>,
  profile?: UserProfile,
  accountSummary?: AccountSummary
) {
  if (!isEmpty(accountSummary) && !isEmpty(profile)) {
    dispatch(receiveUserProfileForNewBooking(profile)); // Set `newBooking.userProfile`
    dispatch(
      profileFormSubmission({
        // set `newBooking.profile`
        ...convertSnakeToCamelCase(profile), // It has to be camelCase for some reason
        phone: accountSummary?.phone, // the phone ane email in `newBooking.profile` dictate the
        email: accountSummary?.email, // account against which the booking is actually made
      })
    );
  }
}
