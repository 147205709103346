import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  GranularPaperworkStatus,
  PaperworkStatus,
} from '../../components/Paperwork/paperworkConstants';
import { UserProfile } from '~/core/dapi/models';

const isPaperworkCompleted = (booking: any) => {
  if (isEmpty(booking)) {
    return false;
  }
  return (
    booking.paperworkStatus === PaperworkStatus.COMPLETE ||
    booking.paperwork_status === PaperworkStatus.COMPLETE
  );
};

const paperworkPreviouslyFilledForProfile = (userProfile: UserProfile) => {
  return !isEmpty(userProfile.address_zip) || !isEmpty(userProfile.race);
};

// faith can allow a patient to bypass paperwork from the facesheet modal in the queue
const isPaperworkBypassed = (booking: any) =>
  booking?.isPaperworkBypassed || booking?.is_paperwork_bypassed;

const usePaperworkStatus = (booking: any) => {
  const userProfileInfo = useSelector((state) =>
    state.account.summary?.user_profiles?.find(
      (profile) => profile.user_profile_id === booking.userProfileId
    )
  );

  if (isPaperworkBypassed(booking)) {
    return GranularPaperworkStatus.NotNeededForVisit;
  }
  if (isPaperworkCompleted(booking)) {
    return GranularPaperworkStatus.Complete;
  }
  if (userProfileInfo && paperworkPreviouslyFilledForProfile(userProfileInfo)) {
    return GranularPaperworkStatus.NeedsReviewAndVerification;
  }
  return GranularPaperworkStatus.NeedsToBeFilled;
};

export { isPaperworkCompleted, isPaperworkBypassed, usePaperworkStatus };
