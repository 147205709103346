import {
  NPI_CHECK_RECEIVED,
  NPI_CHECK_ERROR,
  NPI_CHECK_SUBMITTED,
  NPI_CLEAR_SUBMISSION,
} from '../constants/index';

export default function npiReducer(state = {}, action: any) {
  switch (action.type) {
    case NPI_CHECK_SUBMITTED:
      return {
        ...state,
        error: null,
        submitting: true,
        submitted: [action.payload.value],
      };
    case NPI_CHECK_RECEIVED:
      return {
        ...state,
        result: {
          ...action.payload,
        },
        error: null,
        submitting: false,
      };
    case NPI_CHECK_ERROR:
      return {
        ...state,
        error: action.payload.value,
        submitting: false,
      };
    case NPI_CLEAR_SUBMISSION:
      return {
        ...state,
        error: null,
        submitting: false,
      };
    default:
      return state;
  }
}
