import every from 'lodash/every';
import some from 'lodash/some';
import { ServiceName } from './Reasons';

/** V1 Services are binned into three categories:
 * 1. Covid services
 * 2. X-ray services
 * 3. Everything else
 *
 * This type is used when we need to determine whether a list of services is the
 * "general" service category and not a specific service. So, an rfv of "covid" would
 * match all services in the "covid" category */
export type ServiceCategory = 'covid' | 'xray' | 'none';

// Predefined lists of services used to setup the keyword -> services mapping
const nonAntibodyCovidServices = [
  ServiceName.covid_19_antigen_test,
  ServiceName.covid_19_pcr_test,
  ServiceName.rapid_covid_19_antigen_test,
  ServiceName.rapid_covid_19_pcr_test,
];
const allCovidServices = [ServiceName.covid_19_antibody_test, ...nonAntibodyCovidServices];
const allPcrServices = [ServiceName.covid_19_pcr_test, ServiceName.rapid_covid_19_pcr_test];
const allAntigenServices = [
  ServiceName.covid_19_antigen_test,
  ServiceName.rapid_covid_19_antigen_test,
];
const allRapidCovidServices = [
  ServiceName.rapid_covid_19_antigen_test,
  ServiceName.rapid_covid_19_pcr_test,
];
const covidTravelServices = [ServiceName.covid_19_pcr_test, ServiceName.rapid_covid_19_pcr_test];
const allXrayServices = [ServiceName['x-ray_chest'], ServiceName['x-ray_extremity']];

// 'Set' objects used for fast checking of whether services belong to a general Service
const allCovidServicesSet = new Set<ServiceName>(allCovidServices);
const allXrayServicesSet = new Set<ServiceName>(allXrayServices);

export const getServicesGeneralCategory = (
  serviceNames: readonly ServiceName[]
): ServiceCategory => {
  if (every(serviceNames, (name: any) => allCovidServicesSet.has(name))) {
    return 'covid';
  }

  if (every(serviceNames, (name: any) => allXrayServicesSet.has(name))) {
    return 'xray';
  }

  return 'none';
};

export const getCategoryForSpecificService = (serviceName: ServiceName): ServiceCategory => {
  if (allCovidServicesSet.has(serviceName)) {
    return 'covid';
  }
  if (allXrayServicesSet.has(serviceName)) {
    return 'xray';
  }
  return 'none';
};

/** Gets display text for a general V1 service category
 *
 * @returns The category name or null if the none category */
export const getServiceCategoryDisplayName = (category: ServiceCategory) => {
  switch (category) {
    case 'covid':
      return 'COVID test';
    case 'xray':
      return 'X-ray';
    case 'none':
    default:
      return null;
  }
};

const blacklistedCovidWords = [
  'vaccine',
  'vaccines',
  'positive',
  'negative',
  'symptom',
  'symptoms',
];

const blacklistedStitchesWords = [
  'remove',
  'removal',
  'removing',
  'removed',
  'out',
  'check',
  'take',
  'clean',
  'look',
];
/** Special cases where an rfv with an exact match will give a predefined result
 * Some of these are redundant with entries in keywordServicesMapping but have been added
 * here for completion's sake */
const exactServiceNameMatches: Record<string, ServiceName[]> = {
  'covid-19 pcr test': [ServiceName.covid_19_pcr_test],
  'rapid covid-19 pcr test': [ServiceName.rapid_covid_19_pcr_test],
  'covid-19 antigen test': [ServiceName.covid_19_antigen_test],
  'rapid covid-19 antigen test': [ServiceName.rapid_covid_19_antigen_test],
  'covid-19 antibody test': [ServiceName.covid_19_antibody_test],
  'flu shot': [ServiceName.flu_shot],
  'school physicals': [ServiceName.school_and_sports_physicals],
  'sports physicals': [ServiceName.sports_physicals],
  'camp physicals': [ServiceName.camp_physical],
  'laceration repair (stitches)': [ServiceName.laceration_repair],
  'x-ray (chest)': [ServiceName['x-ray_chest']],
  'x-ray (extremity)': [ServiceName['x-ray_extremity']],
  '10-panel drug test': [ServiceName.ten_panel_drug_test],
  '5-panel drug test': [ServiceName.five_panel_drug_test],
  'alcohol testing': [ServiceName.alcohol_testing],
  'covid pill': [ServiceName.covid_pill],
  'gonorrhea test': [ServiceName.gonorrhea_test],
  'hepatitis test': [ServiceName.hepatitis_test],
  'monoclonal antibody therapy': [ServiceName.monoclonal_antibody_therapy],
  'syphilis test': [ServiceName.syphilis_test],
  botox: [ServiceName.botox],
  dermaplaning: [ServiceName.dermaplaning],
  'dermal filler': [ServiceName.dermal_filler],
  microdermabrasion: [ServiceName.microdermabrasion],
  'mole removal': [ServiceName.mole_removal],
  'wart removal': [ServiceName.wart_removal],

  // These two are strange; they are exact strings used by the universal search dropdown, but differ from the "exact"
  // name of the service by having "rapid" after "covid"
  'covid-19 rapid pcr test': [ServiceName.rapid_covid_19_pcr_test],
  'covid-19 rapid antigen test': [ServiceName.rapid_covid_19_antigen_test],

  // These are strings used by the Global search options
  'covid rapid pcr test': [ServiceName.rapid_covid_19_pcr_test],
  'covid rapid antigen test': [ServiceName.rapid_covid_19_antigen_test],
};

type KeywordsToServices = [string[], ServiceName[]];
type KeywordsToServicesWithBlacklist = [string[], ServiceName[], string[]];

/** The data mapping for the rfv -> Services match algorithm. Defines lists of keywords to the services
 * to return if and only if all keywords are present in the RFV. Can be given an optional list of
 * blacklisted words that will result in a failed match for that mapping, if present in the RFV. */
const keywordServicesMapping: (KeywordsToServices | KeywordsToServicesWithBlacklist)[] = [
  [['office', 'visit'], [ServiceName.base_office_visit]],
  // Covid
  [['covid', 'test'], nonAntibodyCovidServices, blacklistedCovidWords],
  [['covid', 'tests'], nonAntibodyCovidServices, blacklistedCovidWords],
  [['covid-19', 'test'], nonAntibodyCovidServices, blacklistedCovidWords],
  [['covid-19', 'tests'], nonAntibodyCovidServices, blacklistedCovidWords],
  [['covid', 'pcr', 'test'], allPcrServices, blacklistedCovidWords],
  [['covid', 'pcr', 'tests'], allPcrServices, blacklistedCovidWords],
  [['covid-19', 'pcr', 'test'], allPcrServices, blacklistedCovidWords],
  [['covid-19', 'pcr', 'tests'], allPcrServices, blacklistedCovidWords],
  [['pcr', 'test'], allPcrServices, blacklistedCovidWords],
  [['covid', 'antigen', 'test'], allAntigenServices, blacklistedCovidWords],
  [['covid', 'antigen', 'tests'], allAntigenServices, blacklistedCovidWords],
  [['covid-19', 'antigen', 'test'], allAntigenServices, blacklistedCovidWords],
  [['covid-19', 'antigen', 'tests'], allAntigenServices, blacklistedCovidWords],
  [['covid', 'antibody', 'test'], [ServiceName.covid_19_antibody_test], blacklistedCovidWords],
  [['covid', 'antibody', 'tests'], [ServiceName.covid_19_antibody_test], blacklistedCovidWords],
  [['covid-19', 'antibody', 'test'], [ServiceName.covid_19_antibody_test], blacklistedCovidWords],
  [['covid-19', 'antibody', 'tests'], [ServiceName.covid_19_antibody_test], blacklistedCovidWords],
  [['covid', 'rapid', 'test'], allRapidCovidServices, blacklistedCovidWords],
  [['covid', 'rapid', 'tests'], allRapidCovidServices, blacklistedCovidWords],
  [['covid-19', 'rapid', 'test'], allRapidCovidServices, blacklistedCovidWords],
  [['covid-19', 'rapid', 'tests'], allRapidCovidServices, blacklistedCovidWords],
  [['travel', 'test'], covidTravelServices, blacklistedCovidWords],
  [['travel', 'tests'], covidTravelServices, blacklistedCovidWords],
  // Flu shot
  [['flu', 'shot'], [ServiceName.flu_shot]],
  [['flu', 'shots'], [ServiceName.flu_shot]],
  // Physicals
  [['sport', 'physical'], [ServiceName.sports_physicals]],
  [['sports', 'physical'], [ServiceName.sports_physicals]],
  [['sports', 'physicals'], [ServiceName.sports_physicals]],
  [['school', 'physical'], [ServiceName.school_and_sports_physicals]],
  [['school', 'physicals'], [ServiceName.school_and_sports_physicals]],
  [['camp', 'physical'], [ServiceName.camp_physical]],
  [['camp', 'physicals'], [ServiceName.camp_physical]],
  // Laceration Repair
  [['stitch'], [ServiceName.laceration_repair], blacklistedStitchesWords],
  [['stitches'], [ServiceName.laceration_repair], blacklistedStitchesWords],
  [['laceration', 'repair'], [ServiceName.laceration_repair]],
  // X-ray
  [['xray'], allXrayServices],
  [['x-ray'], allXrayServices],
  [['xrays'], allXrayServices],
  [['x-rays'], allXrayServices],
  [['xray', 'chest'], [ServiceName['x-ray_chest']]],
  [['x-ray', 'chest'], [ServiceName['x-ray_chest']]],
  [['xray', 'foot'], [ServiceName['x-ray_extremity']]],
  [['x-ray', 'foot'], [ServiceName['x-ray_extremity']]],
  [['xray', 'hand'], [ServiceName['x-ray_extremity']]],
  [['x-ray', 'hand'], [ServiceName['x-ray_extremity']]],
  [['xray', 'wrist'], [ServiceName['x-ray_extremity']]],
  [['x-ray', 'wrist'], [ServiceName['x-ray_extremity']]],
  [['xray', 'toe'], [ServiceName['x-ray_extremity']]],
  [['x-ray', 'toe'], [ServiceName['x-ray_extremity']]],
  [['xray', 'finger'], [ServiceName['x-ray_extremity']]],
  [['x-ray', 'finger'], [ServiceName['x-ray_extremity']]],
  [['xray', 'leg'], [ServiceName['x-ray_extremity']]],
  [['x-ray', 'leg'], [ServiceName['x-ray_extremity']]],
  [['broken', 'bone'], [ServiceName['x-ray_extremity']]],
  [['broken', 'foot'], [ServiceName['x-ray_extremity']]],
  [['broken', 'hand'], [ServiceName['x-ray_extremity']]],
  [['broken', 'wrist'], [ServiceName['x-ray_extremity']]],
  [['broken', 'toe'], [ServiceName['x-ray_extremity']]],
  [['broken', 'finger'], [ServiceName['x-ray_extremity']]],
  [['broken', 'leg'], [ServiceName['x-ray_extremity']]],
  [['fracture'], [ServiceName['x-ray_extremity']]],
  [['fractured'], [ServiceName['x-ray_extremity']]],
  [['fractures'], [ServiceName['x-ray_extremity']]],
  // Labs and derm
  [['ten', 'panel', 'test'], [ServiceName.ten_panel_drug_test]],
  [['five', 'panel', 'test'], [ServiceName.five_panel_drug_test]],
];

/** Describes the outcome of attempting to map a Reason For Visit (RFV) to a a V1 Service */
export type RfvMappingResult = {
  services: ServiceName[];
  category: ServiceCategory;
  noServicesFound: boolean;
};

/** The default mapping result used whenever we want to return "no results" */
const nullMappingResult: RfvMappingResult = {
  services: [],
  category: 'none',
  noServicesFound: true,
};

/** Words, if present in the RFV, cause no results to be returned. These represent problem cases for the mapping
 * based on real-world data */
const globalBlacklistWords = ['result', 'results', 'shows', 'follow', 'cost', 'price'];

/** Replaces different ways of describing "covid 19" with one that is recognized by our mapping
 *
 * TODO: This could remove some extra entries in our mapping data set if we just replaced `covid-19` and `covid 19` with `covid`
 *
 * @returns The sanitized rfv */
const replaceCovidText = (rfv: string) => {
  // Are there other 'covid' variants?
  return rfv.replace('covid 19', 'covid-19');
};

/** Retrieves a list of V1 service names for Clear Prices and a given reason for visit string.
 *
 * This was built based on expectations set from a spreadsheet of keywords & business rules -> services
 * here: https://docs.google.com/spreadsheets/d/1j2VELWiqkW5VMjxfLimAINBQl_Vaa6epqPAIVIa0AJc/edit#gid=82039255
 *
 * @returns A description of the mapping results */
const getServicesForRfv = (reasonForVisit: string): RfvMappingResult => {
  // Sanitize the RFV for the rest of the matching algorithm
  let rfv = reasonForVisit.toLowerCase().trim();
  rfv = replaceCovidText(rfv);

  // Special case for if the RFV mapping fits a pre-defined case *exactly*
  const exactMatch = exactServiceNameMatches[rfv];

  if (exactMatch) {
    return {
      category: getServicesGeneralCategory(exactMatch),
      noServicesFound: false,
      services: exactMatch,
    };
  }

  // Special case to omit all RFVs with dollar prices; this is a known problem case we've seen in real world RFV searches
  // due to some clinics using a pre-populated RFV dropdown with prices included in the service names
  if (rfv.indexOf('$') !== -1) {
    return nullMappingResult;
  }

  // Early return with no matches if the RFV contains any of the universal "banned" words
  const rfvWords = new Set(rfv.split(' '));

  if (some(globalBlacklistWords, (bannedWord: string) => rfvWords.has(bannedWord))) {
    return nullMappingResult;
  }

  // Whether a match has already been found. Used to short-circuit if we find multiple matches across service categories
  let matchFound = false;
  let matchCategory: ServiceCategory = 'none';

  let matchingServices = new Set<ServiceName>();

  // Test the RFV against each mapping
  for (let i = 0; i < keywordServicesMapping.length; i++) {
    let keywordServicesTuple = keywordServicesMapping[i];
    let keywords = keywordServicesTuple[0];
    let blacklistWords = keywordServicesTuple[2] ?? [];

    // Return no results if the RFV has any blacklisted words for this mapping
    if (blacklistWords.length) {
      if (some(blacklistWords, (word: string) => rfvWords.has(word))) {
        return nullMappingResult;
      }
    }

    // Count if all words in keywords exist in the RFV. All must be present in order for this mapping to be a match
    let matchCount = 0;

    keywords.forEach((keyword) => {
      if (rfvWords.has(keyword)) {
        matchCount += 1;
      }
    });

    if (matchCount === keywords.length) {
      let newMatchCategory = getCategoryForSpecificService(keywordServicesTuple[1][0]);
      let sameGeneralCategory = matchCategory === newMatchCategory && matchCategory !== 'none';

      // Early exit if too many matches && aren't both in xray or covid general categories
      if (matchFound && !sameGeneralCategory) {
        return nullMappingResult;
      }

      matchFound = true;
      matchCategory = newMatchCategory;
      // We push services here instead of returning this as "the" result because there may be other mappings that
      // fit
      matchingServices.clear();

      keywordServicesTuple[1].forEach((serviceName) => matchingServices.add(serviceName));
    }
  }

  const result = Array.from(matchingServices);

  return {
    category: getServicesGeneralCategory(result),
    noServicesFound: result.length === 0,
    services: result,
  };
};

/**
 * This was used in debug to determine if the null result was due to multiple mapping matches across
 * different service categories. Retaining for future debugging.
 *
 * @returns Whether multiple categories were used
 * @deprecated */
const multipleCategoriesUsedInMapping = (serviceNames: ServiceName[]) => {
  // TODO: I don't think this is useful; just here for debug
  let covidServiceCount = 0;
  let xrayServiceCount = 0;
  let nonCategorizedServiceCount = 0;

  serviceNames.forEach((serviceName) => {
    if (allCovidServicesSet.has(serviceName)) {
      covidServiceCount += 1;
    } else if (allXrayServicesSet.has(serviceName)) {
      xrayServiceCount += 1;
    } else {
      nonCategorizedServiceCount += 1;
    }
  });

  return (
    [covidServiceCount, xrayServiceCount, nonCategorizedServiceCount].reduce((prev, cur) =>
      cur ? 1 + prev : prev
    ) > 1
  );
};

export default getServicesForRfv;
