const createBlob = (rawJpeg: any) => {
  const binary = atob(rawJpeg.split(',')[1]);
  const chars = [];
  for (let i = 0; i < binary.length; i++) {
    chars.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(chars)], { type: 'image/jpeg' });
};

function byteArrayToImage(byteArray: any) {
  return Buffer.from(byteArray).toString('base64');
}

export { byteArrayToImage, createBlob };
