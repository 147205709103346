import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { SOLV_PLUS_ENTRYPOINT_CARE_OPTIONS } from '~/core/analytics/events';
import { CareType, getCareOptions } from '~/core/dapi/careTeam';
import { isOpenNow } from '~/core/util/location';
import useShouldShowPremiumVisitEntrypoints from '~/hooks/membership/useShouldShowPremiumVisitEntrypoints';
import useSelectActiveUserProfile from '~/hooks/useSelectActiveUserProfile';
import { usePreloadSlots } from './useSlots';

/**
 * Creates useQuery call for the care options requested
 *
 * @returns a query object
 */
export function useCareOptionsQuery(
  careType: CareType,
  { preloadSlots = false, serviceIds = [] }: { preloadSlots?: boolean; serviceIds?: string[] } = {}
) {
  const userProfile = useSelectActiveUserProfile();

  const { userLocation, position } = useSelector((state) => ({
    userLocation: state.searchPreferences.location,
    position: state.position,
  }));

  let queryParams = {};

  if (careType === 'video') {
    const state = userLocation?.state_code ?? position.result?.state_code;
    queryParams = { state };
  } else {
    if (userLocation?.latitude && userLocation.longitude) {
      queryParams = { lat: userLocation?.latitude, lng: userLocation?.longitude };
    } else if (position.result?.latitude && position.result?.longitude) {
      queryParams = { lat: position.result?.latitude, lng: position.result?.longitude };
    }
  }

  if (serviceIds?.length) {
    queryParams = { ...queryParams, serviceIds };
  }

  const query = useQuery(
    ['care-options', careType, userProfile?.user_profile_id, queryParams],
    () => getCareOptions(careType, userProfile?.user_profile_id, queryParams),
    { staleTime: 10000, enabled: !!userProfile?.user_profile_id, keepPreviousData: true }
  );

  const data = query.data?.data.book_again ?? [];

  usePreloadSlots(
    data.map((d) => d.id),
    { enabled: preloadSlots }
  );

  return query;
}

/**
 * Get the current user profile's care team
 *
 * @returns the useQuery value
 */
export function useCareOptions(
  careType: CareType,
  { preloadSlots = false, serviceIds = [] }: { preloadSlots?: boolean; serviceIds?: string[] } = {}
) {
  const query = useCareOptionsQuery(careType, { preloadSlots, serviceIds });

  const hasRebookOptions = !isEmpty(query.data?.data.book_again);
  const anyLocationIsOpenNow = hasRebookOptions && query.data?.data.book_again.some(isOpenNow);
  const isAfterHours = useMemo(() => {
    const now = moment(new Date());
    const start = moment('08:00:00', 'HH:mm:ss');
    const end = moment('20:00:00', 'HH:mm:ss');

    return !now.isBetween(start, end);
  }, []);

  // Solv plus display logic
  const solvPlusEnabledForUser = useShouldShowPremiumVisitEntrypoints({
    overrideExcludedLocations: true,
    showToExistingMembers: true,
    entrypointId: SOLV_PLUS_ENTRYPOINT_CARE_OPTIONS,
    suppressTracking: true,
  });

  /**
   * @returns whether to show solv plus for the given care type
   */
  const rebookingOptionsAllowForSolvPlus = useMemo(() => {
    if (query.isLoading) {
      return false;
    }
    if (careType === 'uc') {
      if (hasRebookOptions) {
        // If we have clinic relationships, check if any of them are open now
        return !anyLocationIsOpenNow;
      }

      return isAfterHours;
    }
    if (careType === 'video') {
      if (hasRebookOptions) {
        // If we have clinic relationships, check if any of them are open now
        return !anyLocationIsOpenNow;
      }

      return true;
    }
    return false;
  }, [anyLocationIsOpenNow, careType, hasRebookOptions, isAfterHours, query.isLoading]);

  return {
    ...query,
    hasRebookOptions,
    anyLocationIsOpenNow,
    isAfterHours,
    showPremiumVisitUpsell: rebookingOptionsAllowForSolvPlus && solvPlusEnabledForUser,
  };
}
