import moment from 'moment';
import { isValidDateString, isValidDateStringMMDDYYYY } from '../date';
import { isEmpty } from '../empty';
import { BIRTH_DATE_FORMAT, BIRTH_DATE_SLASH_FORMAT } from '../../../config/index';
import { isValidEmail } from '../email';
import { isEmptyObject } from '../object';
import { isEmptyString, isValidZipCode, stripNonNumeric } from '../string';
import { isValidPhone } from '../phoneNumber';
import { TOS_TERMS_ERROR_MESSAGE, TOS_TERMS_PRIVACY_ERROR_MESSAGE } from '~/constants';

const parseDate = (input: any) => moment(input, BIRTH_DATE_FORMAT);
const parseDateMMDDYYYY = (input: string) => moment(input, BIRTH_DATE_SLASH_FORMAT);

const birthDateInputValidator = (rule: any, value: any, callback: any) => {
  if (!isEmpty(value)) {
    if (rule.hasFormatMMDDYYYY && !isValidDateStringMMDDYYYY(value)) {
      return callback('Invalid Birth date, please use format MM/DD/YYYY');
    }

    if (!rule.hasFormatMMDDYYYY && !isValidDateString(value)) {
      return callback('Invalid Birth date');
    }

    const parsedDate = rule.hasFormatMMDDYYYY ? parseDateMMDDYYYY(value) : parseDate(value);

    if (!parsedDate.isValid()) {
      return callback('Invalid Birth date');
    }

    if (parsedDate > moment()) {
      return callback('Birth date cannot be in the future');
    }

    if (parsedDate.isSame(moment(), 'day')) {
      return callback('Birth date may not be today');
    }

    const lowerDateBoundary = moment().subtract(120, 'year');
    if (parsedDate < lowerDateBoundary) {
      return callback('Birth date cannot be more than 120 years ago');
    }

    if (rule.hasTelemedMinimumAgeRequirement) {
      const telemedDateBoundary = moment().subtract(11, 'year');
      if (parsedDate > telemedDateBoundary) {
        return callback('Telemedicine patients must be at least 11 years old.');
      }
    }
  } else {
    if (rule.required) {
      return callback('Please enter a birth date');
    }
  }

  return callback();
};

const dobYearValidator = (rule: any, value: any, callback: any) => {
  if (rule.required && !value) {
    return callback('Birth date year is required');
  }

  if (typeof value !== 'undefined' && !isEmptyString(value)) {
    if (value > moment().year()) {
      return callback('Birth date cannot be in the future');
    }

    const lowerDateBoundary = moment().year() - 120;
    if (value < lowerDateBoundary) {
      return callback('Birth date cannot be more than 120 years ago');
    }

    if (rule.isTelemedLocation) {
      const telemedDateBoundary = moment().year() - 11;
      if (value > telemedDateBoundary) {
        return callback('Telemedicine patients must be at least 11 years old.');
      }
    }
  }

  return callback();
};

const nameInputValidator = (rule: any, value: any, callback: any) => {
  if (isEmpty(value) || isEmpty(value.first) || isEmpty(value.last)) {
    return callback('required');
  }

  return callback();
};

const emptyFieldValidator = (rule: any, value: any, callback: any) => {
  if (isEmpty(value)) {
    return callback('required');
  }

  return callback();
};

const tosInputValidator = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(TOS_TERMS_PRIVACY_ERROR_MESSAGE);
  }

  return callback();
};

const termsInputValidator = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(TOS_TERMS_ERROR_MESSAGE);
  }

  return callback();
};

const accountTosConsentInputValidator = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(TOS_TERMS_PRIVACY_ERROR_MESSAGE);
  }

  return callback();
};

const bookingTosConsentInputValidator = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(TOS_TERMS_PRIVACY_ERROR_MESSAGE);
  }

  return callback();
};

const emailInputValidator = (rule: any, value: any, callback: any) => {
  if (rule.required) {
    if (!value || !isValidEmail(value)) {
      return callback('Please enter a valid email address');
    }
  }

  return callback();
};

const zipCodeInputValidator = (rule: any, value: any, callback: any) => {
  if (rule.required) {
    if (!value || !isValidZipCode(value)) {
      return callback('Please enter a valid zip code');
    }
  }

  return callback();
};

const locationInputValidator = (rule: any, value: any, callback: any) => {
  if (isEmptyObject(value) || !value.latitude || !value.longitude) {
    return callback('Invalid location');
  }

  return callback();
};

const maskedMobilePhoneInputValidator = (rule: any, value: any, callback: any) => {
  if (!value || !value.phone) {
    callback('Please enter a phone number');
    return;
  }

  if (value.phone.length !== 10) {
    callback('Phone number must be 10 digits long');
    return;
  }

  callback();
};

const otpCodeInputValidator = (rule: any, value: any, callback: any) => {
  if (isEmpty(value)) {
    return callback('Required');
  }
  if (!isEmptyObject(value) && value.length !== 4) {
    return callback('The code has to be 4 digits long');
  }

  const digits = value.split('');
  for (let i = 0; i < value.length; i++) {
    if (Number.isNaN(digits[i])) {
      return callback('Only numeric charachters allowed');
    }
  }

  return callback();
};

const phoneNumberValidator = (rule: any, rawValue: any, callback: any) => {
  const value = stripNonNumeric(String(rawValue));
  if (rule.required) {
    if (isEmptyString(value)) {
      return callback('Required');
    }
    if (!isValidPhone(value)) {
      return callback('Invalid phone number');
    }
  } else {
    if (!isEmpty(value) && !isValidPhone(value)) {
      return callback('Invalid phone number');
    }
  }

  return callback();
};

const addressInputValidator = (rule: any, value: any, callback: any) => {
  if (isEmpty(value)) {
    return callback('Please enter patient address');
  }

  if (isEmpty(value.zip)) {
    return callback('Please enter zip code');
  }

  if (isEmpty(value.state)) {
    return callback('Please enter state');
  }

  if (isEmpty(value.city)) {
    return callback('Please enter city');
  }

  if (isEmpty(value.street)) {
    return callback('Please enter street address');
  }

  return callback();
};

const normalizeLength = (length: any) => (value: any) =>
  value && value.length > length ? value.substring(0, length) : value;

export {
  birthDateInputValidator,
  nameInputValidator,
  tosInputValidator,
  emptyFieldValidator,
  emailInputValidator,
  normalizeLength,
  maskedMobilePhoneInputValidator,
  otpCodeInputValidator,
  phoneNumberValidator,
  accountTosConsentInputValidator,
  bookingTosConsentInputValidator,
  zipCodeInputValidator,
  locationInputValidator,
  addressInputValidator,
  termsInputValidator,
  dobYearValidator,
};
