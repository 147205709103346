import { isEmptyString, stripNonNumeric } from '../../../core/util/string';
import { isValidPhone } from '../../../core/util/phoneNumber';

interface Rule {
  field: string;
  fullField: string;
  required: boolean;
  type: string;
}
interface FormValidator {
  (rule: Rule, rawValue: string, callback: (error?: string) => void): void;
}

export const phoneNumberValidator: FormValidator = (rule, rawValue, callback) => {
  const value = stripNonNumeric(String(rawValue));
  if (isEmptyString(value)) {
    return callback('Required');
  }
  if (!isValidPhone(value)) {
    return callback('Invalid phone number');
  }

  return callback();
};

export const lastNameFormValidator: FormValidator = (rule, rawValue, callback) => {
  if (isEmptyString(rawValue)) {
    callback('Please enter a value');
    return;
  }

  const specialCharacters = /[`!@#$%^&*()_+\=\[\]{};:"\\|<>\/?~]/;
  if (specialCharacters.test(rawValue)) {
    callback('Last name cannot contain special characters.');
    return;
  }

  if (/\d/.test(rawValue)) {
    callback('Last name cannot contain numbers.');
    return;
  }

  callback();
};
