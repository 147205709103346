import LocationInterface from '@solvhealth/types/interfaces/Location';
import { arrayToObject } from '../../core/util/array';
import { locationResponseFormatter } from '../../reducers/formatters/location';
import { NEXT_AVAILABLE_SET, NEXT_AVAILABLE_URGENT_CARE_SET } from '../../sagas/nextAvailable';
import { URGENT_CARE_VALUE } from '../../components/Home/Tiles/ProviderTypes';

const LOCATIONS_SUBMITTING = 'locations/SUBMITTING';
const LOCATIONS_SET = 'locations/SET';
const LOCATIONS_ERROR = 'locations/ERROR';
const LOCATIONS_CLEAR = 'locations/CLEAR';
const FAVORITE_LOCATIONS_SET = 'locations/FAVORITES_SET';
const FETCH_APPOINTMENT_DATA_STARTED = 'locations/FETCH_APPOINTMENT_DATA_STARTED';
const FETCH_APPOINTMENT_DATA_FINISHED = 'locations/FETCH_APPOINTMENT_DATA_FINISHED';
const TRADITIONAL_LOCATIONS_SET = 'locations/TRADITIONAL_SET';
const FETCH_GET_CARE_LOCATIONS_DATA_STARTED = 'locations/FETCH_GET_CARE_LOCATIONS_DATA_STARTED';
const FETCH_GET_CARE_LOCATIONS_DATA_FINISHED = 'location/FETCH_GET_CARE_LOCATIONS_DATA_FINISHED';
const NEARBY_LOCATIONS_SET = 'locations/NEARBY_SET';

const submitLocations = (value: any) => ({
  type: LOCATIONS_SUBMITTING,
  payload: { value },
});

const setLocations = (value: any) => ({
  type: LOCATIONS_SET,
  payload: { value },
});

const errorLocations = (value: any) => ({
  type: LOCATIONS_ERROR,
  payload: { value },
});

const clearLocations = () => ({
  type: LOCATIONS_CLEAR,
});

const setTraditionalLocations = (value: any) => ({
  type: TRADITIONAL_LOCATIONS_SET,
  payload: { value },
});

const setNearbyLocations = (value: any) => ({
  type: NEARBY_LOCATIONS_SET,
  payload: { value },
});

const setFavoriteLocations = (value: any) => ({
  type: FAVORITE_LOCATIONS_SET,
  payload: { value },
});

const fetchAppointmentDataStarted = () => ({
  type: FETCH_APPOINTMENT_DATA_STARTED,
});

const fetchAppointmentDataFinished = () => ({
  type: FETCH_APPOINTMENT_DATA_FINISHED,
});

const fetchGetCareLocationsDataStarted = () => ({
  type: FETCH_GET_CARE_LOCATIONS_DATA_STARTED,
});

const fetchGetCareLocationsDataFinished = () => ({
  type: FETCH_GET_CARE_LOCATIONS_DATA_FINISHED,
});

const defaultState: LocationsState = {
  results: {},
  errors: {},
  submitting: false,
  traditionalResults: [],
  favoriteLocations: [],
  nearbyLocations: [],
  nextAvailable: {},
};

export type LocationsState = {
  results: Record<string, LocationInterface>;
  errors: any;
  submitting: boolean;
  traditionalResults: any;
  favoriteLocations: any;
  nearbyLocations: any;
  nextAvailable: any;
};

export default (state = { ...defaultState }, action: any) => {
  switch (action.type) {
    case LOCATIONS_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };

    case LOCATIONS_SET:
      return {
        ...state,
        errors: {},
        results: {
          ...state.results,
          ...arrayToObject(action.payload.value.map(locationResponseFormatter)),
        },
        submitting: false,
      };

    case LOCATIONS_ERROR:
      return {
        ...state,
        errors: {
          ...action.payload.value,
        },
        submitting: false,
      };

    case LOCATIONS_CLEAR:
      return {
        ...state,
        results: {},
        errors: {},
        submitting: false,
      };

    case TRADITIONAL_LOCATIONS_SET:
      return {
        ...state,
        traditionalResults: action.payload.value.map(locationResponseFormatter),
      };

    case NEARBY_LOCATIONS_SET:
      return {
        ...state,
        nearbyLocations: action.payload.value.map(locationResponseFormatter),
      };

    case FAVORITE_LOCATIONS_SET:
      return {
        ...state,
        favoriteLocations: action.payload.value.map(locationResponseFormatter),
      };

    case FETCH_APPOINTMENT_DATA_STARTED:
      return {
        ...state,
        appointmentsDataLoaded: false,
      };

    case FETCH_APPOINTMENT_DATA_FINISHED:
      return {
        ...state,
        appointmentsDataLoaded: true,
      };

    case FETCH_GET_CARE_LOCATIONS_DATA_STARTED:
      return {
        ...state,
        getCareLocationsDataLoaded: false,
      };
    case FETCH_GET_CARE_LOCATIONS_DATA_FINISHED:
      return {
        ...state,
        getCareLocationsDataLoaded: true,
      };

    case NEXT_AVAILABLE_SET:
      return {
        ...state,
        nextAvailable: {
          ...state.nextAvailable,
          ...action.payload.value,
        },
      };

    case NEXT_AVAILABLE_URGENT_CARE_SET:
      return {
        ...state,
        nextAvailable: {
          ...state.nextAvailable,
          [URGENT_CARE_VALUE]: action.payload.value,
        },
      };

    default:
      return state;
  }
};

export {
  submitLocations,
  setLocations,
  errorLocations,
  setFavoriteLocations,
  clearLocations,
  setTraditionalLocations,
  fetchAppointmentDataStarted,
  fetchAppointmentDataFinished,
  fetchGetCareLocationsDataStarted,
  fetchGetCareLocationsDataFinished,
  setNearbyLocations,
};
