import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';
import { hideModal, setRuntimeVariable } from '~/actions/runtime';
import { emptyFunction } from '~/core/util/function';
import { isTestPhoneNumber, sanitizePhone } from '~/core/util/phoneNumber';
import {
  analyticsTagHotjarRecording,
  analyticsTrackEvent,
  analyticsTrackFormErrors,
  analyticsTriggerHotjarRecording,
} from '~/core/analytics';
import {
  LOGIN_RESPONSE_MAX_ATTEMPTS_EXCEEDED,
  LOGIN_RESPONSE_WRONG_OTP,
  PINK_DOOR_ACCEPTED_FIELD_NAME,
  TEST_PHONE_NUMBER,
  TOS_ACCEPTED_FIELD_NAME,
} from '~/constants/phoneLoginForm';
import { apiGetDispatchable, apiPostDispatchable } from '~/core/dapi';
import { getGenerateOneTimePasswordUrl, postLoginDispatchable } from '~/core/dapi/login';
import {
  accountFrozen,
  generateOtpFailure,
  generateOtpSuccess,
  loginFailed,
  loginFailMaxExceeded,
  loginResponseReceived,
  loginSubmitButtonDisabled,
  loginSubmitted,
  noAccount,
  resetLoginData as resetLoginDataAction,
} from '~/actions/login';
import { noOp } from '~/actions';
import { getCreateAccountUrl, getVerificationStatus } from '~/core/dapi/account';
import {
  ACCOUNT_FROZEN,
  LOGIN_BOOKING_ID_MISMATCH,
  OTP_LOGIN_MAX_FAIL,
  OTP_LOGIN_SUBMIT,
  OTP_LOGIN_SUBMIT_ERROR,
  OTP_LOGIN_SUCCESS,
  OTP_LOGIN_WRONG_CODE,
  PHONE_LOGIN_NUMBER_NOT_FOUND,
  PHONE_LOGIN_NUMBER_NOT_FOUND_CREATE_ACCOUNT,
  PHONE_LOGIN_SUBMIT,
  PHONE_LOGIN_SUBMIT_ERROR,
  PHONE_VERIFICATION_FAILED,
  PHONE_VERIFICATION_LAST_NAME_UNVERIFIABLE,
  PHONE_VERIFICATION_NOT_REQUIRED,
  PHONE_VERIFICATION_REQUIRED,
} from '~/core/analytics/events';
import { PhoneVerificationStatus } from '~/components/PhoneLoginForm/util/verificationStatus';
import { setLoginInfo } from '~/core/session';
import { HOTJAR_TRACKING_LABEL } from '~/core/tracking/hotjar/constants';
import { useSolvSelector } from '~/reducers';
import { SET_TOS_ACCEPTED_SAGA } from '~/sagas/account';

const ACCOUNT_NOT_FOUND = 'Account not found';
const ACCOUNT_VERIFICATION_FAILED = 'Could not verify last name';
const ACCOUNT_IS_FROZEN = 'Account is frozen';

export const useLoginForm = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, remove] = useCookies();

  const login = useSolvSelector((state) => state.login);
  const initialValues = useSolvSelector((state) => {
    return {
      lastName: '',
      phone:
        state.bookingFlow.skippedLoginNumber ||
        state.login?.login?.phone ||
        state.newBooking?.lastCreatedBookingInfo?.phone ||
        state?.newBooking?.profile?.phone ||
        '',
    };
  });
  const verificationStatus = useSolvSelector((state) => state.runtime.phoneVerificationStatus);
  const requiresTosConsent = useSolvSelector((state) => !!state.runtime.requiresTosConsent);
  const accountSummary = useSolvSelector((state) => state.account.summary || {});
  const isLoginModalOpen = useSolvSelector((state) => !!state.runtime.modal?.loginModal);
  const location = useSolvSelector((state) => state.location);

  const onSuccessfulLogin = useCallback(
    (form) => (response: any) => {
      setLoginInfo(response, setCookie);
      analyticsTrackEvent(OTP_LOGIN_SUCCESS, {});

      let isPinkDoor: null | boolean = null;
      if (form[PINK_DOOR_ACCEPTED_FIELD_NAME]) {
        isPinkDoor = true;
        dispatch({ type: SET_TOS_ACCEPTED_SAGA, accountId: response.account_id });
      } else if (form[TOS_ACCEPTED_FIELD_NAME]) {
        isPinkDoor = false;
      }
      if (isPinkDoor !== null) {
        dispatch({ type: SET_TOS_ACCEPTED_SAGA, accountId: response.account_id, isPinkDoor });
      }

      return loginResponseReceived(response);
    },
    [dispatch, setCookie]
  );

  const verifyPhone = useCallback(
    (phone: any, bookingId: any) => {
      const onSuccess = (response: any) => {
        dispatch(
          setRuntimeVariable({
            name: 'requiresTosConsent',
            value: response.requires_tos_consent_initial,
          })
        );

        if (bookingId && !response.is_booking_match) {
          analyticsTrackEvent(LOGIN_BOOKING_ID_MISMATCH, {});
          return setRuntimeVariable({
            name: 'phoneVerificationStatus',
            value: { phoneNumber: phone, status: PhoneVerificationStatus.BOOKING_ID_MISMATCH },
          });
        }

        if (response.is_unverifiable) {
          analyticsTrackEvent(PHONE_VERIFICATION_LAST_NAME_UNVERIFIABLE, {});
          return setRuntimeVariable({
            name: 'phoneVerificationStatus',
            value: { phoneNumber: phone, status: PhoneVerificationStatus.UNVERIFIABLE },
          });
        }

        if (response.requires_verification) {
          analyticsTrackEvent(PHONE_VERIFICATION_REQUIRED, {});
          return setRuntimeVariable({
            name: 'phoneVerificationStatus',
            value: { phoneNumber: phone, status: PhoneVerificationStatus.UNVERIFIED },
          });
        }

        analyticsTrackEvent(PHONE_VERIFICATION_NOT_REQUIRED, {});
        return setRuntimeVariable({
          name: 'phoneVerificationStatus',
          value: { phoneNumber: phone, status: PhoneVerificationStatus.VERIFIED },
        });
      };

      const onError = () =>
        setRuntimeVariable({
          name: 'phoneVerificationStatus',
          value: { phoneNumber: phone, status: PhoneVerificationStatus.STATUS_ERROR },
        });

      dispatch(
        setRuntimeVariable({
          name: 'phoneVerificationStatus',
          value: { phoneNumber: phone, status: PhoneVerificationStatus.SUBMITTING },
        })
      );

      return dispatch(
        apiGetDispatchable(getVerificationStatus(phone, bookingId), onSuccess, onError)
      );
    },
    [dispatch]
  );

  const hideLoginModal = useCallback(() => dispatch(hideModal('loginModal')), [dispatch]);

  const handleFormSubmit = useCallback(
    (form: any, contactMethod = 'sms', onSubmitError = emptyFunction) => {
      const phone = sanitizePhone(form.phone);
      if (isTestPhoneNumber(phone)) {
        analyticsTriggerHotjarRecording(TEST_PHONE_NUMBER);
        analyticsTagHotjarRecording(TEST_PHONE_NUMBER);
      }

      const handleAccountCreateSuccess = () => {
        dispatch(
          apiPostDispatchable(
            getGenerateOneTimePasswordUrl(),
            { phone, contactMethod },
            generateOtpSuccess(phone),
            generateOtpFailure
          )
        );
        return noOp();
      };

      const handleAccountCreation = () => {
        dispatch(
          apiPostDispatchable(
            getCreateAccountUrl(),
            { phone },
            handleAccountCreateSuccess,
            noAccount(phone)
          )
        );
        return noOp();
      };

      const handleGenerateOtpFailure = (response: any) => {
        analyticsTrackFormErrors(PHONE_LOGIN_SUBMIT_ERROR, { ...response });

        if (response === ACCOUNT_IS_FROZEN) {
          onSubmitError(ACCOUNT_FROZEN);
          analyticsTrackEvent(ACCOUNT_FROZEN, {});
          return accountFrozen(phone);
        }

        if (response === ACCOUNT_VERIFICATION_FAILED) {
          onSubmitError(PHONE_VERIFICATION_FAILED);
          analyticsTrackEvent(PHONE_VERIFICATION_FAILED, {});
        }

        if (response === ACCOUNT_NOT_FOUND) {
          analyticsTrackEvent(PHONE_LOGIN_NUMBER_NOT_FOUND_CREATE_ACCOUNT, {});
          return handleAccountCreation();
        }

        onSubmitError(PHONE_LOGIN_NUMBER_NOT_FOUND);
        analyticsTrackEvent(PHONE_LOGIN_NUMBER_NOT_FOUND, {});
        dispatch(loginSubmitButtonDisabled(false));

        return generateOtpFailure(response);
      };

      analyticsTrackEvent(PHONE_LOGIN_SUBMIT, {});
      dispatch(
        apiPostDispatchable(
          getGenerateOneTimePasswordUrl(),
          { phone, contactMethod, last_name: form.lastName },
          generateOtpSuccess(phone),
          handleGenerateOtpFailure
        )
      );
      dispatch(setRuntimeVariable({ name: 'otpLoginLastName', value: form.lastName }));
    },
    [dispatch]
  );

  const setVerificationStatus = useCallback(
    (phone: any, status: any) =>
      dispatch(
        setRuntimeVariable({
          name: 'phoneVerificationStatus',
          value: { phoneNumber: phone, status },
        })
      ),
    [dispatch]
  );

  const resetLoginData = useCallback(() => dispatch(resetLoginDataAction()), [dispatch]);

  const handleOtpFormSubmit = useCallback(
    (form: any, cookies: any, onSubmitError = emptyFunction) => {
      analyticsTrackEvent(OTP_LOGIN_SUBMIT, {});
      dispatch(loginSubmitted(true));

      const handleLoginFailed = (response: any) => {
        analyticsTagHotjarRecording([HOTJAR_TRACKING_LABEL.otpLoginError, response]);

        switch (response) {
          case LOGIN_RESPONSE_MAX_ATTEMPTS_EXCEEDED:
            onSubmitError(OTP_LOGIN_MAX_FAIL);
            analyticsTrackEvent(OTP_LOGIN_SUBMIT_ERROR, { reason: OTP_LOGIN_MAX_FAIL });
            return loginFailMaxExceeded(response);

          case LOGIN_RESPONSE_WRONG_OTP:
            onSubmitError(OTP_LOGIN_WRONG_CODE);
            analyticsTrackEvent(OTP_LOGIN_SUBMIT_ERROR, { reason: OTP_LOGIN_WRONG_CODE });
            return loginFailed(response);

          default:
            onSubmitError(response);
            analyticsTrackEvent(OTP_LOGIN_SUBMIT_ERROR, { reason: response });
            return loginFailed(response);
        }
      };

      dispatch(postLoginDispatchable(form, onSuccessfulLogin(form), handleLoginFailed));
    },
    [dispatch, onSuccessfulLogin]
  );

  return {
    // The following were copied from connected mapStateToProps from connected login form
    login,
    initialValues,
    verificationStatus,
    requiresTosConsent,
    accountSummary,
    isLoginModalOpen,
    location,
    // Cookies was copied from withCookies on connected login form
    cookies: { cookies, setCookie, remove },
    verifyPhone,
    hideLoginModal,
    handleFormSubmit,
    setVerificationStatus,
    resetLoginData,
    handleOtpFormSubmit,
  };
};
