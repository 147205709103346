import { ButtonProps } from '@material-ui/core/Button';
import { whiteLabelPalettes } from '@solvhealth/jigsaw';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { usePalette } from '~/hooks/usePalette';
import {
  PinkPrimaryButton,
  PinkSmallPrimaryButton,
  TertiaryButton,
  LinkButton,
  buttonTypes,
  BluePrimaryButton,
  BlueSmallPrimaryButton,
  PinkTertiaryButton,
} from './Button/BaseButton';

import TransitionButton, { buttonStatuses } from './Button/TransitionButton';

export const ButtonVariations = {
  pinkPrimary: 'pinkPrimary',
  bluePrimary: 'bluePrimary',
  pinkPrimarySmall: 'pinkPrimarySmall',
  bluePrimarySmall: 'bluePrimarySmall',
  tertiary: 'tertiary',
  pinkTertiary: 'pinkTertiary',
  link: 'link',
};

const Fixed = styled.div`
  position: relative;
  ${(props) => css`
    width: ${
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
      props.width || '100%'
    };
  `};
`;

type OwnProps = {
  text?: string;
  variation?: any; // TODO: PropTypes.oneOf(buttonTypes)
  status?: any; // TODO: PropTypes.oneOf(buttonStatuses)
  width?: string;
  children?: any;
  animationEnabled?: boolean;
  className?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Button.defaultProps & ButtonProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'Button' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Button = (props: Props) => {
  const { variation, animationEnabled, ...other } = props;

  const { palette, paletteName } = usePalette();

  // I'm not sure the proper order, but text prop takes precedent over children here
  // can change if you want
  const text = props.text || props.children;
  /*
   For the animation/transition type button, you need to either provide a width,
   or give it a container to sit in in which case it will set itself to width 100%
  */
  return (
    <>
      {variation === ButtonVariations.pinkPrimary && animationEnabled && (
        // @ts-expect-error ts-migrate(2769) FIXME: Property 'width' does not exist on type 'Intrinsic... Remove this comment to see the full error message
        <Fixed width={other.width}>
          <TransitionButton {...other}>{text}</TransitionButton>
        </Fixed>
      )}
      {variation === ButtonVariations.pinkPrimary && !animationEnabled && (
        <PinkPrimaryButton variant="contained" {...other}>
          {text}
        </PinkPrimaryButton>
      )}
      {variation === ButtonVariations.bluePrimary && !animationEnabled && (
        <BluePrimaryButton variant="contained" {...other}>
          {text}
        </BluePrimaryButton>
      )}
      {variation === ButtonVariations.pinkPrimarySmall && (
        <PinkSmallPrimaryButton variant="contained" {...other}>
          {text}
        </PinkSmallPrimaryButton>
      )}
      {variation === ButtonVariations.bluePrimarySmall && (
        <BlueSmallPrimaryButton variant="contained" {...other}>
          {text}
        </BlueSmallPrimaryButton>
      )}
      {variation === ButtonVariations.tertiary && (
        <TertiaryButton variant="outlined" {...other}>
          {text}
        </TertiaryButton>
      )}
      {variation === ButtonVariations.pinkTertiary && (
        <PinkTertiaryButton variant="outlined" {...other}>
          {text}
        </PinkTertiaryButton>
      )}

      {variation === ButtonVariations.link && (
        <LinkButton color={paletteName !== 'crunchberry' ? palette.base : ''} {...other}>
          {text}
        </LinkButton>
      )}
    </>
  );
};

Button.defaultProps = {
  variation: 'pinkPrimary',
  animationEnabled: false,
};

export default Button;
