import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import Button, { ButtonVariations } from '../SolvPatternLibrary/Button';
import { fontSize } from '../../constants/text';
import {
  canAskToEnablePush,
  handleAcceptWebPushPermission,
  handleDenyWebPushPermission,
} from './util';
import { emptyFunction } from '../../core/util/function';
import { CREATE_WEB_PUSH_SUBSCRIPTION } from '../../sagas/webPushSubscriptions';
import withNotifications from '../../core/notifications/withNotifications';

const ActionButton = styled(Button)`
  font-size: ${fontSize.mediumSmall};
  line-height: ${fontSize.mediumSmall};
`;

const mapDispatchToProps = (dispatch: any) => ({
  createWebPushSubscription: (accountId: any, subscription: any) =>
    dispatch({
      type: CREATE_WEB_PUSH_SUBSCRIPTION,
      accountId,
      subscription,
    }),
});

type OwnOptInProps = {
  handleDenyCustomBehavior?: (...args: any[]) => any;
  handleAcceptCustomBehavior?: (...args: any[]) => any;
  webPushOptInOrigin: string;
  createWebPushSubscription: (...args: any[]) => any;
  notificationsSubscribe: (...args: any[]) => any;
  accountId: string;
  callbackUponSubscribe?: (...args: any[]) => any;
  optInCopy?: any;
};

type OptInProps = OwnOptInProps & typeof OptIn.defaultProps;

class OptIn extends PureComponent<OptInProps> {
  static defaultProps = {
    handleAcceptCustomBehavior: emptyFunction,
    handleDenyCustomBehavior: emptyFunction,
    callbackUponSubscribe: emptyFunction,
    optInCopy:
      "You'll need to accept notifications on the next" +
      ' screen to receive health checklist reminders.',
  };

  handleAccept = () => {
    const {
      handleAcceptCustomBehavior,
      webPushOptInOrigin,
      notificationsSubscribe,
      createWebPushSubscription,
      accountId,
      callbackUponSubscribe,
    } = this.props;

    handleAcceptCustomBehavior();

    return handleAcceptWebPushPermission(webPushOptInOrigin, notificationsSubscribe)
      .then((subscribeObject: any) => createWebPushSubscription(accountId, subscribeObject))
      .then(callbackUponSubscribe)
      .catch(() => handleDenyWebPushPermission(webPushOptInOrigin, true));
  };

  handleDeny = () => {
    const { handleDenyCustomBehavior, webPushOptInOrigin } = this.props;

    handleDenyCustomBehavior();

    return handleDenyWebPushPermission(webPushOptInOrigin);
  };

  render = () => {
    const { optInCopy } = this.props;
    if (!canAskToEnablePush()) return null;

    return (
      <>
        <DialogTitle>Solv would like to send you notifications.</DialogTitle>

        <DialogContent>
          <DialogContentText>{optInCopy}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <ActionButton onClick={this.handleDeny} variation={ButtonVariations.link}>
            NOT NOW
          </ActionButton>

          <ActionButton onClick={this.handleAccept} variation={ButtonVariations.link}>
            CONTINUE
          </ActionButton>
        </DialogActions>
      </>
    );
  };
}

export default connect(null, mapDispatchToProps)(withNotifications(OptIn));
