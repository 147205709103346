import { Link } from '@solvhealth/jigsaw';
import { string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LEGAL_TOS_URL } from '../../constants/index';

type Props = {
  hash?: string;
  children?: React.ReactNode;
  className?: string;
};

const TermsOfService = ({ hash = '', className, children }: Props) => {
  const { t } = useTranslation();
  return (
    <Link
      className={className}
      external
      href={`${LEGAL_TOS_URL}${hash ? `#${hash}` : ''}`}
      inline
      openInNewWindow
    >
      {children || t('legalLinks.termsOfService')}
    </Link>
  );
};

export default TermsOfService;
