import {
  ENV_DEV,
  ENV_LIVESTAGE,
  ENV_PROD,
  ENV_TEST,
  LOCATION_SOURCE_HARDCODED,
} from '../constants';
import logger, { LOG_LEVEL_DEBUG, LOG_LEVEL_INFO } from '../core/logger';
import { isServerSide } from '../core/util/system';

const isEmptyObject = (obj) => {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  }

  if (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  return true;
};
const isEmptyString = (str) => typeof str === 'undefined' || str === null || str.length === 0;

let safeWindow = {};
if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-global-assign
  safeWindow = window;
}

/* eslint-disable max-len */
/* jscs:disable maximumLineLength */

export const ENV = process.env.SOLV_ENV || process.env.NODE_ENV || 'development';
export const PORT = process.env.PORT || 5000;

let acuantApiKey = null;
let dApiHost = null;
let dApiNoCacheHost = null;
let dApiCactusHost = null;
let googleMapsClientApiKey = null;
let trackingApiHost = null;
let trackingApiEnabled = 'false';
let universalSearchApiHost = null;
let mAppHost = null;
let mapboxToken = null;
const dApiAuthEnabled = true;
let segmentApiKey = 'b8gGF9cYxa5rnP7IncdE5MsnK66vaqZt';
let mAnalyticsEnabled = true;
let mApiClientId = '75e552fec6424520a304f5c5a9e5f51c';
let mApiClientSecret = process.env.M_API_CLIENT_SECRET;
let mIntercomEnabled = true; // todo: deprecate
let intercomAppId = process.env.INTERCOM_APP_ID; // production app https://app.intercom.io/a/apps/ggxrd885
let intercomSecret = process.env.INTERCOM_SECRET_HASH;
let tokboxApiKey = null;
let launchDarklyClientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID;
let remixAppUrl = ''; // on prod/livestage environments, we proxy so that both apps share a URL

// It's ok to expose these stripe keys here -- they are meant to be public, and this lets them
// be the default without having to set an env var on every heroku instance
let stripeApiKey = 'pk_test_e86KtpsFhEw4rOxoEuRAy2A3';
let stripeB2CApiKey =
  'pk_test_51Iy41UHC9udesTpGpkEjtMvLfKP4gPFePyusO6X0fzgMASNmitiyv76MasO89c2iZ7GUD7PdSyJkPF2IAeJ5GRyR00hGRuV0Jt';

logger.setLevel(LOG_LEVEL_INFO);

if (ENV === ENV_DEV) {
  mApiClientId = '75e552fec6424520a304f5c5a9e5f51c';
  logger.setLevel(LOG_LEVEL_DEBUG);
  segmentApiKey = null;
  mAnalyticsEnabled = false;
  mIntercomEnabled = true;
}

export const IS_COOKIE_HTTPS_ONLY = ENV !== ENV_DEV;

export const ANALYTICS = {
  enabled: mAnalyticsEnabled,
  segment: { apiKey: segmentApiKey },
};

export const ACUANT_BASE_URL =
  'https://cssnwebservices.com/CSSNService/CardProcessor/ProcessMedInsuranceCard';
export const ACUANT_TIMEOUT = 20000; // 20s in ms
export const INSURANCE_COVERAGE_TIMEOUT = 25000; // 25s in ms

if (isServerSide() || isEmptyObject(safeWindow.CFG)) {
  acuantApiKey = process.env.ACUANT_API_KEY;
  dApiHost = process.env.DAPI_HOST;
  trackingApiHost = process.env.TRACKING_API_HOST;
  trackingApiEnabled = process.env.TRACKING_API_ENABLED;
  universalSearchApiHost = process.env.UNIVERSAL_SEARCH_API_HOST;
  dApiNoCacheHost = process.env.DAPI_HOST_NO_CACHE;
  dApiCactusHost = process.env.DAPI_CACTUS_HOST;
  mAppHost = process.env.MAPP_HOST;
  mapboxToken = process.env.MAPBOX_TOKEN;
  tokboxApiKey = process.env.TOKBOX_API_KEY;
  stripeB2CApiKey = process.env.STRIPE_B2C_KEY;
  googleMapsClientApiKey = process.env.GOOGLE_MAPS_CLIENT_API_KEY;
  remixAppUrl = process.env.REMIX_APP_URL;
} else {
  if (safeWindow?.CFG?.ACUANT_API_KEY) {
    acuantApiKey = safeWindow?.CFG?.ACUANT_API_KEY;
  } else {
    logger.error('ACUANT_API_KEY undefined');
  }

  if (safeWindow?.CFG?.DAPI_HOST) {
    dApiHost = safeWindow?.CFG?.DAPI_HOST;
    dApiNoCacheHost = safeWindow?.CFG?.DAPI_HOST_NO_CACHE;
    dApiCactusHost = safeWindow?.CFG?.DAPI_CACTUS_HOST;
  } else {
    logger.error('DAPI_HOST undefined');
  }

  if (safeWindow?.CFG?.M_API_CLIENT_SECRET) {
    mApiClientSecret = safeWindow?.CFG?.M_API_CLIENT_SECRET;
  }

  if (safeWindow?.CFG?.MAPP_HOST) {
    mAppHost = safeWindow?.CFG?.MAPP_HOST;
  } else {
    logger.error('MAPP_HOST undefined');
  }

  if (safeWindow?.CFG?.MAPBOX_TOKEN) {
    mapboxToken = safeWindow?.CFG?.MAPBOX_TOKEN;
  } else {
    logger.error('MAPBOX_TOKEN undefined');
  }

  if (safeWindow?.CFG?.TOKBOX_API_KEY) {
    tokboxApiKey = safeWindow?.CFG?.TOKBOX_API_KEY;
  } else {
    logger.error('TOKBOX_API_KEY undefined');
  }

  if (safeWindow?.CFG?.TRACKING_API_HOST) {
    trackingApiHost = safeWindow?.CFG?.TRACKING_API_HOST;
  }

  if (safeWindow?.CFG?.UNIVERSAL_SEARCH_API_HOST) {
    universalSearchApiHost = safeWindow?.CFG?.UNIVERSAL_SEARCH_API_HOST;
  }

  if (!isEmptyString(safeWindow?.CFG?.TRACKING_API_ENABLED)) {
    trackingApiEnabled = safeWindow?.CFG?.TRACKING_API_ENABLED;
  }

  if (window.CFG.STRIPE_B2C_KEY) {
    stripeB2CApiKey = window.CFG.STRIPE_B2C_KEY;
  }

  if (window.CFG.LAUNCH_DARKLY_CLIENT_SIDE_ID) {
    launchDarklyClientSideId = window.CFG.LAUNCH_DARKLY_CLIENT_SIDE_ID;
  }

  if (window.CFG.INTERCOM_APP_ID) {
    intercomAppId = window.CFG.INTERCOM_APP_ID;
  }

  if (safeWindow?.CFG?.GOOGLE_MAPS_CLIENT_API_KEY) {
    googleMapsClientApiKey = safeWindow.CFG.GOOGLE_MAPS_CLIENT_API_KEY;
  }

  if (safeWindow?.CFG?.REMIX_APP_URL) {
    remixAppUrl = safeWindow.CFG.REMIX_APP_URL;
  }
}

export const ACUANT_API_KEY = acuantApiKey;
// eslint-disable-next-line import/no-mutable-exports
export let DAPI_HOST = dApiHost;
// eslint-disable-next-line import/no-mutable-exports
export let DAPI_HOST_NO_CACHE = dApiNoCacheHost;
// eslint-disable-next-line import/no-mutable-exports
export let DAPI_CACTUS_HOST = dApiCactusHost;
export const DAPI_AUTH_ENABLED = dApiAuthEnabled;
export const DAPI_TOKEN = '90dd1fcea0074e7eb4b11e3753a0a334';
export const DAPI_CLIENT_ID = mApiClientId;
export const DAPI_CLIENT_SECRET = mApiClientSecret;
export const REMIX_APP_URL = remixAppUrl;

export const GOOGLE_MAPS_CLIENT_API_KEY = googleMapsClientApiKey;
export const TOKBOX_API_KEY = tokboxApiKey;
export const STRIPE_B2C_KEY = stripeB2CApiKey;
export const LAUNCH_DARKLY_CLIENT_SIDE_ID = launchDarklyClientSideId;
export const INTERCOM_SECRET = intercomSecret;

export const TRACKING_API = {
  host: trackingApiHost,
  enabled: trackingApiEnabled,
};

export const UNIVERSAL_SEARCH_API_HOST = universalSearchApiHost;
export const MAPBOX_TOKEN = mapboxToken;

// https://web-push-codelab.glitch.me/
// or https://github.com/web-push-libs/web-push#command-line
// read more about it here https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user
export const NOTIFICATIONS_APPLICATION_SERVER_KEY =
  'BPwC7wRhtAsnrkmTsKNN8_3FpR_qFqlBxpcQgvuT8Pdkay7i9KqO1jB0nlgZlc4yAKgWVfcqfh7fUdd7eCD4HEg';

export const CLOUD_FRONT_URL = 'https://d3hmu1js3tz3r1.cloudfront.net';

export class CookieNames {
  static INSURANCE_TUTORIAL = 'it';

  static INSURANCE_BADGE = 'insurance-badge';
  static MIXPANEL = 'mp_ec84a100405052b77a1520a3ecebc121_mixpanel';
}
export const OVERRIDE_LAUNCH_DARKLY_FLAGS_COOKIE_NAME = 'override-launch-darkly-flags';
export const SEGMENT_ANONYMOUS_ID_COOKIE_NAME = 'ajs_anonymous_id';
export const LOGIN_INFO_COOKIE_NAME = 'slgn';
export const USER_INFO_COOKIE_NAME = 'usr';
export const LONG_TERM_SESSION_ID_COOKIE_NAME = 'ltsid';
export const SHORT_TERM_SESSION_ID_COOKIE_NAME = 'stsid';
export const ADD_TO_HOME_SCREEN_POP_UP_COOKIE_NAME = 'hide-add-to-home-screen';
export const SCHOOL_NAME_SELECT_VALUE_COOKIE_NAME = 'snsvcn';
export const IS_COMING_FROM_HANDOFF_COOKIE_NAME = 'icfh';
export const IS_COMING_FROM_ACCOUNT_COOKIE_NAME = 'icfa';
export const DOMAIN_REFERRER_COOKIE_NAME = 'sdrc';
export const DO_NOT_TRACK_COOKIE_NAME = 'dntcn';
export const URL_PARAMS_HISTORY_COOKIE_NAME = 'urlParamsHist';
export const PUSH_NOTIFICATION_FULL_SCREEN_PROMPT_COOKIE_NAME = 'pnfsp';
export const RESERVE_WITH_GOOGLE_COOKIE_NAME = 'rwg';
export const NOT_RESERVE_WITH_GOOGLE_COOKIE_NAME = 'not_rwg';
export const GOOGLE_HEALTH_APPOINTMENTS_COOKIE_NAME = 'gha';
export const GOOGLE_MAP_PACK_COOKIE_NAME = 'gmp';

export const WELCOME_TOOLTIP_COOKIE_NAME = 'welcomeToolTip';

export const SLOT_LOOK_BACK_DURATION = 0; // milliseconds

export const METERS_IN_A_MILE = 1609;
export const DEFAULT_SEARCH_RADIUS = 35;
export const SEARCH_RADIUS_LOCATIONS_WITHIN_SAME_GROUP = 15; // miles
export const DEFAULT_RADIUS_SEARCH_INCREMENTS = 10; // miles
export const RADIUS_SEARCH_THRESHOLD = 55; // miles
export const DEFAULT_COBRANDED_SRP_RADIUS = 7;

export const DEFAULT_TELEMED_SLA = 15; // mins (here just in case)
export const GOOGLE_MAPS_API_SCRIPT = 'googleMapsApi';
export const INTERCOM_APP_ID = intercomAppId; // ENV specific
export const INTERCOM_APP_ID_CHAT_TEST = 'trcc5a83';
export const STRIPE_SCRIPT = 'stripeApi';
export const INTERCOM_SCRIPT = 'intercomApi';

export const MAX_UPLOAD_SIZE_MB = 20;

export const isDev = () => ENV === ENV_DEV;
export const isTest = () => ENV === ENV_TEST;

export const isProd = () =>
  ENV === ENV_PROD && DAPI_HOST.indexOf('stage') === -1 && DAPI_HOST.indexOf('preview') === -1;

let sentryDsn = null;

// This only sets the DSN when doing a production build (when DAPI_HOST doensn't contain "stage" or
// "livestage"), and effectively disables Sentry for all non-prouduction builds
if (isProd()) {
  sentryDsn = 'https://9d5cf093b6664a408a034375bad2ba12@sentry.io/110098';
}

export const SENTRY_DSN = sentryDsn;
export const SENTRY_ORGANIZATION = 'solv-health-4m';
export const SENTRY_PROJECT = 'mapp';
// This token is defined as an integration in the Sentry.io dashboard, and
// it only has the "project:releases" permission set to allow creating releases and uploading
// sourcemaps
export const { SENTRY_SOURCEMAP_TOKEN } = process.env;

if (isProd()) {
  stripeApiKey = 'pk_live_rHXxAGaubCMaOb3LSXdjNdhy';
}

export const STRIPE_API_KEY = stripeApiKey;

export const HOST = mAppHost;

export const DEFAULT_LOCATION_DALLAS = {
  source: LOCATION_SOURCE_HARDCODED,
  latitude: 32.7766642,
  longitude: -96.7969879,
  city: 'Dallas',
  state: 'TX',
};

export const DEFAULT_LOCATION_FORT_WORTH = {
  source: LOCATION_SOURCE_HARDCODED,
  latitude: 32.7554883,
  longitude: -97.3307658,
  city: 'Fort Worth',
  state: 'TX',
};

export const DEFAULT_LOCATION_ARLINGTON = {
  source: LOCATION_SOURCE_HARDCODED,
  latitude: 32.735687,
  longitude: -97.1080656,
  state: 'TX',
  city: 'Arlington',
};

export const DEFAULT_LOCATION_PLANO = {
  source: LOCATION_SOURCE_HARDCODED,
  latitude: 33.0198431,
  longitude: -96.69888559999998,
  state: 'TX',
  city: 'Plano',
};

export const DEFAULT_LOCATION_IRVING = {
  source: LOCATION_SOURCE_HARDCODED,
  latitude: 32.8140177,
  longitude: -96.9488945,
  state: 'TX',
  city: 'Irving',
};

export const DEFAULT_LOCATION_DETROIT = {
  source: LOCATION_SOURCE_HARDCODED,
  latitude: 42.3315509,
  longitude: -83.0466403,
  state: 'MI',
  city: 'Detroit',
};

export const DEFAULT_LOCATION_LIST = [
  DEFAULT_LOCATION_DALLAS,
  DEFAULT_LOCATION_FORT_WORTH,
  DEFAULT_LOCATION_ARLINGTON,
  DEFAULT_LOCATION_PLANO,
  DEFAULT_LOCATION_IRVING,
  DEFAULT_LOCATION_DETROIT,
];

export const GEOLOCATION_TIMEOUT_IN_MILLISECONDS = 10 * 1000;
export const GEOLOCATION_MAXIMUM_AGE_IN_MILLISECONDS = 60 * 1000;

export const IP_GEOLOCATION_ACCURACY_THRESHOLD_IN_METERS = 10000;
export const ACCEPTABLE_APPOINTMENT_RANGE_IN_MINUTES = 150;
export const IP_GEOLOCATION_TTL = 1.8e6; // 30 minutes in milliseconds

export const TELEMED_TEST_IS_ON = false;

// show opaque card on SRP immediately after this distance
export const OPAQUE_DISTANCE = TELEMED_TEST_IS_ON ? 7 : 10;

export const BEST_MATCH_MAXIMUM_DISTANCE_IN_METERS =
  (TELEMED_TEST_IS_ON ? 7 : 15) * METERS_IN_A_MILE;

export const OPAQUE_CONFIG = {
  appointmentInterval: 30,
  appointmentSlots: -1,
  hours: {},
};

export const SOLV_OPENS_AT = '08:00:00';
export const SOLV_CLOSES_AT = '17:30:00';

export const MAX_CONTENT_WIDTH = 480;

export const MINIMUM_HIGHLY_RATED_FOR = 4.5;

// Wait list is configured to only be available within two hours of the appointment time.
// 2 * 60 * 60 * 1000 is two hours in milliseconds.
export const WAIT_LIST_TIME_WINDOW = 2 * 60 * 60 * 1000;

export const VIDEO_VISIT_REFETCH_WAIT_LIST_INTERVAL = 2 * 60 * 1000;

export const FACEBOOK_APP_ID = '333374423692725';
export const SMOOCH_APP_ID = '5cd331ca2539150010ff7cae';
export const STRIPE_URL = 'https://js.stripe.com/v3/';

// Minimum input chars required to trigger autosuggest (react-select component)
export const AUTOSUGGEST_MINIMUM_INPUT_CHARS = 3;

// Number of Reviews returned from DAPI
export const REVIEW_LIMIT = 10;

export const BIRTH_DATE_FORMAT = 'YYYY-MM-DD';
export const BIRTH_DATE_SLASH_FORMAT = 'MM/DD/YYYY';

export const SEARCH_PREFERENCES_MIN_TIME = '06:00:00';
export const SEARCH_PREFERENCES_MAX_TIME = '22:00:00';

export const DEFAULT_URGENT_CARE_REASON_FOR_VISIT = 'Feeling Sick';

// How far before the open time to be considered 'soon'
export const OPEN_SOON_TIME = 2;
export const CLOSE_SOON_TIME = 1;

export const METRO_URGENT_CARE_FACEBOOK_TRACKING_ID = '167157893633823';
export const METRO_URGENT_CARE_ADWORDS_TRACKING_ID = 'AW-835736996';
export const METRO_URGENT_CARE_ADWORDS_CONVERSION_LABEL = 'AW-835736996/jKO1CPKuh30QpKvBjgM';

export const REFRESH_TOKEN_TIMEOUT = 10 * 60 * 1000; // 10 minutes

export const REDIRECT_TIMEOUT = 2500;

const getBaseUrl = () => {
  let baseUrl = HOST || safeWindow?.CFG?.MAPP_HOST;

  if (baseUrl) {
    if (baseUrl.includes('localhost')) {
      baseUrl = `http://${baseUrl}`;
    } else {
      baseUrl = `https://${baseUrl}`;
    }
  }

  return baseUrl;
};

export const PAST_VISIT_DISPLAY_TIME_WINDOW = 72;

export const BASE_URL = getBaseUrl();

export const MICHIGAN_GOV_WEBSITE_URL = 'https://www.michigan.gov';
export const HELP_BASE_URL = 'https://patients.solvhealth.com/';

// FEATURE FLAGS - please note whether they're temporary for future cleanup.

// used seasonally
const isFluSeasonFeatureFlag = () => {
  const flag = (typeof window === 'undefined' ? process.env : window.CFG)?.FF_IS_FLU_SEASON;
  return typeof flag === 'string' ? flag === 'true' : flag;
};

export const IS_FLU_SEASON = isFluSeasonFeatureFlag();
