/* jscs:disable maximumLineLength */
import ReactGA from 'react-ga';
import { getCurrentPath } from '../../core/util/url';
import { isEmptyObject } from '../../core/util/object';
import { isEmptyString } from '../../core/util/string';
import UrgentCareForKidsBingPixel from '../../components/Tracking/UrgentCareForKidsBing';
import MetroUrgentCareAdWords from '../../components/Tracking/MetroUrgentCareAdWords';
import { isDev } from '../../config/index';
import { safeGet } from '../util/object';
import { isEmpty } from '../util/empty';

export const GA_EVENT = 'event';

export const GA_EVENT_ACTION_BOOKING = 'submit';
export const GA_EVENT_ACTION_STATUS_TO_CANCELLED = 'status-change-cancelled';

export const GA_EVENT_CATEGORY_BOOKING = 'booking';
export const GA_EVENT_CATEGORY_WORKFLOW = 'workflow';

export const GA_PAGEVIEW = 'pageview';

export const GA_EVENT_CATEGORY_EXTERNAL_BOOKING = 'external-booking';
export const GA_EXTERNAL_BOOKING = 'external-booking-flow';

const fireGaTracking = (trackerId: any, trackerName: any, trackingType: any, opts = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventAction' does not exist on type '{}'... Remove this comment to see the full error message
  if (trackingType === GA_EVENT && (!opts.eventAction || !opts.eventCategory)) {
    throw new Error('Need to pass both eventAction and eventCategory for event tracking');
  }

  const gaOptions = {
    allowLinker: true,
    alwaysSendReferrer: true,
    cookieDomain: 'auto',
    name: trackerName,
  };

  ReactGA.initialize(trackerId, { gaOptions });

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'forceSsl' does not exist on type '{}'.
  if (opts.forceSsl) {
    ReactGA.ga(`${trackerName}.set`, 'forceSSL', true);
  }

  ReactGA.ga(`${trackerName}.require`, 'linker');

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'referringDomain' does not exist on type ... Remove this comment to see the full error message
  if (opts.referringDomain) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'referringDomain' does not exist on type ... Remove this comment to see the full error message
    ReactGA.ga(`${trackerName}.linker:autoLink`, [opts.referringDomain]);
  }

  if (trackingType === GA_PAGEVIEW) {
    ReactGA.ga(`${trackerName}.send`, trackingType, getCurrentPath());
  } else if (trackingType === GA_EVENT) {
    ReactGA.ga(
      `${trackerName}.send`,
      trackingType,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventCategory' does not exist on type '{... Remove this comment to see the full error message
      opts.eventCategory,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventAction' does not exist on type '{}'... Remove this comment to see the full error message
      opts.eventAction,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventLabel' does not exist on type '{}'.
      opts.eventLabel
    );
  }
};

const getAdWordsTrackingProperties = (location: any) =>
  location?.trackingProperties?.google_adwords ?? location?.tracking_properties?.google_adwords;

const isConfiguredForAdWordsTracking = (location: any) => {
  const adWordsTrackingProperties = getAdWordsTrackingProperties(location);
  return !!(adWordsTrackingProperties?.tracking_id && adWordsTrackingProperties?.conversion_label);
};

const isConfiguredForGoogleAnalyticsTracking = (location: any) =>
  !(
    isEmptyObject(location.trackingProperties) ||
    isEmptyObject(location.trackingProperties.google_analytics) ||
    isEmpty(safeGet(location.trackingProperties)('google_analytics.id')) ||
    isEmpty(safeGet(location.trackingProperties)('google_analytics.tracker_name')) ||
    isEmpty(safeGet(location.trackingProperties)('google_analytics.referring_domain'))
  );

const fireGoogleAnalytics = (
  location: any,
  eventType = GA_PAGEVIEW,
  eventAction?: any,
  eventCategory?: any,
  eventLabel?: any
) => {
  if (isDev()) {
    console.warn('Google Analytics Tracking cannot be fired in dev. exiting.');
    return;
  }

  if (
    isEmptyObject(location.trackingProperties) ||
    isEmptyObject(location.trackingProperties.google_analytics)
  ) {
    console.error(
      `Could not fire tracking! Tracking properties for location ${location.id} are not defined`
    );
    return;
  }

  const trackingProperties = location.trackingProperties.google_analytics;
  const trackingId = trackingProperties.id;
  const trackerName = trackingProperties.tracker_name;
  const referringDomain = trackingProperties.referring_domain;

  if (isEmptyString(trackingId) || isEmptyString(trackerName) || isEmptyString(referringDomain)) {
    console.error(
      'Could not fire tracking! trackingId, trackerName, referringDomain must be ' +
        `defined correctly for location ${location.id} `
    );
    return;
  }

  const forceSsl =
    typeof trackingProperties.force_ssl === 'undefined' ? false : trackingProperties.force_ssl;

  const options = {
    referringDomain,
    forceSsl,
  };

  if (eventType === GA_EVENT) {
    // FIXME: the implementation of this function in manage does not use the location id as a label if none is passed.
    //  The mapp-dev and manage-dev implementations should match.
    const label = eventLabel || location.id;

    if (isEmptyString(eventAction) || isEmptyString(eventCategory)) {
      console.error(
        'Could not fire tracking! Tracking properties eventAction and eventCategory ' +
          'need to be specified for a GA_EVENT'
      );
      return;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventAction' does not exist on type '{ r... Remove this comment to see the full error message
    options.eventAction = eventAction;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventCategory' does not exist on type '{... Remove this comment to see the full error message
    options.eventCategory = eventCategory;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventLabel' does not exist on type '{ re... Remove this comment to see the full error message
    options.eventLabel = label;
  }

  fireGaTracking(trackingId, trackerName, eventType, options);
};

const getUrgentCareForKidsBingTrackingPixel = () => UrgentCareForKidsBingPixel();
const getMetroUrgentCareAdwordsTracking = () => MetroUrgentCareAdWords();

export {
  getUrgentCareForKidsBingTrackingPixel,
  getMetroUrgentCareAdwordsTracking,
  isConfiguredForGoogleAnalyticsTracking,
  isConfiguredForAdWordsTracking,
  getAdWordsTrackingProperties,
  fireGoogleAnalytics,
};
