export const ONBOARDING_COMPLETE_STEP = 'sagas/ONBOARDING_COMPLETE_STEP';
export const ONBOARDING_CLEAR_COMPLETED_STEP = 'sagas/ONBOARDING_CLEAR_COMPLETED_STEP';

export const ONBOARDING_VERIFICATION_SUBMITTING = 'sagas/ONBOARDING_VERIFICATION_SUBMITTING';
export const ONBOARDING_VERIFICATION_RESPONSE = 'sagas/ONBOARDING_VERIFICATION_RESPONSE';
export const ONBOARDING_VERIFICATION_ERROR = 'sagas/ONBOARDING_VERIFICATION_ERROR';

// actions
export const completeOnboardingStep = (value: any) => ({
  type: ONBOARDING_COMPLETE_STEP,
  payload: { value },
});

export const onboardingClearCompletedStep = () => ({
  type: ONBOARDING_CLEAR_COMPLETED_STEP,
});

export const onboardingVerificationSubmitting = () => ({
  type: ONBOARDING_VERIFICATION_SUBMITTING,
});

export const onboardingVerificationReceived = (value: any) => ({
  type: ONBOARDING_VERIFICATION_RESPONSE,
  payload: { value },
});

export const onboardingVerificationFailed = (value: any) => ({
  type: ONBOARDING_VERIFICATION_ERROR,
  payload: { value },
});

// reducers
export default (state = { completedStep: '' }, action: any) => {
  switch (action.type) {
    case ONBOARDING_COMPLETE_STEP:
      return {
        ...state,
        completedStep: action.payload.value,
      };
    case ONBOARDING_CLEAR_COMPLETED_STEP:
      return {
        ...state,
        completedStep: null,
      };
    case ONBOARDING_VERIFICATION_SUBMITTING:
      return {
        ...state,
        isVerificationSubmitting: true,
        verificationResponse: null,
      };
    case ONBOARDING_VERIFICATION_RESPONSE:
      return {
        ...state,
        isVerificationSubmitting: false,
        verificationResponse: action.payload.value,
        verificationError: null,
      };
    case ONBOARDING_VERIFICATION_ERROR:
      return {
        ...state,
        isVerificationSubmitting: false,
        verificationError: action.payload.value,
      };
    default:
      return state;
  }
};
