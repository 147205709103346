import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { InsuranceCarrierPlan } from '.';

export const sortInsuranceCarrierPlans = (plans: InsuranceCarrierPlan[]) => {
  return sortBy(plans, (plan) => plan?.insurance_plan_display_name);
};

export const organizeInsuranceCarrierPlansByCarrier = (plans: InsuranceCarrierPlan[]) => {
  return groupBy(plans, (plan) => plan.insurer_code);
};

export const indexesOfPlansOrganizedByCarrier = (plansOrganizedbyCarrier: {
  [insurer_code: string]: InsuranceCarrierPlan[];
}): {
  [insurance_plan_id: string]: {
    insurer_code: string;
    indexInInsurancePlansGroupedByInsurer: number;
  };
} => {
  const planIndexesOrganizedByCarrier: ReturnType<typeof indexesOfPlansOrganizedByCarrier> = {};
  for (const insurer_code of Object.keys(plansOrganizedbyCarrier)) {
    const plansForInsurer = plansOrganizedbyCarrier[insurer_code];
    for (let indexOfPlan = 0; indexOfPlan < plansForInsurer.length; indexOfPlan++) {
      const plan = plansForInsurer[indexOfPlan];
      planIndexesOrganizedByCarrier[plan.insurance_plan_id] = {
        indexInInsurancePlansGroupedByInsurer: indexOfPlan,
        insurer_code,
      };
    }
  }
  return planIndexesOrganizedByCarrier;
};
