import { TextField } from '@solvhealth/jigsaw';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SolvAutocomplete } from '~/components/Paperwork/components/Payment/SolvAutocomplete';
import { PositionTrigger, useUserPosition } from '~/hooks/geo/useUserPosition';
import { useSolvSelector } from '~/reducers';
import { SEARCH_PHARMACIES } from '../../../constants/index';
import { getLatLongStr, isTelemedLocation } from '../../../core/util/location';

const ALLOWED_PLACE_TYPES = ['pharmacy', 'health', 'grocery_or_supermarket', 'store'];

const PharmacyAutoSuggestInput = (props: any) => {
  const [query, setQuery] = useState('');
  const { position } = useUserPosition(PositionTrigger.IPOnly);
  const dispatch = useDispatch();

  const { location, pharmacies } = useSolvSelector((state) => ({
    location: state.location,
    pharmacies: state.runtime.pharmacySearchResults,
  }));

  const fetchResults = useCallback(
    (searchQuery: string) => {
      const latLng =
        isTelemedLocation(location) && position.result
          ? `${position.result.latitude},${position.result.longitude}`
          : getLatLongStr(location);

      dispatch({ type: SEARCH_PHARMACIES, name: searchQuery, latLng });
    },
    [dispatch, location, position.result]
  );

  // eslint doesn't understand `debounce`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((query) => {
      fetchResults(query);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedUpdate(query);
  }, [fetchResults, query, debouncedUpdate]);

  const filterPharmacySuggestions = (options: any, state: any) => {
    if (!options) return [];

    return options?.filter((option: any) =>
      (option.types as string[]).some((type) => ALLOWED_PLACE_TYPES.includes(type))
    );
  };

  const handleSelection = (e: ChangeEvent<any>, value: any) => {
    const suggestion = value;
    props.onChange(e, { newValue: `${suggestion.name}, ${suggestion.vicinity}` });
  };

  return (
    <SolvAutocomplete
      autoHighlight
      disableClearable
      filterOptions={filterPharmacySuggestions}
      getOptionLabel={(pharmacy: any) => pharmacy?.name}
      getOptionSubtitle={(option) => option?.vicinity}
      onChange={handleSelection}
      onInputChange={(e) => setQuery((e.target as any).value)}
      options={pharmacies ?? []}
      renderTextField={(textFieldProps) => (
        <TextField dense style={{ position: 'relative' }} title={props.label} {...textFieldProps} />
      )}
    />
  );
};

export default PharmacyAutoSuggestInput;
