import { WhiteLabelPaletteName } from '@solvhealth/jigsaw';
import { RESET_THEME, SET_THEME } from '~/constants';

export interface ThemeState {
  palette: WhiteLabelPaletteName;
  bookingId: string;
}
const INITIAL_STATE: ThemeState = {
  palette: 'crunchberry',
  bookingId: '',
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_THEME:
      return INITIAL_STATE;
    case SET_THEME:
      return {
        ...state,
        palette: action.payload.paletteName,
      };
    default:
      return state;
  }
};

export default reducer;
