import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import runtime, { RuntimeState } from './runtime';
import searchPreferences, { SearchPreferencesState } from './searchPreferences';
import location from './location';
import newBooking, { newBookingInitialState, NewBookingState } from './newBooking';
import pastBookings, { PastBookingsState } from './pastBookings';
import position, { PositionState } from './position';
import imageData, { ImageDataState } from './imageData';
import insurers, { InsurersState } from './insurers';
import npi from './npi';
import eligibilityCheck from './eligibilityCheck';
import autocomplete from './autocomplete';
import waitList from './waitList';
import outsideServiceArea from './outsideServiceArea';
import toolTip from './toolTip';
import reviews from './reviews';
import tracking from './tracking';
import requestSolvEmail from './requestSolvEmail';
import favorite from './favorite';
import login, { LoginState } from './login';
import account, { AccountState } from './account';
import insuranceProfile from './insuranceProfile';
import distanceMatrix from './distanceMatrix';
import clinicLead from './clinicLead';
import waitTime from './waitTime';
import content from './content';
import payment from './payment';
import addReview from './addReview';
import paperwork, { PaperworkState } from '../ducks/paperwork';
import paperworkMeta, { PaperworkMetaState } from '../ducks/paperworkMeta';
import invoices from './invoices';
import charges from './charge';
import groupLocations, { GroupLocationsState } from './groupLocations';
import popularCitiesNearby from './popularCitiesNearby';
import paymentCustomer, { PaymentCustomerState } from '../ducks/payments/customers';
import userProfilePhoto from '../ducks/profile/photo';
import addInsurance, { AddInsuranceState } from './addInsurance';
import insuranceDetails from '../ducks/insurance/details';
import clinicReviewCalculator from './clinicReviewCalculator';
import nearbyLocations from '../ducks/nearbyClinics';
import bookingNotes from '../ducks/booking/notes';
import bookingImages from '../ducks/booking/images';
import careers from '../ducks/careers';
import locations, { LocationsState } from '../ducks/locations';
import leaveAReview from '../ducks/leaveAReview';
import solvRatings from '../ducks/solvRatings';
import reminders from '../ducks/reminders';
import searchResults, { SearchResultsState } from '../ducks/searchResults';
import providerBooking from '../ducks/bigTexBooking';
import sessionTrackingProperties, { SessionTrackingPropertiesState } from '../ducks/tracking';
import webPushSubscriptions from '../ducks/webPushSubscriptions';
import marketplaceInsurers from '../ducks/marketplaceInsurers';
import onboarding from '../ducks/onboarding';
import specialties from '../ducks/specialties';
import userProfilePhotos from '../ducks/userProfilePhotos';
import universalSearch, { UniversalSearchState } from '../ducks/universalSearch';
import abTests from '../ducks/abTests';
import verifyProfile from '../ducks/verifyProfile';
import videoVisit, { VideoVisitState } from '../ducks/videoVisit';
import mobileCheckIn from '../ducks/mobileCheckIn';
import photoId, { PhotoIdState } from '../ducks/photoId';
import associatedTelemedLocation from '../ducks/associatedTelemedLocation';
import currentlySelectedLocation from '../ducks/currentlySelectedLocation';
import physicalLocations from '../ducks/physicalLocations';
import providerGroups, { ProviderGroupsState } from '../ducks/providerGroups';
import labResults, { LabResultState } from '../ducks/labResults';
import { ORIGIN_BOOKING_WIDGET } from '../constants';
import insuranceCarrierPlansReducer, {
  InsuranceCarrierPlansState,
} from '~/ducks/insuranceCarrierPlans';
import { LeaveAReviewState } from '~/ducks/leaveAReview';
import { EN } from '~/core/util/localizedStr';
import bookingWidgetFamilyBooking, {
  BookingWidgetFamilyBookingState,
} from '~/ducks/bookingWidgetFamilyBooking';
import theme, { ThemeState } from './theme';
import bookingFlow, { BookingFlowState } from './bookingFlow';

/**
 * This function handles the logic around rehydrating the newBooking slice from persisted storage.
 *
 * @param inboundState the state being rehydrated from storage
 * @param originalState the state before the REHYDRATE action
 * @param reducedState the store state after the REHYDRATE action but before the reconcilliation into final "rehydrated" state.
 *
 * @returns reconciledState the new reconciled state
 *
 */
const deepMergeReconcileNewBooking = (inboundState: any, originalState: any, reducedState: any) => {
  let reconciledState: NewBookingState = mergeWith(
    inboundState,
    reducedState,
    (inboundStateValue: any, reducedStateValue: any, key: any) =>
      // @ts-ignore
      isEqual(reducedStateValue, newBookingInitialState[key])
        ? inboundStateValue
        : reducedStateValue
  );

  /**
   * locale is a special case. Because only the booking widget ui is localized, we only want to persist
   * the locale if the booking origin is "booking_widget"
   */
  if (reconciledState?.booking?.origin !== ORIGIN_BOOKING_WIDGET) {
    reconciledState = {
      ...reconciledState,
      locale: EN,
    };
  }

  return reconciledState;
};

const newBookingPersistConfig = {
  key: 'newBooking',
  storage: storageSession,
  stateReconciler: deepMergeReconcileNewBooking,
};

const searchPreferencePersistConfig = {
  key: 'searchPreferences',
  storage: storageSession,
  stateReconciler: deepMergeReconcileNewBooking,
};

const familyBookingspersistConfig = {
  key: 'bookingWidgetFamilyBooking',
  storage: storageSession,
};

const photoIdPersistConfig = {
  key: 'photoId',
  storage: storageSession,
};

/**
 * The great solv redux state!
 *
 * A reference to the order of redux state!
 * Please continue to add to this once you have done some work
 * in typing out a reducer. Getting this completed will go a
 * long ways in inspiring confidence in using redux, as well
 * as cut down on duplication of data in redux state.
 */
export interface SolvReduxState {
  waitList: any;
  account: AccountState;
  groupLocations: GroupLocationsState;
  pastBookings: PastBookingsState;
  insuranceCarrierPlans: InsuranceCarrierPlansState;
  insurers: InsurersState;
  labResults: LabResultState;
  location: Partial<LocationInterface>;
  locations: LocationsState;
  login: LoginState;
  newBooking: NewBookingState;
  paperwork: PaperworkState;
  paperworkMeta: PaperworkMetaState;
  position: PositionState;
  providerGroups: ProviderGroupsState;
  runtime: RuntimeState;
  searchPreferences: SearchPreferencesState;
  searchResults: SearchResultsState;
  sessionTrackingProperties: SessionTrackingPropertiesState;
  universalSearch: UniversalSearchState;
  leaveAReview: LeaveAReviewState;
  photoId: PhotoIdState;
  videoVisit: VideoVisitState;
  paymentCustomer: PaymentCustomerState;
  bookingWidgetFamilyBooking: BookingWidgetFamilyBookingState;
  imageData: ImageDataState;
  addInsurance: AddInsuranceState;
  theme: ThemeState;
  bookingFlow: BookingFlowState;
}

export const useSolvSelector: TypedUseSelectorHook<SolvReduxState> = useSelector;

export default combineReducers<SolvReduxState>({
  abTests,
  account,
  addInsurance,
  addReview,
  associatedTelemedLocation,
  autocomplete,
  bookingImages,
  bookingNotes,
  careers,
  charges,
  clinicLead,
  clinicReviewCalculator,
  content,
  currentlySelectedLocation,
  distanceMatrix,
  eligibilityCheck,
  favorite,
  groupLocations,
  imageData,
  insuranceCarrierPlans: insuranceCarrierPlansReducer,
  insuranceDetails,
  insuranceProfile,
  insurers,
  invoice: invoices,
  labResults,
  leaveAReview,
  location,
  locations,
  login,
  marketplaceInsurers,
  mobileCheckIn,
  nearbyLocations,
  newBooking: persistReducer(newBookingPersistConfig, newBooking) as typeof newBooking,
  npi,
  onboarding,
  outsideServiceArea,
  paperwork,
  paperworkMeta,
  pastBookings,
  payment,
  paymentCustomer,
  photoId: persistReducer(photoIdPersistConfig, photoId) as typeof photoId,
  physicalLocations,
  popularCitiesNearby,
  position,
  providerBooking,
  providerGroups,
  reminders,
  requestSolvEmail,
  reviews,
  runtime,
  searchPreferences: persistReducer(
    searchPreferencePersistConfig,
    searchPreferences
  ) as typeof searchPreferences,
  searchResults,
  sessionTrackingProperties,
  solvRatings,
  specialties,
  theme,
  toolTip,
  tracking,
  universalSearch,
  userProfilePhoto,
  userProfilePhotos,
  verifyProfile,
  videoVisit,
  waitList,
  waitTime,
  webPushSubscriptions,
  bookingWidgetFamilyBooking: persistReducer(
    familyBookingspersistConfig,
    bookingWidgetFamilyBooking
  ) as typeof bookingWidgetFamilyBooking,
  bookingFlow,
});

// if (module.hot) {
//   module.hot.accept();
// }
