export const DEFAULT_JPEG_QUALITY = 0.92;

export default class ExifImageLoader {
  canvas: any;

  canvasCtx: any;

  imageArrayBufferPromise: any;

  imagePromise: any;

  constructor(file: Blob) {
    this.canvas = document.createElement('canvas');
    this.canvasCtx = this.canvas.getContext('2d');

    this.imageArrayBufferPromise = new Promise((resolve) => {
      const reader = new FileReader();
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      reader.onload = (file) => resolve(file.target.result);
      reader.readAsArrayBuffer(file);
    });

    this.imagePromise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (file) => {
        const image = new Image();
        image.onload = () => resolve(image);
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
        image.src = file.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  getImageArrayBufferPromise() {
    return this.imageArrayBufferPromise;
  }

  getImagePromise() {
    return this.imagePromise;
  }

  getDataURLPromise(quality = DEFAULT_JPEG_QUALITY) {
    return new Promise((resolve) => {
      resolve(this.canvas.toDataURL('image/jpeg', quality));
    });
  }

  getOrientationPromise() {
    return this.getImageArrayBufferPromise().then((imageArrayBuffer: any) => {
      const imageDataView = new DataView(imageArrayBuffer);

      if (imageDataView.getUint16(0, false) !== 0xffd8) {
        return -2;
      }

      const length = imageDataView.byteLength;
      let offset = 2;
      while (offset < length) {
        if (imageDataView.getUint16(offset + 2, false) <= 8) {
          return -1;
        }

        const marker = imageDataView.getUint16(offset, false);

        offset += 2;
        if (marker === 0xffe1) {
          offset += 2;
          if (imageDataView.getUint32(offset, false) !== 0x45786966) {
            return -1;
          }

          const little = imageDataView.getUint16((offset += 6), false) === 0x4949;

          offset += imageDataView.getUint32(offset + 4, little);
          const tags = imageDataView.getUint16(offset, little);

          offset += 2;

          for (let i = 0; i < tags; i += 1) {
            if (imageDataView.getUint16(offset + i * 12, little) === 0x0112) {
              return imageDataView.getUint16(offset + i * 12 + 8, little);
            }
          }
          // eslint-disable-next-line no-bitwise
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += imageDataView.getUint16(offset, false);
        }
      }

      return -1;
    });
  }

  drawImage(image: any, width: any, height: any) {
    this.canvasCtx.drawImage(image, 0, 0, width, height);
  }

  orientCanvas(orientation: any, width: any, height: any) {
    if (orientation > 4) {
      this.canvas.width = height;
      this.canvas.height = width;
    } else {
      this.canvas.width = width;
      this.canvas.height = height;
    }

    switch (orientation) {
      case 2:
        // horizontal flip
        this.canvasCtx.translate(width, 0);
        this.canvasCtx.scale(-1, 1);
        break;
      case 3:
        // 180° rotate left
        this.canvasCtx.translate(width, height);
        this.canvasCtx.rotate(Math.PI);
        break;
      case 4:
        // vertical flip
        this.canvasCtx.translate(0, height);
        this.canvasCtx.scale(1, -1);
        break;
      case 5:
        // vertical flip + 90 rotate right
        this.canvasCtx.rotate(0.5 * Math.PI);
        this.canvasCtx.scale(1, -1);
        break;
      case 6:
        // 90° rotate right
        this.canvasCtx.rotate(0.5 * Math.PI);
        this.canvasCtx.translate(0, -height);
        break;
      case 7:
        // horizontal flip + 90 rotate right
        this.canvasCtx.rotate(0.5 * Math.PI);
        this.canvasCtx.translate(width, -height);
        this.canvasCtx.scale(-1, 1);
        break;
      case 8:
        // 90° rotate left
        this.canvasCtx.rotate(-0.5 * Math.PI);
        this.canvasCtx.translate(-width, 0);
        break;
      default:
        break;
    }
  }

  async getDataURL(reorient = true, quality = DEFAULT_JPEG_QUALITY) {
    const image = await this.getImagePromise();
    const { width, height } = image;

    this.canvas.width = width;
    this.canvas.height = height;

    if (reorient) {
      const orientation = await this.getOrientationPromise();
      this.orientCanvas(orientation, width, height);
    }

    this.drawImage(image, width, height);

    return this.getDataURLPromise(quality);
  }
}
