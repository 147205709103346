/**
 * An (incomplete) collection of service IDs as needed elsewhere.
 */

//  ##### This service is deprecated #####
export const COVID_TEST_SERVICE_ID = 'p3MME9';
// ###########################################

export const ANTIBODY_COVID_TEST_SERVICE_ID = 'pPooAg';
export const RAPID_COVID_19_PCR_TEST_SERVICE_ID = 'Olq1m9';
export const COVID_19_PCR_TEST_SERVICE_ID = '9nvZo9';
export const RAPID_COVID_19_ANTIGEN_TEST_SERVICE_ID = 'Ord20g';
export const COVID_19_ANTIGEN_TEST_SERVICE_ID = 'pvN7yp';

export const COVID_SERVICE_IDS = [
  ANTIBODY_COVID_TEST_SERVICE_ID,
  COVID_TEST_SERVICE_ID,
  RAPID_COVID_19_PCR_TEST_SERVICE_ID,
  COVID_19_PCR_TEST_SERVICE_ID,
  RAPID_COVID_19_ANTIGEN_TEST_SERVICE_ID,
  COVID_19_ANTIGEN_TEST_SERVICE_ID,
];

export const RAPID_COVID_SERVICE_IDS = [
  RAPID_COVID_19_PCR_TEST_SERVICE_ID,
  RAPID_COVID_19_ANTIGEN_TEST_SERVICE_ID,
];

export const STD_TEST_SERVICE_ID = '4pzBpq';
export const TB_TEST_SERVICE_ID = '09nR9x';
export const ALLERGY_TEST_SERVICE_ID = 'MgYZdp';
export const PREGNANCY_TEST_SERVICE_ID = 'RpRnpV';
export const COMPREHENSIVE_METABOLIC_PANEL_TEST_SERVICE_ID = 'OQo7eg';
export const HIV_TEST_SERVICE_ID = 'pzo7AO';
export const A1C_TEST_SERVICE_ID = 'g7zxY9';
export const THYROID_TEST_SERVICE_ID = 'Ojx2ng';

export const FLU_SHOT_SERVICE_ID = 'n9wG9K';
export const FLU_TEST_SERVICE_ID = 'XO6RO1';

export const FLU_SERVICE_IDS = [FLU_SHOT_SERVICE_ID, FLU_TEST_SERVICE_ID];
