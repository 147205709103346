import React, { PropsWithChildren } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import solvTheme from '../themes/defaultTheme';
import { usePalette } from '~/hooks/usePalette';

// Creating this HOC to wrap Solv Design System (SDS) components.
// Ideally this would be at the root <App /> component so this wouldn't be necessary, but we only want this
// theming to be used for new components so that we don't mess up styles for existing material ui components being used elsewhere.
export default function withThemeProvider<Props extends {}>(
  Component: React.ComponentType<Props>,
  theme: any = solvTheme
) {
  return (props: PropsWithChildren<Props>) => {
    const { palette, paletteName } = usePalette();

    let useTheme = theme;
    if (paletteName !== 'crunchberry') {
      useTheme = {
        ...useTheme,
        palette: {
          ...useTheme.palette,
          primary: {
            main: palette.base,
            light: palette['100'],
            dark: palette['500'],
          },
        },
      };
    }
    return (
      <MuiThemeProvider theme={useTheme}>
        <Component {...props} />
      </MuiThemeProvider>
    );
  };
}
