// jscs: disable maximumLineLength
import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { canAskToEnablePush } from './util';
import { emptyFunction } from '../../core/util/function';
import OptIn from './OptIn';
import Prompt from './Prompt';

type OwnProps = {
  open: boolean;
  handleDenyCustomBehavior?: (...args: any[]) => any;
  handleAcceptCustomBehavior?: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  webPushOptInOrigin?: string;
  accountId: string;
  callbackUponSubscribe?: (...args: any[]) => any;
  optInCopy?: any;
  promptCopy?: any;
};

type Props = OwnProps & typeof OptInDialog.defaultProps;

export default class OptInDialog extends PureComponent<Props> {
  static defaultProps = {
    handleAcceptCustomBehavior: emptyFunction,
    handleDenyCustomBehavior: emptyFunction,
  };

  render = () => {
    const { open, onClose } = this.props;
    return (
      <Dialog
        aria-describedby="opt in to notifications"
        aria-labelledby="web-push-opt-in"
        onClose={onClose}
        open={open}
      >
        {canAskToEnablePush() ? <OptIn {...this.props} /> : <Prompt {...this.props} />}
      </Dialog>
    );
  };
}
