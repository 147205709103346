import LocationInterface from '@solvhealth/types/interfaces/Location';
import { DAPI_HOST, DEFAULT_SEARCH_RADIUS, METERS_IN_A_MILE } from '../../config/index';
import { apiGetJson, apiGetJsonBlocking, getDapiHost } from './index';
import {
  ORIGIN_REACT_MOBILE_APP,
  PATIENT_TYPE_ADULTS,
  PATIENT_TYPE_KIDS,
} from '../../constants/index';
import {
  formatLocationCategory,
  getAssociatedTelemedLocationId,
  isSolvPartner,
} from '../util/location';
import { isEmptyArray } from '../util/array';
import { isEmptyString, roundLatOrLng } from '../util/string';
import { convertSnakeToCamelCase, safeGet } from '../util/object';
import { PEDIATRIC_URGENT_CARE } from '../../constants/category';
import { getRandomInt } from '../util/numeric';
import { setNearbyTelemed } from '../../ducks/nearbyClinics';
import { isEmpty } from '../util/empty';
import { setAssociatedTelemedLocationWithoutSlots } from '../../ducks/associatedTelemedLocation';
import ProxyTypes from '../proxy/ProxyTypes';
import { locationResponseFormatter } from '~/reducers/formatters/location';

const getLocationUrl = (
  locationId: any,
  {
    origin = ORIGIN_REACT_MOBILE_APP,
    version = 'v2',
    fields = [] as string[],
    bookingCode = '',
  } = {}
) => {
  let url = `${DAPI_HOST}/${version}/locations/${locationId}?origin=${origin}`;
  if (!isEmptyArray(fields)) url += `&fields=${fields.join(',')}`;
  if (!isEmptyString(bookingCode)) url += `&booking_code=${bookingCode}`;

  return url;
};

export const LOCATION_FIELDS = [
  'image_links',
  'appointment_dates',
  'recent_bookings',
  'scores',
  'hours',
  'adjectives',
  'attributes',
  'services',
  'services_obj',
  'timezone',
  'groups',
  'special_operating_hours',
  'hours_default',
  'payment_account_id',
  'accepted_insurance_types',
  'accreditations',
  'fax',
  'display_name_primary',
  'display_name_secondary',
  'display_name_alternate',
  'accepted_insurances',
  'accepted_payment_methods',
  'time_zone',
  'beyond_next_day_limit',
  'category',
  'custom_booking_message',
  'custom_photo_id_upload_copy',
  'id',
  'name',
  'brand_name',
  'brand_url',
  'address',
  'subpremise',
  'city',
  'state',
  'zip_code',
  'phone',
  'lat_long',
  'source',
  'is_bookable',
  'is_viewable',
  'is_solv_partner',
  'is_clear_price_partner',
  'is_in_marketplace',
  'appointment_slots',
  'appointment_interval',
  'booking_url',
  'distance_from_current_location',
  'active',
  'npi',
  'geo',
  'email',
  'google_place_id',
  'status',
  'website',
  'last_walkin_appointment_date',
  'disable_reservations_until',
  'appointment_interval_reservations',
  'tracking_properties',
  'rating_solv',
  'rating_solv_count',
  'rating_non_solv',
  'rating_non_solv_count',
  'is_consent_enabled',
  'is_test_location',
  'is_paperwork_enabled',
  'is_paperwork_required',
  'is_payments_enabled',
  'is_payment_during_booking_enabled',
  'is_payment_during_booking_required',
  'is_payments_required',
  'is_photo_id_upload_enabled',
  'is_photo_id_front_required',
  'is_photo_id_back_required',
  'is_next_day_appointments_disabled',
  'is_beyond_next_day_appointments_enabled',
  'is_telemed',
  'is_provider',
  'is_standalone_telemed',
  'telemed_sla',
  'google_review_source',
  'has_next_in_line',
  'has_provider_schedule',
  'is_mobile_check_in_enabled',
  'is_uber_enabled',
  'review_flow_type',
  'live_date',
  'offboarded_date',
  'contract_signed_date',
  'google_feature_id',
  'ucl_id',
  'description_variation_test',
  'last_verified_on',
  'inside_of',
  'service_prices',
  'display_address',
  'rating_wait_time',
  'rating_bedside_manner',
  'rating_staff_friendliness',
  'rating_facility_cleanliness',
  'rating_quality_of_care',
  'rating_wait_time_count',
  'rating_bedside_manner_count',
  'rating_staff_friendliness_count',
  'rating_facility_cleanliness_count',
  'rating_quality_of_care_count',
  'platform_type',
  'custom_description',
  'year_opened',
  'neighborhoods',
  'cross_streets',
  'parking_details',
  'ownership',
  'hospital_affiliations',
  'provider_names',
  'accepted_insurers',
  'specialties',
  'market',
  'brands',
  'is_birth_sex_required',
  'associated_telemed_location_id',
  'is_asap_telemed_enabled',
  'photo_id_upload_entrypoints',
  'is_covid_testing_only',
  'is_insurance_photo_upload_and_ocr_disabled',
  'is_by_appointment_only',
  'is_default_description_hidden',
  'is_insurance_required_for_booking',
  'are_booking_prescreen_questions_enabled',
  'booking_prescreen_questions_text',
  'is_dosage_dropdown_enabled',
  'is_booking_codes_enabled',
  'is_legal_name_required',
  'is_vaccination_only',
  'is_reason_for_visit_dropdown_enabled',
  'reason_for_visit_dropdown_options',
  'is_insurance_ocr_in_paperwork_flow_enabled',
  'has_boost',
  'is_performance_pricing_enabled',
  'is_insurance_plan_name_required',
  'is_email_required_for_booking_widget',
  'is_address_hidden',
  'is_insurance_disabled_from_booking',
  'package_id',
  'package',
  'is_waitlist_sms_disabled',
  'is_in_service_searches_only',
  'is_family_bookings_enabled',
  'is_cdp_srp_address_required',
  'is_external_telemed',
  'url_slug',
  'hide_insurance_flow_solv_attributed_booking',
  'has_longer_wait_times',
  'external_booking_url',
  'is_external_telemed_modified_sms',
  'is_external_telemed_modified_email',
  'external_telemed_custom_next_steps',
  'external_telemed_custom_tips',
  'is_telemed_connect',
];

export const LOCATION_FIELDS_NO_SLOTS = LOCATION_FIELDS.filter(
  (field) => field !== 'appointment_slots'
);

export const LOCATION_FIELDS_NO_APPOINTMENT_DATES = LOCATION_FIELDS.filter(
  (field) => field !== 'appointment_dates'
);

export const MINIMUM_LOCATION_FIELDS_REQUIRED_FOR_SLOTS = [
  'slots',
  'time_zone',
  'appointment_dates',
  'hours',
];

export const BOOKING_WIDGET_LOCATION_FIELDS_WITHOUT_SLOTS = [
  'booking_widget_languages',
  'display_name_tertiary',
  'is_booking_widget_address_required',
  'is_payment_toggle_hidden',
  'is_widget_telemed_enabled',
  ...LOCATION_FIELDS,
].filter((f) => !['recent_bookings', 'appointment_dates'].includes(f));

// fetch all fields so other parts of the app don't break when
// using this location data
export const ASSOCIATED_TELEMED_LOCATION_FIELDS = LOCATION_FIELDS;

// providers on location is buggy and not useful (providers with multiple specialties will be duplicated).
// Better to get providers by locationId if needed, not sure practice is needed either
const PROVIDER_GROUP_LOCATION_FIELDS = [...LOCATION_FIELDS, /*'providers',*/ 'practice'];

const getSolvAndUclMergedLocationUrl = (
  locationId: any,
  shouldUseCache = true,
  fields = LOCATION_FIELDS
) =>
  `${getDapiHost(shouldUseCache)}/v4/locations/${locationId}?origin=${ORIGIN_REACT_MOBILE_APP}` +
  `&fields=${fields.join(',')}`;

const getLocationSlotsUrl = (locationId: string, origin: string = ORIGIN_REACT_MOBILE_APP) =>
  `${DAPI_HOST}/v4/locations/${locationId}/slots?origin=${origin}`;

const getLocationExternalSlotsUrl = (locationId: string, startDate: string, endDate: string) => {
  const baseUrl = `${DAPI_HOST}/v4/locations/${locationId}/slots?origin=${ORIGIN_REACT_MOBILE_APP}`;
  let queryString = '';
  if (startDate) queryString += `&external_slot_start_date=${startDate}`;
  if (endDate) queryString += `&external_slot_end_date=${endDate}`;
  return baseUrl + queryString;
};

const getNearbyLocationsUrl = ({
  locationId,
  lat,
  lng,
  isSolvPartner,
  filterByBrand,
  filterByGroup,
  groupIds,
  shouldUseCache = true,
  limit,
}: any = {}) => {
  const baseUrl = `${getDapiHost(shouldUseCache)}/v4/locations/nearby`;
  let queryString = '?';
  if (lat && lng) {
    queryString += `lat=${roundLatOrLng(lat)}&lng=${roundLatOrLng(lng)}`;
  } else if (locationId) {
    queryString += `current_location_id=${locationId}`;
  }

  if (isSolvPartner) queryString += `&is_solv_partner=${isSolvPartner}`;
  if (filterByBrand) queryString += `&filter_by_brand=${filterByBrand}`;
  else if (filterByGroup) {
    queryString += `&filter_by_group=${filterByGroup}&group_ids=${groupIds.join(',')}`;
  }

  if (limit && typeof limit === 'number') queryString += `&limit=${limit}`;
  return baseUrl + queryString;
};

const compareSolvPartnerForSort = (locationA: any, locationB: any) => {
  if (isSolvPartner(locationA) && !isSolvPartner(locationB)) {
    return -1;
  }

  if (isSolvPartner(locationB) && !isSolvPartner(locationA)) {
    return 1;
  }

  return locationA.distanceFromCurrentLocation - locationB.distanceFromCurrentLocation;
};

const getGroupLocationsUrl = (
  groupId: string,
  { version = 'v2', fields = [] as string[] } = {}
): string => {
  let url =
    `${DAPI_HOST}/${version}/locations?group_ids=${groupId}&origin=${ORIGIN_REACT_MOBILE_APP}&` +
    `filters=location_features.is_viewable_on_group_multi_location_widget:true`;

  if (!isEmpty(fields)) url += `&fields=${fields.join(',')}`;

  return url;
};

const getLocationsCityUrl = (stateCode: any, cityName: any) =>
  `${DAPI_HOST}/v2/locations?filters=locations.state:${stateCode};locations.city:${cityName}`;

const getGroupTelemedLocations = (groupIds: any) =>
  `${DAPI_HOST}/v3/locations?group_ids=${groupIds}&` +
  'filters=locations.is_telemed:true;' +
  'locations.is_bookable:true;locations.is_solv_partner:true';

const getTraditionalLocations = (latLong: any) =>
  `${DAPI_HOST}/v3/locations?` +
  'filters=locations.is_solv_partner:true;locations.is_test_location:false;' +
  'locations.is_bookable:true;locations.platform_type:traditional;locations.is_provider:false' +
  `&lat=${roundLatOrLng(latLong.latitude)}&long=${roundLatOrLng(latLong.longitude)}&radius=15`;

const getTelemedLocationUrl = ({ state, groupIds, patientType }: any) => {
  const BASE_URL = '/v4/locations';
  const params = [`fields=${LOCATION_FIELDS.join(',')}`];
  const filters = ['locations.is_telemed:true', 'locations.is_viewable:true'];

  if (groupIds) params.push(`group_ids=${groupIds.join(',')}`);
  if (state) filters.push(`locations.state:${state}`);
  if (patientType) {
    filters.push(
      `location_attributes_flat.${
        patientType === PATIENT_TYPE_KIDS
          ? 'is_pediatric_patients_accepted'
          : 'is_adult_patients_accepted'
      }:true`
    );
  }

  params.push(`filters=${filters.join(';')}`);

  return `${DAPI_HOST}${BASE_URL}?${params.join('&')}`;
};

export const fetchNearbyTelemedLocation = async (location: any, dispatch: any) => {
  const currentLocationState = safeGet(location)('state');

  const patientType =
    (location.is_pediatrics_only && PATIENT_TYPE_KIDS) ||
    (formatLocationCategory(location) === PEDIATRIC_URGENT_CARE
      ? PATIENT_TYPE_KIDS
      : PATIENT_TYPE_ADULTS);

  const nonPartnerTelemedUrl = getTelemedLocationUrl({
    state: currentLocationState,
    patientType,
  });

  const telemedPromise = nonPartnerTelemedUrl && apiGetJsonBlocking(nonPartnerTelemedUrl);

  if (telemedPromise) {
    const telemedLocation = await telemedPromise;
    if (telemedLocation.data && telemedLocation.data.results.length >= 1) {
      const randomIndex = getRandomInt(telemedLocation.data.results.length);
      dispatch(setNearbyTelemed(telemedLocation.data.results[randomIndex]));
    } else {
      // If no nearby telemed can be calculated, make sure to dispatch no nearby telemed
      // to override pre-existing nearby telemed in state. See SOLV-7832
      dispatch(setNearbyTelemed({}));
    }
  } else {
    // If no nearby telemed can be calculated, make sure to dispatch no nearby telemed
    // to override pre-existing nearby telemed in state. See SOLV-7832
    dispatch(setNearbyTelemed({}));
  }
};

export const fetchAssociatedTelemedLocation = async (
  physicalLocation: LocationInterface,
  dispatch: any
) => {
  const associatedTelemedLocationUrl = getLocationUrl(
    getAssociatedTelemedLocationId(physicalLocation),
    { version: 'v4', fields: ASSOCIATED_TELEMED_LOCATION_FIELDS as unknown as string[] }
  );
  const telemedLocationPromise = apiGetJsonBlocking(associatedTelemedLocationUrl);

  if (telemedLocationPromise) {
    const associatedTelemedLocation = await telemedLocationPromise;
    if (associatedTelemedLocation.data) {
      dispatch(setAssociatedTelemedLocationWithoutSlots(associatedTelemedLocation.data));
    }
  } else {
    dispatch(setAssociatedTelemedLocationWithoutSlots({}));
  }
};

const getLocationsListUrl = (locationIds: any) =>
  `${DAPI_HOST}/v2/locations?origin=${ORIGIN_REACT_MOBILE_APP}&filters=` +
  'locations.is_bookable:true;locations.is_solv_partner:true;' +
  `locations.id_hash:${locationIds.join(',')}`;

const getAccountLocationsListUrl = (locationIds: string[]) =>
  `${DAPI_HOST}/v2/locations?limit=100&origin=${ORIGIN_REACT_MOBILE_APP}&filters=` +
  `locations.id_hash:${locationIds.join(',')}`;

const getFavoriteLocationsUrl = (accountId: any) =>
  `${DAPI_HOST}/v1/locations/favorite-locations?account_id=${accountId}&fields=${LOCATION_FIELDS.join(
    ','
  )}`;

const getSolvRatingsByLocationsUrl = (
  locationId: string,
  include_booking_details?: boolean | undefined,
  limit?: number,
  page?: number
) => {
  let url = `${DAPI_HOST}/v1/locations/${locationId}/solv-ratings?hide-empty-reviews=true`;
  if (include_booking_details) url += '&include_booking_details=true';
  if (limit) url += `&limit=${limit}`;
  if (page) url += `&page=${page}`;
  url += `&order_by_rating=true`;
  return url;
};

/**
 * Get the url of the next available locations near you
 *
 * @param {Number || string} latitude
 * @param {Number || string} longitude
 * @param {string[]} providerTypes
 * @returns {string}
 */
const getNextAvailableLocationsUrl = ({ latitude, longitude }: any, providerTypes: any) =>
  `${DAPI_HOST}/v1/locations/next-available?lat=${roundLatOrLng(latitude)}&long=${roundLatOrLng(
    longitude
  )}&provider_types=${providerTypes.join(',')}`;

const getTelemedStatesAvailableUrl = () => `${DAPI_HOST}/v1/locations/telemed-states`;

const fetchSlotsForDate = async (location: any, selectedDate: any) => {
  const data = await apiGetJson<any>(
    `${DAPI_HOST}/v1/locations/${location.id}/slots?on_date=${selectedDate.format(
      'YYYY-MM-DD'
    )}&origin=${ORIGIN_REACT_MOBILE_APP}`
  );
  return data.data.map(convertSnakeToCamelCase);
};

const getPhysicalLocationsInGroup = (groupId: any) => {
  return `${DAPI_HOST}/v4/locations?group_ids=${groupId}&filters=locations.is_telemed:false&limit=1&fields=id`;
};

/**
 * Get the URL to return a nearby location that is in the indicated group and has one or more of the serviceIds indicated.
 * Note, it may return a url to a location that is not a bookable CDP (has_boost, is_bookable, is_viewable could be false).
 *
 * @param {string} groupId
 * @param {number} latitude
 * @param {number} longitude
 * @param {number} radius
 * @param {string[]} serviceIds
 * @returns {string}
 */
export const getClosestPhysicalLocationsInGroupWithServiceIdsUrl = (
  groupId: string,
  latitude: number | string,
  longitude: number | string,
  radius = DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE, // unit in meters
  serviceIds: string[] = [] // filter out results that do not have same service ids
) => {
  const serviceIdsFilter = serviceIds.length ? `;services.id:${serviceIds.toString()}` : '';
  return `${DAPI_HOST}/v4/locations?group_ids=${groupId}&filters=locations.is_telemed:false${serviceIdsFilter}&lat=${roundLatOrLng(
    latitude
  )}&long=${roundLatOrLng(longitude)}&radius=${radius}&limit=1`;
};

const getClosestPhysicalLocationsInGroup = (
  groupId: any,
  latitude: any,
  longitude: any,
  radius = DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE, // unit in meters
  limit = 1
) => {
  return `${DAPI_HOST}/v4/locations?group_ids=${groupId}&filters=locations.is_telemed:false&lat=${roundLatOrLng(
    latitude
  )}&long=${roundLatOrLng(longitude)}&radius=${radius}&limit=${limit}`;
};

const getNextAvailableSlotUrl = (locationId: any, origin: any) => {
  return `${DAPI_HOST}/v1/locations/${locationId}/next-available?origin=${origin}`;
};

const getTranslateLocationIdNumericToIdHashUrl = (locationIdNumeric: any) =>
  `/proxy/${ProxyTypes.LocationIdTranslator}?locationIdNumeric=${locationIdNumeric}`;

const fetchTranslateLocationIdNumericToIdHash = (locationIdNumeric: any) =>
  apiGetJson(getTranslateLocationIdNumericToIdHashUrl(locationIdNumeric));

export const getInsurancePlansByLocationUrl = (locationId: string, options: {} = {}) =>
  `${DAPI_HOST}/v1/locations/${locationId}/insurance-plans`;

/**
 * Get a location's slots
 *
 * @returns A list of locations with slots
 */
async function getLocationSlots(locationId?: string) {
  if (!locationId) return null;
  const result = await apiGetJson<LocationInterface>(getLocationSlotsUrl(locationId));

  if (result?.errors?.length && result?.errors?.length > 0) {
    throw new Error(`Failed to load ${result.errors?.toString()}`);
  }

  const formattedResult: LocationInterface = locationResponseFormatter(result.data);

  return { ...result, data: formattedResult };
}

export {
  compareSolvPartnerForSort,
  getGroupLocationsUrl,
  getTelemedLocationUrl,
  getGroupTelemedLocations,
  getFavoriteLocationsUrl,
  getNextAvailableLocationsUrl,
  getLocationSlotsUrl,
  getLocationExternalSlotsUrl,
  getLocationUrl,
  getLocationsCityUrl,
  getLocationsListUrl,
  getPhysicalLocationsInGroup,
  getClosestPhysicalLocationsInGroup,
  getNearbyLocationsUrl,
  getTraditionalLocations,
  getTelemedStatesAvailableUrl,
  getSolvAndUclMergedLocationUrl,
  getSolvRatingsByLocationsUrl,
  fetchSlotsForDate,
  getAccountLocationsListUrl,
  getNextAvailableSlotUrl,
  getTranslateLocationIdNumericToIdHashUrl,
  fetchTranslateLocationIdNumericToIdHash,
  getLocationSlots,
  PROVIDER_GROUP_LOCATION_FIELDS,
};
