import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { BaseClickable, Box, NavBar, Stack } from '@solvhealth/jigsaw';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { resetLoginData } from '~/actions/login';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { colors } from '../../constants/colors';
import { LOGIN_ACTION_OTP_FORM } from '../../constants/index';
import { LoginController } from '../LoginController';
import SolvPlusLogo from '../Membership/SolvPlusLogo';
import { willRedirectToSolvPlusFlowsAfterLogin } from '../Membership/utils';
import Logo from '../SolvPatternLibrary/Logo';
import BorderBoxReset from '../util/BorderBoxReset';

export const LOGIN_SIGN_UP_MODAL = 'login sign up modal';

const mapDispatchToProps = (dispatch: any) => ({
  resetLoginData: () => dispatch(resetLoginData()),
});

type LoginSignUpModalProps = {
  fullScreen?: boolean;
  open?: boolean;
  onClose: (...args: any[]) => any;
  runtime?: any;
  login?: any;
  resetLoginData: (...args: any[]) => any;
};

function LoginSignUpModal(props: LoginSignUpModalProps) {
  const { fullScreen, resetLoginData, open, onClose, runtime, login, ...otherProps } = props;

  const backArrowHandler = useCallback(
    () => (login.action === LOGIN_ACTION_OTP_FORM ? resetLoginData : onClose)(),
    [login.action, onClose, resetLoginData]
  );

  const isInSolvPlusContext = willRedirectToSolvPlusFlowsAfterLogin();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      onEnter={resetLoginData}
      open={!!open}
    >
      <BorderBoxReset>
        {fullScreen ? (
          <NavBar onGoBack={backArrowHandler}>
            {isInSolvPlusContext ? <SolvPlusLogo height={24} /> : <Logo height={24} />}
          </NavBar>
        ) : (
          <Box position="absolute" right={20} top={20} zIndex={20}>
            <BaseClickable onClick={onClose}>
              <img alt="close" src="/images/design_system/icons/close_x.svg" />
            </BaseClickable>
          </Box>
        )}
        <Stack pb={4} position="relative" space={4}>
          <LoginController isModal preventRedirectAfterLogin {...otherProps} />
        </Stack>
      </BorderBoxReset>
    </Dialog>
  );
}

export default withMobileDialog({ breakpoint: 'sm' })(
  connect(null, mapDispatchToProps)(LoginSignUpModal)
);
