import { stripNonNumeric } from './string';
import { TEST_PHONE_NUMBER } from '../../constants/phoneLoginForm';

const dataBasePhoneValueToFormattedDisplay = (phone: any) => {
  if (phone.length !== 12) {
    return phone;
  }

  return `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8)}`;
};

const tenDigitPhoneValueToFormattedDisplay = (input: any) => {
  const phone = `+1${input}`;
  return `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8)}`;
};

const tenDigitDisplayPhoneToDataBasePhoneValue = (phone: any) => `+1${phone}`;

const dataBasePhoneValueToTenDigitDisplayPhone = (phone: any) => phone.substring(2);

const sanitizePhone = (phone: any) => {
  const sanitizedPhone = stripNonNumeric(phone);
  return sanitizedPhone.replace(/^1/, '');
};

const isValidPhone = (phone: any) => {
  const usaPhoneNumberRegex = /^[2-9](\d{9})$/;
  const sanitizedPhone = sanitizePhone(phone);
  return usaPhoneNumberRegex.test(sanitizedPhone);
};

const isTestPhoneNumber = (phone: any) => {
  const sanitizedPhone = sanitizePhone(phone);
  return sanitizedPhone === TEST_PHONE_NUMBER;
};

export {
  dataBasePhoneValueToFormattedDisplay,
  dataBasePhoneValueToTenDigitDisplayPhone,
  tenDigitDisplayPhoneToDataBasePhoneValue,
  isTestPhoneNumber,
  isValidPhone,
  tenDigitPhoneValueToFormattedDisplay,
  sanitizePhone,
};
