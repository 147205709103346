import React, { Fragment, PureComponent } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import moment from 'moment';
import styled from 'styled-components';
import { URGENT_CARE_VALUE } from '../../Home/Tiles/ProviderTypes';
import Input from './Base';
import { BOOKING_REQUESTED_TIME_CHANGED, CONSUMER_APP } from '../../../constants/index';
import { TIME_SELECT_OPTIONS } from './defaults';
import MoreDates from './MoreDates';
import { colors } from '../../../constants/colors';
import { analyticsTrackEvent } from '../../../core/analytics';
import {
  ACTION_BLUR,
  ACTION_CHANGE_VALUE,
  ACTION_CLICK,
  APPOINTMENT_TIME_SET_BY_USER,
  UNIVERSAL_TIME_INPUT,
} from '../../../core/analytics/events';
import { isMobile } from '../../../core/util/device';

const TimeInput = styled(Input)`
  -webkit-appearance: none;
  padding: 1px;
  text-align: left;
`;

const TimeDialog = styled(Dialog)`
  & .dialog-root {
    background-color: ${
      // @ts-expect-error ts-migrate(2551) FIXME: Property 'greyDim' does not exist on type '{ fadeT... Remove this comment to see the full error message
      colors.greyDim
    };
  }
`;

const highlight = (selectedLabel: any, timeLabel: any) =>
  selectedLabel === timeLabel ? `<em>${timeLabel}</em>` : timeLabel;

type TimeProps = {
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  updateSuggestions?: func;
  className?: string;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  setAppointmentTime?: func;
  requestedAppointmentTime?: object;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  clearOnBlur?: bool;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  refSetter?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  onSelect?: func;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  fullScreen?: bool;
  providerType?: string;
};

type TimeState = any;

class Time extends PureComponent<TimeProps, TimeState> {
  state = { moreDateModalOpen: false };

  componentDidMount = () => {
    const { clearOnBlur } = this.props;
    !clearOnBlur && this.handleClick();
  };

  getSuggestions = () => {
    const { providerType, requestedAppointmentTime } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeLabel' does not exist on type 'objec... Remove this comment to see the full error message
    const selectedLabel = requestedAppointmentTime && requestedAppointmentTime.timeLabel;
    const now = moment().startOf('minute');
    const suggestions = TIME_SELECT_OPTIONS.map(({ label, hourOffset, dayOffset, extraLabel }) => {
      const time =
        label === 'ASAP'
          ? now.valueOf()
          : moment().startOf('day').hour(hourOffset).add(dayOffset, 'day').valueOf();

      return {
        primary: `${highlight(selectedLabel, label)}${extraLabel}`,
        key: time.toString(),
        onClick: () => this.updateAndClear(time, label),
      };
      // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'string' ... Remove this comment to see the full error message
    }).filter((d) => d.key >= moment(now).subtract(1, 'hour').valueOf());

    if (providerType && providerType !== URGENT_CARE_VALUE)
      suggestions.push({
        primary: 'More dates',
        key: 'more dates option',
        onClick: () => this.setState({ moreDateModalOpen: true }),
      });

    return suggestions;
  };

  getTimeLabel = () => {
    const { requestedAppointmentTime } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeLabel' does not exist on type 'objec... Remove this comment to see the full error message
    if (requestedAppointmentTime && requestedAppointmentTime.timeLabel)
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeLabel' does not exist on type 'objec... Remove this comment to see the full error message
      return requestedAppointmentTime.timeLabel;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'object'.
    if (requestedAppointmentTime && requestedAppointmentTime.value) {
      const defaultSuggestions = this.getSuggestions();
      for (let i = 0; i < defaultSuggestions.length - 1; ++i) {
        const suggestionTime = defaultSuggestions[i].key;
        // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        if (suggestionTime && Math.abs(suggestionTime - requestedAppointmentTime.value) < 60000)
          return defaultSuggestions[i].primary.replace(/<em>|<\/em>/g, '');
      }

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'object'.
      const time = moment(requestedAppointmentTime.value);
      const selectedHour = time.hour();
      let dayPart = '';

      if (selectedHour >= 18) dayPart = 'Evening';
      else if (selectedHour >= 12) dayPart = 'Afternoon';
      else dayPart = 'Morning';

      return `${time.format('MMM Do')}, ${dayPart}`;
    }

    return 'ASAP';
  };

  updateAndClear = (appointmentTime: any, timeLabel: any) => {
    analyticsTrackEvent(APPOINTMENT_TIME_SET_BY_USER, { sourceOfInput: CONSUMER_APP });
    analyticsTrackEvent(UNIVERSAL_TIME_INPUT, {
      action: ACTION_CHANGE_VALUE,
      value: appointmentTime,
      isMobile: isMobile(),
    });

    const { updateSuggestions, setAppointmentTime, onSelect } = this.props;
    setAppointmentTime({ value: appointmentTime, timeLabel });
    updateSuggestions([]);
    onSelect && onSelect(appointmentTime);
  };

  handleClick = () => {
    analyticsTrackEvent(UNIVERSAL_TIME_INPUT, { action: ACTION_CLICK, isMobile: isMobile() });

    const { updateSuggestions } = this.props;
    updateSuggestions(this.getSuggestions());
  };

  handleBlur = () => {
    analyticsTrackEvent(UNIVERSAL_TIME_INPUT, {
      action: ACTION_BLUR,
      isMobile: isMobile(),
    });
  };

  render() {
    const { className, fullScreen, refSetter } = this.props;
    const { moreDateModalOpen } = this.state;

    return (
      <>
        <TimeInput
          $url="/images/icons/search-icons/Icon-Search-Time.svg"
          className={className}
          onBlur={this.handleBlur}
          onClick={this.handleClick}
          onFocus={this.handleClick}
          ref={refSetter}
          type="button"
          value={this.getTimeLabel()}
        />

        <TimeDialog
          fullScreen={fullScreen}
          maxWidth="sm"
          onClose={() => this.setState({ moreDateModalOpen: false })}
          open={moreDateModalOpen}
          PaperProps={{ classes: { root: 'dialog-root' } }}
        >
          <MoreDates
            onClose={() => this.setState({ moreDateModalOpen: false })}
            setSelectedDate={this.updateAndClear}
          />
        </TimeDialog>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  setAppointmentTime: (value: any) =>
    dispatch({ type: BOOKING_REQUESTED_TIME_CHANGED, payload: { value } }),
});

const mapStateToProps = (state: any) => ({
  requestedAppointmentTime:
    state.newBooking.booking && state.newBooking.booking.requestedAppointmentTime,

  providerType: state.searchPreferences.providerType,
});

export default withMobileDialog({ breakpoint: 'xs' })(
  connect(mapStateToProps, mapDispatchToProps)(Time)
);
