import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { SolvTheme } from '@solvhealth/jigsaw';
import RainMan from '../../components/Animations/RainMan';
import history from '../../core/history';
import { SENTRY_DSN } from '../../config/index';
import { isClientSide } from '../../core/util/system';
import { colors } from '~/constants/colors';
import { fontFamily } from '~/constants/text';
import { biggerThanOrEqualTo } from '~/core/util/styledComponents';
import { analyticsTagHotjarRecording, analyticsTrackEvent } from '~/core/analytics';
import { ERROR_PAGE_RELOAD } from '~/core/analytics/events';
import logger from '../../core/logger/index';
import { HOTJAR_TRACKING_LABEL } from '~/core/tracking/hotjar/constants';
const Sentry = isClientSide() ? require('@sentry/browser') : null;

const Root = styled.div`
  box-sizing: border-box;
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${biggerThanOrEqualTo.tablet`
    padding: 20vh 10vw;
  `} * {
    margin: 0 auto;
  }

  a {
    cursor: pointer;
  }

  pre {
    text-align: left;
    margin-top: 2rem;
  }
`;

const GlobalStyles = createGlobalStyle`
  #app {
    height: 100%;
  }
`;

const Title = styled.div`
  font-family: ${fontFamily.medium};
  font-size: 2em;
  color: ${colors.crunchBerry};
  text-align: center;
`;

const ErrorContent = styled.div`
  font-size: 1em;
  color: ${colors.blueMonday};
  text-align: center;
`;

const reloadPage = () => {
  analyticsTrackEvent(ERROR_PAGE_RELOAD);
  window.location.reload(true);
};

type ErrorPageProps = {
  error?: any;
};

const AnchorButton = styled.a`
  color: ${colors.white};
  background-color: ${colors.crunchBerry};
  padding: 14px 32px;
  border-radius: 8px;
`;

const ErrorPage = ({ error = {} }: ErrorPageProps) => {
  const smallScreen = useMediaQuery(`screen and (max-width: ${SolvTheme.breakpoints.sm})`);

  if ((error?.status ?? error?.code) === 404) {
    history.replace('/404');
    return null;
  }

  if (error && SENTRY_DSN && Sentry) {
    logger.debug(error);
    Sentry.captureException(error);
    analyticsTagHotjarRecording([HOTJAR_TRACKING_LABEL.errorPage]);
  }

  return (
    <Root>
      <GlobalStyles />
      <RainMan />

      <Title>We're a little under the weather.</Title>
      <ErrorContent>
        We're actively working to fix the problem. Please <a onClick={reloadPage}>refresh</a> or try
        back later.
      </ErrorContent>
      <ErrorContent>
        Still not working? {!smallScreen && <a href="mailto:info@solvhealth.com">Contact us.</a>}
      </ErrorContent>
      {smallScreen && <AnchorButton href="mailto:info@solvhealth.com">Contact Us</AnchorButton>}
    </Root>
  );
};

export default ErrorPage;
