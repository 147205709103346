import {
  RECEIVE_INSURANCE_PROFILE,
  INSURANCE_PROFILE_ERROR,
  CLEAR_INSURANCE_PROFILE,
  INSURANCE_UPLOADING,
  SET_INSURANCE_MANUALLY,
  UPDATE_INSURANCE_PROFILE,
} from '../constants/index';

const receiveInsuranceProfile = (value: any) => ({
  type: RECEIVE_INSURANCE_PROFILE,
  payload: { value },
});

const updateInsuranceProfile = (value: any) => ({
  type: UPDATE_INSURANCE_PROFILE,
  payload: { value },
});

const clearInsuranceProfile = (value?: any) => ({
  type: CLEAR_INSURANCE_PROFILE,
  payload: { value },
});

const insuranceProfileError = (value: any) => ({
  type: INSURANCE_PROFILE_ERROR,
  payload: { value },
});

const insuranceFormUploading = (value: any) => ({
  type: INSURANCE_UPLOADING,
  payload: { value },
});

const setEnterInsuranceManually = (value: any) => ({
  type: SET_INSURANCE_MANUALLY,
  payload: { value },
});

export {
  receiveInsuranceProfile,
  insuranceProfileError,
  clearInsuranceProfile,
  insuranceFormUploading,
  setEnterInsuranceManually,
  updateInsuranceProfile,
};
