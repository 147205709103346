import { GenericObj } from '@solvhealth/types/interfaces/generics';
import {
  SCAN_COMPLETED_BACK,
  SCAN_COMPLETED_FRONT,
  SCAN_STARTED_BACK,
  SCAN_STARTED_FRONT,
  OCR_DATA,
  OCR_DATA_BACK,
  SET_CARD_ID_BACK,
  SET_CARD_ID_FRONT,
  CLEAR_CARD_DATA,
  SET_ADD_INSURANCE_FORM_DATA,
  CLEAR_ADD_INSURANCE_FORM_DATA,
} from '../constants/index';

export interface AddInsuranceState {
  front: { scanStarted?: boolean; scanCompleted?: boolean; error?: any };
  back: { scanStarted?: boolean; scanCompleted?: boolean; error?: any };
  insurance: GenericObj;
}

const INITIAL_STATE: AddInsuranceState = { front: {}, back: {}, insurance: {} };

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLEAR_CARD_DATA:
      return {
        ...state,
        front: {},
        back: {},
      };
    case SCAN_STARTED_FRONT:
      return {
        ...state,
        front: {
          ...state.front,
          scanStarted: action.payload.value,
        },
      };
    case SCAN_STARTED_BACK:
      return {
        ...state,
        back: {
          ...state.back,
          scanStarted: action.payload.value,
        },
      };
    case SCAN_COMPLETED_FRONT:
      return {
        ...state,
        front: {
          ...state.front,
          scanCompleted: action.payload.value,
          error: action.payload.error,
        },
      };
    case SCAN_COMPLETED_BACK:
      return {
        ...state,
        back: {
          ...state.back,
          scanCompleted: action.payload.value,
          error: action.payload.error,
        },
      };
    case SET_ADD_INSURANCE_FORM_DATA:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          ...action.payload.value,
        },
      };
    case OCR_DATA: {
      /* Don't overwrite any existing values if the new values are null */
      const newValues = {};
      Object.keys(action.payload.value).forEach((key) => {
        if (action.payload.value[key]) {
          // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
          newValues[key] = action.payload.value[key];
        }
      });
      return {
        ...state,
        insurance: {
          ...state.insurance,
          ...newValues,
        },
      };
    }

    case OCR_DATA_BACK: {
      /* In the event that it's the back of the insurance card
       * being pushed to the state, don't overwrite existing
       * values. This is due to the back of the card often
       * producing poor values that are unreliable. */
      const newValues = {};
      Object.keys(action.payload.value).forEach((key) => {
        if (!state.insurance[key]) {
          // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
          newValues[key] = action.payload.value[key];
        }
      });
      return {
        ...state,
        insurance: {
          ...state.insurance,
          ...newValues,
        },
      };
    }

    case SET_CARD_ID_FRONT:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          cardIds: {
            ...state.insurance.cardIds,
            front: action.payload.id,
          },
        },
      };
    case SET_CARD_ID_BACK:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          cardIds: {
            ...state.insurance.cardIds,
            back: action.payload.id,
          },
        },
      };
    case CLEAR_ADD_INSURANCE_FORM_DATA:
      return {
        ...state,
        insurance: {},
      };
    default:
      return state;
  }
};
