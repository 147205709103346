import {
  INSURANCE_FORM_SUBMISSION,
  INSURANCE_FORM_NOT_SUBMITTED,
  INSURANCE_OCR_DATA,
  INSURANCE_AUTOCOMPLETE,
  INSURANCE_CLEAR,
  INSURANCE_FORM_SUBMITTED,
} from '../constants/index';

const insuranceFormNotSubmitted = (value: any) => ({
  type: INSURANCE_FORM_NOT_SUBMITTED,
  payload: { value },
});

const insuranceFormSubmission = (value: any) => ({
  type: INSURANCE_FORM_SUBMISSION,
  payload: { value },
});

const insuranceFormSubmitted = (value: any) => ({
  type: INSURANCE_FORM_SUBMITTED,
  payload: { value },
});

const insuranceClear = (value?: any) => ({
  type: INSURANCE_CLEAR,
  payload: { value },
});

const insuranceOcrData = (value: any) => ({
  type: INSURANCE_OCR_DATA,
  payload: { value },
});

const insuranceAutocomplete = (value: any) => ({
  type: INSURANCE_AUTOCOMPLETE,
  payload: { value },
});

export {
  insuranceFormNotSubmitted,
  insuranceFormSubmission,
  insuranceFormSubmitted,
  insuranceOcrData,
  insuranceAutocomplete,
  insuranceClear,
};
