import { useEffect } from 'react';
import { isClientSide } from '../core/util/system';

const useSetTitle = (title: typeof document.title) => {
  useEffect(() => {
    if (isClientSide()) {
      document.title = title;
    }
  }, [title]);
};

export default useSetTitle;
