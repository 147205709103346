import React, { Component } from 'react';
import Helmet from 'react-helmet';
import intersection from 'lodash/intersection';
import Geosuggest, { Suggest } from 'react-geosuggest';
import { booleanObjectType } from 'aws-sdk/clients/iam';
import withStyles from '../../core/isomorphic-style-loader/withStyles';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './GeoAutoComplete.css' or its ... Remove this comment to see the full error message
import s from './GeoAutoComplete.css';
import { isClientSide } from '../../core/util/system';
import { stripCountry } from '~/components/GeoAutoComplete/util';
import useLocation from '../BookingFlow/timeSelection/hooks/useLocation';
import { useGoogleMapsAPI } from '~/hooks/geo/googleMaps';

const GEOSUGGEST_PLACEHOLDER = 'Street, City or Zip';

const POSTAL_CODE = 'postal_code';
const CITY = 'locality';
const NEIGHBORHOOD = 'sublocality';
const COUNTY = 'administrative_area_level_2';
const STATE = 'administrative_area_level_1';
const OTHER = 'other';
const ADDRESS = 'geocode';
const validGeoTypes = [ADDRESS, POSTAL_CODE, CITY, NEIGHBORHOOD, COUNTY, STATE];

const LoadGoogleMapsCauseIDontLikeClassComponentUgh = ({ onLoad }: { onLoad: () => void }) => {
  useGoogleMapsAPI(onLoad);
  return null;
};

interface OwnProps {
  onGeoSelect?: (suggest: Suggest) => void;
  onValueChange?: (value: any) => void;
  refSetter?: (el: Geosuggest) => void;
  onFocus?: (value: any) => void;
  onBlur?: (value: any) => void;
  onSuggestNoResults?: (userInput: string) => void;
  onUpdateSuggests?: (suggests: any, activeSuggest: any) => void;
  onShowSuggestions?: (suggest: Suggest) => void;
  onLoad?: () => void;
  currentSearch?: string;
  loadingComponent?: any;
  s?: any;
  zipOnly?: boolean;
  applyStyles?: boolean;
  label?: string;
  id?: string;
}

type State = {
  suggestionsDisplayed?: boolean;
  googleMapsLoaded: boolean;
};

type Props = OwnProps;

class GeoAutoComplete extends Component<Props, State> {
  static defaultProps = { applyStyles: true };

  /**
   * Parse out the location type, e.g. City or ZIP Code, from Google's location
   * tag list. The tag list looks something like:
   *
   * @param {Array} suggestionTypes
   * @returns {string}
   */
  static getGeoType = (suggestionTypes: string[]) =>
    intersection(suggestionTypes, validGeoTypes)[0] || OTHER;

  /**
   * Restrict suggestions to Cities, Neighborhoods and ZIP Codes.
   *
   * @param {google.maps.places.AutocompletePrediction} googleSuggest
   * @returns {boolean}
   */
  static skipSuggest(googleSuggest: google.maps.places.AutocompletePrediction) {
    switch (GeoAutoComplete.getGeoType(googleSuggest.types)) {
      case POSTAL_CODE:
      case CITY:
      case NEIGHBORHOOD:
      case ADDRESS:
        return false;
      default:
        return true;
    }
  }

  checkInterval?: number;

  state: State = {
    googleMapsLoaded: false,
    suggestionsDisplayed: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleLocationSelect = this.handleLocationSelect.bind(this);
  }

  componentDidMount = () => {
    if (!this.isGoogleApiLoaded()) this.checkInterval = setInterval(this.checkForLoadStatus, 500);
    else this.props.onLoad && this.props.onLoad();
  };

  componentWillUnmount() {
    clearInterval(this.checkInterval);
  }

  /**
   * Format autocomplete suggestion display.
   *
   * @param suggest
   * @returns {string}
   */
  getSuggestLabel = (suggest: any) => {
    if (this.props.zipOnly) {
      const zipCodeRegex = /\d\d\d\d\d/;
      const zipCodeIndex = suggest.description.search(zipCodeRegex);
      if (zipCodeIndex > -1) {
        const lengthOfZipCode = 5;
        return suggest.description.substring(zipCodeIndex, zipCodeIndex + lengthOfZipCode);
      }
    }

    return stripCountry(suggest.description);
  };

  checkForLoadStatus = () => {
    if (this.isGoogleApiLoaded()) {
      clearInterval(this.checkInterval);
      this.forceUpdate(this.props.onLoad);
    }
  };

  isGoogleApiLoaded = () => isClientSide() && window.google;

  handleLocationSelect(value: Suggest) {
    this.props.onGeoSelect?.(value);
  }

  handleChange(value: any) {
    this.props.onValueChange?.(value);

    const suggestionsDisplayed = value.length !== 0;
    if (suggestionsDisplayed !== this.state?.suggestionsDisplayed)
      this.setState({ suggestionsDisplayed });
  }

  render() {
    const {
      s: propS,
      applyStyles,
      onBlur,
      id,
      label,
      onFocus,
      refSetter,
      loadingComponent,
      onSuggestNoResults,
      onUpdateSuggests,
      onShowSuggestions,
      currentSearch,
    } = this.props;
    const styleObject = propS || s;
    const shouldApplyStyles = applyStyles || undefined;

    return (
      <div className={shouldApplyStyles && styleObject.root} data-testid="geoAutocomplete">
        <LoadGoogleMapsCauseIDontLikeClassComponentUgh
          onLoad={() => {
            if (!this.state.googleMapsLoaded) {
              this.setState({ googleMapsLoaded: true });
            }
          }}
        />

        {this.state.googleMapsLoaded ? (
          <Geosuggest
            autoActivateFirstSuggest
            className={shouldApplyStyles && styleObject.geoSuggest}
            country="us"
            getSuggestLabel={this.getSuggestLabel}
            id={id}
            initialValue={currentSearch}
            inputClassName={shouldApplyStyles && styleObject.geoSuggestInput}
            label={label}
            onActivateSuggest={onShowSuggestions}
            onBlur={onBlur}
            onChange={this.handleChange}
            onFocus={onFocus}
            onSuggestNoResults={onSuggestNoResults}
            onSuggestSelect={this.handleLocationSelect}
            onUpdateSuggests={onUpdateSuggests}
            placeholder={GEOSUGGEST_PLACEHOLDER}
            ref={refSetter}
            skipSuggest={GeoAutoComplete.skipSuggest}
          />
        ) : (
          loadingComponent || null
        )}
      </div>
    );
  }
}

export default withStyles(s)(GeoAutoComplete) as unknown as React.ComponentClass<Props, State>;
