import {
  RECEIVE_ACCOUNT,
  ACCOUNT_ERROR,
  RECEIVE_ACCOUNT_SUMMARY,
  ACCOUNT_SUMMARY_ERROR,
  ACCOUNT_SUMMARY_LOADING,
  ACCOUNT_RECEIVE_USER_PROFILE,
  ACCOUNT_USER_PROFILE_ERROR,
  ACCOUNT_USER_PROFILE_PROCESSING,
  ACCOUNT_RECEIVE_BOOKING,
  ACCOUNT_BOOKING_ERROR,
  ACCOUNT_CLEAR_SUMMARY,
  UPCOMING_APPOINTMENTS_RECEIVED,
  UPCOMING_APPOINTMENTS_ERROR,
  UPCOMING_APPOINTMENT_RESCHEDULED,
  PAST_APPOINTMENTS_RECEIVED,
  PAST_APPOINTMENTS_ERROR,
  SET_APPOINTMENT_VIEW,
  UPCOMING_APPOINTMENT_CANCELLED,
  GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  GET_INSURANCE_FRONT_CARD_IMAGE_ERROR,
  GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  GET_INSURANCE_BACK_CARD_IMAGE_ERROR,
  BOOKING_TOKBOX_RATING_SUBMITTED,
  CREATE_VERIFIED_USER_PROFILE,
  RECEIVE_USER_PROFILE_SPECIALTY_PREFERENCES,
  RECEIVE_USER_PROFILE_APPOINTMENT_PREFERENCES,
  USER_PROFILE_SPECIALTY_PREFERENCES_ERROR,
  USER_PROFILE_APPOINTMENT_PREFERENCES_ERROR,
  FETCH_USER_PROFILE_SPECIALTY_PREFERENCES,
  FETCH_USER_PROFILE_APPOINTMENT_PREFERENCES,
  ACCOUNT_MARK_MESSAGES_READ,
  ACCOUNT_TRACK_INTERACTION,
  ADD_ACTIVE_ACCOUNT_PROFILE,
  UPCOMING_NEW_BOOKING,
  FETCHING_ACCOUNT_APPOINTMENTS,
  SET_SMS_CONSENT,
} from '../constants/index';
import { analyticsLoginIdentify } from '../core/analytics';

const receiveAccount = (value: any) => ({
  type: RECEIVE_ACCOUNT,
  payload: { value },
});

const accountError = (value: any) => ({
  type: ACCOUNT_ERROR,
  payload: { value },
});

const receiveAccountSummary = (value: any) => {
  analyticsLoginIdentify(value);
  return {
    type: RECEIVE_ACCOUNT_SUMMARY,
    payload: { value },
  };
};

export const getInsuranceProfileImageFrontSuccess = (value: any) => ({
  type: GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  payload: { value },
});

export const getInsuranceProfileImageBackSuccess = (value: any) => ({
  type: GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  payload: { value },
});

export const getInsuranceProfileImageFrontError = (value: any) => ({
  type: GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  payload: { value },
});

export const getInsuranceProfileImageBackError = (value: any) => ({
  type: GET_INSURANCE_BACK_CARD_IMAGE_ERROR,
  payload: { value },
});

const accountSummaryLoading = () => ({
  type: ACCOUNT_SUMMARY_LOADING,
});

const accountSummaryError = (value: any) => ({
  type: ACCOUNT_SUMMARY_ERROR,
  payload: { value },
});

const receiveUserProfileForAccount = (value: any) => ({
  type: ACCOUNT_RECEIVE_USER_PROFILE,
  payload: { value },
});

const userProfileProcessing = () => ({
  type: ACCOUNT_USER_PROFILE_PROCESSING,
});

const userProfileError = (value: any) => ({
  type: ACCOUNT_USER_PROFILE_ERROR,
  payload: { value },
});

const receiveBooking = (value: any) => ({
  type: ACCOUNT_RECEIVE_BOOKING,
  payload: { value },
});

const bookingError = (value: any) => ({
  type: ACCOUNT_BOOKING_ERROR,
  payload: { value },
});

const clearSummary = () => ({
  type: ACCOUNT_CLEAR_SUMMARY,
});

const fetchingAccountAppointments = (value: any) => ({
  type: FETCHING_ACCOUNT_APPOINTMENTS,
  payload: { value },
});

const upcomingAppointmentsReceived = (value: any) => ({
  type: UPCOMING_APPOINTMENTS_RECEIVED,
  payload: { value },
});

const upcomingNewBooking = (value: any) => ({
  type: UPCOMING_NEW_BOOKING,
  payload: { value },
});

const upcomingAppointmentsError = (value: any) => ({
  type: UPCOMING_APPOINTMENTS_ERROR,
  payload: { value },
});

const upcomingAppointmentCancelled = (value: any) => ({
  type: UPCOMING_APPOINTMENT_CANCELLED,
  payload: { value },
});

const upcomingAppointmentRescheduled = (value: any) => ({
  type: UPCOMING_APPOINTMENT_RESCHEDULED,
  payload: { value },
});

const pastAppointmentsReceived = (value: any) => ({
  type: PAST_APPOINTMENTS_RECEIVED,
  payload: { value },
});

const pastAppointmentsError = (value: any) => ({
  type: PAST_APPOINTMENTS_ERROR,
  payload: { value },
});

const setAppointmentView = (value: any) => ({
  type: SET_APPOINTMENT_VIEW,
  payload: { value },
});

const setHasSubmittedTokboxRating = () => ({
  type: BOOKING_TOKBOX_RATING_SUBMITTED,
});

const createVerifiedUserProfile = (value: any) => ({
  type: CREATE_VERIFIED_USER_PROFILE,
  payload: { value },
});

const updateUserProfile = (value: any) => ({
  // @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'UPDATE_USER_PROFILE'. Did you me... Remove this comment to see the full error message
  type: UPDATE_USER_PROFILE,
  payload: { value },
});

const fetchUserProfileSpecialtyPreferences = () => ({
  type: FETCH_USER_PROFILE_SPECIALTY_PREFERENCES,
});

const fetchUserProfileAppointmentPreferences = () => ({
  type: FETCH_USER_PROFILE_APPOINTMENT_PREFERENCES,
});

const receiveUserProfileSpecialtyPreferences = (value: any) => ({
  type: RECEIVE_USER_PROFILE_SPECIALTY_PREFERENCES,
  payload: { value },
});

const receiveUserProfileAppointmentPreferences = (value: any) => ({
  type: RECEIVE_USER_PROFILE_APPOINTMENT_PREFERENCES,
  payload: { value },
});

const userProfileSpecialtyPreferencesError = () => ({
  type: USER_PROFILE_SPECIALTY_PREFERENCES_ERROR,
});

const userProfileAppointmentPreferencesError = () => ({
  type: USER_PROFILE_APPOINTMENT_PREFERENCES_ERROR,
});

const accountMarkMessagesRead = () => ({
  type: ACCOUNT_MARK_MESSAGES_READ,
});

const accountTrackInteraction = (value: any) => ({
  type: ACCOUNT_TRACK_INTERACTION,
  payload: { value },
});

const addActiveAccountProfileToSummary = (value: any) => ({
  type: ADD_ACTIVE_ACCOUNT_PROFILE,
  payload: { value },
});

const updateSMSConsent = (value: boolean) => ({
  type: SET_SMS_CONSENT,
  payload: { value },
});

export {
  receiveAccount,
  accountError,
  receiveAccountSummary,
  accountSummaryError,
  accountSummaryLoading,
  receiveUserProfileForAccount,
  userProfileError,
  userProfileProcessing,
  receiveBooking,
  bookingError,
  clearSummary,
  upcomingNewBooking,
  upcomingAppointmentsReceived,
  upcomingAppointmentRescheduled,
  upcomingAppointmentCancelled,
  upcomingAppointmentsError,
  pastAppointmentsReceived,
  pastAppointmentsError,
  setAppointmentView,
  setHasSubmittedTokboxRating,
  createVerifiedUserProfile,
  fetchingAccountAppointments,
  updateUserProfile,
  receiveUserProfileSpecialtyPreferences,
  receiveUserProfileAppointmentPreferences,
  userProfileSpecialtyPreferencesError,
  userProfileAppointmentPreferencesError,
  fetchUserProfileSpecialtyPreferences,
  fetchUserProfileAppointmentPreferences,
  accountMarkMessagesRead,
  accountTrackInteraction,
  addActiveAccountProfileToSummary,
  updateSMSConsent,
};
