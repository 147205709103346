import {
  LOCATION_RESPONSE_RECEIVED,
  SET_ACTIVE_LOCATION,
  SET_ACTIVE_LOCATION_WITHOUT_SLOTS,
  LOCATION_FAILED,
  LOCATION_SLOTS_RECEIVED,
  LOCATION_EXTERNAL_SLOTS_LOADING,
  LOCATION_EXTERNAL_SLOTS_RECEIVED,
  LOCATION_EXTERNAL_SLOTS_ERROR,
  LOCATION_SLOTS_FAILED,
  SET_LOCATION_TO_EMPTY,
} from '../constants/index';
import {
  locationResponseFormatter,
  locationSlotsFormatter,
  publicBookingLocationFormatter,
} from '../reducers/formatters/location';
import { analyticsTrackEvent } from '../core/analytics';
import { APP_API_FAILURE } from '../core/analytics/events';
import { EMPTY_OBJECT } from '../core/util/object';

function receiveLocation(value: any) {
  return {
    type: LOCATION_RESPONSE_RECEIVED,
    payload: { value: locationResponseFormatter(value) },
  };
}

function receivePublicBookingLocationData(value: any) {
  return {
    type: LOCATION_RESPONSE_RECEIVED,
    payload: { value: publicBookingLocationFormatter(value) },
  };
}

function setActiveLocation(value: any) {
  return {
    type: SET_ACTIVE_LOCATION,
    payload: { value: locationResponseFormatter(value) },
  };
}

function setEmptyLocation() {
  return {
    type: SET_LOCATION_TO_EMPTY,
    payload: { value: EMPTY_OBJECT },
  };
}

function setActiveLocationWithoutSlots(value: any) {
  return {
    type: SET_ACTIVE_LOCATION_WITHOUT_SLOTS,
    payload: { value: locationResponseFormatter(value) },
  };
}

const locationError =
  (locationId: any, properties = {}) =>
  (value: any) => {
    analyticsTrackEvent(APP_API_FAILURE, properties);
    return {
      type: LOCATION_FAILED,
      payload: { value, locationId },
    };
  };

function receiveLocationSlots(value: any) {
  return {
    type: LOCATION_SLOTS_RECEIVED,
    payload: { value: locationSlotsFormatter(value) },
  };
}

const locationExternalSlotsLoading = (value: boolean) => {
  return {
    type: LOCATION_EXTERNAL_SLOTS_LOADING,
    payload: { value },
  };
};

const receiveLocationExternalSlots = (value: any) => {
  // separate action to receive external (from integration) slots for 2 reasons:
  // 1. do not want to run external slots through locationSlotsFormatter (entirely different formatting)
  // 2. our existing LOCATION_SLOTS_RECEIVED logic has side effects in newBooking that we don't want here
  return {
    type: LOCATION_EXTERNAL_SLOTS_RECEIVED,
    payload: { value },
  };
};

const locationExternalSlotsError = (locationId: string) => (value: any) => {
  // TODO add tracking here
  return {
    type: LOCATION_EXTERNAL_SLOTS_ERROR,
    payload: { value, locationId },
  };
};

const locationSlotsError = (locationId: any) => (value: any) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: LOCATION_SLOTS_FAILED,
    payload: { value, locationId },
  };
};

export {
  receiveLocation,
  receiveLocationSlots,
  locationExternalSlotsLoading,
  receiveLocationExternalSlots,
  locationExternalSlotsError,
  setActiveLocation,
  setEmptyLocation,
  setActiveLocationWithoutSlots,
  locationError,
  locationSlotsError,
  receivePublicBookingLocationData,
};
