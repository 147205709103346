import { MAX_CONTENT_WIDTH } from '../../../config/index';
import { isPhoneOrUndefined, isTablet } from '../device';

export const getContentWidth = (viewportWidth: any, hasDesktopStyling: any) => {
  if (hasDesktopStyling) {
    return viewportWidth;
  }

  return viewportWidth > MAX_CONTENT_WIDTH ? MAX_CONTENT_WIDTH : viewportWidth;
};

export const isWiderThanMaxContentWidth = (width: any) => width > MAX_CONTENT_WIDTH;

/**
 * Gets the absolute position of an element by recursively adding up the offsets of its parents.
 *
 * @param el {object} A DOM element
 * @param acc {object} don't pass in. this is used as the accumulator to return.
 */
// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
export const getPos = (el: any, acc = { x: 0, y: 0 }) =>
  el
    ? getPos(el.offsetParent, {
        x: acc.x + el.offsetLeft,
        y: acc.y + el.offsetTop,
      })
    : acc;

export const isExtraNarrowFormat = (width: any) => width < 360;
export const isNarrowFormat = (width: any) => width < 550;
export const isTabletOrSmaller = (width: any) => width <= 1000;
export const isLargeTabletOrLarger = (width: any) => width >= 1024;
export const isLargeTabletOrSmaller = (width: any) => width <= 1024;
export const isWideFormat = (width: number) => width > 768;
export const isIPadOrLarger = (width: any) => width >= 768;
export const isExtraWideFormat = (width: any) => width >= 1280;

export const isMobile = (width: any) => width < 1024;
export const isStandardDesktop = (width: any) => width >= 1024 && width < 1200;
export const isWideDesktop = (width: any) => width >= 1200;

// As of yet unused, just defining it for the sake of completeness
export const isMediumFormat = (width: any) => !isNarrowFormat(width) && !isWideFormat(width);

export const getDefaultViewport = (userAgent?: string) => {
  if (isPhoneOrUndefined(userAgent)) {
    return { width: 375, height: 667 };
  }

  if (isTablet(userAgent)) {
    return { width: 768, height: 1024 };
  }

  return { width: 1366, height: 768 };
};

/**
 * Get matching material UI breakpoint based upon initialViewport which is calculated via UA
 *
 * @returns {string} material ui breakpoint
 */

export const getDefaultBreakpoint = (userAgent?: string) =>
  isPhoneOrUndefined(userAgent) ? 'xs' : 'md';
