import {
  GUARANTOR_FORM_FIELD_NAMES,
  PaperworkFieldName,
  PAYMENT_FORM_FIELD_NAMES,
  SECONDARY_PAYMENT_FORM_FIELD_NAMES,
} from '~/components/Paperwork/paperworkConstants';
import { SectionPageCollection } from '~/components/Paperwork/interfaces';

export enum PaymentPageNumber {
  SelfPayCheck,
  InsuranceInfo,
  SecondaryInsurance,
  GuarantorInfo,
}

export const PAYMENT_PAGES: SectionPageCollection = new Map([
  [
    PaymentPageNumber.SelfPayCheck,
    {
      fieldNames: [PaperworkFieldName.SELF_PAY_CHECK],
    },
  ],
  [
    PaymentPageNumber.InsuranceInfo,
    {
      fieldNames: PAYMENT_FORM_FIELD_NAMES,
    },
  ],
  [
    PaymentPageNumber.SecondaryInsurance,
    {
      fieldNames: SECONDARY_PAYMENT_FORM_FIELD_NAMES,
    },
  ],
  [
    PaymentPageNumber.GuarantorInfo,
    {
      fieldNames: GUARANTOR_FORM_FIELD_NAMES,
    },
  ],
]);
