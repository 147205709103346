import { whiteLabelPalettes } from '@solvhealth/jigsaw';
import { useSolvSelector } from '~/reducers';
import { isWhiteLabelableUrl } from './useWhiteLabelProperties';

export const usePalette = () => {
  const { palette: paletteName } = useSolvSelector((state) => state.theme);
  const { palette } = whiteLabelPalettes[paletteName];
  const isWhiteLabelUrl = isWhiteLabelableUrl();
  return {
    palette: isWhiteLabelUrl ? palette : whiteLabelPalettes.crunchberry.palette,
    paletteName: isWhiteLabelUrl ? paletteName : 'crunchberry',
  };
};
