import { isI18NSupportedLang, Language } from '~/locale/i18n';

export type SupportedLocale = Language;
export { isI18NSupportedLang as isSupportedLocale };

export const EN = 'en';
export const ES = 'es';
export const SO = 'so';
export const HMN = 'hmn';

export const getDapiLanguageFromLocale = (locale: Language): Language => {
  if ((locale as any) === 'es-us') return ES;
  if ((locale as any) === 'en-us') return EN;
  return locale;
};
