import { takeEvery, call, put, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/flat` if it exists or add ... Remove this comment to see the full error message
import flatten from 'flat';
import { GenericObj } from '@solvhealth/types/interfaces/generics';
import { apiGet, apiPost } from '../core/dapi';
import { getLocationPaperworkFieldsUrl, getPaperworkStatusLogUrl } from '../core/dapi/paperwork';
import {
  locationPaperworkFieldsReceived,
  locationPaperworkFieldsError,
  initializingData,
  resetPaperworkState,
  isSubmittingPaperworkPage,
  isSubmittingPaperworkStatusLog,
  locationConsentFormsReceived,
  locationConsentFormsError,
  sendDataToState,
  PaperworkState,
  resetAddressValidationLogs,
} from '../ducks/paperwork';
import { getCreatePaperworkResponsesUrl } from '../core/dapi/paperworkResponses';
import { getLocationUrl } from '../core/dapi/location';
import { setPastBooking } from '../actions/pastBookings';
import { receiveLocation } from '../actions/location';
import { PaperworkTypes } from '../constants/index';
import { getLocationConsentFormsUrl } from '../core/dapi/locationConsentForms';
import { ajaxSuccess } from '../actions/runtime';
import { fetchConsentFormsHelper, LocationConsentFormSagas } from './locationConsentForms';
import { analyticsTrackEvent } from '../core/analytics';
import {
  PAPERWORK_ERROR_LOADING_LOCATION_CONSENT_FORMS,
  PAPERWORK_ERROR_LOADING_LOCATION_PAPERWORK_FIELDS,
} from '../core/analytics/events';
import { isEmptyObject } from '../core/util/object';
import { getBookingsEndpointByPublicity } from '~/core/dapi/newBooking';
import { paperworkTransformerAfterSubmit } from '~/components/Paperwork/util';
import { SolvReduxState } from '~/reducers';
import { LOCATION_PAPERWORK_RESPONSE_TYPE_REGISTRATION } from '~/components/Paperwork/paperworkConstants';
import logger from '../core/logger/index';
import { isProviderGroupFieldDependencySatisfied } from '~/components/Paperwork/providerGroups/util';
import { isFieldDependencySatisfied } from '~/components/Paperwork/util';

export class PaperworkSagas {
  static INITIALIZE_PAPERWORK_DATA = 'saga/INITIALIZE_PAPERWORK_DATA';

  static SUBMIT_FINAL_PAPERWORK_PAGE = 'saga/SUBMIT_PAPERWORK_PAGE';

  static SUBMIT_PAPERWORK_STATUS_LOG = 'saga/SUBMIT_PAPERWORK_STATUS_LOG';

  static SUBMIT_PAPERWORK_PAGE = 'saga/SUBMIT_URGENT_CARE_PAPERWORK_PAGE';
}

function* initializePaperworkData({ bookingId, location, usePublicData }: any) {
  let bookingLocationId = null;
  try {
    yield put(resetPaperworkState());
    yield put(initializingData());
    const bookingUrl = getBookingsEndpointByPublicity(bookingId, usePublicData);
    const bookingResponse = yield call(apiGet, bookingUrl);
    yield put(setPastBooking(bookingResponse));
    bookingLocationId = bookingResponse.location_id;

    if (isEmptyObject(location) || location.error || location.id !== bookingLocationId) {
      const response = yield call(apiGet, getLocationUrl(bookingLocationId));
      yield put(receiveLocation(response));
    }

    const locationPaperworkFieldsUrl = getLocationPaperworkFieldsUrl(
      bookingLocationId,
      PaperworkTypes.REGISTRATION
    );
    const locationPaperworkResponse = yield call(apiGet, locationPaperworkFieldsUrl);
    yield put(locationPaperworkFieldsReceived(locationPaperworkResponse));

    yield call(fetchConsentFormsHelper, { locationId: bookingLocationId });
  } catch (e) {
    console.error(e);
    yield put(locationPaperworkFieldsError(e));
    analyticsTrackEvent(PAPERWORK_ERROR_LOADING_LOCATION_PAPERWORK_FIELDS, {
      bookingLocationId,
      bookingId,
    });
  }
}

function* submitFinalPaperworkPage({ formData, onSuccess, onError }: any) {
  yield put(isSubmittingPaperworkPage(true));
  try {
    const transformedData = paperworkTransformerAfterSubmit(formData);
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    yield call(apiPost, getCreatePaperworkResponsesUrl(), transformedData);
    onSuccess();
  } catch (e) {
    onError();
  } finally {
    yield put(isSubmittingPaperworkPage(false));
  }
}

function* submitPaperworkStatusLog({ logData }: any) {
  yield put(isSubmittingPaperworkStatusLog(true));
  try {
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    yield call(apiPost, getPaperworkStatusLogUrl(), logData);
  } catch (e) {
    console.error(e);
  }
}

function* getFieldsToClear({ newForm, isProviderGroup }: GenericObj) {
  const paperwork: PaperworkState = yield select((state: SolvReduxState) => state.paperwork);
  const updatedPaperwork = { ...paperwork, formData: { ...paperwork.formData, ...newForm } };
  const { formData } = updatedPaperwork;
  const fieldsToClear: GenericObj = {};
  const fieldDependencySatisfied = isProviderGroup
    ? isProviderGroupFieldDependencySatisfied
    : isFieldDependencySatisfied;
  for (const fieldName in formData) {
    if (!fieldDependencySatisfied(fieldName, updatedPaperwork) && formData[fieldName]) {
      fieldsToClear[fieldName] = '';
    }
  }

  return fieldsToClear;
}

function* submitPaperworkPage({
  newForm,
  bookingId,
  paperworkStatus,
  onSuccess,
  isProviderGroup = false,
  extraFields = {},
}: AnyAction) {
  yield put(isSubmittingPaperworkPage(true));
  try {
    const fieldsToClear: GenericObj = yield call(getFieldsToClear, {
      newForm,
      isProviderGroup,
    });
    const cleanForm = { ...newForm, ...fieldsToClear };
    yield put(sendDataToState(cleanForm));
    Object.keys(cleanForm).forEach((key) => {
      if (Array.isArray(cleanForm[key])) {
        cleanForm[key] = JSON.stringify(cleanForm[key]);
      }
    });
    const flatForm = flatten(cleanForm);
    flatForm.paperworkStatus = paperworkStatus;

    const addressValidationLogs: GenericObj[] = yield select(
      (state: SolvReduxState) => state.paperwork.addressValidationLogs
    );
    const data = {
      response_fields: {
        ...flatForm,
        ...extraFields,
      },
      booking_id: bookingId,
      type_: LOCATION_PAPERWORK_RESPONSE_TYPE_REGISTRATION,
      is_editing: true,
      address_validation_logs: addressValidationLogs,
    };
    const transformedData = paperworkTransformerAfterSubmit(data);
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    yield call(apiPost, getCreatePaperworkResponsesUrl(), transformedData);
    yield put(resetAddressValidationLogs());
    const updatedPaperwork: PaperworkState = yield select(
      (state: SolvReduxState) => state.paperwork
    );
    onSuccess(updatedPaperwork);
  } catch (e) {
    logger.error(e);
  } finally {
    yield put(isSubmittingPaperworkPage(false));
  }
}

/**
 *
 */
function* rootSaga() {
  yield takeEvery(PaperworkSagas.INITIALIZE_PAPERWORK_DATA, initializePaperworkData);
  yield takeEvery(PaperworkSagas.SUBMIT_FINAL_PAPERWORK_PAGE, submitFinalPaperworkPage);
  yield takeEvery(PaperworkSagas.SUBMIT_PAPERWORK_STATUS_LOG, submitPaperworkStatusLog);
  yield takeEvery(PaperworkSagas.SUBMIT_PAPERWORK_PAGE, submitPaperworkPage);
}

export { rootSaga as default };
