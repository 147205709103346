import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCookies } from 'react-cookie/cjs';
import { useEffect } from 'react';
import { useAccount } from '~/hooks/account/useAccount';
import { getLDUserObject } from '~/core/launch-darkly/util';
import { LOGIN_INFO_COOKIE_NAME, SEGMENT_ANONYMOUS_ID_COOKIE_NAME } from '../../../config';

/**
 * Update the Launch Darkly user object when the user object has changed.
 * ex: If a user logs in, and/or after we get the account summary and can update the object with richer properties.
 */
export function useLaunchDarklyIdentify() {
  const account = useAccount();
  const lDClient = useLDClient();
  const [cookies] = useCookies([SEGMENT_ANONYMOUS_ID_COOKIE_NAME, LOGIN_INFO_COOKIE_NAME]);
  const segmentAnonymousId = cookies[SEGMENT_ANONYMOUS_ID_COOKIE_NAME];
  const accountId = cookies[LOGIN_INFO_COOKIE_NAME]?.id;

  useEffect(() => {
    const user = getLDUserObject({
      account,
      accountId,
      segmentAnonymousId,
    });

    lDClient?.identify(user);
  }, [lDClient, account, cookies, accountId, segmentAnonymousId]);
}
