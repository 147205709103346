import LocationInterface from '@solvhealth/types/interfaces/Location';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCareOptionsQuery } from '~/components/CareTeam/hooks/useCareOptions';
import {
  MEMBERSHIP_SUPPORTED_STATES,
  SUPPRESSED_LOCATION_IDS,
} from '~/components/Membership/constants';
import { profileCanAccessPremium } from '~/components/Membership/utils';
import { analyticsTrackEvent } from '~/core/analytics';
import { SOLV_NOW_ENTRYPOINT_VISIBILITY } from '~/core/analytics/events';
import { UserProfile } from '~/core/dapi/models';
import AuthenticationContext from '~/core/login/AuthenticationContext';
import { isActiveMembership } from '~/core/util/account';
import { useAccount } from '../account/useAccount';
import { useCurrentCareRegion } from '../useCurrentCareRegion';
import useSelectActiveUserProfile from '../useSelectActiveUserProfile';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';

type MembershipHookProps = {
  /** Should users who have booked at a location that offers telemed or with a clinic group whose patients we are excluding see this entrypoint?
   * When true, that means we decided that they are seeking NEW care, ex: they are initiating a search the SRP. */
  overrideExcludedLocations?: boolean;

  /** If a user has already signed up for the membership, should they still see this entrypoint? */
  showToExistingMembers?: boolean;

  /** Entrypoint ID to associate with tracking data */
  entrypointId?: string;

  /** We can suppress entrypoint tracking for some cases where we call this hook just for its data */
  suppressTracking?: boolean;

  /** Extra tracking data */
  extraTrackingData?: Record<string, any>;
};

type CheckMembershipEntrypointParams = MembershipHookProps & {
  /** Are we loading account and booking data still? */
  isDataLoading?: boolean;

  /** Have we enabled public marketing to non-members for premium visits? */
  isMarketingEnabled?: boolean;

  /** Are we logged in? */
  isLoggedIn?: boolean;

  /** Are we in a supported geography (US state)? */
  inSupportedRegion?: boolean;

  /** Does the user have a membership already */
  hasMembership?: boolean;

  /** Does the user have a relationship with a clinic that offers telemed, or with a clinic group whose patients we are excluding?*/
  hasExcludedLocation?: boolean;

  /** Does the user have a value for relationship to account that excludes them from accessing premium visits?*/
  isPremiumAccessibleToCurrentUserProfile: boolean;
};

/**
 * Handles the flow-chart logic as to whether the entrypoint should be rendered.
 *
 * Derived from: https://lucid.app/lucidchart/invitations/accept/inv_27fb5dc1-4c90-4a20-bf4a-dbe55cf3e827
 *
 * @returns boolean
 */
export function shouldShowPremiumVisitEntrypoint({
  overrideExcludedLocations,
  showToExistingMembers,
  isMarketingEnabled,
  isDataLoading,
  isLoggedIn,
  inSupportedRegion,
  hasExcludedLocation,
  hasMembership,
  isPremiumAccessibleToCurrentUserProfile,
}: CheckMembershipEntrypointParams): boolean {
  // Default to not showing while loading to avoid flashing
  if (isDataLoading) return false;

  // If we are not logged in, only care about geography and feature flag
  if (!isLoggedIn) {
    return !!inSupportedRegion && !!isMarketingEnabled;
  }
  // We never want to show solv plus/now to a logged in user for a profile that
  // doesn't support premium visits
  if (!isPremiumAccessibleToCurrentUserProfile) return false;

  // If we have a membership, whether we show the entrypoint varies
  if (hasMembership) {
    return !!showToExistingMembers;
  }

  // Logged in and no membership means we need supported region
  // and either no clinic relationship (with an excluded partner clinic)
  // or to be in an entrypoint where we don't care about clinic relationship
  return (
    !!isMarketingEnabled &&
    !!inSupportedRegion &&
    (!hasExcludedLocation || !!overrideExcludedLocations)
  );
}

/**
 * Determine whether a premium entrypoint should be shown to the user.
 *
 * @returns A boolean
 */
function useShouldShowPremiumVisitEntrypoints({
  overrideExcludedLocations,
  showToExistingMembers,
  entrypointId,
  extraTrackingData,
  suppressTracking = false,
}: MembershipHookProps) {
  const { isLoggedIn } = useContext(AuthenticationContext);

  const { solvNowMarketingEnabled } = useLDFlags();

  // Base data we'll need to reference
  const hasMembership = isActiveMembership(useAccount());
  const userProfile: UserProfile | undefined = useSelectActiveUserProfile();

  const isPremiumAccessibleToCurrentUserProfile = profileCanAccessPremium(userProfile) ?? true;

  // Clinic relationship logic
  const { data: videoCareOptions, isLoading: isLoadingVideoCareOptions } =
    useCareOptionsQuery('video');
  const { data: ucCareOptions, isLoading: isLoadingUCCareOptions } = useCareOptionsQuery('uc');

  const hasExistingTelemedRelationship = !isEmpty(videoCareOptions?.data?.book_again);
  const ucRelationshipLocationIds =
    ucCareOptions?.data?.book_again.map((location: LocationInterface) => location.id) ?? [];

  const hasExcludedLocation =
    hasExistingTelemedRelationship ||
    SUPPRESSED_LOCATION_IDS.some((locationId: string) =>
      ucRelationshipLocationIds.includes(locationId)
    );

  // Data loading logic
  const isLoadingAccount = useSelector((state) => state.account.accountSummaryLoading);
  const isDataLoading = isLoadingVideoCareOptions || isLoadingUCCareOptions || isLoadingAccount;

  // Supported region logic based on US state
  const region = useCurrentCareRegion();

  const inSupportedRegion = region ? MEMBERSHIP_SUPPORTED_STATES.includes(region ?? '') : true;

  const [showEntrypoint, setShowEntrypoint] = useState(false);

  useEffect(() => {
    if (suppressTracking) {
      return;
    }
    analyticsTrackEvent(SOLV_NOW_ENTRYPOINT_VISIBILITY, {
      visible: showEntrypoint,
      entrypoint: entrypointId,
      isMember: hasMembership,
      solvNowMarketingEnabled,
      overrideExcludedLocations,
      showToExistingMembers,
      isDataLoading,
      isLoggedIn,
      hasMembership,
      hasExcludedLocation,
      inSupportedRegion,
      isPremiumAccessibleToCurrentUserProfile,
      ...extraTrackingData,
    });
  }, [
    entrypointId,
    extraTrackingData,
    hasExcludedLocation,
    hasMembership,
    inSupportedRegion,
    isDataLoading,
    isLoggedIn,
    isPremiumAccessibleToCurrentUserProfile,
    solvNowMarketingEnabled,
    overrideExcludedLocations,
    showEntrypoint,
    showToExistingMembers,
    suppressTracking,
  ]);

  // Pass everything into the boolean logic function
  useEffect(() => {
    setShowEntrypoint(
      shouldShowPremiumVisitEntrypoint({
        overrideExcludedLocations,
        showToExistingMembers,
        isMarketingEnabled: solvNowMarketingEnabled,
        isDataLoading,
        isLoggedIn,
        hasMembership,
        hasExcludedLocation,
        inSupportedRegion,
        isPremiumAccessibleToCurrentUserProfile,
      })
    );
  }, [
    isDataLoading,
    showToExistingMembers,
    hasMembership,
    hasExcludedLocation,
    inSupportedRegion,
    isLoggedIn,
    overrideExcludedLocations,
    isPremiumAccessibleToCurrentUserProfile,
    solvNowMarketingEnabled,
  ]);
  return showEntrypoint;
}

export default useShouldShowPremiumVisitEntrypoints;
