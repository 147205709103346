const dataTestIds = {
  bookingFlow: {
    submit: 'booking-flow-submit',
    submitSecondary: 'booking-flow-submit-secondary',
    rfvEntry: 'booking-flow-rfv-entry',
    profileSelect: 'booking-flow-profile',
    addProfile: 'booking-flow-add-profile',
    skipUploadingPhotos: 'booking-flow-skip-uploading-photos',
    insuranceCarrier: 'booking-flow-insurance-carrier',
    memberId: 'booking-flow-insurance-member-id',
    insuranceType: 'booking-flow-insurance-type',
    insuranceFirstName: 'booking-flow-insurance-first-name',
    insuranceLastName: 'booking-flow-insurance-last-name',
    insuranceDateOfBirth: 'booking-flow-insurance-dob',
    addressStreet: 'booking-flow-address-street',
    addressStreetSecondary: 'booking-flow-address-street-secondary',
    addressCity: 'booking-flow-address-city',
    addressState: 'booking-flow-address-state',
    addressZip: 'booking-flow-adddress-zip',
    newProfileFirstName: 'booking-flow-new-profile-first-name',
    newProfileLastName: 'booking-flow-new-profile-last-name',
    newProfileBirthDate: 'booking-flow-new-profile-birth-date',
    newProfileEmail: 'booking-flow-new-profile-email',
    newProfilePhone: 'booking-flow-new-profile-phone',
  },
  myTestId: 'my-data-test-id',
  homePage: {
    symptomSearch: 'homepage-symptom-search',
    symptomSearchInput: 'homepage-symptom-search-input',
    locationSearch: 'homepage-location-search',
    locationSearchInput: 'homepage-location-search-input',
    searchButton: 'homepage-search-button',
    autoCompleteCovidTest: 'auto-complete-COVID-test-Urgent-Care',
    autoCompleteDallas: 'auto-complete-Dallas-TX,-USA',
    loginHeaderButton: 'login-header-button',
    loginPhoneField: 'phone',
    phoneLoginFormSubmit: 'phoneLoginFormSubmit',
    otpCode: 'otpCode',
    otpCodeFormSubmit: 'otpCodeFormSubmit',
  },
  account: {
    urgentCareNavTile: 'urgent-care-nav-tile',
    videoVisitNavTile: 'video-visit-nav-tile',
    bookAgainCard: 'book-again-card',
    continueWithSearchButton: 'continue-with-search-button',
    insuranceTab: 'insurance-tab',
    getCareTab: 'get-care-tab',
    insuranceCard: 'insurance-card',
    profileView: 'profile-view',
  },
  cdp: {
    clinicHighlights: 'cdp-clinic-highlight',
    clinicRating: 'cdp-clinic-rating',
    description: 'cdp-description',
    clinicHours: 'cdp-clinic-hours',
    businessHoursCard: 'cdp-business-hours-card',
    hours: 'cdp-hours',
    selfPayAccordion: 'cdp-self-pay-accordion',
    covidTestAccordion: 'cdp-covid-test-accordion',
    commonServicesHeader: 'cdp-common-services-header',
    allServices: 'cdp-all-services',
    allServicesBtn: 'cdp-all-services-btn',
    customBookingMessage: 'cdp-custom-booking-message',
    bookingFormSubmitBtn: 'cdp-booking-form-submit-btn',
    mobileBookBtn: 'cdp-mobile-book-btn',
    viewAllVisits: 'cdp-mobile-view-all-visits',
    callForVisitCTA: 'cdp-call-for-visit-cta',
    bookModuleRoot: 'cdp-book-module-root',
    nearbyClinics: 'cdp-nearby-clinics',
    officeVisitToggle: 'cdp-office-visit-toggle',
    videoVisitToggle: 'cdp-video-visit-toggle',
    findMoreBtn: 'cdp-find-more-btn',
    prescreenerYesBtn: 'cdp-prescreener-yes-btn',
    headerPanel: 'cdp-header-panel',
    bookingModalClose: 'booking-modal-closed',
    skipUploadingInsurance: 'skipUploadInsurance',
  },
  srp: {
    clinicCard: 'search-location-card',
    clinicPhoto: 'srp-clinic-photo',
    clinicHours: 'srp-clinic-hours',
    clinicName: 'srp-clinic-name',
    list: {
      base: 'base-list',
      urgentCare: 'urgent-care-list',
    },
    searchBar: {
      mobile: 'srp-mobile-search-bar',
    },
    searchFilter: {
      desktop: {
        filter: 'srp-filter-desktop',
        btn: 'srp-filter-desktop-btn',
      },
      mobile: {
        edit: 'srp-filter-mobile-edit',
        reasonLabel: 'srp-filter-mobile-reason',
        locationLabel: 'srp-filter-mobile-location',
        michiganCostLabel: 'srp-filter-mobile-michigan-cost',
      },
    },
    mobileNav: {
      getCareBtn: 'srp-mobile-navigator-getcare',
      appointmentsBtn: 'srp-mobile-navigator-appointments',
      chatBtn: 'srp-mobile-navigator-chat',
      insuranceBtn: 'srp-mobile-navigator-insurance',
      profileBtn: 'srp-mobile-navigator-profile',
    },
  },
  login: {
    btn: 'login-header-button',
    mobileBtn: 'login-header-icon',
    phoneEnter: 'phone-login-enter',
    phoneSubmit: 'phone-login-submit',
    otpEnter: 'otp-enter',
    otpSubmit: 'otp-submit',
  },
  universalSearch: {
    searchInput: 'universal-search-input',
  },
};

export default dataTestIds;
