import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { hideModal, showModal } from '../actions/runtime';

export default (modalId = '') => {
  const dispatch = useDispatch();
  const openModal = useCallback(
    (callbackModalId?) => dispatch(showModal(modalId || callbackModalId)),
    [dispatch, modalId]
  );
  const closeModal = useCallback(
    (callbackModalId?) => dispatch(hideModal(modalId || callbackModalId)),
    [dispatch, modalId]
  );
  return {
    openModal,
    closeModal,
  };
};
