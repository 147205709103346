import { Dialog, List, ListItem } from '@material-ui/core';
import { Link, NavBar, Spinner, Stack, Text, TextField } from '@solvhealth/jigsaw';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLocation } from '~/actions/searchPreferences';
import BorderBoxReset from '~/components/util/BorderBoxReset';
import { googleGeocode } from '~/core/dapi/geoCode';
import { useLocationAutocomplete } from '~/hooks/geo/googleMaps';
import { PositionTrigger, useUserPosition } from '~/hooks/geo/useUserPosition';
import useToggleRuntimeModal from '~/hooks/runtime/useToggleRuntimeModal';
import { useBreakpoint } from '~/hooks/useBreakpoint';

type Props = {};

export const LOCATION_SELECTOR_MODAL = 'location-selector-modal';

const UserGeoLocationSelector = (props: Props) => {
  const [open, toggle] = useToggleRuntimeModal(LOCATION_SELECTOR_MODAL);
  const [loading, setLoading] = useState<Record<string, boolean | null>>({});

  /**
   * @returns whether we're loading the geocode for a location
   */
  function isResultLoading(id: string) {
    return loading[id] ?? false;
  }

  /**
   * Sets whether a location is loading
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setResultLoading(id: string, value: boolean) {
    setLoading({ ...loading, [id]: value });
  }

  const { requestPosition, position } = useUserPosition();

  const userLocation = useSelector((state) => state.searchPreferences.location);

  const currentCity = useMemo(() => {
    return userLocation?.city ?? position.result?.city;
  }, [position.result?.city, userLocation?.city]);

  const isSm = useBreakpoint('sm', 'below');

  const { data, value, setValue } = useLocationAutocomplete(currentCity);

  const dispatch = useDispatch();

  const handleSelectCurrentLocation = () => {
    requestPosition(PositionTrigger.AccurateSearch);
    toggle();
  };

  const handleSelect = useCallback(
    async (prediction: typeof data[0]) => {
      setResultLoading(prediction.place_id, true);
      try {
        const geoResult = await googleGeocode(prediction.description);
        toggle();

        if (geoResult) {
          dispatch(
            setUserLocation({
              ...geoResult,
              cityLabel: geoResult.city,
            })
          );
        }
      } catch (e) {
        toggle();
      } finally {
        setResultLoading(prediction.place_id, false);
      }
    },
    [dispatch, setResultLoading, toggle]
  );

  return (
    <Dialog fullScreen={isSm} onClose={toggle} open={open}>
      <BorderBoxReset>
        <Stack maxWidth="100%" pb={3} space={2} width="512px">
          <NavBar hideBackButton rightItem={<Link onClick={toggle}>Done</Link>}>
            Set your location
          </NavBar>

          <Stack pt={2} px={3}>
            <TextField
              autoFocus
              dense
              onChange={(e) => setValue(e.target.value)}
              title="Search for a city"
              value={value}
            />
          </Stack>

          <List>
            <ListItem button onClick={handleSelectCurrentLocation}>
              <Stack alignItems="center" px={2} row space={1}>
                <img
                  alt="GPS Location icon"
                  height={13}
                  src="/images/icons/search-icons/Icon-Search-GPS.svg"
                  width={13}
                />
                <Text>Current location</Text>
              </Stack>
            </ListItem>
            {data?.map((googleLocationData) => (
              <ListItem
                button
                key={googleLocationData.place_id}
                onClick={() => handleSelect(googleLocationData)}
              >
                <Stack alignItems="center" justifyContent="space-between" row width="100%">
                  <Stack px={2}>
                    <Text variant="subtitle">
                      {googleLocationData.structured_formatting.main_text}
                    </Text>
                    <Text muted>{googleLocationData.structured_formatting.secondary_text}</Text>
                  </Stack>
                  {isResultLoading(googleLocationData.place_id) && <Spinner />}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>
      </BorderBoxReset>
    </Dialog>
  );
};

export default UserGeoLocationSelector;
