import {
  BROWSER_LOCATION_ALLOWED,
  BROWSER_LOCATION_DENIED,
  CLEAR_SEARCH_PREFERENCES,
  SET_ACTIVE_FILTER,
  SET_SEARCH_COVID_COST_TYPE,
  SET_SEARCH_IN_MARKET_PLACE,
  SET_SEARCH_PREFERENCES_PAGINATION_PARTNER_RADIUS,
  SET_USER_LOCATION,
  SHOW_ACTIVE_FILTER,
  SHOW_SEARCH_FILTERS,
  SYMPTOMS_SUBMIT,
} from '../constants/index';
import {
  SEARCH_NON_PARTNERS_RESPONSE_RECEIVED,
  SEARCH_PARTNERS_RESPONSE_RECEIVED,
} from '../ducks/searchResults';
import { DEFAULT_SEARCH_RADIUS, METERS_IN_A_MILE } from '../config/index';
import { URGENT_CARE_VALUE } from '../components/Home/Tiles/ProviderTypes';

export type SearchPagination = Partial<{
  partnersPage: number;
  nonPartnersPage: number;
  partnersRadius: number;
  nonPartnersRadius: number;
}>;

export type SearchLocation = GeoResponse & {
  cityLabel: string;
};

export enum VisitMode {
  All = 'all',
  InPerson = 'in-person',
  Telemed = 'telemed',
}

export type FilterGroups = {
  mode: VisitMode;
  sortOrder: SrpSortOrder;
  acceptedInsurance: AcceptedInsurance;
  moreFilters: {
    solvRatingMinimum: SolvRatingMinimum;
    requestedServices: string[];
    locationCategories: string[];
  };
};

export type FilterGroupKey = keyof FilterGroups;

export type SearchPreferencesState = { filterGroups: FilterGroups } & Partial<{
  displayActiveFilter: string;
  location: SearchLocation;
  outsideServiceArea: boolean;
  browserLocationAllowed: boolean;
  pagination: SearchPagination;
  showFilters: string;
  activeFilter: string;
  providerType: string;
  category: string;
  requestedInsurance: any;
  isServiceHardFilter: boolean;
  isCovidTestRelatedSearch: boolean;
  isFluRelatedSearch: boolean;
  isInMarketPlaceSearch: boolean;
  covidCostType: boolean;
}>;

export type SolvRatingMinimum = 0 | 1 | 2 | 3 | 4 | 5;

export type AcceptedInsurance =
  | ''
  | 'aetna'
  | 'anthem'
  | 'blue_cross_blue_shield'
  | 'cigna'
  | 'humana'
  | 'united_healthcare'
  | 'medicaid'
  | 'medicare'
  | 'triwest'
  | 'tricare';

export enum SrpSortOrder {
  Relevance = 'relevance',
  Distance = 'distance',
  Rating = 'rating_solv',
  SoonestAvailable = 'soonest_available',
}

const initialPagination: SearchPagination = {
  partnersPage: 1,
  nonPartnersPage: 1,
  partnersRadius: DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE,
  nonPartnersRadius: DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE,
};

export const defaultFilterGroups: FilterGroups = {
  mode: VisitMode.All,
  sortOrder: SrpSortOrder.Relevance,
  acceptedInsurance: '',
  moreFilters: {
    solvRatingMinimum: 0,
    requestedServices: [],
    locationCategories: [],
  },
};

export default function searchReducer(
  state: SearchPreferencesState = {
    pagination: initialPagination,
    filterGroups: defaultFilterGroups,
  },
  action: any
): SearchPreferencesState {
  switch (action.type) {
    case SHOW_ACTIVE_FILTER:
      return {
        ...state,
        displayActiveFilter: action.payload.value,
      };
    case CLEAR_SEARCH_PREFERENCES:
      return {
        pagination: initialPagination,
        filterGroups: defaultFilterGroups,
      };

    case SET_USER_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          ...action.payload.value,
        },
        outsideServiceArea: false,
      };
    case BROWSER_LOCATION_ALLOWED:
      return {
        ...state,
        browserLocationAllowed: true,
      };
    case BROWSER_LOCATION_DENIED:
      return {
        ...state,
        browserLocationAllowed: false,
      };
    case SEARCH_PARTNERS_RESPONSE_RECEIVED: {
      const responsePagination = action.payload.value.response.pagination;
      const pagination = {
        ...state.pagination,
        partnersPage: responsePagination.page,
      };
      return {
        ...state,
        pagination,
      };
    }

    case SEARCH_NON_PARTNERS_RESPONSE_RECEIVED: {
      const responsePagination = action.payload.value.response.pagination;
      const pagination = {
        ...state.pagination,
        nonPartnersPage: responsePagination.page,
      };
      return {
        ...state,
        pagination,
      };
    }

    case SHOW_SEARCH_FILTERS:
      return {
        ...state,
        showFilters: action.payload.value,
      };
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: action.payload.value,
      };
    case SYMPTOMS_SUBMIT:
      return {
        ...state,
        location: action.payload.value.location || state.location,
        providerType: action.payload.value.providerType || state.providerType || URGENT_CARE_VALUE,
        category: action.payload.value.category || state.category,
        requestedInsurance: action.payload.value.requestedInsurance || state.requestedInsurance,
        isServiceHardFilter: action.payload.value.isServiceHardFilter ?? state.isServiceHardFilter,
        isCovidTestRelatedSearch:
          action.payload.value.isCovidTestRelatedSearch ?? state.isCovidTestRelatedSearch,
        isFluRelatedSearch: action.payload.value.isFluRelatedSearch ?? state.isFluRelatedSearch,
        filterGroups: {
          mode: action.payload.value.filterGroups?.mode ?? state.filterGroups.mode,
          acceptedInsurance:
            action.payload.value.filterGroups?.acceptedInsurance ||
            state.filterGroups.acceptedInsurance,
          moreFilters: {
            locationCategories:
              action.payload.value.filterGroups?.moreFilters?.locationCategories ??
              state.filterGroups.moreFilters.locationCategories,
            requestedServices:
              action.payload.value.filterGroups?.moreFilters?.requestedServices ||
              state.filterGroups.moreFilters.requestedServices,
            solvRatingMinimum:
              action.payload.value.filterGroups?.moreFilters?.solvRatingMinimum ??
              state.filterGroups.moreFilters.solvRatingMinimum,
          },
          sortOrder: action.payload.value.filterGroups?.sortOrder ?? state.filterGroups.sortOrder,
        },
      };
    case SET_SEARCH_IN_MARKET_PLACE:
      return {
        ...state,
        isInMarketPlaceSearch: action.payload.value.in_market,
      };
    case SET_SEARCH_COVID_COST_TYPE:
      return {
        ...state,
        covidCostType: action.payload.value,
      };
    case SET_SEARCH_PREFERENCES_PAGINATION_PARTNER_RADIUS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          nonPartnersRadius: action.payload.value.nonPartnersRadius,
          partnersRadius: action.payload.value.partnersRadius,
        },
      };
    default:
      return state;
  }
}
