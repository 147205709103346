// jscs:disable disallowArrayDestructuringReturn
import { put, select } from 'redux-saga/effects';
import merge from 'lodash/merge';
import { setDisplayQuery } from '../../ducks/universalSearch';
import { isEmptyObject, safeGet } from './object';
import { symptomsSubmit } from '../../actions/symptoms';
import history from '../history';
import { DEFAULT_LOCATION_DALLAS, DEFAULT_LOCATION_DETROIT } from '../../config';
import { getQueryParams } from './url';
import { setUserLocation } from '../../actions/searchPreferences';
import {
  LOCATION_SOURCE_DEFAULT,
  LOCATION_SOURCE_USER,
  PATIENT_TYPE_KIDS,
  PATIENT_TYPE_OPTIONS,
} from '../../constants/index';
import { isEmptyString } from './string';
import { providerTypes, URGENT_CARE_VALUE } from '../../components/Home/Tiles/ProviderTypes';
import { isClientSide } from './system';
import { decodeReasonForVisit, getDefaultReasonForVisitForProviderType } from './symptoms';
import { COVID_SYMPTOMS } from '../../constants/covid';
import {
  isCovidTestRelatedSearch,
  isCovidTestServiceSearch,
} from '../../components/Search/components/util/search';
import { COVID_SERVICE_IDS } from '../../constants/services';
import { isEmpty } from './empty';
import { SearchLocation, VisitMode } from '~/reducers/searchPreferences';
import { STATE_CODE_TO_LABEL } from '~/constants';

export const SEARCH_TYPE_PARTNERS = 'partners';
export const SEARCH_TYPE_NON_PARTNERS = 'nonPartners';
const DEFAULT_LOCATION_QUERY_STRING = `${DEFAULT_LOCATION_DALLAS.latitude},${DEFAULT_LOCATION_DALLAS.longitude},Dallas,TX`;

export const isPartnerSearch = (type: any) => type === SEARCH_TYPE_PARTNERS;
export const isNonPartnerSearch = (type: any) => type === SEARCH_TYPE_NON_PARTNERS;

const getFilters = (state: any) => {
  const safeState = safeGet(state);
  const patientType = safeState('newBooking.booking.patientType');
  const searchLocationPreferences = safeState('searchPreferences.location');
  const appointmentTime = safeState('newBooking.booking.requestedAppointmentTime');
  const visitMode = safeState('searchPreferences.filterGroups.mode');

  // Remove categories and services on telemed
  const isTelemed = visitMode === VisitMode.Telemed;
  const locationCategories = isTelemed
    ? []
    : safeState('searchPreferences.filterGroups.moreFilters.locationCategories');
  const requestedServices = isTelemed
    ? []
    : safeState('searchPreferences.filterGroups.moreFilters.requestedServices');

  const isServiceHardFilter = safeState('searchPreferences.isServiceHardFilter') || false;
  const isCovidTestRelatedSearch = safeState('searchPreferences.isCovidTestRelatedSearch') || false;
  const reasonForVisit =
    safeState('newBooking.booking.reasonForVisit') ||
    safeState('newBooking.lastCreatedBookingInfo.reasonForVisit');
  const providerType = safeState('searchPreferences.providerType');
  const category = safeState('searchPreferences.category');
  const isInMarketPlaceSearch = safeState('searchPreferences.isInMarketPlaceSearch');
  const searchPreferencesPagination = safeState('searchPreferences.pagination');
  const partnersResultPagination = safeState('searchResults.partnersPagination');
  const nonPartnersResultPagination = safeState('searchResults.nonPartnersPagination');
  const resultsPagination = {
    partnersResultPagination,
    nonPartnersResultPagination,
  };
  const requestedInsurance = safeState('searchPreferences.requestedInsurance');
  const covidCostType = safeState('searchPreferences.covidCostType');
  const sortOrder = safeState('searchPreferences.filterGroups.sortOrder');
  const solvRatingMinimum = safeState(
    'searchPreferences.filterGroups.moreFilters.solvRatingMinimum'
  );
  const acceptedInsurance = safeState('searchPreferences.filterGroups.acceptedInsurance');

  return {
    patientType: typeof patientType === 'object' ? patientType : { value: patientType },
    searchLocationPreferences,
    appointmentTime,
    reasonForVisit,
    providerType,
    category,
    visitMode,
    isInMarketPlaceSearch,
    searchPreferencesPagination,
    requestedServices,
    resultsPagination,
    requestedInsurance,
    isServiceHardFilter,
    isCovidTestRelatedSearch,
    covidCostType,
    sortOrder,
    solvRatingMinimum,
    locationCategories,
    acceptedInsurance,
  };
};

function* getSearchDataFromState() {
  return yield select(getFilters);
}

/**
 * Get a label from search preferences or position state.
 * Search preferences takes priority over position
 *
 * @param {Object} searchPreferences
 * @param {Object} position
 * @param {string} defaultLabel
 * @returns {string}
 */
export const getLocationLabelFromState = ({
  searchPreferences,
  position,
  defaultLabel = '',
}: any) =>
  (searchPreferences && searchPreferences.location && searchPreferences.location.label) ||
  (position && position.result && position.result.label) ||
  defaultLabel;

const getLatLongFromLocationPreferences = (searchLocationPreferences: any) => {
  if (
    safeGet(searchLocationPreferences)('latitude') &&
    safeGet(searchLocationPreferences)('longitude')
  ) {
    const label = safeGet(searchLocationPreferences)('label') || '';
    return `${searchLocationPreferences.latitude},${searchLocationPreferences.longitude},${label}`;
  }

  return DEFAULT_LOCATION_QUERY_STRING;
};

const buildLocationLabel = (city: any, state: any) => {
  let label = !isEmptyString(city) ? city : '';
  if (!isEmptyString(state)) {
    label += `,${state}`;
  }

  return decodeURIComponent(label);
};

function setSearchDataInQueryString({
  searchLocationPreferences,
  patientType,
  appointmentTime,
  requestedServices,
  reasonForVisit,
  providerType,
  category,
  isServiceHardFilter,
  isCovidTestRelatedSearch,
}: any) {
  const searchParams = [
    `l=${getLatLongFromLocationPreferences(searchLocationPreferences)}`,
    `patientType=${typeof patientType === 'object' ? patientType.value : patientType}`,
    `appointmentTime=${appointmentTime ? appointmentTime.value : new Date().valueOf()}`,
    `reasonForVisit=${reasonForVisit || getDefaultReasonForVisitForProviderType(providerType)}`,
    `providerType=${providerType || URGENT_CARE_VALUE}`,
    `category=${category}`,
    `isCovidTestRelatedSearch=${isCovidTestRelatedSearch || false}`,
    `shf=${isServiceHardFilter || false}`,
  ];
  requestedServices && searchParams.push(`requestedServices=${requestedServices.join()}`);
  searchLocationPreferences.cobrandedSrpLocation &&
    searchParams.push(`cobrandedSrpLocation=${searchLocationPreferences.cobrandedSrpLocation}`);
  const redirectUrl = `/search?${searchParams.join('&')}`;
  history.replace(redirectUrl);
}

export const getProviderTypeFromUrl = () => {
  if (isClientSide()) {
    return safeGet(getQueryParams(), URGENT_CARE_VALUE)('providerType');
  }

  return URGENT_CARE_VALUE;
};

export const isUrgentCareSearch = (providerType: any) =>
  !providerType || (providerType && providerType === URGENT_CARE_VALUE);

export const isTraditionalSearch = (providerType: any) =>
  providerType &&
  !isUrgentCareSearch(providerType) &&
  providerTypes.some((provider) => provider.value.toLowerCase() === providerType.toLowerCase());

export const getSearchDataFromQueryString = () => {
  if (typeof window === 'undefined') return {};

  const searchParams = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      (acc, param) => Object.assign(acc, { [param.split('=')[0]]: param.split('=')[1] }),
      {} as any
    );

  const patientType = searchParams.patientType || '';
  const providerType = searchParams.providerType || URGENT_CARE_VALUE;
  const category = decodeURIComponent(searchParams.category) || '';
  const appointmentTime = searchParams.appointmentTime || '';
  const requestedServices = searchParams.requestedServices
    ? searchParams.requestedServices.split(',')
    : '';
  const isCovidTestRelatedSearch = searchParams.isCovidTestRelatedSearch || false;
  const visitMode = searchParams.visitMode || VisitMode.All;
  const isServiceHardFilter = searchParams.shf || false;
  const reasonForVisit = searchParams.reasonForVisit || '';
  const requestedInsurance = searchParams.requestedInsurance || '';

  const searchPreferences = {
    patientType: { value: patientType },
    providerType,
    category,
    appointmentTime: {
      value: appointmentTime && parseInt(appointmentTime, 10),
    },
    isServiceHardFilter,
    reasonForVisit,
    requestedInsurance,
    requestedServices,
    isCovidTestRelatedSearch,
    visitMode,
  };

  const locationString = searchParams.l;
  if (!isEmptyString(locationString)) {
    let [latitude, longitude, city, state] = locationString.split(',');
    latitude = latitude && parseFloat(latitude);
    longitude = longitude && parseFloat(longitude);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchLocationPreferences' does not exis... Remove this comment to see the full error message
    searchPreferences.searchLocationPreferences = {
      latitude,
      longitude,
      source: LOCATION_SOURCE_USER,
      label: buildLocationLabel(city, state),
    };
  }

  if (searchParams.cobrandedSrpLocation) {
    const location = searchParams.cobrandedSrpLocation;
    if (location === 'MI') {
      const { state } = DEFAULT_LOCATION_DETROIT;
      const { city } = DEFAULT_LOCATION_DETROIT;

      /* Note that this object gets merged with the query search data in getSearchData(),
       creating a searchLocationPreferences with the IP detected locations lat/long & label,
       overriding these values. This does not seem intentional */
      (searchPreferences as any).searchLocationPreferences = {
        label: buildLocationLabel(city, state),
        cobrandedSrpLocation: location,
        latitude: DEFAULT_LOCATION_DETROIT.latitude,
        longitude: DEFAULT_LOCATION_DETROIT.longitude,
        state,
        city,
      };
      searchPreferences.requestedServices = COVID_SERVICE_IDS;
      searchPreferences.isServiceHardFilter = true;
      searchPreferences.isCovidTestRelatedSearch = true;
    }
  }
  return searchPreferences;
};

// TODO Ben: update all instances of SET_USER_LOCATION to include state code so we always have
// have access to state code in searchPreferences and remove getStateCodeFromLabel.
export const getStateCodeFromLabel = ({ label, state_code: stateCode }: SearchLocation) => {
  if (label && label.split(',').length > 1) {
    return label.split(',').pop()?.trim();
  }
  if (stateCode) {
    return stateCode;
  }
  return null;
};

export const isStateCodeAbbreviation = (stateCode: string): boolean =>
  Object.keys(STATE_CODE_TO_LABEL).includes(stateCode.toLowerCase());

export const shouldIncludeCovidTestingOnlyLocationsInResults = (
  requestedServices = [],
  reasonForVisit = ''
) =>
  COVID_SYMPTOMS.includes(reasonForVisit.toLowerCase()) ||
  isCovidTestServiceSearch({ requestedServices }) ||
  isCovidTestRelatedSearch({ requestedServices });

export const shouldExcludeServiceSearchesOnlyLocationsInResults = (requestedServices = []) =>
  isEmpty(requestedServices);

function* setSearchDataInState(searchData: any) {
  yield put(
    symptomsSubmit({
      ...searchData,
      symptoms: decodeReasonForVisit(searchData.reasonForVisit),
    })
  );
  yield put(setDisplayQuery(decodeReasonForVisit(searchData.reasonForVisit)));
  yield put(setUserLocation(searchData.searchLocationPreferences));
}

export function* getSearchData() {
  let searchData;
  const stateSearchData = yield* getSearchDataFromState();
  const querySearchData = getSearchDataFromQueryString();

  /** Spot fix to preserve the query param setting for patient type
   * Without this, stateSearchData's default value of the adult type will overwrite
   * any query specified type.
   *
   * It may be wise to refactor this function to piecewise select the correct version of
   * each property from each state instead of simply merging them. */
  let { patientType } = stateSearchData;

  if ((querySearchData as any).patientType?.value === PATIENT_TYPE_KIDS) {
    // eslint-disable-next-line prefer-destructuring
    patientType = PATIENT_TYPE_OPTIONS[1];
  }

  const mergedSearchData = merge(querySearchData, stateSearchData);

  mergedSearchData.patientType = patientType;

  if (!isEmptyObject(stateSearchData.searchLocationPreferences) && stateSearchData.patientType) {
    // if state contains both searchPreferences.location and patientType use data in state to search
    searchData = stateSearchData;
    setSearchDataInQueryString(searchData);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchLocationPreferences' does not exis... Remove this comment to see the full error message
  } else if (isEmptyObject(querySearchData.searchLocationPreferences)) {
    // if query string does not contain location data, try to replenish it from position
    // or just default to dallas, TX
    let searchLocationPreferences = {
      latitude: DEFAULT_LOCATION_DALLAS.latitude,
      longitude: DEFAULT_LOCATION_DALLAS.longitude,
      source: LOCATION_SOURCE_DEFAULT,
      label: 'Dallas, TX',
    };

    const position = yield select((state) => safeGet(state)('position.result'));
    if (position) {
      searchLocationPreferences = {
        latitude: position.latitude,
        longitude: position.longitude,
        source: position.source,
        label: position.label,
      };
    }

    searchData = { ...mergedSearchData, searchLocationPreferences };
    yield* setSearchDataInState(searchData);
    setSearchDataInQueryString(searchData);
  } else {
    searchData = mergedSearchData;
    yield* setSearchDataInState(searchData);
  }

  return searchData;
}

export const getRequestedPaginationForSearch = (
  resultsType: any,
  pagination: any,
  resultsPagination: any,
  isFetchingMoreResultsForSameSearch: any
) => {
  let requestedPagination = pagination;
  if (!isFetchingMoreResultsForSameSearch) {
    return pagination;
  }

  if (isPartnerSearch(resultsType)) {
    const partnerResultPagination = resultsPagination.partnersResultPagination;
    const { limit } = partnerResultPagination;
    const { page } = partnerResultPagination;
    const totalResults = partnerResultPagination.results_count;
    if (limit * page < totalResults) {
      // increasing page
      requestedPagination = {
        ...requestedPagination,
        partnersPage: requestedPagination.partnersPage + 1,
      };
    }
  } else {
    const nonPartnerResultPagination = resultsPagination.nonPartnersResultPagination;
    const { limit } = nonPartnerResultPagination;
    const { page } = nonPartnerResultPagination;
    const totalResults = nonPartnerResultPagination.results_count;
    if (limit * page < totalResults) {
      // increasing page
      requestedPagination = {
        ...requestedPagination,
        nonPartnersPage: requestedPagination.nonPartnersPage + 1,
      };
    }
  }

  return requestedPagination;
};
