import * as Sentry from '@sentry/browser';

export const LOG_LEVEL_DEBUG = 1;
export const LOG_LEVEL_INFO = 2;
export const LOG_LEVEL_WARN = 4;
export const LOG_LEVEL_ERROR = 8;

let logLevel = LOG_LEVEL_DEBUG;

const doLog = (message: any) => console.dir(message); // eslint-disable-line no-console

const logger = {
  debug: (message: any) => {
    if (logLevel <= LOG_LEVEL_DEBUG) {
      doLog(message);
    }
  },

  info: (message: any) => {
    if (logLevel <= LOG_LEVEL_INFO) {
      doLog(message);
    }
  },

  warn: (message: any) => {
    if (logLevel <= LOG_LEVEL_WARN) {
      doLog(message);
    }
  },

  /** Note that you *must* pass an Error object to this function. Previously we allowed error message strings.
   * But, this leads to Sentry not capturing the relevant Stack trace.*/
  error: (error: Error, ctx?: Parameters<typeof Sentry['captureException']>[1]) => {
    // This conditional will always evaluate "true" as LOG_LEVEL_ERROR is the highest possible value for
    // for logLevel
    if (logLevel <= LOG_LEVEL_ERROR) {
      console.error(error);
      if (ctx) {
        console.error('ctx:', ctx);
      }

      Sentry.captureException(error, ctx);
    }
  },

  setLevel: (
    level:
      | typeof LOG_LEVEL_DEBUG
      | typeof LOG_LEVEL_INFO
      | typeof LOG_LEVEL_WARN
      | typeof LOG_LEVEL_ERROR
  ) => {
    logLevel = level;
  },
};

export default logger;
