import { CHARGES_RECEIVED, CHARGES_ERROR, CHARGES_SUBMITTING } from '../constants/index';

export default (state = {}, action: any) => {
  switch (action.type) {
    case CHARGES_RECEIVED:
      return {
        ...state,
        charge: action.payload.value,
        submitting: false,
      };
    case CHARGES_ERROR:
      return {
        ...state,
        error: action.payload.value,
        submitting: false,
      };
    case CHARGES_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    default:
      return state;
  }
};
