import {
  GUARDIAN_FORM_FIELD_NAMES,
  PATIENT_FORM_PAGE_ONE_FIELD_NAMES,
  PATIENT_FORM_PAGE_TWO_FIELD_NAMES,
} from '../../paperworkConstants';
import { SectionPageCollection } from '../../interfaces';

export enum PatientPageNumber {
  BasicInfo,
  Address,
  GuardianInfo,
}

export const PATIENT_PAGES: SectionPageCollection = new Map([
  [
    PatientPageNumber.BasicInfo,
    {
      fieldNames: PATIENT_FORM_PAGE_ONE_FIELD_NAMES,
    },
  ],
  [
    PatientPageNumber.Address,
    {
      fieldNames: PATIENT_FORM_PAGE_TWO_FIELD_NAMES,
    },
  ],
  [
    PatientPageNumber.GuardianInfo,
    {
      fieldNames: GUARDIAN_FORM_FIELD_NAMES,
    },
  ],
]);
