export const SUPPORTED_URGENT_CARE_SERVICE_LIST = [
  'dot-physicals',
  'flu-shot',
  'physical-exam',
  'sinus-infection-treatment',
  'sports-physicals',
  'std-test',
  'chickenpox-vaccine',
  'hepatitis-vaccine',
  'measles-vaccine-mmr',
  'tetanus-vaccine',
  'typhoid-vaccine',
  'yellow-fever-vaccine',
  'allergy-testing',
  'ear-wax-removal',
  'eye-exam',
  'pink-eye',
  'well-woman-exam',
  'x-ray',
];

export const CATEGORY_VACCINATIONS = 'Vaccinations';
export const CATEGORY_LABS_AND_TESTS = 'Labs and Tests';
