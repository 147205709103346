import { DAPI_HOST } from '../../config/index';

const getUserRemindersUrl = (userProfileId: any) =>
  `${DAPI_HOST}/v1/user-profiles/${userProfileId}/reminders`;

const createReminderUrl = () => `${DAPI_HOST}/v2/reminders`;

const updateReminderUrl = (reminderId: any) => `${DAPI_HOST}/v1/reminders/${reminderId}`;

const deleteReminderUrl = (reminderId: any) => `${DAPI_HOST}/v1/reminders/${reminderId}`;

export { getUserRemindersUrl, createReminderUrl, updateReminderUrl, deleteReminderUrl };
