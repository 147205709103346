import mapValues from 'lodash/mapValues';
import { REMIX_APP_URL } from '../config/index';

function remixUrl(path: string) {
  return `${REMIX_APP_URL}${path}`;
}

export type RemixAppointmentTime =
  | 'asap'
  | 'today-morning'
  | 'today-afternoon'
  | 'today-evening'
  | 'tomorrow-morning'
  | 'tomorrow-afternoon'
  | 'tomorrow-evening';

export type RemixSrpUrlParams = {
  lat: number | string;
  lng: number | string;
  location: string;
  state: string;
  modality: 'in-person' | 'telemed';
  query: string;
  serviceIds: string;
  covidRelated: boolean;
  hardFilter: boolean;
  patient: 'adult' | 'child';
  time: RemixAppointmentTime;
  minRating: '3' | '4' | 'any';
  sort: 'distance' | 'rating' | 'relevance' | 'availability';
};

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

function omitEmpty<T extends {}>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (typeof value === 'undefined' || value === null) {
        return false;
      }

      if (typeof value === 'boolean' && value === false) {
        return false;
      }

      if (typeof value === 'string' && value.trim() === '') {
        return false;
      }

      return true;
    })
  ) as Partial<T>;
}

export const RemixRoutes = {
  triage: remixUrl('/triage'),
  search: {
    base: remixUrl('/r/search'),
    controlled(params: Nullable<Partial<RemixSrpUrlParams>>) {
      const newUrl = new URL(remixUrl('/r/search'));
      const searchParams = new URLSearchParams(
        mapValues(omitEmpty(params), (value) => value?.toString()) as Record<string, string>
      ).toString();

      newUrl.search = searchParams.toString();
      return newUrl.toString();
    },
  },
};
