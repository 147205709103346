import React, { PureComponent, Suspense, lazy, Fragment } from 'react';
import { connect } from 'react-redux';
import { accountMarkMessagesRead } from '../../actions/account';
import { setRuntimeVariable } from '../../actions/runtime';
import { getAccountId } from '../../core/auth';
import { apiPost } from '../../core/dapi';
import { getSmoochWebhookUrl } from '../../core/dapi/userProfile';
import withAuthentication from '../../core/login/withAuthentication';
import { safeGet } from '../../core/util/object';
import { isClientSide } from '../../core/util/system';

const LazySmooch = React.lazy(() => import('./Smooch'));

import WebPushOptInDialog from '../WebPush/OptInDialog';
import { PUSH_OPT_IN_ORIGIN_CHAT } from '../../constants/index';
import {
  canAskToEnablePush,
  hasAcceptedPush,
  handleAcceptIosPushPermission,
} from '../WebPush/util';
import { isNativeApp } from '../../core/util/device';

export const RUNTIME_CHAT_ACTIVATED = 'chatActivated';

const mapStateToProps = (state: any) => ({
  accountSummary: state.account.summary,
  chatActivated: state.runtime[RUNTIME_CHAT_ACTIVATED],
});

const mapDispatchToProps = (dispatch: any) => ({
  closeChat: () => dispatch(setRuntimeVariable({ name: RUNTIME_CHAT_ACTIVATED, value: false })),

  markMessagesRead: () => {
    dispatch(accountMarkMessagesRead());

    const webHookUrl = getSmoochWebhookUrl();
    const payload = {
      trigger: 'mapp:conversation:read',
      account_id: getAccountId(),
    };
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    apiPost(webHookUrl, payload);
  },
});

type ChatLoaderProps = {
  isLoggedIn?: boolean;
  accountSummary?: any;
  chatActivated?: boolean;
  closeChat?: (...args: any[]) => any;
  markMessagesRead?: (...args: any[]) => any;
};

type ChatLoaderState = any;

class ChatLoader extends PureComponent<ChatLoaderProps, ChatLoaderState> {
  constructor(props: ChatLoaderProps) {
    super(props);
    this.state = { showOptInDialog: false };
  }

  onCloseChat = () => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.closeChat();
    this.openPushNotificationFlow();
    this.openOptInDialog();
  };

  iosAppPushNotificationsFlow = () => {
    this.closeOptInDialog();
    handleAcceptIosPushPermission(PUSH_OPT_IN_ORIGIN_CHAT);
  };

  openPushNotificationFlow = () =>
    isNativeApp() ? this.iosAppPushNotificationsFlow() : this.openOptInDialog();

  closeOptInDialog = () => this.setState({ showOptInDialog: false });

  openOptInDialog = () => this.setState({ showOptInDialog: true });

  loader = () => null;

  render = () => {
    const { isLoggedIn, accountSummary, chatActivated, markMessagesRead } = this.props;

    if (!isClientSide() || !isLoggedIn) {
      return null;
    }

    const accountId = safeGet(this.props)('accountSummary.id');

    return (
      <>
        {chatActivated && (
          <Suspense fallback={this.loader()}>
            <LazySmooch
              accountSummary={accountSummary}
              closeChat={this.onCloseChat}
              markMessagesRead={markMessagesRead}
            />
          </Suspense>
        )}

        {!hasAcceptedPush() && canAskToEnablePush() && accountId && (
          <WebPushOptInDialog
            accountId={accountId}
            handleAcceptCustomBehavior={this.closeOptInDialog}
            handleDenyCustomBehavior={this.closeOptInDialog}
            onClose={this.closeOptInDialog}
            open={this.state.showOptInDialog}
            webPushOptInOrigin={PUSH_OPT_IN_ORIGIN_CHAT}
          />
        )}
      </>
    );
  };
}

export default withAuthentication(connect(mapStateToProps, mapDispatchToProps)(ChatLoader));
