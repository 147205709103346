import moment from 'moment';
import {
  ACTION_REHYDRATE,
  RUNTIME_AJAX_ERROR,
  RUNTIME_AJAX_PENDING,
  RUNTIME_AJAX_SUCCESS,
  RUNTIME_MODAL,
  RUNTIME_SNACKBAR,
  SET_AJAX_ERROR,
  SET_AJAX_PENDING,
  SET_RUNTIME_VARIABLE,
} from '../constants/index';
import { PayloadAction } from './payload-action';

export interface RuntimeState extends Record<string, any> {
  viewport?: { width: number; height: number };
  ajax: Record<string, {}>;
  modal?: Record<string, boolean>;
  modalsShownInSession?: Record<string, boolean>;
  snackbar?: {
    open?: boolean;
    message?: string;
  };
  loginHeaderType?: string;
}

export enum ModalId {}

const defaultState: RuntimeState = {
  ajax: {},
  modal: {},
  modalsShownInSession: {},
  snackbar: undefined,
};

export default function runtime(state = defaultState, action: PayloadAction) {
  switch (action.type) {
    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case SET_AJAX_PENDING:
      return {
        ...state,
        ajaxError: null,
        ajaxPending: action.payload.value ? moment().valueOf() : false,
      };
    case SET_AJAX_ERROR:
      return {
        ...state,
        ajaxError: action.payload.value,
        ajaxPending: false,
      };
    case RUNTIME_MODAL: {
      const newState: RuntimeState = {
        ...state,
        modal: {
          ...state.modal,
        },
        modalsShownInSession: { ...state.modalsShownInSession },
      };

      if (newState.modal) {
        newState.modal[action.payload.modalId] = action.payload.value;
      }
      if (action.payload.value && newState.modalsShownInSession) {
        newState.modalsShownInSession[action.payload.modalId] = true;
      }
      return newState;
    }
    case RUNTIME_SNACKBAR: {
      const newState = {
        ...state,
        snackbar: {
          ...state.snackbar,
        },
      };
      newState.snackbar.open = action.payload.open;
      newState.snackbar.message = action.payload.message;
      return newState;
    }

    case RUNTIME_AJAX_PENDING: {
      const newState = {
        ...state,
        ajax: {
          ...state.ajax,
        },
      };
      newState.ajax[action.payload.requestId] = { pending: true };
      return newState;
    }

    case RUNTIME_AJAX_SUCCESS: {
      const newState = {
        ...state,
        ajax: {
          ...state.ajax,
        },
      };
      newState.ajax[action.payload.requestId] = {
        success: true,
        result: action.payload.result,
      };
      return newState;
    }

    case RUNTIME_AJAX_ERROR: {
      const newState = {
        ...state,
        ajax: {
          ...state.ajax,
        },
      };
      newState.ajax[action.payload.requestId] = {
        pending: false,
        error: action.payload.result,
      };
      return newState;
    }

    case ACTION_REHYDRATE:
      return {
        ...state,
        persistentStateLoaded: true,
      };
    default:
      return state;
  }
}
