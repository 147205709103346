import { Action } from 'redux';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { apiPost } from '../core/dapi';
import {
  awsImagesPostUploadStatus,
  getImageUploadFilename,
  putToS3,
  s3SignedUrl,
} from '../core/dapi/cardUpload';
import { IMAGE_UPLOAD_STATUSES } from '../constants';
import { FILE_SIZE_THRESHOLD, JPEG_QUALITY } from '../routes/addInsurance/constants';
import ExifImageLoader, { DEFAULT_JPEG_QUALITY } from '../core/util/ExifImageLoader';
import { createBlob } from '../core/util/image';
import logger from '../core/logger';
import useSelectActiveUserProfile, {
  selectActiveUserProfile,
} from '~/hooks/useSelectActiveUserProfile';
import { UserProfile } from '~/core/dapi/models';

export enum ImagesSaga {
  Upload = 'SAGA/ImagesSaga/Upload',
  Copy = 'SAGA/ImagesSaga/Copy',
}

export enum ImageType {
  DentalInsuranceBack = 'back',
  DentalInsuranceFront = 'front',
  HealthInsuranceBack = 'back',
  HealthInsuranceFront = 'front',
  PhotoIdBack = 'photo_id_back',
  PhotoIdFront = 'photo_id_front',
}

export interface UploadImageSagaAction extends Action {
  imageFile: any;
  imageType: ImageType;
  onStart?: () => Action;
  onStop?: (error?: any) => Action;
  setImageData: ((data: string) => Action)[];
  setImageId: ((id: string) => Action)[];
  shouldReorient: boolean;
  userProfile?: UserProfile;
}

export interface CopyImageSagaAction extends Action {
  imageUrl: string;
  cardId: string;
  onStart?: () => Action;
  onStop?: (error?: any) => Action;
  setImageId: ((id: string) => Action)[];
  setImageData: ((data: string) => Action)[];
}

export function* uploadImageSaga({
  imageFile,
  imageType,
  onStart,
  onStop,
  setImageData,
  setImageId,
  shouldReorient,
  userProfile,
}: UploadImageSagaAction) {
  if (onStart) yield put(onStart());

  let fileBlob;

  if (shouldReorient) {
    const jpegQuality = imageFile.size > FILE_SIZE_THRESHOLD ? JPEG_QUALITY : DEFAULT_JPEG_QUALITY;

    const imageLoader = new ExifImageLoader(imageFile);
    const orientedImageFile = yield imageLoader.getDataURL(true, jpegQuality);

    for (let action of setImageData) {
      yield put(action(orientedImageFile));
    }
    fileBlob = createBlob(orientedImageFile);
  } else {
    for (let action of setImageData) {
      yield put(action(imageFile));
    }
    fileBlob = createBlob(imageFile);
  }

  const data: { image_type: any; user_profile_id?: string } = { image_type: imageType };

  if ([ImageType.PhotoIdBack, ImageType.PhotoIdFront].includes(imageType)) {
    if (userProfile) {
      data.user_profile_id = userProfile.user_profile_id;
    } else {
      const activeUserProfile: UserProfile | null = yield select(selectActiveUserProfile);
      if (activeUserProfile) data.user_profile_id = activeUserProfile.user_profile_id;
    }
  }

  // @ts-ignore
  const { aws_path: name, id: cardId } = yield call(apiPost, getImageUploadFilename(), data);

  for (let action of setImageId) {
    yield put(action(cardId));
  }

  try {
    const image = { name, image: fileBlob, type: 'image/jpg' };

    const { signedRequest } = yield call(s3SignedUrl, image);

    const response = yield call(putToS3, signedRequest, image);

    if (!response.ok) {
      //Catch won't 'catch' 404 or 500 errors; response.ok will be false if status is not within 200 - 299
      // @ts-ignore
      yield call(apiPost, awsImagesPostUploadStatus(cardId), IMAGE_UPLOAD_STATUSES.uploadFailed);
      throw Error('PutToS3 not successful');
    } else {
      // @ts-ignore
      yield call(apiPost, awsImagesPostUploadStatus(cardId), IMAGE_UPLOAD_STATUSES.uploaded);
    }
  } catch (e) {
    logger.error(e);
  } finally {
    if (onStop) yield put(onStop());
  }
}

export function* copyImageSaga({
  imageUrl,
  cardId,
  onStart,
  onStop,
  setImageId,
  setImageData,
}: CopyImageSagaAction) {
  if (onStart) yield put(onStart());
  for (let action of setImageData) {
    yield put(action(imageUrl));
  }
  for (let action of setImageId) {
    yield put(action(cardId));
  }
  if (onStop) yield put(onStop());
}

function* rootSaga() {
  // @ts-ignore
  yield takeEvery(ImagesSaga.Upload, uploadImageSaga);
  yield takeEvery(ImagesSaga.Copy, copyImageSaga);
}

export default rootSaga;
