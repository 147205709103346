import moment from 'moment-timezone';
import { useMemo } from 'react';
import { SupportedLocale } from '../../../core/util/localizedStr';

const createProxyHandler = (locale: SupportedLocale): ProxyHandler<typeof moment> => ({
  /**
   * @param target the target of the proxy (moment)
   * @param property the property of moment being retrieved, such as 'locale' or 'add' or 'format'
   * @param receiver the proxy around moment, or an object that inherits from that proxy
   */
  get(target, property, receiver) {
    let realValueToReturn: any;
    const preExistingLocale = target.locale();
    try {
      target.locale(locale);
      realValueToReturn = Reflect.get(target, property, receiver);
    } finally {
      target.locale(preExistingLocale);
    }

    if (typeof realValueToReturn !== 'function') {
      return realValueToReturn;
    }

    /**
     *
     */
    function wrappedFunction(this: any, ...args: any[]): any {
      const preExistingLocale = target.locale();
      try {
        target.locale(locale);
        const result = Reflect.apply(realValueToReturn, this, args);
        return result;
      } finally {
        target.locale(preExistingLocale);
      }
    }

    return wrappedFunction;
  },

  /**
   * @param target the target of the proxy (moment)
   * @param thisArg the "this" value of the invocation (almost certainly null, but whatever)
   * @param receiver the proxy around moment, or an object that inherits from that proxy
   */
  apply(target, thisArg, argumentsList) {
    const prevLocale = target.locale();
    try {
      target.locale(locale);
      const result = Reflect.apply(target, thisArg, argumentsList);
      return result;
    } finally {
      target.locale(prevLocale);
    }
  },
});

/**
 * We have at least one library (`'material-ui-pickers'`) that expects to have its locale at least
 * partially configured by setting the global locale of the `moment` library. We absolutely do not
 * want to set the global locale of `moment`, so this is a wrapper-utility that allows us to "lie"
 * to external libraries about the global locale of `moment`.
 *
 * In reality, what this proxy does is give the external library a real reference to `moment`,
 * but it wraps any and all uses of the library with calls to `moment.locale()`, setting the
 * locale to the specified value before the usage, and then resetting the locale to whatever it
 * was beforehand, after the usage.
 *
 * @param locale The locale we need to set via `moment.locale()`
 * @param momentInstance the global moment instance you want to wrap. `'moment-timezone'` by default
 * @returns a proxy around the moment instance
 */
export const momentWithLocale = (locale: SupportedLocale, momentInstance = moment): typeof moment =>
  new Proxy(momentInstance, createProxyHandler(locale));

export const useMomentWithLocale = (locale: SupportedLocale, momentInstance = moment) => {
  const localizedMomentInstance = useMemo(
    () => momentWithLocale(locale, momentInstance),
    [locale, momentInstance]
  );

  return localizedMomentInstance;
};
