import { isEmpty, omitBy } from 'lodash';
import { getServiceIdsFromDocId } from '~/components/Home/Tiles/Services';
import { RemixAppointmentTime, RemixRoutes, RemixSrpUrlParams } from '~/core/remix';
import { SolvReduxState } from '~/reducers';

function transformPatientType(patientType?: string | null) {
  if (patientType === 'kids') {
    return 'child';
  }
  if (patientType === 'adults') {
    return 'adult';
  }
  return undefined;
}

/**
 * Transform appointment time to a string that can be used in the URL
 * If the time is within 2 hours of now, return 'asap'
 * If the time is today, return 'today-morning', 'today-afternoon', or 'today-evening'.
 * If the time is tomorrow, return 'tomorrow-morning', 'tomorrow-afternoon', or 'tomorrow-evening'.
 * Morning is defined as 12am to 12pm, afternoon is 12pm to 5pm, and evening is 5pm to 12am.
 *
 * By default or if anything is wrong, return 'asap'.
 */
function transformAppointmentTime(timestamp?: string | number | null): RemixAppointmentTime {
  if (!timestamp) {
    return 'asap';
  }
  const time = new Date(timestamp);
  const now = new Date();
  const diff = time.getTime() - now.getTime();
  const hours = diff / (1000 * 60 * 60);
  if (hours < 2) {
    return 'asap';
  }
  if (time.getDate() === now.getDate()) {
    if (time.getHours() < 12) {
      return 'today-morning';
    }
    if (time.getHours() < 17) {
      return 'today-afternoon';
    }
    return 'today-evening';
  }
  if (time.getDate() === now.getDate() + 1) {
    if (time.getHours() < 12) {
      return 'tomorrow-morning';
    }
    if (time.getHours() < 17) {
      return 'tomorrow-afternoon';
    }
    return 'tomorrow-evening';
  }
  return 'asap';
}

function getRouteParamsFromUrlParams(searchParams: URLSearchParams): Partial<RemixSrpUrlParams> {
  const patient = transformPatientType(searchParams.get('patientType'));
  const appointmentTime = transformAppointmentTime(searchParams.get('appointmentTime'));
  const serviceIds = searchParams.get('requestedServices');
  const covidRelated = searchParams.get('isCovidTestRelatedSearch') === 'true';
  const modality: RemixSrpUrlParams['modality'] = searchParams.has('isTelemed')
    ? 'telemed'
    : 'in-person';
  const hardFilter = searchParams.get('isServiceHardFilter') === 'true';
  const query = searchParams.get('reasonForVisit');
  const location = searchParams.get('l');
  const split = location?.split(',');
  let [latitude, longitude, city, state] = split ?? [undefined, undefined, undefined, undefined];

  const hasLocation = latitude && longitude && city && state;
  const locationPayload: Partial<RemixSrpUrlParams> = hasLocation
    ? { lat: latitude, lng: longitude, location: `${city}, ${state}`, state }
    : {};

  return omitBy(
    {
      patient,
      query,
      serviceIds,
      covidRelated,
      time: appointmentTime,
      modality,
      hardFilter,
      ...locationPayload,
    },
    isEmpty
  );
}

function getRouteParamsFromState(state: SolvReduxState): Partial<RemixSrpUrlParams> {
  const { searchPreferences, newBooking, universalSearch } = state;
  const { selectedDoc } = universalSearch;

  const searchLocation = searchPreferences?.location;
  const hasLocation =
    searchLocation?.latitude &&
    searchLocation?.longitude &&
    searchLocation?.label &&
    searchLocation?.state_code;
  const locationPayload: Partial<RemixSrpUrlParams> = hasLocation
    ? {
        lat: searchLocation?.latitude,
        lng: searchLocation?.longitude,
        state: searchLocation?.state_code,
        location: searchLocation?.label,
      }
    : {
        location: searchLocation?.label,
      };

  const requestedTimeObjectOrString = newBooking?.booking?.requestedAppointmentTime;
  const requestedTime =
    typeof requestedTimeObjectOrString === 'string'
      ? requestedTimeObjectOrString
      : requestedTimeObjectOrString?.value;

  // mapp uses `all` while remix uses `any`
  const modality =
    searchPreferences?.filterGroups.mode === 'all' ? 'any' : searchPreferences?.filterGroups.mode;

  return omitBy(
    {
      covidRelated: selectedDoc?.is_covid_related ?? searchPreferences?.isCovidTestRelatedSearch,
      hardFilter: selectedDoc?.require_hard_filter ?? searchPreferences?.isServiceHardFilter,
      patient: transformPatientType(newBooking?.booking?.patientType?.value),
      query: selectedDoc?.name ?? newBooking?.booking?.reasonForVisit,
      time: transformAppointmentTime(requestedTime),
      serviceIds: selectedDoc?.id
        ? getServiceIdsFromDocId(selectedDoc.id)
        : searchPreferences?.filterGroups.moreFilters.requestedServices?.join(','),
      modality,
      ...locationPayload,
    },
    isEmpty
  );
}

export function getRemixSrpRoute(searchParams?: URLSearchParams, store?: SolvReduxState) {
  const paramsFromUrl = searchParams ? getRouteParamsFromUrlParams(searchParams) : {};
  const paramsFromState = store ? getRouteParamsFromState(store) : {};

  const newUrl = RemixRoutes.search.controlled({
    ...paramsFromState,
    ...paramsFromUrl, // url params take precedence over state params
  });

  return newUrl;
}
