import LocationInterface from '@solvhealth/types/interfaces/Location';

const SET_LOCATION = 'currentlySelectedLocation/SET_LOCATION';
const CLEAR_LOCATION = 'currentlySelectedLocation/CLEAR_LOCATION';

export const setCurrentlySelectedLocation = (value: LocationInterface) => ({
  type: SET_LOCATION,
  payload: { value },
});

export const clearCurrentlySelectedLocation = () => ({
  type: CLEAR_LOCATION,
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case SET_LOCATION:
      return action.payload.value;
    case CLEAR_LOCATION:
      return {};
    default:
      return state;
  }
};
