import {
  WAIT_LIST_BOOKING_RECEIVED,
  WAIT_LIST_BOOKING_NOT_VISIBLE,
  WAIT_LIST_BOOKING_ERROR,
  WAIT_LIST_RECEIVED,
  CLEAR_WAIT_LIST,
  WAIT_LIST_ERROR,
  INSURANCE_INFO_BY_BOOKING,
  INSURANCE_INFO_BY_BOOKING_ERROR,
  USER_PROFILE_BY_BOOKING,
  USER_PROFILE_BY_BOOKING_ERROR,
} from '../constants/index';

function receiveWaitListBooking(value: any) {
  return {
    type: WAIT_LIST_BOOKING_RECEIVED,
    payload: { value },
  };
}

function waitListBookingNotVisible() {
  return {
    type: WAIT_LIST_BOOKING_NOT_VISIBLE,
  };
}

function waitListBookingError(value: any) {
  return {
    type: WAIT_LIST_BOOKING_ERROR,
    payload: { value },
  };
}

function receiveWaitList(value: any) {
  return {
    type: WAIT_LIST_RECEIVED,
    payload: { value },
  };
}

function waitListError(value: any) {
  return {
    type: WAIT_LIST_ERROR,
    payload: { value },
  };
}

function clearWaitList() {
  return {
    type: CLEAR_WAIT_LIST,
  };
}

const receiveInsuranceInfoByBooking = (value: any) => ({
  type: INSURANCE_INFO_BY_BOOKING,
  payload: { value },
});

const receiveInsuranceInfoByBookingError = (value: any) => ({
  type: INSURANCE_INFO_BY_BOOKING_ERROR,
  payload: { value },
});

const receiveUserProfileByBooking = (value: any) => ({
  type: USER_PROFILE_BY_BOOKING,
  payload: { value },
});

const receiveUserProfileByBookingError = (value: any) => ({
  type: USER_PROFILE_BY_BOOKING_ERROR,
  payload: { value },
});

export {
  receiveWaitListBooking,
  waitListBookingNotVisible,
  waitListBookingError,
  receiveWaitList,
  waitListError,
  clearWaitList,
  receiveInsuranceInfoByBooking,
  receiveInsuranceInfoByBookingError,
  receiveUserProfileByBooking,
  receiveUserProfileByBookingError,
};
