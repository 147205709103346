/* eslint-disable max-len */
/* jscs:disable maximumLineLength */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Text } from '@solvhealth/jigsaw';
import { Trans, useTranslation } from 'react-i18next';
import { fontSize } from '../../constants/text';
import TosLinks from './TosLinks';
import { analyticsTrackEvent } from '~/core/analytics';
import { TOS_MODAL } from '~/core/analytics/events';
import { TermsOfService, PrivacyPolicy } from '../LegalLinks';

type Props = {
  accountId?: string;
  title?: string;
  clearLogin: () => void;
  setTosAccepted: (accountId?: string) => void;
};

const TosUpdate = ({ accountId, title, clearLogin, setTosAccepted }: Props) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    analyticsTrackEvent(TOS_MODAL, { action: 'Load Modal' });
  }, []);

  return (
    <Modal
      hideCloseButton
      onClose={() => {}}
      open
      primaryCta={{
        text: 'Agree',
        disabled: submitting,
        onClick: () => {
          analyticsTrackEvent(TOS_MODAL, { action: 'Tap Agree' });
          setTosAccepted(accountId);
          setSubmitting(true);
        },
      }}
      secondaryCta={{
        text: 'Cancel',
        onClick: () => {
          analyticsTrackEvent(TOS_MODAL, { action: 'Tap Cancel' });
          clearLogin();
        },
      }}
      title={title ?? 'Our Terms of Service and Privacy Policy have changed'}
      variant="short"
    >
      <Text as="div" variant="body">
        <Trans i18nKey="haveReadUnderstandTOSPP" t={t}>
          <TermsOfService />
          <PrivacyPolicy />
        </Trans>
      </Text>
    </Modal>
  );
};

export default TosUpdate;
