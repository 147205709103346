import { DAPI_HOST } from '../../config/index';
import { BookingStatus, UPCOMING_BOOKING_STATUSES } from '../../constants/index';
import { queryStringFromObject } from '../util/string';
import { apiPatch } from '~/core/dapi/index';

const getCreateAccountUrl = () => `${DAPI_HOST}/v1/accounts/`;

const getAccountById = (accountId: any) => `${DAPI_HOST}/v1/accounts/${accountId}`;

const isMixpanelAliasedUrl = (accountId: any) =>
  `${DAPI_HOST}/v1/accounts/${accountId}/is-mixpanel-aliased`;

const getSaveLocationUrl = (accountId: any) =>
  `${DAPI_HOST}/v1/accounts/${accountId}/save-for-later`;

const getAccountSummaryById = (accountId: any, activeOnly = true) =>
  `${DAPI_HOST}/v1/accounts/${accountId}/summary?active_only=${activeOnly}&valid_for_booking=true`;

const addNewFavoriteClinic = () => `${DAPI_HOST}/v1/user-profile-location-favorites/`;

const deleteFavoriteClinic = (userProfileLocationFavoriteId: any) =>
  `${DAPI_HOST}/v1/user-profile-location-favorites/${userProfileLocationFavoriteId}`;

const getAppointmentsForUserProfileUrl = (userProfileId: any) =>
  `${DAPI_HOST}/v1/bookings/?user_profile_id=${userProfileId}&limit=100&filters=bookings.status:${
    BookingStatus.DISCHARGED
  },${UPCOMING_BOOKING_STATUSES.join(',')}&order_by=appointment_date%20ASC`;

const buildDapiAccountObject = (form: any) => {
  const account: { first_name?: string; last_name?: string; email?: string } = {};
  for (const field in form) {
    if (form.hasOwnProperty(field)) {
      switch (field) {
        case 'firstName':
          account.first_name = form.firstName;
          break;
        case 'lastName':
          account.last_name = form.lastName;
          break;
        case 'email':
          account.email = form.email;
          break;
        default:
          break;
      }
    }
  }

  return account;
};

const getVerificationStatus = (phone: any, bookingId: any) => {
  const params = { phone };
  if (bookingId) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking_id' does not exist on type '{ ph... Remove this comment to see the full error message
    params.booking_id = bookingId;
  }

  return `${DAPI_HOST}/v1/accounts/verification_status?${queryStringFromObject(params)}`;
};

const ACCOUNT_EXISTS_ERROR_DESCRIPTION = 'Account already exists for the phone number provided.';

const setPinkDoorTosAccepted = (accountId: string) =>
  `${DAPI_HOST}/v1/accounts/${accountId}/solv-tos-accepted`;

const setHasSMSConsent = async (accountId: string, hasSMSConsent: boolean) => {
  const response = await apiPatch(getAccountById(accountId), { sms_consent: hasSMSConsent }, {});
  return response.sms_consent;
};

export {
  getAccountById,
  getCreateAccountUrl,
  addNewFavoriteClinic,
  deleteFavoriteClinic,
  buildDapiAccountObject,
  getAccountSummaryById,
  getAppointmentsForUserProfileUrl,
  getSaveLocationUrl,
  isMixpanelAliasedUrl,
  getVerificationStatus,
  setPinkDoorTosAccepted,
  setHasSMSConsent,
  ACCOUNT_EXISTS_ERROR_DESCRIPTION,
};
