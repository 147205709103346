/**
 * @param {Array} list
 */

// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/string-score` if it exists... Remove this comment to see the full error message
import score from 'string-score';

export const toSuggestionsFormat = (list = []) =>
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'display_name' does not exist on type 'ne... Remove this comment to see the full error message
  list.map((item) => ({ label: item.display_name }));

/**
 * Convert an insurer name, e.g. "UnitedHealthcare", into a code, e.g. "united_healthcare"
 *
 * @param {Array} allSuggestions
 * @param {string} name
 * @returns {string}
 */
export const getInsurerCodeFromName = (allSuggestions: any, name: any) => {
  for (let i = 0; i < allSuggestions.length; i++) {
    if (allSuggestions[i].name === name) {
      return allSuggestions[i].insurer_code;
    }
  }

  return '';
};

/**
 * Get suggested insurers based on input value
 *
 * @param {string} value
 * @param {Array} allSuggestions
 * @returns {Array}
 */
export const insurerSuggestionFilter = (value: any, allSuggestions: any) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const words = inputValue.split(/\s+/);
  const toReturn =
    inputLength === 0
      ? []
      : allSuggestions.filter((insurer: any) => {
          for (const word of words) {
            if (insurer.name.toLowerCase().indexOf(word) === -1) {
              return false;
            }
          }

          return true;
        });

  const displayNameComparator = (a: any, b: any) => {
    if (score(a.display_name, inputValue, 1) > score(b.display_name, inputValue, 1)) {
      return -1;
    }
    if (score(a.display_name, inputValue, 1) < score(b.display_name, inputValue, 1)) {
      return 1;
    }
    return 0;
  };

  const sorted = toReturn.sort(displayNameComparator);

  return sorted.length === 0 ? [] : toSuggestionsFormat(sorted.splice(0, 10));
};
