import {
  EMAIL_FORM_SUBMIT_SUCCESS,
  EMAIL_FORM_SUBMIT_FAILURE,
  EMAIL_FORM_SUBMITTED,
} from '../constants/index';

export default function outsideServiceAreaReducer(state = {}, action: any) {
  switch (action.type) {
    case EMAIL_FORM_SUBMITTED:
      return {
        ...state,
        emailSubmitted: true,
      };
    case EMAIL_FORM_SUBMIT_SUCCESS:
      return {
        submitSuccess: true,
      };
    case EMAIL_FORM_SUBMIT_FAILURE:
      return {
        submitSuccess: false,
      };
    default:
      return state;
  }
}
