import { call, put, select, takeLatest } from 'redux-saga/effects';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import sanitizeHtml from 'sanitize-html';
import { apiPostJson } from '../core/dapi';
import {
  receiveUniversalResults,
  setActiveQuery,
  setUniversalSearchDoc,
  UniversalSearchState,
} from '../ducks/universalSearch';
import history from '../core/history';
import { locationResponseFormatter } from '~/reducers/formatters/location';
import { hideModal, showModal } from '../actions/runtime';
import { USER_CHOICE_MODAL_ID } from '../components/UniversalSearch/UserChoice';
import {
  IMPRESSION_TYPE_BLUR_INTENT,
  IMPRESSION_TYPE_REDIRECT_TO_CDP,
  IMPRESSION_TYPE_SEARCH_INTENT,
  IMPRESSION_TYPE_SELECT_SUGGESTION,
  trackSearchIntentImpression,
  trackUniversalSearchLocation,
} from '../core/tracking/impression/util';
import { isEmpty } from '../core/util/empty';
import { safeGet } from '../core/util/object';
import { symptomsSubmit } from '../actions/symptoms';
import {
  createLocationUrlFromLocation,
  createProviderGroupLocationUrlFromLocation,
} from '../core/url/location';
import { PATIENT_TYPE_ADULTS, PATIENT_TYPE_OPTIONS } from '../constants/index';
import { PLATFORM_TYPE_PROVIDER_GROUP } from '../constants';
import {
  ProviderListSortTypes,
  ValidProviderGroupQueryParamKeys,
} from '../components/ProviderGroup/constants';
import { getDefaultReasonForVisitForProviderType } from '../core/util/symptoms';
import { setUserLocation } from '../actions/searchPreferences';
import { isEmptyString } from '../core/util/string';
import {
  URGENT_CARE_DISPLAY_NAME,
  URGENT_CARE_VALUE,
} from '../components/Home/Tiles/ProviderTypes';
import { FETCH_RESULTS } from './search';
import { getLocationLabel } from '../components/UniversalSearch/util';
import { UNIVERSAL_SEARCH } from '../core/analytics/events';
import { analyticsTrackEvent } from '../core/analytics';
import ProxyTypes from '../core/proxy/ProxyTypes';
import { FLU_SERVICE_IDS } from '~/constants/services';
import { getServiceIdsFromDocId } from '~/components/Home/Tiles/Services';
import { RemixRoutes } from '~/core/remix';
import { SolvReduxState } from '~/reducers';
import position from '~/reducers/position';
import { getBooleanFlag } from '~/core/launch-darkly/flags';
import { getRemixSrpRoute } from '~/routes/search/remixSrp';

const FETCH_UNIVERSAL_SEARCH_SUGGESTIONS = 'sagas/UNIVERSAL_SEARCH_QUERY';
const REQUEST_UNIVERSAL_SEARCH = 'sagas/REQUEST_UNIVERSAL_SEARCH';
const TRACK_UNIVERSAL_SEARCH_IMPRESSION = 'sagas/TRACK_UNIVERSAL_SEARCH_IMPRESSION';
const USER_CHOICE_CONFIDENCE_THRESHOLD = 0.8;

function* fetchUniversalSearchQuery({
  query,
  shouldIncludeLabServiceQuery,
  callback,
  resultsCallback,
}: any) {
  try {
    // Prevent XSS attacks by removing all html tags
    const sanitizedQuery = sanitizeHtml(query, {
      allowedTags: [],
    });
    yield put(setActiveQuery(sanitizedQuery));
    if (!sanitizedQuery) return;

    const { searchPreferences } = yield select((s) => s);
    const response = yield call(
      apiPostJson,
      `/proxy/${ProxyTypes.AutoSuggest}`,
      { query: sanitizedQuery, shouldIncludeLabServiceQuery },
      false
    );
    const { results: unformattedResults, action } = response.data;
    if (!unformattedResults)
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
      throw new Error('Universal search query unable to fetch', sanitizedQuery);

    // TODO better mapping on when provider type should be displayed
    const results = unformattedResults.map((result: any) => ({
      primary: result.highlight,
      key: result.id,
      secondary: result.display_provider_type || '',
      fullDoc: result,
      onClick: () => callback(result),
    }));

    const locationsToTrackImpressions = results.map((result: any) => {
      if (result.fullDoc.type === 'location') {
        return result.fullDoc;
      }

      return {}; // SOLV-5485: pass {} if the object doesn't need to be tracked in order to maintain positions
    });

    const { login, newBooking, sessionTrackingProperties } = yield select((s) => s);
    const accountId = safeGet(login)('login.account_id');
    const userProfileId = safeGet(newBooking)('booking.userProfileId');
    const trackingProperties = { ...sessionTrackingProperties, accountId, userProfileId };
    trackUniversalSearchLocation(locationsToTrackImpressions, trackingProperties);

    yield put(receiveUniversalResults({ results, action, query: sanitizedQuery }));
    resultsCallback(results);
  } catch (e) {
    console.error(e);
  }
}

function* dispatchSearchFiltersFromDocSelection(doc: any) {
  const { searchPreferences, position, newBooking } = yield select((s) => s);
  const patientType = doc.patientType ?? newBooking?.booking?.patientType;

  switch (doc.type) {
    case 'symptom':
      yield put(
        symptomsSubmit({
          providerType: doc.provider_type,
          patientType: patientType || PATIENT_TYPE_OPTIONS[0],
          requestedServices: [],
          symptoms: doc.name,
          isServiceHardFilter: doc.require_hard_filter,
          isCovidTestRelatedSearch: doc.is_covid_related,
          isFluRelatedSearch: doc.is_flu_related ?? doc.name === 'Flu',
        })
      );
      if (!searchPreferences.location && position.result)
        yield put(setUserLocation(position.result));
      break;
    case 'service':
      yield put(
        symptomsSubmit({
          providerType: doc.provider_type,
          patientType: patientType || PATIENT_TYPE_OPTIONS[0],
          requestedServices: (getServiceIdsFromDocId(doc.id) || '').split(','),
          symptoms: doc.name,
          isServiceHardFilter: doc.require_hard_filter,
          isCovidTestRelatedSearch: doc.is_covid_related,
          isFluRelatedSearch: FLU_SERVICE_IDS.includes(getServiceIdsFromDocId(doc.id)),
        })
      );
      if (!searchPreferences.location && position.result)
        yield put(setUserLocation(position.result));
      break;
    case 'free-text':
    case 'provider-type':
      yield put(
        symptomsSubmit({
          providerType: doc.provider_type,
          patientType: patientType || PATIENT_TYPE_OPTIONS[0],
          symptoms:
            doc.type === 'provider-type'
              ? getDefaultReasonForVisitForProviderType(doc.provider_type)
              : doc.highlight.replace(/<em>|<\/em>|"/g, ''),
          category: doc.category,
          isServiceHardFilter: doc.require_hard_filter,
          isCovidTestRelatedSearch: doc.is_covid_related,
          isFluRelatedSearch: doc.is_flu_related,
          requestedServices: [],
        })
      );
      if (!searchPreferences.location && position.result)
        yield put(setUserLocation(position.result));
      break;
    default:
      break;
  }
}

function* doTheSearch(fromSrp: any) {
  const remixSrp = getBooleanFlag('remixSrp');
  const searchParams = new URLSearchParams(window.location.search);
  const isCobrandedSrp = searchParams.has('cobrandedSrpLocation');

  if (remixSrp && !isCobrandedSrp && !fromSrp) {
    window.location.href = getRemixSrpRoute(
      undefined,
      (yield select((state) => state)) as SolvReduxState
    );
  } else {
    if (fromSrp) {
      yield put({ type: FETCH_RESULTS, fullReload: true });
    } else history.push('/search');
  }
}

function* trackSearchImpression({ fullDoc, isSelectionOnly = false, impressionType }: any) {
  const { universalSearch, searchPreferences, newBooking, position } = yield select((s) => s);
  const inMarket = searchPreferences.isInMarketPlaceSearch || false;
  const { results, action, query } = universalSearch;

  const patientType = safeGet(newBooking, PATIENT_TYPE_ADULTS)('booking.patientType.value');

  const providerType = safeGet(searchPreferences, URGENT_CARE_VALUE)('providerType');

  const searchLocationLabel = getLocationLabel(position, searchPreferences).inputValue;

  let searchLatLong;
  if (searchPreferences?.location?.latitude && searchPreferences?.location?.longitude) {
    searchLatLong = `${searchPreferences.location.latitude},${searchPreferences.location.longitude}`;
  } else if (position.result?.latitude && position.result?.longitude) {
    searchLatLong = `${position.result.latitude},${position.result.longitude}`;
  }

  const requestedAppointmentDatetime =
    safeGet(newBooking)('booking.requestedAppointmentTime.value') || new Date().valueOf();

  const getTrackingPropertiesFromState = (state: any) => {
    const { sessionTrackingProperties } = state;
    const accountId = safeGet(state)('login.login.account_id');
    const userProfileId = safeGet(state)('newBooking.booking.userProfileId');
    return {
      ...sessionTrackingProperties,
      accountId,
      userProfileId,
    };
  };

  const doc = fullDoc || universalSearch.selectedDoc;

  const trackingProperties = yield select(getTrackingPropertiesFromState);
  const trackingProps = {
    suggestionList: results.map((result: any) => result.fullDoc),
    suggestionAction: action,
    selectedDoc: doc,
    impressionType,
    requestedAppointmentDatetime,
    searchLocationLabel,
    trackingProperties,
    searchLatLong,
    providerType,
    patientType,
    inMarket,
    query,
  };

  if (isSelectionOnly) {
    Object.assign(trackingProps, {
      userSelectedSuggestion: true,
      redirectToUserChoice: false,
      isSearchEnabled: true,
    });
  } else {
    Object.assign(trackingProps, {
      userSelectedSuggestion: !isEmpty(doc),
      redirectToUserChoice: isEmpty(doc),
      isSearchEnabled: isEmpty(fullDoc), // user was not forced to choose a user choice document
    });
  }

  analyticsTrackEvent(UNIVERSAL_SEARCH, trackingProps);
  trackSearchIntentImpression(trackingProps);
}

function* trackWrapper({ label = '' }) {
  yield trackSearchImpression({
    fullDoc: { name: label },
    isSelectionOnly: true,
    impressionType: IMPRESSION_TYPE_BLUR_INTENT,
  });
}

function* requestSearchHandler({ fullDoc, onlySelectDoc, fromSrp }: any) {
  try {
    const {
      universalSearch,
      newBooking: { booking },
    } = yield select((s) => s);

    if (fullDoc) {
      yield put(setUniversalSearchDoc(fullDoc));
      if (fullDoc.type === 'location') {
        yield trackSearchImpression({
          fullDoc,
          isSelectionOnly: false,
          impressionType: IMPRESSION_TYPE_REDIRECT_TO_CDP,
        });

        const location: LocationInterface = locationResponseFormatter({
          name: fullDoc.display_name_primary,
          city: fullDoc.city,
          state: fullDoc.state,
          id: fullDoc.id,
        });

        const goToUrlFunction = fullDoc.should_replace_url ? history.replace : history.push;

        // Redirect to CDP or PG location page.
        fullDoc.provider_type === PLATFORM_TYPE_PROVIDER_GROUP
          ? goToUrlFunction(
              `${createProviderGroupLocationUrlFromLocation(location)}?${
                ValidProviderGroupQueryParamKeys.SORT_BY
              }=${ProviderListSortTypes.FIRST_AVAILABLE}`
            )
          : goToUrlFunction(createLocationUrlFromLocation(location));

        return;
      }
      // eslint-disable-line no-else-return
      yield dispatchSearchFiltersFromDocSelection(fullDoc);
    }

    if (onlySelectDoc) {
      yield trackSearchImpression({
        fullDoc,
        isSelectionOnly: true,
        impressionType: IMPRESSION_TYPE_SELECT_SUGGESTION,
      });
      return;
    }

    const doc = fullDoc || universalSearch.selectedDoc;
    const { results, action, query } = universalSearch;

    yield trackSearchImpression({
      fullDoc,
      isSelectionOnly: false,
      impressionType: IMPRESSION_TYPE_SEARCH_INTENT,
    });

    if (doc) {
      yield doTheSearch(fromSrp);

      // Make sure to clear universal search selected document in state and close the user choice modal
      yield put(hideModal(USER_CHOICE_MODAL_ID));
    } else if (isEmptyString(query)) {
      if (!fromSrp) {
        // already on the search page and probably is just missing the data in state
        // most likely due to a refresh. it was causing issues where you'd refresh and then
        // search again, which would lead to losing your search preferences even though it's in the
        // url bar
        yield put(
          // for repeat searches
          setUniversalSearchDoc({
            provider_type: 'urgent_care',
            type: 'provider-type',
            highlight: URGENT_CARE_DISPLAY_NAME,
          })
        );
        yield put(
          symptomsSubmit({
            providerType: URGENT_CARE_VALUE,
            symptoms: getDefaultReasonForVisitForProviderType(URGENT_CARE_VALUE),

            patientType: safeGet(booking, PATIENT_TYPE_OPTIONS[0])('patientType'),
          })
        );
      }
      yield doTheSearch(fromSrp);
    } else {
      // User typed and didn't choose a universal search suggestion
      const { confidence, suggestedResult } = action || {};
      const suggestedIndex = suggestedResult?.index;
      const doc = results?.[suggestedIndex]?.fullDoc;
      if (doc && confidence >= USER_CHOICE_CONFIDENCE_THRESHOLD) {
        yield dispatchSearchFiltersFromDocSelection(doc);
        yield doTheSearch(fromSrp);
      } else {
        yield put(showModal(USER_CHOICE_MODAL_ID));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* rootSaga() {
  yield takeLatest(FETCH_UNIVERSAL_SEARCH_SUGGESTIONS, fetchUniversalSearchQuery);
  // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  yield takeLatest(TRACK_UNIVERSAL_SEARCH_IMPRESSION, trackWrapper);
  yield takeLatest(REQUEST_UNIVERSAL_SEARCH, requestSearchHandler);
}

export {
  TRACK_UNIVERSAL_SEARCH_IMPRESSION,
  FETCH_UNIVERSAL_SEARCH_SUGGESTIONS,
  REQUEST_UNIVERSAL_SEARCH,
  rootSaga as default,
};
