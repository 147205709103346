import { Stack, Text } from '@solvhealth/jigsaw';
import React, { PureComponent } from 'react';
import StarSelector from '../../SolvPatternLibrary/StarSelector';

export const PRIMARY_RATING = 'primary rating';

const FLAVOR_COPY = {
  1: 'Worst ever',
  2: 'Not good',
  3: 'Just ok',
  4: 'I liked it',
  5: 'It was great!',
};

type Props = {
  leaveAReview?: any;
  onChange?: Function;
};

function PrimaryRatingSelector({ onChange, leaveAReview }: Props) {
  const onChangeInterpreter = (value: any) => {
    onChange?.({ value, genre: PRIMARY_RATING });
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const flavorCopy = FLAVOR_COPY[leaveAReview[PRIMARY_RATING]];
  return (
    <Stack data-testid="primary-rating-selector-root" space={4} textAlign="center">
      <Text variant="h3">How was your overall experience?</Text>

      <StarSelector
        onChange={onChangeInterpreter}
        rating={leaveAReview[PRIMARY_RATING]}
        starWidth={43}
      />

      {flavorCopy && <Text muted>{flavorCopy}</Text>}
    </Stack>
  );
}

export default PrimaryRatingSelector;
