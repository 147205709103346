import { INVOICE_SAVE_LOCATION_OR_GROUP_ID } from '../constants/index';
import {
  INVOICES_RECEIVED,
  INVOICES_ERROR,
  INVOICES_LIST_RECEIVED,
  INVOICES_LIST_ERROR,
  INVOICE_SET_TRAFFIC_SOURCE,
  CHARGES_RECEIVED,
  LOGIN_RESET_LOGIN_DATA,
} from '../constants/index';

export default function invoicesReducer(state = {}, action: any) {
  switch (action.type) {
    case INVOICE_SET_TRAFFIC_SOURCE:
      return {
        ...state,
        trafficSource: action.payload.value,
      };
    case INVOICES_RECEIVED:
      return {
        ...state,
        result: action.payload.value,
        error: null,
      };
    case INVOICES_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
      };
    case INVOICES_LIST_RECEIVED:
      return {
        ...state,
        invoicesList: action.payload.value.results,
        invoicesListError: null,
      };
    case INVOICES_LIST_ERROR:
      return {
        ...state,
        invoicesList: null,
        invoicesListError: action.payload.value,
      };
    case INVOICE_SAVE_LOCATION_OR_GROUP_ID:
      return {
        ...state,
        source: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'source' does not exist on type '{}'.
          ...state.source,
          ...action.payload.value,
        },
      };
    case CHARGES_RECEIVED:
    case LOGIN_RESET_LOGIN_DATA:
      return {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'trafficSource' does not exist on type '{... Remove this comment to see the full error message
        trafficSource: state.trafficSource,
      };
    default:
      return state;
  }
}
