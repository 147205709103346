import React, { Fragment, PureComponent } from 'react';
import Collapse from '@material-ui/core/Collapse';
import styled, { keyframes } from 'styled-components';
import Provider from '@solvhealth/types/interfaces/Provider';
import { Stack } from '@solvhealth/jigsaw';
import ReviewHeader from './components/ReviewHeader';
import PrimaryRatingSelector from './components/PrimaryRatingSelector';
import SecondaryStarSelectors from './components/SecondaryStarSelectors';
import { colors } from '../../constants/colors';
import RatingDescriptionForm from './components/RatingDescriptionForm';
import { fontFamily, fontSize } from '../../constants/text';
import { LeaveAReviewState } from '~/ducks/leaveAReview';

const Root = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${colors.whiteGrey};
`;

const fill = keyframes`
  from {
    transform: scaleX(0);
  } to {
    transform: scaleX(1);
  }
`;

const ThanksForFeedback = styled.div`
  font-family: ${fontFamily.book};
  font-size: ${fontSize.large};
  color: ${colors.greenHope};
  position: relative;
  margin: 180px auto 220px;
  display: inline-block;

  &:after {
    content: '';
    animation: ${fill} 4s linear 1;
    background-color: ${colors.greenHope};
    display: block;
    margin-top: 10px;
    height: 6px;
    width: 100%;
    transform-origin: left;
  }
`;

type Props = {
  location?: any;
  provider?: Provider;
  providerGroupName?: string;
  leaveAReview: LeaveAReviewState;
  onChange: Function;
  submit: Function;
};

export default class LeaveAReview extends PureComponent<Props> {
  componentDidMount() {
    window.location.hash = 'leaveAReview';
  }

  componentWillUnmount() {
    window.location.hash = '';
  }

  handleSubmit = (formData: any) =>
    this.props.submit({
      ...formData,
      ...this.props.leaveAReview,
      locationId: this.props.location?.id,
      providerId: this.props.provider?.id,
    });

  render() {
    const { location, provider, leaveAReview, onChange, providerGroupName } = this.props;
    return (
      <Root>
        <ReviewHeader location={location} provider={provider} />

        <Collapse in={!leaveAReview.thanksForFeedback} timeout="auto">
          <Stack py="3" space={3}>
            <PrimaryRatingSelector leaveAReview={leaveAReview} onChange={onChange} />

            <SecondaryStarSelectors
              leaveAReview={leaveAReview}
              location={location}
              onChange={onChange}
            />

            <RatingDescriptionForm
              leaveAReview={leaveAReview}
              location={location}
              provider={provider}
              providerGroupName={providerGroupName}
              submit={(formData: any) => {
                this.handleSubmit(formData);
              }}
            />
          </Stack>
        </Collapse>

        <Collapse in={!!leaveAReview.thanksForFeedback} mountOnEnter timeout="auto">
          <ThanksForFeedback>Thanks for the feedback!</ThanksForFeedback>
        </Collapse>
      </Root>
    );
  }
}
