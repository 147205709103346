import React, { Component } from 'react';
import SelectOptions from '@solvhealth/types/interfaces/SelectOptions';
import TextField, { SolvTextFieldProps } from '../SolvPatternLibrary/TextField';
import logger from '../../core/logger/index';

interface Props<T extends unknown> {
  selectOptions: SelectOptions<T>[];
  initialValue?: T;
  OptionElement?: any;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  inputProps?: any;
}

type State = any;

class SelectTextField<T> extends Component<Props<T> & Partial<SolvTextFieldProps>, State> {
  constructor(props: Props<T> & Partial<SolvTextFieldProps>) {
    super(props);
    this.state = {
      option: this.props.initialValue,
    };

    if (!this.props.selectOptions.some((option) => option.value === this.props.initialValue)) {
      logger.debug('initialValue is not a valid select option.');
    }
  }

  getOptions = (selectOptions: any) => {
    const Option = this.props.OptionElement || 'option';
    return selectOptions.map((option: any) => (
      <Option
        disabled={option.disabled || false}
        key={option.key || option.value}
        value={option.value}
      >
        {option.label}
      </Option>
    ));
  };

  handleChange = (name: any) => (event: any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render = () => {
    const { selectOptions, OptionElement, ...others } = this.props;
    const selectTextFieldProps = {
      value: this.state.option,
      onChange: this.handleChange('option'),
      select: true,
      SelectProps: {
        native: true,
      },
      ...others,
    };

    return (
      <TextField {...selectTextFieldProps}>
        {selectOptions && this.getOptions(selectOptions)}
      </TextField>
    );
  };
}

export default SelectTextField;
