// @ts-expect-error Could not find a declaration file for module 'history'.
import { createMemoryHistory, createBrowserHistory } from 'history';

export interface SolvHistory {
  isSrpVideoVisitCtaClicked?: boolean;
  covidNavTile?: boolean;
  isAccountVideoVisitTileClicked?: boolean;
}
const history = process.env.BROWSER
  ? createBrowserHistory<SolvHistory>()
  : createMemoryHistory<SolvHistory>();

export default history;
