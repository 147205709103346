// subratings
export const WAIT_TIME = 'Wait time';
export const BEDSIDE_MANNER = 'Bedside manner';
export const STAFF_FRIENDLINESS = 'Staff friendliness';
export const STAFF_FRIENDLINESS_OPTIONAL = 'Staff friendliness (optional)';
export const QUALITY_OF_CARE = 'Quality of care';
export const FACILITY_CLEANLINESS = 'Facility cleanliness';
export const OFFICE_ENVIRONMENT = 'Office environment';
export const AUDIO_VIDEO_QUALITY = 'Audio and video quality';

// other fields
export const RATING_DESCRIPTION_FIELD = 'ratingDescription';
export const CONSENT_FIELD = 'consentCheckbox';
export const CONSENT_NAME = 'consentNameCheckbox';
export const CONSENT_VISIT_TYPE = 'consentVisitTypeCheckbox';
