import { GenericObj } from '@solvhealth/types/interfaces/generics';
import { PaperworkState } from '~/ducks/paperwork';
import { EmployerPageNumber, EMPLOYER_PAGES } from '../components/Employer/constants';
import { EmployerRelatedVisitReason, PaperworkFieldName } from '../paperworkConstants';
import { hasCustomEmployer, isPaperworkForChild, paperworkHasGuardianFields } from '../util';
import { PaymentPageNumber, PAYMENT_PAGES } from '../components/Payment/constants';
import { PatientPageNumber, PATIENT_PAGES } from '../components/Patient/constants';

type DependencyFn = (paperwork: PaperworkState) => boolean;

const buildFieldDependenciesForPages = (pages: GenericObj[], dependencyFn: DependencyFn) => {
  const fieldDependencies: GenericObj = {};
  pages.forEach((page) => {
    page.fieldNames.forEach((fieldName: string) => {
      fieldDependencies[fieldName] = dependencyFn;
    });
  });
  return fieldDependencies;
};

const patientFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [PATIENT_PAGES.get(PatientPageNumber.GuardianInfo)!],
    (paperwork: PaperworkState) =>
      isPaperworkForChild(paperwork.formData) && paperworkHasGuardianFields(paperwork)
  ),
};

const paymentFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [PAYMENT_PAGES.get(PaymentPageNumber.InsuranceInfo)!],
    (paperwork: PaperworkState) => {
      return paperwork.formData[PaperworkFieldName.SELF_PAY_CHECK] === 'Yes';
    }
  ),
  ...buildFieldDependenciesForPages(
    [PAYMENT_PAGES.get(PaymentPageNumber.SecondaryInsurance)!],
    (paperwork: PaperworkState) => {
      return (
        paperwork.formData[PaperworkFieldName.DO_YOU_HAVE_SECONDARY_INSURANCE_INFORMATION] ===
          'Yes' && paperwork.formData[PaperworkFieldName.SELF_PAY_CHECK] === 'Yes'
      );
    }
  ),
};

const employerFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [{ fieldNames: [PaperworkFieldName.EMPLOYER] }!],
    (paperwork: PaperworkState) => {
      return (
        !hasCustomEmployer(paperwork) &&
        paperwork.formData[PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER] === 'Yes'
      );
    }
  ),
  ...buildFieldDependenciesForPages(
    [{ fieldNames: [PaperworkFieldName.CUSTOM_EMPLOYER] }!],
    (paperwork: PaperworkState) => {
      return (
        hasCustomEmployer(paperwork) &&
        paperwork.formData[PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER] === 'Yes'
      );
    }
  ),
  ...buildFieldDependenciesForPages(
    [EMPLOYER_PAGES.get(EmployerPageNumber.WorkRelatedInjuryFields)!],
    (paperwork: PaperworkState) => {
      return (
        paperwork.formData[PaperworkFieldName.WHAT_ARE_YOU_HERE_FOR] ===
          EmployerRelatedVisitReason.WORK_INJURY &&
        paperwork.formData[PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER] === 'Yes'
      );
    }
  ),
  ...buildFieldDependenciesForPages(
    [EMPLOYER_PAGES.get(EmployerPageNumber.DrugScreenAlcoholTestFields)!],
    (paperwork: PaperworkState) => {
      return (
        paperwork.formData[PaperworkFieldName.WHAT_ARE_YOU_HERE_FOR] ===
          EmployerRelatedVisitReason.DRUG_SCREEN_ALCOHOL_TEST &&
        paperwork.formData[PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER] === 'Yes'
      );
    }
  ),
  ...buildFieldDependenciesForPages(
    [EMPLOYER_PAGES.get(EmployerPageNumber.PhysicalFields)!],
    (paperwork: PaperworkState) => {
      return (
        paperwork.formData[PaperworkFieldName.WHAT_ARE_YOU_HERE_FOR] ===
          EmployerRelatedVisitReason.PHYSICAL &&
        paperwork.formData[PaperworkFieldName.TREATED_AT_THE_REQUEST_OF_EMPLOYER] === 'Yes'
      );
    }
  ),
};

export const fieldDependencies: { [fieldName: string]: DependencyFn } = {
  ...patientFieldDependencies,
  ...employerFieldDependencies,
  ...paymentFieldDependencies,
};

export const isFieldDependencySatisfied = (fieldName: string, paperwork: PaperworkState) => {
  const dependencyFn = fieldDependencies[fieldName];
  return !dependencyFn || dependencyFn(paperwork);
};

export const getFieldsWithDependenciesSatisified = (
  sectionFields: string[],
  paperwork: PaperworkState
) => sectionFields.filter((field) => isFieldDependencySatisfied(field, paperwork));
