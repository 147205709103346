import Booking from '@solvhealth/types/interfaces/Booking';
import Location from '@solvhealth/types/interfaces/Location';
import { createContext } from 'react';
import { queryParamFlowTypes } from '../../constants';

export const ReviewContext = createContext<ReviewProps | null>(null);

export type ReviewProps = {
  bookingId: string;
  booking: Booking;
  location: Location;
  starRating: number;
  handleRatingChange: (...args: any[]) => any;
  submitReview: (...args: any[]) => any;
  submitNps: (...args: any[]) => any;
  googleUrl: string;
  isSubmitting: boolean;
  responseReceived: boolean;
  flowType: string;
  flowState: string;
  setFlowState: (...args: any[]) => any;
  persistentStateLoaded: boolean;
  review: string;
  copyStatus: boolean;
  setCopyStatus: (...args: any[]) => any;
  getBooking: (...args: any[]) => any;
  error?: any;
  queryParamFlowType?: typeof queryParamFlowTypes[number];
  isModal?: boolean;
  leaveAReview: any;
  onStarChange: (...args: any[]) => any;
  handleHasRecommendedClinicChange: (...args: any[]) => any;
  hasRecommendedClinic?: boolean;
};
