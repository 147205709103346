import moment from 'moment-timezone';
import { GenericObj } from '@solvhealth/types/interfaces/generics';
import { setNewBookingLocale } from '~/actions/newBooking';
import { EN, ES, isSupportedLocale, SupportedLocale } from '~/core/util/localizedStr';
import {
  BOOKING_APPOINTMENT_TYPE,
  BOOKING_CLEAR_ERRORS,
  BOOKING_INSURANCE_TYPE,
  BOOKING_IS_PREMIUM,
  BOOKING_NOTES,
  BOOKING_ORIGIN_CHANGED,
  BOOKING_PROFILE_PENDING,
  BOOKING_REASON_FOR_VISIT_CHANGED,
  BOOKING_RECEIVE_USER_PROFILE,
  BOOKING_REQUEST_FAILED,
  BOOKING_REQUEST_NOT_SUBMITTED,
  BOOKING_REQUEST_SUBMITTED,
  BOOKING_REQUESTED_TIME_CHANGED,
  BOOKING_RESPONSE_RECEIVED,
  BOOKING_SET_BOOKING_CODE,
  BOOKING_SET_HAS_BOOKED_HERE_BEFORE_WITH_SOLV,
  BOOKING_SET_IS_EXISTING_PATIENT,
  BOOKING_SET_LOCALE,
  BOOKING_SET_LOCATION_ID,
  BOOKING_SET_PROVIDER_GROUP_ATTRIBUTES,
  BOOKING_SET_STATE_CODE,
  BOOKING_SET_PAYMENT,
  BOOKING_SET_CONSENT,
  BOOKING_TIME_SELECTED,
  BOOKING_WAS_SUCCESSFUL,
  CLEAR_SUCCESSFUL_BOOKING_DATA,
  INSURANCE_CLEAR,
  INSURANCE_FORM_NOT_SUBMITTED,
  INSURANCE_FORM_SUBMISSION,
  INSURANCE_FORM_SUBMITTED,
  INSURANCE_OCR_DATA,
  LOCATION_SLOTS_RECEIVED,
  LOGIN_RESET_LOGIN_DATA,
  PATIENT_TYPE_CHANGED,
  PATIENT_TYPE_OPTIONS,
  POSITION_SUCCESS,
  PROFILE_FORM_SUBMISSION,
  PROFILE_FORM_SUBMITTED,
  RESET_USER_PROFILE,
  SET_ACTIVE_LOCATION,
  SET_CARD_ID_BACK,
  SET_CARD_ID_FRONT,
  SYMPTOM_CLICKED,
  SYMPTOM_SELECTED,
  SYMPTOM_UPDATED,
  SYMPTOMS_SUBMIT,
  BOOKING_IS_PREMIUM_IN_PERSON,
  BOOKING_SET_TOS_CONSENT,
  BOOKING_SET_SMS_CONSENT,
} from '../constants/index';
import { symptomSelected } from '~/actions/symptoms';
import { RECEIVE_ASSOCIATED_TELEMED_LOCATION_SLOTS } from '~/ducks/associatedTelemedLocation';
import { getFirstAvailableAppointmentTime, getNearestSlotAvailable } from '~/core/location/slots';
import { isEmptyObject } from '~/core/util/object';
import {
  ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME,
  TELEMED_CONSENT_FIELD_NAME,
  TOS_CONSENT_FIELD_NAME,
} from '~/components/BookingWidget/util/validators';

/**
 * This isn't a fully complete type, but it's mostly there.
 */
export interface NewBookingState {
  /** data about the new booking being created */
  booking: Partial<{
    reasonForVisit: string;
    reason: any;
    requestedAppointmentTime: { value: number; timeLabel: string } | string;
    appointmentTime: number;
    appointmentType: string;
    /* time zone of booking being created (matches location timezone) */
    timeZone: string;
    locationId: string;
    /** the state abbreviation that the booking is being made in e.g `CA` */
    stateCode: string;
    providerId: string;
    patientType: typeof PATIENT_TYPE_OPTIONS[number];
    origin:
      | 'booking_widget'
      | 'react_mobile_app'
      | 'No Wait Care'
      | 'booking_form'
      | 'queue'
      | 'Solv'
      | 'kiosk_sign_in'
      | 'partner_api';
    isExternalTelemed: boolean;
    isPremiumVisit: boolean;
    isPremiumInPerson: boolean;
    userProfileId: string;
    userProfile: any;
  }>;
  /** data about the new insurance related to new booking in creation */
  insurance: Partial<{
    insurerType: string;
    firstName: string;
    lastName: string;
  }> &
    GenericObj;

  /** data about the new user related to new booking in creation */
  profile: Partial<{
    isExistingPatient: boolean;
    appointmentDate: number | { appointmentDate: number };
    appointmentTime: null | number;
    birthDate: string;
    birthSex: string;
    email: string;
    firstName: string;
    insuranceProfile: any;
    insurerType: string;
    lastName: string;
    phone: string;
    symptoms: string;
    telemedConsent: boolean;
    tosConsent: boolean;
    [ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME]: boolean;
    uberRequest: any;
    userProfile: any;
    payment: string;
    address_street: string;
    address_street_secondary: string;
    address_city: string;
    address_state: string;
    address_zip: string;
    isDirectTelemed?: boolean;
    bookingTosConsent?: boolean;

    // Unfortunately we do have camel-case versions fo thse available from some APIs
    addressStreet: string;
    addressStreetSecondary: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
  }>;

  /** data about the location related to the new booking being created */
  location: Partial<{
    bookingCode: string;
  }>;

  /**
   * Error for the new booking.
   *
   * Populated after submitting a booking and getting a
   * less than satisfactory response from dapi
   */
  error?: null | string;
  errorCode?: number;
  hasBookedHereBeforeSolv?: any;
  submitting?: boolean;
  /** pending new booking profile when adding a new user profile/family member */
  bookingProfilePending?: boolean;

  /**
   * Information about the last saved booking, populated after creating a booking
   */
  lastCreatedBookingInfo?: Partial<{
    id: string;
    phone: string;
    reasonForVisit: string;
  }>;

  locale?: SupportedLocale;

  /** rehydrate data */
  _persist?: { version: number; rehydrated: boolean };
}

export const newBookingInitialState: NewBookingState = {
  booking: {},
  insurance: {},
  locale: EN,
  profile: { isExistingPatient: false },
  location: {},
  bookingProfilePending: false,
};

const createInitialState = () => newBookingInitialState;

/**
 * Reducer for state.newBooking
 *
 * @returns state the modified state
 */
export default function bookingReducer(state = createInitialState(), action: any) {
  const { reasonForVisit } = state.booking || {};

  switch (action.type) {
    case BOOKING_SET_STATE_CODE:
      return {
        ...state,
        booking: {
          ...state.booking,
          stateCode: action.payload.value,
        },
      };
    case BOOKING_REASON_FOR_VISIT_CHANGED:
      return {
        ...state,
        booking: {
          ...state.booking,
          reasonForVisit: action.payload.value,
        },
      };
    case BOOKING_ORIGIN_CHANGED:
      return {
        ...state,
        booking: {
          ...state.booking,
          origin: action.payload.value,
        },
      };
    case BOOKING_WAS_SUCCESSFUL:
      return {
        ...state,
        ...createInitialState(),
        lastCreatedBookingInfo: {
          id: isEmptyObject(action.payload.value) ? null : action.payload.value.booking_id,
          phone: state.profile.phone,
          reasonForVisit,
        },
        error: null,
        submitting: false,
        submitSuccess: true,
        isSymptomClicked: false,
      };
    case BOOKING_RESPONSE_RECEIVED:
      return {
        ...state,
        ...createInitialState(),
        lastCreatedBookingInfo: {
          id: isEmptyObject(action.payload.value) ? null : action.payload.value.booking_id,
          phone: state.profile.phone,
          reasonForVisit,
        },
        error: null,
        submitting: false,
        submitSuccess: true,
        isSymptomClicked: false,
      };
    case CLEAR_SUCCESSFUL_BOOKING_DATA:
      return {
        ...state,
        ...createInitialState(),
      };
    case BOOKING_REQUEST_FAILED:
      return {
        ...state,
        error: action.payload.value,
        errorCode: action.payload.code,
        submitting: false,
      };
    case BOOKING_CLEAR_ERRORS:
      return {
        ...state,
        error: undefined,
        errorCode: undefined,
      };
    case INSURANCE_CLEAR:
      return {
        ...state,
        insurance: {},
        submitting: false,
      };
    case BOOKING_REQUEST_SUBMITTED:
    case PROFILE_FORM_SUBMITTED:
    case INSURANCE_FORM_SUBMITTED:
      return {
        ...state,
        submitting: true,
        submitSuccess: false,
      };
    case BOOKING_IS_PREMIUM:
      return {
        ...state,
        booking: {
          ...state.booking,
          isPremiumVisit: action.payload.value,
        },
      };
    case BOOKING_IS_PREMIUM_IN_PERSON:
      return {
        ...state,
        booking: {
          ...state.booking,
          isPremiumInPerson: action.payload.value,
        },
      };
    case BOOKING_REQUEST_NOT_SUBMITTED:
    case INSURANCE_FORM_NOT_SUBMITTED:
      return {
        ...state,
        submitting: false,
      };
    case PROFILE_FORM_SUBMISSION:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.value,
        },
        submitting: false,
      };
    case SET_CARD_ID_BACK:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          cardBack: action.payload.value,
        },
      };
    case SET_CARD_ID_FRONT:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          cardFront: action.payload.value,
        },
      };
    case INSURANCE_FORM_SUBMISSION:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          ...action.payload.value,
        },
        submitting: false,
      };
    case SYMPTOM_SELECTED: {
      const {
        payload: {
          value: { patientType, symptoms },
        },
      } = action as ReturnType<typeof symptomSelected>;
      return {
        ...state,
        booking: {
          ...state.booking,
          reasonForVisit: symptoms,
          patientType,
        },
      };
    }
    case SYMPTOM_UPDATED:
      return {
        ...state,
        booking: {
          ...state.booking,
          reasonForVisit: action.payload.value,
        },
      };
    case SYMPTOM_CLICKED:
      return {
        ...state,
        isSymptomClicked: action.payload.value,
      };
    case PATIENT_TYPE_CHANGED:
      return {
        ...state,
        booking: {
          ...state.booking,
          patientType: action.payload.value,
        },
      };
    case BOOKING_SET_LOCATION_ID:
      return {
        ...state,
        booking: {
          ...state.booking,
          locationId: action.payload.value,
        },
      };
    case BOOKING_SET_BOOKING_CODE:
      return {
        ...state,
        location: {
          ...state.location,
          bookingCode: action.payload.value,
        },
      };
    case SET_ACTIVE_LOCATION:
    case LOCATION_SLOTS_RECEIVED:
    case RECEIVE_ASSOCIATED_TELEMED_LOCATION_SLOTS: {
      let appointmentTime;

      if (isEmptyObject(state.booking)) {
        const location = action.payload.value;
        appointmentTime = getNearestSlotAvailable(location, moment().tz(location.timeZone));
      } else if (!isEmptyObject(state.booking) && state.booking.appointmentTime) {
        appointmentTime = state.booking.appointmentTime;
      } else {
        appointmentTime = state.booking.requestedAppointmentTime
          ? getNearestSlotAvailable(action.payload.value, state.booking.requestedAppointmentTime)
          : getFirstAvailableAppointmentTime(action.payload.value);
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          locationId: action.payload.value.id || state.booking.locationId,
          appointmentTime,
        },
      };
    }

    case BOOKING_REQUESTED_TIME_CHANGED:
      return {
        ...state,
        booking: {
          ...state.booking,
          requestedAppointmentTime: action.payload.value,
          appointmentTime: null,
        },
      };
    case BOOKING_TIME_SELECTED:
      return {
        ...state,
        booking: {
          ...state.booking,
          appointmentTime: action.payload.appointmentTime,
          timeZone: action.payload.timeZone ?? state.booking.timeZone,
        },
      };
    case BOOKING_SET_PROVIDER_GROUP_ATTRIBUTES:
      return {
        ...state,
        booking: {
          ...state.booking,
          ...action.payload.value,
        },
      };
    case BOOKING_SET_IS_EXISTING_PATIENT:
      return {
        ...state,
        profile: {
          ...state.profile,
          isExistingPatient: action.payload.value,
        },
      };
    case BOOKING_SET_HAS_BOOKED_HERE_BEFORE_WITH_SOLV:
      return {
        ...state,
        hasBookedHereBeforeWithSolv: action.payload.value,
      };
    case BOOKING_NOTES:
      return {
        ...state,
        booking: {
          ...state.booking,
          notes: action.payload.value,
        },
      };
    case BOOKING_APPOINTMENT_TYPE:
      return {
        ...state,
        booking: {
          ...state.booking,
          appointmentType: action.payload.value,
        },
      };
    case INSURANCE_OCR_DATA:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          ...action.payload.value,
        },
      };
    case BOOKING_INSURANCE_TYPE:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          insurerType: action.payload.value,
        },
      };
    case POSITION_SUCCESS:
      return {
        ...state,
        booking: {
          ...state.booking,
          latitude: action.payload.value.latitude,
          longitude: action.payload.value.longitude,
        },
      };
    case BOOKING_RECEIVE_USER_PROFILE:
      return {
        ...state,
        booking: {
          ...state.booking,
          userProfileId: action.payload.value.user_profile_id,
          userProfile: action.payload.value,
        },
      };

    case SYMPTOMS_SUBMIT: {
      const newState = {
        ...state,
        booking: {
          ...state.booking,
        },
      };

      if (action.payload.value.symptoms) {
        newState.booking.reasonForVisit = action.payload.value.symptoms;
      }

      if (action.payload.value.appointmentTime) {
        newState.booking.requestedAppointmentTime = action.payload.value.appointmentTime;
      }

      if (action.payload.value.patientType) {
        newState.booking.patientType = action.payload.value.patientType;
      }

      if (action.payload.value.requestedInsurance) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'requestedInsurance' does not exist on ty... Remove this comment to see the full error message
        newState.booking.requestedInsurance = action.payload.value.requestedInsurance;
      }

      return newState;
    }

    case RESET_USER_PROFILE:
    case LOGIN_RESET_LOGIN_DATA:
      return {
        ...state,
        booking: {
          ...state.booking,
          userProfileId: null,
          userProfile: null,
        },
      };

    case BOOKING_SET_LOCALE: {
      let {
        payload: { locale },
      } = action as ReturnType<typeof setNewBookingLocale>;

      locale = locale.toLowerCase().trim() as typeof locale;

      if ((locale as any) === 'en-us') {
        locale = EN;
      }

      if ((locale as any) === 'es-us') {
        locale = ES;
      }

      if (!isSupportedLocale(locale)) {
        return state;
      }

      return {
        ...state,
        locale,
      };
    }

    case BOOKING_SET_PAYMENT: {
      return {
        ...state,
        profile: {
          ...state.profile,
          payment: action.payload.value,
        },
      };
    }

    case BOOKING_SET_CONSENT: {
      return {
        ...state,
        profile: {
          ...state.profile,
          [TOS_CONSENT_FIELD_NAME]: !!action.payload.value[TOS_CONSENT_FIELD_NAME],
          [TELEMED_CONSENT_FIELD_NAME]: !!action.payload.value[TELEMED_CONSENT_FIELD_NAME],
          [ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME]:
            !!action.payload.value[ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME],
        },
      };
    }

    case BOOKING_SET_TOS_CONSENT: {
      return {
        ...state,
        profile: {
          ...state.profile,
          [TOS_CONSENT_FIELD_NAME]: action.payload.value,
        },
      };
    }

    case BOOKING_SET_SMS_CONSENT: {
      return {
        ...state,
        profile: {
          ...state.profile,
          [ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME]: action.payload.value,
        },
      };
    }

    case BOOKING_PROFILE_PENDING:
      return {
        ...state,
        bookingProfilePending: action.payload.value,
        profile: action.payload.value ? {} : state.profile,
      };

    default:
      return state;
  }
}
