/* eslint-disable max-len */
/* jscs:disable maximumLineLength */

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isValidEmail = (string: any) =>
  typeof string !== 'undefined' && string.length !== 0 && emailRegex.test(string);

export { isValidEmail, emailRegex };
