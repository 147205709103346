import { DAPI_TOKEN } from '../../config/index';
import { getLoginInfo } from '../session';

const getAccountId = () => {
  const returningUserCookie = getLoginInfo();
  return returningUserCookie ? returningUserCookie.id : null;
};

const publicAuthHeaders = (): HeadersInit => ({
  Authorization: `Bearer ${DAPI_TOKEN}`,
});

export const getLoggedInAuthHeaders = (): HeadersInit => {
  const returningUserCookie = getLoginInfo();
  if (returningUserCookie) {
    return {
      Authorization: `${returningUserCookie.tokenType} ${returningUserCookie.authToken}`,
    };
  }

  return {};
};

// token type is not defined if login has not been called
const authHeaders = (): HeadersInit => {
  const returningUserCookie = getLoginInfo();
  return returningUserCookie && returningUserCookie.tokenType
    ? getLoggedInAuthHeaders()
    : publicAuthHeaders();
};

const getAuthToken = () => {
  const returningUserCookie = getLoginInfo();

  return returningUserCookie ? `Bearer ${returningUserCookie.authToken}` : null;
};

export { authHeaders, getAccountId, getAuthToken };
