import {
  POPULAR_CITIES_NEARBY_RECEIVE_DATA,
  POPULAR_CITIES_NEARBY_RECEIVE_DATA_ERROR,
  POPULAR_CITIES_NEARBY_CLEAR_ERRORS,
} from '../constants/index';

export default function popularCitiesNearbyReducer(state = {}, action: any) {
  switch (action.type) {
    case POPULAR_CITIES_NEARBY_RECEIVE_DATA:
      return {
        ...state,
        result: action.payload.value,
        error: null,
      };
    case POPULAR_CITIES_NEARBY_RECEIVE_DATA_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
      };
    case POPULAR_CITIES_NEARBY_CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
