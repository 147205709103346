// TODO: Code commented out until fetchNearbyCities is fixed.
import { PaginatedResponse } from '@solvhealth/types/interfaces/dapi';
import Location from '@solvhealth/types/interfaces/Location';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import {
  getAtHomeCarePartnersSearchUrl,
  getNearbyCitiesUrl,
  getNonSolvPartnersSearchUrl,
  getSolvPartnersSearchUrl,
} from '../core/dapi/search';
import { symptomsSubmit } from '~/actions/symptoms';
import { analyticsTrackEvent } from '~/core/analytics';
import { SRP_FILTERS_APPLY } from '~/core/analytics/events';
import { safeGet } from '../core/util/object';
import { apiGet } from '../core/dapi';
import { locationResponseFormatter } from '../reducers/formatters/location';
import {
  clearSearchResults,
  receiveAtHomeCareResults,
  receiveBestMatch,
  receiveNearbyBrandsResponseSearch,
  receiveNearbyCitiesResponseSearch,
  receiveNonPartnersSearch,
  receivePartnersSearch,
  searchInProgress,
  setNearbyBrandsSearchInProgress,
  setNearbyCitiesSearchInProgress,
} from '../ducks/searchResults';
import { nearbyBrands, nearbyCities } from '../../stories/fixtures/nearby';
import {
  getSortOrderFromFilters,
  getUpdatedLocationsWithSlotsAfterTime,
  sortNonBookableResults,
  sortPartnerResults,
  sortPartnerResultsByRelevancy,
} from '../components/Search/components/Results/util';
import {
  getRequestedPaginationForSearch,
  getSearchData,
  isPartnerSearch,
  isUrgentCareSearch,
  SEARCH_TYPE_NON_PARTNERS,
  SEARCH_TYPE_PARTNERS,
} from '../core/util/search';
import { getRequestedAppointmentTimeValue } from '../core/location/slots';
import { DEFAULT_SEARCH_RADIUS, METERS_IN_A_MILE } from '../config';
import { getBooleanFlag } from '~/core/launch-darkly/flags';
import { HOME_HEALTHCARE_DB } from '~/constants/category';
import { getMilesFromMeters } from '~/core/util/geo';
import { COVID_SERVICE_IDS } from '~/constants/services';
import { VisitMode } from '~/reducers/searchPreferences';

const FETCH_RESULTS = 'saga/SEARCH_FETCH_RESULTS';
const FETCH_MORE_RESULTS_BY_TYPE = 'saga/SEARCH_FETCH_MORE_RESULTS_BY_TYPE';
const FETCH_NEARBY_RESULTS = 'saga/SEARCH_NEARBY_RESULTS';
const DISPATCH_SRP_FILTERS = 'saga/DISPATCH_SRP_FILTERS';
// const FETCH_NEARBY_CITIES = 'saga/SEARCH_FETCH_NEARBY_CITIES';

const DEFAULT_RADIUS = DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE;
const DEFAULT_PAGE = 1;

export const BEST_MATCH_CUTOFF_IN_MILES = 15; // SOLV-5684: update best match cutoff in miles from 6 miles to 15 miles

function* searchResultsCollector(options: any) {
  const {
    resultsType,
    searchLocationPreferences,
    patientType,
    locationCategories,
    providerType,
    searchPreferencesPagination,
    isFetchingMoreResultsForSameSearch,
    resultsPagination,
    appointmentTime,
    requestedInsurance,
    requestedServices,
    reasonForVisit,
    visitMode,
    covidCostType,
    includeAtHomeCare,
    sortOrder,
    solvRatingMinimum,
    acceptedInsurance,
  } = options;
  let { isServiceHardFilter, isCovidTestRelatedSearch } = options;

  try {
    const requestedPagination = getRequestedPaginationForSearch(
      resultsType,
      searchPreferencesPagination,
      resultsPagination,
      isFetchingMoreResultsForSameSearch
    );

    let urlFunction;
    let paginationPage;
    let paginationRadius;

    if (isPartnerSearch(resultsType)) {
      urlFunction = getSolvPartnersSearchUrl;
      isServiceHardFilter = true; // Solv partner service searches are always hard filters
      paginationPage = (requestedPagination && requestedPagination.partnersPage) || DEFAULT_PAGE;
      paginationRadius =
        (requestedPagination && requestedPagination.partnersRadius) || DEFAULT_RADIUS;
    } else {
      urlFunction = getNonSolvPartnersSearchUrl;
      paginationPage = (requestedPagination && requestedPagination.nonPartnersPage) || DEFAULT_PAGE;
      paginationRadius =
        (requestedPagination && requestedPagination.nonPartnersRadius) || DEFAULT_RADIUS;
    }

    const url = urlFunction(
      searchLocationPreferences,
      patientType,
      locationCategories,
      providerType,
      paginationPage,
      paginationRadius,
      requestedInsurance,
      requestedServices,
      isServiceHardFilter,
      isCovidTestRelatedSearch,
      reasonForVisit,
      visitMode,
      covidCostType,
      sortOrder,
      solvRatingMinimum,
      acceptedInsurance
    );

    yield put(searchInProgress(resultsType, true));

    const getStateSearchResults = (state: any) => safeGet(state)('searchResults');
    const stateSearchResults = yield select(getStateSearchResults);
    const response = yield call(apiGet, url);
    const resultsAction = isPartnerSearch(resultsType)
      ? receivePartnersSearch
      : receiveNonPartnersSearch;

    const responsePagination = response.page;
    const { results } = response;
    const serviceFilters = {
      isTelemed: visitMode === VisitMode.Telemed,
      isCovidTestRelatedSearch,
      includeAtHomeCare: locationCategories && locationCategories.includes(HOME_HEALTHCARE_DB),
    };

    let formattedLocations: Location[] = results.map((location: any) =>
      locationResponseFormatter(location)
    );

    // TODO: KIS-462 Removes duplicates; We should ensure locations endpoint does not return duplicates!
    formattedLocations = uniqBy(formattedLocations, 'id');

    formattedLocations = formattedLocations.filter((l) => !l.category.includes(HOME_HEALTHCARE_DB));

    const appointmentTimeValue =
      getRequestedAppointmentTimeValue(appointmentTime) || new Date().getTime();
    if (isPartnerSearch(resultsType)) {
      formattedLocations = getUpdatedLocationsWithSlotsAfterTime(
        formattedLocations,
        appointmentTimeValue
      );
    }

    if (isPartnerSearch(resultsType) && !isFetchingMoreResultsForSameSearch) {
      let partnerSort;
      if (getBooleanFlag('onion614ShowSrpFilterButton', false) && sortOrder) {
        partnerSort = getSortOrderFromFilters({ sortOrder, serviceFilters });
      } else if (getBooleanFlag('sortSrpByRelevancy', false)) {
        partnerSort = sortPartnerResultsByRelevancy;
      } else {
        partnerSort = sortPartnerResults;
      }
      formattedLocations = partnerSort(
        { booking: { requestedAppointmentTime: appointmentTimeValue } },
        formattedLocations
      );

      if (formattedLocations.length > 0) {
        const nearestLocationDistance = getMilesFromMeters(
          formattedLocations[0]?.distanceFromCurrentLocation
        );
        if (nearestLocationDistance && nearestLocationDistance <= BEST_MATCH_CUTOFF_IN_MILES) {
          const bestMatchLocation = formattedLocations.shift();
          if (bestMatchLocation) {
            yield put(
              receiveBestMatch({
                response: {
                  ...stateSearchResults,
                  results: [bestMatchLocation],
                  pagination: responsePagination,
                },
              })
            );
          }
        }
      }
    } else {
      formattedLocations = sortNonBookableResults(formattedLocations);
    }

    // Make sure after Mary clicks the show more results, those results are sorted too
    if (isFetchingMoreResultsForSameSearch) {
      if (isPartnerSearch(resultsType)) {
        formattedLocations = sortPartnerResults(
          { booking: { requestedAppointmentTime: appointmentTimeValue } },
          formattedLocations
        );
      } else {
        formattedLocations = sortNonBookableResults(formattedLocations);
      }
    }

    let atHomeCareLocations: Location[] = [];

    // skip separate home healthcare search if doing a telemed search
    // to prevent home health locations being included in telemed results.
    if (includeAtHomeCare && !(visitMode === VisitMode.Telemed)) {
      const { results }: PaginatedResponse<Location> = yield call(
        apiGet,
        getAtHomeCarePartnersSearchUrl(
          searchLocationPreferences,
          patientType,
          locationCategories,
          providerType,
          paginationPage,
          paginationRadius,
          requestedInsurance,
          requestedServices,
          isServiceHardFilter,
          isCovidTestRelatedSearch,
          reasonForVisit,
          visitMode,
          covidCostType,
          acceptedInsurance
        )
      );
      atHomeCareLocations = results.map((l: any) => locationResponseFormatter(l));
    }

    const formattedResponse = {
      ...stateSearchResults,
      results: formattedLocations,
      pagination: responsePagination,
    };

    yield put(resultsAction({ response: formattedResponse }));
    if (includeAtHomeCare && atHomeCareLocations.length > 0) {
      yield put(receiveAtHomeCareResults(atHomeCareLocations));
    }
    yield put(searchInProgress(resultsType, false));
  } catch (e) {
    console.error('search failed', e);
    yield put(searchInProgress(resultsType, false));
  }
}

function* nearbyResultsCollector(resultsType: any, url: any) {
  const setInProgress =
    resultsType === 'cities' ? setNearbyCitiesSearchInProgress : setNearbyBrandsSearchInProgress;
  const resultsAction =
    resultsType === 'cities'
      ? receiveNearbyCitiesResponseSearch
      : receiveNearbyBrandsResponseSearch;
  yield put(setInProgress(true));
  let response = yield call(apiGet, url);
  response.results = resultsType === 'cities' ? nearbyCities : nearbyBrands;
  yield put(resultsAction({ results: response.results }));
  yield put(setInProgress(false));
}

function* fetchSearchResults({ resultType, isFetchingMoreResultsForSameSearch, fullReload }: any) {
  //TODO: distance matrix
  //TODO: sort client side
  //TODO: appointment slots
  //TODO: deal with no results

  const {
    searchLocationPreferences,
    patientType,
    appointmentTime,
    providerType,
    searchPreferencesPagination,
    resultsPagination,
    requestedInsurance,
    requestedServices,
    isServiceHardFilter,
    isCovidTestRelatedSearch,
    reasonForVisit,
    visitMode,
    covidCostType,
    sortOrder,
    solvRatingMinimum,
    locationCategories,
    acceptedInsurance,
  } = yield* getSearchData();

  if (fullReload) yield put(clearSearchResults());

  if (isUrgentCareSearch(providerType)) {
    if (!resultType || resultType === SEARCH_TYPE_PARTNERS) {
      try {
        yield fork(searchResultsCollector, {
          resultsType: SEARCH_TYPE_PARTNERS,
          searchLocationPreferences,
          patientType,
          requestedServices,
          locationCategories,
          providerType,
          searchPreferencesPagination,
          isFetchingMoreResultsForSameSearch,
          resultsPagination,
          appointmentTime,
          requestedInsurance,
          isServiceHardFilter,
          isCovidTestRelatedSearch,
          reasonForVisit,
          visitMode,
          covidCostType,
          includeAtHomeCare: getBooleanFlag('srpIncludeAtHomeCare', false),
          sortOrder,
          solvRatingMinimum,
          acceptedInsurance,
        });
      } catch (e) {
        yield put(searchInProgress(SEARCH_TYPE_PARTNERS, false));
      }
    }

    if (
      (!resultType || resultType === SEARCH_TYPE_NON_PARTNERS) &&
      !(visitMode === VisitMode.Telemed) // don't do non-partner search for telemed search
    ) {
      try {
        yield fork(searchResultsCollector, {
          resultsType: SEARCH_TYPE_NON_PARTNERS,
          searchLocationPreferences,
          patientType,
          locationCategories,
          requestedServices,
          providerType,
          searchPreferencesPagination,
          isFetchingMoreResultsForSameSearch,
          resultsPagination,
          appointmentTime,
          isServiceHardFilter,
          isCovidTestRelatedSearch,
          reasonForVisit,
          covidCostType,
          acceptedInsurance,
        });
      } catch (e) {
        yield put(searchInProgress(SEARCH_TYPE_NON_PARTNERS, false));
      }
    }
  } else {
    // SOLV-6515: Only conduct non-partner search for marketplace locations
    try {
      yield fork(searchResultsCollector, {
        resultsType: SEARCH_TYPE_NON_PARTNERS,
        searchLocationPreferences,
        patientType,
        locationCategories,
        requestedServices,
        providerType,
        searchPreferencesPagination,
        isFetchingMoreResultsForSameSearch,
        resultsPagination,
        appointmentTime,
        isServiceHardFilter,
        isCovidTestRelatedSearch,
        reasonForVisit,
        covidCostType,
        acceptedInsurance,
      });
    } catch (e) {
      yield put(searchInProgress(SEARCH_TYPE_NON_PARTNERS, false));
    }
  }
}

function* fetchNearbyResults({}) {
  const getSearchPreferences = (state: any) => safeGet(state, {})('searchPreferences.location');
  const searchLocationPreferences = yield select(getSearchPreferences);
  const { latitude, longitude } = searchLocationPreferences;

  yield fork(nearbyResultsCollector, 'cities', getNearbyCitiesUrl(latitude, longitude, 4, 35));
  yield fork(nearbyResultsCollector, 'brands', getNearbyCitiesUrl(latitude, longitude, 4, 35));
}

function* dispatchSrpFilters({ filterSettings }: any) {
  yield put(symptomsSubmit(filterSettings));
  analyticsTrackEvent(SRP_FILTERS_APPLY, {
    acceptedInsurance: filterSettings.filterGroups.acceptedInsurance,
    sortOrder: filterSettings.filterGroups.sortOrder,
    solvRatingMinimum: filterSettings.filterGroups.moreFilters.solvRatingMinimum,
    patientType: filterSettings.patientType,
    covidTesting: filterSettings.requestedServices?.some((service: string) =>
      COVID_SERVICE_IDS.includes(service)
    ),
    isTelemed: filterSettings.filterGroups.mode === VisitMode.Telemed,
    mobileUrgentCare:
      filterSettings.filterGroups.moreFilters.locationCategories.includes(HOME_HEALTHCARE_DB),
    mode: filterSettings.filterGroups.mode,
  });
  yield put({ type: FETCH_RESULTS, fullReload: true });
}

// /**
//  * Fetches cities within a radius of a city, state.
//  */
// function* fetchNearbyCities({
//   payload,
// }: Action & {
//   payload: {
//     city: string;
//     state: string;
//   };
// }) {
//   const { city, state } = payload;
//   yield fork(nearbyResultsCollector, 'cities', getNearbyCitiesByCityUrl(city, state, 4, 35));
// }

function* rootSaga() {
  yield takeEvery(FETCH_RESULTS, fetchSearchResults);
  yield takeEvery(FETCH_MORE_RESULTS_BY_TYPE, fetchSearchResults);
  yield takeEvery(FETCH_NEARBY_RESULTS, fetchNearbyResults);
  yield takeEvery(DISPATCH_SRP_FILTERS, dispatchSrpFilters);
  // yield takeEvery(FETCH_NEARBY_CITIES, fetchNearbyCities);
}

export {
  FETCH_RESULTS,
  FETCH_MORE_RESULTS_BY_TYPE,
  FETCH_NEARBY_RESULTS,
  DISPATCH_SRP_FILTERS,
  // FETCH_NEARBY_CITIES,
  rootSaga as default,
};
