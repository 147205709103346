import cloneDeep from 'lodash/cloneDeep';

export const ADD_SOLV_RATING = 'solvRatings/ADD';
const FLAG_SOLV_RATING = 'solvRatings/FLAG';
const SET_SOLV_RATINGS = 'solvRatings/SET';
const HELPFUL_COUNT_UPDATED = 'solvRatings/HELPFUL_COUNT_UPDATED';
const HELPFUL_COUNT_ERROR = 'solvRatings/HELPFUL_COUNT_ERROR';
const HELPFUL_COUNT_UPDATING = 'solvRatings/HELPFUL_COUNT_UPDATING';

const solvRatingsFormatter = (response: any) => {
  const { results } = response;

  const formattedRatings = results.map((review: any) => ({
    ...review,

    helpful_count: {
      value: review.helpful_count,
      updating: false,
      updated: false,
      error: null,
    },

    currentlyFlagged: false,
  }));

  return formattedRatings;
};

export const flagSolvRating = (ratingId: any, value: any) => ({
  type: FLAG_SOLV_RATING,
  payload: { id: ratingId, value },
});

export const addSolvRating = (rating: any) => ({
  type: ADD_SOLV_RATING,
  payload: { rating },
});

export const setSolvRatings = (value: any) => ({
  type: SET_SOLV_RATINGS,
  payload: { value: solvRatingsFormatter(value) },
});

export const helpfulCountUpdated = (solvRatingId: any, value: any) => ({
  type: HELPFUL_COUNT_UPDATED,
  payload: { id: solvRatingId, value },
});

export const helpfulCountError = (solvRatingId: any, value: any) => ({
  type: HELPFUL_COUNT_ERROR,
  payload: { id: solvRatingId, value },
});

export const helpfulCountUpdating = (solvRatingId: any, value: any) => ({
  type: HELPFUL_COUNT_UPDATING,
  payload: { id: solvRatingId, value },
});

export default (state = [], action: any) => {
  let newReview;
  let newReviews;

  switch (action.type) {
    case FLAG_SOLV_RATING:
      newReviews = state.map((review) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        if (review.id === action.payload.id) {
          return {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...review,
            currentlyFlagged: action.payload.value,
          };
        }

        return review;
      });

      return cloneDeep(newReviews);
    case ADD_SOLV_RATING:
      newReview = {
        ...action.payload.rating,
        helpful_count: {
          value: action.payload.rating.helpful_count,
          updating: false,
          updated: false,
          error: null,
        },
      };
      newReviews = [newReview, ...state];

      return cloneDeep(newReviews);
    case SET_SOLV_RATINGS:
      return action.payload.value;
    case HELPFUL_COUNT_UPDATING:
      newReviews = state.map((review) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        if (review.id === action.payload.id) {
          return {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...review,
            helpful_count: {
              ...review.helpful_count,
              updating: action.payload.value,
            },
          };
        }

        return review;
      });

      return cloneDeep(newReviews);
    case HELPFUL_COUNT_UPDATED:
      newReviews = state.map((review) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        if (review.id === action.payload.id) {
          return {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...review,
            helpful_count: {
              ...review.helpful_count,
              value: action.payload.value,
              error: null,
              updating: false,
              updated: true,
            },
          };
        }

        return review;
      });

      return cloneDeep(newReviews);
    case HELPFUL_COUNT_ERROR:
      newReviews = state.map((review) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        if (review.id === action.payload.id) {
          return {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...review,
            helpful_count: {
              ...review.helpful_count,
              error: action.payload.value,
              updating: false,
            },
          };
        }

        return review;
      });

      return cloneDeep(newReviews);
    default:
      return state;
  }
};
