// Tiles
import {
  ALLERGIST_VALUE,
  CHIROPRACTOR_VALUE,
  DENTIST_VALUE,
  IMAGING_VALUE,
  OB_GYN_VALUE,
  OPTOMETRIST_VALUE,
  URGENT_CARE_VALUE,
} from './ProviderTypes';

export const COVID = 'COVID';
export const FLU_COLD_AND_COUGH = 'Flu, cold, or cough';
export const CONGESTION_OR_SINUS_PAIN = 'Sinus infection';
export const EAR_PAIN_OR_INFECTION = 'Ear ache';
export const FEVER = 'Fever';
export const RASH = 'Rash';
export const SORE_THROAT = 'Sore throat';
export const URINARY_TRACT_INFECTION = 'Urinary tract infection (UTI)';
export const WOUNDS_OR_STITCHES = 'Wounds or stitches';
export const INSECT_BITE = 'Insect bite';
export const ALLERGIES = 'Allergies';
export const ACNE = 'Acne';
export const SPRAIN = 'Sprain or broken bone';
export const SKIN_ALLERGY = 'Skin allergy';
export const BACK_PAIN = 'Back pain';
export const TOOTH_PAIN = 'Tooth pain';
export const PREGNANCY = 'Pregnancy';
export const RED_EYES = 'Red eyes';
export const BROKEN_BONE = 'Broken bone';

// Traditional Conditions

export interface Symptom {
  symptom: string;
  icon: string;
  providerType: string;
}

export const symptomTiles: Array<Symptom> = [
  {
    symptom: SKIN_ALLERGY,
    icon: '/images/icons/symptoms/allergist_skinallergy.svg',
    providerType: ALLERGIST_VALUE,
  },
  {
    symptom: BACK_PAIN,
    icon: '/images/icons/symptoms/chiropractor_backpain.svg',
    providerType: CHIROPRACTOR_VALUE,
  },
  {
    symptom: TOOTH_PAIN,
    icon: '/images/icons/symptoms/dentist_toothpain.svg',
    providerType: DENTIST_VALUE,
  },
  {
    symptom: PREGNANCY,
    icon: '/images/icons/symptoms/ob_pregnancy.svg',
    providerType: OB_GYN_VALUE,
  },
  {
    symptom: RED_EYES,
    icon: '/images/icons/symptoms/optometrist_redeyes.svg',
    providerType: OPTOMETRIST_VALUE,
  },
  {
    symptom: BROKEN_BONE,
    icon: '/images/icons/symptoms/imaging_brokenbone.svg',
    providerType: IMAGING_VALUE,
  },
  {
    symptom: FLU_COLD_AND_COUGH,
    icon: '/images/icons/symptoms/uc_flu.svg',
    providerType: URGENT_CARE_VALUE,
  },
  {
    symptom: WOUNDS_OR_STITCHES,
    icon: '/images/icons/symptoms/uc_stitches.svg',
    providerType: URGENT_CARE_VALUE,
  },
  {
    symptom: FEVER,
    icon: '/images/icons/symptoms/uc_fever.svg',
    providerType: URGENT_CARE_VALUE,
  },
  {
    symptom: SORE_THROAT,
    icon: '/images/icons/symptoms/uc_sorethroat.svg',
    providerType: URGENT_CARE_VALUE,
  },
];
