import {
  locationResponseFormatter,
  locationSlotsFormatter,
} from '../../reducers/formatters/location';

const SET_ASSOCIATED_TELEMED_LOCATION = 'associatedTelemedLocation/SET_LOCATION';
export const RECEIVE_ASSOCIATED_TELEMED_LOCATION_SLOTS = 'associatedTelemedLocation/RECEIVE_SLOTS';
const CLEAR_ASSOCIATED_TELEMED_LOCATION = 'associatedTelemedLocation/CLEAR_LOCATION';

const setAssociatedTelemedLocationWithoutSlots = (value: any) => ({
  type: SET_ASSOCIATED_TELEMED_LOCATION,
  payload: { value: locationResponseFormatter(value) },
});

const clearAssociatedTelemedLocation = () => ({
  type: CLEAR_ASSOCIATED_TELEMED_LOCATION,
});

const receiveAssociatedTelemedLocationSlots = (value: any) => ({
  type: RECEIVE_ASSOCIATED_TELEMED_LOCATION_SLOTS,
  payload: { value: locationSlotsFormatter(value) },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case SET_ASSOCIATED_TELEMED_LOCATION:
      return action.payload.value;
    case RECEIVE_ASSOCIATED_TELEMED_LOCATION_SLOTS:
      return { ...state, slots: action.payload.value.slots, slotsReceived: true };
    case CLEAR_ASSOCIATED_TELEMED_LOCATION:
      return {};
    default:
      return state;
  }
};

export {
  clearAssociatedTelemedLocation,
  setAssociatedTelemedLocationWithoutSlots,
  receiveAssociatedTelemedLocationSlots,
};
