const URGENT_CARE_FOR_KIDS_THE_WOODLANDS = 'JA6Pkg';
const URGENT_CARE_FOR_KIDS_MCKINNEY = '0VE56g';
const URGENT_CARE_FOR_KIDS_WEST_UNIVERSITY = 'gKJ9np';
const URGENT_CARE_FOR_KIDS_KATY = 'K0xzkp';
const URGENT_CARE_FOR_KIDS_SPRING = 'pDznZ0';
const URGENT_CARE_FOR_KIDS_KELLER = 'V0eopx';
const URGENT_CARE_FOR_KIDS_CEDAR_PARK = 'vp3okp';
const URGENT_CARE_FOR_KIDS_ROUND_ROCK = 'ZplbN0';
const URGENT_CARE_FOR_KIDS_ALLEN = '0Ooem0';
const URGENT_CARE_FOR_KIDS_ALLIANCE = 'JA68Mg';
const URGENT_CARE_FOR_KIDS_FRISCO = 'AvVldA';
export const URGENT_CARE_FOR_KIDS_TELEMED = 'AWMwyg';
export const URGENT_CARE_FOR_KIDS_AISD_TELEMED = 'goJ7kp';
export const URGENT_CARE_FOR_KIDS_EL_PASO_SCHOOL_DISTRICT_TELEMED = '0Vo5NA';
export const URGENT_CARE_FOR_KIDS_GCTC_TELEMED = 'pyvV6g';
export const URGENT_CARE_FOR_KIDS_MCISD_TELEMED = 'gJEB7p';
export const URGENT_CARE_FOR_KIDS_PSJA_TELEMED = '0mKLzp';
export const URGENT_CARE_FOR_KIDS_TRAINING_TELEMED = '0r1Z4g';
export const LIBERTY_URGENT_CARE_TELEMED = 'A639Lp';
const CHILDREN_URGENT_CARE_RIVER_FOREST = 'AG3eeA';
const CHILDREN_URGENT_CARE_SKOKIE = '0mb8DA';
const SMART_CARE_ANAHEIM = 'gbYmQp';
const SMART_CARE_BOSTON = 'gQOYzA';
const SMART_CARE_DALLAS = 'g1PlQR';
export const SMART_CARE_VIRTUAL_VISIT = 'AvykR0';
const SMART_CARE_NEW_YORK = '0kmerp';
export const SMART_CARE_SF = 'pnrk5p';
export const SMART_CARE_SCHOOL_DISTRICT = 'pnJoEp';
export const PM_PEDIATRICS_TELEMED_SCHOOL_DISTRICT = 'AWoO6A';
export const PM_PEDIATRICS_TELEMED_TEST_SANDBOX_SCHOOL_DISTRICT = 'pYOq91';
export const SMART_CARE_TEST = 'AWme6g';
export const CITYDOC_FORT_WORTH = 'p8JvG0';
export const CITYDOC_INWOOD = 'n0ryOg';
export const CITYDOC_MANSFIELD = 'goQdoA';
export const CITYDOC_PRESTON = 'A4b1Pg';
export const CITYDOC_UPTOWN = '8A250v';
const CITYDOC_VIDEO_VISIT = 'gNErZp';
export const TUTTLE_SCHOOL_DISTRICT = 'pjM2DA';

export const UCFK_LOCATION_1 = '0Vo5NA';
export const UCFK_LOCATION_2 = 'gJEB7p';

export const RENOWN_LOS_ALTOS = 'YAGowA';
export const RENOWN_NORTH_HILLS = 'p3JYjA';
export const RENOWN_FALLON = '8gdwVp';
export const RENOWN_FERLNEY = 'Rpyneg';
export const RENOWN_USA_PARKWAY = 'egq4vp';
export const RENOWN_DAMONTE_PARKWAY = 'rgJerp';
export const RENOWN_RYLAND_PARKWAY = '2gKR7p';
export const RENOWN_SUMMIT_RIDGE = 'Aa335g';

const CARE_FIRST_DALLAS = 'gJ21yg';
const CARE_FIRST_DESOTO = 'gqbJl0';

export const SMART_CARE_LOCATION_IDS = [
  SMART_CARE_ANAHEIM,
  SMART_CARE_BOSTON,
  SMART_CARE_DALLAS,
  SMART_CARE_VIRTUAL_VISIT,
  SMART_CARE_NEW_YORK,
  SMART_CARE_SF,
];

export const CITYDOC_LOCATION_IDS = [
  CITYDOC_FORT_WORTH,
  CITYDOC_INWOOD,
  CITYDOC_MANSFIELD,
  CITYDOC_PRESTON,
  CITYDOC_UPTOWN,
];

export const URGENT_CARE_FOR_KIDS_LOCATION_IDS = [
  URGENT_CARE_FOR_KIDS_THE_WOODLANDS,
  URGENT_CARE_FOR_KIDS_MCKINNEY,
  URGENT_CARE_FOR_KIDS_WEST_UNIVERSITY,
  URGENT_CARE_FOR_KIDS_KATY,
  URGENT_CARE_FOR_KIDS_SPRING,
  URGENT_CARE_FOR_KIDS_KELLER,
  URGENT_CARE_FOR_KIDS_CEDAR_PARK,
  URGENT_CARE_FOR_KIDS_ROUND_ROCK,
  URGENT_CARE_FOR_KIDS_ALLEN,
  URGENT_CARE_FOR_KIDS_FRISCO,
  URGENT_CARE_FOR_KIDS_ALLIANCE,
];

export const CARE_FIRST_LOCATION_IDS = [CARE_FIRST_DALLAS, CARE_FIRST_DESOTO];

export const CHILDREN_URGENT_CARE_CHICAGO_LOCATION_IDS = [
  CHILDREN_URGENT_CARE_RIVER_FOREST,
  CHILDREN_URGENT_CARE_SKOKIE,
];

const IMMEDIATE_CLINIC_BELLEVUE = 'gJMjzp';
const IMMEDIATE_CLINIC_BOTHELL = 'gqoEEA';
const IMMEDIATE_CLINIC_BURIEN = 'gd81wg';
const IMMEDIATE_CLINIC_BURIEN_OCC_MED_NURSE_VISITS = 'AzbbWA';
const IMMEDIATE_CLINIC_CAPITOL_HILL = 'p8J740';
const IMMEDIATE_CLINIC_CROWN_HILL = '0e1Ow0';
const IMMEDIATE_CLINIC_EVERETT = 'gKvXQp';
const IMMEDIATE_CLINIC_KIRKLAND_IMMEDIATE = 'AGLojp';
const IMMEDIATE_CLINIC_LAKE_CITY = 'AGer1g';
const IMMEDIATE_CLINIC_LYNNWOOD = '0mEyv0';
const IMMEDIATE_CLINIC_POULSBO = 'goQmQA';
const IMMEDIATE_CLINIC_QUEEN_ANNE = 'A4bXNg';
const IMMEDIATE_CLINIC_REDMOND = 'pyXBJA';
const IMMEDIATE_CLINIC_SHORELINE = 'pjwKGg';
const IMMEDIATE_CLINIC_BURIEN_OCCUPATIONAL_MEDICINE_AND_REHABILITATION_CLINIC_BURIEN_OCC_MED =
  'AWeeeg';
const MULTICARE_INDIGO_URGENT_CARE_COVINGTON_INDIGO = 'p8OY8A';
const MULTICARE_INDIGO_URGENT_CARE_FEDERAL_WAY = 'AXXX2A';
const MULTICARE_INDIGO_URGENT_CARE_ISSAQUAH = 'gNeeLp';
const MULTICARE_INDIGO_URGENT_CARE_JAMES_CENTER = '0x8M60';
const MULTICARE_INDIGO_URGENT_CARE_KIRKLAND_INDIGO = '0m8Qzg';
const MULTICARE_INDIGO_URGENT_CARE_LACEY = 'gZ58rp';
const MULTICARE_INDIGO_URGENT_CARE_LAKE_STEVENS = 'go82k0';
const MULTICARE_INDIGO_URGENT_CARE_MARYSVILLE = 'pn88Q0';
const MULTICARE_INDIGO_URGENT_CARE_MILL_CREEK = 'AvZZ8A';
const MULTICARE_INDIGO_URGENT_CARE_OLYMPIA = 'A4EOwp';
const MULTICARE_INDIGO_URGENT_CARE_POINT_RUSTON = 'g544Kp';
const MULTICARE_INDIGO_URGENT_CARE_PUYALLUP_INDIGO = 'gQRRe0';
const MULTICARE_INDIGO_URGENT_CARE_RAINIER = '0eRPOA';
const MULTICARE_INDIGO_URGENT_CARE_TUKWILA = '0k8znA';
const MULTICARE_INDIGO_URGENT_CARE_TUMWATER = 'AWmGyg';
const MULTICARE_INDIGO_URGENT_CARE_WALLINGFORD = 'pRL6z0';
const MULTICARE_ROCKWOOD_URGENT_CARE_ARGONNE = 'gwQm3A';
const MULTICARE_ROCKWOOD_URGENT_CARE_DOWNTOWN_SPOKANE = 'A6RLMA';
const MULTICARE_ROCKWOOD_URGENT_CARE_LIBERTY_LAKE = 'g1ooEA';
const MULTICARE_ROCKWOOD_URGENT_CARE_SOUTH_HILL = '0OjZWg';
const MULTICARE_ROCKWOOD_URGENT_CARE_SPOKANE_VALLEY = 'pjPq80';
const MULTICARE_URGENT_CARE_AUBURN = 'g1PNqp';
const MULTICARE_URGENT_CARE_COVINGTON_MULTICARE = 'A6RLZA';
const MULTICARE_URGENT_CARE_GIG_HARBOR = '0xjnop';
const MULTICARE_URGENT_CARE_KENT = 'gZeDbp';
const MULTICARE_URGENT_CARE_LAKEWOOD = 'AWE2vg';
const MULTICARE_URGENT_CARE_PUYALLUP_MULTICARE = 'AzVnNA';
const MULTICARE_URGENT_CARE_SPANAWAY = 'A9QVlA';
const MULTICARE_URGENT_CARE_UNIVERSITY_PLACE = '0ry8Og';
const MULTICARE_URGENT_CARE_WEST_TACOMA = 'gJVKzg';

export const MULTICARE_LOCATIONS = [
  IMMEDIATE_CLINIC_BELLEVUE,
  IMMEDIATE_CLINIC_BOTHELL,
  IMMEDIATE_CLINIC_BURIEN,
  IMMEDIATE_CLINIC_BURIEN_OCC_MED_NURSE_VISITS,
  IMMEDIATE_CLINIC_CAPITOL_HILL,
  IMMEDIATE_CLINIC_CROWN_HILL,
  IMMEDIATE_CLINIC_EVERETT,
  IMMEDIATE_CLINIC_KIRKLAND_IMMEDIATE,
  IMMEDIATE_CLINIC_LAKE_CITY,
  IMMEDIATE_CLINIC_LYNNWOOD,
  IMMEDIATE_CLINIC_POULSBO,
  IMMEDIATE_CLINIC_QUEEN_ANNE,
  IMMEDIATE_CLINIC_REDMOND,
  IMMEDIATE_CLINIC_SHORELINE,
  IMMEDIATE_CLINIC_BURIEN_OCCUPATIONAL_MEDICINE_AND_REHABILITATION_CLINIC_BURIEN_OCC_MED,
  MULTICARE_INDIGO_URGENT_CARE_COVINGTON_INDIGO,
  MULTICARE_INDIGO_URGENT_CARE_FEDERAL_WAY,
  MULTICARE_INDIGO_URGENT_CARE_ISSAQUAH,
  MULTICARE_INDIGO_URGENT_CARE_JAMES_CENTER,
  MULTICARE_INDIGO_URGENT_CARE_KIRKLAND_INDIGO,
  MULTICARE_INDIGO_URGENT_CARE_LACEY,
  MULTICARE_INDIGO_URGENT_CARE_LAKE_STEVENS,
  MULTICARE_INDIGO_URGENT_CARE_MARYSVILLE,
  MULTICARE_INDIGO_URGENT_CARE_MILL_CREEK,
  MULTICARE_INDIGO_URGENT_CARE_OLYMPIA,
  MULTICARE_INDIGO_URGENT_CARE_POINT_RUSTON,
  MULTICARE_INDIGO_URGENT_CARE_PUYALLUP_INDIGO,
  MULTICARE_INDIGO_URGENT_CARE_RAINIER,
  MULTICARE_INDIGO_URGENT_CARE_TUKWILA,
  MULTICARE_INDIGO_URGENT_CARE_TUMWATER,
  MULTICARE_INDIGO_URGENT_CARE_WALLINGFORD,
  MULTICARE_ROCKWOOD_URGENT_CARE_ARGONNE,
  MULTICARE_ROCKWOOD_URGENT_CARE_DOWNTOWN_SPOKANE,
  MULTICARE_ROCKWOOD_URGENT_CARE_LIBERTY_LAKE,
  MULTICARE_ROCKWOOD_URGENT_CARE_SOUTH_HILL,
  MULTICARE_ROCKWOOD_URGENT_CARE_SPOKANE_VALLEY,
  MULTICARE_URGENT_CARE_AUBURN,
  MULTICARE_URGENT_CARE_COVINGTON_MULTICARE,
  MULTICARE_URGENT_CARE_GIG_HARBOR,
  MULTICARE_URGENT_CARE_KENT,
  MULTICARE_URGENT_CARE_LAKEWOOD,
  MULTICARE_URGENT_CARE_PUYALLUP_MULTICARE,
  MULTICARE_URGENT_CARE_SPANAWAY,
  MULTICARE_URGENT_CARE_UNIVERSITY_PLACE,
  MULTICARE_URGENT_CARE_WEST_TACOMA,
];

export const UNIVERSITY_URGENT_CARE_LOCATION_ID = 'A4dVxA';
export const DAVAM_URGENT_CARE = '0ORYYg';
export const BUMPS_N_BRUISES_LOCATION_ID = '0PLql0';
export const PEDIATRIC_URGENT_CARE_FORT_WORTH_VIDEO_VISIT_LOCATION_ID = 'A9RBlp';
export const MOBIL_MED = 'gLYYw0';

export const TELEMED_LOCATIONS_WITH_MINIMUM_AGE_REQUIREMENT = [CITYDOC_VIDEO_VISIT];

export const ALLIANCE_GROUP_ID = 'oLG5dQ';
export const CHILDRENS_URGENT_CARE_GROUP_ID = 'PLe7QR';
export const IMMEDIATE_CARE_MEDICAL_WALK_IN_GROUP_ID = 'eLy9GL';
export const NIGHTLIGHT_PEDIATRIC_URGENT_CARE = 'eLyKxQ';
export const METRO_URGENT_CARE_GROUP_ID = 'ALX3pJ';
export const MULTICARE_GROUP_ID = 'VLZqp5';
export const NEXT_CARE_GROUP_ID = 'mWBmLo';
export const STATE_OF_MICHIGAN_GROUP_ID = 'bQ2kap';
export const NEXT_CARE_SOLV_TESTING_GROUP_ID = '9pMRLE';
export const PM_PEDIATRICS_GROUP_ID = 'aLO0OW';
export const POP_CARE_PLUS_IMMEDIATE_CARE_GROUP_ID = 'RWdoLz';
export const PORTLAND_URGENT_CARE_GROUP_ID = 'nL8RQr';
export const PRO_HEALTH_GROUP_ID = 'bQ2rmQ';
export const RENOWN_URGENT_CARE_GROUP_ID = 'NQnnlQ';
export const URGENT_CARE_FOR_KIDS_GROUP_ID = 'aLOBp1';
export const WESTMED_MEDICAL_GROUP_ID = '2QAXaW';
export const WESTEX_URGENT_CARE_GROUP_ID = 'VLR0rQ';
export const MERCY_URGENT_CARE_GROUP_ID = 'mQj0L0';
export const LIFELINE_URGENT_CARE_GROUP_ID = 'mW61NL';
export const LITTLE_SPURS_PEDIATRIC_URGENT_CARE_GROUP_ID = 'oWgBXL';
export const CRUCIAL_CARE_GROUP_ID = 'eLya7L';
export const PRESTIGE_EMERGENCY_GROUP_ID = 'VLR98p';
export const RELIANT_VIRTUAL_VISIT = 'gwzrkp';
export const DR_MIKES_WALK_IN_CLINIC_GROUP_ID = 'xQwv2W';
export const INTEGRITY_URGENT_CARE_GROUP_ID = '4px81p';
export const FIRSTMED_URGENT_CARE_GROUP_ID = 'aLO0XW';
export const BOCA_REGIONAL_URGENT_CARE_GROUP_ID = 'eW41zQ';
export const FRANKLIN_PARK_URGENT_CARE_CENTER_GROUP_ID = 'kW7e3p';
export const URGENT_TEAM_GROUP_ID = 'PWv2EW';
export const PHYSICIANONE_URGENT_CARE_GROUP_ID = 'VLRYgp';
export const OCEAN_STATE_URGENT_CARE_GROUP_ID = 'dLJrJL';
export const WOODCREEK_PEDIATRICS_GROUP_ID = 'kW7RNL';
export const YOUR_DOCS_IN_GROUP_ID = 'kW7a3p';
export const TOTAL_ACCESS_GROUP_ID = 'rWKk9W';
export const BARTON_GROUP_ID = 'kQ9KLj';
export const AFC_EAST_TN_GROUP_ID = 'ALXV1Q';
export const GO_HEALTH_GROUP_ID = 'aLOYyQ';
export const INTELLIVISIT_GROUP_ID = 'LXYmZW';
export const JCMG_FAMILY_MEDICINE_GROUP_ID = 'JWV9wL';
export const JCMG_ENT_GROUP_ID = '9pmznL';
export const JCMG_GASTRONENTOOLGY_GROUP_ID = 'mQjm5W';
export const JCMG_INTERNAL_MEDICINE_GROUP_ID = 'aLO6kp';
export const CITY_OF_SEATTLE_GROUP_ID = 'rWKjmL';
export const KING_COUNTY_GROUP_ID = 'kW7xRQ';
export const COMPUNET_LABS_GROUP_ID = 'PLew7Q';
export const SEATTLE_CANCER_CARE_ALLIANCE_GROUP_ID = 'kpN03W';
export const APPLE_CARE_GROUP_ID = '5p553p';
export const SMART_CARE_MEDICAL_GROUP_ID = 'wpkPVL';
export const CROSSROADS_COMMUNITY_HOSPITAL_GROUP_ID = '6QagdL';
export const SEATTLE_FREE_COVID_TESTING_AURORA = 'gdLDbg';
export const SEATTLE_FREE_COVID_TESTING_AURORA_SYMPTOMATIC = 'A9m7wA';
export const SEATTLE_FREE_COVID_TESTING_SODO = '0xvwjp';
export const SEATTLE_FREE_COVID_TESTING_SODO_SYMPTOMATIC = 'gbNDlg';
export const SEATTLE_FREE_COVID_TESTING_TEST_QUEUE = '07Qjvp';
export const UCSD_HEALTH_GROUP_ID = 'WVezoL';
