import { Switch } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Stack, Text } from '@solvhealth/jigsaw';
// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { formShape } from 'rc-form';
import React, { PureComponent } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import withThemeProvider from '~/components/SolvDesignSystem/util/withThemeProvider';
import { analyticsTrackEvent } from '../../../core/analytics';
import { SIGN_UP_KEEP_ME_LOGGED_IN } from '../../../core/analytics/events';
import { isNativeApp } from '../../../core/util/device';
import { isClientSide } from '../../../core/util/system';
import { PERSISTED_LOGIN_ID } from './ids';

/**
 * @returns boolean
 */
function isPrivateDevice() {
  return isNativeApp() && isClientSide() && window.isPrivateDevice;
}

type Props = {
  className?: string;
  form: formShape;
  t: TFunction<['common']>;
};

type State = any;

class PersistedLoginInput extends PureComponent<Props, State> {
  state = {
    checked: isPrivateDevice(),
    isWarningDialogOpen: false,
  };

  componentDidMount = () => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ [PERSISTED_LOGIN_ID]: this.state.checked });
  };

  getInputProps = () => {
    const { getFieldValue, getFieldProps } = this.props.form;

    return {
      ...getFieldProps(PERSISTED_LOGIN_ID),
      id: PERSISTED_LOGIN_ID,
      value: getFieldValue(PERSISTED_LOGIN_ID) || false,
      type: 'checkbox',
    };
  };

  openDialog = () => {
    this.setState({ checked: true, isWarningDialogOpen: true });
    analyticsTrackEvent(SIGN_UP_KEEP_ME_LOGGED_IN, {
      action: 'open dialog',
    });
  };

  handleClick = () => {
    const { checked } = this.state;
    analyticsTrackEvent(SIGN_UP_KEEP_ME_LOGGED_IN, {
      action: checked ? 'uncheck' : 'check',
    });
    if (checked) {
      this.setState({ checked: false, isWarningDialogOpen: false });
    } else {
      this.openDialog();
    }
  };

  render = () => {
    const { className, t } = this.props;
    const { checked, isWarningDialogOpen } = this.state;

    return (
      <Stack>
        <FormControlLabel
          checked={checked}
          className={className}
          control={
            <Switch
              checked={checked}
              color="primary"
              onClick={this.handleClick}
              {...this.getInputProps()}
            />
          }
          label={<Text>{t('common:auth.otpForm.keepMeLoggedIn')}</Text>}
        />
        {!isPrivateDevice() && (
          <Collapse in={isWarningDialogOpen} timeout="auto">
            <Text muted variant="body2">
              {t('common:auth.otpForm.persistedLoginWarning')}
            </Text>
          </Collapse>
        )}
      </Stack>
    );
  };
}

export default withThemeProvider(withTranslation(['common'])(PersistedLoginInput));
