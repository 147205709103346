import React, { PureComponent, ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ListItem from '@material-ui/core/ListItem';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { colors } from '../../constants/colors';
import { fontSize } from '../../constants/text';
import { biggerThan, smallerThanOrEqualTo } from '../../core/util/styledComponents';
import AutocompleteIcon from './AutocompleteIcon';
import SecondaryLabel from './Homepage/SecondaryLabel';

const TIMEOUT = 100;

const Root = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & .item-enter {
    opacity: 0;
    transform: translateY(-5px);
  }

  & .item-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  & .item-exit {
    opacity: 1;
    transform: translateY(0);
  }

  & .item-exit-active {
    opacity: 0;
    transform: translateY(-5px);
  }
`;

const Icon = styled(AutocompleteIcon)`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  ${biggerThan.sm`
    margin-right: 10px;
  `}
`;

const Suggestion = styled.div`
  ${smallerThanOrEqualTo.sm`
    margin-left: 10px;
  `}
`;

const Item = styled(ListItem)<{ $narrow?: boolean; $arrow?: boolean; $hoverEffect?: boolean }>`
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  min-height: ${(props) => (props.$narrow ? '20' : '50')}px;
  border-top: 1px solid ${colors.borderLine};
  background-color: ${colors.white};
  ${(props) =>
    props.$arrow &&
    css`
      background-repeat: no-repeat;
      background-image: url(/images/icons/arrow/grey_forward.svg);
      background-size: 8px 13px;
      background-position: right 10px center;
    `};

  transition: background-color 100ms ease;
  ${(props) =>
    props.$hoverEffect &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${colors.greyHounds};
      }
    `}

  &:last-child {
    border-bottom: 1px solid ${colors.borderLine};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const Primary = styled.div`
  color: ${colors.myRightFoot};
  font-size: ${fontSize.default};
  line-height: 20px;

  & em {
    color: ${colors.crunchBerry};
    font-style: normal;
  }
`;

const Secondary = styled.div`
  color: ${colors.lightText};
  font-size: ${fontSize.extraSmall};
  line-height: ${fontSize.mediumSmall};
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  right: 10%;
  top: 20px;
`;

export interface AutoCompleteResult {
  primary: string;
  primaryNode?: ReactNode;
  secondary?: string;
  key: string;
  icon?: any;
  onClick?: () => void;
  narrow?: boolean;
  showPositionLoader?: boolean;
  static?: boolean;
}

interface BaseSuggestionDoc {
  provider_type: 'urgent_care' | 'provider_group';
  type: 'service' | 'location' | 'symptom';
  confidence_trump: Boolean;
  confidence_trump_term: String;
  display_provider_type: String;
  frequency: Number;
  highlight: String;
  id: String;
  is_covid_related: Boolean;
  name: String;
  require_hard_filter: Boolean;
  score: Number;
}
interface SymptomDoc extends BaseSuggestionDoc {
  type: 'symptom';
  canonical: String;
  is_emergency: false;
}
interface ServiceDoc extends BaseSuggestionDoc {
  type: 'service';
}
interface LocationDoc extends BaseSuggestionDoc {
  type: 'location';
  city: String;
  display_name_primary: String;
  display_name_secondary: String;
  lat_long: String;
  location_images?: {
    full_res: URL;
    webp: URL;
    jp2: URL;
  };
  state: String;
  zip_code: String;
}

export type SuggestionDoc = ServiceDoc | LocationDoc | SymptomDoc;

interface Props {
  className?: string;
  position?: any;
  results?: (AutoCompleteResult & { fullDoc?: SuggestionDoc })[];
}

export default class AutocompleteResults extends PureComponent<Props> {
  render() {
    const { className, results, position } = this.props;

    return (
      <Root className={className}>
        <TransitionGroup component={null}>
          {results?.map((result) => (
            <CSSTransition classNames="item" key={result.key} timeout={TIMEOUT}>
              <Item
                $arrow={false}
                $hoverEffect={!!result.onClick}
                $narrow={result.narrow}
                /* @ts-ignore - Open github issue: https://github.com/mui-org/material-ui/issues/15827#issuecomment-809209533*/
                component="li"
                onClick={result.onClick}
              >
                <Icon suggestion={result} />

                <Suggestion>
                  <Primary dangerouslySetInnerHTML={{ __html: result.primary }} />
                  <SecondaryLabel result={result} />
                </Suggestion>
                {result.showPositionLoader && position?.gettingPosition && (
                  <StyledCircularProgress size={13} />
                )}
              </Item>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Root>
    );
  }
}
