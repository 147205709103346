import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import { insuranceClear, insuranceFormSubmission } from '~/actions/insurance';
import { setActiveLocation } from '~/actions/location';
import { setBookingIsPremium, setBookingStateCode, setLocationId } from '~/actions/newBooking';
import {
  TOUCH_ADD_INSURANCE_APP_BOOKING,
  TOUCH_ADD_INSURANCE_MARKETPLACE_APP_BOOKING,
  TYPE_SELF,
  TYPE_SKIP,
} from '~/constants';
import {
  PAYMENT_OPTION_DECIDE_LATER,
  PAYMENT_OPTION_INSURANCE,
  PAYMENT_OPTION_SELF_PAY,
} from '../BookingPaymentMethod/constants';
import { analyticsTrackEvent } from '~/core/analytics';
import { FINALIZE_PAGE, PHOTO_ID_UPLOAD } from '~/core/util/bookingFlowRouting';
import {
  allowsSelfPay,
  isDentalLocation,
  isInsuranceDisabled,
  isPaymentCollectionDuringBookingEnabled,
  isPhotoIdUploadEnabledForEntrypoint,
  isProviderGroupLocation,
  isSelfPayOnly,
} from '~/core/util/location';
import { setCurrentlySelectedLocation } from '~/ducks/currentlySelectedLocation';
import { locationResponseFormatter } from '~/reducers/formatters/location';
import { PositionState } from '~/reducers/position';
import history from '../../core/history';
import { TELEMED_TEST_IS_ON } from '../../config';

export const getState = (position?: PositionState) => {
  const label = position?.result?.label;
  if (!label) return null;

  const split = label.split(',').map((l) => l.trim());
  if (split.length !== 2) return null;

  return split[1];
};

/**
 * Set a new booking location in state, updating all the many relevant areas
 */
export const changeBookingLocation = (stateData: any, dispatch: Dispatch<any>) => {
  const {
    code,
    location,
    location: { id: locationId },
  } = stateData;

  const formatted = locationResponseFormatter(location);

  batch(() => {
    dispatch(setBookingIsPremium(true));
    dispatch(setLocationId(locationId));
    dispatch(setBookingStateCode(code));
    dispatch(setActiveLocation(formatted));
    dispatch(setCurrentlySelectedLocation(formatted));
  });
};

/** Create an insurance form object for use with the insuranceFormSubmission() action
 * to set Mary's payment method to either self payment or decide later (unset) */
export const insuranceForSelfPayOrSkip = (
  firstName: string | undefined,
  lastName: string | undefined,
  location: LocationInterface,
  selfPayOrSkip: 'self-pay' | 'decide later'
) => {
  let insurerType = '';

  if (selfPayOrSkip === 'self-pay') {
    insurerType = isInsuranceDisabled(location, true) ? TYPE_SKIP : TYPE_SELF;
  } else if (selfPayOrSkip === 'decide later') {
    insurerType = TYPE_SKIP;
  }

  return {
    insurerType,
    firstName,
    lastName,
  };
};

/** Transitions the booking flow to the next step after they've chosen to self-pay (or if that is the
 * only available payment method).  */
export const transitionToSelfPay = (
  dispatch: Dispatch<any>,
  /** The booking user's first name. Available via a form in the profile entry page,
   * and from the newBooking.profile object otherwise */
  firstName: string | undefined,
  lastName: string | undefined,
  location: LocationInterface
) => {
  const newForm = insuranceForSelfPayOrSkip(firstName, lastName, location, 'self-pay');
  dispatch(insuranceFormSubmission(newForm));

  if (isPhotoIdUploadEnabledForEntrypoint(location, 'solvAttributedBooking')) {
    history.push(`${PHOTO_ID_UPLOAD}/ab`);
    return;
  }

  history.push('/finalize');
};

export const transitionToInsuranceEntry = (
  dispatch: Dispatch<any>,
  location: LocationInterface
) => {
  // Reset Insurance data (which includes "self pay") in case the user previously picked self pay and then
  // later chose insurance, such as via hitting 'Back'
  dispatch(insuranceClear());

  if (isDentalLocation(location)) {
    // TODO: Verify that we still want to use these same events when navigating from
    // payment method screen -> insurance, instead of directly to insurance from BookingProfile
    analyticsTrackEvent(TOUCH_ADD_INSURANCE_MARKETPLACE_APP_BOOKING);
    history.push('/add-insurance/dental/ab');
    return;
  }

  analyticsTrackEvent(TOUCH_ADD_INSURANCE_APP_BOOKING);
  history.push('/add-insurance/ab');
};

/** Transitions the Booking Flow to the Finalize page with no payment type selected */
export const skipChoosingPaymentMethod = (dispatch: Dispatch<any>, location: LocationInterface) => {
  dispatch(insuranceClear());

  /* Handle the photo upload step. Note that the insurance and self-pay options each have
   * their own specific ways of handling this, separately */
  if (isPhotoIdUploadEnabledForEntrypoint(location, 'solvAttributedBooking')) {
    history.push(`${PHOTO_ID_UPLOAD}/ab`);
    return;
  }

  history.push(FINALIZE_PAGE);
};

/** Transitions the Booking Flow to the next payment-related step, either:
 * 1. The Insurance flow
 * 2. The Self-Pay flow
 * 3. The Payment Method screen where the user can pick between the above two or to decide later
 */
export const transitionToPaymentStep = (
  dispatch: Dispatch<any>,
  location: LocationInterface,
  /** The booking user's first name. Available via a form in the profile entry page,
   * and from the newBooking.profile object otherwise */
  firstName?: string,
  lastName?: string
) => {
  // This flag is actually intended to skip payment method selection, despite the name
  if (isInsuranceDisabled(location, true)) {
    if (isPaymentCollectionDuringBookingEnabled(location)) {
      history.push(`payments/add-card-bf/${PAYMENT_OPTION_DECIDE_LATER}`);
    } else {
      skipChoosingPaymentMethod(dispatch, location);
    }
  }
  // Accepts insurance AND accepts self pay (not self pay only)
  // Provider group bookings do not use the payment method screen
  else if (
    !isProviderGroupLocation(location) &&
    allowsSelfPay(location) &&
    !isSelfPayOnly(location)
  ) {
    history.push('/paymentMethod');
  }
  // self pay only or telemed test on
  else if (isSelfPayOnly(location) || (TELEMED_TEST_IS_ON && location.isOpaque)) {
    if (isPaymentCollectionDuringBookingEnabled(location)) {
      history.push(`payments/add-card-bf/${PAYMENT_OPTION_SELF_PAY}`);
    } else {
      transitionToSelfPay(dispatch, firstName, lastName, location);
    }
  }
  // is dental location. Note that dental locationsare  deprecated; let's consider removing long-term
  else if (isDentalLocation(location)) {
    analyticsTrackEvent(TOUCH_ADD_INSURANCE_MARKETPLACE_APP_BOOKING);
    history.push('/add-insurance/dental/ab');
  } else {
    // add insurance
    if (isPaymentCollectionDuringBookingEnabled(location)) {
      history.push(`payments/add-card-bf/${PAYMENT_OPTION_INSURANCE}`);
    } else {
      transitionToInsuranceEntry(dispatch, location);
    }
  }
};
