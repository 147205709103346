import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import Button, { ButtonVariations } from '../SolvPatternLibrary/Button';
import { fontSize } from '../../constants/text';
import {
  handleAcceptWebPushPermission,
  handleDenyWebPushPermission,
  handleAcceptIosPushPermission,
} from './util';
import { emptyFunction } from '../../core/util/function';
import { CREATE_WEB_PUSH_SUBSCRIPTION } from '../../sagas/webPushSubscriptions';
import withNotifications from '../../core/notifications/withNotifications';
import { isNativeApp } from '../../core/util/device';

const ActionButton = styled(Button)`
  font-size: ${fontSize.mediumSmall};
  line-height: ${fontSize.mediumSmall};
`;

const mapDispatchToProps = (dispatch: any) => ({
  createWebPushSubscription: (accountId: any, subscription: any) =>
    dispatch({
      type: CREATE_WEB_PUSH_SUBSCRIPTION,
      accountId,
      subscription,
    }),
});

type OwnPromptProps = {
  handleDenyCustomBehavior?: (...args: any[]) => any;
  handleAcceptCustomBehavior?: (...args: any[]) => any;
  webPushOptInOrigin: string;
  createWebPushSubscription: (...args: any[]) => any;
  notificationsSubscribe: (...args: any[]) => any;
  accountId: string;
  callbackUponSubscribe?: (...args: any[]) => any;
  promptCopy?: any;
};

type PromptProps = OwnPromptProps & typeof Prompt.defaultProps;

class Prompt extends PureComponent<PromptProps> {
  static MODAL_ID = 'prompt';

  static defaultProps = {
    handleAcceptCustomBehavior: emptyFunction,
    handleDenyCustomBehavior: emptyFunction,
    callbackUponSubscribe: emptyFunction,
    promptCopy:
      'Looks like your notifications are blocked' +
      " for Solv! You'll need to unblock them in your browser settings," +
      ' then click CONTINUE.',
  };

  handleAccept = () => {
    const {
      handleAcceptCustomBehavior,
      webPushOptInOrigin,
      notificationsSubscribe,
      createWebPushSubscription,
      accountId,
      callbackUponSubscribe,
    } = this.props;

    handleAcceptCustomBehavior();

    if (isNativeApp()) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      return handleAcceptIosPushPermission();
    }

    return handleAcceptWebPushPermission(webPushOptInOrigin, notificationsSubscribe)
      .then((subscribeObject: any) => createWebPushSubscription(accountId, subscribeObject))
      .then(callbackUponSubscribe)
      .catch(() => handleDenyWebPushPermission(webPushOptInOrigin, true));
  };

  render = () => {
    const { promptCopy } = this.props;

    return (
      <>
        <DialogTitle>Solv would like to send you notifications.</DialogTitle>

        <DialogContent>
          <DialogContentText>{promptCopy}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <ActionButton onClick={this.handleAccept} variation={ButtonVariations.link}>
            CONTINUE
          </ActionButton>
        </DialogActions>
      </>
    );
  };
}

export default connect(null, mapDispatchToProps)(withNotifications(Prompt));
