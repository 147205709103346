import {
  DAPI_HOST,
  DEFAULT_COBRANDED_SRP_RADIUS,
  DEFAULT_SEARCH_RADIUS,
  METERS_IN_A_MILE,
} from '../../config/index';
import {
  COVID_COST_TYPE_NO_COST,
  ORIGIN_REACT_MOBILE_APP,
  PATIENT_TYPE_ADULTS,
  PATIENT_TYPE_KIDS,
} from '../../constants/index';
import logger from '../logger/index';
import {
  getStateCodeFromLabel,
  isTraditionalSearch,
  shouldExcludeServiceSearchesOnlyLocationsInResults,
  shouldIncludeCovidTestingOnlyLocationsInResults,
} from '../util/search';
import { convertMilestoMeters } from '../util/geo';
import { roundLatOrLng } from '~/core/util/string';
import { HOME_HEALTHCARE_DB } from '~/constants/category';
import { URGENT_CARE_VALUE } from '~/components/Home/Tiles/ProviderTypes';
import {
  AcceptedInsurance,
  SolvRatingMinimum,
  SrpSortOrder,
  VisitMode,
} from '~/reducers/searchPreferences';

type FilterType = {
  key: string;
  value: string | string[];
}[];

export const DEFAULT_SEARCH_RESULT_LIMIT = '20';

const LOCATION_FIELDS = [
  'image_links',
  'appointment_dates',
  'recent_bookings',
  'scores',
  'hours',
  'hours_default',
  'adjectives',
  'attributes',
  'services',
  'beyond_next_day_limit',
  'is_next_day_appointments_disabled',
  'is_beyond_next_day_appointments_enabled',
  'services_obj',
  'timezone',
  'groups',
  'special_operating_hours',
  'payment_account_id',
  'accepted_insurance_types',
  'accreditations',
  'fax',
  'display_name_primary',
  'display_name_secondary',
  'display_name_alternate',
  'accepted_insurances',
  'accepted_payment_methods',
  'time_zone',
  'category',
  'custom_booking_message',
  'id',
  'name',
  'brand_name',
  'brand_url',
  'address',
  'subpremise',
  'city',
  'state',
  'zip_code',
  'phone',
  'lat_long',
  'source',
  'is_bookable',
  'is_viewable',
  'is_solv_partner',
  'is_in_marketplace',
  'appointment_slots',
  'appointment_interval',
  'booking_url',
  'distance_from_current_location',
  'active',
  'npi',
  'geo',
  'email',
  'google_place_id',
  'status',
  'website',
  'last_walkin_appointment_date',
  'disable_reservations_until',
  'appointment_interval_reservations',
  'tracking_properties',
  'rating_solv',
  'rating_solv_count',
  'rating_non_solv',
  'rating_non_solv_count',
  'is_test_location',
  'is_paperwork_enabled',
  'is_telemed',
  'is_standalone_telemed',
  'telemed_sla',
  'google_review_source',
  'is_performance_pricing_enabled',
  'is_marketplace_priority',
  'has_next_in_line',
  'has_provider_schedule',
  'is_mobile_check_in_enabled',
  'is_uber_enabled',
  'review_flow_type',
  'live_date',
  'offboarded_date',
  'contract_signed_date',
  'google_feature_id',
  'ucl_id',
  'accepted_insurance_types',
  'description_variation_test',
  'last_verified_on',
  'inside_of',
  'service_prices',
  'display_address',
  'rating_wait_time',
  'rating_bedside_manner',
  'rating_staff_friendliness',
  'rating_facility_cleanliness',
  'rating_quality_of_care',
  'rating_wait_time_count',
  'rating_bedside_manner_count',
  'rating_staff_friendliness_count',
  'rating_facility_cleanliness_count',
  'rating_quality_of_care_count',
  'is_pediatrics_only',
  'platform_type',
  'is_pediatric_patients_accepted',
  'is_adult_patients_accepted',
  'accepted_insurers',
  'specialties',
  'market',
  'top_review_json',
  'is_photo_id_upload_enabled',
  'photo_id_upload_entrypoints',
  'is_photo_id_front_required',
  'is_photo_id_back_required',
  'associated_telemed_location_id',
  'is_asap_telemed_enabled',
  'is_insurance_photo_upload_and_ocr_disabled',
  'is_covid_test_free',
  'are_booking_prescreen_questions_enabled',
  'booking_prescreen_questions_text',
  'is_dosage_dropdown_enabled',
  'is_reason_for_visit_dropdown_enabled',
  'reason_for_visit_dropdown_options',
  'has_boost',
  'is_in_service_searches_only',
  'is_email_required_for_booking_widget',
  'is_birth_sex_required',
  'is_address_hidden',
  'is_cdp_srp_address_required',
  'external_booking_url',
];

const LOCATION_FIELDS_WITH_SLOTS = [...LOCATION_FIELDS, 'slots'];

export const getSearchUrl = ({
  searchObj,
  patientType,
  locationCategories = [],
  providerType = null,
  showOnlyPartners = true,
  limit,
  page,
  radius = DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE,
  requestedServices,
  isServiceHardFilter,
  reasonForVisit,
  visitMode,
  isTelemedConnect,
  covidCostType,
  sortOrder,
  solvRatingMinimum,
  acceptedInsurance,
}: any) => {
  const isTelemedSearch = visitMode === VisitMode.Telemed;

  let searchRadius = isTelemedSearch ? 0 : radius;

  if (searchObj.cobrandedSrpLocation) {
    if (searchRadius === convertMilestoMeters(DEFAULT_SEARCH_RADIUS)) {
      searchRadius = convertMilestoMeters(DEFAULT_COBRANDED_SRP_RADIUS);
    }
  }

  let url = `${DAPI_HOST}/v4/locations?limit=${limit}&radius=${searchRadius}&page=${page}&origin=${ORIGIN_REACT_MOBILE_APP}`;

  const isCovidTestFree = covidCostType ? covidCostType.value === COVID_COST_TYPE_NO_COST : false;

  // don't add lat/long if telemed since we're searching by state
  if (!isTelemedSearch) {
    if (searchObj.latitude && searchObj.longitude) {
      url = `${url}&lat=${roundLatOrLng(searchObj.latitude)}&long=${roundLatOrLng(
        searchObj.longitude
      )}`;
    } else if (searchObj.zipCode) {
      url = `${url}&zip_code=${searchObj.zipCode}`;
    } else {
      logger.error(new Error('invalid search?'), (scope) => {
        scope.setContext('search parameters', {
          searchObj,
          patientType,
        });
        return scope;
      });
      url = `${url}&zip_code=95110`;
    }
  }

  const filters: FilterType = [{ key: 'locations.is_test_location', value: 'false' }];

  if (searchObj.cobrandedSrpLocation && isCovidTestFree) {
    filters.push({ key: 'location_features.is_covid_test_free', value: 'true' });
  }

  if (isTelemedConnect) {
    filters.push({ key: 'location_features.is_telemed_connect', value: 'true' });
  }

  if (showOnlyPartners) {
    filters.push(
      { key: 'locations.platform_type', value: 'urgent_care' },
      { key: 'locations.is_solv_partner', value: 'true' },
      { key: 'locations.is_bookable', value: 'true' },
      { key: 'location_features.has_boost', value: 'true' }
    );
    if (!isTelemedSearch) {
      filters.push({ key: 'locations.is_in_marketplace', value: 'true' });
    }
    if (solvRatingMinimum && solvRatingMinimum > 0) {
      filters.push({ key: 'locations.rating_solv', value: `${solvRatingMinimum}` });
    }
  } else {
    if (isTraditionalSearch(providerType)) {
      filters.push(
        { key: 'specialties.name', value: providerType },
        { key: 'locations.platform_type', value: 'traditional' }
      );
    } else {
      filters.push({
        key: 'locations.platform_type',
        value: ['urgent_care', 'unknown'],
      });
    }

    filters.push({ key: 'location_features.has_boost', value: 'false' });
  }

  if (!shouldIncludeCovidTestingOnlyLocationsInResults(requestedServices, reasonForVisit)) {
    filters.push({ key: 'locations.is_covid_testing_only', value: 'false' });
  }

  if (shouldExcludeServiceSearchesOnlyLocationsInResults(requestedServices)) {
    filters.push({ key: 'location_features.is_in_service_searches_only', value: 'false' });
  }

  filters.push(
    { key: 'locations.is_provider', value: 'false' },
    { key: 'locations.is_viewable', value: 'true' }
  );

  if (isTelemedSearch) {
    const telemedState = getStateCodeFromLabel(searchObj);
    filters.push({ key: 'locations.is_telemed', value: 'true' });
    if (telemedState) {
      filters.push({ key: 'locations.state', value: telemedState });
    }
  }

  filters.push({ key: 'locations.status', value: 'open' });
  url = `${url}&location_categories=${locationCategories.join()}`;
  if (showOnlyPartners && patientType.value === PATIENT_TYPE_KIDS) {
    filters.push({
      key: 'location_attributes_flat.is_pediatric_patients_accepted',
      value: 'true',
    });
  } else if (showOnlyPartners && patientType.value === PATIENT_TYPE_ADULTS) {
    filters.push({
      key: 'location_attributes_flat.is_adult_patients_accepted',
      value: 'true',
    });
  }

  if (requestedServices && requestedServices.length && isServiceHardFilter) {
    filters.push({ key: 'services.id', value: requestedServices.join() });
  }

  if (acceptedInsurance) {
    filters.push({
      key: `location_accepted_insurance_types.${acceptedInsurance}`,
      value: 'true',
    });
  }

  if (filters.length > 0) {
    const filtersString = filters.map((filter) => `${filter.key}:${filter.value}`).join(';');
    url = `${url}&filters=${filtersString}`;
  }

  if (showOnlyPartners) {
    url += `&fields=${LOCATION_FIELDS_WITH_SLOTS.join(',')}`;
  } else {
    url += `&fields=${LOCATION_FIELDS.join(',')}`;
  }

  if (sortOrder === SrpSortOrder.Rating) {
    url += `&order_by=${sortOrder} DESC`;
  }

  return url;
};

export const getSolvPartnersSearchUrl = (
  searchObj: any,
  patientType: any,
  locationCategories: string[] = [],
  providerType: any,
  page: any,
  radius: any,
  requestedInsurance: any,
  requestedServices: any,
  isServiceHardFilter: any,
  isCovidTestRelatedSearch: boolean,
  reasonForVisit: any,
  visitMode: VisitMode,
  covidCostType: any,
  sortOrder: SrpSortOrder,
  solvRatingMinimum: SolvRatingMinimum,
  acceptedInsurance: AcceptedInsurance
) =>
  getSearchUrl({
    searchObj,
    patientType,
    locationCategories,
    providerType,
    requestedServices,
    showOnlyPartners: true,
    limit: DEFAULT_SEARCH_RESULT_LIMIT,
    page,
    radius,
    requestedInsurance,
    isServiceHardFilter,
    isCovidTestRelatedSearch,
    reasonForVisit,
    visitMode,
    covidCostType,
    sortOrder,
    solvRatingMinimum,
    acceptedInsurance,
  });

export const getNonSolvPartnersSearchUrl = (
  searchObj: any,
  patientType: any,
  locationCategories: string[] = [],
  providerType: any,
  page: any,
  radius: any,
  requestedInsurance: any,
  requestedServices: any,
  isServiceHardFilter: any,
  isCovidTestRelatedSearch: boolean,
  reasonForVisit: any,
  visitMode: VisitMode,
  covidCostType: any,
  sortOrder: SrpSortOrder,
  solvRatingMinimum: SolvRatingMinimum
) =>
  getSearchUrl({
    searchObj,
    patientType,
    providerType,
    locationCategories,
    showOnlyPartners: false,
    limit: DEFAULT_SEARCH_RESULT_LIMIT,
    page,
    radius,
    requestedInsurance: null,
    requestedServices,
    isServiceHardFilter,
    isCovidTestRelatedSearch,
    reasonForVisit,
    visitMode,
    covidCostType,
    sortOrder: null,
    solvRatingMinimum: null,
  });

export const getAtHomeCarePartnersSearchUrl = (
  searchObj: any,
  patientType: any,
  _locationCategories: any,
  _providerType: any,
  _page: any,
  _radius: any,
  _requestedInsurance: any,
  requestedServices: any,
  isServiceHardFilter: any,
  isCovidTestRelatedSearch: boolean,
  reasonForVisit: any,
  visitMode: VisitMode,
  covidCostType: any,
  acceptedInsurance: AcceptedInsurance
) =>
  getSearchUrl({
    searchObj,
    patientType,
    providerType: URGENT_CARE_VALUE,
    locationCategories: [HOME_HEALTHCARE_DB],
    showOnlyPartners: true,
    limit: DEFAULT_SEARCH_RESULT_LIMIT,
    page: 1,
    radius: METERS_IN_A_MILE * 10,
    requestedInsurance: null,
    requestedServices,
    isServiceHardFilter,
    isCovidTestRelatedSearch,
    reasonForVisit,
    visitMode,
    covidCostType,
    acceptedInsurance,
  });

export const getNearbyCitiesUrl = (latitude: any, longitude: any, limit = 4, radius = 35) =>
  `${DAPI_HOST}/v4/nearby/cities?limit=${limit}&radius=${DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE}`;

export const getNearbyCitiesByCityUrl = (city: string, state: string, limit = 4, radius = 35) =>
  `${DAPI_HOST}/v2/locations/cities?limit=${limit}&radius=${
    DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE
  }&filters=location.city:${city},location.state:${state}`;

export const getNearbyBrandsUrl = (latitude: any, longitude: any, limit = 4, radius = 35) =>
  `${DAPI_HOST}/v4/nearby/brands?limit=${limit}&radius=${DEFAULT_SEARCH_RADIUS * METERS_IN_A_MILE}`;
