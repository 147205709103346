import { INSURANCE_AUTOCOMPLETE, BOOKING_RESPONSE_RECEIVED } from '../constants/index';

export default function autocompleteReducer(state = {}, action: any) {
  switch (action.type) {
    case INSURANCE_AUTOCOMPLETE:
      return {
        ...state,
        insurerName: action.payload.value,
      };
    case BOOKING_RESPONSE_RECEIVED:
      return {
        ...state,
        insurerName: '',
      };
    default:
      return state;
  }
}
