import { isEmptyString } from './string';
import { apiGetJson } from '../dapi';
import { getSymptomsIsEmergencyUrl } from '../dapi/symptom';
import logger from '../logger/index';
import { DEFAULT_URGENT_CARE_REASON_FOR_VISIT } from '../../config/index';
import {
  ALLERGIST_VALUE,
  CHIROPRACTOR_VALUE,
  DENTIST_VALUE,
  DERMATOLOGIST_VALUE,
  OB_GYN_VALUE,
  OPTOMETRIST_VALUE,
  URGENT_CARE_VALUE,
  IMAGING_VALUE,
} from '../../components/Home/Tiles/ProviderTypes';
import { getLocationSpecialties, getLocationSpecialtyValue } from './location';
import { isEmptyArray } from './array';
import { isServerSide } from './system';
import { isEmpty } from './empty';

const fromArrayToReactSelectObjArray = (symptomArray: any) => {
  const resp: any = [];
  symptomArray.forEach((element: any) =>
    resp.push({
      value: element.name.toLowerCase(),
      label: element.name.toLowerCase(),
      isEmergency: element.is_emergency,
      locationCategory: element.location_category,
    })
  );
  return resp;
};

const DEFAULT_DENTIST_REASON_FOR_VISIT = 'See a dentist';
const DEFAULT_DERMATOLOGIST_REASON_FOR_VISIT = 'See a dermatologist';
const DEFAULT_OPTOMETRIST_REASON_FOR_VISIT = 'See an optometrist';
const DEFAULT_OB_GYN_REASON_FOR_VISIT = 'See an ob/gyn';
const DEFAULT_CHIROPRACTOR_REASON_FOR_VISIT = 'See a chiropractor';
const DEFAULT_ALLERGIST_REASON_FOR_VISIT = 'See an allergist';
const DEFAULT_IMAGING_REASON_FOR_VISIT = 'Schedule imaging';

export const DEFAULT_REASON_FOR_VISITS = [
  DEFAULT_URGENT_CARE_REASON_FOR_VISIT,
  DEFAULT_DENTIST_REASON_FOR_VISIT,
  DEFAULT_DERMATOLOGIST_REASON_FOR_VISIT,
  DEFAULT_OPTOMETRIST_REASON_FOR_VISIT,
  DEFAULT_OB_GYN_REASON_FOR_VISIT,
  DEFAULT_CHIROPRACTOR_REASON_FOR_VISIT,
  DEFAULT_ALLERGIST_REASON_FOR_VISIT,
];

const getDefaultReasonForVisitForProviderType = (providerType: any) => {
  switch (providerType) {
    case DENTIST_VALUE:
      return DEFAULT_DENTIST_REASON_FOR_VISIT;
    case DERMATOLOGIST_VALUE:
      return DEFAULT_DERMATOLOGIST_REASON_FOR_VISIT;
    case OPTOMETRIST_VALUE:
      return DEFAULT_OPTOMETRIST_REASON_FOR_VISIT;
    case OB_GYN_VALUE:
      return DEFAULT_OB_GYN_REASON_FOR_VISIT;
    case CHIROPRACTOR_VALUE:
      return DEFAULT_CHIROPRACTOR_REASON_FOR_VISIT;
    case ALLERGIST_VALUE:
      return DEFAULT_ALLERGIST_REASON_FOR_VISIT;
    case IMAGING_VALUE:
      return DEFAULT_IMAGING_REASON_FOR_VISIT;
    case URGENT_CARE_VALUE:
    default:
      return DEFAULT_URGENT_CARE_REASON_FOR_VISIT;
  }
};

const getDefaultReasonForVisitForLocation = (location: any) => {
  const specialty = getLocationSpecialtyValue(location);
  return getDefaultReasonForVisitForProviderType(specialty);
};

const isDefaultReasonForVisit = (reasonForVisit: any) =>
  DEFAULT_REASON_FOR_VISITS.includes(reasonForVisit);

const decodeReasonForVisit = (reasonForVisit: any) => {
  if (isServerSide() || isEmpty(reasonForVisit)) {
    return reasonForVisit;
  }

  return decodeURIComponent(reasonForVisit);
};

const conditionallyToggleEmergencyMessage = (newSymptom: any, toggleFunction: any) => {
  if (isEmptyString(newSymptom)) {
    toggleFunction(false);
    return;
  }

  apiGetJson(getSymptomsIsEmergencyUrl(newSymptom))
    .then((response: any) => {
      if (response.data.is_emergency) {
        toggleFunction(true);
      } else {
        toggleFunction(false);
      }
    })
    .catch((err: any) => {
      logger.debug(err);
      toggleFunction(false);
    });
};

export {
  conditionallyToggleEmergencyMessage,
  fromArrayToReactSelectObjArray,
  getDefaultReasonForVisitForProviderType,
  getDefaultReasonForVisitForLocation,
  decodeReasonForVisit,
  isDefaultReasonForVisit,
};
