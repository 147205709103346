import moment from 'moment-timezone';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import isEmpty from 'lodash/isEmpty';
import {
  ACCEPTABLE_APPOINTMENT_RANGE_IN_MINUTES,
  SEARCH_PREFERENCES_MAX_TIME,
  SEARCH_PREFERENCES_MIN_TIME,
  SLOT_LOOK_BACK_DURATION,
} from '../../config/index';
import { getNearestHalfHour } from '../../core/util/date';
import { TODAY, TOMORROW } from '../util/bookingWidget';
import { hasOpenHoursToday } from '../util/location';
import { safeGet } from '../util/object';
import { NEXT_DAY_BOOKINGS_SLOT_SELECTOR, shouldShowFeature } from '../util/customization';

/**
 * Get fully constructed slot data for a single day
 *
 * @param location {Object} Location data from dapi
 * @param day {Number} Today's day number
 * @returns {Array}
 */
const getSlotsByDay = (location: any, day: any) =>
  (location &&
    location.slots &&
    location.slots.filter(
      (slot: any) => moment(slot.appointmentDate).tz(location.timeZone).date() === day
    )) ||
  [];

/**
 * Get fully constructed slot data for a single day
 *
 * @param location {Object} Location data from dapi
 * @param day {Number} Today's day number
 * @param time {moment} moment object that slot must be after
 * @returns {Array}
 */
const getSlotsByDayAndAfterTime = (location: any, day: any, time: any) =>
  (location &&
    location.slots &&
    location.slots.filter(
      (slot: any) =>
        moment(slot.appointmentDate).tz(location.timeZone).date() === day &&
        slot.appointmentDate >= time.valueOf()
    )) ||
  [];

const filterSlotsByDay = (slots: any, day: any, timeZone: any) =>
  slots.filter((slot: any) => moment.tz(slot.appointmentDate, timeZone).date() === day);

const removeSlotsBeforeDisableReservationsUntilTimestamp = (slots: any, location: any) => {
  if (location.disableReservationsUntil) {
    return slots.filter(
      (slot: any) => slot.appointmentDate >= moment(location.disableReservationsUntil).valueOf()
    );
  }

  return slots;
};

const getRequestedAppointmentTimeValue = (requestedAppointmentTime: any) =>
  typeof requestedAppointmentTime === 'object' && !isEmpty(requestedAppointmentTime)
    ? requestedAppointmentTime.value
    : requestedAppointmentTime;

/**
 * Get first available appointment time
 *
 * @param {object} location
 * @returns {number}
 */
const getFirstAvailableAppointmentTime = (location: any) => {
  const todaySlots = removeSlotsBeforeDisableReservationsUntilTimestamp(
    getSlotsByDay(location, moment().tz(location.timeZone).date()),
    location
  );

  for (const slot of todaySlots) {
    if (slot.availability !== 0 && !slot.isReservationsDisabled) {
      return slot.appointmentDate;
    }
  }

  const tomorrowSlots = getSlotsByDay(
    location,
    moment().tz(location.timeZone).add(1, 'day').date()
  );
  for (const slot of tomorrowSlots) {
    if (slot.availability !== 0) {
      return slot.appointmentDate;
    }
  }

  return null;
};

/**
 * Get nearest slot to selected appointment time
 * Looks back 15 minutes and all of the way into tomorrow.
 *
 * @param location
 * @param appointmentTime
 * @returns {number}
 */
const getNearestSlotAvailable = (location: any, appointmentTime: any) => {
  const disableReservationsUntil = moment(location.disableReservationsUntil)
    .tz(location.timeZone)
    .valueOf();

  const slotAvailable = (slot: any) => {
    if (disableReservationsUntil) {
      return (
        slot.availability !== 0 &&
        slot.appointmentDate >= appointmentTime - SLOT_LOOK_BACK_DURATION &&
        slot.appointmentDate >= disableReservationsUntil
      );
    }

    return (
      slot.availability !== 0 && slot.appointmentDate >= appointmentTime - SLOT_LOOK_BACK_DURATION
    );
  };

  const todaySlots = getSlotsByDay(location, moment().tz(location.timeZone).date());
  for (const slot of todaySlots) {
    if (slotAvailable(slot)) return slot.appointmentDate;
  }

  const tomorrowSlots = getSlotsByDay(
    location,
    moment().tz(location.timeZone).add(1, 'day').date()
  );
  for (const slot of tomorrowSlots) {
    if (slotAvailable(slot)) return slot.appointmentDate;
  }

  return null;
};

/**
 *
 * @param location {object} location object
 * @param x {number} time window for appointment match in minutes
 * @param requestedAppointmentTime {number} epoch time of requested appointment
 * @returns {boolean}
 */
const locationHasAvailabilityWithinXMinutesOfRequestedAppointmentTime = (
  location: any,
  x: any,
  requestedAppointmentTime: any
) => {
  const xInMilliseconds = x * 60 * 1000;
  return location.slots.some(
    (slot: any) =>
      Math.abs(slot.appointmentDate - requestedAppointmentTime) <= xInMilliseconds &&
      slot.availability !== 0
  );
};

/**
 * Returns a list of appointment slots after the requested appointment time
 *
 * @param location {object} DAPI location object
 * @param requestedAppointmentTime {number} requested appointment time in milliseconds since epoch
 */
const getLocationsSlotsAfterRequestedAppointmentTime = (
  location: any,
  requestedAppointmentTime: any
) =>
  location && location.slots
    ? location.slots.filter((slot: any) => {
        if (typeof slot.appointmentDate === 'string') {
          return moment(slot.appointmentDate) >= requestedAppointmentTime;
        }

        return slot.appointmentDate >= requestedAppointmentTime;
      })
    : [];

/**
 * Returns object with today's and tomorrow's slots as arrays
 *
 * @param location {object} DAPI location object
 * @param afterNow {boolean} only get slots after now time
 * @returns {{today: Array, tomorrow: Array}} slots
 */
const getSlotsForTodayAndTomorrow = (location: any, afterNow = false) => {
  if (!location?.timeZone) {
    return { today: [], tomorrow: [] };
  }

  // use moment-timezone so make rendering locationDescription
  // consistent between server-side & client-side
  const today = moment().tz(location.timeZone);
  const tomorrow = today.clone().add(1, 'day').startOf('day');

  const todaySlotsGetter = afterNow ? getSlotsByDayAndAfterTime : getSlotsByDay;
  let todaySlots = todaySlotsGetter(location, today.date(), today);

  if (!isEmpty(location.disableReservationsUntil)) {
    todaySlots = removeSlotsBeforeDisableReservationsUntilTimestamp(todaySlots, location);
  }

  return {
    today: todaySlots,
    tomorrow: shouldShowFeature(NEXT_DAY_BOOKINGS_SLOT_SELECTOR, location)
      ? getSlotsByDay(location, tomorrow.date())
      : [],
  };
};

/**
 * Filters out appointment slots in the past
 *
 * @param slots {Array} [{ appointmentDate: number, availability: number }]
 */
const removeSlotsBeforeNow = (slots: any) =>
  slots.filter((slot: any) => slot.appointmentDate >= moment().valueOf());

const getNumberOfSlots = (location: any, appointmentTime: any) => {
  const matchingSlot =
    location &&
    location.slots &&
    location.slots.filter((slot: any) => slot.appointmentDate === appointmentTime);

  if (matchingSlot.length) {
    return matchingSlot[0].availability;
  }

  return 0;
};

const hasAppointmentSoonAfterRequestedAppointmentTime = (
  location: any,
  requestedAppointmentTime: any
) => {
  const tooLongAfterRequestedTime = moment(requestedAppointmentTime).add(
    ACCEPTABLE_APPOINTMENT_RANGE_IN_MINUTES,
    'minutes'
  );

  const isSoonEnough = (time: any) => moment(time).isSameOrBefore(tooLongAfterRequestedTime);

  return getLocationsSlotsAfterRequestedAppointmentTime(location, requestedAppointmentTime).some(
    (slot: any) => slot.availability !== 0 && isSoonEnough(slot.appointmentDate)
  );
};

const getLastSlots = (location: any, numberOfSlots: any) =>
  location.slots.slice(location.slots.length - numberOfSlots);

/**
 * Returns a list of appointment slots after the requested appointment time
 *
 * @param location {object} DAPI location object
 * @param newBooking {object} newBooking object
 */
const getTodayAndTomorrowSlotsHelper = (newBooking: any, location: any) => {
  let requestedAppointmentTime = newBooking?.booking?.requestedAppointmentTime;
  requestedAppointmentTime = requestedAppointmentTime
    ? getRequestedAppointmentTimeValue(requestedAppointmentTime)
    : moment().tz(location.timeZone);

  if (newBooking?.booking?.appointmentTime) {
    requestedAppointmentTime = newBooking?.booking?.appointmentTime;
  }

  let filteredSlots = getLocationsSlotsAfterRequestedAppointmentTime(
    location,
    requestedAppointmentTime
  );

  if (!isEmpty(location.disableReservationsUntil)) {
    filteredSlots = removeSlotsBeforeDisableReservationsUntilTimestamp(filteredSlots, location);
  }

  const allSlots = [];

  if (filteredSlots.length) {
    for (let i = 0; i < filteredSlots.length; i++) {
      const slot = filteredSlots[i];
      const availability = getNumberOfSlots(location, slot.appointmentDate);
      slot.availability = availability;
      slot.slotsLeft =
        slot.appointmentDate === requestedAppointmentTime &&
        availability <= location.appointmentSlots &&
        availability > 0;
      if (slot.availability > 0 && !slot.isReservationsDisabled) {
        allSlots.push(slot);
      }
    }
  }

  return allSlots;
};

/**
 * Returns a list of appointment slots after the requested appointment time and with availability, today and tomorrow
 *
 * @param location {object} DAPI location object
 * @param newBooking {object} newBooking object
 */
const getTodayAndTomorrowSlots = (newBooking: any, location: LocationInterface) => {
  const allSlots = getTodayAndTomorrowSlotsHelper(newBooking, location);
  const today = moment().tz(location.timeZone);
  const tomorrow = moment().tz(location.timeZone).add(1, 'day');

  return {
    today: filterSlotsByDay(allSlots, today.date(), location.timeZone),
    tomorrow: filterSlotsByDay(allSlots, tomorrow.date(), location.timeZone),
  };
};

/**
 * Returns a list of appointment slots after the requested appointment time and with availability, only today
 *
 * @param location {object} DAPI location object
 * @param newBooking {object} newBooking object
 */
const getTodaySlots = (newBooking: any, location: LocationInterface) => {
  const allSlots = getTodayAndTomorrowSlotsHelper(newBooking, location);
  const today = moment().tz(location.timeZone);

  return filterSlotsByDay(allSlots, today.date(), location.timeZone);
};

/**
 * Returns a list of appointment slots after the requested appointment time and with availability, only tomorrow
 *
 * @param location {object} DAPI location object
 * @param newBooking {object} newBooking object
 */
const getTomorrowSlots = (newBooking: any, location: LocationInterface) => {
  const allSlots = getTodayAndTomorrowSlotsHelper(newBooking, location);
  const tomorrow = moment().tz(location.timeZone).add(1, 'day');

  return filterSlotsByDay(allSlots, tomorrow.date(), location.timeZone);
};

/**
 * Generate time slots.
 *
 * @returns {Array}
 */
const generateSlotTimesForSearch = () => {
  const start = moment(getNearestHalfHour());
  const end = moment().add(1, 'day').endOf('day');
  const times = [];
  for (let iTime = start; iTime < end; iTime.add(30, 'minutes')) {
    const momentTime = moment(iTime);
    const timeOfDay = momentTime.format('HH:mm:ss');
    if (SEARCH_PREFERENCES_MIN_TIME <= timeOfDay && timeOfDay <= SEARCH_PREFERENCES_MAX_TIME) {
      times.push(momentTime);
    }
  }

  return times;
};

const slotTimesByDayHelper = (start: any, end: any, times: any) => {
  for (let time = start; time < end; time.add(30, 'minutes')) {
    const timeSlot = moment(time);
    const timeOfDay = time.format('HH:mm:ss');
    if (SEARCH_PREFERENCES_MIN_TIME <= timeOfDay && timeOfDay <= SEARCH_PREFERENCES_MAX_TIME) {
      times.push(timeSlot);
    }
  }
};

/**
 * Generate slot times separated by day
 *
 * @typedef {{ todayTimes: {Array}, tomorrowTimes: {Array} }} slotTimesSeparatedByDay
 * @returns {slotTimesSeparatedByDay}
 */
const generateSlotTimesSeparatedByDay = () => {
  const startToday = moment(getNearestHalfHour());
  const startTomorrow = moment().add(1, 'day').startOf('day');

  const endToday = moment().endOf('day');
  const endTomorrow = moment().add(1, 'day').endOf('day');

  const todayTimes: any = [];
  const tomorrowTimes: any = [];

  slotTimesByDayHelper(startToday, endToday, todayTimes);
  slotTimesByDayHelper(startTomorrow, endTomorrow, tomorrowTimes);

  return { todayTimes, tomorrowTimes };
};

const filterSlotsByAvailability = (slots: any): any[] =>
  (!isEmpty(slots) ? slots : []).filter(
    (slot: any) => slot.availability !== 0 && !slot.isReservationsDisabled
  );

const hasSlotsAvailable = (slots: any) => !isEmpty(filterSlotsByAvailability(slots));

const hasNoAvailabilityTomorrow = (slotOptions: any[]) => {
  const availableSlots = slotOptions.filter(
    (s: any) => s.label === TOMORROW && s.value.availability !== 0
  );
  return isEmpty(availableSlots);
};

const generateSlotTimesForLocation = (location: any) => {
  const slots = getLocationsSlotsAfterRequestedAppointmentTime(location, Date.now());
  const availableSlots = filterSlotsByAvailability(slots);
  return availableSlots.map((slot: any) => moment(slot.appointmentDate));
};

const getFirstAvailableSlot = (newBooking: any, location: any) => {
  if (isEmpty(location)) {
    return null;
  }

  let slots = getTodayAndTomorrowSlots(newBooking, location);
  const availableSlotsToday = filterSlotsByAvailability(slots.today);
  if (!isEmpty(availableSlotsToday)) {
    return availableSlotsToday[0];
  }

  const availableSlotsTomorrow = filterSlotsByAvailability(slots.tomorrow);
  if (
    !isEmpty(availableSlotsTomorrow) &&
    shouldShowFeature(NEXT_DAY_BOOKINGS_SLOT_SELECTOR, location)
  ) {
    return availableSlotsTomorrow[0];
  }

  return null;
};

const getSlotIfExists = (timeStamp: number, location: LocationInterface) => {
  if (isEmpty(location) || !timeStamp) {
    return null;
  }

  if (location.slots && timeStamp) {
    return location.slots.find((s: any) => s.appointmentDate === timeStamp);
  }

  return null;
};

const isReservationsRemaining = (location: any, momentDate: any) => {
  const slots = getSlotsByDay(location, momentDate.date());

  const isReservable = (slot: any) =>
    slot.appointmentDate >= momentDate && slot.availability > 0 && !slot.isReservationsDisabled;

  return slots.some(isReservable);
};

const isReservationsRemainingToday = (location: any) => {
  const localNow = moment.tz(location.timeZone);
  return isReservationsRemaining(location, localNow);
};

const isLocationSlotsFullTodayAndClosedTomorrow = (slotOptions: any) =>
  slotOptions.length === 1 && slotOptions[0].label === TODAY;

const isLocationClosedTodayAndSlotsFullTomorrow = (slotOptions: any, location: any) =>
  !hasOpenHoursToday(location) && slotOptions.length === 1 && slotOptions[0].label === TOMORROW;

const isLocationSlotsFullTodayAndTomorrow = (slotOptions: any) =>
  slotOptions.length === 2 && slotOptions[0].label === TODAY && slotOptions[1].label === TOMORROW;

const getOperatingHoursByDay = (date: any, location: any) => {
  if (!date) return [];

  const weekdayName = date.format('dddd');

  const hours = safeGet(
    location,

    []
  )(
    `${
      date.isBefore(moment.tz(location.timeZone).add(7, 'days'), 'day') ? 'hours' : 'hoursDefault'
    }.${weekdayName}`
  );

  return hours.map((weekdayHours: any) => ({
    startTime: moment
      .tz(date, location.timeZone)
      .hours(weekdayHours.fromTime.split(':')[0])
      .minutes(weekdayHours.fromTime.split(':')[1]),

    endTime: moment
      .tz(date, location.timeZone)
      .hours(weekdayHours.toTime.split(':')[0])
      .minutes(weekdayHours.toTime.split(':')[1]),
  }));
};

/**
 * Generate a list of slots for day given just the locations hours
 *
 * @param {moment} date
 * @param {Object} location
 * @param {number} slotDuration
 * @param {number} availability
 * @returns {Array}
 */
const generateSlotsFromOperatingHours = (
  date: any,
  location: any,
  slotDuration = 15,
  availability = 1
) => {
  const openHours = getOperatingHoursByDay(date, location);

  const slots = [];

  for (const timeInterval of openHours) {
    const numberOfSlots =
      moment.duration(timeInterval.endTime.diff(timeInterval.startTime)).asMinutes() / slotDuration;
    for (let i = 0; i < numberOfSlots; i++) {
      slots.push({
        appointmentDate: timeInterval.startTime.valueOf(),
        availability,
      });
      timeInterval.startTime.add(slotDuration, 'minutes');
    }
  }

  return slots;
};

const isSlotAvailable = (selectedSlot: any, location: any) =>
  hasSlotsAvailable(location.slots) &&
  Boolean(
    location.slots.find((slot: any) => slot.appointmentDate === selectedSlot.appointmentDate)
  );

const sortSlotsByAppointmentDate = (slots: any) => {
  return slots.sort(
    (slot1: any, slot2: any) =>
      (new Date(slot1.appointment_date) as any) - (new Date(slot2.appointment_date) as any)
  );
};

export {
  filterSlotsByDay,
  generateSlotTimesForSearch,
  generateSlotTimesSeparatedByDay,
  generateSlotTimesForLocation,
  generateSlotsFromOperatingHours,
  getFirstAvailableAppointmentTime,
  getLastSlots,
  getLocationsSlotsAfterRequestedAppointmentTime,
  getNearestSlotAvailable,
  getNumberOfSlots,
  getSlotsForTodayAndTomorrow,
  getTodayAndTomorrowSlots,
  getTodaySlots,
  getTomorrowSlots,
  hasAppointmentSoonAfterRequestedAppointmentTime,
  locationHasAvailabilityWithinXMinutesOfRequestedAppointmentTime,
  removeSlotsBeforeNow,
  removeSlotsBeforeDisableReservationsUntilTimestamp,
  filterSlotsByAvailability,
  getFirstAvailableSlot,
  getSlotIfExists,
  isReservationsRemaining,
  isReservationsRemainingToday,
  getRequestedAppointmentTimeValue,
  hasSlotsAvailable,
  isLocationSlotsFullTodayAndClosedTomorrow,
  isLocationClosedTodayAndSlotsFullTomorrow,
  isLocationSlotsFullTodayAndTomorrow,
  isSlotAvailable,
  hasNoAvailabilityTomorrow,
  sortSlotsByAppointmentDate,
};
