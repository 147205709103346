import { isEmptyObject } from './object';
import { isEmptyArray } from './array';
import { isEmptyString } from './string';

/**
 * @deprecated use lodash isEmpty (note: if your val type is boolean, symbol, function, or bigint, double-check that lodash isEmpty is working as you expect.
 * it returns true for these types, whereas our custom implementation here returned false)
 */
const isEmpty = (val: any) => {
  switch (typeof val) {
    case 'object':
      if (Array.isArray(val)) return isEmptyArray(val);
      return isEmptyObject(val);
    case 'string':
      return isEmptyString(val);
    case 'number':
      return Number.isNaN(val);
    case 'boolean':
    case 'symbol':
    case 'function':
    case 'bigint':
      return false;
    case 'undefined':
    default:
      return true;
  }
};

export { isEmpty };
