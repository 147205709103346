import { put, takeEvery, call } from 'redux-saga/effects';
import { getGroupId } from '../core/util/location';
import { getClosestPhysicalLocationsInGroup } from '../core/dapi/location';
import { ajaxPending } from '../actions/runtime';
import { apiGet } from '../core/dapi';
import { analyticsTrackEvent } from '../core/analytics';
import { PHYSICAL_LOCATIONS_ERROR_LOADING } from '../core/analytics/events';
import { physicalLocationsError, physicalLocationsReceived } from '../ducks/physicalLocations';
import { safeGet } from '../core/util/object';

const FETCH_PHYSICAL_LOCATIONS = 'SAGA/FETCH_PHYSICAL_LOCATIONS';

/**
 * Saga function that call DAPI api and fetch closest location in same group
 */
function* fetchPhysicalLocationsInGroup({ location, latLong }: any) {
  yield put(ajaxPending(FETCH_PHYSICAL_LOCATIONS));
  const groupId = getGroupId(location);
  if (!groupId) return;

  const latitude = safeGet(latLong)('result.latitude');
  const longitude = safeGet(latLong)('result.longitude');
  try {
    const response = yield call(
      apiGet,
      getClosestPhysicalLocationsInGroup(groupId, latitude, longitude)
    );
    yield put(physicalLocationsReceived(response.results));
  } catch (e) {
    yield put(physicalLocationsError(e));
    analyticsTrackEvent(PHYSICAL_LOCATIONS_ERROR_LOADING, {
      location,
      groupId,
    });
  }
}

/**
 * Root Saga function
 */
function* rootSaga() {
  yield takeEvery(FETCH_PHYSICAL_LOCATIONS, fetchPhysicalLocationsInGroup);
}
export { FETCH_PHYSICAL_LOCATIONS, rootSaga as default };
