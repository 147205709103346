import {
  REVIEWS_ERROR,
  REVIEWS_SUBMITTED,
  REVIEWS_RECEIVED,
  REVIEWS_SET_ACTIVE_REVIEW,
} from '../constants/index';

export default function reviewsReducer(state = {}, action: any) {
  switch (action.type) {
    case REVIEWS_SUBMITTED:
      return {
        ...state,
        error: null,
        submitting: true,
      };
    case REVIEWS_RECEIVED: {
      const currentReviews =
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state[action.payload.id] && state[action.payload.id].reviews
          ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state[action.payload.id].reviews
          : [];
      const newState = { ...state };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState[action.payload.id] = {
        reviews: currentReviews.concat(action.payload.value.results),
        error: null,
        submitting: false,
        resultsCount: action.payload.value.page.results_count,
      };
      return newState;
    }

    case REVIEWS_ERROR:
      return {
        ...state,
        error: action.payload.value,
        submitting: false,
      };
    case REVIEWS_SET_ACTIVE_REVIEW:
      return {
        ...state,
        activeReview: action.payload.value,
      };
    default:
      return state;
  }
}
