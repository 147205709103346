import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setRuntimeVariable } from '../../actions/runtime';

export default <T = any>(
  runtimeVariableName: string,
  defaultValue?: T
): [T, (newValue: T) => void] => {
  const runtimeValue = useSelector<{ runtime: { [p: string]: any } }, T>(
    (state) => state.runtime[runtimeVariableName] ?? defaultValue
  );

  const dispatch = useDispatch();
  const setVar = useCallback(
    (newValue: T) => {
      dispatch(setRuntimeVariable({ name: runtimeVariableName, value: newValue }));
    },
    [runtimeVariableName, dispatch]
  );

  return [runtimeValue, setVar];
};
