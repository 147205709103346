import {
  createGenerateClassName,
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { JigsawProvider } from '@solvhealth/jigsaw';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { analyticsTrackEvent } from '~/core/analytics';
import history from '~/core/history';
import { useI18nSsr } from '~/locale/useI18nSsr';
import NotificationsProvider from '../../core/notifications/NotificationsProvider';
import App from '../App';
import { useLaunchDarklyIdentify } from '~/core/launch-darkly/hooks/useLaunchDarklyIdentify';
import { setFlags } from '~/core/launch-darkly/flags';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';
const theme = createMuiTheme();
const sheetsManager = new Map();
const generateClassName = createGenerateClassName();

type Props = {
  route?: any;
  context?: any;
  notificationInfo?: any;
};

const Main = (props: Props) => {
  const {
    route,
    context,
    notificationInfo: { swRegistration, applicationServerKey },
  } = props;

  // Load in translation keys from the SSR
  useI18nSsr();

  useLaunchDarklyIdentify();

  const flags = useLDFlags();

  // To allow us to use the flags in a non functional component
  useEffect(() => {
    setFlags(flags);
  }, [flags]);

  useEffect(() => {
    const jssStyles = document.getElementById('jss-server-side');
    jssStyles?.parentNode?.removeChild(jssStyles);
  }, []);

  const jigsawSettings: PropsType<typeof JigsawProvider>['settings'] = {
    history,
    analytics: {
      trackEvent: analyticsTrackEvent,
    },
  };

  return (
    <JigsawProvider settings={jigsawSettings}>
      <StylesProvider
        generateClassName={generateClassName}
        injectFirst
        sheetsManager={sheetsManager}
      >
        <MuiThemeProvider theme={theme}>
          <CookiesProvider>
            <NotificationsProvider
              applicationServerKey={applicationServerKey}
              swRegistration={swRegistration}
            >
              <App context={context} route={route}>
                {route.component}
              </App>
            </NotificationsProvider>
          </CookiesProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </JigsawProvider>
  );
};

export default Main;
