/* jscs:disable maximumLineLength */
/* eslint-disable max-len */
import { DAPI_HOST } from '../../config/index';
import { apiGetJson, apiGetDispatchable } from './index';
import { waitListBookingNotVisible, receiveWaitList, waitListError } from '../../actions/waitList';
import { isWaitlistVisibleForBooking } from '../../core/waitList';
import { bookingResponseFormatter } from '../../reducers/formatters/booking';

const getPublicBookingByIdUrl = (bookingId: any) => `${DAPI_HOST}/v1/public-bookings/${bookingId}`;

const getPublicBookingsByLocationUrl = (locationId: any) =>
  `${DAPI_HOST}/v2/locations/${locationId}/wait-list`;

const apiGetBookingLocationAndWaitListDispatchable =
  (bookingId: any, onSuccess: any, onError: any) => (dispatch: any) => {
    const bookingPromise = apiGetJson(getPublicBookingByIdUrl(bookingId));
    bookingPromise
      .then((response: any) => {
        if (!response.data) {
          dispatch(onError());
        } else {
          const bookingData = bookingResponseFormatter(response.data);
          dispatch(onSuccess(bookingData));
          if (isWaitlistVisibleForBooking(bookingData)) {
            dispatch(
              apiGetDispatchable(
                getPublicBookingsByLocationUrl(bookingData.locationId),
                receiveWaitList,
                waitListError
              )
            );
          } else {
            dispatch(waitListBookingNotVisible());
          }
        }
      })
      .catch(() => dispatch(onError()));
  };

export {
  getPublicBookingByIdUrl,
  getPublicBookingsByLocationUrl,
  apiGetBookingLocationAndWaitListDispatchable,
};
