import { SupportedLocale } from '~/core/util/localizedStr';
import {
  BOOKING_APPOINTMENT_TYPE,
  BOOKING_CLEAR_ERRORS,
  BOOKING_INSURANCE_TYPE,
  BOOKING_IS_PREMIUM,
  BOOKING_NOTES,
  BOOKING_ORIGIN_CHANGED,
  BOOKING_PROFILE_PENDING,
  BOOKING_REASON_FOR_VISIT_CHANGED,
  BOOKING_RECEIVE_USER_PROFILE,
  BOOKING_REQUEST_FAILED,
  BOOKING_REQUEST_NOT_SUBMITTED,
  BOOKING_REQUEST_SUBMITTED,
  BOOKING_REQUESTED_TIME_CHANGED,
  BOOKING_RESPONSE_RECEIVED,
  BOOKING_SET_BOOKING_CODE,
  BOOKING_SET_HAS_BOOKED_HERE_BEFORE_WITH_SOLV,
  BOOKING_SET_IS_EXISTING_PATIENT,
  BOOKING_SET_LOCALE,
  BOOKING_SET_LOCATION_ID,
  BOOKING_SET_PROVIDER_GROUP_ATTRIBUTES,
  BOOKING_SET_STATE_CODE,
  BOOKING_SET_PAYMENT,
  BOOKING_SET_CONSENT,
  BOOKING_TIME_SELECTED,
  BOOKING_WAS_SUCCESSFUL,
  CLEAR_SUCCESSFUL_BOOKING_DATA,
  PATIENT_TYPE_CHANGED,
  RESET_USER_PROFILE,
  SYMPTOM_CLICKED,
  SYMPTOM_UPDATED,
  BOOKING_IS_PREMIUM_IN_PERSON,
  BOOKING_SET_TOS_CONSENT,
  BOOKING_SET_SMS_CONSENT,
} from '../constants/index';
import { analyticsTrackEvent } from '~/core/analytics';
import { APP_API_FAILURE, APP_API_SUCCESS, AppApiNames } from '~/core/analytics/events';

const clearSuccessfulBookingData = () => ({
  type: CLEAR_SUCCESSFUL_BOOKING_DATA,
});

const bookingRequestSubmitted = (value?: any) => ({
  type: BOOKING_REQUEST_SUBMITTED,
  payload: { value },
});

const bookingRequestNotSubmitted = (value: any) => ({
  type: BOOKING_REQUEST_NOT_SUBMITTED,
  payload: { value },
});

const bookingWasSuccessful = (value: any) => ({
  type: BOOKING_WAS_SUCCESSFUL,
  payload: { value },
});

const bookingResponseReceived = (value: any) => {
  analyticsTrackEvent(APP_API_SUCCESS, { app_api: AppApiNames.CREATE_NEW_BOOKING });
  return {
    type: BOOKING_RESPONSE_RECEIVED,
    payload: { value },
  };
};

const bookingFailed = (value: any, code?: number) => {
  analyticsTrackEvent(APP_API_FAILURE, {
    app_api: AppApiNames.CREATE_NEW_BOOKING,
    errorMessage: value,
  });
  return {
    type: BOOKING_REQUEST_FAILED,
    payload: { value, code },
  };
};

const clearBookingErrors = () => ({
  type: BOOKING_CLEAR_ERRORS,
});

const bookingTimeSelected = (appointmentTime: number | null, timeZone?: string) => ({
  type: BOOKING_TIME_SELECTED,
  payload: { appointmentTime, timeZone },
});

const setProviderGroupAttributes = (value: any) => ({
  type: BOOKING_SET_PROVIDER_GROUP_ATTRIBUTES,
  payload: { value },
});

const setIsExistingPatient = (value: any) => ({
  type: BOOKING_SET_IS_EXISTING_PATIENT,
  payload: { value },
});

const setHasBookedHereBeforeWithSolv = (value: any) => ({
  type: BOOKING_SET_HAS_BOOKED_HERE_BEFORE_WITH_SOLV,
  payload: { value },
});

const setInsuranceType = (value: any) => ({
  type: BOOKING_INSURANCE_TYPE,
  payload: { value },
});

const setReasonForVisit = (value: any) => ({
  type: BOOKING_REASON_FOR_VISIT_CHANGED,
  payload: { value },
});

const setOrigin = (value: any) => ({
  type: BOOKING_ORIGIN_CHANGED,
  payload: { value },
});

const setBookingCode = (value: any) => ({
  type: BOOKING_SET_BOOKING_CODE,
  payload: { value },
});

const patientTypeChanged = (value: any) => ({
  type: PATIENT_TYPE_CHANGED,
  payload: { value },
});

const symptomClicked = (value: any) => ({
  type: SYMPTOM_CLICKED,
  payload: { value },
});

const symptomUpdated = (value: any) => ({
  type: SYMPTOM_UPDATED,
  payload: { value },
});

const requestedAppointmentTimeChanged = (value: any) => ({
  payload: { value },
  type: BOOKING_REQUESTED_TIME_CHANGED,
});

const receiveUserProfileForNewBooking = (value: any) => ({
  type: BOOKING_RECEIVE_USER_PROFILE,
  payload: { value },
});

const setLocationId = (value: string) => ({
  type: BOOKING_SET_LOCATION_ID,
  payload: { value },
});

const setBookingStateCode = (value: string) => ({
  type: BOOKING_SET_STATE_CODE,
  payload: { value },
});

const setBookingIsPremium = (value: boolean) => ({
  type: BOOKING_IS_PREMIUM,
  payload: { value },
});
const setBookingIsPremiumInPerson = (value: boolean) => ({
  type: BOOKING_IS_PREMIUM_IN_PERSON,
  payload: { value },
});

const setBookingNotes = (value: any) => ({
  type: BOOKING_NOTES,
  payload: { value },
});

const setAppointmentType = (value: any) => ({
  type: BOOKING_APPOINTMENT_TYPE,
  payload: { value },
});

const setNewBookingLocale = (locale: SupportedLocale) => ({
  type: BOOKING_SET_LOCALE,
  payload: {
    locale,
  },
});

const setBookingProfilePayment = (value: string) => ({
  type: BOOKING_SET_PAYMENT,
  payload: { value },
});

/** Used to set all three of state.profile's tos_consent, sms_consent, and telemed_consent properties
 * simultaneously. For more granular TOS & SMS setting, see setTosConsent() and setSmsConsent() below */
const setBookingProfileConsent = (value: { [key: string]: boolean }) => ({
  type: BOOKING_SET_CONSENT,
  payload: { value },
});

const setTosConsent = (value: boolean) => ({
  type: BOOKING_SET_TOS_CONSENT,
  payload: { value },
});

const setSmsConsent = (value: boolean) => ({
  type: BOOKING_SET_SMS_CONSENT,
  payload: { value },
});

const setBookingProfilePending = (value: boolean) => ({
  type: BOOKING_PROFILE_PENDING,
  payload: { value },
});

const resetUserProfile = () => ({
  type: RESET_USER_PROFILE,
});

export {
  clearSuccessfulBookingData,
  setReasonForVisit,
  requestedAppointmentTimeChanged,
  bookingWasSuccessful,
  bookingRequestSubmitted,
  bookingRequestNotSubmitted,
  bookingResponseReceived,
  bookingFailed,
  clearBookingErrors,
  bookingTimeSelected,
  setProviderGroupAttributes,
  setIsExistingPatient,
  setHasBookedHereBeforeWithSolv,
  setInsuranceType,
  patientTypeChanged,
  symptomClicked,
  symptomUpdated,
  receiveUserProfileForNewBooking,
  setLocationId,
  setOrigin,
  setBookingCode,
  setBookingNotes,
  setAppointmentType,
  setNewBookingLocale,
  setBookingStateCode,
  setBookingIsPremium,
  setBookingIsPremiumInPerson,
  setBookingProfilePending,
  resetUserProfile,
  setBookingProfilePayment,
  setBookingProfileConsent,
  setTosConsent,
  setSmsConsent,
};
