import { call, put, takeLatest, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { REVIEW_SUBMITTED, reviewResponseReceived, thanksForFeedback } from '../ducks/leaveAReview';
import {
  WAIT_TIME,
  BEDSIDE_MANNER,
  STAFF_FRIENDLINESS,
  QUALITY_OF_CARE,
  FACILITY_CLEANLINESS,
} from '../components/LeaveAReview/constants';

import { PRIMARY_RATING } from '../components/LeaveAReview/components/PrimaryRatingSelector';
import { CONSENT_FIELD, RATING_DESCRIPTION_FIELD } from '../components/LeaveAReview/constants';
import { apiPostJson } from '../core/dapi';
import { DAPI_HOST } from '../config/index';
import { LEAVE_A_REVIEW_MODAL } from '../components/ClinicDetailPageFlexible/components/Modals/LeaveAReviewModal';
import { hideModal, setRuntimeVariable, showModal } from '../actions/runtime';
import { analyticsTrackEvent } from '../core/analytics';
import {
  CDP_LEAVE_REVIEW_SUBMITTED,
  SIGN_UP_ACCOUNT_AFTER_REVIEW,
  SIGN_UP_SWITCH_ACTION,
} from '../core/analytics/events';
import {
  CDP_REVIEW_REPORT_SUBMITTED,
  LOGIN_ACTION_SIGN_UP,
  RECEIVE_ACCOUNT_SUMMARY,
} from '../constants/index';

import { REPORT_REVIEW_MODAL } from '../components/ClinicDetailPageFlexible/components/Modals/ReportReviewModal/ReportReviewModal';
import { addSolvRating, flagSolvRating } from '../ducks/solvRatings';
import { LOGIN_SIGN_UP_MODAL } from '../components/LoginSignUpModal';
import { setLoginAction } from '../actions/login';
import { getCurrentUser } from '../core/util/currentUser';
import { HEADER_TYPES } from '~/components/PhoneLoginForm/PhoneLoginForm';

const FRESH_RATING_ID = 'fresh rating id variable';

function* submitReview({ payload: { value: formData } }: { payload: { value: any } }) {
  try {
    const { accountId } = yield select(getCurrentUser);
    const postData = {
      star_rating: formData[PRIMARY_RATING],
      review: formData[RATING_DESCRIPTION_FIELD],
      consent_to_publish: formData[CONSENT_FIELD],
      rating_wait_time: formData[WAIT_TIME],
      rating_bedside_manner: formData[BEDSIDE_MANNER],
      rating_staff_friendliness: formData[STAFF_FRIENDLINESS],
      rating_quality_of_care: formData[QUALITY_OF_CARE],
      rating_facility_cleanliness: formData[FACILITY_CLEANLINESS],
      location_id: formData.locationId,
      provider_id: formData.providerId,
      account_id: accountId,
      review_date: 'NOW()',
      rating_date: 'NOW()',
    };

    const url = `${DAPI_HOST}/v1/solv-ratings`;
    const response = yield call(apiPostJson, url, postData);

    if (response.errors) {
      yield put(reviewResponseReceived('error'));
      return;
    }

    if (!formData.isPGPage) {
      analyticsTrackEvent(CDP_LEAVE_REVIEW_SUBMITTED, {
        location_id: formData.locationId,
        rating: formData[PRIMARY_RATING],
        consent_to_publish: formData[CONSENT_FIELD],
        rating_wait_time: formData[WAIT_TIME],
        rating_bedside_manner: formData[BEDSIDE_MANNER],
        rating_staff_friendliness: formData[STAFF_FRIENDLINESS],
        rating_quality_of_care: formData[QUALITY_OF_CARE],
        rating_facility_cleanliness: formData[FACILITY_CLEANLINESS],
      });
    }

    yield put(reviewResponseReceived('success'));
    yield put(addSolvRating(response.data));

    if (accountId) {
      yield call(delay, 1000);
      yield put(thanksForFeedback());
      yield call(delay, 4000);
      yield put(hideModal(LEAVE_A_REVIEW_MODAL));
      return;
    }

    // login/sign up setup
    yield put(setRuntimeVariable({ name: FRESH_RATING_ID, value: response.data.id }));
    yield put(
      setRuntimeVariable({
        name: 'loginHeaderType',
        value: HEADER_TYPES.THANKS_FOR_FEEDBACK,
      })
    );
    if (formData.isPGPage) {
      yield put(hideModal(LEAVE_A_REVIEW_MODAL));
    } else {
      yield put(setLoginAction(LOGIN_ACTION_SIGN_UP));
      yield call(delay, 1000);
      yield put(hideModal(LEAVE_A_REVIEW_MODAL));
      yield put(showModal(LOGIN_SIGN_UP_MODAL));
    }

    yield put(thanksForFeedback());
  } catch (e) {
    yield put(reviewResponseReceived('error'));
  }
}

function* reportReview({ value }: any) {
  try {
    const url = `${DAPI_HOST}/v1/solv-ratings/report`;
    yield call(apiPostJson, url, value);
    yield call(delay, 4000);
    yield put(hideModal(REPORT_REVIEW_MODAL));
    yield put(flagSolvRating(value.id, false));
  } catch (e) {
    yield put(hideModal(REPORT_REVIEW_MODAL));
  }
}

function* appendReviewToAccount({
  payload: {
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'accountId' implicitly has an 'any... Remove this comment to see the full error message
    value: { id: accountId },
  },
}) {
  try {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const ratingId = yield select((state) => state.runtime[FRESH_RATING_ID]);
    if (!ratingId) return;

    const url = `${DAPI_HOST}/v1/solv-ratings/append-account`;
    const postData = {
      solv_rating_id: ratingId,
      account_id: accountId,
    };

    yield call(apiPostJson, url, postData);
    analyticsTrackEvent(SIGN_UP_ACCOUNT_AFTER_REVIEW, { ratingId, accountId });
    yield put(showModal(LEAVE_A_REVIEW_MODAL));
    yield call(delay, 4000);
    yield put(hideModal(LEAVE_A_REVIEW_MODAL));
  } catch (e) {
    console.error(e);
  }
}

function* rootSaga() {
  // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  yield takeLatest(REVIEW_SUBMITTED, submitReview);
  yield takeLatest(CDP_REVIEW_REPORT_SUBMITTED, reportReview);
  // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  yield takeLatest(RECEIVE_ACCOUNT_SUMMARY, appendReviewToAccount);
}

export { rootSaga as default };
