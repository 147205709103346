import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/runtime';
import UserChoiceContent from './UserChoice';

export const USER_CHOICE_MODAL_ID = 'user choice modal';

type Props = {
  open?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  fromSrp?: boolean;
};

const UserChoice = ({ open = false, fullScreen, onClose, fromSrp }: Props) => {
  return (
    <Dialog fullScreen={fullScreen} onClose={onClose} open={open} scroll="body">
      <UserChoiceContent fromSrp={fromSrp} onClickBack={onClose} />
    </Dialog>
  );
};

export default connect(
  (state: any) => ({
    open: state.runtime.modal && !!state.runtime.modal[USER_CHOICE_MODAL_ID],
  }),
  (dispatch: any) => ({
    onClose: () => dispatch(hideModal(USER_CHOICE_MODAL_ID)),
  })
)(withMobileDialog({ breakpoint: 'xs' })(UserChoice));
