import Location from '@solvhealth/types/interfaces/Location';
import {
  GROUP_LOCATIONS_RESPONSE_RECEIVED,
  GROUP_LOCATIONS_FAILED,
  GROUP_LOCATIONS_CLEAR_DATA,
  GROUP_LOCATIONS_CLEAR_ERRORS,
  GROUP_LOCATIONS_FETCHING,
} from '../constants/index';

export interface GroupLocationsState {
  fetching: boolean;
  locations: Location[];
}

const DEFAULT_STATE: GroupLocationsState = {
  fetching: false,
  locations: [],
};

export default function groupLocationsReducer(state = DEFAULT_STATE, action: any) {
  switch (action.type) {
    case GROUP_LOCATIONS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case GROUP_LOCATIONS_RESPONSE_RECEIVED:
      return {
        ...state,
        locations: action.payload.formattedLocations,
        fetching: false,
      };
    case GROUP_LOCATIONS_FAILED:
      return {
        ...state,
        error: action.payload.value,
        fetching: false,
      };
    case GROUP_LOCATIONS_CLEAR_DATA:
      return {
        ...state,
        locations: null,
        fetching: null,
      };
    case GROUP_LOCATIONS_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
