import { ANDROID_PLATFORM_NAME, IOS_PLATFORM_NAME, isIOS } from '../../core/util/device';
import { isClientSide } from '../../core/util/system';
import { getQueryParams } from '../../core/util/url';

// These are from Apple
export const SOLV_APPLE_ID = 1464601606;
export const SOLV_APPLE_PROVIDER_ID = 120007617;

// Google specific constants
export const SOLV_GOOGLE_PLAY_ID = 'com.solv';

export const hasAppUtmSource = () => {
  if (isClientSide()) {
    const qParams = getQueryParams(window.location.href);
    const utmSource = (qParams.utm_source ?? '').toLowerCase();
    return utmSource === IOS_PLATFORM_NAME || utmSource === ANDROID_PLATFORM_NAME;
  }

  return false;
};

export const downloadDriverFeatureFlagCheck = () => {
  const flag = (typeof window === 'undefined' ? process.env : window.CFG).FF_DOWNLOAD_DRIVER;
  return typeof flag === 'string' ? flag === 'true' : flag;
};

export const getSolvAppStoreLink = (campaignName = 'generic-solv-website-campaign') =>
  `https://apps.apple.com/app/apple-store/id${SOLV_APPLE_ID}?pt=${SOLV_APPLE_PROVIDER_ID}&ct=${campaignName}&mt=8`;

export const getSolvPlayStoreLink = (campaignName = 'generic-solv-website-campaign') =>
  `https://play.google.com/store/apps/details?id=${SOLV_GOOGLE_PLAY_ID}&referrer=utm_source%3D${campaignName}%26utm_medium%3Ddownload_driver%26anid%3Dadmob`;

export const getNativeStoreDownloadLink = (campaignName = 'generic-solv-website-campaign') =>
  isIOS() ? getSolvAppStoreLink(campaignName) : getSolvPlayStoreLink(campaignName);

export const downloadDriverCarouselVariants = {
  default: [
    {
      img: '/images/account/App-Icon-V1.svg',
      title: 'Keep great care',
      title2: 'a couple taps away',
    },
    {
      img: '/images/account/man-phone.svg',
      title: 'Quality care is a few taps away',
      title2: '',
    },
    {
      img: '/images/account/mary-child-phone.svg',
      title: 'Book same-day visits',
      title2: 'for you and your family',
    },
    {
      img: '/images/account/urgent-care-yellow-3.svg',
      title: 'Find top-rated care near you',
      title2: '',
    },
    {
      img: '/images/account/provider-phone-2.svg',
      title: 'Get seen in-person, or virtually',
      title2: '',
    },
  ],
};

const medstarLocationIdHashes = [
  'AvVVqA',
  '0r7P2A',
  'AvwKqp',
  'pnXBv0',
  'pRGGkg',
  'pyyKPp',
  'pDePng',
  'Avw68p',
  'gJXrEA',
  'pRJJzp',
  '0kDPPA',
  'p3ooKp',
  'pYOOV0',
  'AzVx8A',
  'A9QOQA',
  '0ry7Jg',
  'A24Bwg',
  'AaR6lp',
  'pDzJe0',
  '0EWBPp',
  'pjPOJ0',
  'AXejdp',
  'g53rqA',
  'plxJrp',
  'pDezng',
  '0m8xrg',
  'A9RZap',
  'goJ3Gp',
  'A4Qyxp',
  'pylzeA',
  '0rJ140',
  'plE78g',
  'A6dY8g',
  'A2yKM0',
  'gQGG7A',
  '0e11Y0',
  'g133EA',
  'A472Y0',
  'AzER80',
  'A6dd4g',
  '0mEkR0',
  '0O86BA',
  'A47yw0',
  '0xwwQg',
  'Aaxx50',
  'gK55nA',
  'p8yx8g',
  'gZJJLg',
  'pYJJNg',
  'pnXDQ0',
  'gqwKWA',
  '0mjV3A',
  'pYXqjA',
  'pjYXdA',
  'p8yEog',
  '0kDDnA',
  '0e9EO0',
  'AWooeA',
  '0mjjzA',
  '0VJVm0',
  'g1391A',
  'AGDD1p',
  'A4bbYg',
  'pRJ8zp',
  'A9ll40',
  'gZJ6wg',
  '0OKEB0',
  'A6dE4g',
  'p3VrKA',
  'pRJ5kp',
  '0kDMnA',
  'gbZZNg',
  'gQJX70',
  '0e99O0',
  'ABb1JA',
  '077Dw0',
  'pyyyYp',
  'gLLaGg',
  'AW94yg',
  'gQJJe0',
  'pnXXQ0',
  'p8yy8g',
  'gwwKBg',
  'A477w0',
  'gNNoLg',
  'go55kA',
  'AXJkWg',
  'p8JJo0',
  'Avww8p',
  '0xwKrg',
  'gdzYBA',
  '0xwZQg',
  'gNNDqg',
  'Aama8A',
  'gNNNLg',
  '0e9DY0',
  'g13rEA',
  'gK52KA',
  'AGDzlp',
  'gQJxe0',
  'AMJYZp',
  'pDzPrN',
  '0EW7N4',
  'pjPk9B',
  'g53Zmb',
  'gLRM58',
  '0PLKVJ',
  '07Rz4j',
  '0VOqMj',
  'AB659P',
  'gwLe79',
  '0ORq7E',
  'plberw',
  'p3o6yM',
  'AMVqLB',
  'gJMljl',
  'gqoeEr',
  'gKv3XO',
  'pyXeBw',
  'AGLBYJ',
  'p8J98n',
  '0e1q6o',
  'pRGqwD',
  '0kVkbR',
  'gQGqBB',
  'AvVePQ',
  'pnQeam',
  'gNVqPJ',
  'g1PMNW',
  'A6RWL2',
  '0xjenj',
  'gZeqD8',
  'AWEq2G',
  'AzVenM',
  'pjP6a8',
  'AXeny7',
  'g53WRm',
  'gLRJPk',
  '0PLnoZ',
  '07R9Jl',
  '0VOneR',
  'AB6LNN',
  '0ORnV3',
  'plbZlx',
  'p3ox9w',
  'AMVn75',
  'gJM3RY',
  'gqorLn',
  'gKvMN6',
  'pyX5ov',
  'AGLwMa',
  '0mEWv9',
  'goQ9nM',
  'A4bZ3l',
  'p8Jbea',
  '0e1nzE',
  'pRGnOJ',
  '0kVxY2',
  'gQGnV2',
  'pnQNW7',
  'gNVnRD',
  'g1P1aY',
  'A6Rz2M',
  '0xjXdG',
  'gZenGq',
  'AWEnjn',
  'AzVL81',
  'gJRa6y',
];

const allNoDownloadDriverLocationIdHashes = new Set(medstarLocationIdHashes);

export const locationDoesntArbitrarilyBlockDownloaderDriver = (
  locationId: string | null | undefined
) => {
  if (locationId == null) return true;
  return !allNoDownloadDriverLocationIdHashes.has(locationId);
};
