import React from 'react';
import NotificationsContext from './NotificationsContext';

export default (Component: any) => (props: any) =>
  (
    <NotificationsContext.Consumer>
      {(value) => {
        if (value === null) return null;
        const { notificationsSupported, notificationsSubscription, notifications } = value;

        const notificationsSubscribe = () => {
          if (notificationsSupported) {
            return notifications.subscribe();
          }

          return Promise.reject(new Error('notifications are not supported'));
        };

        const notificationsAddClickListener = (cb: any) => {
          if (notificationsSupported) {
            return notifications.addListener('click', cb);
          }

          console.warn('attempting to add a listener when notifications are not supported');
          return () => {};
        };

        const notificationsAddPushListener = (cb: any) => {
          if (notificationsSupported) {
            return notifications.addListener('push', cb);
          }

          console.warn('attempting to add a listener when notifications are not supported');
          return () => {};
        };

        const extraProps = {
          notificationsSupported,
          notificationsSubscription,
          notificationsSubscribe,
          notificationsAddClickListener,
          notificationsAddPushListener,
        };

        return <Component {...props} {...extraProps} />;
      }}
    </NotificationsContext.Consumer>
  );
