import {
  IMAGE_DATA_CLEAR,
  IMAGE_DATA_SET_FRONT,
  IMAGE_DATA_SET_BACK,
  BOOKING_RESPONSE_RECEIVED,
} from '../constants/index';

export interface ImageDataState {
  front?: string;
  back?: string;
}

const INITIAL_STATE: ImageDataState = {};

export default function imageReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case IMAGE_DATA_SET_FRONT:
      return {
        ...state,
        front: action.payload.imgData,
      };
    case IMAGE_DATA_SET_BACK:
      return {
        ...state,
        back: action.payload.imgData,
      };
    case BOOKING_RESPONSE_RECEIVED:
    case IMAGE_DATA_CLEAR:
      return {};
    default:
      return state;
  }
}
