import {
  POSITION_SUCCESS,
  POSITION_ERROR,
  GETTING_POSITION,
  IP_REVERSE_GEOCODE_RESULTS_RECEIVED,
  IP_REVERSE_GEOCODE_FAILED,
} from '../constants/index';

function positionSuccess(value: any) {
  return {
    type: POSITION_SUCCESS,
    payload: { value },
    timeOfRequest: +new Date(),
  };
}

function positionError(value: any) {
  return {
    type: POSITION_ERROR,
    payload: { value },
  };
}

function setGettingPosition(value: any) {
  return {
    type: GETTING_POSITION,
    payload: { value },
  };
}

const setReverseGeoCodeLocation = (value: any) => ({
  type: IP_REVERSE_GEOCODE_RESULTS_RECEIVED,
  payload: { value },
});

const getReverseGeoCodeLocationFail = () => ({
  type: IP_REVERSE_GEOCODE_FAILED,
});

export {
  positionSuccess,
  positionError,
  setGettingPosition,
  setReverseGeoCodeLocation,
  getReverseGeoCodeLocationFail,
};
