import {
  SYMPTOM_SELECTED,
  SYMPTOMS_SUBMIT,
  PARSE_URL_PARAMETERS,
  GEOLOCATION_SUGGESTED,
  REQUEST_SOLV_EMAIL_SUBMIT_SUCCESS,
  REQUEST_SOLV_EMAIL_SUBMIT_FAILURE,
  REQUEST_SOLV_EMAIL_SUBMITTED,
} from '../constants/index';

function symptomSelected(value: { symptoms: string; patientType: any }) {
  return {
    type: SYMPTOM_SELECTED,
    payload: { value },
  };
}

function parseUrlParameters(value: any) {
  return {
    type: PARSE_URL_PARAMETERS,
    payload: { value },
  };
}

function geoSuggestedLocation(value: any) {
  return {
    type: GEOLOCATION_SUGGESTED,
    payload: { value },
  };
}

const setRequestSolvEmailSubmitSuccess = () => ({
  type: REQUEST_SOLV_EMAIL_SUBMIT_SUCCESS,
});

const setRequestSolvEmailSubmitFailure = () => ({
  type: REQUEST_SOLV_EMAIL_SUBMIT_FAILURE,
});

const submitRequestSolvEmail = () => ({
  type: REQUEST_SOLV_EMAIL_SUBMITTED,
});

const symptomsSubmit = (value: any) => ({
  type: SYMPTOMS_SUBMIT,
  payload: { value },
});

export {
  symptomSelected,
  parseUrlParameters,
  geoSuggestedLocation,
  setRequestSolvEmailSubmitSuccess,
  setRequestSolvEmailSubmitFailure,
  submitRequestSolvEmail,
  symptomsSubmit,
};
