import MobileDetect from 'mobile-detect';
import semver from 'semver';
import { isEmptyObject } from '../object';
import logger from '../../logger/index';
import { useSolvSelector } from '~/reducers';

const IOS_CUSTOM_APP_NAME = 'solv-mapp-native-ios';
const NATIVE_CUSTOM_APP_NAME = 'smnios';
export const IOS_PLATFORM_NAME = 'ios';
export const ANDROID_PLATFORM_NAME = 'android';

const NATIVE_SYSTEM_VERSION = 'sysV';
const NATIVE_DEVICE_ID = 'devId';
const NATIVE_BUILD_NUMBER = 'buildN';
const NATIVE_APP_VERSION = 'appV';
const NATIVE_DEVICE_LOCALE = 'devLocale';
const NATIVE_CARRIER = 'carrier';
const NATIVE_PLATFORM = 'platform';

const MIN_ANDROID_TELEMED_VERSION = 28;
const MIN_IOS_TELEMED_VERSION = 97;

/**
 * Deprecated because we should use below util function "useUserAgent" instead. getUserAgent is trying to fetch global.navigator, which
 * is okay in client side but not in server side. Since it is introducing critical bug like "CDP scramble", we decided to not use global
 * variable anymore. details here (https://github.com/solvhealth/mapp-dev/pull/9205/)
 *
 * TODO: change all potential places that use getUserAgent() to useUserAgent()
 *
 * @deprecated use {@link useUserAgent} instead.
 * @returns userAgent: string
 */
export function getUserAgent() {
  return !isEmptyObject(global.navigator) && typeof global.navigator.userAgent === 'string'
    ? global.navigator.userAgent
    : '';
}

/**
 * A custom hook function to get user agent value from redux state
 * Preferred way to get user agent since it works in both client side and server side.
 *
 * @returns userAgent: string
 */
export function useUserAgent() {
  return useSolvSelector((state) => state.sessionTrackingProperties.userAgent);
}

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const getOs = (userAgent?: string) => {
  const ua = userAgent ?? getUserAgent();
  let uaindex;
  let userOS = 'unknown';
  let userOSver = 'unknown';

  // determine OS
  if (ua.match(/iPad/i) || ua.match(/iPod/i) || ua.match(/iPhone/i)) {
    userOS = 'iOS';
    uaindex = ua.indexOf('OS ');
  } else if (ua.match(/Android/i)) {
    userOS = 'Android';
    uaindex = ua.indexOf('Android ');
  }

  // determine version
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  if (userOS === 'iOS' && uaindex > -1) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    userOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  } else if (userOS === 'Android' && uaindex > -1) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    userOSver = ua.substr(uaindex + 8, 3);
  }

  return { userOS, userOSver };
};

// SMS protocol are different between Android and iOS, and
// even between different iOS versions. Reference:
// http://stackoverflow.com/questions/39529099/trying-to-send-sms-in-ios-10-is-sms-protocol-broken
/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const getSmsSeparator = (userAgent?: string) => {
  const { userOS, userOSver } = getOs(userAgent);
  let separator = '?';
  if (userOS === 'iOS') {
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '<' cannot be applied to types 'string' a... Remove this comment to see the full error message
    separator = userOSver < 7 ? ';' : '&';
  }

  return separator;
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isPhone = () => (getUserAgent() ? new MobileDetect(getUserAgent()).phone() : false);

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 * Better type checking for the isPhone function.
 * returns a boolean value if it can find the user agent, and undefined if it can't
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const isPhoneOrUndefined = (userAgent?: string) => {
  const ua = userAgent ?? getUserAgent();
  return ua ? Boolean(new MobileDetect(ua).mobile()) : undefined;
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const isTablet = (userAgent?: string) => {
  const ua = userAgent ?? getUserAgent();
  return ua ? Boolean(new MobileDetect(ua).tablet()) : false;
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const isMobile = (userAgent?: string) => {
  const isThisPhone = isPhoneOrUndefined(userAgent);
  const isThisTablet = isTablet(userAgent);

  if (isThisPhone && !isThisTablet) {
    return true;
  }

  if (isThisPhone === false || isThisTablet) {
    return false;
  }

  return null;
};

/**
 * Convenience hook that uses useUserAgent to properly get serverside userAgent
 * and returns isMobile(userAgent)
 */
export function useIsMobile() {
  const userAgent = useUserAgent();
  return isMobile(userAgent);
}

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const isAndroidOS = (userAgent?: string) => {
  const ua = userAgent ?? getUserAgent();
  return ua ? new MobileDetect(ua).is('AndroidOS') : false;
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const isIOS = (userAgent?: string) => {
  const ua = userAgent ?? getUserAgent();
  return ua ? new MobileDetect(ua).is('iOS') : false;
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isSamsungNativeBrowser = () => getUserAgent().includes('SamsungBrowser');

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isSafari = () =>
  // because for some f*cking reason, chrome user agent also includes 'safari' on macosx
  getUserAgent().includes('Safari') && !getUserAgent().includes('Chrome');

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isChrome = () => getUserAgent().includes('Chrome');

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getDevice = () => {
  if (isMobile()) {
    return 'phone';
  }
  if (isTablet()) {
    return 'tablet';
  }

  return 'desktop';
};

export const isPwa = () =>
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'standalone' does not exist on type 'Navi... Remove this comment to see the full error message
  (window.navigator && window.navigator.standalone) ||
  (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches);

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isIosPwa = () => isPwa() && isIOS();

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isAndroidPwa = () => isPwa() && isAndroidOS();

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 */
export const isNativeApp = (ua?: string | null) => {
  const userAgent = ua || getUserAgent();
  return RegExp(`${IOS_CUSTOM_APP_NAME}|${NATIVE_CUSTOM_APP_NAME}`).test(userAgent);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const isIosSafariOnly = () => isIOS() && !isNativeApp();
/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 * Get the specified device info.
 *
 * Given a param, query the ua string for the param.
 * Returns {null} if not run inside a native (android/ios) app
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 * @param {string} testString
 * @returns {string|null}
 */
export const getNativeAppDeviceInfo = (testString: any, ua?: string) => {
  if (!isNativeApp(ua)) return null;
  try {
    const userAgent = ua ?? getUserAgent();
    const appVersionStringIndex = userAgent.search(testString);
    return userAgent.slice(appVersionStringIndex).split('-')[0].split(':')[1];
  } catch (e) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
    logger.debug('Error reading native device property', e);
    return null;
  }
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 * Is this inside the ios native app.
 *
 * @deprecated
 * @returns {boolean}
 */
export const isIosApp = () => getNativeAppDeviceInfo(NATIVE_PLATFORM) === IOS_PLATFORM_NAME;

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 * Is this inside the android native app. *
 *
 * @deprecated
 * @returns {boolean}
 */
export const isAndroidApp = () => getNativeAppDeviceInfo(NATIVE_PLATFORM) === ANDROID_PLATFORM_NAME;

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativeAppVersionInUse = () => {
  return getNativeAppDeviceInfo(NATIVE_APP_VERSION);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativeSystemVersion = () => {
  return getNativeAppDeviceInfo(NATIVE_SYSTEM_VERSION);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativeBuildNumber = () => {
  return getNativeAppDeviceInfo(NATIVE_BUILD_NUMBER);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativeDeviceId = (userAgent?: string) => {
  return getNativeAppDeviceInfo(NATIVE_DEVICE_ID, userAgent);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativeDeviceLocale = () => {
  return getNativeAppDeviceInfo(NATIVE_DEVICE_LOCALE);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativeCarrier = () => {
  return getNativeAppDeviceInfo(NATIVE_CARRIER);
};

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export const getNativePlatform = () => getNativeAppDeviceInfo(NATIVE_PLATFORM);

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 *
 * @deprecated
 */
export function isNativeTelemedSupported() {
  const platform = getNativePlatform();
  const appVersion = getNativeBuildNumber();

  if (!platform || !appVersion) return false;
  const supportedVersion =
    platform === 'android' ? MIN_ANDROID_TELEMED_VERSION : MIN_IOS_TELEMED_VERSION;

  // @ts-expect-error ts-migrate(2365) FIXME: Operator '>=' cannot be applied to types 'string' ... Remove this comment to see the full error message
  return appVersion >= supportedVersion;
}

/**
 * Deprecated due to use of deprecated {@link getUserAgent}.
 * Decides whether the phone requires bottom padding.
 *
 * The following chart is generated from this kind person
 * who hopefully keeps it relatively up to date.
 *
 * https://gist.github.com/adamawolf/3048717
 *
 *      DEVICE               ID        Safe
 * --------------------+-------------+-------
 * iPhone 8              iPhone10,4     🚫
 * iPhone 8 Plus         iPhone10,5     🚫
 * iPhone X              iPhone10,6     ✅
 * iPhone Xs             iPhone11,2     ✅
 * iPhone Xs Max         iPhone11,4     ✅
 * iPhone Xr             iPhone11,8     ✅
 * iPhone 11             iPhone12,1     ✅
 * iPhone 11 Pro         iPhone12,3     ✅
 * iPhone 11 Pro Max     iPhone12,5     ✅
 * iPhone SE (2nd gen)   iPhone12,8     🚫
 * iPhone 12 Mini        iPhone13,1     ✅
 * iPhone 12             iPhone13,2     ✅
 * iPhone 12 Pro         iPhone13,3     ✅
 * iPhone 12 Pro Max     iPhone13,4     ✅
 * iPhone 13 Pro         iPhone14,2     ✅
 * iPhone 13 Pro Max     iPhone14,3     ✅
 * iPhone 13 Mini        iPhone14,4     ✅
 * iPhone 13             iPhone14,5     ✅
 * iPhone SE 3rd Gen     iPhone14,6     🚫
 * iPhone 14             iPhone14,7     ✅
 * iPhone 14 Plus        iPhone14,8     ✅
 * iPhone 14 Pro         iPhone15,2     ✅
 * iPhone 14 Pro Max     iPhone15,3     ✅
 *
 * @deprecated please call this function with a userAgent using {@link useUserAgent}.
 * @returns {boolean} whether or not a safe area is required
 */
export const requiresBottomSafeArea = (userAgent?: string) => {
  const deviceId = getNativeDeviceId(userAgent)?.toLowerCase();
  const versionNumber = getNativeAppVersionInUse();

  if (versionNumber && semver.gt(versionNumber, '4.5.0')) {
    // Starting in version 4.5.1, we handle safe area in mapp-native
    // Instead of hacking in extra padding in mapp-dev
    return false;
  }

  switch (deviceId) {
    case 'iphone10,6':
    case 'iphone11,2':
    case 'iphone11,4':
    case 'iphone11,8':
    case 'iphone12,1':
    case 'iphone12,3':
    case 'iphone12,5':
    case 'iphone13,1':
    case 'iphone13,2':
    case 'iphone13,3':
    case 'iphone13,4':
    case 'iphone14,2':
    case 'iphone14,3':
    case 'iphone14,4':
    case 'iphone14,5':
    case 'iphone14,7':
    case 'iphone14,8':
    case 'iphone15,2':
    case 'iphone15,3':
      return true;
    default:
      return false;
  }
};
