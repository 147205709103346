import React, { Component, SyntheticEvent } from 'react';
import PictureInterface from '@solvhealth/types/interfaces/Picture';
import { isEmpty } from '../../../core/util/empty';
import { THUMBNAIL_SMALL } from './util';
import { emptyFunction } from '../../../core/util/function';

type OwnProps = {
  imageObject: PictureInterface;
  imgTag?: any;
  className?: string;
  showThumbnail?: boolean;
  thumbnailSize?: string;
  onPictureLoad?: (...args: any[]) => any;
  alt?: string;
  onClick?: (e: SyntheticEvent) => void;
  itemProp?: string;
  role?: string;
  title?: string;
  width?: any;
};

type Props = OwnProps & typeof Picture.defaultProps;

class Picture extends Component<Props> {
  static defaultProps = {
    onPictureLoad: emptyFunction,
  };

  render() {
    const {
      imageObject: { webp, jp2, fullRes, thumbnail128, thumbnail200 },
      onPictureLoad,
      imgTag,
      className,
      showThumbnail,
      thumbnailSize,
      ...imageProps // jscs: disable
    } = this.props;
    const ImgTag = imgTag || 'img';

    if (showThumbnail && thumbnailSize === THUMBNAIL_SMALL && !isEmpty(thumbnail128)) {
      return (
        <ImgTag className={className} {...imageProps} onLoad={onPictureLoad} src={thumbnail128} />
      );
    }

    if (showThumbnail && !isEmpty(thumbnail200)) {
      return (
        <ImgTag className={className} {...imageProps} onLoad={onPictureLoad} src={thumbnail200} />
      );
    }

    return (
      <picture className={className}>
        {webp && <source onLoad={onPictureLoad} srcSet={webp} type="image/webp" />}

        {jp2 && <source onLoad={onPictureLoad} srcSet={jp2} type="image/jp2" />}
        {fullRes && (
          <ImgTag className={className} {...imageProps} onLoad={onPictureLoad} src={fullRes} />
        )}
      </picture>
    );
  }
}
export default Picture;
