/* eslint-disable func-names */
/* jscs:disable maximumLineLength */
import moment from 'moment-timezone';
import findIndex from 'lodash/findIndex';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import Hours from '@solvhealth/types/interfaces/Location/Hours';
import { PhotoIdUploadEntrypoint } from '@solvhealth/types/interfaces/Location/PhotoIdUploadEntrypoints';
import { isAndroidApp, isIosApp } from './device';
/* eslint-disable max-len */
import { formatInsurerType } from './insurance';
import { isEmptyObject, safeGet } from './object';
import { CLOSE_SOON_TIME, MINIMUM_HIGHLY_RATED_FOR, OPEN_SOON_TIME } from '../../config/index';
import {
  filterSlotsByAvailability,
  getTodayAndTomorrowSlots,
  getTodaySlots,
  getTomorrowSlots,
  hasAppointmentSoonAfterRequestedAppointmentTime,
  hasSlotsAvailable,
} from '../../core/location/slots';
import {
  AFC_EAST_TN_GROUP_ID,
  BARTON_GROUP_ID,
  BUMPS_N_BRUISES_LOCATION_ID,
  CARE_FIRST_LOCATION_IDS,
  CHILDREN_URGENT_CARE_CHICAGO_LOCATION_IDS,
  CHILDRENS_URGENT_CARE_GROUP_ID,
  GO_HEALTH_GROUP_ID,
  IMMEDIATE_CARE_MEDICAL_WALK_IN_GROUP_ID,
  INTELLIVISIT_GROUP_ID,
  METRO_URGENT_CARE_GROUP_ID,
  MULTICARE_GROUP_ID,
  NEXT_CARE_GROUP_ID,
  NEXT_CARE_SOLV_TESTING_GROUP_ID,
  NIGHTLIGHT_PEDIATRIC_URGENT_CARE,
  PM_PEDIATRICS_TELEMED_SCHOOL_DISTRICT,
  PM_PEDIATRICS_TELEMED_TEST_SANDBOX_SCHOOL_DISTRICT,
  POP_CARE_PLUS_IMMEDIATE_CARE_GROUP_ID,
  PORTLAND_URGENT_CARE_GROUP_ID,
  PRO_HEALTH_GROUP_ID,
  RELIANT_VIRTUAL_VISIT,
  RENOWN_URGENT_CARE_GROUP_ID,
  SEATTLE_CANCER_CARE_ALLIANCE_GROUP_ID,
  SEATTLE_FREE_COVID_TESTING_AURORA,
  SEATTLE_FREE_COVID_TESTING_AURORA_SYMPTOMATIC,
  SEATTLE_FREE_COVID_TESTING_SODO,
  SEATTLE_FREE_COVID_TESTING_SODO_SYMPTOMATIC,
  SEATTLE_FREE_COVID_TESTING_TEST_QUEUE,
  SMART_CARE_SCHOOL_DISTRICT,
  SMART_CARE_SF,
  SMART_CARE_TEST,
  TUTTLE_SCHOOL_DISTRICT,
  UCFK_LOCATION_1,
  UCFK_LOCATION_2,
  UNIVERSITY_URGENT_CARE_LOCATION_ID,
  URGENT_CARE_FOR_KIDS_AISD_TELEMED,
  URGENT_CARE_FOR_KIDS_EL_PASO_SCHOOL_DISTRICT_TELEMED,
  URGENT_CARE_FOR_KIDS_GCTC_TELEMED,
  URGENT_CARE_FOR_KIDS_GROUP_ID,
  URGENT_CARE_FOR_KIDS_LOCATION_IDS,
  URGENT_CARE_FOR_KIDS_MCISD_TELEMED,
  URGENT_CARE_FOR_KIDS_PSJA_TELEMED,
  URGENT_CARE_FOR_KIDS_TELEMED,
  URGENT_CARE_FOR_KIDS_TRAINING_TELEMED,
  WESTMED_MEDICAL_GROUP_ID,
} from '~/config/locations';
import { DAPI_TIME_FORMAT, STANDARD_TIME_FORMAT } from './date';
import {
  CLOSED,
  MARKETPLACE_DFW,
  ORDERED_WEEK_DAYS,
  PACKAGE_NAME_SOLV_EXPRESS,
  PLATFORM_TYPE_PROVIDER_GROUP,
  PLATFORM_TYPE_TRADITIONAL,
  TIME_MIDNIGHT,
  TYPE_SELF,
  TYPE_SELF_PAY,
  TYPE_TRICARE,
  TYPE_TRIWEST,
  TYPE_TRIWEST_LABEL,
  TYPE_UNITED_HEALTHCARE,
  TYPE_UNITED_HEALTHCARE_LABEL,
} from '../../constants/index';
import {
  EMERGENCY_ROOM,
  EMERGENCY_ROOMS,
  FAMILY_DOCTOR,
  FREESTANDING_ER,
  HOME_HEALTHCARE_DB,
  HOSPITAL,
  HOSPITAL_ER,
  HOSPITAL_OWNED_URGENT_CARE,
  HOSPITALS,
  INTERNAL_MEDICINE,
  LABORATORY_TESTING,
  OCCUPATIONAL_MEDICINE,
  PEDIATRIC_URGENT_CARE,
  PEDIATRICIANS,
  PRIMARY_CARE,
  RETAIL_CLINIC,
  SPECIALTY_URGENT_CARE_CATEGORIES,
  TELEHEALTH,
  UCL_LOCATION_CATEGORY_TYPES,
  URGENT_CARE,
} from '../../constants/category';
import { isEmpty } from './empty';
import { isEmptyArray } from './array';
import { formatPhone, isEmptyString, queryStringFromObject, toTitleCase } from './string';
import { is24Hours, isDateAfterIntervalInSeconds } from './time';
import {
  DENTIST_VALUE,
  getProviderTypeName,
  URGENT_CARE_VALUE,
} from '../../components/Home/Tiles/ProviderTypes';

import { dayFull } from '../../components/DateTime';
import {
  CovidTestSlugs,
  FluShotSlugs,
  SampleAnalysisTimes,
} from '../../components/ClinicDetailPageFlexible/components/CovidTesting/util';
import { EN } from './localizedStr';

const SELF_PAY_ONLY = 'self_pay_only';

const isSolvPartner = (location?: LocationInterface) => location?.isSolvPartner;
const isBookable = (location: LocationInterface) => location && location.isBookable;
const hasBoost = (location?: LocationInterface) => location && location.hasBoost;
const isViewable = (location: any) => location && location.isViewable;
const isCovidTestingOnly = (location: any) => location && location.isCovidTestingOnly;
const isVaccinationOnly = (location: any) => location && location.isVaccinationOnly;
const getLocationTimeZone = (location: any): string => location.timeZone ?? location.time_zone;

const hasCovidAntibodyTest = (servicesObj: any) => {
  const labsAndTests = safeGet(servicesObj, [])`Labs and Tests`;
  const slugs = labsAndTests.map((lab: any) => lab.slug);
  return slugs.includes(CovidTestSlugs.ANTIBODY);
};

const hasCovidTest = (servicesObj: any) => {
  const labsAndTests = safeGet(servicesObj, [])`Labs and Tests`;
  const slugs = labsAndTests.map((lab: any) => lab.slug);
  return slugs.includes(CovidTestSlugs.COVID_TEST);
};

export const hasCovidTestType = (servicesObj: any, type: string) => {
  const labsAndTests = servicesObj?.['Labs and Tests'];
  if (!labsAndTests) return false;
  return (
    findIndex(labsAndTests, function (test: { slug: string }) {
      return test.slug === type;
    }) !== -1
  );
};

export const findIndexCovidTestType = (servicesObj: any, type: string) => {
  const labsAndTests = servicesObj['Labs and Tests'];
  if (!labsAndTests) return -1;
  return findIndex(labsAndTests, function (test: { slug: string }) {
    return test.slug === type;
  });
};

const isCovidTestingV1 = (location: any) => {
  const { servicesObj } = location;
  return hasCovidAntibodyTest(servicesObj) || hasCovidTest(servicesObj);
};

// V2 contains 5 tests, even though antibody technically is from V1, but we are applying new styles on it
export const isCovidTestingV2 = (location: any) => {
  const { servicesObj } = location;
  return (
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_PCR_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_RAPID_PCR_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_ANTIGEN_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_RAPID_ANTIGEN_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.ANTIBODY)
  );
};

export const containsCovidNewService = (location: any) => {
  const { servicesObj } = location;
  return (
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_PCR_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_RAPID_PCR_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_ANTIGEN_TEST) ||
    hasCovidTestType(servicesObj, CovidTestSlugs.COVID_RAPID_ANTIGEN_TEST)
  );
};

const locationOffersFluShot = (location: any) => {
  const { servicesObj } = location;
  const vaccinations = servicesObj?.Vaccinations ?? [];
  const slugs = vaccinations.map((shot: any) => shot.slug);
  return !!slugs.includes(FluShotSlugs.FLU_SHOT);
};

const isCovidTestFree = (location: any) => location && location.isCovidTestFree;

const isWaitlistSmsDisabled = (location?: any) =>
  location?.isWaitlistSmsDisabled ?? location?.is_waitlist_sms_disabled;

const offersRapidCovidTesting = (servicesObj: any) => {
  if (!hasCovidTest(servicesObj)) return false;

  const labsAndTests = safeGet(servicesObj, [])`Labs and Tests`;
  const covidTestData = labsAndTests.filter(
    (test: any) => test.slug === CovidTestSlugs.COVID_TEST
  )[0];
  const inHouseResultTime = safeGet(covidTestData)('details.sample_analysis.in_house.result_time');
  const sendOutResultTime = safeGet(covidTestData)('details.sample_analysis.send_out.result_time');

  return (
    inHouseResultTime === SampleAnalysisTimes.LessThanOneHour ||
    sendOutResultTime === SampleAnalysisTimes.LessThanOneHour
  );
};

const isInMarketPlace = (location: any) => location && location.isInMarketplace;

const isTraditionalLocation = (location: LocationInterface) =>
  location && location.platformType === PLATFORM_TYPE_TRADITIONAL;

const hasHoursDefault = (location: any) => {
  if (isEmptyObject(location.hoursDefault)) {
    return false;
  }

  const hours = location.hoursDefault;
  for (const day in hours) {
    if (hours.hasOwnProperty(day)) {
      if (!isEmptyArray(hours[day])) {
        return true;
      }
    }
  }

  return false;
};

const isNextDayAppointmentsDisabled = (location: any) =>
  location && location.isNextDayAppointmentsDisabled;

const isBeyondNextDayAppointmentsEnabled = (location: any) =>
  location && location.isBeyondNextDayAppointmentsEnabled && hasHoursDefault(location);

const attributesByType = (values: any, attributeType: any) => {
  const facilities = {};
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      if (
        values[key].attribute_type === attributeType &&
        values[key].attribute_value !== 'no' &&
        values[key].attribute_value !== 'false'
      ) {
        // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
        facilities[key] = values[key];
      }
    }
  }

  return facilities;
};

const getBrands = (location: any) => safeGet(location, {})('brands');

const hasBrands = (location: any) => !isEmpty(getBrands(location));

const isNextInLineFeatureEnabled = (location: any) => location.hasNextInLine;

const allowsSelfPay = (location: LocationInterface) => location?.acceptedInsuranceTypes?.self_pay;

const isSelfPayOnly = (location: any) =>
  safeGet(location, false)(`acceptedInsuranceTypes.${SELF_PAY_ONLY}`);

const validFacilities = (values: any) => attributesByType(values, 'service');

const getLocationServices = (location: any) =>
  [].concat.apply([], Object.values(safeGet(location, [])('services')));

const getUCLAcceptedInsurances = (location: LocationInterface) =>
  location?.acceptedInsurances ?? [];

export const getTraditionalLocationAcceptedInsurances = (location: LocationInterface) =>
  safeGet(location, {})('acceptedInsurers').map((insurer: any) => insurer.display_name);

const getCollectedUrgentCareAcceptedInsuranceTypes = (
  location?: LocationInterface,
  opts: { formatInsurerType?: boolean } = {}
) => {
  const acceptedInsuranceTypes: string[] = [];

  if (!isSelfPayOnly(location)) {
    Object.entries({
      ...location?.acceptedInsuranceTypes,
    }).forEach(([key, value]) => {
      if (value) {
        if (key === TYPE_TRICARE) acceptedInsuranceTypes.push(key.toUpperCase());
        else if (key === TYPE_SELF_PAY) acceptedInsuranceTypes.push(TYPE_SELF);
        else if (key === TYPE_TRIWEST) acceptedInsuranceTypes.push(TYPE_TRIWEST_LABEL);
        else if (key === TYPE_UNITED_HEALTHCARE)
          acceptedInsuranceTypes.push(TYPE_UNITED_HEALTHCARE_LABEL);
        else acceptedInsuranceTypes.push(key);
      }
    });
  }

  if (isEmptyArray(acceptedInsuranceTypes)) {
    return acceptedInsuranceTypes;
  }

  if (opts.formatInsurerType) {
    return acceptedInsuranceTypes.map(formatInsurerType);
  }

  return acceptedInsuranceTypes;
};

/*
  Accepted insurances come from different sources
  IF TRADITIONAL LOCATION (dentist, optometrist etc) : they should come from
    getTraditionalLocationAcceptedInsurances method which looks at the  acceptedInsurers fields in
    the response
  IF NOT TRADITIONAL LOCATION:
     IF NOT SOLV PARTNER: we should look at the data coming from UCL (acceptedInsurances in the
     response). if that's present use that, otherwise fall back to the data that might be collected
     manually by OPS [most likely will be empty]
     IF SOLV PARTNER: OPS usually collect this information
       during onboarding, so we should trust this data coming from
       getCollectedUrgentCareAcceptedInsuranceTypes
       which looks at the acceptedInsuranceTypes field from the response
 */
const getAcceptedInsurances = (location: LocationInterface) => {
  const collectedAcceptedInsuranceTypes = getCollectedUrgentCareAcceptedInsuranceTypes(location, {
    formatInsurerType: true,
  });
  const uclAcceptedInsurances = getUCLAcceptedInsurances(location);

  if (isTraditionalLocation(location)) {
    return getTraditionalLocationAcceptedInsurances(location);
  }

  if (!isSolvPartner(location) && !isEmpty(uclAcceptedInsurances)) {
    return uclAcceptedInsurances;
  }

  return collectedAcceptedInsuranceTypes;
};

const hasKnownHours = (hours: Hours) =>
  hours && ORDERED_WEEK_DAYS.some((day) => !isEmptyArray(hours[day]));

const validPrices = (values: any) => attributesByType(values, 'price');

const locationStarScore = (location: any) => {
  let starRating = 0;
  if (isSolvPartner(location)) {
    starRating = location.ratingSolv;
  } else {
    if (isEmptyObject(location.scores) || isEmptyObject(location.scores.aggregated)) {
      starRating = 0;
    }

    for (const score of location.scores.aggregated) {
      if (score.topic === 'overall') {
        starRating = score.score;
        break;
      }
    }
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  return isEmptyString(starRating) ? null : Number.parseFloat(starRating, 10);
};

const getLocationRatingAndRatingCount = (location: any) => {
  let ratingCount;
  let rating;
  if (isSolvPartner(location)) {
    ratingCount = location.ratingSolvCount;
    rating = location.ratingSolv;
  } else {
    ratingCount = location.ratingNonSolvCount;
    rating = location.ratingNonSolv;
  }

  return { ratingCount, rating: Number(rating) };
};

/**
 * Filter ratings down to those we want to display in the
 * "Highly Rated For" section of the CDP.
 *
 * @param {Array} ratings
 * @returns {Array}
 */
const validHighlyRatedFor = (ratings: any) => {
  if (!ratings || ratings.constructor !== Array) {
    return [];
  }

  const filtered = ratings.filter(
    (rating) =>
      rating.score >= MINIMUM_HIGHLY_RATED_FOR &&
      rating.topic !== 'other' &&
      rating.topic !== 'overall'
  );
  for (const rating of filtered) {
    rating.label = rating.topic.replace('_', ' ');
    rating.label = rating.label.charAt(0).toUpperCase() + rating.label.slice(1);
  }

  return filtered;
};

const formatDisplayAddress = (location: any) => {
  const address = location.displayAddress || location.address;

  return location.subpremise
    ? `${address} ${location.subpremise}, ${location.city}, ${location.state} ${location.zipCode}`
    : `${address}, ${location.city}, ${location.state} ${location.zipCode}`;
};

const formatDisplayShortAddress = (location: any) => {
  const address = location.displayAddress || location.address;
  return location.subpremise
    ? `${address}, ${location.subpremise}, ${location.city}`
    : `${address}, ${location.city}`;
};

const formatDisplayStreetAddress = (location: LocationInterface) => {
  const address = location.displayAddress || location.address;
  return location.subpremise ? `${address}, ${location.subpremise}` : `${address}`;
};

const formatCityAndState = (location: any) => `${location.city}, ${location.state}`;

const formatCityStateZip = (location: LocationInterface) =>
  `${formatCityAndState(location)} ${location.zipCode}`;

const getFullDisplayAddress = (location: any) => {
  const address = !isEmptyString(location.displayAddress)
    ? location.displayAddress
    : location.address;
  return location.subpremise ? `${address}, ${location.subpremise}` : address;
};

const getCdpDisplayName = (location: LocationInterface): string =>
  `${location.displayNamePrimary}${
    location.displayNameSecondary ? `, ${location.displayNameSecondary}` : ''
  }`;

const encodeCityName = (city: any) => city.split(/\s+/g).join('-');

const decodeCityName = (city: any) => city.split('-').join(' ');

const getTodayHours = (location: any) => {
  const dt = moment.tz(location.timeZone).locale(EN);
  const dayOfWeek = dt.format('dddd');
  if (!isEmptyObject(location) && !isEmptyObject(location.hours)) {
    if (!isEmptyObject(location.hours[dayOfWeek]) && !isEmptyObject(location.hours[dayOfWeek][0])) {
      const open = location.hours[dayOfWeek][0].fromTime ?? location.hours[dayOfWeek][0].from_time;
      const close = location.hours[dayOfWeek][0].toTime ?? location.hours[dayOfWeek][0].to_time;

      if (!isEmptyObject(open) && !isEmptyObject(close)) {
        return { fromTime: open, toTime: close };
      }
    }
  }

  return {};
};

const getTomorrowHours = (location: any) => {
  const dt = moment.tz(location.timeZone).locale(EN).add(1, 'day');
  const dayOfWeek = dt.format('dddd');
  if (!isEmptyObject(location) && !isEmptyObject(location.hours)) {
    if (!isEmptyObject(location.hours[dayOfWeek]) && !isEmptyObject(location.hours[dayOfWeek][0])) {
      const open = location.hours[dayOfWeek][0].fromTime;
      const close = location.hours[dayOfWeek][0].toTime;

      if (!isEmptyObject(open) && !isEmptyObject(close)) {
        return { fromTime: open, toTime: close };
      }
    }
  }

  return {};
};

const getAnyDateHours = (location: any, date: any) => {
  if (isEmptyObject(location)) return {};

  const dayOfWeek = moment(date).tz(location.timeZone).locale(EN).format('dddd');

  const hours = date.isBefore(moment().tz(location.timeZone).add(7, 'days'), 'day')
    ? location.hours
    : location.hoursDefault;

  if (!isEmptyObject(hours)) {
    if (!isEmptyObject(hours[dayOfWeek]) && !isEmptyObject(hours[dayOfWeek][0])) {
      const open = hours[dayOfWeek][0].fromTime;
      const close = hours[dayOfWeek][0].toTime;
      if (!isEmptyObject(open) && !isEmptyObject(close)) {
        return { fromTime: open, toTime: close };
      }
    }
  }

  return {};
};

const dateHasOpenHours = (location: any, date: any) => {
  if (!date) return false;
  const dayHours = getAnyDateHours(location, date);
  return dayHours.hasOwnProperty('fromTime') && dayHours.hasOwnProperty('toTime');
};

const dateIsOutsideOfBeyondRange = (location: any, date: any) => {
  if (!date || !location.beyondNextDayLimit) return false;

  return isDateAfterIntervalInSeconds(date, location.beyondNextDayLimit);
};

const isAlwaysOpen = (location: any) => {
  const todayHours = getTodayHours(location);
  if (!todayHours.hasOwnProperty('fromTime') || !todayHours.hasOwnProperty('toTime')) {
    return false;
  }

  const openTime = todayHours.fromTime;
  const closeTime = todayHours.toTime;

  return is24Hours(openTime, closeTime);
};

/**
 * Return the next date that a given location is open
 *
 * @param {Object} location
 * @param {moment} after date to search after
 * @returns {moment} moment date of next available date
 */
const getNextOpenDate = (location: any, after = moment().tz(location.timeZone)) => {
  const openDate = after.clone();
  for (let daysAhead = 0; daysAhead <= 7; ++daysAhead)
    if (dateHasOpenHours(location, openDate.add(daysAhead && 1, 'days'))) break;

  return openDate;
};

const hasOpenHoursToday = (location: any) => {
  const todayHours = getTodayHours(location);
  return todayHours.hasOwnProperty('fromTime') && todayHours.hasOwnProperty('toTime');
};

const isAfterHoursToday = (location: LocationInterface) => {
  if (hasOpenHoursToday(location)) {
    const todayHours = getTodayHours(location);
    const { toTime } = todayHours;
    const HoursMinSec = toTime.split(':');
    const h = parseInt(HoursMinSec[0], 10);
    const m = parseInt(HoursMinSec[1], 10);
    const closeTime = moment.tz(location.timeZone).set({ h, m });
    const now = moment().tz(location.timeZone);
    return now.isAfter(closeTime);
  }

  return false;
};

const isCurrentlyClosedToday = (location: LocationInterface) => {
  if (hasOpenHoursToday(location)) {
    const todayHours = getTodayHours(location);
    const { toTime } = todayHours;
    const HoursMinSec = toTime.split(':');
    const h = parseInt(HoursMinSec[0], 10);
    const m = parseInt(HoursMinSec[1], 10);
    const closeTime = moment.tz(location.timeZone).set({ h, m });
    const now = moment().tz(location.timeZone);
    return now.isAfter(closeTime);
  }

  return true;
};

const hasOpenHoursTomorrow = (location: any) => {
  const tomorrowHours = getTomorrowHours(location);
  return tomorrowHours.hasOwnProperty('fromTime') && tomorrowHours.hasOwnProperty('toTime');
};

const hasPhoneNumber = (location: any) => !!location && !!formatPhone(location.phone);

const isClosedTodayAndTomorrow = (location: any) =>
  !hasOpenHoursToday(location) && !hasOpenHoursTomorrow(location);

const hasAvailabilityToday = (location: LocationInterface, newBooking: any) => {
  const todaySlots = getTodaySlots(newBooking, location);
  return hasSlotsAvailable(todaySlots);
};

const hasAvailabilityTomorrow = (location: LocationInterface, newBooking: any) => {
  const tomorrowSlots = getTomorrowSlots(newBooking, location);
  return hasSlotsAvailable(tomorrowSlots);
};

const getAvailabilityForMultiWidgetProviderCard = (location: any, newBooking: any) => {
  const todayAndTomorrowSlots = getTodayAndTomorrowSlots(newBooking, location);
  const todaySlots = filterSlotsByAvailability(todayAndTomorrowSlots.today);
  const tomorrowSlots = filterSlotsByAvailability(todayAndTomorrowSlots.tomorrow);
  return {
    today: hasSlotsAvailable(todaySlots),
    tomorrow: hasSlotsAvailable(tomorrowSlots),
    isNextDayAppointmentsDisabled: isNextDayAppointmentsDisabled(location),
    isBeyondNextDayAppointmentsEnabled: isBeyondNextDayAppointmentsEnabled(location),
    nextAvailableDate: getNextOpenDate(location),
  };
};

const isOpenNow = (location: any) => {
  const todayHours = getTodayHours(location);
  if (!todayHours.hasOwnProperty('fromTime') || !todayHours.hasOwnProperty('toTime')) {
    return false;
  }

  const tz = getLocationTimeZone(location);

  const openTime = todayHours.fromTime;
  const closeTime = todayHours.toTime;

  const todayString = moment.tz(tz).format('YYYY-MM-DD');
  const openMoment = moment.tz(`${todayString} ${openTime}`, 'YYYY-MM-DD HH:mm:ss', tz);

  let closeMoment = null;

  // make close time 11:59:59 if clinic closes at midnight so that isbetween can compare correctly
  if (closeTime === TIME_MIDNIGHT) {
    closeMoment = moment.tz(`${todayString} 23:59:59`, 'YYYY-MM-DD HH:mm:ss', tz);
  } else {
    closeMoment = moment.tz(`${todayString} ${closeTime}`, 'YYYY-MM-DD HH:mm:ss', tz);
  }

  return moment.tz().isBetween(openMoment, closeMoment);
};

const reservationsDisabledNow = (location: any) =>
  moment.tz().isBefore(location.disableReservationsUntil);

const isOpenSoon = (location: any) => {
  const tz = location.timeZone;
  const todayHours = getTodayHours(location);
  if (!todayHours.hasOwnProperty('fromTime')) {
    return false;
  }

  const openTime = moment.tz(todayHours.fromTime, 'HH:mm:ss', tz);

  return moment.tz(tz).isBetween(moment(openTime).subtract(OPEN_SOON_TIME, 'hours'), openTime);
};

const isClosingSoon = (location: any) => {
  const tz = location.timeZone;
  const todayHours = getTodayHours(location);
  if (!todayHours.hasOwnProperty('fromTime')) {
    return false;
  }

  let closeTime = null;
  if (todayHours.toTime === TIME_MIDNIGHT) {
    closeTime = moment.tz('23:59:59', 'HH:mm:ss', tz);
  } else {
    closeTime = moment.tz(todayHours.toTime, 'HH:mm:ss', tz);
  }

  return moment.tz(tz).isBetween(moment(closeTime).subtract(CLOSE_SOON_TIME, 'hours'), closeTime);
};

const isOutOfBusiness = (location: any) =>
  !isEmpty(location.status) ? location.status === CLOSED : false;

const validPriceList = (values: any) => {
  const prices = validPrices(values);
  const priceList = [];
  for (const attribute in prices) {
    if (prices.hasOwnProperty(attribute)) {
      // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
      if (!isEmptyObject(prices[attribute].attribute_value)) {
        // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
        priceList.push(parseInt(prices[attribute].attribute_value, 10));
      }
    }
  }

  return priceList;
};

const minPrice = (values: any) => Math.min(...validPriceList(values));

const maxPrice = (values: any) => Math.max(...validPriceList(values));

const haveDrivingDistance = (locationId: any, distanceMatrix: any) =>
  locationId &&
  distanceMatrix &&
  distanceMatrix.results &&
  distanceMatrix.results.hasOwnProperty(locationId);

const getDrivingDistance = (locationId: any, distanceMatrix: any) =>
  safeGet(distanceMatrix)(`results.${locationId}.distance.text`);

const isGoodEnoughMatch = (location: any, appointmentTime: any) =>
  hasAppointmentSoonAfterRequestedAppointmentTime(location, appointmentTime);

const formatLocationName = (
  location:
    | {
        displayNamePrimary?: string;
        displayNameSecondary?: string | null;
        display_name_primary?: string;
        display_name_secondary?: string | null;
      }
    | undefined
    | null = {},
  separator = ' - '
) =>
  `${location?.displayNamePrimary ?? (location?.display_name_primary || '')}${
    (location?.displayNameSecondary ?? (location?.display_name_secondary || '')) &&
    `${separator}${location?.displayNameSecondary ?? location?.display_name_secondary}`
  }`;

const getLocationWithHardCodedLogoAndName = (location: any) => {
  let updatedKeysObject;

  // Alliance clinic (JA68Mg) changed from PUCCFW to UK4Ks
  // But we're *only* sending bills for old bookings (PUCCFW) so the logo needs
  // to look like the old ownership not the new.
  if (location.id === 'JA68Mg') {
    updatedKeysObject = {
      displayNamePrimary: 'Pediatric Urgent Care of Forth Worth',
      displayNameSecondary: 'Alliance',
      imageLinks: {
        ...location.imageLinks,
        // jscs:disable maximumLineLength
        logo: [
          'https://d3hmu1js3tz3r1.cloudfront.net/p/JA68Mg/Pediatric-Urgent-Care-of-Fort-Worth-Fort-Worth_20170922001746_logo.png',
        ],
      },
    };
  }

  // UrgiKids clinic (AzWY8p) changed from UrgiKids Pediatric Urgent Care to PM Pediatrics
  // But we're *only* sending bills for old bookings (UrgiKids Pediatric Urgent Care) so the logo needs
  // to look like the old ownership not the new.
  if (location.id === 'AzWY8p') {
    updatedKeysObject = {
      displayNamePrimary: 'UrgiKids',
      displayNameSecondary: '',
      imageLinks: {
        ...location.imageLinks,
        // jscs:disable maximumLineLength
        logo: [
          'https://d3hmu1js3tz3r1.cloudfront.net/p/AzWY8p/UrgiKids-Naperville_20180611220534_logo.png',
        ],
      },
    };
  }

  return { ...location, ...updatedKeysObject };
};

const formatLocationSpecialties = (location: any) =>
  location && !isEmptyArray(location.specialties)
    ? location.specialties.reduce(
        (a: any, c: any) => (a ? `${a}, ${c.display_name}` : c.display_name),
        ''
      )
    : '';

const getLocationSpecialties = (location: any) => safeGet(location, [])('specialties');

const getLocationSpecialtyValue = (location: any) => {
  const specialties = getLocationSpecialties(location);
  if (!specialties || isEmptyArray(specialties)) return URGENT_CARE_VALUE;

  const specialtyValues = specialties.map((specialty: any) => specialty.name);
  // Since a location might have multiple specialties, pick the first one.
  // If no specialty provided - assume Urgent Care
  return specialtyValues[0];
};

const getLocationSpecialtyName = (location: any) => {
  const value = getLocationSpecialtyValue(location);
  return getProviderTypeName(value);
};

const isDentalLocation = (location: any) => getLocationSpecialtyValue(location) === DENTIST_VALUE;

const formatLocationCategory = (location: any) => {
  let chosenCategory = null;

  if (isEmptyArray(location.category)) {
    return 'Urgent Care';
  }

  const locationCategories = location.category.map((category: any) =>
    toTitleCase(category.replace(/_/g, ' '))
  );

  for (let i = 0; i <= UCL_LOCATION_CATEGORY_TYPES.length; i++) {
    if (locationCategories.includes(UCL_LOCATION_CATEGORY_TYPES[i])) {
      chosenCategory = UCL_LOCATION_CATEGORY_TYPES[i];
      break;
    }
  }

  if (
    locationCategories.includes(FREESTANDING_ER) ||
    locationCategories.includes(HOSPITAL) ||
    locationCategories.includes(HOSPITAL_ER) ||
    locationCategories.includes(HOSPITALS) ||
    locationCategories.includes(EMERGENCY_ROOMS)
  ) {
    return EMERGENCY_ROOM;
  }

  if (
    locationCategories.includes(INTERNAL_MEDICINE) ||
    locationCategories.includes(FAMILY_DOCTOR) ||
    locationCategories.includes(PEDIATRICIANS)
  ) {
    return PRIMARY_CARE;
  }

  if (locationCategories.includes(HOSPITAL_OWNED_URGENT_CARE)) {
    return URGENT_CARE;
  }

  return chosenCategory || locationCategories[0];
};

const formatLocationNameFromBooking = (booking: any, separator = '–') => {
  if (!booking) return '';

  const displayNamePrimary = booking.location_display_name_primary;
  if (booking.location_display_name_secondary) {
    return `${displayNamePrimary} ${separator} ${booking.location_display_name_secondary}`;
  }

  return displayNamePrimary || '';
};

const formatLocationNameFromInvoice = (invoice: any) => {
  if (isEmptyObject(invoice)) {
    return '';
  }

  const displayNamePrimary = invoice.display_name_primary;

  if (invoice.display_name_secondary) {
    return `${displayNamePrimary} - ${invoice.display_name_secondary}`;
  }

  return displayNamePrimary;
};

const isUrgentCareForKids = (locationId: any) =>
  URGENT_CARE_FOR_KIDS_LOCATION_IDS.includes(locationId);

const isUrgentCareForKidsTelemed = (location: any) => location.id === URGENT_CARE_FOR_KIDS_TELEMED;

const isCareFirstLocation = (locationId: any) => CARE_FIRST_LOCATION_IDS.includes(locationId);

const isChildrenUrgentCareChicago = (locationId: any) =>
  CHILDREN_URGENT_CARE_CHICAGO_LOCATION_IDS.includes(locationId);

const isUniversityUrgentCare = (locationId: any) =>
  locationId === UNIVERSITY_URGENT_CARE_LOCATION_ID;

const isSmartCareSF = (locationId: any) => SMART_CARE_SF === locationId;

const isSmartCareTest = (location: any) => location.id === SMART_CARE_TEST;

const getOpenHoursString = ({ location, date, separator = 'to' }: any) => {
  const dateAtLocationTimeZone = moment.tz(date, location.timeZone);
  const dayName = dateAtLocationTimeZone.format(dayFull);
  const hours = location.hours[dayName];
  const formattedHours = hours.map((interval: any) => {
    const startTime = moment(interval.fromTime, DAPI_TIME_FORMAT);
    const endTime = moment(interval.toTime, DAPI_TIME_FORMAT);
    return `${startTime.format(STANDARD_TIME_FORMAT)} ${separator} ${endTime.format(
      STANDARD_TIME_FORMAT
    )}`;
  });

  return formattedHours.join(', ');
};

const isInGroup = (location: any, groupId: any) =>
  !isEmptyArray(location.groups) &&
  location.groups.some((group: any) => group.group_id === groupId);

const isConfiguredForExternalTracking = (location: any) =>
  !isEmptyObject(location.trackingProperties);

const isPartOfNextCareGroup = (location: any, includeTest?: any) => {
  if (!includeTest) {
    return isInGroup(location, NEXT_CARE_GROUP_ID);
  }

  return (
    isInGroup(location, NEXT_CARE_GROUP_ID) || isInGroup(location, NEXT_CARE_SOLV_TESTING_GROUP_ID)
  );
};

const isPartOfUrgentCareForKidsGroup = (location: any) =>
  isInGroup(location, URGENT_CARE_FOR_KIDS_GROUP_ID);

const isPediatricPatientsAccepted = (location: any) => location.isPediatricPatientsAccepted;

const isAdultPatientsAccepted = (location: any) => location.isAdultPatientsAccepted;

const isPartOfMetroUrgentCareGroup = (location: any) =>
  isInGroup(location, METRO_URGENT_CARE_GROUP_ID);

const isPartOfChildrensUrgentCareGroup = (location: any) =>
  isInGroup(location, CHILDRENS_URGENT_CARE_GROUP_ID);

const isPartOfPopCarePlusImmediateCareGroup = (location: any) =>
  isInGroup(location, POP_CARE_PLUS_IMMEDIATE_CARE_GROUP_ID);

const isPartOfImmediateCareMedicalWalkInGroup = (location: any) =>
  isInGroup(location, IMMEDIATE_CARE_MEDICAL_WALK_IN_GROUP_ID);

const isPartOfNightLightPediatricUrgentCareGroup = (location: any) =>
  isInGroup(location, NIGHTLIGHT_PEDIATRIC_URGENT_CARE);

const isPartOfPortlandUrgentCareGroup = (location: any) =>
  isInGroup(location, PORTLAND_URGENT_CARE_GROUP_ID);

const isPartOfMulticareGroup = (location: any) => isInGroup(location, MULTICARE_GROUP_ID);

const isPartOfRenownGroup = (location: any) => isInGroup(location, RENOWN_URGENT_CARE_GROUP_ID);

const isPartOfBartonGroup = (location: any) => isInGroup(location, BARTON_GROUP_ID);

const isPartOfAFCEastTnGroup = (location: any) => isInGroup(location, AFC_EAST_TN_GROUP_ID);

const isPartOfGoHealthGroup = (location: any) => isInGroup(location, GO_HEALTH_GROUP_ID);

const isPartOfIntellivisitGroup = (location: any) => isInGroup(location, INTELLIVISIT_GROUP_ID);

const isPartOfSeattleCancerCareAllianceGroup = (location: LocationInterface) =>
  isInGroup(location, SEATTLE_CANCER_CARE_ALLIANCE_GROUP_ID);

const isTelemedLocation = (location: any) => location?.isTelemed ?? location?.is_telemed;

const isExternalTelemedLocation = (location: any) =>
  location?.isExternalTelemed ?? location?.is_external_telemed;

const getExternalTelemedCustomNextSteps = (location: any) =>
  location?.externalTelemedCustomNextSteps ?? location?.external_telemed_custom_next_steps;

const getExternalTelemedCustomTips = (location: any) =>
  location?.externalTelemedCustomTips ?? location?.external_telemed_custom_tips;

const isHomeHealthcareLocation = (location: LocationInterface) =>
  location?.category && location?.category.includes(HOME_HEALTHCARE_DB);

const isAddressHidden = (location: any) => location?.isAddressHidden ?? location?.is_address_hidden;

const isTestLocation = (location: any) =>
  location && (location.isTestLocation || location.is_test_location);

const isWidgetTelemedEnabled = (location: any) =>
  location && (location.isWidgetTelemedEnabled || location.is_widget_telemed_enabled);

const isProviderLocation = (location: any) =>
  location && (location.isProvider || location.is_provider);

const isAsapTelemedEnabledLocation = (location: any) =>
  location?.isAsapTelemedEnabled ?? location?.is_asap_telemed_enabled;

const isTelemedAsapEligible = (location: any) =>
  isTelemedLocation(location) && isAsapTelemedEnabledLocation(location) && isOpenNow(location);

const shouldShowVisitTimeDisclaimer = (location: any) =>
  !isTelemedLocation(location) &&
  (isPartOfNextCareGroup(location) ||
    isPartOfUrgentCareForKidsGroup(location) ||
    isPartOfChildrensUrgentCareGroup(location) ||
    isPartOfNightLightPediatricUrgentCareGroup(location) ||
    isPartOfImmediateCareMedicalWalkInGroup(location) ||
    isPartOfAFCEastTnGroup(location) ||
    isPartOfBartonGroup(location));

const isBumpsNBruisesLocation = (location: any) =>
  location && location.id === BUMPS_N_BRUISES_LOCATION_ID;

const shouldRequireEmailInBookingWidget = (location: any) =>
  location && location.isEmailRequiredForBookingWidget;

const isUberEnabled = (location: any) => location.isUberEnabled;

const isPaperworkEnabled = (location: any): boolean =>
  location && (location.isPaperworkEnabled || location.is_paperwork_enabled);

const areBookingPrescreenQuestionsEnabled = (location: any): boolean =>
  location && location.areBookingPrescreenQuestionsEnabled;

const isUrgentCare = (location: any) =>
  location && formatLocationCategory(location) === URGENT_CARE;
const isPediatricUrgentCare = (location: any) =>
  location &&
  (location.isPediatricsOnly || formatLocationCategory(location) === PEDIATRIC_URGENT_CARE);
const isRetailClinic = (location: any) =>
  location && formatLocationCategory(location) === RETAIL_CLINIC;
const isPrimaryCare = (location: any) =>
  location && formatLocationCategory(location) === PRIMARY_CARE;
const isOccupationalHealth = (location: any) =>
  location && formatLocationCategory(location) === OCCUPATIONAL_MEDICINE;
const isEmergencyRoom = (location: any) =>
  location && formatLocationCategory(location) === EMERGENCY_ROOM;
const isSpecialtyUrgentCare = (location: any) =>
  location && SPECIALTY_URGENT_CARE_CATEGORIES.includes(formatLocationCategory(location));
const isTelehealth = (location: any) => location && formatLocationCategory(location) === TELEHEALTH;

const isLaboratoryTesting = (location: LocationInterface) =>
  location && formatLocationCategory(location) === LABORATORY_TESTING;

const getLocationAttribute = (location: any, attribute: any) => {
  if (!location || isEmpty(location.attributes) || isEmpty(location.attributes[attribute])) {
    return null;
  }

  const value = location.attributes[attribute].attributeValue;

  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }

  return value;
};

const isV1Description = (location: any) => location.descriptionVariationTest === 'v1';
const isV2Description = (location: any) => location.descriptionVariationTest === 'v2';
const isV3Description = (location: any) => location.descriptionVariationTest === 'v3';

const isUrgentCareV1 = (location: any) => isUrgentCare(location) && isV1Description(location);
const isUrgentCareV2 = (location: any) => isUrgentCare(location) && isV2Description(location);
const isUrgentCareV3 = (location: any) => isUrgentCare(location) && isV3Description(location);

const getGoogleDirectionsUrl = (location: any) => {
  let protocol = 'https';
  if (isIosApp()) {
    protocol = 'maps'; // maps:// or geo:// vs https:// is so that it can link to a native app
  } else if (isAndroidApp()) {
    protocol = 'geo';
  }

  const baseUrl = `${protocol}://maps.google.com/`;
  const address = `${location.address}, ${location.city}, ${location.state} ${location.zipCode}`;
  const params = {
    f: 'd', // set layout format to directions
    ll: `${location.latLong.latitude},${location.latLong.longitude}`,
    view: 'map',
    q: location.displayNamePrimary,
    daddr: address,
    address,
  };

  return `${baseUrl}?${queryStringFromObject(params)}`;
};

/**
 * Return the locations latitude and longitude
 *
 * @param location
 * @returns {{latitude: {Number}, longitude: {Number}}}
 */
const getLatitudeLongitude = (location: any) => {
  const { latitude, longitude } = (location && location.latLong) || {};
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const lat = parseFloat(latitude, 10);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const lng = parseFloat(longitude, 10);

  if (Number.isNaN(lat) || Number.isNaN(lng)) {
    return null;
  }

  return { latitude: lat, longitude: lng };
};

const getLatLongStr = (location: any) => {
  const latLong = getLatitudeLongitude(location);

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  return `${latLong.latitude},${latLong.longitude}`;
};

const getGroupId = (location: any) =>
  isEmptyArray(location.groups) ? null : location.groups[0].group_id;

export const getGroupIds = (location: LocationInterface) => {
  const groupIds: any[] = [];
  location?.groups &&
    location.groups.forEach((group: any) => {
      if (group.group_id) groupIds.push(group.group_id);
    });
  return groupIds;
};

const getGroupName = (location: LocationInterface) =>
  isEmptyArray(location.groups) ? null : location.groups[0].name;

const isBirthSexRequired = (location: any) =>
  location && (location.isBirthSexRequired || location.is_birth_sex_required);

const isUrgentCareForKidsTelemedInSchoolsLocation = (location: any) =>
  [
    URGENT_CARE_FOR_KIDS_AISD_TELEMED,
    URGENT_CARE_FOR_KIDS_EL_PASO_SCHOOL_DISTRICT_TELEMED,
    URGENT_CARE_FOR_KIDS_GCTC_TELEMED,
    URGENT_CARE_FOR_KIDS_MCISD_TELEMED,
    URGENT_CARE_FOR_KIDS_PSJA_TELEMED,
    URGENT_CARE_FOR_KIDS_TRAINING_TELEMED,
    UCFK_LOCATION_1,
    UCFK_LOCATION_2,
  ].includes(location.id);

const isPmPediatricsTelemedSchoolDistrict = (location: any) =>
  location?.id === PM_PEDIATRICS_TELEMED_SCHOOL_DISTRICT;

const isPmPediatricsTelemedTestSandboxSchoolDistrict = (location: any) =>
  location?.id === PM_PEDIATRICS_TELEMED_TEST_SANDBOX_SCHOOL_DISTRICT;

const isSmartCareSchoolDistrict = (location: any) => location.id === SMART_CARE_SCHOOL_DISTRICT;

const isTuttleSchoolDistrict = (location: any) => location.id === TUTTLE_SCHOOL_DISTRICT;

const shouldRenderDirectTelemedFlow = (location: any) =>
  isUrgentCareForKidsTelemedInSchoolsLocation(location) ||
  isSmartCareSchoolDistrict(location) ||
  isTuttleSchoolDistrict(location) ||
  isPmPediatricsTelemedSchoolDistrict(location) ||
  isPmPediatricsTelemedTestSandboxSchoolDistrict(location);

const isInDfwMarket = (location: any) => location.market === MARKETPLACE_DFW;

const isLocationConsentFlowEnabled = (location: any) => location.isConsentEnabled;

const getDisplayNamePrimaryWithSpecialProHealthRule = (location: any) =>
  isInGroup(location, PRO_HEALTH_GROUP_ID) ? 'ProHEALTH Urgent Care' : location.displayNamePrimary;

const isReliantVirtualVisit = (location: any) => location.id === RELIANT_VIRTUAL_VISIT;

const isByAppointmentOnly = (location: any) =>
  location && (location.isByAppointmentOnly || location.is_by_appointment_only);

const shouldPromoteWalkingInWhenAllReservationsAreTaken = (location: any) =>
  !isByAppointmentOnly(location) && !isInGroup(location, WESTMED_MEDICAL_GROUP_ID);

const isBookingWidgetAddressRequired = (location: any) => {
  return !!(
    location &&
    (location.is_booking_widget_address_required || location.isBookingWidgetAddressRequired)
  );
};

const isCdpSrpAddressRequiredLocation = (location: any) =>
  location?.isCdpSrpAddressRequired ?? location?.is_cdp_srp_address_required;

const isStandaloneTelemed = (location: any) =>
  location && (location.isStandaloneTelemed || location.is_standalone_telemed);

const isPaperworkRequired = (location: any) => location && location.isPaperworkRequired;

const isPaymentsEnabled = (location: any) =>
  location && (location.isPaymentsEnabled || location.is_payments_enabled);

const isPaymentsRequired = (location: any) => location && location.isPaymentsRequired;

const isPaymentCollectionDuringBookingEnabled = (location: LocationInterface) =>
  location?.isPaymentDuringBookingEnabled;

const isPaymentCollectionDuringBookingRequired = (location: LocationInterface) =>
  location?.isPaymentDuringBookingRequired;

/** Please use isPhotoIdUploadEnabledForEntrypoint() below for any purpose where
 * we need to respect the Photo ID Upload Entrypoints field in manage */
const isPhotoIdUploadEnabled = (location: Pick<LocationInterface, 'isPhotoIdUploadEnabled'>) =>
  location && location.isPhotoIdUploadEnabled;

const isPhotoIdUploadEnabledForEntrypoint = (
  location: Pick<LocationInterface, 'isPhotoIdUploadEnabled' | 'photoIdUploadEntrypoints'>,
  entryPoint: PhotoIdUploadEntrypoint
) =>
  isPhotoIdUploadEnabled(location) &&
  Array.isArray(location.photoIdUploadEntrypoints) &&
  (entryPoint === 'solvAttributedBooking' || entryPoint === 'bookingWidget'
    ? location.photoIdUploadEntrypoints.includes(entryPoint) ||
      // @ts-ignore
      location.photoIdUploadEntrypoints.includes('booking') // Passively accept deprecated 'booking' value
    : location.photoIdUploadEntrypoints.includes(entryPoint));

const isInsuranceDisabled = (
  location: Pick<
    LocationInterface,
    'isInsuranceDisabledFromBooking' | 'hideInsuranceFlowSolvAttributedBooking'
  >,
  isSolvAttributedBooking?: boolean
) =>
  !!(
    location?.isInsuranceDisabledFromBooking ||
    (isSolvAttributedBooking && location?.hideInsuranceFlowSolvAttributedBooking)
  );

const isPhotoIdFrontRequired = (location: LocationInterface) =>
  location && location.isPhotoIdFrontRequired;

const isPhotoIdBackRequired = (location: LocationInterface) =>
  location && location.isPhotoIdBackRequired;

const isMobileCheckInEnabled = (location: any) =>
  location && (location.isMobileCheckInEnabled || location.is_mobile_check_in_enabled);

const isInsuranceOcrInPaperworkFlowEnabled = (location: any) =>
  location &&
  (location.isInsuranceOcrInPaperworkFlowEnabled ||
    location.is_insurance_ocr_in_paperwork_flow_enabled);

const isPaymentToggleHidden = (location: any) =>
  location && (location.isPaymentToggleHidden || location.is_payment_toggle_hidden);

const isCityOfSeattleFreeCovidTestingLocation = (location: any) =>
  location &&
  [
    SEATTLE_FREE_COVID_TESTING_AURORA,
    SEATTLE_FREE_COVID_TESTING_AURORA_SYMPTOMATIC,
    SEATTLE_FREE_COVID_TESTING_SODO,
    SEATTLE_FREE_COVID_TESTING_SODO_SYMPTOMATIC,
    SEATTLE_FREE_COVID_TESTING_TEST_QUEUE,
  ].includes(location.id);

const getAssociatedTelemedLocationId = (location: any) =>
  location && (location.associatedTelemedLocationId || location.associated_telemed_location_id);

const getCurrentlySelectedLocationFromState = (state: any): LocationInterface => {
  // in the new flexible CDP, a user can toggle between booking at a physical location or its associated telemed location.
  // we need to use whichever location is currently selected during the subsequent booking flow. since these components
  // are shared with other, pre-existing routes etc, it will fall back to state.location to make it backwards compatible.
  if (!isEmptyObject(state.currentlySelectedLocation)) {
    return state.currentlySelectedLocation;
  }

  return state.location;
};

const isBookingCodesEnabled = (location?: LocationInterface) => location?.isBookingCodesEnabled;

const isBookingCodeValid = (location?: LocationInterface) => location?.isBookingCodeValid;

const isProviderGroupLocation = (location?: LocationInterface) =>
  location?.platformType === PLATFORM_TYPE_PROVIDER_GROUP;

const isSolvExpress = (location: LocationInterface) => {
  return location?.package?.name === PACKAGE_NAME_SOLV_EXPRESS;
};

const isPhotoOnlyInsuranceFlow = isProviderGroupLocation;

const isDosageDropdownEnabled = (location: LocationInterface) =>
  location && location.isDosageDropdownEnabled;

const isReasonForVisitDropdownEnabled = (location: LocationInterface) =>
  location?.isReasonForVisitDropdownEnabled;

const getReasonForVisitDropdownOptions = (location: LocationInterface) =>
  location?.reasonForVisitDropdownOptions;

const isLegalNameRequired = (location?: LocationInterface) => location?.isLegalNameRequired;

const isWaitlistDisabled = (location: LocationInterface) =>
  isWaitlistSmsDisabled(location) || isSolvExpress(location);

const isContactlessCheckInAndWaitlistDisabled = (location: LocationInterface) =>
  isWaitlistDisabled(location) && location?.isMobileCheckInEnabled;

const areAllLocationsPediatricOnly = (locations: LocationInterface[]) => {
  if (!locations || !locations.length) return false;
  return locations.every((location) => location.isPediatricsOnly);
};
const isFamilyBookingsEnabled = (location: any) => location?.isFamilyBookingsEnabled;

export {
  allowsSelfPay,
  areAllLocationsPediatricOnly,
  areBookingPrescreenQuestionsEnabled,
  dateHasOpenHours,
  dateIsOutsideOfBeyondRange,
  decodeCityName,
  encodeCityName,
  formatCityAndState,
  formatCityStateZip,
  formatDisplayAddress,
  formatDisplayShortAddress,
  formatDisplayStreetAddress,
  formatLocationCategory,
  formatLocationName,
  formatLocationNameFromBooking,
  formatLocationNameFromInvoice,
  formatLocationSpecialties,
  getAcceptedInsurances,
  getAnyDateHours,
  getAssociatedTelemedLocationId,
  getAvailabilityForMultiWidgetProviderCard,
  getBrands,
  getCollectedUrgentCareAcceptedInsuranceTypes,
  getCurrentlySelectedLocationFromState,
  getDisplayNamePrimaryWithSpecialProHealthRule,
  getDrivingDistance,
  getFullDisplayAddress,
  getCdpDisplayName,
  getExternalTelemedCustomNextSteps,
  getExternalTelemedCustomTips,
  getGoogleDirectionsUrl,
  getGroupId,
  getGroupName,
  getLatitudeLongitude,
  getLatLongStr,
  getLocationAttribute,
  getLocationRatingAndRatingCount,
  getLocationServices,
  getLocationSpecialties,
  getLocationSpecialtyName,
  getLocationSpecialtyValue,
  getLocationTimeZone,
  getLocationWithHardCodedLogoAndName,
  getNextOpenDate,
  getOpenHoursString,
  getReasonForVisitDropdownOptions,
  getTodayHours,
  getTomorrowHours,
  getUCLAcceptedInsurances,
  hasAvailabilityToday,
  hasAvailabilityTomorrow,
  hasBoost,
  hasBrands,
  hasCovidAntibodyTest,
  hasCovidTest,
  hasKnownHours,
  hasOpenHoursToday,
  hasOpenHoursTomorrow,
  hasPhoneNumber,
  haveDrivingDistance,
  isAddressHidden,
  isAdultPatientsAccepted,
  isAfterHoursToday,
  isCurrentlyClosedToday,
  isAlwaysOpen,
  isAsapTelemedEnabledLocation,
  isNextDayAppointmentsDisabled,
  isBeyondNextDayAppointmentsEnabled,
  isBirthSexRequired,
  isBookable,
  isBookingCodesEnabled,
  isBookingCodeValid,
  isBookingWidgetAddressRequired,
  isBumpsNBruisesLocation,
  isByAppointmentOnly,
  isCareFirstLocation,
  isCdpSrpAddressRequiredLocation,
  isChildrenUrgentCareChicago,
  isCityOfSeattleFreeCovidTestingLocation,
  isClosedTodayAndTomorrow,
  isClosingSoon,
  isConfiguredForExternalTracking,
  isContactlessCheckInAndWaitlistDisabled,
  isCovidTestFree,
  isCovidTestingOnly,
  isCovidTestingV1,
  isDentalLocation,
  isDosageDropdownEnabled,
  isEmergencyRoom,
  isExternalTelemedLocation,
  isFamilyBookingsEnabled,
  isGoodEnoughMatch,
  isHomeHealthcareLocation,
  isInDfwMarket,
  isInGroup,
  isInMarketPlace,
  isInsuranceOcrInPaperworkFlowEnabled,
  isLaboratoryTesting,
  isLegalNameRequired,
  isLocationConsentFlowEnabled,
  isMobileCheckInEnabled,
  isNextInLineFeatureEnabled,
  isOccupationalHealth,
  isOpenNow,
  isOpenSoon,
  isOutOfBusiness,
  isPaperworkEnabled,
  isPaperworkRequired,
  isPartOfChildrensUrgentCareGroup,
  isPartOfGoHealthGroup,
  isPartOfImmediateCareMedicalWalkInGroup,
  isPartOfIntellivisitGroup,
  isPartOfMetroUrgentCareGroup,
  isPartOfMulticareGroup,
  isPartOfNextCareGroup,
  isPartOfPopCarePlusImmediateCareGroup,
  isPartOfPortlandUrgentCareGroup,
  isPartOfRenownGroup,
  isPartOfSeattleCancerCareAllianceGroup,
  isPartOfUrgentCareForKidsGroup,
  isPaymentCollectionDuringBookingEnabled,
  isPaymentCollectionDuringBookingRequired,
  isPaymentsEnabled,
  isPaymentsRequired,
  isPaymentToggleHidden,
  isPediatricPatientsAccepted,
  isPediatricUrgentCare,
  isPhotoIdBackRequired,
  isPhotoIdFrontRequired,
  isPhotoIdUploadEnabled,
  isPhotoIdUploadEnabledForEntrypoint,
  isPhotoOnlyInsuranceFlow,
  isPrimaryCare,
  isProviderGroupLocation,
  isProviderLocation,
  isReasonForVisitDropdownEnabled,
  isReliantVirtualVisit,
  isRetailClinic,
  isSelfPayOnly,
  isSmartCareSF,
  isSmartCareTest,
  isSolvExpress,
  isSolvPartner,
  isSpecialtyUrgentCare,
  isStandaloneTelemed,
  isTelehealth,
  isTelemedAsapEligible,
  isTelemedLocation,
  isTestLocation,
  isTraditionalLocation,
  isUberEnabled,
  isUniversityUrgentCare,
  isUrgentCare,
  isUrgentCareForKids,
  isUrgentCareForKidsTelemed,
  isUrgentCareForKidsTelemedInSchoolsLocation,
  isUrgentCareV1,
  isUrgentCareV2,
  isUrgentCareV3,
  isVaccinationOnly,
  isViewable,
  isWaitlistSmsDisabled,
  isWidgetTelemedEnabled,
  locationOffersFluShot,
  locationStarScore,
  maxPrice,
  minPrice,
  offersRapidCovidTesting,
  reservationsDisabledNow,
  shouldPromoteWalkingInWhenAllReservationsAreTaken,
  shouldRenderDirectTelemedFlow,
  shouldRequireEmailInBookingWidget,
  shouldShowVisitTimeDisclaimer,
  validFacilities,
  validHighlyRatedFor,
  validPriceList,
  validPrices,
  isInsuranceDisabled,
};
