import LocationInterface from '@solvhealth/types/interfaces/Location';
import { FETCH_PHYSICAL_LOCATIONS } from '../../sagas/physicalLocationsInGroup';

const PHYSICAL_LOCATIONS_ERROR = 'physicalLocations/PHYSICAL_LOCATIONS_ERROR';
const PHYSICAL_LOCATIONS_RECEIVED = 'physicalLocations/PHYSICAL_LOCATIONS_RECEIVED';

const physicalLocationsReceived = (value: any) => ({
  type: PHYSICAL_LOCATIONS_RECEIVED,
  payload: { value },
});

const physicalLocationsError = (value: any) => ({
  type: PHYSICAL_LOCATIONS_ERROR,
  payload: { value },
});

const getPhysicalLocations = (location: LocationInterface, latLong: any) => ({
  type: FETCH_PHYSICAL_LOCATIONS,
  location,
  latLong,
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case PHYSICAL_LOCATIONS_ERROR:
      return {
        ...state,
        physicalLocations: null,
        error: action.payload.value,
      };
    case PHYSICAL_LOCATIONS_RECEIVED:
      return {
        ...state,
        physicalLocations: action.payload.value,
        physicalLocationsReceived: true,
      };
    default:
      return state;
  }
};
export { physicalLocationsError, physicalLocationsReceived, getPhysicalLocations };
