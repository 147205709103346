import { connect } from 'react-redux';
import { LOGIN_VERIFY_PHONE_SUBMIT } from '../../sagas/signUp';
import { LoginController as InnerLoginController } from './LoginController';
import { setLoginAction } from '../../actions/login';
import { LOGIN_ACTION_SIGN_UP } from '../../constants/index';
import { LOGIN_SIGN_UP_MODAL } from '../LoginSignUpModal';
import { hideModal } from '../../actions/runtime';

const mapStateToProps = (state: any) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch: any) => ({
  submitPhoneVerification: (payload: any) =>
    dispatch({ type: LOGIN_VERIFY_PHONE_SUBMIT, ...payload }),
  goToSignUp: () => dispatch(setLoginAction(LOGIN_ACTION_SIGN_UP)),
  onLoginSuccess: () => dispatch(hideModal(LOGIN_SIGN_UP_MODAL)),
});

export const LoginController = connect(mapStateToProps, mapDispatchToProps)(InnerLoginController);
