const AB_TESTING_GROUP_ASSIGNMENT_RECEIVED = 'abTesting/ASSIGNMENT_RECEIVED';
const AB_TESTING_GROUP_ASSIGNMENT_ERROR = 'abTesting/ASSIGNMENT_ERROR';
const AB_TESTING_GROUP_FETCHING_ASSIGNMENT = 'abTesting/FETCHING_ASSIGNMENT';

export const abTestingGroupFetchingAssignment = (value: any) => ({
  type: AB_TESTING_GROUP_FETCHING_ASSIGNMENT,
  payload: { value },
});

export const abTestingGroupAssignmentReceived = (abTestName: any, value: any) => ({
  type: AB_TESTING_GROUP_ASSIGNMENT_RECEIVED,
  payload: { abTestName, value },
});

export const abTestingGroupAssignmentError = (abTestName: any, value: any) => ({
  type: AB_TESTING_GROUP_ASSIGNMENT_ERROR,
  payload: { abTestName, value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case AB_TESTING_GROUP_FETCHING_ASSIGNMENT:
      return {
        ...state,
        fetching: action.payload.value,
      };
    case AB_TESTING_GROUP_ASSIGNMENT_RECEIVED:
      return {
        ...state,
        [action.payload.abTestName]: action.payload.value,
      };
    case AB_TESTING_GROUP_ASSIGNMENT_ERROR:
      return {
        ...state,
        [action.payload.abTestName]: {
          error: action.payload.value,
        },
      };
    default:
      return state;
  }
};
