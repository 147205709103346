import {
  Box,
  Button,
  Link,
  PageHeader,
  Stack,
  Text,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@solvhealth/jigsaw';
// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { createForm, formShape } from 'rc-form';
import React, { PureComponent } from 'react';
import { withCookies } from 'react-cookie';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import {
  BIRTH_SEX_FEMALE,
  BIRTH_SEX_MALE,
  BIRTH_SEX_OTHER,
  LEGAL_TOS_URL,
  PRIVACY_URL,
} from '~/constants';
import { UserProfile } from '~/core/dapi/models';
import {
  birthDateInputValidator,
  emailInputValidator,
  emptyFieldValidator,
} from '~/core/util/rcForm/validators';
import { formatPhone } from '~/core/util/string';
import { AccountSummary } from '~/reducers/account';
import { redirectAfterLogin, shouldFinishSignup } from '../../core/login';
import withAuthentication from '../../core/login/withAuthentication';
import { isEmptyArray } from '../../core/util/array';
import { isEmptyObject } from '../../core/util/object';
import {
  getIsStatusUnverifiable,
  VerificationStatus,
} from '../PhoneLoginForm/util/verificationStatus';
import { BIRTH_SEX_FIELD } from '../SolvPatternLibrary/Form/BirthSexInput';
import Loading from '../SolvPatternLibrary/Loading';
import { JigsawMaskedDateTextField } from '../SolvPatternLibrary/MaskedTextField';
import { BIRTHDAY_ID, EMAIL_ID, FIRST_NAME_ID, LAST_NAME_ID, PHONE_ID } from './Fields/ids';

const DISABLE_BUTTON_TIMEOUT = 5000; // 5s in ms

type Props = {
  form: formShape;
  submitAccount: (form: any, isAuthorized?: boolean, accountId?: string) => void;
  loginButtonClick: () => void;
  isModal?: boolean;
  hideSignupModal?: () => void;
  login?: any;
  cookies: any;
  isAuthorized?: boolean;
  accountSummary?: any;
  isLoggedIn: boolean;
  preventRedirectAfterLogin?: boolean;
  redirectToUrl?: string;
  isBookingWidgetEmbedded?: boolean;
  verificationStatus: VerificationStatus;
  activeProfile?: UserProfile;
  setUserProfile: (profile?: UserProfile, accountSummary?: AccountSummary) => void;
  t: TFunction<['common']>;
};

type State = {
  submitting: boolean;
  isRedirecting: boolean;
};

class SignUpForm extends PureComponent<Props, State> {
  submitTimeout: any;

  state = { submitting: false, isRedirecting: false };

  componentDidUpdate() {
    if (this.state.isRedirecting) {
      setTimeout(() => this.handleCloseModal(), 200);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.submitTimeout);
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await this.props.form.validateFields();
    } catch (e) {
      return;
    }

    const { form, submitAccount, isAuthorized, accountSummary } = this.props;

    submitAccount(
      { ...form.getFieldsValue(), [PHONE_ID]: accountSummary.phone },
      isAuthorized,
      accountSummary.id
    );

    this.setState({ submitting: true, isRedirecting: true });
    this.submitTimeout = setTimeout(
      () => this.setState({ submitting: false }),
      DISABLE_BUTTON_TIMEOUT
    );
  };

  handleCloseModal = () => {
    const { isModal, hideSignupModal } = this.props;

    if (isModal) {
      hideSignupModal?.();
    }
  };

  redirectAfterAccountReceived = () => {
    const {
      preventRedirectAfterLogin,
      isBookingWidgetEmbedded,
      accountSummary,
      redirectToUrl,
      isLoggedIn,
      verificationStatus,
      activeProfile,
      setUserProfile,
    } = this.props;

    if (shouldFinishSignup(accountSummary, verificationStatus)) {
      return;
    }

    // Ensure we have a user profile set for booking
    setUserProfile(activeProfile, accountSummary);

    if (preventRedirectAfterLogin) return;

    if (isLoggedIn) {
      const isAfterSignup = true;
      this.setState({ isRedirecting: true }, () =>
        redirectAfterLogin(accountSummary, redirectToUrl, isBookingWidgetEmbedded, isAfterSignup)
      );
    }
  };

  render() {
    const { isRedirecting } = this.state;
    const { form, accountSummary, verificationStatus, t } = this.props;

    if (
      !isRedirecting &&
      !isEmptyObject(accountSummary) &&
      !isEmptyArray(accountSummary.user_profiles) &&
      !getIsStatusUnverifiable(verificationStatus)
    ) {
      this.redirectAfterAccountReceived();

      return (
        <Stack alignItems="center" p={4}>
          <Loading />
        </Stack>
      );
    }

    if (isRedirecting) {
      return (
        <Stack alignItems="center" p={4}>
          <Loading />
        </Stack>
      );
    }

    return (
      <Stack
        as="form"
        maxWidth="512px"
        mx="auto"
        onSubmit={this.handleSubmit}
        pt={4}
        px={2}
        space={4}
        width="100%"
      >
        <PageHeader
          subtitle={t('common:auth.signup.subtitle', { phone: formatPhone(accountSummary.phone) })}
          title={t('common:auth.signup.title')}
        />

        <Stack space={5}>
          <Stack space={3}>
            <Box display="grid" gridGap="16px" gridTemplateColumns="1fr 1fr">
              <TextField
                autoComplete="given-name"
                required
                {...form.getFieldProps(FIRST_NAME_ID, {
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      validator: emptyFieldValidator,
                    },
                  ],
                })}
                dense
                error={form.getFieldError(FIRST_NAME_ID)?.[0] && t('common:required')}
                title={t('common:form.firstName')}
              />
              <TextField
                autoComplete="family-name"
                required
                {...form.getFieldProps(LAST_NAME_ID, {
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      validator: emptyFieldValidator,
                    },
                  ],
                })}
                dense
                error={form.getFieldError(LAST_NAME_ID)?.[0] && t('common:required')}
                title={t('common:form.lastName')}
              />
            </Box>

            <TextField
              autoComplete="email"
              required
              {...form.getFieldProps(EMAIL_ID, {
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    required: true,
                    validator: emailInputValidator,
                  },
                ],
              })}
              dense
              error={form.getFieldError(EMAIL_ID)?.[0]}
              title={t('common:form.email')}
              type="email"
            />

            <TextField
              dense
              required
              {...form.getFieldProps(BIRTHDAY_ID, {
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    required: true,
                    validator: birthDateInputValidator,
                  },
                ],
              })}
              error={form.getFieldError(BIRTHDAY_ID)?.[0]}
              placeholder="MM/DD/YYYY"
              title={t('common:form.dateOfBirth')}
              type="date"
            />

            <ToggleButtonGroup
              dense
              error={form.getFieldError(BIRTH_SEX_FIELD)?.[0] && t('common:required')}
              {...form.getFieldProps(BIRTH_SEX_FIELD, {
                rules: [{ required: true }],
                validateTrigger: ['onBlur', 'onChange'],
              })}
              title={t('common:form.birthSex')}
            >
              <ToggleButton value={BIRTH_SEX_FEMALE}>{t('common:form.female')}</ToggleButton>
              <ToggleButton value={BIRTH_SEX_MALE}>{t('common:form.male')}</ToggleButton>
              <ToggleButton value={BIRTH_SEX_OTHER}>{t('common:form.other')}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Stack space={2}>
            <Text as="div" muted variant="body2">
              <Trans i18nKey="common:legal.agreeCreateAccount" t={t}>
                <Link external href={LEGAL_TOS_URL} inline openInNewWindow size="small">
                  0
                </Link>
                <Link external href={PRIVACY_URL} inline openInNewWindow size="small">
                  1
                </Link>
              </Trans>
            </Text>
            <Button
              data-testid="sign-up-submit"
              loading={this.state.submitting}
              size="large"
              type="submit"
            >
              {t('common:auth.otpForm.continueAndAgree')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

export default createForm()(
  withCookies(withAuthentication(withTranslation(['common'])(SignUpForm)))
);
