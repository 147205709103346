import Location from '@solvhealth/types/interfaces/Location';
import chunk from 'lodash/chunk';
import moment from 'moment/moment';
import { getRandomIntMinMax } from '~/core/util/numeric';
import { getCookie, setCookie } from '~/core/util/cookies';
import { HOME_HEALTHCARE_DB } from '~/constants/category';

const atHomeCareIndexCookieName = 'at_home_care_index_per_chunk';

export function includeAtHomeCareInSearch(
  atHomeCareLocations: Location[],
  currentLocations: Location[]
): Location[] {
  let atHomeCareIndexPerChunk = parseInt(getCookie(atHomeCareIndexCookieName) || '0', 10);

  if (!atHomeCareIndexPerChunk) {
    // There seems to be 2 cards that always get added to top of SRP, so index 1 is actually 4th in the list.
    atHomeCareIndexPerChunk = getRandomIntMinMax(1, 8);
    setCookie(atHomeCareIndexCookieName, atHomeCareIndexPerChunk, moment().add(1, 'hour').toDate());
  }

  if (currentLocations.length === 0) {
    return atHomeCareLocations;
  }

  const alreadyUsed = new Set(
    currentLocations.filter((l) => l.category.includes(HOME_HEALTHCARE_DB)).map((l) => l.id)
  );

  const chunked = chunk(
    currentLocations,
    currentLocations.length < 10 ? currentLocations.length : 10
  );

  for (let chunk of chunked) {
    const hasAtHomeCare = chunk.find((l) => l.category.includes(HOME_HEALTHCARE_DB));
    if (hasAtHomeCare) {
      continue;
    }
    const available = atHomeCareLocations.filter((l) => !alreadyUsed.has(l.id));
    if (available.length === 0) {
      break;
    }
    const randomLocation = available[getRandomIntMinMax(0, available.length - 1)];
    if (randomLocation) {
      // Want to make sure we only put it in the 3rd or above spot
      if (chunk.length - 1 < atHomeCareIndexPerChunk) {
        chunk.push(randomLocation);
      } else {
        chunk.splice(atHomeCareIndexPerChunk, 0, randomLocation);
      }
      alreadyUsed.add(randomLocation.id);
    }
  }

  return chunked.flat() as Location[];
}
