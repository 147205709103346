import { createSelector } from 'reselect';
import { EMPTY_ARRAY } from '~/core/util/array';
import { emptyFunction } from '~/core/util/function';
import { EMPTY_OBJECT } from '~/core/util/object';
import { SolvReduxState } from '~/reducers';
import { InsuranceCarrierPlanSagas } from '~/sagas/insuranceCarrierPlans';
import { getInsurersList } from '~/selectors/insurers';
import {
  indexesOfPlansOrganizedByCarrier,
  organizeInsuranceCarrierPlansByCarrier,
  sortInsuranceCarrierPlans,
} from './util';

const RECEIVE_INSURANCE_CARRIER_PLANS_FOR_LOCATION =
  'insuranceCarrierPlans/RECEIVE_INSURANCE_CARRIER_PLANS_FOR_LOCATION';
const ERROR_FETCHING_INSURANCE_CARRIER_PLANS_FOR_LOCATION =
  'insuranceCarrierPlans/ERROR_FETCHING_INSURANCE_CARRIER_PLANS_FOR_LOCATION';

export interface InsuranceCarrierPlan {
  /** ISO Datetime */
  created_date: string;
  external_insurer_identifier: string;
  external_plan_identifier: string;
  insurance_plan_display_name: string;
  insurance_plan_id: string;
  insurance_plan_type_display_name: string;
  insurance_plan_type_id: string;
  insurer_code: string;
  insurer_id: string;
  insurer_name: string;
  location_id: string;
  /** ISO Datetime */
  updated_date: string;
}

export interface InsuranceCarrierPlansState {
  locationId: string | null;
  status: 'unfetched' | 'fetching' | 'fetched' | 'error';
  error: any;
  insurancePlansGroupedByInsurer: {
    [insurer_code: string]: InsuranceCarrierPlan[];
  };
  insurancePlanLookupByPlanId: {
    [insurance_plan_id: string]: {
      insurer_code: string;
      indexInInsurancePlansGroupedByInsurer: number;
    };
  };
}

export const actions = {
  fetchInsuranceCarrierPlansForLocation(args: {
    locationId: string;
    onSuccess?(): void;
    onError?(): void;
    onComplete?(): void;
  }) {
    return {
      type: InsuranceCarrierPlanSagas.FETCH_INSURANCE_CARRIER_PLANS_FOR_LOCATION,
      payload: {
        locationId: args.locationId,
        onSuccess: args.onSuccess ?? emptyFunction,
        onError: args.onError ?? emptyFunction,
        onComplete: args.onComplete ?? emptyFunction,
      },
    };
  },
  receiveInsuranceCarrierPlansForLocation(args: {
    plans: InsuranceCarrierPlan[];
    locationId: string;
  }) {
    return {
      type: RECEIVE_INSURANCE_CARRIER_PLANS_FOR_LOCATION,
      payload: {
        plans: args.plans,
        locationId: args.locationId,
      },
    };
  },
  errorFetchingInsuranceCarrierPlansForLocation(args: { error: any; locationId: string }) {
    return {
      type: ERROR_FETCHING_INSURANCE_CARRIER_PLANS_FOR_LOCATION,
      payload: {
        error: args.error,
        locationId: args.locationId,
      },
    };
  },
};

const initState: InsuranceCarrierPlansState = {
  locationId: null,
  status: 'unfetched',
  error: null,
  insurancePlanLookupByPlanId: {},
  insurancePlansGroupedByInsurer: {},
};

const insuranceCarrierPlansReducer = (
  state: InsuranceCarrierPlansState = initState,
  action: any
): InsuranceCarrierPlansState => {
  switch (action.type) {
    case InsuranceCarrierPlanSagas.FETCH_INSURANCE_CARRIER_PLANS_FOR_LOCATION: {
      const {
        payload: { locationId },
      } = action as ReturnType<typeof actions.fetchInsuranceCarrierPlansForLocation>;
      return {
        ...state,
        locationId,
        status: 'fetching',
        error: null,
      };
    }
    case RECEIVE_INSURANCE_CARRIER_PLANS_FOR_LOCATION: {
      const {
        payload: { plans, locationId },
      } = action as ReturnType<typeof actions.receiveInsuranceCarrierPlansForLocation>;

      const sortedPlans = sortInsuranceCarrierPlans(plans);
      const insurancePlansGroupedByInsurer = organizeInsuranceCarrierPlansByCarrier(sortedPlans);
      const insurancePlanLookupByPlanId = indexesOfPlansOrganizedByCarrier(
        insurancePlansGroupedByInsurer
      );

      return {
        ...state,
        error: null,
        insurancePlanLookupByPlanId,
        insurancePlansGroupedByInsurer,
        locationId,
        status: 'fetched',
      };
    }
    case ERROR_FETCHING_INSURANCE_CARRIER_PLANS_FOR_LOCATION: {
      const {
        payload: { error },
      } = action as ReturnType<typeof actions.errorFetchingInsuranceCarrierPlansForLocation>;

      return {
        ...state,
        status: 'error',
        error,
      };
    }
    default:
      return state;
  }
};

export class selectors {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  private constructor() {}
  private static getSlice(state: SolvReduxState) {
    return state.insuranceCarrierPlans ?? null;
  }

  static getLocationId(state: SolvReduxState) {
    return selectors.getSlice(state)?.locationId ?? null;
  }

  static getError(state: SolvReduxState) {
    return selectors.getSlice(state)?.error ?? null;
  }

  static getStatus(state: SolvReduxState) {
    return selectors.getSlice(state)?.status ?? initState.status;
  }

  private static getInsurancePlansGroupedByInsurer(state: SolvReduxState) {
    return selectors.getSlice(state).insurancePlansGroupedByInsurer ?? EMPTY_OBJECT;
  }

  private static getInsurancePlanLookupByPlanId(state: SolvReduxState) {
    return selectors.getSlice(state).insurancePlanLookupByPlanId ?? EMPTY_OBJECT;
  }

  static getInsurancePlansForInsurer(state: SolvReduxState, insurer_code: string) {
    return selectors.getInsurancePlansGroupedByInsurer(state)[insurer_code] ?? EMPTY_ARRAY;
  }

  static getInsurancePlanById(state: SolvReduxState, insurance_plan_id: string) {
    const lookup = selectors.getInsurancePlanLookupByPlanId(state);
    const entry = lookup[insurance_plan_id];
    if (entry == null) return null;
    const { indexInInsurancePlansGroupedByInsurer: index, insurer_code } = entry;
    return selectors.getInsurancePlansForInsurer(state, insurer_code)?.[index] ?? null;
  }

  static getDoPlansExistForInsurer(state: SolvReduxState, insurer_code: string) {
    return !!selectors.getInsurancePlansGroupedByInsurer(state)?.[insurer_code]?.length;
  }

  static getInsurersThatHavePlansSelector = createSelector(
    getInsurersList,
    selectors.getInsurancePlansGroupedByInsurer,
    (insurersList, insurancePlansGroupedByInsurer) =>
      insurersList?.filter(
        (insurer) => !!insurancePlansGroupedByInsurer?.[insurer?.insurer_code]?.length
      ) ?? EMPTY_ARRAY
  );
}

export default insuranceCarrierPlansReducer;
