import {
  ADD_REVIEW_SET_STAR_RATING,
  ADD_REVIEW_REVIEW_CHANGE,
  ADD_REVIEW_SUBMIT_STATUS,
  ADD_REVIEW_RESPONSE_RECEIVED,
  ADD_REVIEW_SET_STATE,
  ADD_REVIEW_INITIAL,
  ADD_REVIEW_THANK_YOU,
  ADD_REVIEW_SET_COPY_STATUS,
  ADD_REVIEW_ERROR,
  ADD_REVIEW_SET_GOOGLE_ID,
  ADD_REVIEW_SET_RECOMMENDED_VALUE,
} from '../constants/index';

const initialState = {};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_REVIEW_SET_STAR_RATING:
      return {
        ...state,
        starRating: action.payload.value,
      };
    case ADD_REVIEW_REVIEW_CHANGE:
      return {
        ...state,
        review: action.payload.value,
      };
    case ADD_REVIEW_SUBMIT_STATUS:
      return {
        ...state,
        submitting: action.payload.value,
      };
    case ADD_REVIEW_RESPONSE_RECEIVED:
      return {
        ...state,
        submitting: false,
        responseReceived: action.payload.value,
        flowState: ADD_REVIEW_THANK_YOU,
      };
    case ADD_REVIEW_SET_STATE:
      return {
        ...state,
        flowState: action.payload.value,
      };
    case ADD_REVIEW_SET_COPY_STATUS:
      return {
        ...state,
        copyStatus: action.payload.value,
      };
    case ADD_REVIEW_ERROR:
      return {
        ...state,
        error: action.payload.value,
        submitting: false,
      };
    case ADD_REVIEW_SET_GOOGLE_ID:
      return {
        ...state,
        googleUrl: action.payload.value,
      };
    case ADD_REVIEW_SET_RECOMMENDED_VALUE:
      return {
        ...state,
        hasRecommendedClinic: action.payload.value,
      };
    default:
      return state;
  }
};
