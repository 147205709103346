import React, { useEffect } from 'react';
import {
  LOGIN_ACTION_LOGIN,
  LOGIN_ACTION_OTP_FORM,
  LOGIN_ACTION_SIGN_UP,
  LOGIN_ACTION_SIGN_UP_VERIFY,
} from '../../constants/index';
import SignUpForm from '../SignUpForm/connected';
import VerifyPhone from '../SignUpForm/PhoneNumberVerify';
import { SIGN_UP_LOGIN_CONTROLLER, SIGN_UP_SWITCH_ACTION } from '../../core/analytics/events';
import { analyticsTrackEvent } from '../../core/analytics';
import LoginForm from '~/components/LoginForm/LoginForm';

type Props = {
  login: { action: any };
  defaultAction?: string;
  isModal?: boolean;
  preventRedirectAfterLogin?: boolean;
  redirectToRemix?: boolean;
  redirectToUrl?: string;
};

export function LoginController(props: Props) {
  const { login, defaultAction } = props;

  const loginAction = login.action || defaultAction;
  useEffect(() => {
    analyticsTrackEvent(SIGN_UP_LOGIN_CONTROLLER, {
      loginAction,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsTrackEvent(SIGN_UP_SWITCH_ACTION, {
      loginAction,
    });
  }, [loginAction]);

  switch (loginAction) {
    case LOGIN_ACTION_SIGN_UP:
      return <SignUpForm {...props} />;
    case LOGIN_ACTION_SIGN_UP_VERIFY:
      return <VerifyPhone {...props} />;
    default:
    case LOGIN_ACTION_LOGIN:
    case LOGIN_ACTION_OTP_FORM:
      return <LoginForm {...props} />;
  }
}
