import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { getQueryParams } from '~/core/util/url';

type PremiumBookingState = {
  returnPath?: string;
  startPage?: string;
};

/**
 * Logic to make sure that the navbar back button can return the user to their
 * previous location independent of browser history
 *
 * @returns returnPath path to take user back too
 */
export function usePremiumBookingPersistedState() {
  const initialLocalStorageValue =
    typeof localStorage === 'undefined' ? undefined : localStorage.getItem('premium-booking-state');

  const queryParamsValue: PremiumBookingState = {
    returnPath: getQueryParams().return,
    startPage: getQueryParams().startPage,
  };

  const initialValue = (() => {
    if (queryParamsValue) return queryParamsValue;
    if (initialLocalStorageValue && typeof initialLocalStorageValue === 'string') {
      try {
        return JSON.parse(initialLocalStorageValue);
      } catch (e) {
        return null;
      }
    }
    return null;
  })();

  const [state, setState] = useState<PremiumBookingState | null>(initialValue);

  const query = getQueryParams();
  useEffect(() => {
    const newStateObject = {
      returnPath: query?.return,
      startPage: query?.startPage,
    };
    if (!query.returnPath && !query.startPage) return;

    if (newStateObject && !isEqual(state, newStateObject)) {
      setState(newStateObject);
      localStorage.setItem('premium-booking-state', JSON.stringify(newStateObject));
    }
  }, [query, state]);

  const clearState = () => {
    localStorage.removeItem('premium-booking-state');
  };

  return { ...state, clearState };
}
