import { DAPI_HOST } from '../../config/index';

export const getInsuranceProfileByIdUrl = (insuranceProfileId: any) =>
  `${DAPI_HOST}/v1/insurance-profiles/${insuranceProfileId}`;

export const createInsuranceProfileUrl = () => `${DAPI_HOST}/v1/insurance-profiles`;

export const getInsuranceProfilesForAccount = (accountId: string) =>
  `${DAPI_HOST}/v1/accounts/${accountId}/insurance-profiles?valid_for_booking=true`;

export const getInsuranceInfoByBooking = (bookingId: any) =>
  `${DAPI_HOST}/v1/user-profile-has-insurance?booking_id=${bookingId}`;

export const getInsuranceDetailsUrl = (
  eligibilityCheckId: any,
  insuranceProfileId: any,
  serviceCodes: any
) =>
  `${DAPI_HOST}/v2/eligibility/${eligibilityCheckId}/details` +
  `?insurance_profile_id=${insuranceProfileId}&service_code=${serviceCodes.join(',')}`;
