import { call, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_SEARCH_RADIUS } from '../../../../config/index';
import {
  getAccountLocationsListUrl,
  getFavoriteLocationsUrl,
  getGroupTelemedLocations,
  getLocationUrl,
  getTraditionalLocations,
} from '../../../../core/dapi/location';
import { setActiveLocation } from '../../../../actions/location';
import { profileFormSubmission } from '../../../../actions/profile';
import { apiGet, apiGetJson, apiPatch, apiPostJson, apiPost } from '../../../../core/dapi';
import {
  getNonSolvPartnersSearchUrl,
  getSolvPartnersSearchUrl,
} from '../../../../core/dapi/search';
import {
  getUserProfileById,
  buildDapiUserProfileObject,
  createUserProfileUrl,
  createAppointmentPreferencesUrl,
  getSpecialtyPreferencesUrl,
  createSpecialtyPreferencesUrl,
  getAppointmentPreferencesUrl,
} from '../../../../core/dapi/userProfile';
import { getAppointmentsForUserProfileUrl } from '../../../../core/dapi/account';
import { parseLatLongString } from '../../../../core/util/geo';
import { safeGet, convertSnakeToCamelCase } from '../../../../core/util/object';
import {
  submitLocations,
  setLocations,
  errorLocations,
  setTraditionalLocations,
  setFavoriteLocations,
  fetchAppointmentDataFinished,
  fetchAppointmentDataStarted,
  fetchGetCareLocationsDataStarted,
  fetchGetCareLocationsDataFinished,
  setNearbyLocations,
} from '../../../../ducks/locations';
import {
  pastAppointmentsError,
  pastAppointmentsReceived,
  upcomingAppointmentsError,
  upcomingAppointmentsReceived,
  userProfileError,
  userProfileSpecialtyPreferencesError,
  receiveUserProfileSpecialtyPreferences,
  receiveUserProfileAppointmentPreferences,
  userProfileAppointmentPreferencesError,
  fetchUserProfileSpecialtyPreferences,
  fetchUserProfileAppointmentPreferences,
  receiveUserProfileForAccount,
  fetchingAccountAppointments,
} from '../../../../actions/account';
import {
  AccountFileType,
  METERS_PER_MILE,
  UploadStatus,
  UPCOMING_BOOKING_STATUSES,
  BookingStatus,
} from '../../../../constants/index';
import { URGENT_CARE_VALUE } from '../../../Home/Tiles/ProviderTypes';
import {
  getGooglePlacesDetailsUrl,
  getGooglePlacesPharmacySearchUrl,
  getGooglePlacesSearchUrl,
} from '../../../../core/google/places';
import { setRuntimeVariable } from '../../../../actions/runtime';
import {
  receiveUserProfileForNewBooking,
  setBookingProfilePending,
} from '../../../../actions/newBooking';
import logger from '../../../../core/logger/index';
import { userProfilePhotoUpload } from '../../../../ducks/profile/photo';
import { putToS3, s3SignedUrl } from '../../../../core/dapi/cardUpload';
import { getProfilePhotoName } from '../../../../core/dapi/amazon';
import { getAccountImageUploadUrl } from '../../../../core/dapi/images';
import { accountFileUpload } from '../../../../ducks/account/photo';
import ExifImageLoader from '../../../../core/util/ExifImageLoader';
import { createBlob } from '../../../../core/util/image';
import { fetchImages } from '../../../../sagas/postVisitSummary';
import { completeOnboardingStep } from '../../../../ducks/onboarding';
import history from '../../../../core/history';
import { removeParentheses } from '~/core/util/string';

export const ACCOUNT_CREATE_PROFILE = 'account/CREATE_PROFILE';
export const ACCOUNT_UPDATE_PROFILE = 'account/UPDATE_PROFILE';

export function* fetchLocation({ locationId }: any) {
  const locationUrl = getLocationUrl(locationId);

  const locationResponse = yield call(apiGet, locationUrl);

  yield put(setActiveLocation(locationResponse));
}

export function* fetchLocationAddToLocations({ locationId }: any) {
  const locationUrl = getLocationUrl(locationId);
  const locationResponse = yield call(apiGet, locationUrl);
  yield put(setLocations([locationResponse]));
}

export function* fetchAppointments({ userProfileId }: any) {
  try {
    yield put(fetchAppointmentDataStarted());
    yield put(fetchingAccountAppointments(true));

    const url = getAppointmentsForUserProfileUrl(userProfileId);
    const bookings = yield call(apiGet, url);

    const upcomingBookings = safeGet(
      bookings,

      []
    )('results').filter((row: any) => UPCOMING_BOOKING_STATUSES.includes(row.status));
    const pastBookings = safeGet(
      bookings,

      []
    )('results').filter((row: any) => row.status === BookingStatus.DISCHARGED);
    yield put(upcomingAppointmentsReceived(upcomingBookings));
    yield put(pastAppointmentsReceived(pastBookings));
    yield put(fetchingAccountAppointments(false));

    try {
      yield put(submitLocations(true));

      // @ts-ignore
      const locationIds: string[] = [...new Set(bookings.results.map((b: any) => b.location_id))];
      if (locationIds && locationIds.length > 0) {
        const locations = yield call(apiGet, getAccountLocationsListUrl(locationIds));
        yield put(setLocations(locations.results));
      }
    } catch (e) {
      yield put(errorLocations(e));
    }
    yield put(fetchAppointmentDataFinished());
  } catch (e) {
    yield put(upcomingAppointmentsError(e));
    yield put(pastAppointmentsError(e));
    yield put(fetchAppointmentDataFinished());
    yield put(fetchingAccountAppointments(false));
  }
}

export function* searchPharmacies({ name, latLng }: any) {
  // TODO: Figure out how to reduce this API cost, either by reducing
  //       the number of calls or replacing the data source
  const formattedLatLng = removeParentheses(latLng);
  try {
    const url = name
      ? getGooglePlacesSearchUrl(name, formattedLatLng)
      : getGooglePlacesPharmacySearchUrl(formattedLatLng);

    const results = yield call(apiGetJson, url);

    if (!isEmpty(results) && !isEmpty(results.results)) {
      yield put(
        setRuntimeVariable({
          name: 'pharmacySearchResults',
          value: results.results,
        })
      );
    }
  } catch (e) {
    logger.warn(e);
  }
}

export function* clearPharmacies() {
  try {
    yield put(
      setRuntimeVariable({
        name: 'pharmacySearchResults',
        value: [],
      })
    );
  } catch (e) {
    logger.warn(e);
  }
}

export function* fetchPharmacy({ placeId }: any) {
  try {
    const url = getGooglePlacesDetailsUrl(placeId);
    const result = yield call(apiGetJson, url);

    if (!isEmpty(result) && !isEmpty(result.result)) {
      yield put(setRuntimeVariable({ name: 'pharmacy', value: result.result }));
    } else {
      yield put(setRuntimeVariable({ name: 'pharmacy', value: null }));
    }
  } catch (e) {
    logger.warn(e);
  }
}

export function* uploadAccountFile(params: any) {
  try {
    const { accountId, userProfileId, bookingId } = params;
    const imageType = AccountFileType.PVS_PHOTO;
    const url = getAccountImageUploadUrl();
    const results = yield call(apiPostJson, url, {
      account_id: accountId,
      user_profile_id: userProfileId,
      booking_id: bookingId,
      image_type: imageType,
    });
    yield put(accountFileUpload({ status: '', bookingId }));
    const name = results.data.image_url;

    const imageLoader = new ExifImageLoader(params.file);
    const rawJpeg = yield imageLoader.getDataURL();
    const fileBlob = createBlob(rawJpeg);

    const image = {
      name,
      image: fileBlob,
      type: 'image/jpg',
      contentDisposition: 'inline',
    };
    const { signedRequest } = yield call(s3SignedUrl, image);

    yield put(accountFileUpload({ status: UploadStatus.PROCESSING, bookingId }));
    yield call(putToS3, signedRequest, image);
    yield put(accountFileUpload({ status: UploadStatus.COMPLETED, bookingId }));

    yield call(fetchImages, { accountId, bookingId, userProfileId, imageType });
  } catch (e) {
    logger.error(e);
  }
}

export function* fetchFavoriteLocations({ accountId }: any) {
  try {
    yield put(submitLocations(true));
    const url = getFavoriteLocationsUrl(accountId);
    const locations = yield call(apiGet, url);
    yield put(setFavoriteLocations(locations.results));
  } catch (e) {
    console.error(e);
  }
}

export function* uploadProfilePhoto({ imageFile, userProfileId, onSuccess }: any) {
  try {
    yield put(userProfilePhotoUpload({ status: '' }));

    const name = getProfilePhotoName(userProfileId);
    const image = { name, image: imageFile, type: 'image/jpg' };
    const { signedRequest } = yield call(s3SignedUrl, image);

    yield put(userProfilePhotoUpload({ status: UploadStatus.PROCESSING }));
    yield call(putToS3, signedRequest, image);
    yield put(userProfilePhotoUpload({ status: UploadStatus.COMPLETED }));
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const response = yield call(apiPatch, getUserProfileById(userProfileId), {
      photo_upload_date: 'NOW()',
    });
    yield put(receiveUserProfileForAccount(response));
    if (onSuccess) onSuccess();
  } catch (e) {
    yield put(userProfilePhotoUpload(UploadStatus.ERROR));
    logger.error(e);
  }
}

export function* setBirthSex({ userProfileId, birthSex }: any) {
  try {
    const userProfilePatchUrl = getUserProfileById(userProfileId);
    const patchData = { birth_sex: birthSex };

    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const response = yield call(apiPatch, userProfilePatchUrl, patchData);
    yield put(receiveUserProfileForAccount(response));
  } catch (e) {
    logger.error(e);
  }
}

export function* fetchNearbyLocations({ position }: any) {
  if (position) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 11 arguments, but got 7.
    const partnerUrl = getSolvPartnersSearchUrl(
      position,
      { value: null },
      [],
      URGENT_CARE_VALUE,
      1,
      Math.round(DEFAULT_SEARCH_RADIUS * METERS_PER_MILE),
      null
    );
    const partnerResult = yield call(apiGet, partnerUrl);
    const partnerLocations = partnerResult.results;
    if (partnerLocations.length) {
      yield put(setNearbyLocations(partnerLocations));
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 11 arguments, but got 6.
      const nonPartnerUrl = getNonSolvPartnersSearchUrl(
        position,
        { value: null },
        [],
        URGENT_CARE_VALUE,
        1,
        Math.round(DEFAULT_SEARCH_RADIUS * METERS_PER_MILE)
      );
      const nonPartnerResults = yield call(apiGet, nonPartnerUrl);
      const nonPartnerLocations = nonPartnerResults.results;
      if (nonPartnerLocations.length) {
        yield put(setNearbyLocations(nonPartnerLocations));
      }
    }
  }
}

export function* fetchGetCareLocations({ booking }: any) {
  try {
    const userLocations = [];
    let recentLocation = null;

    yield put(fetchGetCareLocationsDataStarted());
    if (booking.location_id) {
      const recentUrl = getLocationUrl(booking.location_id);
      recentLocation = yield call(apiGet, recentUrl);
      userLocations.push(recentLocation);
    }

    const groupId = safeGet(recentLocation)('groups.0.group_id');
    if (groupId) {
      const telemedUrl = getGroupTelemedLocations(groupId);
      const telemedResult = yield call(apiGet, telemedUrl);
      if (telemedResult.results.length) {
        userLocations.push(...telemedResult.results);
      }
    }

    if (userLocations.length) {
      yield put(setLocations(userLocations));
    }

    const latLong = booking.zip_lat_long;
    if (latLong) {
      const latLongObject = parseLatLongString(latLong);
      const recommendedUrl = getTraditionalLocations(latLongObject);
      const recommendedResult = yield call(apiGet, recommendedUrl);
      const traditionalLocations = recommendedResult.results;
      if (traditionalLocations.length) {
        yield put(setTraditionalLocations(traditionalLocations));
      }
    }
    yield put(fetchGetCareLocationsDataFinished());
  } catch (e) {
    logger.error(e);
    yield put(fetchGetCareLocationsDataFinished());
  }
}

export function* createVerifiedUserProfile({
  formValues,
  accountId,
  currentScreen,
  redirectToAccountHome,
  currentlyBooking,
}: any) {
  try {
    const userProfileData = {
      ...buildDapiUserProfileObject(formValues),
      account_id: accountId,
      is_verified: true,
      is_hidden: false,
    };

    const url = createUserProfileUrl();
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const response = yield call(apiPost, url, userProfileData);
    yield put(receiveUserProfileForAccount(response));
    if (currentlyBooking) {
      yield put(receiveUserProfileForNewBooking(response));
      yield put(
        profileFormSubmission({
          ...convertSnakeToCamelCase(userProfileData),
          userProfileId: response.user_profile_id,
        })
      );
      yield put(setBookingProfilePending(false));
    }
    if (currentScreen) {
      yield put(completeOnboardingStep(currentScreen));
    }
    if (redirectToAccountHome) {
      history.push('/account/home');
    }
  } catch (e) {
    yield put(userProfileError(e));
    logger.error(e);
  }
}

export function* updateUserProfile({ formValues, userProfileId, currentScreen }: any) {
  try {
    const userProfileData = buildDapiUserProfileObject(formValues);
    const url = getUserProfileById(userProfileId);
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const response = yield call(apiPatch, url, userProfileData);
    yield put(receiveUserProfileForAccount(response));
    yield put(completeOnboardingStep(currentScreen));
  } catch (e) {
    yield put(userProfileError(e));
    logger.error(e);
  }
}

export function* getSpecialtyPreferences({ userProfileId }: any) {
  try {
    const url = getSpecialtyPreferencesUrl(userProfileId);
    yield put(fetchUserProfileSpecialtyPreferences());
    const response = yield call(apiGet, url);
    yield put(receiveUserProfileSpecialtyPreferences(response));
  } catch (e) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(userProfileSpecialtyPreferencesError(e));
    logger.error(e);
  }
}

export function* createSpecialtyPreferences({
  specialtyIds,
  userProfileId,
  accountId,
  currentScreen,
}: any) {
  try {
    const postData = {
      user_profile_id: userProfileId,
      account_id: accountId,
      specialty_ids: specialtyIds,
    };
    const url = createSpecialtyPreferencesUrl();
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const response = yield call(apiPost, url, postData);
    yield put(receiveUserProfileSpecialtyPreferences(response));
    yield put(completeOnboardingStep(currentScreen));
  } catch (e) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(userProfileSpecialtyPreferencesError(e));
    logger.error(e);
  }
}

export function* getAppointmentPreferences({ userProfileId }: any) {
  try {
    const url = getAppointmentPreferencesUrl(userProfileId);
    yield put(fetchUserProfileAppointmentPreferences());
    const response = yield call(apiGet, url);
    yield put(receiveUserProfileAppointmentPreferences(response));
  } catch (e) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(userProfileAppointmentPreferencesError(e));
    logger.error(e);
  }
}

export function* createAppointmentPreferences({
  appointmentPreferences,
  userProfileId,
  accountId,
  currentScreen,
}: any) {
  try {
    const postData = {
      user_profile_id: userProfileId,
      account_id: accountId,
      appointment_preferences: appointmentPreferences.join(','),
    };
    const url = createAppointmentPreferencesUrl();
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const response = yield call(apiPost, url, postData);
    yield put(receiveUserProfileAppointmentPreferences(response));
    yield put(completeOnboardingStep(currentScreen));
  } catch (e) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(userProfileAppointmentPreferencesError(e));
    logger.error(e);
  }
}
