// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/isomorphic-fetch` if it ex... Remove this comment to see the full error message
import fetch from 'isomorphic-fetch';
import { DAPI_HOST } from '../../config/index';
import { apiPostJson } from './index';
import logger from '../logger/index';

const getImageUploadFilename = () => `${DAPI_HOST}/v3/images`;

const awsImagesPostUploadStatus = (cardId: string) =>
  `${DAPI_HOST}/v1/images/${cardId}/upload-status`;

const awsImagesPostUploadStatusCompressed = (cardId: string) =>
  `${DAPI_HOST}/v1/images/${cardId}/upload-status-compressed`;

const getS3SigningUrl = (name: any, type: any) => {
  const n = encodeURIComponent(name);
  const t = encodeURIComponent(type);
  return `/sign-s3?file-name=${n}&file-type=${t}`;
};

const s3SignedUrl = ({ name, type }: any) =>
  fetch(getS3SigningUrl(name, type), {
    method: 'GET',
    headers: {
      'Content-Type': type,
    },
  }).then((res: any) => res.json());

const putToS3 = (url: any, { image, type, contentDisposition = 'insurance.jpg' }: any) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': type,
      'x-amz-server-side-encryption': 'AES256',
      'Content-Disposition': contentDisposition,
    },
    body: image,
  });

const uploadToS3 = (image: any) => {
  const onSigningSuccess = (response: any) => {
    const uploadUrl = response.signedRequest;
    const headers = {
      'Content-Type': image.type,
      'x-amz-server-side-encryption': 'AES256',
      'Content-Disposition': 'insurance.jpg',
    };

    fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': image.type,
        'x-amz-server-side-encryption': 'AES256',
        'Content-Disposition': 'insurance.jpg',
      },
      body: image.image,
    })
      .then(() => logger.debug('S3 upload success'))
      .catch((e: any) => logger.error(e));
  };

  const onSigningFailure = (response: Error) => {
    logger.error(response);
  };

  fetch(getS3SigningUrl(image.name, image.type), {
    method: 'GET',
    headers: {
      'Content-Type': image.type,
    },
  })
    .then((res: any) => res.json().then((json: any) => onSigningSuccess(json)))
    .catch(onSigningFailure);
};

const uploadInsuranceImage = (
  image: any,
  imageType: any,
  insuranceProfileId: any,
  ocrResponse: any,
  onSuccess: any,
  onFailure: any
) => {
  const handleFilenameSuccess = (response: any) => {
    onSuccess(response);
    uploadToS3({ ...image, name: response.data.aws_path });
  };

  apiPostJson(getImageUploadFilename(), {
    image_type: imageType,
    ocr_response: {},
    insurance_profile_id: insuranceProfileId,
  })
    .then(handleFilenameSuccess)
    .catch(onFailure);
};

export {
  uploadInsuranceImage,
  s3SignedUrl,
  getS3SigningUrl,
  getImageUploadFilename,
  putToS3,
  awsImagesPostUploadStatus,
  awsImagesPostUploadStatusCompressed,
};
