import { TFunction } from 'react-i18next';
/* eslint-disable no-nested-ternary */
import moment from 'moment-timezone';
import Location from '@solvhealth/types/interfaces/Location';
import {
  isOpenNow,
  isOpenSoon,
  getTodayHours,
  isAlwaysOpen,
  reservationsDisabledNow,
  shouldPromoteWalkingInWhenAllReservationsAreTaken,
  getNextOpenDate,
  getAnyDateHours,
} from './location';
import { isEmptyObject, safeGet } from './object';
import { isEmptyArray } from '../../core/util/array';
import { isReservationsRemainingToday } from '../location/slots';
import DateTime, { HOUR_MINUTE_PERIOD } from '../../components/DateTime';
import { EMPTY_STRING, pluralize } from './string';
import { isTelemedLocation } from './location';
import { ORIGIN_BOOKING_WIDGET, NO_AVAILABILITY } from '../../constants/index';
import { SupportedLocale } from '../../core/util/localizedStr';

export const TODAY = 'Today';
export const TOMORROW = 'Tomorrow';

enum OpenCloseStatus {
  OPEN_24_HOURS = 'openCloseStatus/open24Hours',
  OPEN = 'openCloseStatus/open',
  OPEN_WALK_IN_ONLY = 'openCloseStatus/openWalkInOnly',
  SOON = 'openCloseStatus/soon',
  CLOSED = 'openCloseStatus/closed',
}

const nowMap = {
  [OpenCloseStatus.OPEN_24_HOURS]: (s: any) => s.alwaysOpen,
  [OpenCloseStatus.OPEN]: (s: any) => s.open,
  [OpenCloseStatus.OPEN_WALK_IN_ONLY]: (s: any) => s.openNowWalkInOnly,
  [OpenCloseStatus.SOON]: (s: any) => s.soon,
  [OpenCloseStatus.CLOSED]: (s: any) => s.closed,
};

const peopleInLineText = (l: any) => `Currently ${l} ${pluralize(l, 'person')} in line`;

const getOpenOrClosedStatus = (location: any): OpenCloseStatus =>
  isAlwaysOpen(location)
    ? OpenCloseStatus.OPEN_24_HOURS
    : !isTelemedLocation(location) &&
      isOpenNow(location) &&
      (reservationsDisabledNow(location) || !isReservationsRemainingToday(location)) &&
      shouldPromoteWalkingInWhenAllReservationsAreTaken(location)
    ? OpenCloseStatus.OPEN_WALK_IN_ONLY
    : isOpenNow(location)
    ? OpenCloseStatus.OPEN
    : isOpenSoon(location)
    ? OpenCloseStatus.SOON
    : OpenCloseStatus.CLOSED;

const renderOpenClosedStatusText = (status: OpenCloseStatus, t: TFunction<'bookingWidget'>) => {
  switch (status) {
    case OpenCloseStatus.OPEN_24_HOURS:
      return t('openClosedStatusText.open24Hours');
    default:
    case OpenCloseStatus.OPEN:
      return t('openClosedStatusText.openNow');
    case OpenCloseStatus.OPEN_WALK_IN_ONLY:
      return t('openClosedStatusText.openWalkInOnly');
    case OpenCloseStatus.SOON:
      return t('openClosedStatusText.openingSoon');
    case OpenCloseStatus.CLOSED:
      return t('openClosedStatusText.closed');
  }
};

const isBeforeOpen = (location: any) => {
  const tz = location.timeZone;
  const curTime = moment.tz(tz);
  const openTime = moment.tz(getTodayHours(location).fromTime, 'HH:mm', tz);

  return curTime.isBetween(moment(openTime).startOf('day'), openTime);
};

const getNextOpenDayAndTimeText = (
  location: Location,
  locale: SupportedLocale,
  openStatus: OpenCloseStatus,
  t: TFunction<'bookingWidget'>
) => {
  const nextOpenDate = getNextOpenDate(location);
  const nextOpenHours = getAnyDateHours(location, nextOpenDate);
  if (isEmptyObject(nextOpenHours)) {
    return EMPTY_STRING;
  }
  const nextOpenStartTime = moment
    .tz(nextOpenHours.fromTime, 'HH:mm', location.timeZone)
    .format('LT');
  const nextOpenStartDay = DateTime.format(nextOpenDate, location.timeZone, { locale })('ddd');
  if (openStatus === OpenCloseStatus.CLOSED) {
    return t('openClosedStatusText.nextOpenDayAndTime', {
      time_of_day: nextOpenStartTime,
      day_of_week: nextOpenStartDay,
    });
  }

  return EMPTY_STRING;
};

const getFirstDailySlot = (day: any, allSlots: any) => {
  let slots = null;
  if (day === TODAY) {
    slots = allSlots.today;
  }

  if (day === TOMORROW) {
    slots = allSlots.tomorrow;
  }

  if (isEmptyArray(slots)) {
    return null;
  }

  return slots[0];
};

const isWalkInBannerAppropriate = (location: any) =>
  !isTelemedLocation(location) && isOpenNow(location);

const getOptions = (allSlots: any, location: any) => {
  const options = [];
  if (isEmptyObject(allSlots)) return [];

  const todayFirstSlot = getFirstDailySlot(TODAY, allSlots);
  if (isEmptyObject(todayFirstSlot)) {
    if (isWalkInBannerAppropriate(location)) {
      options.push({ label: TODAY });
      options.push({ label: NO_AVAILABILITY });
    }
  } else {
    options.push({
      value: todayFirstSlot,
      label: TODAY,
      busy: todayFirstSlot.isBusy,
    });

    allSlots.today.forEach((slot: any) => {
      if (slot.availability !== 0) {
        const aptTime = DateTime.format(
          slot.appointmentDate,
          location.timeZone
        )(HOUR_MINUTE_PERIOD);
        let label = aptTime;
        if (slot.isBusy) {
          label = `${aptTime} (busy time)`;
        }

        options.push({ value: slot, label, busy: slot.isBusy });
      }
    });
  }

  const tomorrowFirstSlot = getFirstDailySlot(TOMORROW, allSlots);
  if (!isEmptyObject(tomorrowFirstSlot)) {
    options.push({
      value: tomorrowFirstSlot,
      label: TOMORROW,
      busy: tomorrowFirstSlot.isBusy,
    });
    allSlots.tomorrow.forEach((slot: any) => {
      if (slot.availability !== 0) {
        const aptTime = DateTime.format(
          slot.appointmentDate,
          location.timeZone
        )(HOUR_MINUTE_PERIOD);
        let label = aptTime;
        if (slot.isBusy) {
          label = `${aptTime} (busy time)`;
        }

        options.push({ value: slot, label, busy: slot.isBusy });
      }
    });
  }

  return options;
};

const isBookingWidgetBookingFlow = (newBooking: any) =>
  safeGet(newBooking)('booking.origin') === ORIGIN_BOOKING_WIDGET;

export {
  nowMap,
  isBeforeOpen,
  isBookingWidgetBookingFlow,
  getOpenOrClosedStatus,
  getOptions,
  isWalkInBannerAppropriate,
  renderOpenClosedStatusText,
  OpenCloseStatus,
  getNextOpenDayAndTimeText,
};
