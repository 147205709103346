import { useQuery } from 'react-query';
import { DAPI_HOST } from '../../../config';
import { apiGetJson } from '../../../core/dapi';
import { queryStringFromObject, camelToSnake } from '~/core/util/string';

type GeneralConfigName = 'isAddressValidationEnabled';
type GeneralConfigsValues = {
  [configName in GeneralConfigName]?: any;
};
type GeneralConfigsResponse = {
  groupId: string | null;
  locationId: string | null;
  serviceId: string | null;
  values: GeneralConfigsValues;
};

export const useLocationGeneralConfigs = <CFN extends GeneralConfigName>(
  locationId: string,
  configNames: CFN[]
): Pick<GeneralConfigsValues, CFN> => {
  const { data } = useQuery(
    ['paperwork-general-configs', locationId],
    async () => {
      const qs = queryStringFromObject({
        config_names: configNames.map(camelToSnake),
        location_id: locationId,
        inherit_from_group: true,
      });
      if (!locationId || !configNames.length) {
        return {};
      }
      const res = await apiGetJson<GeneralConfigsResponse>(`${DAPI_HOST}/v1/config/?${qs}`, {
        camelCase: true,
      });
      return res.data?.values ?? {};
    },
    {
      staleTime: 5 * 60 * 1000,
    }
  );
  return data ?? {};
};
