export const ALLERGIST_DISPLAY_NAME = 'Allergist';
export const CHIROPRACTOR_DISPLAY_NAME = 'Chiropractor';
export const DENTIST_DISPLAY_NAME = 'Dentist';
export const DERMATOLOGIST_DISPLAY_NAME = 'Dermatologist';
export const OB_GYN_DISPLAY_NAME = 'OB/GYN';
export const WELLNESS_SERVICES_DISPLAY_NAME = 'Wellness Services';
export const OPTOMETRIST_DISPLAY_NAME = 'Optometrist';
export const URGENT_CARE_DISPLAY_NAME = 'Urgent Care';
export const PEDIATRIC_DISPLAY_NAME = 'Pediatric Care';
export const IMAGING_DISPLAY_NAME = 'Imaging';

export const ALLERGIST_VALUE = 'allergist';
export const CHIROPRACTOR_VALUE = 'chiropractor';
export const DENTIST_VALUE = 'dentist';
export const DERMATOLOGIST_VALUE = 'dermatologist';
export const OB_GYN_VALUE = 'ob-gyn';
export const WELLNESS_SERVICES_VALUE = 'wellness_services';
export const OPTOMETRIST_VALUE = 'optometrist';
export const URGENT_CARE_VALUE = 'urgent_care';
export const PROVIDER_GROUP_VALUE = 'provider_group';
export const IMAGING_VALUE = 'imaging';
export const PRIMARY_CARE_PHYSICIAN_VALUE = 'pcp';
export const SPECIALIST_VALUE = 'specialist';
export const EMERGENCY_ROOM_VALUE = 'emergency';

export const DENTIST_SLUG = 'dentists';
export const DERMATOLOGIST_SLUG = 'dermatologists';
export const OPTOMETRIST_SLUG = 'optometrists';
export const CHIROPRACTOR_SLUG = 'chiropractors';
export const ALLERGIST_SLUG = 'allergists';
export const OB_GYN_SLUG = 'ob-gyns';
export const IMAGING_SLUG = 'imaging-centers';
export const URGENT_CARE_SLUG = 'urgent-care';

export const ALLERGIST_CRUMB_NAME = 'Allergists';
export const CHIROPRACTOR_CRUMB_NAME = 'Chiropractors';
export const DENTIST_CRUMB_NAME = 'Dentists';
export const DERMATOLOGIST_CRUMB_NAME = 'Dermatologists';
export const OB_GYN_CRUMB_NAME = 'OB/GYNs';
export const OPTOMETRIST_CRUMB_NAME = 'Optometrists';
export const URGENT_CARE_CRUMB_NAME = 'Urgent Care';
export const PEDIATRIC_CRUMB_NAME = 'Pediatric Care';
export const IMAGING_CRUMB_NAME = 'Imaging Centers';

export const providerTypes = [
  {
    name: ALLERGIST_DISPLAY_NAME,
    value: ALLERGIST_VALUE,
    slug: ALLERGIST_SLUG,
    crumbName: ALLERGIST_CRUMB_NAME,
    icon: '/images/icons/universal_search/allergist.svg',
  },
  {
    name: DENTIST_DISPLAY_NAME,
    value: DENTIST_VALUE,
    slug: DENTIST_SLUG,
    crumbName: DENTIST_CRUMB_NAME,
    icon: '/images/icons/universal_search/dentist.svg',
  },
  {
    name: DERMATOLOGIST_DISPLAY_NAME,
    value: DERMATOLOGIST_VALUE,
    slug: DERMATOLOGIST_SLUG,
    crumbName: DERMATOLOGIST_CRUMB_NAME,
    icon: '/images/icons/universal_search/derm.svg',
  },
  {
    name: OPTOMETRIST_DISPLAY_NAME,
    value: OPTOMETRIST_VALUE,
    slug: OPTOMETRIST_SLUG,
    crumbName: OPTOMETRIST_CRUMB_NAME,
    icon: '/images/icons/universal_search/optometrist.svg',
  },
  {
    name: OB_GYN_DISPLAY_NAME,
    value: OB_GYN_VALUE,
    slug: OB_GYN_SLUG,
    crumbName: OB_GYN_CRUMB_NAME,
    icon: '/images/icons/universal_search/obgyn.svg',
  },
  {
    name: CHIROPRACTOR_DISPLAY_NAME,
    value: CHIROPRACTOR_VALUE,
    slug: CHIROPRACTOR_SLUG,
    crumbName: CHIROPRACTOR_CRUMB_NAME,
    icon: '/images/icons/universal_search/chiropractor.svg',
  },
  {
    name: IMAGING_DISPLAY_NAME,
    value: IMAGING_VALUE,
    slug: IMAGING_SLUG,
    crumbName: IMAGING_CRUMB_NAME,
    icon: '/images/icons/universal_search/imaging.svg',
  },
  {
    name: URGENT_CARE_DISPLAY_NAME,
    value: URGENT_CARE_VALUE,
    slug: URGENT_CARE_SLUG,
    crumbName: URGENT_CARE_CRUMB_NAME,
    icon: '/images/icons/universal_search/uc.svg',
  },
];

export const getProviderTypeName = (value: any) => {
  const match = providerTypes.find((el) => el.value === value);
  if (!match) return null;
  return match.name;
};

export const getProviderTypeSlug = (value: any) => {
  const match = providerTypes.find((el) => el.value === value);
  if (!match) return null;
  return match.slug;
};

export const getProviderTypeCrumbName = (value: any) => {
  const match = providerTypes.find((el) => el.value === value);
  if (!match) return null;
  return match.crumbName;
};
