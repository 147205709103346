import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { PrivacyPolicy, TermsOfService } from '../../LegalLinks';

const Red = styled.span`
  color: red;
`;

type Props = {
  displayNamePrimary: string;
  className?: string;
};

class ConsentLabel extends PureComponent<Props> {
  render() {
    const { className, displayNamePrimary } = this.props;
    return (
      <span className={className}>
        I authorize Solv to use my review, publish it publicly and provide it to{' '}
        {displayNamePrimary}, in accordance with Solv’s <TermsOfService /> and <PrivacyPolicy />.
        <Red>*</Red>
      </span>
    );
  }
}

export default ConsentLabel;
