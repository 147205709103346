import Dialog from '@material-ui/core/Dialog';
import { Stack } from '@solvhealth/jigsaw';
import React, { useState } from 'react';
import styled from 'styled-components';
import SolvNowSearchEntrypoint from '~/components/Membership/Entrypoints/SolvNowSearchEntrypoint';
import { SOLV_PLUS_ENTRYPOINT_SEARCH } from '~/core/analytics/events';
import useShouldShowPremiumVisitEntrypoints from '~/hooks/membership/useShouldShowPremiumVisitEntrypoints';
import dataTestIds from '~/test/test-ids';
import { isCobrandedSrpLocation } from '../../../../components/CoBrandedSRP/util';
import { fontFamily } from '../../../../constants/text';
import Header from '../../../UniversalSearch/Header';
import {
  InsuranceCarrier,
  Location,
  Patient,
  Time,
  Universal,
} from '../../../UniversalSearch/Inputs';
import Cost from '../../../UniversalSearch/Inputs/Cost';
import {
  getCostLabel,
  getInsuranceCarrierLabel,
  getLocationLabel,
  getPatientLabel,
  getTimeLabel,
  getUniversalLabel,
} from '../../../UniversalSearch/util';
import FilterInputWrapper from './FilterInputWrapper';

const Bold = styled.span`
  font-family: ${fontFamily.bold};
`;

const FilterContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
  -webkit-padding-start: 0;
`;

const StyledCostFilterInputWrapper = styled(FilterInputWrapper)`
  img {
    width: 30px;
    height: 30px;
    margin-left: -5px;
    margin-right: 7px;
  }
`;

type Props = {
  open?: boolean;
  onClose?: () => void;
  searchFilters?: any;
  requestUniversalSearch?: () => any;
  onUniversalCancel?: () => any;
  onUniversalConfirm?: (...args: any[]) => void;
  universalSearch?: object;
  showInsuranceInput?: boolean;
  newBooking?: object;
  position?: object;
  HeaderProps?: any;
  searchPreferences: any;
  showCancel?: boolean;
  title?: string;
  showSolvPlusEntrypoint?: boolean;
};

const FilterModal = ({
  open,
  onClose,
  universalSearch,
  showInsuranceInput,
  onUniversalCancel,
  onUniversalConfirm,
  requestUniversalSearch,
  position,
  HeaderProps,
  newBooking,
  searchPreferences,
  showSolvPlusEntrypoint,
  showCancel = false,
  title = 'Filters',
}: Props) => {
  const [searchEnabled, setSearchEnabled] = useState(false);

  const handleDone = () => {
    requestUniversalSearch?.();
    onClose?.();
  };

  const wrapperProps = {
    confirmCopy: <Bold>Apply</Bold>,
    cancelCopy: 'Cancel',
    showBackArrow: false,
    confirmDisabled: !searchEnabled,
  };

  const canShowSolvPlusEntryPoint = useShouldShowPremiumVisitEntrypoints({
    showToExistingMembers: true,
    overrideExcludedLocations: true,
    entrypointId: SOLV_PLUS_ENTRYPOINT_SEARCH,
  });

  const showPremiumVisitCta = showSolvPlusEntrypoint && canShowSolvPlusEntryPoint;

  return (
    <Dialog fullScreen onClose={onClose} open={!!open}>
      <Stack bg="gray-100" height="100%" justifyContent="space-between">
        <Stack>
          <Header
            cancelCopy=""
            onCancel={showCancel ? onClose : undefined}
            onConfirm={handleDone}
            showBackArrow={false}
            title={title}
            {...HeaderProps}
          />

          <FilterContainer>
            <FilterInputWrapper
              datatestid={dataTestIds.srp.searchFilter.mobile.reasonLabel}
              HeaderProps={wrapperProps}
              icon="/images/icons/search-icons/Icon-Search.svg"
              inputLabel={getUniversalLabel(
                universalSearch,
                searchPreferences.isInMarketPlaceSearch
              )}
              onCancel={onUniversalCancel}
              onConfirm={onUniversalConfirm}
              title="Find Care"
            >
              <Universal onlySelectDoc setSearchEnabled={setSearchEnabled} />
            </FilterInputWrapper>

            <FilterInputWrapper
              datatestid={dataTestIds.srp.searchFilter.mobile.locationLabel}
              icon="/images/icons/search-icons/Icon-Search-Location.svg"
              inputLabel={getLocationLabel(position, searchPreferences)}
              position={position}
              title="Location"
            >
              <Location />
            </FilterInputWrapper>

            <FilterInputWrapper
              icon="/images/icons/search-icons/Icon-Search-Time.svg"
              inputLabel={getTimeLabel(newBooking)}
              title="Visit Time"
            >
              <Time />
            </FilterInputWrapper>

            <FilterInputWrapper
              icon="/images/icons/search-icons/Icon-Search-Patient.svg"
              inputLabel={getPatientLabel(newBooking)}
              title="Patient"
            >
              <Patient />
            </FilterInputWrapper>
            {isCobrandedSrpLocation() && (
              <StyledCostFilterInputWrapper
                datatestid={dataTestIds.srp.searchFilter.mobile.michiganCostLabel}
                icon="/images/icons/search-icons/Icon-Search-Cost.svg"
                inputLabel={getCostLabel(searchPreferences)}
                title="Cost"
              >
                <Cost updateSuggestions={() => null} />
              </StyledCostFilterInputWrapper>
            )}
            {showInsuranceInput && (
              <FilterInputWrapper
                icon="/images/icons/insurance_shield.svg"
                inputLabel={getInsuranceCarrierLabel(searchPreferences)}
                title="Insurance"
              >
                <InsuranceCarrier />
              </FilterInputWrapper>
            )}
          </FilterContainer>
        </Stack>
        {showPremiumVisitCta && <SolvNowSearchEntrypoint closeParentModal={onClose} />}
      </Stack>
    </Dialog>
  );
};

export default FilterModal;
