import { takeEvery, call, put } from 'redux-saga/effects';
import { getSpecialtiesUrl } from '../core/dapi/specialties';
import logger from '../core/logger/index';
import { specialtiesFetching, specialtiesReceived, specialtiesError } from '../ducks/specialties';
import { apiGet } from '../core/dapi';

export const FETCH_SPECIALTIES = 'specialties/FETCH_SPECIALTIES';

function* fetchSpecialties() {
  try {
    const url = getSpecialtiesUrl();
    yield put(specialtiesFetching());
    const response = yield call(apiGet, url);
    yield put(specialtiesReceived(response));
  } catch (e) {
    yield put(specialtiesError(e));
    logger.error(e);
  }
}

function* rootSaga() {
  yield takeEvery(FETCH_SPECIALTIES, fetchSpecialties);
}

export { rootSaga as default };
