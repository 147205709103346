import { DAPI_HOST } from '../../config/index';
import { queryStringFromObject } from '../util/string';
import { getAuthToken } from '../auth';

const getImageUploadUrl = () => `${DAPI_HOST}/v2/images`;

const getAccountImageUploadUrl = () => `${DAPI_HOST}/v1/images/account`;

const getImageDownloadUrl = (id: any) => `${DAPI_HOST}/v1/images/${id}`;
const getImageUrlDownloadUrl = (id: any) => `${DAPI_HOST}/v1/images_url/${id}`;

const getProfilePhotoUrl = (userProfile: any) =>
  `${DAPI_HOST}/v2/images/profile/${userProfile.user_profile_id}`;

const bytesToMegaBytes = (size: any) => size / 1024 / 1024; // MB

const buildImageFormData = (fileData: any, imageType: any, ocrResponse: any) => {
  const ocr = ocrResponse;
  delete ocr.ReformattedImage;

  const data = new FormData();
  data.append('image', fileData);
  data.append('image_type', imageType);
  data.append('ocr_response', JSON.stringify(ocr));
  return data;
};

export {
  buildImageFormData,
  getAccountImageUploadUrl,
  getImageUploadUrl,
  getImageUrlDownloadUrl,
  getImageDownloadUrl,
  getProfilePhotoUrl,
};
