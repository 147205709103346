const getRandomInt = (max: any) => Math.floor(Math.random() * Math.floor(max));

export function getRandomIntMinMax(min: number, max: number) {
  // eslint-disable-next-line no-param-reassign
  min = Math.ceil(min);
  // eslint-disable-next-line no-param-reassign
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const isNumeric = (n: any) => {
  const parsed = parseFloat(n);
  return !Number.isNaN(parsed) && Number.isFinite(parsed);
};

const roundRating = (rating: number | string) => {
  const localRating = typeof rating === 'string' ? parseFloat(rating) : rating;
  return Math.round(localRating * 10) / 10;
};

const stringifyRoundRating = (rating: number | string) => {
  return roundRating(rating).toFixed(1);
};

/**
 * Transform numbers to have commas as thousands separator
 *
 * @example
 * numberWithCommas(4000);
 * >>> 4,000
 *
 * @returns {string}
 */
const numberWithCommas = (n?: number): string => {
  if (!n) return '';
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export { isNumeric, getRandomInt, roundRating, stringifyRoundRating, numberWithCommas };
