import React from 'react';
import { BaseClickable } from '@solvhealth/jigsaw';
import history from '../../core/history';

const goHome = () => history.push('/');

const getImgName = (showGreyLogo?: boolean) => (showGreyLogo ? 'solv-grey' : 'solv_dark');

const getImagePath = (showGreyLogo?: boolean) => `/images/logo/${getImgName(showGreyLogo)}.svg`;

type OwnLogoProps = {
  className?: string;
  height?: number;
  handleLogoOnClick?: React.MouseEventHandler;
  showGreyLogo?: boolean;
};

const Logo = ({
  className,
  height = 16,
  showGreyLogo,
  handleLogoOnClick = goHome,
}: OwnLogoProps) => (
  <BaseClickable className={className} display="flex" onClick={handleLogoOnClick}>
    <img alt="Solv Logo" height={height} src={getImagePath(showGreyLogo)} />
  </BaseClickable>
);

export default Logo;
