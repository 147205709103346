import React, { useCallback, useEffect, useRef, useState } from 'react';
import type {
  CheckboxWithInputOption,
  OptionsGroup,
} from '@solvhealth/types/interfaces/PaperworkField';
import { Stack, Box, Text } from '@solvhealth/jigsaw';
import CheckboxWithInput from './CheckboxWithInput';
import { EMPTY_ARRAY } from '~/core/util/array';

type CheckboxWithInputGroupProps = {
  onChange: (value: CheckedOption[]) => void;
  error?: boolean;
  optionsGroups: OptionsGroup[];
  value?: CheckedOption[];
};

interface CheckedOption {
  item: string;
  info: string;
}

const CheckboxWithInputGroup = (props: CheckboxWithInputGroupProps): React.ReactElement => {
  const { onChange, error, optionsGroups, value = EMPTY_ARRAY } = props;
  const [disableCheckboxes, setDisableCheckboxes] = useState<boolean>(false);
  const [checkedOptions, setCheckedOptions] = useState<CheckedOption[]>(value);
  const checkedOptionsRef = useRef(checkedOptions);
  checkedOptionsRef.current = checkedOptions;

  const handleCheckboxAndInputChange = useCallback(
    (checked: boolean, item: string, info: string, isExclusive: boolean) => {
      let updatedCheckedOptions = [...checkedOptionsRef.current];

      const index = checkedOptionsRef.current.findIndex(
        (option: CheckedOption) => option.item === item
      );

      if (checked) {
        if (index < 0) {
          const newField = { item, info };
          updatedCheckedOptions = isExclusive ? [newField] : [...updatedCheckedOptions, newField];
        } else {
          updatedCheckedOptions[index].info = info;
        }
      } else {
        updatedCheckedOptions.splice(index, 1);
      }

      onChange(updatedCheckedOptions);
      setCheckedOptions(updatedCheckedOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack space={3}>
      {optionsGroups.map((optionsGroup: OptionsGroup, index: number) => {
        const optionsGroupHeader: string = optionsGroup[0];
        const options: CheckboxWithInputOption[] = optionsGroup[1];

        return (
          <Box key={index}>
            {optionsGroupHeader && (
              <Text mb={1.5} variant="subtitle">
                {optionsGroupHeader}
              </Text>
            )}
            {options.map((option: CheckboxWithInputOption, index: number) => {
              const checkboxLabel = typeof option === 'string' ? option : option.label;
              const inputLabel = typeof option === 'string' ? undefined : option.nested_field_label;
              const isExclusive = typeof option === 'string' ? false : !!option.is_exclusive;
              const checkedOption = checkedOptions.find(
                (option: CheckedOption) => option.item === checkboxLabel
              );
              return (
                <CheckboxWithInput
                  checkboxLabel={checkboxLabel}
                  checked={!!checkedOption}
                  disabled={disableCheckboxes && !isExclusive}
                  error={error}
                  handleCheckboxAndInputChange={handleCheckboxAndInputChange}
                  inputLabel={inputLabel}
                  inputValue={checkedOption ? checkedOption.info : ''}
                  isExclusive={isExclusive}
                  key={index}
                  setDisableCheckboxes={setDisableCheckboxes}
                />
              );
            })}
          </Box>
        );
      })}
    </Stack>
  );
};

export default CheckboxWithInputGroup;
