import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { formShape } from 'rc-form';
import FormLabelControl from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import Location from '@solvhealth/types/interfaces/Location';
import Provider from '@solvhealth/types/interfaces/Provider/index';
import { Button, Checkbox, Stack, Text, TextArea } from '@solvhealth/jigsaw';
import TextField from '../../SolvPatternLibrary/TextField';
import { colors } from '~/constants/colors';
import { fontFamily, fontSize } from '~/constants/text';
import { biggerThanOrEqualTo } from '~/core/util/styledComponents';
import ConsentLabel from './ConsentLabel';

import { PRIMARY_RATING } from './PrimaryRatingSelector';
import { ReviewContext } from '~/routes/addReview/context';
import { ADD_REVIEW_UNGATED } from '~/constants';
import { isInternalOrExternalTelemedBooking } from '~/core/util/booking';
import {
  CONSENT_FIELD,
  CONSENT_NAME,
  CONSENT_VISIT_TYPE,
  RATING_DESCRIPTION_FIELD,
} from '../constants';
import { SolvButton } from '../../SolvDesignSystem';
import withCreateForm from '../../util/withCreateForm';
import { getProviderName } from '~/components/ProviderGroup/Provider/util';
import { TermsOfService, PrivacyPolicy } from '~/components/LegalLinks';

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.dorianGrey};
  display: block;
  margin: 0 20px;

  ${biggerThanOrEqualTo.sm`
    width: 100%;
    margin: 0 auto;
  `}
`;

const Root = styled.form`
  padding: 26px 20px;
`;

const Title = styled.h1`
  margin: 0 auto 20px;
  display: block;
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.large};
  color: ${colors.blueMonday};
`;

const DescriptionField = styled(TextField)`
  max-width: 500px;
  margin: 0 auto;
  background: ${colors.theWhiteAlbum};
  margin-bottom: 32px;

  & .details-root {
    flex-direction: column;
    height: 170px;
  }

  & .input-root {
    text-indent: 0;
    padding: 12px 16px;
    overflow-y: scroll !important;
  }

  & .MuiFormHelperText-root {
    background: ${colors.whiteGrey};
  }
`;

const descriptionPrompts: string[] = [
  'How was the wait time?',
  'Was your check-in process convenient?',
  'How did you book your appointment?',
  "How was the provider's bedside manner?",
  'Was the cost what you expected?',
  'Would you recommend this provider or clinic? If so, why?',
];

const ConsentContainer = styled(FormLabelControl)`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 16px;
`;

const StyledConsentLabel = styled(ConsentLabel)`
  flex-grow: 1;
  cursor: pointer;
  font-size: ${fontSize.mediumSmall};
  font-family: ${fontFamily.book};
  color: ${colors.blueMonday};
  display: inline-block;
  white-space: normal;
  text-align: left;
`;

const StyledLabel = styled.span`
  flex-grow: 1;
  cursor: pointer;
  font-size: ${fontSize.mediumSmall};
  font-family: ${fontFamily.book};
  color: ${colors.blueMonday};
  display: inline-block;
  white-space: normal;
  text-align: left;
`;

type Props = {
  form: formShape;
  submit: Function;
  leaveAReview: any;
  location?: Location;
  provider?: Provider;
  providerGroupName?: string;
};

const RatingDescriptionForm = ({
  form,
  submit,
  leaveAReview,
  location,
  provider,
  providerGroupName,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>('');
  const reviewContext = useContext(ReviewContext);
  const flowType = reviewContext?.flowType || location?.reviewFlowType;
  const displayName =
    location?.displayNamePrimary || providerGroupName || getProviderName(provider);

  const updatePrompt = (userInputLength: number) => {
    if (userInputLength < 20) {
      setPrompt('');
    } else {
      setPrompt(descriptionPrompts[Math.floor(userInputLength / 20) % descriptionPrompts.length]);
    }
  };

  const descriptionProps = useMemo(
    () => ({
      ...form.getFieldProps(RATING_DESCRIPTION_FIELD),
      id: RATING_DESCRIPTION_FIELD,
      placeholder:
        'This is my favorite place to get care! The provider and staff are friendly, ' +
        'exam room is clean, and the wait time is always super short when I use Solv ' +
        'to book an online booking.',
      onChange: ({ currentTarget: { id, value } }: React.FormEvent<HTMLInputElement>) => {
        form.setFieldsValue({ [id]: value });
        updatePrompt(value.length);
      },
    }),
    [form]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isSubmitting) return;

      setSubmitting(true);
      submit(form.getFieldsValue());
    },
    [form, isSubmitting, submit]
  );

  return (
    <>
      <Divider />

      <Stack as="form" onSubmit={handleSubmit} space={3} textAlign="left">
        <Text textAlign="center" variant="h3">
          Describe your experience
        </Text>

        <TextArea helperText={prompt} rows={8} {...descriptionProps} />

        <Stack>
          <Checkbox
            label={
              <>
                I authorize Solv to use my review, publish it publicly and provide it to{' '}
                {displayName}
                , in accordance with Solv’s <TermsOfService /> and <PrivacyPolicy />.
              </>
            }
            {...form.getFieldProps(CONSENT_FIELD)}
            data-testid="leave-a-review-consent"
            id={CONSENT_FIELD}
            onChange={(_: React.ChangeEvent, checked: Boolean) =>
              form.setFieldsValue({ [CONSENT_FIELD]: checked })
            }
            size="small"
          />
          {flowType === ADD_REVIEW_UNGATED && (
            <>
              <Checkbox
                {...form.getFieldProps(CONSENT_NAME)}
                color="primary"
                id={CONSENT_NAME}
                label="Publish with my first name, last initial"
                onChange={(_: React.ChangeEvent, checked: Boolean) =>
                  form.setFieldsValue({ [CONSENT_NAME]: checked })
                }
                size="small"
              />
              {reviewContext?.booking &&
                isInternalOrExternalTelemedBooking(reviewContext?.booking) && (
                  <Checkbox
                    {...form.getFieldProps(CONSENT_VISIT_TYPE)}
                    color="primary"
                    id={CONSENT_VISIT_TYPE}
                    label="Publish with visit type as “video visit”"
                    onChange={(_: React.ChangeEvent, checked: Boolean) =>
                      form.setFieldsValue({ [CONSENT_VISIT_TYPE]: checked })
                    }
                    size="small"
                  />
                )}
            </>
          )}
        </Stack>

        <Button
          data-testid="leave-a-review-submit"
          disabled={!leaveAReview[PRIMARY_RATING] || !form.getFieldProps(CONSENT_FIELD).value}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Done
        </Button>
      </Stack>
    </>
  );
};

export default withCreateForm(RatingDescriptionForm);
