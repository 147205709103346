import { SET_PAST_BOOKING, CLEAR_PAST_BOOKINGS } from '../constants/index';

const setPastBooking = (value: any) => ({
  type: SET_PAST_BOOKING,
  payload: { value },
});

const clearPastBookings = () => ({
  type: CLEAR_PAST_BOOKINGS,
});

export { setPastBooking, clearPastBookings };
