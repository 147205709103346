import intersection from 'lodash/intersection';
import { COVID_SERVICE_IDS } from '../../../../constants/services';
import { safeGet } from '../../../../core/util/object';

export const isCovidTestServiceSearch = (searchPreferences: any) => {
  const requestedServices = safeGet(searchPreferences, [])('requestedServices');
  return intersection(requestedServices, COVID_SERVICE_IDS).length > 0;
};

export const isCovidTestRelatedSearch = (searchPreferences: any) =>
  safeGet(searchPreferences, false)('isCovidTestRelatedSearch');
