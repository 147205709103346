import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Input from './Base';
import { CONSUMER_APP, PATIENT_TYPE_OPTIONS } from '../../../constants/index';
import { symptomsSubmit } from '../../../actions/symptoms';
import { analyticsTrackEvent } from '../../../core/analytics';
import {
  ACTION_BLUR,
  ACTION_CHANGE_VALUE,
  ACTION_CLICK,
  PATIENT_TYPE_SET_BY_USER,
  UNIVERSAL_PATIENT_TYPE_INPUT,
} from '../../../core/analytics/events';
import { isMobile } from '../../../core/util/device';
import { profileIsChild } from '../../../core/util/account';

const PatientInput = styled(Input)`
  -webkit-appearance: none;
  text-transform: capitalize;
  padding: 1px;
  text-align: left;
`;

const tagString = (toTag: any) => `<em>${toTag}</em>`;

type PatientProps = {
  className?: string;
  updateSuggestions?: (...args: any[]) => any;
  refSetter?: (...args: any[]) => any;
  setPatientType?: (...args: any[]) => any;
  clearOnBlur?: boolean;
  onSelect?: (...args: any[]) => any;
  patientType?: {
    value?: string;
    label?: string;
  };
};

const Patient = ({
  className,
  clearOnBlur,
  onSelect,
  patientType,
  refSetter,
  setPatientType,
  updateSuggestions,
}: PatientProps) => {
  const updateAndClear = (patientType: any) => {
    const trackingProperties = {
      patientType: patientType.value,
      sourceOfInput: CONSUMER_APP,
      isMobile: isMobile(),
    };
    analyticsTrackEvent(PATIENT_TYPE_SET_BY_USER, trackingProperties);
    analyticsTrackEvent(UNIVERSAL_PATIENT_TYPE_INPUT, {
      action: ACTION_CHANGE_VALUE,
      ...trackingProperties,
    });

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setPatientType(patientType);

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    updateSuggestions([]);
    onSelect && onSelect(patientType);
  };

  const getSuggestions = () => [
    {
      primary:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        patientType.value === PATIENT_TYPE_OPTIONS[0].value
          ? tagString(PATIENT_TYPE_OPTIONS[0].label)
          : PATIENT_TYPE_OPTIONS[0].label,
      key: PATIENT_TYPE_OPTIONS[0].value,
      onClick: () => updateAndClear(PATIENT_TYPE_OPTIONS[0]),
    },
    {
      primary:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        patientType.value === PATIENT_TYPE_OPTIONS[1].value
          ? tagString(PATIENT_TYPE_OPTIONS[1].label)
          : PATIENT_TYPE_OPTIONS[1].label,
      key: PATIENT_TYPE_OPTIONS[1].value,
      onClick: () => updateAndClear(PATIENT_TYPE_OPTIONS[1]),
    },
  ];

  const handleClick = () => {
    analyticsTrackEvent(UNIVERSAL_PATIENT_TYPE_INPUT, {
      action: ACTION_CLICK,
      isMobile: isMobile(),
    });

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    updateSuggestions(getSuggestions());
  };

  useEffect(() => {
    !clearOnBlur && handleClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    patientType && setPatientType(patientType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = () => {
    analyticsTrackEvent(UNIVERSAL_PATIENT_TYPE_INPUT, {
      action: ACTION_BLUR,
      isMobile: isMobile(),
    });
  };

  return (
    <PatientInput
      $url="/images/icons/search-icons/Icon-Search-Patient.svg"
      className={className}
      onBlur={handleBlur}
      onClick={handleClick}
      onFocus={handleClick}
      ref={refSetter}
      type="button"
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      value={patientType.label}
    />
  );
};

const mapStateToProps = (state: any) => {
  let defaultPatientType: typeof PATIENT_TYPE_OPTIONS[number];
  [defaultPatientType] = PATIENT_TYPE_OPTIONS;
  if (state.newBooking.profile && profileIsChild(state.newBooking.profile)) {
    [, defaultPatientType] = PATIENT_TYPE_OPTIONS; /* PATIENT_TYPE_KIDS */
  } else if (state?.newBooking?.booking?.patientType?.label) {
    defaultPatientType = state.newBooking.booking.patientType;
  }
  return {
    patientType: defaultPatientType,
  };
};

export default connect(mapStateToProps, (dispatch: any) => ({
  setPatientType: (patientType: any) => dispatch(symptomsSubmit({ patientType })),
}))(Patient);
