/* eslint-disable no-empty */
import { safeGet } from '../util/object';
import { isEmptyString } from '../util/string';
import {
  HOMEPAGE_ANCHOR_DOWNLOAD_SOLV_APP,
  HOMEPAGE_ANCHOR_SOLV_VIDEO,
} from '../../constants/index';

export function addEventListener(node: any, event: any, listener: any) {
  if (node) {
    if (node.addEventListener) {
      node.addEventListener(event, listener, false);
    } else {
      node.attachEvent(`on${event}`, listener);
    }
  }
}

export function removeEventListener(node: any, event: any, listener: any) {
  if (node) {
    if (node.removeEventListener) {
      node.removeEventListener(event, listener, false);
    } else {
      node.detachEvent(`on${event}`, listener);
    }
  }
}

export function updateTag(
  tagName: any,
  keyName: any,
  keyValue: any,
  attrName: any,
  attrValue: any
) {
  const node = document.head.querySelector(`${tagName}[${keyName}="${keyValue}"]`);
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    node.parentNode.removeChild(node);
  }

  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name: any, content: any) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property: any, content: any) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel: any, href: any) {
  updateTag('link', 'rel', rel, 'href', href);
}

export const triggerEvent = (target: any, type: any) => {
  const doc = window.document;
  if (doc.createEvent) {
    const event = doc.createEvent('HTMLEvents');
    event.initEvent(type, true, true);
    target.dispatchEvent(event);
  } else {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'createEventObject' does not exist on typ... Remove this comment to see the full error message
    const event = doc.createEventObject();
    target.fireEvent(`on${type}`, event);
  }
};

export const isPassiveEventSupported = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  let passiveIfSupported = false;

  try {
    const options = {
      get passive() {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ passive: boolean; }' is not assignable to ... Remove this comment to see the full error message
        passiveIfSupported = { passive: true };
        return null;
      },
    };
    // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    window.addEventListener('test', null, options);
    // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    window.removeEventListener('test', null, options);
  } catch (e) {}

  return passiveIfSupported;
};

export const scrollPageToId = (id: any) => {
  const hash = safeGet(window, '')('location.hash').replace('#', '');
  if (!isEmptyString(hash)) {
    const target = document.getElementById(hash);
    if (target && hash === id) {
      // eslint-disable-next-line no-global-assign
      const scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      window.scrollTo(0, scrollY);
    }
  }
};
