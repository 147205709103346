import { takeEvery, call, put } from 'redux-saga/effects';
import {
  fetchingGroupLocations,
  groupLocationsError,
  receiveGroupLocations,
} from '~/actions/groupLocations';
import { apiGetAllPages } from './core';
import { getGroupLocationsUrl } from '~/core/dapi/location';

const GET_GROUP_LOCATIONS = 'saga/GET_GROUP_LOCATIONS';

const MULTI_LOCATION_WIDGET_LOCATION_FIELDS = [
  // base location data
  'id',
  'display_name_primary',
  'display_name_secondary',
  'address',
  'subpremise',
  'city',
  'state',
  'zip_code',
  'is_telemed',
  'image_links',
  'time_zone',
  'groups',
  'is_by_appointment_only',
  'is_next_day_appointments_disabled',
  'is_beyond_next_day_appointments_enabled',
  'disable_reservations_until',
  'is_viewable_on_group_multi_location_widget',
  'wait_list_count',
  // fields required to fetch slots
  'hours',
  'appointment_dates',
  'slots',
];

/**
 * Get group locations for the provided groupId
 *
 * @param groupId id of group
 */
function* getGroupLocations({ groupId }: any) {
  yield put(fetchingGroupLocations());

  const response = yield call(apiGetAllPages, {
    url: getGroupLocationsUrl(groupId, {
      version: 'v4',
      fields: MULTI_LOCATION_WIDGET_LOCATION_FIELDS,
    }),
  });

  if (response.errors) {
    yield put(groupLocationsError(response.errors));
  } else {
    yield put(receiveGroupLocations(response));
  }
}

/**
 * Root saga for getting group locations
 */
function* rootSaga() {
  yield takeEvery(GET_GROUP_LOCATIONS, getGroupLocations);
}

export { GET_GROUP_LOCATIONS, rootSaga as default };
