import { EMPTY_ARRAY } from '../../core/util/array';

const CARDS_RECEIVED = 'payments/CARDS_RECEIVED';
const CARDS_ERROR = 'payments/CARDS_ERROR';
const CARDS_SUBMITTING = 'payments/CARDS_SUBMITTING';
const CARDS_CREATE_SUCCESS = 'payments/CARD_CREATE_SUCCESS';

export type SavedCard = {
  brand: string;
  created_date: string;
  expiration_month: number;
  expiration_year: number;
  is_default: boolean;
  last4: number;
  stripe_card_id: string;
  stripe_customer_id: string;
  updated_date: string;
  zip_code: string;
};

export type PaymentCustomerState = {
  cards: SavedCard[];
  submitting: boolean;
};

export const cardsReceived = (value: any) => ({
  type: CARDS_RECEIVED,
  payload: { value },
});

export const createCardSuccess = (value: any) => ({
  type: CARDS_CREATE_SUCCESS,
  payload: { value },
});

export const cardsError = (value: any) => ({
  type: CARDS_ERROR,
  payload: { value },
});

export const cardsSubmitting = (value: any) => ({
  type: CARDS_SUBMITTING,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case CARDS_RECEIVED:
      return {
        ...state,
        cards: action.payload.value.results,
        submitting: false,
      };
    case CARDS_CREATE_SUCCESS:
      return {
        ...state,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'cards' does not exist on type '{}'.
        cards: [...(state.cards || EMPTY_ARRAY), action.payload.value],
        error: null,
        submitting: false,
      };
    case CARDS_ERROR:
      return {
        ...state,
        cards: null,
        error: action.payload.value,
        submitting: false,
      };
    case CARDS_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    default:
      return state;
  }
};
