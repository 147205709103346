import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Button, WhiteLabelThemeProvider } from '@solvhealth/jigsaw';
import { analyticsTrackEvent } from '../../../core/analytics';
import { colors } from '../../../constants/colors';
import { biggerThan, smallerThanOrEqualTo } from '../../../core/util/styledComponents';
import { WAIT_LIST_DELAYS_MODAL_CLOSE } from '../../../core/analytics/events';
import { usePalette } from '~/hooks/usePalette';

export const DELAYS_MODAL = 'delays modal';

const SmoothDialog = styled(Dialog)`
  -webkit-font-smoothing: antialiased;

  & .dialog-root {
    margin: 25px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${biggerThan.sm`
    padding: 30px 50px;
  `}
  ${smallerThanOrEqualTo.sm`
    padding: 30px 25px;
  `}
`;

const ButtonContainer = styled.div``;

const Title = styled.h3`
  margin: 10px 0;
`;

const Disclaimer = styled.p`
  color: ${colors.mrGray};
`;

const CTA = styled(Button)`
  margin-top: 20px;
`;

const Illustration = styled.img`
  width: 100%;
`;

type Props = {
  onClose: (...args: any[]) => any;
  open: boolean;
};

const DelaysModal = ({ open, onClose }: Props) => {
  const { paletteName } = usePalette();

  const handleClose = () => {
    analyticsTrackEvent(WAIT_LIST_DELAYS_MODAL_CLOSE);
    onClose();
  };

  return (
    <SmoothDialog
      onClose={handleClose}
      open={open}
      PaperProps={{ classes: { root: 'dialog-root' } }}
    >
      <Root>
        <Illustration src="/images/waitList/delay_modal.png" />

        <Title>Expected delays and longer wait times</Title>

        <Disclaimer>
          Due to respiratory season, we’ve been experiencing a higher than normal volume of
          patients. We’re working to see patients safely and quickly.
          <br />
          <br />
          This sudden increase in patients may result in longer wait times. We’ll do our best to
          keep you updated on delays and appreciate your patience.
        </Disclaimer>

        <ButtonContainer>
          <WhiteLabelThemeProvider theme={paletteName}>
            <CTA onClick={handleClose}>I understand</CTA>
          </WhiteLabelThemeProvider>
        </ButtonContainer>
      </Root>
    </SmoothDialog>
  );
};

export default withMobileDialog<Props>({ breakpoint: 'xs' })(DelaysModal);
