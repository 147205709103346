import { DAPI_HOST } from '../../config/index';

const getCreateInvoiceUrl = () => `${DAPI_HOST}/v1/invoices`;

const getInvoiceLocationUrl = (invoiceId: any) => `${DAPI_HOST}/v1/invoices/${invoiceId}/location`;

const getInvoicesListUrl = (invoicesOnly: any) =>
  `${DAPI_HOST}/v1/invoices?invoices_only=${JSON.stringify(!!invoicesOnly)}`;

const getInvoicesUrl = (invoiceId: any) => `${DAPI_HOST}/v1/invoices/${invoiceId}`;

const getStubBookingUrl = () => `${DAPI_HOST}/v1/invoice-stub-bookings`;

const getPaymentCardUrl = (accountId: any) =>
  `${DAPI_HOST}/v1/payment-customers/cards/${accountId}`;

const getPaymentCustomersUrl = () => `${DAPI_HOST}/v1/payment-customers`;

export {
  getCreateInvoiceUrl,
  getInvoiceLocationUrl,
  getInvoicesListUrl,
  getInvoicesUrl,
  getPaymentCardUrl,
  getStubBookingUrl,
  getPaymentCustomersUrl,
};
