import {
  SET_USER_LOCATION,
  BROWSER_LOCATION_ALLOWED,
  SHOW_ACTIVE_FILTER,
  BROWSER_LOCATION_DENIED,
  SET_ACTIVE_FILTER,
  SHOW_SEARCH_FILTERS,
  SET_SEARCH_IN_MARKET_PLACE,
  SET_SEARCH_COVID_COST_TYPE,
  SET_SEARCH_PREFERENCES_PAGINATION_PARTNER_RADIUS,
} from '../constants/index';

function setUserLocation(value: any) {
  return {
    type: SET_USER_LOCATION,
    payload: { value },
  };
}

function setBrowserLocationAllowed() {
  return {
    type: BROWSER_LOCATION_ALLOWED,
  };
}

function setBrowserLocationDenied() {
  return {
    type: BROWSER_LOCATION_DENIED,
  };
}

function showSearchFilters(value: any) {
  return {
    payload: { value },
    type: SHOW_SEARCH_FILTERS,
  };
}

const showActiveFilter = (value: any) => ({
  payload: { value },
  type: SHOW_ACTIVE_FILTER,
});

const setActiveFilter = (value: any) => ({
  payload: { value },
  type: SET_ACTIVE_FILTER,
});

const setIsInMarketPlaceSearch = (value: any) => ({
  payload: { value },
  type: SET_SEARCH_IN_MARKET_PLACE,
});

const setCovidCostType = (value: object) => ({
  payload: { value },
  type: SET_SEARCH_COVID_COST_TYPE,
});

const setSearchPreferencesRadius = (value: object) => ({
  payload: { value },
  type: SET_SEARCH_PREFERENCES_PAGINATION_PARTNER_RADIUS,
});

export {
  showActiveFilter,
  setUserLocation,
  setActiveFilter,
  showSearchFilters,
  setIsInMarketPlaceSearch,
  setBrowserLocationAllowed,
  setBrowserLocationDenied,
  setCovidCostType,
  setSearchPreferencesRadius,
};
