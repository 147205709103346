import { DAPI_HOST } from '../../config/index';

const getLocationPaperworkFieldsUrl = (locationId: any, fieldsType: any) => {
  let url = `${DAPI_HOST}/v1/location-paperwork-fields?location_id=${locationId}`;

  if (fieldsType) {
    url += `&type_=${fieldsType}`;
  }

  return url;
};

const getPaperworkStatusLogUrl = () => `${DAPI_HOST}/v1/paperwork-status-log`;

export { getLocationPaperworkFieldsUrl, getPaperworkStatusLogUrl };
