import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { BASE_URL } from '../../config/index';

const StyledImg = styled.img`
  cursor: pointer;
  display: flex;
  ${(props) => css`
    height: ${props.height};
  `};
`;

const getImgName = (props: any) => (props.showGreyLogo ? 'solv-grey' : 'solv_dark');

const getImagePath = (props: any) => `/images/logo/${getImgName(props)}.svg`;

type OwnLogoProps = {
  className?: string;
  height?: number;
  handleLogoOnClick?: (...args: any[]) => any;
  style?: any;
  showGreyLogo?: boolean;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'LogoProps' circularly references itsel... Remove this comment to see the full error message
type LogoProps = OwnLogoProps & typeof Logo.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'Logo' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Logo = (props: LogoProps) => {
  const { className, handleLogoOnClick, height } = props;

  return (
    <>
      {handleLogoOnClick ? (
        <div className={className} onClick={handleLogoOnClick}>
          <StyledImg alt="Solv" height={height} src={getImagePath(props)} />
        </div>
      ) : (
        <a className={className} href={BASE_URL}>
          <StyledImg alt="Solv" height={height} src={getImagePath(props)} />
        </a>
      )}
    </>
  );
};

Logo.defaultProps = {
  height: 16,
  style: {},
};

export default Logo;
