import React from 'react';
import { TFunction, Trans } from 'react-i18next';
import { safeGet } from '../../../../core/util/object';
import { isEmpty } from '../../../../core/util/empty';
import { buildListStringWithDelimiterFromArray, isEmptyArray } from '../../../../core/util/array';
import { findIndexCovidTestType } from '~/core/util/location';
import { ServiceBookText } from '~/components/ClinicDetailPageFlexible/components/SelfPay/util/Reasons';

class CovidTestSlugs {
  static ANTIBODY = 'covid-19-antibody-test' as const;

  static COVID_TEST = 'covid-19-test' as const;

  static COVID_PCR_TEST = 'covid-19-pcr-test' as const;

  static COVID_RAPID_PCR_TEST = 'rapid-covid-19-pcr-test' as const;

  static COVID_ANTIGEN_TEST = 'covid-19-antigen-test' as const;

  static COVID_RAPID_ANTIGEN_TEST = 'rapid-covid-19-antigen-test' as const;
}

export const COVID_TESTING_ANCHOR = 'covid-19-testing';
export const COVID_TEST_DESC =
  'Read below to understand which test is best for your needs. To protect yourself and others, please follow the latest guidance ';
export const CDC_LINK = 'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html';
export const FDA_LINK =
  'https://www.fda.gov/consumers/consumer-updates/coronavirus-disease-2019-testing-basics';
export const COVID_SERVICE_COPY_MAP: {
  [key: string]: {
    definition?: string;
    explanation?: string;
    lowerCase?: string;
    title: string;
    bookText: string;
  };
} = {
  [CovidTestSlugs.COVID_RAPID_ANTIGEN_TEST]: {
    definition:
      'Reliable for quickly detecting an active COVID-19 infection for people recently infected or with symptoms.',
    explanation:
      'This test is not accepted for all purposes, so check with the airline or event to confirm.²',
    lowerCase: 'COVID rapid antigen test',
    title: 'COVID Rapid antigen test',
    bookText: ServiceBookText.rapid_covid_19_antigen_test.bookText,
  },
  [CovidTestSlugs.COVID_ANTIGEN_TEST]: {
    definition:
      'Reliable for detecting an active COVID-19 infection for people recently infected or with symptoms.',
    explanation:
      'This test is not accepted for all purposes, so check with the airline or event to confirm.²',
    lowerCase: 'COVID antigen test',
    title: 'COVID Antigen test',
    bookText: ServiceBookText.covid_19_antigen_test.bookText,
  },
  [CovidTestSlugs.COVID_RAPID_PCR_TEST]: {
    definition:
      'This is the most accurate test for determining if a person is actively infected with COVID and is available for people who are both symptomatic and asymptomatic.',
    explanation:
      'Best for those who are seeking fast results and need official documentation, such as for traveling or for an event.²',
    lowerCase: 'COVID rapid PCR test',
    title: 'COVID Rapid PCR test',
    bookText: ServiceBookText.rapid_covid_19_pcr_test.bookText,
  },
  [CovidTestSlugs.COVID_PCR_TEST]: {
    definition:
      'This is the most accurate test for determining if a person is actively infected with COVID and is available for people who are both symptomatic and asymptomatic.',
    explanation:
      'Best for those who need official documentation, such as for traveling or for an event.²',
    lowerCase: 'COVID PCR test',
    title: 'COVID PCR test',
    bookText: ServiceBookText.covid_19_pcr_test.bookText,
  },
  [CovidTestSlugs.ANTIBODY]: {
    definition: "This test helps you determine if you've had a past COVID-19 infection.",
    explanation:
      'This test does not detect an active infection, and does not determine if you are infectious, so results are not accepted for travel.²',
    lowerCase: 'COVID antibody test',
    title: 'COVID Antibody test',
    bookText: ServiceBookText.covid_19_antibody_test.bookText,
  },
  [CovidTestSlugs.COVID_TEST]: {
    title: 'COVID test',
    bookText: ServiceBookText.covid_19_test.bookText,
  },
};
export const INSURANCE_ACCEPTED =
  'Most insurances are accepted for this test. Check with your insurance to confirm.';
export const INSURANCE_NOT_ACCEPTED = 'Insurance is currently not accepted for this test.';
export const TEXT_FOR_SUPERSCRIPT_1 =
  'An increase in demand may result in longer turnaround times.';
export const TEXT_FOR_SUPERSCRIPT_1_NO_TIME_AROUND =
  'Turnaround times will vary depending on the type of test you receive and how busy the clinic is.';
export const TEXT_FOR_SUPERSCRIPT_2 =
  'The clinic will provide information on how you’ll receive test results.';

export const TEXT_FOR_LEGAL_DESC_CPP = `Solv ClearPrice Partners have agreed to transparency of certain self-pay rates, but Solv makes no guarantees regarding the accuracy or completeness of such information, as it is subject to the partner's discretion. Prices don’t guarantee availability. If you decide to use your insurance for your visit and the claim is fully processed, the clinic may not be able to revert to a self-pay rate, depending on insurance carrier and plan. Taxes may apply.`;
export const TEXT_FOR_LEGAL_DESC_NON_CPP = `Solv works hard to keep this information as updated as possible, but prices are subject to change based on the provider. Prices don’t guarantee availability. Taxes may apply.`;

enum FluShotSlugs {
  FLU_SHOT = 'flu-shot',
}

class SampleAnalysisTypes {
  static IN_HOUSE = 'in_house';

  static SEND_OUT = 'send_out';
}

class LocationsPerforming {
  static IN_OFFICE = 'In office';

  static DRIVE_THROUGH = 'Drive through';

  static PARKING_LOT = 'Parking lot';

  static AT_WORK_SITE = 'At worksite when requested';
}

class SampleAnalysisTimes {
  static LessThanOneHour = '< 1 hour';

  static LessThanOneDay = '< 1 day';

  static OneToFiveDays = '1-5 days';

  static SixPlusDays = '6+ days';

  static PreferNotToSay = 'Prefer not to say';
}

const locationPerformingToCustomCopyMapping = {
  [LocationsPerforming.IN_OFFICE]: {
    singleSelectionCopy: 'The test is done inside the clinic.',
    multipleSelectionCopy: 'inside the clinic',
  },
  [LocationsPerforming.DRIVE_THROUGH]: {
    singleSelectionCopy: 'This is a drive through testing site.',
    multipleSelectionCopy: 'at a drive through testing site',
  },
  [LocationsPerforming.PARKING_LOT]: {
    singleSelectionCopy: 'This test is done in the parking lot.',
    multipleSelectionCopy: 'in a parking lot',
  },
};

const resultTimeToCustomCopyMapping = {
  [SampleAnalysisTimes.LessThanOneHour]: {
    badgeCopy: 'Results in about 1 hour',
    substring: 'one hour or less',
    fullString: 'You should receive your results in one hour or less.',
  },
  [SampleAnalysisTimes.LessThanOneDay]: {
    badgeCopy: 'Same day results',
    substring: '24 hours or less',
    fullString: 'You should receive your results in 24 hours or less.',
  },
  [SampleAnalysisTimes.OneToFiveDays]: {
    badgeCopy: 'Results in 1-5 days',
    substring: '1 to 5 days',
    fullString: 'You should receive your results in 1 to 5 days.',
  },
  [SampleAnalysisTimes.SixPlusDays]: {
    badgeCopy: 'Results in 6+ days',
    substring: 'a week or longer',
    fullString: 'Your results may take a week or longer to come back.',
  },
  [SampleAnalysisTimes.PreferNotToSay]: {
    substring: 'not available at this time',
    fullString:
      'Turnaround time for results are not available at this time. Call the clinic for more details.',
  },
};

const getChecklistProps = (servicesObj: any, slug: any) => {
  const labsAndTests = safeGet(servicesObj, [])`Labs and Tests`;

  let details;
  for (const lab of labsAndTests) {
    if (safeGet(lab)`slug` === slug) {
      details = lab.details;
      break;
    }
  }

  const locationPerforming = safeGet(details, [])`location_performing`;

  const requirements = safeGet(details, [])`requirements`;

  const sampleAnalysis = safeGet(details, {})`sample_analysis`;
  return { locationPerforming, requirements, sampleAnalysis };
};

const getTestProps = (labsAndTests: any, testType: any) => {
  if (isEmpty(labsAndTests) || !testType)
    return { location_performing: [], requirements: [], sample_analysis: {} };
  const { locationPerforming, requirements, sampleAnalysis } = getChecklistProps(
    labsAndTests,
    testType
  );
  return { locationPerforming, requirements, sampleAnalysis };
};

const getCovidTestResultTime = (details: any, sampleAnalysisType: any) => {
  if (sampleAnalysisType === SampleAnalysisTypes.IN_HOUSE) {
    return safeGet(details)('sampleAnalysis.in_house.result_time');
  }

  if (sampleAnalysisType === SampleAnalysisTypes.SEND_OUT) {
    return safeGet(details)('sampleAnalysis.send_out.result_time');
  }

  return null;
};

export const getCovidTestResultTimeListV2 = (servicesObj: any): any[] => {
  const resultList: any[] = [];
  const labsAndTests = servicesObj['Labs and Tests'];
  const servicesTypes = [
    CovidTestSlugs.COVID_RAPID_ANTIGEN_TEST,
    CovidTestSlugs.COVID_ANTIGEN_TEST,
    CovidTestSlugs.COVID_RAPID_PCR_TEST,
    CovidTestSlugs.COVID_PCR_TEST,
  ];

  servicesTypes.forEach((type) => {
    let index = findIndexCovidTestType(servicesObj, type);
    if (index !== -1) {
      const { details } = labsAndTests[index];
      if (details?.time_to_result && details.time_to_result !== 'Prefer not to say') {
        resultList.push({
          serviceName: type,
          time: details?.time_to_result,
        });
      }
    }
  });

  return resultList;
};

const offersInHouseSampleAnalysis = (sampleAnalysis: any) =>
  SampleAnalysisTypes.IN_HOUSE in sampleAnalysis;

const offersSendOutSampleAnalysis = (sampleAnalysis: any) =>
  SampleAnalysisTypes.SEND_OUT in sampleAnalysis;

const getScreeningRequiredCopy = (requirements: any) => {
  if (isEmptyArray(requirements)) return 'No screening required to get a COVID test.';
  return "A screening may be required to check if you're eligible for a COVID test.";
};

const buildScreeningRequirementsCopy = (requirements: any) => {
  if (isEmpty(requirements)) return null;
  const lowerCasedRequirements = requirements.map((location: any) =>
    location.toLowerCase().replace(' screening', '')
  );
  let requirementsString;
  if (requirements.length >= 3) {
    requirementsString = buildListStringWithDelimiterFromArray(lowerCasedRequirements, 'and');
  } else if (requirements.length === 2) {
    requirementsString = lowerCasedRequirements.join(' and ');
  } else {
    [requirementsString] = lowerCasedRequirements;
  }

  return `This clinic offers ${requirementsString} screenings.`;
};

const getLocationPerformingCopy = (
  locationPerforming: any,
  location: any,
  t: TFunction<'pass'>
) => {
  const hasOnlySelectedAtWorkSite =
    !isEmptyArray(locationPerforming) &&
    locationPerforming.length === 1 &&
    locationPerforming[0] === LocationsPerforming.AT_WORK_SITE;

  if (isEmptyArray(locationPerforming) || hasOnlySelectedAtWorkSite) {
    return (
      <Trans
        components={[
          <a href={`tel:${location.phone}`} key={1}>
            call the clinic
          </a>,
        ]}
        i18nKey="confirmationVisitGuidelines.locationPerformingCopy"
        t={t}
      />
    );
  }

  const filteredLocationsPerforming = locationPerforming.filter(
    (location: any) => location !== LocationsPerforming.AT_WORK_SITE
  );

  if (filteredLocationsPerforming.length >= 2) {
    const multipleSelectionsCopy: any = [];
    filteredLocationsPerforming.forEach((location: any) =>
      multipleSelectionsCopy.push(
        locationPerformingToCustomCopyMapping[location]?.multipleSelectionCopy
      )
    );

    let concatenatedListString;

    if (filteredLocationsPerforming.length >= 3) {
      concatenatedListString = buildListStringWithDelimiterFromArray(multipleSelectionsCopy, 'or');
    }

    if (filteredLocationsPerforming.length === 2) {
      concatenatedListString = multipleSelectionsCopy.join(' or ');
    }

    return `The clinic will let you know whether they are testing ${concatenatedListString}.`;
  }

  return locationPerformingToCustomCopyMapping[locationPerforming[0]]?.singleSelectionCopy;
};

const getSampleAnalysisSiteCopy = (sampleAnalysis: any) => {
  const hasInHouseSampleAnalysis = offersInHouseSampleAnalysis(sampleAnalysis);
  const hasSendOutSampleAnalysis = offersSendOutSampleAnalysis(sampleAnalysis);

  if (hasInHouseSampleAnalysis && hasSendOutSampleAnalysis)
    return 'The clinic will let you know whether they are using an onsite or offsite lab for results.';

  if (hasInHouseSampleAnalysis) return 'Tests are analyzed at an onsite lab.';

  return 'Tests are analyzed at an offsite lab.';
};

const getResultTimeCopyV2 = (resultTime: string) => {
  let copy: string = '';

  switch (resultTime) {
    case '15 min':
    case '1-3 days':
    case '1-5 days':
    case '6+ days':
      copy = `Results in ${resultTime}`;
      break;
    case '< 1 hour':
      copy = 'Results in about 1 hour';
      break;
    case 'Same day':
      copy = 'Same day results';
      break;
    case 'Prefer not to say':
    default:
      break;
  }
  return copy;
};

const getResultTimeCopy = (details: any, type?: string) => {
  const inHouseResultTime = getCovidTestResultTime(details, SampleAnalysisTypes.IN_HOUSE);
  const sendOutResultTime = getCovidTestResultTime(details, SampleAnalysisTypes.SEND_OUT);

  if (inHouseResultTime) {
    if (type === 'badgeCopy') return resultTimeToCustomCopyMapping[inHouseResultTime]?.badgeCopy;
    return resultTimeToCustomCopyMapping[inHouseResultTime].fullString;
  }
  if (type === 'badgeCopy') return resultTimeToCustomCopyMapping[sendOutResultTime]?.badgeCopy;
  return resultTimeToCustomCopyMapping[sendOutResultTime].fullString;
};

export {
  CovidTestSlugs,
  FluShotSlugs,
  SampleAnalysisTimes,
  SampleAnalysisTypes,
  getChecklistProps,
  getTestProps,
  getCovidTestResultTime,
  buildScreeningRequirementsCopy,
  getScreeningRequiredCopy,
  getLocationPerformingCopy,
  getSampleAnalysisSiteCopy,
  getResultTimeCopy,
  getResultTimeCopyV2,
  offersInHouseSampleAnalysis,
  offersSendOutSampleAnalysis,
  resultTimeToCustomCopyMapping,
};
