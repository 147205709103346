export const FETCH_USER_PROFILE_PHOTO = 'userProfilePhotos/FETCH_USER_PROFILE_PHOTO';
export const SAVE_USER_PROFILE_PHOTO = 'userProfilePhotos/SAVE_PROFILE_PHOTO';
export const SET_FETCHING_PROFILE_PHOTO = 'userProfilePhotos/FETCHING_USER_PHOTO_STATUS';

export const setFetchingProfileStatus = (value: any, userProfileId: any) => ({
  type: SET_FETCHING_PROFILE_PHOTO,
  payload: { value, userProfileId },
});

export const saveUserProfilePhoto = (value: any, userProfileId: any) => ({
  type: SAVE_USER_PROFILE_PHOTO,
  payload: { value, userProfileId },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case SAVE_USER_PROFILE_PHOTO:
      return {
        ...state,
        [action.payload.userProfileId]: {
          fetchDate: new Date().valueOf(),
          src: action.payload.value,
          fetching: false,
        },
      };
    case SET_FETCHING_PROFILE_PHOTO:
      return {
        ...state,
        [action.payload.userProfileId]: {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[action.payload.userProfileId],
          fetching: action.payload.value,
        },
      };
    default:
      return state;
  }
};
