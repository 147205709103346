import React, { createContext, ReactNode, useEffect, useState } from 'react';

interface NativeVars {
  hasAcceptedIosPush?: boolean;
  notificationsEnabled?: boolean;
  cankAskToEnableIosPush?: boolean;
  isPrivateDevice?: boolean;
  shouldShowPushPrompt?: boolean;
  shouldShowModalFullScreenPushPrompt?: boolean;
}

export const NativeContext = createContext<NativeVars>({});

const NativeContextProvider = (props: { children?: ReactNode }) => {
  const [counter, setCounter] = useState<number>(0);
  const [nativeVars, setNativeVars] = useState<NativeVars>({});

  const refreshNativeAppVars = () => {
    setCounter((prev) => prev + 1);
  };

  useEffect(() => {
    window.refreshNativeAppVars = refreshNativeAppVars;
    refreshNativeAppVars();
  }, []);

  useEffect(() => {
    setNativeVars({
      hasAcceptedIosPush: window?.hasAcceptedIosPush,
      notificationsEnabled: window?.notificationsEnabled,
      cankAskToEnableIosPush: window?.cankAskToEnableIosPush,
      isPrivateDevice: window?.isPrivateDevice,
      shouldShowPushPrompt: window?.shouldShowPushPrompt,
      shouldShowModalFullScreenPushPrompt: window?.shouldShowModalFullScreenPushPrompt,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return <NativeContext.Provider value={nativeVars}>{props.children}</NativeContext.Provider>;
};

export default NativeContextProvider;
