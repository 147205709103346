import React, { PropsWithChildren } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '../Icons/Close';
import { colors } from '../../../constants/colors';

export type Props = {
  onClose?: (event: React.MouseEvent<HTMLImageElement | HTMLDivElement>) => void;
  open: boolean;
  classes: any;
};

const styles = {
  paper: {
    position: 'absolute',
    minWidth: 553,
    minHeight: 224,
    backgroundColor: colors.white,
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  closeIconContainter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `15px 15px 0 0`,
  },
} as const;

const CenteredDialog = withStyles(styles)(
  ({ children, onClose, open, classes }: PropsWithChildren<Props>) => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        onClose={onClose}
        open={open}
      >
        <Paper className={classes.paper}>
          {onClose && (
            <div className={classes.closeIconContainter}>
              <CloseIcon onClick={onClose} />
            </div>
          )}
          {children}
        </Paper>
      </Modal>
    );
  }
);

export default CenteredDialog;
