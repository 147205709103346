export const PRODUCT_PLACEMENT = {
  PAGE: 'page',
  MODAL: 'modal',
  MODULE: 'module',
  DROP_DOWN: 'drop-down',
};

export const PRODUCT_TYPE = {
  CDP: 'cdp',
  BOOKING_WIDGET: 'booking_widget',
  NEARBY_MODULE: 'nearby_module',
  SRP: 'search_results_page',
  SEARCH_RESULT_SLOT: 'search_result_slot',
  CLICK_TO_CALL: 'click_to_call',
  UNIVERSAL_SEARCH: 'universal_search',
  FAVORITE: 'favorite',
  PG_LOCATION_PAGE: 'pg_location_page',
  PG_GROUP_PAGE: 'pg_group_page',
  PG_PROVIDER_PAGE: 'pg_provider_page',
};

export enum PageType {
  LOCATION = 'location',
  GROUP = 'group',
  PROVIDER = 'provider',
}
