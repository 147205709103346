import { emptyFunction } from './function';
import { getNativeStoreRatingLock, setNativeStoreRatingLock } from './storage';

const NATIVE_OPEN_URL = 'openNativeUrl';
const NATIVE_SET_SAFE_AREA_COLOR = 'setSafeAreaColor';
const NATIVE_NAVIGATE_TO_TOKBOX = 'navigateToTokboxVideoNative';
const NATIVE_REQUEST_LOCATION_PERMISSION = 'iosRequestLocationPermission';
const NATIVE_REQUEST_CAMERA_PERMISSION = 'requestCameraPermissions';
const NATIVE_NAVIGATE_LANDING_PAGE = 'navigateToLandingPage';
const NATIVE_SET_USER_AUTH = 'setUserAuthorization';
const NATIVE_CLEAR_USER_AUTH = 'clearUserAuthorization';
const NATIVE_ACCEPT_PUSH_PERMISSIONS = 'handleAcceptIosPushPermission';
const NATIVE_ASK_FOR_RATING = 'askForRating';
const NATIVE_DIRECT_TO_SETTINGS = 'directToSettings';

interface GenericNativeFunction {
  (...args: any[]): void;
}

interface GetNativeFunction<C = GenericNativeFunction> {
  /** If the native function actually exists */
  exists: boolean;
  /** Call the native function or the fallback
   * default function if it doesn't exist */
  call: C;
}

/**
 * Get a function to interface with the native Solv app.
 *
 * Returns an object with two properties, the .call property contains
 * the window function if it exists, else an empty object. The .exists
 * property allows you to easily check that the function was called.
 *
 * @param functionName name of function to retrieve
 * @returns existsAndCallObject a call and an exists property
 */
export const getNativeFunction = <C = GenericNativeFunction>(
  functionName: any
): GetNativeFunction<C> => {
  const exists = typeof window !== 'undefined' && typeof window[functionName] === 'function';
  return {
    call: (exists ? window[functionName] : emptyFunction) as unknown as C,
    exists,
  };
};

/**
 * Helper class to support the growing list of native functions.
 *
 * Typed out following the function signatures of the
 * `webviewInjectedJavascript.ts` file inside of mapp-native repo.
 */
export class NativeFunctions {
  static get openNativeUrl() {
    return getNativeFunction<(url: string) => void>(NATIVE_OPEN_URL);
  }

  static get requestLocationPermission() {
    return getNativeFunction<() => void>(NATIVE_REQUEST_LOCATION_PERMISSION);
  }

  static get setSafeAreaColor() {
    return getNativeFunction<(color: string) => void>(NATIVE_SET_SAFE_AREA_COLOR);
  }

  static get navigateToTokbox() {
    return getNativeFunction<
      (returnUrl: string, tokboxSessionId: string, bookingId: string) => void
    >(NATIVE_NAVIGATE_TO_TOKBOX);
  }

  static get requestCameraPermissions() {
    return getNativeFunction<() => void>(NATIVE_REQUEST_CAMERA_PERMISSION);
  }

  static get navigateToLandingPage() {
    return getNativeFunction<(referrerUrl: string) => void>(NATIVE_NAVIGATE_LANDING_PAGE);
  }

  static get setUserAuth() {
    return getNativeFunction<
      (
        tokenType: string,
        authToken: string,
        accountId: string,
        expirationDate: string,
        isPersisted: boolean
      ) => void
    >(NATIVE_SET_USER_AUTH);
  }

  static get clearUserAuth() {
    return getNativeFunction<() => void>(NATIVE_CLEAR_USER_AUTH);
  }

  static get handleAcceptPushPermissions() {
    return getNativeFunction<(pushOrigin: string) => void>(NATIVE_ACCEPT_PUSH_PERMISSIONS);
  }

  static get askForRating() {
    return getNativeFunction<() => void>(NATIVE_ASK_FOR_RATING);
  }

  static get directToSettings() {
    return getNativeFunction<() => void>(NATIVE_DIRECT_TO_SETTINGS);
  }
}

export namespace NativePrompts {
  /**
   * Prompts the user for permission to push native prompts.  Performs a check first to see if this is allowed.
   *
   * Returns whether the user was prompted.
   *
   * @param origin the location in the app prompting the user
   * @returns true if it has prompted the user
   */
  export const askForPushPermission = (origin: string) => {
    const ask = window.cankAskToEnableIosPush && !window.hasAcceptedIosPush;
    if (ask) {
      NativeFunctions.handleAcceptPushPermissions.call(origin);
    }
    return ask;
  };

  /**
   * Prompts the user to rate the app.  Performs a check first to see if this is allowed.
   *
   * Returns whether the user was prompted.
   *
   * @returns  true if it has prompted the user
   */
  export const askForNativeStoreRating = () => {
    const nativeStoreRatingLock = getNativeStoreRatingLock();
    if (!nativeStoreRatingLock) {
      NativeFunctions.askForRating.call();
      setNativeStoreRatingLock(true);
    }
    return !nativeStoreRatingLock;
  };
}
