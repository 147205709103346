import LocationInterface from '@solvhealth/types/interfaces/Location';
import { RequireAtLeastOne } from '@solvhealth/types/interfaces/generics';
import { BASE_URL } from '../../config/index';
import { fromStateCodeToState } from '../util/seo';

const sanitizeUri = (uri: any) => {
  if (uri) {
    return uri
      .toString()
      .replace("'", '-')
      .replace(/\s/gi, '-')
      .replace(/[^a-z0-9-]/gim, '');
  }

  return null;
};

/**
 * Build state telemedicine url
 *
 * @param {string} state postal code abbreviation
 */
export const buildTelemedicineStateUrl = (state: any) => {
  const stateCode = state.toLowerCase();
  const stateName = fromStateCodeToState(stateCode);
  return `${BASE_URL}/${stateCode}/s/${stateName}-telemedicine`;
};

interface UtmCodes {
  campaign?: string;
  medium?: string;
  source?: string;
  term?: string;
}

/**
 * Build booking widget url with optional utm codes
 *
 * @param {string} id id hash of location
 * @param {string} referrerLocationId id hash of referring CDP
 * @param {string} utmCodesFrom what url codes to append
 */
export const buildBookingWidgetUrl = (
  { id }: Pick<LocationInterface, 'id'>,
  utmCodesFrom?: 'SRP' | 'CDP',
  referrerLocationId?: string
) => {
  const utmCodes: UtmCodes = {};
  const defaultUrl = `/book-online/${id}`;
  if (utmCodesFrom === 'SRP' || utmCodesFrom === 'CDP') {
    utmCodes.medium = 'inproduct';
  }

  if (utmCodesFrom === 'SRP') {
    utmCodes.source = 'search_best_match';
    utmCodes.campaign = 'srp_telemed_best_match';
  } else if (utmCodesFrom === 'CDP') {
    utmCodes.source = 'cdp_telemed_module';
    utmCodes.term = `solv-${referrerLocationId}`;
  }

  return `${defaultUrl}?${Object.keys(utmCodes)
    .map((c) => `utm_${c}=${utmCodes[c as unknown as keyof UtmCodes]}`)
    .join('&')}`;
};

export const createLocationUrlFromLocation = (
  location: Pick<LocationInterface, 'city' | 'state' | 'id'> &
    RequireAtLeastOne<Pick<LocationInterface, 'displayNamePrimary' | 'name'>>
) => {
  if (location) {
    let name;
    if (typeof location.displayNamePrimary !== 'undefined') {
      name = location.displayNamePrimary.toLowerCase();
    } else {
      if (!location.name) {
        return '';
      }
      name = location.name.toLowerCase();
    }

    const cityState = `${location.city.toLowerCase()}-${location.state.toLowerCase()}`;
    const { id } = location;
    return `/${[name, cityState, id].map(sanitizeUri).join('-')}`;
  }

  return '';
};

export const createProviderGroupLocationUrlFromLocation = (location?: LocationInterface) => {
  if (location) {
    let name;
    if (typeof location.displayNamePrimary !== 'undefined') {
      name = location.displayNamePrimary.toLowerCase();
    } else {
      name = location.name?.toLowerCase();
    }

    const cityState = `${location.city.toLowerCase()}-${location.state.toLowerCase()}`;
    const { id } = location;
    return `/location/${[name, cityState, id].map(sanitizeUri).join('-')}`;
  }

  return '';
};
