import { Stack, WhiteLabelThemeProvider } from '@solvhealth/jigsaw';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import OtpCodeLoginForm from '../../components/OtpCodeLoginForm/OtpCodeLoginForm';
import PhoneLoginForm from '../../components/PhoneLoginForm/PhoneLoginForm';
import { analyticsTrackEvent } from '~/core/analytics';
import { LOGIN_PAGE_LOAD } from '~/core/analytics/events';
import { formatLocationName, getLocationWithHardCodedLogoAndName } from '~/core/util/location';
import { getCurrentPath } from '~/core/util/url';
import useSetTitle from '../../hooks/useSetTitle';
import ClinicLogo from '../Location/ClinicLogo';
import { useLoginForm } from '~/components/LoginForm/hooks/useLoginForm';
import { usePalette } from '~/hooks/usePalette';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';
import NewOtpCodeLoginForm from '~/components/OtpCodeLoginForm';

const title = 'Login';

const ClinicLogoContainer = styled(ClinicLogo)`
  margin: 36px auto;
  text-align: center;
  & img {
    height: auto;
    max-width: 300px;
    max-height: 120px;
  }
`;

type Props = {
  isBookingWidgetEmbedded?: boolean;
  invoiceId?: string;
  fetchLocationDataFromInvoice?: (...args: any[]) => any;
  locationId?: string;
  groupId?: string;
  saveNewInvoiceSource?: (...args: any[]) => any;
  groupLocations?: any;
  fetchGroupLocations?: (...args: any[]) => any;
  isModal?: boolean;
  redirectToRemix?: boolean;
};

function LoginForm(props: Props) {
  const loginFormProps = useLoginForm();
  const { login, location } = loginFormProps;

  const {
    invoiceId,
    fetchLocationDataFromInvoice,
    locationId,
    groupId,
    isBookingWidgetEmbedded,
    saveNewInvoiceSource,
    groupLocations,
    fetchGroupLocations,
    isModal,
  } = props;

  const { paletteName } = usePalette();

  useSetTitle(title);

  useEffect(
    () => (locationId || groupId) && saveNewInvoiceSource?.({ locationId, groupId }),
    [groupId, locationId, saveNewInvoiceSource]
  );
  useEffect(() => analyticsTrackEvent(LOGIN_PAGE_LOAD, { loginPath: getCurrentPath() }), []);
  useEffect(
    () => invoiceId && fetchLocationDataFromInvoice && fetchLocationDataFromInvoice(invoiceId),
    [invoiceId, fetchLocationDataFromInvoice]
  );

  const [areGroupLocationsStale, setAreGroupLocationsStale] = useState(true);
  useEffect(() => {
    if (areGroupLocationsStale && groupId) {
      fetchGroupLocations?.(groupId);
      setAreGroupLocationsStale(false);
    }
  }, [fetchGroupLocations, groupId, areGroupLocationsStale]);

  const fixedLocation = useMemo(() => {
    if (invoiceId && !isEmpty(location) && (location.id === 'JA68Mg' || location.id === 'AzWY8p')) {
      return getLocationWithHardCodedLogoAndName(location);
    }

    if (!isEmpty(groupLocations) && !isEmpty(groupLocations.locations)) {
      return groupLocations.locations[0];
    }

    return location;
  }, [groupLocations, invoiceId, location]);

  const header = useMemo(() => {
    if (!isBookingWidgetEmbedded && (invoiceId || groupId)) {
      return (
        <ClinicLogoContainer alt={formatLocationName(fixedLocation)} location={fixedLocation} />
      );
    }
    if (isModal) return null;

    return null;
  }, [fixedLocation, groupId, invoiceId, isBookingWidgetEmbedded, isModal]);

  const hasOTPBeenSent = login.login && login.login.otp_sent;

  const propsWithFixedLocation = { ...props, ...loginFormProps, location: fixedLocation };

  return (
    <>
      {header}
      <WhiteLabelThemeProvider theme={paletteName}>
        <Stack pb={6} width="100%">
          {hasOTPBeenSent ? (
            <OtpCodeLoginForm {...props} {...loginFormProps} />
          ) : (
            <PhoneLoginForm {...propsWithFixedLocation} />
          )}
        </Stack>
      </WhiteLabelThemeProvider>
    </>
  );
}

LoginForm.contextTypes = {
  setTitle: PropTypes.func.isRequired,
};

export default LoginForm;
