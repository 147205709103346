/**
 * This custom hook allows us to proxy the flags from Launch Darkly and trigger custom logic
 * whenever the value of a flag is read.
 * An example use case is for being able to track which variant Mary has been assigned
 * to an experiment (e.g. the new booking flow) without having to retrieve the value
 * from LD which would enter Mary into the experiment.
 */
import { useContext } from 'react';
import { LDProxyContext } from '../../../core/launch-darkly/LDProxyProvider';

export function useLDFlags() {
  const { flags } = useContext(LDProxyContext);

  return flags;
}
