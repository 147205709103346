import { Stack, SolvTheme, Button, Text } from '@solvhealth/jigsaw';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SOLV_PLUS_ENTRYPOINT_SEARCH } from '~/core/analytics/events';
import { MEMBERSHIP_NAME, PREMIUM_VISIT_NAME } from '../constants';
import { useOnMembershipEntrypointClicked } from '../utils';

type Props = {
  closeParentModal?: () => void;
};

/**
 * Entrypoint for Solv Plus designed for the search filters screen.
 */
const SearchEntrypoint = ({ closeParentModal }: Props) => {
  const { onClick, isMember } = useOnMembershipEntrypointClicked(SOLV_PLUS_ENTRYPOINT_SEARCH);

  /**
   * Make sure we close the parent modal when clicking on the CTA
   */
  function handleClick() {
    closeParentModal?.();
    onClick();
  }

  const { t } = useTranslation('membership');

  const iconSrc = isMember
    ? '/images/account/camera_teal.png'
    : '/images/membership/solv-now-clock.png';

  const isMemberKey = isMember ? 'member' : 'nonMember';

  return (
    <Stack pb={2} px={2}>
      <Stack
        bg="white"
        borderColor="gray-200"
        borderRadius="sm"
        borderStyle="solid"
        borderWidth="1px"
        boxShadow="sm"
        pt={2}
        px={3}
        space={2}
        style={{ paddingBottom: `calc(env(safe-area-inset-bottom) + ${SolvTheme.space[4]})` }}
      >
        <Stack maxWidth={SolvTheme.breakpoints.sm} mx="auto" space={2}>
          <Stack space={1}>
            <Stack alignItems="center" row space={2}>
              <img alt="Save time" height={40} src={iconSrc} width={40} />
              <Text variant="subtitle">
                {t(`solvNowEntrypoints.searchFilters.${isMemberKey}.title` as const, {
                  MEMBERSHIP_NAME,
                })}
              </Text>
            </Stack>
            {!isMember && (
              <Text muted variant="body2">
                {t(`solvNowEntrypoints.searchFilters.nonMember.subtitle`, {
                  SOLV_NOW_NAME: PREMIUM_VISIT_NAME,
                })}
              </Text>
            )}
          </Stack>
          <Button fullWidth onClick={handleClick} size="small" variant="outlined">
            {t(`solvNowEntrypoints.searchFilters.${isMemberKey}.cta` as const)}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SearchEntrypoint;
