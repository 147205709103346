/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
import DateTime, { YEAR_MONTH_DAY_DASHED } from '../../components/DateTime';
import { ANALYTICS, ENV, PAST_VISIT_DISPLAY_TIME_WINDOW } from '../../config/index';
import { getIsMixpanelAliased, setIsMixpanelAliased, setIsNativeAppUser } from '../dapi/tracking';
import logger from '../logger/index';
import { isPersistedLogin } from '../session';
import { isEmptyArray } from '../util/array';
import { getAgeFromBirthDate } from '../util/date';
import {
  getNativeAppVersionInUse,
  getNativeBuildNumber,
  getNativeCarrier,
  getNativeDeviceId,
  getNativeDeviceLocale,
  getNativeSystemVersion,
  isAndroidApp,
  isAndroidPwa,
  isIosApp,
  isIosPwa,
  isNativeApp,
} from '../util/device';
import { safeGet } from '../util/object';
import { isEmptyString } from '../util/string';
import { isClientSide, isIframed } from '../util/system';
import { hoursAfterNow } from '../util/time';
import {
  analyticsIdentifyIterable,
  analyticsTrackEventOnlyToIterableViaSegment,
  getIterableProperties,
} from './iterable';

const DEBUG_ANALYTICS = false;

const analyticsTrackPage = (category?: any, name?: any) => {
  if (isClientSide() && ANALYTICS.enabled) {
    if (!window.analytics) {
      logger.warn('Track page Analytics not loaded');
      return;
    }

    window.analytics.page(category, name, {
      isIosPwa: isIosPwa(),
      isAndroidPwa: isAndroidPwa(),
      isIosApp: isIosApp(),
      isAndroidApp: isAndroidApp(),
      isNativeApp: isNativeApp(),
      isIframed: isIframed(),

      // By default, segment pulls this data first from the `canonical` url, then from the
      // current window/document location. Because our booking widget pages point to a CDP
      // as their canonical URL, we want to override this behavior and explicitly pass in
      // the current URL, as otherwise our tracking data would be incorrect and report
      // booking widget page views as CDP page views.
      //
      // @see https://segment.com/docs/sources/website/analytics.js/#page for discussion of
      //      this canonical URL preference in segment.
      //
      // @see https://github.com/solvhealth/mapp-dev/pull/2301 for where we originally made this
      //      change in `mapp`.
      //
      // @see https://developers.google.com/search/docs/advanced/crawling/consolidate-duplicate-urls
      //      for more info on what a canonical URL is and how to use/implement one.
      //
      url: window.location.href,
      path: window.location.pathname,
    });
  }
};

export const getExtendedProperties = (properties: any, originalEventName: any) => ({
  ...properties,
  url: window.location.href,
  path: window.location.pathname,
  isIosPwa: isIosPwa(),
  isAndroidPwa: isAndroidPwa(),
  isIosApp: isIosApp(),
  isAndroidApp: isAndroidApp(),
  isNativeApp: isNativeApp(),
  userAgent: navigator.userAgent,
  originalEventName,
  repo: 'mapp-dev',
  package: 'mapp-dev',
  environment: ENV,
  metricValue: properties?.metricValue ?? 1,
});

const analyticsTrackEvent = (event: any, properties = {}) => {
  if (DEBUG_ANALYTICS) {
    console.info('Analytics Event Triggered', { event, properties });
  }

  if (isClientSide() && ANALYTICS.enabled) {
    // Segment
    if (window.analytics) {
      // Send the event to all the destinations besides Iterable, which has a custom handling.
      // see analyticsTrackingEventOnlyToIterableViaSegment below
      const segmentOptions = {
        integrations: {
          All: true,
          Iterable: false,
        },
      };
      window.analytics.track(event, getExtendedProperties(properties, event), segmentOptions);
      analyticsTrackEventOnlyToIterableViaSegment(event, properties);
    } else {
      logger.warn('Track event Analytics not loaded');
      return;
    }
  }
};

const analyticsTrackFormErrors = (event: any, errors: any) => {
  const properties = {
    ...errors,
    fields: Object.keys(errors).join(','),
    url: window.location.href,
    path: window.location.pathname,
    isIosPwa: isIosPwa(),
    isAndroidPwa: isAndroidPwa(),
    isIosApp: isIosApp(),
    isAndroidApp: isAndroidApp(),
    isNativeApp: isNativeApp(),
  };
  analyticsTrackEvent(event, properties);
};

const getBookingProperties = (accountId: any, bookingPostData: any) => ({
  origin: bookingPostData.booking.origin,
  reason: bookingPostData.booking.reason_for_visit,
  insurer_type: bookingPostData?.insurance_profile?.insurer_type ?? null,
  insurer_code: bookingPostData?.insurance_profile?.insurer_code ?? null,
  url: window.location.href,
  risk_partner_id: bookingPostData.risk_partner_id,
  solv_user_date: bookingPostData.solv_user_date,
  path: window.location.pathname,
  id: accountId,
  isPremium: bookingPostData.booking?.is_premium_visit,
  isIosPwa: isIosPwa(),
  isAndroidPwa: isAndroidPwa(),
  isIosApp: isIosApp(),
  isAndroidApp: isAndroidApp(),
  isNativeApp: isNativeApp(),
});

const analyticsIdentify = async (accountId: any, bookingPostData: any) => {
  if (isClientSide() && ANALYTICS.enabled) {
    if (!window.analytics) {
      logger.warn('Analytics not loaded');
      return;
    }

    const isUserAlreadyTracked = await getIsMixpanelAliased(accountId);
    if (!isUserAlreadyTracked) {
      window.analytics.alias(accountId);
      setIsMixpanelAliased(accountId);
    }

    const identifyTraits = getBookingProperties(accountId, bookingPostData);

    const segmentOptions = {
      integrations: {
        All: true,
        Iterable: false,
      },
    };

    // will this be an issue if email is not available? identify() requires email?
    if (safeGet(bookingPostData)('booking.email')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'email' does not exist on type '{ origin:... Remove this comment to see the full error message
      identifyTraits.email = safeGet(bookingPostData)('booking.email');
    }
    window.analytics.identify(accountId, identifyTraits, segmentOptions);
    analyticsIdentifyIterable(accountId, identifyTraits);
  }
};

const analyticsLoginIdentify = async (accountSummary: any) => {
  if (isClientSide() && ANALYTICS.enabled) {
    if (!window.analytics) {
      logger.warn('Analytics not loaded');
      return;
    }

    if (!accountSummary || isEmptyArray(accountSummary.user_profiles)) {
      logger.warn('No account summary found');
      return;
    }

    const accountId = accountSummary.id;

    const userProfiles = accountSummary.user_profiles;
    const oldestProfile = userProfiles
      .filter((p: any) => p.birth_date)
      .sort(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        (a: any, b: any) => getAgeFromBirthDate(b.birth_date) - getAgeFromBirthDate(a.birth_date)
      )
      .shift();
    const youngestProfile = userProfiles
      .filter((p: any) => p.birth_date)
      .sort(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        (a: any, b: any) => getAgeFromBirthDate(a.birth_date) - getAgeFromBirthDate(b.birth_date)
      )
      .shift();
    const lastBooking = accountSummary.bookings.most_recent;

    const properties = {
      market: accountSummary.market,
      isIosPwa: isIosPwa(),
      isAndroidPwa: isAndroidPwa(),
      isPersistedLogin: isPersistedLogin(),
      riskPartnerId: accountSummary.risk_partner_id,

      accountCreatedDate: DateTime.format(accountSummary.created_date)(YEAR_MONTH_DAY_DASHED),
      accountOnboardingDate: DateTime.format(accountSummary.onboarding_date)(YEAR_MONTH_DAY_DASHED),
      accountLoginCount: accountSummary.login_count,
      accountFirstLogin: DateTime.format(accountSummary.first_login)(YEAR_MONTH_DAY_DASHED),
      accountLastLogin: DateTime.format(accountSummary.last_login)(YEAR_MONTH_DAY_DASHED),
      accountSolvUserDate: DateTime.format(accountSummary.solv_user_date)(YEAR_MONTH_DAY_DASHED),
      accountProfiles: userProfiles.length,
      accountUnverifiedProfiles: userProfiles.filter((p: any) => !p.is_verified).length,
      accountUnder4Profiles: userProfiles.filter(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        (p: any) => p.birth_date && getAgeFromBirthDate(p.birth_date) < 4
      ).length,
      accountBetween4and18Profiles: userProfiles.filter(
        (p: any) =>
          p.birth_date &&
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          getAgeFromBirthDate(p.birth_date) >= 4 &&
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          getAgeFromBirthDate(p.birth_date) <= 18
      ).length,
      accountOver18Profiles: userProfiles.filter(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        (p: any) => p.birth_date && getAgeFromBirthDate(p.birth_date) > 18
      ).length,
      accountOldestProfileAge: getAgeFromBirthDate(safeGet(oldestProfile)('birth_date')),
      accountYoungestProfileAge: getAgeFromBirthDate(safeGet(youngestProfile)('birth_date')),
      accountTotalBookings: accountSummary.bookings.total,
      accountActiveInsuranceProfiles: accountSummary.insurance_profiles.filter(
        (ip: any) => ip.active
      ).length,
      accountInsuranceCarrier: accountSummary.insurance_profiles.length
        ? accountSummary.insurance_profiles[0].insurer_name
        : null,
      accountLastBookingDate:
        lastBooking && DateTime.format(lastBooking.appointment_date)(YEAR_MONTH_DAY_DASHED),
      accountLastBookingOrigin: lastBooking && lastBooking.origin,
      accountLastBookingIsTelemed: lastBooking && !!lastBooking.telemed_status,
      accountLastBookingStatus: lastBooking && lastBooking.status,
      accountLastBookingInVisit:
        lastBooking &&
        hoursAfterNow(lastBooking.appointment_date) > -1 * PAST_VISIT_DISPLAY_TIME_WINDOW,
    };

    if (!isEmptyString(safeGet(accountSummary, '')('email'))) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'email' does not exist on type '{ market:... Remove this comment to see the full error message
      properties.email = accountSummary.email;
    }

    if (isNativeApp()) {
      const nativeProperties = {
        appVersion: getNativeAppVersionInUse(),
        systemVersion: getNativeSystemVersion(),
        buildNumber: getNativeBuildNumber(),
        deviceId: getNativeDeviceId(),
        deviceLocale: getNativeDeviceLocale(),
        carrier: getNativeCarrier(),
      };

      Object.assign(properties, nativeProperties);

      // Make sure that the related account is tagged to the proper device'
      if (
        (!accountSummary.is_ios_app_user && isIosApp()) ||
        (!accountSummary.is_android_app_user && isAndroidApp())
      ) {
        setIsNativeAppUser(accountId);
      }
    }

    const isUserAlreadyTracked = accountSummary.is_mixpanel_aliased;
    if (!isUserAlreadyTracked) {
      window.analytics.alias(accountId);
      setIsMixpanelAliased(accountId);
    }

    const segmentOptions = {
      integrations: {
        All: true,
        Iterable: false,
      },
    };
    window.analytics.identify(accountId, properties, segmentOptions);
    analyticsIdentifyIterable(accountId, getIterableProperties(accountSummary));
  }
};

const analyticsTagHotjarRecording = (tags: any) => {
  if (isClientSide() && ANALYTICS.enabled && !isEmptyArray(tags)) {
    if (!window.hj) {
      window.hj =
        window.hj ||
        function () {
          // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'hj'.
          (hj.q = hj.q || []).push(arguments);
        };
      if (!window.hj) {
        logger.warn('Hot jar tagging not loaded');
        return;
      }
    }

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'hj'.
    hj('tagRecording', tags);
  }
};

const analyticsTriggerHotjarRecording = (recordingTag: any) => {
  if (isClientSide() && ANALYTICS.enabled && !isEmptyString(recordingTag)) {
    if (!window.hj) {
      window.hj =
        window.hj ||
        function () {
          // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'hj'.
          (hj.q = hj.q || []).push(arguments);
        };
      if (!window.hj) {
        logger.warn('Hot jar trigger recording not loaded');
        return;
      }
    }

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'hj'.
    hj('trigger', recordingTag);
  }
};

export {
  analyticsTrackPage,
  analyticsTrackEvent,
  analyticsLoginIdentify,
  analyticsIdentify,
  analyticsTrackFormErrors,
  analyticsTagHotjarRecording,
  analyticsTriggerHotjarRecording,
  getBookingProperties,
};
