/**
 * Service names for Price Transparency V1
 */
export enum ServiceName {
  alcohol_testing = 'alcohol_testing',
  base_office_visit = 'base_office_visit',
  botox = 'botox',
  camp_physical = 'camp_physical',
  covid_19_antibody_test = 'covid_19_antibody_test',
  covid_19_antigen_test = 'covid_19_antigen_test',
  covid_19_pcr_test = 'covid_19_pcr_test',
  covid_19_test = 'covid_19_test',
  covid_pill = 'covid_pill',
  dermal_filler = 'dermal_filler',
  dermaplaning = 'dermaplaning',
  five_panel_drug_test = 'five_panel_drug_test',
  flu_shot = 'flu_shot',
  gonorrhea_test = 'gonorrhea_test',
  hepatitis_test = 'hepatitis_test',
  laceration_repair = 'laceration_repair',
  microdermabrasion = 'microdermabrasion',
  mole_removal = 'mole_removal',
  monoclonal_antibody_therapy = 'monoclonal_antibody_therapy',
  rapid_covid_19_antigen_test = 'rapid_covid_19_antigen_test',
  rapid_covid_19_pcr_test = 'rapid_covid_19_pcr_test',
  school_and_sports_physicals = 'school_and_sports_physicals',
  sports_physicals = 'sports_physicals',
  syphilis_test = 'syphilis_test',
  ten_panel_drug_test = 'ten_panel_drug_test',
  wart_removal = 'wart_removal',
  'x-ray_chest' = 'x-ray_chest',
  'x-ray_extremity' = 'x-ray_extremity',
}

export const ServiceInfo: Record<ServiceName, { displayName: string }> = {
  base_office_visit: {
    // Renamed from "Base office visit"
    displayName: 'Office visit',
  },
  // Confirm w/ Sean
  covid_19_antibody_test: {
    displayName: 'COVID-19 antibody test',
  },
  covid_19_antigen_test: {
    displayName: 'COVID-19 antigen test',
  },
  covid_19_pcr_test: {
    displayName: 'COVID-19 PCR test',
  },
  covid_19_test: {
    displayName: 'COVID-19 test',
  },
  rapid_covid_19_antigen_test: {
    displayName: 'Rapid COVID-19 antigen test',
  },
  rapid_covid_19_pcr_test: {
    displayName: 'Rapid COVID-19 PCR test',
  },
  flu_shot: {
    displayName: 'Flu shot',
  },
  school_and_sports_physicals: {
    displayName: 'School physicals',
  },
  camp_physical: {
    displayName: 'Camp physicals',
  },
  sports_physicals: {
    displayName: 'Sports physicals',
  },
  laceration_repair: {
    displayName: 'Laceration repair',
  },
  'x-ray_chest': {
    displayName: 'X-ray (chest)',
  },
  'x-ray_extremity': {
    displayName: 'X-ray (extremity)',
  },
  ten_panel_drug_test: {
    displayName: '10-panel drug test',
  },
  five_panel_drug_test: {
    displayName: '5-panel drug test',
  },
  alcohol_testing: {
    displayName: 'Alcohol testing',
  },
  covid_pill: {
    displayName: 'Covid pill',
  },
  gonorrhea_test: {
    displayName: 'Gonorrhea test',
  },
  hepatitis_test: {
    displayName: 'Hepatitis test',
  },
  monoclonal_antibody_therapy: {
    displayName: 'Monoclonal antibody therapy',
  },
  syphilis_test: {
    displayName: 'Syphilis test',
  },
  botox: {
    displayName: 'Botox',
  },
  dermaplaning: {
    displayName: 'Dermaplaning',
  },
  dermal_filler: {
    displayName: 'Dermal filler',
  },
  microdermabrasion: {
    displayName: 'Microdermabrasion',
  },
  mole_removal: {
    displayName: 'Mole removal',
  },
  wart_removal: {
    displayName: 'Wart removal',
  },
};

export const ServiceDescription: Record<ServiceName, { description: string } | undefined> = {
  base_office_visit: {
    description:
      'Includes a basic examination and consultation from a provider. Also includes basic treatment plans and scripts. The office visit fee may or may not cover tests, labs, and services that may be administered during the visit, depending on the location.',
  },
  covid_19_antibody_test: undefined,
  covid_19_antigen_test: undefined,
  covid_19_pcr_test: undefined,
  covid_19_test: undefined,
  rapid_covid_19_antigen_test: undefined,
  rapid_covid_19_pcr_test: undefined,
  ten_panel_drug_test: undefined,
  five_panel_drug_test: undefined,
  alcohol_testing: undefined,
  covid_pill: undefined,
  gonorrhea_test: undefined,
  hepatitis_test: undefined,
  monoclonal_antibody_therapy: undefined,
  syphilis_test: undefined,
  botox: undefined,
  dermaplaning: undefined,
  dermal_filler: undefined,
  microdermabrasion: undefined,
  mole_removal: undefined,
  wart_removal: undefined,
  flu_shot: {
    description:
      'The best way to protect yourself from seasonal flu. The flu virus can mutate over time, so it’s important to stay up-to-date with flu shots to make sure you are protected against the current or expected strains.',
  },
  school_and_sports_physicals: {
    description:
      'Get ready to go back-to-school with a school physical. Check in on your child’s current development and overall health.',
  },
  camp_physical: {
    description:
      'Prepare your kiddo for fun in the sun with a camp physical. Camps often require participants to receive a physical examination prior to enrollment. Ensures that your child is healthy and doesn’t have any potentially undiagnosed conditions.',
  },
  sports_physicals: {
    description:
      'Kick off your athlete’s sports season with a sports physical. Helps determine whether it is safe for an individual to participate in a sporting activity. Assesses general health, current fitness level, and risk of injury.',
  },
  laceration_repair: {
    description:
      'If you have a tear or cut in your skin, tissue and or muscle, you may need to have a laceration repair. This is a procedure that includes cleaning, preparing, and closing of open wounds.',
  },
  'x-ray_chest': {
    description:
      'Imaging of the structures and organs in your chest. An X-ray can help diagnose a variety of conditions that affect your heart, lungs, ribs, blood vessels, and spine. Allows for quicker, more accurate diagnosis of a variety of lung and heart diseases.',
  },
  'x-ray_extremity': {
    description:
      'Broken bone? An X-ray can help diagnose a variety of conditions in your arms, legs, hands, and feet.',
  },
};

export enum ServiceNameInText {
  base_office_visit = 'office visit',
  video_visit = 'video visit',
  covid_19_pcr_test = 'COVID PCR test',
  rapid_covid_19_pcr_test = 'rapid COVID PCR test',
  covid_19_antigen_test = 'COVID antigen test',
  rapid_covid_19_antigen_test = 'rapid COVID antigen test',
  covid_19_antibody_test = 'COVID antibody test',
  covid_19_test = 'COVID test',
  flu_shot = 'flu shot',
  school_and_sports_physicals = 'school physical',
  camp_physical = 'camp physical',
  sports_physicals = 'sports physical',
  laceration_repair = 'laceration repair',
  ten_panel_drug_test = '10-panel drug test',
  five_panel_drug_test = '5-panel drug test',
  alcohol_testing = 'alcohol testing',
  covid_pill = 'covid pill',
  gonorrhea_test = 'gonorrhea test',
  hepatitis_test = 'hepatitis test',
  monoclonal_antibody_therapy = 'monoclonal antibody therapy',
  syphilis_test = 'syphilis test',
  botox = 'botox',
  dermaplaning = 'dermaplaning',
  dermal_filler = 'dermal filler',
  microdermabrasion = 'microdermabrasion',
  mole_removal = 'mole removal',
  wart_removal = 'wart removal',
  'x-ray_chest' = 'X-ray (chest)',
  'x-ray_extremity' = 'X-ray (extremity)',
}

export const ServiceBookText: Record<ServiceName, { bookText: string }> = {
  base_office_visit: {
    bookText: `Book an ${ServiceNameInText.base_office_visit}`,
  },
  covid_19_antibody_test: {
    bookText: `Book a ${ServiceNameInText.covid_19_antibody_test}`,
  },
  covid_19_antigen_test: {
    bookText: `Book a ${ServiceNameInText.covid_19_antigen_test}`,
  },
  covid_19_pcr_test: {
    bookText: `Book a ${ServiceNameInText.covid_19_pcr_test}`,
  },
  covid_19_test: {
    bookText: `Book a ${ServiceNameInText.covid_19_test}`,
  },
  rapid_covid_19_antigen_test: {
    bookText: `Book a ${ServiceNameInText.rapid_covid_19_antigen_test}`,
  },
  rapid_covid_19_pcr_test: {
    bookText: `Book a ${ServiceNameInText.rapid_covid_19_pcr_test}`,
  },
  flu_shot: {
    bookText: `Book a ${ServiceNameInText.flu_shot}`,
  },
  school_and_sports_physicals: {
    bookText: `Book a school physical`,
  },
  camp_physical: {
    bookText: `Book a camp physical`,
  },
  sports_physicals: {
    bookText: `Book a sports physical`,
  },
  laceration_repair: {
    bookText: `Book a ${ServiceNameInText.laceration_repair}`,
  },
  ten_panel_drug_test: {
    bookText: `Book a ${ServiceNameInText.ten_panel_drug_test}`,
  },
  five_panel_drug_test: {
    bookText: `Book a ${ServiceNameInText.five_panel_drug_test}`,
  },
  alcohol_testing: {
    bookText: `Book a ${ServiceNameInText.alcohol_testing}`,
  },
  covid_pill: {
    bookText: `Book a ${ServiceNameInText.covid_pill}`,
  },
  gonorrhea_test: {
    bookText: `Book a ${ServiceNameInText.gonorrhea_test}`,
  },
  hepatitis_test: {
    bookText: `Book a ${ServiceNameInText.hepatitis_test}`,
  },
  monoclonal_antibody_therapy: {
    bookText: `Book a ${ServiceNameInText.monoclonal_antibody_therapy}`,
  },
  syphilis_test: {
    bookText: `Book a ${ServiceNameInText.syphilis_test}`,
  },
  botox: {
    bookText: `Book a ${ServiceNameInText.botox}`,
  },
  dermaplaning: {
    bookText: `Book a ${ServiceNameInText.dermaplaning}`,
  },
  dermal_filler: {
    bookText: `Book a ${ServiceNameInText.dermal_filler}`,
  },
  microdermabrasion: {
    bookText: `Book a ${ServiceNameInText.microdermabrasion}`,
  },
  mole_removal: {
    bookText: `Book a ${ServiceNameInText.mole_removal}`,
  },
  wart_removal: {
    bookText: `Book a ${ServiceNameInText.wart_removal}`,
  },
  'x-ray_chest': {
    bookText: `Book an ${ServiceNameInText['x-ray_chest']}`,
  },
  'x-ray_extremity': {
    bookText: `Book an ${ServiceNameInText['x-ray_extremity']}`,
  },
};

/**
 * Reasons for visits for Price Transparency V1
 */
export const Reasons: Record<ServiceName | `${ServiceName}`, string[]> = {
  base_office_visit: ['Office visit', 'Clinic visit'],
  covid_19_pcr_test: ['COVID PCR test'],
  rapid_covid_19_pcr_test: ['COVID rapid PCR test'],
  covid_19_antigen_test: ['COVID antigen test'],
  rapid_covid_19_antigen_test: ['COVID rapid antigen test'],
  covid_19_antibody_test: ['COVID antibody test'],
  covid_19_test: ['COVID test (general results/ranges)'],
  flu_shot: ['Flu shot'],
  school_and_sports_physicals: ['School physical'],
  camp_physical: ['Camp physical', 'Sports physical'],
  sports_physicals: ['Sports physical'],
  laceration_repair: ['Stitches', 'Laceration repair'],
  ten_panel_drug_test: ['10 panel drug test'],
  five_panel_drug_test: ['5 panel drug test'],
  alcohol_testing: ['Alcohol testing'],
  covid_pill: ['Covid pill'],
  gonorrhea_test: ['Gonorrhea test'],
  hepatitis_test: ['Hepatitis test'],
  monoclonal_antibody_therapy: ['Monoclonal antibody therapy'],
  syphilis_test: ['Syphilis test'],
  botox: ['Botox'],
  dermaplaning: ['Dermaplaning'],
  dermal_filler: ['Dermal filler'],
  microdermabrasion: ['Microdermabrasion'],
  mole_removal: ['Mole removal'],
  wart_removal: ['Wart removal'],
  'x-ray_chest': ['X-ray (chest)', 'Chest x-ray', 'x-ray chest'],
  'x-ray_extremity': [
    'X-ray (extremity)',
    'X-ray hand',
    'X-ray foot',
    'X-ray arm',
    'X-ray toe',
    'X-ray finger',
    'X-ray leg',
  ],
};
