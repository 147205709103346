import React, { useState } from 'react';
import { Box, TextField } from '@solvhealth/jigsaw';
import { InputGroupValues } from '~/components/Form/InputGroupList';

type InputGroupProps = {
  item: InputGroupValues;
  handleFormChange: (value: InputGroupValues, index: number) => void;
  index: number;
};

const InputGroup = (props: InputGroupProps): React.ReactElement => {
  const { item, handleFormChange, index } = props;
  const [inputValues, setInputValues] = useState<InputGroupValues>(item);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newInputValues = { ...inputValues, [e.target.name]: e.target.value };
    setInputValues(newInputValues);
    handleFormChange(newInputValues, index);
  };

  return (
    <Box key={index}>
      {Object.keys(item).map((labelName: string) => {
        return (
          <Box key={labelName} mb={3}>
            <TextField
              dense
              name={labelName}
              onChange={handleInputChange}
              title={labelName}
              value={item[labelName]}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default InputGroup;
