import { analyticsTrackEvent } from '~/core/analytics';

export const PWA_ADD_TO_HOME_SCREEN = 'PWA - Add to home screen';
export const MAPP_LOADED_IN_IFRAME = 'Mapp - Loaded in iframe';

export const trackIfSiteLoadedWithinIframe = () => {
  try {
    if (window.location !== window.parent?.location) {
      analyticsTrackEvent(MAPP_LOADED_IN_IFRAME, {
        currentLocation: window.location.href,
        parentLocation: window.location.ancestorOrigins?.[0],
      });
    }
  } catch {
    return;
  }
};
