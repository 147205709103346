const BIGTEX_BOOKING_RECEIVED = 'bigTexBooking/RECEIVED';
const BIGTEX_BOOKING_ERROR = 'bigTexBooking/ERROR';
const BIGTEX_BOOKING_SUBMITTING = 'bigTexBooking/SUBMITTING';

export const bigTexBookingReceived = (value: any) => ({
  type: BIGTEX_BOOKING_RECEIVED,
  payload: { value },
});

export const bigTexBookingError = (value: any) => ({
  type: BIGTEX_BOOKING_ERROR,
  payload: { value },
});

export const bigTexBookingSubmitting = (value: any) => ({
  type: BIGTEX_BOOKING_SUBMITTING,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case BIGTEX_BOOKING_RECEIVED:
      return {
        ...state,
        result: action.payload.value,
        error: null,
        submitting: false,
      };
    case BIGTEX_BOOKING_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
        submitting: false,
      };
    case BIGTEX_BOOKING_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    default:
      return state;
  }
};
