import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { LOGIN_INFO_COOKIE_NAME, REFRESH_TOKEN_TIMEOUT } from '../../config/index';
import { getLoginInfo, setLoginInfo } from '../../core/session';
import { loginFailed, refreshResponseReceived } from '../../actions/login';
import { postLoginRefresh } from '../../core/dapi/login';

const RefreshAccessToken = (props: any) => {
  const [cookies, setCookie] = useCookies([LOGIN_INFO_COOKIE_NAME]);
  const { login, error } = useSelector((state: any) => state.login);
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshToken = () => {
      if (cookies[LOGIN_INFO_COOKIE_NAME]?.persisted) {
        // Nothing to refresh here.
        // Having to log back in every 100 years (current long session duration) is fine.
        return;
      }

      if (login?.refresh_token && !error) {
        const handleRefreshSuccess = (response: any) => {
          const currentLoginInfo = getLoginInfo();
          setLoginInfo(
            {
              account_id: currentLoginInfo?.id,
              ...response,
            },
            setCookie
          );
          return refreshResponseReceived(response);
        };

        dispatch(postLoginRefresh(login, handleRefreshSuccess, loginFailed));
      }
    };
    const intervalId = setInterval(refreshToken, REFRESH_TOKEN_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [cookies, setCookie, dispatch, login, error]);
  return null;
};

RefreshAccessToken.propTypes = { cookies: PropTypes.object.isRequired };

export default RefreshAccessToken;
