import { DAPI_HOST } from '../../config/index';

const DEFAULT_SYMPTOM_LIST_LIMIT = 10;
/**
 *
 * @param {object} parts or full symptom name
 * @returns {string}
 */
const getSymptomsListUrl = (symptom: any, limit: any) => {
  const queryLimit = typeof limit === 'undefined' ? DEFAULT_SYMPTOM_LIST_LIMIT : limit;
  return `${DAPI_HOST}/v2/symptoms?symptom=${encodeURIComponent(symptom)}&limit=${queryLimit}`;
};

const getSymptomsIsEmergencyUrl = (symptom: any) =>
  `${DAPI_HOST}/v2/symptoms/is-emergency?symptom=${encodeURIComponent(symptom)}`;

const getSymptomIsNotSuitableForTelemedUrl = (symptom: any) =>
  `${DAPI_HOST}/v1/symptoms/telemed?symptom=${encodeURIComponent(symptom)}`;

export { getSymptomsListUrl, getSymptomsIsEmergencyUrl, getSymptomIsNotSuitableForTelemedUrl };
