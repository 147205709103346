export const TrackingEntities = {
  ACCOUNTS: 'accounts',
  BOOKINGS: 'bookings',
  INVOICES: 'invoices',
  USER_PROFILES: 'user_profiles',
  INSURANCE_PROFILES: 'insurance_profiles',
  LAB_RESULTS: 'lab_results',
};

export const TrackingTypes = {
  ACTION_ONBOARD: 'action.onboard',
  ACTION_REDIRECT: 'action.redirect',
  CLICK_EMAIL: 'click.email',
  CLICK_SMS: 'click.sms',
  CLICK_TILE: 'click.tile',
  CLICK_PUSH: 'click.push',
  INFO_REFERRER: 'info.referrer',
};

export const UtmSourceTypes = { GMB: 'gmb', GOOGLE_RESERVE: 'gmb_reserve', RIBBON: 'ribbon' };

export const UTMMediumTypes = {
  EMAIL: 'email',
  SMS: 'sms',
  ORGANIC: 'organic',
  BOOKING_TIMES: 'booking-times',
};
