import { useEffect, useState } from 'react';
import { useUserAgent } from '~/core/util/device';
import { isServerSide } from '../core/util/system';
import { getDefaultViewport } from '../core/util/device/screen';

// Define general type for useWindowSize hook, which includes width and height
export interface Viewport {
  width: number;
  height: number;
}

/**
 * a custom hook to get dynamic window viewport size
 *
 * @returns viewport: Size
 */
const useViewport = (): Viewport => {
  let defaultViewport = getDefaultViewport();
  const userAgent = useUserAgent();
  if (isServerSide()) {
    defaultViewport = getDefaultViewport(userAgent);
  }
  const [viewport, setViewport] = useState<Viewport>(defaultViewport);
  useEffect(() => {
    const handleResize = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return viewport;
};

export default useViewport;
