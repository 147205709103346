import Booking from '@solvhealth/types/interfaces/Booking';
import { SET_PAST_BOOKING, CLEAR_PAST_BOOKINGS } from '../constants/index';
import { bookingResponseFormatter } from './formatters/booking';

export interface PastBookingsState {
  [bookingId: string]: Booking;
}

/**
 * Past bookings reducer
 *
 * @returns state past bookings redux state
 */
export default function bookingReducer(state: PastBookingsState = {}, action: any) {
  let newState = null;
  switch (action.type) {
    case SET_PAST_BOOKING:
      newState = { ...state };
      newState[action.payload.value.id as string] = {
        ...newState[action.payload.value.id as string],
        ...bookingResponseFormatter(action.payload.value),
      };
      return newState;
    case CLEAR_PAST_BOOKINGS:
      return {};
    default:
      return state;
  }
}
