import {
  TYPE_HMO,
  TYPE_MEDICAID,
  TYPE_MEDICARE,
  TYPE_PPO,
  TYPE_SELF,
  TYPE_SELF_LABEL,
  TYPE_SKIP,
  TYPE_TRICARE,
  TYPE_TRIWEST,
  TYPE_TRIWEST_LABEL,
  TYPE_UNITED_HEALTHCARE,
  TYPE_UNITED_HEALTHCARE_LABEL,
} from '../../constants/index';
import {
  InsuranceCarrierDisplayNames,
  InsuranceCarrierPhoneNumbers,
  InsurerCodes,
} from '../../constants/insurance';
import { npiCheckError, npiCheckReceived, npiCheckSubmitted } from '../../actions/npi';
import { capitalCase, isEmptyString } from './string';
import { isEmptyObject } from './object';
import { getNpiCheckUrl } from '../../core/dapi/npi';
import { apiPostDispatchable } from '../../core/dapi';
import { isEmpty } from './empty';
import { getAcceptedInsurances } from './location';
import { colors } from '../../constants/colors';

const mapToStandardInsurerType = (insurerType: any) => {
  const name = [TYPE_HMO, TYPE_PPO, TYPE_MEDICAID, TYPE_MEDICARE, TYPE_TRICARE].find(
    (type) => insurerType.indexOf(type) !== -1
  );
  if (typeof name === 'undefined') {
    return null;
  }

  return name;
};

const isMedicareOrMedicaid = (insurerType: any) =>
  insurerType === TYPE_MEDICAID || insurerType === TYPE_MEDICARE;

const isMedicaid = (insurerType: any) => insurerType && insurerType.toLowerCase() === TYPE_MEDICAID;

const usingInsurance = (insurance: any) =>
  !(
    insurance &&
    insurance.insurerType &&
    (insurance.insurerType === TYPE_SELF || insurance.insurerType === TYPE_SKIP)
  );

const insuranceEntered = (insurance: any) =>
  usingInsurance(insurance) &&
  !isEmptyString(insurance.insurerCode) &&
  !isEmptyString(insurance.memberCode);

const sufficientInsuranceDataForEligibilityCheck = (newBooking: any) =>
  newBooking &&
  newBooking.insurance &&
  newBooking.insurance.memberCode &&
  newBooking.insurance.firstName &&
  newBooking.insurance.lastName &&
  newBooking.insurance.birthDate;

const sufficientInsuranceDataForNPICheck = (newBooking: any, location: any) =>
  newBooking &&
  newBooking.insurance &&
  newBooking.insurance.memberCode &&
  newBooking.insurance.firstName &&
  newBooking.insurance.lastName &&
  newBooking.insurance.birthDate &&
  location;

// TODO: maybe remove location if we want to support opaque

const sufficientInsuranceDataToDisplayInsuranceCard = (profile: any) => {
  if (
    profile.insurer_type === TYPE_SELF ||
    profile.insurer_type === TYPE_SKIP ||
    isEmpty(profile.insurer_type)
  ) {
    return false;
  }

  const requiredFields = [
    profile.member_code,
    profile.first_name,
    profile.last_name,
    profile.insurer_name,
    profile.insurer_type,
  ];

  return !requiredFields.every((field) => isEmptyString(field));
};

const sufficientDataToDisplayInsuranceFacesheet = (insuranceProfile: any) =>
  !isEmptyObject(insuranceProfile) && !isEmptyObject(insuranceProfile.facesheet);

const getStoredNpiData = (newBooking: any, location: any, npi: any) => {
  if (!sufficientInsuranceDataForEligibilityCheck(newBooking)) {
    return null;
  }

  if (!npi || !npi.result) {
    return null;
  }

  return npi.result;
};

const getStoredEligibilityCheckData = (newBooking: any, location: any, eligibilityCheck: any) => {
  if (!sufficientInsuranceDataForEligibilityCheck(newBooking)) {
    return null;
  }

  if (!eligibilityCheck || !eligibilityCheck.result) {
    return null;
  }

  return eligibilityCheck.result;
};

const isInNetwork = (newBooking: any, location: any, npi: any) => {
  const networkData = getStoredNpiData(newBooking, location, npi);
  if (networkData === null) {
    return false;
  }

  if (networkData.in_network && networkData.in_network.constructor === Array) {
    return networkData.in_network[0] === location.id;
  }

  return false;
};

const getEligibilityId = (newBooking: any, location: any, npi: any, eligibilityCheck: any) => {
  const storedNpiData = getStoredNpiData(newBooking, location, npi);
  const storedEligibilityCheckData = getStoredEligibilityCheckData(
    newBooking,
    location,
    eligibilityCheck
  );

  if (storedNpiData !== null) {
    return storedNpiData.eligibility_requests_id;
  }

  if (storedEligibilityCheckData !== null) {
    return storedEligibilityCheckData.eligibility_requests_id;
  }

  return null;
};

const getInsurerId = (newBooking: any, location: any, npi: any) => {
  const storedNpiData = getStoredNpiData(newBooking, location, npi);

  if (storedNpiData !== null) {
    return storedNpiData.insurer_id;
  }

  return null;
};

/**
 * The insurance type names are sourced from the column names of the
 * `location_accepted_insurance_types` table. We don't actually store any human-readable string
 * for plans in the DB. One day, we'll probably need a switch statement here that maps each
 * column name to a proper english name. For now, we can get by just capitalizing the column,
 * removing underscores, watching for a couple special cases.
 */
const formatInsurerType = (type: string) => {
  if ([TYPE_PPO, TYPE_HMO, TYPE_TRICARE].includes(type.toLowerCase())) {
    return type.toUpperCase();
  }

  if (type === TYPE_TRIWEST) {
    return TYPE_TRIWEST_LABEL;
  }

  if (type === TYPE_UNITED_HEALTHCARE) {
    return TYPE_UNITED_HEALTHCARE_LABEL;
  }

  if (type === TYPE_SELF) {
    return TYPE_SELF_LABEL;
  }

  return capitalCase(type.replace(/_/g, ' '));
};

const insuranceStatusToHumanReadable = (status: any) => (status === 'true' ? 'active' : 'inactive');

const getInsurerIdByCode = (insurerCode: any, insurers: any) => {
  if (!insurers) {
    return null;
  }

  const insurer = insurers.find((i: any) => i.insurer_code === insurerCode);
  if (insurer) {
    return insurer.id;
  }

  return null;
};

const doNpiCheck = ({ newBooking, location }: any, onFinish: any, dispatch: any) => {
  dispatch(npiCheckSubmitted(location.id));
  const postData = {
    member_code: newBooking.insurance.memberCode,
    insurer_name: newBooking.insurance.insurerName,
    plan_type: newBooking.insurance.insurerType,
    first_name: newBooking.insurance.firstName,
    last_name: newBooking.insurance.lastName,
    birth_date: newBooking.insurance.birthDate,
    npi_list_str: location.npi,
    location_id: location.id,
    phone: newBooking.profile.phone,
  };

  if (newBooking.insurance.cardFront) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'image_id' does not exist on type '{ memb... Remove this comment to see the full error message
    postData.image_id = newBooking.insurance.cardFront;
  }

  if (newBooking.insurance.insurerCode) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'insurer_code' does not exist on type '{ ... Remove this comment to see the full error message
    postData.insurer_code = newBooking.insurance.insurerCode;
  }

  const onSuccess = (value: any) => {
    dispatch(npiCheckReceived(value));
    onFinish();
    return npiCheckReceived;
  };

  const onError = (value: any) => {
    dispatch(npiCheckError(value));
    onFinish();
    return npiCheckError;
  };

  dispatch(apiPostDispatchable(getNpiCheckUrl(), postData, onSuccess, onError));
};

const doesNotAcceptMedicaid = (location: any) => !location.acceptedInsuranceTypes?.[TYPE_MEDICAID];

const doesNotAcceptMedicare = (location: any) => !location.acceptedInsuranceTypes?.[TYPE_MEDICARE];

const isRequestedInsuranceMatchingLocation = (requestedInsurance: any, location: any) => {
  if (!isEmptyObject(requestedInsurance)) {
    const matched = getAcceptedInsurances(location).find(
      (el: any) => el.toLowerCase() === requestedInsurance.display_name.toLowerCase()
    );
    return !isEmptyObject(matched);
  }

  return false;
};

const isInsuranceCarrierOfType = (insurerCode: any, insuranceCarrierConstant: any) =>
  insurerCode && insurerCode.includes(insuranceCarrierConstant);

const getInsuranceCarrierCardDisplayProperties = (insurerCode: any) => {
  switch (true) {
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.AETNA):
      return {
        color: colors.aetnaPurple,
        logo: '/images/account/insurance/aetna.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.AETNA,
        displayName: InsuranceCarrierDisplayNames.AETNA,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.AMBETTER):
      return {
        color: colors.ambetterPink,
        logo: '/images/account/insurance/ambetter.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.AMBETTER,
        displayName: InsuranceCarrierDisplayNames.AMBETTER,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.AMERIGROUP):
      return {
        color: colors.amerigroupBlue,
        logo: '/images/account/insurance/amerigroup.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.AMERIGROUP,
        displayName: InsuranceCarrierDisplayNames.AMERIGROUP,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.ANTHEM):
      return {
        color: colors.anthemBlue,
        logo: '/images/account/insurance/anthem.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.ANTHEM,
        displayName: InsuranceCarrierDisplayNames.ANTHEM,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.BLUE_CROSS):
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.BLUE_SHIELD):
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.BCBS):
      return {
        color: colors.bcbsBlue,
        logo: '/images/account/insurance/blue_cross_blue_shield.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.BLUE_CROSS_BLUE_SHIELD,
        displayName: InsuranceCarrierDisplayNames.BLUE_CROSS_BLUE_SHIELD,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.CIGNA):
      return {
        color: colors.cignaGreen,
        logo: '/images/account/insurance/cigna.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.CIGNA,
        displayName: InsuranceCarrierDisplayNames.CIGNA,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.HUMANA):
      return {
        color: colors.humanaGreen,
        logo: '/images/account/insurance/humana.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.HUMANA,
        displayName: InsuranceCarrierDisplayNames.HUMANA,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.SUPERIOR_HEALTH_PLAN):
      return {
        color: colors.superiorBlue,
        logo: '/images/account/insurance/superior_plan.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.SUPERIOR_HEALTH_PLAN,
        displayName: InsuranceCarrierDisplayNames.SUPERIOR_HEALTH_PLAN,
      };
    case isInsuranceCarrierOfType(insurerCode, InsurerCodes.UNITED_HEALTH_CARE):
      return {
        color: colors.uhcBlue,
        logo: '/images/account/insurance/united_health_care.png',
        phoneNumber: InsuranceCarrierPhoneNumbers.UNITED_HEALTH_CARE,
        displayName: InsuranceCarrierDisplayNames.UNITED_HEALTH_CARE,
      };
    default:
      return null;
  }
};

const isInsuranceCarrierInCustomUIGroup = (insurerCode: any) =>
  !!getInsuranceCarrierCardDisplayProperties(insurerCode);

export {
  getInsurerId,
  usingInsurance,
  sufficientInsuranceDataForNPICheck,
  sufficientInsuranceDataForEligibilityCheck,
  sufficientInsuranceDataToDisplayInsuranceCard,
  sufficientDataToDisplayInsuranceFacesheet,
  isInNetwork,
  insuranceEntered,
  getEligibilityId,
  formatInsurerType,
  insuranceStatusToHumanReadable,
  isInsuranceCarrierInCustomUIGroup,
  isRequestedInsuranceMatchingLocation,
  getInsurerIdByCode,
  mapToStandardInsurerType,
  doNpiCheck,
  isMedicaid,
  isMedicareOrMedicaid,
  doesNotAcceptMedicaid,
  doesNotAcceptMedicare,
  isInsuranceCarrierOfType,
  getInsuranceCarrierCardDisplayProperties,
};
