import { Box, TextField, ChoiceCard } from '@solvhealth/jigsaw';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

type CheckboxCardWithInputProps = {
  checkboxCardTitle: string;
  inputLabel?: string;
  handleCheckboxCardAndInputChange: (
    checked: boolean,
    item: string,
    info: string,
    isExclusive: boolean
  ) => void;
  error?: boolean;
  inputValue: string;
  checked: boolean;
  isExclusive: boolean;
  disabled: boolean;
  setDisableCheckboxes: Dispatch<SetStateAction<boolean>>;
};

const CheckboxCardWithInput = (props: CheckboxCardWithInputProps): React.ReactElement => {
  const {
    checkboxCardTitle,
    inputLabel,
    handleCheckboxCardAndInputChange,
    checked,
    inputValue,
    isExclusive,
    disabled,
    setDisableCheckboxes,
  } = props;

  const [inputFieldValue, setInputFieldValue] = useState<string>(inputValue);

  useEffect(() => {
    if (isExclusive) setDisableCheckboxes(!!checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleCheckboxCardClick = () => {
    handleCheckboxCardAndInputChange(!checked, checkboxCardTitle, inputFieldValue, isExclusive);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleCheckboxCardAndInputChange(checked, checkboxCardTitle, e.target.value, isExclusive);
    setInputFieldValue(e.target.value);
  };

  const shouldRenderInput = checked && inputLabel !== undefined;

  return (
    <>
      <ChoiceCard
        checkbox
        disabled={disabled}
        key={checkboxCardTitle}
        onClick={handleCheckboxCardClick}
        selected={checked}
        title={checkboxCardTitle}
        type="button"
      />
      {shouldRenderInput && (
        <Box mb={2.5}>
          <TextField
            dense
            onChange={handleInputChange}
            title={inputLabel}
            value={inputFieldValue}
          />
        </Box>
      )}
    </>
  );
};

export default React.memo(CheckboxCardWithInput);
