export const PRIMARY_CARE = 'Primary Care';
export const PEDIATRIC_URGENT_CARE = 'Pediatric Urgent Care';
export const RETAIL_CLINIC = 'Retail Clinic';
export const EMERGENCY_ROOM = 'Emergency Room';
export const EMERGENCY_ROOMS = 'Emergency Rooms';
export const ORTHOPEDIC_URGENT_CARE = 'Orthopedic Urgent Care';
export const DENTAL_URGENT_CARE = 'Dental Urgent Care';
export const TELEHEALTH = 'Telehealth';
export const OCCUPATIONAL_MEDICINE = 'Occupational Medicine';
export const URGENT_CARE = 'Urgent Care';
export const FREESTANDING_ER = 'Freestanding Er';
export const HOSPITAL = 'Hospital';
export const HOSPITAL_ER = 'Hospital Er';
export const HOSPITALS = 'Hospitals';
export const HOSPITAL_OWNED_URGENT_CARE = 'hospital_owned_urgent_care';
export const PEDIATRICIANS = 'pediatricians';
export const HOME_HEALTHCARE = 'Home Healthcare';
export const HOME_HEALTHCARE_DB = 'home_healthcare';

export const INTERNAL_MEDICINE = 'Internal Med';
export const FAMILY_DOCTOR = 'Family Doctor';

export const LABORATORY_TESTING = 'Laboratory Testing';

export const SPECIALTY_URGENT_CARE_CATEGORIES = [
  ORTHOPEDIC_URGENT_CARE,
  DENTAL_URGENT_CARE,
  TELEHEALTH,
];

// Ordered by specificity
export const UCL_LOCATION_CATEGORY_TYPES = [
  PRIMARY_CARE,
  PEDIATRIC_URGENT_CARE,
  RETAIL_CLINIC,
  EMERGENCY_ROOM,
  ORTHOPEDIC_URGENT_CARE,
  DENTAL_URGENT_CARE,
  TELEHEALTH,
  OCCUPATIONAL_MEDICINE,
  URGENT_CARE,
];
