import LocationInterface from '@solvhealth/types/interfaces/Location/index';
import logger from '../logger/index';
import { getExtendedProperties } from './index';
import { convertSnakeToCamelCaseRecursive } from '../util/object';
import {
  ACCOUNT_CALL_INSURANCE_BENEFITS,
  ACCOUNT_CALL_INSURANCE_CARRIER,
  ACCOUNT_INSURANCE_PROFILE,
  ACCOUNT_INSURANCE_USER_ERROR_BANNER,
  ADD_A_FAMILY_TILE,
  ADD_PROFILE_SUBMIT,
  ADD_REVIEW_I_WOULD_NOT_RECOMMEND,
  ADD_REVIEW_I_WOULD_RECOMMEND,
  ADD_REVIEW_LEAVE_REVIEW_ON_GOOGLE,
  ADD_REVIEW_PUBLISH_TO_CONSENT,
  ADD_REVIEW_SUBMIT,
  BOOKING_WIDGET_BOOK_FAIL,
  CDP_CLICK_LEAVE_REVIEW,
  CDP_LEAVE_REVIEW_SUBMITTED,
  CDP_LOADED_CDP,
  CDP_TOUCH_PRIMARY_ACTION,
  CONFIRMATION_TOUCH_ADD_PREFERRED_PHARMACY,
  CONFIRMATION_TOUCH_RESCHEDULE,
  DOWNLOAD_DRIVER_CLICK_LINK,
  FIND_CARE_TAP_LOCATION_CARD,
  INSURANCE_ACUANT_FAILED,
  INSURANCE_FORM_VALIDATION_ERROR,
  INSURANCE_SWIMLANE_COPAY_COINSURANCE_FINDCARE_TAPPED,
  INSURANCE_SWIMLANE_COPAY_COINSURANCE_TAPPED,
  INSURANCE_SWIMLANE_DEDUCTIBLE_TAPPED,
  INSURANCE_SWIMLANE_OUT_OF_POCKET_MAX_TAPPED,
  INSURANCE_SWIMLANE_PREVENTATIVE_PERKS_TAPPED,
  ONE_CLICK_BOOK_TOUCH_CHANGE_PROFILE,
  PROFILES_MY_VISITS_TOUCH_BOOK_AGAIN,
  PROFILES_MY_VISITS_TOUCH_COST_ESTIMATE,
  PROFILES_TOUCH_ADD_INSURANCE,
  PROFILES_TOUCH_REMOVE_INSURANCE,
  PROFILE_CARD_BASIC_CANCEL,
  PROFILE_CARD_BASIC_SAVE,
  PROFILE_CARD_PHARMACY_CANCEL,
  PROFILE_CARD_PHARMACY_SAVE,
  PROFILE_DISCOVER,
  PROFILE_DISCOVER_CARD_TAPPED,
  PROFILE_EDIT_INSURANCE,
  REBOOKING_PROVIDERS_TAPPED,
  RESCHEDULE_FAIL,
  REVIEW_BOOKING_API_NEW_BOOKING,
  REVIEW_BOOKING_TOUCH_FINALIZE,
  SELECT_TIME_SELECT_TIME,
  SRP_SRP_VIEW,
  UPDATE_APP_MODAL_CLOSE,
  UPDATE_APP_MODAL_OPEN,
} from './events';

const ITERABLE_TRACKABLE_EVENTS = [
  ACCOUNT_CALL_INSURANCE_BENEFITS,
  ACCOUNT_CALL_INSURANCE_CARRIER,
  ACCOUNT_INSURANCE_PROFILE,
  ACCOUNT_INSURANCE_USER_ERROR_BANNER,
  ADD_A_FAMILY_TILE,
  ADD_PROFILE_SUBMIT,
  ADD_REVIEW_I_WOULD_NOT_RECOMMEND,
  ADD_REVIEW_I_WOULD_RECOMMEND,
  ADD_REVIEW_LEAVE_REVIEW_ON_GOOGLE,
  ADD_REVIEW_PUBLISH_TO_CONSENT,
  ADD_REVIEW_SUBMIT,
  BOOKING_WIDGET_BOOK_FAIL,
  CDP_CLICK_LEAVE_REVIEW,
  CDP_LEAVE_REVIEW_SUBMITTED,
  CDP_LOADED_CDP,
  CDP_TOUCH_PRIMARY_ACTION,
  CONFIRMATION_TOUCH_ADD_PREFERRED_PHARMACY,
  CONFIRMATION_TOUCH_RESCHEDULE,
  DOWNLOAD_DRIVER_CLICK_LINK,
  FIND_CARE_TAP_LOCATION_CARD,
  INSURANCE_ACUANT_FAILED,
  INSURANCE_FORM_VALIDATION_ERROR,
  INSURANCE_SWIMLANE_COPAY_COINSURANCE_FINDCARE_TAPPED,
  INSURANCE_SWIMLANE_COPAY_COINSURANCE_TAPPED,
  INSURANCE_SWIMLANE_DEDUCTIBLE_TAPPED,
  INSURANCE_SWIMLANE_OUT_OF_POCKET_MAX_TAPPED,
  INSURANCE_SWIMLANE_PREVENTATIVE_PERKS_TAPPED,
  ONE_CLICK_BOOK_TOUCH_CHANGE_PROFILE,
  PROFILES_MY_VISITS_TOUCH_BOOK_AGAIN,
  PROFILES_MY_VISITS_TOUCH_BOOK_AGAIN,
  PROFILES_MY_VISITS_TOUCH_COST_ESTIMATE,
  PROFILES_MY_VISITS_TOUCH_COST_ESTIMATE,
  PROFILES_TOUCH_ADD_INSURANCE,
  PROFILES_TOUCH_REMOVE_INSURANCE,
  PROFILE_CARD_BASIC_CANCEL,
  PROFILE_CARD_BASIC_SAVE,
  PROFILE_CARD_PHARMACY_CANCEL,
  PROFILE_CARD_PHARMACY_SAVE,
  PROFILE_DISCOVER,
  PROFILE_DISCOVER_CARD_TAPPED,
  PROFILE_EDIT_INSURANCE,
  REBOOKING_PROVIDERS_TAPPED,
  RESCHEDULE_FAIL,
  REVIEW_BOOKING_API_NEW_BOOKING,
  REVIEW_BOOKING_TOUCH_FINALIZE,
  SELECT_TIME_SELECT_TIME,
  SRP_SRP_VIEW,
  UPDATE_APP_MODAL_CLOSE,
  UPDATE_APP_MODAL_OPEN,
];

const isEventWhiteListedForIterable = (eventName: any) =>
  ITERABLE_TRACKABLE_EVENTS.includes(eventName);

export const analyticsTrackEventOnlyToIterableViaSegment = (event: any, properties: any) => {
  if (window.analytics) {
    if (isEventWhiteListedForIterable(event)) {
      const segmentOptions = {
        integrations: {
          All: false,
          Iterable: true,
        },
      };
      window.analytics.track(event, getExtendedProperties(properties, event), segmentOptions);
    }
  } else {
    logger.warn('Track event Analytics not loaded');
  }
};

export const getIterableProperties = (accountSummary: any) => accountSummary.iterable_fields || {};

export const analyticsIdentifyIterable = (accountId: any, properties: any) => {
  if (window.analytics) {
    const segmentOptions = {
      integrations: {
        All: false,
        Iterable: true,
      },
    };
    const extendedProperties = {
      ...convertSnakeToCamelCaseRecursive(properties),
      userId: accountId,
    };
    window.analytics.identify(accountId, extendedProperties, segmentOptions);
  }
};

//jpeg is best for emailing, not all clients support webp
export const getLogoForIterable = (location: LocationInterface): string | undefined =>
  location?.imageLinks?.logo_jp2?.[0];
