import LocationInterface from '@solvhealth/types/interfaces/Location';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import Provider from '@solvhealth/types/interfaces/Provider';
import { locationResponseFormatter } from '~/reducers/formatters/location';
import { apiGetJson, apiPostJson } from '.';
import { DAPI_HOST } from '../../config';
import { careTeamProvidersResponseFormatter } from '~/reducers/formatters/careTeamProviders';

export const careTypes = ['uc', 'covid', 'video', 'provider', 'lab'] as const;
export type CareType = typeof careTypes[number];

type CareTeamResponse = Record<CareType, (LocationInterface | Provider)[]>;

export type ProviderWithCareTypes = Provider & {
  careTypes: CareType[];
  last_booked?: string;
};

type CareOptionsResponse = {
  book_again: LocationInterface[];
};

/**
 * Returns all of the user's previously booked locations, minus those they've
 * chosen to exclude, keyed by the `CareType`
 *
 * @param userProfileId The user profile ID to search bookings for
 *
 * @returns {CareTeamResponse} The user's care team
 */
export async function getCareTeam(userProfileId?: string) {
  if (!userProfileId) return null;

  const result = await apiGetJson<CareTeamResponse>(`${DAPI_HOST}/v1/care-team/${userProfileId}`);

  const formattedCareTypes: CareTeamResponse = {
    uc: result.data?.uc?.map?.(locationResponseFormatter) ?? [],
    covid: result.data?.covid?.map?.(locationResponseFormatter) ?? [],
    video: result.data?.video?.map?.(locationResponseFormatter) ?? [],
    provider: result.data?.provider.map?.(careTeamProvidersResponseFormatter) ?? [],
    lab: [], // Labs are handled elsewhere
  };

  return { ...result, data: formattedCareTypes };
}

/**
 * Retrieves a specific slice of the user's Care Team,
 * given a specific geolocation and type of care.
 *
 * @param careType The type of care to get options for
 * @param userProfileId The user profile id to get options for
 * @param queryParams Specify a lat and lng or a state code to center the search around
 *                    (lat/lng applies to in-person or covid test, state to telemed)
 * @param queryParams.lat The latitude to search around
 * @param queryParams.lng The longitude to search around
 * @param queryParams.state The state to search around
 *
 * @returns {CareOptionsResponse} The user's care options, given the parameters
 */
export async function getCareOptions(
  careType: CareType,
  userProfileId?: string,
  queryParams: { lat?: string; lng?: string; state?: string; serviceIds?: string[] } = {}
) {
  if (!userProfileId) return null;

  const { serviceIds, ...rest } = queryParams;
  const query = new URLSearchParams(pickBy(rest, identity) as Record<string, string>);

  for (const serviceId of serviceIds ?? []) {
    query.append('service_ids', serviceId);
  }

  const result = await apiGetJson<CareOptionsResponse>(
    `${DAPI_HOST}/v1/care-team/${userProfileId}/go/${careType}?${query.toString()}`
  );
  if (result?.errors?.length && result?.errors?.length > 0) {
    throw new Error(`Failed to load ${result.errors?.toString()}`);
  }

  const formattedData: CareOptionsResponse = {
    book_again: result.data?.book_again?.map?.(locationResponseFormatter) ?? [],
  };

  return { ...result, data: formattedData };
}

export type DeleteFromCareTeamRequest = {
  locationId?: string | null;
  providerId?: string | null;
  userProfileId: string;
  careType: CareType;
};

/**
 * Deletes a location from a user's care team
 *
 * @returns empty api response
 */
export async function deleteFromCareTeam({
  locationId,
  providerId,
  careType,
  userProfileId,
}: DeleteFromCareTeamRequest) {
  if (!userProfileId) return null;

  return await apiPostJson(`${DAPI_HOST}/v1/care-team/${userProfileId}/exclude`, {
    location_id: locationId,
    provider_id: providerId,
    care_type: careType,
  });
}
