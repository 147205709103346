import moment from 'moment-timezone';
import { willRedirectToSolvPlusFlowsAfterLogin } from '~/components/Membership/utils';
import {
  getIsStatusUnverifiable,
  VerificationStatus,
} from '../../components/PhoneLoginForm/util/verificationStatus';
import { REMIX_APP_URL } from '../../config';
import { LaunchDates, Marketplaces } from '../../constants/marketplace';
import history from '../../core/history';
import {
  getFirstProfileFromAccountSummary,
  getFirstProfileIdFromAccountSummary,
  needsToSeeWelcomeFlow,
  needsToVerifyProfile,
} from '../util/account';
import { isEmptyArray } from '../util/array';
import { isEmpty } from '../util/empty';
import { isEmptyObject, safeGet } from '../util/object';
import { queryStringFromObject } from '../util/string';
import { getQueryParams } from '../util/url';

export class Routes {
  static ACCOUNT_PROFILE_CREATE = '/account/profile/create';
}

const ACCOUNT_HOME = '/account/home';
const WELCOME_FLOW = 'welcome';
const ONBOARDING_FLOW = 'onboarding';

let onboardingStartingPoint: any = null;

const isPaymentsLoginFlow = () => /payments/.test(window.location.href);

const isPremiumBookingFlow = () => /\/book\//.test(window.location.href);

/**
 * Checks if we're in a context where we never want to redirect to the account home
 * after login, and instead let the parent components/application context handle
 * routing.
 *
 * This is necessary, as `preventRedirectAfterLogin` isn't always honored
 * (due to our saga setups in `src/sagas/signUp.ts`).
 *
 * TODO: Audit and remove the `redirectAfterLogin` calls from these sagas
 *
 * @returns Whether we are in a context we should never redirect from
 */
function redirectAfterLoginWillBeHandledExternally() {
  return isPremiumBookingFlow();
}

const shouldOnBoardAgain = (accountSummary: any) => {
  const onBoardingDate = safeGet(accountSummary)('onboarding_date');
  const { market } = accountSummary;
  const shouldOnBoardAgain =
    !isEmpty(onBoardingDate) &&
    moment(onBoardingDate) < moment(LaunchDates.DFW) &&
    market === Marketplaces.DFW;
  if (shouldOnBoardAgain) {
    onboardingStartingPoint = WELCOME_FLOW;
  }
  return shouldOnBoardAgain;
};

const shouldOnBoard = (accountSummary: any, isBookingWidgetEmbedded: any, isAfterSignup: any) => {
  if (needsToSeeWelcomeFlow(accountSummary)) {
    onboardingStartingPoint = WELCOME_FLOW;
  } else if (needsToVerifyProfile(accountSummary)) {
    onboardingStartingPoint = ONBOARDING_FLOW;
  }
  return !isBookingWidgetEmbedded && !!onboardingStartingPoint && !isAfterSignup;
};

export const getDefaultAccountRoute = (userProfileId: any) => {
  // this should never happen
  if (!userProfileId) {
    return '/';
  }

  return `${ACCOUNT_HOME}/${userProfileId}/get-care`;
};

const getURLToRedirect = (
  accountSummary: any = null,
  query: any = null,
  redirectToUrl: string | null = null
) => {
  const urlDetails = /^\/account\/login\/(next\/)?(.+)/g.exec(window.location.pathname);

  let path;
  if (redirectToUrl) {
    path = redirectToUrl;
  } else if (urlDetails instanceof Array && urlDetails.length > 2) {
    path = `/${urlDetails[2]}`;
  } else if (accountSummary) {
    const userProfile = getFirstProfileFromAccountSummary(accountSummary);
    path = userProfile
      ? getDefaultAccountRoute(userProfile.user_profile_id)
      : Routes.ACCOUNT_PROFILE_CREATE;
  } else {
    path = '/account/people';
  }
  return isEmptyObject(query) ? path : `${path}?${queryStringFromObject(query)}`;
};

export const redirectAfterLogin = (
  accountSummary: any = null,
  redirectToUrl: string | null = null,
  isBookingWidgetEmbedded = false,
  isAfterSignup = false,
  isRemixRedirect = false
) => {
  if (redirectAfterLoginWillBeHandledExternally()) {
    return null;
  }

  const query = getQueryParams();

  if (isRemixRedirect && redirectToUrl) {
    window.location.href = `${REMIX_APP_URL}${redirectToUrl}`;
    return null;
  }
  const redirectURL = getURLToRedirect(accountSummary, query, redirectToUrl);

  if (query.skip_onboarding) {
    return history.replace(redirectURL);
  }

  if (
    accountSummary &&
    (shouldOnBoard(accountSummary, isBookingWidgetEmbedded, isAfterSignup) ||
      shouldOnBoardAgain(accountSummary)) &&
    !isPaymentsLoginFlow() &&
    !willRedirectToSolvPlusFlowsAfterLogin()
  ) {
    const firstUserProfileId = getFirstProfileIdFromAccountSummary(accountSummary);

    const nextUrl = firstUserProfileId
      ? `/account/welcome/${firstUserProfileId}?f=${onboardingStartingPoint}`
      : Routes.ACCOUNT_PROFILE_CREATE;
    return history.replace(nextUrl);
  }

  return history.replace(redirectURL);
};

/**
 * A user still has to finish sign up if they don't have any user profiles or if the user profiles relate to an account with a currently unverifiable last name
 *
 * @param {Object} accountSummary
 */
export const shouldFinishSignup = (accountSummary: any, verificationStatus: VerificationStatus) =>
  !isEmptyObject(accountSummary) &&
  (isEmptyArray(accountSummary.user_profiles) || getIsStatusUnverifiable(verificationStatus));
