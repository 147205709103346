import { CLINIC_LEAD_FORM_SUCCESS, CLINIC_LEAD_FORM_ERROR } from '../constants/index';

export default function clinicLeadReducer(state = {}, action: any) {
  switch (action.type) {
    case CLINIC_LEAD_FORM_SUCCESS:
      return {
        submitSuccess: true,
        error: null,
      };
    case CLINIC_LEAD_FORM_ERROR:
      return {
        error: action.payload.value,
        submitSuccess: false,
      };
    default:
      return state;
  }
}
