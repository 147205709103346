import moment from 'moment';
import Provider from '@solvhealth/types/interfaces/Provider';
import isEmpty from 'lodash/isEmpty';
import { DAPI_HOST } from '../../config';
import {
  EXTERNAL_SLOT_DATE_FETCH_FORMAT,
  FETCH_SLOTS_INTERVAL_DAYS,
} from '~/components/ProviderGroup/constants';

const PROVIDER_FIELDS: Array<keyof Provider> = [
  'id',
  'locations',
  'first_name',
  'middle_name',
  'last_name',
  'biography',
  'title',
  'image_links',
  'is_pg_solv_partner',
  'specialties',
  'languages',
  'gender',
  'rating_solv',
  'rating_solv_count',
  'rating_non_solv',
  'rating_non_solv_count',
  'top_review_json',
  'rating_wait_time',
  'rating_bedside_manner',
  'rating_staff_friendliness',
  'rating_facility_cleanliness',
  'accepts_new_patients',
  'video_visits_available',
  'partner_location_ids',
];

const SLOTS = 'slots';

const EXTERNAL_PROVIDER_FIELDS = [SLOTS];

const getProviderUrl = (
  providerId: string,
  includeExternal: boolean = false,
  startDate: string = moment().format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
  endDate: string = moment()
    .add(FETCH_SLOTS_INTERVAL_DAYS, 'day')
    .format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
  fields: Array<keyof Provider> = PROVIDER_FIELDS
) => {
  return includeExternal
    ? `${DAPI_HOST}/v1/providers/${providerId}?fields=${[
        ...fields,
        ...EXTERNAL_PROVIDER_FIELDS,
      ].join(',')}&external_slot_start_date=${startDate}&external_slot_end_date=${endDate}`
    : `${DAPI_HOST}/v1/providers/${providerId}?fields=${fields.join(',')}`;
};

const getProvidersUrl = ({
  providerIds,
  specialties,
  groupId,
  locationId,
  fields = PROVIDER_FIELDS,
  startDate = moment().format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
  endDate = moment().add(FETCH_SLOTS_INTERVAL_DAYS, 'day').format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
}: {
  providerIds?: string[];
  specialties?: string[];
  groupId?: string;
  locationId?: string;
  fields?: Array<keyof Provider>;
  startDate?: string;
  endDate?: string;
}) => {
  let url = `${DAPI_HOST}/v2/providers?fields=${fields.join(',')}`;
  if (specialties?.length) {
    url += `&filters=specialties.name:${specialties.join(',')}`;
  }

  if (!isEmpty(groupId)) {
    url += `&group_ids=${[groupId]}`;
  }

  if (!isEmpty(locationId)) {
    url += `&location_ids=${[locationId]}`;
  }

  if (!isEmpty(providerIds)) {
    url += `&provider_ids=${providerIds}`;
  }

  if (fields.includes(SLOTS))
    url += `&external_slot_start_date=${startDate}&external_slot_end_date=${endDate}`;

  // TODO add real pagination logic
  url += '&limit=100';
  return url;
};

const getProviderRatingsUrl = (providerIds: string[], page: number) => {
  const providerIdsString = providerIds.join(',');
  const filtersString = `provider_ids=${providerIdsString}&page=${page}&show_unverified=true&show_only_publicly_viewable=true`;
  return `${DAPI_HOST}/v1/providers/reviews?${filtersString}`;
};

export { PROVIDER_FIELDS, getProviderUrl, getProvidersUrl, getProviderRatingsUrl };
