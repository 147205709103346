import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationFromIp, reverseGeocode } from '~/core/dapi/geoCode';
import { positionError, positionSuccess, setGettingPosition } from '../../actions/position';
import {
  setBrowserLocationAllowed,
  setBrowserLocationDenied,
  setUserLocation,
} from '../../actions/searchPreferences';
import {
  GEOLOCATION_MAXIMUM_AGE_IN_MILLISECONDS,
  GEOLOCATION_TIMEOUT_IN_MILLISECONDS,
} from '../../config/index';
import {
  CONSUMER_APP,
  LOCATION_SOURCE_BROWSER,
  LOCATION_SOURCE_IP_ADDRESS,
} from '../../constants/index';
import { analyticsTrackEvent } from '../../core/analytics';
import {
  CHANGE_LOCATION_SET_LOCATION_PERMISSION,
  IP_GEOLOCATION_RESULTS_RECEIVED,
  LOCATION_SET_BY_USER,
} from '../../core/analytics/events';
import { LOCATION_PERMISSIONS_FAIL, LOCATION_PERMISSIONS_SUCCESS } from '../../core/ios';
import logger from '../../core/logger/index';
import { isMobile, isNativeApp } from '../../core/util/device';
import { NativeFunctions } from '../../core/util/native';
import { PositionRedux } from './interfaces/PositionRedux';
import { isValidPosition } from './util';

export enum PositionTrigger {
  /** Retrieve user's position without browser location */
  Default,
  /** Retrieve user's location only by IP Address */
  IPOnly,
  /** This forcefully updates the user's search location using the browser location, falling back to IP Address */
  AccurateSearch,
}

/**
 * Custom hook for getting the user's location. Returns a function to manually request position.
 * This can be called with different triggers detailed above.
 *
 * @param autoTrigger Position Trigger that is called immediately client side
 * @param timeout Time to wait fore timeout
 * @returns Position from redux and a calback to trigger the request
 */
export function useUserPosition(
  autoTrigger: PositionTrigger = PositionTrigger.Default,
  timeout: number = GEOLOCATION_TIMEOUT_IN_MILLISECONDS
): {
  position: PositionRedux;
  requestPosition: (trigger?: PositionTrigger) => void;
  accuratePositionId: number; // This incremented id is used to track and trigger finished requests for accurate position
} {
  /**
   * Request user's browser or IP location automatically (as soon has hook is invoked)
   */
  const dispatch = useDispatch();
  const reduxPosition: PositionRedux = useSelector((state: any) => state.position);
  const positionWatcher = useRef(0);

  const [accuratePositionId, setAccuratePositionId] = useState(0);

  const finishRequest = useCallback(
    (success: boolean, accurateTrigger: boolean, result?: any) => {
      if (accurateTrigger && success) {
        analyticsTrackEvent(LOCATION_SET_BY_USER, {
          sourceOfInput: CONSUMER_APP,
          isMobile: isMobile(),
        });
        dispatch(setUserLocation(result));
      }
      if (result) {
        dispatch(success ? positionSuccess(result) : positionError(result));
      }
      dispatch(setGettingPosition(false));

      if (accurateTrigger) {
        setAccuratePositionId((prev) => {
          return prev + 1;
        });
      }
    },
    [dispatch]
  );

  const reverseGeocodeAndDispatch = useCallback(
    (position: { latitude: string; longitude: string; source: string }) => {
      (async () => {
        try {
          const reverseGeocodeResult = await reverseGeocode(position.latitude, position.longitude, {
            fallbackToGoogle: true,
          });
          const updatedPosition = {
            label: 'Current Location',
            ...position,
            ...reverseGeocodeResult,
          };
          finishRequest(true, true, updatedPosition);
        } catch (e) {
          const updatedPosition = {
            ...position,
            label: 'Current Location',
          };
          finishRequest(true, true, updatedPosition);
        }
      })();
    },
    [finishRequest]
  );

  const getIpLocation = useCallback(
    (accuratePosition: boolean) => {
      getLocationFromIp({ fallbackToGoogle: true })
        .then((res) => {
          analyticsTrackEvent(IP_GEOLOCATION_RESULTS_RECEIVED);
          finishRequest(true, accuratePosition, { ...res, source: LOCATION_SOURCE_IP_ADDRESS });
        })
        .catch((err) => {
          finishRequest(false, accuratePosition, { err, source: LOCATION_SOURCE_IP_ADDRESS });
        });
    },
    [finishRequest]
  );

  const handleBrowserLocationSuccess = useCallback(
    (result: any) => {
      navigator.geolocation.clearWatch(positionWatcher.current);

      // last minute validate
      if (!result?.coords?.latitude || !result?.coords?.longitude) {
        getIpLocation(true);
        return;
      }

      analyticsTrackEvent(CHANGE_LOCATION_SET_LOCATION_PERMISSION, {
        value: 'Allow',
      });

      const position = {
        latitude: result?.coords?.latitude,
        longitude: result?.coords?.longitude,
        source: LOCATION_SOURCE_BROWSER,
      };
      reverseGeocodeAndDispatch(position);
      dispatch(setBrowserLocationAllowed());
    },
    [reverseGeocodeAndDispatch, dispatch, getIpLocation]
  );

  const handleBrowserLocationError = useCallback(
    (error: any) => {
      analyticsTrackEvent(CHANGE_LOCATION_SET_LOCATION_PERMISSION, {
        value: "Don't Allow",
        details: error,
      });

      navigator.geolocation.clearWatch(positionWatcher.current);
      dispatch(setBrowserLocationDenied());

      getIpLocation(true);
    },
    [dispatch, getIpLocation]
  );

  const iosRequestLocationPermission = useCallback(
    (retryCount = 0) => {
      const requestLocation = NativeFunctions.requestLocationPermission;
      if (requestLocation.exists) {
        requestLocation.call();
      } else if (retryCount < 10) {
        setTimeout(iosRequestLocationPermission, 100, retryCount + 1);
      } else {
        getIpLocation(false);
        logger.error(
          new Error(`Couldn't request ios location after ${retryCount} tries at 100ms interval`)
        );
      }
    },
    [getIpLocation]
  );

  const handleIosMessages = useCallback(
    (event: any) => {
      if (event && event.data) {
        let data: any;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          return;
        }
        const { action, params = {} } = data;
        switch (action) {
          case LOCATION_PERMISSIONS_SUCCESS: {
            const { position: iosPosition } = params;
            handleBrowserLocationSuccess(iosPosition);
            break;
          }
          case LOCATION_PERMISSIONS_FAIL: {
            const { error } = params;
            handleBrowserLocationError(error);
            break;
          }
          default:
            break;
        }
      }
    },
    [handleBrowserLocationSuccess, handleBrowserLocationError]
  );

  const requestLocationPermission = useCallback(() => {
    // @ts-ignore - getCurrentPosition claims to return void but really returns a reference
    positionWatcher.current = navigator.geolocation.getCurrentPosition(
      handleBrowserLocationSuccess,
      handleBrowserLocationError,
      {
        timeout: timeout || GEOLOCATION_TIMEOUT_IN_MILLISECONDS,
        maximumAge: GEOLOCATION_MAXIMUM_AGE_IN_MILLISECONDS,
      }
    );
  }, [handleBrowserLocationSuccess, handleBrowserLocationError, timeout]);

  useEffect(() => {
    if (isNativeApp()) {
      window.addEventListener('message', handleIosMessages);
      return () => window.removeEventListener('message', handleIosMessages);
    }

    if (navigator && navigator.geolocation) {
      return () => navigator.geolocation.clearWatch(positionWatcher.current);
    }
    return undefined;
  }, [handleIosMessages]);

  const requestPosition = useCallback(
    (trigger: PositionTrigger = PositionTrigger.Default) => {
      dispatch(setGettingPosition(true));

      const accuratePosition = trigger === PositionTrigger.AccurateSearch;

      if (accuratePosition) {
        dispatch(setUserLocation({ label: 'Finding your location...' }));
        const requestBrowserLocation = navigator && navigator.geolocation;

        if (requestBrowserLocation) {
          requestLocationPermission();
          return;
        }
        getIpLocation(true);
        return;
      }

      const hasValidPosition = isValidPosition(reduxPosition?.result);

      if (trigger === PositionTrigger.IPOnly || isNativeApp()) {
        if (hasValidPosition) {
          finishRequest(true, false);
          return;
        }
        trigger === PositionTrigger.IPOnly ? getIpLocation(false) : iosRequestLocationPermission();
        return;
      }

      if (hasValidPosition) {
        finishRequest(true, false);
        return;
      }
      getIpLocation(false);
      return;
    },
    [
      dispatch,
      finishRequest,
      getIpLocation,
      iosRequestLocationPermission,
      reduxPosition,
      requestLocationPermission,
    ]
  );

  // Get user's location automatically client side
  useEffect(() => {
    requestPosition(autoTrigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoTrigger]);

  return { position: reduxPosition, requestPosition, accuratePositionId };
}

export default useUserPosition;
