import {
  NPI_CHECK_RECEIVED,
  NPI_CHECK_ERROR,
  NPI_CHECK_SUBMITTED,
  NPI_CLEAR_SUBMISSION,
} from '../constants/index';

const npiCheckSubmitted = (value: any) => ({
  type: NPI_CHECK_SUBMITTED,
  payload: { value },
});

const npiCheckReceived = (value: any) => {
  const action = {
    type: NPI_CHECK_RECEIVED,
    payload: {},
  };
  action.payload = value;
  return action;
};

const npiCheckError = (value: any) => ({
  type: NPI_CHECK_ERROR,
  payload: { value },
});

const npiClearSubmission = (value: any) => ({
  type: NPI_CLEAR_SUBMISSION,
  payload: { value },
});

export { npiCheckReceived, npiCheckError, npiCheckSubmitted, npiClearSubmission };
