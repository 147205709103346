import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { colors } from '../../../constants/colors';
import { fontFamily } from '../../../constants/text';

// pulling palette out into its own theme so that constants can be accessed in the base and default theme
// put palette adjustments here in this paletteTheme plz
const paletteTheme = createMuiTheme({
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      main: colors.crunchBerry,
    },
    secondary: {
      main: colors.gray600,
    },
    error: {
      main: colors.cherry,
    },
    grey: {
      100: colors.gray100,
      200: colors.gray200,
      300: colors.gray300,
      400: colors.gray400,
      500: colors.gray500,
      600: colors.gray600,
    },
    text: {
      primary: colors.gray600,
    },
  },
  typography: {
    htmlFontSize: 18,
  },
});

const baseTheme = createMuiTheme({
  ...paletteTheme,
  shadows: [
    'none',
    '0px 1px 4px rgba(0, 0, 0, 0.16)', // shadow 1
    '0px 2px 8px rgba(0, 0, 0, 0.16)', // shadow 2
    '0px 4px 16px rgba(0, 0, 0, 0.16)', // shadow 3
    '0px 8px 24px rgba(0, 0, 0, 0.16)', // shadow 4
    // material ui literally requires there to be 25 shadow variants. we're only specifying 4 bc we're not maniacs.
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: fontFamily.regular,
    h1: {
      fontFamily: fontFamily.bold,
      fontSize: paletteTheme.typography.pxToRem(28),
      lineHeight: paletteTheme.typography.pxToRem(36),
      color: paletteTheme.palette.secondary.main,
      [paletteTheme.breakpoints.up('sm')]: {
        fontSize: paletteTheme.typography.pxToRem(32),
        lineHeight: paletteTheme.typography.pxToRem(40),
      },
    },
    h2: {
      fontFamily: fontFamily.bold,
      fontSize: paletteTheme.typography.pxToRem(24),
      lineHeight: paletteTheme.typography.pxToRem(32),
      color: paletteTheme.palette.secondary.main,
    },
    h3: {
      fontFamily: fontFamily.bold,
      fontSize: paletteTheme.typography.pxToRem(20),
      lineHeight: paletteTheme.typography.pxToRem(28),
      color: paletteTheme.palette.secondary.main,
    },
    h4: {
      fontFamily: fontFamily.bold,
      fontSize: paletteTheme.typography.pxToRem(16),
      lineHeight: paletteTheme.typography.pxToRem(24),
      color: paletteTheme.palette.secondary.main,
    },
    h5: {
      fontFamily: fontFamily.bold,
      fontSize: paletteTheme.typography.pxToRem(14),
      lineHeight: paletteTheme.typography.pxToRem(20),
      color: paletteTheme.palette.secondary.main,
    },
    subtitle1: {
      fontFamily: fontFamily.medium,
      fontSize: paletteTheme.typography.pxToRem(16),
      lineHeight: paletteTheme.typography.pxToRem(24),
      color: paletteTheme.palette.secondary.main,
    },
    subtitle2: {
      fontFamily: fontFamily.medium,
      fontSize: paletteTheme.typography.pxToRem(14),
      lineHeight: paletteTheme.typography.pxToRem(20),
      color: paletteTheme.palette.secondary.main,
    },
    body1: {
      fontFamily: fontFamily.regular,
      fontSize: paletteTheme.typography.pxToRem(16),
      lineHeight: paletteTheme.typography.pxToRem(24),
      color: paletteTheme.palette.secondary.main,
    },
    body2: {
      fontFamily: fontFamily.regular,
      fontSize: paletteTheme.typography.pxToRem(14),
      lineHeight: paletteTheme.typography.pxToRem(20),
      color: paletteTheme.palette.secondary.main,
    },
    caption: {
      fontFamily: fontFamily.regular,
      fontSize: paletteTheme.typography.pxToRem(12),
      lineHeight: paletteTheme.typography.pxToRem(16),
      color: paletteTheme.palette.grey['500'],
    },
  },
});

const solvTheme = createMuiTheme({
  ...baseTheme,
  overrides: {
    MuiFormLabel: {
      root: {
        ...baseTheme.typography.subtitle2,
        '&$focused': {
          color: baseTheme.palette.secondary.main,
        },
        '&$error': {
          color: baseTheme.palette.secondary.main,
        },
        '&$disabled': {
          color: paletteTheme.palette.grey['300'],
        },
        marginBottom: baseTheme.typography.pxToRem(8),
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: fontFamily.bold,
        fontSize: baseTheme.typography.pxToRem(20),
        lineHeight: baseTheme.typography.pxToRem(20),
        color: baseTheme.palette.secondary.main,
        paddingTop: 0,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '8px',
        backgroundColor: paletteTheme.palette.common.white,
        boxShadow: baseTheme.shadows[1],
      },
      elevation1: {
        boxShadow: baseTheme.shadows[1],
      },
      elevation2: {
        boxShadow: baseTheme.shadows[2],
      },
      rounded: {
        borderRadius: '0.5rem',
      },
    },
    MuiFormHelperText: {
      root: {
        ...baseTheme.typography.subtitle2,
      },
    },
    MuiSelect: {
      icon: {
        paddingRight: baseTheme.typography.pxToRem(12),
      },
      iconOpen: {
        marginRight: baseTheme.typography.pxToRem(12),
      },
      select: {
        fontSize: baseTheme.typography.pxToRem(14),
        paddingTop: baseTheme.typography.pxToRem(4),
      },
      selectMenu: {
        fontSize: baseTheme.typography.pxToRem(16),
        height: baseTheme.typography.pxToRem(56),
        color: baseTheme.palette.secondary.main,
        paddingLeft: baseTheme.typography.pxToRem(16),
        paddingTop: baseTheme.typography.pxToRem(20),
        paddingBottom: baseTheme.typography.pxToRem(20),
        boxSizing: 'border-box',
        '&:focus': {
          borderRadius: 8,
        },
      },
    },
    MuiInputBase: {
      root: {
        height: baseTheme.typography.pxToRem(56),
        width: baseTheme.typography.pxToRem(344),
        '&$disabled': {
          color: colors.disgraybled,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        width: '100%',
        '&$focused $notchedOutline': {
          borderColor: baseTheme.palette.secondary.main,
        },
      },
    },
    MuiMenuItem: {
      root: {
        ...baseTheme.typography.body1,
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        '&:hover': {
          backgroundColor: paletteTheme.palette.grey['100'],
        },
        '&$selected': {
          backgroundColor: paletteTheme.palette.grey['200'],
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: baseTheme.typography.pxToRem(8),
        width: 'fit-content',
        height: baseTheme.typography.pxToRem(25),
      },
      label: {
        ...baseTheme.typography.subtitle2,
        padding: 0,
        color: 'inherit',
      },
      icon: {
        marginLeft: baseTheme.typography.pxToRem(12),
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        fontFamily: fontFamily.bold,
        letterSpacing: '0',
        borderRadius: baseTheme.typography.pxToRem(8),
        '&$disabled': {
          backgroundColor: baseTheme.palette.grey['200'],
          color: baseTheme.palette.grey['300'],
          border: 'none',
        },
      },
      label: {
        fontSize: baseTheme.typography.pxToRem(16),
        lineHeight: baseTheme.typography.pxToRem(24),
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: baseTheme.palette.primary.main,
        color: paletteTheme.palette.common.white,
        '&hover': {
          backgroundColor: colors.crunchBerryMedium,
          color: baseTheme.palette.grey['300'],
          border: 'none',
        },
      },
      contained: {
        paddingTop: baseTheme.typography.pxToRem(12),
        paddingBottom: baseTheme.typography.pxToRem(12),
        paddingLeft: baseTheme.typography.pxToRem(20),
        paddingRight: baseTheme.typography.pxToRem(20),
      },
      outlined: {
        paddingTop: baseTheme.typography.pxToRem(12),
        paddingBottom: baseTheme.typography.pxToRem(12),
        paddingLeft: baseTheme.typography.pxToRem(20),
        paddingRight: baseTheme.typography.pxToRem(20),
      },
      outlinedPrimary: {
        '&hover': {
          backgroundColor: colors.crunchBerryXLight,
          color: baseTheme.palette.primary.main,
          border: `1px solid ${baseTheme.palette.primary.main}`,
        },
        '&$disabled': {
          backgroundColor: baseTheme.palette.common.white,
          color: baseTheme.palette.grey['300'],
          border: `1px solid ${baseTheme.palette.grey['300']}`,
        },
      },
      outlinedSecondary: {
        fontFamily: fontFamily.bold,
        border: `1px solid ${baseTheme.palette.grey['400']}`,
      },
      textSecondary: {
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      sizeSmall: {
        fontSize: baseTheme.typography.pxToRem(16),
        paddingTop: baseTheme.typography.pxToRem(8),
        paddingBottom: baseTheme.typography.pxToRem(8),
        paddingLeft: baseTheme.typography.pxToRem(16),
        paddingRight: baseTheme.typography.pxToRem(16),
      },
      sizeLarge: {
        paddingTop: baseTheme.typography.pxToRem(16),
        paddingBottom: baseTheme.typography.pxToRem(16),
        paddingLeft: baseTheme.typography.pxToRem(24),
        paddingRight: baseTheme.typography.pxToRem(24),
      },
      textPrimary: {
        '&:focus': {
          color: paletteTheme.palette.primary.main,
          border: `2px solid ${baseTheme.palette.grey['600']}`,
        },
        '&:hover': {
          color: colors.crunchBerryMedium,
        },
        '&:disabled': {
          color: colors.crunchBerryLight,
        },
      },
    },
    MuiSnackbar: {
      root: {
        backgroundColor: paletteTheme.palette.common.white,
        color: baseTheme.palette.grey['600'],
        fontFamily: fontFamily.regular,
        [baseTheme.breakpoints.down('sm')]: {
          width: '90%',
          margin: '0 auto',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: paletteTheme.palette.common.white,
        color: baseTheme.palette.grey['600'],
        fontFamily: fontFamily.regular,
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
        fontSize: baseTheme.typography.pxToRem(14),
        lineHeight: baseTheme.typography.pxToRem(20),
      },
      message: {
        width: '80%',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: baseTheme.shadows[2],
      },
    },
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

export default solvTheme;
