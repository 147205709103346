import { TextField } from '@solvhealth/jigsaw';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchSorter } from 'match-sorter';
import { v4 as uuid } from 'uuid';
import { queryStringFromObject } from '~/core/util/string';
import { addAddressValidationLog } from '~/ducks/paperwork';
import { useSolvSelector } from '~/reducers';
import { DAPI_HOST } from '../../../../config';
import { apiGetJson } from '../../../../core/dapi';
import { PaperworkFieldName } from '../../paperworkConstants';
import { SolvAutocomplete } from '../Payment/SolvAutocomplete';
import { useAddressValidationSessionTokenContext } from './AddressValidationSessionTokenContext';

type AddressAutocompleteResult = {
  placeId: string;
  description: string;
};

type AddressAutocompleteResults = AddressAutocompleteResult[];

export type Address = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};

export type AddressValidationLog = {
  correction_context: 'booking-widget' | 'paperwork';
  correction_type: 'subpremise' | 'fixed-typos' | 'selected-autocomplete';
  validation_successful: boolean;
};

export const AddressAutocomplete = (props: any) => {
  const { form, initialValue, field, errorLabel, ...rest } = props;

  const [autofillOptions, setAutofillOptions] = useState([] as AddressAutocompleteResults);

  const sessionToken = useAddressValidationSessionTokenContext();

  const location = useSolvSelector((state) => state.location);
  const dispatch = useDispatch();
  const { t } = useTranslation('paperwork');

  const currentAddress = useMemo(() => {
    return form.getFieldValue(PaperworkFieldName.PATIENT_ADDRESS_STREET) ?? initialValue;
  }, [form, initialValue]);

  // eslint doesn't understand `debounce`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(async (addressInProgress) => {
      if (addressInProgress) {
        const result = await apiGetJson<AddressAutocompleteResults>(
          `${DAPI_HOST}/v1/address/location/${location.id}/autocomplete?${queryStringFromObject({
            address_input: addressInProgress,
            location_id: location.id,
            session_token: sessionToken,
          })}`,
          {
            camelCase: true,
          }
        );
        if (result?.data) {
          setAutofillOptions(result.data);
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedUpdate(currentAddress);
  }, [currentAddress, debouncedUpdate]);

  const handleChange = (val: string) => {
    form.setFields({ [PaperworkFieldName.PATIENT_ADDRESS_STREET]: { value: val, touched: true } });
  };

  const setAddressDetails = async (value: AddressAutocompleteResult) => {
    const { placeId } = value;
    if (placeId) {
      const val = await apiGetJson<Address>(
        `${DAPI_HOST}/v1/address/location/${
          location.id
        }/autocomplete/details/${placeId}?${queryStringFromObject({
          session_token: sessionToken,
        })}`
      );
      if (val?.data) {
        const { address1, city, state, zip } = val.data;
        form.setFields({
          [PaperworkFieldName.PATIENT_ADDRESS_STREET]: {
            value: address1,
            touched: true,
          },
          [PaperworkFieldName.PATIENT_ADDRESS_CITY]: {
            value: city,
            touched: true,
          },
          [PaperworkFieldName.PATIENT_ADDRESS_STATE]: {
            value: state,
            touched: true,
          },
          [PaperworkFieldName.PATIENT_ADDRESS_ZIP]: {
            value: zip,
            touched: true,
          },
        });
      }
    }
  };

  /**
   * Use matchSorter to filter the address options so that, for example, "123 Street Ave San Francisco" can map to "123 Street Ave, San Francisco"
   */
  const filterOptions = (options: string[], { inputValue }: { inputValue: string }) =>
    matchSorter(options, inputValue, { keys: ['description'] });

  return (
    <SolvAutocomplete
      {...form.getFieldProps(field.field_name, {
        rules: [
          {
            required: field.is_required_field,
            message: t(['common.required', 'common.requiredShort'], {
              FORM_FIELD_NAME: errorLabel,
            }),
          },
        ],
        initialValue,
      })}
      autoHighlight
      filterOptions={filterOptions}
      freeSolo
      getOptionLabel={(option: AddressAutocompleteResult) => option?.description ?? ''}
      inputValue={currentAddress ?? ''}
      onBlur={() => form.validateFields([PaperworkFieldName.PATIENT_ADDRESS_STREET])}
      onChange={(event, newValue) => {
        setAddressDetails(newValue as AddressAutocompleteResult);
        dispatch(
          addAddressValidationLog({
            correction_context: 'paperwork',
            correction_type: 'selected-autocomplete',
            validation_successful: true,
          })
        );
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason !== 'reset') {
          handleChange(newValue);
        }
      }}
      options={autofillOptions}
      renderTextField={(textFieldProps) => (
        <TextField
          {...textFieldProps}
          {...rest}
          aria-required={field.is_required_field ? 'true' : 'false'}
          data-hj-suppress
          dense
          key={PaperworkFieldName.PATIENT_ADDRESS_STREET}
        />
      )}
    />
  );
};
