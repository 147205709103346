import { PAYMENT_FULFILLED, PAYMENT_PENDING } from '../../constants/payment';
import { isEmptyString } from '../../core/util/string';
import { isEmptyObject, safeGet } from '../../core/util/object';
import { INVOICE_STATUS_PAID } from '../../constants/index';

const isInvoicePending = (status: any) => status === PAYMENT_PENDING;
const isInvoiceFulfilled = (status: any) => status === PAYMENT_FULFILLED;
const hasInvoice = (booking: any) => !isEmptyObject(booking) && !isEmptyString(booking.invoice_id);

const isPaymentMethodAddedForCurrentBooking = (booking: any) =>
  safeGet(booking, false)('isLastPaymentAddedLessThanWeekBeforeBooking') ||
  safeGet(booking, false)('is_last_payment_added_less_than_week_before_booking');

// faith can allow a patient to bypass payment from the facesheet modal in the queue
const isPaymentBypassed = (booking: any) =>
  safeGet(booking, false)('isPaymentBypassed') || safeGet(booking, false)('is_payment_bypassed');

export {
  isPaymentMethodAddedForCurrentBooking,
  isInvoicePending,
  isInvoiceFulfilled,
  hasInvoice,
  isPaymentBypassed,
};
