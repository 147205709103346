import { PaperworkFieldName, ProviderGroupPaperworkFieldName } from '../../../paperworkConstants';
import { SectionPageCollection } from '../../../interfaces';

export enum PatientPageNumber {
  BasicInfo,
  Address,
  Guardian,
  Language,
  LanguageOther,
  Gender,
  Pronouns,
  Race,
  Ethnicity,
}

export const PATIENT_PAGES: SectionPageCollection = new Map([
  [
    PatientPageNumber.BasicInfo,
    {
      fieldNames: [
        PaperworkFieldName.PATIENT_FIELD_FIRST_NAME,
        PaperworkFieldName.PATIENT_FIELD_MIDDLE_NAME,
        PaperworkFieldName.PATIENT_FIELD_LAST_NAME,
        PaperworkFieldName.PATIENT_FIELD_BIRTH_SEX,
        PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE,
        PaperworkFieldName.PATIENT_PRIMARY_PHONE,
        PaperworkFieldName.PATIENT_EMAIL,
      ],
    },
  ],
  [
    PatientPageNumber.Address,
    {
      fieldNames: [
        PaperworkFieldName.PATIENT_ADDRESS_STREET,
        PaperworkFieldName.PATIENT_ADDRESS_STREET_TWO,
        PaperworkFieldName.PATIENT_ADDRESS_CITY,
        PaperworkFieldName.PATIENT_ADDRESS_STATE,
        PaperworkFieldName.PATIENT_ADDRESS_ZIP,
      ],
    },
  ],
  [
    PatientPageNumber.Guardian,
    {
      fieldNames: [
        PaperworkFieldName.GUARDIAN_FIRST_NAME,
        PaperworkFieldName.GUARDIAN_LAST_NAME,
        PaperworkFieldName.GUARDIAN_BIRTH_DATE,
        PaperworkFieldName.GUARDIAN_PHONE,
        PaperworkFieldName.GUARDIAN_ADDRESS,
        ProviderGroupPaperworkFieldName.GUARDIAN_ADDRESS_TWO,
        ProviderGroupPaperworkFieldName.GUARDIAN_CITY,
        ProviderGroupPaperworkFieldName.GUARDIAN_STATE,
        ProviderGroupPaperworkFieldName.GUARDIAN_ZIP,
      ],
    },
  ],
  [
    PatientPageNumber.Language,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.LANGUAGE],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    PatientPageNumber.LanguageOther,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.LANGUAGE_OTHER],
    },
  ],
  [
    PatientPageNumber.Gender,
    {
      fieldNames: [PaperworkFieldName.PATIENT_FIELD_GENDER],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    PatientPageNumber.Pronouns,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.PREFERRED_PRONOUNS],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    PatientPageNumber.Race,
    {
      fieldNames: [PaperworkFieldName.PATIENT_FIELD_RACE],
      showSubmit: false,
      allowSkip: true,
    },
  ],
  [
    PatientPageNumber.Ethnicity,
    {
      fieldNames: [PaperworkFieldName.PATIENT_FIELD_ETHNICITY],
      showSubmit: false,
      allowSkip: true,
    },
  ],
]);
