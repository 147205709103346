import {
  ELIGIBILITY_CHECK_RECEIVED,
  ELIGIBILITY_CHECK_ERROR,
  ELIGIBILITY_CHECK_SUBMITTED,
} from '../constants/index';

export default function eligibilityCheckReducer(state = {}, action: any) {
  switch (action.type) {
    case ELIGIBILITY_CHECK_SUBMITTED:
      return {
        ...state,
        error: null,
        submitting: true,
      };
    case ELIGIBILITY_CHECK_RECEIVED:
      return {
        ...state,
        result: {
          ...action.payload,
        },
        error: null,
        submitting: false,
      };
    case ELIGIBILITY_CHECK_ERROR:
      return {
        ...state,
        error: action.payload.value,
        submitting: false,
      };
    default:
      return state;
  }
}
