const CREATED_WEB_PUSH_SUBSCRIPTION_RECEIVED =
  'webPushSubscriptions/CREATED_WEB_PUSH_SUBSCRIPTION_RECEIVED';
const WEB_PUSH_SUBSCRIPTIONS_ERROR = 'webPushSubscriptions/ERROR';
const WEB_PUSH_SUBSCRIPTIONS_SUBMITTING = 'webPushSubscriptions/SUBMITTING';
const WEB_PUSH_SUBSCRIPTIONS_FETCHING = 'webPushSubscriptions/FETCHING';
const FETCH_WEB_PUSH_SUBSCRIPTION_RECEIVED =
  'webPushSubscriptions/FETCH_WEB_PUSH_SUBSCRIPTION_RECEIVED';
const DELETE_WEB_PUSH_SUBCRIPTION_RECEIVED =
  'webPushSubscriptions/DELETE_WEB_PUSH_SUBCRIPTION_RECEIVED';

export const webPushSubscriptionFetching = (value: any) => ({
  type: WEB_PUSH_SUBSCRIPTIONS_FETCHING,
  payload: {},
});

export const submitWebPushSubscription = (value: any) => ({
  type: WEB_PUSH_SUBSCRIPTIONS_SUBMITTING,
  payload: {},
});

export const fetchWebPushSubscriptionReceived = (value: any) => ({
  type: FETCH_WEB_PUSH_SUBSCRIPTION_RECEIVED,
  payload: { value },
});

export const createdWebPushSubscriptionReceived = (value: any) => ({
  type: CREATED_WEB_PUSH_SUBSCRIPTION_RECEIVED,
  payload: { value },
});

export const deleteWebPushSubscriptionReceived = (value: any) => ({
  type: DELETE_WEB_PUSH_SUBCRIPTION_RECEIVED,
  payload: { value },
});

export const webPushSubscriptionsError = (value: any) => ({
  type: WEB_PUSH_SUBSCRIPTIONS_ERROR,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_WEB_PUSH_SUBSCRIPTION_RECEIVED:
    case CREATED_WEB_PUSH_SUBSCRIPTION_RECEIVED:
      return {
        ...state,
        result: action.payload.value,
        error: null,
        submitting: false,
        fetching: false,
      };
    case DELETE_WEB_PUSH_SUBCRIPTION_RECEIVED:
      return {
        ...state,
        result: null,
        error: null,
        submitting: false,
        fetching: false,
      };
    case WEB_PUSH_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
        submitting: false,
        fetching: false,
      };
    case WEB_PUSH_SUBSCRIPTIONS_FETCHING:
      return {
        ...state,
        result: null,
        error: null,
        fetching: true,
        submitting: false,
      };
    case WEB_PUSH_SUBSCRIPTIONS_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    default:
      return state;
  }
};
