import Account from '@solvhealth/types/interfaces/Account';
import Booking from '@solvhealth/types/interfaces/Booking';
import cloneDeep from 'lodash/cloneDeep';
import { UserProfile } from '../core/dapi/models/UserProfile';
import {
  ACCOUNT_ADD_SAVED_LOCATION,
  ACCOUNT_BOOKING_ERROR,
  ACCOUNT_CLEAR_SUMMARY,
  ACCOUNT_ERROR,
  ACCOUNT_MARK_MESSAGES_READ,
  ACCOUNT_RECEIVE_BOOKING,
  ACCOUNT_RECEIVE_USER_PROFILE,
  ACCOUNT_REMOVE_SAVED_LOCATION,
  ACCOUNT_REPLACE_SAVED_LOCATION,
  ACCOUNT_SUMMARY_ERROR,
  ACCOUNT_SUMMARY_LOADING,
  ACCOUNT_TRACK_INTERACTION,
  ACCOUNT_USER_PROFILE_ERROR,
  ACCOUNT_USER_PROFILE_PROCESSING,
  ADD_ACTIVE_ACCOUNT_PROFILE,
  BOOKING_RESPONSE_RECEIVED,
  BOOKING_TOKBOX_RATING_SUBMITTED,
  CLEAR_INSURANCE_PROFILE,
  FAVORITE_RECEIVED,
  FETCH_USER_PROFILE_APPOINTMENT_PREFERENCES,
  FETCH_USER_PROFILE_SPECIALTY_PREFERENCES,
  FETCHING_ACCOUNT_APPOINTMENTS,
  GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS,
  GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS,
  LOGIN_RESET_LOGIN_DATA,
  PAST_APPOINTMENTS_ERROR,
  PAST_APPOINTMENTS_RECEIVED,
  RECEIVE_ACCOUNT,
  RECEIVE_ACCOUNT_SUMMARY,
  RECEIVE_INSURANCE_PROFILE,
  RECEIVE_USER_PROFILE_APPOINTMENT_PREFERENCES,
  RECEIVE_USER_PROFILE_SPECIALTY_PREFERENCES,
  SET_APPOINTMENT_VIEW,
  SET_SMS_CONSENT,
  UPCOMING_APPOINTMENT_CANCELLED,
  UPCOMING_APPOINTMENT_RESCHEDULED,
  UPCOMING_APPOINTMENTS_ERROR,
  UPCOMING_APPOINTMENTS_RECEIVED,
  UPCOMING_NEW_BOOKING,
  UPDATE_INSURANCE_PROFILE,
  USER_PROFILE_APPOINTMENT_PREFERENCES_ERROR,
  USER_PROFILE_SPECIALTY_PREFERENCES_ERROR,
} from '../constants/index';
import { deNormalizeMarketPlace, filterHiddenProfiles } from '../core/util/account';
import { EMPTY_OBJECT, isEmptyObject, safeGet } from '../core/util/object';

export type AccountSummary = Account & {
  account_favorite_locations: string[];
  app_interactions: any[];
  bookings: Booking[];
  insurance_profiles: any[];
  iterable_fields: {
    [key: string]: any;
  };
  user_profiles: UserProfile[];
  stripe_customer_id?: string;
  membership_account_id: number;
  is_active_membership: boolean;
  triage_manually_enabled?: boolean;
  solv_employee: boolean;
};

export type AccountState = Partial<{
  summary: AccountSummary;
  fullSummary: AccountSummary;
  accountSummaryError: boolean;
  accountSummaryLoading: boolean;
  accountAppointmentsFetching: boolean;
  upcomingAppointments: any[];
  pastAppointments: any[];
  upcomingAppointmentsError: string;
  pastAppointmentsError: string;
  booking: Booking;
  bookingError: null | string;
  isTokBoxRatingSubmitted: boolean;
}>;

/**
 * @returns account state
 */
export default function accountsReducer(state: AccountState = {}, action: any) {
  switch (action.type) {
    case RECEIVE_ACCOUNT:
      return {
        ...state,
        account: { ...action.payload.value },
        accountError: null,
      };
    case ACCOUNT_ERROR:
      return {
        ...state,
        account: null,
        accountSummaryLoading: false,
        accountError: { ...action.payload.value },
      };
    case RECEIVE_ACCOUNT_SUMMARY: {
      let summary = filterHiddenProfiles(action.payload.value);
      summary = deNormalizeMarketPlace(summary);
      return {
        ...state,
        summary,
        fullSummary: { ...action.payload.value },
        accountSummaryError: null,
        accountSummaryLoading: false,
      };
    }

    case ACCOUNT_SUMMARY_LOADING:
      return {
        ...state,
        summary: null,
        accountSummaryError: null,
        accountSummaryLoading: true,
      };
    case CLEAR_INSURANCE_PROFILE: {
      const { summary } = state;
      if (!summary) return state;

      const id = action.payload.value;
      if (id)
        for (let i = 0; i < summary.insurance_profiles.length; ++i) {
          if (summary.insurance_profiles[i].insurance_profile_id === id) {
            summary.insurance_profiles.splice(i, 1);
            break;
          }
        }
      else summary.insurance_profiles = [];
      return state;
    }
    case BOOKING_RESPONSE_RECEIVED:
    case ACCOUNT_CLEAR_SUMMARY:
      return {
        ...state,
        summary: null,
        accountSummaryError: null,
        accountSummaryLoading: null,
      };
    case ACCOUNT_SUMMARY_ERROR:
      return {
        ...state,
        summary: null,
        accountSummaryError: action.payload.value,
        accountSummaryLoading: false,
      };
    case ACCOUNT_RECEIVE_USER_PROFILE: {
      if (isEmptyObject(state.summary)) {
        return state;
      }

      const newSummary = {
        ...state.summary,
        user_profiles: [...(state.summary?.user_profiles ?? [])],
      };
      const newProfile = action.payload.value;
      let profileExistsInSummary = false;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'summary' does not exist on type '{}'.
      state.summary.user_profiles.forEach((profile: any, i: any) => {
        if (profile.user_profile_id === newProfile.id) {
          newSummary.user_profiles[i] = {
            ...newSummary.user_profiles[i],
            ...newProfile,
          };
          profileExistsInSummary = true;
        }

        return profileExistsInSummary;
      });
      if (!profileExistsInSummary) {
        newProfile.user_profile_id = newProfile.id;
        newProfile.location_favorites = [];
        newProfile.insurance_favorites = [];
        newProfile.insurance_favorites = [];
        newProfile.bookings = { total: 0 };
        newSummary.user_profiles.push(newProfile);
      }

      const newFullSummary = {
        ...state.fullSummary,
        user_profiles: [...newSummary.user_profiles],
      };

      return {
        ...state,
        fullSummary: newFullSummary,
        summary: newSummary,
        userProfile: { ...newProfile },
        userProfileError: null,
        userProfileProcessing: false,
      };
    }

    case RECEIVE_INSURANCE_PROFILE: {
      let newSummary = { ...state.summary } as Partial<AccountSummary>;
      if (state.summary && Array.isArray(state.summary.insurance_profiles))
        newSummary.insurance_profiles?.push(action.payload.value);
      else newSummary.insurance_profiles = [action.payload.value];

      return {
        ...state,
        summary: newSummary,
      };
    }

    case UPDATE_INSURANCE_PROFILE: {
      let newSummary = { ...state.summary } as Partial<AccountSummary>;
      if (state.summary && Array.isArray(state.summary.insurance_profiles)) {
        const insuranceProfileIndexToReplace = state.summary.insurance_profiles.findIndex(
          (profile: any) => profile.insurance_profile_id === action.payload.value.id
        );
        if (insuranceProfileIndexToReplace < 0) {
          newSummary.insurance_profiles?.push(action.payload.value);
        } else {
          const updatedInsuranceProfile = {
            ...action.payload.value,
            insurance_profile_id: action.payload.value.id,
          };
          newSummary.insurance_profiles?.splice(
            insuranceProfileIndexToReplace,
            1,
            updatedInsuranceProfile
          );
        }
      } else {
        newSummary.insurance_profiles = [action.payload.value];
      }

      return {
        ...state,
        summary: newSummary,
      };
    }

    case ACCOUNT_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfile: null,
        userProfileError: { ...action.payload.value },
        userProfileProcessing: false,
      };
    case ACCOUNT_USER_PROFILE_PROCESSING:
      return {
        ...state,
        userProfile: null,
        userProfileError: null,
        userProfileProcessing: true,
      };
    case ACCOUNT_RECEIVE_BOOKING:
      return {
        ...state,
        booking: { ...action.payload.value },
        bookingError: null,
      };
    case ACCOUNT_BOOKING_ERROR:
      return {
        ...state,
        booking: null,
        bookingError: action.payload.value,
      };
    case FAVORITE_RECEIVED: {
      const updatedFavorite = action.payload.value;
      let newState = state;
      let favoriteFound = false;
      if (!isEmptyObject(state.summary)) {
        state.summary?.user_profiles.some((profile: any, i: any) => {
          if (profile.user_profile_id === updatedFavorite.user_profile_id) {
            profile.location_favorites.some((favorite: any, j: any) => {
              if (favorite.user_profile_location_favorite_id === updatedFavorite.id) {
                favoriteFound = true;
                newState = cloneDeep(state);
                newState.summary &&
                  (newState.summary.user_profiles[i].location_favorites[j] = {
                    ...state.summary?.user_profiles[i].location_favorites[j],
                    ...updatedFavorite,
                  });

                return true;
              }

              return false;
            });

            if (!favoriteFound) {
              updatedFavorite.user_profile_location_favorite_id = updatedFavorite.id;
              newState.summary?.user_profiles[i].location_favorites.push(updatedFavorite);
            }

            return true;
          }

          return false;
        });
      }

      return newState;
    }

    case FETCHING_ACCOUNT_APPOINTMENTS:
      return {
        ...state,
        accountAppointmentsFetching: action.payload.value,
      };

    case UPCOMING_APPOINTMENTS_RECEIVED:
      return {
        ...state,
        upcomingAppointments: action.payload.value || null,
        upcomingAppointmentsError: null,
      };

    case UPCOMING_NEW_BOOKING:
      return {
        ...state,
        upcomingAppointments: [...(state.upcomingAppointments || []), action.payload.value],
        upcomingAppointmentsError: null,
      };

    case UPCOMING_APPOINTMENTS_ERROR:
      return {
        ...state,
        upcomingAppointments: null,
        upcomingAppointmentsError: action.payload.value,
      };

    case UPCOMING_APPOINTMENT_CANCELLED:
      return {
        ...state,
        upcomingAppointments: state.upcomingAppointments?.filter(
          (appointment: any) => appointment.id !== action.payload.value.id
        ),
      };

    case UPCOMING_APPOINTMENT_RESCHEDULED:
      return {
        ...state,
        upcomingAppointments: state.upcomingAppointments?.map((appointment: any) => {
          if (appointment.id === action.payload.value.booking_id) {
            let newAppointment = { ...appointment };
            newAppointment.appointment_date = action.payload.value.appointment_date;
            return newAppointment;
          }

          return appointment;
        }),
      };

    case PAST_APPOINTMENTS_RECEIVED:
      return {
        ...state,
        pastAppointments: action.payload.value || null,
        pastAppointmentsError: null,
      };

    case PAST_APPOINTMENTS_ERROR:
      return {
        ...state,
        pastAppointments: null,
        pastAppointmentsError: action.payload.value,
      };

    case RECEIVE_USER_PROFILE_APPOINTMENT_PREFERENCES: {
      const updatedProfileId = action.payload.value.user_profile_id;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'summary' does not exist on type '{}'.
      const currentUserProfiles = state.summary.user_profiles || [];
      const appointmentPreferences = action.payload.value.appointment_preferences;
      for (let i = 0; i < currentUserProfiles.length; i++) {
        if (currentUserProfiles[i].user_profile_id === updatedProfileId) {
          currentUserProfiles[i].appointmentPreferences = [
            ...((currentUserProfiles[i] && currentUserProfiles[i].appointmentPreferences) || []),
            ...appointmentPreferences,
          ];
        }
      }

      const newSummary = {
        ...state.summary,
        user_profiles: [...(state.summary?.user_profiles ?? [])],
      };
      return {
        ...state,
        newSummary,
        userProfileAppointmentPreferencesFetching: false,
      };
    }

    case RECEIVE_USER_PROFILE_SPECIALTY_PREFERENCES: {
      const updatedProfileId = action.payload.value.user_profile_id;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'summary' does not exist on type '{}'.
      const currentUserProfiles = state.summary.user_profiles || [];
      const specialtyPreferences = action.payload.value.specialty_preferences;

      for (let i = 0; i < currentUserProfiles.length; i++) {
        if (currentUserProfiles[i].user_profile_id === updatedProfileId) {
          currentUserProfiles[i].specialtyPreferences = [
            ...((currentUserProfiles[i] && currentUserProfiles[i].specialtyPreferences) || []),
            ...specialtyPreferences,
          ];
        }
      }

      const newSummary = {
        ...state.summary,
        user_profiles: [...(state.summary?.user_profiles ?? [])],
      };
      return {
        ...state,
        newSummary,
        userProfileSpecialtyPreferencesFetching: false,
      };
    }

    case FETCH_USER_PROFILE_APPOINTMENT_PREFERENCES: {
      return {
        ...state,
        userProfileAppointmentPreferencesFetching: true,
      };
    }

    case FETCH_USER_PROFILE_SPECIALTY_PREFERENCES: {
      return {
        ...state,
        userProfileSpecialtyPreferencesFetching: true,
      };
    }

    case USER_PROFILE_APPOINTMENT_PREFERENCES_ERROR: {
      return {
        ...state,
        userProfileAppointmentPreferencesFetching: false,
      };
    }

    case USER_PROFILE_SPECIALTY_PREFERENCES_ERROR: {
      return {
        ...state,
        userProfileSpecialtyPreferencesFetching: false,
      };
    }

    case SET_APPOINTMENT_VIEW:
      return {
        ...state,
        appointmentView: action.payload.value,
      };

    case LOGIN_RESET_LOGIN_DATA:
      return {};

    case GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS:
    case GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS: {
      const cardType = action.type === GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS ? 'front' : 'back';

      const insuranceCardPhotos = safeGet(state, {})(`insuranceCardPhotos`);
      return {
        ...state,
        insuranceCardPhotos: {
          ...insuranceCardPhotos,
          [cardType]: action.payload.value.image_url,
        },
      };
    }
    case BOOKING_TOKBOX_RATING_SUBMITTED:
      return {
        ...state,
        isTokBoxRatingSubmitted: true,
      };

    case ACCOUNT_ADD_SAVED_LOCATION: {
      if (!state.summary) return state;
      return {
        ...state,
        summary: {
          ...state.summary,
          account_favorite_locations: [
            ...state.summary.account_favorite_locations,
            action.payload.value,
          ],
        },
      };
    }

    case ACCOUNT_REMOVE_SAVED_LOCATION: {
      const currentSaved = [...(state.summary?.account_favorite_locations ?? [])];
      const indexToRemove = currentSaved.indexOf(action.payload.value);
      if (indexToRemove > -1) currentSaved.splice(indexToRemove, 1);
      return {
        ...state,
        summary: {
          ...state.summary,
          account_favorite_locations: currentSaved,
        },
      };
    }

    case ACCOUNT_REPLACE_SAVED_LOCATION:
      return {
        ...state,
        summary: {
          ...state.summary,
          account_favorite_locations: action.payload.value,
        },
      };

    case ACCOUNT_MARK_MESSAGES_READ:
      return {
        ...state,
        summary: {
          ...state.summary,
          number_of_unread_messages: 0,
        },
      };

    case ACCOUNT_TRACK_INTERACTION:
      return {
        ...state,
        summary: {
          ...state.summary,
          app_interactions: safeGet(
            state.summary?.app_interactions,
            []
          )('app_interactions').concat([action.payload.value]),
        },
      };

    case ADD_ACTIVE_ACCOUNT_PROFILE:
      return {
        ...state,
        summary: {
          ...state.summary,
          activeAccountProfile: action.payload.value || EMPTY_OBJECT,
        },
      };

    case SET_SMS_CONSENT:
      return {
        ...state,
        fullSummary: {
          ...state.fullSummary,
          sms_consent: action.payload.value,
        },
      };

    default:
      return state;
  }
}
