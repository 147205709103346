import { OcrData } from '@solvhealth/types/interfaces/OcrData';
import {
  SCAN_COMPLETED_FRONT,
  SCAN_STARTED_FRONT,
  SCAN_COMPLETED_BACK,
  SCAN_STARTED_BACK,
  OCR_DATA,
  OCR_DATA_BACK,
  SET_CARD_ID_FRONT,
  SET_CARD_ID_BACK,
  CLEAR_ADD_INSURANCE_FORM_DATA,
  CLEAR_CARD_DATA,
  SET_ADD_INSURANCE_FORM_DATA,
} from '../constants/index';

const scanStartedBack = () => ({
  type: SCAN_STARTED_BACK,
  payload: { value: true },
});

const scanStartedFront = () => ({
  type: SCAN_STARTED_FRONT,
  payload: { value: true },
});

const scanCompletedBack = (error?: any) => ({
  type: SCAN_COMPLETED_BACK,
  payload: { value: true, error },
});

const scanCompletedFront = (error?: any) => ({
  type: SCAN_COMPLETED_FRONT,
  payload: { value: true, error },
});

const setOcrDataFront = (value: OcrData) => ({
  type: OCR_DATA,
  payload: { value },
});

const setOcrDataBack = (value: OcrData) => ({
  type: OCR_DATA_BACK,
  payload: { value },
});

const setCardIdFront = (id: string) => ({
  type: SET_CARD_ID_FRONT,
  payload: { id },
});

const setCardIdBack = (id: string) => ({
  type: SET_CARD_ID_BACK,
  payload: { id },
});

const setFormData = (value: any) => ({
  type: SET_ADD_INSURANCE_FORM_DATA,
  payload: { value },
});

const clearCardData = () => ({
  type: CLEAR_CARD_DATA,
  payload: {},
});

const clearFormData = () => ({
  type: CLEAR_ADD_INSURANCE_FORM_DATA,
  payload: {},
});

export {
  scanStartedBack,
  scanStartedFront,
  scanCompletedBack,
  scanCompletedFront,
  setOcrDataFront,
  setOcrDataBack,
  setCardIdBack,
  setCardIdFront,
  setFormData,
  clearCardData,
  clearFormData,
};
