import { isClientSide } from './system';
import { NativeFunctions } from './native';
import { getNativePlatform } from './device';
import { isEmptyString } from '~/core/util/string';

const getCurrentPath = () => {
  if (isClientSide()) {
    return decodeURIComponent(window.location.pathname.replace(/\+/g, ' '));
  }

  return null;
};

/**
 * Get query params for a given url.
 *
 * Defaults to window.location.href
 *
 * @param url
 *
 * @returns {[param: string]: string } query params
 */
const getQueryParams = (
  url = typeof window !== 'undefined' && window.location.href
): { [param: string]: string } => {
  if (!url) {
    return {};
  }

  return (/\?/.test(url) ? url.slice(url.indexOf('?') + 1) : url).split('&').reduce(
    (queryParams, param) => {
      const newParams = { ...queryParams };
      const [key, value] = param.split('=');
      if (value) {
        newParams[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      }

      return newParams;
    },

    {} as { [param: string]: string }
  );
};

/**
 * Append utm_source to end of url string if native app.
 *
 * The following conditions must be met for a source
 * to be appended:
 *  1. url is of http protocol
 *
 * @param url target of appended source
 * @returns {string} url + appended source if necessary
 */
const appendUtmSource = (url: string) => {
  if (url.startsWith('http')) {
    const appendingSymbol = url.includes('?') ? '&' : '?';
    return `${url}${appendingSymbol}utm_source=${getNativePlatform() ?? 'browser'}`;
  }

  return url;
};

const hasQueryParams = () => {
  return window.location.search !== '';
};

/**
 * Open a non-solv url.
 *
 * Uses the openNativeUrl function if it exists,
 * otherwise falls back to window.open
 *
 * @param url URL to open
 * @param target Passed as second argument to window.open
 */
const openExternalUrl = (url: string, target?: string): void => {
  if (NativeFunctions.openNativeUrl.exists) {
    const urlWithSource = appendUtmSource(url);
    NativeFunctions.openNativeUrl.call(urlWithSource);
  } else if (typeof window !== 'undefined') {
    window.open(url, target);
  } else {
    console.error(`Cannot open URL (${url}), no window is defined`);
  }
};

/**
 * Helper to determine
 *   a) If the user is on a login route that will be redirecting to a specific page on success
 *   b) Whether they are being redirected to a certain set of paths
 *
 * @returns whether there is a redirect, optionally filtered by specified pages
 */
function willRedirectAfterLogin({ to }: { to?: RegExp[] } | undefined = {}) {
  if (!isClientSide()) {
    return false;
  }
  const url = window.location.pathname;
  const matches = url.match(/\/login\/next\/.+/);

  if (!to) {
    return matches;
  }

  return matches && to.some((path) => url.match(path));
}

export { getCurrentPath, getQueryParams, hasQueryParams, openExternalUrl, willRedirectAfterLogin };
