import { Cookies } from 'react-cookie';
import moment from 'moment';
import {
  NOT_RESERVE_WITH_GOOGLE_COOKIE_NAME,
  RESERVE_WITH_GOOGLE_COOKIE_NAME,
  GOOGLE_HEALTH_APPOINTMENTS_COOKIE_NAME,
  GOOGLE_MAP_PACK_COOKIE_NAME,
} from '../../config';

export type ReserveWithGoogleType = 'rwg' | 'not_rwg' | 'gha' | 'gmp';

const cookies = new Cookies();

const doesCookieExist = (cookieName: string) => Boolean(cookies.get(cookieName));

const getCookie = (cookieName: string) => cookies.get(cookieName);

const setCookie = (
  cookieName: string,
  value?: any,
  expires = new Date(new Date().setFullYear(2099))
) => {
  cookies.set(cookieName, value, {
    expires,
    path: '/',
  });
};

const unsetCookie = (cookieName: any) => cookies.remove(cookieName, { path: '/' });

const getReserveWithGoogleAttributionViaCookies = (): ReserveWithGoogleType => {
  const getOldestVisit = (visits: number[]): number => Math.min(...visits);
  const rwgCookieNames: ReserveWithGoogleType[] = [
    RESERVE_WITH_GOOGLE_COOKIE_NAME,
    NOT_RESERVE_WITH_GOOGLE_COOKIE_NAME,
    GOOGLE_HEALTH_APPOINTMENTS_COOKIE_NAME,
    GOOGLE_MAP_PACK_COOKIE_NAME,
  ];
  let oldestVisit: number = Number.MAX_VALUE;
  let oldestCookie: ReserveWithGoogleType = NOT_RESERVE_WITH_GOOGLE_COOKIE_NAME;
  for (let i = 0; i < rwgCookieNames.length; i++) {
    let cookie = getCookie(rwgCookieNames[i]);
    if (cookie && getOldestVisit(cookie) < oldestVisit) {
      oldestVisit = getOldestVisit(cookie);
      oldestCookie = rwgCookieNames[i];
    }
  }
  return oldestCookie;
};

const trackVisitForReserveWithGoogleAttribution = (
  req: any,
  res: any,
  visitSource: ReserveWithGoogleType
) => {
  let existingCookieValue =
    visitSource in req.universalCookies.cookies
      ? req.universalCookies.cookies[visitSource].split(':')
      : [];

  existingCookieValue = existingCookieValue.length !== 0 ? JSON.parse(existingCookieValue[1]) : [];

  const within24hrs = moment().subtract(1, 'day').valueOf();
  const visitedwithin24hrs = Math.max(...existingCookieValue) >= within24hrs;

  if (!visitedwithin24hrs) {
    if (existingCookieValue.length !== 0) {
      const thirtyDaysAgo = moment().subtract(30, 'day').valueOf();
      const filterGreaterThanThirty = (visitDate: number) => visitDate > thirtyDaysAgo;
      existingCookieValue = existingCookieValue.filter((visitDate: number): boolean =>
        filterGreaterThanThirty(visitDate)
      );
    }

    const newCookieValue = [moment().valueOf(), ...existingCookieValue];
    const expires = moment().add(30, 'day').toDate();
    res.cookie(visitSource, newCookieValue, { expires });
  }
};

export {
  doesCookieExist,
  setCookie,
  unsetCookie,
  getCookie,
  getReserveWithGoogleAttributionViaCookies,
  trackVisitForReserveWithGoogleAttribution,
};
