const CAREER_FORM_RECEIVED = 'careerForm/RECEIVED';
const CAREER_FORM_ERROR = 'careerForm/ERROR';
const CAREER_FORM_SUBMITTING = 'careerForm/SUBMITTING';

export const careerFormReceived = (value: any) => ({
  type: CAREER_FORM_RECEIVED,
  payload: { value },
});

export const careerFormError = (value: any) => ({
  type: CAREER_FORM_ERROR,
  payload: { value },
});

export const careerFormSubmitting = (value: any) => ({
  type: CAREER_FORM_SUBMITTING,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case CAREER_FORM_RECEIVED:
      return {
        ...state,
        result: action.payload.value,
        error: null,
        submitting: false,
      };
    case CAREER_FORM_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
        submitting: false,
      };
    case CAREER_FORM_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    default:
      return state;
  }
};
