import { Link } from '@solvhealth/jigsaw';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRIVACY_URL } from '../../constants/index';

type Props = {
  children?: string;
  className?: string;
};

const PrivacyPolicy = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Link className={props.className} external href={PRIVACY_URL} inline openInNewWindow>
      {props.children || t('legalLinks.privacyPolicy')}
    </Link>
  );
};

export default PrivacyPolicy;
