import {
  REMOTE_PHONE_VALIDATION_SUBMITTED,
  REMOTE_PHONE_VALIDATION_ERROR,
  GENERATE_OTP_FAILURE,
  GENERATE_OTP_SUCCESS,
  LOGIN_ACCOUNT_FROZEN,
  LOGIN_FAILED,
  LOGIN_FAIL_MAX_EXCEEDED,
  LOGIN_SUBMITTED,
  LOGIN_ACCOUNT_RECEIVED,
  LOGIN_ACCOUNT_ERROR,
  LOGIN_RESPONSE_RECEIVED,
  VALIDATE_PHONE_SUCCESS,
  VALIDATE_PHONE_ERROR,
  LOGIN_RESET_LOGIN_DATA,
  LOGIN_NO_ACCOUNT,
  LOGIN_SUBMIT_BUTTON_DISABLED,
  TOKEN_REFRESH_RESPONSE_RECEIVED,
  LOGIN_SWITCH_LOGIN_SIGN_UP,
  LOGIN_SAVE_SIGN_UP_DATA_STATE,
  LOGIN_SET_AFTER_LOGIN_URL,
  LOGIN_ACCOUNT_FETCHING,
  ACCOUNT_ADD_SAVED_LOCATION,
  ACCOUNT_REMOVE_SAVED_LOCATION,
  ACCOUNT_REPLACE_SAVED_LOCATION,
} from '../constants/index';

const remotePhoneValidationSuccess = (value: any) => ({
  type: REMOTE_PHONE_VALIDATION_SUBMITTED,
  payload: { value },
});

const loginSubmitted = (value: any) => ({
  type: LOGIN_SUBMITTED,
  payload: { value },
});

const generateOtpSuccess = (phone: any) => (value: any) => ({
  type: GENERATE_OTP_SUCCESS,
  payload: { phone, value },
});

const setLoginAction = (value: any) => ({
  type: LOGIN_SWITCH_LOGIN_SIGN_UP,
  payload: { value },
});

const saveSignUpPostData = (value: any) => ({
  type: LOGIN_SAVE_SIGN_UP_DATA_STATE,
  payload: { value },
});

const validatePhoneSuccess = (value: any) => ({
  type: VALIDATE_PHONE_SUCCESS,
  payload: { value },
});

const validatePhoneError = (value: any) => ({
  type: VALIDATE_PHONE_ERROR,
  payload: { value },
});

const generateOtpFailure = (value: any, phone?: any) => ({
  type: GENERATE_OTP_FAILURE,
  payload: { value, phone },
});

const loginResponseReceived = (value: any) => ({
  type: LOGIN_RESPONSE_RECEIVED,
  payload: { value },
});

const accountReceived = (value: any) => ({
  type: LOGIN_ACCOUNT_RECEIVED,
  payload: { value },
});

const accountFetching = (value: any) => ({
  type: LOGIN_ACCOUNT_FETCHING,
  payload: { value },
});

const accountSaveLocation = (value: any, active: any) => ({
  type: active ? ACCOUNT_ADD_SAVED_LOCATION : ACCOUNT_REMOVE_SAVED_LOCATION,
  payload: { value },
});

const accountReplaceSavedLocations = (value: any) => ({
  type: ACCOUNT_REPLACE_SAVED_LOCATION,
  payload: { value },
});

const accountFrozen = (value: any) => ({
  type: LOGIN_ACCOUNT_FROZEN,
  payload: { value },
});

const noAccount = (value: any) => ({
  type: LOGIN_NO_ACCOUNT,
  payload: { value },
});

const loginFailed = (value: any) => ({
  type: LOGIN_FAILED,
  payload: { value },
});

const loginFailMaxExceeded = (value: any) => ({
  type: LOGIN_FAIL_MAX_EXCEEDED,
  payload: { value },
});

const setAfterLoginUrl = (value: any) => ({
  type: LOGIN_SET_AFTER_LOGIN_URL,
  payload: { value },
});

const resetLoginData = () => ({
  type: LOGIN_RESET_LOGIN_DATA,
});

const accountError = (value: any) => ({
  type: LOGIN_ACCOUNT_ERROR,
  payload: { value },
});

const remotePhoneValidationError = (value: any) => ({
  type: REMOTE_PHONE_VALIDATION_ERROR,
  payload: { value },
});

const loginSubmitButtonDisabled = (value: any) => ({
  type: LOGIN_SUBMIT_BUTTON_DISABLED,
  payload: { value },
});

const refreshResponseReceived = (value: any) => ({
  type: TOKEN_REFRESH_RESPONSE_RECEIVED,
  payload: { value },
});

export {
  loginResponseReceived,
  accountReceived,
  accountFrozen,
  accountError,
  accountFetching,
  generateOtpFailure,
  generateOtpSuccess,
  loginFailed,
  loginFailMaxExceeded,
  loginSubmitted,
  loginSubmitButtonDisabled,
  accountReplaceSavedLocations,
  accountSaveLocation,
  noAccount,
  remotePhoneValidationError,
  remotePhoneValidationSuccess,
  refreshResponseReceived,
  resetLoginData,
  saveSignUpPostData,
  setAfterLoginUrl,
  setLoginAction,
  validatePhoneSuccess,
  validatePhoneError,
};
