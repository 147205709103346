import Booking from '@solvhealth/types/interfaces/Booking';
import moment from 'moment-timezone';
import isEmptry from 'lodash/isEmpty';
import isEmpty from 'lodash/isEmpty';
import mapRfvToService, {
  getServiceCategoryDisplayName,
  getServicesGeneralCategory,
} from '~/components/ClinicDetailPageFlexible/components/SelfPay/util/mapRfvToService';
import { MEMBERSHIP_BOOKING_FLAG_ENABLED_DATE } from '~/components/Membership/constants';
import {
  ASAP_VALUE,
  BookingStatus,
  BOOKING_CANCELLATION_REASON_TRANSFERRED,
  ORIGIN_BOOKING_WIDGET,
  ORIGIN_EXTERNAL_SCHEDULE_SYNC,
  ORIGIN_KIOSK_SIGN_IN,
  ORIGIN_QUEUE,
  TYPE_SELF,
  TYPE_SKIP,
  UBER_RIDE_REQUESTED,
} from '../../constants/index';
import { isEmptyString } from '../../core/util/string';
import { NewBookingState } from '~/reducers/newBooking';

const isRescheduledBooking = (booking: any) =>
  booking.hasBeenRescheduled === true || !isEmptyString(booking.rescheduledTo);

const isBookingWidgetBooking = (booking: any) => booking.origin === ORIGIN_BOOKING_WIDGET;

/**
 * These bookings were originally made in the partner's EHR, then synced to Solv via scheduled task
 *
 * @param booking the booking in question
 * @returns {boolean} whether or not the booking was created via the external_schedule_sync
 */
const isExternalScheduleSyncBooking = (booking: Booking) =>
  booking.origin === ORIGIN_EXTERNAL_SCHEDULE_SYNC;

const isWalkIn = (origin: any) => origin === ORIGIN_KIOSK_SIGN_IN || origin === ORIGIN_QUEUE;

const upcomingStatuses = [
  BookingStatus.PENDING,
  BookingStatus.RESERVED,
  BookingStatus.CONFIRMED,
  BookingStatus.CHECKED_IN,
  BookingStatus.IN_EXAM_ROOM,
];

const isUpcoming = (booking: any) => upcomingStatuses.includes(booking.status);

const hasInsuranceInfo = (booking: any) => booking.hasInsuranceInfo;

const isSelfPay = (booking: any) =>
  booking.insurerType === TYPE_SELF || booking.insurer_type === TYPE_SELF;

const isInsuranceSkipped = (booking: any) =>
  booking.insurerType === TYPE_SKIP || booking.insurer_type === TYPE_SKIP;

const isInsuranceTypeUnset = (bookingInsurance: NewBookingState['insurance']) =>
  isEmpty(bookingInsurance.insurerType);

const isCallAhead = (booking: any) => booking.isCallAhead;

const isRescheduled = (booking: any) =>
  booking.status !== BookingStatus.CANCELLED && isRescheduledBooking(booking);

const isCancelled = (booking: any) => booking.status === BookingStatus.CANCELLED;

const isDischarged = (booking: any) => booking.status === BookingStatus.DISCHARGED;

const isNoShow = (booking: any) => booking.status === BookingStatus.NO_SHOW;

const isPending = (booking: any) => booking.status === BookingStatus.PENDING;

const isCheckedIn = (booking: any) => booking.status === BookingStatus.CONFIRMED;

const isFamilyBooking = (booking: any) => !!booking.bookingFamilyId;

const shouldShowRebookingScreen = (booking: any) =>
  isDischarged(booking) || isCancelled(booking) || isNoShow(booking);

const hasBeenTransferred = (booking: any) =>
  booking.status === BookingStatus.CANCELLED &&
  booking.cancellationReason === BOOKING_CANCELLATION_REASON_TRANSFERRED;

const isActiveBooking = (booking: any) =>
  !isPending(booking) && !isCancelled(booking) && !isRescheduledBooking(booking);

const isOldRescheduledFlow = (booking: any) =>
  !isEmptyString(booking.rescheduledTo) && booking.hasBeenRescheduled === false;

const hasRequestedUberRide = (booking: any) => booking.uberRideStatus === UBER_RIDE_REQUESTED;

const isAsapBooking = (booking: any) =>
  booking.preferredTime === ASAP_VALUE || booking.preferred_time === ASAP_VALUE;

const getTokboxSessionId = (booking: any) => booking?.tokbox_session_id ?? booking?.tokboxSessionId;

const isAtOpeningTime = (appointmentTime: any, hours: any, timezone: any) => {
  const appointmentDay = moment(appointmentTime).format('dddd');
  const openingTimeOfAppointmentDay =
    hours && hours[appointmentDay] && hours[appointmentDay][0] && hours[appointmentDay][0].fromTime;

  if (timezone) {
    return moment(appointmentTime).tz(timezone).format('HH:mm:ss') === openingTimeOfAppointmentDay;
  }

  return moment(appointmentTime).format('HH:mm:ss') === openingTimeOfAppointmentDay;
};

const isPremiumBooking = (booking: Booking) => booking?.isPremiumVisit || booking?.is_premium_visit;
const isBookingCoveredByMembership = (booking: Booking) => {
  if (
    moment(booking.created_date ?? booking.createdDate).isBefore(
      MEMBERSHIP_BOOKING_FLAG_ENABLED_DATE
    )
  ) {
    return isPremiumBooking(booking);
  }
  return booking?.isCoveredByMembership || booking?.is_covered_by_membership;
};

const isProviderGroupBooking = (booking: Booking) =>
  !!(booking?.providerId ?? booking?.provider_id);
const getProviderId = (booking: any) => booking.provider_id || booking.providerId;

const isDirectTelemedBooking = (booking: any) =>
  booking.isDirectTelemed || booking.is_direct_telemed;

const isTelemedBooking = (booking: Booking) =>
  !isEmptyString(booking.tokbox_session_id) || !isEmptyString(booking.tokboxSessionId);

const isExternalTelemedBooking = (booking: Booking) =>
  !!(booking.is_external_telemed || booking.isExternalTelemed);

const isInternalOrExternalTelemedBooking = (booking: Booking) => {
  if (isProviderGroupBooking(booking)) {
    return isExternalTelemedBooking(booking);
  }
  return isTelemedBooking(booking);
};

const hasSubmittedRating = (booking: any) =>
  booking && (!!booking.rating_date || !!booking.ratingDate);

const isBookingWithin = (booking: any, amount = 1, unit = 'minutes') =>
  booking &&
  (booking.appointment_date || booking.appointmentDate) &&
  // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  moment()
    .add(amount, unit)
    .isAfter(booking.appointment_date || booking.appointmentDate);

const isPhotoIdUploadedForBooking = (booking: any) =>
  booking && (booking.hasUploadedPhotoIdFront || booking.hasUploadedPhotoIdBack);

/** Finds the name of the V1 service that matches a given reason for visit.
 *
 * @returns the v1Service name */
const getSelfPayV1ServiceName = (reasonForVisit: string) => {
  const serviceMatches = mapRfvToService(reasonForVisit).services;
  let v1ServiceName = '';

  if (serviceMatches?.length) {
    if (serviceMatches.length > 1) {
      const serviceCategory = getServicesGeneralCategory(serviceMatches);
      v1ServiceName = getServiceCategoryDisplayName(serviceCategory) ?? '';
    } else {
      [v1ServiceName] = serviceMatches;
    }
  }

  return v1ServiceName;
};

const hasAssociatedLabResults = (booking: any) =>
  booking && (booking.has_associated_lab_results || booking.hasAssociatedLabResults);

export {
  hasInsuranceInfo,
  isWalkIn,
  isBookingWithin,
  hasSubmittedRating,
  isBookingWidgetBooking,
  isExternalScheduleSyncBooking,
  isSelfPay,
  isInsuranceSkipped,
  isInsuranceTypeUnset,
  isUpcoming,
  isRescheduledBooking,
  isCallAhead,
  isActiveBooking,
  isCancelled,
  isDischarged,
  isNoShow,
  isRescheduled,
  isOldRescheduledFlow,
  hasBeenTransferred,
  hasRequestedUberRide,
  shouldShowRebookingScreen,
  isAsapBooking,
  isAtOpeningTime,
  isPending,
  isDirectTelemedBooking,
  isTelemedBooking,
  isPremiumBooking,
  isBookingCoveredByMembership,
  isPhotoIdUploadedForBooking,
  isProviderGroupBooking,
  getProviderId,
  isExternalTelemedBooking,
  isInternalOrExternalTelemedBooking,
  isCheckedIn,
  isFamilyBooking,
  getTokboxSessionId,
  getSelfPayV1ServiceName,
  hasAssociatedLabResults,
};
