import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import { includeAtHomeCareInSearch } from '~/core/util/atHomeCare';

export const SEARCH_PARTNERS_RESPONSE_RECEIVED = 'searchResults/PARTNERS_RESPONSE_RECEIVED';
export const AT_HOME_CARE_RESPONSE_RECEIVED = 'searchResults/AT_HOME_CARE_RESPONSE_RECEIVED';

export const SEARCH_BEST_MATCH_RESPONSE_RECEIVED = 'searchResults/BEST_MATCH_RESPONSE_RECEIVED';
export const SEARCH_NON_PARTNERS_RESPONSE_RECEIVED = 'searchResults/NON_PARTNERS_RESPONSE_RECEIVED';
export const SEARCH_IN_PROGRESS = 'searchResults/SEARCH_IN_PROGRESS';

export const SEARCH_NEARBY_CITIES_SEARCH_IN_PROGRESS =
  'searchResults/NEARBY_CITIES_SEARCH_IN_PROGRESS';
export const SEARCH_NEARBY_BRANDS_SEARCH_IN_PROGRESS =
  'searchResults/NEARBY_BRANDS_SEARCH_IN_PROGRESS';

export const SEARCH_NEARBY_CITIES_RESPONSE_RECEIVED =
  'searchResults/NEARBY_CITIES_RESPONSE_RECEIVED';
export const SEARCH_NEARBY_BRANDS_RESPONSE_RECEIVED =
  'searchResults/NEARBY_BRANDS_RESPONSE_RECEIVED';

export const CLEAR_SEARCH_RESULTS = 'searchResults/CLEAR_RESULTS_SET';

export const SET_SEARCH_START_TIMESTAMP_MS = 'searchPreferences/SET_SEARCH_START_TIMESTAMP_MS';
export const CLEAR_SEARCH_START_TIMESTAMP = 'searchPreferences/CLEAR_SEARCH_START_TIMESTAMP';

const initialState: SearchResultsState = {
  nearby: {
    cities: [],
    brands: [],
    isFetchingCities: false,
    isFetchingBrands: false,
  },
};

type Pagination = {
  limit: number;
  links: { href: string; rel: string }[];
  page: number;
  radius: number;
  results_count: number;
};

export type SearchResultsState = {
  nearby: {
    cities: Array<any>;
    brands: Array<any>;
    isFetchingCities: boolean;
    isFetchingBrands: boolean;
  };
  partnersSearchInProgress?: boolean;
  nonPartnersSearchInProgress?: boolean;
  bestMatchResults?: LocationInterface[];
  partnersResults?: LocationInterface[];
  nonPartnersResults?: LocationInterface[];
  partnersPagination?: Pagination;
  nonPartnersPagination?: Pagination;
  searchWasRequestedTimestampMs?: number;
};

/**
 * Sets the search to in progress
 *
 * @returns An action object used in redux
 */
export function searchInProgress(type: any, value: any) {
  return {
    type: SEARCH_IN_PROGRESS,
    payload: { type, value },
  };
}

/**
 * Passes partner search results into the store
 *
 * @returns An action object used in redux
 */
export function receivePartnersSearch(value: any) {
  return {
    type: SEARCH_PARTNERS_RESPONSE_RECEIVED,
    payload: { value },
  };
}

/**
 * Passes partner search results into the store
 *
 * @returns An action object used in redux
 */
export function receiveAtHomeCareResults(locations: LocationInterface[]) {
  return {
    type: AT_HOME_CARE_RESPONSE_RECEIVED,
    payload: { value: locations },
  };
}

/**
 * Sets the best match search result
 *
 * @returns An action object used in redux
 */
export function receiveBestMatch(value: any) {
  return {
    type: SEARCH_BEST_MATCH_RESPONSE_RECEIVED,
    payload: { value },
  };
}

/**
 * Sets the nearby cities search to be in progress
 *
 * @returns An action object used in redux
 */
export function setNearbyCitiesSearchInProgress(value: any) {
  return {
    type: SEARCH_NEARBY_CITIES_SEARCH_IN_PROGRESS,
    payload: { value },
  };
}

/**
 * Sets the nearby brands search to be in progress
 *
 * @returns An action object used in redux
 */
export function setNearbyBrandsSearchInProgress(value: any) {
  return {
    type: SEARCH_NEARBY_BRANDS_SEARCH_IN_PROGRESS,
    payload: { value },
  };
}

/**
 * Sets the nearby cities result
 *
 * @returns An action object used in redux
 */
export function receiveNearbyCitiesResponseSearch(value: any) {
  return {
    type: SEARCH_NEARBY_CITIES_RESPONSE_RECEIVED,
    payload: { value },
  };
}

/**
 * Sets the nearby brands result
 *
 * @returns An action object used in redux
 */
export function receiveNearbyBrandsResponseSearch(value: any) {
  return {
    type: SEARCH_NEARBY_BRANDS_RESPONSE_RECEIVED,
    payload: { value },
  };
}

/**
 * Sets the non-partners search results
 *
 * @returns An action object used in redux
 */
export function receiveNonPartnersSearch(value: any) {
  return {
    type: SEARCH_NON_PARTNERS_RESPONSE_RECEIVED,
    payload: { value },
  };
}

export const clearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});

export function setSearchStartTimestampMs(value: number) {
  return {
    type: SET_SEARCH_START_TIMESTAMP_MS,
    payload: { value },
  };
}

export function clearSearchStartTimestamp() {
  return {
    type: CLEAR_SEARCH_START_TIMESTAMP,
  };
}

export default (state: SearchResultsState = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_IN_PROGRESS: {
      const { type, value } = action.payload;
      const key = `${type}SearchInProgress`;
      return {
        ...state,
        [key]: value,
      };
    }

    case SEARCH_PARTNERS_RESPONSE_RECEIVED: {
      const { response } = action.payload.value;

      const currentResultsSet = state.partnersResults || [];

      const newPageNumber = (response.pagination as Pagination).page;
      const shouldMergeData =
        newPageNumber && newPageNumber > 1 && newPageNumber !== state.partnersPagination?.page;
      const newResults = shouldMergeData
        ? concat(currentResultsSet, response.results)
        : response.results;

      return {
        ...state,
        partnersResults: uniqBy(newResults, 'id'),
        partnersPagination: response.pagination,
      };
    }

    case AT_HOME_CARE_RESPONSE_RECEIVED: {
      const locations = action.payload.value;
      const newLocations = includeAtHomeCareInSearch(locations, state.partnersResults || []);

      return {
        ...state,
        partnersResults: uniqBy(newLocations, 'id'),
      };
    }

    case SEARCH_NON_PARTNERS_RESPONSE_RECEIVED: {
      const { response } = action.payload.value;
      const currentResultsSet = state.nonPartnersResults || [];

      const newPageNumber = (response.pagination as Pagination).page;
      const shouldMergeData =
        newPageNumber && newPageNumber > 1 && newPageNumber !== state.nonPartnersPagination?.page;
      const newResults = shouldMergeData
        ? concat(currentResultsSet, response.results)
        : response.results;

      return {
        ...state,
        nonPartnersResults: uniqBy(newResults, 'id'),
        nonPartnersPagination: response.pagination,
      };
    }

    case CLEAR_SEARCH_RESULTS:
      return initialState;

    case SEARCH_BEST_MATCH_RESPONSE_RECEIVED: {
      const { response } = action.payload.value;
      return {
        ...state,
        bestMatchResults: response.results,
      };
    }

    case SEARCH_NEARBY_CITIES_SEARCH_IN_PROGRESS:
      return {
        ...state,
        nearby: { ...state.nearby, isFetchingCities: action.payload.value },
      };
    case SEARCH_NEARBY_BRANDS_SEARCH_IN_PROGRESS:
      return {
        ...state,
        nearby: { ...state.nearby, isFetchingBrands: action.payload.value },
      };
    case SEARCH_NEARBY_CITIES_RESPONSE_RECEIVED:
      return {
        ...state,
        nearby: { ...state.nearby, cities: action.payload.value.results },
      };
    case SEARCH_NEARBY_BRANDS_RESPONSE_RECEIVED:
      return {
        ...state,
        nearby: { ...state.nearby, brands: action.payload.value.results },
      };
    case SET_SEARCH_START_TIMESTAMP_MS:
      return {
        ...state,
        searchWasRequestedTimestampMs: action.payload.value,
      };
    case CLEAR_SEARCH_START_TIMESTAMP:
      return {
        ...state,
        searchWasRequestedTimestampMs: undefined,
      };

    default:
      return state;
  }
};
