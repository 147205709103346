import { DAPI_HOST } from '../../config/index';
import { queryStringFromObject } from '../../core/util/string';
import { apiGet, apiPatch } from '.';
import { getAccountById, isMixpanelAliasedUrl } from './account';
import { getNativePlatform, isAndroidApp, isIosApp } from '../util/device';

const trackingUrl = `${DAPI_HOST}/v1/tracking`;
const getFeedbackUrl = (bookingId: any) => `${trackingUrl}/feedback/${bookingId}`;

const getDapiGoogleReviewUrl = ({ bookingId, locationId }: any = {}) =>
  `${trackingUrl}/google-review/${(locationId && 'l') || (bookingId && 'b')}/` +
  `${bookingId || locationId}`;

const getDapiGoogleReviewEmailUrl = ({ bookingId, source }: any = {}) =>
  `${trackingUrl}/google-review/b/${bookingId}/${source}`;

const trackReviewLink = (id: any) => `${trackingUrl}/survey-monkey/${id}`;

const getConsentFormUrl = (bookingId: any) => `${DAPI_HOST}/v1/consent-forms`;

const getCDPPhoneClickUrl = () => `${trackingUrl}/cdp-phone-click`;

const getTrackingUrl = (entity: any, entityId: any, trackingType: any) => {
  const params = { entity, entity_id: entityId, tracking_type: trackingType };
  return `${trackingUrl}?${queryStringFromObject(params)}`;
};

const getIsMixpanelAliased = async (accountId: any) => {
  const response = await apiGet(isMixpanelAliasedUrl(accountId));
  return response.is_mp_aliased;
};

const setIsMixpanelAliased = async (accountId: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
  const response = await apiPatch(isMixpanelAliasedUrl(accountId));
  return response.is_mp_aliased;
};

const setIsNativeAppUser = async (accountId: any) => {
  const platform = getNativePlatform();
  if (!platform) return null;

  const dataKey = `is_${platform}_app_user`;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  const response = await apiPatch(getAccountById(accountId), { [dataKey]: true });

  return response[dataKey];
};

export {
  getCDPPhoneClickUrl,
  getConsentFormUrl,
  getDapiGoogleReviewEmailUrl,
  getDapiGoogleReviewUrl,
  getFeedbackUrl,
  getTrackingUrl,
  trackReviewLink,
  getIsMixpanelAliased,
  setIsMixpanelAliased,
  setIsNativeAppUser,
};
