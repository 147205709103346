import moment from 'moment';

/**
 * Normalize booking data into a friendly format.
 *
 * @param {object} booking
 * @returns {object}
 */
const bookingResponseFormatter = (booking: any) => {
  if (booking.isFormatted) {
    return booking;
  }

  const formatted = {};
  for (const key in booking) {
    if (!booking.hasOwnProperty(key)) {
      continue;
    }

    const camelCaseKey = key.replace(/_(\w)/g, (m, w) => w.toUpperCase());
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    formatted[camelCaseKey] = booking[key];
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'appointmentTime' does not exist on type ... Remove this comment to see the full error message
  formatted.appointmentTime = moment(booking.appointment_date).valueOf();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFormatted' does not exist on type '{}'... Remove this comment to see the full error message
  formatted.isFormatted = true;
  return formatted;
};

export { bookingResponseFormatter };
