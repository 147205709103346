import React, { PureComponent, Fragment } from 'react';
import { func, bool } from 'prop-types';
import styled from 'styled-components';

// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { createForm, formShape } from 'rc-form';
import { withCookies, Cookies } from 'react-cookie';
import { fontFamily, fontSize } from '../../constants/text';
import { colors } from '../../constants/colors';
import { PhoneInput } from './Fields';
import Button, { ButtonVariations } from '../SolvPatternLibrary/Button';
import { PHONE_ID } from './Fields/ids';
import { stripNonNumeric } from '../../core/util/string';
import BrandedHeader from '../LoginForm/BrandedHeader';
import { shouldShowAppInstallPrompt } from '../AddToHomeScreen/util';
import { PWA_ADD_TO_HOME_SCREEN } from '../App/util/tracking';
import { analyticsTrackEvent } from '../../core/analytics';

const Root = styled.form`
  display: block;
  max-width: 450px;
  margin: 20px auto;
  padding: 0 12px 40px;
  text-align: center;

  select,
  input {
    color: ${colors.blueMonday};
  }

  label {
    font-family: ${fontFamily.book};
    color: ${colors.blueish};
  }
`;

const Title = styled.h2`
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.extraExtraLarge};
  letter-spacing: 0.25px;
  color: ${colors.blueLoadingScreen};
`;

const Explanation = styled.p`
  font-family: ${fontFamily.book};
  font-size: ${fontSize.default};
  color: ${colors.blueMonday};
  letter-spacing: -0.23px;
  line-height: ${fontSize.extraLarge};
`;

const Submit = styled(Button)`
  width: 100%;
  height: auto;
  margin-top: 24px;
  padding: 10px 0;
  line-height: normal;
  box-shadow: none;
`;

const WidthConstraint = styled.div`
  max-width: 316px;
  margin: 0 auto;
`;

const PwaDownloadCta = styled(Button)`
  margin: 10px 0;
`;

type Props = {
  login?: object;
  // @ts-expect-error ts-migrate(2749) FIXME: 'func' refers to a value, but is being used as a t... Remove this comment to see the full error message
  submitPhoneVerification?: func;
  form: formShape;
  // @ts-expect-error ts-migrate(2749) FIXME: 'bool' refers to a value, but is being used as a t... Remove this comment to see the full error message
  isModal?: bool;
  cookies: object;
};

class PhoneNumberVerify extends PureComponent<Props> {
  onClickAddToHomeScreen = () => {
    window.deferredA2HSPrompt && window.deferredA2HSPrompt.prompt();
    analyticsTrackEvent(PWA_ADD_TO_HOME_SCREEN, {
      action: 'solv prompt',
      origin: 'phone number verify',
    });
  };

  getInitialPhoneValue = () => {
    const { login } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'signUpData' does not exist on type 'obje... Remove this comment to see the full error message
    return login && login.signUpData && login.signUpData.phone;
  };

  handleSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const {
      submitPhoneVerification,
      form,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'signUpData' does not exist on type 'obje... Remove this comment to see the full error message
      login: { signUpData },
    } = this.props;
    const { [PHONE_ID]: editedPhone } = form.getFieldsValue();

    submitPhoneVerification({
      updatedPhone: stripNonNumeric(editedPhone),
      signUpData,
    });
  };

  render() {
    const { form, isModal } = this.props;
    const phone = this.getInitialPhoneValue();
    const showPrompt = shouldShowAppInstallPrompt();

    // jscs: disable
    return (
      <>
        {!isModal && <BrandedHeader />}

        <Root onSubmit={this.handleSubmit}>
          <Title>Great! It looks like you already have an account.</Title>

          <Explanation>
            We found an account associated with the phone number you entered. Please verify the
            number below and we’ll send you a PIN to make sure it’s you.
          </Explanation>

          <WidthConstraint>
            <PhoneInput form={form} initialValue={phone} />

            <Submit type="submit">Send PIN</Submit>
            {showPrompt && (
              <PwaDownloadCta
                fullWidth
                onClick={this.onClickAddToHomeScreen}
                variation={ButtonVariations.link}
              >
                Download App
              </PwaDownloadCta>
            )}
          </WidthConstraint>
        </Root>
      </>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'object... Remove this comment to see the full error message
export default createForm()(withCookies(PhoneNumberVerify));
