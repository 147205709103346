const INSURANCE_DETAILS_RECEIVED = 'insuranceDetails/RECEIVED';
const INSURANCE_DETAILS_ERROR = 'insuranceDetails/ERROR';
const INSURANCE_DETAILS_SUBMITTING = 'insuranceDetails/SUBMITTING';
const INSURANCE_DETAILS_REMOVING = 'insuranceDetails/REMOVING';

export const insuranceDetailsReceived = (value: any, userProfileId: any) => ({
  type: INSURANCE_DETAILS_RECEIVED,
  payload: { value, userProfileId },
});

export const insuranceDetailsError = (value: any) => ({
  type: INSURANCE_DETAILS_ERROR,
  payload: { value },
});

export const insuranceDetailsSubmitting = (value: any) => ({
  type: INSURANCE_DETAILS_SUBMITTING,
  payload: { value },
});

export const clearInsuranceDetails = () => ({
  type: INSURANCE_DETAILS_REMOVING,
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case INSURANCE_DETAILS_RECEIVED:
      return {
        ...state,
        result: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
          ...state.result,
          [action.payload.userProfileId]: action.payload.value,
        },
        error: null,
        submitting: false,
      };
    case INSURANCE_DETAILS_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
        submitting: false,
      };
    case INSURANCE_DETAILS_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    case INSURANCE_DETAILS_REMOVING:
      return {
        ...state,
        result: null,
        error: null,
        submitting: false,
      };
    default:
      return state;
  }
};
