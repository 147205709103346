/* jscs:disable requireCamelCaseOrUpperCaseIdentifiers */
import Location from '@solvhealth/types/interfaces/Location';
import { isExternalTelemedBooking } from '../../../core/util/booking';
import { isEmptyObject, safeGet } from '../../../core/util/object';
import { BASE_URL } from '../../../config/index';
import { buildStreetViewUrl } from '../../../core/google/staticmap';
import { isTabletOrSmaller } from '../../../core/util/device/screen';
import { isTelemedLocation } from '../../../core/util/location';
const MOBILE_MAX_VIEW_WIDTH = 480;

const getWidth = (viewport: any) => {
  if (!viewport) return MOBILE_MAX_VIEW_WIDTH;
  if (isTabletOrSmaller(viewport.width)) {
    if (viewport.width > MOBILE_MAX_VIEW_WIDTH) {
      return MOBILE_MAX_VIEW_WIDTH;
    }

    return viewport.width;
  }

  return 537;
};

const getHeight = (viewport: any) => {
  if (!viewport) return 200;
  return isTabletOrSmaller(viewport.width) ? 200 : 308;
};

export const LOGO = 'logo';
export const PHOTO = 'photo';

export const THUMBNAIL_SMALL = 'small'; // 128x128
export const THUMBNAIL_MEDIUM = 'medium'; //200x200

const TELEMED_IMAGE = `${BASE_URL}/images/telemed/telemed-location-placeholder.svg`;

type ImageProps = Partial<{
  imageIndex: number;
  imageType: string;
  showDefaultPictureIfMissing: boolean;
  booking: any;

  /**
   * @deprecated not used
   */
  viewport: any;
}>;

const getImageObject = (
  location: Location,
  { imageIndex, imageType, showDefaultPictureIfMissing, booking }: ImageProps = {}
) => {
  const defaultImage = showDefaultPictureIfMissing ? '/images/Default-Clinic-Photo.svg' : null;
  if (!location || isEmptyObject(location) || (!defaultImage && isEmptyObject(location.imageLinks)))
    return {};

  const { imageLinks } = location;
  const { logo, logo_webp, logo_jp2 } = imageLinks ?? {};

  let imageObject = {};

  if (imageType === LOGO) {
    const fullResLogo = logo?.[0] ?? defaultImage;

    imageObject = {
      fullRes: fullResLogo,
      webp: logo_webp?.[0] ?? fullResLogo,
      jp2: logo_jp2?.[0] ?? fullResLogo,
    };
  } else if (isTelemedLocation(location) || (booking && isExternalTelemedBooking(booking))) {
    return { fullRes: TELEMED_IMAGE };
  } else {
    let fullRes = imageLinks?.image?.[imageIndex ?? 0];
    let webp = imageLinks?.image_webp?.[imageIndex ?? 0];
    let jp2 = imageLinks?.image_jp2?.[imageIndex ?? 0];
    let thumbnail128 = imageLinks?.image_128x128_thumbnail?.[imageIndex ?? 0];
    let thumbnail200 = imageLinks?.image_200x200_thumbnail?.[imageIndex ?? 0];
    if (!fullRes && defaultImage) {
      fullRes = defaultImage;
      webp = defaultImage;
      jp2 = defaultImage;
      thumbnail128 = defaultImage;
      thumbnail200 = defaultImage;
    }

    imageObject = { fullRes, webp, jp2, thumbnail128, thumbnail200 };
  }

  return imageObject;
};

export { getImageObject };
