import { takeEvery } from 'redux-saga/effects';
import {
  fetchAppointments,
  fetchFavoriteLocations,
  fetchGetCareLocations,
  fetchLocation,
  fetchPharmacy,
  searchPharmacies,
  setBirthSex,
  uploadAccountFile,
  uploadProfilePhoto,
  createVerifiedUserProfile,
  updateUserProfile,
  getSpecialtyPreferences,
  createSpecialtyPreferences,
  getAppointmentPreferences,
  createAppointmentPreferences,
  fetchNearbyLocations,
  clearPharmacies,
  fetchLocationAddToLocations,
} from '../components/Account/Profile/sagas/accountProfile';
import {
  FETCH_FAVORITE_LOCATIONS,
  FETCH_APPOINTMENTS,
  FETCH_GET_CARE_LOCATIONS,
  FETCH_LOCATION,
  FETCH_PHARMACY,
  SEARCH_PHARMACIES,
  CLEAR_PHARMACIES,
  SET_BIRTH_SEX,
  UPLOAD_ACCOUNT_FILE,
  UPLOAD_PROFILE_PHOTO,
  CREATE_VERIFIED_USER_PROFILE,
  UPDATE_USER_PROFILE,
  GET_SPECIALTY_PREFERENCES,
  CREATE_SPECIALTY_PREFERENCES,
  GET_APPOINTMENT_PREFERENCES,
  CREATE_APPOINTMENT_PREFERENCES,
  FETCH_NEARBY_LOCATIONS,
  FETCH_LOCATION_SET_LOCATIONS,
} from '../constants/index';

function* rootSaga() {
  yield takeEvery(FETCH_APPOINTMENTS, fetchAppointments);
  yield takeEvery(FETCH_GET_CARE_LOCATIONS, fetchGetCareLocations);
  yield takeEvery(FETCH_NEARBY_LOCATIONS, fetchNearbyLocations);
  yield takeEvery(FETCH_LOCATION, fetchLocation);
  yield takeEvery(FETCH_LOCATION_SET_LOCATIONS, fetchLocationAddToLocations);
  yield takeEvery(FETCH_PHARMACY, fetchPharmacy);
  yield takeEvery(SEARCH_PHARMACIES, searchPharmacies);
  yield takeEvery(CLEAR_PHARMACIES, clearPharmacies);
  yield takeEvery(SET_BIRTH_SEX, setBirthSex);
  yield takeEvery(UPLOAD_ACCOUNT_FILE, uploadAccountFile);
  yield takeEvery(UPLOAD_PROFILE_PHOTO, uploadProfilePhoto);
  yield takeEvery(FETCH_FAVORITE_LOCATIONS, fetchFavoriteLocations);
  yield takeEvery(CREATE_VERIFIED_USER_PROFILE, createVerifiedUserProfile);
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
  yield takeEvery(GET_SPECIALTY_PREFERENCES, getSpecialtyPreferences);
  yield takeEvery(CREATE_SPECIALTY_PREFERENCES, createSpecialtyPreferences);
  yield takeEvery(GET_APPOINTMENT_PREFERENCES, getAppointmentPreferences);
  yield takeEvery(CREATE_APPOINTMENT_PREFERENCES, createAppointmentPreferences);
}

export { rootSaga as default };
