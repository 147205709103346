export const HOTJAR_TRACKING_LABEL = {
  booking: 'booking',
  cdp: 'cdp',
  srp: 'srp',
  bookingWidget: 'booking-widget',
  account: 'account',
  detect: 'detect',
  errorPage: 'error-page',
  otpLoginError: 'otp-login-error',
  pgProviderPage: 'pg-provider-page',
  pgLocationPage: 'pg-location-page',
  pgGroupPage: 'pg-group-page',
};
