import React, { PureComponent } from 'react';
import styled from 'styled-components';
import semver from 'semver';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { ErrorWithCause } from 'pony-cause';
import { colors } from '../../constants/colors';
import { emptyFunction } from '../../core/util/function';
import Logo from '../Logo';
import { fontFamily, fontSize } from '../../constants/text';
import { isNativeApp, getNativeAppVersionInUse } from '../../core/util/device';
import logger from '../../core/logger/index';
import { analyticsTrackEvent } from '../../core/analytics';
import { UPDATE_APP_MODAL_OPEN, UPDATE_APP_MODAL_CLOSE } from '../../core/analytics/events';
import { SOLV_APPLE_ID, SOLV_APPLE_PROVIDER_ID } from '../DownloadDriver/util';

export const UPDATE_APP_MODAL = 'update app modal';

const SmoothDialog = styled(Dialog)`
  -webkit-font-smoothing: antialiased;
  & .dialog-root {
    background-color: ${colors.whiteGrey};
  }
`;

const Root = styled.div`
  padding: 10%;
  height: 100%;
  overflow: hidden;
`;

const SolvLogo = styled(Logo)`
  > img {
    height: 40px;
  }
  margin-bottom: 50px;
`;

const Title = styled.h3`
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.extraExtraLarge};
`;
const Text = styled.p`
  font-size: ${fontSize.medium};
  color: ${colors.squid};
`;

const Cta = styled.a`
  font-size: ${fontSize.large};
  display: block;
  margin-top: 40px;
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 26px;
  top: 20px;
  cursor: pointer;

  &:before {
    content: '';
    left: 50%;
    height: 100%;
    width: 2px;
    position: absolute;
    transform: rotate(45deg);
    background-color: ${colors.squid};
  }

  &:after {
    content: '';
    left: 50%;
    height: 100%;
    width: 2px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: ${colors.squid};
  }
`;

type Props = {
  fullScreen?: boolean;
  onClose?: (...args: any[]) => any;
};

type State = any;

export class UpdateAppModal extends PureComponent<Props, State> {
  appVersionInUse: any;

  mustUpdate: any;

  mustUpdateVersion: any;

  shouldUpdate: any;

  shouldUpdateVersion: any;

  constructor(props: Props) {
    super(props);
    this.mustUpdate = false;
    this.shouldUpdate = false;
    this.mustUpdateVersion = null;
    this.shouldUpdateVersion = null;
    this.appVersionInUse = null;
  }

  state = {
    isOpen: false,
    canBeClosed: false,
  };

  componentDidMount = () => {
    try {
      if (this.shouldOpen()) {
        this.setState({
          isOpen: true,
        });

        analyticsTrackEvent(UPDATE_APP_MODAL_OPEN);
      }
    } catch (e) {
      logger.error(
        new ErrorWithCause('Error while trying to open the ios app update modal', { cause: e })
      );
    }
  };

  onClose = () => {
    this.setState({
      isOpen: false,
    });

    analyticsTrackEvent(UPDATE_APP_MODAL_CLOSE);
  };

  shouldOpen = () => {
    if (!isNativeApp()) {
      return false;
    }

    this.mustUpdateVersion = window.CFG.IOS_APP_MUST_UPDATE_MINIMUM_VERSION;
    this.shouldUpdateVersion = window.CFG.IOS_APP_SHOULD_UPDATE_MINIMUM_VERSION;
    this.appVersionInUse = getNativeAppVersionInUse();
    if (typeof this.mustUpdateVersion === 'string' && semver.valid(this.mustUpdateVersion)) {
      this.mustUpdate = semver.lt(this.appVersionInUse, this.mustUpdateVersion);
      return this.mustUpdate;
    }

    if (typeof this.shouldUpdateVersion === 'string' && semver.valid(this.shouldUpdateVersion)) {
      this.shouldUpdate = semver.lt(this.appVersionInUse, this.shouldUpdateVersion);
      if (this.shouldUpdate && !this.mustUpdate) {
        this.setState({ canBeClosed: true });
      }

      return this.shouldUpdate;
    }

    return false;
  };

  render() {
    const { fullScreen } = this.props;
    const { isOpen, canBeClosed } = this.state;
    const updateSeverity = this.mustUpdate ? 'must' : 'should';
    const versionToUpdateTo = this.mustUpdate ? this.mustUpdateVersion : this.shouldUpdateVersion;

    return (
      <SmoothDialog
        data-testid="updateAppModal"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={emptyFunction}
        open={isOpen}
        PaperProps={{ classes: { root: 'dialog-root' } }}
        scroll="body"
      >
        <Root data-testid="updateAppModalContent">
          {!this.mustUpdate && (
            <Close
              data-testid="updateAppModalCloseButton"
              onClick={canBeClosed ? this.onClose : emptyFunction}
            />
          )}
          <SolvLogo handleLogoOnClick={emptyFunction} />

          <Title>Update to continue</Title>

          <Text>
            Your Solv app is out of date. Update to the latest version today for an improved
            experience.
          </Text>

          <Cta
            href={`https://apps.apple.com/app/apple-store/id${SOLV_APPLE_ID}?pt=${SOLV_APPLE_PROVIDER_ID}&ct=${updateSeverity}_update_${versionToUpdateTo}&mt=8`}
            target="_blank"
          >
            Download the update now &rarr;
          </Cta>
        </Root>
      </SmoothDialog>
    );
  }
}

export default withMobileDialog({ breakpoint: 'xs' })(UpdateAppModal);
