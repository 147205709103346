import { TOOLTIP_SHOW, TOOLTIP_HIDE } from '../constants/index';

export default function toolTipReducer(state = {}, action: any) {
  switch (action.type) {
    case TOOLTIP_SHOW: {
      const newState = { ...state };
      if (!newState.hasOwnProperty(action.payload.value)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[action.payload.value] = {};
      }

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState[action.payload.value].visible = true;
      return newState;
    }

    case TOOLTIP_HIDE: {
      const newState = { ...state };
      if (!newState.hasOwnProperty(action.payload.value)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[action.payload.value] = {};
      }

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState[action.payload.value].visible = false;
      return newState;
    }

    default:
      return state;
  }
}
