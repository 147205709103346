import {
  WAIT_LIST_BOOKING_RECEIVED,
  WAIT_LIST_BOOKING_NOT_VISIBLE,
  WAIT_LIST_BOOKING_ERROR,
  WAIT_LIST_RECEIVED,
  CLEAR_WAIT_LIST,
  WAIT_LIST_ERROR,
  API_RESULT_STATUS_SUCCESS,
  API_RESULT_STATUS_ERROR,
  WAIT_LIST_CLEARED,
  INSURANCE_INFO_BY_BOOKING,
  INSURANCE_INFO_BY_BOOKING_ERROR,
  USER_PROFILE_BY_BOOKING,
  USER_PROFILE_BY_BOOKING_ERROR,
} from '../constants/index';
import { waitListResponseFormatter } from '../reducers/formatters/waitList';

export default function waitListReducer(state = {}, action: any) {
  switch (action.type) {
    case WAIT_LIST_BOOKING_RECEIVED:
      return {
        ...state,
        waitListBooking: {
          booking: action.payload.value,
          status: API_RESULT_STATUS_SUCCESS,
        },
      };
    case WAIT_LIST_BOOKING_NOT_VISIBLE:
      return {
        ...state,
        waitList: {
          waitList: [],
          status: WAIT_LIST_BOOKING_NOT_VISIBLE,
        },
      };
    case WAIT_LIST_BOOKING_ERROR:
      return {
        ...state,
        waitListBooking: {
          status: API_RESULT_STATUS_ERROR,
          error: action.payload.value,
        },
      };
    case WAIT_LIST_RECEIVED:
      return {
        ...state,
        waitList: {
          waitList: action.payload.value.map(waitListResponseFormatter),
          status: API_RESULT_STATUS_SUCCESS,
        },
      };
    case CLEAR_WAIT_LIST:
      return {
        ...state,
        waitList: {
          waitList: [],
          status: WAIT_LIST_CLEARED,
        },
      };
    case WAIT_LIST_ERROR:
      return {
        ...state,
        waitList: {
          status: API_RESULT_STATUS_ERROR,
          error: action.payload.value,
        },
      };
    case INSURANCE_INFO_BY_BOOKING:
      return {
        ...state,
        bookingInsurance: action.payload.value,
      };
    case INSURANCE_INFO_BY_BOOKING_ERROR:
      return {
        ...state,
        bookingInsurance: { error: action.payload.value },
      };
    case USER_PROFILE_BY_BOOKING:
      return {
        ...state,
        userProfile: action.payload.value,
      };
    case USER_PROFILE_BY_BOOKING_ERROR:
      return {
        ...state,
        userProfile: { error: action.payload.value },
      };
    default:
      return state;
  }
}
