import moment from 'moment-timezone';
import { getNextBirthday } from '../../../../../core/util/date';
import { TileAction, TileRules } from '../../util/tileEnums';
import {
  DENTIST_VALUE,
  OB_GYN_VALUE,
  OPTOMETRIST_VALUE,
} from '../../../../Home/Tiles/ProviderTypes';
import { TileIds } from '../../util/tileEnums';

// jscs:disable
export const DEFAULT_REMINDER_INTERVAL = 7;
export const WOMEN_ONLY_COPY = 'for the ladies';

export const reminderStatus = {
  DONE: 'done',
  NOT_DONE: 'not_done',
  WILL_NOT_DO: 'will_not_do',
};

export class ReminderStatus {
  static DONE = 'done';

  static NOT_DONE = 'not_done';

  static WILL_NOT_DO = 'will_not_do';

  static getStatus = (reminder: any) => reminder && reminder.status;

  static getExpirationDate = (reminder: any) => reminder && reminder.expiration_date;

  static getNextReminderDate = (reminder: any) =>
    reminder &&
    reminder.reminder_dates &&
    moment(reminder.reminder_dates[0], 'YYYY-MM-DD').tz('utc').valueOf();

  static get complete() {
    return [ReminderStatus.DONE, ReminderStatus.WILL_NOT_DO];
  }
}

export const remindersSpec = {
  [TileIds.TEETH_CLEANING]: {
    title: 'Teeth Cleaning',
    description: 'Had one in the past 6 months?',
    sortIdx: 0,
    info: 'Talk to your Dentist about the best frequency of cleanings for you (typically twice a year).',
    recommendedBy: 'ADA',
    recommendedLogo: '/images/reminders/ada.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.SRP,
    providerType: DENTIST_VALUE,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last teeth cleaning?',
  },
  [TileIds.EYE_EXAMS]: {
    title: 'Eye Exam',
    description: 'Had one in the past two years?',
    sortIdx: 1,
    info: 'Talk to your Optometrist about the best frequency for you (typically every two years).',
    recommendedBy: 'AOA',
    recommendedLogo: '/images/reminders/AOA.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.SRP,
    providerType: OPTOMETRIST_VALUE,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last eye exam?',
  },
  [TileIds.ANNUAL_CHECK_UP_TWENTY]: {
    title: 'Annual Check Up',
    description: 'Had one in the past year?',
    sortIdx: 2,
    info: 'Talk to your Primary Care Provider about a Mental Health Assessment and High Blood Pressure Screening.',
    recommendedBy: 'USPSTF',
    recommendedLogo: '/images/reminders/US_preventive_services.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.COMING_SOON,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last check-up?',
  },
  [TileIds.ANNUAL_CHECK_UP_FORTY]: {
    title: 'Annual Check Up',
    description: 'Had one in the past year?',
    sortIdx: 2,
    info: 'Talk to your Primary Care Provider about a Mental Health Assessment, High Blood Pressure Screening, and Diabetes Screening.',
    recommendedBy: 'USPSTF',
    recommendedLogo: '/images/reminders/US_preventive_services.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.COMING_SOON,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last check-up?',
  },
  [TileIds.ANNUAL_CHECK_UP_FIFTY]: {
    title: 'Annual Check Up',
    description: 'Had one in the past year?',
    sortIdx: 2,
    info: 'Talk to your Primary Care Provider about a Mental Health Assessment, High Blood Pressure Screening, Diabetes Screening, and Shingles Vaccine.',
    recommendedBy: 'USPSTF',
    recommendedLogo: '/images/reminders/US_preventive_services.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.COMING_SOON,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last check-up?',
  },
  [TileIds.FLU_SHOT]: {
    title: 'Flu Shot',
    description: 'Had one in the past year?',
    sortIdx: 3,
    info: 'Typically recommended yearly during flu season (offered at most Urgent Care clinics).',
    recommendedBy: 'CDC',
    recommendedLogo: '/images/reminders/cdc.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    bookable: true,
    action: TileAction.RECENT_URGENT_CARE,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last flu shot?',
  },
  [TileIds.COLORECTA_CANCER_SCREENING]: {
    title: 'Colorectal Cancer Screening',
    description: 'Talk to your doctor about frequency.',
    sortIdx: 4,
    info: 'Talk to your Gastroenterologist about the best frequency for you.',
    recommendedBy: 'USPSTF',
    recommendedLogo: '/images/reminders/US_preventive_services.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.COMING_SOON,
    rules: [TileRules.IS_NOT_CHILD],
    whenWasYourLastTitle: 'When was your last screening?',
  },
  [TileIds.PAP_SMEAR]: {
    title: 'Pap Smear',
    description: 'Had one in the past 3 years?',
    sortIdx: 5,
    info: 'Talk to your Gynecologist about which tests you should be having and how often (typically every 3 years).',
    recommendedBy: 'USPSTF',
    recommendedLogo: '/images/reminders/US_preventive_services.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.SRP,
    providerType: OB_GYN_VALUE,
    rules: [TileRules.IS_WOMAN_AGE_18_65],
    whenWasYourLastTitle: 'When was your last pap smear?',
  },
  [TileIds.MAMMOGRAM]: {
    title: 'Mammogram',
    description: 'Talk to your doctor about frequency.',
    sortIdx: 6,
    info: 'Talk to your Gynecologist about the best frequency for you (typically every other year).',
    recommendedBy: 'USPSTF',
    recommendedLogo: '/images/reminders/US_preventive_services.png',
    remindMeCopy: 'Send me a reminder to book this appointment.',
    action: TileAction.SRP,
    providerType: OB_GYN_VALUE,
    rules: [TileRules.IS_NOT_CHILD, TileRules.IS_NOT_MALE],
    whenWasYourLastTitle: 'When was your last mammogram?',
  },
};

export const reminderLists = {
  twenty_to_thirtynine: [
    TileIds.ANNUAL_CHECK_UP_TWENTY,
    // TODO - add back during flu season
    // TileIds.FLU_SHOT,
    TileIds.TEETH_CLEANING,
    TileIds.EYE_EXAMS,
    TileIds.PAP_SMEAR,
  ],
  forty_to_fortynine: [
    TileIds.ANNUAL_CHECK_UP_FORTY,
    // TODO - add back during flu season
    // TileIds.FLU_SHOT,
    TileIds.TEETH_CLEANING,
    TileIds.EYE_EXAMS,
    TileIds.PAP_SMEAR,
  ],
  fifty_plus: [
    TileIds.ANNUAL_CHECK_UP_FIFTY,
    // TODO - add back during flu season
    // TileIds.FLU_SHOT,
    TileIds.TEETH_CLEANING,
    TileIds.EYE_EXAMS,
    TileIds.COLORECTA_CANCER_SCREENING,
    TileIds.PAP_SMEAR,
    TileIds.MAMMOGRAM,
  ],
};

export const getRemindersForAge = (age: any) => {
  if (age >= 20 && age <= 39) {
    return reminderLists.twenty_to_thirtynine;
  }
  if (age >= 40 && age <= 49) {
    return reminderLists.forty_to_fortynine;
  }
  if (age >= 50) {
    return reminderLists.fifty_plus;
  }
  return reminderLists.twenty_to_thirtynine;
};

export const getLastReminderSendDate = (firstReminderDelay = DEFAULT_REMINDER_INTERVAL) =>
  moment()
    .add(firstReminderDelay + 2 * DEFAULT_REMINDER_INTERVAL, 'days')
    .format('YYYY-MM-DD');

export const getExpirationDate = (birthDate: any, firstReminderDelay: any) =>
  getLastReminderSendDate(firstReminderDelay);
