import { SolvReduxState } from '~/reducers';
import { isUndefined, safeGet } from './object';
import { isClientSide } from './system';

const getAccountId = ({ account, newBooking }: Partial<SolvReduxState>) => {
  // 1. user is logged in
  if (!isUndefined(account?.summary?.id)) {
    return account?.summary?.id;
  }
  if (!isUndefined(newBooking?.booking?.userProfile?.account_id)) {
    // 2. user has just booked an appointment, so we have account data embedded in the booking data
    return newBooking?.booking?.userProfile?.account_id;
  }

  return '';
};

const getUserProfileId = ({ newBooking }: Partial<SolvReduxState>) => {
  // 1. user is logged in and on the profile page
  // Note: if they're simply logged in, we can't guess what profile they're adding the insurance for
  if (isClientSide() && window.location.pathname.includes('account/home')) {
    const urlParts = window.location.pathname.split('/');
    return urlParts[3]; // example path: /account/home/vkGmVe/get-care
  }
  if (newBooking?.booking?.userProfileId) {
    // 2. user has just booked an appointment, so we have user profile data inside the booking data
    return newBooking?.booking?.userProfileId;
  }
  if (isClientSide() && window.location.href.includes('up?p')) {
    // 3. the user is on the addInsurance flow triggered from the profile page,
    // so we have the userProfileId in the url
    return window.location.search.split('p=')[1];
  }

  return '';
};

const getPhone = ({ newBooking }: Partial<SolvReduxState>) => newBooking?.profile.phone;

const getFirstName = ({ newBooking }: Partial<SolvReduxState>) => newBooking?.profile.firstName;

const getLastName = ({ newBooking }: Partial<SolvReduxState>) => newBooking?.profile.lastName;

const getDob = ({ newBooking }: Partial<SolvReduxState>) => newBooking?.profile.birthDate;

const getCurrentUser = (state: Partial<SolvReduxState> = {}) => {
  const phone = getPhone(state);
  const firstName = getFirstName(state);
  const lastName = getLastName(state);
  const dob = getDob(state);

  const accountId = getAccountId(state);
  const userProfileId = getUserProfileId(state);

  return {
    phone,
    firstName,
    lastName,
    dob,
    accountId,
    userProfileId,
  };
};

export { getCurrentUser };
