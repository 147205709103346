import { Alert, Button, Link, PageHeader, Stack, Text, TextField } from '@solvhealth/jigsaw';
// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { createForm, formShape } from 'rc-form';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import { RuntimeState } from '~/reducers/runtime';
import { UNFREEZE_TIME_MINUTES } from '~/constants/phoneLoginForm';
import { analyticsTrackFormErrors } from '~/core/analytics';
import {
  PHONE_LOGIN_LAST_NAME_DOES_NOT_MATCH,
  PHONE_LOGIN_NUMBER_NOT_FOUND,
  PHONE_LOGIN_SUBMIT_ERROR,
} from '~/core/analytics/events';
import { isCdp } from '~/core/opengraph';
import { clearUserInfo } from '~/core/session';
import { formatLocationName } from '~/core/util/location';
import { isValidPhone } from '~/core/util/phoneNumber';
import { isString, stripNonNumeric } from '~/core/util/string';
import { getCurrentPath, willRedirectAfterLogin } from '~/core/util/url';
import { lastNameFormValidator, phoneNumberValidator } from '../OtpCodeLoginForm/util/validator';
import { JigsawMaskedPhoneTextField } from '../SolvPatternLibrary/MaskedTextField';
import {
  getIsStatusBookingIdMismatch,
  getIsStatusSubmitting,
  getIsStatusUnverified,
  PhoneVerificationStatus,
  VerificationStatus,
} from './util/verificationStatus';
import {
  isWithinSolvPlusBookingFlow,
  willRedirectToSolvPlusFlowsAfterLogin,
} from '../Membership/utils';
import dataTestIds from '~/test/test-ids';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';

const ACCOUNT_NOT_FOUND = 'Account not found';
const PHONE_FIELD_NAME = 'phone';
const LAST_NAME_FIELD_NAME = 'lastName';

export const HEADER_TYPES = {
  DEFAULT: 'default header',
  THANKS_FOR_FEEDBACK: 'thanks for feedback header',
};

const getBookingIdFromUrl = () => {
  const url = window?.location?.pathname;
  if (url) {
    const videoVisitMatch = url.match(/\/video-visit\/[a-zA-Z0-9]{6}/);
    if (videoVisitMatch && videoVisitMatch.length > 0) {
      return videoVisitMatch[0].split('/')[2];
    }
  }

  return null;
};

type Props = {
  form: formShape;
  handleFormSubmit: (...args: any[]) => any;
  isBookingWidgetEmbedded?: boolean;
  hideLoginModal: (...args: any[]) => any;
  setVerificationStatus: (...args: any[]) => any;
  resetLoginData: (...args: any[]) => any;
  verificationStatus?: VerificationStatus;
  verifyPhone: (...args: any[]) => any;
  login?: any;
  location?: any;
  initialValues?: any;
  isLoginModalOpen?: boolean;
  cookies?: any;
  t: TFunction<['common', 'bookingFlow']>;
  runtime: RuntimeState;
  isBookingFlow?: boolean;
  externalSubmit?: boolean;
  resetExternalSubmit?: () => void;
  onPhoneChange?: (phone: string) => void;
  setSubmitDisabled?: (disabled: boolean) => void;
  /** If set, overrides the otherwise provided handleFormSubmit behavior and does not trigger OTP validation. This
   * is used to treat this form as plain input form & bypass the phone input verification behavior. */
  overrideHandleFormSubmit?: (...args: any[]) => any;
};

type FieldErrors = {
  [PHONE_FIELD_NAME]: any;
  [LAST_NAME_FIELD_NAME]: any;
};

const PhoneLoginForm = ({
  initialValues = {},
  login = { login: {} },
  verificationStatus,
  resetLoginData,
  cookies,
  setVerificationStatus,
  form,
  verifyPhone,
  onPhoneChange,
  handleFormSubmit,
  overrideHandleFormSubmit,
  setSubmitDisabled,
  externalSubmit,
  resetExternalSubmit,
  t,
  location,
  runtime,
  isBookingFlow,
}: Props) => {
  const [errors, setErrors] = useState<FieldErrors>({
    [PHONE_FIELD_NAME]: { errors: '' },
    [LAST_NAME_FIELD_NAME]: { errors: '' },
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [verificationPromise, setVerificationPromise] = useState<Promise<any>>(Promise.resolve());
  const formRef = useRef<HTMLFormElement>(null);
  const [previousInputLength, setPreviousInputLength] = useState(
    initialValues[PHONE_FIELD_NAME]?.length || 0
  );

  const enableFormSubmissionIfNoErrors = (errors: any) => {
    if (isEmpty(errors)) {
      if (submitButtonDisabled) {
        setSubmitDisabled?.(false);
        setSubmitButtonDisabled(false);
        if (previousInputLength < 9) {
          // Autosubmit if phone number is autopopulated
          formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      }
    } else {
      if (!submitButtonDisabled) {
        setSubmitDisabled?.(true);
        setSubmitButtonDisabled(true);
      }
    }
  };

  const onPhoneFieldValid = (rawValue: any) => {
    const value = stripNonNumeric(String(rawValue));
    const bookingId = getBookingIdFromUrl();
    if (value !== verificationStatus?.phoneNumber) {
      setVerificationStatus(value, PhoneVerificationStatus.WAITING);
      setVerificationPromise(verifyPhone(value, bookingId));
    }
  };

  const onFieldChange = async (value: any, fieldName: any) => {
    if (fieldName === PHONE_FIELD_NAME) {
      onPhoneChange?.(value);
      if (isValidPhone(value)) {
        onPhoneFieldValid(value);
      }
    }

    setErrors((prevState: any) => ({ ...prevState, [fieldName]: false }));
    form.setFieldsValue({ [fieldName]: value });
    form.validateFields(enableFormSubmissionIfNoErrors);
    setPreviousInputLength(stripNonNumeric(value).length);
  };

  const onBlur = (fieldName: any) => {
    form.validateFields([fieldName], {}, (errors: any) => {
      enableFormSubmissionIfNoErrors(errors);

      if (isEmpty(errors)) {
        setErrors((prevState: any) => ({ ...prevState, [fieldName]: false }));
      } else {
        setErrors((prevState: any) => ({ ...prevState, [fieldName]: errors[fieldName] }));
      }
    });
  };

  const onSubmitError = useCallback(
    (error: any) => {
      const lastNameError =
        error === PHONE_LOGIN_NUMBER_NOT_FOUND ? PHONE_LOGIN_LAST_NAME_DOES_NOT_MATCH : '';
      setSubmitting(false);
      setErrors({ [PHONE_FIELD_NAME]: error, [LAST_NAME_FIELD_NAME]: lastNameError });
    },
    [setErrors, setSubmitting]
  );

  const onSubmit = useCallback(
    (e: any) => {
      e && e.preventDefault();

      setSubmitting(true);
      form.validateFields((errors: any, values: any) => {
        if (isEmpty(errors)) {
          return verificationPromise.then(() => {
            if (overrideHandleFormSubmit) {
              overrideHandleFormSubmit();
              return;
            }

            if (
              getIsStatusSubmitting(verificationStatus) &&
              getIsStatusUnverified(verificationStatus)
            ) {
              setSubmitting(false);
            } else {
              handleFormSubmit(values, 'sms', onSubmitError);
            }
          });
        }

        setSubmitting(false);
        analyticsTrackFormErrors(PHONE_LOGIN_SUBMIT_ERROR, {
          errors,
        });
        return null;
      });
    },
    [
      form,
      handleFormSubmit,
      onSubmitError,
      overrideHandleFormSubmit,
      verificationPromise,
      verificationStatus,
    ]
  );

  useEffect(
    () => {
      resetLoginData?.();
      clearUserInfo(cookies);
      const phoneFieldValue = initialValues[PHONE_FIELD_NAME] || '';
      setVerificationStatus?.(phoneFieldValue, PhoneVerificationStatus.WAITING);
      const bookingId = getBookingIdFromUrl();
      if (!isEmpty(phoneFieldValue)) {
        setVerificationPromise(verifyPhone?.(phoneFieldValue, bookingId));
        form.validateFields(enableFormSubmissionIfNoErrors);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  ); // We only want to run this on mount

  useEffect(() => {
    if (externalSubmit) {
      resetExternalSubmit?.();
      onSubmit(null);
    }
  }, [externalSubmit, onSubmit, resetExternalSubmit]);

  const getErrorState = () => {
    if (errors[LAST_NAME_FIELD_NAME] === PHONE_LOGIN_LAST_NAME_DOES_NOT_MATCH) {
      return false;
    }
    return Boolean(errors[PHONE_FIELD_NAME]);
  };

  const getPhoneFieldHelperText = () => {
    if (!isEmpty(errors[PHONE_FIELD_NAME])) {
      const errorDetail = login?.login?.login?.otp_error_detail;
      const accountFrozen = login?.login?.accountFrozen;
      if (errorDetail && errorDetail !== ACCOUNT_NOT_FOUND) {
        return t('common:errors.generic');
      }
      if (accountFrozen) {
        return t('common:auth.errors.accountFrozen', { count: UNFREEZE_TIME_MINUTES });
      }
    }
    return errors[PHONE_FIELD_NAME].errors?.[0]?.message || '';
  };

  const getLastNameError = () => {
    const fieldErrors = errors?.[LAST_NAME_FIELD_NAME];
    if (isString(fieldErrors)) {
      return fieldErrors;
    }
    return !isEmpty(fieldErrors) ? fieldErrors?.errors?.[0]?.message : '';
  };

  const shouldShowClinicDisclaimer = () => {
    return (
      !isCdp(getCurrentPath()) &&
      !isEmpty(location) &&
      location.displayNamePrimary &&
      !willRedirectToSolvPlusFlowsAfterLogin() &&
      !isWithinSolvPlusBookingFlow()
    );
  };

  const isFeedbackMode = () => runtime?.loginHeaderType === HEADER_TYPES.THANKS_FOR_FEEDBACK;

  const shouldShowLastNameVerificationTrouble = () =>
    getLastNameError() === PHONE_LOGIN_LAST_NAME_DOES_NOT_MATCH;

  const getPageTitle = (): string => {
    if (isBookingFlow) {
      return t('bookingFlow:numberLookup.title');
    }
    if (isFeedbackMode()) {
      return t('common:auth.phoneForm.feedbackMode.title');
    }
    if (willRedirectAfterLogin()) {
      return t('common:auth.phoneForm.redirectTitle');
    }
    return t('common:auth.phoneForm.title');
  };

  const phone = initialValues[PHONE_FIELD_NAME] || '';

  const getSubtitle = () => {
    if (isBookingFlow) {
      return t('bookingFlow:numberLookup.subtitle');
    }
    if (isFeedbackMode()) {
      return t('common:auth.phoneForm.feedbackMode.subtitle');
    }
    return t('common:auth.phoneForm.subtitle');
  };

  return (
    <Stack
      as="form"
      maxWidth="512px"
      onSubmit={onSubmit}
      ref={formRef}
      space={4}
      {...(!isBookingFlow && { pt: 4, px: 2, mx: 'auto' })}
      width="100%"
    >
      <PageHeader subtitle={getSubtitle()} title={getPageTitle()} />

      <Stack space={3} width="100%">
        <JigsawMaskedPhoneTextField
          {...form.getFieldProps(PHONE_FIELD_NAME, {
            rules: [
              {
                required: true,
                validator: phoneNumberValidator,
              },
            ],
            initialValue: phone,
          })}
          autoComplete="tel-national"
          autoFocus={!phone}
          data-hj-suppress
          data-testid={dataTestIds.login.phoneEnter}
          error={getErrorState() ? getPhoneFieldHelperText() : undefined}
          helperText={
            getIsStatusUnverified(verificationStatus) && t('common:auth.phoneForm.newToSolv')
          }
          id="tel"
          name="tel"
          onBlur={() => onBlur(PHONE_FIELD_NAME)}
          onChange={({ target: { value } }: any) => onFieldChange(value, PHONE_FIELD_NAME)}
          placeholder="(555) 555-5555"
          style={{ height: 'auto' }}
          title={t('common:auth.phoneForm.fieldTitle')}
          type="tel"
        />

        {getIsStatusUnverified(verificationStatus) && (
          <TextField
            {...form.getFieldProps(LAST_NAME_FIELD_NAME, {
              rules: [{ required: true, validator: lastNameFormValidator }],
              initialValue: initialValues[LAST_NAME_FIELD_NAME] || '',
            })}
            autoComplete="family-name"
            data-hj-suppress
            data-testid="lastName"
            error={getLastNameError()}
            fullWidth
            onBlur={() => onBlur(LAST_NAME_FIELD_NAME)}
            onChange={({ target: { value } }: any) => onFieldChange(value, LAST_NAME_FIELD_NAME)}
            style={{ height: 'auto' }}
            title={t('common:auth.phoneForm.lasteNameField')}
          />
        )}

        <Alert
          actions={[
            <Alert.Action goBack key="0">
              {t('common:auth.errors.cancelYourVisit')}
            </Alert.Action>,
          ]}
          show={getIsStatusBookingIdMismatch(verificationStatus)}
          title={t('common:auth.errors.phoneMismatchTitle')}
          tone="critical"
          variant="inline"
        >
          {t('common:auth.errors.phoneMismatch')}
        </Alert>
      </Stack>
      {!isBookingFlow && (
        <Button
          data-testid={dataTestIds.login.phoneSubmit}
          fullWidth
          loading={submitting}
          size="large"
          type="submit"
        >
          {t('common:auth.phoneForm.cta')}
        </Button>
      )}
      {shouldShowLastNameVerificationTrouble() ? (
        <Text as="div" muted>
          <Trans i18nKey="common:auth.errors.havingTrouble" t={t}>
            <Link href="mailto:info@solvhealth.com" inline>
              info@solvhealth.com
            </Link>
          </Trans>
        </Text>
      ) : (
        shouldShowClinicDisclaimer() && (
          <Text as="div" muted>
            <Trans
              i18nKey="common:auth.clinicDisclaimer"
              t={t}
              values={{ clinicName: formatLocationName(location) }}
            >
              {/* empty elements used by Trans component to display disclaimer */}
              <Text as="span" fontWeight="medium" muted></Text>
              <Text as="span" fontWeight="medium" muted></Text>
            </Trans>
          </Text>
        )
      )}
    </Stack>
  );
};

export default createForm()(withTranslation()(PhoneLoginForm));
