import React, { useMemo, useContext, PropsWithChildren } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import styled, { css } from 'styled-components';
import { colors } from '../../../constants/colors';
import CloseIcon from '../Icons/Close';
import withThemeProvider from '../util/withThemeProvider';
import { requiresBottomSafeArea, useUserAgent } from '../../../core/util/device';
import AuthenticationContext from '../../../core/login/AuthenticationContext';

const Modal = styled(({ isLoggedIn, needsSafeArea, ...rest }) => <Dialog {...rest} />)`
  & .paper-root {
    position: fixed;
    left: 0;
    bottom: ${(props) => (props.isLoggedIn && props.$shouldOffsetBottomNav ? 56 : 0)}px;
    padding-bottom: env(safe-area-inset-bottom, 20px);
    background-color: ${colors.theWhiteAlbum};
    margin: 0;
    height: fit-content;
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    ${({ $squared }) =>
      $squared &&
      css`
        border-radius: 0;
      `}
    ${(props) =>
      props.needsSafeArea && // for iOS native
      css`
        padding-bottom: 24px;
      `};
  }
`;

const CloseContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  margin: 10px auto;
`;

export type SlideInBottomDialogProps = {
  onClose?: (event: React.MouseEvent<HTMLImageElement | HTMLDivElement>) => void;
  open: boolean;
  title?: string;
  shouldOffsetBottomNav?: boolean;
  squared?: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlideInBottomDialog = (props: PropsWithChildren<SlideInBottomDialogProps>) => {
  const { children, onClose, open, title, shouldOffsetBottomNav = true, squared = true } = props;
  const { isLoggedIn } = useContext(AuthenticationContext);
  const userAgent = useUserAgent();

  return (
    <Modal
      $shouldOffsetBottomNav={shouldOffsetBottomNav}
      $squared={squared}
      aria-labelledby="dialog-title"
      fullWidth
      isLoggedIn={isLoggedIn}
      needsSafeArea={useMemo(() => requiresBottomSafeArea(userAgent), [userAgent])}
      onClose={onClose}
      open={open}
      PaperProps={{ classes: { root: 'paper-root' } }}
      TransitionComponent={Transition}
      transitionDuration={250}
    >
      {onClose && (
        <CloseContainer>
          <CloseIcon onClick={onClose} />
        </CloseContainer>
      )}
      {title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Modal>
  );
};

export default withThemeProvider(SlideInBottomDialog);
