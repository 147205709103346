import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { profileCanAccessPremium } from '~/components/Membership/utils';
import { Actions, Events } from '../../components/Account/util/analytics';
import { BIRTH_DATE_FORMAT, DAPI_HOST } from '../../config/index';
import {
  AccountOrigins,
  APP_INTERACTION_ACTIONS,
  APP_INTERACTION_TYPES,
  BIRTHSEX_TYPE_FEMALE,
  BIRTHSEX_TYPE_MALE,
  BIRTH_SEX_OTHER,
  ContactTypes,
  MARKETPLACE_DFW,
  RELATIONSHIP_TYPE_CHILD,
  RELATIONSHIP_TYPE_OTHER,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_SELF,
  RELATIONSHIP_TYPE_SIBLING,
  RELATIONSHIP_TYPE_SPOUSE,
} from '../../constants/index';
import { isValidDateString, sortByCreatedDateDesc } from '../../core/util/date';
import { convertCamelToSnakeCase, isEmptyObject } from '../../core/util/object';
import { analyticsTrackEvent } from '../analytics';
import { isEmptyArray } from './array';
import { isNativeApp } from './device';
import { isEmpty } from './empty';
import { isInvoicePending } from './payment';
import { isEmptyString, queryStringFromObject } from './string';
import { isClientSide } from './system';

export const RELATIONSHIP_OPTIONS = [
  '',
  RELATIONSHIP_TYPE_SELF,
  RELATIONSHIP_TYPE_CHILD,
  RELATIONSHIP_TYPE_SPOUSE,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_SIBLING,
  RELATIONSHIP_TYPE_OTHER,
];

export const RELATIONSHIP_ALLOW_UNDERAGE_TYPES = [
  RELATIONSHIP_TYPE_CHILD.toLowerCase(),
  RELATIONSHIP_TYPE_SIBLING.toLowerCase(),
  RELATIONSHIP_TYPE_OTHER.toLowerCase(),
];

export const BIRTH_SEX_OPTIONS = ['', BIRTHSEX_TYPE_FEMALE, BIRTHSEX_TYPE_MALE, BIRTH_SEX_OTHER];

const SELF = RELATIONSHIP_TYPE_SELF.toLowerCase();

export const PINK_DOOR_EXCEPTIONS = [
  /\/video-visit/,
  /\/photo-id-upload/,
  /\/payments/,
  /\/lab-results/,
  /\/paperwork/,
  /\/pass/,
  // These two paths must be used as exceptions to avoid re-showing
  // the ToS modal when viewing them
  /\/privacy/,
  /\/legal\/tos/,
];

const getProfileFromAccountSummary = (accountSummary: any, userProfileId: any) => {
  if (accountSummary && accountSummary.user_profiles) {
    const profiles = accountSummary.user_profiles;

    for (const profileIdx in profiles) {
      if (
        profiles.hasOwnProperty(profileIdx) &&
        profiles[profileIdx].user_profile_id === userProfileId
      ) {
        return accountSummary.user_profiles[profileIdx];
      }
    }
  }

  return null;
};

/**
 *
 * @param {object} location
 * @param {{account_favorite_locations}} accountSummary
 * @returns {boolean}
 */
const isSavedLocation = (location: any, accountSummary: any) => {
  const { id } = location;
  return (
    accountSummary &&
    Array.isArray(accountSummary.account_favorite_locations) &&
    accountSummary.account_favorite_locations.includes(id)
  );
};

const filterHiddenProfiles = (summary: any) => {
  const filteredSummary = cloneDeep(summary);
  filteredSummary.user_profiles = filteredSummary.user_profiles.filter(
    (userProfile: any) => !userProfile.is_hidden
  );
  return filteredSummary;
};

const deNormalizeMarketPlace = (summary: any) => {
  const deNormalized = { ...summary };
  if (deNormalized && deNormalized.user_profiles) {
    deNormalized.user_profiles = deNormalized.user_profiles.map((profile: any) => ({
      ...profile,
      market: deNormalized.market,
    }));
  }

  return deNormalized;
};

const getProfileFromAccountSummaryMatchingAccountHolder = (accountSummary: any) => {
  let userProfile = null;
  if (accountSummary && accountSummary.user_profiles) {
    const firstName = accountSummary.first_name;
    const lastName = accountSummary.last_name;

    accountSummary.user_profiles.some((profile: any) => {
      if (profile.first_name === firstName && profile.last_name === lastName) {
        userProfile = profile;
        return true;
      }

      return false;
    });
  }

  return userProfile;
};

const getFullNameFromCamelCaseProps = (props: any) => `${props.firstName} ${props.lastName}`;

const getFullNameFromSnakeCaseProps = (props: any) => `${props.first_name} ${props.last_name}`;

const getFavoriteFromProfileByLocationId = (
  accountSummary: any,
  userProfileId: any,
  locationId: any
) => {
  const userProfile = getProfileFromAccountSummary(accountSummary, userProfileId);
  for (const favorite of userProfile.location_favorites) {
    if (favorite.location_id === locationId) {
      return favorite;
    }
  }

  return null;
};

const getMostRecentBooking = (object: any) => object?.bookings?.most_recent;
const hasProfilePhoto = (userProfile: any) =>
  !isEmpty(userProfile) && !isEmpty(userProfile.photo_upload_date);

const getLastProfilePhotoUploadDate = (userProfile: any) =>
  userProfile && userProfile.photo_upload_date && moment(userProfile.photo_upload_date).valueOf();

const profileIsChild = (userProfile: any) => {
  if (isEmptyObject(userProfile)) {
    return false;
  }

  const userBirthDate = userProfile.birth_date || userProfile.birthDate;

  return (
    userBirthDate &&
    moment(userBirthDate, BIRTH_DATE_FORMAT).isAfter(moment().subtract(18, 'years'))
  );
};

const doesProfileRequireVerification = (userProfile: any) =>
  userProfile && !userProfile.is_verified;
const isProfileHidden = (userProfile: any) => userProfile && userProfile.is_hidden;

const calculateDefaultRelationshipType = (userProfile: any) => {
  if (isEmptyObject(userProfile)) {
    return RELATIONSHIP_TYPE_SELF.toLowerCase();
  }

  if (userProfile.relationship_to_account) {
    return userProfile.relationship_to_account;
  }

  if (profileIsChild(userProfile)) {
    return RELATIONSHIP_TYPE_CHILD.toLowerCase();
  }

  return RELATIONSHIP_TYPE_SELF.toLowerCase();
};

const hasSufficientDataToDisplayInsuranceProfile = (profile: any) =>
  !isEmptyObject(profile) &&
  profile.member_code !== null &&
  profile.insurer_type !== null &&
  profile.first_name !== null &&
  profile.last_name !== null;

const hasSufficientDataToDisplayDentalInsuranceProfile = (profile: any) =>
  profile.insurer_name !== null;

const hasSufficientDataToDisplayVisionInsuranceProfile = (profile: any) =>
  profile.insurer_name !== null;

const getFirstValidInsuranceProfile = (insuranceProfiles: any) =>
  insuranceProfiles.find((profile: any) => hasSufficientDataToDisplayInsuranceProfile(profile));

const getInsuranceProfileById = (insuranceProfiles: any, insuranceProfileId: any) =>
  insuranceProfiles.find((profile: any) => profile.insurance_profile_id === insuranceProfileId);

const getHealthInsuranceProfileByProfileId = (summary: any, userProfileId: any) => {
  const userProfile = getProfileFromAccountSummary(summary, userProfileId);

  if (!summary || !summary.insurance_profiles || !summary.user_profiles) {
    return null;
  }

  if (!userProfile || !userProfile.insurance_profile_id) {
    return null;
  }

  return getInsuranceProfileById(summary.insurance_profiles, userProfile.insurance_profile_id);
};

const getDentalInsuranceProfileByProfileId = (summary: any, userProfileId: any) => {
  const userProfile = getProfileFromAccountSummary(summary, userProfileId);

  if (!summary || !summary.insurance_profiles || !summary.user_profiles) {
    return null;
  }

  if (!userProfile || !userProfile.dental_insurance_profile_id) {
    return null;
  }

  return getInsuranceProfileById(
    summary.insurance_profiles,
    userProfile.dental_insurance_profile_id
  );
};

const getInsuranceProfile = (summary: any) => {
  let insuranceProfileId;

  // If there is most recent booking, try to get the insurance profile id from there
  if (!isEmptyObject(summary.bookings.most_recent)) {
    insuranceProfileId = summary.bookings.most_recent.insurance_profile_id;
  }

  if (insuranceProfileId === null) {
    // Deep search for any insurance profile with insurance information
    return getFirstValidInsuranceProfile(summary.insurance_profiles);
  }

  const recentInsuranceProfile = getInsuranceProfileById(
    summary.insurance_profiles,
    insuranceProfileId
  );

  return hasSufficientDataToDisplayInsuranceProfile(recentInsuranceProfile)
    ? recentInsuranceProfile
    : getFirstValidInsuranceProfile(summary.insurance_profiles);
};

const getActiveProfileFromState = (state: any) => {
  let userProfile = null;
  if (!isEmptyObject(state.account.summary)) {
    if (!isEmptyObject(state.account.summary.bookings.most_recent)) {
      const userProfileId =
        state.newBooking.booking.userProfileId ||
        state.newBooking.profile.userProfileId ||
        state.account.summary.bookings.most_recent.user_profile_id;
      userProfile = getProfileFromAccountSummary(state.account.summary, userProfileId);
    } else if (!isEmptyObject(state.account.summary.user_profiles[0])) {
      [userProfile] = state.account.summary.user_profiles;
    }
  }

  return userProfile;
};

const getActiveProfileFromFullSummaryInState = (state: any) => {
  let userProfile = null;
  if (!isEmptyObject(state.account.summary)) {
    const userProfileId =
      state.newBooking.booking.userProfileId ||
      state.account.summary.bookings.most_recent.user_profile_id;
    userProfile = getProfileFromAccountSummary(state.account.fullSummary, userProfileId);
  }

  return userProfile;
};

const getActiveProfileFromAccountSummary = (accountSummary: any) => {
  let userProfile = null;
  if (!isEmptyObject(accountSummary)) {
    if (!isEmptyObject(accountSummary.bookings.most_recent)) {
      const userProfileId = accountSummary.bookings.most_recent.user_profile_id;
      userProfile = getProfileFromAccountSummary(accountSummary, userProfileId);
    } else if (!isEmptyArray(accountSummary.user_profiles)) {
      userProfile = accountSummary.user_profiles.find((profile: any) => profile.is_verified);
    }
  }

  return userProfile;
};

const getDisplayableUserProfilesInAccountSummary = (accountSummary: any) => {
  const userProfiles = accountSummary?.user_profiles ?? [];
  return userProfiles.filter(
    (profile: any) => profile && profile.is_verified && profile.active && !profile.is_hidden
  );
};

const isOldEnoughToCreateAccount = (birthDate: any) => {
  // default to true
  if (!isValidDateString(birthDate)) {
    return true;
  }

  const birthDateMoment = moment(birthDate, BIRTH_DATE_FORMAT);

  if (!birthDateMoment.isValid()) {
    return true;
  }

  return birthDateMoment.isBefore(moment().subtract(13, 'years'));
};

const getOnboardingNextPathUrl = (partnerType: any, userProfileId: any) =>
  partnerType === 'opaque' || partnerType === null
    ? `/account/people/${userProfileId}#insurance-details`
    : `/account/favorite/${userProfileId}`;

const numUnpaidBills = (pastAppointments: any) =>
  Array.isArray(pastAppointments) &&
  pastAppointments.filter((appt) => isInvoicePending(appt.invoice_status)).length;

const hasUnpaidBills = (numUnpaidBills: any) => numUnpaidBills > 0;

const getFirstProfileFromAccountSummary = (accountSummary: any) => {
  if (accountSummary && accountSummary.user_profiles)
    for (const profileIdx in accountSummary.user_profiles) {
      if (
        accountSummary.user_profiles.hasOwnProperty(profileIdx) &&
        accountSummary.user_profiles[profileIdx].is_verified
      )
        return accountSummary.user_profiles[profileIdx];
    }

  return null;
};

const getFirstProfileIdFromAccountSummary = (accountSummary: any) => {
  const profile = getFirstProfileFromAccountSummary(accountSummary);
  return profile && profile.user_profile_id;
};

const isAccountInMarketPlace = (accountSummary: any) => accountSummary?.market === MARKETPLACE_DFW;
const getAccountImagesUrl = (params: any) => {
  const obj = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
      obj[key] = value;
    }
  });

  const snaked = convertCamelToSnakeCase(obj);
  return `${DAPI_HOST}/v1/images/account?${queryStringFromObject(snaked)}`;
};

const hasAddressInfo = (userProfile: any) =>
  userProfile.address_street &&
  userProfile.address_city &&
  userProfile.address_state &&
  userProfile.address_zip;

const hasBasicInfo = (userProfile: any) =>
  userProfile.first_name ||
  userProfile.last_name ||
  userProfile.birth_date ||
  userProfile.relationship_to_account ||
  userProfile.birth_sex ||
  userProfile.gender;

const hasPharmacyInfo = (userProfile: any) => userProfile.pharmacy;

const hasInsuranceInfo = (userProfile: any) => userProfile.insurance_profile_id;

const hasMultipleProfiles = (accountSummary: any) => {
  const nonHiddenProfiles = filterHiddenProfiles(accountSummary).user_profiles;

  return nonHiddenProfiles && nonHiddenProfiles.length > 1;
};

const getCityStateZipStr = (userProfile: any) => {
  const { address_city: city, address_state: state, address_zip: zip } = userProfile;
  if (hasAddressInfo(userProfile)) {
    return state && `${city}, ${state} ${zip}`;
  }
  return null;
};

const getPharmacyParts = (pharmacyStr: any) => {
  if (isEmptyString(pharmacyStr)) return {};

  const [first, ...second] = pharmacyStr.split(', ');
  return { first, ...(second && { second: second.join(', ') }) };
};

const getProfileCompletionPercentage = (userProfile: any) => {
  const completionStatuses = [
    hasBasicInfo(userProfile),
    hasAddressInfo(userProfile),
    hasPharmacyInfo(userProfile),
    hasProfilePhoto(userProfile),
  ];

  const numCompleted = completionStatuses.filter((status) => status).length;
  return Math.floor((numCompleted / completionStatuses.length) * 100);
};

const getIsSelfProfile = (userProfile: any) => userProfile.relationship_to_account === SELF;

const getHasSelfProfile = (userProfiles = []) =>
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'relationship_to_account' does not exist ... Remove this comment to see the full error message
  !!userProfiles.find((profile) => profile.relationship_to_account === SELF && profile.is_verified);

const getHasVerifiedProfilesWithoutRole = (userProfiles: any) =>
  !!userProfiles.find((profile: any) => profile.is_verified && !profile.relationship_to_account);

const getMostRecentBookingUserProfile = (accountSummary: any) => {
  const mostRecentBookingUserProfileId =
    accountSummary?.bookings?.most_recent?.user_profile_id ?? false;
  return getProfileFromAccountSummary(accountSummary, mostRecentBookingUserProfileId);
};

const getSelfProfile = (accountSummary: any) =>
  accountSummary.user_profiles.find((profile: any) => getIsSelfProfile(profile));

const userSignedUpForAccount = (accountSummary: any) =>
  accountSummary && accountSummary.origin === AccountOrigins.CONSUMER_SELF;

const needsToVerifyProfile = (accountSummary: any) => {
  const profile = getMostRecentBookingUserProfile(accountSummary);
  return doesProfileRequireVerification(profile);
};

const needsToSeeWelcomeFlow = (accountSummary: any) =>
  !isNativeApp() &&
  !userSignedUpForAccount(accountSummary) &&
  (isEmpty(accountSummary.solv_user_date) || isEmpty(accountSummary.onboarding_date));

const hasBooking = (userProfile: any) => {
  const recentBooking = getMostRecentBooking(userProfile);

  return recentBooking && recentBooking.active;
};

const getProfileFromBooking = (accountSummary: any, booking: any) => {
  const userProfileId = booking && booking.userProfileId;

  return getProfileFromAccountSummary(accountSummary, userProfileId);
};

const getMostRecentContactByType = (userProfile: any, type = ContactTypes.EMERGENCY) => {
  const contacts = userProfile.contacts || [];
  const contactsByType = contacts.filter((contact: any) => contact.contact_type === type);
  const mostRecent = contactsByType.sort(sortByCreatedDateDesc)[0];

  return mostRecent;
};

const getAppInteraction = (accountSummary: any, moduleType: any, action: any) =>
  (accountSummary?.app_interactions || []).find(
    (interaction: any) => interaction.module_type === moduleType && interaction.action === action
  );

const shouldShowInsuranceFeatureBadge = (
  accountSummary: any,
  userProfileId: any,
  featureTimestamp: any
) => {
  const insuranceProfile = getHealthInsuranceProfileByProfileId(accountSummary, userProfileId);
  if (
    !accountSummary ||
    !insuranceProfile ||
    insuranceProfile.eligibility_result !== 'true' ||
    !insuranceProfile?.facesheet?.deductible?.individual?.in_network
  ) {
    return false;
  }

  const appInteraction = getAppInteraction(
    accountSummary,
    APP_INTERACTION_TYPES.insuranceTab,
    APP_INTERACTION_ACTIONS.view
  );

  if (!appInteraction) {
    analyticsTrackEvent(Events.INSURANCE_BADGE, { action: Actions.BADGE_FIRST_VISIT });
    return true;
  }

  const lastInteraction = moment(appInteraction.created_date);

  if (insuranceProfile.last_change && lastInteraction < moment(insuranceProfile.last_change)) {
    analyticsTrackEvent(Events.INSURANCE_BADGE, { action: Actions.BADGE_DEDUCTIBLE_CHANGE });
    return true;
  }

  if (lastInteraction < moment(featureTimestamp)) {
    analyticsTrackEvent(Events.INSURANCE_BADGE, { action: Actions.BADGE_FEATURE_CHANGE });
    return true;
  }

  return false;
};

const isPinkDoorExceptionUrl = (url?: string) => {
  let testUrl = url;
  if (!testUrl && isClientSide()) {
    testUrl = window.location.pathname;
  }

  if (!testUrl) {
    return false;
  }

  return PINK_DOOR_EXCEPTIONS.some((url) => testUrl && url.test(testUrl));
};

const hasCrossedPinkDoor = (accountSummary: any): boolean | null => {
  if (!accountSummary) {
    return null;
  }

  return !!(accountSummary?.solv_user_date ?? accountSummary?.solvUserDate);
};

const getSolvUserDate = (accountSummary: any) =>
  accountSummary?.solv_user_date ?? accountSummary?.solvUserDate;

const didCrossPinkdoorAfterTimeSpecified = (accountSummary: any, timeSpecified: any) => {
  const solvUserDate = getSolvUserDate(accountSummary);
  const parsedSolvUserDate = solvUserDate ? moment(solvUserDate) : null;
  return parsedSolvUserDate && parsedSolvUserDate.isAfter(timeSpecified);
};

const isPhotoIdUploadedForUserProfile = (userProfile: any) =>
  userProfile && (userProfile.photo_id_front_id || userProfile.photo_id_back_id);

const isActiveMembership = (accountSummary: any): boolean => !!accountSummary?.is_active_membership;

const isTriageManuallyEnabled = (accountSummary: any): boolean =>
  !!accountSummary?.triage_manually_enabled ?? !!accountSummary.triageManuallyEnabled;

const isUserProfileCoveredByMembership = (accountSummary: any, userProfile: any): boolean =>
  isActiveMembership(accountSummary) && profileCanAccessPremium(userProfile);

const showChatEntrypoint = (
  featureFlagIsOn: boolean,
  accountSummary: any,
  userProfile: any,
  isPremiumBooking: boolean
): boolean =>
  featureFlagIsOn &&
  isUserProfileCoveredByMembership(accountSummary, userProfile) &&
  isPremiumBooking;

export {
  calculateDefaultRelationshipType,
  deNormalizeMarketPlace,
  didCrossPinkdoorAfterTimeSpecified,
  getActiveProfileFromFullSummaryInState,
  getActiveProfileFromState,
  getActiveProfileFromAccountSummary,
  getFavoriteFromProfileByLocationId,
  getMostRecentBooking,
  getFirstValidInsuranceProfile,
  getFirstProfileFromAccountSummary,
  getFirstProfileIdFromAccountSummary,
  getFullNameFromCamelCaseProps,
  getFullNameFromSnakeCaseProps,
  getInsuranceProfileById,
  getMostRecentContactByType,
  getOnboardingNextPathUrl,
  getDisplayableUserProfilesInAccountSummary,
  getProfileFromAccountSummary,
  getProfileFromAccountSummaryMatchingAccountHolder,
  getProfileFromBooking,
  hasCrossedPinkDoor,
  hasProfilePhoto,
  hasSufficientDataToDisplayInsuranceProfile,
  getSolvUserDate,
  hasUnpaidBills,
  isAccountInMarketPlace,
  isOldEnoughToCreateAccount,
  numUnpaidBills,
  profileIsChild,
  filterHiddenProfiles,
  doesProfileRequireVerification,
  isSavedLocation,
  isProfileHidden,
  getHealthInsuranceProfileByProfileId,
  getAccountImagesUrl,
  getLastProfilePhotoUploadDate,
  getCityStateZipStr,
  hasAddressInfo,
  hasPharmacyInfo,
  hasInsuranceInfo,
  hasMultipleProfiles,
  hasBasicInfo,
  getPharmacyParts,
  getProfileCompletionPercentage,
  hasSufficientDataToDisplayDentalInsuranceProfile,
  hasSufficientDataToDisplayVisionInsuranceProfile,
  getDentalInsuranceProfileByProfileId,
  getIsSelfProfile,
  getHasSelfProfile,
  getHasVerifiedProfilesWithoutRole,
  getMostRecentBookingUserProfile,
  getSelfProfile,
  needsToVerifyProfile,
  needsToSeeWelcomeFlow,
  hasBooking,
  userSignedUpForAccount,
  shouldShowInsuranceFeatureBadge,
  isPinkDoorExceptionUrl,
  isPhotoIdUploadedForUserProfile,
  isActiveMembership,
  isUserProfileCoveredByMembership,
  showChatEntrypoint,
  isTriageManuallyEnabled,
};
