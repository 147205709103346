import moment from 'moment';

export const formatDate = (
  birthDateYear: string,
  birthDateMonth: string,
  birthDateDay: number
): string => {
  return `${birthDateYear}-${moment().month(birthDateMonth).format('MM')}-${
    birthDateDay < 10 ? '0' : ''
  }${birthDateDay}`;
};
