import { TFunction } from 'react-i18next';
import moment from 'moment';
import { parseLatLongString } from '../../core/util/geo';
import { dateFormat, parseSqlDateTime } from '../../core/util/date';
import { isEmptyString } from '../../core/util/string';
import { isEmptyObject } from '../../core/util/object';
import { TIME_FORMAT } from '../../constants/index';
import { isMidnightish } from '../../core/util/time';

const openingHoursFormatter = (interval: any, t: TFunction<'bookingWidget'>) => {
  if (isEmptyObject(interval)) {
    return '';
  }

  const now = dateFormat(Date.now(), 'isoDate');
  const startTime = dateFormat(parseSqlDateTime(`${now} ${interval.fromTime}`), TIME_FORMAT);
  const endTime = isMidnightish(interval.toTime)
    ? t('openingHoursFormatter.midnight')
    : dateFormat(parseSqlDateTime(`${now} ${interval.toTime}`), TIME_FORMAT);

  return t('openingHoursFormatter.hourToHour', {
    start_time: startTime,
    end_time: endTime,
  });
};

const services = [
  {
    key: 'isPediatricPatientsAccepted',
    label: 'Pediatrics',
  },
  {
    key: 'isWellWomanExamAvailable',
    label: "Women's Health",
  },
  {
    key: 'isXRayAvailable',
    label: 'X-rays',
  },
];

/**
 * Normalize location data into a friendly format.
 *
 * @param {object} location
 * @returns {object}
 */

const locationResponseFormatter = (location: any) => {
  if (isEmptyObject(location)) return {};

  if (location.isFormatted) {
    return location;
  }

  const formatted = {};
  const regex = /_(\w)/g;
  for (const key in location) {
    if (!location.hasOwnProperty(key)) {
      continue;
    }

    const camelCaseKey = key.replace(regex, (m, w) => w.toUpperCase());
    let value = null;
    switch (camelCaseKey) {
      case 'latLong':
        value = parseLatLongString(location.lat_long);
        break;
      case 'hoursDefault':
      case 'hours':
        value = {};
        for (const day in location[key]) {
          if (location[key].hasOwnProperty(day)) {
            // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
            value[day] = location[key][day].map((hourInterval: any) => ({
              fromTime: hourInterval.from_time,
              toTime: hourInterval.to_time,
              isSpecial: Boolean(hourInterval.is_special),
            }));
          }
        }

        break;
      case 'slots':
        value = location.slots?.map((slot: any) => ({
          availability: slot.availability,
          appointmentDate: moment(slot.appointment_date).valueOf(),
          isBusy: slot.busy,
          isReservationsDisabled: slot.is_reservations_disabled,
        }));
        break;
      default:
        value = location[key];
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'facilities' does not exist on type '{}'.
    formatted.facilities = [];
    for (const service of services) {
      if (location[service.key]) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'facilities' does not exist on type '{}'.
        formatted.facilities.push(service);
      }
    }

    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    formatted[camelCaseKey] = value;
  }

  if (!isEmptyString(location.display_name_primary)) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{}'.
    formatted.name = location.display_name_primary;
  }

  if (!location.image_links) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'imageLinks' does not exist on type '{}'.
    formatted.imageLinks = {};
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFormatted' does not exist on type '{}'... Remove this comment to see the full error message
  formatted.isFormatted = true;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpaque' does not exist on type '{}'.
  formatted.isOpaque = false;
  if (!location.hasOwnProperty('isBestMatch')) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isBestMatch' does not exist on type '{}'... Remove this comment to see the full error message
    formatted.isBestMatch = false;
  }

  return formatted;
};

const locationSlotsFormatter = (response: any = { slots: [] }) => {
  const { slots } = response;
  if (slots.isFormatted) return slots;

  let formatted: Record<string, any> = {};
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'slots' does not exist on type '{}'.
  formatted.slots = slots.map((slot) => ({
    availability: slot.availability,
    appointmentDate: moment(slot.appointment_date).valueOf(),
    isBusy: slot.busy,
    isReservationsDisabled: slot.is_reservations_disabled,
  }));

  formatted.isFormatted = true;
  formatted.timeZone = response.time_zone;
  formatted.disableReservationsUntil = response.disable_reservations_until;
  // Update the location with a fresher value, as the slots endpoint is cached for less time than the location endpoint
  formatted.hasBoost = response.has_boost;
  return formatted;
};

const publicBookingLocationFormatter = (response: any) => ({
  name: response.location_display_name_primary,
  id: response.location_id,
});

export {
  publicBookingLocationFormatter,
  locationResponseFormatter,
  openingHoursFormatter,
  locationSlotsFormatter,
};
