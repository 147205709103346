import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setBookingIsPremiumInPerson } from '~/actions/newBooking';
import { Route } from '~/components/BookingFlow/machines/RouterMachine';
import { analyticsTrackEvent } from '~/core/analytics';
import { SOLV_NOW_ENTRYPOINT_TAPPED, SOLV_PLUS_CHAT_ENTRYPOINT } from '~/core/analytics/events';
import { UserProfile } from '~/core/dapi/models';
import history from '~/core/history';
import { isActiveMembership } from '~/core/util/account';
import { willRedirectAfterLogin } from '~/core/util/url';
import { useAccount } from '~/hooks/account/useAccount';
import { usePremiumBookingPersistedState } from '~/routes/book/hooks/usePremiumBookingPersistedState';
import { setRuntimeVariable } from '../../actions/runtime';
import { isClientSide } from '../../core/util/system';
import useRuntimeVariable from '../../hooks/runtime/useRuntimeVariable';
import { PREMIUM_SUPPORTED_RELATIONSHIPS } from './constants';

const PERSISTED_ENTRYPOINT_TTL_HOURS = 2;
const LAST_PREMIUM_ENTRYPOINT_CLICKED = 'lastPremiumEntrypointClicked';

type PersistedEntrypointPayload = {
  entrypointId: string;
  storedAt: Date;
};

/**
 * Stores the most recently clicked entrypoint in local storage
 * with a default TTL of 2 hours.
 */
function setLastClickedEntrypoint(entrypointId: string) {
  if (typeof localStorage === 'undefined') return;

  const payload: PersistedEntrypointPayload = {
    entrypointId,
    storedAt: new Date(),
  };

  localStorage.setItem(LAST_PREMIUM_ENTRYPOINT_CLICKED, JSON.stringify(payload));
}

/**
 * Retrieves the last stored entrypoint from local storage, or null
 * if none exists or the TTL has expired.
 *
 * @returns An entrypoint ID or null
 */
export function getLastClickedEntrypoint() {
  if (typeof localStorage === 'undefined') return null;

  const data = localStorage.getItem(LAST_PREMIUM_ENTRYPOINT_CLICKED);
  if (!data) return null;

  const payload = JSON.parse(data);

  if (
    !(
      payload &&
      payload.entrypointId &&
      payload.storedAt &&
      typeof payload.entrypointId === 'string' &&
      typeof payload.storedAt === 'string'
    )
  ) {
    return null;
  }

  const validatedPayload: PersistedEntrypointPayload = {
    entrypointId: payload.entrypointId,
    storedAt: new Date(payload.storedAt),
  };

  const differenceMs = Date.now() - validatedPayload.storedAt.getTime();

  if (differenceMs > PERSISTED_ENTRYPOINT_TTL_HOURS * 60 * 60 * 1000) {
    // Stored value has expired
    return null;
  }

  return validatedPayload.entrypointId;
}

/**
 * @returns a callback to push to the premium booking flow
 */
export function usePushToPremiumBookingFlow() {
  const { clearState } = usePremiumBookingPersistedState();

  const push = useCallback(
    ({ startFrom, returnPath }: { startFrom?: Route; returnPath?: string }) => {
      // Make sure no older stored return path can affect us here
      clearState();

      // URLSearchParams doesn't support null/undefined values
      const params = omitBy(
        {
          startPage: startFrom,
          return: returnPath ?? `${window.location.pathname}${window.location.search}`,
        },
        isNil
      ) as Record<string, string>;

      const urlParams = new URLSearchParams(params);

      history.push(`/book?${urlParams}`);
    },
    [clearState]
  );

  return push;
}

/**
 * Handles when a solv plus entrypoint is clicked, directing to the proper page
 * based on whether the user has a membership.
 *
 * @param entrypoint An entrypoint string to use in analytics tracking
 *
 * @param directToPayment
 * @param trackingOptions
 * @returns an onClick handler and membership state
 */
export function useOnMembershipEntrypointClicked(
  entrypoint: string,

  {
    directToPayment = false,
  }: {
    /** @deprecated This is the default behavior once Solv Now is enabled */
    directToPayment?: boolean;
  } = {},
  trackingOptions: Record<string, any> = {}
) {
  interface DynamicTrackingProperties {
    slot?: string;
  }

  const accountSummary = useAccount();
  const isMember = isActiveMembership(accountSummary);
  const { clearState } = usePremiumBookingPersistedState();

  const pushToBookingFlow = usePushToPremiumBookingFlow();
  const dispatch = useDispatch();

  const onClick = (dynamicTrackingProperties: DynamicTrackingProperties = {}) => {
    analyticsTrackEvent(SOLV_NOW_ENTRYPOINT_TAPPED, {
      entrypoint,
      isMember,
      ...trackingOptions,
      ...dynamicTrackingProperties,
    });
    dispatch(setBookingIsPremiumInPerson(false));

    setLastClickedEntrypoint(entrypoint);

    // Make sure no older stored return path can affect us here
    clearState();

    pushToBookingFlow({ returnPath: `${window.location.pathname}${window.location.search}` });
  };

  return { onClick, isMember };
}

/**
 * Whether the user is on a login page and about to be redirect to a Solv Plus route
 *
 * @returns bool
 */
export function willRedirectToSolvPlusFlowsAfterLogin() {
  return willRedirectAfterLogin({ to: [/\/book\//, /\/membership\/payment/] });
}

/**
 * Whether the user is on a page embedded within the plus booking flow
 *
 * @returns boolean
 */
export function isWithinSolvPlusBookingFlow() {
  if (!isClientSide()) {
    return false;
  }

  return window.location.pathname.startsWith('/book');
}

const lowercaseRelationships = PREMIUM_SUPPORTED_RELATIONSHIPS.map((s) => s.toLowerCase());

/**
 * Whether a given user profile has the right relationship to an account to be
 * supported by premium (Solv Plus, Solv Now)
 *
 * @returns boolean
 */
export function profileCanAccessPremium(userProfile?: UserProfile) {
  // initial profiles do not have a "relationship" set
  if (userProfile?.relationship_to_account === null) return true;

  return lowercaseRelationships.includes(userProfile?.relationship_to_account?.toLowerCase() ?? '');
}

/**
 * Function to handle opening plus chat
 * and tracking for analytics.
 *
 * @returns () => void
 */
export default function usePlusChat(entrypoint: string): () => void {
  const dispatch = useDispatch();
  const showConciergeChat = useRuntimeVariable('showConciergeChat');
  const onChatClick = () => {
    if (showConciergeChat) {
      // In the case that you're trying to click the entrypoint but the runtime variable is already true
      dispatch(setRuntimeVariable({ name: 'showConciergeChat', value: false }));
    }
    dispatch(setRuntimeVariable({ name: 'showConciergeChat', value: true }));

    analyticsTrackEvent(SOLV_PLUS_CHAT_ENTRYPOINT, {
      entrypoint,
    });
  };

  return onChatClick;
}
