import { Box, Select, TextArea, TextField } from '@solvhealth/jigsaw';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import type PaperworkField from '@solvhealth/types/interfaces/PaperworkField';
import type {
  JsonCheckboxMetadata,
  ResponseOption,
  JsonListMetadata,
  JsonCheckboxCardsMetadata,
} from '@solvhealth/types/interfaces/PaperworkField';
import { TFuncKey } from 'react-i18next';
import moment from 'moment';
// @ts-ignore
import { formShape } from 'rc-form';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { Lock } from '@material-ui/icons';
import { GenericObj } from '@solvhealth/types/interfaces/generics';
import isEmpty from 'lodash/isEmpty';
import CheckboxChoiceCardGroup from '~/components/Form/CheckboxGroup/CheckboxChoiceCardGroup';
import RadioChoiceCardGroup from '~/components/Form/RadioChoiceCardGroup';
import { emailInputValidator, phoneNumberValidator } from '~/core/util/rcForm/validators';
import { BIRTH_DATE_SLASH_FORMAT } from '../../../config/index';
import { colors } from '../../../constants/colors';
import { TYPE_SELF } from '../../../constants';
import history from '../../../core/history';
import { EMPTY_ARRAY } from '../../../core/util/array';
import {
  formatDatabaseDateToDisplay,
  validateArbitraryPastDateString,
  DATE_SLASH_FORMAT,
  DAPI_DATE_FORMAT,
  MONTH_NAME_DAY_YEAR_FORMAT,
} from '../../../core/util/date';
import { isMobile } from '../../../core/util/device';
import {
  isLegalNameRequired,
  isLocationConsentFlowEnabled,
  isProviderGroupLocation,
} from '../../../core/util/location';
import {
  capitalize,
  replaceCharactersBeginningAtIndex,
  stripNonNumeric,
} from '../../../core/util/string';
import { PaperworkState } from '../../../ducks/paperwork';
import { isResponseOptions } from '../../../typeGuards/paperwork';
import { phoneNumberNormalizer } from '../../SignUpForm/utils';
import CheckboxGroupField from '~/components/Form/CheckboxGroup/CheckboxGroupField';
import { getValidRelationships } from '../../SolvPatternLibrary/Form/RelationshipToAccountInput';
import {
  EMERGENCY_CONTACT_FIELD_NAMES,
  GUARDIAN_FORM_FIELD_NAMES,
  PaperworkFieldName,
  PaperworkFieldType,
  PaperworkSectionRoute,
  PAPERWORK_FIELD_ANSWER_NO,
  PAPERWORK_FIELD_ANSWER_YES,
  PATIENT_FORM_PAGE_ONE_FIELD_NAMES,
  PATIENT_FORM_PAGE_TWO_FIELD_NAMES,
  HEALTHCARE_CONTACTS_PAGE_ONE_FIELD_NAMES,
  PAYMENT_FORM_FIELD_NAMES,
  PAPERWORK_FIELD_VALIDATIONS,
  PAPERWORK_FIELD_INPUT_ADDITIONAL_PROPS,
  ProviderGroupPaperworkFieldName,
  EMPLOYER_FORM_FIELD_NAMES,
  PaperworkCopyFormFieldsCheckboxName,
} from '../paperworkConstants';
import { JigsawMaskedDateTextField } from '../../SolvPatternLibrary/MaskedTextField';
import PharmacyAutoSuggestInput from '../../SolvPatternLibrary/Form/PharmacyAutoSuggestInput';
import { isPremiumBooking } from '~/core/util/booking';
import CheckboxWithInputGroup from '~/components/Form/CheckboxWithInputGroup';
import InputGroupList from '~/components/Form/InputGroupList';
import CheckboxCardWithInputGroup from '~/components/Form/CheckboxCardWithInputGroup';
import { AddressAutocomplete } from '../components/Patient/AddressAutocomplete';
import { useShouldShowAddressValidation } from '../components/Patient/hooks/useShouldShowAddressValidation';
export * from './fieldDependencies';
export * from './page';
export * from './progress';
const parser = require('parse-address');

const {
  PATIENT_FIELD_FIRST_NAME,
  PATIENT_FIELD_MIDDLE_NAME,
  PATIENT_FIELD_LAST_NAME,
  PATIENT_FIELD_BIRTH_DATE,
  PATIENT_ADDRESS_STREET,
  PATIENT_ADDRESS_STREET_TWO,
  PATIENT_ADDRESS_CITY,
  PATIENT_ADDRESS_STATE,
  PATIENT_ADDRESS_ZIP,
  PATIENT_PRIMARY_PHONE,
  PATIENT_EMAIL,
  SELF_PAY_CHECK,
  PRIMARY_INSURANCE_COMPANY,
  PRIMARY_MEMBER_ID,
  PRIMARY_GROUP_NUMBER,
  PRIMARY_POLICY_HOLDER_FIRST_NAME,
  PRIMARY_POLICY_HOLDER_MIDDLE_NAME,
  PRIMARY_POLICY_HOLDER_LAST_NAME,
  PRIMARY_POLICY_HOLDER_BIRTH_DATE,
  PRIMARY_POLICY_HOLDER_BIRTH_SEX,
  GUARDIAN_FIRST_NAME,
  GUARDIAN_MIDDLE_NAME,
  GUARDIAN_LAST_NAME,
  GUARDIAN_BIRTH_DATE,
  GUARDIAN_ADDRESS,
  GUARDIAN_ADDRESS_TWO,
  GUARDIAN_CITY,
  GUARDIAN_STATE,
  GUARDIAN_ZIP,
  GUARDIAN_PHONE,
  GUARDIAN_ALTERNATE_PHONE,
  GUARDIAN_EMAIL,
  EMERGENCY_CONTACT_FIRST_NAME,
  EMERGENCY_CONTACT_MIDDLE_NAME,
  EMERGENCY_CONTACT_LAST_NAME,
  EMERGENCY_CONTACT_PHONE,
} = PaperworkFieldName;

const getStandardFormFieldData = (fieldName: any, paperwork: any) => {
  if (!paperwork.standardFields) {
    return null;
  }

  return paperwork.standardFields.find((field: any) => field.field_name === fieldName);
};

const hasRequiredFields = (fieldNames: string[], paperwork: PaperworkState): boolean => {
  const fields: PaperworkField[] = fieldNames.map((fieldName: string) =>
    getStandardFormFieldData(fieldName, paperwork)
  );
  return fields.some((field: PaperworkField) => field?.is_required_field);
};

const getRequiredFieldNames = (fieldNames: string[], paperwork: PaperworkState): string[] => {
  const fields: PaperworkField[] = fieldNames.map((fieldName: string) =>
    getStandardFormFieldData(fieldName, paperwork)
  );
  return fields
    .filter((field: PaperworkField) => field?.is_required_field)
    .map((field: PaperworkField) => field.field_name);
};

/**
 * Returns an autocomplete key for a paperwork field.
 *
 * @param fieldName The field name to get a key for
 * @returns A value for the html `autoComplete` attribute
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
const getFieldAutoCompleteKey = (fieldName: string): string | undefined => {
  const fieldNameToAutocompleteKeyMap: Record<string, string> = {
    [PATIENT_FIELD_FIRST_NAME]: 'given-name',
    [PATIENT_FIELD_MIDDLE_NAME]: 'additional-name',
    [PATIENT_FIELD_LAST_NAME]: 'family-name',
    [PATIENT_FIELD_BIRTH_DATE]: 'bday',

    [PATIENT_ADDRESS_STREET]: 'address-line1',
    [PATIENT_ADDRESS_STREET_TWO]: 'address-line2',
    [PATIENT_ADDRESS_CITY]: 'address-level2',
    [PATIENT_ADDRESS_STATE]: 'address-level1',
    [PATIENT_ADDRESS_ZIP]: 'postal-code',
    [PATIENT_PRIMARY_PHONE]: 'tel-national',
    [PATIENT_EMAIL]: 'email',

    [GUARDIAN_FIRST_NAME]: 'given-name',
    [GUARDIAN_MIDDLE_NAME]: 'additional-name',
    [GUARDIAN_LAST_NAME]: 'family-name',
    [GUARDIAN_BIRTH_DATE]: 'bday',
    [GUARDIAN_ADDRESS]: 'address-line1',
    [GUARDIAN_ADDRESS_TWO]: 'address-line2',
    [GUARDIAN_CITY]: 'address-level2',
    [GUARDIAN_STATE]: 'address-level1',
    [GUARDIAN_ZIP]: 'postal-code',
    [GUARDIAN_PHONE]: 'tel-national',
    [GUARDIAN_ALTERNATE_PHONE]: 'tel-national',
    [GUARDIAN_EMAIL]: 'email',
    [EMERGENCY_CONTACT_FIRST_NAME]: 'given-name',
    [EMERGENCY_CONTACT_MIDDLE_NAME]: 'additional-name',
    [EMERGENCY_CONTACT_LAST_NAME]: 'family-name',
    [EMERGENCY_CONTACT_PHONE]: 'tel-national',
  };

  return fieldNameToAutocompleteKeyMap[fieldName];
};

enum ParseAddressFieldsKey {
  Number = 'number',
  Prefix = 'prefix',
  Street = 'street',
  Suffix = 'suffix',
  Type = 'type',
  SecUnitType = 'sec_unit_type',
  SecUnitNum = 'sec_unit_num',
  City = 'city',
  State = 'state',
  Zip = 'zip',
}

type ParseAddressFields = {
  [key in ParseAddressFieldsKey]?: string;
};

interface GuardianAddressFields {
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
}

const valuesFromParsedAddressByFields = (
  parsedAddress: ParseAddressFields,
  fields: ParseAddressFieldsKey[]
): string[] =>
  fields.filter((field) => !!parsedAddress[field]).map((field) => parsedAddress[field]!);

const parseGuardianAddressField = (
  guardianAddress: string | null | undefined
): GuardianAddressFields => {
  let parsedAddress = {} as ParseAddressFields;
  if (guardianAddress) {
    parsedAddress = parser.parseLocation(guardianAddress) as ParseAddressFields;
  }
  const addressLineOne = valuesFromParsedAddressByFields(parsedAddress ?? {}, [
    ParseAddressFieldsKey.Number,
    ParseAddressFieldsKey.Prefix,
    ParseAddressFieldsKey.Street,
    ParseAddressFieldsKey.Suffix,
    ParseAddressFieldsKey.Type,
  ]);
  const addressLineTwo = valuesFromParsedAddressByFields(parsedAddress, [
    ParseAddressFieldsKey.SecUnitType,
    ParseAddressFieldsKey.SecUnitNum,
  ]);

  return {
    addressLineOne: addressLineOne.join(' '),
    addressLineTwo: addressLineTwo.join(' '),
    city: parsedAddress[ParseAddressFieldsKey.City] ?? '',
    state: parsedAddress[ParseAddressFieldsKey.State] ?? '',
    zip: parsedAddress[ParseAddressFieldsKey.Zip] ?? '',
  };
};

const getFieldInitialValue = (fieldName: string, paperworkFormData: any, preloadedData: any) => {
  if (paperworkFormData[fieldName]) return paperworkFormData[fieldName];
  if (isEmpty(preloadedData)) return '';
  const { newBooking, booking } = preloadedData;
  const insuranceProfile = preloadedData.selectedInsurance || {};
  const fieldNameToPreloadedData: { [key in PaperworkFieldName]?: any } = {
    [SELF_PAY_CHECK]:
      booking.insurerType === TYPE_SELF ? PAPERWORK_FIELD_ANSWER_NO : PAPERWORK_FIELD_ANSWER_YES,
    [PRIMARY_INSURANCE_COMPANY]: insuranceProfile.insurer_name || newBooking.insurance.insurerName,
    [PRIMARY_MEMBER_ID]: insuranceProfile.member_code || newBooking.insurance.memberCode,
    [PRIMARY_GROUP_NUMBER]: insuranceProfile.group_number,
    [PRIMARY_POLICY_HOLDER_FIRST_NAME]:
      insuranceProfile.first_name || newBooking.insurance.firstName,
    [PRIMARY_POLICY_HOLDER_MIDDLE_NAME]: insuranceProfile.middle_name,
    [PRIMARY_POLICY_HOLDER_LAST_NAME]: insuranceProfile.last_name || newBooking.insurance.lastName,
    [PRIMARY_POLICY_HOLDER_BIRTH_DATE]:
      insuranceProfile.birth_date || newBooking.insurance.birthDate,
    [PRIMARY_POLICY_HOLDER_BIRTH_SEX]: insuranceProfile.birth_sex || newBooking.insurance.birthSex,
  };

  return fieldNameToPreloadedData[fieldName as PaperworkFieldName] || '';
};

const CustomFieldLabel = styled.div`
  text-align: left;
  margin: 15px 0 10px 5px;
  color: ${colors.squid};
  font-size: 16px;
`;

const buildOptions = (
  responseOptions: string[] | ResponseOption[],
  fieldType: PaperworkFieldType
) => {
  if (isEmpty(responseOptions)) return [];

  const options = [];

  if (fieldType === PaperworkFieldType.SELECT) options.push({ key: '', value: '', label: '' });

  if (isResponseOptions(responseOptions)) {
    return options.concat(
      responseOptions.map((option) => ({
        key: option.value,
        value: option.value,
        label: option.label,
      }))
    );
  }

  return options.concat(
    responseOptions.map((option: any) => {
      // In some cases, option can be an interger which would cause string function errors.
      const optionToString: any = String(option);
      return {
        key: option,
        value: fieldType === PaperworkFieldType.CHECK_BOX ? false : option,
        label: capitalize(optionToString),
      };
    })
  );
};

type GeneratePaperworkFieldArgs = {
  field: PaperworkField;
  paperwork: PaperworkState;
  form: formShape;
  preloadedData?: any;
  location?: LocationInterface;
  handleSetWarning?: (fieldName: string) => string | boolean;
};

type FieldLabelType = 'default' | 'error';

const PaperworkFieldGenerator = React.forwardRef(
  (
    {
      field,
      paperwork,
      form,
      preloadedData = {},
      location,
      handleSetWarning,
    }: GeneratePaperworkFieldArgs,
    ref
  ) => {
    const shouldUseLegalNameTreatment =
      ['patientFirstName', 'patientLastName'].includes(field.field_name) &&
      isLegalNameRequired(location);

    const handleFormFieldChange = (value: any, fieldName: string) => {
      form.setFieldsValue({ [fieldName]: value });
    };
    const { t } = useTranslation(
      isProviderGroupLocation(location) ? 'providerGroupPaperwork' : 'paperwork'
    );

    const isAddressValidationEnabled = useShouldShowAddressValidation(location?.id ?? '');

    const getLabelForMobile = (field: PaperworkField, type: FieldLabelType = 'default') => {
      switch (field.field_name) {
        case PaperworkFieldName.DO_YOU_HAVE_SECONDARY_INSURANCE_INFORMATION:
          return t(`standardFields.doYouHaveSecondInsuranceInformationMobile.${type}` as any);
        default:
          return null;
      }
    };

    const getFieldLabel = (field: PaperworkField, type: FieldLabelType = 'default') => {
      let label = t(`standardFields.${field.field_name}.${type}` as any, field.field_display_name);

      if (isMobile() && !isProviderGroupLocation(location)) {
        const mobileLabel = getLabelForMobile(field, type);
        if (mobileLabel) {
          label = mobileLabel;
        }
      }

      if (shouldUseLegalNameTreatment) {
        const lowerCaseLabel = label.toLowerCase();
        label = t(`standardFields.legalTreatment.${type}` as any, { field: lowerCaseLabel });
      }

      return label;
    };

    const getFieldHelperText = (field: PaperworkField) =>
      t(`standardFields.${field.field_name}.helperText` as any, field.helper_text ?? '');

    const getInitialValueForDOB = (fieldName: string, formData: object, preloadedData: any) => {
      const initial = getFieldInitialValue(fieldName, formData, preloadedData);
      if (moment(initial).isValid()) return moment(initial).format(DATE_SLASH_FORMAT);
      return initial;
    };

    if (!field) return null;

    // Have to any[] this because the TextField only allows {required: bool} as a rule for some reason.
    const rules: any[] = PAPERWORK_FIELD_VALIDATIONS[field.field_name] || [];
    const additionalProps = PAPERWORK_FIELD_INPUT_ADDITIONAL_PROPS[field.field_name] || {};

    const error = form.getFieldError(field.field_name)?.[0];
    const warning = handleSetWarning?.(field.field_name);
    switch (field.field_type) {
      case PaperworkFieldType.TEXT_AREA:
        return (
          <TextArea
            key={field.field_name}
            ref={ref}
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            data-hj-suppress
            dense
            error={error}
            helperText={getFieldHelperText(field)}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            optional={!field.is_required_field && (t('common.optional') || true)}
            title={getFieldLabel(field)}
            {...additionalProps}
          />
        );
      case PaperworkFieldType.DATE:
      case PaperworkFieldType.BIRTH_DATE:
        return (
          <JigsawMaskedDateTextField
            autoComplete={field.field_type === PaperworkFieldType.BIRTH_DATE ? 'bday' : undefined}
            dense
            error={error}
            helperText={getFieldHelperText(field)}
            ref={ref}
            title={getFieldLabel(field)}
            type="text"
            {...form.getFieldProps(field.field_name, {
              validator: (rule: any, value: string) => validateArbitraryPastDateString(value),
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getInitialValueForDOB(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            data-hj-suppress
            key={field.field_name}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            optional={!field.is_required_field && (t('common.optional') || true)}
            placeholder="mm/dd/yyyy"
          />
        );
      case PaperworkFieldType.EMAIL:
        return (
          <TextField
            autoComplete="email"
            dense
            ref={ref}
            type="email"
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  validator: emailInputValidator,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            data-hj-suppress
            error={error}
            helperText={getFieldHelperText(field)}
            key={field.field_name}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            optional={!field.is_required_field && (t('common.optional') || true)}
            title={getFieldLabel(field) || 'Email'}
          />
        );
      case PaperworkFieldType.PHARMACY_SEARCH:
        if (!isPremiumBooking(preloadedData.booking)) {
          return (
            <TextField
              key={field.field_name}
              ref={ref}
              {...form.getFieldProps(field.field_name, {
                rules: [
                  {
                    required: field.is_required_field,
                    message: t(['common.required', 'common.requiredShort'], {
                      FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                    }),
                  },
                ].concat(rules),
                initialValue: getFieldInitialValue(
                  field.field_name,
                  paperwork.formData,
                  preloadedData
                ),
              })}
              aria-required={field.is_required_field ? 'true' : 'false'}
              data-hj-suppress
              dense
              error={error}
              helperText={getFieldHelperText(field)}
              onBlur={() => form.validateFields([field.field_name])}
              onChange={({ target: { value } }: any) =>
                handleFormFieldChange(value, field.field_name)
              }
              optional={!field.is_required_field && (t('common.optional') || true)}
              title={getFieldLabel(field)}
              {...additionalProps}
            />
          );
        }
        return (
          <PharmacyAutoSuggestInput
            key={field.field_name}
            {...form.getFieldProps(field.field_name, {
              rules: [{ required: field.is_required_field }],
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            data-hj-suppress
            error={!!form.getFieldError(field.field_name)}
            fullWidth
            id={field.field_name}
            label={field.is_standard_field && getFieldLabel(field)}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={(e: any, { newValue }: any) =>
              handleFormFieldChange(newValue, field.field_name)
            }
            shouldRenderSuggestions={() => true}
          />
        );
      case PaperworkFieldType.INPUT:
        if (
          field.field_name === PaperworkFieldName.PATIENT_ADDRESS_STREET &&
          isAddressValidationEnabled
        ) {
          return (
            <AddressAutocomplete
              autoComplete={getFieldAutoCompleteKey(field.field_name)}
              error={error}
              errorLabel={getFieldLabel(field, 'error')}
              field={field}
              form={form}
              helperText={getFieldHelperText(field)}
              initialValue={getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              )}
              key={field.field_name}
              optional={!field.is_required_field && (t('common.optional') || true)}
              title={getFieldLabel(field)}
              warning={warning}
              {...additionalProps}
            />
          );
        }
        return (
          <TextField
            autoComplete={getFieldAutoCompleteKey(field.field_name)}
            key={field.field_name}
            ref={ref}
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            data-hj-suppress
            dense
            error={error}
            helperText={getFieldHelperText(field)}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            optional={!field.is_required_field && (t('common.optional') || true)}
            title={getFieldLabel(field)}
            warning={warning}
            {...additionalProps}
          />
        );

      case PaperworkFieldType.PHONE_NUMBER:
        return (
          <TextField
            autoComplete="tel-national"
            data-hj-suppress
            dense
            ref={ref}
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  validator: phoneNumberValidator,
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            error={error}
            helperText={getFieldHelperText(field)}
            key={field.field_name}
            onBlur={() => {
              form.setFieldsValue({
                [field.field_name]: phoneNumberNormalizer(form.getFieldValue(field.field_name)),
              });
              form.validateFields([field.field_name]);
            }}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            onFocus={() => {
              form.setFieldsValue({
                [field.field_name]: stripNonNumeric(form.getFieldValue(field.field_name)),
              });
            }}
            optional={!field.is_required_field && (t('common.optional') || true)}
            title={getFieldLabel(field) ?? 'Mobile Phone Number'}
            type="tel"
            {...additionalProps}
          />
        );
      case PaperworkFieldType.RELATIONSHIP_TO_ACCOUNT:
        return (
          <Select
            data-hj-suppress
            dense
            error={error}
            fullWidth
            helperText={getFieldHelperText(field)}
            key={field.field_name}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            ref={ref}
            title={getFieldLabel(field)}
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            {...additionalProps}
          >
            {getValidRelationships().map((option) => (
              <option key={option.key} value={option.value}>
                {t(`responses.${option.label}` as any, option.label)}
              </option>
            ))}
          </Select>
        );
      case PaperworkFieldType.SELECT:
        return (
          <Select
            key={field.field_name}
            ref={ref}
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            data-hj-suppress
            dense
            error={error}
            fullWidth
            helperText={getFieldHelperText(field)}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            optional={!field.is_required_field && (t('common.optional') || true)}
            title={getFieldLabel(field)}
            {...additionalProps}
          >
            {buildOptions(field.response_options, PaperworkFieldType.SELECT).map((option) => (
              <option key={option.key} value={option.value}>
                {t(`responses.${option.label.trim()}` as any, option.label)}
              </option>
            ))}
          </Select>
        );
      case PaperworkFieldType.CHECK_BOX: {
        const defaultProps = {
          ...form.getFieldProps(field.field_name, {
            rules: [
              {
                required: field.is_required_field,
                message: t(['common.required', 'common.requiredShort'], {
                  FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                }),
              },
            ].concat(rules),
            initialValue:
              getFieldInitialValue(field.field_name, paperwork.formData, preloadedData) ||
              EMPTY_ARRAY,
          }),
        };
        return (
          <Fragment key={field.field_name}>
            {!field.is_standard_field && (
              <CustomFieldLabel>{getFieldLabel(field)}</CustomFieldLabel>
            )}
            <CheckboxGroupField
              {...defaultProps}
              error={!!error}
              options={buildOptions(field.response_options, PaperworkFieldType.CHECK_BOX)}
              ref={ref}
              {...additionalProps}
            />
          </Fragment>
        );
      }
      case PaperworkFieldType.CHECK_BOX_CHOICE_CARDS: {
        const defaultProps = {
          ...form.getFieldProps(field.field_name, {
            rules: [
              {
                required: field.is_required_field,
                message: t(['common.required', 'common.requiredShort'], {
                  FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                }),
              },
            ].concat(rules),
            initialValue:
              getFieldInitialValue(field.field_name, paperwork.formData, preloadedData) ||
              EMPTY_ARRAY,
          }),
        };
        return (
          <Fragment key={field.field_name}>
            {!field.is_standard_field && (
              <CustomFieldLabel>{getFieldLabel(field)}</CustomFieldLabel>
            )}
            <CheckboxChoiceCardGroup
              {...defaultProps}
              error={!!error}
              options={buildOptions(
                field.response_options,
                PaperworkFieldType.CHECK_BOX_CHOICE_CARDS
              )}
              ref={ref}
              {...additionalProps}
            />
          </Fragment>
        );
      }
      case PaperworkFieldType.SELECT_CARDS: {
        const defaultProps = {
          ...form.getFieldProps(field.field_name, {
            rules: [
              {
                required: field.is_required_field,
                message: t(['common.required', 'common.requiredShort'], {
                  FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                }),
              },
            ].concat(rules),
            initialValue: getFieldInitialValue(field.field_name, paperwork.formData, preloadedData),
          }),
        };
        return (
          <Fragment key={field.field_name}>
            {!field.is_standard_field && (
              <CustomFieldLabel>{getFieldLabel(field)}</CustomFieldLabel>
            )}
            <RadioChoiceCardGroup
              {...defaultProps}
              error={!!error}
              options={buildOptions(field.response_options, PaperworkFieldType.SELECT_CARDS)}
              {...additionalProps}
              disabled={paperwork.isSubmitting}
              ref={ref}
            />
          </Fragment>
        );
      }
      case PaperworkFieldType.SOCIAL_SECURITY_NUMBER:
        return (
          <TextField
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue: getFieldInitialValue(
                field.field_name,
                paperwork.formData,
                preloadedData
              ),
            })}
            aria-required={field.is_required_field ? 'true' : 'false'}
            data-hj-suppress
            dense
            endIcon={
              <Box paddingRight={1.5}>
                <Lock fontSize="small" />
              </Box>
            }
            error={error}
            id={field.field_name}
            inputMode="numeric"
            key={field.field_name}
            maxLength={9}
            onBlur={() => form.validateFields([field.field_name])}
            onChange={({ target: { value } }: any) =>
              handleFormFieldChange(value, field.field_name)
            }
            optional={!field.is_required_field && (t('common.optional') || true)}
            pattern="[0-9]*"
            ref={ref}
            title={getFieldLabel(field)}
            type="password"
            {...additionalProps}
          />
        );
      case PaperworkFieldType.JSON_CHECKBOX: {
        const metadata = field.metadata as JsonCheckboxMetadata;
        return (
          <Box>
            <CheckboxWithInputGroup
              {...form.getFieldProps(field.field_name, {
                rules: [
                  {
                    required: field.is_required_field,
                    message: t(['common.required', 'common.requiredShort'], {
                      FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                    }),
                  },
                ].concat(rules),
                initialValue:
                  getFieldInitialValue(field.field_name, paperwork.formData, preloadedData) ||
                  EMPTY_ARRAY,
              })}
              data-hj-suppress
              error={!!error}
              key={field.field_name}
              optionsGroups={metadata.response_options_groups}
              ref={ref}
            />
          </Box>
        );
      }
      case PaperworkFieldType.JSON_LIST: {
        const metadata = field.metadata as JsonListMetadata;
        return (
          <InputGroupList
            {...form.getFieldProps(field.field_name, {
              rules: [
                {
                  required: field.is_required_field,
                  message: t(['common.required', 'common.requiredShort'], {
                    FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                  }),
                },
              ].concat(rules),
              initialValue:
                getFieldInitialValue(field.field_name, paperwork.formData, preloadedData) ||
                EMPTY_ARRAY,
            })}
            buttonLabel={metadata.button_label}
            data-hj-suppress
            error={!!error}
            fieldLabels={metadata.field_labels}
            key={field.field_name}
            maxListLength={metadata.max_list_length}
            ref={ref}
            title={field.field_name}
          />
        );
      }
      case PaperworkFieldType.JSON_CHECKBOX_CARDS: {
        const metadata = field.metadata as JsonCheckboxCardsMetadata;
        return (
          <Box>
            <CheckboxCardWithInputGroup
              {...form.getFieldProps(field.field_name, {
                rules: [
                  {
                    required: field.is_required_field,
                    message: t(['common.required', 'common.requiredShort'], {
                      FORM_FIELD_NAME: getFieldLabel(field, 'error'),
                    }),
                  },
                ].concat(rules),
                initialValue:
                  getFieldInitialValue(field.field_name, paperwork.formData, preloadedData) ||
                  EMPTY_ARRAY,
              })}
              data-hj-suppress
              error={!!error}
              key={field.field_name}
              options={metadata.response_options}
              ref={ref}
            />
          </Box>
        );
      }
      default:
        return null;
    }
  }
);

const Wrapper = React.forwardRef((props: GeneratePaperworkFieldArgs, ref) => {
  if (!props.field) return null;
  return <PaperworkFieldGenerator ref={ref} {...props} />;
});

const paperworkHasFieldsOfType = (
  paperwork: PaperworkState,
  fields: (PaperworkFieldName | PaperworkCopyFormFieldsCheckboxName)[]
) =>
  !!(
    paperwork &&
    paperwork.standardFields &&
    fields.some((fieldName) => {
      if (!Object.values<string>(PaperworkCopyFormFieldsCheckboxName).includes(fieldName)) {
        return paperwork.standardFields?.find((field: any) => field.field_name === fieldName);
      }
      return false;
    })
  );

const paperworkHasPatientFields = (paperwork: PaperworkState) =>
  paperworkHasFieldsOfType(paperwork, [
    ...PATIENT_FORM_PAGE_ONE_FIELD_NAMES,
    ...PATIENT_FORM_PAGE_TWO_FIELD_NAMES,
  ]);

const paperworkHasPaymentFields = (paperwork: PaperworkState) =>
  paperworkHasFieldsOfType(paperwork, PAYMENT_FORM_FIELD_NAMES);

const paperworkHasEmployerFields = (paperwork: PaperworkState) =>
  paperworkHasFieldsOfType(paperwork, EMPLOYER_FORM_FIELD_NAMES);

const paperworkHasHealthcareContactFields = (paperwork: PaperworkState) =>
  paperworkHasFieldsOfType(paperwork, [
    ...HEALTHCARE_CONTACTS_PAGE_ONE_FIELD_NAMES,
    ...EMERGENCY_CONTACT_FIELD_NAMES,
  ]);

const paperworkHasGuardianFields = (paperwork: PaperworkState) =>
  paperworkHasFieldsOfType(paperwork, GUARDIAN_FORM_FIELD_NAMES);

const paperworkHasCustomFields = (paperwork: PaperworkState) =>
  !!(paperwork && paperwork.customFields && !isEmpty(paperwork.customFields));

const paperworkHasVerificationAndReviewFields = (paperwork: PaperworkState) =>
  paperworkHasFieldsOfType(paperwork, [PaperworkFieldName.REASON_FOR_VISIT]);

const paperworkHasConsentForms = (paperwork: PaperworkState) => {
  return !(
    !paperwork.consentForms ||
    paperwork?.consentForms?.error ||
    isEmpty(paperwork.consentForms)
  );
};

const isPatientAgeAtLeast = (age: number, birthDate: string): boolean => {
  return (
    !isEmpty(birthDate) &&
    moment(birthDate, BIRTH_DATE_SLASH_FORMAT).isSameOrBefore(moment().subtract(age, 'years'))
  );
};

const isLegalWorkingAge = (paperworkFormData: GenericObj) => {
  if (!paperworkFormData?.birthDate) return false;

  return isPatientAgeAtLeast(14, paperworkFormData[PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE]);
};

const isPaperworkForChild = (paperworkFormData: any, userProfile?: any, newBooking?: any) => {
  if (
    isEmpty(paperworkFormData) ||
    isEmpty(paperworkFormData[PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE])
  ) {
    return false;
  }

  return !isPatientAgeAtLeast(
    18,
    paperworkFormData[PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE] ??
      userProfile?.birth_date ??
      newBooking?.profile.birthDate
  );
};

const formatJsonFieldTypesToDisplay = (fieldValue?: GenericObj[]) => {
  if (!fieldValue) {
    return '';
  }

  return fieldValue
    .map((groupOfValues: GenericObj) =>
      Object.values(groupOfValues)
        .filter((valueWithinGroup) => !isEmpty(valueWithinGroup))
        .join(', ')
    )
    .join('; ');
};

const getFieldValueFromFormDataFieldName = (fieldData: any, formData: any) => {
  let fieldValue;
  const { field_name: fieldName, field_type: fieldType } = fieldData;

  switch (fieldType) {
    case PaperworkFieldType.CHECK_BOX_CHOICE_CARDS:
      fieldValue = formData[fieldName]?.join(', ');
      break;
    case PaperworkFieldType.JSON_CHECKBOX:
    case PaperworkFieldType.JSON_LIST:
    case PaperworkFieldType.JSON_CHECKBOX_CARDS:
      fieldValue = formatJsonFieldTypesToDisplay(formData[fieldName]);
      break;
    case PaperworkFieldType.DATE:
      fieldValue = formatDatabaseDateToDisplay(formData[fieldName]);
      break;
    case PaperworkFieldType.BIRTH_DATE:
      fieldValue = formatDatabaseDateToDisplay(formData[fieldName], MONTH_NAME_DAY_YEAR_FORMAT);
      break;
    case PaperworkFieldType.SOCIAL_SECURITY_NUMBER:
      fieldValue =
        formData[fieldName] && replaceCharactersBeginningAtIndex(formData[fieldName], '*****', 0);
      break;
    default:
      fieldValue = formData[fieldName];
  }

  switch (fieldName) {
    case ProviderGroupPaperworkFieldName.LAST_PERIOD:
    case ProviderGroupPaperworkFieldName.PAP_SMEAR_LAST_DATE:
      fieldValue = formatDatabaseDateToDisplay(formData[fieldName], MONTH_NAME_DAY_YEAR_FORMAT);
      break;
    default:
      // skip default case
      break;
  }

  return fieldValue;
};

const getNextButtonKey = (paperwork: any): TFuncKey<['paperwork', 'common']> =>
  paperwork.isInFinalizeEditMode ? 'common:update' : 'common:saveAndContinue';

const toggleEditModeOffAndPushBackToFinalize = (toggleFinalizeEditMode: any, bookingId: any) => {
  toggleFinalizeEditMode(false);
  return history.push(`${PaperworkSectionRoute.FINALIZE}/${bookingId}`);
};

const hasCustomEmployer = (paperwork: any) =>
  paperwork &&
  paperwork.standardFields &&
  paperwork.standardFields.find(
    (field: any) => field.field_name === PaperworkFieldName.CUSTOM_EMPLOYER
  );

const paperworkTransformerAfterSubmit = (formData: any) => {
  let transformedForm = cloneDeep(formData);
  formData?.response_fields &&
    Object.keys(formData.response_fields).forEach((field) => {
      if (field.toLowerCase().includes('birthdate')) {
        const originalDate = formData.response_fields[field];
        transformedForm.response_fields[field] = moment(originalDate, DATE_SLASH_FORMAT).format(
          DAPI_DATE_FORMAT
        );
      }
    });
  return transformedForm;
};

const isConsentIncluded = (location: any, paperwork: any) =>
  paperworkHasConsentForms(paperwork) && isLocationConsentFlowEnabled(location);

const isAwsLink = (url: string) => url.includes('amazonaws.com');

export {
  getStandardFormFieldData,
  Wrapper as PaperworkField,
  paperworkHasPatientFields,
  paperworkHasGuardianFields,
  paperworkHasCustomFields,
  paperworkHasConsentForms,
  paperworkHasHealthcareContactFields,
  paperworkHasEmployerFields,
  paperworkHasPaymentFields,
  paperworkHasVerificationAndReviewFields,
  isPaperworkForChild,
  isLegalWorkingAge,
  isPatientAgeAtLeast,
  getFieldValueFromFormDataFieldName,
  getNextButtonKey,
  toggleEditModeOffAndPushBackToFinalize,
  getFieldInitialValue,
  hasCustomEmployer,
  paperworkTransformerAfterSubmit,
  isConsentIncluded,
  hasRequiredFields,
  getRequiredFieldNames,
  parseGuardianAddressField,
  isAwsLink,
};
