import { WhiteLabelThemeProvider } from '@solvhealth/jigsaw';
import React, { ReactNode } from 'react';
import { useGetWhiteLabelProperties } from '~/hooks/useWhiteLabelProperties';

export const WhiteLabelWrapper = ({
  children,
  bookingId,
}: {
  children: ReactNode;
  bookingId: string;
}) => {
  const { paletteName } = useGetWhiteLabelProperties({ bookingId });

  return <WhiteLabelThemeProvider theme={paletteName}>{children}</WhiteLabelThemeProvider>;
};
