import { GenericObj } from '@solvhealth/types/interfaces/generics';
import isEmpty from 'lodash/isEmpty';
import { PaperworkState } from '~/ducks/paperwork';
import {
  PatientPageNumber,
  MedicalPageNumber,
  LifestyleFactorsPageNumber,
  HealthcareContactsPageNumber,
  PATIENT_PAGES,
  MEDICAL_PAGES,
  LIFESTYLE_FACTORS_PAGES,
  HEALTHCARE_CONTACTS_PAGES,
} from '../constants';
import { isLegalWorkingAge, isPaperworkForChild, isPatientAgeAtLeast } from '../../util';
import { PaperworkFieldName, ProviderGroupPaperworkFieldName } from '../../paperworkConstants';
import { EMPTY_OBJECT } from '~/core/util/object';

const shouldAskPregnancyQuestions = (paperwork: PaperworkState) => {
  if (isEmpty(paperwork.formData)) return false;

  return (
    isPatientAgeAtLeast(14, paperwork.formData[PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE]) &&
    (paperwork.formData[PaperworkFieldName.PATIENT_FIELD_BIRTH_SEX] === 'Female' ||
      paperwork.formData[PaperworkFieldName.PATIENT_FIELD_GENDER] === 'Female' ||
      paperwork.formData[PaperworkFieldName.PATIENT_FIELD_GENDER] === 'Transgender female')
  );
};

type DependencyFn = (paperwork: PaperworkState) => boolean;

const buildFieldDependenciesForPages = (pages: GenericObj[], dependencyFn: DependencyFn) => {
  const fieldDependencies: GenericObj = {};

  pages.forEach((page) => {
    page.fieldNames.forEach((fieldName: string) => {
      fieldDependencies[fieldName] = dependencyFn;
    });
  });

  return fieldDependencies;
};

const patientFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [PATIENT_PAGES.get(PatientPageNumber.Guardian)!],
    (paperwork: PaperworkState) => isPaperworkForChild(paperwork.formData)
  ),
  ...buildFieldDependenciesForPages(
    [PATIENT_PAGES.get(PatientPageNumber.LanguageOther)!],
    (paperwork: PaperworkState) => {
      return paperwork.formData[ProviderGroupPaperworkFieldName.LANGUAGE] === 'Other';
    }
  ),
};

const medicalFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [MEDICAL_PAGES.get(MedicalPageNumber.MedicationsList)!],
    (paperwork: PaperworkState) => {
      return paperwork.formData[ProviderGroupPaperworkFieldName.MEDICATIONS_IS_TAKING] === 'Yes';
    }
  ),
  ...buildFieldDependenciesForPages(
    [MEDICAL_PAGES.get(MedicalPageNumber.AllergiesList)!],
    (paperwork: PaperworkState) => {
      return paperwork.formData[ProviderGroupPaperworkFieldName.ALLERGIES] === 'Yes';
    }
  ),
  ...buildFieldDependenciesForPages(
    [
      MEDICAL_PAGES.get(MedicalPageNumber.Pregnancy)!,
      MEDICAL_PAGES.get(MedicalPageNumber.BirthControl)!,
      MEDICAL_PAGES.get(MedicalPageNumber.PapSmear)!,
    ],
    (paperwork: PaperworkState) => shouldAskPregnancyQuestions(paperwork)
  ),
  ...buildFieldDependenciesForPages(
    [MEDICAL_PAGES.get(MedicalPageNumber.PapSmearDate)!],
    (paperwork: PaperworkState) => {
      return (
        shouldAskPregnancyQuestions(paperwork) &&
        paperwork.formData[ProviderGroupPaperworkFieldName.PAP_SMEAR_EVER_HAD] === 'Yes'
      );
    }
  ),
  ...buildFieldDependenciesForPages(
    [
      MEDICAL_PAGES.get(MedicalPageNumber.Menopause)!,
      MEDICAL_PAGES.get(MedicalPageNumber.Mammogram)!,
    ],
    (paperwork: PaperworkState) => {
      return (
        shouldAskPregnancyQuestions(paperwork) &&
        isPatientAgeAtLeast(40, paperwork.formData[PaperworkFieldName.PATIENT_FIELD_BIRTH_DATE])
      );
    }
  ),
};

const lifestyleFactorsFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [LIFESTYLE_FACTORS_PAGES.get(LifestyleFactorsPageNumber.AlcoholIntake)!],
    (paperwork: PaperworkState) => {
      return paperwork.formData[ProviderGroupPaperworkFieldName.DO_YOU_DRINK] === 'Yes';
    }
  ),
  ...buildFieldDependenciesForPages(
    [
      LIFESTYLE_FACTORS_PAGES.get(LifestyleFactorsPageNumber.CurrentSmoker)!,
      LIFESTYLE_FACTORS_PAGES.get(LifestyleFactorsPageNumber.SmokingIntake)!,
      LIFESTYLE_FACTORS_PAGES.get(LifestyleFactorsPageNumber.SmokingLength)!,
    ],
    (paperwork: PaperworkState) => {
      return paperwork.formData[ProviderGroupPaperworkFieldName.EVER_SMOKED] === 'Yes';
    }
  ),
  ...buildFieldDependenciesForPages(
    [LIFESTYLE_FACTORS_PAGES.get(LifestyleFactorsPageNumber.DrugsList)!],
    (paperwork: PaperworkState) => {
      return paperwork.formData[ProviderGroupPaperworkFieldName.DRUGS] === 'Yes';
    }
  ),
};

const healthcareContactsFieldDependencies = {
  ...buildFieldDependenciesForPages(
    [HEALTHCARE_CONTACTS_PAGES.get(HealthcareContactsPageNumber.PrimaryCareProvider)!],
    (paperwork: PaperworkState) => {
      return (
        paperwork.formData[ProviderGroupPaperworkFieldName.HAS_PRIMARY_CARE_PROVIDER] === 'Yes'
      );
    }
  ),
  ...buildFieldDependenciesForPages(
    [HEALTHCARE_CONTACTS_PAGES.get(HealthcareContactsPageNumber.IsEmployed)!],
    (paperwork: PaperworkState) => isLegalWorkingAge(paperwork.formData)
  ),
  ...buildFieldDependenciesForPages(
    [
      HEALTHCARE_CONTACTS_PAGES.get(HealthcareContactsPageNumber.IsEmployerRequest)!,
      HEALTHCARE_CONTACTS_PAGES.get(HealthcareContactsPageNumber.EmployerInfo)!,
    ],
    (paperwork: PaperworkState) => {
      return (
        isLegalWorkingAge(paperwork.formData) &&
        paperwork.formData[ProviderGroupPaperworkFieldName.IS_EMPLOYED] === 'Yes'
      );
    }
  ),
};

const verificationAndReviewFieldDependencies = EMPTY_OBJECT;

const pgFieldDependencies: { [fieldName: string]: DependencyFn } = {
  ...patientFieldDependencies,
  ...medicalFieldDependencies,
  ...lifestyleFactorsFieldDependencies,
  ...healthcareContactsFieldDependencies,
  ...verificationAndReviewFieldDependencies,
};

export const isProviderGroupFieldDependencySatisfied = (
  fieldName: string,
  paperwork: PaperworkState
) => {
  const dependencyFn = pgFieldDependencies[fieldName];
  return !dependencyFn || dependencyFn(paperwork);
};

export const getFieldsWithDependenciesSatisified = (
  sectionFields: string[],
  paperwork: PaperworkState
) => sectionFields.filter((field) => isProviderGroupFieldDependencySatisfied(field, paperwork));
