import React, { useEffect, useState, SyntheticEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Collapse from '@material-ui/core/Collapse';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';

// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { createForm, formShape } from 'rc-form';
import { colors } from '../../../../../constants/colors';
import { fontSize, fontFamily } from '../../../../../constants/text';
import {
  biggerThanOrEqualTo,
  smallerThanOrEqualTo,
} from '../../../../../core/util/styledComponents';
import TextField from '../../../../SolvPatternLibrary/TextField';
import Button, { ButtonVariations } from '../../../../SolvPatternLibrary/Button';
import ReasonForReportField from './ReasonForReportField';
import { CDP_REVIEW_REPORT_SUBMITTED } from '../../../../../constants';
import { REASON_FIELD } from './constants';

export const REPORT_REVIEW_MODAL = 'reportReviewModal';
const NAME_FIELD = 'name';
const EMAIL_FIELD = 'email';
const OTHER_FIELD = 'other';

const SmoothDialog = styled(Dialog)`
  -webkit-font-smoothing: antialiased;
  & .paper-root {
    overflow-y: unset;
  }
`;

const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  background-color: white;
  padding: 20px 40px;

  ${smallerThanOrEqualTo.md`
    padding: 20px;
  `}
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  margin: 0 0 -10px auto;
  ${smallerThanOrEqualTo.md`
    margin 0 0 0 auto;
  `}

  &:before {
    content: '';
    left: 50%;
    height: 100%;
    width: 1px;
    position: absolute;
    transform: rotate(45deg);
    background-color: ${colors.squid};
  }

  &:after {
    content: '';
    left: 50%;
    height: 100%;
    width: 1px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: ${colors.squid};
  }
`;

const Header = styled.div`
  font-size: ${fontSize.hellaLarge};
  font-family: ${fontFamily.bold};
  color: ${colors.squid};
  text-align: center;
  margin: 0 auto;

  ${smallerThanOrEqualTo.md`
    font-size: ${fontSize.extraLarge}
  `}
`;

const ActionContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${smallerThanOrEqualTo.md`
    flex-direction: column-reverse;
  `}
`;

const CancelButton = styled(Button)`
  color: ${colors.crunchBerry};
  border-color: ${colors.crunchBerry};

  ${smallerThanOrEqualTo.md`
    width: 100%;
  `}
`;

const SubmitButton = styled(CancelButton)`
  color: ${colors.white};
  margin: 0 0 15px;

  ${biggerThanOrEqualTo.md`
    margin: 0 0 0 15px;
  `}
`;

const fill = keyframes`
  from {
    transform: scaleX(0);
  } to {
    transform: scaleX(1);
  }
`;

const ThanksForFeedback = styled.div`
  font-family: ${fontFamily.book};
  font-size: ${fontSize.large};
  color: ${colors.greenHope};
  position: relative;
  margin: 100px auto 140px;
  display: inline-block;

  &:after {
    content: '';
    animation: ${fill} 4s linear 1;
    background-color: ${colors.greenHope};
    display: block;
    margin-top: 10px;
    height: 6px;
    width: 100%;
    transform-origin: left;
  }
`;

const CenteredCollapse = styled(Collapse)`
  text-align: center;
`;

type ReportReviewModalProps = {
  submitInfo: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  form: formShape;
  fullScreen?: boolean;
  solvRatings?: any[];
  open?: boolean;
};

const ReportReviewModal = (props: ReportReviewModalProps) => {
  const { fullScreen, open = false, onClose, form, submitInfo, solvRatings } = props;
  const {
    getFieldProps,
    getFieldValue,
    setFieldsValue,
    getFieldError,
    validateFields,
    getFieldsValue,
  } = form;

  const [submitted, setSubmitted] = useState(false);
  const [otherFieldError, setOtherFieldError] = React.useState(false);

  useEffect(() => {
    if (submitted) {
      const submitTimeout = setTimeout(() => setSubmitted(false), 4500);
      return () => clearTimeout(submitTimeout);
    }
    return undefined;
  }, [submitted, onClose]);

  const onChange = ({ target: { id, value } }: any) => setFieldsValue({ [id]: value });

  const getOtherFieldError = () => {
    const formData = getFieldsValue();
    const otherFieldValue = formData[OTHER_FIELD];
    return (
      formData[REASON_FIELD] === 'Other' &&
      (otherFieldValue === undefined || otherFieldValue === '')
    );
  };

  const handleOtherFieldChange = (e: SyntheticEvent) => {
    onChange(e);
    const hasError = getOtherFieldError();
    setOtherFieldError(hasError);
  };

  const getErrors = () => ({
    nameError: getFieldError(NAME_FIELD),
    emailError: getFieldError(EMAIL_FIELD),
  });

  const nameField = {
    ...getFieldProps(NAME_FIELD, {
      validateTrigger: 'onBlur',
      rules: [{ required: true, message: 'required' }],
    }),
    id: NAME_FIELD,
    label: 'Name',
    onChange,
  };

  const emailField = {
    ...getFieldProps(EMAIL_FIELD, {
      validateTrigger: 'onBlur',
      rules: [
        { required: true, message: 'required' },
        { type: 'email', message: 'must be an email' },
      ],
    }),
    id: EMAIL_FIELD,
    type: 'email',
    label: 'Email',
    onChange,
  };

  const otherField = {
    ...getFieldProps(OTHER_FIELD),
    onChange: handleOtherFieldChange,
    id: OTHER_FIELD,
    label: 'enter reason',
  };

  const validateAndSubmit = (errors: any) => {
    if (errors) return;
    const otherFieldError = getOtherFieldError();
    if (otherFieldError) {
      setOtherFieldError(true);
      return;
    }
    setOtherFieldError(false);
    const formData = getFieldsValue();
    const reasonValue =
      formData[REASON_FIELD] === 'Other' ? formData[OTHER_FIELD] : formData[REASON_FIELD];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const filtered = solvRatings.filter((rating) => rating.currentlyFlagged);

    if (Array.isArray(filtered)) {
      const reportedRating = filtered[0];
      submitInfo({
        name: formData[NAME_FIELD],
        email: formData[EMAIL_FIELD],
        reason: reasonValue,
        ...reportedRating,
      });
    }
    setSubmitted(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields(validateAndSubmit);
  };

  const helperNode = (error: any) => Array.isArray(error) && error[0];
  const { nameError, emailError } = getErrors();

  return (
    <SmoothDialog
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      open={open}
      PaperProps={{ classes: { root: 'paper-root' } }}
    >
      <FormContainer>
        <Close onClick={onClose} />

        <Header>Report Inaccurate Information</Header>

        <Collapse in={!submitted} timeout="auto">
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(nameError)}
              fullWidth
              helperText={helperNode(nameError)}
              {...nameField}
            />

            <TextField
              error={Boolean(emailError)}
              fullWidth
              helperText={helperNode(emailError)}
              {...emailField}
            />

            <ReasonForReportField getFieldProps={getFieldProps} onChange={onChange} />

            <Collapse in={getFieldValue(REASON_FIELD) === 'Other'}>
              <TextField error={Boolean(otherFieldError)} fullWidth {...otherField} />
            </Collapse>

            <ActionContainer>
              <CancelButton onClick={onClose} variation={ButtonVariations.tertiary}>
                Cancel
              </CancelButton>

              <SubmitButton type="submit" variation={ButtonVariations.pinkPrimary}>
                Submit
              </SubmitButton>
            </ActionContainer>
          </form>
        </Collapse>

        <CenteredCollapse in={submitted} mountOnEnter timeout="auto" unmountOnExit>
          <ThanksForFeedback>Thanks for the report!</ThanksForFeedback>
        </CenteredCollapse>
      </FormContainer>
    </SmoothDialog>
  );
};

const mapStateToProps = (state: any) => ({
  solvRatings: state.solvRatings,
});

const mapDispatchToProps = (dispatch: any) => ({
  submitInfo: (value: any) => dispatch({ type: CDP_REVIEW_REPORT_SUBMITTED, value }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog({ breakpoint: 'xs' })(createForm()(ReportReviewModal)));
