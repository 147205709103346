// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/haversine` if it exists or... Remove this comment to see the full error message
import haversine from 'haversine';

// @ts-ignore ts-migrate(7016) FIXME: Try `npm install @types/isomorphic-fetch` if it ex... Remove this comment to see the full error message
import fetch from 'isomorphic-fetch';
import {
  METERS_PER_MILE,
  CHECKIN_THRESHOLD_DISTANCE,
  METERS_PER_FOOT,
} from '../../constants/index';
import { safeGet } from './object';
import { METERS_IN_A_MILE } from '../../config';

/**
 * State data
 */
const fiftyStatesData = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

/**
 * Convert meters to miles
 *
 * @param {number} meters
 * @param {number} precision
 * @returns {number}
 */
const getMilesFromMeters = (meters: any, precision = 2) => {
  let distanceInMiles = null;

  if (meters !== 0 && !meters) return distanceInMiles;

  distanceInMiles = meters > 0 ? (meters / METERS_PER_MILE).toPrecision(precision) : 0;

  return distanceInMiles;
};

/**
 * Convert miles to meters
 *
 * @param {number} miles
 * @param {number} precision
 * @returns {number}
 */
const getMetersFromMiles = (miles: any, precision = 2) => {
  let distanceInMeters = null;

  if (miles !== 0 && !miles) return distanceInMeters;

  distanceInMeters = miles > 0 ? (miles * METERS_PER_MILE).toPrecision(precision) : 0;

  return distanceInMeters;
};

/**
 * Convert meters to miles
 *
 * @param {number} meters
 * @returns {number}
 */
const getFeetFromMeters = (meters: any) => {
  let distanceInFeet = null;

  if (meters !== 0 && !meters) return distanceInFeet;

  distanceInFeet = meters > 0 ? Math.round(meters / METERS_PER_FOOT) : 0;

  return distanceInFeet;
};

/**
 * Get human readable distance in miles / feet
 *
 * @param {number} meters
 * @returns {string} human readable distance format
 */
const getHumanReadableDistance = (meters: any) => {
  let distance = 0;
  let unit = 'mi';

  if (meters > 305)
    // 1000ft in meters
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    distance = getMilesFromMeters(meters);
  else {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    distance = getFeetFromMeters(meters);
    unit = 'ft';
  }

  return `${distance} ${unit}`;
};

/**
 * Parse out lat/long from API string value
 *
 * @param latLongString {string}
 * @returns {{latitude: float, longitude: float}}
 */
const parseLatLongString = (latLongString: any) => {
  // (37.59514,-122.37353)
  const parseRegex = /\((-?[0-9.]+),(-?[0-9.]+)/;
  let latitude = null;
  let longitude = null;
  latLongString.replace(parseRegex, (wholeMatch: any, $1: any, $2: any) => {
    latitude = $1;
    longitude = $2;
  });
  return { latitude, longitude };
};

const distance = (x: any, y: any, unit: any) => haversine(x, y, { unit });
const isWithinCheckinDistance = (positionLatLng: any, locationLatLng: any) =>
  distance(positionLatLng, locationLatLng, 'mile') < CHECKIN_THRESHOLD_DISTANCE;

/**
 * Returns true if x is within range of any point in ys
 *
 * @param x {{latitude: float, longitude: float}}
 * @param range {float}
 * @param ys {[{latitude: float, longitude: float}]}
 * @returns {boolean}
 */

const xIsWithinRangeOfY = (x: any, range: any, ys: any) =>
  ys.some((serviceLocation: any) => haversine(x, serviceLocation, { unit: 'mile' }) <= range);

/**
 * Returns object in list ys that is closest to x and distanace between object and x.
 *
 * @param x {{latitude: float, longitude: float}}
 * @param ys {[{..., latLong: {latitude: float, longitude: float}, ...}]}
 * @returns {object, float}
 */
const nearestToXFromAmongY = (x: any, ys: any) => {
  let nearest = null;
  let distance = null;
  for (const y of ys) {
    if (!distance) {
      nearest = y;
      distance = haversine(x, y.latLong, { unit: 'mile' });
    } else if (haversine(x, y.latLong, { unit: 'mile' }) < distance) {
      nearest = y;
      distance = haversine(x, y.latLong, { unit: 'mile' });
    }
  }

  return { nearest, distance };
};

const locationsDistanceMatrixDistanceIsWithinRange = (
  locationId: any,
  distanceMatrix: any,
  range: any
) => {
  if (
    !(
      distanceMatrix.results.hasOwnProperty(locationId) &&
      distanceMatrix.results[locationId].hasOwnProperty('distance') &&
      distanceMatrix.results[locationId].distance.hasOwnProperty('value')
    )
  ) {
    return false;
  }

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  if (getMilesFromMeters(distanceMatrix.results[locationId].distance.value) <= range) {
    return true;
  }

  return false;
};

const getDistanceMatrixDistanceByLocationId = (distanceMatrix: any, locationId: any) => {
  if (!distanceMatrix || !distanceMatrix.results || !locationId) {
    return null;
  }

  if (
    distanceMatrix.results.hasOwnProperty(locationId) &&
    distanceMatrix.results[locationId].distance
  ) {
    return distanceMatrix.results[locationId].distance.value;
  }

  return null;
};

const convertMilestoMeters = (miles: number) => miles * METERS_IN_A_MILE;

export {
  convertMilestoMeters,
  getMilesFromMeters,
  getMetersFromMiles,
  parseLatLongString,
  getHumanReadableDistance,
  xIsWithinRangeOfY,
  nearestToXFromAmongY,
  locationsDistanceMatrixDistanceIsWithinRange,
  getDistanceMatrixDistanceByLocationId,
  fiftyStatesData,
  isWithinCheckinDistance,
};
