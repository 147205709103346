const REMINDERS_RECEIVED = 'reminders/RECEIVED';
const CREATED_REMINDER_RECEIVED = 'reminders/CREATED_REMINDER_RECEIVED';
const UPDATED_REMINDER_RECEIVED = 'reminders/UPDATED_REMINDER_RECCEIVED';
const DELETED_REMINDER_RECEIVED = 'reminders/DELETED_REMINDER_RECEIVED';
const REMINDERS_ERROR = 'reminders/ERROR';
const REMINDERS_SUBMITTING = 'reminders/SUBMITTING';
const REMINDERS_FETCHING = 'reminders/FETCHING';

export const remindersReceived = (value: any) => ({
  type: REMINDERS_RECEIVED,
  payload: { value },
});

export const createdReminderReceived = (value: any) => ({
  type: CREATED_REMINDER_RECEIVED,
  payload: { value },
});

export const updatedReminderReceived = (value: any) => ({
  type: UPDATED_REMINDER_RECEIVED,
  payload: { value },
});

export const deletedReminderReceived = (value: any) => ({
  type: DELETED_REMINDER_RECEIVED,
  payload: { value },
});

export const remindersError = (value: any) => ({
  type: REMINDERS_ERROR,
  payload: { value },
});

export const remindersSubmitting = (value: any) => ({
  type: REMINDERS_SUBMITTING,
  payload: { value },
});

export const remindersFetching = (value: any) => ({
  type: REMINDERS_FETCHING,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case REMINDERS_RECEIVED:
      return {
        ...state,
        result: action.payload.value,
        error: null,
        submitting: false,
        fetching: false,
      };
    case CREATED_REMINDER_RECEIVED:
    case UPDATED_REMINDER_RECEIVED:
      return {
        ...state,
        result: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
          ...state.result,
          [action.payload.value.reminder_type]: action.payload.value,
        },
        error: null,
        submitting: false,
        fetching: false,
      };
    case DELETED_REMINDER_RECEIVED:
      return {
        ...state,
        result: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
          ...state.result,
          [action.payload.value.data.reminder_type]: action.payload.value.data,
        },
        error: null,
        submitting: false,
        fetching: false,
      };
    case REMINDERS_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
        submitting: false,
        fetching: false,
      };
    case REMINDERS_FETCHING:
      return {
        ...state,
        error: null,
        fetching: true,
        submitting: false,
      };
    case REMINDERS_SUBMITTING:
      return {
        ...state,
        submitting: action.payload.value,
      };
    default:
      return state;
  }
};
