class BookingImages {
  static RECEIVED = 'bookingImages/RECEIVED';

  static ERROR = 'bookingImages/ERROR';
}

export const bookingImagesReceived = (value: any) => ({
  type: BookingImages.RECEIVED,
  payload: { value },
});

export const bookingImagesError = (value: any) => ({
  type: BookingImages.ERROR,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case BookingImages.RECEIVED:
      return {
        ...state,
        [action.payload.value.bookingId]: {
          results: action.payload.value.results,
          error: null,
          submitting: false,
          fetching: false,
        },
      };
    case BookingImages.ERROR:
      return {
        ...state,
        [action.payload.value.bookingId]: {
          results: null,
          error: action.payload.value,
          submitting: false,
          fetching: false,
        },
      };
    default:
      return state;
  }
};
