import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components';
import AutocompleteResults from '../../../UniversalSearch/AutocompleteResults';
import Header from '../../../UniversalSearch/Header';
import { colors } from '../../../../constants/colors';
import { fontFamily, fontSize } from '../../../../constants/text';

const DialogContainer = styled.div`
  width: 100%;
  background-color: ${
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'greyDim' does not exist on type '{ fadeT... Remove this comment to see the full error message
    colors.greyDim
  };
  min-height: 100%;
`;

const Gutter = styled.div`
  padding: 0 10px;
  background-color: ${colors.white};
`;

const ResultsAnchor = styled.div`
  position: relative;
  width: 100%;
`;

const MobileResults = styled(AutocompleteResults)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};

  & > li:first-child {
    border-top: none;
  }
`;

const FilterRow = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding: 18px 8px 18px 20px;
  border-top: 1px solid ${colors.grayArea};
  background-color: ${colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${colors.greyHounds};
  }

  &:last-child {
    border-bottom: 1px solid ${colors.grayArea};
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 15px;
`;

const Group = styled.span`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const FilterLabel = styled.span`
  font-size: ${fontSize.default};
  font-family: ${fontFamily.book};
  color: ${colors.myRightFoot};
  white-space: nowrap;
`;

const ToTheRight = styled(ChevronRight)`
  color: ${colors.lightText};
`;

const Filter = styled(FilterLabel)`
  color: ${colors.lightText};
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  icon?: string;
  title: string;
  onCancel?: (requestSearch?: any) => void;
  onConfirm?: (requestSearch?: any) => void;
  inputLabel?: {
    inputValue?: string;
    isPlaceholder?: boolean;
  };
  className?: string;
  setSearchEnabled?: (searchEnabled: any) => void;
  position?: object;
  HeaderProps?: object;
  datatestid?: string;
};

type State = any;

export default class FilterInputWrapper extends PureComponent<Props, State> {
  inputRef: any;

  state = { results: [], inputModalOpen: false };

  closeModalCancel = () => {
    this.props.onCancel && this.props.onCancel();
    this.setState({ inputModalOpen: false });
  };

  closeModalConfirm = () => {
    this.props.onConfirm && this.props.onConfirm();
    this.setState({ inputModalOpen: false });
  };

  openModal = () =>
    this.setState({ inputModalOpen: true }, () => this.inputRef && this.inputRef.focus());

  handleUpdateSuggestionsRequest = (newSuggestions: any) =>
    this.setState({ results: newSuggestions });

  render() {
    const { className, children, icon, inputLabel, position, title, HeaderProps } = this.props;
    const { results, inputModalOpen } = this.state;

    return (
      <>
        <FilterRow
          className={className}
          /* @ts-ignore - Open github issue: https://github.com/mui-org/material-ui/issues/15827#issuecomment-809209533*/
          component="li"
          data-testid={this.props.datatestid}
          onClick={this.openModal}
        >
          <Group style={{ flexShrink: 0, paddingRight: '8px' }}>
            <Icon alt={icon} src={icon} />

            <FilterLabel>{title}</FilterLabel>
          </Group>

          <Group>
            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            <Filter>{inputLabel.inputValue}</Filter>
            {/* @ts-expect-error ts-migrate(2769) FIXME: Property 'size' does not exist on type '(Intrinsic... Remove this comment to see the full error message */}
            <ToTheRight size={12} />
          </Group>
        </FilterRow>

        <Dialog fullScreen keepMounted open={inputModalOpen}>
          <DialogContainer>
            <Gutter>
              <Header
                onCancel={this.closeModalCancel}
                onConfirm={this.closeModalConfirm}
                title={title}
                {...HeaderProps}
              />
              {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'ReactE... Remove this comment to see the full error message */}
              {React.cloneElement(children, {
                updateSuggestions: this.handleUpdateSuggestionsRequest,
                refSetter: (el: any) => (this.inputRef = el),
                onSelect: () => this.setState({ inputModalOpen: false }),
              })}
            </Gutter>

            <ResultsAnchor>
              <MobileResults position={position} results={results} />
            </ResultsAnchor>
          </DialogContainer>
        </Dialog>
      </>
    );
  }
}
