import moment from 'moment-timezone';
import {
  TIME_LAST_MINUTE_OF_THE_DAY,
  TIME_LAST_SECOND_OF_THE_DAY,
  TIME_MIDNIGHT,
} from '../../constants/index';

const timeDelta = (start: any, end: any) => {
  const first = moment(start);
  const second = moment(end);
  return second.diff(first);
};

const hoursAfterNow = (datetime: any) => {
  const delta = timeDelta(Date.now(), datetime);
  return moment.duration(delta).asHours();
};

const hoursBeforeNow = (datetime: any) => {
  const delta = timeDelta(datetime, Date.now());
  return moment.duration(delta).asHours();
};

const isMidnightish = (time: string) =>
  [TIME_LAST_MINUTE_OF_THE_DAY, TIME_LAST_SECOND_OF_THE_DAY, TIME_MIDNIGHT].includes(time);

const is24Hours = (from: string, to: string) => {
  if (isMidnightish(from) && isMidnightish(to)) return true;

  return from === to;
};

const isDatabaseBirthDate = (time: any) => /^\d{4}-\d{2}-\d{2}$/.test(time);

const isAfterToday = (time: any) => moment(time).isAfter(new Date(), 'day');

const isDateAfterIntervalInSeconds = (date: any, intervalInSeconds: any) =>
  moment(date).isSameOrAfter(moment().add(intervalInSeconds, 'seconds'), 'day');

export {
  isDateAfterIntervalInSeconds,
  hoursBeforeNow,
  hoursAfterNow,
  is24Hours,
  isDatabaseBirthDate,
  isMidnightish,
  isAfterToday,
};
