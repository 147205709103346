import { takeEvery, put, call, select } from 'redux-saga/effects';
import { AnyAction, Action } from 'redux';
import LabResultConfig from '@solvhealth/types/interfaces/LabResultConfig';
import LabResult from '@solvhealth/types/interfaces/LabResult';
import { DapiResponse, ResponseType } from '@solvhealth/types/interfaces/dapi';
import { getLabResultConfigUrl, getLabResultUrl } from '../core/dapi/labResults';
import { setRuntimeVariable } from '../actions/runtime';
import { apiGetJson, apiPatchJson } from '../core/dapi';
import { setLabResult, setLabResultConfig } from '../ducks/labResults';
import { analyticsTrackEvent } from '../core/analytics';
import { LAB_RESULTS_FAILED_TO_LOAD, LAB_RESULTS_VIEWED } from '../core/analytics/events';
import { getSolvUserDate } from '../core/util/account';

export enum LabResultsSagas {
  FETCH_LAB_RESULTS = 'labResultsSaga/FETCH_RESULTS',
  FETCH_LAB_RESULT_CONFIGS = 'labResultsSaga/FETCH_CONFIGS',
}

/**
 * Fetch lab result configs for the provided id's.
 *
 * @param args
 * @param args.labResultConfigIds list of lab results configs to fetch
 */
function* fetchLabResultConfigs({
  labResultConfigIds,
}: { labResultConfigIds: string[] } & AnyAction) {
  try {
    const response: DapiResponse<LabResultConfig, ResponseType.Paginated> = yield call(
      apiGetJson,
      getLabResultConfigUrl(labResultConfigIds)
    );

    yield put(setLabResultConfig(response.data.results));
  } catch (e) {
    console.error('sagas/labResults - fetchLabResultConfigs', e);
  }
}

/**
 * Fetch lab results for provided bookingId.
 *
 * Patches labResult if is_viewed flag is false.
 *
 * Then fetches the relevant lab result config
 *
 * @param args
 * @param args.labResultId id of lab result entity to fetch
 * @param args.lastName last name for verification to view result
 */
function* fetchLabResults({ labResultId, lastName }: AnyAction) {
  try {
    yield put(
      setRuntimeVariable({
        name: 'labResultsFetching',
        value: true,
      })
    );

    const { data, errors }: DapiResponse<LabResult> = yield call(
      apiGetJson,
      getLabResultUrl(labResultId, lastName)
    );
    yield put(setLabResult(labResultId, data));

    if (!data) {
      throw new Error(`Lab Result request denied: ${JSON.stringify(errors)}`);
    }

    analyticsTrackEvent(LAB_RESULTS_VIEWED, {
      solvUserDate: yield select((state: any) => getSolvUserDate(state.account?.summary)),
      locationId: data?.location_id,
      result: data?.result,
    });

    if (!data.is_viewed) {
      yield call(apiPatchJson, getLabResultUrl(labResultId), { is_viewed: true });
    }

    const labResultConfigIds = [data.lab_result_config_id];
    yield call(fetchLabResultConfigs, {
      labResultConfigIds,
      type: LabResultsSagas.FETCH_LAB_RESULT_CONFIGS,
    });
  } catch (e) {
    analyticsTrackEvent(LAB_RESULTS_FAILED_TO_LOAD, {
      error: e,
    });
    console.error('sagas/labResults - fetchLabResults', e);
  } finally {
    yield put(
      setRuntimeVariable({
        name: 'labResultsFetching',
        value: false,
      })
    );
  }
}

export default function* rootSaga() {
  yield takeEvery(LabResultsSagas.FETCH_LAB_RESULTS, fetchLabResults);
  yield takeEvery(LabResultsSagas.FETCH_LAB_RESULT_CONFIGS, fetchLabResultConfigs);
}
