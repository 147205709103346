export class Events {
  static ACCOUNT_NAVIGATION = 'Header Navigation';

  static UPCOMING_CARD = 'Upcoming Card';

  static IN_VISIT_CARD = 'In Visit Card';

  static PAST_CARD = 'Past Card';

  static OPEN_DRAWER = 'Open Drawer Menu';

  static CLOSE_DRAWER = 'Open Drawer Menu';

  static BOOKING_NOTES = 'Booking Notes';

  static INSURANCE_BADGE = 'Badges';
}

export class Actions {
  static TOUCH_PRIMARY_TAB = 'Touch Primary Tab';

  static PROFILE_SELECTOR = 'Profile Selector';

  static CLICK_COST_ESTIMATE = 'click cost estimate';

  static CLICK_ADD_INSURANCE = 'click add insurance';

  static CLICK_FILL_OUT_PAPERWORK = 'click fill out paperwork';

  static CLICK_ADD_DENTAL_INSURANCE = 'click add dental insurance';

  static CLICK_ADD_VISION_INSURANCE = 'click add vision insurance';

  static CLICK_PAY_BILL = 'click pay bill';

  static CLICK_ADD_NOTES = 'click add notes';

  static CLICK_ADD_PHOTO = 'click add photo';

  static CLICK_EDIT_NOTES = 'click edit notes';

  static CLICK_SAVE_NOTES = 'click save notes';

  static CLICK_CANCEL_NOTES = 'click cancel editing notes';

  static BADGE_DEDUCTIBLE_CHANGE = 'deductible change';

  static BADGE_FEATURE_CHANGE = 'feature change';

  static BADGE_FIRST_VISIT = 'first visit';

  static BADGE_DIMISS = 'dismiss';

  static CLICK_VIEW_VISIT_DETAILS = 'click view visit details';

  static CLICK_LEAVE_REVIEW = 'click leave a review';

  static CLICK_BOOK_AGAIN = 'click book again';

  static CLICK_MEMBERSHIP_AND_BILLING = 'click membership and billing';
}
