import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetJson } from '~/core/dapi';
import { getInsurancePlansByLocationUrl } from '~/core/dapi/location';
import { actions, selectors } from '../ducks/insuranceCarrierPlans';

export class InsuranceCarrierPlanSagas {
  static readonly FETCH_INSURANCE_CARRIER_PLANS_FOR_LOCATION =
    'insuranceCarrierPlans/sagas/FETCH_INSURANCE_CARRIER_PLANS_FOR_LOCATION';
}

function* doFetchInsuranceCarrierPlansForLocation(
  action: ReturnType<typeof actions.fetchInsuranceCarrierPlansForLocation>
) {
  const {
    payload: { locationId, onComplete, onError, onSuccess },
  } = action;

  try {
    if (!locationId) {
      return;
    }
    const {
      data: { results },
    } = yield call(apiGetJson, getInsurancePlansByLocationUrl(locationId));
    yield put(actions.receiveInsuranceCarrierPlansForLocation({ plans: results, locationId }));
    onSuccess?.();
  } catch (error) {
    yield put(actions.errorFetchingInsuranceCarrierPlansForLocation(error));
    onError?.();
  } finally {
    onComplete?.();
  }
}

function* rootSaga() {
  yield takeEvery(
    InsuranceCarrierPlanSagas.FETCH_INSURANCE_CARRIER_PLANS_FOR_LOCATION,
    doFetchInsuranceCarrierPlansForLocation
  );
}

export { rootSaga as default };
