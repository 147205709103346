import { Box, Checkbox, TextField } from '@solvhealth/jigsaw';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

type CheckboxWithInputProps = {
  checkboxLabel: string;
  inputLabel?: string;
  handleCheckboxAndInputChange: (
    checked: boolean,
    item: string,
    info: string,
    isExclusive: boolean
  ) => void;
  error?: boolean;
  inputValue: string;
  checked: boolean;
  isExclusive: boolean;
  disabled: boolean;
  setDisableCheckboxes: Dispatch<SetStateAction<boolean>>;
};

const CheckboxWithInput = (props: CheckboxWithInputProps): React.ReactElement => {
  const {
    checkboxLabel,
    inputLabel,
    handleCheckboxAndInputChange,
    error,
    checked,
    inputValue,
    isExclusive,
    disabled,
    setDisableCheckboxes,
  } = props;

  const [inputFieldValue, setInputFieldValue] = useState<string>(inputValue);

  useEffect(() => {
    if (isExclusive) setDisableCheckboxes(!!checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleCheckboxChange = (e: React.ChangeEvent, checked: boolean) => {
    handleCheckboxAndInputChange(checked, checkboxLabel, inputFieldValue, isExclusive);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleCheckboxAndInputChange(checked, checkboxLabel, e.target.value, isExclusive);
    setInputFieldValue(e.target.value);
  };

  const shouldRenderInput = checked && inputLabel !== undefined;

  return (
    <>
      <Checkbox
        checked={checked}
        disabled={disabled}
        label={checkboxLabel}
        onChange={handleCheckboxChange}
      />
      {shouldRenderInput && (
        <Box mb={2.5}>
          <TextField
            dense
            onChange={handleInputChange}
            title={inputLabel}
            value={inputFieldValue}
          />
        </Box>
      )}
    </>
  );
};

export default React.memo(CheckboxWithInput);
