import { analyticsTrackEvent } from '../../../core/analytics';
import { WEB_PUSH_OPT_IN } from '../../../core/analytics/events';
import logger from '../../../core/logger/index';
import { isUndefined } from '../../../core/util/object';
import { isMobile, isNativeApp } from '../../../core/util/device';
import { doesCookieExist } from '../../../core/util/cookies';
import { PUSH_NOTIFICATION_FULL_SCREEN_PROMPT_COOKIE_NAME } from '../../../config/index';

const PERMISSION_DENIED = 'denied';
const PERMISSION_GRANTED = 'granted';
const PERMISSION_DEFAULT = 'default';

const ACTION_DENY = 'deny';
const NATIVE_DENY = 'browser-deny';
const ACTION_ACCEPT = 'accept';

// Verify (if the browser supports it) that the user hans't already denied permission for web push
// https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission
export const canAskToEnablePush = () => {
  if (isNativeApp()) {
    return window.cankAskToEnableIosPush;
  }
  return isMobile() && 'Notification' in window && Notification.permission !== PERMISSION_DENIED;
};

export const hasAcceptedWebPush = () =>
  'Notification' in window && Notification.permission === PERMISSION_GRANTED;

export const hasAcceptedPush = () => {
  if (isNativeApp()) {
    return window.hasAcceptedIosPush;
  }
  return hasAcceptedWebPush();
};

export const handleAcceptWebPushPermission = (
  webPushOptInOrigin: any,
  notificationsSubscribe: any
) => {
  if (isUndefined(webPushOptInOrigin)) {
    logger.error(new Error('Please specify an origin for the web push opt in prompt'));
  }

  analyticsTrackEvent(WEB_PUSH_OPT_IN, {
    action: ACTION_ACCEPT,
    webPushOptInOrigin,
  });
  return notificationsSubscribe();
};

export const handleAcceptIosPushPermission = (origin: any) => {
  return window.handleAcceptIosPushPermission(origin);
};

export const handleDenyWebPushPermission = (webPushOptInOrigin: any, nativeDeny?: any) => {
  if (isUndefined(webPushOptInOrigin)) {
    logger.error(new Error('Please specify an origin for the web push opt in prompt'));
  }

  analyticsTrackEvent(WEB_PUSH_OPT_IN, {
    action: nativeDeny ? NATIVE_DENY : ACTION_DENY,
    webPushOptInOrigin,
  });
};

export const handleDenyIosPushPermission = () => {
  return window.handleDenyIosPushPermission();
};

export const hasSeenFullScreenPushPrompt = () =>
  doesCookieExist(PUSH_NOTIFICATION_FULL_SCREEN_PROMPT_COOKIE_NAME);
