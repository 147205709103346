import { SectionPageCollection } from '../../../interfaces';
import { ProviderGroupPaperworkFieldName } from '../../../paperworkConstants';

export enum MedicalPageNumber {
  Symptoms,
  PastConditions,
  Procedures,
  MedicationsIsTaking,
  MedicationsList,
  Allergies,
  AllergiesList,
  ImmunizationStatus,
  Pregnancy,
  BirthControl,
  PapSmear,
  PapSmearDate,
  Menopause,
  Mammogram,
  FamilyHistory,
}

export const MEDICAL_PAGES: SectionPageCollection = new Map([
  [
    MedicalPageNumber.Symptoms,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.SYMPTOMS],
      allowDisableSubmit: true,
    },
  ],
  [
    MedicalPageNumber.PastConditions,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.MEDICAL_CONDITIONS],
      allowDisableSubmit: true,
    },
  ],
  [
    MedicalPageNumber.Procedures,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.PROCEDURES],
      allowDisableSubmit: true,
    },
  ],
  [
    MedicalPageNumber.MedicationsIsTaking,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.MEDICATIONS_IS_TAKING],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.MedicationsList,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.MEDICATIONS_LIST],
      allowDisableSubmit: true,
    },
  ],
  [
    MedicalPageNumber.Allergies,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.ALLERGIES],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.AllergiesList,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.ALLERGIES_LIST],
      allowDisableSubmit: true,
    },
  ],
  [
    MedicalPageNumber.ImmunizationStatus,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.IMMUNIZATION_STATUS_IS_CURRENT],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.Pregnancy,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.EVER_BEEN_PREGNANT],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.BirthControl,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.BIRTH_CONTROL_USE],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.PapSmear,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.PAP_SMEAR_EVER_HAD],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.PapSmearDate,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.PAP_SMEAR_LAST_DATE],
    },
  ],
  [
    MedicalPageNumber.Menopause,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.LAST_PERIOD],
    },
  ],
  [
    MedicalPageNumber.Mammogram,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.MAMMOGRAM_EVER_HAD],
      allowSkip: true,
      showSubmit: false,
    },
  ],
  [
    MedicalPageNumber.FamilyHistory,
    {
      fieldNames: [ProviderGroupPaperworkFieldName.FAMILY_HISTORY_MEDICAL_CONDITIONS],
      allowDisableSubmit: true,
    },
  ],
]);
