const STORAGE_NATIVE_STORE_RATING_LOCK = 'NativeFunctions__NativeStoreRatingLock';

/**
 * This is for all Solv stored data in local storage
 */
export interface SolvLocalStorage {
  [STORAGE_NATIVE_STORE_RATING_LOCK]?: string;
  ccpaConsentBannerShown?: boolean; // For whether to show the cookie banner on first page load
}
type LsKeys = keyof SolvLocalStorage;

/**
 * Solv's utility function to set item in local storage.
 *
 * @param key one of the key defined in SolvLocalStorage interface
 * @param value value to set
 */
export function setItemInLocalStorage<K extends LsKeys>(key: K, value: string) {
  localStorage.setItem(key, value);
}

/**
 * Solv's utitlity function to get item in local storage.
 *
 * @param key one of the key defined in SolvLocalStorage interface
 * @returns value stored in local storage
 */
export function getItemInLocalStorage<K extends LsKeys>(key: K) {
  return localStorage.getItem(key);
}

export const getNativeStoreRatingLock = () => {
  const lock = getItemInLocalStorage(STORAGE_NATIVE_STORE_RATING_LOCK);
  if (lock) {
    const result = JSON.parse(lock);
    return !!result;
  }
  return false;
};

export const setNativeStoreRatingLock = (value: boolean) => {
  setItemInLocalStorage(STORAGE_NATIVE_STORE_RATING_LOCK, JSON.stringify(value));
};
