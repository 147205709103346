import { RESET_THEME, SET_THEME } from '~/constants';

const setTheme = (paletteName: string) => ({
  type: SET_THEME,
  payload: { paletteName },
});

const resetTheme = () => ({
  type: RESET_THEME,
});

export { setTheme, resetTheme };
