import { EnumType } from '../../../core/util/class';

export default class AccountViews extends EnumType {
  static GetCare = 'get-care';

  static Insurance = 'insurance';

  static Chat = 'chat';

  static Profile = 'profile';

  static Manage = 'manage';
}

export enum AccountManageTabs {
  Upcoming,
  Past,
}
