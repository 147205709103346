import { takeEvery, call, put } from 'redux-saga/effects';
import { apiPost, apiDeleteJson, apiGet } from '../core/dapi';
import {
  createWebPushSubscriptionUrl,
  fetchWebPushSubscriptionUrl,
  deleteWebPushSubscriptionUrl,
} from '../core/dapi/webPushSubscriptions';
import {
  createdWebPushSubscriptionReceived,
  webPushSubscriptionsError,
  webPushSubscriptionFetching,
  deleteWebPushSubscriptionReceived,
  fetchWebPushSubscriptionReceived,
  submitWebPushSubscription,
} from '../ducks/webPushSubscriptions';
import { analyticsTrackEvent } from '../core/analytics';
import { WEB_PUSH_SUBSCRIPTION } from '../core/analytics/events';

export const CREATE_WEB_PUSH_SUBSCRIPTION = 'saga/CREATE_WEB_PUSH_SUBSCRIPTION';
export const FETCH_WEB_PUSH_SUBSCRIPTION = 'saga/FETCH_WEB_PUSH_SUBSCRIPTION';
export const DELETE_WEB_PUSH_SUBSCRIPTION = 'saga/DELETE_WEB_PUSH_SUBSCRIPTION';

function* fetchWebPushSubscription({ accountId }: any) {
  try {
    const url = fetchWebPushSubscriptionUrl(accountId);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    yield put(webPushSubscriptionFetching());
    const response = yield call(apiGet, url);
    yield put(fetchWebPushSubscriptionReceived(response));
    analyticsTrackEvent(WEB_PUSH_SUBSCRIPTION, { action: 'fetch' });
  } catch (e) {
    yield put(webPushSubscriptionsError(e));
    analyticsTrackEvent(WEB_PUSH_SUBSCRIPTION, { action: 'error', error: e });
  }
}

function* createWebPushSubscription({ accountId, subscription }: any) {
  try {
    const { endpoint } = subscription;
    const { p256dh } = subscription.toJSON().keys;
    const { auth } = subscription.toJSON().keys;
    const url = createWebPushSubscriptionUrl();
    const webPushSubscriptionPostData = {
      account_id: accountId,
      endpoint,
      auth_key: auth,
      p256dh_key: p256dh,
      raw_subscription: JSON.stringify(subscription),
    };

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    yield put(submitWebPushSubscription());
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const createWebPushSubscriptionResponse = yield call(apiPost, url, webPushSubscriptionPostData);
    yield put(createdWebPushSubscriptionReceived(createWebPushSubscriptionResponse));
    analyticsTrackEvent(WEB_PUSH_SUBSCRIPTION, { action: 'create' });
  } catch (e) {
    yield put(webPushSubscriptionsError(e));
    analyticsTrackEvent(WEB_PUSH_SUBSCRIPTION, { action: 'error', error: e });
  }
}

function* deleteWebPushSubscription({ subscriptionId }: any) {
  try {
    const url = deleteWebPushSubscriptionUrl(subscriptionId);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    yield put(submitWebPushSubscription());
    const response = yield call(apiDeleteJson, url);
    yield put(deleteWebPushSubscriptionReceived(response));
    analyticsTrackEvent(WEB_PUSH_SUBSCRIPTION, { action: 'delete' });
  } catch (e) {
    yield put(webPushSubscriptionsError(e));
    analyticsTrackEvent(WEB_PUSH_SUBSCRIPTION, { action: 'error', error: e });
  }
}

function* rootSaga() {
  yield takeEvery(CREATE_WEB_PUSH_SUBSCRIPTION, createWebPushSubscription);
  yield takeEvery(FETCH_WEB_PUSH_SUBSCRIPTION, fetchWebPushSubscription);
  yield takeEvery(DELETE_WEB_PUSH_SUBSCRIPTION, deleteWebPushSubscription);
}

export { rootSaga as default };
