import React, { PureComponent } from 'react';
import styled from 'styled-components';

const PaddedStar = styled.svg`
  padding: 0 ${({ spacing }) => spacing} 0 0;
`;

const STAR_PATH =
  'M45.2197193,3.51562572 C45.9672956,1.95312573 47.0575099,0.911460065 48.4903653,' +
  '0.390625742 C49.9232207,-0.130208581 51.3560732,-0.130208581 52.7889286,0.390625742 C54.221783' +
  '9,0.911460065 55.3119983,1.95312573 56.0595745,3.51562572 L68.2076881,29.2968755 L95.4942201,3' +
  '3.398438 C97.1139696,33.6588537 98.391078,34.4401037 99.3255483,35.742188 C100.260019,37.04427' +
  '23 100.696104,38.476563 100.633807,40.0390629 C100.57151,41.6015629 99.9796775,42.9687504 98.8' +
  '583131,44.1406254 L79.0475433,64.2578128 L83.7198947,92.7734376 C83.9690858,94.3359375 83.6887' +
  '447,95.8007813 82.8788714,97.1679688 C82.0689981,98.5351563 80.9164839,99.4140625 79.4213314,9' +
  '9.8046875 C77.926179,100.195312 76.4933236,100 75.1227681,99.21875 L50.6396469,85.9375001 L26.' +
  '1565257,99.21875 C24.7859703,100 23.3531149,100.195312 21.8579625,99.8046875 C20.36281,99.4140' +
  '625 19.2102957,98.5351563 18.4004224,97.1679688 C17.5905492,95.8007813 17.3102081,94.3359375 1' +
  '7.5593992,92.7734376 L22.2317506,64.2578128 L2.42098074,44.1406254 C1.29961641,42.9687504 0.70' +
  '7784289,41.6015629 0.645487221,40.0390629 C0.583190153,38.476563 1.01927533,37.0442723 1.95374' +
  '561,35.742188 C2.88821588,34.4401037 4.16532431,33.6588537 5.78507373,33.398438 L33.0716058,29' +
  '.2968755 L45.2197193,3.51562572 Z';

type OwnProps = {
  percentFilled: number;
  identifier: string;
  size?: number | string;
  primaryColor?: string;
  secondaryColor?: string;
  spacing?: string;
};

type Props = OwnProps & typeof Star.defaultProps;

export default class Star extends PureComponent<Props> {
  static defaultProps = { size: 40 };

  render = () => {
    const { size, percentFilled, identifier, primaryColor, secondaryColor, spacing } = this.props;
    const gradientId = `fillRect-${percentFilled}-${identifier}`;
    return (
      <PaddedStar height={size} spacing={spacing} viewBox="0 0 100 100" width={size}>
        <linearGradient id={gradientId} x1="0" x2="1" y1="0" y2="0">
          <stop offset="0%" stopColor={primaryColor} />

          <stop offset={`${percentFilled}%`} stopColor={primaryColor} />

          <stop offset={`${percentFilled}%`} stopColor={secondaryColor} />

          <stop offset="100" stopColor={secondaryColor} />
        </linearGradient>

        <g fill={`url(#${gradientId})`} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path d={STAR_PATH} />
        </g>
      </PaddedStar>
    );
  };
}
