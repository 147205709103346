export default class ProxyTypes {
  constructor() {
    throw new Error('Class is of enum type and should not be instantiated.');
  }

  static ZipCode = 'zipcode';

  static Mailchimp = 'mailchimp';

  static GeoCode = 'geocode';

  static DistanceMatrix = 'distancematrix';

  static GooglePlaces = 'googleplaces';

  static Greenhouse = 'greenhouse';

  static AutoSuggest = 'autosuggest';

  static LocationIdTranslator = 'locationidtranslator';

  static TwilioLookup = 'twiliolookup';
}
