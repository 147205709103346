import { useSelector } from 'react-redux';
import { SolvReduxState } from '~/reducers';
import { AccountSummary } from '~/reducers/account';

/**
 * Hook to access the currently signed in user account
 *
 * @returns The account summary or undefined
 */
export function useAccount(): AccountSummary | undefined {
  return useSelector((state: SolvReduxState) => state.account.summary);
}
