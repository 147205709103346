import { patientRequestedUberWhileBooking } from '../../../core/util/uberHealth';
import {
  isTelemedLocation,
  shouldRequireEmailInBookingWidget,
  isPartOfUrgentCareForKidsGroup,
  isBirthSexRequired,
  isCityOfSeattleFreeCovidTestingLocation,
  isFamilyBookingsEnabled,
} from '../../../core/util/location';

export const SYMPTOMS_FIELD_NAME = 'symptoms';
export const LOCATION_FIELD_NAME = 'location';
export const APPOINTMENT_TIME_FIELD_NAME = 'appointmentTime';
export const PATIENT_TYPE_FIELD_NAME = 'patientType';
export const USER_PROFILE_FIELD_NAME = 'userProfile';
export const FIRST_NAME_FIELD_NAME = 'firstName';
export const LAST_NAME_FIELD_NAME = 'lastName';
export const BIRTH_DATE_FIELD_NAME = 'birthDate';
export const PHONE_FIELD_NAME = 'phone';
export const EMAIL_FIELD_NAME = 'email';
export const PAYMENT_FIELD_NAME = 'payment';
export const APPOINTMENT_TYPE_FIELD_NAME = 'appointmentType';
export const TOS_CONSENT_FIELD_NAME = 'tosConsent';
export const UNDER_13_GUARDIAN_CONSENT_FIELD_NAME = 'under13GuardianConsent';
export const TELEMED_CONSENT_FIELD_NAME = 'telemedConsent';
export const ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME = 'electronicCommunicationConsent';
export const ADDRESS_FIELD_NAME = 'address_street';
export const ADDRESS_SECONDARY_FIELD_NAME = 'address_street_secondary';
export const CITY_FIELD_NAME = 'address_city';
export const STATE_CODE_FIELD_NAME = 'address_state';
export const ZIP_CODE_FIELD_NAME = 'address_zip';
export const INSURANCE_PROFILE_FIELD_NAME = 'insuranceProfile';
export const BIRTH_SEX_FIELD_NAME = 'birthSex';
export const IS_EXISTING_PATIENT_FIELD_NAME = 'isExistingPatient';
export const SAME_ADDRESS = 'addressIsSameAsAccount';
export const IS_FAMILY_VISIT_FIELD_NAME = 'isFamilyVisit';

const ALL_ADDRESS_FIELDS = [
  ADDRESS_FIELD_NAME,
  CITY_FIELD_NAME,
  STATE_CODE_FIELD_NAME,
  ZIP_CODE_FIELD_NAME,
];

export const getDesktopRequiredFields = (location: any, values: any) => {
  let requiredFields = [];

  if (isTelemedLocation(location) || patientRequestedUberWhileBooking(values)) {
    requiredFields = [
      SYMPTOMS_FIELD_NAME,
      FIRST_NAME_FIELD_NAME,
      LAST_NAME_FIELD_NAME,
      BIRTH_DATE_FIELD_NAME,
      PHONE_FIELD_NAME,
      PAYMENT_FIELD_NAME,
      ADDRESS_FIELD_NAME,
      CITY_FIELD_NAME,
      STATE_CODE_FIELD_NAME,
      ZIP_CODE_FIELD_NAME,
      TOS_CONSENT_FIELD_NAME,
      IS_EXISTING_PATIENT_FIELD_NAME,
      UNDER_13_GUARDIAN_CONSENT_FIELD_NAME,
      APPOINTMENT_TIME_FIELD_NAME,
      TELEMED_CONSENT_FIELD_NAME,
      ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME,
    ];
  } else {
    requiredFields = [
      SYMPTOMS_FIELD_NAME,
      APPOINTMENT_TIME_FIELD_NAME,
      FIRST_NAME_FIELD_NAME,
      LAST_NAME_FIELD_NAME,
      BIRTH_DATE_FIELD_NAME,
      PHONE_FIELD_NAME,
      PAYMENT_FIELD_NAME,
      ADDRESS_FIELD_NAME,
      CITY_FIELD_NAME,
      STATE_CODE_FIELD_NAME,
      ZIP_CODE_FIELD_NAME,
      TOS_CONSENT_FIELD_NAME,
      IS_EXISTING_PATIENT_FIELD_NAME,
      UNDER_13_GUARDIAN_CONSENT_FIELD_NAME,
      ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME,
    ];
  }

  if (shouldRequireEmailInBookingWidget(location)) {
    requiredFields.push(EMAIL_FIELD_NAME);
  }

  if (isPartOfUrgentCareForKidsGroup(location)) {
    requiredFields.push(BIRTH_SEX_FIELD_NAME);
    ALL_ADDRESS_FIELDS.forEach((field) => requiredFields.push(field));
  }

  if (isCityOfSeattleFreeCovidTestingLocation(location)) {
    ALL_ADDRESS_FIELDS.forEach((field) => requiredFields.push(field));
  }

  if (isBirthSexRequired(location)) {
    requiredFields.push(BIRTH_SEX_FIELD_NAME);
  }

  if (isFamilyBookingsEnabled(location)) {
    requiredFields.push(IS_FAMILY_VISIT_FIELD_NAME);
  }

  return [...new Set(requiredFields)];
};

export const getMobileFirstFormRequiredFields = (location: any, values: any) => {
  return [SYMPTOMS_FIELD_NAME, APPOINTMENT_TIME_FIELD_NAME];
};

export const getMobileSecondFormRequiredFields = (location: any, form: any) => {
  let requiredFields = [];
  const values = form.getFieldsValue();
  requiredFields = [
    FIRST_NAME_FIELD_NAME,
    LAST_NAME_FIELD_NAME,
    BIRTH_DATE_FIELD_NAME,
    PHONE_FIELD_NAME,
    PAYMENT_FIELD_NAME,
    IS_EXISTING_PATIENT_FIELD_NAME,
    UNDER_13_GUARDIAN_CONSENT_FIELD_NAME,
    ADDRESS_FIELD_NAME,
    CITY_FIELD_NAME,
    STATE_CODE_FIELD_NAME,
    ZIP_CODE_FIELD_NAME,
    ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME,
  ];

  if (isTelemedLocation(location) || patientRequestedUberWhileBooking(values)) {
    requiredFields.push(EMAIL_FIELD_NAME);
    requiredFields.push(TELEMED_CONSENT_FIELD_NAME);
    ALL_ADDRESS_FIELDS.forEach((field) => requiredFields.push(field));
  }

  if (shouldRequireEmailInBookingWidget(location)) {
    requiredFields.push(EMAIL_FIELD_NAME);
  }

  if (isPartOfUrgentCareForKidsGroup(location)) {
    requiredFields.push(BIRTH_SEX_FIELD_NAME);
    ALL_ADDRESS_FIELDS.forEach((field) => requiredFields.push(field));
  }

  if (isCityOfSeattleFreeCovidTestingLocation(location)) {
    ALL_ADDRESS_FIELDS.forEach((field) => requiredFields.push(field));
  }

  if (isBirthSexRequired(location)) {
    requiredFields.push(BIRTH_SEX_FIELD_NAME);
  }

  return [...new Set(requiredFields)];
};

export const isFieldRequired = (fieldName: any, requiredLocation: any, values: any) => {
  const requiredFields = getDesktopRequiredFields(requiredLocation, values);
  return requiredFields.includes(fieldName);
};
