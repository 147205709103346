import {
  LOCATION_RESPONSE_RECEIVED,
  SET_ACTIVE_LOCATION,
  SET_ACTIVE_LOCATION_WITHOUT_SLOTS,
  LOCATION_FAILED,
  LOCATION_SLOTS_RECEIVED,
  LOCATION_EXTERNAL_SLOTS_LOADING,
  LOCATION_EXTERNAL_SLOTS_RECEIVED,
  LOCATION_EXTERNAL_SLOTS_ERROR,
  LOCATION_SLOTS_FAILED,
  SET_LOCATION_TO_EMPTY,
} from '../constants/index';
import { ADD_SOLV_RATING } from '../ducks/solvRatings';
import { getNewOverallRatingsAndCounts } from '../core/util/rating';
import { safeGet } from '../core/util/object';

export default function locationReducer(state = {}, action: any) {
  switch (action.type) {
    case ADD_SOLV_RATING:
      return {
        ...state,
        ...getNewOverallRatingsAndCounts(state, action.payload.rating),
      };
    case LOCATION_RESPONSE_RECEIVED:
    case SET_ACTIVE_LOCATION:
      return action.payload.value;
    case SET_LOCATION_TO_EMPTY:
      return action.payload.value;
    case SET_ACTIVE_LOCATION_WITHOUT_SLOTS:
      return action.payload.value;
    case LOCATION_SLOTS_RECEIVED:
      return {
        ...state,
        locationSlotsReceived: true,
        slots: action.payload.value.slots,
        hasBoost: action.payload.value.hasBoost,
      };
    case LOCATION_EXTERNAL_SLOTS_LOADING:
      return {
        externalSlotsLoading: action.payload.value,
      };
    case LOCATION_EXTERNAL_SLOTS_RECEIVED:
      return {
        ...state,
        externalSlots: {
          slots: action.payload.value.slots.appointments,
          loading: false,
        },
        externalSlotAppointmentReasons: action.payload.value.slots.reasons,
      };
    case LOCATION_EXTERNAL_SLOTS_ERROR: {
      // if we think there's valid location data in state, don't overwrite it
      if (
        safeGet(state)('id') === action.payload.locationId &&
        safeGet(state, 0)('externalSlots.length') > 0
      ) {
        return state;
      }

      return {
        ...state,
        externalSlots: {
          slots: [],
          loading: false,
          error: action.payload.value,
        },
        externalSlotAppointmentReasons: {},
      };
    }
    case LOCATION_FAILED: {
      // if we think there's valid data in state, don't overwrite it
      if (safeGet(state)('id') === action.payload.locationId) {
        return state;
      }

      return { error: action.payload.value };
    }
    case LOCATION_SLOTS_FAILED: {
      // if we think there's valid data in state, don't overwrite it
      if (
        safeGet(state)('id') === action.payload.locationId &&
        safeGet(state, 0)('slots.length') > 0
      ) {
        return { ...state, slotsFetchError: true };
      }

      return { ...state, slotsFetchError: true, slots: [] };
    }
    default:
      return state;
  }
}
