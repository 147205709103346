import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../actions/runtime';
import { analyticsTrackEvent } from '../../core/analytics';
import { CLOSE, MODAL, OPEN } from '../../core/analytics/events';

/**
 * Toggle the state of a modal in state.runtime.modal
 *
 * Returns both the state, and a toggle function to easily
 * switch between open/closed.
 *
 * @param modalId
 */
export default (modalId: string): [boolean, (e?: any) => void] => {
  const dispatch = useDispatch();
  const open = useSelector<any, boolean>((state: any) => !!state.runtime?.modal?.[modalId]);

  const toggle = useCallback(
    (e?: any) => {
      e?.stopPropigation?.();
      e?.preventDefault?.();

      analyticsTrackEvent(MODAL, { action: !open ? OPEN : CLOSE, modalId });
      dispatch(showModal(modalId, !open));
    },
    [open, modalId, dispatch]
  );

  return [open, toggle];
};
