import moment from 'moment-timezone';
import { OPAQUE_CONFIG } from '../../config/index';

const opaqueLocation = {
  appointmentInterval: OPAQUE_CONFIG.appointmentInterval,
  appointmentSlots: OPAQUE_CONFIG.appointmentSlots,
  distanceFromCurrentLocation: null,
  hours: OPAQUE_CONFIG.hours,
  id: null,
  isFormatted: true,
  isOpaque: true,
  isSolvPartner: false,
  name: 'TBD',
  timeZone: 'America/Los_Angeles',
};

const generateOpaqueSlots = () => {
  const dayNameFormat = 'dddd';
  const slotDuration = opaqueLocation.appointmentInterval;

  const setTime = (datetime: any, time: any) => {
    const timeParts = time.split(':');
    return datetime.hours(timeParts[0]).minutes(timeParts[1]).seconds(timeParts[2]).milliseconds(0);
  };

  const today = [];
  let dayName = moment().tz(opaqueLocation.timeZone).format(dayNameFormat);
  if (dayName in opaqueLocation.hours) {
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    const todaysHours = opaqueLocation.hours[dayName];

    for (const openClosePair of todaysHours) {
      const start = setTime(moment.tz(opaqueLocation.timeZone), openClosePair.fromTime);
      const finish = setTime(moment.tz(opaqueLocation.timeZone), openClosePair.toTime);
      for (let slot = start; slot.isSameOrBefore(finish); slot.add(slotDuration, 'minutes')) {
        today.push({
          appointmentDate: slot.valueOf(),
          availability: opaqueLocation.appointmentSlots,
        });
      }
    }
  }

  const tomorrow = [];
  dayName = moment().tz(opaqueLocation.timeZone).add(1, 'day').format(dayNameFormat);
  if (dayName in opaqueLocation.hours) {
    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    const tomorrowsHours = opaqueLocation.hours[dayName];

    for (const openClosePair of tomorrowsHours) {
      const start = setTime(moment.tz(opaqueLocation.timeZone), openClosePair.fromTime).add(
        1,
        'day'
      );
      const finish = setTime(moment.tz(opaqueLocation.timeZone), openClosePair.toTime).add(
        1,
        'day'
      );
      for (let slot = start; slot.isSameOrBefore(finish); slot.add(slotDuration, 'minutes')) {
        tomorrow.push({
          appointmentDate: slot.valueOf(),
          availability: opaqueLocation.appointmentSlots,
        });
      }
    }
  }

  return today.concat(tomorrow);
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'slots' does not exist on type '{ appoint... Remove this comment to see the full error message
opaqueLocation.slots = generateOpaqueSlots();

export { opaqueLocation };
