import {
  COVID_SERVICE_IDS,
  FLU_SHOT_SERVICE_ID,
  RAPID_COVID_SERVICE_IDS,
  STD_TEST_SERVICE_ID,
  TB_TEST_SERVICE_ID,
  ALLERGY_TEST_SERVICE_ID,
  PREGNANCY_TEST_SERVICE_ID,
  COMPREHENSIVE_METABOLIC_PANEL_TEST_SERVICE_ID,
  HIV_TEST_SERVICE_ID,
  A1C_TEST_SERVICE_ID,
  THYROID_TEST_SERVICE_ID,
} from '../../../constants/services';
import { CATEGORY_LABS_AND_TESTS } from '~/components/ClinicDetailPageFlexible/components/Services/constants';
import { URGENT_CARE_VALUE } from './ProviderTypes';

export interface Service {
  name: string;
  ids?: string;
  value?: string;
  providerType: string;
  icon?: string;
  highlight?: string;
  isCovidRelated: boolean;
  isFluRelated: boolean;
  category?: string;
}

export const services: Array<Service> = [
  {
    name: 'COVID test',
    ids: COVID_SERVICE_IDS.join(','),
    providerType: URGENT_CARE_VALUE,
    icon: '/images/icons/services/covid_test.svg',
    highlight: 'COVID test',
    isCovidRelated: true,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'Rapid COVID test',
    ids: RAPID_COVID_SERVICE_IDS.join(','),
    providerType: URGENT_CARE_VALUE,
    icon: '/images/icons/services/antibody_test.svg',
    isCovidRelated: true,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'Flu shot',
    ids: FLU_SHOT_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    icon: '/images/icons/services/covid_test.svg',
    highlight: 'Flu shot',
    isCovidRelated: false,
    isFluRelated: true,
    category: CATEGORY_LABS_AND_TESTS,
  },
];

export const labServices: Array<Service> = [
  {
    name: 'STD testing',
    ids: STD_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'STD testing',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'TB test',
    ids: TB_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'TB test',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'Allergy test',
    ids: ALLERGY_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'Allergy test',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'Pregnancy test',
    ids: PREGNANCY_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'Pregnancy test',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'Comprehensive metabolic panel',
    ids: COMPREHENSIVE_METABOLIC_PANEL_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'Comprehensive metabolic panel',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'HIV test',
    ids: HIV_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'HIV test',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'A1C test',
    ids: A1C_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'A1C test',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
  {
    name: 'Thyroid test',
    ids: THYROID_TEST_SERVICE_ID,
    providerType: URGENT_CARE_VALUE,
    highlight: 'Thyroid test',
    isCovidRelated: false,
    isFluRelated: false,
    category: CATEGORY_LABS_AND_TESTS,
  },
];

export const getServiceIndexByServiceIds = (serviceIds: string[]) =>
  services.findIndex((item) => item.ids === serviceIds.join(','));

export const servicesContainingCovid = services.filter((service) => service.name.includes('COVID'));

export const getServiceIdsFromDocId = (docId: string) => docId.split('_')[0];
