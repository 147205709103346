import { COVID_COST_TYPE_OPTIONS, PATIENT_TYPE_OPTIONS } from '../../constants/index';
import { profileIsChild } from '../../core/util/account';
import { getLocationLabelFromState } from '../../core/util/search';
import { AutoCompleteResult, SuggestionDoc } from './AutocompleteResults';
import { UNIVERSAL_SEARCH_PLACEHOLDER } from './Inputs/Universal';

export const POWERED_BY_GOOGLE_ID = 'Powered by google';

export const getUniversalLabel = (universalSearch: any, isInMarketPlaceSearch?: any) => {
  const inputQuery = universalSearch.displayQuery;
  const universalPlaceholder = UNIVERSAL_SEARCH_PLACEHOLDER;

  return {
    inputValue: inputQuery || universalPlaceholder,
    isPlaceholder: !inputQuery,
  };
};

export const getLocationLabel = (position: any, searchPreferences: any) => {
  const defaultLabel = 'City, State, or Zip';
  const inputValue = getLocationLabelFromState({
    position,
    searchPreferences,
    defaultLabel,
  });
  return {
    inputValue: inputValue || defaultLabel,
    isPlaceholder: inputValue === defaultLabel,
  };
};

export const getTimeLabel = (newBooking: any) => {
  const inputValue =
    newBooking.booking &&
    newBooking.booking.requestedAppointmentTime &&
    newBooking.booking.requestedAppointmentTime.timeLabel;
  return { inputValue: inputValue || 'ASAP' };
};

export const getPatientLabel = (newBooking: any) => {
  let inputValue =
    newBooking.booking && newBooking.booking.patientType && newBooking.booking.patientType.label;
  if (!inputValue && newBooking.profile && profileIsChild(newBooking.profile)) {
    inputValue = PATIENT_TYPE_OPTIONS[1].label; /* PATIENT_TYPE_KIDS */
  }
  return { inputValue: inputValue || PATIENT_TYPE_OPTIONS[0].label };
};

export const getInsuranceCarrierLabel = (searchPreferences: any) => {
  const inputValue =
    searchPreferences.requestedInsurance && searchPreferences.requestedInsurance.display_name;
  return { inputValue: inputValue || 'Select insurance' };
};

export const getCostLabel = (searchPreferences: any) => {
  return {
    inputValue: searchPreferences.covidCostType
      ? searchPreferences.covidCostType.label
      : COVID_COST_TYPE_OPTIONS[0].label,
  };
};

export const getDocProviderType = (result: AutoCompleteResult & { fullDoc?: SuggestionDoc }) =>
  result.fullDoc?.provider_type;

export const formatMultipleProviderTypes = (secondaryLabel: String | undefined) =>
  secondaryLabel?.split(',')?.join(' · ');
