import useMediaQuery from '@material-ui/core/useMediaQuery';
import { breakpoints } from '../core/util/styledComponents';

/**
 * A hook which allows simple programmatic access to breakpoints outside of JSX
 * or CSS.
 *
 * @param breakpoint The breakpoint name
 * @param direction Whether to target larger or smaller sizes
 * @returns Whether the current breakpoint is active
 */
export function useBreakpoint(
  breakpoint: keyof typeof breakpoints,
  direction: 'above' | 'below' = 'above'
) {
  const mediaQueryType = { above: 'min', below: 'max' }[direction];
  const mediaQuery = `(${mediaQueryType}-width: ${breakpoints[breakpoint]})`;

  return useMediaQuery(mediaQuery);
}
