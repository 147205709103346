import { useSelector } from 'react-redux';
import { UserProfile } from '~/core/dapi/models';
import { SolvReduxState } from '~/reducers';
import {
  getFirstProfileFromAccountSummary,
  getProfileFromAccountSummary,
} from '../core/util/account';

export const selectActiveUserProfile = (state: SolvReduxState): UserProfile | null | undefined => {
  const userProfileId = (state.newBooking?.profile as any)?.userProfileId;
  return userProfileId
    ? getProfileFromAccountSummary(state.account.summary, userProfileId)
    : getFirstProfileFromAccountSummary(state.account.summary);
};

export default (): UserProfile | undefined => useSelector(selectActiveUserProfile) ?? undefined;
