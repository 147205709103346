const getNewRatingAverage = (prevRatingCount: any, prevRating: any, newRating: any) =>
  (prevRating * prevRatingCount + newRating) / (prevRatingCount + 1);

const getNewOverallRatingsAndCounts = (locationState: any, rating: any) => {
  let {
    ratingNonSolv,
    ratingNonSolvCount,
    ratingSolv,
    ratingSolvCount,
    ratingWaitTime,
    ratingWaitTimeCount,
    ratingQualityOfCare,
    ratingQualityOfCareCount,
    ratingBedsideManner,
    ratingBedsideMannerCount,
    ratingStaffFriendliness,
    ratingStaffFriendlinessCount,
    ratingFacilityCleanliness,
    ratingFacilityCleanlinessCount,
  } = locationState;

  const {
    rating_bedside_manner: submittedRatingBedsideManner,
    rating_quality_of_care: submittedRatingQualityOfCare,
    rating_solv: submittedRatingSolv,
    rating_staff_friendliness: submittedRatingStaffFriendliness,
    rating_wait_time: submittedRatingWaitTime,
    rating_facility_cleanliness: submittedRatingFacilityCleanliness,
  } = rating;

  ratingNonSolv = getNewRatingAverage(ratingNonSolvCount, ratingNonSolv, submittedRatingSolv);
  ratingNonSolvCount += 1;
  ratingSolv = getNewRatingAverage(ratingSolvCount, ratingSolv, submittedRatingSolv);
  ratingSolvCount += 1;

  if (submittedRatingWaitTime) {
    ratingWaitTime = getNewRatingAverage(
      ratingWaitTimeCount,
      ratingWaitTime,
      submittedRatingWaitTime
    );
    ratingWaitTimeCount += 1;
  }

  if (submittedRatingQualityOfCare) {
    ratingQualityOfCare = getNewRatingAverage(
      ratingQualityOfCareCount,
      ratingQualityOfCare,
      submittedRatingQualityOfCare
    );
    ratingQualityOfCareCount += 1;
  }

  if (submittedRatingBedsideManner) {
    ratingBedsideManner = getNewRatingAverage(
      ratingBedsideMannerCount,
      ratingBedsideManner,
      submittedRatingBedsideManner
    );
    ratingBedsideMannerCount += 1;
  }

  if (submittedRatingStaffFriendliness) {
    ratingStaffFriendliness = getNewRatingAverage(
      ratingStaffFriendlinessCount,
      ratingStaffFriendliness,
      submittedRatingStaffFriendliness
    );
    ratingStaffFriendlinessCount += 1;
  }

  if (submittedRatingFacilityCleanliness) {
    ratingFacilityCleanliness = getNewRatingAverage(
      ratingFacilityCleanlinessCount,
      ratingFacilityCleanliness,
      submittedRatingFacilityCleanliness
    );
    ratingFacilityCleanlinessCount += 1;
  }

  return {
    ratingNonSolv,
    ratingNonSolvCount,
    ratingSolv,
    ratingSolvCount,
    ratingWaitTime,
    ratingWaitTimeCount,
    ratingQualityOfCare,
    ratingQualityOfCareCount,
    ratingBedsideManner,
    ratingBedsideMannerCount,
    ratingStaffFriendliness,
    ratingStaffFriendlinessCount,
    ratingFacilityCleanliness,
    ratingFacilityCleanlinessCount,
  };
};

export { getNewRatingAverage, getNewOverallRatingsAndCounts };
