import { takeEvery, call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiGet } from '../core/dapi';
import { getLocationConsentFormsUrl } from '../core/dapi/locationConsentForms';
import { ajaxPending, ajaxSuccess } from '../actions/runtime';
import { locationConsentFormsReceived, locationConsentFormsError } from '../ducks/paperwork';
import { analyticsTrackEvent } from '../core/analytics';
import { PAPERWORK_ERROR_LOADING_LOCATION_CONSENT_FORMS } from '../core/analytics/events';
import { SolvReduxState } from '~/reducers';

export class LocationConsentFormSagas {
  static FETCH_CONSENT_FORMS = 'SAGA/FETCH_CONSENT_FORMS';
}

export function* fetchConsentFormsHelper({ locationId }: any) {
  yield put(ajaxPending(LocationConsentFormSagas.FETCH_CONSENT_FORMS));
  let locationIdFromRedux = yield select((reduxState: SolvReduxState) => reduxState.location?.id);
  try {
    const response = yield call(
      apiGet,
      getLocationConsentFormsUrl(locationId ?? locationIdFromRedux)
    );
    yield put(locationConsentFormsReceived(response.results));
    yield put(ajaxSuccess(LocationConsentFormSagas.FETCH_CONSENT_FORMS)(response.results));
  } catch (e) {
    let err = e;
    analyticsTrackEvent(PAPERWORK_ERROR_LOADING_LOCATION_CONSENT_FORMS, {
      locationId: locationId ?? locationIdFromRedux,
    });
    for (let i = 0; i < 6; i++) {
      yield delay(2000);
      try {
        locationIdFromRedux = yield select((reduxState: SolvReduxState) => reduxState.location?.id);
        const response = yield call(
          apiGet,
          getLocationConsentFormsUrl(locationId ?? locationIdFromRedux)
        );
        yield put(locationConsentFormsReceived(response.results));
        yield put(ajaxSuccess(LocationConsentFormSagas.FETCH_CONSENT_FORMS)(response.results));
      } catch (ee) {
        err = ee;
        continue;
      }
      return;
    }
    yield put(locationConsentFormsError(err));
  }
}

export default function* fetchConsentForms() {
  yield takeEvery(LocationConsentFormSagas.FETCH_CONSENT_FORMS, fetchConsentFormsHelper);
}
