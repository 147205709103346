import { convertSnakeToCamelCaseRecursive } from '../../core/util/object';
import { compareSolvPartnerForSort } from '../../core/dapi/location';
import { locationResponseFormatter } from '../../reducers/formatters/location';

const SET_NEARBY_LOCATIONS_ALL = 'nearbyLocations/SET_LIST';
const SET_NEARBY_LOCATIONS_PARTNERS = 'nearbyLocations/SET_PARTNERS_LIST';
const SET_NEARBY_BRAND_LOCATION = 'nearbyLocations/SET_BRAND';
const SET_NEARBY_GROUP_LOCATIONS = 'nearbyLocations/SET_GROUP';
const SET_NEARBY_TELEMED_LOCATION = 'nearbyLocations/SET_TELEMED';
const CLEAR_NEARBY_LOCATIONS = 'nearbyLocations/CLEAR_LIST';

const formatter = (locations: any) =>
  locations.map((location: any) => locationResponseFormatter(location));

const setNearbyLocations = (value: any) => ({
  type: SET_NEARBY_LOCATIONS_ALL,
  payload: { value: formatter(value).sort(compareSolvPartnerForSort) },
});

const setNearbyPartnerLocations = (value: any) => ({
  type: SET_NEARBY_LOCATIONS_PARTNERS,
  payload: { value: formatter(value.results).sort(compareSolvPartnerForSort) },
});

const setNearbyBrandLocation = (value: any) => ({
  type: SET_NEARBY_BRAND_LOCATION,
  payload: { value: convertSnakeToCamelCaseRecursive(value) },
});

const setNearbyGroupLocations = (value: any) => ({
  type: SET_NEARBY_GROUP_LOCATIONS,
  payload: { value: convertSnakeToCamelCaseRecursive(value) },
});

const setNearbyTelemed = (value: any) => ({
  type: SET_NEARBY_TELEMED_LOCATION,
  payload: { value: locationResponseFormatter(value) },
});

const clearNearbyLocations = () => ({
  type: CLEAR_NEARBY_LOCATIONS,
});

export default (state = { nearby: [], partners: [], telemed: {} }, action: any) => {
  switch (action.type) {
    case SET_NEARBY_LOCATIONS_ALL:
      return {
        ...state,
        nearby: action.payload.value,
      };
    case SET_NEARBY_LOCATIONS_PARTNERS:
      return {
        ...state,
        partners: action.payload.value,
      };
    case SET_NEARBY_BRAND_LOCATION:
      return {
        ...state,
        brand: {
          location: action.payload.value.results[0] || {},
          locations: action.payload.value.results || [],
          brandCount: action.payload.value.page.resultsCount,
        },
      };
    case SET_NEARBY_GROUP_LOCATIONS:
      return {
        ...state,
        group: {
          locations: action.payload.value || [],
        },
      };
    case SET_NEARBY_TELEMED_LOCATION:
      return {
        ...state,
        telemed: action.payload.value,
      };
    case CLEAR_NEARBY_LOCATIONS:
      return {};
    default:
      return state;
  }
};

export {
  setNearbyTelemed,
  setNearbyLocations,
  setNearbyPartnerLocations,
  setNearbyBrandLocation,
  setNearbyGroupLocations,
  clearNearbyLocations,
};
