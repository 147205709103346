import { takeEvery, call, put, select, fork } from 'redux-saga/effects';
import { apiGet } from '../core/dapi';
import { getMarketplaceInsurersUrl } from '../core/dapi/marketplaceInsurers';
import { receiveMarketplaceInsurers } from '../ducks/marketplaceInsurers';

const FETCH_MARKETPLACE_INSURERS = 'saga/MARKETPLACE_INSURERS';

function* fetchMarketplaceInsurers() {
  const response = yield call(apiGet, getMarketplaceInsurersUrl());
  yield put(receiveMarketplaceInsurers(response));
}

function* rootSaga() {
  yield takeEvery(FETCH_MARKETPLACE_INSURERS, fetchMarketplaceInsurers);
}

export { FETCH_MARKETPLACE_INSURERS, rootSaga as default };
