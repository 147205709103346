import { RELATIONSHIP_TYPE_CHILD, RELATIONSHIP_TYPE_SELF } from '~/constants';

export const MEMBER_TELEMED_VISIT_COST_CENTS = 4900;
export const NON_MEMBER_TELEMED_VISIT_COST_CENTS = 7900;

export const PREMIUM_TELEMED_SLA_MINUTES = 30;

export const MEMBERSHIP_COST_CENTS = 1499;

// TODO we should aim to replace all these $_ format strings with cent consts and calls to centsToCurrencyStr()
// I don't think this is worth doing all at once right now, but probably a better direction to evolve in.
export const MONTHLY_MEMBERSHIP_COST_STRING = '$14.99';
export const ANNUAL_MEMBERSHIP_COST_STRING = '$99';
export const ANNUAL_MEMBERSHIP_MONTHLY_COST_STRING = '$8.25';

export const CARD_PROCESSING_FEE = '$3';
export const STEADYMD_FEE = '$38';

export const VIDEO_VISIT_MEMBER_DISCOUNT_DOLLARS = '$30';
export const VIDEO_VISIT_MEMBER_DISCOUNT_PERCENT = '35%';

export const MEMBERSHIP_SUPPORT_EMAIL = 'plussupport@solvhealth.com';
export const PREMIUM_VISIT_SUPPORT_EMAIL = 'solvnowsupport@solvhealth.com';

export const MEMBERSHIP_WAITLIST_URL = 'https://go.solvhealth.com/solvplus-waitlist';

export const MEMBERSHIP_NAME = 'Solv Plus';

export const PREMIUM_VISIT_NAME = 'Solv Now';

//this is the number to SteadyMD, our contracted vendor
export const PREMIUM_TELEMED_HOTLINE = '3149303541';
export const NON_PREMIUM_TELEMED_HOTLINE = '8555007989';
export const STEADY_MD_SUPPORT_EMAIL = 'info@steadymd.com';

export const MEMBERSHIP_SUPPORTED_STATES = [
  'AZ',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'ID',
  'IL',
  'KS',
  'MA',
  'MD',
  'MI',
  'MN',
  'MO',
  'NC',
  'NH',
  'NJ',
  'NM',
  'NY',
  'OH',
  'OR',
  'PA',
  'TX',
  'UT',
  'VA',
  'WA',
  'WI',
];

export const PREMIUM_SUPPORTED_RELATIONSHIPS = [RELATIONSHIP_TYPE_SELF, RELATIONSHIP_TYPE_CHILD];

export const SUPPRESSED_LOCATION_IDS = [
  // MultiCare. MedStar, Wellstreet, PM Pediatrics
  'gJRa6y',
  '0xY1kA',
  'gdL5Dg',
  'gZlLrA',
  'pjMmJA',
  'AaDXJ0',
  '0kDykA',
  'gwvdzp',
  '0x1BwA',
  'gbLO1A',
  '0EMRP0',
  'pnXqX0',
  'A24xoN',
  'pjwKGg',
  '0m8Qzg',
  '0eRPOA',
  'gZ58rp',
  'AWmGyg',
  'go82k0',
  'A4EOwp',
  'pRL6z0',
  'gQRRe0',
  'AvZZ8A',
  'AXXX2A',
  'g544Kp',
  'AXMQ7p',
  'pDYV7g',
  'gJMjzp',
  '0EMvG0',
  'pjM1dA',
  'gQo92A',
  'gLmqG0',
  'A2mk80',
  'AaDKX0',
  'gNeeLp',
  'A6yJ30',
  'gqoEEA',
  '0k8znA',
  'p8RdO0',
  'A6RLMA',
  '0x8M60',
  'p8OY8A',
  'pn88Q0',
  'goyLMA',
  'gd81wg',
  'A4ml2p',
  'A2m750',
  '076lxg',
  'g1m8Zg',
  'gbayNp',
  '0xvyrp',
  'AMZzNp',
  'A2w95p',
  '0OB1Wg',
  '0xdJM0',
  '0xvBrp',
  'py3B60',
  'g1P1oZ',
  'AzQRM0',
  '0PLwm9',
  'A6R961',
  'AGLwLa',
  'goQ9QM',
  'A4bZbl',
  'gNVnVD',
  'A6RzRM',
  '0xjXjG',
  'A9QrQz',
  'gbknkK',
  'gZeneB',
  'AzVLVn',
  '0xjXjk',
  'gbknkl',
  '0ry27N',
  'AaRn6R',
  'A246BY',
  '0EWVB9',
  'pjP6On',
  'AXenj8',
  'g53Wr4',
  'gLRJXE',
  '0PLnG9',
  '07R9wQ',
  '0VOnG6',
  'AB6Lre',
  'plbZDo',
  'AMVnMJ',
  'gJM3B4',
  'gqorBO',
  'gd8nea',
  'gKvMek',
  'gbknGO',
  'pnQN7m',
  'gNVnkJ',
  'g1P19W',
  'A6RzY2',
  '0xjXKj',
  'gZen68',
  'AWEnBG',
  'AzVLKM',
  'A9QrjB',
  'gbkn23',
  '0ry2Kv',
  'A246b5',
  'AaRnr8',
  'pYOn1l',
  'gbmWNp',
  'gdGr4p',
  'JA6n4A',
  '3gZ7L0',
  'qAW5eg',
  'K0xEQA',
  'pyXvWA',
  'gwvKdp',
  'pyX56K',
  'AGLwG2',
  '0EYwz0',
  'A6Pq4g',
  'AzlEWg',
  '0r3EJA',
  '0m3Z5p',
  'gQJM20',
  'A9ZqQp',
  'AMmYJA',
  'gZGmy8',
  'gKvvKp',
  'pjJ3np',
  '0EXxXg',
  'dgQzz0',
  'go3bdg',
  'A6yOD0',
  '0P989A',
  'AaRRJp',
  'g5q3mg',
  'pjnnDp',
  'p83KaA',
  'gdLevg',
  'AaD6Q0',
  'pjNolp',
  'AzWq1p',
  'epDwnA',
  'p834nA',
  '0eQJo0',
  '0OeQ3p',
  'xA9n4p',
  '0VoX6A',
  'pnJQkp',
  'gdQ9Q0',
  'gwzywp',
  '0rQGzg',
  '0e93B0',
  'pRJ9Kp',
  '0Pm6qA',
  'AzWY8p',
  'AWeQeg',
  '0mEWdq',
  'p8yVag',
  'gdLYag',
  'Az8b9M',
  'gdL6Bg',
  'gL82wg',
  'gZlwrA',
  'A9DG9B',
  'g1oVnA',
  'goQ9BG',
  'A9Gy4g',
  'gbLm83',
  'gL8QEg',
  '0P8Q90',
  '076kDg',
  'gKv86p',
  '0kDqGA',
  'AvyjR0',
  'AXNOVg',
  'AvdBjg',
  '0rdvYv',
  '076dQg',
  'gLx8Eg',
  'gQJ6a0',
  'A2W3Np',
  'Npnn5p',
  'A47Rl0',
  '0OdXdA',
  'gZx4BA',
  '0V8Q6p',
  '0O8DqA',
  'gZmQLp',
  'gqyKOg',
  'g5Gy4A',
  'AMJGkp',
  '0mjM9A',
  'AaxEl0',
  'A6d23g',
  'gK5vzA',
  'gNNlog',
  '0xwybg',
  'pD2Ll0',
  'A47dm0',
  'p8ymqg',
  'g1Z4Z0',
  'A6rO1A',
  '0rlLzp',
  'pyyXEp',
  'g13ERA',
  '0mjrDA',
  '0e9wk0',
  'g57onA',
  'go5MMA',
  'g57b4A',
  'p3VzQA',
  'ABDQeg',
  '0kDw2A',
  '1pR91A',
  'AGDLYp',
  'A6dG9g',
  'pRJR3p',
  '0OV8ag',
  'AzQrN0',
  'p8yq4g',
  '0e9Ww0',
  'gLLKEg',
  'gwwldg',
  'A9lKa0',
  'Pg1zn0',
  'pRJ3Jp',
  'gbZ92g',
  '0VJkm0',
  '0kDj7A',
  'AWoO6A',
  'p3mrQ0',
  '0mjEyA',
  'g13JRA',
  'A6dx9g',
  'x0kyrp',
  '0e92Q0',
  '0xwajg',
  '0rwWvA',
  'AaxN90',
  'JA6ak0',
  'pRJarp',
  'AvwlQp',
  'gNNQJg',
  'gZJX8g',
  'AzQDM0',
  'A27a5p',
  'AWoDvA',
  'AzQDN0',
  'gbZO1g',
  'A4bZNx',
  'AWv9e0',
  'A47bP0',
  'g132WA',
  'AWoDGA',
  'A9lLB0',
  'A9lLl0',
  '0rwWOA',
  '0V1wMg',
  '0kQwnp',
  'AGnEw0',
  'p8ydGg',
  'A6dj2g',
  'gbZO3g',
  'A27axp',
  'pYJwjg',
  '0kVVWk',
  'gNVV7a',
  'AWEwWe',
  'gbkr7N',
  '0ryZPz',
  'gwLeYz',
  '0ORqam',
  'plbe1Y',
  'gqoezl',
  'gKv395',
  '0mEe67',
  'goQeZV',
  'A4bxw2',
  'p8J9NP',
  '0e1qGB',
  'pjNddp',
  'AvVea3',
  'AvVeQQ',
  'pYOq91',
  'pDz7Zn',
  'gd8nvQ',
  '0xl8w0',
  'pDz7Xn',
  '0EWVmx',
  'pjP6z8',
  'AvwKqp',
  'pnXBv0',
  'pyyKPp',
  'Avw68p',
  'gJXrEA',
  'pRJJzp',
  '0kDPPA',
  'p3ooKp',
  'pYOOV0',
  'AzVx8A',
  'A9QOQA',
  '0ry7Jg',
  'A24Bwg',
  'AaR6lp',
  'pDzJe0',
  '0EWBPp',
  'pjPOJ0',
  'AXejdp',
  'g53rqA',
  'plxJrp',
  'pDezng',
  '0m8xrg',
  'A9RZap',
  'goJ3Gp',
  'A4Qyxp',
  'pylzeA',
  'plE78g',
  'A6dY8g',
  'A2yKM0',
  '0e11Y0',
  'g133EA',
  'A472Y0',
  'A6dd4g',
  '0O86BA',
  'A47yw0',
  '0xwwQg',
  'Aaxx50',
  'gK55nA',
  'p8yx8g',
  'gZJJLg',
  'pYJJNg',
  'pnXDQ0',
  'gqwKWA',
  '0mjV3A',
  'pYXqjA',
  'pjYXdA',
  'p8yEog',
  '0kDDnA',
  '0e9EO0',
  'AWooeA',
  '0mjjzA',
  '0VJVm0',
  'g1391A',
  'AGDD1p',
  'A4bbYg',
  'pRJ8zp',
  'A9ll40',
  'gZJ6wg',
  '0OKEB0',
  'A6dE4g',
  'p3VrKA',
  'pRJ5kp',
  '0kDMnA',
  'gbZZNg',
  'gQJX70',
  '0e99O0',
  'ABb1JA',
  '077Dw0',
  'pyyyYp',
  'gLLaGg',
  'gQJJe0',
  'pnXXQ0',
  'p8yy8g',
  'gwwKBg',
  'A477w0',
  'gNNoLg',
  'go55kA',
  'AXJkWg',
  'Avww8p',
  '0xwKrg',
  'gdzYBA',
  '0xwZQg',
  'gNNDqg',
  'gNNNLg',
  '0e9DY0',
  'g13rEA',
  'gK52KA',
  'AGDzlp',
  'gQJxe0',
  'AMJYZp',
  'pDzPrN',
  '0EW7N4',
  'pjPk9B',
  'g53Zmb',
  'gLRM58',
  '0PLKVJ',
  '07Rz4j',
  '0VOqMj',
  'AB659P',
  'gwLe79',
  '0ORq7E',
  'plberw',
  'p3o6yM',
  'AMVqLB',
  'gJMljl',
  'gqoeEr',
  'gKv3XO',
  'pyXeBw',
  'AGLBYJ',
  'p8J98n',
  '0e1q6o',
  'pRGqwD',
  '0kVkbR',
  'gQGqBB',
  'AvVePQ',
  'pnQeam',
  'gNVqPJ',
  'g1PMNW',
  'A6RWL2',
  '0xjenj',
  'gZeqD8',
  'AWEq2G',
  'AzVenM',
  'pjP6a8',
  'AXeny7',
  'g53WRm',
  'gLRJPk',
  '0PLnoZ',
  '07R9Jl',
  '0VOneR',
  'AB6LNN',
  '0ORnV3',
  'plbZlx',
  'p3ox9w',
  'AMVn75',
  'gJM3RY',
  'gqorLn',
  'gKvMN6',
  'pyX5ov',
  'AGLwMa',
  '0mEWv9',
  'goQ9nM',
  'A4bZ3l',
  'p8Jbea',
  '0e1nzE',
  'pRGnOJ',
  '0kVxY2',
  'gQGnV2',
  'pnQNW7',
  'gNVnRD',
  'g1P1aY',
  'A6Rz2M',
  '0xjXdG',
  'gZenGq',
  'AWEnjn',
  'AzVL81',
];

export const MEMBERSHIP_BOOKING_FLAG_ENABLED_DATE = new Date('2022-02-15');
