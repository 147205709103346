import React, { PureComponent } from 'react';

// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { formShape } from 'rc-form';
import styled from 'styled-components';
import { array } from 'prop-types';
import SelectField from '../SelectTextField';
import { emptyFieldValidator } from '../../../core/util/rcForm/validators';
import {
  RELATIONSHIP_TYPE_SELF,
  RELATIONSHIP_TYPE_CHILD,
  RELATIONSHIP_TYPE_OTHER,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_SPOUSE,
  RELATIONSHIP_TYPE_SIBLING,
} from '../../../constants/index';
import { isEmptyArray } from '../../../core/util/array';

const StyledSelect = styled(SelectField)`
  width: ${(props) =>
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.width || '140px'};
  margin-right: 10px;
`;

const relationshipToAccountValues = [
  '',
  RELATIONSHIP_TYPE_SELF,
  RELATIONSHIP_TYPE_CHILD,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_SPOUSE,
  RELATIONSHIP_TYPE_OTHER,
  RELATIONSHIP_TYPE_SIBLING,
];

export const getValidRelationships = (profiles?: any) => {
  const values = relationshipToAccountValues.map((rel) => ({
    key: rel.toLowerCase(),
    value: rel.toLowerCase(),
    label: rel,
  }));
  if (!isEmptyArray(profiles)) {
    if (
      profiles.find(
        (profile: any) => profile.relationship_to_account === RELATIONSHIP_TYPE_SELF.toLowerCase()
      )
    )
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
      return values.filter((value) => value !== RELATIONSHIP_TYPE_SELF);
  }
  return values;
};

type RelationshipToAccountInputProps = {
  fieldId?: string;
  form: formShape;
  className?: string;
  label?: string;
  initialValue?: string;
  // @ts-expect-error ts-migrate(2749) FIXME: 'array' refers to a value, but is being used as a ... Remove this comment to see the full error message
  userProfiles?: array;
  width?: string;
};

export default class RelationshipToAccountInput extends PureComponent<RelationshipToAccountInputProps> {
  RELATIONSHIP_TO_ACCOUNT_FIELD = this.props.fieldId || 'relationshipToAccount';

  getRelationshipToAccountProps = () => {
    const { form, label, initialValue, width } = this.props;
    const error = form.getFieldError(this.RELATIONSHIP_TO_ACCOUNT_FIELD);
    return {
      ...form.getFieldProps(this.RELATIONSHIP_TO_ACCOUNT_FIELD, {
        initialValue: initialValue || '',
        validateTrigger: ['onBlur', 'onChange'],
        rules: [
          {
            required: true,
            validator: emptyFieldValidator,
          },
        ],
      }),
      id: this.RELATIONSHIP_TO_ACCOUNT_FIELD,
      label: label || 'Relationship',
      error: !!error,
      selectOptions: getValidRelationships(),
      width,
    };
  };

  render = () => {
    const { className } = this.props;

    return <StyledSelect className={className} {...this.getRelationshipToAccountProps()} />;
  };
}
