import pickBy from 'lodash/pickBy';
import Provider from '@solvhealth/types/interfaces/Provider';
import { ImageObject } from '@solvhealth/types/interfaces/ImageObject';
import { isEmpty } from '../../../core/util/empty';
import { getNameInitialsFromFullName } from '../../../core/util/string';
import { analyticsTrackEvent } from '../../../core/analytics';
import { isClientSide } from '../../../core/util/system';
import {
  isProviderGroupLocationPage,
  isProviderGroupProviderPage,
  isProviderGroupSpecialtyPage,
} from '../../../core/opengraph';
import {
  PROVIDER_DETAIL_PAGE,
  PROVIDER_GROUP_LOCATION_PAGE,
  PROVIDER_GROUP_SPECIALTY_PAGE,
} from '../../../core/analytics/events';

const doesTitleGrantDrPrefix = (title: string) => {
  if (isEmpty(title)) {
    return false;
  }
  return ['md', 'do', 'phd', 'dnp', 'dpm'].some(
    (t) => title.replace(/\W/g, '').toLowerCase() === t
  );
};

const getProviderName = (
  provider?: Pick<Provider, 'first_name' | 'last_name' | 'title'>,
  shouldIncludeTitle: boolean = true,
  shouldIncludeDrPrefix: boolean = true,
  isDrPrefixDisabled: boolean = false
) => {
  if (!provider) return '';
  let name = `${provider.first_name} ${provider.last_name}`;
  if (provider?.title) {
    if (!isDrPrefixDisabled && shouldIncludeDrPrefix && doesTitleGrantDrPrefix(provider.title)) {
      name = `Dr. ${name}`;
    }
    if (shouldIncludeTitle) {
      name = `${name}, ${provider.title}`;
    }
  }
  return name;
};

const getShortProviderName = (provider: Provider) => {
  if (doesTitleGrantDrPrefix(provider.title)) {
    return `Dr. ${provider.last_name}`;
  }
  return provider.first_name;
};

const getProviderInitials = (provider: Provider) =>
  getNameInitialsFromFullName(getProviderName(provider, false, false), '');

const getImageObject = (provider: Provider): ImageObject => {
  const defaultPhoto = '/images/providerGroups/provider_default.svg';
  if (!provider?.image_links)
    return { fullRes: defaultPhoto, webp: defaultPhoto, jp2: defaultPhoto };
  const fullRes = provider?.image_links?.image?.[0] ?? defaultPhoto;
  const webp = provider?.image_links?.image_webp?.[0] ?? fullRes;
  const jp2 = provider?.image_links?.image_jp2?.[0] ?? fullRes;
  return { fullRes, webp, jp2 };
};

const getTrackingPageEventName = (): string => {
  if (isClientSide()) {
    const url = window?.location?.pathname ?? '';
    if (isProviderGroupProviderPage(url)) {
      return PROVIDER_DETAIL_PAGE;
    }
    if (isProviderGroupSpecialtyPage(url)) {
      return PROVIDER_GROUP_SPECIALTY_PAGE;
    }
    if (isProviderGroupLocationPage(url)) {
      return PROVIDER_GROUP_LOCATION_PAGE;
    }
  }
  return '';
};

const trackAction = ({
  providerId,
  locationId,
  groupId,
  actionName,
  eventName = getTrackingPageEventName(),
  additionalTrackingProps,
}: {
  providerId?: string;
  locationId?: string;
  groupId?: string;
  actionName: string;
  eventName?: string;
  additionalTrackingProps?: { [key: string]: string | number | boolean | null };
}) => {
  const trackingProperties = {
    action: actionName,
    providerId: providerId ?? undefined,
    locationId: locationId ?? undefined,
    groupId: groupId ?? undefined,
    ...additionalTrackingProps,
  };

  const cleanedTrackingProperties = pickBy(trackingProperties, (v) => v !== undefined);

  analyticsTrackEvent(eventName, cleanedTrackingProperties);
};

export {
  getProviderName,
  getShortProviderName,
  getImageObject,
  getProviderInitials,
  getTrackingPageEventName,
  trackAction,
  doesTitleGrantDrPrefix,
};
