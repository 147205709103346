import isEmpty from 'lodash/isEmpty';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import { formatLocationName, getLocationSpecialtyValue } from './location';
import {
  PEDIATRIC_PATH,
  STATE_CODE_TO_LABEL,
  URGENT_CARE_PATH,
  WALK_IN_CLINIC_PATH,
} from '../../constants/index';
import { HOME_HEALTHCARE, URGENT_CARE, PEDIATRIC_URGENT_CARE } from '../../constants/category';
import { CDP_TITLE_CATEGORY_OR_SPECIALTY, getCustomization } from './customization';
import { getProviderTypeName } from '../../components/Home/Tiles/ProviderTypes';

const formatLocationImageTitle = (location: any) => {
  const providerTypeName = getProviderTypeName(getLocationSpecialtyValue(location));
  return `${formatLocationName(location)} - ${providerTypeName} Solv in ${location.city}, ${
    location.state
  }`;
};

// SOLV-4761: location image alt should match location image title and the content should be merged.
const formatLocationImageAlt = (location: any) => formatLocationImageTitle(location);

const fromStateCodeToState = (stateCode: any) => {
  if (!isEmpty(stateCode)) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const stateName = STATE_CODE_TO_LABEL[stateCode];
    return !isEmpty(stateName) ? stateName.toLowerCase().replace(/ /g, '-') : null;
  }

  return null;
};

const getStateDirectoryUrl = (stateCode: any) => {
  let stateCodeLowerCase = stateCode.toLowerCase();
  return `/${stateCodeLowerCase}/s/${fromStateCodeToState(stateCodeLowerCase)}-urgent-care`;
};

const getSanitizedCity = (cityName: any) => {
  return cityName
    .toLowerCase()
    .replace("'", '-')
    .replace(/\s/gi, '-')
    .replace(/[^a-z0-9-]/gim, '');
};

const getServiceDirectoryUrl = (state: any, city: any, serviceSlug: any) => {
  const stateCode = state.toLowerCase();
  const cityName = getSanitizedCity(city);
  return `/${stateCode}/c/${cityName}-${stateCode}-srv-${serviceSlug}`;
};

const getCityDirectoryUrl = (stateCode: any, cityName: any, specialtySlug = 'urgent-care') => {
  const sanitizedCity = getSanitizedCity(cityName);
  const stateCodeLowerCase = stateCode.toLowerCase();
  return `/${stateCodeLowerCase}/c/${sanitizedCity}-${stateCodeLowerCase}-${specialtySlug}`;
};

const getCdpTitle = (location: LocationInterface, abTestFeatureFlag: boolean = false) => {
  const clinicName = formatLocationName(location, ', ');
  let categoryOrSpecialty = getCustomization(CDP_TITLE_CATEGORY_OR_SPECIALTY, { location }).value;

  const { displayNamePrimary, city, state, zipCode, specialties } = location;
  let title = `${clinicName} - Book Online - ${categoryOrSpecialty} in ${city}, ${state} ${zipCode} | Solv`;

  // SEO A/B/C Test
  if (city.toLowerCase() === 'houston' && abTestFeatureFlag && categoryOrSpecialty !== '') {
    title = `${displayNamePrimary} - ${city}, ${state} ${categoryOrSpecialty}`;
  } else if (city.toLowerCase() === 'san antonio' && abTestFeatureFlag) {
    title = `Visit ${displayNamePrimary} - ${city}, ${state} | Solv`;
  } else if (city.toLowerCase() === 'phoenix' && abTestFeatureFlag && categoryOrSpecialty !== '') {
    title = `${displayNamePrimary} - ${city}, ${state} - Visit this ${categoryOrSpecialty}`;
  }

  return title;
};

const getCategoryPath = (category: any) => {
  switch (category) {
    case URGENT_CARE:
    case HOME_HEALTHCARE:
      return URGENT_CARE_PATH;
    case PEDIATRIC_URGENT_CARE:
      return PEDIATRIC_PATH;
    default:
      return WALK_IN_CLINIC_PATH;
  }
};

export {
  formatLocationImageAlt,
  formatLocationImageTitle,
  getStateDirectoryUrl,
  getCityDirectoryUrl,
  getServiceDirectoryUrl,
  fromStateCodeToState,
  getCdpTitle,
  getCategoryPath,
  getSanitizedCity,
};
