const USER_PROFILE_PHOTO_UPLOAD = 'userProfile/PROFILE_PHOTO_UPLOAD';

// actions
export const userProfilePhotoUpload = (value: any) => ({
  type: USER_PROFILE_PHOTO_UPLOAD,
  payload: { value },
});

// reducers
export default (state = { status: '' }, action: any) => {
  switch (action.type) {
    case USER_PROFILE_PHOTO_UPLOAD:
      return {
        ...state,
        ...action.payload.value,
      };
    default:
      return state;
  }
};
