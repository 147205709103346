// actions
/* eslint-disable max-classes-per-file */
import { EnumType } from '../core/util/class';

export const ACTION_NOOP = 'action/NOOP';
export const ACTION_REHYDRATE = 'persist/REHYDRATE';

export const CDP_REVIEW_REPORT_SUBMITTED = 'ratings/REPORT_SUBMITTED';

export const SET_RUNTIME_VARIABLE = 'runtime/SET_RUNTIME_VARIABLE';
export const SET_AJAX_PENDING = 'runtime/SET_AJAX_PENDING';
export const SET_AJAX_ERROR = 'runtime/SET_AJAX_ERROR';
export const RUNTIME_MODAL = 'runtime/MODAL';
export const RUNTIME_SNACKBAR = 'runtime/SNACKBAR';
export const RUNTIME_AJAX_PENDING = 'runtime/AJAX_PENDING';
export const RUNTIME_AJAX_SUCCESS = 'runtime/AJAX_SUCCESS';
export const RUNTIME_AJAX_ERROR = 'runtime/AJAX_ERROR';

export const SET_THEME = 'theme/SET_THEME';
export const RESET_THEME = 'theme/RESET_THEME';

export const DISTANCE_MATRIX_RESPONSE_RECEIVED = 'distanceMatrix/RESPONSE_RECEIVED';
export const DISTANCE_MATRIX_FAILED = 'distanceMatrix/MATRIX_FAILED';

export const SET_USER_LOCATION = 'searchPreferences/SET_USER_LOCATION';
export const SET_SEARCH_IN_MARKET_PLACE = 'searchPreferences/SET_SEARCH_IN_MARKET_PLACE';
export const BROWSER_LOCATION_ALLOWED = 'searchPreferences/BROWSER_LOCATION_ALLOWED';
export const BROWSER_LOCATION_DENIED = 'searchPreferences/BROWSER_LOCATION_DENIED';
export const SHOW_SEARCH_FILTERS = 'searchPreferences/SEARCH_FILTERS';
export const SET_ACTIVE_FILTER = 'searchPreferences/SET_ACTIVE_FILTER';
export const SHOW_ACTIVE_FILTER = 'searchPreferences/SHOW_ACTIVE_FILTER';
export const SET_SEARCH_COVID_COST_TYPE = 'searchPreferences/SET_SEARCH_COVID_COST_TYPE';
export const SET_SEARCH_PREFERENCES_PAGINATION_PARTNER_RADIUS =
  'searchPreferences/SET_SEARCH_PREFERENCES_PAGINATION_PARTNER_RADIUS';
export const SET_SEARCH_RESULTS_RADIUS = 'searchResults/SET_SEARCH_RESULTS_RADIUS';

export const TOOLTIP_SHOW = 'toolTip/TOOLTIP_SHOW';
export const TOOLTIP_HIDE = 'toolTip/TOOLTIP_HIDE';

export const RECENT_LOCATION_RESPONSE_RECEIVED = 'oneClickBook/RECENT_LOCATION_RESPONSE_RECEIVED';
export const RECENT_LOCATION_ERROR = 'oneClickBook/RECENT_LOCATION_ERROR,';

export const LOCATION_RESPONSE_RECEIVED = 'location/RESPONSE_RECEIVED';
export const LOCATION_SLOTS_RECEIVED = 'location/SLOTS_RECEIVED';
export const LOCATION_EXTERNAL_SLOTS_LOADING = 'location/EXTERNAL_SLOTS_LOADING';
export const LOCATION_EXTERNAL_SLOTS_RECEIVED = 'location/EXTERNAL_SLOTS_RECEIVED';
export const LOCATION_EXTERNAL_SLOTS_ERROR = 'location/EXTERNAL_SLOTS_ERROR';
export const LOCATION_FAILED = 'location/FAILED';
export const LOCATION_SLOTS_FAILED = 'loaction/SLOTS_FAILED';
export const SET_ACTIVE_LOCATION = 'location/SET_ACTIVE';
export const SET_ACTIVE_LOCATION_WITHOUT_SLOTS = 'location/SET_ACTIVE_WITHOUT_SLOTS';

export const GROUP_LOCATIONS_RESPONSE_RECEIVED = 'groupLocations/RESPONSE_RECEIVED';
export const GROUP_LOCATIONS_FAILED = 'groupLocations/FAILED';
export const GROUP_LOCATIONS_CLEAR_DATA = 'groupLocations/CLEAR_DATA';
export const GROUP_LOCATIONS_CLEAR_ERRORS = 'groupLocations/CLEAR_ERRORS';
export const GROUP_LOCATIONS_FETCHING = 'groupLocations/FETCHING';

export const EMAIL_FORM_SUBMIT_SUCCESS = 'outsideServiceArea/EMAIL_FORM_SUBMIT_SUCCESS';
export const EMAIL_FORM_SUBMIT_FAILURE = 'outsideServiceArea/EMAIL_FORM_SUBMIT_FAILURE';
export const EMAIL_FORM_SUBMITTED = 'outsideServiceArea/EMAIL_FORM_SUBMITTED';

export const WAIT_TIME_RECEIVED = 'waitTime/RECEIVED';
export const WAIT_TIME_ERROR = 'waitTime/ERROR';
export const LOADING_WAIT_TIME = 'waitTime/LOADING';
export const SUBMITTING_WAIT_TIME = 'waitTime/SUBMITTING';

export const CLEAR_SUCCESSFUL_BOOKING_DATA = 'newBooking/CLEAR_SUCCESSFUL_BOOKING_DATA';
export const BOOKING_RESPONSE_RECEIVED = 'newBooking/RESPONSE_RECEIVED';
export const BOOKING_REQUEST_SUBMITTED = 'newBooking/REQUEST_SUBMITTED';
export const BOOKING_WAS_SUCCESSFUL = 'newBooking/SUCCESSFUL_BOOKING';
export const BOOKING_REQUEST_FAILED = 'newBooking/REQUEST_FAILED';
export const BOOKING_REQUEST_NOT_SUBMITTED = 'newBooking/REQUEST_NOT_SUBMITTED';
export const BOOKING_CLEAR_ERRORS = 'newBooking/CLEAR_ERRORS';
export const BOOKING_TIME_SELECTED = 'newBooking/TIME_SELECTED';
export const BOOKING_SET_PROVIDER_GROUP_ATTRIBUTES = 'newBooking/SET_PROVIDER_GROUP_ATTRIBUTES';
export const BOOKING_INSURANCE_TYPE = 'newBooking/INSURANCE_TYPE';
export const PATIENT_TYPE_CHANGED = 'newBooking/PATIENT_TYPE_CHANGED';
export const BOOKING_REQUESTED_TIME_CHANGED = 'newBooking/REQUESTED_TIME_CHANGED';
export const BOOKING_REASON_FOR_VISIT_CHANGED = 'newBooking/REASON_CHANGED';
export const BOOKING_ORIGIN_CHANGED = 'newBooking/ORIGIN_CHANGED';
export const BOOKING_RECEIVE_USER_PROFILE = 'newBooking/RECEIVE_USER_PROFILE';
export const BOOKING_SET_LOCATION_ID = 'newBooking/SET_LOCATION_ID';
export const BOOKING_SET_STATE_CODE = 'newBooking/SET_STATE_CODE';
export const BOOKING_SET_BOOKING_CODE = 'newBooking/SET_BOOKING_CODE';
export const BOOKING_SET_IS_EXISTING_PATIENT = 'newBooking/SET_IS_EXISTING_PATIENT';
export const BOOKING_SET_HAS_BOOKED_HERE_BEFORE_WITH_SOLV =
  'newBooking/SET_HAS_BOOKED_HERE_BEFORE_WITH_SOLV';
export const BOOKING_NOTES = 'newBooking/NOTES';
export const BOOKING_SET_LOCALE = 'newBooking/SET_LOCALE';
export const BOOKING_SET_PAYMENT = 'newBooking/BOOKING_SET_PAYMENT';
export const BOOKING_SET_CONSENT = 'newBooking/BOOKING_SET_CONSENT';
export const BOOKING_SET_TOS_CONSENT = 'newBooking/BOOKING_SET_TOS_CONSENT';
export const BOOKING_SET_SMS_CONSENT = 'newBooking/BOOKING_SET_SMS_CONSENT';

export const BOOKING_APPOINTMENT_TYPE = 'newBooking/APPOINTMENT_TYPE';
export const BOOKING_IS_PREMIUM_IN_PERSON = 'newBooking/IS_PREMIUM_IN_PERSON';
export const BOOKING_IS_PREMIUM = 'newBooking/IS_PREMIUM';
export const BOOKING_PAYMENT_OPTIONS = 'Booking payment options';
export const PAYMENT_OPTIONS_SELECT_OPTION = 'Payment options - select option';
export const BOOKING_PROFILE_PENDING = 'newBooking/SET_BOOKING_PROFILE_PENDING';
export const RESET_USER_PROFILE = 'newBooking/RESET_USER_PROFILE';
export const DENTAL_SKIP_COPY_VARIABLE = 'dental-show-skip-copy';

export const SET_PAST_BOOKING = 'pastBookings/SET';
export const CLEAR_PAST_BOOKINGS = 'pastBookings/CLEAR';

export const PROFILE_FORM_SUBMISSION = 'profile/FORM_SUBMISSION';
export const PROFILE_FORM_SUBMITTED = 'profile/FORM_SUBMITTED';

export const INSURANCE_FORM_SUBMISSION = 'insurance/FORM_SUBMISSION';
export const INSURANCE_FORM_SUBMITTED = 'insurance/FORM_SUBMITTED';
export const INSURANCE_FORM_NOT_SUBMITTED = 'insurance/FORM_NOT_SUBMITTED';
export const INSURANCE_CLEAR = 'insurance/clear';
export const INSURANCE_OCR_DATA = 'insurance/OCR_DATA';
export const INSURANCE_AUTOCOMPLETE = 'insurance/AUTOCOMPLETE';
export const INSURANCE_UPLOADING = 'insurance/UPLOADING';
export const SET_INSURANCE_MANUALLY = 'insurance/SET_INSURANCE_MANUALLY';

export const INSURERS_RECEIVED = 'insurers/RECEIVED';
export const INSURERS_ERROR = 'insurers/ERROR';
export const SET_VIEW = 'payment/SET_VIEW';

export const IN_NETWORK = 'yes';
export const OUT_OF_NETWORK = 'no';
export const IN_NETWORK_KEY = 'in_network';
export const OUT_OF_NETWORK_KEY = 'out_of_network';

export const CHARGES_RECEIVED = 'charges/RECEIVED';
export const CHARGES_ERROR = 'charges/ERROR';
export const CHARGES_SUBMITTING = 'charges/SUBMITTING';
export const CHARGES_TOKEN_ERROR = 'charges/TOKEN_ERROR';
export const INVOICES_RECEIVED = 'invoice/RECEIVED';
export const INVOICES_ERROR = 'invoice/ERROR';
export const INVOICES_LIST_RECEIVED = 'invoice/LIST_RECEIVED';
export const INVOICES_LIST_ERROR = 'invoice/LIST_ERROR';
export const INVOICE_SAVE_LOCATION_OR_GROUP_ID = 'invoice/SAVE_LOCATION_OR_GROUP_ID';
export const INVOICE_SET_TRAFFIC_SOURCE = 'invoice/SET_TRAFFIC_SOURCE';

export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_UNPAID = 'pending';
export const INVOICE_STATUS_CANCELLED = 'cancelled';

export const INVOICE_TYPE_WEBSITE = 'website';

export const RECEIVE_INSURANCE_PROFILE = 'insuranceProfiles/RECEIVE_INSURANCE_PROFILE';
export const INSURANCE_PROFILE_ERROR = 'insuranceProfiles/INSURANCE_PROFILE_ERROR';
export const CLEAR_INSURANCE_PROFILE = 'insuranceProfiles/INSURANCE_PROFILE_CLEAR';

export const REMOTE_PHONE_VALIDATION_SUBMITTED = 'login/REMOTE_PHONE_VALIDATION_SUBMITTED';
export const REMOTE_PHONE_VALIDATION_ERROR = 'login/REMOTE_PHONE_VALIDATION_ERROR';
export const GENERATE_OTP_SUCCESS = 'login/GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILURE = 'login/GENERATE_OTP_FAILURE';

export const LOGIN_SWITCH_LOGIN_SIGN_UP = 'login/SWITCH_LOGIN_SIGN_UP';
export const LOGIN_SAVE_SIGN_UP_DATA_STATE = 'login/SAVE_SIGN_UP_DATA_STATE';
export const LOGIN_SUBMITTED = 'login/LOGIN_SUBMITTED';
export const LOGIN_FAILED = 'login/LOGIN_FAILED';
export const LOGIN_ACCOUNT_FROZEN = 'login/ACCOUNT_FROZEN';
export const LOGIN_FAIL_MAX_EXCEEDED = 'login/LOGIN_FAIL_MAX_EXCEEDED';
export const LOGIN_RESET_LOGIN_DATA = 'login/LOGIN_RESET_LOGIN_DATA';
export const LOGIN_SET_AFTER_LOGIN_URL = 'login/SET_AFTER_LOGIN_URL';
export const LOGIN_ACCOUNT_FETCHING = 'login/LOGIN_ACCOUNT_FETCHING';
export const LOGIN_ACCOUNT_RECEIVED = 'login/LOGIN_ACCOUNT_RECEIVED';
export const LOGIN_ACCOUNT_ERROR = 'login/LOGIN_ACCOUNT_ERROR';
export const LOGIN_RESPONSE_RECEIVED = 'login/LOGIN_RESPONSE_RECEIVED';
export const TOKEN_REFRESH_RESPONSE_RECEIVED = 'login/REFRESH_RECEIVED';
export const LOGIN_NO_ACCOUNT = 'login/LOGIN_NO_ACCOUNT';
export const VALIDATE_PHONE_SUCCESS = 'login/VALIDATE_PHONE_SUCCESS';
export const VALIDATE_PHONE_ERROR = 'login/VALIDATE_PHONE_ERROR';
export const LOGIN_SUBMIT_BUTTON_DISABLED = 'login/SUBMIT_BUTTON_DISABLED';
export const LOGIN_ACTION_SIGN_UP = 'sign-up';
export const LOGIN_ACTION_LOGIN = 'login';
export const LOGIN_ACTION_OTP_FORM = 'otp-login';
export const LOGIN_ACTION_SIGN_UP_VERIFY = 'sign-up-verify';

export const NPI_CHECK_RECEIVED = 'npi/RECEIVED';
export const NPI_CHECK_ERROR = 'npi/ERROR';
export const NPI_CHECK_SUBMITTED = 'npi/SUBMITTED';
export const NPI_CLEAR_SUBMISSION = 'npi/CLEAR';

export const ELIGIBILITY_CHECK_RECEIVED = 'eligibilityCheck/RECEIVED';
export const ELIGIBILITY_CHECK_ERROR = 'eligibilityCheck/SUBMITTED';
export const ELIGIBILITY_CHECK_SUBMITTED = 'eligibilityCheck/SUBMITTED';

export const POSITION_SUCCESS = 'position/SUCCESS';
export const POSITION_ERROR = 'position/ERROR';
export const GETTING_POSITION = 'position/GETTING_POSITION';
export const IP_REVERSE_GEOCODE_RESULTS_RECEIVED = 'position/IP_REVERSE_GEOCODE_RESULTS_RECEIVED';
export const IP_REVERSE_GEOCODE_FAILED = 'position/IP_REVERSE_GEOCODE_FAILED';

export const REVIEWS_SUBMITTED = 'reviews/SUBMITTED';
export const REVIEWS_RECEIVED = 'reviews/RECEIVED';
export const REVIEWS_ERROR = 'reviews/ERROR';
export const REVIEWS_SET_ACTIVE_REVIEW = 'reviews/SET_ACTIVE_REVIEW';

export const CLEAR_SEARCH_PREFERENCES = 'search/CLEAR_PREFERENCES';
export const SYMPTOMS_SUBMIT = 'symptoms/SUBMIT';
export const SYMPTOM_CLICKED = 'symptom/SYMPTOM_CLICKED';
export const SYMPTOM_SELECTED = 'symptom/SYMPTOM_SELECTED';
export const SYMPTOM_UPDATED = 'symptom/SYMPTOM_UPDATED';

export const PARSE_URL_PARAMETERS = 'tracking/PARSE_URL_PARAMETERS';
export const ADD_URL_PARAMETERS_FROM_COOKIE_HISTORY =
  'tracking/ADD_URL_PARAMETERS_FROM_COOKIE_HISTORY';

export const GEOLOCATION_SUGGESTED = 'requestSolvEmail/GEOLOCATION_SUGGESTED';
export const REQUEST_SOLV_EMAIL_SUBMIT_SUCCESS = 'requestSolvEmail/EMAIL_FORM_SUBMIT_SUCCESS';
export const REQUEST_SOLV_EMAIL_SUBMIT_FAILURE = 'requestSolvEmail/EMAIL_FORM_SUBMIT_FAILURE';
export const REQUEST_SOLV_EMAIL_SUBMITTED = 'requestSolvEmail/EMAIL_FORM_SUBMITTED';

export const IMAGE_DATA_SET_FRONT = 'imageData/SET_FRONT';
export const IMAGE_DATA_SET_BACK = 'imageData/SET_BACK';
export const IMAGE_DATA_CLEAR = 'imageData/CLEAR';
export const IMAGE_TYPE_FRONT = 'imageType/FRONT';
export const IMAGE_TYPE_BACK = 'imageType/BACK';
export const IMAGE_DATA_UPLOAD_SUCCESS = 'imageData/UPLOAD_SUCCESS';
export const IMAGE_DATA_UPLOAD_FAILED = 'imageData/UPLOAD_FAILED';

export const WAIT_LIST_BOOKING_RECEIVED = 'waitList/BOOKING_RECEIVED';
export const WAIT_LIST_BOOKING_NOT_VISIBLE = 'waitList/BOOKING_NOT_VISIBLE';
export const WAIT_LIST_BOOKING_ERROR = 'waitList/BOOKING_ERROR';
export const WAIT_LIST_RECEIVED = 'waitList/WAIT_LIST_RECEIVED';
export const WAIT_LIST_ERROR = 'waitList/WAIT_LIST_ERROR';
export const CLEAR_WAIT_LIST = 'waitList/CLEAR_WAIT_LIST';
export const INSURANCE_INFO_BY_BOOKING = 'waitList/INSURANCE_INFO_BY_BOOKING';
export const INSURANCE_INFO_BY_BOOKING_ERROR = 'waitList/INSURANCE_INFO_BY_BOOKING_ERROR';
export const USER_PROFILE_BY_BOOKING = 'waitList/USER_PROFILE_BY_BOOKING';
export const USER_PROFILE_BY_BOOKING_ERROR = 'waitList/USER_PROFILE_BY_BOOKING_ERROR';

export const CLICK_404_LINK = 'tracking/USER_CLICK_HOMEPAGE_FROM_404_PAGE';

export const RECEIVE_ACCOUNT = 'account/RECEIVE_ACCOUNT';
export const UPDATE_INSURANCE_PROFILE = 'account/UPDATE_INSURANCE_PROFILE';
export const ACCOUNT_ERROR = 'account/ACCOUNT_ERROR';
export const RECEIVE_ACCOUNT_SUMMARY = 'account/RECEIVE_ACCOUNT_SUMMARY';
export const ACCOUNT_SUMMARY_ERROR = 'account/ACCOUNT_SUMMARY_ERROR';
export const ACCOUNT_SUMMARY_LOADING = 'account/ACCOUNT_SUMMARY_LOADING';
export const ACCOUNT_RECEIVE_USER_PROFILE = 'account/RECEIVE_USER_PROFILE';
export const ACCOUNT_USER_PROFILE_ERROR = 'account/USER_PROFILE_ERROR';
export const ACCOUNT_USER_PROFILE_PROCESSING = 'account/USER_PROFILE_PROCESSING';
export const ACCOUNT_ADD_SAVED_LOCATION = 'account/ADD_SAVED_LOCATION';
export const ACCOUNT_REMOVE_SAVED_LOCATION = 'account/REMOVE_SAVED_LOCATION';
export const ACCOUNT_REPLACE_SAVED_LOCATION = 'account/REPLACE_SAVED_LOCATIONS';
export const ACCOUNT_MARK_MESSAGES_READ = 'account/MARK_MESSAGES_READ';
export const ACCOUNT_TRACK_INTERACTION = 'account/TRACK_INTERACTION';
export const ADD_ACTIVE_ACCOUNT_PROFILE = 'account/ADD_ACTIVE_ACCOUNT_PROFILE';
export const SET_SMS_CONSENT = 'account/SET_SMS_CONSENT';

export const GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS =
  'account/GET_INSURANCE_FRONT_CARD_IMAGE_SUCCESS';
export const GET_INSURANCE_FRONT_CARD_IMAGE_ERROR = 'account/GET_INSURANCE_FRONT_CARD_IMAGE_ERROR';

export const GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS =
  'account/GET_INSURANCE_BACK_CARD_IMAGE_SUCCESS';
export const GET_INSURANCE_BACK_CARD_IMAGE_ERROR = 'account/GET_INSURANCE_BACK_CARD_IMAGE_ERROR';

export const ACCOUNT_RECEIVE_BOOKING = 'account/RECEIVE_BOOKING';
export const ACCOUNT_BOOKING_ERROR = 'account/BOOKING_ERROR';
export const ACCOUNT_CLEAR_SUMMARY = 'account/CLEAR_SUMMARY';
export const ONBOARDING_STEP_COMPLETE = 'onboarding/ONBOARDING_STEP_COMPLETE';
export const RECEIVE_ONBOARDED_STATUS = 'waitList/RECEIVE_ONBOARDED_STATUS';
export const ONBOARDED_STATUS_ERROR = 'waitList/ONBOARDED_STATUS_ERROR';
export const SET_TAB_NUMBER = 'account/SET_TAB_NUMBER';
export const BOOKING_TOKBOX_RATING_SUBMITTED = 'account/BOOKING_TOKBOX_RATING_SUBMITTED';

export const FAVORITE_PROCESSING = 'favorite/PROCESSING';
export const FAVORITE_ERROR = 'favorite/ERROR';
export const FAVORITE_RECEIVED = 'favorite/RECEIVED';
export const FAVORITE_RECEIVE_NEW_CLINIC = 'favorite/RECEIVE_NEW_CLINIC';

export const RECEIVE_FAVORITE_CLINICS = 'receive/FAVORITE_CLINICS';
export const RECEIVE_FAVORITE_CLINICS_ERROR = 'receive/FAVORITE_CLINICS_ERROR';
export const RECEIVE_FAVORITE_CLINICS_SCHEDULE = 'receive/FAVORITE_CLINICS_SCHEDULE';
export const RECEIVE_FAVORITE_CLINICS_SCHEDULE_ERROR = 'receive/FAVORITE_CLINICS_SCHEDULE_ERROR';

export const CLINIC_LEAD_FORM_SUCCESS = 'clinicLead/SUBMIT_SUCCESS';
export const CLINIC_LEAD_FORM_ERROR = 'clinicLead/SUBMIT_ERROR';

export const NEXT_AVAILABLE_SELECTED = 'nextAvailable/TIME_SELECTED';

export const RECEIVE_CALCULATOR_DATA = 'clinicReviewCalculator/RECEIVE_CLINIC_REVIEW_DATA';
export const CALCULATOR_DATA_CLEAR = 'clinicReviewCalculator/CALCULATOR_DATA_CLEAR';
export const CALCULATOR_DATA_ERROR = 'clinicReviewCalculator/CALCULATOR_DATA_ERROR';
export const CALCULATOR_DATA_SUBMITTED = 'clinicReviewCalculator/CALCULATOR_DATA_SUBMITTED';

export const POPULAR_CITIES_RECEIVE_DATA = 'popular_cities/RECEIVE_DATA';
export const POPULAR_CITIES_RECEIVE_DATA_ERROR = 'popular_cities/RECEIVE_DATA_ERROR';
export const POPULAR_CITIES_CLEAR_ERRORS = 'popular_cities/CLEAR_ERRORS';

export const POPULAR_CITIES_NEARBY_RECEIVE_DATA = 'popular_cities_nearby/RECEIVE_DATA';
export const POPULAR_CITIES_NEARBY_RECEIVE_DATA_ERROR = 'popular_cities_nearby/RECEIVE_DATA_ERROR';
export const POPULAR_CITIES_NEARBY_CLEAR_ERRORS = 'popular_cities_nearby/CLEAR_ERRORS';

export const ADD_TO_HOME_SCREEN_ENABLED = 'add_to_home_screen/ENABLED';

// other stuff
export const ENV_DEV = 'development';
export const ENV_STAGE = 'stage';
export const ENV_PROD = 'production';
export const ENV_TEST = 'test';

export const LOCATION_SOURCE_BROWSER = 'BROWSER';
export const LOCATION_SOURCE_USER = 'USER';
export const LOCATION_SOURCE_QUERY_STRING = 'QUERY_STRING';
export const LOCATION_SOURCE_DEFAULT = 'DEFAULT';
export const LOCATION_SOURCE_HARDCODED = 'HARD_CODED';
export const LOCATION_SOURCE_IP_ADDRESS = 'IP_ADDRESS';
export const LOCATION_SOURCE_CDP = 'CDP';

export const SOURCE_BOOKING_WIDGET = 'BOOKING_WIDGET';

export const HOMEPAGE_ANCHOR_SOLV_VIDEO = 'how-solv-works';
export const HOMEPAGE_ANCHOR_DOWNLOAD_SOLV_APP = 'download-solv-app';
export const SOLV_VIDEO_HOW_SOLV_WORKS_URL =
  'https://s3-us-west-2.amazonaws.com/www.solvhealth.com/solv_homepage_video.mp4';
export const CHECKIN_THRESHOLD_DISTANCE = 1; //in miles

export const METERS_PER_MILE = 1609.34;
export const METERS_PER_FOOT = 0.3048;
export const TIME_FORMAT = 'h:MMtt';
export const DATE_FORMAT = 'dddd mmm dS, yyyy';
export const TIME_LAST_MINUTE_OF_THE_DAY = '23:59:00';
export const TIME_LAST_SECOND_OF_THE_DAY = '23:59:59';
export const TIME_MIDNIGHT = '00:00:00';
export const OPEN_24_HOURS = 'Open 24 hours';

export const VOID_LOCATION = 'void_location';

export const INDIVIDUAL_DEDUCTIBLE_INDEX = 0;
export const FAMILY_DEDUCTIBLE_INDEX = 1;

export const TYPE_HMO = 'hmo';
export const TYPE_PPO = 'ppo';
export const TYPE_UNKNOWN = 'unknown';
export const TYPE_SELF = 'self-pay';
export const TYPE_INSURANCE = 'insurance';
export const TYPE_MEDICARE = 'medicare';
export const TYPE_MEDICAID = 'medicaid';
export const TYPE_TRICARE = 'tricare';
export const TYPE_TRIWEST = 'triwest';
export const TYPE_UNITED_HEALTHCARE = 'united_healthcare';
export const TYPE_OTHER = 'other';
export const TYPE_SKIP = 'skip';
export const TYPE_SELF_PAY = 'self_pay';

export const TYPE_SELF_LABEL = 'Self-Pay';

export const TYPE_TRIWEST_LABEL = 'TriWest';

export const TYPE_UNITED_HEALTHCARE_LABEL = 'UnitedHealthcare';
export const TYPE_INSURANCE_LABEL = 'Insurance';
export const TYPE_SKIP_LABEL = 'Decide Later';
export const ASAP_LABEL = 'Next Available';
export const ASAP_VALUE = 'asap';
export const ASAP_APPOINTMENT_CARD = 'ASAP';
export const PICK_TIME_LABEL = 'Pick a time';
export const PICK_TIME_VALUE = 'pick';

export const API_RESULT_STATUS_SUCCESS = 'success';
export const API_RESULT_STATUS_ERROR = 'error';
export const WAIT_LIST_CLEARED = 'cleared';
export const WAIT_LIST_OUTSIDE_TIME_WINDOW = 'outsideTimeWindow';

export const PATIENT_TYPE_KIDS = 'kids';
export const PATIENT_TYPE_ADULTS = 'adults';
export const PATIENT_TYPE_GUARDIAN = 'guardian';

/* WARNING: PATIENT_TYPE_OPTIONS order matters! (Index 0 == ADULT) */
export const PATIENT_TYPE_OPTIONS = [
  { value: PATIENT_TYPE_ADULTS, label: 'Adult' },
  { value: PATIENT_TYPE_KIDS, label: 'Child' },
] as const;

export const COVID_COST_TYPE_ANY_COST = 'any_cost';
export const COVID_COST_TYPE_NO_COST = 'no_cost';

export const COVID_COST_TYPE_OPTIONS = [
  { value: COVID_COST_TYPE_ANY_COST, label: 'Any cost' },
  { value: COVID_COST_TYPE_NO_COST, label: 'No-cost' },
];

export class BookingProfileStatus extends EnumType {
  static EXISTS = 'exists';
  static PENDING = 'pending';
}

export class BookingStatus extends EnumType {
  // pre-visit
  static PENDING = 'pending';

  static RESERVED = 'reserved';

  // in-visit
  static CONFIRMED = 'confirmed';

  static CHECKED_IN = 'checked_in';

  static IN_EXAM_ROOM = 'in_exam_room';

  // post-visit
  static CANCELLED = 'cancelled';

  static DISCHARGED = 'discharged';

  static NO_SHOW = 'no_show';

  static PRE_VISIT = [BookingStatus.PENDING, BookingStatus.RESERVED];

  static IN_VISIT = [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.IN_EXAM_ROOM];

  static isPreVisit = (status: any) => BookingStatus.PRE_VISIT.includes(status);

  static isInVisit = (status: any) => BookingStatus.IN_VISIT.includes(status);
}

export const UPCOMING_BOOKING_STATUSES = BookingStatus.PRE_VISIT.concat(BookingStatus.IN_VISIT);

export const BOOKING_CANCELLATION_REASON_TRANSFERRED = 'transferred';

export const RELATIONSHIP_TYPE_SELF = 'Self';
export const RELATIONSHIP_TYPE_CHILD = 'Child';
export const RELATIONSHIP_TYPE_SPOUSE = 'Spouse';
export const RELATIONSHIP_TYPE_PARENT = 'Parent';
export const RELATIONSHIP_TYPE_SIBLING = 'Sibling';
export const RELATIONSHIP_TYPE_OTHER = 'Other';
export const RELATIONSHIP_ALLOW_UNDERAGE_TYPES = [
  RELATIONSHIP_TYPE_CHILD.toLowerCase(),
  RELATIONSHIP_TYPE_SIBLING.toLowerCase(),
  RELATIONSHIP_TYPE_OTHER.toLowerCase(),
];

export type RelationshipType =
  | typeof RELATIONSHIP_TYPE_SELF
  | typeof RELATIONSHIP_TYPE_CHILD
  | typeof RELATIONSHIP_TYPE_SPOUSE
  | typeof RELATIONSHIP_TYPE_PARENT
  | typeof RELATIONSHIP_TYPE_SIBLING
  | typeof RELATIONSHIP_TYPE_OTHER;

// Birth sex, gender
export const BIRTHSEX_TYPE_FEMALE = 'Female';
export const BIRTHSEX_TYPE_MALE = 'Male';
export const GENDER_TYPE_DECLINE_TO_STATE = 'Decline to state';
export const GENDER_TYPE_FEMALE = 'Female';
export const GENDER_TYPE_MALE = 'Male';
export const GENDER_TYPE_OTHER = 'Other';
export const GENDER_TYPE_TRANSGENDER = 'Transgender';

export const REFRESH_INTERVAL = 2 * 60 * 1000; // two minutes in ms
export const MAX_APPOINTMENT_INTERVAL = 600; // ten minutes minutes in s
export const PROVIDER_GROUP_SLOTS_REFRESH_INTERVAL = 5 * 60 * 1000; // five minutes in ms

// waitlist origins
export const ORIGIN_REACT_MOBILE_APP = 'react_mobile_app';
export const ORIGIN_BOOKING_FORM = 'booking_form';
export const ORIGIN_BOOKING_WIDGET = 'booking_widget';
export const ORIGIN_PARTNER_API = 'partner_api';
export const ORIGIN_ASAP = 'asap';
export const ORIGIN_QUEUE = 'queue';
export const ORIGIN_KIOSK_SIGN_IN = 'kiosk_sign_in';
export const ORIGIN_DRCHRONO_SYNC = 'drchrono_sync';
export const ORIGIN_EXTERNAL_SCHEDULE_SYNC = 'external_schedule_sync';

// check-in types
export const CHECK_IN_TYPE_CONTACTLESS_BR = 'contactless_br';
export const CHECK_IN_TYPE_CONTACTLESS_QR = 'contactless_qr';
export const CHECK_IN_TYPE_CONTACTLESS_SMS = 'contactless_sms';

// insurance flows
export const INSURANCE_FLOW_NOT_LOGGED_AT_BOOKING = 'insurance_flow_not_logged_at_booking';
export const INSURANCE_FLOW_LOGGED_IN_AT_BOOKING = 'insurance_flow_logged_in_at_booking';
export const INSURANCE_FLOW_ON_WAITLIST = 'insurance_flow_on_waitlist';

export const FETCHING_ACCOUNT_APPOINTMENTS = 'account/FETCHING_ACCOUNT_APPOINTMENTS';

// upcoming appointments
export const UPCOMING_APPOINTMENTS_RECEIVED = 'account/UPCOMING_RECEIVED';
export const UPCOMING_NEW_BOOKING = 'account/UPCOMING_NEW_BOOKING';
export const UPCOMING_APPOINTMENTS_ERROR = 'account/UPCOMING_ERROR';
export const UPCOMING_APPOINTMENT_CANCELLED = 'account/UPCOMING_CANCELLED';
export const UPCOMING_APPOINTMENT_RESCHEDULED = 'account/UPCOMING_RESCHEDULED';

//past appointments
export const PAST_APPOINTMENTS_RECEIVED = 'account/PAST_RECEIVED';
export const PAST_APPOINTMENTS_ERROR = 'account/PAST_ERROR';
export const SET_APPOINTMENT_VIEW = 'account/SET_APPOINTMENT_VIEW';

// content
export const RECEIVE_PARENT_CONTENT = 'content/RECEIVE_PARENT_CONTENT';
export const RECEIVE_PARENT_CONTENT_ERROR = 'content/RECEIVE_PARENT_CONTENT_ERROR';
export const CLEAR_CONTENT = 'content/CLEAR_CONTENT';
export const SET_CONTENT_PATHS = 'content/SET_PATHS';
export const SET_SLUG = 'content/SET_SLUG';
export const SET_CHILD_SLUG = 'content/SET_CHILD_SLUG';
export const SET_GRANDPARENT_SLUG = 'content/SET_GRANDPARENT_SLUG';

//mobile check in
export const MOBILE_CHECK_IN_GET_LOCATION_DATA = 'mobileCheckIn/GET_LOCATION_DATA';

// add review
export const ADD_REVIEW_SET_STAR_RATING = 'addReview/SET_STAR_RATING';
export const ADD_REVIEW_SET_RECOMMENDED_VALUE = 'addReview/SET_RECOMMENDED_VALUE';
export const ADD_REVIEW_REVIEW_CHANGE = 'addReview/REVIEW_CHANGE';
export const ADD_REVIEW_SUBMIT_STATUS = 'addReview/SUBMIT_STATUS';
export const ADD_REVIEW_RESPONSE_RECEIVED = 'addReview/RESPONSE_RECEIVED';
export const ADD_REVIEW_ERROR = 'addReview/ERROR';
export const ADD_REVIEW_SET_STATE = 'addReview/SET_STATE';
export const ADD_REVIEW_SET_COPY_STATUS = 'addReview/SET_COPY_STATUS';
export const ADD_REVIEW_SET_GOOGLE_ID = 'addReview/GOOGLE_ID';
export const ADD_REVIEW_FLOW_RECOMMEND_TO_GOOGLE = 'WEB_ASK_RECOMMEND_AND_DIRECT_TO_GOOGLE';
export const NPS_AND_ADD_REVIEW_FLOW_RECOMMEND_TO_GOOGLE =
  'NPS_AND_WEB_ASK_RECOMMEND_AND_DIRECT_TO_GOOGLE';
export const ADD_REVIEW_FROM_DISCHARGE_EMAIL_DIRECT_TO_GOOGLE =
  'ADD_REVIEW_FROM_REVIEW_EMAIL_DIRECT_TO_GOOGLE';
export const ADD_REVIEW_FROM_DISCHARGE_EMAIL_DIRECT_TO_SOLV =
  'ADD_REVIEW_FROM_DISCHARGE_EMAIL_DIRECT_TO_SOLV';
export const ADD_REVIEW_UNGATED_REVIEW_ON_GOOGLE_OR_SOLV = 'UNGATED_REVIEW_ON_GOOGLE_OR_SOLV';
export const ADD_REVIEW_UNGATED = 'UNGATED';
export const REVIEW_FLOW_DISABLED = 'REVIEW_FLOW_DISABLED';
export const ADD_SOLV_REVIEW_WOULD_RECOMMEND = 'swr';
export const ADD_SOLV_REVIEW_WOULD_NOT_RECOMMEND = 'swnr';
export const ADD_REVIEW_DIRECT_TO_SOLV = 'WEB_DIRECT_TO_SOLV';

export const queryParamFlowTypes = [
  ADD_SOLV_REVIEW_WOULD_RECOMMEND,
  ADD_SOLV_REVIEW_WOULD_NOT_RECOMMEND,
] as const;

export type QueryParamFlowType = typeof queryParamFlowTypes[number];

// add insurance
export const SCAN_STARTED_FRONT = 'addInsurance/SCAN_STARTED_FRONT';
export const SCAN_STARTED_BACK = 'addInsurance/SCAN_STARTED_BACK';
export const SCAN_COMPLETED_FRONT = 'addInsurance/SCAN_COMPLETED_FRONT';
export const SCAN_COMPLETED_BACK = 'addInsurance/SCAN_COMPLETED_BACK';
export const SET_SHOW_FORM = 'addInsurance/CLICK_ENTER_MANUAL';
export const SET_SKIP_BACK = 'addInsurance/SET_SKIP_BACK';
export const OCR_DATA = 'addInsurance/OCR_DATA';
export const OCR_DATA_BACK = 'addInsurance/OCR_DATA_BACK';
export const SET_CARD_ID_FRONT = 'addInsurance/CARD_ID_FRONT';
export const SET_CARD_ID_BACK = 'addInsurance/CARD_ID_BACK';
export const ACUANT_POST = 'SAGA/addInsurance/ACUANT_POST';
export const FETCH_INSURERS_LIST = 'SAGA/addInsurance/FETCH_INSURERS_LIST';
export const SUBMIT_DENTAL_INSURANCE_FORM = 'SAGA/addInsurance/SUBMIT_DENTAL_INSURANCE_FORM';
export const SUBMIT_VISION_INSURANCE_FORM = 'addInsurance/SUBMIT_VISION_INSURANCE_FORM';
export const SUBMIT_INSURANCE_FORM = 'SAGA/addInsurance/SUBMIT_INSURANCE_FORM';
export const SET_ADD_INSURANCE_FORM_DATA = 'addInsurance/SET_ADD_INSURANCE_FORM_DATA';
export const CLEAR_ADD_INSURANCE_ORIGIN = 'addInsurance/CLEAR_ADD_INSURANCE_ORIGIN';
export const CLEAR_ADD_INSURANCE_FORM_DATA = 'addInsurance/CLEAR_ADD_INSURANCE_FORM_DATA';
export const CLEAR_CARD_DATA = 'addInsurance/CLEAR_CARD_DATA';
export const TOUCH_SKIP_ALL_TOGETHER = 'addInsurance/TOUCH_SKIP_UPLOAD_INSURANCE';
export const TOUCH_SKIP_BACK = 'addInsurance/TOUCH_SKIP_UPLOAD_BACK_OF_CARD';
export const TOUCH_ENTER_MANUALLY = 'addInsurance/TOUCH_ENTER_INSURANCE_MANUALLY';
export const SHOW_TAKING_TOO_LONG = 'addInsurance/SHOW_TAKING_TOO_LONG_ENTER_MANUALLY';
export const ENTER_MANUALLY_FROM_TIMEOUT = 'addInsurance/TOUCH_ENTER_MANUALLY_FROM_TIMEOUT';
export const TOUCH_FRONT = 'addInsurance/TOUCH_UPLOAD_CARD_FRONT';
export const TOUCH_BACK = 'addInsurance/TOUCH_UPLOAD_CARD_BACK';
export const TOUCH_ADD_INSURANCE_APP_BOOKING = 'addInsurance/TOUCH_ADD_INSURANCE_APP_BOOKING';
export const TOUCH_ADD_INSURANCE_MARKETPLACE_APP_BOOKING =
  'addInsurance/TOUCH_ADD_INSURANCE_MARKETPLACE_APP_BOOKING';

export const DO_YOU_HAVE_GOOGLE_ACCOUNT = 'doYouHaveGoogleAccount';
export const ADD_REVIEW_TO_SOLV = 'addReviewToSolv';

export const ADD_REVIEW_INITIAL = 'initial';
export const ADD_REVIEW_THANK_YOU = 'thanks';
export const ADD_REVIEW_COPY_PASTE = 'copyPasta';
export const ADD_REVIEW_STRAIGHT_TO_GOOGLE = 'straightToGoogle';

export const UBER_RIDE_REQUESTED = 'requested';
export const PUSH_OPT_IN_ORIGIN_HEALTH_CHECKLIST = 'health-checklist';
export const PUSH_OPT_IN_ORIGIN_SETTINGS = 'settings';
export const PUSH_OPT_IN_ORIGIN_INSURANCE = 'insurance';
export const PUSH_OPT_IN_ORIGIN_CHAT = 'chat';
export const PUSH_OPT_IN_ORIGIN_AFTER_BOOKING = 'after-booking';
export const PUSH_OPT_IN_ORIGIN_ADDED_INSURANCE = 'insurance';

// add insurance origins
export const SOURCE_PEOPLE = 'people';

// Maps 2-letter state abbreviation code to display label for the full name of the state.
//
// Abbreviation codes are for a U.S. mailing or shipping address and assumes U.S. Web Design System
//  as the source of truth.
//  https://designsystem.digital.gov/components/form-templates/#address-form
export const STATE_CODE_TO_LABEL = {
  al: 'Alabama',
  ak: 'Alaska',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  dc: 'District of Columbia',
  de: 'Delaware',
  fl: 'Florida',
  ga: 'Georgia',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pa: 'Pennsylvania',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming',
  aa: 'AA - Armed Forces Americas',
  ae: 'AE - Armed Forces Africa, Canada, Europe, and Middle East',
  ap: 'AP - Armed Forces Pacific',
};

export const ARMED_FORCES_STATES = ['aa', 'ae', 'ap'];

export const STATE_CODES = Object.keys(STATE_CODE_TO_LABEL).sort();

export type WeekDay =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export const ORDERED_WEEK_DAYS: WeekDay[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export type WeekDayAbbr = 'Mon' | 'Tues' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export const ORDERED_WEEK_DAYS_ABBR: WeekDayAbbr[] = [
  'Mon',
  'Tues',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];

export const ORDERED_WEEK_DAYS_ABBR_MAP_LONG_NAME: Record<WeekDayAbbr, WeekDay> = {
  Mon: 'Monday',
  Tues: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export const ORDERED_WEEK_DAYS_LONG_NAME_MAP_ABBR: Record<WeekDay, WeekDayAbbr> = {
  Monday: 'Mon',
  Tuesday: 'Tues',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

export const TIMEZONE_ABBR_MAP_LONG_NAME = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
};

export const WAIT_LIST_ORIGIN_SOLV = 'Solv';
export const WAIT_LIST_ORIGIN_WEBSITE = 'Website';
export const WAIT_LIST_ORIGIN_WALKIN = 'Walk-in';
export const WAIT_LIST_ORIGIN_CALL_AHEAD = 'Book ahead';

export const INSURANCE_FLOW_STEP_BEGINNING = 'BEGINNING';
export const INSURANCE_FLOW_STEP_SHOW_FORM = 'SHOW_INSURANCE_FORM';
export const INSURANCE_FLOW_STEP_ADD_CARD_BACK = 'ADD_BACK_CARD';

export const AUTHOR_TYPE_ACCOUNT = 'account';

export const PACKAGE_NAME_SOLV_EXPRESS = 'solv_express';

export const PLATFORM_TYPE_TRADITIONAL = 'traditional';
export const PLATFORM_TYPE_URGENT_CARE = 'urgent_care';
export const PLATFORM_TYPE_PROVIDER_GROUP = 'provider_group';

export const CONFIRMATION_PAGE = 'confirmation_page';
export const CONSENT_FORM_COMPLETED = 'COMPLETE';

export const TELEMED_IN_PROGRESS = 'in_progress';

export const TRACKING_PAYMENT_VIEW_INVOICE = 'VIEW_INVOICE';

export const TRACKING_CLICK_LINK_FOLLOW_UP_APPOINTMENT_TEXT =
  'CLICK_LINK_FOLLOW_UP_APPOINTMENT_TEXT';

export const TRACKING_CLICK_TILE_IN_THE_ACCOUNT = 'CLICK_TILE_IN_THE_ACCOUNT';

export const TRANSACTIONAL = 'transactional';
export const WRAPUP = 'wrapup';

export const POKITDOK_CODE_URGENT_CARE = 'UC';
export const POKITDOK_CODE_EMERGENCY_SERVICES = '86';
export const POKITDOK_CODE_PRIMARY_CARE = '98';

export const BIRTH_SEX_MALE = 'Male';
export const BIRTH_SEX_FEMALE = 'Female';
export const BIRTH_SEX_OTHER = 'Other';

export class UploadStatus {
  static COMPLETED = 'completed';

  static ERROR = 'error';

  static PROCESSING = 'processing';
}

export const SET_BIRTH_SEX = 'saga/SET_USER_PROFILE_BIRTH_SEX';

export class AccountFileType {
  static PVS_PHOTO = 'pvs_photo';
}

export const FETCH_LOCATION = 'saga/FETCH_LOCATION';
export const FETCH_LOCATION_SET_LOCATIONS = 'saga/FETCH_LOCATION_SET_LOCATIONS';
export const SET_LOCATION_TO_EMPTY = 'saga/SET_LOCATION_TO_EMPTY';
export const FETCH_APPOINTMENTS = 'saga/FETCH_APPOINTMENTS';
export const FETCH_GET_CARE_LOCATIONS = 'saga/FETCH_GET_CARE_LOCATIONS';
export const FETCH_FAVORITE_LOCATIONS = 'saga/FETCH_FAVORITE_LOCATIONS';
export const FETCH_NEARBY_LOCATIONS = 'saga/FETCH_NEARBY_LOCATIONS';

export const UPLOAD_PROFILE_PHOTO = 'saga/UPLOAD_PROFILE_PHOTO';
export const UPLOAD_ACCOUNT_FILE = 'saga/UPLOAD_ACCOUNT_FILE';
export const SEARCH_PHARMACIES = 'saga/SEARCH_PHARMACIES';
export const CLEAR_PHARMACIES = 'saga/CLEAR_PHARMACIES';
export const FETCH_PHARMACY = 'saga/FETCH_PHARMACY';
export const CREATE_VERIFIED_USER_PROFILE = 'sagas/CREATE_VERIFIED_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'sagas/UPDATE_USER_PROFILE';
export const GET_SPECIALTY_PREFERENCES = 'sagas/GET_SPECIALTY_PREFERENCES';
export const CREATE_SPECIALTY_PREFERENCES = 'sagas/CREATE_SPECIALTY_PREFERENCES';
export const GET_APPOINTMENT_PREFERENCES = 'sagas/GET_APPOINTMENT_PREFERENCES';
export const CREATE_APPOINTMENT_PREFERENCES = 'sagas/CREATE_APPOINTMENT_PREFERENCES';
export const RECEIVE_USER_PROFILE_SPECIALTY_PREFERENCES =
  'sagas/RECEIVE_USER_PROFILE_SPECIALTY_PREFERENCES';
export const RECEIVE_USER_PROFILE_APPOINTMENT_PREFERENCES =
  'sagas/RECEIVE_USER_PROFILE_APPOINTMENT_PREFERENCES';
export const USER_PROFILE_SPECIALTY_PREFERENCES_ERROR =
  'sagas/USER_PROFILE_SPECIALTY_PREFERENCES_ERROR';
export const USER_PROFILE_APPOINTMENT_PREFERENCES_ERROR =
  'sagas/USER_PROFILE_APPOINTMENT_PREFERENCES_ERROR';
export const FETCH_USER_PROFILE_SPECIALTY_PREFERENCES =
  'sagas/FETCH_USER_PROFILE_SPECIALTY_PREFERENCES';
export const FETCH_USER_PROFILE_APPOINTMENT_PREFERENCES =
  'sagas/FETCH_USER_PROFILE_APPOINTMENT_PREFERENCES';
export const UPPERCASE_TIME_FORMAT = 'h:mm A'; // ex: 11:15 AM

export const REVIEW_CTA_COPY = 'Write a review';

export const CLOSED = 'closed';

// Site menu paths
export const ABOUT_US_PATH = '/about-us';
export const ACCOUNT_HOME_PATH = '/account/home';
export const ACCOUNT_SETTINGS_PATH = '/account/settings';
export const BLOG_PATH = '/blog'; //consumer blog
export const COVID_PATH = '/covid19';
export const CAREERS_PATH = '/careers';
export const FAQ_PATH = '/faq';
export const FIND_PATH = '/search';
export const PRESS_PATH = '/press';
export const SAVE_SOLV_PATH = '/save-solv';

//for-providers paths
// alpha-ordered but added 'for-providers' on some to reduce confusion with consumer paths:
export const FOR_PROVIDERS_PATH = '/for-providers';
export const FOR_PROVIDERS_BLOG_PATH = '/for-providers/blog';
export const CASE_STUDIES_PATH = '/for-providers/blog/case-studies';
export const CLAIM_PATH = '/for-providers/claim/form';
export const CONTACTLESS_DEMO_PATH = '/for-providers/demo/contactless-demo';
export const FOR_PROVIDERS_COVID_TESTING_VACCINE_PATH = '/for-providers/covid-testing-vaccine';
export const EBOOKS_PATH = '/for-providers/ebooks';
export const INTEGRATIONS_PATH = '/for-providers/integrations';
export const PATIENT_BOOST_PATH = '/for-providers/patient-boost';
export const FOR_PROVIDERS_PRICING_PATH = '/for-providers/pricing';
export const REQUEST_DEMO_PATH = '/for-providers/demo/request-demo';
export const URGENT_CARE_MARKETING_PATH =
  '/for-providers/blog/5-marketing-wins-for-your-urgent-care-center';
export const VIDEO_TELEMEDICINE_PATH = '/for-providers/video-telemedicine';
export const WEBINARS_PATH = '/for-providers/webinars';

//health-a-z paths
export const HEALTH_AZ_PATH = '/health-a-z';
export const HEALTH_AZ_SYMPTOM_PATHS = {
  sprain: `${HEALTH_AZ_PATH}/sprain`,
  fractures: `${HEALTH_AZ_PATH}/fractures`,
  burns: `${HEALTH_AZ_PATH}/burns`,
  bites: `${HEALTH_AZ_PATH}/bug-bites`,
  cold: `${HEALTH_AZ_PATH}/cold`,
  flu: `${HEALTH_AZ_PATH}/flu`,
  abdominalPain: `${HEALTH_AZ_PATH}/abdominal-pain`,
  nausea: `${HEALTH_AZ_PATH}/nausea`,
  vomiting: `${HEALTH_AZ_PATH}/vomiting`,
  annualPhysicals: `${HEALTH_AZ_PATH}/annual-physical`,
  immunizations: `${HEALTH_AZ_PATH}/vaccinations`,
  fluShots: `${HEALTH_AZ_PATH}/flu-shot`,
};

//general, legal, tos paths
export const BRAND_PATH = '/company';
export const HOME_PATH = '/';
export const PRIVACY_URL = '/privacy';
export const LEGAL_TOS_URL = '/legal/tos';
export const LEGAL_HIPAA_URL = '/legal/hipaaconsent';
export const RESPONSIBLE_DISCLOSURE_POLICY = '/responsible-disclosure-policy';
export const PRIVACY_DATA_REQUEST_FORM_URL =
  'https://submit-irm.trustarc.com/services/validation/1a6d1c86-4652-47d1-8e53-eaa7693e839b';

// consumer-side search paths
export const LAB_TESTS_PATH = '/lab-tests';
export const ACCREDITED_URGENT_CARE_PATH = '/accredited-urgent-care-centers';
export const CITY_GUIDE_PATH = '/city-guides';
export const COVID_TESTING_PATH = '/covid-testing';
export const COVID_VACCINE_PATH = '/covid-vaccine';
export const PEDIATRIC_PATH = '/pediatric-urgent-care';
export const PEDIATRIC_NEAR_ME_PATH = '/pediatric-urgent-care';
export const URGENT_CARE_PATH = '/urgent-care';
export const VA_URGENT_CARE_NEAR_ME_PATH = '/va-urgent-care';
export const WALK_IN_CLINIC_NEAR_ME_PATH = '/walk-in-clinic';
export const WALK_IN_CLINIC_PATH = '/walk-in-clinic';
export const CLEAR_PRICE_PATH = '/clearprice';
export const FIND_CARE_PATH = '/find-care';
// END OF Site Menu Paths

export const SOLV_AUTHOR_NAME = 'Solv';

export const CONSUMER_APP = 'CONSUMER APP';
export const BOOKING_WIDGET = 'BOOKING WIDGET';

export const MARKETPLACE_DFW = 'DFW';

export const CREATE_PROFILE = 'createProfile';
export const UPDATE_PROFILE = 'updateProfile';

export const PAPERWORK_TEXT_UPON_RESERVED = 'pr';
export const PAPERWORK_TEXT_UPON_CONFIRMED = 'pc';
export const PAPERWORK_CTA_CONFIRMATION_EMAIL = 'bcp';

export class PaperworkTypes {
  static REGISTRATION = 'registration';

  static KIOSK = 'kiosk';
}

export class AccountOrigins {
  static CONSUMER_SELF = 'consumer_self';
}

export class ContactTypes {
  static EMERGENCY = 'emergency';

  static GUARDIAN = 'guardian';
}

export const INSURANCE_TYPES = {
  medical: 'medical',
  dental: 'dental',
  vision: 'vision',
};

export const DIRECT_TELEMED_VERSION = '2.1.8';

export const APP_INTERACTION_TYPES = { insuranceTab: 'insurance tab' };

export const APP_INTERACTION_ACTIONS = { view: 'view' };

export const NO_AVAILABILITY = 'no-availability';

export const IMAGE_UPLOAD_STATUSES = {
  uploadFailed: { upload_status: 'upload failed' },
  uploaded: { upload_status: 'uploaded' },
  uploadFailedCompressed: { upload_status_compressed: 'upload failed' },
  uploadedCompressed: { upload_status_compressed: 'uploaded' },
};

// Family Booking - Booking widget
export const MAX_FAMILY_BOOKING_PATIENTS = 4;
export const ACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING =
  'bookingWidgetFamilyBooking/ACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING'; // pragma: allowlist secret
export const DEACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING =
  'bookingWidgetFamilyBooking/DEACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING'; // pragma: allowlist secret
export const SELECT_FAMILY_BOOKING_TOTAL_PATIENTS =
  'bookingWidgetFamilyBooking/SELECT_FAMILY_BOOKING_TOTAL_PATIENTS'; // pragma: allowlist secret

/** Used to indicate whether the H2 2022 Booking Flow is creating a new profile or using an existing one */
export const CREATING_NEW_PROFILE = 'bookingFlow/CREATING_NEW_PROFILE';
/** Used to store the relationship of a new profile in H2 2022 Booking Flow */
export const NEW_PROFILE_RELATIONSHIP = 'bookingFlow/NEW_PROFILE_RELATIONSHIP';
/** Used to store the profile data (i.e. first name, last name, dob) of a new profile in H2 2022 Booking Flow */
export const SUBMIT_PROFILE_DATA = 'bookingFlow/SUBMIT_PROFILE_DATA';
/** Used to indicate whether login was skipped in the h2 2022 Booking Flow */
export const SKIPPED_LOGIN_NUMBER = 'bookingFlow/SKIPPED_LOGIN_NUMBER'; // pragma: allowlist secret
/** Used to select the payment method in the h2 2022 Booking Flow */
export const SELECT_PAYMENT_METHOD = 'bookingFlow/SELECT_PAYMENT_METHOD'; // pragma: allowlist secret

export const TOS_TERMS_PRIVACY_ERROR_MESSAGE =
  'You must accept our Privacy Policy and Terms of Service to continue.';
export const TOS_TERMS_ERROR_MESSAGE = 'You must accept our Terms to continue.';
