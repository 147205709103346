import React, { useState } from 'react';
import { Box, Button } from '@solvhealth/jigsaw';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { colors } from '~/constants/colors';
import InputGroup from './InputGroup';
import { EMPTY_ARRAY } from '~/core/util/array';

const StyledButton = styled(Button)`
  padding: 0px !important;
`;

interface HorizontalLineProps {
  color?: string;
  height?: number;
  border?: string;
}

const StyledHorizontalLine = styled.hr<HorizontalLineProps>`
  border: ${(props: HorizontalLineProps) => props.border ?? 'none'};
  height: ${(props: HorizontalLineProps) => (props.height ? `${props.height}px` : '1px')};
  background-color: ${(props: HorizontalLineProps) => props.color ?? colors.borderLine};
`;

type InputGroupListProps = {
  fieldLabels: string[];
  onChange: (value: InputGroupValues[]) => void;
  error: boolean;
  value?: InputGroupValues[];
  maxListLength: number;
  buttonLabel: string;
};

export interface InputGroupValues {
  [label: string]: string;
}

const InputGroupList = (props: InputGroupListProps): React.ReactElement => {
  const { onChange, value = EMPTY_ARRAY, fieldLabels, buttonLabel, maxListLength } = props;

  const getNewInputGroup = () =>
    fieldLabels.reduce((acc: InputGroupValues, label: string) => ({ ...acc, [label]: '' }), {});

  const [inputGroups, setInputGroups] = useState<InputGroupValues[]>(
    isEmpty(value) ? [{ ...getNewInputGroup() }] : value
  );

  const areAllInputsEmpty = (inputGroups: InputGroupValues[]) =>
    inputGroups.every((inputGroup) => Object.values(inputGroup).every(isEmpty));

  const addInputGroup = (): void => {
    let newInputGroup = getNewInputGroup();

    let updatedItemsList = [...inputGroups];
    updatedItemsList.push(newInputGroup);
    setInputGroups(updatedItemsList);
  };

  const updateFormFieldChange = (item: InputGroupValues, index: number): void => {
    let updatedInputGroups = [...inputGroups];
    updatedInputGroups[index] = item;
    setInputGroups(updatedInputGroups);

    if (areAllInputsEmpty(updatedInputGroups)) updatedInputGroups = EMPTY_ARRAY;
    onChange(updatedInputGroups);
  };

  return (
    <Box>
      {inputGroups.map(
        (item: InputGroupValues, index: number): React.ReactElement => (
          <Box key={index}>
            <InputGroup handleFormChange={updateFormFieldChange} index={index} item={item} />
            {inputGroups.length > 1 && index !== inputGroups.length - 1 && (
              <Box my={5}>
                <StyledHorizontalLine color={colors.borderLine} />
              </Box>
            )}
          </Box>
        )
      )}
      {inputGroups.length < maxListLength && (
        <StyledButton onClick={addInputGroup} type="button" variant="text">
          {buttonLabel}
        </StyledButton>
      )}
    </Box>
  );
};

export default InputGroupList;
