import cookieSignature from 'cookie-signature';
import { Request, Response } from 'express';
import { z } from 'zod';
import logger from '../logger';
import { IS_COOKIE_HTTPS_ONLY, LOGIN_INFO_COOKIE_NAME } from '../../config';

export const UNIVERSAL_SESSION_COOKIE_NAME = '__session';

export function secondsUntil(date: Date) {
  return Math.floor((date.getTime() - Date.now()) / 1000);
}

const universalSessionCookieSchema = z.object({
  tokenType: z.string(),
  authToken: z.string(),
  accountId: z.string(),
  persisted: z.boolean(),
  expiresAt: z.number().transform((val) => new Date(val)),
});

export type UniversalSessionCookie = z.infer<typeof universalSessionCookieSchema>;

// When we set a cookie from the client, we know max-age instead of its expiration time.
export type CreateUniversalSessionCookiePayload = Omit<UniversalSessionCookie, 'expiresAt'> & {
  expiresAt: number;
};

/**
 * Get the shared session secret
 *
 * @throws if the secret is not set
 * @returns the secret key
 */
export function getSharedSessionSecret() {
  const secret = process.env.SHARED_SESSION_SECRET;

  const envType = typeof secret; // splitting this on two lines so the secret checker doesn't complain
  if (envType !== 'string') {
    throw new Error('SHARED_SESSION_SECRET is not set');
  }

  return secret as string;
}

/**
 * Persists the login information and auth token to a secure HttpOnly session shared
 * amongst all solv frontend projects.
 * @param session The session data to store
 * @returns A promise that resolves when the session is stored
 */
export async function persistUniversalAuthSession(session: CreateUniversalSessionCookiePayload) {
  return await fetch('/auth/store-login', {
    method: 'POST',
    body: JSON.stringify(session),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}

/**
 * Sends a fetch request to delete the shared HttpOnly session.
 * @returns A promise that resolves when the session is deleted
 */
export async function deleteUniversalAuthSession() {
  return await fetch('/auth/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}

/**
 * Serialize and sign the session data into a cookie string with the universal
 * cookie secret.
 *
 * @param payload The cookie data to serialize and sign into a cookie string
 * @returns The signed cookie string to be set
 */
export function buildAndSignSharedSessionCookie(payload: UniversalSessionCookie) {
  const newSession: Omit<UniversalSessionCookie, 'expiresAt'> & { expiresAt: number } = {
    authToken: payload.authToken,
    tokenType: payload.tokenType,
    accountId: payload.accountId,
    persisted: payload.persisted,
    expiresAt: payload.expiresAt.getTime(),
  };

  const jsonString = JSON.stringify(newSession);
  const encoded = Buffer.from(jsonString).toString('base64');
  const signed = cookieSignature.sign(encoded, getSharedSessionSecret());

  return signed;
}

/**
 * Verify the signature of, parse, and validate the fields of the shared
 * session cookie.
 *
 * @param sessionValue The value of the shared session cookie.
 * @returns The strongly typed object of the cookie data.
 */
export function validateAndDecodeSharedSessionCookie(sessionValue: string) {
  try {
    if (!sessionValue) return null;
    const unSigned = cookieSignature.unsign(sessionValue, getSharedSessionSecret());

    // If the signature is not valid, then we consider the cookie to not be set.
    if (!unSigned) return null;

    const rawString = Buffer.from(unSigned, 'base64').toString('utf-8');
    const sharedSessionObject = JSON.parse(rawString);

    return universalSessionCookieSchema.parse(sharedSessionObject);
  } catch (e: any) {
    logger.error(e);
    return null;
  }
}

export function unifyClientSideAndUniversalAuthCookies(req: any, res: Response) {
  try {
    const universalSessionRaw = req.universalCookies.cookies[UNIVERSAL_SESSION_COOKIE_NAME];
    const clientSessionRaw = req.universalCookies.cookies[LOGIN_INFO_COOKIE_NAME];

    const clientSession = clientSessionRaw ? JSON.parse(clientSessionRaw) : undefined;
    const universalSession = validateAndDecodeSharedSessionCookie(universalSessionRaw);

    if (universalSession) {
      req.universalCookies.set(
        LOGIN_INFO_COOKIE_NAME,
        {
          tokenType: 'bearer',
          authToken: universalSession.authToken,
          id: universalSession.accountId,
          persisted: universalSession.persisted,
          expiresAt: universalSession.expiresAt.getTime(),
        },
        {
          secure: IS_COOKIE_HTTPS_ONLY,
          httpOnly: false,
          sameSite: 'lax',
          maxAge: secondsUntil(universalSession.expiresAt),
        }
      );
    } else if (clientSession) {
      // If the cookie doesn't have the `expiresAt` flag, then just set it to one hour from now
      // (One hour is already the default)

      let expirationDate;

      if (clientSession.expiresAt) {
        // All new sessions will have this value
        expirationDate = new Date(clientSession.expiresAt);
      } else if (clientSession.persisted) {
        // Old sessions with persistence will expire at midnight
        let midnight = new Date();
        midnight.setHours(24);
        expirationDate = midnight;
      } else {
        // Old sessions without persistence will expire at the end of the session
        let now = new Date();
        expirationDate = now;
      }

      const newSession = buildAndSignSharedSessionCookie({
        accountId: clientSession.id,
        authToken: clientSession.authToken,
        persisted: clientSession.persisted,
        expiresAt: expirationDate,
        tokenType: clientSession.tokenType,
      });

      req.universalCookies.set(UNIVERSAL_SESSION_COOKIE_NAME, newSession, {
        secure: IS_COOKIE_HTTPS_ONLY,
        httpOnly: true,
        path: '/',
        sameSite: 'lax',
        maxAge: secondsUntil(expirationDate),
      });
    }
  } catch (e) {
    console.error('Failed to unify client side and universal auth cookies', e);
  }
}
