import { connect } from 'react-redux';
import { setUserProfileForBooking } from '~/components/LoginController/util';
import { UserProfile } from '~/core/dapi/models';
import { selectActiveUserProfile } from '~/hooks/useSelectActiveUserProfile';
import { AccountSummary } from '~/reducers/account';
import { resetLoginData, setLoginAction } from '../../actions/login';
import { hideModal } from '../../actions/runtime';
import { LOGIN_ACTION_LOGIN } from '../../constants/index';
import { SUBMIT_ACCOUNT } from '../../sagas/signUp';
import { LOGIN_SIGN_UP_MODAL } from '../LoginSignUpModal';
import SignUpForm from './index';

const mapStateToProps = (state: any) => ({
  accountSummary: state.account.summary || {},
  verificationStatus: state.runtime.phoneVerificationStatus,
  activeProfile: selectActiveUserProfile(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  submitAccount: (form: any, isAuthorized: any, accountId: any) =>
    dispatch({ type: SUBMIT_ACCOUNT, form, isAuthorized, accountId }),

  loginButtonClick: () => {
    dispatch(resetLoginData());
    dispatch(setLoginAction(LOGIN_ACTION_LOGIN));
  },

  // Let us set the profile for the booking state ASAP
  setUserProfile: (profile?: UserProfile, accountSummary?: AccountSummary) => {
    setUserProfileForBooking(dispatch, profile, accountSummary);
  },

  hideSignupModal: () => dispatch(hideModal(LOGIN_SIGN_UP_MODAL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
