import uniqBy from 'lodash/uniqBy';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import { Action, Reducer } from 'redux';
import { GenericObj } from '@solvhealth/types/interfaces/generics';
import type PaperworkField from '@solvhealth/types/interfaces/PaperworkField';
import ConsentForm from '@solvhealth/types/interfaces/ConsentForm';
import { AddressValidationLog } from '~/components/Paperwork/components/Patient/AddressAutocomplete';

const PAPERWORK_INCREMENT_PROGRESS_BAR = 'paperwork/INCREMENT_PROGRESS_BAR';
const PAPERWORK_SET_PAGE_VIEW = 'paperwork/SET_PAGE_VIEW';
const PAPERWORK_TOGGLE_FINALIZE_EDIT_MODE = 'paperwork/TOGGLE_FINALIZE_EDIT_MODE';
const PAPERWORK_SEND_FORM_DATA_TO_STATE = 'paperwork/SEND_FORM_DATA_TO_STATE';
const PAPERWORK_INITIALIZING_DATA = 'paperwork/INITIALIZING_DATA';
const PAPERWORK_SUBMITTING_PAGE = 'paperwork/SUBMITTING_PAGE';
const PAPERWORK_LOCATION_PAPERWORK_FIELDS_RECEIVED = 'paperwork/LOCATION_PAPERWORK_FIELDS_RECEIVED';
const PAPERWORK_LOCATION_PAPERWORK_FIELDS_ERROR = 'paperwork/LOCATION_PAPERWORK_FIELDS_ERROR';
const PAPERWORK_CONSENT_FORM_SIGNATURES_ERROR = 'paperwork/CONSENT_FORM_SIGNATURES_ERROR';
const PAPERWORK_CONSENT_FORM_SIGNATURES_SUCCESS = 'paperwork/CONSENT_FORM_SIGNATURES_SUCCESS';
const PAPERWORK_LOCATION_CONSENT_FORMS_ERROR = 'paperwork/LOCATION_CONSENT_FORMS_ERROR';
const PAPERWORK_LOCATION_CONSENT_FORMS_RECEIVED = 'paperwork/LOCATION_CONSENT_FORMS_RECEIVED';
const PAPERWORK_RESET_STATE = 'paperwork/RESET_STATE';
const PAPERWORK_SUBMITTING_STATUS_LOG = 'paperwork/SUBMITTING_STATUS_LOG';
const PAPERWORK_COMING_FROM_PASS = 'paperwork/COMING_FROM_PASS';
const PAPERWORK_ADD_ADDRESS_VALIDATION_LOG = 'paperwork/PAPERWORK_ADD_ADDRESS_VALIDATION_LOG';
const PAPERWORK_RESET_ADDRESS_VALIDATION_LOGS = 'paperwork/PAPERWORK_RESET_ADDRESS_VALIDATION_LOGS';

const incrementProgressBar = (value: any) => ({
  type: PAPERWORK_INCREMENT_PROGRESS_BAR,
  payload: { value },
});

const setPageView = (value: any) => ({
  type: PAPERWORK_SET_PAGE_VIEW,
  payload: { value },
});

const toggleFinalizeEditMode = (value: any) => ({
  type: PAPERWORK_TOGGLE_FINALIZE_EDIT_MODE,
  payload: { value },
});

const sendDataToState = (value: any) => ({
  type: PAPERWORK_SEND_FORM_DATA_TO_STATE,
  payload: { value },
});

const addAddressValidationLog = (value: AddressValidationLog) => ({
  type: PAPERWORK_ADD_ADDRESS_VALIDATION_LOG,
  payload: { value },
});

const resetAddressValidationLogs = () => ({
  type: PAPERWORK_RESET_ADDRESS_VALIDATION_LOGS,
});

const initializingData = () => ({
  type: PAPERWORK_INITIALIZING_DATA,
});

const isSubmittingPaperworkPage = (value: any) => ({
  type: PAPERWORK_SUBMITTING_PAGE,
  payload: { value },
});

const isSubmittingPaperworkStatusLog = (value: any) => ({
  type: PAPERWORK_SUBMITTING_STATUS_LOG,
  payload: { value },
});

const locationPaperworkFieldsReceived = (value: any) => ({
  type: PAPERWORK_LOCATION_PAPERWORK_FIELDS_RECEIVED,
  payload: { value },
});

const locationPaperworkFieldsError = (value: any) => ({
  type: PAPERWORK_LOCATION_PAPERWORK_FIELDS_ERROR,
  payload: { value },
});

const locationConsentFormsReceived = (value: any) => ({
  type: PAPERWORK_LOCATION_CONSENT_FORMS_RECEIVED,
  payload: { value },
});

const locationConsentFormsError = (value: any) => ({
  type: PAPERWORK_LOCATION_CONSENT_FORMS_ERROR,
  payload: { value },
});

const consentFormSignaturesSuccess = () => ({
  type: PAPERWORK_CONSENT_FORM_SIGNATURES_SUCCESS,
});

const consentFormSignaturesError = () => ({
  type: PAPERWORK_CONSENT_FORM_SIGNATURES_ERROR,
});

const resetPaperworkState = () => ({ type: PAPERWORK_RESET_STATE });

const setIsComingFromPass = (value: any) => ({
  type: PAPERWORK_COMING_FROM_PASS,
  payload: { value },
});

export const getInitialState = (): PaperworkState => ({
  pageView: 0,
  formData: {},
  isInFinalizeEditMode: false,
  percentCompleted: 0,
  isSubmitting: false,
});

export type ConsentForms = {
  forms?: ConsentForm[];
  error?: any;
};

export type PaperworkState = {
  consentForms?: ConsentForms;
  customFields?: GenericObj[];
  error?: any;
  formData: GenericObj;
  initializing?: boolean;
  isComingFromPass?: boolean;
  isInFinalizeEditMode: boolean;
  isSubmitting: boolean;
  pageView: number;
  percentCompleted: number;
  signatures?: GenericObj;
  standardFields?: PaperworkField[];
  addressValidationLogs?: GenericObj[];
};

const reducer: Reducer<PaperworkState> = (
  state = getInitialState(),
  action: any
): PaperworkState => {
  switch (action.type) {
    case PAPERWORK_INITIALIZING_DATA:
      return {
        ...state,
        initializing: true,
      };
    case PAPERWORK_SUBMITTING_PAGE:
      return {
        ...state,
        isSubmitting: action.payload.value,
      };
    case PAPERWORK_INCREMENT_PROGRESS_BAR:
      return {
        ...state,
        percentCompleted: action.payload.value,
      };
    case PAPERWORK_TOGGLE_FINALIZE_EDIT_MODE:
      return {
        ...state,
        isInFinalizeEditMode: action.payload.value,
      };
    case PAPERWORK_SET_PAGE_VIEW:
      return {
        ...state,
        pageView: action.payload.value,
      };
    case PAPERWORK_SEND_FORM_DATA_TO_STATE: {
      let formData = state.formData ? { ...state.formData } : {};
      for (const formField in action.payload.value) {
        if (action.payload.value) {
          formData[formField] = action.payload.value[formField];
        }
      }

      return {
        ...state,
        formData,
      };
    }
    case PAPERWORK_ADD_ADDRESS_VALIDATION_LOG:
      return {
        ...state,
        addressValidationLogs: (state.addressValidationLogs ?? []).concat(action.payload.value),
      };
    case PAPERWORK_RESET_ADDRESS_VALIDATION_LOGS:
      return {
        ...state,
        addressValidationLogs: [],
      };
    case PAPERWORK_LOCATION_PAPERWORK_FIELDS_RECEIVED: {
      let [stdLpfs, custLpfs] = partition(
        action.payload.value,
        (lpf: any) => lpf?.is_standard_field
      );
      custLpfs = uniqBy(custLpfs, 'field_name');
      custLpfs = sortBy(custLpfs, 'ranking');
      return {
        ...state,
        standardFields: stdLpfs,
        customFields: custLpfs,
        initializing: false,
      };
    }
    case PAPERWORK_LOCATION_PAPERWORK_FIELDS_ERROR:
      return {
        ...state,
        error: action.payload.value,
        initializing: false,
      };

    case PAPERWORK_LOCATION_CONSENT_FORMS_RECEIVED:
      return {
        ...state,
        consentForms: {
          ...state.consentForms,
          error: false,
          forms: action.payload.value,
        },
      };

    case PAPERWORK_LOCATION_CONSENT_FORMS_ERROR:
      return {
        ...state,
        consentForms: {
          ...state.consentForms,
          error: action.payload.value,
        },
      };

    case PAPERWORK_CONSENT_FORM_SIGNATURES_SUCCESS:
      return {
        ...state,
        signatures: {
          error: false,
          success: true,
        },
      };

    case PAPERWORK_CONSENT_FORM_SIGNATURES_ERROR:
      return {
        ...state,
        signatures: {
          error: true,
          success: false,
        },
      };

    case PAPERWORK_RESET_STATE:
      return {
        ...state,
        ...getInitialState(),
      };

    case PAPERWORK_COMING_FROM_PASS:
      return {
        ...state,
        isComingFromPass: action.payload.value,
      };

    default:
      return state;
  }
};

export default reducer;

export {
  incrementProgressBar,
  toggleFinalizeEditMode,
  setPageView,
  sendDataToState,
  initializingData,
  isSubmittingPaperworkPage,
  locationPaperworkFieldsReceived,
  locationPaperworkFieldsError,
  locationConsentFormsReceived,
  locationConsentFormsError,
  consentFormSignaturesSuccess,
  consentFormSignaturesError,
  resetPaperworkState,
  isSubmittingPaperworkStatusLog,
  setIsComingFromPass,
  addAddressValidationLog,
  resetAddressValidationLogs,
};
