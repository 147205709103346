import React, { useEffect, useState } from 'react';
import { Alert, Text, Link } from '@solvhealth/jigsaw';
import styled from 'styled-components';
import { analyticsTrackEvent } from '~/core/analytics';
import { isNativeApp } from '../../core/util/device';
import { CCPA_CONSENT_BANNER_POLICY_LINK } from '~/core/analytics/events';
import history from '~/core/history';
import { setItemInLocalStorage, getItemInLocalStorage } from '~/core/util/storage';
import { colors } from '~/constants/colors';
import useViewport from '~/components/useViewport';

// left position is calc(50% - 17px) because the banner has a close icon section on the right that has a width of 34px.
// z-index is 1300 because it should be higher than most of pages' content but lower than modals' (login modal etc.) content, which has a value of 1400.
const AlertContainer = styled.div`
  position: fixed;
  bottom: 50px;
  left: calc(50% - 17px);
  transform: translate(-50%, 0);
  z-index: 1300;
  width: 87%;
  max-width: 912px;
  & svg {
    color: ${colors.crunchBerry};
  }
`;

const CCPAConsentBanner = () => {
  const [hasViewed, setHasViewed] = useState(true);
  const { width, height } = useViewport();

  useEffect(() => {
    if (!isNativeApp() && !getItemInLocalStorage('ccpaConsentBannerShown')) {
      setHasViewed(false);
      setItemInLocalStorage('ccpaConsentBannerShown', 'true');
    }
  }, [setHasViewed]);

  useEffect(() => {
    if (!isNativeApp() && !hasViewed) {
      let unlisten = history.listen(() => {
        setHasViewed(true);
        unlisten();
        unlisten = () => null;
      });

      return () => {
        try {
          unlisten();
          unlisten = () => null;
        } catch (err) {
          // nothing really needed here, if unlisten throws an error that means the listener has already been removed.
          return;
        }
      };
    }
    return undefined;
  }, [hasViewed]);

  if (hasViewed) return null;

  const handleLinkClick = () => {
    analyticsTrackEvent(CCPA_CONSENT_BANNER_POLICY_LINK);
    window.location.href = '/privacy';
  };

  return (
    <AlertContainer>
      <Alert collapsible icon={null} show tone="message" variant="inline">
        <Text as="span" variant="body">
          This site uses cookies to provide you with a great user experience. By using Solv, you
          accept our{' '}
        </Text>
        <Link inline onClick={handleLinkClick} subtle sx={{ textDecoration: 'underline' }}>
          use of cookies.
        </Link>
      </Alert>
    </AlertContainer>
  );
};

export default CCPAConsentBanner;
