import React from 'react';
import { Text } from '@solvhealth/jigsaw';
import { AutoCompleteResult, SuggestionDoc } from '../AutocompleteResults';
import { URGENT_CARE_VALUE, PROVIDER_GROUP_VALUE } from '~/components/Home/Tiles/ProviderTypes';
import { formatMultipleProviderTypes, getDocProviderType } from '../util';

type Props = {
  result: AutoCompleteResult & { fullDoc?: SuggestionDoc };
};

const SecondaryLabel = ({ result }: Props) => {
  const docProviderType = getDocProviderType(result);
  if (docProviderType === URGENT_CARE_VALUE) return null;
  return (
    <Text paddingTop={0.5} textColor="gray-400" variant="caption">
      {docProviderType === PROVIDER_GROUP_VALUE
        ? formatMultipleProviderTypes(result?.secondary)
        : result?.secondary}
    </Text>
  );
};

export default SecondaryLabel;
