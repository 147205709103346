import React, { PureComponent } from 'react';
import CircleImage from '../SolvPatternLibrary/CircleImage';
import { safeGet } from '../../core/util/object';
import { isEmpty } from '../../core/util/empty';
import { THUMBNAIL_SMALL } from '../SolvPatternLibrary/Picture/util';
import { AutoCompleteResult } from './AutocompleteResults';

type Props = {
  suggestion: AutoCompleteResult & { fullDoc?: any };
  className?: string;
};

class AutocompleteIcon extends PureComponent<Props> {
  render() {
    const {
      className,
      suggestion: { fullDoc, icon },
    } = this.props;

    if (icon) return icon;

    const locationImages = fullDoc?.location_images;
    const docType = fullDoc?.type;

    if (docType === 'provider-type' || docType === 'free-text' || docType === 'keyword') {
      return (
        <img
          alt="provider type"
          className={className}
          src="/images/icons/universal_search/uc.svg"
        />
      );
    }

    if (docType === 'location' && !isEmpty(locationImages)) {
      const imageObject = {
        fullRes: locationImages.full_res,
        webp: locationImages.webp,
        jp2: locationImages.jp2,
        thumbnail128: locationImages.thumbnail128,
        thumbnail200: locationImages.thumbnail200,
      };
      return (
        <CircleImage
          className={className}
          imageObject={imageObject}
          showThumbnail
          thumbnailSize={THUMBNAIL_SMALL}
        />
      );
    }

    return null;
  }
}

export default AutocompleteIcon;
