import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Input from './Base';
import { CONSUMER_APP, COVID_COST_TYPE_OPTIONS } from '../../../constants';
import { analyticsTrackEvent } from '../../../core/analytics';
import {
  ACTION_BLUR,
  ACTION_CHANGE_VALUE,
  ACTION_CLICK,
  PATIENT_TYPE_SET_BY_USER,
  UNIVERSAL_COST_TYPE_INPUT,
  UNIVERSAL_PATIENT_TYPE_INPUT,
} from '../../../core/analytics/events';
import { isMobile } from '../../../core/util/device';
import { setCovidCostType } from '../../../actions/searchPreferences';

const CostInput = styled(Input)`
  -webkit-appearance: none;
  text-transform: capitalize;
  padding: 1px;
  text-align: left;
`;

const tagString = (toTag: string) => `<em>${toTag}</em>`;

type CostProps = {
  className?: string;
  updateSuggestions?: (...args: any[]) => any;
  refSetter?: (...args: any[]) => any;
  clearOnBlur?: boolean;
  onSelect?: (...args: any[]) => any;
};

const COVID_COST_DEFAULT = COVID_COST_TYPE_OPTIONS[0];

const Cost = ({ className, clearOnBlur, onSelect, refSetter, updateSuggestions }: CostProps) => {
  const dispatch = useDispatch();
  const stateCostType = useSelector((state: any) => state.searchPreferences.covidCostType);
  const covidCostType: { label: string; value: string } = stateCostType || COVID_COST_DEFAULT;

  const updateAndClear = (covidCostType: any) => {
    const trackingProperties = {
      covidCostType: covidCostType.value,
      sourceOfInput: CONSUMER_APP,
      isMobile: isMobile(),
    };
    analyticsTrackEvent(PATIENT_TYPE_SET_BY_USER, trackingProperties);
    analyticsTrackEvent(UNIVERSAL_PATIENT_TYPE_INPUT, {
      action: ACTION_CHANGE_VALUE,
      ...trackingProperties,
    });

    dispatch(setCovidCostType(covidCostType));

    updateSuggestions?.([]);
    onSelect && onSelect(covidCostType);
  };

  const getSuggestions = () => [
    {
      primary:
        covidCostType?.value === COVID_COST_TYPE_OPTIONS[0].value
          ? tagString(COVID_COST_TYPE_OPTIONS[0].label)
          : COVID_COST_TYPE_OPTIONS[0].label,
      key: COVID_COST_TYPE_OPTIONS[0].value,
      onClick: () => updateAndClear(COVID_COST_TYPE_OPTIONS[0]),
    },
    {
      primary:
        covidCostType?.value === COVID_COST_TYPE_OPTIONS[1].value
          ? tagString(COVID_COST_TYPE_OPTIONS[1].label)
          : COVID_COST_TYPE_OPTIONS[1].label,
      key: COVID_COST_TYPE_OPTIONS[1].value,
      onClick: () => updateAndClear(COVID_COST_TYPE_OPTIONS[1]),
    },
  ];

  const handleClick = () => {
    analyticsTrackEvent(UNIVERSAL_COST_TYPE_INPUT, {
      action: ACTION_CLICK,
      isMobile: isMobile(),
    });
    updateSuggestions?.(getSuggestions());
  };

  useEffect(() => {
    !clearOnBlur && handleClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stateCostType) dispatch(setCovidCostType(COVID_COST_DEFAULT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = () => {
    analyticsTrackEvent(UNIVERSAL_PATIENT_TYPE_INPUT, {
      action: ACTION_BLUR,
      isMobile: isMobile(),
    });
  };

  return (
    <CostInput
      $url="/images/icons/search-icons/Icon-Seach-Patient.svg"
      className={className}
      onBlur={handleBlur}
      onClick={handleClick}
      onFocus={handleClick}
      ref={refSetter}
      type="button"
      value={covidCostType?.label}
    />
  );
};

export default Cost;
