import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Provider from '@solvhealth/types/interfaces/Provider';
import LeaveAReview from '../../../LeaveAReview/connected';
import { colors } from '~/constants/colors';

export const LEAVE_A_REVIEW_MODAL = 'leave a review modal';

const SmoothDialog = styled(Dialog)`
  -webkit-font-smoothing: antialiased;
  & .dialog-root {
    background-color: ${colors.whiteGrey};
  }
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 26px;
  top: 20px;
  cursor: pointer;

  &:before {
    content: '';
    left: 50%;
    height: 100%;
    width: 1px;
    position: absolute;
    transform: rotate(45deg);
    background-color: ${colors.theWhiteAlbum};
  }

  &:after {
    content: '';
    left: 50%;
    height: 100%;
    width: 1px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: ${colors.theWhiteAlbum};
  }
`;

type Props = {
  open?: boolean;
  fullScreen?: boolean;
  onClose?: (...args: any[]) => any;
  provider?: Provider;
  providerGroupName?: string;
};

const LeaveAReviewModal = (props: Props) => {
  const { open = false, fullScreen, onClose, provider, providerGroupName } = props;
  return (
    <SmoothDialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      PaperProps={{ classes: { root: 'dialog-root' } }}
      scroll="body"
    >
      <Close onClick={onClose} />
      <LeaveAReview provider={provider} providerGroupName={providerGroupName} />;
    </SmoothDialog>
  );
};

export default withMobileDialog({ breakpoint: 'xs' })(LeaveAReviewModal);
