import { DAPI_HOST } from '../../config';

export const getLabResultUrl = (labResultId: string, lastName: string = '') =>
  `${DAPI_HOST}/v1/lab-results/${labResultId}?expand_entities=true&last_name=${lastName}`;

export const getLabResultsByBookingUrl = (
  bookingId: string,
  lastName: string,
  expandEntities = true
) =>
  `${DAPI_HOST}/v1/lab-results?filters=bookings.id:${bookingId}&last_name=${lastName}&expand_entities=${expandEntities}`;

export const getLabResultConfigUrl = (labResultConfigIds: string[]) =>
  `${DAPI_HOST}/v1/lab-result-configs?filters=lab_result_configs.id:${labResultConfigIds.join(
    ','
  )}`;
