import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { Box, Text, TextField } from '@solvhealth/jigsaw';
import React from 'react';
import styled from 'styled-components';

const DropdownBox = styled(Box)`
  /* Need to override the padding on the Mui's li element */
  .MuiAutocomplete-option {
    padding: 0;
  }
`;

const DropdownComponent = (props: React.HTMLAttributes<HTMLElement>) => (
  <DropdownBox {...props} backgroundColor="white" borderRadius="base" boxShadow="lg" />
);

const DropdownOption = styled(Box).attrs({
  paddingX: 2,
  paddingY: 1.5,
})``;

type TextFieldProps = PropsType<typeof TextField>;

/**
 * The prop interface of SolvAutocomplete is the same as the material-ui Autocomplete component,
 * with three exceptions:
 * 1. The `renderInput` prop is replaced with `renderTextField`, which has a different interface
 *    & expects the consumer to render a Jigsaw TextField component.
 * 2. The `PaperComponent` prop is removed / not overridable.
 * 3. The getOptionLabel is required, not optional.
 */
type SolvAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'getOptionLabel' | 'PaperComponent'
> &
  Required<Pick<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'getOptionLabel'>> & {
    renderTextField(textFieldProps: TextFieldProps): React.ReactNode;
    getOptionSubtitle?(option: T): React.ReactNode;
  };

/**
 * This component is intentionally a class component in order to play well with antd form's
 * expectations around refs. tl;dr antd form wants to pass a ref to its wrapped component,
 * and wrapping a generic component in forwardRef is REALLY hard to do while preserving the
 * generic interface.
 */
export class SolvAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends React.Component<SolvAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
  renderInput: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'] = ({
    InputLabelProps: _InputLabelProps,
    InputProps: { className, endAdornment, startAdornment, ref: anchorRef },
    disabled,
    inputProps,
  }) =>
    this.props.renderTextField({
      className,
      disabled,
      ...inputProps,
      containerRef: anchorRef,
      endIcon: endAdornment,
      startIcon: startAdornment,
    });

  renderOption: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderOption'] = (
    option: T,
    state: AutocompleteRenderOptionState
  ) => {
    const optionLabel = this.props.getOptionLabel?.(option) ?? String(option);
    const subLabel = this.props.getOptionSubtitle?.(option);
    const inputText = state.inputValue;
    return this.renderOptionCore(optionLabel, inputText, subLabel);
  };

  renderOptionCore(optionLabel: string, input: string, subLabel?: React.ReactNode) {
    const inputIndexInLabel = optionLabel.toLowerCase().indexOf(input.toLowerCase());
    // Assume the label was not found in the input, in which case the entirety of the output is "pre-highlight"
    let preHighlight = optionLabel;
    let highlight = '';
    let postHighlight = '';
    if (inputIndexInLabel >= 0) {
      preHighlight = optionLabel.substr(0, inputIndexInLabel);
      highlight = optionLabel.substr(inputIndexInLabel, input.length);
      postHighlight = optionLabel.substr(inputIndexInLabel + input.length);
    }

    return (
      <DropdownOption>
        <Box>
          {!!preHighlight && (
            <Text as="span" variant="body">
              {preHighlight}
            </Text>
          )}
          {!!highlight && (
            <Text as="span" variant="subtitle">
              {highlight}
            </Text>
          )}
          {!!postHighlight && (
            <Text as="span" variant="body">
              {postHighlight}
            </Text>
          )}
        </Box>
        {!['number', 'string'].includes(typeof subLabel) ? (
          subLabel
        ) : (
          <Text as="span" muted variant="body2">
            {subLabel}
          </Text>
        )}
      </DropdownOption>
    );
  }

  render() {
    const { renderTextField, ...props } = this.props;
    return (
      <Autocomplete
        // Place `renderOption` before the ...props so that it is overridable by the consumer.
        renderOption={this.renderOption}
        {...props}
        PaperComponent={DropdownComponent}
        renderInput={this.renderInput}
      />
    );
  }
}
