export const getInsurerFromInsurersList = (id: any, insurers: any) =>
  insurers.find((insurer: any) => insurer.id === id);

export const getInsurerIdByInsurerCode = (code: any, insurers: any) => {
  const insurer = insurers.find((i: any) => i.insurer_code === code);
  if (insurer) {
    return insurer.id;
  }

  return null;
};
