import history from '../../../core/history';
import { BASE_URL } from '../../../config/index';
import { ABOUT_US_PATH, BLOG_PATH, FOR_PROVIDERS_PATH, HOME_PATH } from '../../../constants/index';
import AccountViews from '../../AccountGetsMarried/constants/AccountViews';

// Logged In Tabs
export const ACCOUNT_HOME_VIEW = 'home';
export const ACCOUNT_FIND_CARE_VIEW = AccountViews.GetCare;
export const ACCOUNT_INSURANCE_VIEW = AccountViews.Insurance;
export const ACCOUNT_PROFILE_VIEW = AccountViews.Profile;
export const ACCOUNT_MANAGE_VISITS_VIEW = AccountViews.Manage;

export const ACCOUNT_HOME_URL_PART = 'get-care';
export const ACCOUNT_FIND_CARE_URL_PART = 'find-care';
export const ACCOUNT_PROFILE_URL_PART = 'profile';

// Logged in standalone - convert to modal?
export const BIG_TEX_FORM_VIEW = 'Big Tex Form';

// TODO: convert to modals
export const NEW_PROFILE_VIEW = 'New Person';
export const VISIT_SUMMARY_VIEW = 'Visit Summary';
export const COST_ESTIMATE_VIEW = 'Cost Estimate';
export const SETTINGS_VIEW = 'Account Settings';

// Logged Out tabs
export const LOGGED_OUT_FIND_CARE_VIEW: string = 'Logged Out Find Care';
export const LOGGED_OUT_FOR_PROVIDERS_VIEW: string = 'For Providers';
export const LOGGED_OUT_BLOG_VIEW: string = 'Blog';
export const LOGGED_OUT_ABOUT_VIEW: string = 'About';

// legacy
export const VISITS_URL_PART = 'appointments';

export const viewTypes = [
  /* Logged in views */
  ACCOUNT_HOME_VIEW,
  ACCOUNT_FIND_CARE_VIEW,
  ACCOUNT_PROFILE_VIEW,
  ACCOUNT_INSURANCE_VIEW,

  // Logged in standalone - convert to modal?
  BIG_TEX_FORM_VIEW,

  // TODO: convert to modals
  NEW_PROFILE_VIEW,
  VISIT_SUMMARY_VIEW,
  COST_ESTIMATE_VIEW,
  SETTINGS_VIEW,

  /* Logged out views */
  LOGGED_OUT_FIND_CARE_VIEW,
  LOGGED_OUT_ABOUT_VIEW,
  LOGGED_OUT_FOR_PROVIDERS_VIEW,
  LOGGED_OUT_BLOG_VIEW,
];

export const VISITS_UPCOMING = 'upcoming';
export const VISITS_PAST = 'past';
export const GET_CARE_DISCOVER = 'discover';
export const GET_CARE_CHECKLIST = 'checklist';
export const GET_CARE_LOCATION_LIST = 'locations';

/**
 * View Config
 * The template for go is (userProfileId) => { return '...url...'; }
 * At least for now, more params can be added!
 *
 * @type {{}}
 */
export const VIEW_CONFIG = {
  /* Logged Out Views */
  [LOGGED_OUT_FIND_CARE_VIEW]: {
    navLabel: 'Find Care',
    go: () => history.push('/'),
  },
  [LOGGED_OUT_FOR_PROVIDERS_VIEW]: {
    navLabel: 'For Providers',
    go: () => {
      window.location.href = `${BASE_URL}${FOR_PROVIDERS_PATH}`;
    },
  },
  [LOGGED_OUT_BLOG_VIEW]: {
    navLabel: 'Blog',
    go: () => {
      window.location.href = `${BASE_URL}${BLOG_PATH}`;
    },
  },
  [LOGGED_OUT_ABOUT_VIEW]: {
    navLabel: 'About',
    go: () => history.push('/about-us'),
  },

  // TODO: update these for updated routes
  /* Logged in views */
  [ACCOUNT_HOME_VIEW]: {
    navLabel: 'Home',
    go: (userProfileId: any) =>
      history.push(`/account/home/${userProfileId}/${AccountViews.GetCare}`),
  },
  [ACCOUNT_FIND_CARE_VIEW]: {
    navLabel: 'Find Care',
    go: (userProfileId: any) =>
      history.push(`/account/home/${userProfileId}/${AccountViews.GetCare}`),
  },
  [ACCOUNT_PROFILE_VIEW]: {
    navLabel: 'Profile',
    go: (userProfileId: any) =>
      history.push(`/account/home/${userProfileId}/${AccountViews.Profile}`),
  },
  [ACCOUNT_INSURANCE_VIEW]: {
    navLabel: 'Insurance',
    go: (userProfileId: any) =>
      history.push(`/account/home/${userProfileId}/${AccountViews.Insurance}`),
  },
  [ACCOUNT_MANAGE_VISITS_VIEW]: {
    navLabel: 'Manage Visits',
    go: (userProfileId: any) =>
      history.push(`/account/home/${userProfileId}/${AccountViews.Manage}`),
  },

  /* Misc convert to modal? */
  [BIG_TEX_FORM_VIEW]: {},

  /* TODO: Convert to modals */
  [VISIT_SUMMARY_VIEW]: {},
  [NEW_PROFILE_VIEW]: {},
  [COST_ESTIMATE_VIEW]: {},
  [SETTINGS_VIEW]: {},
};

export const HeaderTabUrls = {
  [LOGGED_OUT_FIND_CARE_VIEW]: HOME_PATH,
  [LOGGED_OUT_FOR_PROVIDERS_VIEW]: FOR_PROVIDERS_PATH,
  [LOGGED_OUT_BLOG_VIEW]: BLOG_PATH,
  [LOGGED_OUT_ABOUT_VIEW]: ABOUT_US_PATH,
};

export const navigateToView = (view: any, userProfileId = null) => {
  if (VIEW_CONFIG.hasOwnProperty(view)) {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    VIEW_CONFIG[view].go(userProfileId);
  }
};
