import {
  CALCULATOR_DATA_SUBMITTED,
  RECEIVE_CALCULATOR_DATA,
  CALCULATOR_DATA_CLEAR,
  CALCULATOR_DATA_ERROR,
} from '../constants/index';

const createInitialState = () => ({ currentRating: 0, ratingWithSolv: 0 });

export default (state = createInitialState(), action: any) => {
  switch (action.type) {
    case CALCULATOR_DATA_SUBMITTED:
      return {
        ...state,
        submitting: true,
        completed: false,
        error: false,
      };

    case RECEIVE_CALCULATOR_DATA:
      return {
        ...state,
        ...action.payload.value,
        submitting: false,
        completed: true,
        error: false,
      };

    case CALCULATOR_DATA_ERROR:
      return {
        ...state,
        submitting: false,
        completed: true,
        error: true,
        errorValue: action.payload.value,
      };

    case CALCULATOR_DATA_CLEAR:
      return {
        ...createInitialState(),
      };

    default:
      return state;
  }
};
