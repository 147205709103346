export interface VerificationStatus {
  phoneNumber: string;
  status: number;
}

export interface IsStatusGetter {
  (verificationStatus?: VerificationStatus): boolean;
}

export enum PhoneVerificationStatus {
  WAITING,
  SUBMITTING,
  VERIFIED,
  UNVERIFIED,
  STATUS_ERROR,
  BOOKING_ID_MISMATCH,
  UNVERIFIABLE,
}

export const getIsStatusSubmitting: IsStatusGetter = (verificationStatus) =>
  verificationStatus?.status === PhoneVerificationStatus.SUBMITTING;

export const getIsStatusUnverified: IsStatusGetter = (verificationStatus) =>
  verificationStatus?.status === PhoneVerificationStatus.UNVERIFIED;

export const getIsStatusUnverifiable: IsStatusGetter = (verificationStatus) =>
  verificationStatus?.status === PhoneVerificationStatus.UNVERIFIABLE;

export const getIsStatusBookingIdMismatch: IsStatusGetter = (verificationStatus) =>
  verificationStatus?.status === PhoneVerificationStatus.BOOKING_ID_MISMATCH;
