import {
  GETTING_POSITION,
  IP_REVERSE_GEOCODE_RESULTS_RECEIVED,
  LOCATION_SOURCE_IP_ADDRESS,
  POSITION_ERROR,
  POSITION_SUCCESS,
} from '../constants/index';
import { parseLocationFromReverseGeoCodeResult } from '../core/dapi/geoCode';

export type PositionState = {
  success?: boolean;
  result?: GeoResponse & {
    source?: string;
    initialLabel?: string;
    cityLabel?: string;
  };
  timeOfRequest?: Date;
  gettingPosition?: boolean;
};

export default function positionReducer(state = {}, action: any) {
  switch (action.type) {
    case POSITION_SUCCESS:
      return {
        ...state,
        success: true,
        result: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type '{}'.
          initialLabel: state.result && state.result.label,
          cityLabel: action.payload.value?.city,
          ...action.payload.value,
        },
        timeOfRequest:
          action.payload.value.source === LOCATION_SOURCE_IP_ADDRESS ? action.timeOfRequest : null,
      };
    case POSITION_ERROR:
      return {
        ...state,
        success: false,
        result: null,
        timeOfRequest: null,
      };
    case GETTING_POSITION:
      return {
        ...state,
        gettingPosition: action.payload.value,
      };
    case IP_REVERSE_GEOCODE_RESULTS_RECEIVED:
      return {
        ...state,
        result: {
          ...parseLocationFromReverseGeoCodeResult(action.payload.value.results[0]),
          source: LOCATION_SOURCE_IP_ADDRESS,
        },
      };
    default:
      return state;
  }
}
