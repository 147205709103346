import { FAVORITE_RECEIVED, FAVORITE_PROCESSING, FAVORITE_ERROR } from '../constants/index';

const favoriteReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FAVORITE_RECEIVED:
      return {
        ...state,
        favorite: action.payload.value,
        favoriteProcessing: null,
        favoriteError: null,
      };
    case FAVORITE_PROCESSING:
      return {
        ...state,
        favorite: null,
        favoriteProcessing: true,
        favoriteError: null,
      };
    case FAVORITE_ERROR:
      return {
        ...state,
        favorite: null,
        favoriteProcessing: null,
        favoriteError: action.payload.value,
      };
    default:
      return state;
  }
};

export default favoriteReducer;
