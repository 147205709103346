import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Provider from '@solvhealth/types/interfaces/Provider/index';
import { colors } from '../../../constants/colors';
import { fontFamily, fontSize } from '../../../constants/text';
import { getLocationRatingAndRatingCount } from '../../../core/util/location';
import StarRating from '../../SolvPatternLibrary/StarRating';
import { getProviderName } from '~/components/ProviderGroup/Provider/util';

const Root = styled.div`
  background: ${colors.mrBlueSky};
  padding: 20px 26px;
  text-align: left;
`;

const PrimaryTitle = styled.div`
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.medium};
  color: ${colors.theWhiteAlbum};
`;

const SecondaryTitle = styled(PrimaryTitle)`
  font-family: ${fontFamily.book};
`;

const RatingContainer = styled.div`
  display: ${({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hide' does not exist on type 'Pick<Detai... Remove this comment to see the full error message
    hide,
  }) => (hide ? 'none' : 'flex')};
  padding-top: 5px;
  justify-content: flex-start;
  font-size: ${fontSize.mediumSmall};
`;

const NumericRating = styled.span`
  font-family: ${fontFamily.bold};
  color: ${colors.theWhiteAlbum};
  padding-right: 8px;
`;

type Props = {
  location?: any;
  provider?: Provider;
};

const getTitle = (location?: any, provider?: Provider) => {
  if (location) {
    return (
      <PrimaryTitle>
        {location.displayNamePrimary}
        {location.displayNameSecondary && ','}
        <SecondaryTitle>{location.displayNameSecondary}</SecondaryTitle>
      </PrimaryTitle>
    );
  }
  return <PrimaryTitle>{getProviderName(provider)}</PrimaryTitle>;
};

export default class ReviewHeader extends PureComponent<Props> {
  render() {
    const { location, provider } = this.props;
    const rating = location
      ? Math.round(getLocationRatingAndRatingCount(location).rating * 10) / 10
      : Math.round((provider?.rating_solv ?? 0) * 10) / 10;

    return (
      <Root>
        {getTitle(location, provider)}
        {/* @ts-expect-error ts-migrate(2769) FIXME: Property 'hide' does not exist on type 'IntrinsicA... Remove this comment to see the full error message */}
        <RatingContainer hide={Number.isNaN(rating)}>
          <NumericRating>{rating || null}</NumericRating>

          <StarRating
            identifier={`leaveareview-${location?.displayNamePrimary || getProviderName(provider)}`}
            primaryColor={colors.theWhiteAlbum}
            rating={rating}
            secondaryColor={colors.mrBlueSky}
            size={11}
            spacing="6px"
          />
        </RatingContainer>
      </Root>
    );
  }
}
