import produce from 'immer';
import { IndividualPatientFields } from '~/components/AdditionalPatients';
import { EMPTY_ARRAY } from '~/core/util/array';
import { SolvReduxState } from '~/reducers';
import { formatDate } from './util';

// Family Booking - Booking widget
export const MAX_FAMILY_BOOKING_PATIENTS = 4;
export const ACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING =
  'bookingWidgetFamilyBooking/ACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING';
export const DEACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING =
  'bookingWidgetFamilyBooking/DEACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING';
export const SELECT_FAMILY_BOOKING_TOTAL_PATIENTS =
  'bookingWidgetFamilyBooking/SELECT_FAMILY_BOOKING_TOTAL_PATIENTS';

export const SET_AUX_PATIENTS_INFO = 'bookingWidgetFamilyBooking/SET_AUX_PATIENTS_INFO';

export const SET_APPLY_ID_TO_ALL_FAMILY_MEMBERS =
  'bookingWidgetFamilyBooking/SET_APPLY_ID_TO_ALL_FAMILY_MEMBERS';

export const SET_APPLY_INSURANCE_TO_ALL_FAMILY_MEMBERS =
  'bookingWidgetFamilyBooking/SET_APPLY_INSURANCE_TO_ALL_FAMILY_MEMBERS';

export const RESET_BOOKING_WIDGET_FAMILY_BOOKING =
  'bookingWidgetFamilyBooking/RESET_BOOKING_WIDGET_FAMILY_BOOKING';

export const CLEAR_BOOKING_WIDGET_AUX_PATIENTS_ADDRESS =
  'bookingWidgetFamilyBooking/CLEAR_BOOKING_WIDGET_AUX_PATIENTS_ADDRESS';

export const CLEAR_BOOKING_WIDGET_AUX_PATIENTS_BIRTH_SEX =
  'bookingWidgetFamilyBooking/CLEAR_BOOKING_WIDGET_AUX_PATIENTS_BIRTH_SEX';

export const activateBookingWidgetFamilyBooking = () => ({
  type: ACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING,
});

export const deactivateBookingWidgetFamilyBooking = () => ({
  type: DEACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING,
});

export const selectFamilyBookingTotalPatients = (numTotalPatients: number) => ({
  type: SELECT_FAMILY_BOOKING_TOTAL_PATIENTS,
  payload: numTotalPatients,
});

export const setAuxPatientInfo = (auxPatients: Array<IndividualPatientFields>) => ({
  type: SET_AUX_PATIENTS_INFO,
  payload: auxPatients,
});

export const setApplyIdToAllMembers = (value: boolean) => ({
  type: SET_APPLY_ID_TO_ALL_FAMILY_MEMBERS,
  payload: value,
});

export const setApplyInsuranceToAllMembers = (value: boolean) => ({
  type: SET_APPLY_INSURANCE_TO_ALL_FAMILY_MEMBERS,
  payload: value,
});

export const resetBookingWidgetFamilyBooking = () => ({
  type: RESET_BOOKING_WIDGET_FAMILY_BOOKING,
});

export const clearBookingWidgetAuxPatientsAddress = () => ({
  type: CLEAR_BOOKING_WIDGET_AUX_PATIENTS_ADDRESS,
});
export const clearBookingWidgetAuxPatientsBirthSex = () => ({
  type: CLEAR_BOOKING_WIDGET_AUX_PATIENTS_BIRTH_SEX,
});

export interface BookingWidgetFamilyBookingState {
  auxPatients: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    symptoms: string;
    birthSex: string;
    address_street: string;
    address_street_secondary: string;
    address_city: string;
    address_state: string;
    address_zip: string;
    // YYYY-MM-DD
    birthDate: string;
    hasDifferentAddress: boolean;
  }[];
  maxNumAuxPatients: number;
  /**
   * The anticipated number of aux patients in a family booking. Used to reason about
   * how many fields to render on the additional fields page, before the auxPatients
   * array is populated. Once that array is populated, its length should take precedence
   * over this field.
   */
  numberOfAuxPatients: number;
  applyIDToAllFamilyMembers: boolean;
  applyInsuranceToAllFamilyMembers: boolean;
}

const INIT_STATE: BookingWidgetFamilyBookingState = {
  auxPatients: [],
  maxNumAuxPatients: MAX_FAMILY_BOOKING_PATIENTS,
  numberOfAuxPatients: 0,
  applyIDToAllFamilyMembers: false,
  applyInsuranceToAllFamilyMembers: false,
};

export default function bookingWidgetFamilyBookingReducer(state = INIT_STATE, action: any) {
  let newState = produce(state, (draft) => {
    switch (action.type) {
      case RESET_BOOKING_WIDGET_FAMILY_BOOKING:
        return INIT_STATE;
      case ACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING:
        draft.numberOfAuxPatients = 0;
        break;
      case DEACTIVATE_BOOKING_WIDGET_FAMILY_BOOKING:
        draft.numberOfAuxPatients = 0;
        draft.auxPatients = EMPTY_ARRAY;
        break;
      case SELECT_FAMILY_BOOKING_TOTAL_PATIENTS:
        draft.numberOfAuxPatients = action.payload;
        draft.auxPatients = EMPTY_ARRAY;
        break;
      case CLEAR_BOOKING_WIDGET_AUX_PATIENTS_ADDRESS:
        for (const patient of draft.auxPatients) {
          patient.address_city = '';
          patient.address_state = '';
          patient.address_street = '';
          patient.address_street_secondary = '';
          patient.address_zip = '';
        }
        break;
      case CLEAR_BOOKING_WIDGET_AUX_PATIENTS_BIRTH_SEX:
        for (const patient of draft.auxPatients) {
          patient.birthSex = '';
        }
        break;
      case SET_AUX_PATIENTS_INFO:
        draft.auxPatients = action.payload.map(
          ({
            birthDateDay,
            birthDateMonth,
            birthDateYear,
            ...patient
          }: IndividualPatientFields) => ({
            ...patient,
            birthDate: formatDate(birthDateYear, birthDateMonth, parseInt(birthDateDay, 10)),
          })
        );
        break;
      case SET_APPLY_ID_TO_ALL_FAMILY_MEMBERS:
        draft.applyIDToAllFamilyMembers = !!action.payload;
        break;
      case SET_APPLY_INSURANCE_TO_ALL_FAMILY_MEMBERS:
        draft.applyInsuranceToAllFamilyMembers = !!action.payload;
        break;
      default:
        return state;
    }
    // eslint-disable-next-line consistent-return
    return;
  });

  if (newState !== state) {
    // Immer freezes the object, but that breaks redux persist.
    // A shallow copy is enough to compenstate.
    newState = { ...newState };
  }

  return newState;
}

export const getBookingWidgetFamilyBooking = (reduxState: SolvReduxState) =>
  reduxState?.bookingWidgetFamilyBooking;

export const getIsFamilyVisitActivatedInBookingWidget = (reduxState: SolvReduxState) =>
  reduxState.bookingWidgetFamilyBooking.numberOfAuxPatients > 0;

export const getSpecifiedNumberofAuxPatients = (reduxState: SolvReduxState): number =>
  reduxState?.bookingWidgetFamilyBooking?.numberOfAuxPatients ?? 0;

export const getAuxPatients = (
  reduxState: SolvReduxState
): Array<IndividualPatientFields> | any[] =>
  reduxState?.bookingWidgetFamilyBooking?.auxPatients ?? EMPTY_ARRAY;

export const getApplyIDToAllFamilyMembers = (reduxState: SolvReduxState): boolean =>
  reduxState?.bookingWidgetFamilyBooking?.applyIDToAllFamilyMembers ?? false;

export const getApplyInsuranceToAllFamilyMembers = (reduxState: SolvReduxState): boolean =>
  reduxState?.bookingWidgetFamilyBooking?.applyInsuranceToAllFamilyMembers ?? false;
