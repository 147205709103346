import {
  CREATING_NEW_PROFILE,
  NEW_PROFILE_RELATIONSHIP,
  RelationshipType,
  SELECT_PAYMENT_METHOD,
  SKIPPED_LOGIN_NUMBER,
  SUBMIT_PROFILE_DATA,
} from '../constants/index';
import { SelectedPaymentMethod } from '~/routes/book/components/BookingFlow/types';

export interface NewProfileData {
  firstName: string;
  lastName: string;
  birthDate: string;
  birthSex: string;
  email?: string;
  phone?: string;
  tosConsent?: boolean;
  smsConsent?: boolean;
}

export interface BookingFlowState {
  newProfileRelationship: RelationshipType;
  creatingNewProfile: boolean;
  newProfile?: NewProfileData;
  skippedLoginNumber: string;
  selectedPaymentMethod?: SelectedPaymentMethod;
}

export default (state = {}, action: any) => {
  switch (action.type) {
    case CREATING_NEW_PROFILE:
      return {
        ...state,
        creatingNewProfile: action.payload.value,
      };
    case NEW_PROFILE_RELATIONSHIP:
      return {
        ...state,
        newProfileRelationship: action.payload.value,
      };
    case SUBMIT_PROFILE_DATA:
      return {
        ...state,
        newProfile: action.payload.value,
      };
    case SKIPPED_LOGIN_NUMBER:
      return {
        ...state,
        skippedLoginNumber: action.payload.value,
      };
    case SELECT_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.payload.value,
      };
    default:
      return state;
  }
};
