import { isEmptyObject } from '~/core/util/object';

/**
 * careTeamProvidersResponseFormatter is used only for the response of /care-teams to format the providers data.
 * The /care-team endpoint can only set the resource type to location. This clashes and overwrites the provider
 * id with a location id hash. To fix this, we've set a new field in our care team providers response to
 * provider_id to prevent overwriting the id hash. Here we convert it back to id so we can utilize pre-existing
 * helper functions.
 */

export const careTeamProvidersResponseFormatter = (provider: any) => {
  if (isEmptyObject(provider)) return {};

  if (provider.isFormatted) {
    return provider;
  }

  let formatted = {
    id: null,
  };
  for (const key in provider) {
    if (!provider.hasOwnProperty(key)) {
      continue;
    }

    let value = null;
    switch (key) {
      case 'provider_id':
        /* Overwrite provider_id to id for our formatted data*/
        value = provider.provider_id;
        formatted.id = value;
        break;
      default:
        value = provider[key];
    }

    // @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
    formatted[key] = value;
  }
  return formatted;
};
