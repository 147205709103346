import {
  RECEIVE_PARENT_CONTENT,
  RECEIVE_PARENT_CONTENT_ERROR,
  CLEAR_CONTENT,
  SET_SLUG,
  SET_CHILD_SLUG,
  SET_GRANDPARENT_SLUG,
  SET_CONTENT_PATHS,
} from '../constants/index';

export default function contentReducer(state = {}, action: any) {
  switch (action.type) {
    case SET_SLUG:
      return {
        ...state,
        parentContent: action.payload.value,
      };
    case SET_CONTENT_PATHS:
      return {
        ...state,
        paths: action.payload.value,
      };
    case SET_CHILD_SLUG:
      return {
        ...state,
        childContent: action.payload.value,
      };
    case SET_GRANDPARENT_SLUG:
      return {
        ...state,
        grandparentContent: action.payload.value,
      };
    case CLEAR_CONTENT:
      return {
        ...state,
        childContent: null,
        parentContent: null,
      };
    case RECEIVE_PARENT_CONTENT:
      return {
        ...state,
        footerParentContent: action.payload.value,
      };
    case RECEIVE_PARENT_CONTENT_ERROR:
      return {
        ...state,
        footerParentContent: null,
        footerParentContentError: action.payload.value,
      };
    default:
      return state;
  }
}
