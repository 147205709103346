import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from '../../config/index';
import { loadServerSideLaunchDarklyFlags } from '~/core/launch-darkly/bootstrap';
import { getLDUserObject, LDUserObjectProps } from '~/core/launch-darkly/util';

/**
 * This function initializes Launch Darkly on the client side with a user object and a set of server-side flags (utilizing
 * the bootstrap option), and awaits so all that flags and the ldClient are ready before the consumer app is rendered.
 * The returned provider, which is a React FunctionalComponent, saves all flags and the ldClient instance in the context
 * API and subscribes to flag changes to propagate them through the context API.
 */
export async function initClientSideLaunchDarkly({
  accountId,
  segmentAnonymousId,
}: LDUserObjectProps): Promise<({ children }: { children: React.ReactNode }) => JSX.Element> {
  const user = getLDUserObject({ accountId, segmentAnonymousId });

  return await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
    user,
    options: {
      bootstrap: loadServerSideLaunchDarklyFlags(),
    },
  });
}
