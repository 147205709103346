import { matrix } from './array';
import { EN, SupportedLocale } from './localizedStr';

/* eslint-disable no-bitwise */
const queryStringFromObject = (obj: any) => {
  const str = [];
  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'constructor'.
      if (obj[property].hasOwnProperty(constructor) && obj[property].constructor === Array) {
        for (const subProperty of obj[property]) {
          str.push(`${encodeURIComponent(property)}=${encodeURIComponent(subProperty)}`);
        }
      } else {
        const value =
          typeof obj[property] === 'undefined' || obj[property] === null ? '' : obj[property];
        str.push(`${encodeURIComponent(property)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return str.join('&');
};

/**
 * @deprecated use lodash isEmpty
 */
const isEmptyString = (str: any) => typeof str === 'undefined' || str === null || str.length === 0;

const leftPad = (paddingChar: any, padCount: any, str: any) =>
  `${paddingChar.repeat(padCount)}${str || ''}`.slice(-padCount);

const camelToSnake = (str: any) => {
  let newStr = str.replace(
    /([A-Z])/g,
    (originalString: any, firstCharacter: any) => `_${firstCharacter.toLowerCase()}`
  );

  if (newStr.slice(0, 1) === '_') {
    newStr = newStr.slice(1);
  }

  return newStr;
};

/** Removes the '.00' portion of a price string if present so that we do not show the cents part
 * of a price if it's an even dollar amount.
 * IE: 100.00 -> 100, 100.02 -> 100.02
 *
 * @returns The trimmed price string */
const trimZeroCents = (price: string) => {
  // TODO: Confirm this endsWith() transpiles OK on IE
  return price.endsWith('.00') ? price.substring(0, price.length - 3) : price;
};

function numberWithCommas(x: string | number, shouldStripTrailingZeroCents: boolean = false) {
  if (shouldStripTrailingZeroCents) {
    return trimZeroCents(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const formatPrice = (
  price?: string | number | null,
  decimals = 2,
  shouldRemoveDecimalIfInteger = true,
  shouldStripTrailingZeroCents = false,
  showCurrencySymbol = true
) => {
  if (isEmptyString(price)) {
    return '$0';
  }

  let decimalsOverride = decimals;

  if (shouldRemoveDecimalIfInteger && Number.isInteger(Number.parseFloat(price as string))) {
    decimalsOverride = 0;
  }

  if (decimalsOverride) {
    return `${showCurrencySymbol ? '$' : ''}${numberWithCommas(
      parseFloat(price as string).toFixed(decimalsOverride),
      shouldStripTrailingZeroCents
    )}`;
  }

  return `${showCurrencySymbol ? '$' : ''}${numberWithCommas(
    price as string,
    shouldStripTrailingZeroCents
  )}`;
};

const formatPercentage = (decimalFraction: any) => {
  const integerPercentage = decimalFraction * 100;
  return `${integerPercentage}%`;
};

const trimTo = (str: any, maxLen: any) => {
  if (str.length <= maxLen) return str;

  return `${str.substring(0, maxLen - 3)}...`;
};

const setCharAt = (str: any, index: any, char: any) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + char + str.substring(index + 1);
};

/**
 * Pluralize a word. E.g.:
 * pluralize(1, 'lay') => 'lay'
 * pluralize(2, 'egg') => 'eggs'
 * pluralize(3, 'hatch', 'es') => 'hatches'
 * pluralize(4, 'octopus', 'i', '2') => 'octopi'
 *
 * @param {number} number
 * @param {string} word
 * @param {string} postfix
 * @param {number} trim
 * @returns {string}
 */
const pluralize = (number: any, word: any, postfix = 's', trim = 0) => {
  if (word === 'is') {
    if (number === 1) {
      return 'is';
    }

    return 'are';
  }

  if (word === 'has') {
    if (number === 1) {
      return 'has';
    }

    return 'have';
  }

  if (word === 'person') {
    if (number === 1) {
      return 'person';
    }

    return 'people';
  }

  if (number === 1) {
    return word;
  }

  let pluralized = word;
  if (trim > 0) {
    pluralized = pluralized.substring(0, pluralized.length - trim);
  }

  pluralized += postfix;
  return pluralized;
};

const arrayToEnglish = (arr: any) => {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return arr.join(' and ');
  }

  const last = arr.slice(-1);
  const first = arr.slice(0, -1).join(', ');
  return [first, last].join(', and ');
};

const objectToEnglish = (obj: any) => {
  const arr = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      arr.push(obj[key]);
    }
  }

  return arrayToEnglish(arr);
};

/**
 * Strip all non numeric characters from a string.
 *
 * @param string {String}
 * @returns string {String} Only numeric characters
 */
const stripNonNumeric = (string: any) => {
  switch (typeof string) {
    case 'string':
      return string.replace(/[^\d]/g, '');
    case 'number':
      return String(string);
    default:
      return '';
  }
};

/**
 * Strips all non-alphanumeric characters from a string.
 *
 * e.g M.D. => MD
 *
 * @param {string} string
 * @returns {string}
 */
const stripNonAlphanumeric = (string: string): string => {
  if (!string) return '';
  return string.replace(/\W/g, '');
};

/**
 * Get first and last initials separated by specified separator.
 *
 * e.g John Doe => J D
 *
 * @param {string} fullName
 * @param {string} separator
 * @returns {string}
 */
const getNameInitialsFromFullName = (fullName: any, separator: any) => {
  if (isEmptyString(fullName)) {
    return '';
  }

  return [fullName.split(' ')[0].slice(0, 1), fullName.split(' ')[1].slice(0, 1)].join(separator);
};

interface CapitalizeOptions {
  lowercaseRest?: boolean;
  locale?: SupportedLocale;
  ignorePunctuation?: boolean;
}

const capitalize = (
  string?: string,
  { lowercaseRest = false, locale = EN, ignorePunctuation = false }: CapitalizeOptions = {}
) => {
  if (string == null || isEmptyString(string)) {
    return '';
  }

  let firstCharIndex = 0;
  if (ignorePunctuation) {
    // the first index must instead be the first non-punctuation character
    // this is complicated by the existence of non-ASCII characters such as ¡ or ¿

    // Searching via regex unicode property values. \P = negation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
    // General Category Property reference:
    // https://unicode.org/reports/tr18/#General_Category_Property
    firstCharIndex = string.search(/\P{Punctuation}/u);
    if (firstCharIndex < 0) {
      // If we don't find any non-punctuation characters, just default to first char
      firstCharIndex = 0;
    }
  }

  const preFirstChar = string.slice(0, firstCharIndex);
  const firstChar = string.slice(firstCharIndex, firstCharIndex + 1);
  const theRest = string.slice(firstCharIndex + 1);

  const toUpper = (str: string) =>
    locale == null ? str.toUpperCase() : str.toLocaleUpperCase(locale);
  const toLower = (str: string) =>
    locale == null ? str.toLowerCase() : str.toLocaleLowerCase(locale);

  return preFirstChar + toUpper(firstChar) + (lowercaseRest ? toLower(theRest) : theRest);
};

interface CapitalCaseOptions {
  locale?: CapitalizeOptions['locale'];
  ignorePunctuation?: CapitalizeOptions['ignorePunctuation'];
}

const capitalCase = (
  sentence: any,
  { locale = EN, ignorePunctuation }: CapitalCaseOptions = {}
) => {
  const sentenceStr = typeof sentence === 'string' ? sentence : '';
  const lowerCaseStr = sentenceStr.toLocaleLowerCase(locale);

  const whitespaceCapitalCaseStr = lowerCaseStr
    .split(/\s+/)
    .map((word) => capitalize(word, { locale, ignorePunctuation }))
    .join(' ');

  return whitespaceCapitalCaseStr;
};

/**
 * The primary use-case of this function is capitalizing phrases like `'self-pay'` to `'Self-Pay'`
 */
const capitalCaseAcrossPunctuation = (
  sentence: string,
  { locale }: Omit<CapitalCaseOptions, 'ignorePunctuation'>
) => {
  const whitespaceCapitalCaseStr = capitalCase(sentence, { locale, ignorePunctuation: true });

  const punctuationCapitalCaseStr = whitespaceCapitalCaseStr.replace(
    /(\p{Punctuation}+)(\P{Punctuation}+)/gu,
    (
      matchingStr: string,
      punctuationSequence: string,
      nonPunctuationSequence: string,
      offset: number,
      entireStr: string
    ) =>
      punctuationSequence + capitalize(nonPunctuationSequence, { locale, ignorePunctuation: true })
  );

  return punctuationCapitalCaseStr;
};

/**
 * Is the string made up of only numbers.
 *
 * @param {string} string
 * @returns {boolean}
 */
const isNumericString = (string: any) => /^\d+$/.test(string);

const formObjectToSlackPost = (form: any) => {
  const fields = [];

  for (const key of Object.keys(form)) {
    fields.push(`*${capitalize(key)}:* ${form[key]}`);
  }

  return `${fields.join('\n')}`;
};

const splitFullName = (name: any) => {
  const splitName = { first: null, last: null };

  const indexOfFirstSpace = name.indexOf(' ');

  if (indexOfFirstSpace !== -1) {
    splitName.first = name.slice(0, indexOfFirstSpace);
    splitName.last = name.slice(indexOfFirstSpace + 1);
  } else {
    splitName.first = name;
  }

  return splitName;
};

const toTitleCase = (input: any) => {
  if (isEmptyString(input)) {
    return null;
  }

  const str = input.toString();
  return str.replace(
    /\w\S*/g,
    (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

const toUpperCase = (string: string, locale?: SupportedLocale) =>
  locale == null ? string.toUpperCase() : string.toLocaleUpperCase(locale);

const toLowerCase = (string: string, locale?: SupportedLocale) =>
  locale == null ? string.toLowerCase() : string.toLocaleLowerCase(locale);

const isValidZipCode = (string: any) => string && isNumericString(string) && string.length === 5;

const firstNCharacters = (string: any, n: any, addPeriod = false) =>
  `${string.slice(0, n)}${addPeriod ? '.' : ''}`;

const formatPhone = (phoneString: any) => {
  if (!phoneString) {
    return '';
  }

  const cleaned = phoneString.replace(/\D/g, '');
  const matches = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);

  if (!matches) {
    return null;
  }

  const formatted = `(${matches[1]})\u00a0${matches[2]}\u2011${matches[3]}`;
  // \u00a0 = non breaking space
  // \u2011 = non breaking hyphen
  // so the phone number will not be word wrapped
  return formatted;
};

const sanitizeKebab = (input: any) => input.replace(/-/g, ' ');

const EMPTY_STRING = '';

const STRING_TRUE = 'true';
const STRING_FALSE = 'false';
const STRING_BOOLEANS = [STRING_TRUE, STRING_FALSE];

const toHashCode = (str: any) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return hash;
};

const comparator = (a: any, b: any) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};

const fromSnakeCaseToSpaces = (str: any) => str.split('_').join(' ');

const isString = (str: any) => typeof str === 'string' || str instanceof String;

const removeParentheses = (str: any) => str && String(str).replace(/[()]/g, '');

const isVowel = (letter: any) => ['a', 'e', 'i', 'o', 'u'].includes(letter && letter.toLowerCase());

const addAorAnToNoun = (noun: any) => (isVowel(noun && noun[0]) ? `an ${noun}` : `a ${noun}`);

const fromKebabToCamel = (str: any) =>
  str
    .split('-')
    .reduce((acc: any, cur: any, idx: any) => `${acc}${idx === 0 ? cur : capitalize(cur)}`);

const sentenceToKebab = (str: any) => str.toLowerCase().split(' ').join('-');

const replaceCharactersBeginningAtIndex = (
  string: any,
  replacementString: any,
  replacementStartingIndex: any
) => {
  const leadingString = string.substring(0, replacementStartingIndex);
  const trailingString = string.substring(leadingString.length + replacementString.length);

  return leadingString + replacementString + trailingString;
};

const isSolvIdHash = (str: any) => /^[a-z0-9]{6,}$/i.test(str);

const equalsIgnoreCase = (
  str1: string | null | undefined,
  str2: string | null | undefined,
  { locale = EN }: { locale: SupportedLocale }
) => {
  const string1 = str1 ?? '';
  const string2 = str2 ?? '';
  return string1.toLocaleLowerCase(locale) === string2.toLocaleLowerCase(locale);
};

/**
 * Levenshtein Ratio - calculates levenshtein ratio between two strings.
 * For all i and j, distance[i,j] will contain the Levenshtein
 * distance between the first i characters of s and the
 * first j characters of t
 */
export function levenshteinRatio(s: string, t: string) {
  // Initialize matrix of zeros
  const rows = s.length + 1;
  const cols = t.length + 1;
  const distance = matrix(rows, cols);

  // Populate matrix of zeros with the indeces of each character of both strings
  for (let i = 1; i < rows; i++) {
    for (let k = 1; k < cols; k++) {
      distance[i][0] = i;
      distance[0][k] = k;
    }
  }

  // Iterate over the matrix to compute the cost of deletions,insertions and/or substitutions
  for (let col = 1; col < cols; col++) {
    for (let row = 1; row < rows; row++) {
      // If the characters are the same in the two strings in a given position [i,j] then the cost is 0
      const cost = s[row - 1] === t[col - 1] ? 0 : 2;
      distance[row][col] = Math.min(
        distance[row - 1][col] + 1, // Cost of deletions
        distance[row][col - 1] + 1, // Cost of insertions
        distance[row - 1][col - 1] + cost // Cost of substitutions
      );
    }
  }

  // Find the Levenshtein Distance Ratio
  const ratio = (s.length + t.length - distance[rows - 1][cols - 1]) / (s.length + t.length);

  return ratio;
}

/**
 * Strict Subset - determines if s is a substring of t.
 */
export function isStrictSubset(s: string, t: string) {
  return t.includes(s);
}

/**
 * Match a string by Levenschtein Ratio
 */
export function matchFuzzy(value: string, options: string[], minimumRatio = 0) {
  // Flatten formatting
  const valueLower = value.toLowerCase().replace(/\W/g, '');
  // Create a new array for sorting
  return (
    [...options]
      // Flatten formatting
      // Create distances for sorting
      .map<[string, number]>((option) => [
        option,
        levenshteinRatio(valueLower, option.toLowerCase().replace(/\W/g, '')),
      ])
      .filter((option) => option[1] > minimumRatio)
      // Sort on distances
      .sort(([_a, distanceA], [_b, distanceB]) => {
        return distanceB - distanceA;
      })
      // Extract option values
      .map(([option]) => option)
  );
}

/**
 * Match a string by strict substring
 */
export function matchStrictSubset(value: string, options: string[], minimumRatio = 0) {
  // Flatten formatting
  const valueLower = value.toLowerCase().replace(/\W/g, '');
  // Create a new array for sorting
  return (
    [...options]
      // Flatten formatting
      // Create distances for sorting
      .map<[string, boolean]>((option) => [
        option,
        isStrictSubset(valueLower, option.toLowerCase().replace(/\W/g, '')),
      ])
      .filter((option) => option[1])
      // Extract option values
      .map(([option]) => option)
  );
}

/**
 *
 * @param string input string (expect a base 10 number in string)
 * @returns an integer (if input is invalid it will return 0)
 */
export function parseIntOrZero(string?: string) {
  return (string && parseInt(string, 10)) || 0;
}

const roundLatOrLng = (latOrLng: string | number) => parseFloat(latOrLng as string).toFixed(2);

/**
 * Converts a string in to "Sentence case", where the first word is captialized
 * and every subsequent word is fully lowercase.
 * @param str The string to transform
 * @returns The sentence-cased input */
const toSentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

const genderToPronoun = (gender?: string) => {
  if (gender?.toLowerCase() === 'male') return 'he';
  if (gender?.toLowerCase() === 'female') return 'she';
  return 'they';
};

const genderToPossessivePronoun = (gender: string) => {
  if (gender.toLowerCase() === 'male') return 'his';
  if (gender.toLowerCase() === 'female') return 'hers';
  return 'theirs';
};

const genderToPossessiveDeterminer = (gender: string) => {
  if (gender.toLowerCase() === 'male') return 'his';
  if (gender.toLowerCase() === 'female') return 'her';
  return 'their';
};

export {
  roundLatOrLng,
  comparator,
  isString,
  trimTo,
  setCharAt,
  queryStringFromObject,
  isEmptyString,
  leftPad,
  numberWithCommas,
  formatPrice,
  trimZeroCents,
  formatPercentage,
  pluralize,
  arrayToEnglish,
  objectToEnglish,
  stripNonNumeric,
  stripNonAlphanumeric,
  getNameInitialsFromFullName,
  capitalize,
  capitalCase,
  capitalCaseAcrossPunctuation,
  isNumericString,
  formObjectToSlackPost,
  splitFullName,
  camelToSnake,
  isValidZipCode,
  toTitleCase,
  firstNCharacters,
  toUpperCase,
  toLowerCase,
  formatPhone,
  sanitizeKebab,
  EMPTY_STRING,
  STRING_TRUE,
  STRING_FALSE,
  STRING_BOOLEANS,
  toHashCode,
  fromSnakeCaseToSpaces,
  removeParentheses,
  isVowel,
  addAorAnToNoun,
  fromKebabToCamel,
  sentenceToKebab,
  replaceCharactersBeginningAtIndex,
  isSolvIdHash,
  equalsIgnoreCase,
  toSentenceCase,
  genderToPronoun,
  genderToPossessivePronoun,
  genderToPossessiveDeterminer,
};
