/* eslint-disable max-classes-per-file */

export const APP_API_FAILURE = 'App - API failure';
export const APP_API_SUCCESS = 'App - API success';

export enum AppApiNames {
  CREATE_NEW_BOOKING = 'Create new booking',
}

export const LAUNCH_DARKLY_TRACKED_FLAG = 'Launch Darkly - Tracked flag';

export const IP_GEOLOCATION_RESULTS_RECEIVED = 'IP address geolocation results received';

export const SYMPTOM_TOUCH_REASON = 'Symptom - Touch reason';
export const SYMPTOM_GET_911_MESSAGE = 'Symptom - Get 911 message';
export const ONE_CLICK_BOOK_TOUCH_CHANGE_PROFILE = 'One click Book - Touch change profile';

export const NUMERIC_KEYPRESS_USED_FOR_MONTH_INPUT = 'numeric keypress used for month input';

export const ADD_INSURANCE_DENTIST = 'Add insurance - touch dental carrier dropdown';

export const PROFILES_TOUCH_ADD_INSURANCE = 'Profiles - Touch add insurance';
export const PROFILES_TOUCH_REMOVE_INSURANCE = 'Profiles - Touch remove insurance';
export const USER_PROFILE_PAST_APPOINTMENT_CARD = 'User profile past appointment card';

export const ADD_NEW_FAMILY_MEMBER_FORM_SUBMIT = 'Add family member - click next';

export const FIND_CARE_TAP_LOCATION_CARD = 'Find Care - click book again card';
export const FIND_CARE_SEARCH_OTHER_OPTIONS = 'Find Care - search other options';

export const PROFILES_MY_VISITS_TOUCH_BOOK_AGAIN = 'Profiles My Visits - Touch book again';
export const PROFILES_MY_VISITS_TOUCH_COST_ESTIMATE = 'Profiles My Visits - Touch cost estimate';

export const ADD_PROFILE_SUBMIT = 'Add Profile - Submit';
export const ADD_PROFILE_CANCEL = 'Add Profile - Cancel';

export const ADD_FAMILY_MEMBER_SUBMIT = 'Add Family Member - Submit';

export const PROFILE_REFRESH_INSURANCE = 'Profile - Refresh insurance';
export const PROFILE_EDIT_INSURANCE = 'Profile - Edit insurance';

export const CDP_LOADED_CDP = 'CDP - loaded clinic detail page';
export const CDP_START_BOOKING_FLOW = 'CDP - start booking flow';
export const CDP_TOUCH_NEARBY_CLINIC = 'CDP - navigating to nearby clinic';
export const CDP_HEADER_NAVIGATION = 'CDP - Header Navigation';
export const CDP_TOUCH_CLINIC_PHONE_NUMBER = 'CDP - opening telephone href link';
export const CDP_TOUCH_CLICK_TO_CALL_NEARBY_FIND_MORE =
  'CDP - Click to call - click find more link';
export const CDP_TOUCH_ADDITIONAL_LOCATION = 'CDP - navigating to additional location';
export const CDP_TOUCH_CLAIM_THIS_CLINIC = 'CDP - Claim clinic';
export const CDP_TOUCH_REPORT_INACCURATE_INFO = 'CDP - Report inaccurate info';
export const CDP_TOUCH_FIND_NEARBY_CLINICS = 'CDP - opening find nearby clinics modal';
export const CDP_TOUCH_PRIMARY_ACTION = 'CDP - touched call for visit or book online button';
export const CDP_TOUCH_SERVICES = 'CDP - Services click';
export const CDP_TOUCH_SHOW_MORE_SERVICES = 'CDP - Services show more services';
export const CDP_CDP_PHOTO_CAROUSEL = 'CDP - CDP photo carousel';
export const CDP_PHOTO_CAROUSEL_INTERACTION = 'CDP - photo carousel interaction';
export const CLINIC_DETAIL_PAGE_BREADCRUMB_CLICK = 'CDP - Click on Breadcrumb';
export const CLINIC_DETAIL_PAGE_PHONE_NUMBER_CLICK = 'CDP - Click on phone number';
export const CLINIC_DETAIL_PAGE_SEE_ACCEPTED_INSURANCE_PLANS = 'CDP - See accepted insurance plans';
export const CLINIC_DETAIL_PAGE_GET_DIRECTIONS_CLICK = 'CDP - Click get directions';
export const CLINIC_DETAIL_PAGE_SHOW_BUSINESS_HOURS = 'CDP - Show business hours';
export const CLINIC_DETAIL_PAGE_VIEW_OTHER_SERVICES = 'CDP - View Other Services';
export const CLINIC_DETAIL_PAGE_NO_SLOTS_SHOW_ADDITIONAL_LOCATIONS =
  'CDP - No Slots Today: additional locations shown';
export const LOCATION_SET_BY_USER = 'Location - Set by user';
export const APPOINTMENT_TIME_SET_BY_USER = 'Appointment time - set by user';
export const PATIENT_TYPE_SET_BY_USER = 'Patient type - set by user';

export const PROVIDER_DETAIL_PAGE_BREADCRUMB_CLICK = 'PDP - Click on Breadcrumb';
export const LOCATION_DETAIL_PAGE_BREADCRUMB_CLICK = 'LDP - Click on Breadcrumb';

export const SIGN_UP_LOGIN_CONTROLLER = 'Sign Up - login controller mounted';
export const SIGN_UP_SWITCH_ACTION = 'Sign up - switch modal action';
export const SIGN_UP_CREATE_ACCOUNT = 'Sign up - account created';
export const SIGN_UP_ACCOUNT_AFTER_REVIEW = 'Sign up - account appended to review';
export const SIGN_UP_DIRECT = 'Sign up - direct account creation';
export const SIGN_UP_PATCH = 'Sign up - patch account creation';
export const SUBMIT_BOOKING_END = 'Submit Booking - pushing to /pass';

export enum FluShotDriverModalEvents {
  LOADED = 'Flu shot driver modal - loaded',
  BOOK_NOW_CLICKED = 'Flu shot driver modal - book now clicked',
  DISMISS_CLICKED = 'Flu shot driver modal - dismiss clicked',
}

export const DOWNLOAD_DRIVER_IMPRESSION = 'Download driver - cta impression shown';
export const DOWNLOAD_DRIVER_CLICK_LINK = 'Download driver - click campaign link';
export const DOWNLOAD_DRIVER_DISMISS = 'Download driver - dismiss cta';

export const CCPA_CONSENT_BANNER_POLICY_LINK = 'CCPA consent banner - click policy link';

export const DOWNLOAD_DRIVER_CAROUSEL = 'Download driver carousel';
export const CTA_APP_CLICKED = 'cta app clicked';
export const CTA_DISMISS_CLICKED = 'cta dismiss clicked';
export const LOADED = 'loaded';

export const CONTACT_US_NAVIGATION = 'Contact Us - Navigation';

export const campaignNames = {
  LOGGED_OUT_MENU: 'logged-out-menu',
  SMS_LINK_SENT: 'sms-link-sent',
  DESKTOP_FOOTER: 'desktop-solv-footer',
  HOMEPAGE_HOW_IT_WORKS: 'homepage-how-it-works',
  HOMEPAGE_MOBILE: 'homepage-solv-mobile',
  NON_PARTNER_CDP: 'non-partner-cdp',
  IN_ACCOUNT_LINK: 'in-account-banner',
  ACCOUNT_BOTTOM_CAROUSEL: 'account-bottom-carousel',
  ACCOUNT_DRAWER: 'account-drawer',
  DEFAULT_CAMPAIGN: 'unknown-solv-link',
  WAIT_LIST_BELOW_ACCOUNT: 'wait-list-below-account-cta',
  CONFIRMATION_COVID_ONLY: 'cofirmation-page-covid-only',
  CONFIRMATION: 'confirmation-banner',
  REBOOKING: 'rebooking-page',
  END_OF_REVIEW: 'end-of-reivew',
  END_OF_PAPERWORK: 'end-of-paperwork',
  END_OF_CONTACTLESS: 'end-of-contactless',
  LAB_RESULTS: 'lab-results',
  WAITLIST: 'waitlist',
};

export const PHONE_LOGIN_SUBMIT = 'Login - Submit phone';
export const PHONE_LOGIN_SUBMIT_ERROR = 'Login - Submit phone error';
export const PHONE_LOGIN_NUMBER_NOT_FOUND = 'Login - Account not found';
export const PHONE_LOGIN_NUMBER_NOT_FOUND_CREATE_ACCOUNT =
  'Login - Account not found, creating a new one';
export const PHONE_LOGIN_LAST_NAME_DOES_NOT_MATCH = 'Last name does not match our records';

export const PHONE_VERIFICATION_REQUIRED = 'Login - Phone verification required';
export const PHONE_VERIFICATION_NOT_REQUIRED = 'Login - Phone verification not required';
export const PHONE_VERIFICATION_LAST_NAME_UNVERIFIABLE =
  'Login - Phone verification last name unverifiable';
export const PHONE_VERIFICATION_FAILED = 'Login - Phone verification failed';
export const ACCOUNT_FROZEN = 'Login - Account frozen';
export const OTP_LOGIN_FIELD_FOCUS = 'OTP - Focus on field';
export const OTP_RESEND_SMS = 'OTP - Get another code by text';
export const OTP_RESEND_VOICE_CALL = 'OTP - Get another code by phone call';
export const OTP_LOGIN_SUBMIT = 'OTP - Login submit';
export const OTP_LOGIN_SUBMIT_ERROR = 'OTP - Login error';
export const OTP_LOGIN_WRONG_CODE = 'OTP - Login wrong code entered';
export const OTP_LOGIN_MAX_FAIL = 'OTP - Max limit exceeded login wrong code';
export const OTP_LOGIN_SUCCESS = 'OTP - Success';
export const OTP_WRONG_NUMBER = 'OTP - Wrong number';
export const OTP_CONTINUE_WITHOUT_LOGIN = 'OTP - Continue without login';
export const LOGIN_PAGE_LOAD = 'Login - load first page for phone number input';
export const SIGN_UP_KEEP_ME_LOGGED_IN = 'Sign up - keep me logged in';
export const LOGIN_PERSISTED = 'Login - persisted';
export const LOGIN_CLICK_HEADER = 'Login - click header';

export const LOGIN_BOOKING_ID_MISMATCH = 'Login - Booking ID mismatch';

export const LOGIN = 'Login - Login attempt';
export const LOGIN_STATUS_SUCESS = 'success';
export const LOGIN_STATUS_ERROR = 'error';

export const CHANGE_LOCATION_SET_LOCATION_PERMISSION = 'Change Location - Set location permission';
export const SELECT_TIME_SELECT_TIME = 'Select Time - Select new time';

export const INSURANCE_FILE_UPLOAD = 'Insurance - uploaded file';
export const INSURANCE_ACUANT_SCAN_COMPLETE = 'Insurance - acuant has finished scanning';
export const INSURANCE_ACUANT_FAILED = 'Insurance - acuant failure';
export const INSURANCE_TOUCH_NEXT_INSURANCE_COMPLETED =
  'Insurance - Touch Next Insurance completed';
export const INSURANCE_FORM_VALIDATION_ERROR = 'Insurance - Form validation error';
export const OVERRIDE_INSURANCE_ERROR =
  "Insurance - Dapi response overwritten, they're clear to go";
export const INSURANCE_OPEN_ERROR_DIALOG = 'Insurance - Open error dialog';
export const INSURANCE_CLOSE_ERROR_DIALOG = 'Insurance - Close error dialog';
export const INSURANCE_CLICK_CLOSE_CTA_ERROR_DIALOG = 'Insurance - Click close cta error dialog';
export const INSURANCE_CLICK_CONTINUE_CTA_ERROR_DIALOG =
  'Insurance - Click continue cta error dialog';

export const PHOTO_ID_UPLOAD = 'Photo ID Upload';
export const PHOTO_ID_SKIP_UPLOAD = 'Skip card upload';
export const PHOTO_ID_PAGE_LOADED = 'Page loaded';

export const INSURANCE_SWIMLANE_COPAY_COINSURANCE_TAPPED =
  'Insurance copay and coinsurance swimlane tapped';
export const INSURANCE_SWIMLANE_COPAY_COINSURANCE_FINDCARE_TAPPED =
  'Insurance copay and coinsurance Find Care tapped';
export const INSURANCE_SWIMLANE_DEDUCTIBLE_TAPPED = 'Insurance deductible swimlane tapped';
export const INSURANCE_SWIMLANE_OUT_OF_POCKET_MAX_TAPPED =
  'Insurance out of pocket max swimlane tapped';
export const INSURANCE_SWIMLANE_PREVENTATIVE_PERKS_TAPPED =
  'Insurance preventative perks swimlane tapped';
export const INSURANCE_COPAY_COINSURANCE_DETAILS_MODAL_OPENED =
  'Insurance copay and coinsurance details modal';

export const BOOKING_WIDGET_TOUCH_FIRST_NAME = 'Booking Widget - Touch First Name';
export const BOOKING_WIDGET_TOUCH_LAST_NAME = 'Booking Widget- Touch Last Name';
export const BOOKING_WIDGET_TOUCH_DOB = 'Booking Widget- Touch DOB';
export const BOOKING_WIDGET_TOUCH_EMAIL = 'Booking Widget- Touch Email';
export const BOOKING_WIDGET_TOUCH_PHONE = 'Booking Widget - Touch Phone';
export const BOOKING_WIDGET_BOOK_FAIL = 'BookingWidget Book - Form submission error';
export const BOOKING_WIDGET_TOUCH_GEOSUGGEST = 'Booking Widget - Touch geosuggest input';
export const BOOKING_WIDGET_SELECT_GEOSUGGEST = 'Booking Widget - Select geosuggest item';
export const BOOKING_WIDGET_TOUCH_ADDRESS = 'Booking Widget - Touch address';
export const BOOKING_WIDGET_TOUCH_ADDRESS_SECONDARY = 'Booking Widget - Touch address secondary';
export const BOOKING_WIDGET_TOUCH_CITY = 'Booking Widget - Touch city';
export const BOOKING_WIDGET_TOUCH_STATE = 'Booking Widget - Touch state';
export const BOOKING_WIDGET_TOUCH_ZIP_CODE = 'Booking Widget - Touch zip code';
export const CANCELLATION_FAIL = 'BookingWidget Book - Cancel Form submission error';
export const RESCHEDULE_FAIL = 'Reschedule Form submission error';
export const BOOKING_WIDGET_TOUCH_LOGIN_RETURNING_PATIENT =
  'Booking Widget - Touch Log in returning patient';
export const BOOKING_WIDGET_TOUCH_PATIENT_SEX = 'Booking Widget - Touch Patient Sex';
export const BOOKING_WIDGET_DESKTOP_CLICK_CONTINUE_BUTTON =
  'Booking Widget - Desktop click continue button';
export const BOOKING_WIDGET_MOBILE_CLICK_CONTINUE_BUTTON_FIRST_FORM =
  'Booking Widget - Mobile click continue button first form';
export const BOOKING_WIDGET_MOBILE_CLICK_CONTINUE_BUTTON_SECOND_FORM =
  'Booking Widget - Mobile click continue button second form';

export const MULTI_LOCATION_WIDGET_PAGE_LOAD = 'MultiLocation Widget - Page Load';
export const MULTI_LOCATION_WIDGET_TOUCH_BOOK = 'MultiLocation Widget - Touch Book';

export const PRESCREENER_ELIGIBLE_FOR_VACCINE = 'Prescreener - Eligible for vaccine';
export const PRESCREENER_NOT_ELIGIBLE_FOR_VACCINE = 'Prescreener - Not eligible for vaccine';

export const PAPERWORK_PAYMENT_SELECT_USE_SAVED_INSURANCE =
  'Paperwork - Payments select use saved insurance';
export const PAPERWORK_PAYMENT_SELECT_ADD_NEW_INSURANCE =
  'Paperwork - Payments select add new insurance';
export const PAPERWORK_ERROR_LOADING_LOCATION_CONSENT_FORMS =
  'Paperwork - Error loading location consent forms';
export const PAPERWORK_ERROR_LOADING_LOCATION_PAPERWORK_FIELDS =
  'Paperwork - Error loading location paperwork fields';

export const PHYSICAL_LOCATIONS_ERROR_LOADING =
  'Physical Locations - Error loading physical locations';

export const ACCOUNT_INFO_FORM_SUBMIT = 'Account info - Click next';
export const ACCOUNT_INFO_TOUCH_EDIT_EMAIL = 'Account info - Touch edit email';
export const ACCOUNT_INFO_TOUCH_SAVE_EDITS = 'Account info - Touch save edits';
export const ACCOUNT_INFO_TOUCH_EDIT_WEB_PUSH = 'Account info - Touch edit web push preference';
export const ACCOUNT_INFO_TOUCH_CANCEL = 'Account Info - Touch cancel';

export const REVIEW_BOOKING_TOUCH_FINALIZE = 'Review booking - Touch finalize';
export const REVIEW_BOOKING_API_NEW_BOOKING = 'Review booking - API New booking';
export const REVIEW_BOOKING_API_NEW_IN_PERSON_BOOKING =
  'Review booking - API New in person booking';
export const REVIEW_BOOKING_API_NEW_TELEMED_BOOKING = 'Review booking - API New telemed booking';
export const REVIEW_BOOKING_SELF_PAY_TOOLTIP = 'Review Booking - self pay tooltip';
export const REVIEW_BOOKING_API_NEW_FAMILY_BOOKING = 'Review booking - API New family booking';

export const ACCOUNT_INSURANCE_PROFILE = 'Account - insurance profile';

export const ACCOUNT_INSURANCE_USER_ERROR_BANNER = 'Account - Insurance user error banner ';

export const VERIFY_PROFILE_VIEW_VERIFICATION_SCREEN = 'Verify Profile - View verification screen';
export const VERIFY_PROFILE_VERIFICATION_FAILED = 'Verify Profile - Verification failed';
export const VERIFY_PROFILE_VERIFICATION_SUCCESS = 'Verify Profile - Verification Success';

export const CONFIRMATION_TOUCH_CREATE_ACCOUNT = 'Confirmation - Touch Create Account';
export const CONFIRMATION_TOUCH_LOGIN = 'Confirmation - Touch Login';
export const CONFIRMATION_TOUCH_ADD_TO_CALENDAR = 'Confirmation - Touch add to calendar';
export const CONFIRMATION_TOUCH_ADD_TO_CALENDAR_ADDED = 'Confirmation - Added to calendar';
export const CONFIRMATION_TOUCH_RESCHEDULE = 'Confirmation - Touch reschedule';
export const CONFIRMATION_TOUCH_FILL_OUT_PAPERWORK = 'Confirmation - Touch fill out paperwork';
export const CONFIRMATION_TOUCH_ADD_PAYMENT_METHOD = 'Confirmation - Touch add payment method';
export const CONFIRMATION_TOUCH_VIEW_WAITLIST = 'Confirmation - Touch view waitlist';
export const CONFIRMATION_TOUCH_JOIN_VIDEO_VISIT = 'Confirmation - Touch join video visit';
export const CONFIRMATION_TOUCH_ADD_PREFERRED_PHARMACY = 'Confirmation - Add preferred pharmacy';
export const CONFIRMATION_TOUCH_ONLINE_CHECK_IN = 'Confirmation - Touch online check-in';
export const CONFIRMATION_REQUEST_UBER = 'Confirmation - Request Uber';
export const REQUEST_UBER_FAIL = 'Confirmation - Request Uber Fail';
export const CONFIRMATION_GET_DIRECTIONS = 'Confirmation - Get directions';
export const CONFIRMATION_TOUCH_SOLV_LOGO = 'Confirmation - Touch Solv logo';
export const CONFIRMATION_TOUCH_FAMILY_BOOKING_CTA = 'Confirmation - Touch family booking cta';

export const CONFIRMATION_NEXT_STEPS_PAPERWORK_CTA_LOADED =
  'Confirmation - Next steps paperwork cta loaded';
export const CONFIRMATION_NEXT_STEPS_PAYMENT_CTA_LOADED =
  'Confirmation - Next steps payment cta loaded';
export const CONFIRMATION_NEXT_STEPS_ONLINE_CHECK_IN_CTA_LOADED =
  'Confirmation - Next steps online check-in cta loaded';
export const CONFIRMATION_NEXT_STEPS_WAITLIST_CTA_LOADED =
  'Confirmation - Next steps waitlist cta loaded';
export const CONFIRMATION_NEXT_STEPS_JOIN_VIDEO_VISIT_CTA_LOADED =
  'Confirmation - Next steps join video visit cta loaded';
export const CONFIRMATION_NEXT_STEPS_FRONT_DESK_CHECK_IN_CTA_LOADED =
  'Confirmation - Next steps front desk check-in cta loaded';
export const CONFIRMATION_FAMILY_BOOKING_CTA_LOADED = 'Confirmation - Family booking cta loaded';
export const CONFIRMATION_NEXT_STEPS_HOME_HEALTHCARE_VISIT_CONFIRMATION =
  'Confirmation - Next steps home healthcare visit confirmation cta loaded';

// Valid actions are 'Load Modal', 'Tap Agree', and 'Tap Cancel'
export const TOS_MODAL = 'TOS Modal';

export const CROSSED_PINK_DOOR = 'Crossed Pink Door';

export const CROSSED_PINK_DOOR_TYPE_ACCEPTED_TOS_LOGIN = 'accepted tos login';
export const CROSSED_PINK_DOOR_TYPE_ACCEPTED_TOS_ACCOUNT = 'accepted tos account';
export const CROSSED_PINK_DOOR_TYPE_CONSUMER_APP_BOOKING = 'consumer app booking';
export const CROSSED_PINK_DOOR_TYPE_SIGNUP = 'signup';

export const BOOKING_WIDGET_TOUCH_CONSENT = 'Booking Widget - Touch consent';
export const BOOKING_WIDGET_TOUCH_TELEMED_CONSENT = 'Booking Widget - Touch telemed consent';
export const BOOKING_WIDGET_ELECTRONIC_COMMUNICATION_CONSENT_FIELD_NAME =
  'Booking Widget - Touch electronic communication consent';

export const BOOKING_WIDGET_TELEMED_MODAL_IN_PERSON_REDIRECT =
  'Booking Widget Telemed in person redirect';
export const ACTION_CLICK_CALL_CLINIC_MODAL = 'Action click call clinic';
export const ACTION_CLICK_BOOK_IN_PERSON_MODAL = 'Action click book in person';
export const ACTION_CLICK_CONTINUE_BOOK_TELEMED_MODAL = 'Action click to continue to book telemed';
export const ACTION_OPEN_MODAL = 'Action open modal in telemed booking';

export const WAIT_LIST_CALL_TO_ACTION_CLICK = 'Waitlist - Activate Account Call To Action Click';
export const WAIT_LIST_DELAYS_FOOTER_DISPLAYED = 'Waitlist - delays footer displayed';
export const WAIT_LIST_DELAYS_MODAL_OPEN = 'Waitlist - delays modal open';
export const WAIT_LIST_DELAYS_MODAL_CLOSE = 'Waitlist - delays modal close';
export const WAIT_LIST_EXPANDED = 'Wait list - expanded';
export const WAIT_LIST_LIST_LOADED = 'Wait list - list loaded';
export const WAIT_LIST_PAGE_LOAD = 'Wait list page load';
export const WAIT_LIST_WELCOME_TEST = 'Wait list - Welcome flow test 3/22/18';

export const ADD_TO_HOME_SCREEN = 'Add to Home Screen';

export const BURGER_MENU_CLOSE = 'Burger menu modal - Close burger menu';
export const BURGER_MENU_OPEN = 'Burger menu modal - Open burger menu';
export const BURGER_MENU_TOUCH_ABOUT = 'Burger menu modal - Touch burger menu about';
export const BURGER_MENU_TOUCH_BOOK = 'Burger menu modal - Touch burger menu book';
export const BURGER_MENU_TOUCH_HOME = 'Burger menu modal - Touch burger menu home';
export const BURGER_MENU_TOUCH_FOR_PROVIDERS =
  'Burger menu modal - Touch burger menu for providers';
export const BURGER_MENU_TOUCH_LOGIN = 'Burger menu modal - Touch burger menu login';
export const BURGER_MENU_TOUCH_LOGOUT = 'Burger menu modal - Touch burger menu logout';
export const BURGER_MENU_TOUCH_MY_ACCOUNT = 'Burger menu modal - Touch burger menu my account';
export const BURGER_MENU_TOUCH_SAVE_SOLV = 'Burger menu modal - Touch burger menu save solv';
export const BURGER_MENU_TOUCH_SETTINGS = 'Burger menu modal - Touch burger menu settings';
export const BURGER_MENU_TOUCH_HELP = 'Burger menu modal - Touch burger menu help';
export const BURGER_MENU_TOUCH_BLOG = 'Burger menu modal - Touch burger menu blog';
export const BURGER_MENU_TOUCH_SHARE_SOLV = 'Burger menu modal - Touch share solv';

export const HEADER_V2_CLICK_LOGIN = 'Header v2 - Click on Login';
export const HEADER_V2_CLICK_SIGNUP = 'Header v2 - Click on Sign Up';
export const HEADER_V2_CLICK_HELP = 'Header v2 - Click on Help';
export const HEADER_V2_TOGGLE_DRAWER = 'Header v2 - Toggle drawer';
export const HEADER_V2_CLICK_LOGO = 'Header v2 - Click Logo';

export const SMS_SHARE_OPENED_BY_RECIPIENT = 'Share Solv - opened by recipient';

export const SAVE_SOLV_POP_UP_TOUCH_CLOSE = 'Save Solv pop up - Touch close';

export const SETTINGS_UPDATED = 'Settings - Updated';

export const SMS_REVIEW_CLICK = 'SMS - review flow click';
export const SMS_MOBILE_CHECK_IN_CLICK = 'SMS - mobile check in click';

export const HOMEPAGE_VIDEO_VISIT_MODULE = 'HOMEPAGE - video visit module';
export const HOMEPAGE_LAB_TAB = 'HOMEPAGE - lab tab';
export const HOMEPAGE_FIND_CARE_TAB_CLICK = 'HOMEPAGE - switch tab in find care section';
export const HOMEPAGE_FIND_CARE_LINK_CLICK = 'HOMEPAGE - find care link click';
export const HOMEPAGE = 'HOMEPAGE';
export const DFW_HOMEPAGE = 'DFW_HOMEPAGE';
export const ACTION_CLICK_SIGN_UP_BUTTON = 'Click on sign up button';
export const ACTION_CLICK_PROVIDER_TYPE_TILE = 'Click on provider type tile';
export const ACTION_CLICK_SELF_PAY_SERVICE_TILE = 'Click on self-pay service tile';
export const ACTION_LOAD_PROVIDER_TYPE_TILES = 'Load provider type tiles';
export const ACTION_LOAD_SELF_PAY_SERVICE_TILES = 'Load self-pay service tiles';
export const ACTION_SEE_SELF_PAY_SERVICE_TILES = 'See self-pay service tiles';
export const ACTION_VIEW_MORE_SELF_PAY_SERVICES = 'View more self-pay services';
export const ACTION_VIEW_FEWER_SELF_PAY_SERVICES = 'View fewer self-pay services';
export const ACTION_CHANGE_SEARCH_TAB = 'Change search tab';
export const ACTION_HOW_IT_WORKS_SET_STEP = 'Set step in "How it works" section';
export const ACTION_CHANGE_TESTIMONIAL = 'Change which testimonial is being viewed';
export const ACTION_LEARN_MORE_ABOUT_SELF_PAY = 'Click on learn more in clear prices section';

export const PAPERWORK_LOG_IN_CLICK = 'PAPERWORK - click log in to prefill paperwork';
export const PAPERWORK_SIGN_UP_CLICK = 'PAPERWORK - click sign up';

export const PAPERWORK_START = 'PAPERWORK - Start';
export const PAPERWORK_NEXT_PAGE = 'PAPERWORK - Next page';
export const PAPERWORK_NEXT_SECTION = 'PAPERWORK - Next section';
export const PAPERWORK_JUMP_TO_SECTION = 'PAPERWORK - Jump to section';
export const PAPERWORK_COMPLETE_SECTION = 'PAPERWORK - Complete section';

export const OPEN_CLINIC_LEAD_FORM_MODAL_FOR_PROVIDERS =
  'Open clinic lead form modal - For Providers CTA';
export const CLINIC_LEAD_FORM_SUBMIT_FOR_PROVIDERS = 'Clinic lead form submit - For Providers';
export const OPEN_CLINIC_LEAD_FORM_MODAL_TELEMED = 'Open clinic lead form modal - Telemedicine CTA';

export const ADD_REVIEW_SUBMIT = 'Add review web flow: submit review';
export const ADD_REVIEW_LEAVE_REVIEW_ON_GOOGLE = 'Add review web flow: leave review on google';
export const ADD_REVIEW_NO_GOOGLE_ACCOUNT = 'Add review web flow: no google account link';
export const ADD_REVIEW_LEAVE_REVIEW_ON_SOLV =
  'Add review web flow: chose to leave a review on solv';
export const ADD_REVIEW_I_WOULD_NOT_RECOMMEND = 'Add review web flow: i would not recommend';
export const ADD_REVIEW_I_WOULD_RECOMMEND = 'Add review web flow: i would recommend';
export const ADD_REVIEW_PUBLISH_TO_CONSENT = 'Add review web flow: tap on checkbox to consent';

export const MOBILE_CHECK_IN = 'Mobile check in';

export const MOBILE_CHECK_IN_ALREADY_CHECKED_IN_REDIRECT =
  'Mobile check in - already checked in and redirect';
export const MOBILE_CHECK_IN_TAP_CHECK_IN_BUTTON = 'Mobile check in - tap check in button';
export const MOBILE_CHECK_IN_LOCATION_SERVICES_DISABLED =
  'Mobile check in - location services disabled';
export const MOBILE_CHECK_IN_FAR_AWAY_FROM_CLINIC = 'Mobile check in - far away from clinic';
export const MOBILE_CHECK_IN_FAR_AWAY_FROM_CLINIC_BUT_TAP_CHECK_IN_ANYWAY =
  'Mobile check in - far away from clinic but tap on check in button anyway';
export const MOBILE_CHECK_IN_FAR_AWAY_FROM_CLINIC_AND_CANCEL_CHECK_IN =
  'Mobile check in - far away from clinic and tap on cancel check in';
export const MOBILE_CHECK_IN_SUCCESS_LOCATION_AND_REDIRECT =
  'Mobile check in - success and redirect';
export const MOBILE_CHECK_IN_REDIRECT_TO_WAITLIST = 'Mobile check in - redirect to waitlist';

export const MOBILE_CHECK_IN_REDIRECT_TO_KIOSK_FROM_BROWSER =
  'Mobile check in - redirect to kiosk - from browser';
export const MOBILE_CHECK_IN_REDIRECT_TO_KIOSK_FROM_QR_CODE =
  'Mobile check in - redirect to kiosk - from QR code';

export const SMS_TWILIO_REDIRECT_TO_CONFIRMATION_PASS =
  'Sms twilio - redirect to confirmation pass';
export const SMS_PAYMENTS_REDIRECT_TO_INVOICE = 'Sms twilio - redirect to invoice payment';
export const EMAIL_PAYMENTS_REDIRECT_TO_INVOICE = 'Email - redirect to invoice payment';
export const BOOKING_CONFIRMATION_EMAIL_REDIRECT_TO_WAITLIST =
  'Booking confirmation email - redirect to waitlist';
export const BOOKING_CONFIRMATION_EMAIL_REDIRECT_TO_PROFILE_PAGE =
  'Booking confirmation email - redirect to profile page';
export const BOOKING_CONFIRMATION_EMAIL_REDIRECT_TO_LOGIN =
  'Booking confirmation email - redirect to login';
export const BOOKING_CONFIRMATION_EMAIL_REDIRECT_TO_LOGIN_ERROR =
  'Redirect - Error Booking confirmation email - redirect to login ';
export const BOOKING_CONFIRMATION_WALK_IN_EMAIL_REDIRECT_TO_WAITLIST =
  'Walk In booking confirmation email - redirect to waitlist';
export const BOOKING_CONFIRMATION_WALK_IN_EMAIL_REDIRECT_TO_PROFILE_PAGE =
  'Walk In booking confirmation email - redirect to profile page';
export const BOOKING_CONFIRMATION_WALK_IN_EMAIL_REDIRECT_TO_LOGIN =
  'Walk In booking confirmation email - redirect to login';
export const BOOKING_CONFIRMATION_WALK_IN_EMAIL_REDIRECT_TO_LOGIN_ERROR =
  'Redirect - Error Booking confirmation email - redirect to login ';

export const REDIRECT_TO_VIDEO_VISIT = 'Redirect - Video Visit';
export const REDIRECT_TO_PAPERWORK = 'Redirect - Paperwork';

export const CDP_BOOK_MODAL = 'CDP - Book Modal';
export const CDP_CLICK_LEAVE_REVIEW = 'CDP - click on leave a review CTA';
export const CDP_LEAVE_REVIEW_SUBMITTED = 'CDP - review submitted';
export const CDP_LEAVE_REVIEW_QUESTIONNAIRE = 'CDP - leave review questionnaire completed';
export const CDP_SHARE = 'CDP - Share';
export const CDP_CLICK_DIRECTION_ABOVE_THE_FOLD = 'CDP - Click on above the fold directions';
export const CDP_CLICK_BOOK_VISIT_MAP_SECTION = 'CDP - Click on book visit in the map section';
export const CDP_SHOW_MORE_DESCRIPTION = 'CDP - Show more description';
export const CDP_SHOW_LESS_DESCRIPTION = 'CDP - Show less description';
export const CDP_BOOK_MODULE_EVENT = 'CDP - Book Module';
export const CDP_COVID_TEST_SECTION = 'CDP - Covid Testing';
export const CDP_COVID_TEST_SECTION_BOOK = 'CDP - Book a covid test';
export const CDP_COVID_TEST_SECTION_ACCORDION_CLICK = 'CDP - Toggle covid test accordion';
export const CDP_BOOK_ONLINE_SELF_PAY = 'CDP - Book online - Self Pay Service';
export const CDP_SELF_PAY_ACCORDION_CLICK = 'CDP - Toggle self pay accordion';

export const CDP_LEARN_MORE_SELF_PAY_CLICK = 'CDP - Learn more about self pay prices';
export const CDP_SEE_ALL_SELF_PAY = 'CDP - See all self pay prices';
export const CDP_SCROLL_TO_CLEAR_PRICE = 'CDP - Scroll to Common Clear Price section';
export const CDP_CLICK_COVID_SERVICES_AVAILABLE = 'CDP - Click covid services available';
export const CDP_CLICK_VIDEO_VISIT = 'CDP - Click video visit';

// eslint-disable-next-line @typescript-eslint/naming-convention
export class CDP_COVID_TEST_ACTIONS {
  static CLICK_CALL_CLINIC = 'Click call clinic';

  static CLICK_BOOK_A_VISIT = 'Click book a visit';

  static CLICK_BOOK_A_VIDEO_VISIT = 'Click book a video visit';
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class CDP_BOOK_MODULE_ACTION {
  static CLICK_NEXT_AVAILABLE = 'Click next available';

  static CLICK_PICK_A_TIME = 'Click pick a time';

  static CLICK_OFFICE_VISIT = 'Click office visit';

  static CLICK_VIDEO_VISIT = 'Click video visit';

  static SLOT_RECEIVED_FROM_QUERY_PARAM = 'Slot selected by query param';

  static APPOINTMENT_DATE_QUERY_PARAM_OCCUPIED =
    'Tried to select appointment from query param but is not available';
}

export const BOOKING_FLOW_TRANSITION = 'Solv Booking Flow - Transition';
export const BOOKING_FLOW_NAVIGATION = 'Solv Booking Flow - Navigation';
export const BOOKING_FLOW_CREATE_PROFILE = 'Solv Booking Flow - Create Profile';

export const BOOKING_FLOW_STEP = {
  REASON_FOR_VISIT: 'Solv Booking Flow - Enter Reason for Visit',
  ENTER_PHONE_NUMBER: 'Solv Booking Flow - Enter Phone Number',
  ENTER_OTP: 'Solv Booking Flow - Enter OTP',
  SELECT_PATIENT: 'Solv Booking Flow - Select Patient',
  BOOKING_PROFILE: 'Solv Booking Flow - Booking Profile',
  INSURANCE: 'Solv Booking Flow - Insurance',
  FINALIZE: 'Solv Booking Flow - Finalize',
  HOME_ADDRESS: 'Solv Booking Flow - Home Address',
  PAYMENT_METHOD: 'Solv Booking Flow - Payment Method',
  PAYMENT_COLLECTION: 'Solv Booking Flow - Payment Collection',
  PHOTO_ID: 'Solv Booking Flow - Photo ID',
  SELECT_A_TIME: 'Solv Booking Flow - Select a Time',
} as const;

export const LOCATION_SAVE_TO_ACCOUNT = 'Saved locations - favoring location';
export const LOCATION_SAVE_BEFORE_LOGGED_IN =
  'Saved locations - saved a location without being logged in';
export const LOCATION_SAVE_AFTER_LOGIN =
  'Saved locations - saved a location automatically after login';

export const REDIRECT_TO_BOOKING_WIDGET = 'Redirect - booking widget';
export const REDIRECT_TO_WAITLIST = 'Redirect - waitlist';
export const REDIRECT_TO_WAITLIST_ERROR = 'Redirect error - waitlist';
export const REDIRECT_TO_CONFIRMATION_SHORTLINK_ERROR = 'Redirect error - confirmation /c route';
export const REDIRECT_TO_REMINDER = 'Redirect - reminder /r route';
export const REDIRECT_TO_ADD_CREDIT_CARD_SHORT_LINK = 'Redirect - add credit card /cc route';

export const TOKBOX_REQUIREMENTS_NOT_MET = 'Tokbox - Requirements not met';
export const TOKBOX_CLIENT_INFO = 'Tokbox - Client info';

export const PAYMENTS_CLICK_WHY_THIS_AMOUNT = 'Payments - click why this amount';
export const BOOK_VISIT_FOOTER_TRANSACTIONAL = 'Book visit footer transactional';
export const BOOK_VISIT_FOOTER_WRAPUP = 'Book visit footer wrapup';
export const BOOK_VISIT_BODY_WRAPUP = 'Book visit body wrapup';

export const REDIRECT_TO_CDP_ERROR =
  'Redirect - from booking confirmation or wrapup email to cdp error';

export const REDIRECT_TO_BOOKING_WIDGET_ERROR =
  'Redirect - from booking confirmation or wrapup email to booking widget error';

export const REDIRECT_TO_PVS_ERROR = 'Redirect - from email or sms to pvs error';

export const VIDEO_VISIT_UNSUPPORTED_BROWSER = 'Video Visit -- Unsupported Browser';
export const VIDEO_VISIT_TOUCH_REBOOK = 'Video visit = touch rebook';
export const VIDEO_VISIT = 'Video visit';
export const VIDEO_VISIT_ACTION_SUBMIT_CONNECTION_FEEDBACK =
  'Video visit - submit connection feedback';
export const REBOOKING_BOOK_VISIT = 'Rebooking - book visit';
export const REBOOKING_REVIEW_CLINIC = 'Rebooking - review clinic';

export const ADD_CARD_TOUCH_SAVE_PAYMENT_METHOD = 'Add Card - Touch save payment method';
export const ADD_CARD_DURING_BOOKING = 'Add card during booking';
export const ADD_CARD_DURING_BOOKING_SKIP = 'Add card during booking skip';

export const PRE_VISIT_PROMPT_SHOW_PAPERWORK_SCREEN = 'Pre visit prompt - show paperwork screen';
export const PRE_VISIT_PROMPT_SHOW_PAYMENT_SCREEN = 'Pre visit prompt - show payment screen';
export const PRE_VISIT_PROMPT_SHOW_PHOTO_ID_SCREEN = 'Pre visit prompt - show photo id screen';
export const PRE_VISIT_PROMPT_SHOW_ABOUT_TO_JOIN_SCREEN =
  'Pre visit prompt - show about to join screen';
export const PRE_VISIT_PROMPT_SHOW_THANK_YOU_SCREEN = 'Pre visit prompt - show thank you screen';

export const POST_VISIT_PROMPT_CLICK_SUBMIT_QUALITY_FEEDBACK =
  'Post visit prompt - submit quality feedback';

export const PRE_VISIT_PROMPT_CLICK_PAPERWORK = 'Pre visit prompt - click paperwork';
export const PRE_VISIT_PROMPT_CLICK_PHOTO_ID = 'Pre visit prompt - click photo id';
export const PRE_VISIT_PROMPT_CLICK_ADD_PAYMENT = 'Pre visit prompt - click add payment';
export const PRE_VISIT_PROMPT_CLICK_JOIN_CALL = 'Pre visit prompt - click join call';
export const PRE_VISIT_PROMPT_CLICK_PAPERWORK_SKIP = 'Pre visit prompt - click paperwork skip';
export const PRE_VISIT_PROMPT_CLICK_PHOTO_ID_SKIP = 'Pre visit prompt - click photo id skip';
export const PRE_VISIT_PROMPT_CLICK_PAYMENT_SKIP = 'Pre visit prompt - click payment skip';
export const PRE_VISIT_PROMPT_DATA_LOADING_ERROR = 'Pre visit prompt - data loading error';

export const ADD_YOUR_AVAILABILITY_PAGE_LOADED = 'Add Your Availability - Page Loaded';

export const POST_VISIT_SUMMARY_TAP_YES = 'Post Visit Summary - Tap Yes';
export const POST_VISIT_SUMMARY_TAP_NO = 'Post Visit Summary - Tap No';
export const POST_VISIT_SUMMARY_TOUCH_PRESCRIPTIONS = 'Post Visit Summary - Touch Prescriptions';
export const POST_VISIT_SUMMARY_TOUCH_NOTES = 'Post Visit Summary - Touch Notes';
export const POST_VISIT_SUMMARY_SUBMIT = 'Post Visit Summary - Submit';
export const POST_VISIT_SUMMARY_BOOK_AGAIN = 'Post Visit Summary - Book Again';
export const POST_VISIT_SUMMARY_CLICK_NOTIFICATION = 'Post Visit Summary - Click email/sms CTA';

export const SHARE_WITH_SOCIAL_MEDIA_CLICK = 'Click - share with social media';

export const PROFILE_CARD_BASIC_SAVE = 'Profile Card - Basic - Tap Save';
export const PROFILE_CARD_BASIC_CANCEL = 'Profile Card - Basic - Tap Cancel';

export const PROFILE_CARD_ADDRESS_SAVE = 'Profile Card - Address - Tap Save';
export const PROFILE_CARD_ADDRESS_CANCEL = 'Profile Card - Address - Tap Cancel';

export const PROFILE_CARD_PHARMACY_SAVE = 'Profile Card - Pharmacy - Tap Save';
export const PROFILE_CARD_PHARMACY_CANCEL = 'Profile Card - Pharmacy - Tap Cancel';

export const PROFILE_CARD_PHOTO_SAVE = 'Profile Card - Photo - Tap Save';
export const PROFILE_CARD_PHOTO_CANCEL = 'Profile Card - Photo - Tap Cancel';

export const CDP_REVIEWS_TOUCH_SHOW_MORE = 'CDP Reviews - Touch show more';
export const CDP_REVIEWS_VIEW_ALL = 'CDP Reviews - View all reviews';
export const CDP_REVIEWS_MODAL = 'CDP Review Modal';
export const CDP_REVIEWS_TOUCH_WRITE_REVIEW = 'CDP Reviews - Header Summary - Touch write review';
export const CDP_REVIEWS_FETCH_TOTAL_VIEWABLE_NUMBER_ERROR =
  'CDP Reviews - fetch error for total viewable reviews';
export const CDP_TELEMED_MODULE_CLICK = 'CDP - touch telemed module to go to booking widget';

export const PROFILE_DISCOVER_CARD_TAPPED = 'Profile Discover - Card tapped';
export const REBOOKING_PROVIDERS_TAPPED = 'Rebooking Providers Swimlane - Tile tapped';
export const PROFILE_DISCOVER = 'Profile Discover';
export const ACTION_SEE_SWIMLANE = 'See swimlane';
export const ACTION_SEE_TILE = 'See tile';
export const ACTION_DISMISS_TILE = 'Dismiss tile';

export const SOLV_PLUS_LANDING_PAGE_FAQ = 'Solv Plus - FAQ click';

export const SOLV_NOW_LANDING_PAGE_FAQ = 'Solv Now - FAQ click';

export const PREMIUM_VISIT_BOOKING_FLOW = 'Premium visit Booking flow';

export const SOLV_PLUS_STATE_CONFIRMATION_DEFAULT = 'Default state selected';
export const SOLV_PLUS_STATE_CONFIRMATION_OTHER = 'Other state selected';
export const SOLV_PLUS_NEW_STATE_SELECTED = 'New State Selected';
export const SOLV_PLUS_REDIRECT_TO_SEARCH_NO_STATE =
  'Unsupported state clicked and redirected to search';
export const SOLV_PLUS_BOOKING_TIME_CHOSEN = 'Booking time chosen';
export const SOLV_PLUS_LOGIN_SUCCEEDED = 'Login Succeeded';
export const SOLV_PLUS_EXISTING_PROFILE_SELECTED = 'Existing profile selected';
export const SOLV_PLUS_NEW_PROFILE_CREATED = 'New profile created';
export const SOLV_PLUS_REASON_FOR_VISIT_ENTERED = 'Reason for visit entered';
export const SOLV_PLUS_REASON_FOR_VISIT_EMERGENCY = 'Reason for visit emergency';
export const SOLV_PLUS_FINALIZE_CLICKED = 'Finalize visit clicked';
export const SOLV_PLUS_SEE_MORE_ON_PAYMENT = `Click 'See more' about Solv Plus`;
export const PREMIUM_BOOKING_CHANGE_CARD_ON_PAYMENTS_PAGE =
  'Clicked "Change card" on payments page';
export const PREMIUM_BOOKING_CHECK_SUBSCRIBE_BOX = 'Check Solv Plus Box';
export const PREMIUM_BOOKING_CHANGE_SELECTED_MEMBERSHIP_LENGTH =
  'Switch selected membership length';
export const PREMIUM_BOOKING_CARD_SAVE_ERROR = 'Credit card save error';
export const PREMIUM_BOOKING_CARD_SAVE_SUCCESS = 'Credit card save success';
export const PREMIUM_BOOKING_PAYMENT_PAGE_DONE = 'Payment page done';

export const SOLV_PLUS_NEW_MEMBER = 'Solv Plus -- New member successfully signed up';

export const SOLV_PLUS_SIGNUP = 'Solv Plus -- Signup';
export const SOLV_PLUS_SIGNUP_SUCCESS = 'Signup Success';
export const SOLV_PLUS_SIGNUP_SHOWN_STATE_ALERT = 'User shown not in state alert';

export const SOLV_PLUS_MODAL_ACTION = 'Solv Plus Product Modal';
export const SOLV_PLUS_MODAL_ACTION_LEARN_MORE = 'Click Learn More';
export const SOLV_PLUS_MODAL_ACTION_GET_CARE = 'Click Get Care';
export const SOLV_PLUS_MODAL_ACTION_DISMISS = 'Dismiss Modal';

export const SOLV_PLUS_UPSELL_MODAL_ACTION = 'Solv Plus Upsell Modal';
export const SOLV_PLUS_UPSELL_MODAL_ACTION_DISMISS = 'Dismiss Modal';

export const PREMIUM_IN_PERSON_ENTRYPOINT_TAPPED = 'Premium In Person - Entrypoint Tapped';
export const PREMIUM_IN_PERSON_CTA_TYPE_LEARN_MORE = 'Learn More';
export const PREMIUM_IN_PERSON_CTA_TYPE_SIGN_UP = 'Sign Up';

export const PREMIUM_IN_PERSON_START_BOOKING_FLOW = 'Premium In Person - Start Booking Flow';
export const PREMIUM_IN_PERSON_BOOKING_ENTRYPOINT_ACCOUNT_HOME = 'Account Home';

export const PREMIUM_IN_PERSON_BOOKING_FLOW = 'Premium In Person Booking Flow';
export const PREMIUM_IN_PERSON_SELECT_PREVIEW_TIME_SLOT = 'Select Preview Time Slot';
export const PREMIUM_IN_PERSON_SELECT_SEE_MORE_TIMES = 'Select See More Times';
export const PREMIUM_IN_PERSON_START_SOLV_NOW_VISIT = 'Start solv now visit';
export const PREMIUM_IN_PERSON_START_NEW_UC_SEARCH = 'Start new UC search';

export const SOLV_PLUS_ENTRYPOINT_TAPPED = 'Solv Plus - Entrypoint Tapped';
export const SOLV_NOW_ENTRYPOINT_TAPPED = 'Solv Now - Entrypoint Tapped';
export const SOLV_PLUS_ENTRYPOINT_SEARCH = 'Account Search Entrypoint';
export const SOLV_PLUS_ENTRYPOINT_LANDING_PAGE = 'Landing Page Entrypoint';
export const SOLV_PLUS_ENTRYPOINT_HOMEPAGE = 'Homepage Entrypoint';
export const SOLV_PLUS_ENTRYPOINT_ACCOUNT_HOME = 'Account Home Entrypoint';
export const SOLV_PLUS_ENTRYPOINT_ACCOUNT_SIDEBAR = 'Account Sidebar Entrypoint';
export const SOLV_PLUS_ENTRYPOINT_CARE_OPTIONS = 'Care options entrypoint';
export const SOLV_NOW_ENTRYPOINT_IN_PERSON_SLOTS = 'In person slot selector';
export const SOLV_PLUS_POST_TELEMED_UPSELL_SCREEN = 'Solv Plus Post Video Visit Upsell Entrypoint';

export const SOLV_PLUS_CHAT_ENTRYPOINT = 'Solv Plus Chat Entrypoint';
export const ACCOUNT_PROFILE_PAST_VISITS_CARD = 'Account profile past visits card';
export const VISIT_SUMMARY_HELP_SECTION = 'Visit summary help section';
export const VISIT_CONFIRMATION_PAGE_HELP_SECTION = 'Visit confirmation page - help section';
export const BOTTOM_NAVBAR_CHAT_ICON = 'Bottom navbar chat icon';

export const SOLV_PLUS_ENTRYPOINT_VISIBILITY = 'Solv Plus -- Entrypoint Visibility';
export const SOLV_NOW_ENTRYPOINT_VISIBILITY = 'Solv Now -- Entrypoint Visibility';

export const IN_PERSON_PREMIUM_ENTRYPOINT_VISIBILITY = 'In Person Premium -- Entrypoint Visibility';

export const SOLV_NOW_ENTRYPOINT_LANDING_PAGE = 'Solv Now Landing Page Entrypoint';
export const SOLV_NOW_ENTRYPOINT_LANDING_PAGE_TABLE = 'Solv Now Landing Page Table Entrypoint';

export const TRIAGE_ENTRYPOINT_CLICKED = 'Triage - Entrypoint Clicked';
export const TRIAGE_ENTRYPOINT_VISIBLE = 'Triage - Entrypoint Visible';
export const TRIAGE_ENTRYPOINT_SEEN = 'Triage - Entrypoint Seen';

export const TRIAGE_ENTRYPOINT_HOMEPAGE = 'Homepage';
export const TRIAGE_ENTRYPOINT_ACCOUNT_HOME = 'Account home';
export const TRIAGE_ENTRYPOINT_ACCOUNT_HOME_MODALITY_CARD = 'Account home - modality card';

export const ACCOUNT_HOME_TOUCH_APPOINTMENT_CARD_UPCOMING = 'Account home - Touch upcoming Card';
export const ACCOUNT_HOME_TOUCH_APPOINTMENT_CARD_PAST = 'Account home - Touch past Card';

export const VISIT_SUMMARY_TOUCH_DOWNLOAD = 'Touch download view summary';
export const VISIT_SUMMARY_TOUCH_VIEW = 'Touch view visit summary';

export const NAV_TILE_CLICKED = 'Account home -- clicked nav tile';

export const DRAWER_TYPE_URGENT_CARE = 'urgent-care';
export const DRAWER_TYPE_COVID_TEST = 'covid';
export const DRAWER_TYPE_MANAGE_VISITS = 'manage_visits';
export const DRAWER_TYPE_TELEMED = 'telemed';
export const DRAWER_TYPE_FLU_SHOT = 'flu-shot';
export const DRAWER_TYPE_LAB_TEST = 'lab';
export const DRAWER_TYPE_SOLV_PLUS_VIDEO_VISIT = 'solv-plus-telemed';
export const DRAWER_TYPE_SEARCH = 'search';
export const DRAWER_TYPE_TRIAGE = 'triage';
export const DRAWER_TYPE_FALL_ALLERGIES = 'fall-allergies';
export const DRAWER_TYPE_COLD_AND_COUGH = 'cold-and-cough';
export const DRAWER_TYPE_MENTAL_HEALTH = 'mental-health';

export const DRAWER_TYPES = [
  DRAWER_TYPE_URGENT_CARE,
  DRAWER_TYPE_COVID_TEST,
  DRAWER_TYPE_MANAGE_VISITS,
  DRAWER_TYPE_TELEMED,
  DRAWER_TYPE_FLU_SHOT,
  DRAWER_TYPE_LAB_TEST,
  DRAWER_TYPE_SOLV_PLUS_VIDEO_VISIT,
  DRAWER_TYPE_SEARCH,
  DRAWER_TYPE_TRIAGE,
  DRAWER_TYPE_FALL_ALLERGIES,
  DRAWER_TYPE_COLD_AND_COUGH,
  DRAWER_TYPE_MENTAL_HEALTH,
] as const;

export const ACCOUNT_HOME_CLICK_BOOK_AGAIN_SUGGESTION = 'ACCOUNT_HOME_CLICK_BOOK_AGAIN_SUGGESTION';

export const ACCOUNT_HOME_REBOOKING_SECTION = 'Account home rebooking section loaded';

export const ADD_A_FAMILY_TILE = 'Add a family tile';

export const SRP_SRP_VIEW = 'SRP - SRP view';
export const SRP_TOUCH_SELECT_TIME = 'SRP - Touch Select Time';
export const SRP_TOUCH_SHOW_MORE_TIMESLOTS = 'SRP - Touch Show More Time Slots';
export const SRP_TOUCH_SHOW_LESS_TIMESLOTS = 'SRP - Touch Show Less Time Slots';
export const SRP_TOUCH_SHOW_MORE_RESULTS = 'SRP - Touch Show More Results';
export const SRP_MAP_TOUCH_MARKER = 'SRP MAP - Touch Map Marker';
export const SRP_TOGGLE_TO_MAP = 'SRP - Toggle to map view';
export const SRP_TOGGLE_TO_LIST = 'SRP - Toggle to list view';
export const SRP_OPEN_FILTERS_MODAL = 'SRP - open filters modal';
export const SRP_CLOSE_FILTERS_MODAL = 'SRP - close filters modal';
export const SRP_FILTERS_APPLY = 'SRP filters - Apply filters';
export const SRP_ADD_INSURANCE_CTA = 'SRP - Add insurance CTA';
export const SRP_TELEMED_CONNECT_LOCATION_CARD = 'SRP - Telemed Connect location card';

export const SRP_LOCATION_CARD = 'SRP - Location card';
export const SRP_EXIT = 'SRP - Exit';
export const SRP_CLEAR_PRICE_BANNER = 'SRP - Clear price banner';
export const SRP_SELF_PAY_POPOVER = 'SRP - Self pay tooltip';

export const SRP_AB_CLICK_ANY_PARTNER_CTA = 'SRP - Click any partner CTA';
export const SRP_AB_CLICK_TIME_SLOT = 'SRP - Click time slot';
export const SRP_AB_CLICK_LOCATION_CARD = 'SRP - Click location card';
export const SRP_AB_LOAD_TIME = 'SRP - Load time';

export const ACTION_VIEW_MORE_INSURANCES = 'SRP - Touch view more insurances';
export const ACTION_VIEW_LESS_INSURANCES = 'SRP - Touch view less insurances';
export const ACTION_CLICK_NON_PARTNER_CALL = 'SRP - Click non-partner call';
export const ACTION_CLICK_CARD = 'SRP - Click card';
export const tapSearchAction = (distance: number) => `SRP - Tap search within ${distance} miles`;
export const tapSearchCovidAction = (rapid?: boolean) =>
  `SRP - Tap search for${rapid ? ' rapid ' : ' '}COVID test`;

export const MARKETPLACE_INSURANCE_DROPDOWN = 'Marketplace insurance dropdown';

export const SRP_BEST_MATCH_IN_RESULTS_DISPLAYED = 'SRP Results - Best match displayed';
export const SRP_BEST_MATCH_TOUCH_APPOINTMENT_TIME =
  'SRP Results - Best match touch appointment time';

export const WEB_PUSH_OPT_IN = 'Web push opt in';
export const WEB_PUSH_SUBSCRIPTION = 'Web push subscription';
export const NATIVE_PUSH_FULL_SCREEN_OPEN_MODAL = 'Native push full screen open modal';
export const NATIVE_PUSH_FULL_SCREEN_ALLOW = 'Native push full screen - allow';
export const NATIVE_PUSH_FULL_SCREEN_DENY = 'Native push full screen - deny';
export const NATIVE_PUSH_MISSING_NOTIFICATION_OPEN_MODAL =
  'Native push missing notification open modal';
export const NATIVE_PUSH_MISSING_NOTIFICATION_ALLOW = 'Native push missing notification - allow';
export const NATIVE_PUSH_MISSING_NOTIFICATION_DENY = 'Native push missing notification - deny';

export const INTERSTITIAL_DISPLAYED = 'Interstitial - Displayed';
export const INTERSTITIAL_REDIRECTED = 'Interstitial - Redirected';
export const INTERSTITIAL_TOUCH_GO_BACK = 'Interstitial - Touch go back';

// actions
export const ACTION_LOAD = 'action - load';
export const ACTION_CTA_CLICK = 'action - click CTA';
export const ACTION_CHANGE_VALUE = 'action - change value';
export const ACTION_CLICK = 'action - click';
export const ACTION_CLEAR_VALUE = 'action - clear value';
export const ACTION_BLUR = 'action - blur';
export const ACTION_FOCUS = 'action - focus';
export const ACTION_DISMISS = 'action - dismiss';

export const ACCOUNT_PROFILE_TOUCH_EDIT = 'Account Profile - Touch Edit';
export const ACCOUNT_CALL_INSURANCE_CARRIER = 'Account - Click call insurance carrier';
export const ACCOUNT_CALL_INSURANCE_BENEFITS = 'Account - Click call insurance benefits';
export const COPAY_MODAL_CALL_INSURANCE_CARRIER = 'Copay modal - Click call insurance carrier';

// onboarding
export const ONBOARDING_FLOW_EVENT = 'onboarding flow';
export const ONBOARDING_ACTION_SUBMIT_FORM = 'submit form';
export const ONBOARDING_ACTION_START = 'start';
export const ONBOARDING_ACTION_EXIT = 'exit';
export const ONBOARDING_ACTION_BACK = 'back';
export const ONBOARDING_ACTION_COMPLETE = 'complete';
export const WELCOME_FLOW_EVENT = 'welcome flow';
export const WELCOME_CLICK_EXIT = 'exit';

// universal
export const UNIVERSAL_SEARCH_BUTTON = 'universal search button';
export const UNIVERSAL_TIME_INPUT = 'universal time input';
export const UNIVERSAL_PATIENT_TYPE_INPUT = 'universal patient type input';
export const UNIVERSAL_MORE_DATES_INPUT = 'universal more dates input';
export const UNIVERSAL_LOCATION_INPUT = 'universal location input';
export const UNIVERSAL_AUTOSUGGEST_INPUT = 'universal autosuggest input';
export const UNIVERSAL_INSURANCE_INPUT = 'universal insurance input';
export const UNIVERSAL_SEARCH = 'universal search';
export const UNIVERSAL_COST_TYPE_INPUT = 'universal cost type input';
export const UNIVERSAL_STATE_INPUT = 'universal state input';
export const UNIVERSAL_VACCINE_INPUT = 'universal vaccine input';

// Expanded services project events
export const ACCOUNT_HOME_NEW_SERVICES_V1_ON = 'Account home - new services v1 feature flag on';
export const TOUCH_SERVICE_SEARCH_FIELD = 'Service search field touched';
export const ACCOUNT_HOME_TOUCH_BROWSE_ALL_SERVICES = 'Browse all services button touched';
export const ACCOUNT_HOME_TOUCH_MODALITY_OF_CARE_CARD = 'Modality of care card touched';
export const CONSUMER_SERVICE_CATEGORY_CARD_TOUCHED = 'Consumer service category card touched';
export const ACCOUNT_HOMEPAGE_CONSUMER_SERVICE_SECTION_LOADED =
  'Account homepage - consumer services section loaded';
export const CONSUMER_SERVICE_TOUCHED = 'Consumer service touched';

// iOS app
export const UPDATE_APP_MODAL_OPEN = 'update modal app open';
export const UPDATE_APP_MODAL_CLOSE = 'update modal app close';
// ab testing
export const AB_TEST_GROUP_ASSIGNMENT = 'AB test group assignment';

export const INSURANCE_INSIGHTS_THUMBS = 'Insurance Insight - Tapped thumbs up/down';

export const COPAY_MODAL_FEEDBACK_THUMBS = 'Copay modal - feedback thumbs';

export const BOOKING_WIDGET_LOADED = 'Booking Widget - loaded booking widget page';
export const BOOKING_WIDGET_OPENED_LOCALE_SELECT =
  'Booking Widget - opened locale select on booking widget';
export const BOOKING_WIDGET_CHANGED_LOCALE_SELECT =
  'Booking Widget - changed locale using select on booking widget';

export const TRIED_TO_JOIN_VISIT_TOO_EARLY = 'Shown "Tried to join visit too early" modal';

export const VIDEO_VISIT_SCREEN_LOADED = 'Video Visit - loaded video visit page';

// Lab results
export const LAB_RESULTS_SAVE_RESULTS = 'Results Notification Save My Results Clicked';
export const LAB_RESULTS_VIEWED = 'Results Notification Lab Result Viewed';
export const LAB_RESULTS_FAILED_TO_LOAD = 'Results Notification Lab Results Error';
export const LAB_RESULTS_VERIFY_LAST_NAME_LOAD =
  'Results Notification Lab Results Verify Last Name Component Loaded';
export const LAB_RESULTS_VERIFY_LAST_NAME_ERROR =
  'Results Notification Lab Results Last Name Error';
export const LAB_RESULTS_VIEW_RESULTS_DISPLAY_LOAD =
  'Results Notification Lab Results Results Display Component Loaded';

export const CALL_CLINIC = 'Call clinic';

// Lab Drawer
export const LAB_DRAWER_HOMEPAGE_LOADED = 'Lab Drawer Home page';
export const LAB_DRAWER_CARD_CLICK = 'Lab Drawer Card click';
export const LAB_DRAWER_SEARCH = 'Lab Drawer Search';

// Provider Group

export const PROVIDER_DETAIL_PAGE = 'Provider Detail Page';
export const PROVIDER_GROUP_SPECIALTY_PAGE = 'Provider Group Specialty Page';
export const PROVIDER_GROUP_LOCATION_PAGE = 'Provider Group Location Page';
export const CLICK_ON_GROUP_AFFILIATION = 'Click On Group Affiliation';
export const CLICK_CHANGE_APPOINTMENT_REASON_TYPE = 'Click Change Appointment Reason Type';
export const CLICK_CHANGE_APPOINTMENT_REASON_NAME = 'Click Change Appointment Reason Name';
export const CLICK_SELECT_SLOT = 'Click Select Time Slot';
export const REDIRECT_TO_SPECIALTY_PAGE = 'Redirect To Specialty Page';
export const REDIRECT_TO_PROVIDER_PAGE = 'Redirect To Provider Page';
export const CLICK_SINGLE_DAY_SLIDER = 'Click Single Day Slider';
export const CLICK_OPEN_FULL_CALENDAR = 'Click Open Full Calendar';
export const CLICK_NEXT_AVAILABLE_BUTTON = 'Click Next Available Button';
export const MODAL = 'Modal';
export const OPEN = 'Open';
export const CLOSE = 'Close';
export const CLICK_SELECT_SPECIALTY = 'Click Select Specialty';

export const LOADED_A_PAGE = 'Loaded a Page';

// ERROR reporting
export const ERROR_CAUGHT = 'error caught';
export const ERROR_PAGE_RELOAD = 'error page reload';

export class HandoffEvents {
  static SUBMIT_HANDOFF_CODE = 'Handoff - Submit code';

  static ERROR = 'Handoff - Error';

  static SUCCESSFUL_REDIRECT = 'Handoff - Successful redirect';
}

export const LOCATION_PAGE = 'Location page';

export const REFERRAL_CLICKED = 'Referral Clicked';
export const REFERRAL_UC = 'Urgent Care Search';
export const REFERRAL_COVID_TEST = 'Covid test search';

// Care team
export const CARE_TEAM_BOOK_AGAIN_SEE_ALL = 'Care Team - Click book again see all';
export const CARE_TEAM_DELETE_ENTRY = 'Care Team - Delete Entry';
export const CARE_TEAM_CLICK_ON_LOCATION = 'Care Team - Click on location';

// Care Options
export const CARE_OPTIONS_SHOWN = 'Care Options Displayed';

export const CARE_OPTION_CHOSEN = 'Care option chosen';

export const CARE_OPTION_TYPE_SEARCH = 'Continue to search';
export const CARE_OPTION_TYPE_REBOOKING = 'Rebook at location';
export const CARE_OPTION_TYPE_PREMIUM_VISIT = 'Solv Plus/Now visit';

export const ALMA_CARD_VISIBLE = 'Alma Card - Visible';
export const ALMA_CARD_SEEN = 'Alma Card - Seen';
export const ALMA_SERVICE_PAGE_VIEWED = 'Alma Service Page - Viewed';
export const ALMA_CLICK_FAQ = 'Alma Click - FAQ';
export const ALMA_CLICK_FIND_BUTTON_PRE_MODAL = 'Alma Click - Find Button (Pre-Modal)';
export const ALMA_CLICK_SEARCH = 'Alma Click - Search';
export const ALMA_CLICK_SERVICE = 'Alma Click - Service';
export const ALMA_CLICK_JUMP_TO_FAQ = 'Alma Click - Jump to FAQ';
export const ALMA_CLICK_JUMP_TO_INSURANCE = 'Alma Click - Jump to Insurance';
export const ALMA_ENTRYPOINT_CLICKED = 'Alma - Entrypoint Clicked';

export const MEDMO_CARD_VISIBLE = 'Medmo Card - Visible';
export const MEDMO_CARD_SEEN = 'Medmo Card - Seen';
export const MEDMO_SERVICE_PAGE_VIEWED = 'Medmo Service Page - Viewed';
export const MEDMO_CLICK_FAQ = 'Medmo Click - FAQ';
export const MEDMO_CLICK_FIND_BUTTON_PRE_MODAL = 'Medmo Click - Find Button (Pre-Modal)';
export const MEDMO_CLICK_GO_TO_MEDMO = 'Medmo Click - Go to Medmo';
export const MEDMO_CLICK_SERVICE = 'Medmo Click - Service';
export const MEDMO_CLICK_JUMP_TO_FAQ = 'Medmo Click - Jump to FAQ';

export const ALMA_ENTRYPOINT_HOMEPAGE = 'Homepage';
export const ALMA_ENTRYPOINT_DRAWER = 'Account Drawer';
export const CONSUMER_SERVICES_ENTRYPOINT_ACCOUNT_HOME = 'account home' as const;
export const CONSUMER_SERVICES_ENTRYPOINT_CATEGORY_LIST_PAGE = 'category list page' as const;

export const CLICK_EXTERNAL_BOOKING_FLOW_LINK = 'Click external booking flow link' as const;
export const LOAD_EXTERNAL_TELEMED_BOOKING_CONFIRMATION =
  'Load external telemed booking confirmation';
