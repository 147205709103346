import { isPhotoIdUploadedForUserProfile } from './account';
import {
  isPaperworkEnabled,
  isPaymentsEnabled,
  isPhotoIdUploadEnabledForEntrypoint,
} from './location';
import { isPaperworkCompleted } from './paperwork';
import { isPaymentMethodAddedForCurrentBooking } from './payment';

const getPreVisitPaymentRoute = (booking: any) =>
  `/mobile-check-in/${booking.id}/pre-visit-payment?skip_onboarding=true`;

const getPreVisitPaperworkRoute = (booking: any) =>
  `/mobile-check-in/${booking.id}/pre-visit-paperwork?skip_onboarding=true`;

const getPreVisitPhotoIdUploadRoute = (booking: any) =>
  `/mobile-check-in/${booking.id}/pre-visit-photo-id-upload?skip_onboarding=true`;

const getWaitlistRoute = (booking: any) =>
  `/mobile-check-in/${booking.id}/pre-visit-waitlist?skip_onboarding=true`;

const checkForPaperworkRouting = (booking: any, location: any) => {
  // Does location have paperwork enabled?
  if (isPaperworkEnabled(location)) {
    if (isPaperworkCompleted(booking)) {
      // No need to navigate to paperwork
      return null;
    }
    // Paperwork not completed
    return getPreVisitPaperworkRoute(booking);
  }
  // No need to navigate to paperwork since it's not enabled
  return null;
};

const checkForPaymentRouting = (booking: any, location: any) => {
  // Does location have payment enabled?
  if (isPaymentsEnabled(location)) {
    if (isPaymentMethodAddedForCurrentBooking(booking)) {
      // No need to navigate to payment
      return null;
    }
    // Payment not completed
    return getPreVisitPaymentRoute(booking);
  }
  // No need to navigate to payment since it's not enabled
  return null;
};

const checkForPhotoIdUploadRouting = (booking: any, location: any, userProfile: any) => {
  // Does location have photo ID upload enabled?
  if (isPhotoIdUploadEnabledForEntrypoint(location, 'mobileCheckIn')) {
    if (isPhotoIdUploadedForUserProfile(userProfile)) {
      // No need to navigate to photo id upload
      return null;
    }
    // Photo ID not uploaded yet
    return getPreVisitPhotoIdUploadRoute(booking);
  }
  // No need to navigate to photo ID upload since it's not enabled
  return null;
};

const getNextRouteForMobileCheckInFlowAfterPaperwork = (
  booking: any,
  location: any,
  userProfile: any
) => {
  let url;

  // first check if we should route to photo id upload
  url = checkForPhotoIdUploadRouting(booking, location, userProfile);

  if (!url) {
    // then check if we should route to payment
    url = checkForPaymentRouting(booking, location);

    if (!url) {
      // Redirect to the pre-visit thank you screen
      url = getWaitlistRoute(booking);
    }
  }

  return url;
};

// do not go to account home even if the user is logged in. This is because the account does not
// have ways to finish up the check in process, yet, so it's a dead end! (SOLV-9101)
const getRouteForMobileCheckInFlowBeforePaperwork = (
  booking: any,
  location: any,
  isLoggedIn: any
) => {
  return `/mobile-check-in/${booking.id}/success`;
};

const getRouteForMobileCheckInFlowBeforePhotoIdUpload = (
  booking: any,
  location: any,
  isLoggedIn: any
) => {
  // if paperwork enabled and not completed then send to paperwork
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  if (isPaperworkEnabled(booking, location) && !isPaperworkCompleted(booking)) {
    return getPreVisitPaperworkRoute(booking);
  }

  return getRouteForMobileCheckInFlowBeforePaperwork(booking, location, isLoggedIn);
};

const getNextRouteForMobileCheckInFlowAfterPhotoIdUpload = (
  booking: any,
  location: any,
  userProfile: any
) => {
  let url;

  // first check if we should route to payment
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
  url = checkForPaymentRouting(booking, location, userProfile);

  if (!url) {
    // Redirect to the pre-visit thank you screen
    url = getWaitlistRoute(booking);
  }

  return url;
};

const getRouteForMobileCheckInFlowBeforePayment = (
  booking: any,
  location: any,
  isLoggedIn: any,
  userProfile: any
) => {
  // if photo id upload enabled and not completed then send to photo id upload
  if (
    isPhotoIdUploadEnabledForEntrypoint(location, 'mobileCheckIn') &&
    !isPhotoIdUploadedForUserProfile(userProfile)
  ) {
    return getPreVisitPhotoIdUploadRoute(booking);
  }

  // if paperwork enabled and not completed then send to paperwork
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  if (isPaperworkEnabled(booking, location) && !isPaperworkCompleted(booking)) {
    return getPreVisitPaperworkRoute(booking);
  }

  return getRouteForMobileCheckInFlowBeforePaperwork(booking, location, isLoggedIn);
};

const getRouteForMobileCheckInFlowBeforeWaitlist = (
  booking: any,
  location: any,
  isLoggedIn: any,
  userProfile: any
) => {
  // if payment enabled and not completed then send to payment
  if (isPaymentsEnabled(location) && !isPaymentMethodAddedForCurrentBooking(booking)) {
    return getPreVisitPaymentRoute(booking);
  }

  return getRouteForMobileCheckInFlowBeforePayment(booking, location, isLoggedIn, userProfile);
};

const getStartingPointRouteForMobileCheckIn = (booking: any, location: any) => {
  let url;

  // First check if i should route to paperwork
  url = checkForPaperworkRouting(booking, location);
  if (!url) {
    // Second check if should route to payments
    url = checkForPaymentRouting(booking, location);
    if (!url) {
      // Redirect to waitlist
      url = getWaitlistRoute(booking);
    }
  }

  return url;
};

export {
  getStartingPointRouteForMobileCheckIn,
  getNextRouteForMobileCheckInFlowAfterPaperwork,
  getRouteForMobileCheckInFlowBeforePaperwork,
  getNextRouteForMobileCheckInFlowAfterPhotoIdUpload,
  getRouteForMobileCheckInFlowBeforePhotoIdUpload,
  getRouteForMobileCheckInFlowBeforeWaitlist,
  getRouteForMobileCheckInFlowBeforePayment,
  getWaitlistRoute,
};
