import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Geosuggest, { Suggest } from 'react-geosuggest';
import { Position } from '../../../hooks/geo/interfaces/Position';
import GeoAutoComplete from '../../GeoAutoComplete/GeoAutoComplete';
import Input, { Clear } from './Base';
import { colors } from '../../../constants/colors';
import { fontFamily, fontSize } from '../../../constants/text';
// @ts-ignore
import GeoLocate from '../../GeoLocate';
import { setUserLocation } from '../../../actions/searchPreferences';
import { getLocationLabelFromState } from '../../../core/util/search';
import { isEmptyObject } from '../../../core/util/object';
import { biggerThan } from '../../../core/util/styledComponents';
import { analyticsTrackEvent } from '../../../core/analytics';
import {
  ACTION_BLUR,
  ACTION_CLEAR_VALUE,
  LOCATION_SET_BY_USER,
  UNIVERSAL_LOCATION_INPUT,
} from '../../../core/analytics/events';
import { CONSUMER_APP } from '../../../constants/index';
import { isMobile } from '../../../core/util/device';
import { getCityLabelFromGeosuggestValue } from '../../Search/components/Filters/Fields/LocationInput/util';
import { POWERED_BY_GOOGLE_ID } from '../util';
import { PositionTrigger } from '~/hooks/geo/useUserPosition';

const GeoSuggestContainer = styled.div`
  width: 100%;
  position: relative;

  & .geosuggest__input {
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: -0.1px;
    line-height: 20px;
    border: 1px solid ${colors.fatherJohnMisty};
    transition: border-color 200ms ease;
    text-indent: 34px;
    padding: 0;
    background-color: ${colors.white};
    color: ${colors.squid};
    font-family: ${fontFamily.book};
    font-size: ${fontSize.default};
    height: 40px;
    width: 100%;
    background-image: url(/images/icons/search-icons/Icon-Search-Location.svg);
    background-position: 11px center;
    background-size: 16px 16px;
    background-repeat: no-repeat;

    ::placeholder {
      color: ${colors.greyLock};
      font-family: ${fontFamily.book};
      font-size: ${fontSize.default};
    }

    :focus {
      outline: 0;
      border-color: ${colors.mrBlueSky};
    }
  }

  & .geosuggest__suggests {
    display: none;
  }

  & .geosuggest__suggests--hidden {
    display: none;
  }
`;

const GpsIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-left: 10px;
  ${biggerThan.sm`
    margin-right: 10px;
    margin-left: 0;
  `}
`;

const PoweredByGoogle = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  & img {
    width: 80px;
  }
`;

const poweredByGoogleHTML = ReactDOMServer.renderToString(
  <PoweredByGoogle>
    <img alt="powered by google" src="/images/homepage/powered-by-google.png" />
  </PoweredByGoogle>
);

const currentLocationHTML = ReactDOMServer.renderToString(
  <span style={{ color: colors.link }}>Current Location</span>
);

interface LocationProps {
  updateSuggestions?: (suggestions: any[]) => void;
  handleSetUserLocation: (location: any) => void;
  position?: any;
  searchPreferences?: any;
  refSetter?: (el: Geosuggest) => void;
  clearOnBlur?: boolean;
  autoFocus?: boolean;
  onSelect?: (suggest: Position | Suggest) => void;
  setDefaultIdx?: (idx: number) => void;
  className?: string;
}

interface LocationState {
  loadingValue: string;
  loadingFocus: boolean;
  clearEnabled: boolean;
  requestedCurrentLocation: boolean;
}

class Location extends PureComponent<LocationProps, LocationState> {
  geoRef: any;

  loadRef: any;

  state = {
    loadingValue: '',
    loadingFocus: false,
    clearEnabled: !this.props.clearOnBlur,
    requestedCurrentLocation: false,
  };

  componentDidMount() {
    const { autoFocus, setDefaultIdx } = this.props;

    if (autoFocus) {
      (this.geoRef || this.loadRef)?.focus();
    }

    setDefaultIdx?.(1);
  }

  onChangeWatcher = (value: any) => {
    const { clearEnabled } = this.state;
    if (value) !clearEnabled && this.setState({ clearEnabled: true });
    else if (clearEnabled) this.setState({ clearEnabled: false });
  };

  getCurrentLocationSuggestion = () => ({
    key: 'current location',
    primary: currentLocationHTML,

    icon: <GpsIcon src="/images/icons/search-icons/Icon-Search-GPS.svg" />,
    showPositionLoader: true,
    onClick: () => this.setState({ requestedCurrentLocation: true }),
  });

  getPoweredByGoogleSuggestion = () => ({
    key: POWERED_BY_GOOGLE_ID,
    narrow: true,
    primary: poweredByGoogleHTML,
  });

  handleGeoSelect = (suggestion: Suggest) => {
    analyticsTrackEvent(LOCATION_SET_BY_USER, {
      sourceOfInput: CONSUMER_APP,
      isMobile: isMobile(),
    });
    const { handleSetUserLocation, clearOnBlur, updateSuggestions, onSelect } = this.props;
    if (suggestion) {
      if (!clearOnBlur) updateSuggestions?.([]);

      handleSetUserLocation(suggestion);
      onSelect?.(suggestion);
    } else {
      this.clearSuggestions();
    }
  };

  handleGeoLoad = () => {
    const { loadingValue, loadingFocus } = this.state;
    const { autoFocus } = this.props;

    if (loadingValue) this.geoRef.update(loadingValue);
    if (autoFocus || loadingFocus) this.geoRef.focus();
  };

  handleGeoSuggestions = (suggests: any) =>
    this.updateSuggestions(suggests.map(this.transformSuggestion));

  handleSuggestionSelect = (suggestion: any) => {
    this.geoRef.update(suggestion.label);
    this.geoRef.blur();
    this.props.updateSuggestions?.([]);
    this.geoRef.selectSuggest(suggestion);
  };

  transformSuggestion = (suggestion: any) => {
    const { label, matchedSubstrings, placeId } = suggestion;
    let primary = label;
    if (matchedSubstrings && matchedSubstrings.length)
      primary = `${primary.slice(0, matchedSubstrings.offset)}<em>${primary.slice(
        matchedSubstrings.offset,
        matchedSubstrings.length
      )}</em>${primary.slice(matchedSubstrings.offset + matchedSubstrings.length)}`;

    return {
      primary,
      key: placeId,
      secondary: null,
      onClick: () => this.handleSuggestionSelect(suggestion),
    };
  };

  handleClearClick = () => {
    analyticsTrackEvent(UNIVERSAL_LOCATION_INPUT, {
      action: ACTION_CLEAR_VALUE,
      isMobile: isMobile(),
    });
    this.setState({ clearEnabled: false });
    // TODO: minor bug, suggestions pop back up from the focus call even though
    // there's no input /shrug
    this.geoRef.focus();
    this.geoRef.clear();
  };

  handleGeoLocateOnSuccess = (result: Position, override = false) => {
    if (result) {
      if (override || isEmptyObject(this.props.searchPreferences.location)) {
        const { latitude, longitude, cityLabel, label } = result;
        this.props.handleSetUserLocation({
          label: label ?? 'Current Location',
          location: {
            lat: latitude,
            lng: longitude,
          },
          cityLabel,
        });
        this.clearSuggestions();
      }
    }
  };

  handleBlur = () => {
    analyticsTrackEvent(UNIVERSAL_LOCATION_INPUT, {
      action: ACTION_BLUR,
      isMobile: isMobile(),
    });
  };

  handleFocus = () => {
    this.geoRef?.input.input.select();
  };

  clearSuggestions = () => this.props.updateSuggestions?.([]);

  updateSuggestions = (suggestions: any) =>
    this.props.updateSuggestions?.([
      this.getCurrentLocationSuggestion(),
      ...suggestions,
      this.getPoweredByGoogleSuggestion(),
    ]);

  render() {
    const { className, position, searchPreferences, refSetter } = this.props;
    const { clearEnabled, requestedCurrentLocation } = this.state;

    return (
      <GeoSuggestContainer className={className}>
        {requestedCurrentLocation && (
          <GeoLocate
            onSuccess={(result: Position) => {
              this.handleGeoLocateOnSuccess(result, true);
              this.setState({ requestedCurrentLocation: false });
              this.props.onSelect?.(result);
            }}
            trigger={PositionTrigger.AccurateSearch}
          />
        )}

        <GeoAutoComplete
          currentSearch={getLocationLabelFromState({
            searchPreferences,
            position,
          })}
          id="GeoAutoComplete"
          loadingComponent={
            <Input
              $url="/images/icons/search-icons/Icon-Search-Location.svg"
              onBlur={() => this.setState({ loadingFocus: false })}
              onChange={(e) => this.setState({ loadingValue: e.target.value })}
              onFocus={() => this.setState({ loadingFocus: true })}
              placeholder="Street, City or Zip"
              ref={(el) => (this.loadRef = el)}
            />
          }
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onGeoSelect={this.handleGeoSelect}
          onLoad={this.handleGeoLoad}
          onUpdateSuggests={this.handleGeoSuggestions}
          onValueChange={this.onChangeWatcher}
          refSetter={(el: any) => {
            this.geoRef = el;
            refSetter && refSetter(el);
          }}
        />

        <Clear
          $shown={clearEnabled}
          alt="clear"
          onClick={this.handleClearClick}
          src="/images/icons/search-icons/Icon-Search-Clear.svg"
        />
      </GeoSuggestContainer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  position: state.position,
  searchPreferences: state.searchPreferences,
});

const mapDispatchToProps = (dispatch: any) => ({
  handleSetUserLocation: (l: any) => {
    if (!l) return;
    const cityLabel = l.cityLabel || getCityLabelFromGeosuggestValue(l.gmaps);
    const location = {
      latitude: l.location.lat,
      longitude: l.location.lng,
      label: l.label,
      cityLabel,
    };
    dispatch(setUserLocation(location));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
