import { LDUser } from 'launchdarkly-js-client-sdk';
import { AccountSummary } from '~/reducers/account';
import { isMobile } from '~/core/util/device';
import { getBooleanFlag } from '~/core/launch-darkly/flags';

export interface LDUserObjectProps {
  account?: AccountSummary;
  accountId?: string;
  segmentAnonymousId: string;
}

/**
 * This function creates or updates a user object for Launch Darkly (LD). If anonymous is set to false, users
 * will not be shown in the User panel in the LD console. Any unique keys contribute to our MAU cost.
 *
 * @returns an LDUser object
 */
export function getLDUserObject({
  account,
  accountId,
  segmentAnonymousId,
}: LDUserObjectProps): LDUser {
  let user: any;
  if (account) {
    /**
     * BEFORE ADDING CUSTOM ATTRIBUTES: Don't spread PHI if we dont have to. Attributes, together with Segment event data,
     * could constitute PHI. This current configuration surfaces all account ids to launchDarkly, not just those who have
     * crossed the pink door, so we need to abide by BAA agreements we have with clinic partners.
     */
    const customAttributes = {
      isSolvEmployee: account.solv_employee,
      isActiveMembership: account.is_active_membership,
      firstLogin: account.first_login,
      uniqueId: segmentAnonymousId,
    };

    user = {
      anonymous: false,
      key: account.id,
      custom: customAttributes,
    };
  } else if (accountId) {
    user = {
      anonymous: false,
      key: accountId,
      custom: {
        uniqueId: segmentAnonymousId,
      },
    };
  } else {
    user = {
      anonymous: true,
      key: segmentAnonymousId,
      custom: {
        uniqueId: segmentAnonymousId,
      },
    };
  }

  return user;
}

export const isInExperimentKis596 = (
  userAgent: string,
  isLoggedIn: boolean,
  isCdpOrSrp: boolean = true
) => {
  // this function should be called on CDP or SRP only
  if (isMobile(userAgent) && !isLoggedIn && isCdpOrSrp) {
    // KIS-596 experiment is for Mobile only; only read flag on Mobile for accurate results
    // NavBar already hidden for logged-in users
    return getBooleanFlag('kis596HideFindCareNavBar');
  }
  return false;
};
