import { WhiteLabelPaletteName, whiteLabelPalettes } from '@solvhealth/jigsaw';
import Location from '@solvhealth/types/interfaces/Location';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import Booking from '@solvhealth/types/interfaces/Booking';
import { setTheme } from '~/actions/theme';
import { DAPI_HOST } from '../config';
import { ORIGIN_PARTNER_API, ORIGIN_BOOKING_WIDGET } from '~/constants';
import { apiGetJson } from '~/core/dapi';
import { useSolvSelector } from '~/reducers';
import { getPublicBookingById } from '~/core/dapi/newBooking';
import {
  PHOTO_ID_UPLOAD_ORIGIN_MOBILE_CHECK_IN,
  PHOTO_ID_UPLOAD_ORIGIN_VIDEO_VISIT,
} from '~/core/util/bookingFlowRouting';
import { getLocationUrl } from '~/core/dapi/location';
import { PaperworkSectionRoute } from '~/components/Paperwork/paperworkConstants';

/**
 * There are specific flows that should be whitelabeled, and they are:
 *  -Paperwork
 *  -Pass
 *  -Mobile Check In
 *  -Video Visit Check In
 *  -Booking waitlist
 *  -Photo Id Upload (for Paperwork, Mobile Check In, Video Visit Check in)
 * @returns boolean indicating whether the current URL is one that should be able to be whitelabeled
 */
export const isWhiteLabelableUrl = () => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    return (
      url.includes('/b/') ||
      url.includes('/pass/') ||
      url.includes('/mobile-check-in/') ||
      url.includes('/video-visit/') ||
      Object.values(PaperworkSectionRoute).some((route) => url.includes(route)) ||
      url.includes(`/${PHOTO_ID_UPLOAD_ORIGIN_VIDEO_VISIT}`) ||
      url.includes(`/${PHOTO_ID_UPLOAD_ORIGIN_MOBILE_CHECK_IN}`)
    );
  }
  return false;
};

const useLocation = (locationId: string) => {
  const { data, isLoading } = useQuery(
    ['get-location', locationId],
    () => {
      if (!locationId) {
        return null;
      }
      return apiGetJson<Location>(getLocationUrl(locationId));
    },
    {
      staleTime: 5 * 60 * 1000,
    }
  );
  return {
    location: data?.data ?? undefined,
    isLoadingLocation: isLoading,
  };
};

const usePublicBooking = (bookingId: string) => {
  const { data, isLoading } = useQuery(
    ['get-public-booking', bookingId],
    () => {
      if (!bookingId) {
        return null;
      }
      return apiGetJson<Booking>(getPublicBookingById(bookingId));
    },
    {
      staleTime: 5 * 60 * 1000,
    }
  );
  return {
    booking: data?.data ?? undefined,
    isLoadingBooking: isLoading,
  };
};

export const useGetWhiteLabelProperties = ({ bookingId }: { bookingId: string }) => {
  const { booking: currentBooking, isLoadingBooking } = usePublicBooking(bookingId);
  const { location, isLoadingLocation } = useLocation(currentBooking?.location_id);
  const dispatch = useDispatch();
  const locationId = location?.id;

  const shouldWhiteLabel = useMemo(() => {
    const bookingHasWhiteLabelOrigin =
      currentBooking?.origin === ORIGIN_PARTNER_API ||
      currentBooking?.origin === ORIGIN_BOOKING_WIDGET;
    const locationHasWhiteLabelPackage = ['professional', 'advanced', 'enterprise'].includes(
      location?.package?.name ?? ''
    );
    return bookingHasWhiteLabelOrigin && locationHasWhiteLabelPackage && isWhiteLabelableUrl();
  }, [currentBooking?.origin, location?.package?.name]);

  const { palette: currentPaletteFromState } = useSolvSelector((state) => state.theme);

  const { data, isLoading } = useQuery(
    ['get-palette-config', locationId, shouldWhiteLabel],
    () => {
      if (!locationId || !shouldWhiteLabel) {
        return null;
      }
      return apiGetJson<{ values: { palette?: WhiteLabelPaletteName } }>(
        `${DAPI_HOST}/v1/config?location_id=${locationId}&inherit_from_group=true&config_names=palette`
      );
    },
    {
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        if (data?.data?.values?.palette) {
          dispatch(setTheme(data.data.values.palette));
        } else {
          if (currentPaletteFromState !== 'crunchberry') {
            dispatch(setTheme('crunchberry'));
          }
        }
      },
    }
  );

  useEffect(() => {
    if (
      (isLoading || isLoadingBooking || isLoadingLocation) &&
      currentPaletteFromState !== 'whiteout'
    ) {
      dispatch(setTheme('whiteout'));
    }
  }, [isLoading, isLoadingBooking, isLoadingLocation, currentPaletteFromState, dispatch]);

  if (isLoading || isLoadingBooking || isLoadingLocation) {
    return {
      paletteName: 'whiteout' as WhiteLabelPaletteName,
      shouldWhiteLabel,
      palette: whiteLabelPalettes.whiteout.palette,
    };
  }

  if (!shouldWhiteLabel || !data?.data?.values?.palette) {
    return {
      paletteName: 'crunchberry' as WhiteLabelPaletteName,
      shouldWhiteLabel,
      palette: whiteLabelPalettes.crunchberry.palette,
    };
  }

  return {
    paletteName: data.data.values.palette,
    shouldWhiteLabel,
    palette: whiteLabelPalettes[data.data.values.palette].palette,
  };
};
