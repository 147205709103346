import {
  RUNTIME_AJAX_ERROR,
  RUNTIME_AJAX_PENDING,
  RUNTIME_AJAX_SUCCESS,
  RUNTIME_MODAL,
  RUNTIME_SNACKBAR,
  SET_AJAX_ERROR,
  SET_AJAX_PENDING,
  SET_RUNTIME_VARIABLE,
} from '../constants/index';

export const setRuntimeVariable = ({ name, value }: { name: string; value: unknown }) => ({
  type: SET_RUNTIME_VARIABLE,
  payload: {
    name,
    value,
  },
});

export const setAjaxPending = (value: any) => ({
  type: SET_AJAX_PENDING,
  payload: value,
});

export const setAjaxError = (value: any) => ({
  type: SET_AJAX_ERROR,
  payload: value,
});

export const showModal = (modalId: any, value = true) => ({
  type: RUNTIME_MODAL,
  payload: {
    modalId,
    value,
  },
});

export const hideModal = (modalId: any) => ({
  type: RUNTIME_MODAL,
  payload: {
    modalId,
    value: false,
  },
});

export const showSnackbar = (message: any) => ({
  type: RUNTIME_SNACKBAR,

  payload: {
    open: true,
    message,
  },
});

export const hideSnackbar = () => ({
  type: RUNTIME_SNACKBAR,
  payload: {
    open: false,
    message: null,
  },
});

export const ajaxPending = (requestId: any) => ({
  type: RUNTIME_AJAX_PENDING,

  payload: {
    requestId,
  },
});

export const ajaxSuccess = (requestId: any) => (result: any) => ({
  type: RUNTIME_AJAX_SUCCESS,

  payload: {
    requestId,
    result,
  },
});

export const ajaxError = (requestId: any) => (result: any) => ({
  type: RUNTIME_AJAX_ERROR,

  payload: {
    requestId,
    result,
  },
});
