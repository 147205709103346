import {
  REQUEST_SOLV_EMAIL_SUBMIT_SUCCESS,
  REQUEST_SOLV_EMAIL_SUBMIT_FAILURE,
  REQUEST_SOLV_EMAIL_SUBMITTED,
  GEOLOCATION_SUGGESTED,
} from '../constants/index';

export default function requestSolvEmail(state = {}, action: any) {
  switch (action.type) {
    case REQUEST_SOLV_EMAIL_SUBMITTED:
      return {
        ...state,
        emailSubmitted: true,
      };
    case REQUEST_SOLV_EMAIL_SUBMIT_SUCCESS:
      return {
        submitSuccess: true,
      };
    case REQUEST_SOLV_EMAIL_SUBMIT_FAILURE:
      return {
        submitSuccess: false,
      };
    case GEOLOCATION_SUGGESTED:
      return {
        ...state,
        geosuggestion: {
          latitude: action.payload.value.latitude,
          longitude: action.payload.value.longitude,
        },
      };
    default:
      return state;
  }
}
