import { DISTANCE_MATRIX_RESPONSE_RECEIVED, DISTANCE_MATRIX_FAILED } from '../constants/index';

export default function distanceMatrixReducer(state = {}, action: any) {
  switch (action.type) {
    case DISTANCE_MATRIX_RESPONSE_RECEIVED:
      return {
        ...state,
        results: action.payload.value,
        error: null,
      };
    case DISTANCE_MATRIX_FAILED:
      return {
        ...state,
        results: {},
        error: action.payload.value,
      };
    default:
      return state;
  }
}
