import { sanitizePhone } from '../../core/util/phoneNumber';

const TEN_SPACES = '          ';

const phoneNumberNormalizer = (value: any) => {
  let stringValue = sanitizePhone(value);

  if (stringValue.length === 0 || Number.isNaN(parseInt(stringValue, 10))) {
    return '';
  }

  stringValue = (stringValue + TEN_SPACES).substring(0, 10);
  const areaCode = stringValue.substring(0, 3);
  const localCode = stringValue.substring(3, 6);
  const digits = stringValue.substring(6, 10);

  return `(${areaCode}) ${localCode}-${digits}`;
};

const normalizeMonthFast = (value: any) => {
  switch (value) {
    case 'January':
      return '01';
    case 'February':
      return '02';
    case 'March':
      return '03';
    case 'April':
      return '04';
    case 'May':
      return '05';
    case 'June':
      return '06';
    case 'July':
      return '07';
    case 'August':
      return '08';
    case 'September':
      return '09';
    case 'October':
      return '10';
    case 'November':
      return '11';
    case 'December':
      return '12';
    default:
      return '';
  }
};

export { phoneNumberNormalizer, normalizeMonthFast };
