import React from 'react';
import styled from 'styled-components';

const Root = styled.img`
  cursor: pointer;
`;

type CloseIconProps = {
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
};

const CloseIcon = ({ onClick }: CloseIconProps) => (
  <Root alt="close" onClick={onClick} src="/images/design_system/icons/close_x.svg" />
);

export default CloseIcon;
