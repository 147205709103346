import { INSURERS_RECEIVED, INSURERS_ERROR } from '../constants/index';

const insurersReceived = (value: any) => ({
  type: INSURERS_RECEIVED,
  payload: { value },
});

const insurersError = (value: any) => ({
  type: INSURERS_ERROR,
  payload: { value },
});

export { insurersReceived, insurersError };
