import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginSignUpModal, { LOGIN_SIGN_UP_MODAL } from '~/components/LoginSignUpModal';
import DelaysModal, { DELAYS_MODAL } from '~/components/Waitlist/DelaysModal';
import {
  FullScreenPrompt,
  MissingNotificationPrompt,
  MISSING_NOTIFICATION_PROMPT_MODAL,
  FULL_SCREEN_PUSH_PROMPT_MODAL,
} from '~/components/NativePush';
import useLazyCloseModal from './hooks/useLazyCloseModal';
import useCloseLoginModal from './hooks/useCloseLoginModal';
import { isModalOpen } from './util/modal';
import ProductModal from '../Membership/ProductModal';
import { useSolvSelector } from '~/reducers';
import ExportableFilterModal from '../Search/components/ExportableFilterModal';
import UserGeoLocationSelector from '../AccountGetsMarried/components/UserGeoLocationSelector';
import VideoVisitExplainerDialog from '../CareTeam/VideoVisitExplainerDialog';

export const SEARCH_MODAL = 'search-modal';

export const ModalController = () => {
  const login = useSolvSelector((state) => state.login);
  const runtime = useSolvSelector((state) => state.runtime);

  const closeModal = useLazyCloseModal();

  useCloseLoginModal(runtime, closeModal);

  const { t } = useTranslation(['account', 'common']);

  return (
    <>
      <LoginSignUpModal
        login={login}
        onClose={closeModal(LOGIN_SIGN_UP_MODAL)}
        open={isModalOpen(runtime.modal, LOGIN_SIGN_UP_MODAL)}
        runtime={runtime}
      />
      <DelaysModal
        onClose={closeModal(DELAYS_MODAL)}
        open={isModalOpen(runtime.modal, DELAYS_MODAL)}
      />

      <FullScreenPrompt
        onClose={closeModal(FULL_SCREEN_PUSH_PROMPT_MODAL)}
        open={isModalOpen(runtime.modal, FULL_SCREEN_PUSH_PROMPT_MODAL)}
      />

      <MissingNotificationPrompt
        onClose={closeModal(MISSING_NOTIFICATION_PROMPT_MODAL)}
        open={isModalOpen(runtime.modal, MISSING_NOTIFICATION_PROMPT_MODAL)}
      />

      <ProductModal />

      <ExportableFilterModal
        closeModal={closeModal(SEARCH_MODAL)}
        HeaderProps={{
          onCancel: closeModal(SEARCH_MODAL),
          cancelCopy: t('common:cancel'),
          confirmCopy: t('common:search'),
        }}
        modalOpen={isModalOpen(runtime.modal, SEARCH_MODAL)}
        showSolvPlusEntrypoint
        title={t('account:searchModal.titles.findCare')}
      />
      <UserGeoLocationSelector />
      <VideoVisitExplainerDialog />
    </>
  );
};
