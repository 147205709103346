/**
 * Formats a number in cents as a US currency
 *
 * @param amount The integer amount in cents
 * @returns A formatted string
 */
export function centsToCurrencyStr(amount: number): string {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount / 100);
}
