import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Button, { ButtonVariations } from '../SolvPatternLibrary/Button';
import { fontFamily, fontSize } from '../../constants/text';
import { colors } from '../../constants/colors';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  background-color: ${colors.white};
`;

const Action = styled(Button)`
  height: 32px;
  width: 68px;
  padding: 0;
  color: ${colors.crunchBerry} !important;
  font-family: ${fontFamily.book};
  font-size: ${fontSize.default};
`;

const Back = styled(ChevronLeft)`
  color: ${colors.crunchBerry};
`;

const Title = styled.h3`
  font-family: ${fontFamily.bold};
  font-size: ${fontSize.mediumLarge};
  color: ${colors.squid};
`;

type OwnProps = {
  confirmDisabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmCopy?: string;
  cancelCopy?: string;
  showBackArrow?: boolean;
  title: string;
};

type Props = OwnProps & typeof Header.defaultProps;

export default class Header extends PureComponent<Props> {
  static defaultProps = {
    confirmCopy: 'Done',
    cancelCopy: 'Back',
    showBackArrow: true,
  };

  render() {
    const { title, showBackArrow, confirmDisabled, onCancel, onConfirm, confirmCopy, cancelCopy } =
      this.props;

    return (
      <Root>
        <Action onClick={onCancel} variation={ButtonVariations.link}>
          {showBackArrow && <Back />} {cancelCopy}
        </Action>

        <Title>{title}</Title>

        <Action disabled={confirmDisabled} onClick={onConfirm} variation={ButtonVariations.link}>
          {confirmCopy}
        </Action>
      </Root>
    );
  }
}
