import React, { useCallback, useRef, useState } from 'react';
import type { CheckboxWithInputOption } from '@solvhealth/types/interfaces/PaperworkField';
import { Stack } from '@solvhealth/jigsaw';
import CheckboxCardWithInput from './CheckboxCardWithInput';
import { EMPTY_ARRAY } from '~/core/util/array';

type CheckboxCardWithInputGroupProps = {
  onChange: (value: CheckedOption[]) => void;
  error?: boolean;
  options: CheckboxWithInputOption[];
  value?: CheckedOption[];
};

interface CheckedOption {
  item: string;
  info: string;
}

const CheckboxCardWithInputGroup = (props: CheckboxCardWithInputGroupProps): React.ReactElement => {
  const { onChange, error, options, value = EMPTY_ARRAY } = props;
  const [disableCheckboxes, setDisableCheckboxes] = useState<boolean>(false);
  const [checkedOptions, setCheckedOptions] = useState<CheckedOption[]>(value);
  const checkedOptionsRef = useRef(checkedOptions);
  checkedOptionsRef.current = checkedOptions;

  const handleCheckboxCardAndInputChange = useCallback(
    (checked: boolean, item: string, info: string, isExclusive: boolean) => {
      let updatedCheckedOptions = [...checkedOptionsRef.current];

      const index = checkedOptionsRef.current.findIndex(
        (option: CheckedOption) => option.item === item
      );

      if (checked) {
        if (index < 0) {
          const newField = { item, info };
          updatedCheckedOptions = isExclusive ? [newField] : [...updatedCheckedOptions, newField];
        } else {
          updatedCheckedOptions[index].info = info;
        }
      } else {
        updatedCheckedOptions.splice(index, 1);
      }

      onChange(updatedCheckedOptions);
      setCheckedOptions(updatedCheckedOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack space={3}>
      {options.map((option: CheckboxWithInputOption, index: number) => {
        const checkboxCardTitle = typeof option === 'string' ? option : option.label;
        const inputLabel = typeof option === 'string' ? undefined : option.nested_field_label;
        const isExclusive = typeof option === 'string' ? false : !!option.is_exclusive;
        const checkedOption = checkedOptions.find(
          (option: CheckedOption) => option.item === checkboxCardTitle
        );
        return (
          <CheckboxCardWithInput
            checkboxCardTitle={checkboxCardTitle}
            checked={!!checkedOption}
            disabled={disableCheckboxes && !isExclusive}
            error={error}
            handleCheckboxCardAndInputChange={handleCheckboxCardAndInputChange}
            inputLabel={inputLabel}
            inputValue={checkedOption ? checkedOption.info : ''}
            isExclusive={isExclusive}
            key={index}
            setDisableCheckboxes={setDisableCheckboxes}
          />
        );
      })}
    </Stack>
  );
};

export default CheckboxCardWithInputGroup;
