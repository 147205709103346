import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Stack } from '@solvhealth/jigsaw';
import { FormattedResponseOption } from '~/components/Form/types/types';
import { STRING_TRUE } from '~/core/util/string';
import { colors } from '~/constants/colors';
import { handleCheckboxChange } from '~/components/Form/CheckboxGroup/util';

type Props = {
  options: FormattedResponseOption[];
  onChange?: (...args: any[]) => any;
  value?: any[];
  error?: boolean;
};

const StyledCheckbox = styled(Checkbox)<{ error?: string | null }>`
  ${(props) =>
    props.error === STRING_TRUE &&
    css`
      svg .checkbox-border {
        color: ${colors.ladyInRed};
      }
    `};
`;

const CheckboxGroupField = (props: Props) => {
  const { value, options, onChange, error } = props;
  const [values, setValues] = useState(value);

  return (
    <Stack>
      {options.map((option) => (
        <StyledCheckbox
          checked={values ? values.indexOf(option.key) > -1 : false}
          error={error ? STRING_TRUE : null}
          key={option.key}
          label={option.label}
          onClick={() => handleCheckboxChange(option.key, setValues, values, onChange)}
        />
      ))}
    </Stack>
  );
};

export default CheckboxGroupField;
