import { comparator } from './string';

const EMPTY_ARRAY: any[] = [];

/**
 * Is the given array an untruthy value or is the array have length 0
 *
 * @deprecated use lodash isEmpty
 * @param {Array} array
 * @returns {boolean}
 */
const isEmptyArray = (array: any) => !Array.isArray(array) || array.length < 1;

/**
 * Return the last element of the provided array.
 *
 * @param {Array} array
 * @returns {*}
 */
const getLastElement = (array: any) => array[array.length - 1];

const shuffle = (array: any) => {
  // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
  let i;
  let j = 0;
  let temp = null;

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j]; // eslint-disable-line no-param-reassign
    array[j] = temp; // eslint-disable-line no-param-reassign
  }

  return array;
};

/**
 * Sort an array alphabetically.
 *
 * While the sorted array is returned, note that the array is
 * sorted IN PLACE! This creates side effects
 *
 * @param {Array} array
 * @param {function({any}): string} getStringForComparison
 * @returns {Array}
 */
const sortAlphabetical = <T>(array: Array<T>, getStringForComparison: (p: T) => string): Array<T> =>
  array.sort((item1: T, item2: T) => {
    const str1 = getStringForComparison(item1).toLowerCase();
    const str2 = getStringForComparison(item2).toLowerCase();
    return comparator(str1, str2);
  });

/**
 * Convert an array of objects into an object of objects.
 * [{...a}, ..., {...n}] => {a: {...a}, ..., n: {...n}}
 *
 * @param {Array} array
 * @param {string} key
 * @returns {Object}
 */
const arrayToObject = (array: any, key = 'id') => {
  const object: Record<any, any> = {};
  if (isEmptyArray(array)) return object;

  array.forEach((a: any) => {
    object[a[key]] = a;
  });

  return object;
};

/**
 * Create a range from the configuration.
 *
 * Range generation is inclusive of start and end values.
 *
 * @example ```
 * range(0, 5)
 * >>> [0, 1, 2, 3, 4, 5]
 *
 * range(0, 5, 2)
 * >>> [0, 2, 4]
 * ```
 * @param start
 * @param end
 * @param step
 */
const range = (start: number, end: number, step: number = 1) => {
  const arr: number[] = [];

  for (let i = start; i <= end; i += step) {
    arr.push(i);
  }

  return arr;
};

/**
 *
 * @param arr
 * @param finalElementDelimiter
 */
const buildListStringWithDelimiterFromArray = (arr: any[], finalElementDelimiter = 'and') => {
  // use this if you have an array that you need to convert to a list string e.g. "this, that, and that other thing"
  if (isEmptyArray(arr)) return '';

  return `${arr.slice(0, -1).join(', ')}, ${finalElementDelimiter} ${arr.slice(-1)}`;
};

/**
 * Get a Matrix
 */
export function matrix(width: number, height: number, fill = 0) {
  const matrix = Array(width);
  for (let i = 0; i < width; i++) {
    matrix[i] = Array(height).fill(fill);
  }
  return matrix;
}

export {
  shuffle,
  arrayToObject,
  isEmptyArray,
  getLastElement,
  EMPTY_ARRAY,
  sortAlphabetical,
  range,
  buildListStringWithDelimiterFromArray,
};
