import { apiGetJsonBlocking } from '../dapi';
import { isEmptyObject } from '../../core/util/object';
import { isEmptyArray } from '../../core/util/array';
import { getLocationsCityUrl } from '../dapi/location';
import { fromStateCodeToState } from '../../core/util/seo';
import { toUpperCase, toTitleCase, isEmptyString } from '../../core/util/string';
import { decodeCityName } from '../../core/util/location';
import { getSearchDataFromQueryString } from '../util/search';
import { safeGet } from '../util/object';
import { getProviderTypeName, URGENT_CARE_VALUE } from '../../components/Home/Tiles/ProviderTypes';
import { pluralize } from '../util/string';

export const DEFAULT_IMAGE = '/images/opengraph.png';
const SMS_IMAGE = '/images/rating_text.png';

export const HOMEPAGE_TITLE = 'Solv: Find & Book Same-Day Urgent Care Near You';
export const HOMEPAGE_DESCRIPTION =
  'Find and book same-day appointments for top-rated urgent cares, lab tests and telemed. Download the Solv app today! Convenient care, simplified with Solv.';
const DEFAULT_TITLE = HOMEPAGE_TITLE;
const SMS_TITLE = 'Your experience is what matters most to us';
const DEFAULT_DESCRIPTION = HOMEPAGE_DESCRIPTION;

// Locations Directory
const LOCATIONS_DIRECTORY_TITLE = 'Urgent Care Locations on Solv';
const LOCATIONS_DIRECTORY_DESCRIPTION =
  'Search hundreds of urgent care centers and medical ' +
  'clinics. We help you find and book a no-wait appointment and make sure the urgent care takes ' +
  'your insurance.';

const getStatePageTitle = (stateName: any) => `${stateName} Urgent Care and Medical Clinics | Solv`;
const getStatePageDescription = (stateName: any, numLocations: any) =>
  `${stateName} urgent care, medical ` +
  "clinics and walk-in clinics provide same-day access to doctor's appointments. There are more " +
  `than ${numLocations} locations in ${stateName} on Solv where you can find an urgent care near ` +
  'you and book an appointment.';

const getCityPageTitle = (cityName: any) => `${cityName} Urgent Care and Medical Clinics | Solv`;
const getCityPageDescription = (stateCode: any, cityName: any, numLocations: any) =>
  `Find an urgent care, medical clinic or walk-in clinic in ${cityName}, ${stateCode}. Select from ` +
  `${numLocations} locations in ${cityName} to find an urgent care center near you and book an ` +
  'appointment.';

export const DFW_LANDING_PAGE_TITLE = 'Solv: Book Same-Day Appointments With Great Doctors In DFW';
export const DFW_LANDING_PAGE_DESCRIPTION =
  "Find locally loved doctors who'll take your insurance and see you this week.";

export const hasLogo = (location: any) => !!location?.imageLinks?.logo;

const isSMSExperiment = (url: any) => /^\/t\/s\//.test(url);

/**
 * Check that the requested url is a wait list url
 * url should start with /l/ or /b/
 *
 * @param url {string} request url
 * @returns {boolean}
 */
const isWaitList = (url: any) => /^\/l\//.test(url) || /^\/b\//.test(url);

/**
 * Check that the requested url is a tracked url
 * url should start with /t/
 *
 * @param url {string} request url
 * @returns {boolean}
 */
const containsTracking = (url: any) => /^\/t\//.test(url);

/**
 * Check that the requested url is a confirmation pass
 * url should start with /r/ (reminder) or /c/ (short for confirmation) or /pass
 *
 * @param url {string} request url
 * @returns {boolean}
 */
const isConfirmationPass = (url: any) =>
  /^\/r\//.test(url) || /^\/c\//.test(url) || /^\/pass\//.test(url);

/**
 * Check that the requested url is a booking widget url
 * url should start with /book-online/
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isBookingWidget = (url: any) => /^\/book-online\//.test(url) && !/confirm/.test(url);

/**
 *  Check that the requested url is inside the Solv booking flow or Solv Now booking flow
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isBookingFlow = (url: string) => /^\/book\//.test(url);

/**
 * Check that the requested url is a CDP url
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isCdp = (url: any) =>
  /^(?:\/([a-z0-9-]{0,}?))?-([a-z0-9]{6,})(?:\/(?=$))?(\?.*)?$/i.test(url);

/**
 * Check that the requested url is a Provider booking page url
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isProviderGroupProviderPage = (url: any) =>
  /^\/provider\/(?:([a-z0-9-]{0,}?))?-([a-z0-9]{6,})(?:\/(?=$))?(\?.*)?$/i.test(url);

/**
 * Check that the requested url is a isProviderGroupSpecialtyPage
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isProviderGroupSpecialtyPage = (url: any) =>
  /^\/group\/(?:([a-z0-9-]{0,}?))?-([a-z0-9]{6,})(?:\/(?=$))?(\?.*)?$/i.test(url);

/**
 * Check that the requested url is a pg location page url
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isProviderGroupLocationPage = (url: any) =>
  /^\/location\/(?:([a-z0-9-]{0,}?))?-([a-z0-9]{6,})(?:\/(?=$))?(\?.*)?$/i.test(url);

/**
 * Check that the requested url the homepage
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isHomepage = (url: any) => /^\/$/.test(url);

/**
 * Check that the requested url the homepage
 *
 * @param url {string} request url
 * @returns {boolean}
 */
export const isSRP = (url: any) => /^\/search?(\?.*)?$/.test(url);

const isStatePage = (url: any) => /^\/locations\/([a-z]){2}\/([a-z-]*)-urgent-care/.test(url);

const isCityPage = (url: any) =>
  /^\/locations\/([a-z]){2}\/([a-z-])+\/([a-z-]*)-urgent-care/.test(url);

const isDfwLandingPage = (url: any) => /^\/dfw$/.test(url);

/**
 * Rip the location id from a /book-online/ url
 *
 * @param url {string} request url
 * @returns {string}
 */
const getLocIdFromBookOnline = (url: any) => /^\/book-online\/(.*?)(?:\s|$)/g.exec(url);

/**
 * Rip the location id from a CDP url
 *
 * @param url {string} request url
 * @returns {string}
 */
const getLocIdFromCdpUrl = (url: any) =>
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  /^(?:\/([a-z0-9-]{0,}?))?-([a-z0-9]{6,})(?:\/(?=$))?$/i.exec(url)[2];

/**
 * Gets both the title and image url for open graph data
 * Needs to be async because of the book online requesting from dapi the location data
 *
 * @param req {object} the request
 * @returns {object} open graph data
 */
export async function openGraphData(req: any, location: any) {
  if (isHomepage(req.url)) {
    return {
      image: DEFAULT_IMAGE,
      title: HOMEPAGE_TITLE,
      description: HOMEPAGE_DESCRIPTION,
      bookingWidget: false,
    };
  }

  if (isSRP(req.url)) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    const searchParams = getSearchDataFromQueryString(req.url);
    const providerType = pluralize(
      2,

      getProviderTypeName(safeGet(searchParams, URGENT_CARE_VALUE)('providerType'))
    );

    let locationLabel = safeGet(searchParams, '')('searchLocationPreferences.label');
    if (!isEmptyString(locationLabel)) {
      locationLabel = locationLabel.split(',').join(', ');
    }

    return {
      image: DEFAULT_IMAGE,
      title: `${providerType} ${locationLabel && `near ${locationLabel}`} | Solv`,
      description: `Find ${providerType} ${
        locationLabel && `near ${locationLabel}`
      } and book online today.`,
      bookingWidget: false,
    };
  }

  if (isStatePage(req.url)) {
    const stateCode = req.url.split('/')[2];
    const stateName = toTitleCase(fromStateCodeToState(stateCode));

    return {
      title: getStatePageTitle(stateName),
      description: getStatePageDescription(stateName, 1),
      bookingWidget: false,
      cdp: false,
    };
  }

  if (isCityPage(req.url)) {
    const stateCode = toUpperCase(req.url.split('/')[2]);
    const cityName = toTitleCase(decodeCityName(req.url.split('/')[3]));
    const data = await apiGetJsonBlocking(getLocationsCityUrl(stateCode, cityName));
    let numLocations = 1;
    if (data && data.data && data.data.page && data.data.page.results_count) {
      numLocations = data.data.page.results_count;
    }

    return {
      title: getCityPageTitle(cityName),
      description: getCityPageDescription(stateCode, cityName, numLocations),
      bookingWidget: false,
      cdp: false,
    };
  }

  if (isSMSExperiment(req.url)) {
    return {
      image: SMS_IMAGE,
      title: SMS_TITLE,
      bookingWidget: false,
    };
  }

  // SOLV-593 SOLV-809: confirmation pass or waitlist should not have open graph
  if (isConfirmationPass(req.url) || isWaitList(req.url) || containsTracking(req.url)) {
    return {};
  }

  // SOLV-6554: in order to remove duplicate location call to fetch opengraph data for booking widget
  // moving the open graph generation via helmet like we do with the CDP. See OpenGraph component and Canonical
  if (
    isCdp(req.url) ||
    isBookingWidget(req.url) ||
    isProviderGroupProviderPage(req.url) ||
    isProviderGroupSpecialtyPage(req.url) ||
    isProviderGroupLocationPage(req.url)
  ) {
    return {
      image: null,
      url: null,
      title: null,
      description: null,
      locationUrl: null,
      pageTitle: null,
    };
  }

  if (isDfwLandingPage(req.url)) {
    return {
      image: DEFAULT_IMAGE,
      title: DFW_LANDING_PAGE_TITLE,
      description: DFW_LANDING_PAGE_DESCRIPTION,
      bookingWidget: false,
    };
  }

  return {
    image: DEFAULT_IMAGE,
    title: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
    bookingWidget: false,
  };
}
