export const VERIFY_PROFILE_SUBMITTING = 'sagas/VERIFY_PROFILE_VERIFICATION_SUBMITTING';
export const VERIFY_PROFILE_SUCCEEDED = 'sagas/VERIFY_PROFILE_VERIFICATION_SUCCEEDED';
export const VERIFY_PROFILE_FAILED = 'sagas/VERIFY_PROFILE_FAILED';

// actions
export const verifyProfileSubmitting = () => ({
  type: VERIFY_PROFILE_SUBMITTING,
});

export const verifyProfileSucceeded = (value: any) => ({
  type: VERIFY_PROFILE_SUCCEEDED,
  payload: { value },
});

export const verifyProfileFailed = (value: any) => ({
  type: VERIFY_PROFILE_FAILED,
  payload: { value },
});

// reducers
export default (state = {}, action: any) => {
  switch (action.type) {
    case VERIFY_PROFILE_SUBMITTING:
      return {
        ...state,
        isVerificationSubmitting: true,
        verificationResponse: null,
      };
    case VERIFY_PROFILE_SUCCEEDED:
      return {
        ...state,
        isVerificationSubmitting: false,
        verificationResponse: action.payload.value,
        verificationError: null,
      };
    case VERIFY_PROFILE_FAILED:
      return {
        ...state,
        isVerificationSubmitting: false,
        verificationError: action.payload.value,
      };
    default:
      return state;
  }
};
