import Group from '@solvhealth/types/interfaces/Group';
import Provider from '@solvhealth/types/interfaces/Provider';
import LocationInterface from '@solvhealth/types/interfaces/Location';
import { getDevice, getOs } from '../../util/device/index';
import { apiPostJson } from '~/core/dapi';
import { isServerSide } from '../../util/system';
import { isBookable, isInMarketPlace, isSolvPartner, hasBoost } from '../../util/location';
import { isEmpty } from '../../util/empty';
import { PRODUCT_PLACEMENT, PRODUCT_TYPE, PageType } from './constants';
import { TRACKING_API } from '../../../config/index';
import { isEmptyString } from '../../util/string';
import { MARKETPLACE_DFW, PATIENT_TYPE_ADULTS } from '../../../constants/index';
import { URGENT_CARE_VALUE } from '~/components/Home/Tiles/ProviderTypes';
import { isEmptyObject } from '../../util/object';
import { UtmSourceTypes } from '~/constants/dapi/tracking';
import { getTrackingUrl } from '../../dapi/tracking';
import { filterSlotsByAvailability, getSlotsForTodayAndTomorrow } from '~/core/location/slots';

export type ImpressionData = {
  referrer: string;
  ip: string;
  accountId?: string;
  userProfileId?: string;
  sessionID: string;
  browserLocationAllowed: boolean;
};

const TRACKING_API_URL = `${TRACKING_API.host}/api/v1/track`;
const isTrackingEnabled = !isEmptyString(TRACKING_API.enabled) && TRACKING_API.enabled === 'true';

const getLocationFlags = (location: LocationInterface) => ({
  is_solv_partner: isSolvPartner(location),
  is_in_marketplace: isInMarketPlace(location),
  is_bookable: isBookable(location),
  has_boost: hasBoost(location),
  is_performance_pricing_enabled: location.isPerformancePricingEnabled,
  platform_type: location.platformType,
  distance_from_search_location: location.distanceFromCurrentLocation,
});

export const IMPRESSION_TYPE_BLUR_INTENT = 'blur-intent';
export const IMPRESSION_TYPE_SEARCH_INTENT = 'search-intent';
export const IMPRESSION_TYPE_SELECT_SUGGESTION = 'select-suggestion';
export const IMPRESSION_TYPE_REDIRECT_TO_CDP = 'redirect-to-cdp';

const trackSearchIntentImpression = (props: any) => {
  if (!isTrackingEnabled) {
    return;
  }

  const {
    query,
    selectedDoc,
    suggestionList,
    suggestionAction,
    trackingProperties,
    searchLocationLabel,
    searchLatLong,
    requestedAppointmentDatetime,
    userSelectedSuggestion = false,
    redirectToUserChoice,
    isSearchEnabled,
    inMarket,
    patientType = PATIENT_TYPE_ADULTS,
    providerType = URGENT_CARE_VALUE,
    impressionType = IMPRESSION_TYPE_SEARCH_INTENT,
    productPlacement = PRODUCT_PLACEMENT.MODAL,
  } = props;

  const postData = {
    url_source: !isServerSide() ? window.location.href : '',
    suggestion_list: suggestionList,
    suggestion_action: JSON.stringify(suggestionAction),
    results_page: 1,
    search_location_label: searchLocationLabel,
    search_location_lat_long: searchLatLong,
    requested_appointment_datetime: requestedAppointmentDatetime,
    did_user_select_suggestion: userSelectedSuggestion,
    patient_type: patientType,
    provider_type: providerType,
    impression_type: impressionType,
    input_string: query,
    product_type: PRODUCT_TYPE.UNIVERSAL_SEARCH,
    url_referral: trackingProperties.referrer,
    ip_address: trackingProperties.ip,
    os: getOs().userOS,
    device: getDevice(),
    product_placement: productPlacement,
    account_id: trackingProperties.accountId,
    user_profile_id: trackingProperties.userProfileId,
    session_id: trackingProperties.sessionID,
  };

  if (selectedDoc) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selected_suggestion' does not exist on t... Remove this comment to see the full error message
    postData.selected_suggestion = selectedDoc.name;
  }

  if (inMarket) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'market' does not exist on type '{ url_so... Remove this comment to see the full error message
    postData.market = MARKETPLACE_DFW;
  }

  if (!isEmpty(redirectToUserChoice)) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirect_to_user_choice' does not exist ... Remove this comment to see the full error message
    postData.redirect_to_user_choice = redirectToUserChoice;
  }

  if (!isEmpty(isSearchEnabled)) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'is_search_enabled' does not exist on typ... Remove this comment to see the full error message
    postData.is_search_enabled = isSearchEnabled;
  }

  apiPostJson(`${TRACKING_API_URL}/search-intent/send`, postData);
};

const trackPageImpression = (
  trackingObject: LocationInterface | Group | Provider,
  properties: ImpressionData,
  slots: any,
  productType: string,
  pageType: PageType
) => {
  if (!isTrackingEnabled) {
    return;
  }

  const postData: { [k: string]: any } = {
    url_source: !isServerSide() ? window.location.href : '',
    product_type: productType,
    url_referral: properties.referrer,
    ip_address: properties.ip,
    os: getOs().userOS,
    device: getDevice(),
    product_placement: PRODUCT_PLACEMENT.PAGE,
    account_id: properties.accountId,
    user_profile_id: properties.userProfileId,
    session_id: properties.sessionID,
    locations_slots: JSON.stringify(slots),
    browser_location_allowed: properties.browserLocationAllowed,
  };

  if (pageType === PageType.GROUP) {
    postData.group_id = trackingObject.id;
  }
  if (pageType === PageType.PROVIDER) {
    postData.provider_id = trackingObject.id;
  }
  if (pageType === PageType.LOCATION) {
    const locationFlags = getLocationFlags(trackingObject as LocationInterface);
    postData.location_flags = JSON.stringify(locationFlags);
    postData.location_id = trackingObject.id;
  }

  apiPostJson(`${TRACKING_API_URL}/${pageType}s/pageview`, postData);
};

const trackSearchResultSlotClick = (
  location: LocationInterface,
  properties: ImpressionData,
  slots: any
) => {
  if (!isTrackingEnabled) {
    return;
  }

  const postData: { [k: string]: any } = {
    account_id: properties.accountId,
    device: getDevice(),
    ip_address: properties.ip,
    location_flags: JSON.stringify(getLocationFlags(location)),
    location_id: location.id,
    os: getOs().userOS,
    product_placement: PRODUCT_PLACEMENT.MODULE,
    product_type: PRODUCT_TYPE.SEARCH_RESULT_SLOT,
    session_id: properties.sessionID,
    slots: JSON.stringify(slots),
    url_referral: properties.referrer,
    url_source: window.location.href,
    user_profile_id: properties.userProfileId,
  };

  apiPostJson(`${TRACKING_API_URL}/locations/click`, postData);
};

const trackUniversalSearchLocation = (
  locationsList: any,
  props: any,
  productType = PRODUCT_TYPE.UNIVERSAL_SEARCH
) => {
  if (!isTrackingEnabled) {
    return;
  }

  const postData = {
    url_source: !isServerSide() ? window.location.href : '',
    location_ids_list: locationsList.map((location: any) => location.id),
    locations_flags: locationsList.map(getLocationFlags),
    product_type: productType,
    url_referral: props.referrer,
    ip_address: props.ip,
    os: getOs().userOS,
    device: getDevice(),
    account_id: props.accountId,
    user_profile_id: props.userProfileId,
    product_placement: PRODUCT_PLACEMENT.DROP_DOWN,
    results_page: 1,
    session_id: props.sessionID,
  };

  apiPostJson(`${TRACKING_API_URL}/locations/result`, postData);
};

const trackNearbyModule = (
  locationsList: any,
  props: any,
  productType = PRODUCT_TYPE.NEARBY_MODULE
) => {
  if (!isTrackingEnabled) {
    return;
  }

  const postData = {
    url_source: !isServerSide() ? window.location.href : '',
    location_ids_list: locationsList.map((location: any) => location.id),
    locations_flags: locationsList.map(getLocationFlags),
    product_type: productType,
    url_referral: props.referrer,
    ip_address: props.ip,
    os: getOs().userOS,
    device: getDevice(),
    account_id: props.accountId,
    user_profile_id: props.userProfileId,
    product_placement: PRODUCT_PLACEMENT.MODULE,
    results_page: 1,
    session_id: props.sessionID,
  };

  apiPostJson(`${TRACKING_API_URL}/locations/result`, postData);
};

const trackSearchResults = (locationsList: any, props: any, productType = PRODUCT_TYPE.SRP) => {
  if (!isTrackingEnabled) {
    return;
  }

  const postData = {
    url_source: !isServerSide() ? window.location.href : '',
    location_ids_list: locationsList.map((location: any) => location.id),
    location_distances_from_search_location: locationsList.map(
      (location: any) => location.distanceFromCurrentLocation
    ),
    locations_slots: locationsList.map((location: any) => location.slots),
    locations_flags: locationsList.map(getLocationFlags),
    product_type: productType,
    url_referral: props.referrer,
    ip_address: props.ip,
    os: getOs().userOS,
    device: getDevice(),
    account_id: props.accountId,
    user_profile_id: props.userProfileId,
    product_placement: PRODUCT_PLACEMENT.PAGE,
    results_page: props.page,
    session_id: props.sessionID,
  };

  apiPostJson(`${TRACKING_API_URL}/locations/result`, postData);
};

const trackLocationFavoritesFromAccount = (
  locationsList: any,
  props: any,
  productType = PRODUCT_TYPE.FAVORITE
) => {
  if (!isTrackingEnabled) {
    return;
  }

  const postData = {
    url_source: !isServerSide() ? window.location.href : '',
    location_ids_list: locationsList.map((location: any) => location.id),
    locations_flags: locationsList.map(getLocationFlags),
    locations_slots: locationsList.map((location: any) => location.slots),
    product_type: productType,
    url_referral: props.referrer,
    ip_address: props.ip,
    os: getOs().userOS,
    device: getDevice(),
    account_id: props.accountId,
    user_profile_id: props.userProfileId,
    product_placement: PRODUCT_PLACEMENT.MODULE,
    results_page: 1,
    session_id: props.sessionID,
  };

  apiPostJson(`${TRACKING_API_URL}/locations/result`, postData);
};

const shouldTrackCDPImpression = (props: any) => {
  if (isSolvPartner(props.location) && isEmpty(props.location.slots)) {
    return false;
  }

  return props.persistentStateLoaded;
};

const shouldTrackGoogleReservePageViewImpression = (context: any) =>
  !isEmptyObject(context.query) &&
  !isEmpty(context.query.utm_source) &&
  context.query.utm_source === UtmSourceTypes.GOOGLE_RESERVE;

const trackGoogleReservePageViewImpression = (
  location: any,
  sessionTrackingProperties: any,
  contextQuery: any,
  productType: any
) => {
  apiPostJson(getTrackingUrl('locations', location.id, 'action.google_reserve_pageview'), {
    ...sessionTrackingProperties,
    ...contextQuery,
    productType,
  });
};

const getSlotCounts = (
  location: LocationInterface,
  associatedTelemedLocation?: LocationInterface
) => {
  let numSlotsAvailableToday = 0;
  let numSlotsAvailableTomorrow = 0;
  if (isBookable(location)) {
    const { today: todaySlots, tomorrow: tomorrowSlots } = getSlotsForTodayAndTomorrow(
      location,
      true
    );
    numSlotsAvailableToday = filterSlotsByAvailability(todaySlots).length;
    numSlotsAvailableTomorrow = filterSlotsByAvailability(tomorrowSlots).length;
  }

  let numSlotsAvailableTodayAssociatedTelemed = 0;
  let numSlotsAvailableTomorrowAssociatedTelemed = 0;
  if (associatedTelemedLocation && isBookable(associatedTelemedLocation)) {
    const { today: todaySlotsAssociatedTelemed, tomorrow: tomorrowSlotsAssociatedTelemed } =
      getSlotsForTodayAndTomorrow(associatedTelemedLocation, true);
    numSlotsAvailableTodayAssociatedTelemed = filterSlotsByAvailability(
      todaySlotsAssociatedTelemed
    ).length;
    numSlotsAvailableTomorrowAssociatedTelemed = filterSlotsByAvailability(
      tomorrowSlotsAssociatedTelemed
    ).length;
  }
  return {
    numSlotsAvailableToday,
    numSlotsAvailableTomorrow,
    numSlotsAvailableTodayAssociatedTelemed,
    numSlotsAvailableTomorrowAssociatedTelemed,
  };
};

export {
  getSlotCounts,
  trackNearbyModule,
  trackPageImpression,
  trackUniversalSearchLocation,
  trackSearchResults,
  trackSearchIntentImpression,
  trackSearchResultSlotClick,
  shouldTrackCDPImpression,
  shouldTrackGoogleReservePageViewImpression,
  trackLocationFavoritesFromAccount,
  trackGoogleReservePageViewImpression,
};
