import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGet } from '../core/dapi';
import { getPreVisitAndInVisitPublicBookingsByLocationUrl } from '../core/dapi/bookings';
import { setNumberOfBookingsAhead } from '../ducks/videoVisit';

export class VideoVisitSagas {
  static GET_NUMBER_OF_BOOKINGS_AHEAD = 'sagas/GET_NUMBER_OF_BOOKINGS_AHEAD';
}

function* getNumberOfBookingsAhead({ ownBooking }: any) {
  try {
    const bookings = yield call(
      apiGet,
      getPreVisitAndInVisitPublicBookingsByLocationUrl(ownBooking.location_id)
    );
    const bookingsAhead = bookings.results.findIndex(
      (booking: any) => booking.id === ownBooking.id
    );
    yield put(setNumberOfBookingsAhead(bookingsAhead));
  } catch (e) {
    yield put(setNumberOfBookingsAhead(0));
  }
}

export default function* rootSaga() {
  yield takeEvery(VideoVisitSagas.GET_NUMBER_OF_BOOKINGS_AHEAD, getNumberOfBookingsAhead);
}
