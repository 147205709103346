import { takeEvery, call, put } from 'redux-saga/effects';
import {
  verifyProfileSubmitting,
  verifyProfileFailed,
  verifyProfileSucceeded,
} from '../ducks/verifyProfile';
import { verifyProfileLastName, getUserProfileById } from '../core/dapi/userProfile';
import { receiveUserProfileForAccount, receiveAccountSummary } from '../actions/account';
import { apiPatch, apiPost, apiGet } from '../core/dapi';
import { analyticsTrackEvent } from '../core/analytics';
import { VERIFY_PROFILE_VERIFICATION_SUCCESS } from '../core/analytics/events';
import { getAccountSummaryById } from '../core/dapi/account';
import logger from '../core/logger/index';

export class VerifyProfileSagas {
  static VERIFY_LAST_NAME = 'sagas/VERIFY_PROFILE_LAST_NAME';

  static HIDE_UNRECOGNIZED_PROFILE = 'sagas/VERIFY_PROFILE_HIDE_UNRECOGNIZED';
}

function* verifyLastName({ accountId, userProfileId, lastNameInput, onSuccess }: any) {
  try {
    yield put(verifyProfileSubmitting());
    const verifyLastNameUrl = verifyProfileLastName(userProfileId);
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const verifyLastNameResponse = yield call(apiPost, verifyLastNameUrl, {
      last_name: lastNameInput,
    });

    if (verifyLastNameResponse.is_match) {
      yield put(verifyProfileSucceeded(verifyLastNameResponse));
      analyticsTrackEvent(VERIFY_PROFILE_VERIFICATION_SUCCESS, {
        profile_id: userProfileId,
        account_id: accountId,
      });

      const userProfileUrl = getUserProfileById(userProfileId);
      // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
      const userProfileResponse = yield call(apiPatch, userProfileUrl, { is_verified: true });
      yield put(receiveUserProfileForAccount(userProfileResponse));

      // need to refetch the account summary so any previously unverified insurance profiles become available
      const accountSummaryUrl = getAccountSummaryById(accountId);
      const accountSummaryResponse = yield call(apiGet, accountSummaryUrl);
      yield put(receiveAccountSummary(accountSummaryResponse));

      if (onSuccess) onSuccess();
    } else {
      yield put(verifyProfileFailed(verifyLastNameResponse));
    }
  } catch (e) {
    logger.info(e);
    yield put(verifyProfileFailed(e));
  }
}

function* hideUnrecognizedUserProfile({ userProfileId, onSuccess }: any) {
  try {
    const userProfileUrl = getUserProfileById(userProfileId);
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const userProfileResponse = yield call(apiPatch, userProfileUrl, { is_hidden: true });
    yield put(receiveUserProfileForAccount(userProfileResponse));
    if (onSuccess) onSuccess();
  } catch (e) {
    console.error(e);
  }
}

function* rootSaga() {
  yield takeEvery(VerifyProfileSagas.VERIFY_LAST_NAME, verifyLastName);
  yield takeEvery(VerifyProfileSagas.HIDE_UNRECOGNIZED_PROFILE, hideUnrecognizedUserProfile);
}

export { rootSaga as default };
