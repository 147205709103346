import { useContext, createContext } from 'react';

const AuthenticationContext = createContext({
  isAuthorized: false,
  isLoggedIn: false,
});

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export default AuthenticationContext;
