let launchDarklyFlags: Record<string, any> = {};

export function getFlags() {
  return launchDarklyFlags;
}

export function setFlags(flags: Record<string, any>) {
  launchDarklyFlags = flags;
}

export function getFlag<T>(key: string, defaultValue?: T): T {
  return (launchDarklyFlags[key] ?? defaultValue) as T;
}

export function getBooleanFlag(key: string, defaultValue?: boolean): boolean {
  return !!getFlag(key, defaultValue);
}
