import React from 'react';
import Booking from '@solvhealth/types/interfaces/Booking';
import Location from '@solvhealth/types/interfaces/Location';
import { formatLocationName } from '../../core/util/location';
import { isEmpty } from '../../core/util/empty';

type Props = {
  location: Location;
  booking?: Booking;
  alt: string;
  className?: string;
  height?: number;
};

const ClinicLogo = (props: Props) => {
  const { height = 49, alt, className, location, booking } = props;
  const logos = location?.imageLinks?.logo;
  if (isEmpty(logos)) {
    return (
      <div className={className}>
        <h2>{formatLocationName(location)}</h2>
      </div>
    );
  }

  return (
    <div className={className}>
      <img alt={alt} height={height} itemProp="image" role="presentation" src={logos?.[0]} />
    </div>
  );
};

export default ClinicLogo;
