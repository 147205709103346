export const nearbyCities = [
  'Fake City, FK1',
  'Fake City, FK2',
  'Fake City, FK3',
  'Fake City, FK4',
];
export const nearbyBrands = [
  'Fake Urgent Care1',
  'Fake Urgent Care2',
  'Fake Urgent Care3',
  'Fake Urgent Care4',
];
