import {
  GROUP_LOCATIONS_RESPONSE_RECEIVED,
  GROUP_LOCATIONS_FAILED,
  GROUP_LOCATIONS_CLEAR_DATA,
  GROUP_LOCATIONS_CLEAR_ERRORS,
  GROUP_LOCATIONS_FETCHING,
} from '../constants/index';
import { locationResponseFormatter } from '../reducers/formatters/location';
import { analyticsTrackEvent } from '../core/analytics';
import { isEmptyArray } from '../core/util/array';
import { APP_API_FAILURE } from '../core/analytics/events';

const fetchingGroupLocations = () => ({
  type: GROUP_LOCATIONS_FETCHING,
});

const receiveGroupLocations = (value: any) => {
  let formattedLocations = [];
  if (!isEmptyArray(value.results)) {
    formattedLocations = value.results.map((result: any) => locationResponseFormatter(result));
  }

  return {
    type: GROUP_LOCATIONS_RESPONSE_RECEIVED,
    payload: { formattedLocations },
  };
};

const groupLocationsError = (value: any) => {
  analyticsTrackEvent(APP_API_FAILURE);
  return {
    type: GROUP_LOCATIONS_FAILED,
    payload: { value },
  };
};

const clearGroupLocationsData = () => ({
  type: GROUP_LOCATIONS_CLEAR_DATA,
});

const clearGroupLocationsErrors = () => ({
  type: GROUP_LOCATIONS_CLEAR_ERRORS,
});

export {
  fetchingGroupLocations,
  receiveGroupLocations,
  groupLocationsError,
  clearGroupLocationsData,
  clearGroupLocationsErrors,
};
