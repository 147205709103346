import LocationInterface from '@solvhealth/types/interfaces/Location';
import { isEmptyString } from '../../core/util/string';
import { getSearchDataFromQueryString } from '../../core/util/search';
import { isSolvPartner, isInGroup, isOpenNow } from '~/core/util/location';
import { STATE_OF_MICHIGAN_GROUP_ID } from '~/config/locations';

export const getCobrandedSrpLocation = () =>
  (getSearchDataFromQueryString() as any).searchLocationPreferences?.cobrandedSrpLocation ?? null;

export const isCobrandedSrpLocation = () => !isEmptyString(getCobrandedSrpLocation());

export const shouldShowWaitTime = (location: LocationInterface): boolean =>
  getCobrandedSrpLocation() === 'MI' &&
  isOpenNow(location) &&
  isInGroup(location, STATE_OF_MICHIGAN_GROUP_ID) &&
  isSolvPartner(location);
