import { Alert, Button, Link, Stack, Text, TextField, Modal, Icons, Box } from '@solvhealth/jigsaw';
import isEmpty from 'lodash/isEmpty';
// @ts-ignore FIXME: Try `npm install @types/rc-form` if it exists or a... Remove this comment to see the full error message
import { createForm, formShape } from 'rc-form';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { setUserProfileForBooking } from '~/components/LoginController/util';
import { LEGAL_TOS_URL, PRIVACY_URL } from '~/constants';
import useSelectActiveUserProfile from '~/hooks/useSelectActiveUserProfile';
import dataTestIds from '~/test/test-ids';
import Loading from '../../components/SolvPatternLibrary/Loading';
import {
  CODE_FIELD_NAME,
  PHONE_FIELD_NAME,
  PINK_DOOR_ACCEPTED_FIELD_NAME,
  TOS_ACCEPTED_FIELD_NAME,
} from '~/constants/phoneLoginForm';
import { analyticsTrackEvent, analyticsTrackFormErrors } from '~/core/analytics';
import {
  OTP_CONTINUE_WITHOUT_LOGIN,
  OTP_LOGIN_FIELD_FOCUS,
  OTP_LOGIN_SUBMIT_ERROR,
  OTP_RESEND_SMS,
  OTP_RESEND_VOICE_CALL,
  OTP_WRONG_NUMBER,
} from '~/core/analytics/events';
import { redirectAfterLogin, shouldFinishSignup } from '~/core/login';
import AuthenticationContext from '../../core/login/AuthenticationContext';
import { isPinkDoorExceptionUrl } from '~/core/util/account';
import { emptyFunction } from '~/core/util/function';
import { tenDigitPhoneValueToFormattedDisplay } from '~/core/util/phoneNumber';
import { otpCodeInputValidator } from '~/core/util/rcForm/validators';
import { VerificationStatus } from '../PhoneLoginForm/util/verificationStatus';
import PersistedLoginInput from '../SignUpForm/Fields/PersistedLoginInput';

type Props = {
  form: formShape;
  handleOtpFormSubmit: (...args: any[]) => any;
  onLoginSuccess: (...args: any[]) => any;
  isBookingWidgetEmbedded?: boolean;
  redirectToUrl?: string;
  login?: any;
  preventRedirectAfterLogin?: boolean;
  accountSummary?: any;
  initialValues?: any;
  query?: any;
  requiresTosConsent?: boolean;
  goToSignUp: (...args: any[]) => any;
  handleFormSubmit: (...args: any[]) => any;
  verificationStatus: VerificationStatus;
  resetLoginData: (...args: any[]) => any;
  isBookingFlow?: boolean;
  externalSubmit?: boolean;
  resetExternalSubmit?: () => void;
  handleSkipLogin?: (phone: string) => void;
  redirectToRemix?: boolean;
};

const OtpCodeLoginForm = (props: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [, setCookie] = useCookies();
  const { isLoggedIn } = useContext(AuthenticationContext);
  const [isResubmitDisabled, setIsResubmitDisabled] = useState(false);
  const {
    form,
    handleOtpFormSubmit,
    handleFormSubmit,
    initialValues = {},
    requiresTosConsent,
    redirectToUrl,
    preventRedirectAfterLogin,
    isBookingWidgetEmbedded,
    goToSignUp,
    accountSummary,
    login,
    verificationStatus,
    resetLoginData,
    isBookingFlow,
    externalSubmit,
    resetExternalSubmit,
    handleSkipLogin,
    redirectToRemix,
  } = props;

  const { t } = useTranslation(['common']);

  const fieldError = form.getFieldError(CODE_FIELD_NAME);
  const isPinkDoorLogin = !isPinkDoorExceptionUrl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showProblemsReceivingCode, setShowProblemsReceivingCode] = useState(false);

  const phoneValue = form.getFieldValue(PHONE_FIELD_NAME) || initialValues.phone;

  // Show "problems receiving code?" link after 5 seconds
  // We hide it for the first 5 seconds to reduce visual clutter
  // for users experiencing the "happy path"
  setTimeout(() => {
    setShowProblemsReceivingCode(true);
  }, 5000);

  const onFocus = () => {
    analyticsTrackEvent(OTP_LOGIN_FIELD_FOCUS);
  };

  const onSubmitError = useCallback(
    (error: any) => {
      form.setFields({ [CODE_FIELD_NAME]: { value: '', errors: [error] } });
      setSubmitting(false);
    },
    [form]
  );

  const onSubmit = useCallback(
    (e: any) => {
      e && e.preventDefault();

      setSubmitting(true);
      form.validateFields((errors: any, values: any) => {
        if (isEmpty(errors)) {
          return handleOtpFormSubmit(values, { set: setCookie }, onSubmitError);
        }

        setSubmitting(false);
        analyticsTrackFormErrors(OTP_LOGIN_SUBMIT_ERROR, { ...errors });
        return null;
      });
    },
    [form, handleOtpFormSubmit, onSubmitError, setCookie]
  );

  const handleResendCode = (contactMethod: any) => {
    if (!isResubmitDisabled) {
      handleFormSubmit(form.getFieldsValue(), contactMethod);

      if (contactMethod === 'voice') {
        toast.success('Calling you now!', { position: 'bottom-center' });
        analyticsTrackEvent(OTP_RESEND_VOICE_CALL);
      } else if (contactMethod === 'sms') {
        toast.promise(
          new Promise((resolve) => setTimeout(resolve, 1000)),
          {
            loading: 'Sending code',
            success: `Code resent to ${tenDigitPhoneValueToFormattedDisplay(phoneValue)}`,
            error: '',
          },
          { position: 'bottom-center' }
        );
        analyticsTrackEvent(OTP_RESEND_SMS);
      }
    }

    setIsResubmitDisabled(true);
  };

  useEffect(() => {
    if (externalSubmit) {
      resetExternalSubmit?.();
      onSubmit(null);
    }
  }, [externalSubmit, onSubmit, resetExternalSubmit]);

  useEffect(() => {
    // Disable resubmit button for 2 seconds after submission
    // to prevent accidental or rage-click resubmissions
    if (isResubmitDisabled) {
      const timer = setTimeout(() => {
        setIsResubmitDisabled(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
    return emptyFunction;
  }, [isResubmitDisabled]);

  const redirectAfterAccountReceived = () => {
    if (isBookingFlow) {
      setIsRedirecting(true);
      return;
    }

    if (shouldFinishSignup(accountSummary, verificationStatus)) {
      goToSignUp();
      return;
    }
    if (preventRedirectAfterLogin) return;
    if (isLoggedIn) {
      setIsRedirecting(true);

      redirectAfterLogin(
        accountSummary,
        redirectToUrl,
        isBookingWidgetEmbedded,
        false,
        redirectToRemix
      );
    }
  };

  const dispatch = useDispatch();
  const activeProfile = useSelectActiveUserProfile();
  const formRef = useRef<HTMLFormElement>(null);
  const [inputValue, setInputValue] = useState('');

  // Only auto-submit once (to avoid locking a user out of their account due to multiple incorrect submissions)
  const shouldAutoSubmit = useRef(true);

  // When input value reaches four characters, submit form
  useEffect(() => {
    if (inputValue.length >= 4 && shouldAutoSubmit.current) {
      shouldAutoSubmit.current = false;
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [inputValue, formRef]);

  // Ensure that a pre-existing user automatically has a profile selected upon login for the newBooking
  // flow -- as multiple places assume that this will be set, and it has multiple entrypoints.
  useEffect(() => {
    setUserProfileForBooking(dispatch, activeProfile, accountSummary);
  }, [accountSummary, activeProfile, dispatch]);

  if (!isRedirecting && !isEmpty(accountSummary)) {
    redirectAfterAccountReceived();

    return (
      <Stack alignItems="center" height="80vh" justifyContent="center">
        <Loading />
      </Stack>
    );
  }

  if (isRedirecting) {
    return (
      <Stack alignItems="center" height="80vh" justifyContent="center">
        <Loading />
      </Stack>
    );
  }

  return (
    <Stack>
      <Alert
        icon={false}
        maxContentWidth="512px"
        show={fieldError && login.maxFailLimit}
        title={t('common:auth.errors.tooManyFailedAttempts', { count: 2 })}
        tone="critical"
        variant="banner"
      >
        {t('common:auth.errors.accountFrozen', { count: 2 })}
      </Alert>

      <Stack maxWidth="512px" space={4} {...(!isBookingFlow && { pt: 4, px: 2, mx: 'auto' })}>
        <Stack borderColor="gray-100" space={1}>
          <Text variant="h3">{t('common:auth.otpForm.title')}</Text>

          <Box>
            <Text as="span" muted>
              {t(
                phoneValue
                  ? 'common:auth.otpForm.subtitleWithPhone'
                  : 'common:auth.otpForm.subtitle',
                { phone: tenDigitPhoneValueToFormattedDisplay(phoneValue) }
              )}
            </Text>{' '}
            <a
              href="#"
              onClick={(e: any) => {
                analyticsTrackEvent(OTP_WRONG_NUMBER, {
                  entryPoint: 'otp entry form',
                });
                resetLoginData(e);
              }}
            >
              <Text as="span" textColor="crunchberry">
                {t('common:auth.otpForm.wrongNumber')}
              </Text>
            </a>
          </Box>
        </Stack>

        <Stack as="form" onSubmit={onSubmit} ref={formRef} space={2}>
          <input
            {...form.getFieldProps(PHONE_FIELD_NAME, {
              initialValue: phoneValue,
            })}
            type="hidden"
          />

          <Stack space={3}>
            <TextField
              {...form.getFieldProps(CODE_FIELD_NAME, {
                rules: [
                  {
                    required: true,
                    validator: otpCodeInputValidator,
                    validateTrigger: ['onBlur'],
                  },
                ],
                initialValue: initialValues[CODE_FIELD_NAME] || '',
                onFocus,
              })}
              autoComplete="one-time-code"
              autoFocus
              data-testid={dataTestIds.login.otpEnter}
              error={
                fieldError &&
                !login.maxFailLimit &&
                login.accountError &&
                t('common:auth.otpForm.otpError')
              }
              fullWidth
              inputMode="numeric"
              maxLength="4"
              onChange={(e) => {
                form.setFieldsValue({ [CODE_FIELD_NAME]: e.target.value });
                setInputValue(e.target.value);
              }}
              pattern="[0-9]*"
              placeholder="— — — —"
              style={{ height: 'auto' }}
              title="Verification code"
              value={inputValue}
            />
            <Text as="div" muted>
              <Box height="18px">
                {showProblemsReceivingCode && (
                  <Link inline onClick={() => setIsModalOpen(true)} type="button">
                    {t('common:auth.otpForm.problemReceivingCode')}
                  </Link>
                )}
              </Box>
            </Text>
          </Stack>

          <PersistedLoginInput form={form} />

          <Stack space={4}>
            {requiresTosConsent && (
              <Text as="div" muted variant="body2">
                <Trans i18nKey="common:legal.agreeTosAndPp" t={t}>
                  <Link external href={LEGAL_TOS_URL} inline openInNewWindow size="small">
                    0
                  </Link>
                  <Link external href={PRIVACY_URL} inline openInNewWindow size="small">
                    1
                  </Link>
                </Trans>

                <input
                  {...form.getFieldProps(
                    isPinkDoorLogin ? PINK_DOOR_ACCEPTED_FIELD_NAME : TOS_ACCEPTED_FIELD_NAME,
                    {
                      initialValue: true,
                    }
                  )}
                  type="hidden"
                />
              </Text>
            )}

            {!isBookingFlow && (
              <Button
                data-testid={dataTestIds.login.otpSubmit}
                fullWidth
                loading={submitting}
                size="large"
                type="submit"
              >
                {requiresTosConsent ? 'Agree and continue' : 'Continue'}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        title="Resend verification code"
      >
        <Stack space={2}>
          <Stack pb={3} row space={1}>
            {phoneValue && (
              <Text muted>to {tenDigitPhoneValueToFormattedDisplay(phoneValue)}.</Text>
            )}
            <Link
              inline
              onClick={(e: any) => {
                analyticsTrackEvent(OTP_WRONG_NUMBER, {
                  entryPoint: 'problems receiving code modal',
                });
                resetLoginData(e);
              }}
              style={{ alignSelf: 'flex-start' }}
              type="button"
            >
              {t('common:auth.otpForm.wrongNumber')}
            </Link>
          </Stack>

          <Button
            fullWidth
            onClick={() => {
              handleResendCode('voice');
              setIsModalOpen(false);
            }}
            variant="outlined"
          >
            <Stack row space={1}>
              <Icons.Phone />
              <Box>Call me with the code</Box>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              handleResendCode('sms');
              setIsModalOpen(false);
            }}
            variant="outlined"
          >
            <Stack row space={1}>
              <Icons.ChatBubble />
              <Box>Text me the code</Box>
            </Stack>
          </Button>
          {isBookingFlow && (
            <Button
              onClick={() => {
                analyticsTrackEvent(OTP_CONTINUE_WITHOUT_LOGIN);
                handleSkipLogin?.(phoneValue);
              }}
              variant="text"
            >
              Continue without logging in
            </Button>
          )}
        </Stack>
      </Modal>
    </Stack>
  );
};

export default createForm()(OtpCodeLoginForm);
