import {
  ALLERGIST_DISPLAY_NAME,
  ALLERGIST_VALUE,
  CHIROPRACTOR_DISPLAY_NAME,
  CHIROPRACTOR_VALUE,
  DENTIST_DISPLAY_NAME,
  DENTIST_VALUE,
  DERMATOLOGIST_DISPLAY_NAME,
  DERMATOLOGIST_VALUE,
  IMAGING_DISPLAY_NAME,
  IMAGING_VALUE,
  OB_GYN_DISPLAY_NAME,
  OB_GYN_VALUE,
  OPTOMETRIST_DISPLAY_NAME,
  OPTOMETRIST_VALUE,
} from '../../../Home/Tiles/ProviderTypes';
import { analyticsTrackEvent } from '../../../../core/analytics';
import { FIND_CARE_SEARCH_OTHER_OPTIONS } from '../../../../core/analytics/events';
import { isEmpty } from '../../../../core/util/empty';

// this value roughly determines the distance between the cards and space around the cards
// in the swimlanes. you might see it being multiplied or divided by 2. this is because there
// should be 16px between the cards, which turns out to 8px padding, but on the start and end
// card that would be 16, and material ui wants double the value which is 32 :)
export const SWIMLANE_SPACING = 16;

export const PROVIDER_TYPES = [
  {
    name: ALLERGIST_DISPLAY_NAME,
    providerType: ALLERGIST_VALUE,
  },
  {
    name: CHIROPRACTOR_DISPLAY_NAME,
    providerType: CHIROPRACTOR_VALUE,
  },
  {
    name: DENTIST_DISPLAY_NAME,
    providerType: DENTIST_VALUE,
  },
  {
    name: DERMATOLOGIST_DISPLAY_NAME,
    providerType: DERMATOLOGIST_VALUE,
  },
  {
    name: OB_GYN_DISPLAY_NAME,
    providerType: OB_GYN_VALUE,
  },
  {
    name: OPTOMETRIST_DISPLAY_NAME,
    providerType: OPTOMETRIST_VALUE,
  },
  {
    name: IMAGING_DISPLAY_NAME,
    providerType: IMAGING_VALUE,
  },
];

export const getRecentlyBooked = (locationsResults: any) =>
  isEmpty(locationsResults) ? null : Object.values(locationsResults)[0];

export const handleSearchOtherOptions = (redirectToSrp: any) => {
  analyticsTrackEvent(FIND_CARE_SEARCH_OTHER_OPTIONS);
  redirectToSrp();
};
