import { DAPI_HOST } from '../../config/index';
import { UserProfile } from './models';

const getUserProfileById = (userProfileId: any) => `${DAPI_HOST}/v1/user-profiles/${userProfileId}`;

const createUserProfileUrl = () => `${DAPI_HOST}/v1/user-profiles`;

const buildDapiUserProfileObject = (form: any) => {
  const userProfile = <UserProfile>{};
  for (const field in form) {
    if (form.hasOwnProperty(field)) {
      switch (field) {
        case 'firstName':
          userProfile.first_name = form.firstName;
          break;
        case 'lastName':
          userProfile.last_name = form.lastName;
          break;
        case 'email':
          userProfile.email = form.email;
          break;
        case 'birthDate':
          userProfile.birth_date = form.birthDate;
          break;
        case 'relationshipToAccount':
          userProfile.relationship_to_account = form.relationshipToAccount;
          break;
        case 'accountId':
          userProfile.account_id = form.accountId;
          break;
        case 'isVerified':
          // User profiles created from the account should be verified by default
          userProfile.is_verified = form.isVerified;
          break;
        case 'birthSex':
          userProfile.birth_sex = form.birthSex;
          break;
        case 'address_street':
        case 'address_street_secondary':
        case 'address_city':
        case 'address_state':
        case 'address_zip':
        case 'pharmacy':
        case 'pharmacy_google_place_id':
          userProfile[field] = form[field];
          break;
        case 'gender':
          userProfile.gender = form.gender;
          break;
        default:
          break;
      }
    }
  }

  userProfile.active = true;

  userProfile.is_hidden = false;
  return userProfile;
};

const createSpecialtyPreferencesUrl = () => `${DAPI_HOST}/v1/user-profiles-specialty-preferences`;

const getSpecialtyPreferencesUrl = (userProfileId: any) =>
  `${DAPI_HOST}/v1/user-profiles-specialty-preferences/${userProfileId}`;

const createAppointmentPreferencesUrl = () =>
  `${DAPI_HOST}/v1/user-profiles-appointment-preferences`;

const getAppointmentPreferencesUrl = (userProfileId: any) =>
  `${DAPI_HOST}/v1/user-profiles-appointment-preferences/${userProfileId}`;

const verifyProfileLastName = (userProfileId: any) =>
  `${DAPI_HOST}/v1/user-profiles/${userProfileId}/verify`;

const getSmoochWebhookUrl = () => `${DAPI_HOST}/v1/webhooks/smooch`;

export {
  buildDapiUserProfileObject,
  createAppointmentPreferencesUrl,
  createSpecialtyPreferencesUrl,
  createUserProfileUrl,
  getAppointmentPreferencesUrl,
  getSmoochWebhookUrl,
  getSpecialtyPreferencesUrl,
  getUserProfileById,
  verifyProfileLastName,
};
