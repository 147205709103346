import { call, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { apiGet, apiPatchJson, apiPost, apiPostJson } from '../core/dapi';
import logger from '../core/logger/index';
import {
  buildDapiAccountObject,
  getAccountById,
  getSaveLocationUrl,
  setPinkDoorTosAccepted,
} from '../core/dapi/account';
import {
  accountError,
  accountReceived,
  accountReplaceSavedLocations,
  accountSaveLocation,
  loginFailed,
} from '../actions/login';
import { setUserInfo } from '../core/session';
import { analyticsTrackEvent } from '../core/analytics';
import {
  CROSSED_PINK_DOOR,
  CROSSED_PINK_DOOR_TYPE_ACCEPTED_TOS_LOGIN,
  LOCATION_SAVE_AFTER_LOGIN,
  LOCATION_SAVE_BEFORE_LOGGED_IN,
  LOCATION_SAVE_TO_ACCOUNT,
  LOGIN,
  LOGIN_STATUS_ERROR,
  LOGIN_STATUS_SUCESS,
} from '../core/analytics/events';
import { setRuntimeVariable, showModal } from '../actions/runtime';
import { LOGIN_SIGN_UP_MODAL } from '../components/LoginSignUpModal';
import { RECEIVE_ACCOUNT_SUMMARY } from '../constants/index';
import { completeOnboardingStep } from '../ducks/onboarding';
import { isAndroidApp, isAndroidOS, isIOS, isIosApp } from '../core/util/device';
import { didCrossPinkdoorAfterTimeSpecified, getSolvUserDate } from '../core/util/account';
import { getUserAccountUrl } from '../core/dapi/userAccounts';

export const FETCH_LOGIN_ACCOUNT = 'saga/FETCH_LOGIN_ACCOUNT';
export const ONOBARDING_UPDATE_ACCOUNT = 'sagas/ONOBARDING_UPDATE_ACCOUNT';
export const SAVE_LOCATION_ACCOUNT = 'saga/SAVE_LOCATION_ACCOUNT';
export const SET_TOS_ACCEPTED_SAGA = 'saga/SET_TOS_ACCEPTED';

function* fetchLoginAccount({ accountId }: any) {
  try {
    const url = getAccountById(accountId);

    const loginAccountResponse = yield call(apiGet, url, {
      fullResponse: true,
    });
    setUserInfo(loginAccountResponse);
    analyticsTrackEvent(LOGIN, {
      ...loginAccountResponse,
      status: LOGIN_STATUS_SUCESS,
    });
    yield put(accountReceived(loginAccountResponse));
  } catch (e) {
    logger.warn(e);
    analyticsTrackEvent(LOGIN, { status: LOGIN_STATUS_ERROR });
    yield put(loginFailed(e));
  }
}

function* saveLocationToAccount({ locationId, accountId, save, isLoggedIn }: any) {
  try {
    if (!accountId || !isLoggedIn) {
      yield put(
        setRuntimeVariable({
          name: 'saveLocationAfterLoginSuccess',
          value: locationId,
        })
      );
      analyticsTrackEvent(LOCATION_SAVE_BEFORE_LOGGED_IN, { locationId });
      yield put(showModal(LOGIN_SIGN_UP_MODAL));
      return;
    }

    analyticsTrackEvent(LOCATION_SAVE_TO_ACCOUNT, {
      settingSaveTo: save,
      accountId,
      locationId,
    });

    yield put(accountSaveLocation(locationId, save));
    const url = getSaveLocationUrl(accountId);
    // @ts-expect-error ts-migrate(2769) FIXME: Type '(url: any, data: any, options: any) => any' ... Remove this comment to see the full error message
    const newLocations = yield call(apiPost, url, {
      account_id: accountId,
      location_id: locationId,
      active: save,
    });

    yield put(accountReplaceSavedLocations(newLocations));
  } catch (e) {
    console.error(e);
  }
}

function* loginSaveLocationObserver({ payload }: any) {
  try {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const locationId = yield select((state) => state.runtime.saveLocationAfterLoginSuccess);
    if (!locationId) return;

    analyticsTrackEvent(LOCATION_SAVE_AFTER_LOGIN, { locationId });
    const accountId = payload.value.id;

    yield put(setRuntimeVariable({ name: 'saveLocationAfterLoginSuccess', value: null }));

    yield put({
      type: SAVE_LOCATION_ACCOUNT,
      locationId,
      accountId,
      save: true,
    });
  } catch (e) {
    console.error(e);
  }
}

function* setTosAccepted({
  accountId: paramAccountId,
  isPinkDoor = false,
}: {
  accountId?: string;
  isPinkDoor: boolean;
  type: string;
}) {
  try {
    let accountId;
    if (!paramAccountId) {
      accountId = yield select((state: any) => state.login?.account?.id);
    } else {
      accountId = paramAccountId;
    }

    const patchData = {
      is_ios_user: isIOS(),
      is_android_user: isAndroidOS(),
      is_ios_app_user: isIosApp(),
      is_android_app_user: isAndroidApp(),
    };
    if (isPinkDoor) {
      const setTosAcceptedTimestamp = moment();
      const resp = yield call(apiPostJson, setPinkDoorTosAccepted(accountId), patchData);

      if (resp && didCrossPinkdoorAfterTimeSpecified(resp, setTosAcceptedTimestamp)) {
        analyticsTrackEvent(CROSSED_PINK_DOOR, {
          solv_user_date: getSolvUserDate(resp),
          type: CROSSED_PINK_DOOR_TYPE_ACCEPTED_TOS_LOGIN,
        });
      }
      yield put(
        setRuntimeVariable({
          name: 'requiresTosConsent',
          value: false,
        })
      );
    } else {
      const resp = yield call(apiPatchJson, getUserAccountUrl(accountId), {
        tos_consent: true,
        last_tos_acceptance_date: moment(),
        ...patchData,
      });
      if (resp?.data) {
        yield put(accountReceived(resp));
      }
    }
  } catch (e) {
    console.error(`Error accepting ${isPinkDoor ? '' : 'non'} pink door ToS`, e);
  }
}

function* onboardingUpdateAccount({ formValues, accountId, currentScreen }: any) {
  try {
    const url = getAccountById(accountId);
    const data = buildDapiAccountObject(formValues);
    const response = yield call(apiPatchJson, url, data);
    yield put(accountReceived(response));
    yield put(completeOnboardingStep(currentScreen));
  } catch (e) {
    logger.error(e);
    yield put(accountError(e));
  }
}

function* rootSaga() {
  yield takeEvery(RECEIVE_ACCOUNT_SUMMARY, loginSaveLocationObserver);
  yield takeEvery(FETCH_LOGIN_ACCOUNT, fetchLoginAccount);
  yield takeEvery(SAVE_LOCATION_ACCOUNT, saveLocationToAccount);
  yield takeEvery(ONOBARDING_UPDATE_ACCOUNT, onboardingUpdateAccount);
  yield takeEvery(SET_TOS_ACCEPTED_SAGA, setTosAccepted);
}

export { rootSaga as default };
