import styled, { css } from 'styled-components';
import { colors } from '../../../constants/colors';
import { fontSize, fontFamily } from '../../../constants/text';

export const Clear = styled.img<{ $shown?: boolean }>`
  height: 16px;
  width: 16px;
  padding: 6px;
  position: absolute;
  right: 4px;
  top: calc(50% - 14px);
  transition: opacity 200ms ease;

  ${(props) =>
    !props.$shown &&
    css`
      pointer-events: none;
      opacity: 0;
    `};
`;

export default styled.input<{ $url?: string; sc?: any }>`
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  letter-spacing: -0.1px;
  position: relative;
  line-height: 20px;
  border: 1px solid ${colors.fatherJohnMisty};
  transition: border-color 200ms ease;
  text-indent: 34px;
  background-color: ${colors.white};
  color: ${colors.squid};
  font-family: ${fontFamily.book};
  font-size: ${fontSize.default};
  height: 40px;
  background-image: url(${(props) => props.$url});
  background-position: 11px center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: ${(props) => (props.sc?.shortEllipsis ? '85%' : '100%')};

  &&&&&&:focus {
    outline: 0;
    border-color: ${colors.mrBlueSky};
  }

  &::placeholder {
    color: ${colors.greyLock};
  }

  ${(props) =>
    props.sc?.isPlaceholder &&
    css`
      color: ${colors.greyLock};
    `}
`;
