import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Loading from './Loading';
import { fontSize } from '../../constants/text';
import SolvLogo from '../SolvPatternLibrary/SolvLogo';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Message = styled.p`
  font-size: ${fontSize.default};
  margin-bottom: 30px;
`;

type Props = {
  text?: string;
  showLogo?: boolean;
};

class LoadingScreen extends PureComponent<Props> {
  render = () => {
    const { text, showLogo = false } = this.props;
    return (
      <Centered data-testid="LoadingScreen">
        {showLogo && <SolvLogo />}
        {text && <Message>{text}</Message>}

        <Loading />
      </Centered>
    );
  };
}

export default LoadingScreen;
