import React from 'react';
import Location from '@solvhealth/types/interfaces/Location';
import {
  formatLocationCategory,
  formatLocationSpecialties,
  isBeyondNextDayAppointmentsEnabled,
  isByAppointmentOnly,
  isOpenNow,
  isPaperworkEnabled,
  isReliantVirtualVisit,
  isStandaloneTelemed,
  isTelemedLocation,
  isTraditionalLocation,
  shouldPromoteWalkingInWhenAllReservationsAreTaken,
  isInGroup,
  isCovidTestingOnly,
  isProviderGroupLocation,
  isNextDayAppointmentsDisabled,
  isCurrentlyClosedToday,
} from './location';
import { isLaboratoryTesting } from './location';

import { isNativeApp } from './device';
import {
  APPLE_CARE_GROUP_ID,
  CITY_OF_SEATTLE_GROUP_ID,
  KING_COUNTY_GROUP_ID,
} from '../../config/locations';

export const BREADCRUMBS = 'Breadcrumbs';
export const CLAIMING_LOCATION = 'Claiming location';
export const SELF_PAY_PRICES = 'Self pay prices';
export const SERVICES = 'Services';
export const FOOTER = 'Footer';
export const CDP_INFO_DESCRIPTION = 'Cdp info description';
export const INSURANCE_AND_PAYMENTS = 'Insurance and payments';
export const RECENTLY_BOOKED = 'Recently booked';
export const SCHEMA_ORG_MEDICAL_SPECIALTY = 'Schema org medical specialty property';
export const SCHEMA_ORG_PRICE_RANGE = 'Schema org price range';
export const SCHEMA_ORG_PAYMENTS_ACCEPTED = 'Schema org payments accepted';
export const CDP_TITLE_CATEGORY_OR_SPECIALTY = 'Cdp title, category or specialty';
export const SHARE_THE_CDP = 'Share the cdp';
export const BOOK_MODULE_NO_MORE_ONLINE_VISITS_ALTERNATIVES_COPY =
  ' Book module, no more online visits. Alternative options copy';
export const PASS_CHECKLIST_ADD_PREFERRED_PHARMACY = 'Pass checklist add preferred pharmacy';
export const PASS_CHECKLIST_VIEW_LIVE_WAITLIST = 'Pass checklist view live waitlist';
export const PASS_BIG_CTA_ADD_PAPERWORK = 'Pass new paperwork big CTA';
export const PASS_CHECKLIST_ADD_PAYMENT_METHOD = 'Pass checklist add payment method';
export const PASS_CHECKLIST_VIEW_COST_BENEFITS = 'Pass checklist view cost benefits';
export const PASS_APPOINTMENT_FOOTNOTES = 'Pass appointment footnotes';
export const PASS_WHATS_NEXT = 'Pass whats next';
export const NEXT_DAY_BOOKINGS_SLOT_SELECTOR = 'select next day dates from the booking module';
export const MORE_DATES_SLOT_SELECTOR = 'select more dates from the booking module';
export const MORE_DATES_URGENT_CARE = 'more dates for real availability fetching';
export const SHOW_DATES_ON_BOOK_BUTTON = 'show dates on the book button module';
export const BOOKING_WIDGET_GEOSUGGEST = 'booking widget home address geosuggest';
export const COVID_TESTING_CONFIRMATION_PAGE = 'covid testing confirmation page';
export const CDP_FREQUENTLY_ASKED_QUESTIONS = 'Cdp frequently asked questions';

/**
 * CDP IDs where an FAQ will be shown
 */
const CDP_FAQ_ENABLED_IDS = [
  // Smart Care
  'g1PlQR',

  // Partner CDPs
  'qAWlep',
  'Az5790',
  'Y0VlXp',
  'gLGEDp',
  'RpybYg',
  '2gKxv0',
  'A4N4Og',
  '07BMvg',
  'xA9m4A',
  '0xQy9A',

  // Non-Partner CDPs
  'goKYdg',
  'A4EW2p',
  'gw57wA',
  'pY57Vp',
  '7gNMDp',
  '0k8LkA',
  'gKaOvA',
  'A24ZNg',
  'gqnxl0',
  '0ry6zg',
];

export const getCustomization = (
  featureName: any,
  { location, userAgent = null }: { location: Location; userAgent?: null }
) => {
  switch (featureName) {
    case BREADCRUMBS:
      if (isNativeApp(userAgent)) {
        return { shouldBeLinkable: false };
      }
      return { shouldBeLinkable: true };
    case CDP_INFO_DESCRIPTION:
      if (isNativeApp(userAgent)) {
        return { shouldBeLinkable: false };
      }
      return { shouldBeLinkable: true };
    case FOOTER:
      if (isNativeApp(userAgent)) {
        return { visible: false };
      }
      return { visible: true };
    case SERVICES:
      if (isNativeApp(userAgent)) {
        return { shouldBeLinkable: false };
      }
      return { shouldBeLinkable: true };
    case CLAIMING_LOCATION:
      if (isTraditionalLocation(location)) {
        return {
          visible: true,
          claimedText: 'This practice has been claimed',
          unclaimedText: 'Claim this practice',
        };
      }

      return {
        visible: true,
        claimedText: 'This clinic has been claimed',
        unclaimedText: 'Claim this clinic',
      };
    case INSURANCE_AND_PAYMENTS:
      return {
        insuranceSectionCollapsable: isTraditionalLocation(location),
        entity: isTraditionalLocation(location) ? 'Practice' : 'Clinic',
      };
    case SCHEMA_ORG_MEDICAL_SPECIALTY:
      if (isTraditionalLocation(location)) {
        return { value: formatLocationSpecialties(location) };
      }

      return { value: formatLocationCategory(location) };
    case CDP_TITLE_CATEGORY_OR_SPECIALTY:
      if (isTraditionalLocation(location)) {
        return { value: formatLocationSpecialties(location) };
      }

      if (isLaboratoryTesting(location)) {
        return {
          value: 'Lab Testing',
        };
      }

      return { value: formatLocationCategory(location) };
    case SHARE_THE_CDP:
      if (isTraditionalLocation(location)) {
        return { modalTitle: 'Share this Practice' };
      }

      return { modalTitle: 'Share this Clinic' };

    case SCHEMA_ORG_PRICE_RANGE:
    case SELF_PAY_PRICES:
    case SCHEMA_ORG_PAYMENTS_ACCEPTED:
    case RECENTLY_BOOKED:
    case PASS_CHECKLIST_ADD_PREFERRED_PHARMACY:
    case PASS_CHECKLIST_VIEW_LIVE_WAITLIST:
    case PASS_CHECKLIST_VIEW_COST_BENEFITS:
    case PASS_APPOINTMENT_FOOTNOTES:
      return { visible: !isTraditionalLocation(location) };
    case BOOK_MODULE_NO_MORE_ONLINE_VISITS_ALTERNATIVES_COPY:
      return {
        visible:
          !isTraditionalLocation(location) &&
          !isTelemedLocation(location) &&
          shouldPromoteWalkingInWhenAllReservationsAreTaken(location) &&
          isOpenNow(location),
      };
    case PASS_BIG_CTA_ADD_PAPERWORK:
      return {
        visible:
          isPaperworkEnabled(location) &&
          !isTelemedLocation(location) && // SOLV-9357 telemed location should not show the cta to up conversion since they require people to go through pre-call flows
          !isTraditionalLocation(location),
      };
    case PASS_CHECKLIST_ADD_PAYMENT_METHOD:
      return {
        visible:
          !isTraditionalLocation(location) &&
          !isReliantVirtualVisit(location) &&
          !isStandaloneTelemed(location),
      };
    case NEXT_DAY_BOOKINGS_SLOT_SELECTOR:
      return {
        visible: !isNextDayAppointmentsDisabled(location) || isCurrentlyClosedToday(location),
      };
    case MORE_DATES_SLOT_SELECTOR:
    case SHOW_DATES_ON_BOOK_BUTTON:
      return {
        visible:
          isBeyondNextDayAppointmentsEnabled(location) && !isNextDayAppointmentsDisabled(location),
      };
    case BOOKING_WIDGET_GEOSUGGEST:
      return {
        visible:
          isInGroup(location, CITY_OF_SEATTLE_GROUP_ID) ||
          isInGroup(location, KING_COUNTY_GROUP_ID) ||
          isInGroup(location, APPLE_CARE_GROUP_ID),
      };
    case COVID_TESTING_CONFIRMATION_PAGE:
      return { visible: isCovidTestingOnly(location) };
    case CDP_FREQUENTLY_ASKED_QUESTIONS:
      return { visible: location?.id && CDP_FAQ_ENABLED_IDS.includes(location.id) };
    case PASS_WHATS_NEXT:
      return { visible: !isProviderGroupLocation(location) };
    default:
      return { visible: true };
  }
};

export const shouldShowFeature = (featureName: any, location: any) =>
  getCustomization(featureName, { location }).visible;
