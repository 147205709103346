import { produce } from 'immer';
import Provider, { ProviderSlots } from '@solvhealth/types/interfaces/Provider';
import moment from 'moment';
import { AnyAction, Reducer } from 'redux';
import Specialty from '@solvhealth/types/interfaces/Specialty';
import Group from '@solvhealth/types/interfaces/Group';
import Location from '@solvhealth/types/interfaces/Location';
import {
  ProviderCollectionStatus,
  ProviderStatus,
  SearchFilters,
  Rating,
  RatingsCollection,
} from '../../components/ProviderGroup/interfaces';
import { ExternalSlotDateRange } from '~/components/ProviderGroup/interfaces/ExternalSlot';
import {
  EXTERNAL_SLOT_DATE_FETCH_FORMAT,
  FETCH_SLOTS_INTERVAL_DAYS,
  PRIMARY_CARE_SPECIALTY_NAME,
  PRIMARY_CARE_SPECIALTY_OBJECT,
} from '~/components/ProviderGroup/constants';
import { formatProvider } from './util';
import { isEmpty } from '~/core/util/empty';
import ValidProviderGroupQueryParams from '../../components/ProviderGroup/interfaces/ValidProviderGroupQueryParams';
import { locationResponseFormatter } from '~/reducers/formatters/location';
import { ProviderLocation } from '../../components/ProviderGroup/interfaces';
import { isEmptyArray } from '~/core/util/array';
import { SolvReduxState } from '../../reducers';

enum ProviderGroupsActions {
  // one provider
  PROVIDER_STATUS = 'providerGroups/PROVIDER_STATUS',
  PROVIDER_LOADING = 'providerGroups/PROVIDER_LOADING',
  PROVIDER_RECEIVED = 'providerGroups/PROVIDER_RECEIVED',
  PROVIDER_LOADING_EXTERNAL = 'providerGroups/PROVIDER_LOADING_EXTERNAL',
  PROVIDER_EXTERNAL_ERROR = 'providerGroups/PROVIDER_EXTERNAL_ERROR',
  PROVIDER_ERROR = 'providerGroups/PROVIDER_ERROR',
  // groups
  GROUP_STATUS = 'providerGroups/GROUP_STATUS',
  GROUP_ERROR = 'providerGroups/GROUP_ERROR',
  GROUP_RECEIVED = 'providerGroups/GROUP_RECEIVED',
  // providers by group
  GROUP_PROVIDERS_RECEIVED = 'providerGroups/GROUP_PROVIDERS_RECEIVED',
  PROVIDERS_SLOTS_RECEIVED = 'providerGroups/PROVIDERS_SLOTS_RECEIVED',
  // location
  LOCATION_STATUS = 'providerGroups/LOCATION_STATUS',
  LOCATION_ERROR = 'providerGroups/LOCATION_ERROR',
  LOCATION_RECEIVED = 'providerGroups/LOCATION_RECEIVED',
  // providers by location
  LOCATION_PROVIDERS_RECEIVED = 'providerGroups/LOCATION_PROVIDERS_RECEIVED',
  // specialties
  SPECIALTIES_RECEIVED = 'providerGroups/SPECIALTIES_RECEIVED',
  SPECIALTY_ERROR = 'providerGroups/SPECIALTY_ERROR',
  SET_SEARCH_FILTERS = 'providerGroups/SET_SEARCH_FILTERS',
  CLEAR_SEARCH_FILTERS = 'providerGroups/CLEAR_SEARCH_FILTERS',
  SET_EXTERNAL_SLOT_DATE_RANGE = 'providerGroups/SET_EXTERNAL_SLOT_DATE_RANGE',
  // url query param customizations
  SET_VIEW_CUSTOMIZATIONS = 'providerGroups/SET_VIEW_CUSTOMIZATIONS',
  // ratings
  INITIAL_PAGINATED_RATINGS_RECEIVED = 'providerGroups/INITIAL_PAGINATED_RATINGS_RECEIVED',
  PAGINATED_RATINGS_RECEIVED = 'providerGroups/PAGINATED_RATINGS_RECEIVED',
  RATINGS_ERROR = 'providerGroups/RATINGS_ERROR',

  CLEAR_SLOTS = 'providerGroups/CLEAR_SLOTS',

  SET_DATE_SELECTED_BY_USER = 'providerGroups/SET_DATE_SELECTED_BY_USER',
}

export const defaultProviderStatus: ProviderStatus = {
  loading: false,
  loadingExternal: false,
};

const updateProviderStatus = produce((draft, newStatus: ProviderStatus) => {
  Object.assign(draft, newStatus);
}, defaultProviderStatus);

export const defaultProviderCollectionStatus: ProviderCollectionStatus = {
  loading: false,
  providersLoading: false,
  providersLoadingExternal: false,
};

export const defaultExternalSlotDateRange: ExternalSlotDateRange = {
  minDate: moment().format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
  lastFetchStartDate: moment().format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
  lastFetchEndDate: moment()
    .add(FETCH_SLOTS_INTERVAL_DAYS, 'day')
    .format(EXTERNAL_SLOT_DATE_FETCH_FORMAT),
  dateSelectedByUser: null,
};

const updateProviderCollectionStatus = produce((draft, newStatus: ProviderCollectionStatus) => {
  Object.assign(draft, newStatus);
}, defaultProviderCollectionStatus);

// single provider
export const providerStatus = (payload: { providerId: string; status: ProviderStatus }) => ({
  type: ProviderGroupsActions.PROVIDER_STATUS,
  payload,
});

export const providerLoading = (providerId: string): AnyAction => ({
  type: ProviderGroupsActions.PROVIDER_LOADING,
  payload: providerId,
});

export const receiveProvider = (value: Provider): AnyAction => ({
  type: ProviderGroupsActions.PROVIDER_RECEIVED,
  payload: { value },
});

export const providerLoadingExternal = (providerId: string): AnyAction => ({
  type: ProviderGroupsActions.PROVIDER_LOADING_EXTERNAL,
  payload: providerId,
});

export const receiveProviderError = (value: { error: any; providerId: string }): AnyAction => ({
  type: ProviderGroupsActions.PROVIDER_ERROR,
  payload: { value },
});

export const receiveProviderExternalError = (value: {
  providerId: string;
  error: any;
}): AnyAction => ({
  type: ProviderGroupsActions.PROVIDER_EXTERNAL_ERROR,
  payload: value,
});

// many providers
export const receiveGroupProviders = (value: {
  providersResponse: { page: any; results: Provider[] };
  groupId: string;
}): AnyAction => {
  return {
    type: ProviderGroupsActions.GROUP_PROVIDERS_RECEIVED,
    payload: { value },
  };
};

export const receiveLocationProviders = (value: {
  providersResponse: { page: any; results: Provider[] };
  locationId: string;
}): AnyAction => {
  return {
    type: ProviderGroupsActions.LOCATION_PROVIDERS_RECEIVED,
    payload: { value },
  };
};

export const receiveProvidersSlots = (value: Provider[]): AnyAction => ({
  type: ProviderGroupsActions.PROVIDERS_SLOTS_RECEIVED,
  payload: { value },
});

// Group action creators
export const receiveGroup = (value: Group) => ({
  type: ProviderGroupsActions.GROUP_RECEIVED,
  payload: { value },
});

export const receiveGroupError = (value: any): AnyAction => ({
  type: ProviderGroupsActions.GROUP_ERROR,
  payload: { value },
});

export const groupStatus = (value: {
  status: ProviderCollectionStatus;
  groupId: string;
}): AnyAction => ({
  type: ProviderGroupsActions.GROUP_STATUS,
  payload: value,
});

// Location action creators
export const receiveLocation = (value: Location) => ({
  type: ProviderGroupsActions.LOCATION_RECEIVED,
  payload: { value },
});

export const locationStatus = (value: {
  status: ProviderCollectionStatus;
  locationId: string;
}): AnyAction => ({
  type: ProviderGroupsActions.LOCATION_STATUS,
  payload: value,
});

// specialty action creators
export const receiveSpecialties = (value: { page: any; results: Specialty[] }): AnyAction => ({
  type: ProviderGroupsActions.SPECIALTIES_RECEIVED,
  payload: { value },
});

export const receiveSpecialtiesError = (value: any): AnyAction => ({
  type: ProviderGroupsActions.SPECIALTY_ERROR,
  payload: { value },
});

export const setSearchFilters = (value: SearchFilters): AnyAction => ({
  type: ProviderGroupsActions.SET_SEARCH_FILTERS,
  payload: { value },
});

export const clearSearchFilters = (): AnyAction => ({
  type: ProviderGroupsActions.CLEAR_SEARCH_FILTERS,
});

export const setExternalSlotDateRange = (value: ExternalSlotDateRange): AnyAction => ({
  type: ProviderGroupsActions.SET_EXTERNAL_SLOT_DATE_RANGE,
  payload: { value },
});

export const setViewCustomizations = (value: ValidProviderGroupQueryParams): AnyAction => ({
  type: ProviderGroupsActions.SET_VIEW_CUSTOMIZATIONS,
  payload: { value },
});

export const clearSlots = () => ({
  type: ProviderGroupsActions.CLEAR_SLOTS,
});

// ratings
export const receiveInitialPaginatedRatings = (value: { page: any; results: any[] }) => ({
  type: ProviderGroupsActions.INITIAL_PAGINATED_RATINGS_RECEIVED,
  payload: { value },
});

export const receivePaginatedRatings = (value: { page: any; results: any[] }) => ({
  type: ProviderGroupsActions.PAGINATED_RATINGS_RECEIVED,
  payload: { value },
});

export const receiveRatingsError = (value: any) => ({
  type: ProviderGroupsActions.RATINGS_ERROR,
  payload: { value },
});

export const setDateSelectedByUser = (value: moment.Moment | null) => ({
  type: ProviderGroupsActions.SET_DATE_SELECTED_BY_USER,
  payload: { value },
});

export interface Slots {
  [providerId: string]: ProviderSlots;
}

export interface ProviderGroupsState {
  externalSlotDateRange: ExternalSlotDateRange;
  groupStatuses: {
    [groupId: string]: ProviderCollectionStatus;
  };
  groups: {
    [groupId: string]: Group;
  };
  locationStatuses: {
    [locationId: string]: ProviderCollectionStatus;
  };
  locations: {
    [locationId: string]: ProviderLocation;
  };
  providerStatuses: {
    [providerId: string]: ProviderStatus;
  };
  providers: {
    [providerId: string]: Omit<Provider, 'slots'>;
  };
  ratings?: RatingsCollection;
  searchFilters: SearchFilters;
  slots: Slots;
  specialties: {
    [specialtyName: string]: Specialty;
  };
  viewCustomizations: ValidProviderGroupQueryParams;
}

const DEFAULT_STATE: ProviderGroupsState = {
  externalSlotDateRange: {} as ExternalSlotDateRange,
  groupStatuses: {},
  groups: {},
  locationStatuses: {},
  locations: {},
  providerStatuses: {},
  providers: {},
  searchFilters: {},
  slots: {},
  specialties: {},
  viewCustomizations: {},
};

const reducer: Reducer<ProviderGroupsState> = (
  state: ProviderGroupsState = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    // one provider
    case ProviderGroupsActions.PROVIDER_LOADING: {
      return produce(state, (draft) => {
        draft.providerStatuses[action.payload] = updateProviderStatus(
          draft.providerStatuses[action.payload],
          {
            loading: true,
          }
        );
      });
    }
    case ProviderGroupsActions.PROVIDER_LOADING_EXTERNAL: {
      return produce(state, (draft) => {
        draft.providerStatuses[action.payload] = updateProviderStatus(
          draft.providerStatuses[action.payload],
          { loadingExternal: true }
        );
      });
    }
    case ProviderGroupsActions.PROVIDER_STATUS: {
      const { providerId, status } = action.payload;
      return produce(state, (draft) => {
        draft.providerStatuses[providerId] = updateProviderStatus(
          draft.providerStatuses[providerId],
          status
        );
      });
    }
    case ProviderGroupsActions.PROVIDER_RECEIVED: {
      const provider: Provider = action.payload.value;
      return produce(state, (draft) => {
        draft.providers[provider.id] = formatProvider(provider);
        draft.providerStatuses[provider.id] = updateProviderStatus(
          draft.providerStatuses[provider.id],
          {
            loading: false,
          }
        );
      });
    }
    case ProviderGroupsActions.PROVIDER_ERROR:
      return produce(state, (draft) => {
        const {
          value: { error, providerId },
        } = action.payload;
        draft.providerStatuses[providerId] = updateProviderStatus(
          draft.providerStatuses[providerId],
          {
            error: true,
            errorMessage: error,
            loading: false,
            loadingExternal: false,
          }
        );
      });
    case ProviderGroupsActions.PROVIDER_EXTERNAL_ERROR:
      return produce(state, (draft) => {
        const { error, providerId } = action.payload;
        draft.providerStatuses[providerId] = updateProviderStatus(
          draft.providerStatuses[providerId],
          {
            errorExternal: true,
            errorMessage: error,
            loading: false,
            loadingExternal: false,
          }
        );
      });
    // many providers
    case ProviderGroupsActions.GROUP_PROVIDERS_RECEIVED:
      return produce(state, (draft) => {
        const {
          providersResponse: { results },
          groupId,
        }: { providersResponse: { results: Provider[] }; groupId: string } = action.payload.value;
        const newProvidersById = results
          .filter((provider) => !isEmptyArray(provider.specialties))
          .reduce(
            (providersById: { [key: string]: Provider }, provider: Provider) => ({
              ...providersById,
              [provider.id]: formatProvider(provider),
            }),
            {}
          );
        Object.assign(draft.providers, newProvidersById);
        const groupProvidersIds = Object.keys(newProvidersById);
        draft.groups[groupId].providers = groupProvidersIds;
      });
    case ProviderGroupsActions.LOCATION_PROVIDERS_RECEIVED:
      return produce(state, (draft) => {
        const {
          providersResponse: { results },
          locationId,
        }: {
          providersResponse: { results: Provider[] };
          locationId: string;
        } = action.payload.value;

        const newProvidersById = results
          .filter((provider) => !isEmptyArray(provider.specialties))
          .reduce(
            (providersById: { [key: string]: Provider }, provider: Provider) => ({
              ...providersById,
              [provider.id]: formatProvider(provider),
            }),
            {}
          );
        Object.assign(draft.providers, newProvidersById);
        const groupProvidersIds = Object.keys(newProvidersById);
        draft.locations[locationId].providers = groupProvidersIds;
      });
    case ProviderGroupsActions.PROVIDERS_SLOTS_RECEIVED: {
      const {
        value,
      }: {
        value: Array<{ id: string; slots: ProviderSlots }>;
      } = action.payload;
      return produce(state, (draft: ProviderGroupsState) => {
        value.forEach(({ id: providerId, slots }: { id: string; slots: ProviderSlots }) => {
          Object.entries(slots).forEach(([locationId, slots]) => {
            const existingSlotsForProvider = draft.slots[providerId] ?? {};
            if (isEmpty(existingSlotsForProvider)) {
              draft.slots[providerId] = {};
            }
            draft.slots[providerId][locationId] = {
              appointments: slots.appointments,
              reasons: slots.reasons,
            };
          });
        });
      });
    }
    case ProviderGroupsActions.GROUP_RECEIVED:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.value.id]: action.payload.value,
        },
      };
    case ProviderGroupsActions.GROUP_ERROR:
      return {
        ...state,
        groups: {
          ...state.groups,
          error: action.payload.value,
        },
      };
    case ProviderGroupsActions.GROUP_STATUS:
      return produce(state, (draft) => {
        const { status, groupId }: { status: ProviderCollectionStatus; groupId: string } =
          action.payload;
        draft.groupStatuses[groupId] = updateProviderCollectionStatus(
          draft.groupStatuses[groupId],
          status
        );
      });
    case ProviderGroupsActions.LOCATION_RECEIVED:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.payload.value.id]: locationResponseFormatter(action.payload.value),
        },
      };
    case ProviderGroupsActions.LOCATION_ERROR:
      return {
        ...state,
        locations: {
          ...state.locations,
          error: action.payload.value,
        },
      };
    case ProviderGroupsActions.LOCATION_STATUS:
      return produce(state, (draft) => {
        const { status, locationId }: { status: ProviderCollectionStatus; locationId: string } =
          action.payload;
        draft.locationStatuses[locationId] = updateProviderCollectionStatus(
          draft.locationStatuses[locationId],
          status
        );
      });
    case ProviderGroupsActions.SPECIALTIES_RECEIVED:
      return {
        ...state,
        specialties: {
          ...state.specialties,
          [PRIMARY_CARE_SPECIALTY_NAME]: PRIMARY_CARE_SPECIALTY_OBJECT,
          ...action.payload.value.results.reduce(
            (specialtiesByName: { [key: string]: Specialty }, specialty: Specialty) => ({
              ...specialtiesByName,
              [specialty.name]: specialty,
            }),
            {}
          ),
        },
      };
    case ProviderGroupsActions.SPECIALTY_ERROR:
      return {
        ...state,
        specialties: {
          ...state.specialties,
          error: action.payload.value,
        },
      };
    case ProviderGroupsActions.INITIAL_PAGINATED_RATINGS_RECEIVED: {
      return {
        ...state,
        ratings: {
          page: action.payload.value.page,
          ratingsData: [...action.payload.value.results],
          error: undefined,
        },
      };
    }
    case ProviderGroupsActions.PAGINATED_RATINGS_RECEIVED: {
      const priorRatings = state.ratings?.ratingsData ?? [];
      return {
        ...state,
        ratings: {
          page: action.payload.value.page,
          ratingsData: [...priorRatings, ...action.payload.value.results],
          error: undefined,
        },
      };
    }
    case ProviderGroupsActions.RATINGS_ERROR:
      return {
        ...state,
        ratings: {
          ...state.ratings,
          page: {
            limit: 0,
            page: 0,
            results_count: 0,
          },
          ratingsData: [],
          error: action.payload.value,
        },
      };
    case ProviderGroupsActions.SET_SEARCH_FILTERS:
      return produce(state, (draft) => {
        Object.assign(draft.searchFilters, action.payload.value);
      });
    case ProviderGroupsActions.CLEAR_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: {},
      };
    case ProviderGroupsActions.SET_EXTERNAL_SLOT_DATE_RANGE:
      return produce(state, (draft) => {
        draft.externalSlotDateRange = action.payload.value;
      });
    case ProviderGroupsActions.SET_DATE_SELECTED_BY_USER:
      return produce(state, (draft) => {
        draft.externalSlotDateRange.dateSelectedByUser = action.payload.value;
      });
    case ProviderGroupsActions.SET_VIEW_CUSTOMIZATIONS:
      return produce(state, (draft) => {
        Object.assign(draft.viewCustomizations, action.payload.value);
      });
    case ProviderGroupsActions.CLEAR_SLOTS:
      return produce(state, (draft) => {
        draft.slots = {};
      });
    default:
      return produce(state, (draft: any) => {
        if (!draft.providers) {
          draft.providers = {};
        }
        if (!draft.providerStatuses) {
          draft.providerStatuses = {};
        }
        if (!draft.groupStatuses) {
          draft.groupStatuses = {};
        }
        if (!draft.ratings) {
          draft.ratings = { page: {} };
        }
      });
  }
};

export default reducer;
