import { Reducer, AnyAction } from 'redux';
import LabResult from '@solvhealth/types/interfaces/LabResult';
import LabResultConfig from '@solvhealth/types/interfaces/LabResultConfig';

const SET_LAB_RESULT = 'labResults/SET';
const SET_LAB_RESULT_CONFIG = 'labResults/SET_CONFIG';

export const setLabResult = (labResultId: string, value: LabResult): AnyAction => ({
  type: SET_LAB_RESULT,
  labResultId,
  payload: value,
});

export const setLabResultConfig = (value: LabResultConfig[]): AnyAction => ({
  type: SET_LAB_RESULT_CONFIG,
  payload: value,
});

export interface LabResultState {
  labResults: {
    [labResultId: string]: LabResult;
  };
  labConfigs: {
    [labResultConfigId: string]: LabResultConfig;
  };
}

const DEFAULT_STATE: LabResultState = {
  labConfigs: {},
  labResults: {},
};

const reducer: Reducer<LabResultState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_LAB_RESULT:
      return {
        ...state,
        labResults: {
          ...state.labResults,
          [action.labResultId]: action.payload,
        },
      };
    case SET_LAB_RESULT_CONFIG: {
      const results: LabResultConfig[] = action.payload;
      const labConfigs = results.reduce(
        (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
        {} as { [labResultConfigId: string]: LabResultConfig }
      );
      return {
        ...state,
        labConfigs,
      };
    }

    default:
      return state;
  }
};

export default reducer;
