import { useEffect, useState } from 'react';
import { Position } from '../../hooks/geo/interfaces/Position';
import { PositionTrigger, useUserPosition } from '../../hooks/geo/useUserPosition';

interface Props {
  onSuccess?: (position: Position) => void;
  onError?: (error: any) => void;
  trigger?: PositionTrigger;
  timeout?: number;
  requestBrowserLocation?: boolean;
}

/**
 * Component wrapper for the 'useUserPosition()` hook.
 *
 * @deprecated ONLY CREATED FOR LEGACY CLASS COMPONENTS. DO NOT USE.
 * @param props
 * @class
 */
const GeoLocate = (props: Props) => {
  const { onSuccess, onError, trigger, timeout } = props;

  const { position, accuratePositionId } = useUserPosition(trigger, timeout);

  useEffect(() => {
    if (trigger !== PositionTrigger.AccurateSearch || accuratePositionId > 0) {
      if (position.result && position.success) {
        onSuccess?.(position.result);
      } else if (position.error) {
        onError?.(position.error);
      }
    }
  }, [
    onSuccess,
    onError,
    position.result,
    position.success,
    position.error,
    position.gettingPosition,
    trigger,
    accuratePositionId,
  ]);

  return null;
};

GeoLocate.defaultProps = {
  ipOnly: false,
};

export default GeoLocate;
