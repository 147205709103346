export const STAR_RATING_UPDATED = 'leaveAReview/PRIMARY_RATING_UPDATED';
export const REVIEW_SUBMITTED = 'leaveAReview/REVIEW_SUBMITTED';
export const SUBMISSION_RESPONSE_RECEIVED = 'leaveAReview/SUBMISSION_RESPONSE_RECEIVED';
export const THANKS_FOR_FEEDBACK = 'leaveAReview/THANKS_FOR_FEEDBACK';
const CLEAR_LEAVE_A_REVIEW = 'leaveAReview/CLEAR_DATA';

export type LeaveAReviewState = {
  value?: number;
  genre?: string;
  submissionStatus?: string;
  thanksForFeedback?: boolean;
};

export const starRatingUpdated = ({ value, genre }: any) => ({
  type: STAR_RATING_UPDATED,
  payload: { value, genre },
});

export const reviewSubmitted = (value: any) => ({
  type: REVIEW_SUBMITTED,
  payload: { value },
});

export const reviewResponseReceived = (value: any) => ({
  type: SUBMISSION_RESPONSE_RECEIVED,
  payload: { value },
});

export const thanksForFeedback = () => ({
  type: THANKS_FOR_FEEDBACK,
});

export const clearLeaveAReview = () => ({
  type: CLEAR_LEAVE_A_REVIEW,
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case STAR_RATING_UPDATED:
      return {
        ...state,
        [action.payload.genre]: action.payload.value,
      };
    case REVIEW_SUBMITTED:
      return {
        ...state,
        submissionStatus: 'submitting',
      };
    case SUBMISSION_RESPONSE_RECEIVED:
      return {
        ...state,
        submissionStatus: action.payload.value,
      };
    case THANKS_FOR_FEEDBACK:
      return {
        ...state,
        thanksForFeedback: true,
      };
    case CLEAR_LEAVE_A_REVIEW:
      return {};
    default:
      return state;
  }
};
