import { takeEvery, call, put, select } from 'redux-saga/effects';
import Location from '@solvhealth/types/interfaces/Location';
import { SolvReduxState } from 'src/reducers';
import { ProviderStatus } from 'src/components/ProviderGroup/interfaces';
import Provider from '@solvhealth/types/interfaces/Provider';
import { getProvidersUrl, getProviderUrl } from '~/core/dapi/providerGroups';
import { apiGet } from '~/core/dapi';
import {
  defaultExternalSlotDateRange,
  defaultProviderStatus,
  groupStatus,
  locationStatus,
  providerLoading,
  providerStatus,
  providerLoadingExternal,
  receiveGroup,
  receiveLocation,
  receiveProvider,
  receiveProviderError,
  receiveProviderExternalError,
  receiveGroupProviders,
  receiveProvidersSlots,
  receiveSpecialties,
  receiveSpecialtiesError,
  setExternalSlotDateRange,
  receiveLocationProviders,
} from '../ducks/providerGroups';
import { getSpecialtyListFromString } from '~/components/ProviderGroup/util';
import { getGroupUrl } from '~/core/dapi/groups';
import { getSpecialtiesUrl } from '~/core/dapi/specialties';
import { getLocationUrl, PROVIDER_GROUP_LOCATION_FIELDS } from '~/core/dapi/location';

export const FETCH_PROVIDER = 'saga/providerGroups/FETCH_PROVIDER';
export const FETCH_PROVIDER_INTERNAL = 'saga/providerGroups/FETCH_PROVIDER_INTERNAL';
export const FETCH_PROVIDER_EXTERNAL = 'saga/providerGroups/FETCH_PROVIDER_EXTERNAL';

export const FETCH_PROVIDERS_EXTERNAL = 'saga/providerGroups/FETCH_PROVIDERS_EXTERNAL';
export const FETCH_GROUP_PROVIDERS = 'saga/providerGroups/FETCH_GROUP_PROVIDERS';
export const FETCH_GROUP_PROVIDERS_INTERNAL = 'saga/providerGroups/FETCH_GROUP_PROVIDERS_INTERNAL';
export const FETCH_GROUP_PROVIDERS_EXTERNAL = 'saga/providerGroups/FETCH_GROUP_PROVIDERS_EXTERNAL';

export const FETCH_SPECIALTIES = 'saga/providerGroups/FETCH_SPECIALTIES';

export const INITIALIZE_GROUP_SPECIALTY_PAGE =
  'saga/providerGroups/INITIALIZE_GROUP_SPECIALTY_PAGE';
export const INITIALIZE_GROUP_LOCATION_PAGE = 'saga/providerGroups/INITIALIZE_GROUP_LOCATION_PAGE';

const {
  lastFetchStartDate: defaultStartDate,
  lastFetchEndDate: defaultEndDate,
  dateSelectedByUser: defaultDateSelectedByUser,
} = defaultExternalSlotDateRange;

function* fetchProvider({ providerId }: { providerId: string; type: string }) {
  yield put({ type: FETCH_PROVIDER_INTERNAL, providerId });
  yield put({ type: FETCH_PROVIDER_EXTERNAL, providerId });
}

function* fetchProviderInternal({ providerId }: { providerId: string; type: string }) {
  const { loading, error }: ProviderStatus = yield select(
    (state: any) => state.providerGroups.providerStatuses[providerId] || defaultProviderStatus
  );
  const provider: Provider = yield select(
    (state: any) => state.providerGroups.providers[providerId]
  );
  if (!provider && !(loading || error)) {
    try {
      yield put(providerLoading(providerId));
      const url = getProviderUrl(providerId, false);
      const providerResponse = yield call(apiGet, url);
      yield put(receiveProvider(providerResponse));
    } catch (error) {
      yield put(receiveProviderError({ error, providerId }));
    }
  }
}

function* fetchProviderExternal({
  providerId,
  startDate,
  endDate,
}: {
  providerId: string;
  startDate?: string;
  endDate?: string;
  type: string;
}) {
  const { loadingExternal, errorExternal }: ProviderStatus = yield select(
    (state: SolvReduxState) =>
      state.providerGroups.providerStatuses[providerId] || defaultProviderStatus
  );
  const provider: Provider = yield select(
    (state: SolvReduxState) => state.providerGroups.providers[providerId]
  );
  const { dateSelectedByUser } = yield select(
    (state: SolvReduxState) => state.providerGroups.externalSlotDateRange
  );

  if (provider?.is_pg_solv_partner && !(loadingExternal || errorExternal)) {
    try {
      yield put(providerLoadingExternal(providerId));
      yield put(
        setExternalSlotDateRange({
          minDate: defaultStartDate,
          lastFetchStartDate: startDate || defaultStartDate,
          lastFetchEndDate: endDate || defaultEndDate,
          dateSelectedByUser: dateSelectedByUser || defaultDateSelectedByUser,
        })
      );
      const url = getProviderUrl(providerId, true, startDate, endDate);
      const providerResponse = yield call(apiGet, url);
      yield put(receiveProvidersSlots([providerResponse]));
      yield put(providerStatus({ providerId, status: { loadingExternal: false } }));
    } catch (error) {
      yield put(receiveProviderExternalError({ providerId, error }));
    }
  }
}

function* fetchGroupProviders({
  payload,
}: {
  type: string;
  payload: { groupId: string; specialty: string };
}) {
  yield put({ type: FETCH_GROUP_PROVIDERS_INTERNAL, payload });
  yield put({ type: FETCH_GROUP_PROVIDERS_EXTERNAL, payload });
}

function* fetchGroupProvidersInternal({
  payload: { groupId, specialty },
}: {
  type: string;
  payload: { groupId: string; specialty?: string };
}) {
  yield put(
    groupStatus({
      groupId,
      status: {
        providersLoading: true,
      },
    })
  );

  const specialtyList = getSpecialtyListFromString(specialty);

  const url = getProvidersUrl({
    groupId,
    specialties: specialtyList,
  });

  try {
    const providersResponse = yield call(apiGet, url);
    yield put(receiveGroupProviders({ providersResponse, groupId }));
    yield put(groupStatus({ groupId, status: { providersLoading: false } }));
  } catch (error) {
    yield put(
      groupStatus({
        groupId,
        status: { providersLoading: false, providersError: true },
      })
    );
  }
}

function* fetchGroupProvidersExternal({
  payload: { groupId, locationId, specialty, startDate, endDate },
}: {
  type: string;
  payload: {
    groupId?: string;
    locationId?: string;
    specialty?: string;
    startDate?: string;
    endDate?: string;
  };
}) {
  const { dateSelectedByUser } = yield select(
    (state: SolvReduxState) => state.providerGroups.externalSlotDateRange
  );

  // these ifs are hackey, the external loading status should maybe just be moved to the top level of the PG store slice
  if (groupId) {
    yield put(
      groupStatus({
        groupId,
        status: {
          providersLoadingExternal: true,
        },
      })
    );
  }

  if (locationId) {
    yield put(
      locationStatus({
        locationId,
        status: {
          providersLoadingExternal: true,
        },
      })
    );
  }

  const specialtyList = getSpecialtyListFromString(specialty);

  const url = getProvidersUrl({
    groupId,
    ...(groupId && { groupId }),
    ...(locationId && { locationId }),
    ...(specialtyList && { specialties: specialtyList }),
    fields: ['id', 'slots'],
    startDate,
    endDate,
  });

  try {
    const providersResponse = yield call(apiGet, url);
    yield put(
      setExternalSlotDateRange({
        minDate: defaultStartDate,
        lastFetchStartDate: startDate || defaultStartDate,
        lastFetchEndDate: endDate || defaultEndDate,
        dateSelectedByUser: dateSelectedByUser || defaultDateSelectedByUser,
      })
    );
    yield put(receiveProvidersSlots(providersResponse.results));

    if (groupId) {
      yield put(groupStatus({ groupId, status: { providersLoadingExternal: false } }));
    }
    if (locationId) {
      yield put(locationStatus({ locationId, status: { providersLoadingExternal: false } }));
    }
  } catch (error) {
    if (groupId) {
      yield put(
        groupStatus({
          groupId,
          status: { providersLoadingExternal: false, providersErrorExternal: true },
        })
      );
    }
    if (locationId) {
      yield put(
        locationStatus({
          locationId,
          status: { providersLoadingExternal: false, providersErrorExternal: true },
        })
      );
    }
  }
}

/** generic slots fetching by providerIds, we should migrate to this one for proper paginated fetching
 *
 */
function* fetchProvidersExternal({
  payload: { providerIds, startDate, endDate },
}: {
  type: string;
  payload: { providerIds: string[]; startDate?: string; endDate?: string };
}) {
  const { dateSelectedByUser } = yield select(
    (state: SolvReduxState) => state.providerGroups.externalSlotDateRange
  );

  providerIds.forEach((providerId) => {
    put(providerStatus({ providerId, status: { loadingExternal: true } }));
  });

  const url = getProvidersUrl({
    providerIds,
    fields: ['id', 'slots'],
    startDate,
    endDate,
  });

  try {
    const response = yield call(apiGet, url);
    yield put(receiveProvidersSlots(response.results));
    yield put(
      setExternalSlotDateRange({
        minDate: defaultStartDate,
        lastFetchStartDate: startDate || defaultStartDate,
        lastFetchEndDate: endDate || defaultEndDate,
        dateSelectedByUser: dateSelectedByUser || defaultDateSelectedByUser,
      })
    );
    providerIds.forEach((providerId) => {
      put(providerStatus({ providerId, status: { loadingExternal: false } }));
    });
  } catch (error) {
    providerIds.forEach((providerId) => {
      put(providerStatus({ providerId, status: { errorExternal: true } }));
    });
  }
}

/** Saga to get all specialties in our solv specialties table
 *
 */
function* fetchProviderSpecialties() {
  const specialtiesUrl = getSpecialtiesUrl();
  try {
    const specialtiesResponse = yield call(apiGet, specialtiesUrl);
    if (specialtiesResponse.results) {
      yield put(receiveSpecialties(specialtiesResponse));
    }
  } catch (error) {
    yield put(receiveSpecialtiesError(error));
  }
}

/**
 * Saga to initialize everything we need to render our PG group specialty page.
 *
 * In order for our PG group specialty page to work, we need the group information
 * (specifically group name and configuration), all of the providers that work
 * within that group, and all of the specialties in our solv specialties table.
 *
 * @param {string} groupId id hash of group
 * @returns {Function}
 */
function* initializeGroupSpecialtyPage({
  payload: { groupId },
}: {
  type: string;
  payload: { groupId: string };
}) {
  const groupUrl = getGroupUrl(groupId);
  const providersUrl = getProvidersUrl({
    groupId,
  });

  yield put(groupStatus({ groupId, status: { loading: true, providersLoading: true } }));

  // get group itself (name, configuration, etc)
  try {
    const groupResponse = yield call(apiGet, groupUrl);
    if (groupResponse) {
      yield put(receiveGroup(groupResponse));
    }
  } catch (error) {
    yield put(groupStatus({ groupId, status: { loading: false, error: true } }));
  }

  // get all providers in group, regardless of specialty
  try {
    const providersResponse = yield call(apiGet, providersUrl);
    if (providersResponse.results) {
      yield put(receiveGroupProviders({ providersResponse, groupId }));
    }
  } catch (error) {
    yield put(groupStatus({ groupId, status: { providersLoading: false, providersError: true } }));
  }

  yield put({ type: FETCH_SPECIALTIES });

  yield put(
    groupStatus({
      groupId,
      status: { loading: false, providersLoading: false },
    })
  );
}

function* initializeGroupLocationPage({
  payload: { locationId },
}: {
  type: string;
  payload: { locationId: string };
}) {
  yield put(locationStatus({ locationId, status: { loading: true, providersLoading: true } }));

  try {
    const locationUrl = getLocationUrl(locationId, {
      version: 'v4',
      fields: PROVIDER_GROUP_LOCATION_FIELDS,
    });
    const locationResponse = yield call(apiGet, locationUrl);
    if (locationResponse) {
      yield put(receiveLocation(locationResponse));
    }
  } catch (error) {
    yield put(locationStatus({ locationId, status: { loading: false, error: true } }));
  }

  const location: Location = yield select(
    (state: SolvReduxState) => state.providerGroups.locations[locationId]
  );

  const {
    groups: [{ group_id: groupId }],
  } = location;

  try {
    const groupUrl = getGroupUrl(groupId);
    const groupResponse = yield call(apiGet, groupUrl);
    if (groupResponse) {
      yield put(receiveGroup(groupResponse));
    }
  } catch (error) {
    yield put(groupStatus({ groupId, status: { loading: false, error: true } }));
  }

  try {
    const providersUrl = getProvidersUrl({ locationId });
    const providersResponse = yield call(apiGet, providersUrl);
    if (providersResponse.results) {
      yield put(receiveLocationProviders({ providersResponse, locationId }));
    }
  } catch (error) {
    yield put(
      locationStatus({ locationId, status: { providersLoading: false, providersError: true } })
    );
  }

  yield put({ type: FETCH_SPECIALTIES });

  yield put(locationStatus({ locationId, status: { loading: false, providersLoading: false } }));
}

function* watchProviderRequests() {
  yield takeEvery(FETCH_SPECIALTIES, fetchProviderSpecialties);
  yield takeEvery(FETCH_PROVIDER, fetchProvider);
  yield takeEvery(FETCH_PROVIDER_INTERNAL, fetchProviderInternal);
  yield takeEvery(FETCH_PROVIDER_EXTERNAL, fetchProviderExternal);
  yield takeEvery(FETCH_PROVIDERS_EXTERNAL, fetchProvidersExternal);
  yield takeEvery(FETCH_GROUP_PROVIDERS, fetchGroupProviders);
  yield takeEvery(FETCH_GROUP_PROVIDERS_INTERNAL, fetchGroupProvidersInternal);
  yield takeEvery(FETCH_GROUP_PROVIDERS_EXTERNAL, fetchGroupProvidersExternal);
  yield takeEvery(INITIALIZE_GROUP_SPECIALTY_PAGE, initializeGroupSpecialtyPage);
  yield takeEvery(INITIALIZE_GROUP_LOCATION_PAGE, initializeGroupLocationPage);
}

export { watchProviderRequests as default };
