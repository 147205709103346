import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
// @ts-ignore
import { formShape } from 'rc-form';
import { REASON_FIELD, REPORT_REASONS } from './constants';

type ReasonForReportFieldProps = {
  getFieldProps: formShape['getFieldProps'];
  onChange: any;
};

const ReasonForReportField = (props: ReasonForReportFieldProps) => {
  const { getFieldProps, onChange } = props;
  const reasonField = {
    ...getFieldProps(REASON_FIELD, {
      validateTrigger: 'onBlur',
      rules: [{ required: true, message: 'required' }],
    }),
  };

  return (
    <RadioGroup
      data-testid="report-field-radio-group"
      id={REASON_FIELD}
      name={REASON_FIELD}
      onChange={(e, val) => {
        onChange({ target: { id: REASON_FIELD, value: val } });
      }}
      {...reasonField}
      required
    >
      {REPORT_REASONS.map((r) => (
        <FormControlLabel
          control={<Radio required />}
          data-testid="report-field-label"
          key={r}
          label={r}
          value={r}
        />
      ))}
    </RadioGroup>
  );
};

export default ReasonForReportField;
