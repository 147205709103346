import React from 'react';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { camelCaseKeys, useFlags } from 'launchdarkly-react-client-sdk';
import { useCookies } from 'react-cookie';
import { getFlags } from '~/core/launch-darkly/flags';
import { analyticsTrackEvent } from '~/core/analytics';
import { LAUNCH_DARKLY_TRACKED_FLAG } from '~/core/analytics/events';
import { OVERRIDE_LAUNCH_DARKLY_FLAGS_COOKIE_NAME } from '../../config';

export const LDProxyContext = React.createContext({
  flags: getFlags(),
});

/** Here we are storing which flags have been evaluated from the user in their current session so that when flags are
 * evaluated we can track the event without spamming our segment events with flag reads that have already been reported.
 * If a flag value is changed, we will trigger another event that will be tracked.
 */
let trackedFlags: Record<string, any> = {};

/**
 * This Wrapper for application uses a Javascript Proxy object on top of the Launch Darkly SDK in order
 * to track which flags are getting assigned to the user. This is necessary because the Launch Darkly
 * SDK does not provide a way to get a list of all flags that are being used in the application without
 * assigning the user to each flag for an experiment.
 */
export const LDProxyProvider = ({ children }: any) => {
  // eslint-disable-next-line no-restricted-syntax
  const ldFlags = useFlags();
  const [cookies] = useCookies([OVERRIDE_LAUNCH_DARKLY_FLAGS_COOKIE_NAME]);
  const overrideLDFlags = camelCaseKeys(cookies[OVERRIDE_LAUNCH_DARKLY_FLAGS_COOKIE_NAME] ?? {});

  const getFunc = (target: LDFlagSet, property: string) => {
    // Here we are overriding the launch darkly flags with any explicitly set values for the purpose of e2e testing.
    const val = overrideLDFlags[property] ?? target[property];

    if (
      val !== undefined &&
      (!trackedFlags.hasOwnProperty(property) || trackedFlags[property] !== val)
    ) {
      analyticsTrackEvent(LAUNCH_DARKLY_TRACKED_FLAG, {
        flag: property,
        value: `${val}`,
      });
      trackedFlags[property] = val;
    }

    return val;
  };

  const handler = {
    get: getFunc,
  };

  const flags = new Proxy(ldFlags, handler);

  return <LDProxyContext.Provider value={{ flags }}>{children}</LDProxyContext.Provider>;
};
