import { TFunction } from 'react-i18next';
import {
  scanCompletedBack,
  scanCompletedFront,
  setCardIdBack,
  setCardIdFront,
  setOcrDataFront,
} from '~/actions/addInsurance';
import { setRuntimeVariable } from '~/actions/runtime';
import { getInsurerIdByInsurerCode } from '~/core/util/insurers';
import { buildDateStringFromUserInput, deconstructDateString } from '~/core/util/date';
import { getInsurerCodeFromName } from './insurers';
import { isString } from '~/core/util/string';
import { doValuesMatch, isEmptyObject } from '~/core/util/object';
import { OriginType } from '~/core/util/bookingFlowRoutingTypes';
import {
  ADD_INSURANCE_ORIGIN_APP_BOOKING,
  ADD_INSURANCE_ORIGIN_BOOKING_WIDGET,
  ADD_INSURANCE_ORIGIN_USER_PROFILE,
} from '~/core/util/bookingFlowRouting';
import { SolvReduxState } from '~/reducers';
import { InsurersState } from '~/reducers/insurers';

export type InsuranceFormShape = {
  insuranceCarrier: string;
  insuranceType: string;
  memberId: string;
  firstName: string;
  lastName: string;
  cardFront: string;
  cardBack: string;
  dobYear: string;
  dobMonth: string;
  dobDay: string;
};

export const getFields = (t: TFunction<('addInsurance' | 'common')[]>) => ({
  dobYear: { id: 'dobYear', label: t('common:year') },
  dobMonth: {
    id: 'dobMonth',
    label: t('common:userInfo.birthDateMonth'),
  },
  dobDay: { id: 'dobDay', label: t('common:day') },
  insuranceCarrier: {
    id: 'insuranceCarrier',
    label: t('addInsurance:insuranceForm.insuranceCarrier'),
  },
  insuranceType: {
    id: 'insuranceType',
    label: t('addInsurance:insuranceForm.insuranceType'),
  },
  memberId: { id: 'memberId', label: t('addInsurance:insuranceForm.memberId') },
  firstName: { id: 'firstName', label: t('addInsurance:insuranceForm.firstName') },
  lastName: { id: 'lastName', label: t('addInsurance:insuranceForm.lastName') },
});

const isMatchingProfiles = (profile1: any, profile2: any) =>
  doValuesMatch(
    [profile1, profile2],
    ['firstName', 'lastName'],
    (val1, val2) =>
      isString(val1) && isString(val2) && val1.trim().toLowerCase() === val2.trim().toLowerCase()
  );

const getInitialValues = (state: SolvReduxState) => {
  /* If there is no data from addInsurance state, use the newBooking data
   * If there is addInsurance data, check if profiles match and return a combination
   * Otherwise, return the addInsurance data
   */
  const { day, month, year } = deconstructDateString(state.newBooking.profile.birthDate);
  if (isEmptyObject(state.addInsurance.insurance)) {
    return {
      dobDay: day,
      dobMonth: month,
      dobYear: year,
      ...state.newBooking.profile,
    };
  }

  const { dobDay, dobMonth, dobYear, ...insuranceProfile } = state.addInsurance.insurance;
  if (isMatchingProfiles(state.addInsurance.insurance, state.newBooking.profile)) {
    return {
      dobDay: dobDay || day,
      dobMonth: dobMonth || month,
      dobYear: dobYear || year,
      ...state.newBooking.profile,
      ...insuranceProfile,
    };
  }

  return state.addInsurance.insurance;
};

const buildInsuranceProfilePostData = (
  insuranceForm: Partial<InsuranceFormShape> = {},
  accountId: any,
  insurers: InsurersState = []
) => {
  const insuranceCarrierName =
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'insuranceCarrier' does not exist on type... Remove this comment to see the full error message
    isString(insuranceForm.insuranceCarrier) && insuranceForm.insuranceCarrier.trim();
  return {
    account_id: accountId,
    insurer_id: getInsurerIdByInsurerCode(
      getInsurerCodeFromName(insurers, insuranceCarrierName),
      insurers
    ),
    insurer_type: insuranceForm.insuranceType,
    insurer_name: insuranceCarrierName,
    member_code: insuranceForm.memberId,
    first_name: insuranceForm.firstName,
    last_name: insuranceForm.lastName,
    birth_date: buildDateStringFromUserInput(
      insuranceForm.dobYear,
      insuranceForm.dobMonth,
      insuranceForm.dobDay
    ),
    card_front_id: insuranceForm.cardFront,
    card_back_id: insuranceForm.cardBack,
  };
};

const buildInsuranceCoveragePostData = (
  insuranceForm: Partial<InsuranceFormShape> = {},
  location: Partial<Location> = {},
  insurers: InsurersState = [],
  userProfileId: any = null,
  insuranceProfileId: any = null
) => {
  const insuranceCarrierName =
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'insuranceCarrier' does not exist on type... Remove this comment to see the full error message
    isString(insuranceForm.insuranceCarrier) && insuranceForm.insuranceCarrier.trim();
  return {
    member_code: insuranceForm.memberId,
    insurer_name: insuranceCarrierName,
    insurer_code: getInsurerCodeFromName(insurers, insuranceCarrierName),
    plan_type: insuranceForm.insuranceType,
    first_name: insuranceForm.firstName,
    last_name: insuranceForm.lastName,
    birth_date: buildDateStringFromUserInput(
      insuranceForm.dobYear,
      insuranceForm.dobMonth,
      insuranceForm.dobDay
    ),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'npi' does not exist on type '{}'.
    npi_list_str: location.npi,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
    location_id: location.id,
    user_profile_id: userProfileId,
    insurance_profile_id: insuranceProfileId,
  };
};

const buildNewBookingInsuranceData = (
  insuranceForm: Partial<InsuranceFormShape> = {},
  insurers: InsurersState = [],
  userProfileId: string | null = null,
  accountId: any = null
) => {
  const insuranceCarrierName =
    isString(insuranceForm.insuranceCarrier) && insuranceForm.insuranceCarrier?.trim();
  return {
    memberCode: insuranceForm.memberId,
    insurerName: insuranceCarrierName,
    insurerCode: getInsurerCodeFromName(insurers, insuranceCarrierName),
    insurerType: insuranceForm.insuranceType,
    firstName: insuranceForm.firstName,
    lastName: insuranceForm.lastName,
    cardFront: insuranceForm.cardFront,
    cardBack: insuranceForm.cardBack,
    birthDate: buildDateStringFromUserInput(
      insuranceForm.dobYear,
      insuranceForm.dobMonth,
      insuranceForm.dobDay
    ),
    userProfileId,
    accountId,
  };
};

const dispatchInsuranceForEdit = (dispatch: any) => (insuranceProfile: any) => {
  const { day, month, year } = deconstructDateString(insuranceProfile.birth_date);
  dispatch(
    setOcrDataFront({
      firstName: insuranceProfile.first_name,
      lastName: insuranceProfile.last_name,
      insuranceCarrier: insuranceProfile.insurer_name,
      insuranceType: insuranceProfile.insurer_type,
      memberId: insuranceProfile.member_code,
      dobDay: day,
      dobMonth: month,
      dobYear: year,
    })
  );
  dispatch(setCardIdFront(insuranceProfile.card_front_id));
  dispatch(setCardIdBack(insuranceProfile.card_back_id));
  dispatch(scanCompletedFront());
  dispatch(scanCompletedBack());
  dispatch(setRuntimeVariable({ name: 'isEditFlow', value: true }));
};

const getSkipButtonCopy = (
  origin: OriginType,
  isInsuranceRequiredForBooking: boolean,
  isMobileFlow: boolean,
  isInsuranceFormPage: boolean,
  t: TFunction<('addInsurance' | 'common')[]>
): string | null => {
  const handleMobileFlow = () => {
    const skipAddingInsuranceMessage = t('addInsurance:insuranceForm.skipAddingInsurance');
    if (isMobileFlow) {
      if (isInsuranceFormPage) {
        return skipAddingInsuranceMessage;
      }
      return t('addInsurance:insuranceForm.skipUploadingPhotos');
    }
    return skipAddingInsuranceMessage;
  };

  // CDP flow
  if (origin === ADD_INSURANCE_ORIGIN_APP_BOOKING) {
    if (isInsuranceRequiredForBooking) {
      return t('addInsurance:insuranceForm.imNotUsingInsurance');
    }
    return handleMobileFlow();
  }
  // Booking widget flow
  if (origin === ADD_INSURANCE_ORIGIN_BOOKING_WIDGET) {
    if (isInsuranceRequiredForBooking) {
      return null;
    }
    return handleMobileFlow();
  }
  // Account AddInsurance flow
  if (origin === ADD_INSURANCE_ORIGIN_USER_PROFILE) {
    if (isInsuranceFormPage) {
      return handleMobileFlow();
    }
    return t('addInsurance:insuranceForm.skipUploadingPhotos');
  }

  return null;
};

export {
  buildInsuranceCoveragePostData,
  buildInsuranceProfilePostData,
  buildNewBookingInsuranceData,
  dispatchInsuranceForEdit,
  isMatchingProfiles,
  getInitialValues,
  getSkipButtonCopy,
};
