import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { fontFamily, fontSize } from '../../../constants/text';

export const PinkPrimaryButton = styled(Button)`
  text-transform: none;
  background-color: ${colors.crunchBerry};
  padding: 13px 51px 16px 52px;
  font-size: ${fontSize.medium};
  border-radius: 4px;
  box-shadow: none;
  font-family: ${fontFamily.bold};
  height: 52px;
  width: ${(props) =>
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'ButtonPro... Remove this comment to see the full error message
    props.width || '168px'};
  color: ${colors.white};
  letter-spacing: -0.26px;
  line-height: 23px;
  text-align: center;
  &:disabled {
    color: white;
  }

  &:hover {
    background-color: ${colors.crunchBerry};
    box-shadow: none;
  }
  -webkit-font-smoothing: antialiased;
  span {
    white-space: nowrap;
  }
`;

export const BluePrimaryButton = styled(PinkPrimaryButton)`
  background-color: ${colors.mrBlueSky};
  &:hover {
    background-color: ${colors.mrBlueSky};
  }
`;

export const PinkSmallPrimaryButton = styled(PinkPrimaryButton)`
  font-size: 14px;
  height: 40px;
  width: 97px;
  padding: 10px 24px 12px 23px;
  line-height: 18px;
  letter-spacing: -0.2px;
`;

export const BlueSmallPrimaryButton = styled(BluePrimaryButton)`
  font-size: 14px;
  height: 40px;
  width: 97px;
  padding: 10px 24px 12px 23px;
  line-height: 18px;
  letter-spacing: -0.2px;
`;

export const TertiaryButton = styled(Button)`
  text-transform: none;
  padding: 13px 51px 16px 52px;
  font-size: ${fontSize.medium};
  border-radius: 4px;
  font-family: ${fontFamily.book};
  height: 52px;
  width: ${(props) =>
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'ButtonPro... Remove this comment to see the full error message
    props.width || '168px'};
  color: ${colors.tangledUpInBlue};
  border: 1px solid ${colors.tangledUpInBlue};
  letter-spacing: -0.26px;
  line-height: 23px;
  text-align: center;
  &:hover {
    background-color: ${colors.white};
    border-color: ${colors.blueCloud};
  }
  -webkit-font-smoothing: antialiased;
`;

export const PinkTertiaryButton = styled(Button)`
  text-transform: none;
  padding: 8px 16px;
  font-size: ${fontSize.default};
  border-radius: 4px;
  font-family: ${fontFamily.book};
  height: 40px;
  width: ${(props) =>
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'ButtonPro... Remove this comment to see the full error message
    props.width || '168px'};
  color: ${colors.crunchBerry};
  border: 1px solid ${colors.pinkie};
  letter-spacing: -0.26px;
  line-height: 23px;
  text-align: center;
  &:hover {
    background-color: ${colors.white};
    border-color: ${colors.pinkie};
  }
  -webkit-font-smoothing: antialiased;
`;

export const buttonTypes = [
  'pinkPrimary',
  'bluePrimary',
  'pinkPrimarySmall',
  'tertiary',
  'pinkTertiary',
  'link',
];

export const LinkButton = styled(Button)<{ color?: string }>`
  color: ${(props) => props.color || colors.blueJayWay};
  font-size: ${fontSize.default};
  font-family: ${fontFamily.book};
  text-transform: capitalize;
  &:hover {
    background-color: unset;
  }
`;
