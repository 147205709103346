import { FETCH_SPECIALTIES } from '../../sagas/specialties';

const SPECIALTIES_FETCHING = 'specialties/SPECIALTIES_FETCHING';
const SPECIALTIES_ERROR = 'specialties/SPECIALTIES_ERROR';
const SPECIALTIES_RECEIVED = 'specialties/SPECIALTIES_RECEIVED';

export const fetchSpecialty = () => ({
  type: FETCH_SPECIALTIES,
});

export const specialtiesFetching = () => ({
  type: SPECIALTIES_FETCHING,
});

export const specialtiesError = (value: any) => ({
  type: SPECIALTIES_ERROR,
  payload: { value },
});

export const specialtiesReceived = (value: any) => ({
  type: SPECIALTIES_RECEIVED,
  payload: { value },
});

export default (state = {}, action: any) => {
  switch (action.type) {
    case SPECIALTIES_RECEIVED:
      return {
        ...state,
        result: action.payload.value.results,
        error: null,
        fetching: false,
      };
    case SPECIALTIES_ERROR:
      return {
        ...state,
        result: null,
        error: action.payload.value,
        fetching: false,
      };
    case SPECIALTIES_FETCHING:
      return {
        ...state,
        result: null,
        error: null,
        fetching: true,
      };
    default:
      return state;
  }
};
