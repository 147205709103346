import i18n, { InitOptions } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import has from 'lodash/has';
import moment from 'moment-timezone';
import { DefaultNamespace, Fallback, initReactI18next } from 'react-i18next';

export const defaultNS = 'common';

export type Language = 'en' | 'es' | 'so' | 'hmn';
export const langs: Language[] = ['en', 'es', 'so', 'hmn'];
export const isI18NSupportedLang = (str: any) => langs.includes(str);

/**
 * Returns a moment value from:
 * - a moment value
 * - a date
 * - a string
 *
 * @returns a moment value
 */
function getMomentValue(value: any) {
  let momentValue: moment.Moment | null = null;
  let parsedMomentFromStr: moment.Moment | null = null;
  if (moment.isMoment(value) && value.isValid()) {
    momentValue = value.clone();
  } else if (value instanceof Date) {
    momentValue = moment(value);
  } else if (
    typeof value === 'string' &&
    // eslint-disable-next-line no-cond-assign
    (parsedMomentFromStr = moment(value)).isValid()
  ) {
    momentValue = parsedMomentFromStr;
  }

  return momentValue;
}

const formatDirectives = {
  /**
   * Allow interpolated datetime values to be formatted using a {{value, moment::<momentFormatString>}} directive.
   * If you need to specify the timezone of the datetime, you should pass it in as a moment object.
   *
   * @param {moment.Moment | Date | string} value the datetime value to be formatted as a human-readable string
   * @param {string} momentFormatString a moment formatting string such as "MM/DD/YYYY" or "[Today is] dddd"
   * @returns {string} the datetime value presented as a string
   */
  moment(value: any, momentFormatString: string | null, lng: Language) {
    if (momentFormatString == null) {
      return value;
    }
    const momentValue = getMomentValue(value);

    if (momentValue == null) {
      return value;
    }

    return momentValue.locale(lng).format(momentFormatString);
  },
  /**
   * Allow interpolated datetime values to be formatted using a {{value, relative}} directive.
   * If you need to specify the timezone of the datetime, you should pass it in as a moment object.
   *
   * @param {moment.Moment | Date | string} value the datetime value to be formatted as a relative string
   * @returns {string} the datetime value presented as a relative string
   */
  relative(value: any, _: string | null, lng: Language) {
    const momentValue = getMomentValue(value);

    if (momentValue == null) {
      return value;
    }

    return momentValue.locale(lng).fromNow();
  },

  // Allow interpolated string values to be formatted using a {{value, uppercase}} directive.
  uppercase(value: any, _: string | null, lng: Language) {
    if (value == null) return '';
    if (typeof value !== 'string') return value;
    return value.toLocaleUpperCase(lng ?? 'en');
  },
};
const isFormatDirective = (str: string): str is keyof typeof formatDirectives =>
  has(formatDirectives, str);

export const options: InitOptions = {
  fallbackLng: 'en',
  supportedLngs: langs,

  // Lazy load all namespaces on the client (needed ones are loaded in by SSR or with a suspense call)
  ns: [],
  defaultNS,

  debug: false,

  react: {
    useSuspense: false,
    transKeepBasicHtmlNodesFor: ['p', 'strong', 'br', 'em', 'a', 'ul', 'li'],
  },
  interpolation: {
    format(value, format, lng) {
      // eslint-disable-next-line no-param-reassign
      format = format?.trim?.() ?? '';

      if (!format) return value;
      if (isFormatDirective(format)) {
        return formatDirectives[format]?.(value, null, lng as any) ?? value;
      }
      const directiveSeparatorIndex = format.indexOf('::');
      if (directiveSeparatorIndex >= 0) {
        let formatDirective = format.substring(0, directiveSeparatorIndex).trim();
        let formatArgument = format.substring(directiveSeparatorIndex + 2).trim();
        if (isFormatDirective(formatDirective)) {
          return formatDirectives[formatDirective]?.(value, formatArgument, lng as any) ?? value;
        }
      }

      return value;
    },
    // alwaysFormat: true,
    escapeValue: false, // react already safe from xss
  },
};

/**
 * Load in our ENGLISH translation keys directly from the `.lang.ts` files so we can update them in the UI
 * immediately without a build step. This also makes sure we always have a base set of translations loaded.
 *
 * @returns the resource bundle keyed under `en`.
 */
async function loadNamespaces() {
  const rootLocale = (await import(/* webpackChunkName: 'english.lang' */ '.')).default;
  return { en: rootLocale };
}

/**
 * Inits and then passes a callback and middleware to the i18n instance
 *
 * @returns A promise
 */
export async function init(i18nOptions: InitOptions, backend: any, callback?: () => void) {
  const resources = await loadNamespaces();
  i18n.use(ChainedBackend).use(initReactI18next);
  if (!i18n.isInitialized) {
    return i18n.init(
      {
        ...i18nOptions,
        backend: {
          // @ts-ignore
          backends: [resourcesToBackend(resources), backend],
          backendOptions: [{}, i18nOptions.backend],
        },
      },
      () => {
        callback?.();
      }
    );
  }
  return Promise.resolve();
}

/**
 * Loads required namespaces into a route
 *
 * @returns A string or list of namespaces
 */
export function requireNamespaces<N extends Fallback<string> = DefaultNamespace>(
  ...namespace: N[]
) {
  return {
    requiredNamespaces: namespace,
  };
}

export default i18n;
