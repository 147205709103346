import { call, put, takeEvery } from 'redux-saga/effects';
import { apiGetJson, apiPatchJson } from '../core/dapi';
import { getPublicBookingByIdUrl } from '../core/dapi/waitList';
import { setPastBooking } from '../actions/pastBookings';
import { isEmpty } from '../core/util/empty';
import { receiveLocation, locationError, setActiveLocation } from '../actions/location';
import { opaqueLocation } from '../core/location/opaque';
import { getLocationUrl } from '../core/dapi/location';
import logger from '../core/logger/index';
import { getBookingById } from '../core/dapi/newBooking';

class UpcomingVisitActions {
  static FETCH_DATA = 'FETCH_DATA_UPCOMING_VISIT';

  static PATCH_DATA = 'PATCH_DATA_UPCOMING_VISIT';
}

function* fetchData({ bookingId }: any) {
  const publicBookingUrl = getPublicBookingByIdUrl(bookingId);

  const response = yield call(apiGetJson, publicBookingUrl);

  if (response.errors) {
    logger.error(response.errors);
  }

  if (response.data) {
    yield put(setPastBooking(response.data));

    const locationId = response.data.location_id;

    if (isEmpty(locationId)) {
      yield put(receiveLocation(opaqueLocation));
    } else {
      const locationUrl = getLocationUrl(locationId);

      const locationResponse = yield call(apiGetJson, locationUrl);

      if (locationResponse.errors) {
        yield put(locationError(locationId)(locationResponse.data));
      }

      if (locationResponse.data) {
        yield put(setActiveLocation(locationResponse.data));
      }
    }
  }
}

function* patchData({ bookingId, patchData, onSuccessCallback, onErrorCallback }: any) {
  const patchUrl = getBookingById(bookingId);

  const response = yield call(apiPatchJson, patchUrl, patchData);

  if (response.errors) {
    onErrorCallback(response.errors);
  }

  if (response.data) {
    let updatedBooking = { ...response.data, ...patchData };
    yield put(setPastBooking(updatedBooking));
    onSuccessCallback(response.data);
  }
}

function* rootSaga() {
  yield takeEvery(UpcomingVisitActions.FETCH_DATA, fetchData);
  yield takeEvery(UpcomingVisitActions.PATCH_DATA, patchData);
}

export { UpcomingVisitActions, rootSaga as default };
