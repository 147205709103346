import { DAPI_HOST } from '../../config/index';
import { BookingStatus } from '../../constants/index';

const getPreVisitAndInVisitPublicBookingsByLocationUrl = (locationId: any) =>
  `${DAPI_HOST}/v1/public-bookings?location_id=${locationId}` +
  `&filters=bookings.status:${BookingStatus.PRE_VISIT.concat(BookingStatus.IN_VISIT).join(',')}` +
  `&order_by=created_date ASC`;

const getUserBookingsAtLocation = (locationId: any, userProfileId: any) =>
  `${DAPI_HOST}/v1/bookings?location_id=${locationId}&user_profile_id=${userProfileId}`;

export { getPreVisitAndInVisitPublicBookingsByLocationUrl, getUserBookingsAtLocation };
