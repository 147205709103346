import {
  RECEIVE_INSURANCE_PROFILE,
  INSURANCE_PROFILE_ERROR,
  CLEAR_INSURANCE_PROFILE,
  INSURANCE_UPLOADING,
  SET_INSURANCE_MANUALLY,
} from '../constants/index';

export default function insuranceProfileReducer(state = {}, action: any) {
  switch (action.type) {
    case RECEIVE_INSURANCE_PROFILE:
      return { ...action.payload.value };
    case INSURANCE_PROFILE_ERROR:
      return { error: action.payload.value };
    case CLEAR_INSURANCE_PROFILE:
      return {};
    case INSURANCE_UPLOADING:
      return {
        isUploading: action.payload.value,
      };
    case SET_INSURANCE_MANUALLY:
      return {
        setInsuranceManually: action.payload.value,
      };
    default:
      return state;
  }
}
