import { DAPI_HOST } from '../../config/index';

const baseEndpointUrl = `${DAPI_HOST}/v1/web-push-subscriptions`;

const fetchWebPushSubscriptionUrl = (accountId: any) =>
  `${baseEndpointUrl}?account_id=${accountId}`;

const createWebPushSubscriptionUrl = () => baseEndpointUrl;

const deleteWebPushSubscriptionUrl = (subscriptionId: any) =>
  `${baseEndpointUrl}/${subscriptionId}`;

export { fetchWebPushSubscriptionUrl, createWebPushSubscriptionUrl, deleteWebPushSubscriptionUrl };
