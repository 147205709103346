import {
  WAIT_TIME_RECEIVED,
  WAIT_TIME_ERROR,
  LOADING_WAIT_TIME,
  SUBMITTING_WAIT_TIME,
} from '../constants/index';

const createInitialState = () => ({
  waitTime: null,
});

export default function waitTime(state = createInitialState(), action: any) {
  switch (action.type) {
    case WAIT_TIME_RECEIVED:
      return {
        waitTime: action.payload.value.duration_minutes,
      };
    case WAIT_TIME_ERROR:
      return {
        error: action.payload.value,
      };
    case LOADING_WAIT_TIME:
      return {
        loading: true,
      };
    case SUBMITTING_WAIT_TIME:
      return {
        submitting: true,
      };
    default:
      return state;
  }
}
