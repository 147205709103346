import React, { useState } from 'react';
import { ChoiceCard } from '@solvhealth/jigsaw';
import { Stack } from '@solvhealth/jigsaw';
import { FormattedResponseOption } from '~/components/Form/types/types';
import { handleCheckboxChange } from '~/components/Form/CheckboxGroup/util';

type Props = {
  options: FormattedResponseOption[];
  onChange?: (...args: any[]) => any;
  value?: Pick<FormattedResponseOption, 'value'>[];
};

const CheckboxChoiceCardGroup = (props: Props) => {
  const { value, options, onChange } = props;
  const [values, setValues] = useState(value);

  return (
    <Stack space={3} width="100%">
      {options.map((option) => {
        const { key, label } = option;
        const isSelected = values ? values.indexOf(key) > -1 : false;
        return (
          <ChoiceCard
            checkbox
            key={key}
            onClick={() => handleCheckboxChange(key, setValues, values, onChange)}
            selected={isSelected}
            title={label}
            type="button"
          />
        );
      })}
    </Stack>
  );
};

export default CheckboxChoiceCardGroup;
