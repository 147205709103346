import { useCallback, useRef } from 'react';
import useModal from '../../../hooks/useModal';

export default () => {
  const { closeModal } = useModal();
  const closers = useRef<Record<string, () => void>>({});

  return useCallback(
    (modalId: string) => {
      if (!closers.current[modalId]) {
        closers.current[modalId] = () => closeModal(modalId);
      }

      return closers.current[modalId];
    },
    [closers, closeModal]
  );
};
